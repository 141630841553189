import { ReactNode } from 'react'
import { Image } from 'shared/ui/components'

import { ImageWrapper } from './styled'

interface ImageItemProps {
  optionsBtn: ReactNode
  bookmarkBtn: ReactNode
  openGallery?: () => void
  imgPath: any
  isMain?: boolean
  fileName?: string
}

export const ImageItem = ({
  imgPath,
  openGallery,
  bookmarkBtn,
  optionsBtn,
}: ImageItemProps) => (
  <ImageWrapper
    display="inline-flex"
    width="inherit"
    height="inherit"
    position="relative"
    onClick={() => {
      if (openGallery) openGallery()}
    }
  >
    <Image
      src={imgPath}
    />
    {optionsBtn}
    {bookmarkBtn}
  </ImageWrapper>
)
