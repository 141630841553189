import { Box, Typography } from '@mui/material'

export const Header = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="flex-start"
    flexDirection="column"
    my={3}
  >
    <Typography component="h1" variant="h3" fontSize="1.5rem">
      Доступ к отчетам
    </Typography>
  </Box>
)