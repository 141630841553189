import { useQuery } from 'react-query'
import axios from 'axios'

export const axiosClient = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
})
export const getHeaderQueryKey = () =>
  ['head', '/'] as const
const getHeader = () =>
  axiosClient
    .head(
      '/'
    )
    .then(({ headers }) => headers)


export const useGetHeaderQuery = () => useQuery(getHeaderQueryKey(), getHeader, { 
  refetchInterval: 300000,
  keepPreviousData: false  })
