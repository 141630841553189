import { pimApi } from 'shared/api'
import { MediaImageItemMemo } from 'widgets'
import { snackActions } from 'shared/lib/react/snackbar'

interface PhotoItemProps {
  productId: UniqueId
  item: pimApi.products.MediaFile
  onFileDelete: (item: pimApi.products.MediaFile) => void
  index: number
  disabled?: boolean
}

const fileThumbnailSize = { maxWidth: 350, maxHeight: 350 }

export const PhotoItem = ({ productId, item, onFileDelete, disabled }: PhotoItemProps) => {
  const { downloadUrl, id, fileName } = item
  const { mutate: importMediaFromProduct, isLoading: importMediaInProgress } =
    pimApi.products.common.useImportMediaFromProduct()

  const imgPath = pimApi.products.simple.getSimpleMediaApiPath(productId, id, 'photos', fileThumbnailSize)
  const handleFileDelete = () => onFileDelete(item)

  const handleImportPhotoFromProduct = () => {
    importMediaFromProduct({ productId, mediaId: id, marketplaceName: 'simple' },
      {
        onSuccess: () => {
          snackActions.info('Успешно')
        }
      })
  }

  return (
    <MediaImageItemMemo
      imgPath={imgPath}
      fileName={fileName}
      onFileDelete={handleFileDelete}
      isLoading={importMediaInProgress}
      importPhotoFromProduct={handleImportPhotoFromProduct}
      downloadUrl={downloadUrl}
      disabled={disabled}
    />
  )
}
