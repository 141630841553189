/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import axios, { AxiosTransformer } from "axios";
import queryString from "query-string";
import { toCamelCase, toSnakeCase } from "../../../lib/transform";

export type ResponseType = any

export type IRequest = CamelCasedPropertiesDeep<{
  clientId: string,
  goodsId: number
}>

export const apiFunction = async ({ clientId,goodsId }: IRequest) => {
  return axios.get(
    `/yml/goods/${clientId}/${goodsId}`, {
      transformResponse: [
        ...axios.defaults.transformResponse as AxiosTransformer[],
        (data) => data instanceof Blob ? data: toCamelCase(data),
      ]
    }
  ).then(res => res.data)
}


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ clientId,goodsId }: IRequest) => ['get', `/yml/goods/${clientId}/${goodsId}`, { clientId,goodsId }] as const

export const getGoodInfo = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

