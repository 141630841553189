import { FieldArray } from 'react-final-form-arrays'
import { useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'

import { PriceCardWithMenu } from './PriceCardWithMenu'
import { PriceChangeWrapper } from './styled'


interface PriceChangeProps {
  price: number
  oldPrice: number
  discount?: number
  futurePrices?: Array<pimApi.products.PriceInfo>
  priceRecentHistory?: Array<any>
  onUpdate: (data: pimApi.products.SetOzonPriceInfo) => void
  readOnly?: boolean
}

export const PriceChange = ({
  futurePrices,
  onUpdate,
  ...props
}: PriceChangeProps) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  return (
    <PriceChangeWrapper>
      <PriceCardWithMenu
        productId={productId}
        onAdd={onUpdate}
        {...props}
      />
      <FieldArray<pimApi.products.PriceInfo> name="futurePrices">
        {({ fields }) =>
          !isEmpty(fields) &&
            fields?.value?.map(({ changeDate, id: priceId, ...rest }, index) => (
              <PriceCardWithMenu
                key={priceId}
                id={priceId}
                title={`Цена 
                ${changeDate.split('-')[2].split('T')[0]} 
                ${DateAppearanceSettings[changeDate.split('-')[1]].month.toLowerCase()}`
                }
                productId={productId}
                onRemove={() => fields.remove(index)}
                {...rest}
              />
            ))
        }
      </FieldArray>
    </PriceChangeWrapper>
  )
}
