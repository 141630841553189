import { useQueryClient } from 'react-query'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { Option } from 'shared/ui/components/interface'
import { ArchiveModalForm } from 'shared/ui/components/ArchiveModal'

interface ArchiveModalProps {
  close: () => void
  accept: () => void
  product: pimApi.products.common.Product
}

interface OptionItem {
  name?: string
  id: number
  commentsRequired?: boolean
}


export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
  commentsRequired: optionItem.commentsRequired,
})


export const ArchiveModal = ({ close, product }: ArchiveModalProps) => {
  const queryClient = useQueryClient()
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(product.productId)

  const { mutate: archiveProduct, isLoading } =
    pimApi.products.common.useArchiveProductMutation()
  

  const onSubmit = (formValues) => {
    const archiveProductPayload = {
      productId: product.productId,
      archivationInfo: {
        productId: product.productId,
        versionNo: product.versionNo,
        archivationreasonId: formValues.archivationReasonId,
        archivationComments: formValues.archivationComments,
      },
    }

    archiveProduct(archiveProductPayload, {
      onSuccess: ({ data }) => {
        close()
        queryClient.setQueryData(pimApi.products.common.getFetchCommonProductQueryKey(product.productId), data)
        ozonProductQuery.refetch()
        snackActions.info('Сохранено!')
      },
    })
  }

  return (
    <ArchiveModalForm onSubmit={onSubmit} close={close} isLoading={isLoading}/>
  )
}
