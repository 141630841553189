import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import TextInput from 'shared/ui/components/TextInput'
import { BaseInputFieldI } from 'shared/ui/components/TextInput/TextInputI'

interface InputFieldWithSymbolsCounterI<T> extends FieldRenderProps<T>, BaseInputFieldI {
  label?: string
  maxLength: number
  input: any
}

const Text = styled.span`
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.54);
  background-color: white;
  padding: 0 5px;
  position: absolute;
  top: -9px;
  right: 8px;
`

export const ProductName = <T extends {}>(
  {
    label,
    maxLength,
    input,
    ...rest
  }: InputFieldWithSymbolsCounterI<T>
) =>
    (
      <div style={{ position: 'relative' }}>
        <TextInput {...input} label={label} {...rest} type={input.type} />
        <Text>Символов: {input.value.length}/{maxLength}</Text>
      </div>
    )
