import styled from 'styled-components'
import { Box } from '@mui/material'

export const ValueLegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${props => props.color};
`
export const ValueDeltaContainer = styled(Box)<{ $diff: boolean }>`
  #value-delta-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    color: #0000008A;
  }
  
  #value-delta-container {
    display: flex;
    flex-direction: row;
    
    #value-text {
      font-size: 24px;
      line-height: 133.4%;
      margin-right: 8px;
    }
    
    #delta-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: fit-content;
      padding-top: 1px;
      #delta-icon {
        font-size: 16px;
        color: ${({ $diff }) => $diff ? '#F50057' : '#00000099'};
        rotate: ${({ $diff }) => $diff ? '90deg' : ''};
      }
      #delta-value {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: ${({ $diff }) => $diff ? '#F50057' : '#00000099'};
      }
    }
  }
`