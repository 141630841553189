import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

const dateFormat = (date) => format(new Date(date), DateFormats.dayMonthYearHoursMinutes)

export const UserDateOutCell = ({ values }: { values?: string }) => {
  if (!values) return null
  return(
    <>
      <span>Закрытие доступа {dateFormat(values)}</span>
    </>
  )
}
