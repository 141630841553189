import { ICode } from 'shared/services/interfaces/product'
import { getIsUniqueElements } from 'shared/lib/utils/unique'
import * as yup from 'yup'

export const validationSchema = yup.object({
  codes: yup.array().test(
    'unique',
    'Коды товара поставщика не уникальны',
    (codes: Array<ICode> | undefined) => {
      if (!codes) return true

      // @ts-ignore
      return getIsUniqueElements(codes.map(s => s.code))
    }
  ).of(
    yup.object().shape({
      code: yup.string().max(100, 'Максимальное количество символов - 100')
    }).required()
  )
})
