import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api/index'

function getReports() {
  return api.get<customerApi.ReportGroupCamel[]>('/customer/reports/list').then((res) => res.data)
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'customer/reports/list'] as const

export const useGetReportsQuery = () =>
  useQuery<
    customerApi.ReportGroupCamel[],
    errorType,
   customerApi.ReportGroupCamel[],
    QueryKey
  >(
    QUERY_KEY,
    getReports, { refetchOnMount: false, }
  )
