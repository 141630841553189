import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { AnalyticsParams, AnalyticsStocks } from './model'

import { toSnakeCase } from '../../lib/transform'
import { toSnake } from '../../lib/transform/toSnakeCase'

const getAnalyticsStocksQueryKey = (queryParams: AnalyticsParams) =>
  ['post', 'analytics/stocks', queryParams] as const

type GetAnalyticsStocksQueryKey = ReturnType<typeof getAnalyticsStocksQueryKey>

function getAnalyticsStocks(queryParams: AnalyticsParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  const { dateFrom, dateTo, units, ...rest }= queryParams

  return api.post<AnalyticsStocks>(
    '/analytics/stocks', rest,
    {
      params: { date_from: dateFrom, date_to: dateTo, units }
    }
  )
}

const getAllAnalyticsStocks = (context: QueryFunctionContext<GetAnalyticsStocksQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getAnalyticsStocks(queryParams)
}

export const useAnalyticsStocksQuery = (queryParams: AnalyticsParams) =>
  useQuery<
    AxiosResponse<AnalyticsStocks>,
    errorType,
    AxiosResponse<AnalyticsStocks>,
    GetAnalyticsStocksQueryKey
  >(
    getAnalyticsStocksQueryKey(queryParams),
    getAllAnalyticsStocks,
    { staleTime: Infinity, keepPreviousData: true }
  )
