import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const WmsSuppliers = ({ row }) => (
  <Wrapper>
    <span title={row.supplierName}>{ row.supplierName }</span>
    <span title={row.brand}>{ row.brand }</span>
  </Wrapper>
)