import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #007DFF;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`