import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { toSnakeCase } from 'shared/lib/transform'

import {
  Category,
  BrandOption,
  ProductPreview,
  AttributeValue
} from './models'

import { api } from '../base'

export * as model from './models'

const BASE_URL = '/ozonproxy'

export const fetchCategories = (
  searchString: string,
  companymarketplaceId: UniqueId
): Promise<AxiosResponse<Array<Category>>> =>
  api.get<Array<Category>>(
    `${BASE_URL}/categories?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
  )

export const fetchBrands = (
  searchString: string,
  companymarketplaceId: UniqueId,
  // timeout?: number
) => api.get<Array<BrandOption>>(
  `${BASE_URL}/brands?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
)


export const fetchProductSku = (
  sku: string,
) =>
  api.get<ProductPreview>(
    `${BASE_URL}/product/${sku}`
  )

type OzonproxyProductBySkuQuery = ReturnType<typeof getGetOzonproxyProductBySkuQueryKey>

const getGetOzonproxyProductBySkuQueryKey = (sku: string) => ['get', `${BASE_URL}/product/`, sku] as const

export const useFetchProductSkuQuery = (sku: string) =>
  useQuery<
    AxiosResponse<ProductPreview>,
    AxiosError<errorType>,
    AxiosResponse<ProductPreview>,
    OzonproxyProductBySkuQuery
  >(
    getGetOzonproxyProductBySkuQueryKey(sku),
    (context) => fetchProductSku(context.queryKey[2]),
    {
      enabled: sku.trim().length > 0
    }
  )

export const fetchAttributeValues = (
  {
    searchString,
    attributeId,
    descriptionCategoryId,
    typeId,
    companymarketplaceId,
  }: {
    searchString: string,
    attributeId: UniqueId,
    descriptionCategoryId: UniqueId,
    typeId: UniqueId,
    companymarketplaceId: UniqueId,
  }
) => 
  api.get<Array<AttributeValue>>(
    `${BASE_URL}/attribute/values`,
    {
      params: toSnakeCase({
        searchString,
        attributeId,
        descriptionCategoryId,
        typeId,
        companymarketplaceId
      })
    }
  )