import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { STOCKS_GOODSSUPPLYING } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'
import { GoodsSupplying } from 'features/stocks/goodssupplying'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: STOCKS_GOODSSUPPLYING,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: GoodsSupplying,
  }
] as Array<Route>