import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import { Checkbox } from '@mui/material'
import { toSimpleDate } from 'shared/lib/utils/dateToString'
import { Edit } from '@mui/icons-material'
import { customerGen } from 'shared/lib/generated'
import { dialogModel } from 'shared/ui/components/dialog'

import { StyledPrivilegeContainer } from './styled'

import { UserAccessModal } from '../../../../modals/UserAccessModal'
import { expandAllChildren } from '../../../../lib/expandNestedPrivileges'
import { isNotNil } from '../../../../../../../../shared/lib/checkers'

export const TreeItemLabel = ({ node }) => {
  const { id: usersId } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  const userId = parseInt(usersId, 10)
  const { data: userData } =
    customerGen.administration.GetUserInfo.useGetUserInfo({ userId })

  const { mutate: deleteUserPrivilegeMutation } =
    customerGen.administration.DeletePrivilegeFromUser.useDeletePrivilegeFromUser()

  const { mutate: addUserPrivilegeMutation } =
    customerGen.administration.AddPrivilegeToUser.useAddPrivilegeToUser()

  const { mutate: editPrivilegeMutation } =
    customerGen.administration.ClosePrivilegeOfUser.useClosePrivilegeOfUser()


  const addPrivileges = (privilegeData) => {
    const isSinglePrivilege = privilegeData?.id

    const addedPrivileges = isSinglePrivilege ?
      [privilegeData.id] :
      expandAllChildren([privilegeData]).filter(el => !el.selected).map(el => el.id)

    addUserPrivilegeMutation({
      userId,
      data: {
        dateIn: privilegeData.dateIn,
        dateOut: privilegeData.dateOut,
        privilegeIds: addedPrivileges
      }
    },{
      onSuccess: (response) => {
        queryClient.setQueryData<customerGen.administration.GetUserInfo.ResponseType>(
          customerGen.administration.GetUserInfo.getQueryKey({ userId }), response)
        snackActions.info(
          isSinglePrivilege ?
            'Привилегия успешно добавлена' :
            'Привилегии успешно добавлены'
        )
      },
    })
  }

  const editPrivileges = (privilegeData) => {
    const isSinglePrivilege = privilegeData?.id

    const addedPrivileges = isSinglePrivilege ?
      [privilegeData].map(el => ({
        dateOut: privilegeData.dateOut,
        versionNo: el.userPrivilegeVersionNo,
        id: el.userPrivilegeId })) :
      expandAllChildren([privilegeData]).map(el => ({
        dateOut: privilegeData.dateOut,
        versionNo: el.userPrivilegeVersionNo,
        id: el.userPrivilegeId }))

    editPrivilegeMutation({
      userId,
      data: addedPrivileges
    },{
      onSuccess: (response) => {
        queryClient.setQueryData<customerGen.administration.GetUserInfo.ResponseType>(
          customerGen.administration.GetUserInfo.getQueryKey({ userId }), response)
        snackActions.info('Успешно изменено')
      },
    })
  }
  const deleteUserPrivileges = () => {
    const isSinglePrivilege = node?.id

    const deletedPrivileges = isSinglePrivilege ?
      [{ id: node.userPrivilegeId, versionNo: node.userPrivilegeVersionNo }] :
      expandAllChildren([node]).filter(el => el.selected && isNotNil(el.userPrivilegeId)).map(el => ({
        id: el.userPrivilegeId,
        versionNo: el.userPrivilegeVersionNo
      }))


    deleteUserPrivilegeMutation({
      userId,
      data: deletedPrivileges
    },{
      onSuccess: () => {
        queryClient.setQueryData<customerGen.administration.GetUserInfo.ResponseType>(
          customerGen.administration.GetUserInfo.getQueryKey({ userId }), (updater) => {
            const newObj = updater?.privileges?.filter(el => !deletedPrivileges.map(elem => elem.id).includes(el.id) )

            return {
              ...updater,
              privileges: newObj
            } as customerGen.administration.GetUserInfo.ResponseType
          })
        snackActions.info(
          isSinglePrivilege ?
            'Привилегия успешно удалена' :
            'Привилегии успешно удалены'
        )
      },
    })
  }

  const addPrivilegeModal = () => {
    dialogModel.openDialog({
      component: ({ close }) =>
        <UserAccessModal
          close={close}
          name={node.name}
          manager={node}
          category="привилегии"
          handleUserAdd={addPrivileges}
        />
    })
  }

  const editPrivilegeModal = () => {
    const dateIn = node.dateIn || expandAllChildren([node], [])?.[0].dateIn

    dialogModel.openDialog({
      component: ({ close }) =>
        <UserAccessModal
          close={close}
          name={node.name}
          manager={{ ...node, dateIn }}
          category="привилегии"
          handleUserChange={editPrivileges}
        />
    })
  }

  const handleCheckboxClick = () => {
    if (node.selected) {
      deleteUserPrivileges()
    } else {
      addPrivilegeModal()
    }
  }

  const userPrivilege = userData?.privileges?.find(el => el.privilegeId === node.id)
  if (userData) return (
    <StyledPrivilegeContainer>
      <span className="privileges-title">{ node.name }</span>
      <div className="checkbox-container">
        <Checkbox
          checked={ node.selected || false}
          disabled={ node.disabled }
          key={ node?.id ? node.id.toString() : node.name }
          id={ node?.id ? node.id.toString() : node.name }
          onClick={(e) => {
            e.stopPropagation()
            handleCheckboxClick()
          }}/>
      </div>
      <div className="date-container">
        { toSimpleDate(userPrivilege?.dateIn) }
      </div>
      <div className="date-container">
        { toSimpleDate(userPrivilege?.dateOut) }
      </div>
      <div className="edit-icon-container">
        { node.selected && !node.disabled ?
          <Edit onClick={(e) => {
            e.stopPropagation()
            editPrivilegeModal()
          }}/> :
          <></>
        }
      </div>
    </StyledPrivilegeContainer>
  )
  return <></>
}