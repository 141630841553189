import { useParams } from 'react-router-dom'
import { Preloader, BackButton } from 'shared/ui/components'
import { pimApi } from 'shared/api'

import { VariantStep } from './VariantStep'

import { PageTitleStyled } from '../../components/common'

export const ProductVariant = () => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)

  return (
    <div>
      <PageTitleStyled>
        <BackButton/>
        <span>Вариация товара</span>
      </PageTitleStyled>
      {goodsAttributesForVariationsQuery.isLoading ? <Preloader /> : <VariantStep />}
    </div>
  )
}
