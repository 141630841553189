import { Box, Tooltip, Typography } from '@mui/material'
import { format, isToday } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import sumBy from 'lodash/sumBy'
import { tradeApi } from 'shared/api'
import UpdateIcon from '@mui/icons-material/Update'
import { DateFormats } from 'shared/config/dateFormats'
import { PieChart } from 'shared/ui/components'
import { COLORS } from 'shared/ui/components/PieChart'
import { sort } from 'shared/lib/utils/sort'
import { InfoOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import { tooltipClasses } from '@mui/material/Tooltip'

import { ChartWrapper, TitleWrapper, StyledCard, StyledCardContent } from './styled'

import { HelpWithMargin } from '../../../../shared/ui/styled/Help'

export interface StocksCardProps {
  stocksData?: tradeApi.ProductStocksInfo
}

export function getStocksDate(date) {
  const isTodayDate = isToday(new Date(date))

  if (isTodayDate) {
    return format(new Date(date), DateFormats.hours24Minutes)
  }
  return format(new Date(date), DateFormats.dayMonthHoursMinutes, {
    locale: ruLocale,
  })
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 6px 9px -7px rgba(0, 0, 0, 0.2), 0px 19px 30px 3px rgba(0, 0, 0, 0.14), 0px 9px 16px 8px rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    padding: '12px'
  },
}))

export const StocksCard = ({ stocksData }: StocksCardProps) => {
  const newStocksDate: Array<any> = []
  const stocksDataPrepared = sort(stocksData?.stocks?.map((stock) => ({
    name: stock.marketplacewarehouseName,
    value: stock.available,
    reserved: stock.reserved,
  })) || [], 'value')

  let availableSumm = 0
  let reservedSumm = 0
  stocksDataPrepared.forEach((el, index) => {
    if (index === 0 || index === 1) {
      newStocksDate.push(el)
    }
    else {
      reservedSumm += el.reserved
      availableSumm += el.value
    }
  })
  if (availableSumm !== 0) {
    newStocksDate.push({
      name: 'Другие',
      value: availableSumm,
      reserved: reservedSumm
    })
  }

  const stocksUpdatedData = stocksData?.lastChangeDate
    ? getStocksDate(stocksData?.lastChangeDate)
    : ''

  const isSingleWarehouse = stocksData?.stocks?.length === 1
  const isSingleWareHouseUnnamed =
    isSingleWarehouse && !stocksData?.stocks?.[0].marketplacewarehouseName

  return (
    <StyledCard>
      <StyledCardContent>
        <TitleWrapper>
          <Typography fontSize={12} color="rgba(0, 0, 0, 0.6)">
            Остатки
          </Typography>
          <Box ml={2}  display="flex" flexDirection="row" gap="5px" alignItems="center">
            {stocksUpdatedData &&
            <>
              <UpdateIcon sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.26)' }}/>
              <Typography fontSize={12} color="#9e9e9e">
                {stocksUpdatedData}
              </Typography>
            </>
            }
          </Box>
        </TitleWrapper>
        <Box display="flex" flexGrow="1" mt="4px" justifyContent={isSingleWareHouseUnnamed ? 'center' : ''}>
          <ChartWrapper>
            <PieChart
              data={newStocksDate}
              width={88}
              height={88}
              innerRadius={38}
              outerRadius={43}
              showValuesSumInTheCenter={true}
            />
          </ChartWrapper>
          {!isSingleWareHouseUnnamed && (
            <Box display="flex" flexDirection="column" ml="14px" justifyContent="center">
              {newStocksDate?.map((stock, index) => (
                <Box
                  key={stock.name}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="8px"
                >
                  <Typography
                    fontSize={14}
                    fontWeight="400"
                    textAlign="left"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    {stock.name}
                  </Typography>
                  {stock.name === 'Другие' &&
                    <CustomTooltip title={
                      <Box display="flex" flexDirection="column" gap="5px">
                        {stocksDataPrepared.slice(2).map((el, ind) => (
                          <Box key={el.name} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography
                              fontSize={14}
                              fontWeight="400"
                              textAlign="left"
                              color="rgba(0, 0, 0, 0.87)"
                            >
                              {el.name}
                            </Typography>
                            <Typography
                              fontSize={13}
                              ml={3}
                              fontWeight="500"
                              color={COLORS[ind + 3 % COLORS.length]}
                            >
                              {el.value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    } 
                    placement="right">
                      <HelpWithMargin style={{ marginLeft: '5px' }}>
                        <InfoOutlined />
                      </HelpWithMargin>
                    </CustomTooltip>
                  }
                  
                  <Tooltip
                    title={`Доступно: ${stock.value} / Зарезервировано: ${stock.reserved}`}
                    placement="right"
                  >
                    <Typography
                      fontSize={13}
                      ml={3}
                      fontWeight="500"
                      color={COLORS[index % COLORS.length]}
                    >
                      {stock.value}
                    </Typography>
                  </Tooltip>
                </Box>
              ))}
              <Box>
                <Tooltip
                  title={`Всего доступно: ${sumBy(
                    stocksData?.stocks,
                    'available'
                  )} / Всего зарезервировано: ${sumBy(
                    stocksData?.stocks,
                    'reserved'
                  )}`}
                  placement="right"
                >
                  <Box>
                    {stocksData?.inSupply && (
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography
                          fontSize={14}
                          color="rgba(0, 0, 0, 0.38)">
                          В поставке
                        </Typography>
                        <Typography
                          fontSize={13}
                          ml={3}
                          fontWeight="500"
                          color="rgba(0, 0, 0, 0.38)"
                        >
                          {stocksData?.inSupply}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
        {isSingleWareHouseUnnamed && stocksData?.inSupply && (
          <Box
            display="flex"
            justifyContent="center"
            pt="4px"
            mt={2}
            borderTop="1px solid #afafaf;"
          >
            <Typography
              fontSize={10}
              color="#666666"
              mb="4px"
              ml="4px"
            >
              +{stocksData?.inSupply} в поставке
            </Typography>
          </Box>
        )}
      </StyledCardContent>
    </StyledCard>
  )
}
