import React from 'react'
import { analyticsApi } from 'shared/api'
import { SalesWithSmallChart } from 'widgets/DashboardWidgets'
import { generatePath } from 'react-router'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { subtractDays } from 'shared/lib/utils/subtractDays'

export const DoubleSmallWeeklySalesWidget = () => {
  const utc = new Date()
  const dateFrom = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 14)
  const dateTo = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 1)

  const { data: salesData } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })
  const currentData = salesData?.data.data.slice(7)
  const prevData = salesData?.data.data.slice(0,7)
  const currentWeekQuantity = currentData?.reduce((sum, elem) => sum + elem.quantity, 0)
  const prevWeekQuantity = prevData?.reduce((sum, elem) => sum + elem.quantity, 0)
  const diff = (currentWeekQuantity !== undefined && prevWeekQuantity !== undefined) ? (currentWeekQuantity - prevWeekQuantity) : 0
  const chartUrl = generatePath('/analytics/charts?range=0&tab=1')
  return (
    <>
      {salesData?.data.data ?
        <SalesWithSmallChart
          title="Продажи за неделю"
          chartUrl={chartUrl}
          data={currentWeekQuantity}
          diff={diff}
          currentSalesData={currentData}
          prevSalesData={prevData}/>
        :
        <Skeleton/>
      }
    </>
  )
}