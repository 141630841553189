import { useMemo } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import sortBy from 'lodash/sortBy'
import { useFormState } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { getProductEditPath } from 'shared/config'
import { pimApi } from 'shared/api'
import { CreateNewTab } from 'features/product/WB/CreateNewTab'
import styled from 'styled-components'
import { ProductSettings } from 'widgets/product/WB/SettingsMenu'
import { TabPanel } from 'shared/ui/components'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { VariationsForm } from './ui/VariationsForm'

import { SizeTabsNav } from '../styled'

interface StyledTabProps {
  label: string;
  allVariationsArchive?: boolean
  value: number
  isArchived: number | undefined
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab iconPosition="end" disableRipple={true}
    icon={<ProductSettings/>}
    {...props} />
))((props) => ({
  '&.MuiTab-root': {
    color: props.isArchived ? '#00000061' : '',
  },
  '&.Mui-selected': {
    color: props.isArchived ? '#ED6C02' : '',
    backgroundColor: props.isArchived ? '#FAFAFA' : 'rgba(33, 150, 243, 0.08)',
    borderBottom: props.allVariationsArchive ? '1px solid #E0E0E0' : '1px solid #80BEFF',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  borderRadius: '4px 4px 0 0',
  minHeight: '42px',
  height: '42px',
}))

const getTabLabel = (tab: pimApi.products.wb.WBVariation) => {
  let label = 'НОВЫЙ РАЗМЕР'
  if (tab.size) {
    label = tab.size
  } if (tab.ruSize) {
    label = tab.ruSize
  }
  return label
}

export const Variations = ({ isArchived = false }) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const { initialValues } = useFormState({ subscription: { initialValues: true } })
  const { sizeSupport } = initialValues
  const variations = initialValues?.variations || []
  const handleChangeSizeTab = (_: React.ChangeEvent<{}>, newVariationIndex: number) => {
    if (newVariationIndex !== productId) {
      history.push(getProductEditPath(newVariationIndex))
    }
  }

  const tabs = useMemo(() => {

    const labeledTabs = variations.map((tab) => ({
      ...tab,
      label: getTabLabel(tab)
    }))
    return sortBy(labeledTabs, 'id')
  }, [variations])

  return (
    <>
      {sizeSupport  && (
        <Box pt="20px">
          <SizeTabsNav $isArchived={isArchived}>
            <Tabs
              value={productId}
              onChange={handleChangeSizeTab}
            >
              {tabs.map((tab, index) => (
                <StyledTab
                  value={tab.productId}
                  isArchived={tab.archivationreasonId}
                  allVariationsArchive={isArchived}
                  key={tab.productId}
                  label={tab.label}
                  {...a11yProps(index)}
                />
              ))}
              {!isArchived && <CreateNewTab newVariation={true} />}
            </Tabs>
          </SizeTabsNav>
        </Box>
      )}

      {initialValues.variations.map((_, index) => (
        <TabPanel
          key={initialValues.variations[index].productId}
          value={productId}
          index={initialValues.variations[index].productId}>
          <VariationsForm
            key={initialValues.variations[index].productId}
            name={`variations[${index}]`}
            productId={productId}
          />
        </TabPanel>
      ))
      }
    </>
  )
}
