import {
  AlgorithmCell, AutoChangePriceCell,
  ChartCell,
  CurrentPriceCell,
  ExpandButtonCell,
  IncomingPriceCell,
  ProductInfoCell,
  SaveButtonCell,
  StatusCell,
  StocksCell
} from '../../../ui/tableCells'

export const PriceSettingsCells = [
  {
    name: '',
    width: '48px',
    padding: '0px',
    Component: ExpandButtonCell
  },
  {
    name: 'Продукт',
    width: '220px',
    padding: '0px 16px 0px 16px',
    Component: ProductInfoCell
  },
  {
    name: '',
    width: '40px',
    padding: '17px 8px 16px 8px',
    Component: ChartCell
  },
  {
    name: 'Алгоритм',
    width: '220px',
    padding: '16px',
    Component: AlgorithmCell
  },
  {
    name: 'Статус',
    width: '240px',
    padding: '20px 16px 16px 16px',
    Component: StatusCell
  },
  {
    name: 'Остатки',
    width: '86px',
    padding: '20px 16px 16px 16px',
    Component: StocksCell
  },
  {
    name: 'Текущая цена',
    width: '194px',
    padding: '8px 16px 8px 16px',
    Component: CurrentPriceCell
  },
  {
    name: 'Входящая цена',
    width: '140px',
    padding: '20px 16px 8px 16px',
    Component: IncomingPriceCell
  },
  {
    name: 'Автоизменение',
    width: 'auto',
    padding: '16px 0px 16px 16px',
    Component: AutoChangePriceCell
  },
  {
    name: '',
    width: '150px',
    padding: '0px 16px 0px 16px',
    Component: SaveButtonCell
  },
]