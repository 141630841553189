import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import {
  AutoCreateBarcodeButton,
  EditBarcodeFieldStyled,
  NewBarcodeWrapper,
  AddBarcodeContainer,
  Close
} from './styled'

export interface MarketPlaceAdvicedBarcode {
  labelText: string,
  getBarcode: (onGetBarcode: (barcode: string) => void) => void,
  isLoading: boolean
}

interface NewBarcodeFieldProps {
  marketPlaceAdvicedBarcode?: MarketPlaceAdvicedBarcode,
  goodsAdvicedBarcode?: string
  createNewBarcode: (barcode: string) => void
  disabled?: boolean
  barcodeType?: string
  setAddingBarcodeInProgress?: (boolean) => void
}

export const NewBarcodeField = React.memo((
  {
    marketPlaceAdvicedBarcode,
    goodsAdvicedBarcode,
    createNewBarcode,
    disabled,
    barcodeType = 'number',
    setAddingBarcodeInProgress,
  } : NewBarcodeFieldProps
) => {
  const [closeBarcodeField, setCloseField] = useState(false)
  const handleNewBarcodeBlur = ({ target } ) => {
    if (target.value.length>0 && !closeBarcodeField) {
      createNewBarcode(target.value)
    }
  }
  
  const onCopyFromGoodsButtonClick = () => {
    if (goodsAdvicedBarcode !== undefined) {
      createNewBarcode(goodsAdvicedBarcode)
    }
  }

  const onGetMarketPlaceBarcodeClick = () => {
    marketPlaceAdvicedBarcode?.getBarcode( (barcode) => {
      if (barcode !== undefined) {
        createNewBarcode(barcode)
      }
    })
  }

  const handleCloseBarcodeCreator = () => {
    if (setAddingBarcodeInProgress) {
      setAddingBarcodeInProgress(false)
    }
  }

  return (
    <NewBarcodeWrapper>
      <EditBarcodeFieldStyled
        label="Штрих-код"
        variant="outlined"
        size="small"
        autoComplete="off"
        type={barcodeType}
        fullWidth={true}
        onBlur={handleNewBarcodeBlur}
        disabled={disabled}
        $boldLabel={false}
      />
      <Close 
        onMouseOver={() => {setCloseField(true)}}
        onClick={handleCloseBarcodeCreator}
        onMouseLeave={() => {setCloseField(false)}}
      >
        <CloseIcon />
      </Close>
      <AddBarcodeContainer>
        {marketPlaceAdvicedBarcode &&
        <AutoCreateBarcodeButton
          type="button"
          onClick={onGetMarketPlaceBarcodeClick}
          disabled={marketPlaceAdvicedBarcode.isLoading}
          $disabled={marketPlaceAdvicedBarcode.isLoading}>
          {marketPlaceAdvicedBarcode.labelText}
          {marketPlaceAdvicedBarcode.isLoading && (
            <CircularProgress size={12} />
          )}
        </AutoCreateBarcodeButton>
        }
        {goodsAdvicedBarcode &&
        <AutoCreateBarcodeButton type="button" onClick={onCopyFromGoodsButtonClick}>
          Скопировать из товара
        </AutoCreateBarcodeButton>
        }
      </AddBarcodeContainer>

    </NewBarcodeWrapper>
  )
})