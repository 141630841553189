import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { useSidebarStore } from '../../../store/sidebar/useSidebarStore'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div<{ width: string, sidebarOpen: boolean }>`
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  transition: min-width 0.2s ease-out 0.05s;
  min-width: ${({ sidebarOpen }) => sidebarOpen ? '1142px' : '1300px'};
  margin: auto;
`

interface LayoutProps {
  width: string
}

export const Layout = ({ children, width }: PropsWithChildren<LayoutProps>) => {
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen)
  return (
    <Wrapper className="main-content">
      <Box>
        <Content sidebarOpen={sidebarOpen} width={ width }>{ children }</Content>
      </Box>
    </Wrapper>
  )
}
