import React, { useMemo, useState } from 'react'
import { pimGen } from 'shared/lib/generated'
import { Field, Form } from 'react-final-form'
import { ProductWithPriceSettings } from 'shared/api/pim/price'
import { Autocomplete, Button, Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { CheckboxField, InputLabel, SelectField } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InfoOutlined } from '@mui/icons-material'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNil, isNotNil, isSameValues } from 'shared/lib/checkers'
import { sort } from 'shared/lib/utils/sort'
import { AlgorithmWithValue } from 'shared/api/pim/products/common'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import LoadingButton from '@mui/lab/LoadingButton'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import { DefaultValue } from './DefaultValue'

import { getValidationSchema } from '../../lib/getValidationSchema'
import { FormWrapper, InputTextWrapper, Title } from '../styled'
import { mergeArrays } from '../../lib/mergeArrays'
import { ParametersArray } from '../../../../../../shared/ui/components/DataType/ParametersArray'

interface IPriceSettingsForm {
  productPriceSettings: ProductWithPriceSettings | null
  algorithmsData: pimGen.priceWizard.GetAlgorithms.ResponseType
  productId: number
  externalOnSubmit?: (e) => void
  externalOnCancel?: () => void
}

export const PriceSettingsForm = ({
  productPriceSettings,
  algorithmsData,
  productId,
  externalOnSubmit,
  externalOnCancel }: IPriceSettingsForm) => {
  const queryClient = useQueryClient()
  const { mutate: createPriceSettings, isLoading: createIsLoading } = pimGen.priceWizard.CreatePriceSettings.useCreatePriceSettings()
  const { mutate: changePriceSettings, isLoading: changeIsLoading } = pimGen.priceWizard.PutPriceSettings.usePutPriceSettings()
  const { data: adviceIntervalOptions } = pimGen.priceWizard.GetIntervals.useGetIntervals()
  
  const [ algorithmId, setAlgorithmId ] = useState<number | null>(productPriceSettings?.advisor.selectedAlgorithmId || null)

  const settings = useMemo(() => {
    if (algorithmsData) {
      const advisor = productPriceSettings?.advisor
      const algorithm =  algorithmsData.map(el => {
        const addedAlgorithm = advisor?.algorithms.find(alg => alg.id === el.id)
        if (addedAlgorithm) {
          return { ...el, parameters: sort(mergeArrays(addedAlgorithm.parameters, el.parameters), 'indexNumber', 'desc') as any[] }
        }
        return el
      }).find(el => el.id === algorithmId)
      
      return {
        initialValues: { 
          ...(algorithm?.parameters as any)?.reduce((acc, param) => {
            acc[`id${param.id}`] = param.value ?? null
            return acc
          }, {}),
          disabled: productPriceSettings?.disabled,
          adviceInterval: productPriceSettings?.adviceInterval,
          autoChangePrice: productPriceSettings?.autoChangePrice,
          algorithm
        },
      }
    }
    return {}
  }, [productPriceSettings, algorithmsData, algorithmId])

  const algorithmsOptions = useMemo(
    () => algorithmsData.map(getOptions) || [],
    [algorithmsData]
  )

  const validationSchema = getValidationSchema(settings?.initialValues?.algorithm?.parameters)
  const validate = useValidationSchema(validationSchema)
  const showSubmitButton = isNotNil(algorithmId)
  
  const handleCancelClick = (form) => {
    if (externalOnCancel) {
      externalOnCancel()
    } else {
      setAlgorithmId(productPriceSettings?.advisor.selectedAlgorithmId || null)
      form.restart()
    }
  }

  const isDirty = (values, initialValues) => {
    if (algorithmId !== productPriceSettings?.advisor.selectedAlgorithmId) {
      return true
    }
    return !isSameValues(values, initialValues)
  }

  const onSubmit = (value) => {
    const { adviceInterval } = value
    const { autoChangePrice } = value
    
    const newParams = (value.algorithm?.parameters || []).map(el => ({
      ...el,
      value: value[`id${ el.id }`]
    }))
    
    if (isNil(productPriceSettings)) {
      createPriceSettings({ 
        data: {
          adviceInterval,
          autoChangePrice,
          productId,
          advisor: { 
            selectedAlgorithmId: algorithmId!,
            algorithms: [
              { ...settings.initialValues.algorithm, parameters: newParams } as AlgorithmWithValue
            ]
          }
        }
      }, {
        onSuccess: (response) => {
          snackActions.info('Продукт добавлен в волшебник цен')
          queryClient.setQueryData<pimGen.priceWizard.GetPriceSettings.ResponseType>(pimGen.priceWizard.GetPriceSettings.getQueryKey({
            query: {
              productId
            }
          }), (updater) => updater!.concat([response as any]) as pimGen.priceWizard.GetPriceSettings.ResponseType )
        }
      })
    } else {
      const index = productPriceSettings?.advisor.algorithms.findIndex(item => item.id === settings?.initialValues.algorithm?.id)
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        productPriceSettings.advisor.algorithms[index] = {
          ...settings.initialValues.algorithm, parameters: newParams } as AlgorithmWithValue
      } else {
        productPriceSettings?.advisor.algorithms.push({ ...settings.initialValues.algorithm, parameters: newParams } as AlgorithmWithValue)
      }
      changePriceSettings({
        data: {
          ...productPriceSettings,
          adviceInterval,
          autoChangePrice,
          advisor: {
            selectedAlgorithmId: algorithmId!,
            algorithms: productPriceSettings?.advisor.algorithms
          }
        }
      }, { 
        onSuccess: (response) => {
          snackActions.info('Настройки продукта изменены')
          queryClient.setQueryData<pimGen.priceWizard.GetPriceSettings.ResponseType>(pimGen.priceWizard.GetPriceSettings.getQueryKey({
            query: {
              productId
            }
          }), (updater) => updater!.map(el => {
            if (el.id === response.id) {
              return { ...el, ...response }
            }
            return el
          }) as pimGen.priceWizard.GetPriceSettings.ResponseType)
        }
      })
    }
  }

  const handleSwitchChange = () => {
    changePriceSettings({
      data: {
        ...productPriceSettings as ProductWithPriceSettings,
        disabled: !productPriceSettings?.disabled
      }
    }, {
      onSuccess: (response) => {
        snackActions.info('Настройки продукта изменены')
        queryClient.setQueryData<pimGen.priceWizard.GetPriceSettings.ResponseType>(pimGen.priceWizard.GetPriceSettings.getQueryKey({
          query: {
            productId
          }
        }), (updater) => updater!.map(el => {
          if (el.id === response.id) {
            return response
          }
          return el
        }) as pimGen.priceWizard.GetPriceSettings.ResponseType)
      }
    })
  }

  return (
    <Form
      onSubmit={(value) => {
        if (externalOnSubmit) {
          externalOnSubmit(value)
        } else {
          onSubmit(value)
        }
      }}
      validate={validate}
      initialValues={settings.initialValues}
      render={({ handleSubmit, errors, form, valid, values, initialValues }) => (
        <FormWrapper>
          <form onSubmit={ handleSubmit }>
            { isNil(externalOnSubmit) &&
              <div className="title">
                <Title>Настройка волшебника цен</Title>
                <FormControlLabel
                  control={ <Switch
                    checked={ isNil(productPriceSettings) ? false : !productPriceSettings?.disabled }
                    disabled={ createIsLoading || changeIsLoading || isNil(productPriceSettings) }
                    onChange={ handleSwitchChange }
                    size="small"
                    color="primary"
                    sx={ { marginRight: '8px' } }
                  /> }
                  label="Участвует в волшебнике цен"
                  labelPlacement="end"
                />
              </div>
            }
            <div className="algorithm-container">

              <div className="full-width-container">
                <div className="full-width-container">
                  <InputLabel
                    label="Алгоритм"
                    required={ false }/>
                </div>
                <Autocomplete
                  id="units"
                  value={ (algorithmsOptions.find(el => el.value === algorithmId) || null) as any }
                  disableClearable={ true }
                  onChange={ (_, value) => setAlgorithmId(value?.value || null)
                  }
                  size="small"
                  blurOnSelect={ true }
                  options={ algorithmsOptions }
                  renderInput={ (params) =>
                    <TextField
                      placeholder="Не выбран"
                      { ...params }
                    />
                  }
                />
              </div>

              { algorithmId &&
                <>
                  <div className="settings-container">
                    <div className="full-width-container">
                      <InputLabel
                        style={ { marginBottom: '0' } }
                        label="Автоматический котроль"
                        required={ false } />
                    </div>

                    <div>
                      <InputTextWrapper>
                        <Field
                          name="adviceInterval"
                          placeholder="Проверка"
                          disableClearable={ true }
                          component={ SelectField }
                          options={ adviceIntervalOptions || [] }
                          errorAfterTouch={ true }
                          fullWidth={ true }
                        />
                        <div style={{ width: '27px' }}/>
                      </InputTextWrapper>
                    </div>

                    <div style={ { paddingLeft: '16px', fontSize: '14px' } }>
                      <Field
                        name="autoChangePrice"
                        label="Автоизмение"
                        type="checkbox"
                        component={ CheckboxField }
                      />
                    </div>

                  </div>
                </>
              }

              { isNotEmptyArray(settings?.initialValues.algorithm?.parameters) &&
                <div className="settings-container">
                  <>
                    <div className="full-width-container">
                      <InputLabel
                        style={ { marginBottom: 0 } }
                        label="Настройка"
                        required={ false } />
                    </div>

                    { settings.initialValues.algorithm?.parameters.map(el => (
                      <div>
                        <InputTextWrapper>
                          <Field
                            {...el.dataType === 1 && { type: 'number' }}
                            {...el.dataType === 6 && { type: 'checkbox' }}
                            component={ ParametersArray[el.dataType].component }
                            error={ !!errors?.[`id${ el.id }`] }
                            fullWidth={ true }
                            label={ el.name }
                            name={ `id${ el.id }` }
                            InputProps={ { autoComplete: 'off' } }
                          />
                          <div className="description-container">
                            { el.description &&
                              <Tooltip title={ el.description } placement="right">
                                <HelpWithMargin>
                                  <InfoOutlined />
                                </HelpWithMargin>
                              </Tooltip>
                            }
                          </div>
                        </InputTextWrapper>
                        <DefaultValue
                          parameter={ el }
                          algorithmId={ settings.initialValues.algorithm?.id! }
                          productId={ productId }
                        />
                      </div>
                    )) }
                  </>
                </div>
              }

              
              <div className="full-width-container">
                <div className="submit-cancel-container">
                 
                  <>
                    { (showSubmitButton || isNotNil(externalOnSubmit) || isNotNil(externalOnCancel)) &&
                      <>
                        {isNil(externalOnCancel) ?
                          <Button
                            variant="text"
                            disabled={ !isDirty(values, initialValues) || changeIsLoading || createIsLoading }
                            onClick={ () => handleCancelClick(form) }>
                            Отмена
                          </Button>
                          :
                          <Button
                            variant="text"
                            onClick={() => handleCancelClick(form)}
                          >
                            ОТМЕНА
                          </Button>
                        }
                        {isNil(externalOnSubmit) ?
                          <LoadingButton
                            disabled={ !isDirty(values, initialValues) || !valid }
                            loading={ changeIsLoading || createIsLoading }
                            onClick={ handleSubmit }
                            variant="contained">
                            Сохранить
                          </LoadingButton>
                          :
                          <LoadingButton
                            disabled={ !isDirty(values, initialValues) || !valid || isNil(algorithmId) }
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Добавить
                          </LoadingButton>
                        }
                      </>
                    }
                  </>
                  
                </div>
              </div>
            </div>
          </form>
        </FormWrapper>
      ) }
    />
  )
}