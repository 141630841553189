import { snackActions } from 'shared/lib/react/snackbar'
import { pimApi } from 'shared/api'
import { ProductsResultArgs, QueryProductsArgs } from 'shared/lib/generated/products/Api'

interface ProductPayload extends QueryProductsArgs, ProductsResultArgs {}
export const useDownloadFile = () => {
  const { mutate, isLoading } = pimApi.excel.useDownloadProductsFile()

  const download = (queryParams: ProductPayload) => {
    mutate(queryParams, {
      onSuccess: () => {
        snackActions.info('Файл формируется')
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  return { download, isLoading }
}