import React, { memo } from 'react'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Image } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import imagePlaceholder from 'assets/images/imagePlaceholder.svg'
import { Link } from 'react-router-dom'
import { generatePath } from 'react-router'
import { PRODUCT_EDIT } from 'shared/config'

import { ComponentInfo, ProductImage, ProductTitle, ResultItem, StyledInfoItem } from './styled'

interface CustomProductShortInfoWidgetProps {
  productId?: UniqueId
  name?: string
  marketplaceCode?: string
  marketplaceUrl?: string
  marketplaceName?: string
  marketplaceCategory?: string
  barcode?: string
  price?: number
  companyName?: string
  cabinetName?: string
  autoOrdering?: boolean
  disabled?: boolean
  onAddClick?: () => void
}

export const CustomProductShortInfoWidget = memo(({
  productId,
  name,
  marketplaceCode,
  marketplaceUrl,
  marketplaceName,
  cabinetName,
  marketplaceCategory,
  price,
  companyName,
  autoOrdering,
  disabled,
  onAddClick
}: CustomProductShortInfoWidgetProps) => {


  const InfoText = ({ title, text }: { title?: string, text?: string | number}) => {
    if(!text) return null
    const optionalTitle = title ? `${title}: ` : ''
    return <StyledInfoItem>{`${optionalTitle}${text}`}</StyledInfoItem>
  }

  const Placeholder = () => (
    <img src={imagePlaceholder} alt="placeholder"/>
  )

  const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

  function getApiUrl(prodId) {
    return pimApi.products.getFileThumbnailApiPath(
      prodId,
      fileThumbnailSize
    )
  }

  return   (
    <ResultItem>
      <ProductImage>
        <Image src={getApiUrl(productId)} onError={Placeholder} />
      </ProductImage>
      <ComponentInfo>
        <ProductTitle>
          <Tooltip title={name?.length ? name : ''} placement="top">
            <Typography noWrap={true}>
              {productId && (
                <Link
                  to={generatePath(generatePath(PRODUCT_EDIT, { id: productId }))}
                  target="_blank"
                >
                  {name}
                </Link>
              )}
            </Typography>
          </Tooltip>
        </ProductTitle>
        <Box ml="-8px">
          <InfoText text={marketplaceName}/>
          <InfoText text={companyName}/>
          <span style={{ marginLeft: '8px' }}>{cabinetName}</span>
          {marketplaceUrl ? (
            <a href={marketplaceUrl} target="_blank" rel="noreferrer" >
              <InfoText title="Номенклатура" text={marketplaceCode}/>
            </a>
          ) : (
            <InfoText title="Номенклатура" text={marketplaceCode}/>
          )}
          <InfoText title="Цена, Р" text={price}/>
          {marketplaceCategory && <InfoText title="Категория" text={marketplaceCategory}/>}
          {autoOrdering ? <InfoText text="Автозаказ"/> : <></>}
        </Box>
      </ComponentInfo>
      <Box display="inline-flex" alignItems="center" pl={2}>
        <Box display="inline-flex" pl={1} pr={1} >
          <Box pr={1.5} onClick={ onAddClick }>
            <Button variant="contained" disabled={disabled}>ДОБАВИТЬ</Button>
          </Box>
        </Box>
      </Box>
    </ResultItem>
  )
}
)