import { Link as RouterLink } from 'react-router-dom'

import { ElementWrapper, Content } from './styled'

interface IAdminPanelContentElement {
    label: string
    subtitle?: string
    icon: JSX.Element
    value: string,
    primaryColor?: string,
    secondaryColor?: string,
}

interface IAdminPanelContentElementProps {
    element: IAdminPanelContentElement
}

const PanelContentElement = ({ element }: IAdminPanelContentElementProps) => {
  const { 
    label, 
    subtitle, 
    icon, 
    value,
  } = element
  return (
    <ElementWrapper elevation={0}>
      <RouterLink to={value}>
        <Content>
          {icon}
          <span>{label}</span>
          <div>{subtitle}</div>
        </Content>
      </RouterLink>
    </ElementWrapper>
  )
}

export default PanelContentElement