import React from 'react'
import TextField from '@mui/material/TextField'

export const InputCell = ({ setInitialValues, name, elem }) => {
  const onlyNumberTest = new RegExp('^[0-9]+$')

  const handleChange = (e) => {
    if (onlyNumberTest.test(e.target.value) || e.target.value === '') {
      setInitialValues(prev => prev.map(el => {
        if (el.id === elem.id) {
          return { ...el, [name]: e.target.value }
        }
        return el
      }))
    }
  }


  return (
    <TextField
      value={ elem[name] }
      onChange={handleChange}
      InputProps={{ autoComplete: 'off' }}
    />
  )
}