import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

export const columns = {
  changeDate: 'changeDate',
  changer: 'changer',
  operation: 'operation',
  changes: 'changes',
} as const

export const columnsConfig: Array<ColumnConfig<any>> = [
  {
    name: columns.changeDate,
    columnName: columns.changeDate,
    title: 'Дата изменения',
    width: 190,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    name: columns.changer,
    columnName: columns.changer,
    title: 'Автор',
    width: 190,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    name: columns.operation,
    columnName: columns.operation,
    title: 'Операция',
    width: 'auto',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    name: columns.changes,
    columnName: columns.changes,
    title: 'Изменения',
    width: 'auto',
    sortingEnabled: false,
    wordWrapEnabled: true,
    cellType: CellType.RepriceComments
  },
]
