import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const PleloaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`

export const Preloader = () => (
  <PleloaderWrapper>
    <CircularProgress size={60} />
  </PleloaderWrapper>
)
