import { Box, Divider, Typography } from '@mui/material'
import { history } from 'app/providers/with-router'
import { pimApi } from 'shared/api'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { downloadFile } from 'shared/lib/utils'

const DEFAULT_FILE_NAME =  'template.xlsx'

export const GoodsBulkCreation = () => {
  const { mutate: downloadMutation, isLoading: isDownloading } = pimApi.goods.useDownloadTemplateFileMutation()
  const { mutate: uploadMutation, isLoading: isUploading } = pimApi.goods.useUploadGoodsFile()

  const download = () => {
    downloadMutation(undefined,  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          response.headers['content-disposition']?.replace('inline; filename=', '')
          || DEFAULT_FILE_NAME

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
      },
      {
        onSuccess: (data: pimApi.products.ImportMetadata) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data.messages}
                fileUrl={data.fileUrl}
                batchId={data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('goods'))
  }

  return (
    <Box>
      <Typography fontSize="24px" fontWeight={400} lineHeight="133%">
        Массовое создание товаров
      </Typography>
      <Typography fontSize="16px" fontWeight={400} color="#757575">Товары</Typography>
      <Box display="flex" justifyContent="end" mt={3} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <Divider />
      <BulkFileActionsFooter
        download={download}
        upload={upload}
        isUploading={isUploading}
        isDownloading={isDownloading}
        onGoBack={handleGoBack}
      />
    </Box>
  )
}
