import { ReactElement } from 'react'
import RestoreIcon from '@mui/icons-material/Restore'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { StatusChips } from '../ui/Header/StatusChips'

interface NumberDeviations {
  singular: string
  plural: string
  chip: ReactElement
}

export const StatusVariations: Record<number, NumberDeviations> = {
  0: {
    singular: 'Новое',
    plural: 'Новые',
    chip: <StatusChips textColor="#3F51B5" iconColor="#3F51B5" icon={<RestoreIcon/>} backgroundColor="#F2F3FA" title="Новое"/>
  },
  1: {
    singular: 'На сборке',
    plural: 'На сборке',
    chip: <StatusChips textColor="#3B873E" iconColor="#4CAF50" icon={<RefreshIcon/>} backgroundColor="#EDF7ED" title="На сборке"/>
  },
  2: {
    singular: 'Завершено',
    plural: 'Завершенные',
    chip: <StatusChips textColor="#3B873E" iconColor="#4CAF50" icon={<CheckCircleIcon/>} backgroundColor="#EDF7ED" title="Завершено"/>
  },
}