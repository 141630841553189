import { SentryInit } from '../SentryInit/SentryInit'
import { VersionControl } from '../../ui/components/VersionControl/VersionControll'
import { Notifications } from '../../ui/components/Notifications'
import { StreamEvents } from '../../ui/components/StreamEvents/StreamEvents'


export const AsyncServices = () => (
  <>
    <VersionControl/>
    <Notifications/>
    <StreamEvents/>
    <SentryInit/>
  </>
)