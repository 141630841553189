import { AxiosResponse } from 'axios'
import { QueryFunctionContext, useQuery } from 'react-query'

import { api } from '../../base'

export const getCompanyLogoQueryKey = (companyId: UniqueId) =>
  ['get', `customer/companies/${companyId}/logo`, companyId] as const

type GetCompanyLogoQueryKey = ReturnType<typeof getCompanyLogoQueryKey>

export async function getCompanyLogo(companyId: UniqueId): Promise<AxiosResponse<Blob>> {
  return api.get(
    `/customer/companies/${companyId}/logo`,
    {
      responseType: 'blob'
    }
  )
}

const getCompanyLogoFn = (context: QueryFunctionContext<GetCompanyLogoQueryKey>) => {
  const [, ,userId] = context.queryKey
  return getCompanyLogo(userId)
}

export const useCompanyLogoQuery = (companyId: UniqueId) =>
  useQuery(
    getCompanyLogoQueryKey(companyId),
    getCompanyLogoFn
  )

