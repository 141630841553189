import styled from 'styled-components'

export const EditableInput = styled.div<any>`
  input {
    border: none;
    background: transparent;
    min-width: 8px;
    width: ${(props) => (props.width ? props.width : '20px')};
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: ${(props) => props.textAlign || 'start'};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
`
