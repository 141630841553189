import List from '@mui/material/List'
import { Box, Typography } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import React, { useMemo } from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { customerGen } from 'shared/lib/generated'

import {
  DeleteIcon,
  PrivilegeName,
  ReportName,
  StyledFileItem,
  StyledFolderItem,
} from './styled'

import { EditReportsAccessModal } from '../modal/EditReportsAccessModal'
import { expandPrivileges } from '../../Dicts/Privileges/lib/expandPrivileges'

export const ReportsTable = ({ groupData, refetch }) => (
  <List
    sx={{ paddingTop: '0', paddingBottom: '0', paddingLeft: '16px' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <Listing refetch={refetch} groupData={groupData} step={0} />
  </List>
)

const Listing = ({ groupData, step, refetch }) => (
  <div>
    {groupData &&
      groupData?.map((el) => (
        <Folder refetch={refetch} group={el} step={step} />
      ))}
  </div>
)

const File = (props) => {
  const { file, step, refetch } = props

  const { data: privilegesData } = customerGen.administration.GetPrivileges.useGetPrivileges(({ query: { customer: false } }))
  
  const expandedPrivileges = useMemo( () => {
    if (privilegesData) {
      return expandPrivileges(privilegesData)
    }
    return null
  }, [privilegesData])

  const { mutate: editReportsAccess } =
    customerGen.administration.EditReport.useEditReport()

  const handleEditAccess = (selectedValue) => {
    editReportsAccess(
      {
        reportId: file.id,
        data: {
          id: file.id,
          privilegeId: selectedValue,
        },
      },
      {
        onSuccess: () => {
          snackActions.info('Изменение сохранено')
          refetch()
        },
      }
    )
  }

  let privilegeName: string = ''
  // eslint-disable-next-line array-callback-return
  expandedPrivileges?.map((el) => {
    if (el.privilegeId === file.privilegeId) {
      privilegeName = el.name
    }
  })

  return (
    <StyledFileItem step={step} key={file?.id}>
      <ReportName step={step}>{file?.name}</ReportName>
      <PrivilegeName title={privilegeName}>{privilegeName}</PrivilegeName>
      <DeleteIcon
        style={{ marginLeft: 'auto', marginRight: '0' }}
        onClick={() => {
          dialogModel.openDialog({
            component: ({ close, accept }) => (
              <EditReportsAccessModal
                close={close}
                file={file}
                privileges={privilegesData}
                accept={accept}
              />
            ),
            onAccept: (selectedValue) => {
              handleEditAccess(selectedValue)
            },
          })
        }}
      />
    </StyledFileItem>
  )
}

const Folder = (props) => {
  const { group, step, refetch } = props

  if (group !== undefined) {
    if (group.groups || group.reports) {
      if (group.groups && group.reports) {
        return (
          <List component="div" sx={{ pt: 0, pb: 0 }}>
            <StyledFolderItem step={step}>
              <ExpandMore />
              <Typography>{group.name}</Typography>
            </StyledFolderItem>
            <Box>
              <Listing
                refetch={refetch}
                groupData={group.groups}
                step={(step || 0) + 1}
              />
              <Listing
                refetch={refetch}
                groupData={group.reports}
                step={(step || 0) + 1}
              />
            </Box>
          </List>
        )
      }
      return (
        <List component="div" sx={{ pt: 0, pb: 0 }}>
          <StyledFolderItem step={step}>
            <ExpandMore />
            <Typography>{group.name}</Typography>
          </StyledFolderItem>
          <Box>
            <Listing
              refetch={refetch}
              groupData={group.groups || group.reports}
              step={(step || 0) + 1}
            />
          </Box>
        </List>
      )
    }
    return <File refetch={refetch} file={group} step={step} />
  }
  return <div />
}
