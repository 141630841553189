import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const validationSchema = yup.object().shape({

  orderNumber: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  comments: yup
    .string()
    .nullable()
    .max(100, 'Не более 100 символов')
    .transform(yupTransformEmptyStringInNull),
    
})
