import { getFileSizeParams } from '../getFileSizeParams'

export const getFileThumbnailApiPath = (
  goodsId: UniqueId,
  fileId: UniqueId,
  size?: { maxWidth?: number, maxHeight?: number }
) => {
  const fileThumbnailPath = `/wms/goods/${goodsId}/image/${fileId}`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}
