import styled from 'styled-components'
import { TabsNav } from 'pages/goods/components/common'
import { FormControlLabel } from '@mui/material'

export const ProductCardWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.26);
  margin-bottom: 20px;
  margin-top: 36px;
`
export const Wrapper = styled.div`
  
`
export const ProductTabsNav = styled(TabsNav)`
  margin-top: -18px;
  border-bottom: none;
  padding-left: 23px;
  padding-right: 23px;
  .MuiTab-root{
    border: 1px solid rgba(0, 0, 0, 0.26);
    background: #FAFAFA;
    border-left: none;
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 0;
    min-width: 0;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
  }
  .MuiTab-root.Mui-selected{
    background: #F4F4F4;
  }
  .MuiTab-textColorInherit{
    opacity: 1;
    color: #FAFAFA;
  }
  .MuiTab-root:first-child{
    border-color: rgba(0, 0, 0, 0.26);
    border-left: 1px solid rgba(0, 0, 0, 0.26);
    border-bottom-left-radius: 4px;
  }
  .MuiTab-root:last-child{
    border-color: rgba(0, 0, 0, 0.26);
    border-bottom-right-radius: 4px;
  }


  .Mui-selected{
    color: #000;
  }
`
export const FormControlLabelStyled = styled(FormControlLabel)`
  vertical-align: top;
  align-items: flex-start;

  span{
    font-size: 14px;  
  }
`

export const SideBlock = styled.div`
  .MuiTypography-root {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 24px;
  }
  margin-top: 20px;
  width: 266px;
  min-width: 266px;
  padding-right: 12px;
`

export const BarcodeBlock = styled.div`
  display: flex;
  align-items: flex-end;
`

export const VarBlock = styled.div`
  margin-bottom: 16px;
  display: flex;
  margin-top: 0;
`