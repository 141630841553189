import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { dialogModel } from 'shared/ui/components/dialog'
import { saveDataToExcel } from 'shared/lib/exports'
import { snackActions } from 'shared/lib/react/snackbar'
import {  pimApi } from 'shared/api'

import { BrandsDictTable } from './ui/BrandsDictTable'
import { BrandModal } from './ui/modals/BrandModal'
import { ExcelTemplate } from './lib/excelTemplate'
import { BrandsPlug } from './ui/BrandsPlug'
import { StyledLoader } from './ui/styled'

export const BrandsDict = () => {

  const { data: brandsList, isLoading, isFetching, refetch } = pimApi.dictionaries.useBrandListQuery()
  const { mutate: deleteBrand } =
    pimApi.brands.useDeleteBrandMutation()

  const { mutate: editBrand } =
    pimApi.brands.useEditBrandMutation()

  const { mutate: createBrand } =
    pimApi.brands.useCreateNewBrand()

  const handleCellChange = (data) => {
    if (data && brandsList) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <BrandModal
            close={close}
            accept={accept}
            changedCell={data}
          />
        ),
        onAccept: (newBrandData) => {
          editBrand({
            brandId: newBrandData.id,
            brandData: newBrandData
          }, {
            onSuccess: () => {
              refetch().then()
              snackActions.info('Бренд изменён!')
            },
          }
          )
        },
      })
    }}

  const handleAddBrand = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <BrandModal
          close={close}
          accept={accept}
        />
      ),
      onAccept: (createdBrand: pimApi.brands.NewBrand) => {
        createBrand({
          name: createdBrand.name,
          code: createdBrand.code,
          comments: createdBrand.comments
        }, {
          onSuccess: () => {
            refetch().then()
            snackActions.info('Бренд изменён!')
          },
        }
        )
      },
    })
  }

  const handleRowDelete = (id) => {
    if (id && brandsList) {
      const deletedBrand = brandsList.filter(brand => brand.id === id)[0]
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удалить бренд?"
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
            variant="text"
          />
        ),
        onAccept: () => {
          deleteBrand({
            brandId: id,
            value: {
              id: deletedBrand.id,
              versionNo: deletedBrand.versionNo,
              comments: ''
            }
          }, {
            onSuccess: () => {
              refetch().then()
              snackActions.info('Бренд удалён!')
            },
          }
          )
        },
      })
    }}

  const dataForExcelExport = useMemo(() =>
    brandsList?.map((excelDataRow) => {
      const formatDateTime = (jsonDate) =>
        format(new Date(jsonDate), 'dd.MM.yyyy HH:mm')
      return [
        formatDateTime(excelDataRow.changed),
        excelDataRow.name,
        excelDataRow.code,
      ]
    }), [brandsList])

  const exportToExcelPossible = () => !dataForExcelExport?.length
  const isExportToExcel = exportToExcelPossible()


  const exportToExcelClick = async () => {
    if (dataForExcelExport !== undefined) {
      await saveDataToExcel(dataForExcelExport, ExcelTemplate)
    }
  }

  return (
    <Box px={3} pb={3}>
      {/* <Typography */}
      {/*  component="h2" */}
      {/*  variant="h4" */}
      {/*  fontSize="1.25rem" */}
      {/* > */}
      {/*  Бренды */}
      {/* </Typography> */}
      {/* <Typography */}
      {/*  fontSize="0.75rem" */}
      {/*  color="rgba(0, 0, 0, 0.54)" */}
      {/* > */}
      {/*  Создание и привязка брендов к поставщикам */}
      {/* </Typography> */}
      { (isFetching || isLoading) && <StyledLoader size={60} />}
      { brandsList &&
        <>
          { brandsList?.length
            ? <BrandsDictTable
              brandsList={brandsList}
              exportToExcelClick={exportToExcelClick}
              handleCellChange={handleCellChange}
              isLoading={isLoading}
              isFetching={isFetching}
              isExportToExcel={isExportToExcel}
              handleAddBrand={handleAddBrand}
              handleRowDelete={handleRowDelete}
            />
            : <BrandsPlug handleAddBrand={handleAddBrand}/>
          }
        </>
      }
    </Box>
  )
}
