import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'
import { AxiosResponse } from 'axios'

type QueryKey = typeof QUERY_KEY
const QUERY_KEY = ['get', 'customer/marketplaces'] as const

export function getMarketplaces(): Promise<AxiosResponse<customerApi.MarketplaceShortInfo[]>> {
  return api.get<customerApi.MarketplaceShortInfo[]>('/customer/marketplaces')
}

function getMarketplacesFn() {
  return getMarketplaces().then((res) => res.data)
}

export const useGetMarketplacesQuery = () =>
  useQuery<
    customerApi.MarketplaceShortInfo[],
    errorType,
    customerApi.MarketplaceShortInfo[],
    QueryKey
  >(
    QUERY_KEY,
    getMarketplacesFn
  )
