import { RepriceCommentsCell } from 'shared/ui/components/Table/ui/TableCells'
import styled from 'styled-components'

const TextWrapper = styled.div`
  >div {
    padding: 0 !important;
    font-size: 14px;
    
    a {
      -webkit-line-clamp: 3;
    }
  }
`

export const CommentCell = ({ row }) => (
  <TextWrapper>
    <RepriceCommentsCell comments={row.comment}/>
  </TextWrapper>
)