import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

const innValueCheck = (inn) => {
  if (inn !==undefined) {
    return inn?.length === 10 || inn?.length === 12
  }
  return true
}

const kppValueCheck = (kpp) => {
  if ((kpp !== undefined) && (kpp !== null)) {
    return kpp?.length === 0 || kpp?.length === 9
  }
  return true
}

export const validationSchema = yup.object().shape({

  shortName: yup
    .string()
    .max(100)
    .required(' ')
    .transform(yupTransformEmptyStringInNull),

  inn: yup
    .string()
    .required(' ')
    .test('InnCheck', 'Длина ИНН должна быть 10 или 12 цифр', value => innValueCheck(value))
    .matches(/^[0-9]+$/, 'ИНН может содержать только цифры')
    .transform(yupTransformEmptyStringInNull),

  kpp: yup
    .string()
    .nullable()
    .test('KppCheck', 'Длина КПП должна быть 9 цифр', value => kppValueCheck(value))
    .matches(/^[0-9]+$/, { message:'КПП может содержать только цифры',excludeEmptyString:true } )
})
