import { IconButton } from '@mui/material'
import { history } from 'app/providers/with-router'
import { ArrowBack } from '@mui/icons-material'

import { Nav } from './styled'

export const ButtonArrowBack = () => (
  <Nav>
    <IconButton onClick={() => history.goBack()}>
      <ArrowBack/>
    </IconButton>
  </Nav>
)
