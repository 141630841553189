import React, { useMemo } from 'react'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { Table } from 'shared/ui/components/Table'
import { pagingPanelLocale } from 'features/wms/lib/localization'
import { wmsGen } from 'shared/lib/generated'
import { columnsConfig } from 'features/wms/lib/columnsConfig'
import { CircularProgressStyled as SharedCircularProgress } from 'shared/ui/components/CircularProgress/CircularProgress'

import { ISelectedState } from '../lib/types'

const MAX_PAGE_SIZE = 50
const getRowId = (row) => JSON.stringify(row)

export const WmsTable = ({ selectedRows, setSelectedRows }: ISelectedState) => {
  const { searchObj } = useQueryParams()

  const { areaId, supplierId, recipientId, ownerId, brandId, searchString } = searchObj

  const { data, isLoading, isFetching } =
    wmsGen.stocks.GetAreaStocksList.useGetAreaStocksList({
      query: { areaId, supplierId, recipientId, ownerId, searchString, brandId }
    })
  const { calculatedRowsPerPage } = useRowsPerPage(undefined, 120)
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage)
  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      columnsSettingsId: 'wms',
      page: 0,
    }),
    [data]
  )

  const rowSelection = {
    onRowSelect: (row) => setSelectedRows(prev => {
      if (prev.includes(JSON.stringify(row))) {
        return prev.filter(el => el !== JSON.stringify(row))
      }
      return prev.concat([JSON.stringify(row)])
    }),
    selectedCondition: (row) => selectedRows.includes(JSON.stringify(row))
  }

  return (
    <>
      { isLoading || isFetching && <SharedCircularProgress size={60}/> }
      { data ?
        <Table
          tableList={data || []}
          totalCount={data.length}
          getRowId={getRowId}
          paginationLocale={pagingPanelLocale}
          tableParams={memoTableParams}
          calculatedRowsPerPage={calculatedRowsPerPage}
          columnsConfig={columnsConfig}
          rowSelection={rowSelection}
          searching="internal"
          pagination="internal"
          sorting="internal"
          filtering="internal"
        />
        :
        <div style={{ height: '350px' }}/>
      }
    </>
  )
}