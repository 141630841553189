import React from 'react'
import { Button } from '@mui/material'
import { dialogModel } from 'shared/ui/components/dialog'
import AddIcon from '@mui/icons-material/Add'
import { pimApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import { pimGen } from 'shared/lib/generated'
import { genReactQueryCacheAdd } from 'shared/lib/genReactQuery'

import { AttributesValuesModal } from '../../modal/AttributesValuesModal'

interface WidgetHeaderProps {
  dictionaryValues: pimApi.dictionaries.Value[]
  dictionaryId?: number
}
export const WidgetHeader = ({
  dictionaryValues,
  dictionaryId,
}: WidgetHeaderProps) => {
  const queryClient = useQueryClient()

  const { mutate: addValue } = pimApi.dictionaries.useAddValues()
  const handleClick = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <AttributesValuesModal
          accept={accept}
          close={close}
          attributesValues={dictionaryValues}
        />
      ),
      onAccept: (data) => {
        addValue(
          { dictionaryId: dictionaryId!, name: data.name },
          {
            onSuccess: genReactQueryCacheAdd({
              queryClient,
              queryCacheKey:
                pimGen.dictionary.GetAllDictionaryValues.getQueryKey({
                  dictionaryId: dictionaryId!,
                }),
              onSuccess: () => {
                snackActions.info('Значение добавлено')
              },
            }),
          }
        )
      },
    })
  }
  return (
    <div className="widget-header">
      <span>Значения</span>
      <Button
        startIcon={<AddIcon />}
        variant="text"
        disabled={!dictionaryId}
        onClick={handleClick}
      >
        Добавить значение
      </Button>
    </div>
  )
}
