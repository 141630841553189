import { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash/debounce'
import { Box, Grid, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { DateFormats } from 'shared/config/dateFormats'
import { ProductHeader } from 'shared/ui/product/ProductHeader'
import { Chart, DatePicker, Preloader, Slider } from 'shared/ui/components'
import { getCalendarReadableDateTime, toMaxDateTime, toMinDateTime } from 'shared/lib/utils'
import { saveDataToExcel } from 'shared/lib/exports'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { PriceHistoryTable } from './ui'
import { PriceHistoryRecord, ExcelTemplate } from './model'


const currentDate = new Date()
const DEFAULT_MIN_DATE = new Date(currentDate).setMonth(
  toMinDateTime(currentDate).getMonth() - 1
)
const CHART_MARGIN = { top: 5, right: 25 }
const COLORS = ['#979797', '#f980ab']

function getReadableDateTime(date: Date | number | string) {
  return new Date(
    new Date(date).getTime() - currentDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 16)
}

export const PriceHistory = ({
  data = [],
  fromDate,
  toDate = Number(currentDate),
  title,
  isLoading,
  startPrice,
  onTimeIntervalChange,
}: {
  data: PriceHistoryRecord[]
  fromDate: number
  toDate?: number
  title?: string
  isLoading?: boolean
  startPrice?: number
  onTimeIntervalChange: (timeInterval: Array<number>) => void
}) => {
  const [sliderTimeInterval, setSliderTimeInterval] = useState<Array<number>>([
    DEFAULT_MIN_DATE,
    Number(toMaxDateTime(new Date())),
  ])

  useEffect(() => {
    if (fromDate && toDate) setSliderTimeInterval([fromDate, toDate])
  }, [fromDate, toDate])

  const defaultCalendarMinDate = getCalendarReadableDateTime(fromDate)
  const defaultCalendarMaxDate = getCalendarReadableDateTime(toDate)

  const datePoints = useMemo(() => {
    let curDate = fromDate
    const points = [fromDate]
    while (curDate < toDate) {
      curDate = new Date(curDate).setDate(new Date(curDate).getDate() + 1)
      points.push(curDate)
    }
    if (points[points.length - 1] > toDate) {
      // eslint-disable-next-line prefer-destructuring
      points[points.length - 1] = toDate
    }
    return points
  }, [fromDate, toDate])

  const sliderOptions = useMemo<Array<any>>(
    () =>
      datePoints.map((datePoint) => {
        let label = ''
        if (datePoint === fromDate || datePoint === toDate) {
          label = format(new Date(datePoint), DateFormats.dayMonthYear)
        }
        if (new Date(datePoint).toDateString() === new Date().toDateString()) {
          label = 'Сегодня'
        }
        return {
          value: datePoint,
          label,
        }
      }),
    [fromDate, toDate]
  )

  const preparedChartData = useMemo(() => {
    const res = data.map(({ changeDate, price }) => ({
      date: Number(
        new Date(
          new Date(changeDate).getTime() -
            currentDate.getTimezoneOffset() * 60000
        )
      ),
      price
    }))

    if(isEmpty(res) && startPrice) {
      res.push({
        date: sliderTimeInterval[0],
        price: startPrice,
      })
    }

    return res
  }, [data])


  const preparedPriceHistory = useMemo<Array<any>>(() => {
    const res = Array<any>()
    let price = data[0]?.price
    let futurePrice
    const updatePrice = (newPrice) => {
      price = newPrice
    }
    const getPrice = () => price

    const updateFuturePrice = (newPrice) => {
      futurePrice = newPrice
    }
    const getFuturePrice = () => futurePrice

    for (let i = 0; i < datePoints.length; i += 1) {
      const curPoint = preparedChartData.filter(
        ({ date }) =>
          new Date(date).toDateString() ===
          new Date(datePoints[i]).toDateString()
      )

      curPoint.forEach((point) => {
        const isFutureDate = new Date(point?.date || datePoints[i]) > new Date()

        res.push({
          date: point?.date || datePoints[i],
          ...(isFutureDate ? {} : { price: point?.price || getPrice() }),
          ...(isFutureDate
            ? { futurePrice: point?.price || getFuturePrice() }
            : {}),
        })

        if (point?.price) {
          updatePrice(point?.price)
        }

        if (isFutureDate) {
          if (getFuturePrice() === undefined) {
            const prev = res[res.length - 2] || res[res.length - 1] || {}
            res.push(
              {
                date: Number(new Date()),
                price: prev?.price || prev?.futurePrice,
              },
              {
                date: Number(new Date()) + 1,
                futurePrice: prev?.price || prev?.futurePrice,
              }
            )
          }
          updateFuturePrice(point?.price)
        }
      })
    }
    return orderBy(res, 'date')
  }, [data])

  const slicedDataByTime = useMemo<Array<any>>(() => {
    const res = preparedPriceHistory.filter(
      ({ date }) =>
        date >= sliderTimeInterval[0] && date <= sliderTimeInterval[1]
    )
    const maxPoint = res[res.length - 1]
    const minPoint = res[0]
    const prevPoints = orderBy(
      preparedPriceHistory.filter(({ date }) => date < sliderTimeInterval[0]),
      'date',
      'desc'
    )
    const prevPrice = prevPoints?.[0]?.price || startPrice || minPoint?.price
    const prevFuturePrice =
      prevPoints?.[0]?.futurePrice || minPoint?.futurePrice

    res.push(
      {
        date: sliderTimeInterval[1],
        ...(sliderTimeInterval[1] < Number(new Date()) && maxPoint?.price
          ? { price: maxPoint?.price }
          : {}),
        ...(sliderTimeInterval[1] > Number(new Date())
          ? { futurePrice: maxPoint?.futurePrice || maxPoint?.price }
          : {}),
      },
      {
        date: sliderTimeInterval[0],
        ...(!(startPrice === 0) && prevPrice ? { price: prevPrice } : {}),
        ...(prevFuturePrice ? { futurePrice: prevFuturePrice } : {}),
      }
    )
    return orderBy(res, 'date')
  }, [...sliderTimeInterval, data])

  const handleSliderTimeIntervalChange = (
    newSliderTimeInterval: Array<number>
  ) => {
    setSliderTimeInterval([
      Number(toMinDateTime(newSliderTimeInterval[0])),
      Number(toMaxDateTime(newSliderTimeInterval[1])),
    ])
  }

  const debouncedChange = debounce(handleSliderTimeIntervalChange, 200)

  const handleFromDateChange = (date) => {
    if (getReadableDateTime(date) !== defaultCalendarMinDate)
      onTimeIntervalChange([Number(toMinDateTime(date)), toDate])
  }

  const handleToDateChange = (date) => {
    if (getReadableDateTime(date) !== defaultCalendarMaxDate) {
      onTimeIntervalChange([fromDate, Number(toMaxDateTime(date))])
    }
  }

  const dataForExcelExport = useMemo(() => {
    const exceldata = data.filter(({ changeDate }) => {
      const date = Number(
        new Date(
          new Date(changeDate).getTime() -
            currentDate.getTimezoneOffset() * 60000
        )
      )
      return date >= sliderTimeInterval[0] && date <= sliderTimeInterval[1]
    })

    return exceldata.map((excelDataRow) => {
      const formatDateTime = (jsonDate) =>
        format(new Date(jsonDate), 'dd.MM.yyyy HH:mm')

      return [
        formatDateTime(excelDataRow.changeDate),
        excelDataRow.price,
        excelDataRow.discount,
        excelDataRow.priceBeforeDiscount,
        excelDataRow.pricechangereason,
        excelDataRow.comments,
        excelDataRow.changer,
      ]
    })
  }, [data, ...sliderTimeInterval])

  const exportToExcelPossible = () => !dataForExcelExport.length

  const exportToExcelClick = async () => {
    await saveDataToExcel(dataForExcelExport, ExcelTemplate)
  }

  return (
    <>
      <ProductHeader goods={null} title={title} />
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Typography fontSize={20} fontWeight={500} pt={2}>
            История изменения цены
          </Typography>
        </Grid>
        <Grid item={true} xs={2}>
          <DatePicker
            name="fromDate"
            label="Начало периода"
            views={['day']}
            showClear={false}
            defaultValue={defaultCalendarMinDate}
            onChange={handleFromDateChange}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <DatePicker
            name="toDate"
            label="Конец периода"
            views={['day']}
            showClear={false}
            defaultValue={defaultCalendarMaxDate}
            value={getReadableDateTime(toDate)}
            onChange={handleToDateChange}
          />
        </Grid>
        <Grid item={true} xs={8}>
          <Box ml={2} display="flex" justifyContent="end">
            <LoadingButton
              variant="contained"
              color="primary"
              loadingPosition="start"
              startIcon={<ExcelIcon />}
              disabled={exportToExcelPossible()}
              onClick={exportToExcelClick}
            >
              ЭКСПОРТ
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <Box pt={2} mt={3} pl="40px" pr="36px">
        <Slider 
          options={sliderOptions}
          onChange={debouncedChange}
          startColor={COLORS[0]}
          endColor={COLORS[1]}
        />
      </Box>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Box mt={2} pr={2} width={1099} height={361}>
            <Chart
              data={slicedDataByTime}
              min={sliderTimeInterval[0]}
              max={sliderTimeInterval[1]}
              xAxisDataKey="date"
              chartDataKey={['price', 'futurePrice']}
              showTooltip={true}
              margin={CHART_MARGIN}
              colors={COLORS}
              lineType="stepAfter"
            />
          </Box>
          <Box mt={2}>
            <PriceHistoryTable
              timeInterval={sliderTimeInterval}
              tableData={data}
            />
          </Box>
        </>
      )}
    </>
  )
}
