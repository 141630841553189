import React, { useMemo } from 'react'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Autocomplete, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { pimGen } from 'shared/lib/generated'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'


interface ITableFilter {
  expandTree: boolean
  setExpandTree: React.Dispatch<React.SetStateAction<boolean>>
}
export const TableFilter = ({ expandTree, setExpandTree }: ITableFilter) => {
  const { data: contragentsData } = pimGen.administration.GetContragents.useGetContragents()
  const { data: brandData } = pimGen.brand.GetBrands.useGetBrands()

  const contragentsOptions = useMemo(
    () => contragentsData?.map(getOptions) || [],
    [contragentsData]
  )

  const brandOptions = useMemo(
    () => brandData?.map(getOptions) || [],
    [brandData])

  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })
  return (
    <div className="filter-container">
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={ searchObj.searchString }
        onKeyPress={ (e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        } }
        onBlur={ (e) => {
          handleChangeParams({
            params: {
              searchString: e.target.value,
              limit: null,
              page: 0
            },
            options: {
              skipEmptyString: true,
              skipNull: true
            }
          })
        } }
        InputProps={ {
          endAdornment: <SearchIcon sx={ { color: 'rgba(0, 0, 0, 0.26)' } }/>
        } }/>

      <StyledFilterContainer className="filters-wrap-container">
        <SelectWrapper width={145}>
          <Autocomplete
            options={contragentsOptions}
            noOptionsText="Нет фильтров"
            size="small"
            value={contragentsOptions.find(el => searchObj.supplierId === el.value) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(_, value) => {
              handleChangeParams({
                params: {
                  supplierId: value?.value,
                },
                options: {
                  skipNull: true,
                  skipEmptyString: true } })
            }}
            disableClearable={!searchObj.supplierId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Поставщик"/>
            )}
          />
        </SelectWrapper>

        <SelectWrapper width={150}>
          <Autocomplete
            options={contragentsOptions}
            noOptionsText="Нет фильтров"
            size="small"
            value={contragentsOptions.find(el => searchObj.recipientId === el.value) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(_, value) => {
              handleChangeParams({
                params: {
                  recipientId: value?.value,
                },
                options: {
                  skipNull: true,
                  skipEmptyString: true } })
            }}
            disableClearable={!searchObj.recipientId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Получатель"/>
            )}
          />
        </SelectWrapper>

        <SelectWrapper width={150}>
          <Autocomplete
            options={contragentsOptions}
            noOptionsText="Нет фильтров"
            size="small"
            value={contragentsOptions.find(el => searchObj.ownerId === el.value) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(_, value) => {
              handleChangeParams({
                params: {
                  ownerId: value?.value,
                },
                options: {
                  skipNull: true,
                  skipEmptyString: true } })
            }}
            disableClearable={!searchObj.ownerId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Владелец"/>
            )}
          />
        </SelectWrapper>

        <SelectWrapper width={155}>
          <Autocomplete
            options={brandOptions}
            noOptionsText="Нет фильтров"
            size="small"
            value={brandOptions.find(el => searchObj.brandId === el.value) || null}
            isOptionEqualToValue={isOptionEqual}
            onChange={(_, value) => {
              handleChangeParams({
                params: {
                  brandId: value?.value,
                },
                options: {
                  skipNull: true,
                  skipEmptyString: true } })
            }}
            disableClearable={!searchObj.brandId}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField {...params} label="Бренд"/>
            )}
          />
        </SelectWrapper>

      </StyledFilterContainer>
      <FormControlLabel
        value="Области склада"
        control={ <Switch
          size="small"
          color="primary"
          checked={ expandTree }
          onChange={ (_, checked) => setExpandTree(checked) }
        /> }
        label="Области склада"
        labelPlacement="end"
      />
    </div>
  )
}