import React from 'react'
import { Field, Form } from 'react-final-form'
import { Box, Button, DialogActions, DialogTitle, } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { InputField, InputLabel } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import isEmpty from 'lodash/isEmpty'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'

import { HelperTextSupply, ModalContainer } from './styled'

import { validationSchema } from '../../lib/validationSchema'


interface AddCompanyModalProps {
    close?: () => void;
    accept: (receiveData: any) => void
}


export const AddCompanyModal = ({
  close,
  accept,
}: AddCompanyModalProps) => {

  const onSubmit = (companyData) => {
    const receiveData = { companyData, close }
    accept(receiveData)
  }

  const validate = useValidationSchema(validationSchema)

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20} whiteSpace="pre-line">
          Новая компания
        </DialogTitle>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ 
              handleSubmit,
              errors,
              visited
            }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                <Box mt={1}>
                  <InputLabel
                    label="Наименование"
                    required={true}
                  />
                  <StyledField>
                    <HelperTextSupply>
                      <Field
                        name="name"
                        placeholder="Наименование"
                        helperText={visited?.name ? errors?.name : ''}
                        component={InputField}
                        fullWidth={true}
                      />
                    </HelperTextSupply>
                  </StyledField>
                </Box>
                <Box mt={4}>
                  <InputLabel
                    label="ИНН"
                    required={true}
                  />
                  <StyledField>
                    <HelperTextSupply>
                      <Field
                        name="inn"
                        placeholder="ИНН"
                        component={InputField}
                        helperText={visited?.inn ? errors?.inn : ''}
                        errorAfterTouch={true}
                        fullWidth={true}
                      />
                    </HelperTextSupply>
                  </StyledField>
                </Box>

                <Box mt={4}>
                  <InputLabel
                    label="URL сайта компании"
                    required={false}
                  />
                  <StyledField>
                    <HelperTextSupply>
                      <Field
                        name="siteUrl"
                        placeholder="URL"
                        component={InputField}
                        errorAfterTouch={true}
                        fullWidth={true}
                      />
                    </HelperTextSupply>
                  </StyledField>
                </Box>

                <DialogActions sx={{
                  marginTop: '32px',
                  padding: 0,
                  marginBottom: '24px'
                }}>

                  <Box>
                    <Button
                      onClick={close}
                      color="primary"
                    >
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={false}
                      disabled={!isEmpty(errors)}
                      sx={{ marginLeft: 2 }}
                    >
                      ДОБАВИТЬ
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )
            }
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



