import { AuthorizationForm, SuAuthorizationForm } from 'features/auth/ui'
import { Route } from 'pages/interface'
import { LAYOUT_WIDTH, SULOGIN, USER_PASSWORD_CHANGE, USER_PASSWORD_RECOVERY } from 'shared/config'
import { LOGIN } from 'shared/config/routes'
import { AuthLayout } from 'shared/ui/layouts'

import { SetPasswordForm } from './setpassword/SetPasswordForm'
import { PasswordRecoveryForm } from './passRecovery/PasswordRecoveryForm'

export default () => [
  {
    exact: false,
    path: USER_PASSWORD_CHANGE,
    layout: AuthLayout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: SetPasswordForm,
  },
  {
    protectedStatus: 'unauthorized',
    exact: true,
    path: USER_PASSWORD_RECOVERY,
    layout: AuthLayout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: PasswordRecoveryForm,
  },
  {
    protectedStatus: 'unauthorized',
    exact: false,
    path: SULOGIN,
    layout: AuthLayout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: SuAuthorizationForm,
  },
  {
    protectedStatus: 'unauthorized',
    exact: true,
    path: LOGIN,
    layout: AuthLayout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: AuthorizationForm,
  },
] as Array<Route>
