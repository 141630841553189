import { FC, memo } from 'react'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { CheckboxField, Field, Form, InputField } from 'shared/ui/components/form'
import { InfoOutlined } from '@mui/icons-material'
import { Button, Grid, Tooltip } from '@mui/material'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { GridArrayWrapper } from 'shared/ui/styled/GridArrayWrapper'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { InputLabel } from 'shared/ui/components'

import { validationSchema } from './validationSchema'

import { BottomButtonWrapper, BottomSection, InputActions } from '../../../components/common'
import { FormValues, InitialFormValues, IVariation } from '../../interface'
import { VariantFields } from '../VariantFields'

interface VariantFormProps {
  initialValues: InitialFormValues;
  posibleVariations: Array<IVariation>
  onSubmit: (formValues: FormValues) => void;
}

const VariantForm: FC<VariantFormProps> = ({
  initialValues,
  posibleVariations,
  onSubmit,
}) => {
  const validate = useValidationSchema(validationSchema)
  return (
    <Form<InitialFormValues, InitialFormValues>
      // @ts-ignore
      onSubmit={onSubmit}
      // @ts-ignore
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      subscription={{
        values: true
      }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray<ArrayInfer<InitialFormValues['variationAttributes']>> name="variationAttributes">
            {({ fields }) => {
              const handleAdd = () => {
                fields.push({
                  variation: {}
                })
              }
    
              const uniqueVariations = posibleVariations.filter(
                variation => values.variationAttributes.every(
                  selectVariation => selectVariation.variation?.dictionaryId !== variation.dictionaryId
                )
              )
    
              const hasMoreVariations = posibleVariations.length > (fields.length ?? 0)
    
              return (
                <GridArrayWrapper container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <InputLabel label="Параметры" />
                  </Grid>
                  {fields.map((name, index) => {
                    const handleRemove = () => {
                      fields.remove(index)
                    }
    
                    // <SelectField />, который используется в <VariantFields /> при сравнении показывает значение
                    // Если проп variations будет пустым массивом, то и значение не будет отображаться
                    const uniqueVariationsWithCurrentOption = values.variationAttributes[index].variation !== undefined ?
                      uniqueVariations.concat([
                        values.variationAttributes[index].variation as IVariation
                      ]) :
                      uniqueVariations
  
                    const isVariantDeletable = (fields.length ?? 0) > 1
  
                    return (
                      <VariantFields
                        key={name}
                        name={name}
                        dictionaryId={values.variationAttributes[index].variation?.dictionaryId}
                        variations={uniqueVariationsWithCurrentOption}
                        deletable={isVariantDeletable}
                        onRemove={handleRemove}
                      />
                    )
                  })}
                  {
                    hasMoreVariations && (
                      <InputWrapper>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                        >
                          ДОБАВИТЬ
                        </Button>
                      </InputWrapper>
                    )
                  }
                </GridArrayWrapper>
              )
            }}
          </FieldArray>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <InputLabel single={true} label="Артикулы" />
            </Grid>
            <Grid item={true} xs={6}>
              <InputWrapper>
                <Field
                  name="code"
                  label="Артикул"
                  component={InputField}
                />
                <Tooltip
                  title="Тип товара и текст небольшой"
                  placement="right"
                >
                  <HelpWithMargin>
                    <InfoOutlined />
                  </HelpWithMargin>
                </Tooltip>
              </InputWrapper>
            </Grid>
            <Grid item={true} xs={6}>
              <InputWrapper>
                <Field
                  name="auxCode"
                  label="Дополнительный артикул"
                  component={InputField}
                />
                <Tooltip
                  title="Тип товара и текст небольшой"
                  placement="right"
                >
                  <HelpWithMargin>
                    <InfoOutlined />
                  </HelpWithMargin>
                </Tooltip>
              </InputWrapper>
            </Grid>
            <Grid item={true} xs={12}>
              <InputActions>
                <Field
                  name="copyMedia"
                  label="Копировать медиа в вариацию "
                  component={CheckboxField}
                  type="checkbox"
                />
              </InputActions>
            </Grid>
          </Grid>
          <BottomSection>
            <BottomButtonWrapper>
              <Button variant="contained" color="primary" type="submit">
                ДАЛЕЕ
              </Button>
            </BottomButtonWrapper>
          </BottomSection>
        </form>
      )}
    />
  )
}

export const VariantFormMemo = memo(VariantForm)