import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig, FilterType } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'

type ColumnName = keyof Omit<
  tradeApi.SupplyOrderWithoutPositions,
  | 'id'
  | 'versionNo'
  | 'changed'
  | 'changer'
  | 'days'
  | 'missing'
  | 'estimatedSupplyDate'
  | 'ordersStatFromDate'
  | 'ordersStatToDate'
  | 'ignoreOrdersStatFromDate'
  | 'ignoreOrdersStatToDate'
  | 'companyId'
  | 'contragentId'
  | 'marketplaceId'
  | 'brandId'
  | 'useBoxes'
  | 'companymarketplaceId'
  | 'companymarketplace'
>

type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  created: 'created',
  creator: 'creator',
  extra: 'extra',
  orderNumber: 'orderNumber',
  orderDate: 'orderDate',
  totalSumm: 'totalSumm',
  statusId: 'statusId',
  company: 'company',
  contragent: 'contragent',
  marketplace: 'marketplace',
  marketplacewarehouse: 'marketplacewarehouse',
  brand: 'brand',
  comments: 'comments',
  sumDelta: 'sumDelta'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfigWithStatus: ColumnsConfigProps = {
  config: [
    {
      name: columns.orderNumber,
      columnName: columns.orderNumber,
      title: 'Номер',
      width: 152,
      sortingEnabled: false,
      cellType: CellType.OrderLink,
    },
    {
      name: columns.contragent,
      columnName: columns.contragent,
      title: 'Поставщик',
      width: 274,
      sortingEnabled: true,
      filterType: FilterType.Contragent,
    },
    {
      name: columns.company,
      columnName: columns.company,
      title: 'Продавец',
      width: 156,
      sortingEnabled: true,
      filterType: FilterType.Company,
    },
    {
      name: columns.statusId,
      columnName: columns.statusId,
      title: 'Статус',
      width: 360,
      filterType: FilterType.OrderStatus,
      cellType: CellType.Status,
      sortingEnabled: false,
    },
    {
      name: columns.orderDate,
      columnName: columns.orderDate,
      title: 'Дата',
      width: 88,
      sortingEnabled: true,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthShortYear,
    },
    {
      name: columns.totalSumm,
      columnName: columns.totalSumm,
      title: 'Сумма',
      width: 176,
      cellType: CellType.Sum,
      sortingEnabled: true,
      align: 'right'
    },
    {
      name: columns.sumDelta,
      columnName: columns.sumDelta,
      title: 'Расхождение\nв рублях',
      width: 140,
      align: 'right',
      cellType: CellType.Sum,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.extra,
      columnName: columns.extra,
      title: 'Расхождение\nв штуках',
      width: 140,
      align: 'left',
      cellType: CellType.OrderDeltaItems,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.marketplace,
      columnName: columns.marketplace,
      title: 'Маркетплейс',
      width: 144,
      sortingEnabled: false,
    },
    {
      name: columns.marketplacewarehouse,
      columnName: columns.marketplacewarehouse,
      title: 'РЦ',
      width: 112,
      sortingEnabled: false,
    },
    {
      name: columns.brand,
      columnName: columns.brand,
      title: 'Бренд',
      width: 144,
      sortingEnabled: false,
    },
    {
      name: columns.comments,
      columnName: columns.comments,
      title: 'Комментарии',
      width: 350,
      sortingEnabled: true,
      wordWrapEnabled: true,
      cellType: CellType.WordWrapCell
    },
  ],
}

export const columnsConfigWithoutStatus: ColumnsConfigProps = {
  config: [
    {
      name: columns.orderNumber,
      columnName: columns.orderNumber,
      title: 'Номер',
      width: 152,
      sortingEnabled: false,
      cellType: CellType.OrderLink,
    },
    {
      name: columns.contragent,
      columnName: columns.contragent,
      title: 'Поставщик',
      width: 274,
      sortingEnabled: true,
      filterType: FilterType.Contragent,
    },
    {
      name: columns.company,
      columnName: columns.company,
      title: 'Продавец',
      width: 156,
      sortingEnabled: true,
      filterType: FilterType.Company,
    },
    {
      name: columns.orderDate,
      columnName: columns.orderDate,
      title: 'Дата',
      width: 88,
      sortingEnabled: true,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthShortYear,
    },
    {
      name: columns.totalSumm,
      columnName: columns.totalSumm,
      title: 'Сумма',
      width: 176,
      cellType: CellType.Sum,
      sortingEnabled: true,
      align: 'right'
    },
    {
      name: columns.sumDelta,
      columnName: columns.sumDelta,
      title: 'Расхождение\nв рублях',
      width: 140,
      align: 'right',
      cellType: CellType.Sum,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.extra,
      columnName: columns.extra,
      title: 'Расхождение\nв штуках',
      width: 140,
      align: 'left',
      cellType: CellType.OrderDeltaItems,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.marketplace,
      columnName: columns.marketplace,
      title: 'Маркетплейс',
      width: 144,
      sortingEnabled: false,
    },
    {
      name: columns.marketplacewarehouse,
      columnName: columns.marketplacewarehouse,
      title: 'РЦ',
      width: 112,
      sortingEnabled: false,
    },
    {
      name: columns.brand,
      columnName: columns.brand,
      title: 'Бренд',
      width: 144,
      sortingEnabled: false,
    },
    {
      name: columns.comments,
      columnName: columns.comments,
      title: 'Комментарии',
      width: 350,
      sortingEnabled: true,
      wordWrapEnabled: true,
      cellType: CellType.WordWrapCell
    },
  ],
}