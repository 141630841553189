import React from 'react'
import {
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Chart as DxChart,
  Label, Legend,
  Point,
  Series,
  Tick, Tooltip,
  ValueAxis, Font, Margin
} from 'devextreme-react/chart'
import { customDay, customDayOfWeek } from 'widgets/DashboardWidgets/LargeSizeWidget/ui/Chart'
import { Box, Typography } from '@mui/material'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import { round } from 'shared/lib/utils/round'
import { formatter } from 'shared/lib/utils/numberWithSpaces'

import { StyledTooltip } from './styled'

import { Skeleton } from '../../components/Skeleton/Skeleton'

interface WidgetData {
  name: string
  valueField: string
  data: number
  diff: number
  color: string
  areaColor?: {
    fillId: string
  }
}

interface Props {
  leftData: WidgetData
  rightData: WidgetData
  chartData: Array<any>
  dataParams: any
  isLoading: boolean
}

export const Chart = ({ leftData, rightData, chartData, dataParams, isLoading }: Props) => {
  if (isLoading) {
    return (
      <Skeleton style={{
        paddingLeft: '16px',
        paddingRight: '32px',
        margin: '0px',
        backgroundColor: 'rgb(245,245,245)',
        width: 'auto',
        marginTop: '16px'
      }}/>
    )
  }

  const renderTooltip = (props: any) => <CustomTooltip x={props} color={{ leftColor: leftData.color, rightColor: rightData.color }}/>
  
  return (
    <DxChart
      className="mediumChart"
      palette="Ocean"
      dataSource={chartData}
    >
      <Margin top={36} right={9} left={9} bottom={43}/>
      <CommonSeriesSettings
        argumentField="orderDate"
        hoverMode="none"
      />
      <Series
        type="spline"
        color={leftData.color}
        width={1.5}
        name={leftData.name}
        valueField={leftData.valueField}>
        <Point visible={false}/>
      </Series>
      {leftData.areaColor &&
        <Series
          type="splineArea"
          color={leftData.areaColor}
          width={1.5}
          name={leftData.name}
          valueField={leftData.valueField}>
          <Point visible={false}/>
        </Series>
      }
      <Series
        type="spline"
        color={rightData.color}
        width={1.5}
        name={rightData.name}
        axis={rightData.valueField}
        valueField={rightData.valueField}>
        <Point visible={false}/>
      </Series>
      {rightData.areaColor &&
        <Series
          type="splineArea"
          color={rightData.areaColor}
          name={rightData.name}
          width={1.5}
          axis={rightData.valueField}
          valueField={rightData.valueField}>
          <Point visible={false}/>
        </Series>
      }
      <ValueAxis visible={false} name={leftData.valueField}>
        <Tick visible={false}/>
        <Label customizeText={(x: { value: any }) => formatter(x.value)}>
          <Font color={leftData.color}/>
        </Label>
        <Grid visible={false} color="#E0E0E066"/>
      </ValueAxis>
      <ValueAxis showZero={true} visible={false} position="right" name={rightData.valueField}>
        <Tick visible={false}/>
        <Label customizeText={(x: { value: any }) => formatter(x.value)}>
          <Font color={rightData.color}/>
        </Label>
        <Grid visible={false} color="#E0E0E066"/>
      </ValueAxis>
      <ArgumentAxis
        valueMarginsEnabled={false}
        visible={true}
        argumentType="string"
        discreteAxisDivisionMode="crossLabels"
        color="#E0E0E0"
        endOnTick={true}
      >
        <Tick visible={false}/>
        <Label font={{ color: '#00000061' }} customizeText={dataParams.range === 0 ? customDayOfWeek : customDay}/>
        <Grid visible={false}/>
      </ArgumentAxis>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="bottom"
        visible={false}
      />
      <Tooltip
        enabled={true}
        shared={true}
        interactive={false}
        cornerRadius={4}
        border={{ opacity: 0 }}
        color="#FFFFFF00"
        paddingLeftRight={0}
        paddingTopBottom={0}
        shadow={{ blur: 0, offsetY: 0, offsetX: 0, opacity: 0 }}
        contentRender={renderTooltip}
      />
    </DxChart>
  )
}

const CustomTooltip = ({ x, color }: {x: any, color: { leftColor: string, rightColor: string }}) => {
  const { argumentText } = x
  const utc = new Date(argumentText)
  const stringData = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset()*60000)).toISOString().split('T')[0]

  return (
    <StyledTooltip>
      <Typography sx={{ fontSize: '12px', marginBottom: '12px' }}>{`
        ${stringData.split('-')[2]  }
        ${  DateAppearanceSettings[stringData.split('-')[1]].month},
        ${stringData.split('-')[0]}`}
      </Typography>
      {x.points[1] &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px', gap: '8px' }}>
        <Typography sx={{ fontSize: '12px' }}>{x.points[1].seriesName}</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            marginLeft: 'auto',
            color: color.leftColor
          }}>
          {round(x.points[1]?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
      {x.points[2] &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
        <Typography sx={{ fontSize: '12px', marginRight: '8px' }}>{x.points[2].seriesName}</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            marginLeft: 'auto',
            color: color.rightColor
          }}>
          {round(x.points[2]?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </Typography>
      </Box>
      }
    </StyledTooltip>
  )
}