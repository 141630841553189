import { isNil } from 'shared/lib/checkers/isNil'
import { SelectField, CheckboxField } from 'shared/ui/components/form'
import { InputField } from 'shared/ui/components/form/GoodsInputField/GoodsInputField'

import { AutocompleteDictinaryAttributeRemoteSearchFieldMemo } from './AutocompleteDictinaryAttributeRemoteSearchField'

const EMPTY_ARRAY: Array<any> = []
const EMPTY_OBJECT = {}
const MULTIPLE_PROPS = {
  allowNull: true,
  multiple: true,
  format: (value: null | any) => value === null ? EMPTY_ARRAY : value
}

// TODO: добавить поддержку fixedValues=false через <Autocomplete /> (combo box)
export function getGoodsAttributeComponent({
  catalog,
  dictionaryValues,
  multipleValues,
  attributeDataType,
  dictionaryId,
  changeValue,
  value,
}: {
  catalog?: string;
  dictionaryValues?: string[];
  fixedValues?: boolean;
  multipleValues?: boolean;
  attributeDataType?: string,
  dictionaryId?: UniqueId;
  changeValue?: (payload: { name: string, value: string | Array<string> }) => void;
  value?: any;
}): {
  Component: React.ComponentType<any>,
  props: Record<string, any>
} {
  const changeValueLocal = (event: React.FocusEvent<HTMLInputElement>) => {
    changeValue?.(event.target)
  }

  if(attributeDataType === 'boolean') {
    return {
      Component: CheckboxField,
      props: {
        checked: (value?.[0]?.value === 'true') && true
      }
    }
  }

  if (isNil(catalog)) {
    return {
      Component: InputField,
      props: {
        type: attributeDataType === 'integer' ? 'number' : undefined,
        ...(changeValue ? {
          onBlur: changeValueLocal
        } : {}),
      }
    }
  }

  if (isNil(dictionaryValues)) {
    return {
      Component: AutocompleteDictinaryAttributeRemoteSearchFieldMemo,
      props: {
        ...(multipleValues ? MULTIPLE_PROPS : EMPTY_OBJECT),
        options: value,
        withReset: true,
        dictionaryId,
        ...(changeValue ? {
          onChange: changeValueLocal
        } : {}),
      }
    }
  }

  return {
    Component: SelectField,
    props: {
      ...(multipleValues ? MULTIPLE_PROPS : EMPTY_OBJECT),
      multipleValues,
      options: dictionaryValues.map(selectFieldValue => ({ label: selectFieldValue, value: selectFieldValue })),
      withReset: true,
      ...(changeValue ? {
        onBlur: changeValueLocal
      } : {}),
    }
  }
}
