import { FC, memo, useCallback, useMemo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteValue } from '@mui/material/useAutocomplete'

export interface ColorProps extends FieldRenderProps<string> {
  companyId: number,
}

const getOptionFromCategory = ({ name }: { name: string }) => ({
  label: name,
  value: name
})

export const Color: FC<ColorProps> = ({ companymarketplaceId, maxCount, ...props }) => {
  const fetchCatalog = useCallback<(steach: string) => Promise<Array<Option<any>>>>(
    (search: string) => wbproxyApi.fetchColors(encodeURIComponent(search), companymarketplaceId)
      .then(
        data => data.data.map(getOptionFromCategory)
      ),
  [companymarketplaceId]
  )

  const handleChange = (values: AutocompleteValue<Option<any>, true, undefined, undefined>) => {
    props.input.onChange(values.map(({ value }) => value))
  }

  const values = typeof props.input.value === 'string' ? [] : props.input.value

  const input = useMemo(() => ({
    ...props.input,
    value: values.map((value) => ({
      label: value,
      value
    }))
  }), [props.input])

  const multipleMaxCountDisabled: boolean =
    typeof maxCount === 'number' &&
    props.input.multiple! &&
    (values as Array<Option>).length >= maxCount

  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      // @ts-ignore
      input={input}
      error={props.error.toString()}
      // @ts-ignore
      onChange={handleChange}
      multiple={true}
      getOptionDisabled={(optionItem: Option) =>
        multipleMaxCountDisabled &&
        !props.input.value.includes(optionItem.value)
      }
      allowEmptyString={true}
      disableClearable={false}
      fetchCatalog={fetchCatalog}
    />
  )
}

export const ColorMemo = memo(Color)