// @ts-nocheck
import { Table } from '@devexpress/dx-react-grid-material-ui'
import styled from 'styled-components'

export const StyledTableCell = styled(Table.Cell)`
  height: 52px;
  padding: 0px 8px 0px 8px;
  word-wrap: no-wrap;
`

export const StyledLink = styled.a`
  width: 100%;
  text-decoration: none;
  white-space: pre-wrap !important;
  color: #007DFF;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

interface Props {
  directionUp: boolean;
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'} !important;

  svg {
    font-size: 19px;
    margin-right: 8px;
  }
`

export const StyledIcon = styled.div<Props>`
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'}
`
