import React, { FC } from 'react'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'

import { NormalizedAttributesRight as NormalizedAttributesRightType, ProductAttributeValue } from '../../lib/getNormalizedAttributes'
import { RightComponent } from '../RightComponent'

interface NormalizedAttributesRightProps {
  normalizedAttributesRights: Array<NormalizedAttributesRightType>
  showGoodsInfo: boolean
  onAttributeValueChange: (
    id: number
  ) => (productAttributeValues: Array<ProductAttributeValue>) => void
  disabled?: boolean
}

export const NormalizedAttributesRight: FC<NormalizedAttributesRightProps> = ({
  normalizedAttributesRights,
  onAttributeValueChange,
  showGoodsInfo,
  disabled
}) => (
  <>

    {normalizedAttributesRights.map(({ right }) => (
      <InputsWrapper hideGoodsInfo={!showGoodsInfo} key={right.attributeName} arrow={null} description={right.description}>
        {null}
        <RightComponent right={right} onChange={onAttributeValueChange(right.id)} disabled={disabled} />
      </InputsWrapper>
    )
    )}
  </>
)