import { memo } from 'react'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Help } from 'shared/ui/styled/Help'
import { InfoOutlined } from '@mui/icons-material'

import { DividerStyled, GridTitleStyled } from './styled'

interface TitleDividedProps {
  title: string | React.ReactNode;
  description?: string;
}

export function TitleDivided({ title, description }: TitleDividedProps) {
  return (
    <Grid container={true} wrap="nowrap" alignItems="center">
      <GridTitleStyled>
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </GridTitleStyled>
      {description && (
        <Box mr={1}>
          <Tooltip
            title={description}
            placement="right"
          >
            <Help>
              <InfoOutlined />
            </Help>
          </Tooltip>
        </Box>
      )}
      <Box width="100%">
        <DividerStyled />
      </Box>
    </Grid>
  )
}

export const MemoTitleDivided = memo(TitleDivided)