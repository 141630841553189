import React from 'react'
import { PriceHistoryType } from 'features/priceHistory/Table/model/types'

import { PriceContainer } from '../../../../../PriceContainer'


export const NewPriceCell = ({ row }: { row: PriceHistoryType }) => {
  const { newCurrentPrice, newPrevPrice, newDiscount }: any = row
  
  return (
    <div style={{ height: '62px', display: 'flex', alignItems: 'end' }}>
      <PriceContainer
        currentPrice={newCurrentPrice}
        prevPrice={newPrevPrice}
        discount={newDiscount}/>
    </div>
  )
}