import { memo } from 'react'
import {
  ColumnChooser,
  TableColumnVisibility as DXTableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { Box, Divider, FormControlLabel, Switch, Tooltip } from '@mui/material'
import { Settings } from '@mui/icons-material'
import { Plugin } from '@devexpress/dx-react-core'

import { StyledColumnChooserOverlay, StyledIconButton } from './styled'

import { TableParams } from '../../model/types'
import { columnChooserLocale } from '../../lib'

export const Overlay = memo(
  ({
    children,
    ...restProps
  }: ColumnChooser.OverlayProps) => (
    <StyledColumnChooserOverlay {...restProps}>
      {children}
    </StyledColumnChooserOverlay>
  )
)

export const ItemComponent = ({ item, ...rest }: ColumnChooser.ItemProps) => {
  if (item.column.name === 'actionColumn') return null
  if (item.column.title === ' ') return null
  return <ColumnChooser.Item {...rest} item={item} />
}

interface TableColumnVisibilityProps {
  handleTableParams?: (params: Partial<TableParams>) => void
  handleTableSettingsChange?: (any) => void
  inArchive?: string
  tableHiddenColumns?: Array<string>
  showInArchiveControl?: boolean
}

interface CutomToggleButtonProps
  extends Omit<ColumnChooser.ToggleButtonProps, 'buttonRef'> {
  buttonRef?:
    | ((instance: HTMLButtonElement) => void)
    | React.RefObject<HTMLButtonElement>
}

const toggleButtonComponent = memo(
  ({
    getMessage,
    onToggle,
    buttonRef,
    active,
    ...restProps
  }: CutomToggleButtonProps) => (
    <Tooltip
      title={getMessage('showColumnChooser')}
      placement="bottom"
      enterDelay={300} className="column-settings"
    >
      <StyledIconButton
        aria-controls={active ? 'long-menu' : undefined}
        aria-expanded={active ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="more"
        id="long-button"
        ref={buttonRef}
        onClick={onToggle}
        $open={active}
        {...restProps}
      >
        <Settings />
      </StyledIconButton>
    </Tooltip>
  )
)

const overlayComponent = memo((props: ColumnChooser.OverlayProps) => (
  <Overlay {...props} />
))

export const TableColumnVisibility = ({
  handleTableParams,
  handleTableSettingsChange,
  inArchive,
  tableHiddenColumns = [],
  showInArchiveControl,
}: TableColumnVisibilityProps) => {
  const handleInArchiveChange = () => {
    handleTableParams?.({ inArchive: inArchive === 'true' ? '' : 'true' })
  }



  const Container = memo(({ children, ...props }) => (
    <ColumnChooser.Container {...props}>
      {children}
      {showInArchiveControl && (
        <>
          <Divider />
          <Box p={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={inArchive === 'true'}
                  onChange={handleInArchiveChange}
                />
              }
              label="Показывать архивные"
            />
          </Box>
        </>
      )}
    </ColumnChooser.Container>
  ))

  return (
    <Plugin>
      <DXTableColumnVisibility
        columnTogglingEnabled={true}
        defaultHiddenColumnNames={tableHiddenColumns}
        onHiddenColumnNamesChange={handleTableSettingsChange}
      />

      <ColumnChooser
        toggleButtonComponent={toggleButtonComponent}
        containerComponent={Container}
        overlayComponent={overlayComponent}
        itemComponent={ItemComponent}
        messages={columnChooserLocale}
      />
    </Plugin>
  )
}