import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

const downloadCopackingForm = async (copackingorderId?: UniqueId) =>
  api.get<File>(
    `/trade/orders/copackingorders/${copackingorderId}/excel`,
    {
      responseType: 'blob',
    }
  )

export const useDownloadCopackingForm = () =>
  useMutation(downloadCopackingForm)