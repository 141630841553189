import React, { memo, useCallback, useRef } from 'react'
import { CircularProgress } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import imagePlaceholder from 'assets/images/photo-size-select-actual.svg'
import { CompanyImg, EditPhoto } from 'features/admin/Company/EditCompany/ui/styled'


interface ICompanyLogo {
  companyLogo: any
  inArchive: boolean
  handleUploadLogo: (event: React.ChangeEvent<HTMLInputElement>) => void
}


export const CompanyLogo = memo(({ companyLogo, handleUploadLogo, inArchive }: ICompanyLogo) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleAvatarPlaceHolderClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [inputRef])

  const urlLogo = companyLogo?.data?.data

  if (inArchive) {
    return (
      <>
        {companyLogo.isSuccess ?
          <CompanyImg  src={URL.createObjectURL(urlLogo)} $inArchive={inArchive}>
            <img src={imagePlaceholder} alt="default icon"/>
          </CompanyImg>
          :
          <>
            <img src={imagePlaceholder} alt="default icon"/>
          </>
        }
      </>
    )
  }
  return (
    <CompanyImg>
      {!companyLogo.isSuccess ?
        <CircularProgress/> :
        <>
          <CompanyImg src={URL.createObjectURL(urlLogo)}  alt="company icon">
            <img src={imagePlaceholder} alt="default icon"/>
          </CompanyImg>
          <EditPhoto onClick={handleAvatarPlaceHolderClick}>
            <CameraAltIcon sx={{ margin: '4px 5px 5px 3px', color: 'rgba(0, 0, 0, 0.38)' }}/>
            <input
              ref={inputRef}
              accept="image/jpg, image/jpeg, image/png, image/webp"
              type="file"
              multiple={true}
              onChange={handleUploadLogo}
              style={{ display: 'none' }}
            />
          </EditPhoto>
        </>
      }
    </CompanyImg>
  )
}
)