import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledTabPanel = styled.div`
  margin: 8px 24px;
  box-shadow: 0 -1px 0 0 #E0E0E0 inset;

  .MuiTab-root {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    padding: 11px 16px 11px 16px;
    text-transform: none;
    min-width: min-content;
  }
`

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;

  .MuiTableCell-root {
    height: 52px;
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }

  [isrowexpanded] {
    background-color: inherit;
    .MuiTableCell-root {
      border: 0;
    }
    &:hover {
      background-color: inherit !important;
    }
  }

  .MuiTableRow-root:not(.TableRow-with-detailBtn) .TableDetailCell-active {
    background-color: rgba(255, 255, 255, 0) !important;
    padding: 0;
  }

  .MuiTableBody-root .MuiTableRow-root:not(.MuiTableRow-head):not(.TableRow-expanded):hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }

`