/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const PhotoRequirements = () => {
  const Text = 'Формат — JPEG, JPG, PNG. Размер — не больше 10 Мбайт'
  const Element = (<StyledTextContainer>
    <ul>
      <li>формат — JPEG, JPG, PNG;</li>
      <li>разрешение — от 200px до 7680px по большей стороне;</li>
      <li>размер — до 10 Мбайт;</li>
      <li>фон белый или светлый, для белых или прозрачных товаров — чёрный, для категории Одежда, обувь и аксессуары —
        серый #f2f3f5;
      </li>
      <li>соотношение сторон 3:4 для категорий Одежда, обувь и аксессуары, 1:1 — для остальных.</li>
    </ul>
    <span>Не используйте:</span>
    <ul>
      <li>изображения алкоголя;</li>
      <li>чёрно-белые фото;</li>
      <li>некачественные снимки: размытые, снятые в «домашних условиях» на фоне бытовых предметов;</li>
      <li>надписи c указанием цен, скидок, любых контактнов и ссылок, с любыми призывами к действию — например,
        позвонить, сделать покупку и т.д.
      </li>
    </ul>
    <span>На фото 18+ интимные части тела должны быть размыты или скрыты другим образом</span>
  </StyledTextContainer>
  )
  
  return { Element, Text }
}