import { pimApi } from 'shared/api'
import { VariationAndMarketPlace } from 'widgets/VariationAndMarketplace'
import { PaperTitle } from 'pages/goods/components/common'


interface ProductEditHeadingProps {
  goodsData?: pimApi.goods.Goods
  initialMarketplaceValues: any
  onChangeVariation: any
}

export const ProductEditHeading = ({
  goodsData,
  initialMarketplaceValues,
  onChangeVariation,
}: ProductEditHeadingProps) => (
  <>
    {goodsData?.name ? (
      <VariationAndMarketPlace
        variations={goodsData?.variations}
        goodsId={goodsData.id}
        initialMarketplaceValues={initialMarketplaceValues}
        onChange={onChangeVariation}
        withMarketplaces={true}
      />
    ) : (
      <PaperTitle>Информация о продукте</PaperTitle>
    )}
  </>
)
