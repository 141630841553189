import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Box } from '@mui/material'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'

import { ArchiveModalFormRender } from './ArchiveModalFormRender'

import { validationSchema } from '../lib/validationSchema'
import { DialogTitleWithBtn } from '../../ModalComponents'

const MODAL_STYLES = { width: '423px', maxWidth: '600px', overflowX: 'hidden' }
const SX_TITLE = { fontSize: '20px', minWidth: '480px', padding: '0', marginBottom: '24px' }

const focusOnError = createDecorator()
const decorators = [focusOnError]

interface ArchiveModalFormProps {
  onSubmit: (any) => void
  close: () => void
  isLoading?: boolean
}

export const ArchiveModalForm = ({ onSubmit, close, isLoading } : ArchiveModalFormProps) => {

  const validate = useValidationSchema(validationSchema)
  
  return (
    <Box p={3} pt={3} sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
        Перенос в архив
      </DialogTitleWithBtn>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        decorators={decorators}
        render={({ handleSubmit, valid }) => (
          <ArchiveModalFormRender valid={valid} handleSubmit={handleSubmit} close={close} isLoading={isLoading} />
        )}
      />
    </Box>
  )
}