// @ts-nocheck
import React, { useCallback } from 'react'
import { Field, InputField } from 'shared/ui/components/form'

import InfoItem from './InfoItem'
import * as UI from './styled'

interface InfoProps {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isTransportInfoDisabled: boolean
  isNotEditable: boolean
}

const Info = ({
  handleBlur,
  handleChange,
  isTransportInfoDisabled,
  isNotEditable,
}: InfoProps) => {
  const Input = useCallback(({ ...props }) => (
    <UI.EditableStyledInput width={props.width} disabled={props.disabled}>
      <InputField {...props} />
    </UI.EditableStyledInput>
  ), [])

  return (
    <UI.InfoList>
      <UI.InfoBlock>
        <InfoItem title="Отправитель">
          <Field
            name="supplier"
            width="100%"
            component={Input}
            onBlur={handleBlur}
            disabled={true}
          />
        </InfoItem>
        <InfoItem title="Получатель">
          <Field
            name="recipient"
            width="100%"
            component={Input}
            onBlur={handleBlur}
            disabled={true}
          />
        </InfoItem>
        <InfoItem title="Транспорт">
          <Field
            name="transportInfo"
            width="100%"
            placeholder={isTransportInfoDisabled ? 'Нет данных' : 'Добавить'}
            onBlur={handleBlur}
            onChange={handleChange}
            component={Input}
            disabled={isTransportInfoDisabled}
          />
        </InfoItem>
      </UI.InfoBlock>

      <UI.InfoBlock>
        <InfoItem title="Паллеты">
          <Field
            name="palletQuantity"
            width="100%"
            type="number"
            placeholder="0"
            component={Input}
            disabled={isNotEditable}
            onBlur={handleBlur}
            onChange={handleChange}
            max={100}
            min={0}
          />
        </InfoItem>
        <InfoItem title="Коробки">
          <Field
            name="boxQuantity"
            width="100%"
            type="number"
            placeholder="0"
            component={Input}
            disabled={isNotEditable}
            onBlur={handleBlur}
            onChange={handleChange}
            max={100}
            min={0}
          />
        </InfoItem>
        <InfoItem title="Комментарии">
          <Field
            name="comments"
            width="100%"
            placeholder={isNotEditable ? 'Нет данных' : 'Добавить'}
            component={Input}
            disabled={isNotEditable}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InfoItem>
      </UI.InfoBlock>
    </UI.InfoList>
  )
}

export default Info
