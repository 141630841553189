import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useStore } from 'effector-react'
import { Box, Button, DialogActions, Divider, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import UploadIcon from '@mui/icons-material/Upload'
import {
  DialogTitleWithBtn,
  Field,
  Form,
  InputField,
} from 'shared/ui/components'
import { tradeApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'
import { Accordion } from 'shared/ui/components/Accordion'

import { UploadWrapper } from './styled'


interface ModalContentProps {
  accept: (params: any) => void
  close: () => void
  method?: tradeApi.Transition
}

const SX_TITLE = { fontSize: '20px', paddingTop: '24px', paddingBottom: '24px' }

const FILE_EXTENSION =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const PatchSupplyOrderDialogContent = ({
  accept,
  close,
  method,
}: ModalContentProps) => {
  const { isLoading } = useStore(dialogModel.$dialogState)
  const [file, setFile] = useState<File>()
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0])
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }

  const handleFileClear = () => {
    setFile(undefined)
  }


  const onSubmit = (values) => {
    dialogModel.setDialogIsLoading({ isLoading: true })
    accept({ file, comments: values?.comments })
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box minWidth="680px">
            <DialogTitleWithBtn onClose={close} sx={SX_TITLE} disabled={isLoading}>
              Подтверждение операции:
              <Typography mt={2} fontSize={14}>{`"${method?.name}"`}</Typography>
            </DialogTitleWithBtn>
            {method?.commentsPossible && (
              <Box px={3} pb={3}>
                <Grid item={true} xs={12}>
                  <Accordion
                    title="Добавить комментарий"
                  >
                    <Field
                      name="comments"
                      label="Добавить комментарий"
                      component={InputField}
                      multiline={true}
                      fullWidth={true}
                      errorAfterTouch={false}
                      minRows={1}
                      maxRows={10}
                      max={255}
                    />
                  </Accordion>
                </Grid>
              </Box>
            )}
            {
              // method?.fileRequired 
              // true
              //   && (
              //     <Box px={3} pb={3}>
              //
              //     </Box>
              //   )
            }
            <Divider sx={{ margin: '0 24px' }} />
            <DialogActions sx={{ padding: '0' }} >

              {method?.fileRequired && (
                <UploadWrapper sx={{ marginRight: 'auto', marginLeft: '24px' }}>
                  <Box display="inline-flex">
                    <label htmlFor="excel-file">
                      <input
                        accept={FILE_EXTENSION}
                        id="excel-file"
                        type="file"
                        onChange={uploadFile}
                      />
                      {file ? (
                        <Box display="flex" alignItems="center">
                          <span>Добавлен файл: {file.name}</span>
                          <IconButton
                            aria-label="close"
                            onClick={handleFileClear}
                            size="small"
                            disabled={isLoading}
                          >
                            <CloseIcon/>
                          </IconButton>
                        </Box>
                      ) : (
                        <LoadingButton
                          color="primary"
                          variant="text"
                          component="span"
                          disabled={Boolean(file)}
                          startIcon={<UploadIcon/>}
                        >
                          ПРИКРЕПИТЬ ФАЙЛ
                        </LoadingButton>
                      )}
                    </label>
                  </Box>
                </UploadWrapper>
              )}
              
              <Box p={3}>
                <Button
                  onClick={close}
                  color="primary"
                  disabled={isLoading}
                >
                  ОТМЕНА
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  sx={{
                    marginLeft: 2,
                  }}
                >
                  ПОДТВЕРДИТЬ
                </LoadingButton>
              </Box>
            </DialogActions>
          </Box>
        </form>
      )}
    />
  )
}
