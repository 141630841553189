import { ApolloClient, createHttpLink, from, fromPromise, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { getToken } from '../api/base'
import { refreshToken } from '../api/auth'
import { getFingerprint } from '../lib/browser/getFingerprint'


const links = {
  products: '/api/products/graphql',
  goods: '/api/goods/graphql'
}
const httpLink = createHttpLink({
  uri: ({ getContext }) => {
    const { apiName } = getContext()

    return links[apiName]
  }
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})

const getNewToken = async () => {
  const fingerprint = await getFingerprint()

  return refreshToken({ fingerprint }).then(res => res.data.accessToken)
}

const errorLink = onError(
  // eslint-disable-next-line consistent-return
  ({ networkError, forward, operation }) => {
    if (networkError?.message === 'Response not successful: Received status code 401') {
      return fromPromise(
        getNewToken()
          .catch(() => {}))
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            },
          })
          return forward(operation)
        })
    }
  }
)
export const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: false,
})