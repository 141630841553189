import { Plugin } from '@devexpress/dx-react-core'
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid'
import {
  Table,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui'

import { CellInput } from '../TableCells'

const CustomCell = ({ children, ...props }: Table.DataCellProps) => (
  <Table.Cell {...props} sx={{ p: 0 }}>{children}</Table.Cell>
)

const EditingColumnsProvider = (props: { for: string[] }) => (
  <DataTypeProvider editorComponent={CellInput} {...props}/>
)

export const TableEditing = ({ 
  columnsConfig, 
  onCommitChanges, 
  selectTextOnEditStart = false
}) => {
  const editingColumns = columnsConfig
    .filter((column) => column.editingEnabled)
    .map((column) => column.columnName)

  return (
    <>
      <Plugin>
        <EditingColumnsProvider for={editingColumns} />
        <EditingState
          onCommitChanges={onCommitChanges}
          columnExtensions={columnsConfig.config}
        />
        <TableInlineCellEditing
          startEditAction="click"
          cellComponent={CustomCell}
          selectTextOnEditStart={selectTextOnEditStart}
        />
      </Plugin>
    </>
  )
}
