import React from 'react'
import { ArrowBack } from '@mui/icons-material'
import { history } from 'app/providers/with-router'
import { HOME } from 'shared/config/routes'
import get from 'lodash.get'

export const BackButton = ({ backUrl }: { backUrl?: string }) => {
  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl)
    } else if (get(history, 'state.key') || get(history, 'location.key')) {
      history.goBack()
    } else {
      history.push(HOME)
    }
  }

  return (
    <button type="button" onClick={handleBack}>
      <ArrowBack />
    </button>
  )
}
