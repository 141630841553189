import { AxiosResponse } from 'axios'
import { CalculatedReceiptDocPosition, ReceiptDoc } from 'domains/doc'

export enum docStatus {
  new = 'new',
  started = 'started',
  paused = 'paused',
  finished = 'finished',
  finished_with_diff = 'finished_with_diff',
}

export enum docNextStatus {
  start = 'start',
  pause = 'pause',
  resume = 'resume',
  finish = 'finish',
}

export interface DocType extends ReceiptDoc{
  sumAccepted: number
  sumDefective: number
  sumFact: number
  sumPrice: number
  sumQuantity: number
  positions?: CalculatedReceiptDocPosition[]
}
export interface IDoc {
  calcAcceptanceInPositions(doc: ReceiptDoc): DocType;
  getDocDetail(docId: number): Promise<DocType>;
  loadDocList(warehouseId: number): Promise<DocType[]>;
  filterList(list: DocType[], query: any): DocType[]
  changeDocStatus(docId: number): Promise<AxiosResponse<[]>>;
  updateDoc(doc: DocType): Promise<AxiosResponse<DocType>>;
  changeDocAcceptance(params: any): Promise<AxiosResponse<{}>>;
  docAddGood(goodId: number): Promise<AxiosResponse<{}>>;
}
