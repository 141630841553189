import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;

  .MuiTableCell-root {
    height: 52px;
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }

  .Toolbar-toolbar {
    >.MuiIconButton-root {
      order: 3;
      :last-child {
        margin-bottom: auto;
        margin-top: 0;
        padding-top: 24px;
      }
    }
    .SearchPanelInput-root {
      min-width: 220px;
      margin-top: 0;
      padding-top: 16px;
      margin-bottom: auto;
      max-width: 220px;
      margin-left: 0;
      order: 1;
      display: none;
    }
    #table-filter {
      width: auto;
      order: 2;
    }
  }

  [isrowexpanded] {
    background-color: inherit;
    .MuiTableCell-root {
      border: 0;
    }
    &:hover {
      background-color: inherit !important;
    }
  }

  .MuiTableRow-root:not(.TableRow-with-detailBtn) .TableDetailCell-active {
    background-color: rgba(255, 255, 255, 0) !important;
    padding: 0;
  }

  .MuiTableBody-root .MuiTableRow-root:not(.MuiTableRow-head):not(.TableRow-expanded):hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }

`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`