import logo from 'assets/images/sidebarLogo.svg'
import { BRANDQUAD } from 'shared/config'
import { Link } from 'react-router-dom'
import { generatePath, useParams } from 'react-router'

import { Filters } from '../Filters'

export const Header = ({ goodsStack }) => {
  const { clientId } = useParams<{ clientId: string }>()
  return (
    <div className="header-container">
      <Link className="ecom-logo-img" to={ generatePath(BRANDQUAD, { clientId }) }>
        <img src={ logo } alt="" />
      </Link>

      <span className="title">Открытый каталог</span>

      <Filters goodsStack={goodsStack}/>
    </div>
  )
}
