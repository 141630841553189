import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosResponse } from 'axios'

import { api } from '../base'

const downloadProductAddTemplateFile = async (queryParams: { activityId: number }) => (
  api.get<File>(`/activity/activity/${queryParams.activityId}/products/file/template`, {
    responseType: 'blob',
    params: { format: 'excel' }
  })
)

export const useDownloadProductAddTemplateFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, { activityId: number }>
) => useMutation(
  downloadProductAddTemplateFile,
  options
)

const downloadActivityProductsFile = async (queryParams: { activityId: number }) => (
  api.get<File>(`/activity/activity/${queryParams.activityId}/products/file`, {
    responseType: 'blob',
    params: { format: 'excel' }
  })
)

export const useDownloadActivityProductsFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, { activityId: number }>
) => useMutation(
  downloadActivityProductsFile,
  options
)

const uploadActivityProductsFile = async (
  {
    file,
    activityId,
  }: {
    file: File | null
    activityId: UniqueId
  }) => {
  const body = new FormData()
  body.append('file', file || '')

  return api.post(
    `/activity/activity/${activityId}/products/file`,
    body, { params: { format: 'excel' } }
  )
}

export const useUploadActivityProductsFile = (
  options?: UseMutationOptions<AxiosResponse,
    errorType,
    {
      file: File
      activityId: UniqueId
    }>
) => useMutation(uploadActivityProductsFile, options)
