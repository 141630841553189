import { IntegratedSummary } from '@devexpress/dx-react-grid'
import { CalculatedReceiptDocPosition } from 'domains/doc'

import { columnNames, columnSummaryConfig } from '../ColumnsConfig'

export const getTotalSummaryValues = (rows: CalculatedReceiptDocPosition[]) => {
  let quantity = 0

  const summaryValues = columnSummaryConfig.map((summary) => {
    const { columnName, type } = summary
    switch (columnName) {
      case columnNames.quantity:
        return {
          value: IntegratedSummary.defaultCalculator(type, rows, row => {
            quantity += row[columnName]
            return row[columnName]
          }),
          columnName
        }

      case columnNames.price:
        return {
          value: IntegratedSummary.defaultCalculator(type, rows, row => (
            row[columnName] *  row.quantity
          )),
          columnName
        }

      case columnNames.difference:
        return {
          negative: IntegratedSummary.defaultCalculator(type, rows, row => (
            Math.min(0, row[columnName])
          )),
          positive: IntegratedSummary.defaultCalculator(type, rows, row => (
            Math.max(0, row[columnName])
          )),
          columnName
        }

      default:
        return {
          value: IntegratedSummary.defaultCalculator(type, rows, row => row[columnName]),
          columnName
        }
    }
  })

  const result = summaryValues.map(summaryValue => {
    if (summaryValue.columnName === columnNames.sumFact) {
      return { ...summaryValue, valid: quantity >= summaryValue.value }
    }
    return summaryValue
  })

  return result
}