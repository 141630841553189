import styled from 'styled-components'
import { Box } from '@mui/material'
import { TabPanel } from 'shared/ui/components'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
`

export const StyledTabPanel = styled(TabPanel)`
  padding: 0 24px;
  .MuiPaper-root {
    overflow: visible;
  }
`