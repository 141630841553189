import { Box } from '@mui/material'
import styled from 'styled-components'

export const ResultItem = styled(Box)< { $status?: boolean } >`
  border: 1px solid;
  border-color: ${({ $status }) => $status ? 'rgba(153, 163, 216, 0.50)' : 'rgba(0, 0, 0, 0.23)'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ $status }) => $status
    ? 'linear-gradient(0deg, #F3F5FB 0%, #F3F5FB 100%), #FFF;'
    : 'linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)), #FFFFFF;' }
`
export const ProductType = styled.div`
  position: absolute;
  top: 0;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #EBEAEA;
  border-bottom: 1px solid #EBEAEA;
  background-color: #fff;
  svg {
    width: 10px !important;
    height: 10px;
    color: #BDBDBD;
  }
`
export const ProductTitle = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;

`
export const ProductAuxCode = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
`
export const ProductImage = styled.div<{ $photoBase64?: boolean, $status?: boolean }>`
  position: relative;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEAEA;
  filter: ${({ $status }) => !$status && 'grayscale(1)'};
  opacity: ${({ $status }) => !$status && 0.8 };
  img {
    width: ${({ $photoBase64 }) => $photoBase64 ? '80px' : '60px'};
    height: ${({ $photoBase64 }) => $photoBase64 ? '80px' : '60px'};
    object-fit: cover;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  > div:first-child{
    height: 80px;
    width: 80px;
  }
`
export const ComponentInfo = styled.div`
  width: 100%;
  padding: 8px 16px;
`

export const ProductInfo = styled.span`
  font-size: 12px;
  color: grey;
`
export const BorderedInfo = styled.span<{$isDarkened?: boolean}>`
  display: inline-block;
  font-size: 12px;
  color: ${({ $isDarkened }) => $isDarkened ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)' };
  border-right: 1px solid rgba(0,0,0,0.12);
  padding-right: 5px;
  margin-right: 5px;
  > span{
    margin-right: 3px;
  }
  > a{
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
  &:last-child{
    border-right: none;
  }
`

export const Status = styled.div<{ $status?: boolean }>`
  font-size: 14px;
  height: 18px;
  width: max-content;
  color: ${({ $status }) => !$status ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)' };
`

export const MoreMenu = styled.div`
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.54);
`