
import { StatusName, StatusDescription } from './styled'


interface StatusProps {
  status: {
    name: string;
    description?: string;
    status: string;
  }
  isArchived?: boolean
}

export const StatusCell = ({ status, isArchived }: StatusProps) => (
  <div>
    <StatusName
      title={status.name}
      statustype={status.status}
      variant="body2"
      fontSize="14px"
      lineHeight="16px"
      $isArchived={isArchived}
    >
      {status.name}
    </StatusName>
    {status.description && (
      <StatusDescription
        title={status.description}
        variant="body2"
        fontSize="12px"
        lineHeight="16px"
        $isArchived={isArchived}
      >
        {status.description}
      </StatusDescription>
    )}
  </div>
)
 