import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

import { Goods } from './models'

const updateGoodsAssortment = (
  goodsId: UniqueId,
  assortmentId: UniqueId,
) =>
  api.post<Goods>(`/pim/goods/${goodsId}/assortment/${assortmentId}`)

export const useUpdateGoodsAssortmentMutation = () =>
  useMutation<
    AxiosResponse<Goods>,
    errorType,
    {
      goodsId: UniqueId,
      assortmentId: UniqueId,
    }
  >(({ goodsId, assortmentId }) => updateGoodsAssortment(goodsId, assortmentId))

const deleteGoodsAssortment = (
  goodsId: UniqueId,
  assortmentId: UniqueId,
) => 
  api.delete<Goods>(`/pim/goods/${goodsId}/assortment/${assortmentId}`)

export const useDeleteGoodsAssortmentMutation = () =>
  useMutation<
    AxiosResponse<Goods>,
    errorType,
    {
      goodsId: UniqueId,
      assortmentId: UniqueId,
    }
  >(({ goodsId, assortmentId }) => deleteGoodsAssortment(goodsId, assortmentId))