import React from 'react'
import { Grid, GridDirection } from '@mui/material'
import { CatalogSelectField, Field } from 'shared/ui/components/form'

import { StatusFieldWrapper } from './styled'

interface StatusChangeI {
  warehouseId: number | null,
  isDisabled: boolean,
  direction: Extract<GridDirection, 'row' | 'column'>
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const StatusChange = ({ warehouseId, isDisabled, direction, onChange }: StatusChangeI) => (
  <Grid container={true} direction={direction} justifyContent="space-between">
    {warehouseId && (
      <StatusFieldWrapper>
        <Field
          name="defaultStorageId"
          component={CatalogSelectField}
          catalog={`wms/storages?warehouse_id=${warehouseId}`}
          label="Место хранения"
          disabled={isDisabled}
          onChange={onChange}
        />
      </StatusFieldWrapper>
    )}
  </Grid>
)

export default StatusChange