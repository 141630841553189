import { HOME } from 'shared/config/routes'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { viewerModel } from 'store/user'
import { useHistory } from 'react-router'
import NotFoundImage from 'assets/images/notFoundImage/withBorderRaduis/notFound.svg'

import { useSidebarStore } from '../../../store/sidebar/useSidebarStore'

const BoxStyled = styled(Box)<{ sidebarOpen: boolean }>`
  padding-left: ${(props) => (props.sidebarOpen ? '250px' : '56px')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    margin-top: 24px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  
  .subTitle {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    text-align: center;
    color: ${({ theme }) => theme.palette.text.disabled};
    white-space: break-spaces;
  }
`

export const NotFound = () => {
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen)
  const status = useSelector(viewerModel.viewerStatusSelector)
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if ((status === 'authorized') && (pathname === '/login' || pathname === '/sulogin')) {
      history.push(HOME)
    }
  }, [pathname, status])

  return (
    <BoxStyled sidebarOpen={sidebarOpen}>
      <img style={{ width: '215px', height: '190px' }} src={NotFoundImage} alt=""/>
      <span className="title">Страница не найдена</span>
      <span className="subTitle">{'Эта ссылка удалена или указан\n неправильный URL'}</span>
    </BoxStyled>
  )
}
