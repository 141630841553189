import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PriceHistory } from 'features/product/priceHistory'
import { pimApi } from 'shared/api'
import {
  getServerReadableDateTime,
  toMaxDateTime,
  toMinDateTime,
} from 'shared/lib/utils'
import {
  PriceHistoryQueryProvider,
  PriceHistoryRecord,
} from 'features/product/priceHistory/model'
import { getDiscountPrice } from 'entities/pim/price/lib'

const currentDate = new Date()
const currentMinDate = toMinDateTime(currentDate)
const DEFAULT_MIN_DATE = Number(
  currentMinDate.setMonth(new Date().getMonth() - 1)
)

export const WBPriceHistory = () => {
  const [fromDate, setFromDate] = useState<number>(DEFAULT_MIN_DATE)
  const [toDate, setToDate] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const wBProductQuery = pimApi.products.wb.useFetchWBProductQuery(productId)

  const wBProductPriceHistoryQuery =
    pimApi.products.wb.useFetchPriceHistoryQuery({
      productId,
      ...(fromDate ? { fromDate: getServerReadableDateTime(fromDate) } : {}),
      ...(toDate ? { toDate: getServerReadableDateTime(toDate) } : {}),
    })
  const priceHistory = useMemo((): PriceHistoryRecord[] => {
    if (wBProductPriceHistoryQuery?.data === undefined) {
      return []
    }
    return wBProductPriceHistoryQuery?.data.history.map(
      ({ price, discount, ...rest }) => {
        const getWbPrice = () => {
          if (discount === undefined || discount === 0) {
            return price
          }
          if (price === undefined) {
            return undefined
          }
          return Math.floor(price - price * (discount / 100))
        }
        const getWbDiscount = () => {
          if (discount === undefined || discount === 0) {
            return undefined
          }
          return discount
        }
        const getWbPriceBeforeDiscount = () => {
          if (discount === undefined || discount === 0) {
            return undefined
          }
          return price
        }
        return {
          ...rest,
          price: getWbPrice(),
          discount: getWbDiscount(),
          priceBeforeDiscount: getWbPriceBeforeDiscount(),
        }
      }
    )
  }, [wBProductPriceHistoryQuery])

  const { mutate: deleteProductPrice } =
    pimApi.products.wb.useDeleteProductPriceMutation()
  
  const deleteFuturePriceQuery = (priceId, versionNo, options) =>
    deleteProductPrice(
      {
        productId,
        priceId,
        data: {
          id: priceId,
          versionNo,
        },
      },
      options
    )

  const startPrice = getDiscountPrice(
    wBProductPriceHistoryQuery?.data?.startPrice,
    wBProductPriceHistoryQuery?.data?.startDiscount
  )
  const title = wBProductQuery?.data?.name

  useEffect(() => {
    if (
      !toDate &&
      wBProductPriceHistoryQuery?.data &&
      priceHistory?.length
    ) {
      const maxItem = priceHistory[priceHistory.length - 1]

      if (maxItem?.changeDate) {
        setToDate(Number(toMaxDateTime(maxItem.changeDate)))
      } else {
        setToDate(Number(toMaxDateTime(currentDate)))
      }
    }
  }, [wBProductPriceHistoryQuery?.data])

  const handleTimeIntervalChange = useCallback((newTimeInterval) => {
    setFromDate(newTimeInterval[0])
    setToDate(newTimeInterval[1])
  }, [])

  return (
    <PriceHistoryQueryProvider
      priceHistoryQuery={wBProductPriceHistoryQuery}
      deleteFuturePriceQuery={deleteFuturePriceQuery}
    >
      <PriceHistory
        data={priceHistory}
        title={title}
        isLoading={wBProductPriceHistoryQuery.isLoading}
        startPrice={startPrice}
        fromDate={fromDate}
        toDate={toDate}
        onTimeIntervalChange={handleTimeIntervalChange}
      />
    </PriceHistoryQueryProvider>
  )
}
