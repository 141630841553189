import React from 'react'

import { StyledDot } from '../ProductAbcClassCell/styled'

export const AbcXyzClass = ({ row }) => {
  const classColors: Record<string, string> = {
    'AX': '#4CAF50',
    'AY': '#4CAF5080',
    'AZ': '#FFB547',
    'BX': '#4CAF5080',
    'BY': '#FFB547',
    'BZ': '#F4433680',
    'CX': '#FFB547',
    'CY': '#F4433680',
    'CZ': '#F44336'
  }

  const abcClassLetter = row.abcClass[0]
  const xyzClassLetter = row.xyzClass

  const abcXyz = abcClassLetter+xyzClassLetter

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <StyledDot color={classColors[abcXyz]}/>
      {abcXyz}
    </div>
  )
}