import styled from 'styled-components'
import { IconButton } from '@mui/material'

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    color: #000000DE;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
  .subTitle {
    color: #000000DE;
    font-size: 16px;
    white-space: break-spaces;
    font-weight: 400;
    line-height: 24px;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  padding: 0;
  right: 5px;
  top: 8px;
`