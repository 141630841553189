import { pimApi } from 'shared/api'
import { isNil } from 'shared/lib/checkers'
import isArray from 'lodash/isArray'

export const serializeWBCardAttributeToEditWBCardAttribute = ({
  id,
  versionNo,
  attributeId,
  values,
  attributeGlobal,
  valuesType,
  maxcount
}:
  pimApi.products.wb.WBNomenclatureAttribute): pimApi.products.wb.EditWBNomenclatureAttribute => {
  let p_values

  if (isNil(values)){
    p_values = values
  }else if(valuesType === 4 && (maxcount === 0 || maxcount === 1) ) {
    p_values = parseFloat(<string><unknown>values)
  }else if((valuesType === 1 || valuesType === 0) && (maxcount === 0 || maxcount === 1)) {
    p_values = isArray(values) ? values.join(', ') : String(values)
  } else p_values = (<string[]>values).map(String)

  return  {
    id,
    versionNo,
    attributeId: isNil(attributeId) ? undefined : attributeId,
    values: p_values,
    attributeGlobal
  }
}