import React, { useCallback, useMemo } from 'react'
import { pimApi } from 'shared/api'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Box, Paper } from '@mui/material'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { useQueryClient } from 'react-query'
import { getCategoryAttributesQueryKey } from 'shared/api/pim/categoryAttributes'
import { IMenuItem } from 'shared/ui/components'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { getAttributeQueryKey } from 'shared/api/pim/attributes'

import { Header } from './Header'

import { StyledLoader } from '../../../../User/EditUser/ui/styled'
import { AttributesTable } from '../../../Attributes'
import { columnsConfig } from '../../lib/columnsConfig'
import { AttributeModal } from '../../../Attributes/ui/modal/AttributeModal'
import { CategoryAttributeModal } from '../../../Attributes/ui/modal/CategoryAttributeModal'
import { reactQueryCacheUpdateByKey } from '../../../../../../shared/lib/reactQuery'


export const EditCategoryPanel = ({ categoryId }: { categoryId: number } ) => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)
  const queryClient = useQueryClient()

  const { limit, calculatedRowsPerPage } = useRowsPerPage(searchObj.limit, 220)
  
  const { data: categoryData } = pimApi.categories.useGetCurrentCategory(categoryId)
  const {
    data: categoryAttributesData,
    refetch: categoryAttributeRefetch
  } = pimApi.categoryAttributes.useGetCategoryAttributes({ categoryId })

  const { mutate: deleteCategoryAttribute } = pimApi.categoryAttributes.useDeleteAttributeMutation()
  const { mutate: deleteAttribute } = pimApi.attributes.useDeleteAttributeMutation()

  const { mutate: editCategoryAttribute } = pimApi.categoryAttributes.useEditCategoryAttributeMutation()
  const { mutate: editAttribute } = pimApi.attributes.useEditAttributeMutation()

  const { mutate: addCategoryAttribute } = pimApi.categoryAttributes.useAddCategoryAttribute()
  const { mutate: addAttribute } = pimApi.attributes.useAddAttribute()

  const settingsMenuOptions: (e: pimApi.categoryAttributes.CategoryAttribute) => Array<IMenuItem> = useCallback((row) => [
    {
      label: 'Изменить атрибут',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => {
            const { data: attributeData } = pimApi.attributes.useGetCurrentAttribute(row.attributeId!)
            if (attributeData) return (
              <AttributeModal close={close} accept={accept} initialValues={attributeData}/>
            )
            return (
              <div style={{ position: 'fixed' }}>
                <StyledLoader size={60}/>
              </div>
            )
          }, onAccept: (editedData: pimApi.attributes.CommonAttribute) => {
            editAttribute(editedData, {
              onSuccess: (response) => {
                categoryAttributeRefetch()
                queryClient.setQueryData<pimApi.attributes.CommonAttribute>(
                  getAttributeQueryKey(row.attributeId!), response.data
                )
                snackActions.info('Изменение сохранено')
              }
            })
          }
        })
      }
    },
    {
      label: 'Удалить из категории',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <ModalContent
              close={close}
              accept={accept}
              title="Удалить атрибут категории?"
              acceptBtnText="УДАЛИТЬ"
              acceptBtnColor="error"
              variant="text"
            />
          ),
          onAccept: () => {
            deleteCategoryAttribute(
              {
                categoryId,
                value: row
              }, {
                onSuccess: () => {
                  snackActions.info('Атрибут категории удален')
                  queryClient.setQueryData<pimApi.categoryAttributes.CategoryAttribute[]>(
                    getCategoryAttributesQueryKey({ categoryId }),
                    (updater) => updater?.filter(el => el.id !== row.id) as pimApi.categoryAttributes.CategoryAttribute[]
                  )
                }
              })
          },
        })
      },
      divider: true,
    },
    {
      label: 'Удалить атрибут',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <ModalContent
              close={close}
              accept={accept}
              description="Атрибут будет удален из всех категорий и восстановить атрибут будет нельзя. "
              title="Удалить атрибут?"
              acceptBtnText="УДАЛИТЬ"
              acceptBtnColor="error"
              variant="text"
            />
          ),
          onAccept: () => {
            deleteAttribute(
              { id: row.attributeId!, versionNo: row.versionNo, comments: ''  }, {
                onSuccess: () => {
                  snackActions.info('Атрибут удален')
                  queryClient.setQueryData<pimApi.categoryAttributes.CategoryAttribute[]>(
                    getCategoryAttributesQueryKey({ categoryId }),
                    (updater) => updater?.filter(el => el.id !== row.id) as pimApi.categoryAttributes.CategoryAttribute[]
                  )
                }
              })
          },
        })
      },
      type: 'error'
    },
  ],[])


  const handleCellChange = (changedCells: pimApi.categoryAttributes.CategoryAttribute) => {
    editCategoryAttribute({ categoryId, editedAttribute: changedCells }, {
      onSuccess: reactQueryCacheUpdateByKey({
        queryClient,
        queryCacheKey: getCategoryAttributesQueryKey({ categoryId }),
        onSuccess: () => {snackActions.info('Атрибут изменен')}
      })
    })
  }

  const addAttributeFunction = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <CategoryAttributeModal close={close} accept={accept} currentAttributes={categoryAttributesData}/>,
        onAccept: (formData) => {
          if (typeof formData.attribute.value === 'number') {
            addCategoryAttribute(
              {
                categoryId,
                newCategoryAttribute: {
                  attribute_id: formData.attribute.value
                }
              }, {
                onSuccess: ({ data: responseData }) => {
                  snackActions.info('Атрибут добавлен')
                  queryClient.setQueryData<pimApi.categoryAttributes.CategoryAttribute[]>(
                    getCategoryAttributesQueryKey({ categoryId }),
                    (updater) => [...updater!, responseData] as pimApi.categoryAttributes.CategoryAttribute[]
                  )
                }
              })
          } else {
            addAttribute({ ...formData, name: formData.attribute.value }, {
              onSuccess: ({ data: newAttribute }) => {
                addCategoryAttribute(
                  {
                    categoryId,
                    newCategoryAttribute: {
                      attribute_id: newAttribute.id
                    }
                  }, {
                    onSuccess: ({ data: responseData }) => {
                      snackActions.info('Атрибут добавлен')
                      queryClient.setQueryData<pimApi.categoryAttributes.CategoryAttribute[]>(
                        getCategoryAttributesQueryKey({ categoryId }),
                        (updater) => [...updater!, responseData] as pimApi.categoryAttributes.CategoryAttribute[]
                      )
                    }
                  })
              }
            })
          }
        },
      }
    )
  }


  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit,
      page: 0,
      calculatedRowsPerPage
    }),
    [categoryAttributesData]
  )
  
  if (categoryData && categoryAttributesData) {
    return (
      <Box component={Paper} mt="28px">
        <Header categoryData={categoryData}/>
        <AttributesTable
          addAttributeFunction={addAttributeFunction}
          columnsConfig={columnsConfig}
          internalPagination={true}
          handleCellChange={handleCellChange}
          settingsMenuOptions={settingsMenuOptions}
          attributesData={categoryAttributesData}
          totalCount={categoryAttributesData.length}
          memoTableParams={memoTableParams}
        />
      </Box>
    )
  }
  return (<StyledLoader size={60} />)
}