import React, { memo, useEffect, useState } from 'react'
import { tradeApi } from 'shared/api'
import { Paper } from '@mui/material'
import { GetGoodsSupplyingParams } from 'shared/api/trade/stocks'
import { useOpenedStreamsContext } from 'shared/ui/components/StreamEvents/context/OpenedStreamsContext'

import { Header, Table, StyledTableBox, StyledLoader, columnsConfig } from './ui'
import { pagingPanelLocale } from './lib/localization'


const ROW_HEIGHT = 49
const TABLE_HEIGHT = 690
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEDAULT_ROWS_COUNT = 5
export const GoodsSupplying = () => {
  const { value: context } = useOpenedStreamsContext()
  const bonusCols = Math.floor((window.innerHeight - TABLE_HEIGHT) / ROW_HEIGHT)
  const calculatedRowsPerPage = Math.min(MAX_PAGE_SIZE, bonusCols > MIN_ROWS_COUNT ? DEDAULT_ROWS_COUNT + bonusCols : DEDAULT_ROWS_COUNT)
  const [queryParams, setQueryParams] = useState<GetGoodsSupplyingParams>(
    {
      searchString: undefined,
      page: 0,
      limit: calculatedRowsPerPage,
      sort: undefined,
      order: undefined
    })
  const goodsSupplyingQuery = tradeApi.stocks.useGoodsSupplyingQuery(queryParams)
  const handleQueryParamsChange = (params: Partial<GetGoodsSupplyingParams>) => {
    setQueryParams({ ...queryParams, ...params })
  }

  useEffect(() => {
    if (goodsSupplyingQuery.status === 'success') {
      if (goodsSupplyingQuery?.data.data.inProgress === true) {
        context.changeStreamState({ goodsSupplyingStream: true })
      }
    }
  }, [goodsSupplyingQuery.status])

  return (
    <>
      <StyledTableBox component={Paper}>
        <Header goodsSupplyingQuery={goodsSupplyingQuery}/>
        {(goodsSupplyingQuery.isLoading || goodsSupplyingQuery.isFetching) && (<StyledLoader size={60} />)}
        {goodsSupplyingQuery?.data && (
          <Table
            tableData={goodsSupplyingQuery.data.data}
            tableParams={queryParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={columnsConfig}
            tableParamsChange={handleQueryParamsChange}
            paginationLocale={pagingPanelLocale}
          />
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoGoodsSupplying = memo(GoodsSupplying)
