import { ReceiptDocAcceptance } from 'domains/doc'
import styled from 'styled-components'
import { Delete } from '@mui/icons-material'

import { nestedTableColumnNames } from '../ColumnsConfig'

const ChangableValue = styled.span<{ isEmpty: boolean }>`
  color: ${({ isEmpty }) => isEmpty ? 'rgba(0, 125, 255, 0.5)' : '#007DFF'};
  padding: 2px 0;
  display: inline-block;
`

export const DeleteIcon = styled(Delete)`
  display: none;
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 16px;
  margin-top: 2px;
`

export const getCell = (
  value: string | number,
  name: string,
  row: ReceiptDocAcceptance,
  isEditableCell: boolean,
  removeAcceptance: ({ id, versionNo, barcode }: {id: number, versionNo: number, barcode: string}) => void,
) => {
  switch (name) {
    case nestedTableColumnNames.packagingType:
      return `${value} (${row.quantityInPackaging} шт)`

    case nestedTableColumnNames.quantityAccepted:
    case nestedTableColumnNames.quantityDefective:
      if (isEditableCell) {
        const isEmpty = +value === 0
        return <ChangableValue isEmpty={isEmpty}>{value}</ChangableValue>
      }
      return value

    case nestedTableColumnNames.quantityAcceptedTotal:
      return row.quantityAccepted * row.quantityInPackaging

    case nestedTableColumnNames.actionColumn:
      return (
        <span title="Удалить">
          <DeleteIcon
            className="delete_action"
            onClick={() => {removeAcceptance({ id: row.id, versionNo: row.versionNo, barcode: row.barcode })}}
          />
        </span>
      )

    default:
      return undefined
  }
}
