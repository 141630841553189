import React from 'react'
import { Button } from '@mui/material'

import { useDistributionStore } from '../../api/useChangeDistribution'
import { getButtonColor, getButtonVariant } from '../../lib/getTransitionStyles'
import {
  ChangeWarehouse,
  ExportWarehouseOrder,
  ChangeWarehouseStatus,
  DeleteCustomWarehouse,
} from '../../lib/WarehouseTransitionsFunc'

export const WarehouseTransitions = () => {
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const { changeWarehouseFunc } = ChangeWarehouse()
  const { exportWarehouseFile } = ExportWarehouseOrder()
  const { changeWarehouseStatus } = ChangeWarehouseStatus()
  const { deleteWarehouse } = DeleteCustomWarehouse()

  const handleTransitionClick = (statusId: number, nextStatus?: number) => {
    if (statusId === 5) {
      changeWarehouseStatus({ statusId: 2, onSuccessCallback: () => exportWarehouseFile() })
      return
    }
    if (statusId === 6) {
      changeWarehouseFunc()
      return
    }
    if (statusId === 8) {
      deleteWarehouse()
      return
    }
    changeWarehouseStatus({ statusId: nextStatus! })
  }
  return (
    <>
      { selectedProducts?.warehouse?.transitions?.map(el => (
        <Button
          key={el.id}
          sx={{ height: '36px' }} color={getButtonColor(el.id)}
          variant={getButtonVariant(el.id)} onClick={() => handleTransitionClick(el.id, el.nextStatus)}>{el.name}
        </Button>
      )) }
    </>
  )
}