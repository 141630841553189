import { FieldRenderProps } from 'react-final-form'

import TextInput from '../TextInput'
import { BaseInputFieldI } from '../TextInput/TextInputI'

interface CatalogSelectFieldI<T> extends FieldRenderProps<T>, BaseInputFieldI {
  label?: string,
}

const FormInputField = <T extends {}>({
  input,
  label,
  isError,
  ignoreWhiteSpaces,
  onlyDigits,
  ...rest
}: CatalogSelectFieldI<T>) => {
  const handleChange = (event) => {
    if(input.type === 'number') {
      input.onChange(event.target.value !== '' ? Number(event.target.value) : null)
    } else if(ignoreWhiteSpaces) {
      input.onChange(event.target.value.replace(/\s/g, ''))
    } else if (onlyDigits) {
      input.onChange(event.target.value.replace(/[\D]+/g, ''))
    } else {
      input.onChange(event)
    }
  }

  return (
    <TextInput {...input} label={label} isError={isError} {...rest} type={input.type} onChange={handleChange}/>
  )
}

export default FormInputField
