import styled from 'styled-components'
import { Modal } from '@mui/material'

export const LoadingModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingModalText = styled.div`
  font-size: 14px;
  color: #000;
  padding-top: 24px;
`

export const LoadingModalContent = styled.div`
  width: 627px;
  height: 284px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column-reverse;
`
