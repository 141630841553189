import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'


interface DeleteActivityProduct {
  activityId: UniqueId,
  activityProductId: UniqueId
}
const deleteActivityProduct = ({ activityId, activityProductId }: DeleteActivityProduct) =>
  api.delete(
    `/activity/activity/${activityId}/product/${activityProductId}`
  )


export const useDeleteActivityProductMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteActivityProduct
  >(deleteActivityProduct)