import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'

import { api } from '../../base'
import { NewCategoryAttribute } from '../../../lib/generated/pim/Api'

interface AddCategoryAttribute {
  newCategoryAttribute: NewCategoryAttribute
  categoryId: UniqueId
}
const addCategoryAttribute = (newAttribute: AddCategoryAttribute) =>
  api.post(`/pim/categories/${newAttribute.categoryId}/attributes`, newAttribute.newCategoryAttribute)


export const useAddCategoryAttribute = () => useMutation<
  AxiosResponse<pimApi.attributes.CommonAttribute>,
  AxiosError<errorType>,
  AddCategoryAttribute
>(addCategoryAttribute)