import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { ProductChangeGoodsModal } from 'features/product/ProductChangeGoodsModal'
import { ArchiveModal } from 'features/product/WB/ArchiveModal'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'
import { ProductCopyModal } from 'features/product/WB/ProductCopyModal/ProductCopyModal'

interface SettingsMenuItem {
  label: string,
  handler: () => void,
  type?: string,
  divider?: boolean,
  disabled?: boolean
}

export const ProductSettings = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const { commonProductQuery } = useCommonProductContext()
  const { wbProductQuery } = useWBProductContext()
  const { mutate: unarchiveProductMutation } = pimApi.products.common.useUnarchiveProductMutation()
  
  const variations = wbProductQuery?.data?.variations
  const variationSelected = variations?.find((variation) => variation?.productId === productId)
  
  const isArchived = variationSelected ? variationSelected?.inArchive : wbProductQuery?.data?.inArchive
  
  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: isArchived ? 'Вернуть из архива': 'Перенести в архив',
      handler: () => {
        if(isArchived && commonProductQuery.data) {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DefaultModal 
                  close={close}
                  accept={accept}
                  variant="contained"
                  acceptBtnColor="primary"
                  title="Вернуть размер из архива?"
                />,
              onAccept: () => {
                unarchiveProductMutation({
                  productId: commonProductQuery.data?.productId, 
                  deArchivationInfo: {
                    productId: commonProductQuery.data?.productId,
                    versionNo: commonProductQuery.data?.versionNo,
                  }
                }, {
                  onSuccess: ({ data }) => {
                    queryClient.setQueryData(pimApi.products.common.getFetchCommonProductQueryKey(parseInt(id, 10)), data)
                    wbProductQuery.refetch()
                    snackActions.info('Размер успешно возвращен из архива!')
                  }
                })
              }
            }
          )
        } else {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) =>
                commonProductQuery.data && <ArchiveModal close={close} accept={accept} product={commonProductQuery.data}/>,
              onAccept: () => {},
            }
          )
        }
      }
    },
    {
      label: 'Создать копию',
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close }) =>
              <ProductCopyModal wbProductQuery={wbProductQuery.data} commonProductData={commonProductQuery!.data!} close={close}/>
          }
        )
      }
    },
    { 
      label: 'Перенести на другой товар',
      disabled: isArchived,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) => <ProductChangeGoodsModal close={close} accept={accept} productId={id}/>,
            onAccept: () => {},
          }
        )
      }
    },
  ]

  return (
    <SettingsMenu
      options={settingsOptions}
      iconType={<MoreVert />}
      iconSize="small"
    />
  )
}
