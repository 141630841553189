import React, { useCallback, useState } from 'react'
import {
  CircularProgress,
  Box,
} from '@mui/material'
import { Search, Close, FormatListBulleted } from '@mui/icons-material'
import { subStr } from 'shared/lib/utils'
import { Option } from 'shared/ui/components/interface'

import {
  AutocompleteStyled,
  Item,
  Wrapper,
  SearchButton,
  ItemSpan,
  ItemText,
  PopperStyled,
  ActiveRemove,
  TextFieldStyled,
  ListSubheaderStyled
} from './styled'
import { ListBoxComponent } from './ListBoxComponent'

const renderGroup = (params: any) => [
  <ListSubheaderStyled key={params.key} component="div">
    {params.group}
  </ListSubheaderStyled>,
  params.children,
]

const VALUE_EMPTY = null

export interface Props {
  label?: string;
  options: Array<Option>;
  value: Option | null;
  error?: boolean;
  onChange: (option: Option | null) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement>) => void;
  onFocus: (event?: React.FocusEvent<HTMLElement>) => void;
  onCloseList: () => void;
  onSearch: (inputValue: string) => void;
  loading: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export const TnvedSelectComponent = ({
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  error,
  options,
  loading,
  onCloseList,
  onSearch,
  disabled,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: any, option: any) => {
    setIsOpen(false)
    onChange(option)
  }

  const removeOption = () => {
    if (value) {
      onChange(VALUE_EMPTY)
    }
  }

  const handleClose = () => {
    onCloseList()
    setIsOpen(false)
  }

  const handleOpen = useCallback(() => {
    if ((options && options.length) || value?.label) {
      setIsOpen(true)
    }
  }, [value])

  const PopperMy = ({ children, ...props }: any) => (
    <PopperStyled
      {...props}
      $isActive={!!value?.label as unknown as string}
      placement="bottom-start"
    >
      {children}
    </PopperStyled>
  )

  const handleInputChange = useCallback((event, newInputValue, reason) => {
    const shouldCloseSelect = reason === 'reset' && newInputValue.length > 3

    setInputValue(newInputValue)
    if (newInputValue.length < 3) {
      setIsOpen(false)
      return
    }
    setIsOpen(true)

    if (shouldCloseSelect) {
      setIsOpen(false)
    }
    if (reason === 'input') {
      onSearch(newInputValue)
    }
  }, [])

  const getRenderList = (): Array<Option> => {
    const filtredOptions = options.filter(option => option.value !== value?.value)

    if (value?.value) {
      filtredOptions.push(value)
    }

    return filtredOptions
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    onSearch(inputValue)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <Wrapper data-testid="TnvedSelectComponent">
      <AutocompleteStyled<Option>
        data-testid="AutocompleteStyled"
        size="small"
        aria-label="Меню с выпадающим списком"
        aria-haspopup="true"
        blurOnSelect={true}
        // @ts-ignore
        disableClearable={true}
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        ListboxComponent={
          ListBoxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        renderGroup={renderGroup}
        PopperComponent={PopperMy}
        value={value}
        inputValue={inputValue}
        // без filterOptions будет всегда выводится текст из noOptionsText
        filterOptions={(x) => x}
        noOptionsText="Список пуст"
        loadingText="Поиск..."
        getOptionLabel={(option) =>
          option && option.label ? `${option.value} - ${option.label}` : ''
        }
        isOptionEqualToValue={(option: any, currentValue: Option) =>
          option.value === currentValue.value
        }
        options={getRenderList()}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        renderOption={(props: any, option: any) => {
          const isActive: boolean = option.value !== null ?
            option.value.toLowerCase().includes(inputValue.toLowerCase()) :
            false
          const [code, description]: Array<string> = option.label?.split('| -') ?? ['', '']

          return (
            <Item data-testid="ItemOption" {...props}>
              <Box display="flex">
                <ItemSpan
                  $isActive={isActive}
                >
                  {option.value}
                </ItemSpan>
                <ItemText
                  $isActive={isActive}
                >
                  {subStr(code, 270)}
                  <div>-{subStr(description, 180)}</div>
                </ItemText>
              </Box>
              {option.value === value?.value && (
                <ActiveRemove onClick={removeOption}>
                  Удалить <Close />
                </ActiveRemove>
              )}
            </Item>
          )
        }}
        renderInput={(params) => (
          <TextFieldStyled
            data-testid="TextFieldStyled"
            {...params}
            label={label}
            variant="outlined"
            size="small"
            error={error}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {!value && (
                    <SearchButton
                      $isActive={isOpen}
                      type="button"
                      onClick={onClick}
                      disabled={inputValue === ''}
                      className="searchBtn"
                      color="primary"
                    >
                      <Search />
                    </SearchButton>
                  )}
                </>
              ),
            }}
            value={inputValue}
          />
        )}
      />
      {value && (
        <SearchButton type="button" disabled={true}>
          <FormatListBulleted />
        </SearchButton>
      )}
    </Wrapper>
  )
}
