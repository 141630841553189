import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

export const columns = {
  changeDate: 'changeDate',
  price: 'price',
  pricechangereason: 'pricechangereason',
  comments: 'comments',
  changer: 'changer',
  actions: 'actions',
} as const

export const columnsConfig: Array<ColumnConfig<any>> = [
  {
    name: columns.changeDate,
    columnName: columns.changeDate,
    title: 'Дата изменения',
    width: 190,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    name: columns.price,
    columnName: columns.price,
    title: 'Значение',
    width: 140,
    wordWrapEnabled: true,
    sortingEnabled: false,
  },
  {
    name: columns.pricechangereason,
    columnName: columns.pricechangereason,
    title: 'Причина изменения',
    width: 260,
    sortingEnabled: false,
    wordWrapEnabled: true,
  },
  {
    name: columns.comments,
    columnName: columns.comments,
    title: 'Комментарий',
    width: 240,
    sortingEnabled: false,
    wordWrapEnabled: true,
    cellType: CellType.RepriceComments
  },
  {
    name: columns.changer,
    columnName: columns.changer,
    title: 'Пользователь',
    width: 190,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    name: columns.actions,
    columnName: columns.actions,
    title: ' ',
    width: 60,
    wordWrapEnabled: true,
    sortingEnabled: false,
  },
]
