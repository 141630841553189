import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import LayersIcon from '@mui/icons-material/Layers'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

interface ColumnName
  extends Omit<
    pimApi.attributes.CommonAttribute,
    | 'id'
    | 'versionNo'
    | 'changer'
    | 'componentSize'
  > {
  actionColumn?: string
  padding?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  name: 'name',
  dataType: 'dataType',
  description: 'description',
  dictionaryId: 'dictionaryId',
  fixedValues: 'fixedValues',
  multipleValues: 'multipleValues',
  maxLength: 'maxLength',
  unitId: 'unitId',
  useDictionary: 'useDictionary',
  variationSupport: 'variationSupport',
  wbNomenclatureSupport: 'wbNomenclatureSupport',
  wbSizeSupport: 'wbSizeSupport',
  code: 'code',
  padding: 'padding',
  numberOfValues: 'numberOfValues',
  changed: 'changed',
  actionColumn: 'actionColumn',
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование атрибута',
      width: 220,
      sortingEnabled: false,
    },
    {
      name: columns.variationSupport,
      columnName: columns.variationSupport,
      title: 'Вариативный',
      icon: <LayersIcon/>,
      width: 160,
      sortingEnabled: false,
      editingEnabled: true,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.multipleValues,
      columnName: columns.multipleValues,
      title: 'Множественный',
      icon: <LibraryAddIcon/>,
      sortingEnabled: false,
      editingEnabled: true,
      width: 165,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.dataType,
      columnName: columns.dataType,
      title: 'Тип данных',
      sortingEnabled: false,
      width: 110,
      cellType: CellType.AttributeTypeCell
    },
    {
      name: columns.useDictionary,
      columnName: columns.useDictionary,
      title: 'Словарь\nзначений',
      sortingEnabled: false,
      wordWrapEnabled: true,
      width: 90,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.fixedValues,
      columnName: columns.fixedValues,
      title: 'Использовать значения\nтолько из словаря',
      sortingEnabled: false,
      wordWrapEnabled: true,
      width: 195,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.numberOfValues,
      columnName: columns.numberOfValues,
      title: 'Количество\nзначений',
      sortingEnabled: false,
      wordWrapEnabled: true,
      width: 'auto',
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 40,
      cellType: CellType.SettingsMenuCell
    }
  ],
}
