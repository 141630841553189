import { AxiosResponse, AxiosError } from 'axios'
import { useQuery, useMutation } from 'react-query'
import { IFileItem, IMediaItem } from 'shared/services/interfaces/product'
import { api } from 'shared/api/base'
import { getFileSizeParams } from 'shared/api/getFileSizeParams'
import { pimApi } from 'shared/api'

import { GoodsMedia, EditGoodsFile, GoodsFile, NewGoodsFileURL } from './models'


type GoodsMediaQueryKey = ReturnType<typeof getGoodsMediaQueryKey>

export const getGoodsMediaQueryKey = (goodsId?: UniqueId) =>
  ['get', `/pim/goods/${goodsId}/media`, goodsId] as const

export const fetchGoodsMedia = (
  goodsId: UniqueId
) => api.get<pimApi.goods.GoodsMedia>(`/pim/goods/${goodsId}/media`).then((res) => res.data)

export const useFetchGoodsMediaQuery = (goodsId?: UniqueId) =>
  useQuery<
  pimApi.goods.GoodsMedia,
  AxiosError<errorType>,
  pimApi.goods.GoodsMedia,
  GoodsMediaQueryKey
>(
  getGoodsMediaQueryKey(goodsId),
  (context) => fetchGoodsMedia(context.queryKey[2] as UniqueId),
)


// TODO remove this
export function getGoodsMedia(
  goodsId: UniqueId
): Promise<AxiosResponse<GoodsMedia>> {
  return api.get<GoodsMedia>(`/pim/goods/${goodsId}/media`)
}

interface CreateMediaPayload {
  goodsId: UniqueId
  mediaType?: string
  file: FormData | null
}

interface createMediaByURLPayload {
  goodsId: UniqueId
  mediaType: string
  url?: string,
}

export const validateProductMediaByURL = ({
  goodsId,
  mediaType,
  url,
}: createMediaByURLPayload) =>
  api.post(
    `/pim/goods/${goodsId}/media/url/validator?media_type=${mediaType}`,
    { url }
  )

export const useValidateMediaByURLMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    createMediaByURLPayload
  >(validateProductMediaByURL)

export const validateMediaFile = ({ goodsId, mediaType, file }: CreateMediaPayload) =>
  api.post<pimApi.goods.GoodsFile>(
    `/pim/goods/${goodsId}/media/file/validator?media_type=${mediaType}`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useValidateMediaFileMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsFile>,
    AxiosError<errorType>,
    CreateMediaPayload
  >(validateMediaFile)

export const createMedia = ({ goodsId, mediaType, file }: CreateMediaPayload) =>
  api.post<pimApi.goods.GoodsFile>(
    `/pim/goods/${goodsId}/media/file?media_type=${mediaType}`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useCreateMediaMutation = (options) =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsFile>,
    AxiosError<errorType>,
    CreateMediaPayload
  >(createMedia, options)

interface UpdateMediaPayload {
  goodsId: UniqueId
  type: string
  editMediaFileList: Array<pimApi.goods.EditGoodsFile>
}

export const updateMedia = ({ goodsId, type, editMediaFileList }: UpdateMediaPayload) =>
  api.put<Array<pimApi.goods.EditGoodsFile>>(
    `/pim/goods/${goodsId}/media`, { [type]: editMediaFileList }
  )

export const useUpdateMediaMutation = (options) =>
  useMutation<
    AxiosResponse<Array<pimApi.goods.EditGoodsFile>>,
    AxiosError<errorType>,
    UpdateMediaPayload
  >(updateMedia, options)

interface DeleteMediaPayload {
  goodsId: UniqueId,
  id: UniqueId,
  versionNo: UniqueId,
  comments?: string
}

export const deleteMedia = ({ goodsId, id, versionNo }: DeleteMediaPayload) => 
  api.delete<pimApi.goods.EditGoodsFile>(
    `/pim/goods/${goodsId}/media/${id}`,  {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )
  

export const useDeleteMediaMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.EditGoodsFile>,
    AxiosError<errorType>,
    DeleteMediaPayload
  >(deleteMedia)

// TODO: remove this when media is refactored.
export function createGoodsMedia({ goodsId, mediaType, file }: IFileItem) {
  return api.post<GoodsFile>(
    `/pim/goods/${goodsId}/media/file?media_type=${mediaType}`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export function updateGoodsMedia({
  goodsId,
  newGoodsMedia
}): Promise<AxiosResponse<EditGoodsFile>> {
  return api.put<EditGoodsFile>(`/pim/goods/${goodsId}/media`, {
    newGoodsMedia
  })
}

export async function deleteGoodsMedia({
  goodsId,
  id,
  versionNo,
}: IMediaItem): Promise<AxiosResponse<GoodsFile>> {
  return api.delete<GoodsFile>(
    `/pim/goods/${goodsId}/media/${id}`,
    {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )
}

export const createMediaByURL = ({ goodsId, mediaType, url }: createMediaByURLPayload) =>
  api.post<NewGoodsFileURL>(
    `/pim/goods/${goodsId}/media/url?media_type=${mediaType}`,
    { url }
  )

export const useCreateMediaByURLMutation = (options) =>
  useMutation<
    AxiosResponse<pimApi.goods.NewGoodsFileURL>,
    AxiosError<errorType>,
    createMediaByURLPayload
  >(createMediaByURL, options)

///

export function createUrlGoodsMedia({
  goodsId,
  mediaType,
  url,
  description,
}: IFileItem): Promise<AxiosResponse<NewGoodsFileURL>> {
  return api.post<NewGoodsFileURL>(
    `/pim/goods/${goodsId}/media/url?media_type=${mediaType}`,
    { url, description }
  )
}

export const getFileDownloadApiPath = (
  goodsId: UniqueId,
  fileId: UniqueId,
  size?: { maxWidth?: number, maxHeight?: number }
) => {
  const fileThumbnailPath = `/pim/goods/${goodsId}/media/file/${fileId}`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}

export const getGoodsThumbnailApiPath = (
  goodsId: UniqueId,
  size?: { maxWidth?: number, maxHeight?: number }
) => {
  const fileThumbnailPath = `/pim/goods/${goodsId}/thumbnail`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}

export const getFileThumbnailApiPath = (
  goodsId: UniqueId,
  fileId: UniqueId,
  size?: { maxWidth?: number, maxHeight?: number }
) => {
  const fileThumbnailPath = `/pim/goods/${goodsId}/media/filethumbnail/${fileId}`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}
