import styled from 'styled-components'
import { CardMedia, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const ItemImage = styled(CardMedia)`
  height: 60px;
`
export const ItemText = styled(Typography)`
  max-width: 100%;
  text-overflow: ellipsis;
`
export const LinkWrapper = styled(Link)`
  display: block;
  width: 100%;
  &&:hover {
    text-decoration: none;
  }
`
