import { memo } from 'react'
import { ImageItem } from 'entities/pim/MediaImage'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'
import { SettingsMenu } from 'shared/ui/components'
import { downloadFileWithPath } from 'shared/lib/utils'
import { isNil, isNotNil } from 'shared/lib/checkers'

import { BookmarkBtn } from './BookmarkBtn'


interface MediaImageItemProps {
  imgPath: any
  openGallery?: () => void
  bookmarkActive?: boolean
  onFileDelete: () => void
  isLoading?: boolean
  importPhotoFromProduct?: () => void
  onBookmarkToggle?: () => void
  downloadUrl?: string
  fileName?: string
  disabled?: boolean
}

const MediaImageItem = ({
  imgPath,
  bookmarkActive,
  onFileDelete,
  onBookmarkToggle,
  importPhotoFromProduct,
  fileName,
  downloadUrl,
  openGallery,
  disabled,
}: MediaImageItemProps) => {

  const settingsOptions: Array<any> = [
    {
      label: 'Скопировать ссылку',
      disabled: !downloadUrl,
      icon: <LinkIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        if (downloadUrl) navigator.clipboard.writeText(downloadUrl)
      }
    },
    {
      label: 'Скачать',
      icon: <DownloadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        if (downloadUrl) {
          const url = new URL(downloadUrl)
          downloadFileWithPath(url.pathname, fileName || '')
        }
      },
      divider: isNil(importPhotoFromProduct),
    },
    {
      label: 'Импорт в товар',
      icon: <UploadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: importPhotoFromProduct,
      divider: isNotNil(importPhotoFromProduct),
    },
    {
      label: 'Удалить',
      icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
      type: 'error',
      handler: onFileDelete
    },
  ]
  return (
    <ImageItem
      openGallery={openGallery}
      imgPath={imgPath}
      fileName={fileName}
      optionsBtn={
        <SettingsMenu
          className="settingsMenu"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          options={settingsOptions}
          iconSize="small"
          iconType={<MoreVert />}
        />
      }
      bookmarkBtn={
        onBookmarkToggle ? <BookmarkBtn isActive={bookmarkActive} handleBookmarkToggle={onBookmarkToggle} disabled={disabled} /> : null
      }
    />
  )
}

export const MediaImageItemMemo = memo(MediaImageItem)
