import { memo } from 'react'

import { UserStateText } from './styled'

interface UserStateCellProps {
  value: string
}

interface StateAppearance {
  color: string
  labelText: string
}

const StateAppearanceSettings: Record<string, StateAppearance> = {
  'active': { color: '#2196F3', labelText: 'Активен' },
  'closed': { color: '#BDBDBD', labelText: 'Доступ закрыт' },
  'blocked': { color: '#FF4081', labelText: 'Заблокирован' },
}

export const UserState = memo(({ value }: UserStateCellProps) => {
  const current_status = StateAppearanceSettings[value]?.labelText
  const current_color = StateAppearanceSettings[value]?.color

  return (
    <UserStateText label={current_status} sx={{ backgroundColor: current_color }}/>
  )
}
)