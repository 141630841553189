import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { MarketplaceWarehouse } from './models'

import { api } from '../../base'

export function fetchMarketplaceWarehouses(
  marketplaceId?: UniqueId
): Promise<AxiosResponse<MarketplaceWarehouse[]>> {
  return api.get<MarketplaceWarehouse[]>(
    `/pim/marketplacewarehouses?marketplace_id=${marketplaceId}`
  )
}

type QueryKey = ReturnType<typeof getMarketplaceWarehousesQueryKey>

const getMarketplaceWarehousesQueryKey = (marketplaceId?: UniqueId) =>
  ['get', 'pim/marketplacewarehouses', marketplaceId] as const

function getMarketplaceWarehouses(marketplaceId?: UniqueId) {
  return fetchMarketplaceWarehouses(marketplaceId).then((res) => res.data)
}

export const useMarketplaceWarehouseListQuery = (marketplaceId?: UniqueId) =>
  useQuery<
    Array<MarketplaceWarehouse>,
    errorType,
    Array<MarketplaceWarehouse>,
    QueryKey
  >(
    getMarketplaceWarehousesQueryKey(marketplaceId),
    (context) => getMarketplaceWarehouses(context.queryKey[2] as UniqueId),
    {
      refetchOnMount: false,
    }
  )

export function useMarketplaceWarehouseList(marketplaceId?: UniqueId) {
  const marketplaceWarehouseListQuery = useQuery<
    Array<MarketplaceWarehouse>,
    errorType,
    Array<MarketplaceWarehouse>,
    QueryKey
  >(getMarketplaceWarehousesQueryKey(marketplaceId), (context) =>
    getMarketplaceWarehouses(context.queryKey[2] as UniqueId)
  )

  return {
    marketplaceWarehouseListQuery,
  } as const
}
