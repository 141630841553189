import { Chip } from '@mui/material'
import styled from 'styled-components'

export const StyledChips = styled(Chip)`
  background: #FFFFFF;
  border: 1px solid #007DFF;

  &&.MuiChip-clickable {
    &:hover {
      background: rgba(33, 150, 243, 0.08);
    }
  }

  .MuiChip-deleteIcon {
    color: #007DFF;

    &:hover {
      color: #0155ad;
    }
  }
;

  .MuiChip-label {
    color: #0B79D0;
  }
`