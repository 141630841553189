import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'


const maxSixSymbols = yup.number()
  .transform(yupTransformEmptyStringInNull)
  .nullable()
  .test((el: number | any) =>  (el - 0).toString().length <= 6)

const barcodeIsRequired = yup.string()
  .transform(yupTransformEmptyStringInNull)
  .nullable()
  .test(
    // eslint-disable-next-line func-names
    function (val: string | any) {
      return this.options.context!.indexOf(this.parent.packagingtypeId) !== -1 || val
    }
  )

// eslint-disable-next-line func-names
yup.addMethod(yup.array, 'uniqueBarcode', function(message) {
  // eslint-disable-next-line func-names
  return this.test('unique', message, function(list: any) {

    const mapper = (x: any) => x.barcode
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    const idx = list.findIndex((l: any, i: any) => mapper(l) !== set[i])

    if (isUnique) {
      return true
    }
    return this.createError({
      path: `packaging[${idx}].barcode`,
      message,
    })
  })
})

export const validationSchema = yup.object({
  packaging: yup.array().of(
    yup.object().shape({
      packagingtypeId: yup.number().required().transform(yupTransformEmptyStringInNull),
      barcode: barcodeIsRequired,
      unitQuantity: yup.number().required().transform(yupTransformEmptyStringInNull),
      weight: maxSixSymbols,
      width: maxSixSymbols,
      depth: maxSixSymbols,
      height: maxSixSymbols,
    }).required()
  // @ts-ignore
  ).uniqueBarcode('Штрих-код не уникален')
})
