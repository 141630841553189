import { useMemo } from 'react'
import { Autocomplete, Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { pimGen } from 'shared/lib/generated'

import { AutocompleteWrapper } from './styled'

export const AlgorithmCell = ({ row, algorithmId, setAlgorithmId, setExpandNested }) => {
  const { data: algorithmsData } = pimGen.priceWizard.GetAlgorithms.useGetAlgorithms()
  
  const algorithmsOptions = useMemo(
    () => algorithmsData?.map(getOptions) || [],
    [algorithmsData]
  )

  const handleAlgorithmIdChange = (_ ,value) => {
    if (row.advisor.selectedAlgorithmId !== value.value) {
      setExpandNested(true)
    }
    setAlgorithmId(value?.value || null)
  }

  return (
    <AutocompleteWrapper>
      <Autocomplete
        id={row.id}
        value={ (algorithmsOptions.find(el => el.value === algorithmId) || null) as any }
        disableClearable={ true }
        onChange={handleAlgorithmIdChange}
        size="small"
        fullWidth={true}
        blurOnSelect={ true }
        renderOption={(props, option) => (
          <Box fontSize="14px" component="li" {...props} key={option.value}>
            {option.label}
          </Box>
        )}
        options={ algorithmsOptions }
        renderInput={ (params) =>
          <TextField
            placeholder="Не выбран"
            { ...params }
          />
        }
      />
    </AutocompleteWrapper>
  )
}