import styled from 'styled-components'

export const PhotoWrapper = styled.div<{ $isArchived?: boolean }>`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  filter: ${({ $isArchived }) => $isArchived && 'grayscale(1)'};
  opacity: ${({ $isArchived }) => $isArchived && 0.8 };
`

export const StyledButton = styled.button<{ disabled: boolean }>`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  overflow: hidden;
`

export const PlaceholderImg = styled.img<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
;
`
