import React, { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import { QueryObserverResult } from 'react-query'
import { pimApi } from 'shared/api'
import { useGoods } from 'features/goods/getGoods'
import { getGoodsEditPath, GOODS_EDIT } from 'shared/config/routes'
import { FlexWrapper, Preloader } from 'shared/ui/components'
import { goodsLib } from 'entities/pim/goods'
import { EditCard } from 'shared/ui/layouts/EditCard'
import { GoodsSettings } from 'widgets/goods/SettingsMenu'
import { snackActions } from 'shared/lib/react/snackbar'

import { InfoWrapperStep } from './InfoWrapperStep'
import { DeliveryStep } from './DeliveryStep'
import { CharacteristicStep } from './CharacteristicStep'
import { MediaStep } from './MediaStep'
import { GoodsEditPageStep, GOODS_EDIT_PAGE_STEPS } from './router-helper'
import { ProductsStep } from './ProductsStep'
import { ChangelogStep } from './ChangelogStep'
import { ExistsInStep } from './ExistsInStep'
import { useTabsForCurrentGoods } from './helpers/getTabsForCurrentGoods'
import { CompetitorsStep } from './CompetitorsStep'

import { PaperTitle } from '../components/common'
import { Tabber } from '../components/Tabber'
import { TabOption } from '../components/Tabber/Tabber'
import { MemoGoodsHeader } from '../components'


const MAX_STEP_INDEX = GOODS_EDIT_PAGE_STEPS.length - 1
const MIN_STEP_INDEX = 0

interface OnChangePayload {
  goodsId: UniqueId,
  companyId: UniqueId | null,
  marketplaceId: UniqueId | null
}

interface GoodsEditContextProviderProps {
  goodsQuery: QueryObserverResult<pimApi.goods.Goods>
}

export const GoodsEditContext = React.createContext<GoodsEditContextProviderProps | undefined>(undefined)

export const GoodsEdit = () => {
  const history = useHistory()
  const { id, step } = useParams<{ id: string; step: GoodsEditPageStep }>()

  const { goodsQuery, getGoodsReserialized, changeInfoWithoutForm, uploadGoods } = useGoods(parseInt(id, 10))
  const tabs = useTabsForCurrentGoods(goodsQuery.data)
  const goodsSteps = tabs.filter((tab) => !tab.disabled).map((tab) => tab.value)
  const isArchived = goodsQuery.data?.inArchive 
  

  const setStep = useCallback((newStep: GoodsEditPageStep) => {
    history.push(getGoodsEditPath(id, newStep))
  }, [id])

  const handlePrev = useCallback(() => {
    const index = goodsSteps.indexOf(step)

    const prevStep = goodsSteps[
      Math.max(
        index - 1,
        MIN_STEP_INDEX
      )
    ]

    setStep(prevStep)
  }, [step, goodsSteps])


  const handleNext = useCallback(() => {
    const index = goodsSteps.indexOf(step)

    const nextStep = goodsSteps[
      Math.min(
        index + 1,
        MAX_STEP_INDEX
      )
    ]

    setStep(nextStep)
  }, [step, goodsSteps])

  const initialValues = useMemo(
    () => goodsQuery.data ?
      getGoodsReserialized(goodsQuery.data) :
      {} as ReturnType<typeof getGoodsReserialized>,
    [goodsQuery.data]
  )

  const handleInfoStepSubmit = (goodsSubmitValues) => {
    uploadGoods(goodsSubmitValues).then(() => {
      snackActions.info('Сохранено')
    })
  }

  const handleOptionClick = useCallback((tabOption: TabOption<GoodsEditPageStep>) => {
    setStep(tabOption.value)
  }, [setStep])

  const handleChangeVariation = ({ goodsId }: OnChangePayload) => {
    const path = generatePath(GOODS_EDIT, { id: goodsId, step })
    history.push(path)
  }

  return (
    <GoodsEditContext.Provider value={{ goodsQuery }}>
      <EditCard>
        {goodsQuery.isLoading || goodsQuery.data === undefined
          ? (<Preloader />)
          : (
            <>
              {goodsQuery.data.name
                ? <MemoGoodsHeader
                  name={goodsQuery.data.name}
                  code={goodsQuery.data.code}
                  auxCode={goodsQuery.data.auxCode}
                  externalId={goodsQuery.data.externalId}
                  goodsId={goodsQuery.data.id}
                  imgId={goodsQuery.data.goodsBaseImageId}
                  variations={goodsQuery.data?.variations}
                  goodsType={goodsQuery.data.goodsType}
                  isArchived={isArchived}
                  onChangeVariation={handleChangeVariation}
                  archivationComments={goodsQuery.data?.archivationComments}
                  archivationReason={goodsQuery.data?.archivationreason}
                />
                : <PaperTitle>{goodsLib.getGoodsTitleByType(goodsQuery.data.goodsType)}</PaperTitle>
              }

              <FlexWrapper>
                <Tabber
                  options={tabs}
                  currentOption={{ value: step, label: '' }}
                  onOptionClick={handleOptionClick}
                />
                <GoodsSettings />
              </FlexWrapper>


              {step === 'info' && (
                <InfoWrapperStep
                  initialValues={initialValues}
                  onSubmit={handleInfoStepSubmit}
                  isArchived={isArchived}
                />
              )}

              {step === 'delivery' && (
                <DeliveryStep
                  suppliers={goodsQuery.data.suppliers ?? []}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  isArchived={isArchived}
                />
              )}

              {step === 'characteristic' && (
                <CharacteristicStep
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  onSubmit={handleNext}
                  goods={goodsQuery.data}
                  isArchived={isArchived}
                />
              )}

              {step === 'media' && (
                <MediaStep
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  goods={goodsQuery.data}
                  changeInfo={changeInfoWithoutForm}
                  refetchGoodsInfo={goodsQuery.refetch}
                  isArchived={isArchived}
                />
              )}

              {step === 'existsIn' && (
                <ExistsInStep
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  goodsType={goodsQuery.data.goodsType}
                  isArchived={isArchived}
                />
              )}

              {step === 'products' && (
                <ProductsStep
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  goodsType={goodsQuery.data.goodsType}
                  isArchived={isArchived}
                />
              )}

              {step === 'competitors' && (
                <CompetitorsStep
                  isArchived={isArchived}
                />
              )}

              {step === 'changelog' && (
                <ChangelogStep
                  handlePrev={handlePrev}
                />
              )}
            </>
          )
        }
      </EditCard>
    </GoodsEditContext.Provider>
  )
}
