import { requestBodyType } from 'shared/services/interfaces/request'
import { Request } from 'domains/request'
import { api } from 'shared/api/base'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { localStore } from './localStore'
import { browserHistory } from './browserHistory'

import toSnakeCase from '../lib/transform/toSnakeCase'

const fetchData = (type: 'get' | 'put' | 'post' | 'patch' | 'delete') =>
  async <T>( 
    endpoint: string,
    data?: requestBodyType,
    headers?: { [key: string]: string },
    options?: AxiosRequestConfig
  ): Promise<{ res: AxiosResponse<T>, data?: T }> =>
    (
      api({
        url: endpoint,
        method: type,
        data: toSnakeCase(data),
        headers: {
          ...headers,
          ...(options?.headers ?? {})
        },
        ...(options ?? {})
      })
    )
      .then((res: AxiosResponse<T>) => ({ res, data: res.data }))

      .catch(({ response }: AxiosError<T>) => ({ res: response as AxiosResponse<T>, data: response?.data }))

const fetchMethods = {
  get: fetchData('get'),
  put: fetchData('put'),
  post: fetchData('post'),
  patch: fetchData('patch'),
  delete: fetchData('delete'),
}

const request = new Request(
  fetchMethods,
  localStore,
  browserHistory
)

export { request }