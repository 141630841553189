import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

export const apiFunction = () =>
  api.get('/customer/user/badge', { responseType: 'blob' })


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', '/customer/user/badge'] as const

export const useGetUsersBadge = (options?: any) =>
  useMutation<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

