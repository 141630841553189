export function highlight(s,e){
  const el = document.getElementsByClassName(e)
  if (el.length) {
    Object.keys(el).forEach(key => { 
      // eslint-disable-next-line no-param-reassign
      el[key].innerHTML=el[key].innerHTML.replace(RegExp('(<highlight>|<\\/highlight>)', 'gi'), '')
      // eslint-disable-next-line no-param-reassign
      if(s) el[key].innerHTML=el[key].innerHTML.replace(RegExp(`(${s })`, 'gi'), '<highlight>$1</highlight>')
    }
    )
  }
} 