import { useParams } from 'react-router-dom'
import { brandquadApi } from 'shared/api'

import { BrandCard } from './BrandCard'

export const Brands = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const { data: brandsData } = brandquadApi.brands.getBrands.useGetBrands({ clientId })

  return (
    <div className="content-container">
      {brandsData?.map((brandItem) => (
        <BrandCard brandItem={brandItem} />
      ))}
    </div>
  )
}
