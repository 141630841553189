import { useMemo } from 'react'
import { Grid, Button } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import { Form } from 'shared/ui/components/form'
import arrayMutators from 'final-form-arrays'
import { sort } from 'shared/lib/utils/sort'
import { useParams } from 'react-router-dom'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { GridArrayWrapper } from 'shared/ui/styled/GridArrayWrapper'
import { pimApi } from 'shared/api'

import { validationSchema } from './validationSchema'
import { ICodeExist, ICodeNew } from './inteface'
import { Code } from './Code'


interface InitialValues {
  codes: Array<ICodeExist | ICodeNew>
}

interface CodesFormProps {
  supplier: pimApi.goods.GoodsSupplier;
  disabled?: boolean;
}

function isNewCode(code: ICodeExist | ICodeNew): code is ICodeNew {
  return !isExistingCode(code)
}
function isExistingCode(code: ICodeExist | ICodeNew): code is ICodeExist {
  return typeof (code as ICodeExist)?.id === 'number'
}

export const CodesForm = ({ supplier, disabled }: CodesFormProps) => {
  const { id } = useParams<{ id: string }>()
  const validate = useValidationSchema(validationSchema)

  const productId = parseInt(id, 10) as UniqueId

  const initialValues: InitialValues = useMemo(() => ({
    codes: supplier.codes ?
      sort(supplier.codes, 'id', 'desc') :
      []
  }), [supplier.codes])

  return (
    <Form<InitialValues>
      onSubmit={() => { }}
      mutators={{
        ...arrayMutators,
        clearCode([index], state, utils) {
          utils.changeValue(
            state,
            `codes.${index}.code`,
            () => (state.formState.initialValues as InitialValues).codes[index as number]?.code ?? '')
        }
      }}
      initialValues={initialValues}
      validateOnBlur={true}
      validate={validate}
      render={({ errors, form: { mutators: { clearCode } } }) => (
        <form>
          <FieldArray<InitialValues['codes'][number]> name="codes">
            {({ fields }) => {
              const handleAdd = () => { fields.push({ code: '', expiredFlag: false } as ICodeNew) }

              const fieldsLength = fields.value?.length
              const lastCreatedCodeIndex = fieldsLength -1

              const isCreating = Boolean(fieldsLength && isNewCode(fields.value[lastCreatedCodeIndex]))
              return (
                <GridArrayWrapper container={true} spacing={2}>
                  {fields.map((name, index) => (
                    <Code
                      key={name}
                      name={name}
                      index={index}
                      isCodeUnique={!(errors?.codes as string)}
                      clearCode={clearCode}
                      productId={productId}
                      supplier={supplier}
                      fields={fields}
                      disabled={disabled}
                    />
                  ))}
                  <Grid item={true} xs={3}>
                    <InputWrapper>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}
                        disabled={isCreating || disabled}
                      >
                        ДОБАВИТЬ
                      </Button>
                    </InputWrapper>
                  </Grid>
                </GridArrayWrapper>
              )
            }}
          </FieldArray>
        </form>
      )}
    />
  )
}
