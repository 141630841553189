import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledReportsTable = styled(Box)`
  box-shadow: 0 0 0 1px #E0E0E0;
  background-color: rgba(247, 247, 247, 0.5);
  min-width: 960px;
  border-radius: 4px;
`

export const ReportsTableHeader = styled(Box)`
  height: 50px;
  background-color: rgba(247, 247, 247, 0.5);
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0px -1px 0px #E0E0E0;
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    margin-left: 700px
  }
`

export const ReportsTableBody = styled(Box)`
  min-height: 300px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`