/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditUserPrivilege, UserInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserInfo>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  data: EditUserPrivilege[]
}>

export const apiFunction = ({ userId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/admin/users/${userId}/privileges`,data).then(res => res.data)


export const useClosePrivilegeOfUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

