import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { usePrevious } from 'shared/lib/hooks/usePrevious'

const ScrollToTop = ({ location }: RouteComponentProps) => {
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  })

  return null
}

export default withRouter(ScrollToTop)
