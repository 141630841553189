import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

const downloadSupplyOrderOrderForm = async (supplyOrderId?: UniqueId) =>
  api.get<File>(
    `/trade/orders/supplyorders/${supplyOrderId}/orderform`,
    {
      responseType: 'blob',
    }
  )

export const useDownloadSupplyOrderOrderForm = () =>
  useMutation(downloadSupplyOrderOrderForm)