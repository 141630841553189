import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { tradeGen } from 'shared/lib/generated'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { downloadFile } from 'shared/lib/utils'
import { api } from 'shared/api/base'

import { useDistributionStore } from '../../api/useChangeDistribution'

export const ExportWarehouseOrder = () => {
  const { supplyOrderQuery } = useSupplyOrderContext()
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const setIsLoading = useDistributionStore(state => state.setIsLoading)

  const apiFunction = ({ copackingorderId,query }: tradeGen.orders.GetCopackingDistributionsInExcel.IRequest) =>
    api.get<
      tradeGen.orders.GetCopackingDistributionsInExcel.ResponseType
    >(`/trade/orders/copackingorders/${copackingorderId}/distributions/excel`,
      {
        params: query,
        responseType: 'blob'
      })
  
  const useExportWarehouseFile = () =>
    useMutation<
      AxiosResponse<tradeGen.orders.GetCopackingDistributionsInExcel.ResponseType>,
      AxiosError<errorType>,
      tradeGen.orders.GetCopackingDistributionsInExcel.IRequest
    >
    (apiFunction)

  const { mutate: exportWarehouseFileMutation } = useExportWarehouseFile()
  
  const exportWarehouseFile = () => {
    setIsLoading(true)
    exportWarehouseFileMutation(
      {
        copackingorderId: supplyOrderQuery.data?.copackingorderId!,
        query: {
          copackingdistributionwarehouseId: selectedProducts?.warehouse?.id!,
        }
      }, {
        onSuccess: (response: any) => {
          setIsLoading(false)
          const file = response.data
          const fileName =
            decodeURIComponent(
              response.headers['content-disposition']?.replace(
                'inline; filename=',
                ''
              )
            ) || 'file.xlsx'

          downloadFile(file, fileName)
        },
        onError: () => setIsLoading(false)
      }
    )
  }
  
  return ({
    exportWarehouseFile
  })
}