import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const passwordValidation = yup.object().shape({

  password: yup
    .string()
    .min(8, 'Не менее 8 символов').max(30, 'Не более 30 символов')
    .transform(yupTransformEmptyStringInNull),

  passwordExpired: yup
    .date()
    .nullable()
    .typeError('Данные введены неверно')
    .transform(yupTransformEmptyStringInNull),

})

export const validationSchema = yup.object().shape({

  name: yup
    .string()
    .max(100, 'Не более 100 символов')
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  login: yup
    .string()
    .max(100, 'Не более 100 символов')
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  email: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .nullable()
    // eslint-disable-next-line max-len
    .matches(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, 'Некорректный e-mail ')
    .transform(yupTransformEmptyStringInNull),
})