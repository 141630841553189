// @ts-nocheck
import { Result, err, ok } from 'neverthrow'
import { injectable } from 'inversify'
import { ISocket, ISocketClient } from 'shared/services/interfaces/socket'
import { api } from 'shared/api'

@injectable()
export class Socket implements ISocket {

  constructor(
    private readonly _socketClient: ISocketClient,
    private readonly _path: string
  ) {}

  socket: ISocketClient

  connect(id: number): Promise<Result<ISocketClient, errorType>> {
    return new Promise((resolve) => {
      if (!this.socket && api.getToken()) {
        const socket = this._socketClient.connect(`/receiptdocs/${id}`)
        socket.onopen = () => {
          this.socket = socket
          resolve(ok(socket))
        }

        socket.onerror = (error) => {
          socket.close()
          resolve(err({ status: 9, message: error }))
        }
      } else if (this.socket) {
        if (this.socket.close) this.socket.close()
        this.socket.onclose = () => {
          const socket = this._socketClient.connect(`/receiptdocs/${id}`)

          socket.onopen = () => {
            this.socket = socket
            resolve(ok(socket))
          }

          socket.onerror = (error) => {
            socket.close()
            resolve(err({ status: 9, message: error }))
          }
        }
      } else {
        resolve(err({ status: 8, message: 'socket is not available' }))
      }
    })
  }

  on(callback: (data: any) => void): void {
    if (
      this.socket.readyState === 1 &&
      this.socket.addEventListener &&
      typeof callback === 'function'
    ) {
      this.socket.addEventListener('message', callback)
    }
  }

  off(eventName: string): void {
    if (this.socket.readyState === 1 && this.socket.removeAllListeners)
      this.socket.removeAllListeners(eventName)
  }

  emit(data: any): void {
    if (this.socket.readyState === 1 && this.socket.send && this.socket.emit)
      this.socket.send(data)
  }

  close(): void {
    if (this.socket.readyState === 1 && this.socket.close) this.socket.close()
  }
}
