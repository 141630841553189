import React, { useEffect, useState } from 'react'
import {
  Box,
  DialogActions,
  Button,
  Grid,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { customerApi } from 'shared/api'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import TextField from '@mui/material/TextField'
import { InputLabel } from 'shared/ui/components'


export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 480px;
  }
`

interface PrivilegeAddModalProps {
  close: () => void;
  accept: (newPrivilegeData: customerApi.NewPrivilege) => void
  changedCell?: customerApi.Privilege
}

export const PrivilegeModal = ({
  close,
  accept,
  changedCell,
}: PrivilegeAddModalProps) => {

  const [privilegeName, setPrivilegeName] = useState(changedCell?.name || '')
  const [privilegeCode, setPrivilegeCode] = useState(changedCell?.code || '')
  const [privilegeDesc, setPrivilegeDesc] = useState(changedCell?.description || '')
  const [isDisabled, setIsDisabled] = useState(true)


  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivilegeName(event.target.value)
  }
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivilegeCode(event.target.value)
  }

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivilegeDesc(event.target.value)
  }

  const handleSubmit = () => {
    if (changedCell) {
      const editedPrivilege = {
        id: changedCell.id,
        versionNo: changedCell.versionNo,
        name: privilegeName,
        code: privilegeCode,
        description: privilegeDesc,
      }
      accept(editedPrivilege)
      close()
    } else {
      const newPrivilege = {
        name: privilegeName,
        code: privilegeCode,
        description: privilegeDesc,
      }
      accept(newPrivilege)
      close()
    }}
  
  useEffect(()=> {
    if ((
      privilegeName === (changedCell?.name || '') &&
      privilegeCode === (changedCell?.code || '') &&
      privilegeDesc === (changedCell?.description || '')
    ) ||
      privilegeName.length === 0 || privilegeDesc.length > 255
      || privilegeName.length > 100
      || privilegeCode.length > 100
    )
    {
      setIsDisabled(true)
    } else setIsDisabled(false)

  }, [privilegeCode, privilegeName, privilegeDesc])

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <Box fontSize={20} m={3} p={0} fontWeight={500}>
          {changedCell
            ? changedCell.name
            : 'Новая привилегия'
          }
        </Box>
        <Box px={3}>
          <Grid item={true} xs={6}>
            <InputLabel label="Наименование" required={true}/>
            <TextField
              sx={{ marginBottom: '24px' }}
              id="privilegeNameField"
              placeholder="Наименование привилегии"
              variant="outlined"
              error={privilegeName.length > 100}
              size="small"
              fullWidth={true}
              value={privilegeName}
              onChange={handleNameChange}
            />
          </Grid>
          <InputLabel label="Код" required={false}/>
          <Grid item={true} xs={6}>
            <TextField
              sx={{ marginBottom: '24px' }}
              id="privilegeCodeField"
              placeholder="Код привилегии"
              variant="outlined"
              size="small"
              error={privilegeCode.length > 100}
              fullWidth={true}
              value={privilegeCode}
              onChange={handleCodeChange}
            />
          </Grid>
          <InputLabel label="Описание" required={false}/>
          <Grid item={true} xs={6}>
            <TextField
              id="privilegeDescField"
              placeholder="Описание"
              variant="outlined"
              multiline={true}
              minRows={1}
              maxRows={4}
              error={privilegeDesc.length > 255}
              size="small"
              fullWidth={true}
              value={privilegeDesc}
              onChange={handleDescChange}
            />
            <Box mt="4px" textAlign="right">
              <span 
                style={{
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.54)', 
                  lineHeight: '20px',
                }}>
                Символов: {privilegeDesc.length ?? 0}/255
              </span>
            </Box>
          </Grid>

          <DialogActions sx={{
            marginTop: '24px',
            padding: 0,
            marginBottom: '24px'
          }}>

            <Box>
              <Button
                onClick={close}
                color="primary"
              >
                ОТМЕНА
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={false}
                disabled={isDisabled}
                onClick={handleSubmit}
                sx={{
                  marginLeft: 2,
                }}
              >
                {changedCell
                  ? 'ИЗМЕНИТЬ'
                  : 'ДОБАВИТЬ'
                }
              </LoadingButton>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </ModalContainer>
  )
}



