export function getPreviousElements(arr, value1, value2) {

  const index1 = arr.map(el => el.period).indexOf(value1)
  const index2 = arr.map(el => el.period).indexOf(value2)

  const startIndex = Math.min(index1 !== -1 ? index1 : 0, index2 !== -1 ? index2 : arr.length - 1)
  const endIndex = Math.max(index1 !== -1 ? index1 : 0, index2 !== -1 ? index2 : arr.length - 1)

  const numElementsBetween = endIndex - startIndex + 1

  const previousStartIndex = startIndex - numElementsBetween
  
  return {
    previousElements: arr.slice(previousStartIndex, startIndex),
    currentElements: arr.slice(index1 !== -1 ? index1 : 0, index2 !== -1 ? index2 + 1 : arr.length) }
}