import { ReceiptDocAcceptance } from 'domains/doc'

type NestedColumnName = keyof ReceiptDocAcceptance | 'quantityAcceptedTotal' | 'actionColumn'

interface NestedColumn {
  name: NestedColumnName;
  title: string;
}

interface NestedColumnExtensions {
  columnName: NestedColumnName;
  editingEnabled: boolean;
  width: number | string;
  align?: 'left' | 'right' | 'center';
}

export const nestedTableColumnNames = {
  packagingType: 'packagingType',
  barcode: 'barcode',
  quantityAccepted: 'quantityAccepted',
  quantityAcceptedTotal: 'quantityAcceptedTotal',
  quantityDefective: 'quantityDefective',
  actionColumn: 'actionColumn',
} as const

export const nestedTableColumns: Array<NestedColumn> = [
  {
    name: nestedTableColumnNames.packagingType,
    title: 'Вид упаковки'
  },
  {
    name: nestedTableColumnNames.barcode,
    title: 'Штрих код'
  },
  {
    name: nestedTableColumnNames.quantityAccepted,
    title: 'Кол-во упаковок'
  },
  {
    name: nestedTableColumnNames.quantityAcceptedTotal,
    title: 'Ед. товар, штук'
  },
  {
    name: nestedTableColumnNames.quantityDefective,
    title: 'Брак'
  },
  {
    name: nestedTableColumnNames.actionColumn,
    title: ' '
  },
]

export const nestedTableColumnExtensions: Array<NestedColumnExtensions> = [
  {
    columnName: nestedTableColumnNames.packagingType,
    editingEnabled: false,
    width: 150,
  },
  {
    columnName: nestedTableColumnNames.barcode,
    editingEnabled: false,
    width: 165
  },
  {
    columnName: nestedTableColumnNames.quantityAccepted,
    editingEnabled: true,
    width: 145
  },
  {
    columnName: nestedTableColumnNames.quantityAcceptedTotal,
    editingEnabled: false,
    width: 145
  },
  {
    columnName: nestedTableColumnNames.quantityDefective,
    editingEnabled: true,
    width: 145
  },
  {
    columnName: nestedTableColumnNames.actionColumn,
    editingEnabled: true,
    width: 'auto',
    align: 'right'
  },
]
