import React from 'react'
import { tradeApi } from 'shared/api'
import { Box, DialogTitle } from '@mui/material'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { getOrderForPackingSearch } from 'shared/api/trade/orders'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { snackActions } from 'shared/lib/react/snackbar'

import { CustomSelectProductWidget } from './customWidget/CustomSelectProductWidget'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 740px;
    max-width: 740px;
  }
`

interface ProductCreateModalProps {
  close: () => void;
  copackingordersId: UniqueId
  goodId: string
  refetchPage: () => void;
}

export const AddPositionsGoodsCopackingModalContent = ({
  close,
  copackingordersId,
  goodId,
  refetchPage
}: ProductCreateModalProps) => {

  const { mutate: addPosition } = tradeApi.orders.usePostNewProductForPacking()
  
  const addGoodInProducts = (relatedId) => {
    addPosition(
      {
        copackingordersId,
        productId: relatedId
      },
      {
        onSuccess: () => {
          snackActions.info('Успешно сохранено!')
          refetchPage()
          close()

        }
      }  
    )
  }

  const searchFunction = () => getOrderForPackingSearch(copackingordersId, '', Number(goodId) )

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20}>Новая позиция из товара</DialogTitle>
        <Box px={3}>
          <CustomSelectProductWidget
            searchFunction={searchFunction}
            onSelectProduct={addGoodInProducts}
            close={close}

          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



