import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { isNil, isNotNil } from 'shared/lib/checkers'
import { useEffect, useState } from 'react'

import { Header } from './Header'

import { Brands } from '../Brands'
import { Paper } from '../styled'
import { Goods } from '../Goods'
import { GoodsInfo } from '../GoodsInfo'

export const Showcase = () => {
  const { searchObj } = useQueryParams({
    parseNumbers: true,
    arrayFormat: 'bracket',
  })

  const { searchString, brandIds, goodsId } = searchObj

  const brandRendering = isNil(searchString) && isNil(brandIds) && isNil(goodsId)
  const goodsRendering = isNil(goodsId) && ( isNotNil(searchString) || isNotNil(brandIds) )
  const goodInfoRendering = isNotNil(goodsId)
  
  const [ currentPage, setCurrentPage ] = useState(0)
  const [goodsStack, setGoodsStack] = useState<any[]>([])

  useEffect(() => {
    if (isNil(goodsId) && (isNotNil(searchString) || isNotNil(brandIds))) {
      setGoodsStack([])
      setCurrentPage(0)
    }
  }, [JSON.stringify(searchObj)])

  return (
    <Paper $height={window.innerHeight}>
      <div className="showcase-main">
        <Header goodsStack={goodsStack}/>
        {brandRendering && <Brands />}
        {goodsRendering && <Goods
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goodsStack={goodsStack}
          setGoodsStack={setGoodsStack}
        />}
        {goodInfoRendering && <GoodsInfo />}
      </div>
    </Paper>
  )
}
