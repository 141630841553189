import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

const syncProductsBulkOperationResult = (
  batchId: string,
) =>
  api.patch(`/pim/ops/products?batch_id=${batchId}&method=sync`)

export const useSyncProductsBulkOperationResultMutation = () =>
  useMutation<
    AxiosResponse,
    errorType,
    {
      batchId: string,
    }
  >(({ batchId }) => syncProductsBulkOperationResult(batchId))