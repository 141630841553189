import styled from 'styled-components'
import { TableSelection } from '@devexpress/dx-react-grid-material-ui'
import { TableRow } from '@mui/material'

const StyledSelectedRowComponent = styled(TableRow)<{ isSelected: boolean, oneSelected: boolean }>`
  opacity: ${({ isSelected, oneSelected }) => {
    if (oneSelected) {
      if (isSelected) {
        return 1
      } return 0.5
    } return 1
  }
};
`

export const CustomSelectedComponent = ({ props, oneSelected }: { props: TableSelection.RowProps, oneSelected: boolean }) => {
  const { highlighted } = props
  return (
    <StyledSelectedRowComponent oneSelected={oneSelected} isSelected={!!highlighted} onClick={props.onToggle} {...props}/>
  )
}
