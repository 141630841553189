import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'

import { theme } from '../../../config'


export const IsAspect = () => (
  <Tooltip
    placement="right"
    title="Отличительный признак. Заполняйте по-разному у товаров, которые собираетесь объединить в одной карточке">
    <InfoIcon fontSize="small" style={{ color: theme.palette.action.disabled, cursor: 'default' }}/>
  </Tooltip>
)