import { AxiosResponse } from 'axios'
import {
  Box,
  Typography,
  Autocomplete,
  TextField, Divider
} from '@mui/material'
import { history } from 'app/providers/with-router'
import { InputLabel } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { downloadFile } from 'shared/lib/utils'
import React, { memo, useMemo, useState } from 'react'
import { getOptions } from 'shared/ui/components/ArchiveModal/ui/ArchiveModalFormRender'
import { Accordion } from 'shared/ui/components/Accordion'

export const BulkGoodsArchive = memo(() => {
  const { archivationReasonListQuery } = pimApi.dictionaries.useArchivationReasonList()
  const { mutate: downloadMutation, isLoading: isDownloading } = pimApi.goods.useDownloadGoodsMassArchiveTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } = pimApi.goods.useUploadGoodsMassArchiveFile()

  const [archivationReasonId, setArchivationReasonId] = useState<number | null>(null)
  const [archivationComment, setArchivationComment] = useState<string>()

  const archivationReasonOptionList = useMemo(
    () => archivationReasonListQuery.data?.map(getOptions) || [],
    [archivationReasonListQuery.data]
  )
  
  const handleReasonChange = (value) => {
    setArchivationReasonId(value)
  }

  const download = () => {
    downloadMutation({ method: 'archive' },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(response.headers['content-disposition']?.replace(
            'inline; filename=',
            ''
          ))
        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
        archivationreason_id: archivationReasonId!,
        method: 'archive',
        archivation_comment: archivationComment
      },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box>
      <Typography fontSize="20px" fontWeight={400} lineHeight="160%">
        Массовая архивация товаров
      </Typography>
      <Box mt={4}>
        <InputLabel label="Причина переноса в архив" required={true} />
        <Autocomplete
          options={archivationReasonOptionList}
          size="small"
          noOptionsText="Не найдено"
          clearOnBlur={false}
          blurOnSelect={true}
          value={archivationReasonOptionList.find(item => item.value === archivationReasonId) || null}
          onChange={(event, value) => {
            handleReasonChange(value ? value.value : null)
          }}
          sx={{ width: '437px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Причина архивации"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        <Accordion
          sx={{ width: '437px', marginTop: '24px' }}
          title="Добавить комментарий"
        >
          <TextField
            label="Комментарий"
            size="small"
            value={archivationComment}
            onChange={(event) => setArchivationComment(event.target.value)}
            sx={{ width: '300px' }}
            autoComplete="off"
          />
        </Accordion>
      </Box>
      <Box display="flex" justifyContent="end" mt={3} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <Divider/>
      <Box mb="28px">
        <BulkFileActionsFooter
          download={download}
          upload={upload}
          disabled={archivationReasonId === null}
          isUploading={isUploading}
          isDownloading={isDownloading}
          onGoBack={handleGoBack}
        />
      </Box>
    </Box>
  )
}
)
