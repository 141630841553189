import { Box } from '@mui/material'
import styled from 'styled-components'

export const ImageWrapper = styled(Box)`
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 4px;
  }

  .tooltip-icon {
    display: none;
  }

  .settingsMenu {
    visibility: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: none;

    button {
      width: 28px;
      height: 28px;
      background-color: white;
    }
  }

  :hover {
    .previewBtn {
      cursor: pointer;
      display: block;
    }

    .bookmarkBtn {
      display: flex;
      cursor: pointer;
    }

    .deleteBtn {
      cursor: pointer;
      display: block;
    }

    .settingsMenu {
      display: flex;
      visibility: visible;
    }

    .tooltip-icon {
      display: block;
      cursor: pointer;
    }
  }
`


