import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { ComponentCard, GoodsCardSkeletonMemo } from 'entities/pim/goods'
import { useField } from 'react-final-form'
import { InputLabel } from 'shared/ui/components'
import { isNil } from 'shared/lib/checkers'
import { pimApi } from 'shared/api'
import { getGoodsLabelByType } from 'entities/pim/goods/lib'


interface GoodsInComponentProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onRemove: (componentId:  UniqueId) => void
  disabled?: boolean
  goodsType: pimApi.goods.GoodsType
  onChange?: (newComponent: any) => void
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

const REQUIRED_GOODS_COMPONENTS = 2

export const GoodsInComponent: FC<GoodsInComponentProps> = ({
  goodsComponents,
  onRemove,
  goodsType,
  disabled,
  onChange
}) => {

  const assortmentField = useField('assortment')

  const isGoodsComponentFilled = goodsComponents.length >= REQUIRED_GOODS_COMPONENTS

  const goodsComponentsFillifed: Array<AppPimGoodsModelsGoodsGoodsComponent | null> = isGoodsComponentFilled ?
    goodsComponents :
    // Заполняет возможно пустыми значениями чтоб вывести скелетон
    [
      goodsComponents[0],
      goodsComponents[1]
    ]

  const handleChange = (newComponent) => {
    if(onChange) onChange(newComponent)
  }

  return (
    <Box mt={3}>
      <InputLabel
        label={goodsType ? getGoodsLabelByType(goodsType) : 'Товары'}
        required={false}
        error={assortmentField.meta.touched && assortmentField.meta.error}
      />
      <Grid container={true} spacing={2}>
        {goodsComponentsFillifed.map((goodsComponent, index) => {
          const imgUrl = pimApi.goods.getGoodsThumbnailApiPath(
            goodsComponent?.componentId as number,
            fileThumbnailSize
          )

          return (
            <Grid item={true} xs={6} key={goodsComponent?.componentId ?? index}>
              {isNil(goodsComponent) ?
                <GoodsCardSkeletonMemo /> :
                <ComponentCard
                  imgUrl={imgUrl}
                  goodsType={goodsComponent.goodsType}
                  componentId={goodsComponent.componentId}
                  name={goodsComponent.name}
                  code={goodsComponent.code}
                  auxCode={goodsComponent.auxCode}
                  unitPartible={goodsComponent.unitPartible}
                  onRemoveClick={() => {
                    onRemove(goodsComponent.componentId)
                  }}
                  disabled={disabled}
                  unitCode={goodsComponent.unitCode}
                  quantityFieldName={`components.${index}.quantity`}
                  onChange={() => handleChange(goodsComponent)}
                />
              }
            </Grid>
          )
        })
        }
      </Grid>
    </Box>
  )
}
