import { Slider } from '@mui/material'
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  min-width: 175px;
  
  #checkbox-form { 
    .MuiCheckbox-root {
      padding: 5px;
      margin-right: 5px;
    }; 
    .MuiFormControlLabel-label {
      font-size: 15px;
      margin-top: 1px;
      color: #00000099
    } 
  }
    
  @media (min-width:1848px) {
      flex-direction: column;
      margin-left: 23px;
  }
`

export const StyledSlider = styled(Slider)({
  color: '#007DFF',
  height: 2,
  padding: '20px 0',
  marginBottom: '0',
  marginTop: '8px',

  '& .MuiSlider-markLabel': {
    color: '#00000099',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    border: '2px solid currentColor',
    '&:focus, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:hover': {
      boxShadow: '0px 0px 0px 5px rgba(0,125,255,0.16)'
    },
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 8px rgba(0,125,255,0.16)'
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#007DFF',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

export const HistoryHeader = styled.div`
  display: flex;
  flex-direction: column;
    
  .settings-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      margin-top: 3px;
  }
  @media (min-width:1848px) {
      flex-direction: row;

      .settings-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;
          margin-top: 0;
      }
  }
`

export const StyledSpace = styled.div`
    height: 70px;
    width: 1px;
    
    @media (min-width: 1848px) {
        background-color: #E0E0E0;
    }
`