import { Paper } from '@mui/material'
import { pimGen } from 'shared/lib/generated'
import { isNil, isNotNil } from 'shared/lib/checkers/isNil'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import { useInView } from 'react-intersection-observer'
import { useCallback, useEffect, useState } from 'react'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import {
  FlexWrap,
  HistoryListWrap, 
  LoaderWrapper,
  StyledLoaderExtra, 
  StyledLoader
} from 'pages/bulkOperations/bulkOperationsPage/ui/styled'

import { HistoryFilters, HistoryListItem, HistoryItemDetail, PlugHistoryContainer } from './ui'

const PAGE_SIZE = 15

export const HistoryBulkOperationsTab = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchObj = queryString.parse(search, { parseNumbers: true })

  const [bulkOperationsStack, setBulkOperationsStack] =
    useState<pimGen.bulkOperation.GetBulkOperations.ResponseType>([])

  const [ historyId, setHistoryId ] = useState<number | null>(null)

  const handleChooseHistoryItem = useCallback((id) => {
    setHistoryId(prevHistoryId => prevHistoryId === id ? null : id)
  }, [historyId])

  const [page, setPage] = useState(0)

  const handleChangeFilterParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true }
    )
    if (isNil(params.historyId)) {
      setPage(0)
      setHistoryId(null)
      setBulkOperationsStack([])
    }
    history.replace({ search: `?${historyStr}` })
  }

  const {
    data: bulkOperationsData,
    isFetching,
    isLoading,
  } = pimGen.bulkOperation.GetBulkOperations.useGetBulkOperations({
    query: { ...searchObj, pageSize: PAGE_SIZE, page },
  })

  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView && bulkOperationsData?.length === PAGE_SIZE) {
      setPage((prev) => prev + 1)
    } else setPage((prev) => prev)
  }, [inView])

  useEffect(() => {
    if (bulkOperationsData) {
      setBulkOperationsStack((prev) => prev.concat(bulkOperationsData))
    }
  }, [bulkOperationsData])

  return (
    <Paper sx={{ boxShadow: 'none' }}>
      {bulkOperationsData && !isLoading ? (
        <>
          <HistoryFilters
            bulkOperationsData={bulkOperationsStack}
            handleChangeFilterParams={handleChangeFilterParams}
          />
          <FlexWrap>
            <HistoryListWrap>
              {isEmptyArray(bulkOperationsStack) && isEmptyArray(bulkOperationsData) && (
                <PlugHistoryContainer/>
              )}
              {(bulkOperationsStack || bulkOperationsData)?.map((el) => (
                <HistoryListItem
                  key={el.id}
                  bulkOperationData={el}
                  historyId={Number(historyId) || null}
                  handleChooseHistoryItem={handleChooseHistoryItem}
                />
              ))}
              <LoaderWrapper height={20}>
                {isFetching && <StyledLoaderExtra size={20} />}
              </LoaderWrapper>

              {bulkOperationsStack.length !== 0 ? <div ref={ref} /> : null}
            </HistoryListWrap>
            {isNotNil(historyId) ?
              <HistoryItemDetail historyId={Number(historyId)} /> :
              <></>
            }
          </FlexWrap>
        </>
      ) : (
        <LoaderWrapper height={150}>
          <StyledLoader size={60} />
        </LoaderWrapper>
      )}
    </Paper>
  )
}
