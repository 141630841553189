import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { Delete } from '@mui/icons-material'
import { pimApi } from 'shared/api'
import { Autocomplete, Button, Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useRef } from 'react'

import { BrandName, IconStyle, BrandSelectWrapper } from './styled'


export const BrandsSupplierForm = ({ 
  values, 
  handleBrandsChange, 
  handleBrandsDelete,
  push,
  pop,
  inArchive
}) => {

  const { data } = pimApi.dictionaries.useBrandListQuery()

  const selectEl = useRef<HTMLInputElement>(null)

  const filteredBrandsFn = () => {
    if ( values ) {
      const selectedBrandNames = values.brands?.map(object => object.brandName)
      return data?.filter(item => !selectedBrandNames?.includes(item.name))
    }
    return data
  }

  const filteredBrands = filteredBrandsFn() || []

  const openAddField = () => {
    push('brands', undefined)
  }

  const onSubmit = (brand) => {
    handleBrandsChange(brand)
  }

  const onCancelChoose = () => {
    pop('brands')
  }

  return (
    <Box p={3} >
      <FieldArray name="brands">
        {({ fields }) =>
          fields.map((name, index) => (
            <BrandSelectWrapper key={name} isDisabled={false}>
              {index >= values.brands.length
                ? <>
                  <Field name={`${name}.id`}>
                    {() => (
                      <Autocomplete
                        ref={selectEl}
                        id="brandId"
                        onChange={(event, value) => onSubmit(value)}
                        options={filteredBrands}
                        getOptionLabel={(option) => option.name as string}
                        sx={{ marginRight: '21px', width: '100%' }}
                        renderInput={(params) =>
                          <TextField {...params} placeholder="Поиск бренда"/>
                        }
                      />)}
                  </Field>
                  <IconStyle>
                    <Delete
                      onClick={onCancelChoose}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconStyle>
                </>
                : <>
                  <BrandName> {values.brands[index].brandName} </BrandName>
                  <IconStyle inArchive={inArchive}>
                    <Delete
                      onClick={() => handleBrandsDelete({
                        removeId: values.brands[index].id,
                        versionNo: values.brands[index].versionNo
                      })}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconStyle>
                </>
              }
            </BrandSelectWrapper>
          ))
        }
      </FieldArray>
      <FieldArray name="brands">
        {({ fields }) =>
          (
            <Button variant="contained" onClick={openAddField} disabled={fields.value.length>values.brands.length || inArchive}>
              ВЫБРАТЬ БРЕНД
            </Button>
          )
        }
      </FieldArray>
    </Box>
  )
}