import { privilegeCheck } from 'shared/api/base'
import styled from 'styled-components'

export const DisableWrapper = styled.div`
  pointer-events: none;
  opacity: 0.5;
`

interface IPermissionCheck {
  xPrivilegeCheck: Object
  hideComponent?: boolean
  children: JSX.Element
}

export const PermissionCheckWrapper = ({ xPrivilegeCheck, hideComponent, children }: IPermissionCheck) => {
  if (privilegeCheck(xPrivilegeCheck)) {
    return (children)
  }
  return (
    <>
      {hideComponent ?
        null :
        <DisableWrapper>{children}</DisableWrapper>
      }
    </>
  )
}