import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import {
  Box,
  DialogActions,
  Button, 
  DialogTitle,
  Autocomplete,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { customerApi } from 'shared/api'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import TextField from '@mui/material/TextField'
import { InputField, InputLabel } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import arrayMutators from 'final-form-arrays'
import {
  getMarketplaceInfo,
} from 'shared/api/customer/companies/getMarketplaceInfo'
import isEmpty from 'lodash/isEmpty'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'

import { MarketplaceSelectWrapper, ModalContainer } from './styled'
import { ArrayOfCredentials } from './ArrayOfCredentials'

import { cabinetValidationSchema } from '../../lib/cabinetValidationSchema'


const HEADTEXT = 'Добавить новый кабинет \n в компанию '

interface AddCabinetModalProps {
    close: () => void;
    handleAddCabinet: (cabinetData: any) => void
    companyName: string
    cabinets?: customerApi.CompanyMarketplace[]
}

export const AddCabinetModal = ({
  close,
  handleAddCabinet,
  companyName
}: AddCabinetModalProps) => {

  const { data } = customerApi.useGetMarketplacesQuery()
  const [selectedMarketplace, setSelectedMarketplace] = useState<number | undefined>(undefined)
  const [marketplaceInfo, setMarketplaceInfo] = useState<customerApi.Marketplace | undefined>(undefined)

  const onSubmit = (cabinetsData) => {
    handleAddCabinet(cabinetsData)
    close()
  }
  useEffect(()=> {
    if (selectedMarketplace) {
      getMarketplaceInfo(selectedMarketplace).then(
        (value) => setMarketplaceInfo(value)
      )
    } else setMarketplaceInfo(undefined)
  },[selectedMarketplace])

  const validate = useValidationSchema(cabinetValidationSchema)

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20} whiteSpace="pre-line">
          {HEADTEXT + companyName}
        </DialogTitle>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={marketplaceInfo}
            mutators={{
              ...arrayMutators
            }}
            render={({
              handleSubmit,
              errors,
              visited,
              values
            }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                {data && <Box mt={1}>
                  <InputLabel
                    label="Маркетплейс"
                    required={true}
                  />
                  <Box mb="5px">
                    <Field name="marketplaceId">
                      {() => (
                        <MarketplaceSelectWrapper>
                          <Autocomplete
                            id="userId"
                            onChange={(event, selectedValue) => setSelectedMarketplace(selectedValue?.id)}
                            options={data}
                            getOptionLabel={(option) => option.name || ''}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label="Маркетплейс"
                              />
                            }
                          />
                        </MarketplaceSelectWrapper>
                      )
                      }
                    </Field>
                  </Box>
                </Box>
                }

                {
                  marketplaceInfo &&
                    <Box mt={3}>
                      <InputLabel
                        label="Наименование"
                        required={true}
                      />
                      <StyledField sx={{ marginBottom: '36px' }}>
                        <Field
                          name="cabinetName"
                          placeholder="Наименование"
                          errorAfterTouch={true}
                          helperText={visited?.cabinetName ? errors?.cabinetName : ''}
                          component={InputField}
                          required={true}
                          fullWidth={true}
                        />
                      </StyledField>
                      <ArrayOfCredentials values={values} marketplaceInfo={marketplaceInfo}/>
                    </Box>
                }
                
                <DialogActions sx={{
                  marginTop: '32px',
                  padding: 0,
                  marginBottom: '24px'
                }}>

                  <Box>
                    <Button
                      onClick={close}
                      color="primary"
                    >
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={false}
                      disabled={!isEmpty(errors)}
                      sx={{ marginLeft: 2 }}
                    >
                      ДОБАВИТЬ
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )}
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



