/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import { ok, err, Result } from 'neverthrow'
import { injectable } from 'inversify'
import {
  IRequest,
  clientType,
  errorStatuses,
} from 'shared/services/interfaces/request'
import { IStorage } from 'shared/services/interfaces/storage'
import { IHistory } from 'shared/services/interfaces/history'
import { AxiosRequestConfig } from 'axios'
import { isNil } from 'shared/lib/checkers/isNil'

export type {
  tokensType,
  clientType,
  errorStatuses,
  requestBodyType,
} from 'shared/services/interfaces/request'

@injectable()
export class Request implements IRequest {
  private _statuses: {
    isRefreshing: boolean
    onUpdateFunctions: (() => void)[]
  }

  constructor(
    private readonly _client: clientType,
    private readonly _localStore: IStorage,
    private readonly _browserHistory: IHistory
  ) {
    const updateHandler = {
      set: (obj, prop, value) => {
        obj[prop] = value
        if (prop !== 'onUpdateFunctions') {
          obj.onUpdateFunctions.forEach((fn: () => void) => fn())
          obj.onUpdateFunctions = []
        }
        return true
      },
    }

    this._statuses = new Proxy(
      {
        isRefreshing: false,
        onUpdateFunctions: [],
      },
      updateHandler
    )
  }

  private _waitForRefresh(): Promise<Result<undefined, undefined>> {
    return new Promise((resolve) => {
      this._statuses.onUpdateFunctions = [
        ...this._statuses.onUpdateFunctions,
        () => {
          if (this._statuses.isRefreshing === false) {
            resolve(ok(undefined))
          }
        },
      ]
    })
  }

  private _reloadAppVersion(res) {
    const serverVersion = res.headers['x-version']

    if (!serverVersion) return

    const current = this._localStore.get('X-Version')
    this._localStore.set('X-Version', serverVersion)

    const shouldReload = !isNil(current) && current !== serverVersion

    if (shouldReload) {
      location.reload()
    }
    
  }

  async request<T, B = unknown>(
    method: string, url: string, body?: B, headers = {}, options?: AxiosRequestConfig
  ): Promise<Result<T, errorType>> {
    const req = this._client[method]

    const { res, data } = await req<T>(url, body, headers, options)
    this._reloadAppVersion(res)

    if ((!res) || (errorStatuses[res.status])) {
      if ((!res) || (errorStatuses.unauthorized === res.status)) {
        if (this._statuses.isRefreshing === false) {
          this._statuses.isRefreshing = true
          const refreshStatus = await this.refresh()
          if (refreshStatus.isErr()) return err(refreshStatus.error)
        } else {
          await this._waitForRefresh()
        }

        const refreshedResponse = await req<T>(url, body)
        
        return ok(refreshedResponse.data)
      }
      return err({
        status: res.status,
        message: data ? data.message : 'Ошибка',
      })
    }

    return ok(data)
  }

  query<T>(url: string, options: T): string {
    if (!options) return url
    return Object.keys(options)
      .map((key) =>
        options[key] !== undefined && options[key] !== ''
          ? `${key}=${options[key]}`
          : ''
      )
      .filter((option) => option)
      .reduce(
        (prev, curr, index) =>
          index
            ? `${prev}${curr ? `&${curr}` : ''}`
            : `${prev}${curr ? `?${curr}` : ''}`,
        `${url}`
      )
  }
}
