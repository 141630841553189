import React from 'react'
import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import { Button, Box } from '@mui/material'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import useYupValidation from 'shared/lib/hooks/useYupValidation'

import { ExistingWBCard } from '../ModalFormMarketplaceWB/ExistingWBCard'
import { StyledLoadingButton } from '../ModalFormMarketplaceWB/styled'
import { validationSchema } from '../../lib/validation/validation'

export const ModalFormProductCopy = ({
  goodsId,
  companymarketplaceId,
  handleClose,
} : { goodsId: number, companymarketplaceId: number, handleClose: () => void }) => {
  const history = useHistory()
  const { mutate: createProductWB, isLoading } = pimApi.products.wb.useCreateProductWBMutation()
  const validation = useYupValidation(validationSchema)

  const onSubmit = (formValues) => {
    createProductWB(
      {
        companymarketplaceId,
        goodsId,
        useGoodsMedia: false,
        ...(formValues.productId ? { productId: parseInt(formValues?.productId, 10) } : {}),
        newVariation: true,
        ...(formValues.cardType === 'new' ? { category: formValues?.category?.value } : {})
      } as any,
      {
        onSuccess: ({ data }) => {
          handleClose()
          history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
        },
      }
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      render={({ handleSubmit, valid  }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box mt={3}>
              <ExistingWBCard multipleSelect={true} companymarketplaceId={undefined} productCopy={true}/>
            </Box>
          </Box>
          <Box pt={3} sx={{ float: 'right' }}>
            <Button onClick={handleClose} color="primary">
              ОТМЕНА
            </Button>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!valid}
            >
              СОЗДАТЬ
            </StyledLoadingButton>
          </Box>
        </form>
      )}
    />
  )
}
