import styled from 'styled-components'

export const StyledChartDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: calc(5%);
  margin-top: 16px;
  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 14px;
      color: #0000008A;
      letter-spacing: 0.15px;
    }
  }
`