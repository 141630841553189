import { Form as FinalForm } from 'react-final-form'


// TODO: обрабатывать ошибки с бэка
// function Form<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>(
//   { ...props }: FormProps<FormValues, InitialFormValues>
// ) {
//   return <FinalForm {...props}  />
// }

export default FinalForm
