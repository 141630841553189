import { TextInput } from 'shared/ui/components'
import { DataTypeProvider, GetCellValueFn } from '@devexpress/dx-react-grid'

import { EditableStyledInput } from './styled'

const reg = new RegExp('^[0-9]+$')

interface cellPropsType extends DataTypeProvider.ValueEditorProps {
  column: {
    cellType?: string,
    name: string;
    title?: string;
    getCellValue?: GetCellValueFn; }
}

export const CellInput = ({
  onValueChange,
  onBlur,
  onFocus,
  value,
  disabled,
  ...props
}: cellPropsType) => {
  const onChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    if (reg.test(event.target.value) || event.target.value === '') {
      onValueChange(event.target.value)
    }
  }
  return (
    <EditableStyledInput width="100%" customStyle={props.column.cellType}>
      <TextInput
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={true}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name="customInput"
      />
    </EditableStyledInput>
  )
}
