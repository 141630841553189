import { useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { Box, Typography, Divider, Link, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { pimApi, tradeApi } from 'shared/api'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { isNotNil } from 'shared/lib/checkers'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'

import { Message, UploadWrapper, ReportDownload } from './styled'



const statusIcons: Record<
  pimApi.goods.GoodsResponseMessageType,
  React.ReactElement
> = {
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  error: <InfoOutlinedIcon />,
}

const DEFAULT_FILE_NAME = 'template.xlsx'

interface ModalContentProps {
  close: () => void
  title?: string
  supplyOrderQuery: QueryObserverResult<tradeApi.SupplyOrder>
}
const SX_TITLE = { fontSize: '20px', paddingTop: '24px', paddingBottom: '24px' }

const FILE_EXTENSION =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 740px;
    max-width: 740px;
  }
`

export const AddSupplyOrderByFileModalContent = ({
  close,
  title,
  supplyOrderQuery,
}: ModalContentProps) => {
  const [messages, setMessages] = useState<Array<any>>([])
  const [fileUrl, setFileUrl] = useState<string | undefined>()
  const [isError, setIsError] = useState<boolean>(false)
  const { mutate: downloadMutation, isLoading: isDownloading } =
    tradeApi.orders.useDownloadSupplyOrdersPositionsTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } =
    tradeApi.orders.useUploadSupplyOrdersPositionsTemplateFile()
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      uploadMutation(
        { file, supplyOrderId: supplyOrderQuery.data?.id },
        {
          onSuccess: (data) => {
            supplyOrderQuery.refetch()
            if (data?.data.responseType !== 'successful_import') {
              setMessages(data?.data.messages.filter(item => item.type === 'error'))
              setFileUrl(data?.data.fileUrl)
              setIsError(true )
            } else {
              setMessages(data?.data.messages)
              setFileUrl(data?.data.fileUrl)
              setIsError(false )
            }
          },
        }
      )
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }

  const handleDownload = () => {
    downloadMutation(undefined, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(response.headers['content-disposition']?.replace(
            'inline; filename=',
            ''
          )) || DEFAULT_FILE_NAME

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }

  return (
    <ModalContainer open={true}>
      <Box>
        <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
          {title}
        </DialogTitleWithBtn>

        <Box px={3}>

          {isNotNil(messages) && (
            <Box>
              {messages.map((message) => (
                <Message
                  key={message.text}
                  messageType={message.type}
                  px={2}
                  py={1.5}
                  mb={3}
                >
                  {statusIcons[message.type]}
                  <Typography variant="body2" component="span">
                    {message.text}
                  </Typography>
                </Message>
              ))}
            </Box>
          )}
          {
            messages.length && !isError

              ? <></>
              :
              <>
                <Box display="flex" mb={1}>
                  <Typography variant="body2" gutterBottom={true} component="span" ml="auto" mb="8px">
                    Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
                  </Typography>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" mt={2}>
                  { isError
                    ?
                    fileUrl && (
                      <ReportDownload
                        sx={{ marginBottom: '24px' }}
                      >
                        <DownloadIcon />
                        <Link href={fileUrl} color="inherit" download="template.xlsx">
                          Скачать файл с ошибками
                        </Link>
                      </ReportDownload>
                    )
                    :
                    <LoadingButton
                      color="primary"
                      loading={isDownloading}
                      startIcon={<DownloadIcon />}
                      onClick={handleDownload}
                      size="small"
                      sx={{ marginBottom: '24px' }}
                    >
                      Скачать шаблон
                    </LoadingButton>
                  }

                  <UploadWrapper>
                    <Box ml={1} display="inline-flex" mb={3}>
                      <label htmlFor="excel-file">
                        <input
                          accept={FILE_EXTENSION}
                          id="excel-file"
                          type="file"
                          onChange={uploadFile}
                        />
                        <Button
                          variant="text"
                          onClick={ close }
                          sx={{ mr: 2 }}
                        >
                          Назад
                        </Button>
                        <LoadingButton
                          color="primary"
                          variant="contained"
                          loading={isUploading}
                          component="span"
                          startIcon={<UploadIcon />}
                        >
                          ИМПОРТ
                        </LoadingButton>
                      </label>
                    </Box>
                  </UploadWrapper>
                </Box>
              </>
          }
        </Box>
      </Box>
    </ModalContainer>
  )
}
