import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import { api } from '../base'

export const getUserSettingsQueryKey = () => ['get', '/customer/user/settings'] as const

type UserSettingsQueryKey = ReturnType<typeof getUserSettingsQueryKey>

export function getUserSettings(): Promise<AxiosResponse> {
  return api.get(
    '/customer/user/settings'
  )
}

export const useFetchUserSettingsQuery = () => useQuery<
  AxiosResponse,
  AxiosError<errorType>,
  AxiosResponse,
  UserSettingsQueryKey
>(
  getUserSettingsQueryKey(),
  getUserSettings, { refetchOnMount: false }
)

const updateUserSettings = (
  settings
) => api.put('/customer/user/settings', settings)
  

export const useUpdateUserSettingsMutation = () =>
  useMutation<
    AxiosResponse,
    errorType,
    {
      settings,
    }
  >(({ settings }) => updateUserSettings(settings))