import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import {
  arrayMove
} from '@dnd-kit/sortable'
import { pimApi } from 'shared/api'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import {
  reactQueryCacheAdd,
  reactQueryCacheDelete,
} from 'shared/lib/reactQuery'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { dialogModel } from 'shared/ui/components/dialog'
import { CircularProgressStyled, CircularProgressWrap, ContentsWrapper } from 'shared/ui/components/CircularProgress/CircularProgress'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { GoodsMediaTypes } from 'shared/types/MediaTypes'
import { PhotoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Goods/photoRequirements'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { PhotoItem } from './ui/PhotoItem'


const PICTURE_SUPPORTED_FILE_EXTENSION = ['.jpeg', '.jpg', '.webp', '.png']

export const Photos = ({ disabled }) => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const [photos, setPhotos] = useState<Array<pimApi.goods.GoodsFile>>([])

  const { data: goodsMedia } = pimApi.goods.useFetchGoodsMediaQuery(goodsId)

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.goods.useValidateMediaFileMutation()
  const { mutateAsync: validateMediaUrlMutation } =
    pimApi.goods.useValidateMediaByURLMutation()
  
  const { mutate: createMediaMutation, isLoading: createInProgress } = pimApi.goods.useCreateMediaMutation({
    onSuccess: reactQueryCacheAdd({
      queryClient,
      queryCacheKey: pimApi.goods.getGoodsMediaQueryKey(goodsId),
      path: 'photo',
    }),
  })
  const { mutate: createMediaByURLMutation, isLoading: createByURLInProgress } = pimApi.goods.useCreateMediaByURLMutation({
    onSuccess: reactQueryCacheAdd({
      queryClient,
      queryCacheKey: pimApi.goods.getGoodsMediaQueryKey(goodsId),
      path: 'photo',
    }),
  })
  const { mutate: updateMediaMutation, isLoading: updateInProgress  } = pimApi.goods.useUpdateMediaMutation({
    onSuccess: (queryResult) => {
      queryClient.setQueryData(pimApi.goods.getGoodsMediaQueryKey(goodsId), queryResult?.data)
    },
  })
  const { mutate: deleteMediaMutation, isLoading: deleteInProgress } = pimApi.goods.useDeleteMediaMutation()

  useEffect(() => {
    setPhotos(goodsMedia === undefined ? [] : goodsMedia.photo)
  }, [goodsMedia])

  const onFileValidate = (mediaType: GoodsMediaTypes) =>
    (file, onSuccess) => {
      if (typeof file === 'string') {
        validateMediaUrlMutation({ url: file, goodsId, mediaType }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      } else {
        const formFile = new FormData()
        formFile.append('file', file)
        validateMediaFileMutation({
          goodsId,
          mediaType,
          file: formFile,
        }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      }
    }
    
  const onFileUpload =
    (mediaType: GoodsMediaTypes) =>
      (files) => {
        if (files && isNotEmptyArray(files)) {

          files.forEach(el => {
            if (typeof el.file === 'string') {
              createMediaByURLMutation({ url: el.file, goodsId, mediaType })
            } else {
              const file = new FormData()

              file.append('file', el.file || '')

              createMediaMutation({
                goodsId,
                mediaType,
                file,
              })
            }
          })
        }
      }

  const onDragChange = (sourceId, sourceIndex, targetIndex) => {
    const swappedPhotos = arrayMove<pimApi.goods.GoodsFile>(photos, sourceIndex, targetIndex)
    const editMediaFileList = swappedPhotos.map<pimApi.goods.EditGoodsFile>(
      (photo: pimApi.goods.GoodsFile, index) => ({
        id: photo.id,
        versionNo: photo.versionNo,
        indexNumber: index
      })
    )
    setPhotos(swappedPhotos)
    updateMediaMutation({ goodsId, type: 'photo', editMediaFileList },
      { onError: () => { setPhotos(photos) }
      }
    )
  }

  const handleDelete = (deletingPhoto: pimApi.goods.EditGoodsFile) => {
    dialogModel.openDialog(
      {
        component: MediaDeleteConfirmModal,
        onAccept: () => {
          deleteMediaMutation(
            { goodsId, ...deletingPhoto },
            {
              onSuccess: () => {
                reactQueryCacheDelete({
                  queryClient,
                  queryCacheKey: pimApi.goods.getGoodsMediaQueryKey(goodsId),
                  deleteItem: deletingPhoto,
                  path: 'photo',
                })()
              },
            }
          )
        },
      }
    )
  }

  const isQueriesInProgress =
    createByURLInProgress ||
    createInProgress ||
    updateInProgress ||
    deleteInProgress

  const getFilePath = (photoId, size) => pimApi.goods.getFileThumbnailApiPath(goodsId, photoId, size)
  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          close={close}
          mediaVariation="image"
          mediaType="Фото"
          getFilePath={getFilePath}
          clickedItem={item}
          items={photos}
        />
      )
    })
  }

  return (
    <div>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <span style={{ fontWeight: '500', color: '#000000DE' }}>Фото</span>
            <span style={{ color: '#0000008A' }}>{ photos?.length }/30</span>
          </div>
        </Grid>
      </Grid>
      <ContentsWrapper>
        {isQueriesInProgress && (
          <CircularProgressWrap>
            <CircularProgressStyled />
          </CircularProgressWrap>
        )}
        {photos && (
          <UploadGallery
            localMedia={photos}
            mediaType="фото"
            supportedFileExtension={PICTURE_SUPPORTED_FILE_EXTENSION}
            mediaRequirement={PhotoRequirements}
            onFileValidate={onFileValidate('photo')}
            onUploadFile={onFileUpload('photo')}
            urlUploadingExist={true}
            withMainElement={true}
            addDisabled={photos.length > 30 || disabled}
            onDragChange={onDragChange}>
            {photos?.map((item, index) => (
              <PhotoItem
                index={index}
                openGallery={() => {handleOpenGallery(item)}}
                item={item}
                goodsId={goodsId}
                onFileDelete={handleDelete}
                disabled={disabled}
              />
            ))}
          </UploadGallery>
        )}
      </ContentsWrapper>
    </div>
  )
}
