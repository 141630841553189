import React from 'react'
import { analyticsApi } from 'shared/api'
import { DifferenceDataWidget } from 'widgets/DashboardWidgets'
import { generatePath } from 'react-router'
import { subtractDays } from 'shared/lib/utils/subtractDays'

export const SmallWeeklySalesWidget = () => {
  const utc = new Date()
  const dateFrom = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 14)
  const dateTo = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 1)

  const { data: salesData } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })
  const currentWeekQuantity = salesData?.data.data.slice(7).reduce((sum, elem) => sum + elem.quantity, 0)
  const prevWeekQuantity = salesData?.data.data.slice(0,7).reduce((sum, elem) => sum + elem.quantity, 0)
  const diff = (currentWeekQuantity !== undefined && prevWeekQuantity !== undefined) ? (currentWeekQuantity - prevWeekQuantity) : 0
  const chartUrl = generatePath('/analytics/charts?range=0&tab=1'
  )
  return (
    <DifferenceDataWidget title="Продажи за неделю" chartUrl={chartUrl} data={currentWeekQuantity} diff={diff}/>
  )
}