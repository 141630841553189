// @ts-nocheck
import { SnackbarProvider } from 'notistack'
import { SNACKBAR_CONFIG_PROPS } from 'shared/config'
import { SnackbarUtilsConfigurator } from 'shared/lib/react/snackbar'

export const withSnackbar = (component: () => React.ReactNode) => () => (
  <SnackbarProvider {...SNACKBAR_CONFIG_PROPS}>
    <SnackbarUtilsConfigurator />
    {component()}
  </SnackbarProvider>
)
