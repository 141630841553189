import React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import { pimApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { getCategoriesTreeQueryKey } from 'shared/api/pim/categories'
import { snackActions } from 'shared/lib/react/snackbar'

import { TabHeader } from '../../../ui/TabHeader'
import { CategoryModal } from '../modal/CategoryModal'
import { ReceivedData } from '../../lib/types'
import { addCategory } from '../../lib/CategoriesTreeOperations'

export const Header = () => {
  const { mutate: addCategoryMutation } = pimApi.categories.useAddCategory()
  const queryClient = useQueryClient()

  const handleClick = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <CategoryModal close={close} accept={accept}/>,
        onAccept: (data: ReceivedData) => {
          addCategoryMutation({ ...data, parentcategory_id: data.parentcategoryId }, {
            onSuccess: ({ data: responseData }) => {
              queryClient.setQueryData<pimApi.categories.Category[]>(getCategoriesTreeQueryKey(), (categories) => {
                if (responseData.parentcategoryId) {
                  return addCategory(categories, responseData.parentcategoryId, { ...responseData, children: [] })
                }
                return [ ...categories!, { ...responseData, children: [] } ] as pimApi.categories.Category[]
              })
              snackActions.info('Категория добавлена')
            }
          })
        },
      }
    )
  }

  return (
    <TabHeader title="Категории" addButtonTitle="Добавить категорию" addFunction={handleClick}/>
  )
}