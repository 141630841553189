import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { Image as SharedImage } from 'shared/ui/components'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'
import { dialogModel } from 'shared/ui/components/dialog'
import _uniqueId from 'lodash/uniqueId'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export const MediaTab = ({ handleDownload, goodsInfo }) => {
  const images = isNotEmptyArray(goodsInfo.images) ?
    goodsInfo.images?.map(el => ({ ...el, id: _uniqueId('prefix-'), })) :
    []

  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          mediaVariation="image"
          close={close}
          mediaType="Фото"
          clickedItem={item}
          items={images}
        />
      )
    })
  }
  
  return (
    <div className="tab-content-wrapper">
      <div className="download-main-wrapper">
        <span>Медиа</span>
        <Button
          onClick={ handleDownload }
          variant="outlined"
          startIcon={ <DownloadIcon /> }
        >
          Скачать
        </Button>
      </div>
      { (goodsInfo.images || goodsInfo.videos) &&
        <div className="media-tab-wrapper">
          { goodsInfo.images && isNotEmptyArray(goodsInfo.images) &&
            <div className="image-container">
              <span>Фото</span>
              <UploadGallery
                mediaType="фото"
                withMainElement={ true }
                boxesPerRow={ 8 }
                localMedia={ goodsInfo.images }
                addDisabled={ true }>
                { goodsInfo.images?.map((item) => (
                  <SharedImage src={ item.url } key={ JSON.stringify(item) } onClick={() => handleOpenGallery(item)}/>
                )) }
              </UploadGallery>
            </div>
          }
        </div>
      }
    </div>
  )
}