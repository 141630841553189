import React from 'react'
import { pimGen } from 'shared/lib/generated'
import styled from 'styled-components'

import { isNotEmptyArray } from '../../../lib/checkers/isNotEmptyArray'

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
`

const Tag = styled.div`
  padding: 0 8px;
  border-radius: 4px;
  background-color: #EEEEEE;
  font-size: 13px;
`

export const ProductsTags = ({ productId }: { productId: number }) => {
  const { data: objectTagsData } = pimGen.tags.GetObjectTags.useGetObjectTags({ objectId: productId })

  if (objectTagsData && isNotEmptyArray(objectTagsData)) return (
    <TagsWrapper>
      {objectTagsData.map(el => (
        <Tag key={el.tagsId}>{el.tagsName}</Tag>
      ))}
    </TagsWrapper>
  )

  return null
}