import styled from 'styled-components'
import { TextField } from '@mui/material'


export const TextFieldStyled = styled(TextField)<{$isEmpty: boolean}>`
  .MuiInputBase-adornedEnd {
    height: 40px;
  }
  .MuiInputBase-input{
    color: ${({ $isEmpty, inputProps }) => ($isEmpty || inputProps?.value === '' ? '#ffffff' : 'rgba(0, 0, 0, 0.87)')};
  }
`

export const Wrapper = styled.div<{ $fullWidth?: boolean, $height: number | undefined }>`
  position: relative;
  text-align: center;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '')};

  .MuiTextField-root {
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '')};
  }
  .MuiIconButton-root {
    padding: 8px 8px;
  }
  
  .MuiFormLabel-root {
    z-index: 0;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }

  .MuiInputBase-root {
    height: ${({ $height }) => (`${$height}px`)};
  }
  .MuiIconButton-root {
    padding: 8px 8px;
  }
  .MuiInputBase-input{
    padding-left: 12px;
  }
`

export const ClearButton = styled.div`
  position: absolute;
  bottom: -20px;
  cursor: pointer;
  font-size: 11px;
`
