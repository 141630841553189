import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  Grid,
  Box,
  DialogActions,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { QueryObserverResult } from 'react-query/types/core/types'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { DialogTitleWithBtn } from 'shared/ui/components'

import { MemoGoodsSearchSection } from './GoodsSearchSection'

interface ProductCreateModalProps {
  close: () => void;
  companymarketplaceId?: UniqueId;
  wbProductQuery?: QueryObserverResult<pimApi.products.wb.WBNomenclature>;
  newVariation?: boolean;
}

const MODAL_STYLES = { width: '600px', maxWidth: '600px', paddingTop: '32px', padding: '24px' }

const DIALOG_TITLE_WITH_BTN_SX = {
  marginBottom: '24px',
  padding: '0',
  fontSize: 20,
  fontWeight: 500,
}

export const CreateNewModal = ({
  close,
  companymarketplaceId,
  wbProductQuery,
  newVariation,
}: ProductCreateModalProps) => {
  const history = useHistory()
  const { mutate: createProductWB, isLoading } = pimApi.products.wb.useCreateProductWBMutation()

  const onSubmit = (formValues) => {
    const goodsId = formValues?.selectedGoods?.[0]?.componentId

    if(companymarketplaceId) {
      createProductWB(
        {
          companymarketplaceId,
          ...(newVariation ? { newVariation } : {}),
          wbnomenclatureId: wbProductQuery?.data?.id,
          goodsId,
          useGoodsMedia: false
        } as any,
        {
          onSuccess: ({ data }) => {
            close()
            snackActions.info('Сохранено!')
            history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
          },
        }
      )
    }
  }

  return (
    <Box sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={close} sx={DIALOG_TITLE_WITH_BTN_SX}>
        Новый размер
      </DialogTitleWithBtn>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <MemoGoodsSearchSection />
              </Grid>
            </Grid>
            <DialogActions style={{ padding: 0 }}>
              <Box>
                <Button onClick={close} color="primary">
                  ОТМЕНА
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={values.selectedGoods?.length < 1}
                  sx={{
                    marginLeft: 2,
                  }}
                >
                  ПРОДОЛЖИТЬ
                </LoadingButton>
              </Box>
            </DialogActions>
          </form>
        )}
      />
    </Box>
  )
}
