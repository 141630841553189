import styled from 'styled-components'

export const EditQuantityStyle = styled.div<{ isLoading?: boolean }>`
  display: flex;
  opacity: ${({ isLoading }) => isLoading ? '0.5' : '1' };
  flex-wrap: nowrap;
`

export const EditableInput = styled.input`
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 72px;
  font-size: 14px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
`

export const Same = styled.div`
  margin-left: 8px;
  width: 40px;
  height: 35px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), 
    rgba(255, 255, 255, 0.9)), #4CAF50;  
  border: none;
  border-radius: 4px;
`

export const Difference = styled.div`
  margin-left: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 35px;
  background: rgba(245, 0, 87, 0.08);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  color: #F50057;
`