import { api } from 'shared/api/base'
import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { Barcode, NewBarcode, EditBarcode } from '../barcodes'


interface createProductBarcodePayload {
  productId: UniqueId,
  variationId: UniqueId,
  barcode: NewBarcode,
}

interface editProductBarcodePayload {
  productId: UniqueId,
  variationId: UniqueId,
  barcode: EditBarcode,
}

interface deleteProductBarcodePayload {
  productId: UniqueId,
  variationId: UniqueId,
  barcode: DeletingObject,
}

const createProductBarcode = ({ productId, variationId, barcode } : createProductBarcodePayload) =>
  api.post<Barcode>(`/pim/products/wb/product/${productId}/variations/${variationId}/barcodes`, barcode)
    .then((res) => res.data)


const editProductBarcode = ({ productId, variationId, barcode } : editProductBarcodePayload) =>
  api.put<Barcode>(`/pim/products/wb/product/${productId}/variations/${variationId}/barcodes/${barcode.id}`, barcode)
    .then((res) => res.data)

const deleteProductBarcode = ({ productId, variationId, barcode } : deleteProductBarcodePayload) =>
  api.delete(`/pim/products/wb/product/${productId}/variations/${variationId}/barcodes/${barcode.id}`,{ data: barcode })

export const useCreateProductBarcodeMutation =
  (options?: UseMutationOptions<Barcode,AxiosError<errorType>,createProductBarcodePayload>) =>
    useMutation<Barcode,AxiosError<errorType>,createProductBarcodePayload>
    ((createProductBarcodePayload) => createProductBarcode(createProductBarcodePayload), options)

export const useEditProductBarcodeMutation =
  (options?: UseMutationOptions<Barcode,AxiosError<errorType>,editProductBarcodePayload>) =>
    useMutation<Barcode,AxiosError<errorType>,editProductBarcodePayload>
    ((editProductBarcodePayload) => editProductBarcode(editProductBarcodePayload), options)

export const useDeleteProductBarcodeMutation =
  (options?: UseMutationOptions<AxiosResponse,AxiosError<errorType>,deleteProductBarcodePayload>) =>
    useMutation<AxiosResponse,AxiosError<errorType>,deleteProductBarcodePayload>
    ((deleteProductBarcodePayload) => deleteProductBarcode(deleteProductBarcodePayload), options)
