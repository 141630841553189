import React from 'react'
import { Box, Typography } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import { AnalyticsSale } from 'shared/api/analytics'
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Label,
  Legend, Margin,
  Point,
  Series,
  Size,
  Tick,
  Tooltip,
  ValueAxis
} from 'devextreme-react/chart'
import { registerGradient } from 'devextreme/common/charts'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'

interface DiffDataParams {
  title: string
  data?: number
  diff?: number
  currentSalesData?: Array<AnalyticsSale>
  prevSalesData?: Array<AnalyticsSale>
  chartUrl?: string
}

interface dataType {
  currentSale: number
  prevSale?: number
  orderDate: Date
}

const currentSeriesColor = {
  fillId: registerGradient('linear', {
    colors: [{
      offset: '30%',
      color: '#2196F3'
    },
    {
      offset: '80%',
      color: '#2196F300'
    }],
    rotationAngle: 90
  })
}

const prevSeriesColor = {
  fillId: registerGradient('linear', {
    colors: [{
      offset: '20%',
      color: '#858585'
    },
    {
      offset: '90%',
      color: 'rgba(255,255,255,0)'
    }],
    rotationAngle: 90
  })
}
export const SalesWithSmallChart = ({ title, data, diff, chartUrl, currentSalesData, prevSalesData }: DiffDataParams) => {

  const chartData: Array<dataType> | undefined = currentSalesData?.map((el, index) => ({
    currentSale: el.quantity,
    prevSale: prevSalesData?.[index]?.quantity,
    orderDate: new Date(el.saleDate),
  }
  ))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%'
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          {title}
        </Typography>

        <ValueDelta delta={diff} value={data || 0}/>

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '50%' }}>
        {chartUrl &&
        <Box>
          <a
            style={{ display: 'flex', alignItems: 'center' }}
            href={chartUrl}
            target="_blank" rel="noreferrer">
            <BarChartIcon sx={{ color: '#00000061' }}/>
          </a>
        </Box>
        }
        <Box sx={{ width: '100%', height: '45px' }}>
          <Chart
            palette="Ocean"
            dataSource={chartData}
          >
            <Size height={55}/>
            <CommonSeriesSettings
              argumentField="orderDate"
              hoverMode="none"
            />
            <Margin top={6}/>
            <Series
              type="stackedspline"
              color="#E0E0E0"
              width={1.7}
              valueField="prevSale">
              <Point visible={false}/>
            </Series>
            <Series
              type="splinearea"
              color={prevSeriesColor}
              width={1.4}
              opacity={0.1}
              valueField="prevSale">
              <Point visible={false}/>
            </Series>
            <Series
              type="splinearea"
              color={currentSeriesColor}
              width={1.4}
              opacity={0.05}
              valueField="currentSale">
              <Point visible={false}/>
            </Series>
            <Series
              type="spline"
              color="#2196F3"
              width={1.35}
              opacity={0.3}
              valueField="currentSale">
              <Point visible={false}/>
            </Series>
            <ValueAxis
              visible={false}
            >
              <Tick visible={false}/>
              <Label visible={false}/>
              <Grid  visible={false} />
            </ValueAxis>
            <ArgumentAxis
              valueMarginsEnabled={false}
              discreteAxisDivisionMode="crossLabels"
              visible={false}
            >
              <Tick visible={false}/>
              <Label visible={false}/>
              <Grid  visible={false} />
            </ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
            />
            <Tooltip enabled={false} />
          </Chart>
        </Box>
      </Box>
    </Box>
  )
}
