import { AxiosError } from 'axios'

interface ServerError {
  detail?: string
}

export function getErrorType<E = ServerError>(error: AxiosError<E>): errorType {
  return {
    status: error.response?.status as number,
    // @ts-ignore
    message: error.response?.data?.detail
  }
}