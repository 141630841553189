// @ts-nocheck
import styled from 'styled-components'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

import { columnNames } from '../ColumnsConfig'

const StyledTableHeaderCellComponent = styled(TableHeaderRow.Cell)<{ columnname: string }>`
  background-color: #F6F6F6;
  padding: 12px 0 12px 16px !important;

  ${({ columnname }) => {
    if (columnname === columnNames.number) {
      return 'padding-left: 10px !important;'
    }
    if (columnname === columnNames.goodsBaseImageId) {
      return 'padding-left: 0px !important;'
    }
    return undefined
  }}
`

export const TableHeaderCellComponent = (props: TableHeaderRow.CellProps) => {
  const { column } = props
  return (
    <StyledTableHeaderCellComponent {...props} columnname={column.name} />
  )
}
