import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

import toSnakeCase from '../../../lib/transform/toSnakeCase'

interface EditCategoryAttribute {
  categoryId: UniqueId
  editedAttribute?: pimApi.categoryAttributes.CategoryAttribute
}

export const editCategoryAttribute = (editedAttribute: EditCategoryAttribute) => {
  const editedAttributeSnake = toSnakeCase(editedAttribute.editedAttribute)
  return api.put<pimApi.attributes.CommonAttribute>(
    `/pim/categories/${editedAttribute.categoryId}/attributes/${editedAttribute?.editedAttribute?.id}`,
    editedAttributeSnake
  )
}


export const useEditCategoryAttributeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.categoryAttributes.CategoryAttribute>,
    AxiosError<errorType>,
    EditCategoryAttribute
  >(editCategoryAttribute)
