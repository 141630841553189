import styled from 'styled-components'
import Box from '@mui/material/Box'
import { ButtonBase, Dialog } from '@mui/material'
import { Help } from 'shared/ui/styled/Help'

export const RadioList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 370px;
  overflow-y: auto;
`
export const RadioBox = styled(ButtonBase)<{ description?: string, checked: boolean }>`
  min-height: 58px;
  border: ${({ checked }) => checked ? 
    '1px solid #2196F3' :
    '1px solid #E0E0E0'};
  border-radius: 4px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding-left: ${({ description }) => description ? '' : '51px'};
  :hover {
    background-color: ${({ checked }) => checked ? '#E9F4FE' : '#FAFAFA'};
  }
  .MuiTypography-root {
    color: ${({ checked }) => checked ? '' : 'rgba(0, 0, 0, 0.6)'};
    display: inline-block;
    pointer-events: none;
    white-space: nowrap;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .MuiButtonBase-root {
    pointer-events: none;
    margin-left: auto;
    margin-right: 0
  }
  .MuiTouchRipple-child {
    background-color: #90CBF9;
  }
`

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 597px;
    max-height: 580px !important;
    padding: 24px;
  }
`

export const HelpWithMargin = styled(Help)`
  margin-left: 24px;
  margin-right: 10px;
`