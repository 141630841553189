import React, { useState } from 'react'
import styled from 'styled-components'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { isNotNil } from '../../../../../../lib/checkers'
import { isEmptyArray } from '../../../../../../lib/checkers/isNotEmptyArray'

const StyledSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;

`
export const PackagingRequirement = ({ row, allRows }) => {
  const { packagingrequirement } = row
  const operations = row.operations.map(el => el.name)
  const [expand, setExpand] = useState(allRows)
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '28px' }}>
      {expand ?
        <ExpandMoreIcon 
          sx={{ color: '#007DFF', cursor: 'pointer', visibility: isEmptyArray(operations) ? 'hidden' : 'visible' }} 
          onClick={() => {setExpand(prevState => !prevState)}}/>
        :
        <ChevronRightIcon 
          sx={{ color: '#007DFF', cursor: 'pointer', visibility: isEmptyArray(operations) ? 'hidden' : 'visible' }} 
          onClick={() => {setExpand(prevState => !prevState)}}/>
      }
      <StyledSpan>{isNotNil(packagingrequirement) ? packagingrequirement : 'Нестандартная'}</StyledSpan>
      {expand ?
        <>
          {operations.map(el => (
            <span style={{ color: '#00000099' }}>{el}</span>
          ))}
        </>
        : 
        <></>
      }
    </div>
  )
}