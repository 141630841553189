import { round } from '../utils/round'
import { DateAppearanceSettings } from '../utils/DateAppearanceSettings'

export function formatDate({ date, includeDayDiff }: { date: any, includeDayDiff?: boolean }) {
  let dayOfMonth = date.getDate()
  let month = date.getMonth() + 1
  const year = date.getFullYear()
  let hour = date.getHours()
  let minutes = date.getMinutes()
  // @ts-ignore
  const diffMs = new Date() - date
  const diffSec = Math.round(diffMs / 1000)
  const diffMin = diffSec / 60
  const diffHour = diffMin / 60
  const diffDay = diffHour / 24
  const diffMonth = diffDay / 30

  // форматирование
  month = month < 10 ? `0${  month}` : month
  dayOfMonth = dayOfMonth < 10 ? `0${  dayOfMonth}` : dayOfMonth
  hour = hour < 10 ? `0${  hour}` : hour
  minutes = minutes < 10 ? `0${  minutes}` : minutes

  if (!includeDayDiff) {
    if (diffSec < 1) {
      return 'прямо сейчас'
    }
    if (diffMin < 1) {
      return `${ diffSec } сек. назад`
    }
    if (diffHour < 1) {
      return `${ round(diffMin, 0) } мин. назад`
    }
    if (diffDay < 1 && diffHour < 5) {
      return `${ round(diffHour, 0) } ч. назад`
    }
    if ((new Date().getFullYear() - year) > 0) {
      return `${ dayOfMonth }.${ month }.${ year }`
    }
  } else {
    if (diffSec < 1) {
      return 'прямо сейчас'
    }
    if (diffMin < 1) {
      return `${ diffSec } сек. назад`
    }
    if (diffHour < 1) {
      return `${ round(diffMin, 0) } мин. назад`
    }
    if (diffDay < 1) {
      return `${ round(diffHour, 0) } ч. назад`
    }
    if (diffMonth < 1) {
      return `${ round(diffDay, 0) } дн. назад`
    }
    if ((new Date().getFullYear() - year) > 0) {
      return `${ dayOfMonth }.${ month }.${ year }`
    }
  }
  return `${dayOfMonth} ${DateAppearanceSettings[month].month}, ${hour}:${minutes}`
  
}