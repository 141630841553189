import React from 'react'
import { StyledDiscount } from 'entities/pim/price/ui/styled'

import { round } from '../../../../lib/utils/round'

interface IProfitabilityContainer {
  profit?: string
  profitPercent?: string
}
export const ProfitabilityContainer = ({ profit, profitPercent }: IProfitabilityContainer) => (
  <>
    { profit ?
      <div title={profit} style={{ height: 'auto', width: '50%' }}>
        <span style={{ color: Number(profit) < 0 ? '#F50057' : 'black' }}>{ round(profit, 0) }</span>
      </div>
      : null }
    { profitPercent ?
      <div title={profitPercent} style={{ height: 'auto', width: '50%' }}>
        <StyledDiscount style={{
          marginLeft: '0',
          color: Number(profitPercent) < 0 ? '#F50057' : '#2196F3',
          border: Number(profitPercent) < 0 ? '1px solid #F50057' : 'border: 1px solid #007DFF',
          width: 'min-content',
          alignItems: 'center !important' }}>{ round(profitPercent, 0) }%</StyledDiscount>
      </div>
      : null }
  </>
)