import styled from 'styled-components'
import { Chip, Tooltip, Typography , Alert } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

export const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: 12px;
  padding: 0 6px;
  height: 20px;
  background-color: #ED6C02 !important;
  color: #fff;
  .MuiChip-label {
    padding: 0;
  }
`

export const StyledIcon = styled.span`
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.warning.main };
    height: 22px;
  }
`

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000',
    width: 298,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
    padding: 16,
  },
  h3: {
    marginTop: 0,
    paddingTop: 0,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  '.MuiTextField-root': {
    marginTop: 22,
  },
}))


export const AlertTitleStyled = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
`
export const AlertStyled = styled(Alert)`
  padding: 8px 16px 12px 10px;
  background-color: #EBEBEB;
  color: rgba(0, 0, 0, 0.38);
  .MuiAlert-icon {
    margin-right: 8px;
    margin-top: 16px;
    padding: 0;
    color: rgba(0, 0, 0, 0.38);
  };
  .MuiAlert-message { 
    padding: 0;
  }
`
export const AlertMessageStyled = styled(Typography)`
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.15px;
`
