import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

import {
  EditQuantityStyle,
  EditableInput
} from './styled'

interface EditFieldPropsType {
  value?: string
  isLoading?: boolean
}

export const StyledLoader = styled(CircularProgress)`
  margin-left: 16px;
`

function round(value?: number | string) {
  if (value) {
    const toNumber = Number(value)
    return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(toNumber)
  } return '0'
}

export const EditField = ({ value, isLoading }: EditFieldPropsType) => (
  <EditQuantityStyle>
    <EditableInput>
      {isLoading ? <StyledLoader size={14} /> : round(value)}
    </EditableInput>
  </EditQuantityStyle>
)