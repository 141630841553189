// @ts-nocheck
import styled from 'styled-components'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'
import { TemplateConnector } from '@devexpress/dx-react-core'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import { Theme } from '@mui/material'

import { getColorForRow } from '../helpers'

const StyledTableRowComponent = styled(Table.Row)<{
  theme: Theme, row: CalculatedReceiptDocPosition, isrowexpanded?: boolean
}>`
  position: relative;
  :hover {
    .delete_action {
      display: block;
    }
  }
  ${({ theme, row, isrowexpanded }) => {
    const { bgColor = 'rgba(255, 255, 255, 0.01)', borderColor = '#007DFF' } = getColorForRow(theme, row, isrowexpanded)

    return `
      background-color: ${bgColor};
      ${isrowexpanded && `
        > td {
          border-bottom: 1px solid transparent;
          border-top: 1px solid ${borderColor}
        }
        box-shadow: inset 2px 0px 0px ${borderColor};
      `};
    `
  }}
`

const RowComponent = memo(({
  row,
  expandedDetailRowIds,
  ...restProps
}: Table.DataRowProps & { expandedDetailRowIds: Array<number> }) => {
  const isRowExpanded = expandedDetailRowIds.includes(row.id)

  return (
    <StyledTableRowComponent
      {...restProps}
      row={row}
      isrowexpanded={isRowExpanded ? 'true' : undefined}
    />
  )
})

const TableRow = (props: Table.DataRowProps) => (
  <TemplateConnector>
    {({ expandedDetailRowIds }) => (
      <RowComponent expandedDetailRowIds={expandedDetailRowIds} {...props}  />
    )}
  </TemplateConnector>
) 
export const TableRowComponent = memo(TableRow)
