import { PriceCard } from 'entities/pim/price'

import { PriceWrapper } from '../currentPriceCell/styled'

export const NewPriceCell = ({ row }) => {
  const { discount, price, spp, oldPrice } = row.advicePriceDetail

  return (
    <PriceWrapper>
      <PriceCard
        price={price}
        oldPrice={oldPrice}
        sppValue={spp}
        readOnly={false}
        discount={discount}
      />
    </PriceWrapper>
  )
}