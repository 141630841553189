import { api } from 'shared/api/base'
import { AxiosResponse, AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

interface UpdateProductGoodsPayload {
  productId: string
  content: pimApi.products.EditProduct
}

export const updateProductGoods = ({ productId, content }: UpdateProductGoodsPayload) =>
  api.put<pimApi.products.common.Product>(
    `/pim/products/common/product/${productId}`,
    content
  )

export const useUpdateProductGoodsMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.common.Product>,
    AxiosError<errorType>,
    UpdateProductGoodsPayload
  >(updateProductGoods)