import React, { useState } from 'react'
import { Grid, Button, Box } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { generatePath } from 'react-router'
import { dialogModel } from 'shared/ui/components/dialog'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { GOODS_VARIANT } from 'shared/config/routes'
import {
  BottomButtonWrapper,
  BottomSection,
} from 'shared/ui/goods/common'
import { Goods } from 'shared/api/pim/goods'
import { GoodsFile } from 'shared/api/pim/goods/models'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { Photos } from 'features/goods/media/photos'
import { pimApi } from 'shared/api'
import styled from 'styled-components'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { VideoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Goods/videoRequirements'
import { GoodsMediaTypes } from 'shared/types/MediaTypes'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { DocumentRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Goods/documentRequirements'
import { CircularProgressStyled, CircularProgressWrap, ContentsWrapper } from 'shared/ui/components/CircularProgress/CircularProgress'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { DocItem , VideoItem } from './components'

import { useGetGoodsMedia, useMediaCreate,
  useMediaDelete, useMediaUpdate, useMediaUrlCreate
} from '../../model/hooks'

interface MediaStepProps {
  handlePrev: () => void
  handleNext: () => void
  goods: Goods
  changeInfo: (goodsSubmitValues: Goods) => void;
  refetchGoodsInfo: () => void;
  isArchived?: boolean;
}

interface DeleteParams {
  value: GoodsFile,
  goodsId: UniqueId,
  callback?: () => void
}

const StyledMediaGrid = styled.div<{ rowGap: number }>`
  height: max-content;
  margin-bottom: 24px;
  .mediaGrid {
    gap: 0 !important;
    grid-row-gap: ${({ rowGap }) => `${rowGap}px !important`};
  }
`

const VIDEO_SUPPORTED_FILE_EXTENSION = ['.mp4', '.avi', '.webm', '.mpeg', '.mov']
const DOCUMENT_SUPPORTED_FILE_EXTENSION = ['.pdf', '.jpeg', '.jpg', '.webp', '.png']

export const MediaStep = ({
  handlePrev, handleNext, isArchived
} : MediaStepProps) => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.goods.useValidateMediaFileMutation()
  const { mutateAsync: validateMediaUrlMutation } =
    pimApi.goods.useValidateMediaByURLMutation()

  const { data: goodsMedia } = useGetGoodsMedia(goodsId)
  const { mediaCreate, mediaCreateInProgress } = useMediaCreate()
  const { mediaUrlCreate } = useMediaUrlCreate()
  const { mediaDelete } = useMediaDelete()
  const { mediaUpdate } = useMediaUpdate()
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)
  const [openCreateModal, setOpenCreateModal] = useState(false)


  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const hasAttributesForVariation = goodsAttributesForVariationsQuery.data?.length !== 0

  const onFileValidate = (mediaType: GoodsMediaTypes) =>
    (file, onSuccess) => {
      if (typeof file === 'string') {
        validateMediaUrlMutation({ url: file, goodsId, mediaType }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      } else {
        const formFile = new FormData()
        formFile.append('file', file)
        validateMediaFileMutation({
          goodsId,
          mediaType,
          file: formFile,
        }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      }
    }

  const onFileUpload =
    (mediaType: any, callback?: any) =>
      (files) => {
        if (files && isNotEmptyArray(files)) {

          files.forEach(el => {
            if (typeof el.file === 'string') {
              mediaUrlCreate({ formValues: { url: el.file }, goodsId, mediaType })
            } else {
              mediaCreate({
                file: el.file,
                goodsId,
                mediaType,
                callback
              })
            }
          })
        }
      }

  const handleDelete = (params: DeleteParams) => {
    dialogModel.openDialog(
      {
        component: MediaDeleteConfirmModal,
        onAccept: () => {
          mediaDelete({
            ...params,
            // TODO remove this mess
            callback: () => {
              params?.callback?.()
            }
          })
        }
      }
    )
  }

  const getModalFilePath = (photoId, size) => pimApi.goods.getFileThumbnailApiPath(goodsId, photoId, size)
  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          close={close}
          mediaVariation="video"
          mediaType="Видео"
          getFilePath={getModalFilePath}
          clickedItem={item}
          items={goodsMedia?.video || []}
        />
      )
    })
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <span style={{ fontSize: '20px', fontWeight: '500', color: '#000000DE', marginBottom: '16px' }}>Медиа</span>
        <Photos disabled={isArchived} />
        <div>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <span style={{ fontWeight: '500', color: '#000000DE' }}>Видео</span>
              </div>
            </Grid>
          </Grid>
          <ContentsWrapper width={1024}>
            {mediaCreateInProgress && (
              <CircularProgressWrap>
                <CircularProgressStyled />
              </CircularProgressWrap>
            )}
            <StyledMediaGrid rowGap={24}>
              {goodsMedia?.video && (
                <UploadGallery
                  localMedia={goodsMedia?.video || []}
                  mediaType="видео"
                  largeContainer={true}
                  boxesPerRow={5}
                  onFileValidate={onFileValidate('video')}
                  addDisabled={isArchived || goodsMedia?.video.length > 5}
                  supportedFileExtension={VIDEO_SUPPORTED_FILE_EXTENSION}
                  mediaRequirement={VideoRequirements}
                  onUploadFile={onFileUpload('video')}
                  urlUploadingExist={true}>
                  {goodsMedia?.video?.map((item) => {
                    const handleFileDelete = () => {
                      handleDelete({ value: item, goodsId })
                    }
                    return (
                      <VideoItem
                        key={ item.id }
                        openGallery={() => {handleOpenGallery(item)}}
                        item={ item }
                        goodsId={ goodsId }
                        onFileDelete={ handleFileDelete }
                        disabled={ isArchived }
                      />
                    )})}
                </UploadGallery>
              )}
            </StyledMediaGrid>
          </ContentsWrapper>
        </div>
        <div>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <span style={{ fontWeight: '500', color: '#000000DE' }}>Инструкции, сертификаты и пр.</span>
              </div>
            </Grid>
          </Grid>
          <ContentsWrapper width={1024}>
            {mediaCreateInProgress && (
              <CircularProgressWrap>
                <CircularProgressStyled />
              </CircularProgressWrap>
            )}
            <StyledMediaGrid rowGap={80}>
              {goodsMedia?.video && (
                <UploadGallery
                  localMedia={goodsMedia?.document || []}
                  mediaType="документ"
                  largeContainer={true}
                  boxesPerRow={5}
                  onFileValidate={onFileValidate('document')}
                  addDisabled={isArchived || goodsMedia?.document?.length > 5}
                  supportedFileExtension={DOCUMENT_SUPPORTED_FILE_EXTENSION}
                  mediaRequirement={DocumentRequirements}
                  onUploadFile={onFileUpload('document')}
                  urlUploadingExist={true}>
                  {goodsMedia?.document?.map((item) => {
                    const handleFileDelete = () => {
                      handleDelete({ value: item, goodsId })
                    }
                    const handleFileUpdate = (description: string, callback: () => void) => {
                      mediaUpdate(
                        { value: { ...item, description }, goodsId },
                        { onSuccess: callback, onError: callback }
                      )
                    }
                    return (
                      <DocItem
                        key={item.id}
                        item={item}
                        goodsId={goodsId}
                        onFileUpdate={handleFileUpdate}
                        onFileDelete={handleFileDelete}
                        disabled={isArchived}
                      />
                    )})}
                </UploadGallery>
              )}
            </StyledMediaGrid>
          </ContentsWrapper>
        </div>
      </div>
      <BottomSection>
        <BottomButtonWrapper>
          <div>
            <Button color="primary" onClick={handlePrev}>
              НАЗАД
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isArchived}
            >
              ДАЛЕЕ
            </Button>
          </div>
          <div>
            {goodsId && hasAttributesForVariation && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generatePath(GOODS_VARIANT, { id: goodsId })}
                disabled={isArchived}
              >
                ДОБАВИТЬ ВАРИАЦИЮ
              </Button>
            )}
            <Box display="inline-flex" pl={2}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleOpenCreateModal}
                disabled={isArchived}
              >
                СОЗДАТЬ ПРОДУКТ
              </Button>
              <ProductCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} />
            </Box>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
