import Paper from '@mui/material/Paper'
import { isNotNil } from 'shared/lib/checkers'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { pimGen } from 'shared/lib/generated'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { TablePagination } from '@mui/material'
import { getRowsPerPageOptions } from 'shared/ui/components/Table/lib'
import { useMemo } from 'react'

import { StyledLoader, StyledTableBox } from '../styled'
import { PriceSettingsTableRow } from '../PriceSettingsTableRow'
import { TableFilters } from '../../../../filter/tableFilters'
import { TableHeader } from '../../../../ui/TableHeader'
import { PriceSettingsCells } from '../../lib/priceSettingsCells'
import { StyledTable } from '../../../../ui/styled'
import { TableBody } from '../../../../ui/TableBody'
import { pagingPanelLocale } from '../../lib/localization'


const ROW_HEIGHT = 76
const HEADER_HEIGHT = 160
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

const bonusCols = Math.floor(
  (window.innerHeight -
    DEFAULT_ROWS_COUNT * ROW_HEIGHT -
    HEADER_HEIGHT -
    TABLE_CONTROLS -
    TABLE_HEADER_FOOTER) /
  ROW_HEIGHT
)
const calculatedRowsPerPage =
  bonusCols > MIN_ROWS_COUNT
    ? DEFAULT_ROWS_COUNT + bonusCols
    : DEFAULT_ROWS_COUNT


export const PriceSettingsTable = () => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })
  const { limit, page, tab, ...searchParams } = searchObj
  const { data: priceSettingsQuery, isLoading, isFetching } =
    pimGen.priceWizard.GetPriceSettings.useGetPriceSettings({ query: searchParams })

  const priceSettingsArray = priceSettingsQuery?.filter(el => !el.disabled)

  const currentRowsPerPage = isNotNil(limit)
    ? parseInt(limit, 10)
    : calculatedRowsPerPage

  const limits = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const pageSizes = getRowsPerPageOptions(priceSettingsArray?.length || 0, currentRowsPerPage)

  const startIndex = (page ?? 0) * limits

  const tableData = useMemo(
    () => priceSettingsArray?.slice(startIndex, startIndex + limits),
    [priceSettingsArray, page, limit])

  const handlePaginationChange = (field: 'page' | 'limit', value) => {
    handleChangeParams({ params: { [field]: value }, options: { skipNull: true, skipEmptyString: true } })
  }

  return (
    <StyledTableBox component={Paper}>
      <TableFilters/>
      {(isLoading || isFetching) && (<StyledLoader size={60} />)}
      {tableData &&
        <StyledTable>
          <table>
            <TableHeader tableCells={PriceSettingsCells}/>
            <TableBody
              tableCells={PriceSettingsCells}
              tableData={tableData}
              tableRow={PriceSettingsTableRow}/>
          </table>
        </StyledTable> }
      {isNotEmptyArray(priceSettingsArray) && priceSettingsArray && priceSettingsArray.length > calculatedRowsPerPage &&
        <div style={ { width: '100%', display: 'flex', justifyContent: 'end' } }>
          <TablePagination
            count={priceSettingsArray.length}
            onPageChange={(_, value) => handlePaginationChange('page', value)}
            onRowsPerPageChange={(e) => handlePaginationChange('limit', e.target.value)}
            page={page ?? 0}
            rowsPerPageOptions={pageSizes}
            rowsPerPage={limits}
            labelDisplayedRows={ pagingPanelLocale.info }
            labelRowsPerPage={ pagingPanelLocale.rowsPerPage }
          />
        </div>
      }
    </StyledTableBox>
  )
}