import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface EditBrand {
    brandId: UniqueId,
    brandData: pimApi.brands.EditingBrand
}
export const editBrand = ({ brandId, brandData }: EditBrand) =>
  api.put<pimApi.brands.Brand>(
    `/pim/brands/${brandId}`,
    brandData
  )


export const useEditBrandMutation = () =>
  useMutation<
        AxiosResponse<pimApi.brands.Brand>,
        AxiosError<errorType>,
        EditBrand
        >(editBrand)
