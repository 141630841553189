import { useQuery } from 'react-query'
import { api } from 'shared/api/base'

interface ITemplatesModel {
  id: number
  name: string
}
const getTemplatesModelQueryKey = () =>
  ['get', '/review/models'] as const

type GetTemplatesModelQueryKey = ReturnType<typeof getTemplatesModelQueryKey>

function getTemplatesModel() {
  return api.get(
    '/review/models'
  ).then(res => res.data)
}
const getAllTemplatesModel = () => getTemplatesModel()

export const useGetTemplatesModelQuery = () =>
  useQuery<
    Array<ITemplatesModel>,
    errorType,
    Array<ITemplatesModel>,
    GetTemplatesModelQueryKey
  >(
    getTemplatesModelQueryKey(),
    getAllTemplatesModel,
    { staleTime: Infinity, keepPreviousData: true }
  )
