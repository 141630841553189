import { useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'
import { Form } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { snackActions } from 'shared/lib/react/snackbar'
import { ADMIN_PANEL_EDIT_USER } from 'shared/config'
import { customerGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'

import { CreateUserForm } from './ui/CreateUserForm'
import { validationSchema } from './lib/validationSchema'

export const CreateUser = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutate: createUserMutation, isLoading } =
    customerGen.administration.CreateUser.useCreateUser()

  const validate = useValidationSchema(validationSchema)

  const onSubmit = (values) => {
    createUserMutation(
      {
        data: {
          email: values.email,
          name: values.name,
          login: values.mailAsLogin ? values.email : values.login,
        },
      },
      {
        onSuccess: (data) => {
          snackActions.info('Сохранено!')
          queryClient.invalidateQueries(customerGen.administration.GetUsers.getQueryKey({ query: undefined }))
          history.push(generatePath(ADMIN_PANEL_EDIT_USER, { id: data.id }))
        },
      }
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ mailAsLogin: true }}
      render={({ handleSubmit, errors, valid, visited }) => (
        <CreateUserForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          errors={errors}
          valid={valid}
          visited={visited}
        />
      )}
    />
  )
}
