import { UserImage } from './UserImage'


interface StateCellProps {
  name: string
  apiUrl: string
}


export const UserPhotoCell = ({ apiUrl, name }: StateCellProps) => (
  <UserImage
    src={apiUrl}
    userName={name}
  />
)

