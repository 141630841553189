import styled from 'styled-components'
import { Grid } from '@mui/material'
import { EditableInput } from 'shared/ui/components'

export const EditableStyledInput = styled(EditableInput)`
  input {
    color: #007DFF;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    ${({ disabled }) => !disabled && `
      :hover {
        ::placeholder {
          opacity: 1;
        }
      }
    `}
  }
`

export const InfoList = styled.div`
  display: flex;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
`

export const InfoBlock = styled.div`
  width: 50%;
`

export const InfoItem = styled.div`
  border-bottom: 1px solid #E0E0E0;
  padding: 0px 16px;
  height: 32px;
  display: flex;
  align-items: center;
  &:last-child{
    border-bottom: none;
  }

  input {
    font-size: 14px;
    padding: 3px;
  }
`

export const ItemTitle = styled.span`
  display: inline-block;
  min-width: 130px;
`

export const ItemValue = styled.span`
  display: inline-block;
  width: calc(100% - 130px);
`

export const OneRowValues = styled.div`
  display: flex;
`

export const OneRowValuesCentered = styled(OneRowValues)`
  align-items: center
`

export const Separator = styled.div`
  margin: 0 10px;
`

export const Info = styled.div`
  font-size: 14px;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  text-align: center;

  svg {
    margin-right: 10px;
  }
`

export const ToolbarButtonStart = styled(Grid)`
  button {
    height: 100%;
  }
`

export const StatusChangeTitle = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`

export const StatusChangeTitleWrapper = styled(Grid)<{ hasRightMargin: boolean }>`
  ${
  ({ hasRightMargin, theme }) => hasRightMargin &&
    `margin-right ${theme.spacing(2)};`
}
`

export const StatusFieldWrapper = styled(Grid)`
  * > div {
    min-width: 132px;
  }
`

export const MessageStorageIdWrapper = styled(Grid)`
  flex-grow: 1 ;
`