import { Tooltip } from '@mui/material'
import sumBy from 'lodash/sumBy'
import round from 'lodash/round'

import { isNotNil } from '../../../../../../lib/checkers'
import { isNotEmptyArray } from '../../../../../../lib/checkers/isNotEmptyArray'

export const NameValueArraySumCell = ({
  daysWithStocks,
  dataArray,
}: {
  daysWithStocks: any
  dataArray: Array<{ name: string; value: string; ignore?: boolean; use?: boolean, orders: number }>
}) => {
  const { anywhere } = daysWithStocks
  let orders: number | undefined

  // eslint-disable-next-line no-prototype-builtins
  const ordersExist = dataArray.filter(el => el.hasOwnProperty('orders') && isNotNil(el.orders))
  if (isNotNil(ordersExist) && isNotEmptyArray(ordersExist)) {
    orders = dataArray.filter(arr => !arr?.ignore && arr?.use).reduce((acc, obj) => acc + (obj?.orders || 0),0)/anywhere
  }

  const withoutIgnored = dataArray.filter(arr => !arr?.ignore && arr?.use)
  const withoutNull = withoutIgnored.filter(arr => Number(arr.value) !== 0)

  // eslint-disable-next-line no-restricted-globals
  if (isNotNil(orders) && !isNaN(Number(orders))) {
    return (
      <span>{round(orders, 2)}</span>
    )
  }

  return (
    <>
      {withoutNull.length > 0 ? (
        <Tooltip
          title={withoutIgnored.map((item) => (
            <div key={item.name}>
              {`${item.name}: ${item.value} `}
              <br/>
            </div>
          ))}
          placement="right"
        >
          <span>{round(sumBy(withoutIgnored, 'value'), 2)}</span>
        </Tooltip>
      ) : (
        <span>{round(sumBy(withoutIgnored, 'value'), 2)}</span>
      )}
    </>
  )
}
