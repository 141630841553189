import { AxiosResponse } from 'axios'
import { Result } from 'neverthrow'

export type requestBodyType = any

export interface responseType<T> {
  res: AxiosResponse
  data?: T
}

export interface clientType {
  get: <T>(url: string) => Promise<responseType<T>>;
  put: <T>(url: string, body?: requestBodyType) => Promise<responseType<T>>;
  post: <T>(url: string, body?: requestBodyType) => Promise<responseType<T>>;
  patch: <T>(url: string, body?: requestBodyType) => Promise<responseType<T>>;
  delete: <T>(url: string, body?: requestBodyType) => Promise<responseType<T>>;
}

export interface tokensType {
  accessToken: string
  refreshToken: string
}

export interface qParams {
  [name: string]: string | number
}

export enum errorStatuses {
  unauthorized = 401,
  notFound = 404,
  forbidden = 403,
  badRequest = 400,
  unprocessable = 422,
  serverError = 500,
}
export interface IRequest {
  request<T>(
    type: keyof clientType,
    url: string,
    body?: requestBodyType,
    headers?: { [key: string]: string },
  ): Promise<Result<T, errorType>>
  query<T>(url: string, options: T): string
  refresh(): Promise<Result<string, errorType>>
}
