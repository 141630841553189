/* eslint class-methods-use-this: 0 */
import { filterQuery, IListUtils } from 'shared/services/interfaces/list-utils'
import { injectable } from 'inversify'

@injectable()
export class ListUtils implements IListUtils {

  filter(
    list : any[], // DocType
    search : filterQuery,
  ) : any[] {
    return list.filter((item) => {
      if (search.status && search.status.length > 0 && item.status) {
        const status = Array.isArray(search.status) ? search.status : [search.status]

        if (!status.includes(item.status)) {
          return false
        }
      }

      if (search.supplier && item.supplier) {
        const searchString = search.supplier.replace(new RegExp('\\\\', 'g'), '\\\\')
        if (!item.supplier.match(new RegExp(searchString, 'gi'))) return false
      }
      
      if (search.recipient && item.recipient) {
        const searchString = search.recipient.replace(new RegExp('\\\\', 'g'), '\\\\')
        if (!item.recipient.match(new RegExp(searchString, 'gi'))) return false
      }

      if (search.numberDoc) {
        const searchString = search.numberDoc.replace(new RegExp('\\\\', 'g'), '\\\\')
        if (
          !item.docNumber.match(new RegExp(searchString, 'gi'))
          && !item.supplierDocNumber.match(new RegExp(searchString, 'gi'))
        ) return false
      }
      

      if (search.dateFrom && item.docDate) {
        if (new Date(search.dateFrom).getTime() > new Date(item.docDate).getTime()) return false
      }

      if (search.dateTo && item.docDate) {
        if (new Date(search.dateTo).getTime() < new Date(item.docDate).getTime()) return false
      }

      return true
    })
  }

  sort<T extends { docDate: number }>(list : T[], search : {[key: string] : string}) : T[]{
    function sortBy(a: { [x: string]: number }, b: { [x: string]: number }) {
      if (search.sortBy.includes('-')) {
        const sortField = search.sortBy.substring(1)
        if (a[sortField] < b[sortField]) {
          return 1
        }
        if (a[sortField] > b[sortField]) {
          return -1
        }
        return 0
      }
      if (a[search.sortBy] < b[search.sortBy]) {
        return -1
      }
      if (a[search.sortBy] > b[search.sortBy]) {
        return 1
      }
      return 0
    }
    if (search.sortBy) {
      return list.sort(sortBy)
    }
    return list
  }
}
