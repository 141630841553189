/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Areas } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Areas>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** Только области для хранения FBS */
    is_fbs?: boolean,
  /** Только области для хранения */
    is_storage?: boolean,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wms/area/list`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wms/area/list`, { query }] as const

export const useGetAreaList = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

