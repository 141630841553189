import { pimApi } from 'shared/api'
import { SettingsMenuItem } from 'pages/product/edit/main/model/types'
import { getGoodsPanelContentPath } from 'shared/config'
import { useHistory } from 'react-router'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'

import { ReceivedData } from './types'
import { addCategory, deleteCategoryItem } from './CategoriesTreeOperations'

import { CategoryModal } from '../ui/modal/CategoryModal'

export const CategorySettings = (categoryData: pimApi.categories.Category  ) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { mutate: deleteCategory } = pimApi.categories.useDeleteCategoryMutation()
  const { mutate: editCategory } = pimApi.categories.useEditCategoryMutation()

  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: 'Изменить',
      divider: true,
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <CategoryModal close={close} accept={accept} initialValues={categoryData}/>
          ), onAccept: (data: ReceivedData) => {
            editCategory({ ...data, versionNo: categoryData.versionNo, id: categoryData.id }, {
              onSuccess: ({ data: responseData }) => {
                queryClient.setQueriesData<pimApi.categories.Category[]>(pimApi.categories.getCategoriesTreeQueryKey(), (categories) =>
                  addCategory(
                    deleteCategoryItem(categories, categoryData.id), responseData.parentcategoryId, { ...responseData, children: [] })
                )
                queryClient.setQueriesData(pimApi.categories.getCurrentCategoryQueryKey(responseData.id), responseData)
                snackActions.info('Изменение сохранено')
              }
            })
          }
        })
      }
    },
    {
      label: 'Удалить',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <ModalContent
              close={close}
              accept={accept}
              title="Удалить категорию?"
              acceptBtnText="УДАЛИТЬ"
              acceptBtnColor="error"
              variant="text"
            />
          ),
          onAccept: () => {
            deleteCategory({ id: categoryData.id, versionNo: categoryData.versionNo, comments: ''  }, {
              onSuccess: () => {
                queryClient.setQueryData<pimApi.categories.Category[]>(pimApi.categories.getCategoriesTreeQueryKey(), (categories) =>
                  deleteCategoryItem(categories, categoryData.id) as pimApi.categories.Category[])
                const path = getGoodsPanelContentPath()
                history.push(path)
                snackActions.info('Категория удалена')
              }
            })
          },
        })
      },
      type: 'error'
    },
  ]

  return (settingsOptions)
}