import { Label, withUnits, withMaxCount, withRequired } from 'shared/lib/transform/Label'

import { WBMapingAttribute } from './getNormalizedAttributes'

export const getLabelPreparedWBAttribute = (WBCardAttribute: WBMapingAttribute) => {
  const labelInstance = new Label(WBCardAttribute.attributeName)

  if (WBCardAttribute.unitName) {
    withUnits(labelInstance, WBCardAttribute.unitName)
  }

  if (WBCardAttribute.maxcount) {
    withMaxCount(labelInstance, WBCardAttribute.maxcount)
  }


  if (WBCardAttribute.required) {
    withRequired(labelInstance)
  }

  return labelInstance.label
}