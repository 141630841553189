import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosError } from 'axios'

export const getUsersContragentsQueryKey = (userId: UniqueId) =>
  ['get', 'pim/admin/users/contragents', userId] as const

type GetUsersContragentsQueryKey = ReturnType<
  typeof getUsersContragentsQueryKey
>

function getUsersContragents(userId: UniqueId) {
  return api
    .get<pimApi.admin.ContragentUser[]>(
      `/pim/admin/users/${userId}/contragents`
    )
    .then(({ data }) => data)
}

export const useGetUsersContragentQuery = (userId: UniqueId) =>
  useQuery<
    pimApi.admin.ContragentUser[],
    AxiosError<errorType>,
    pimApi.admin.ContragentUser[],
    GetUsersContragentsQueryKey
  >(getUsersContragentsQueryKey(userId), (context) =>
    getUsersContragents(context.queryKey[2] as UniqueId)
  )
