import { useState, useEffect, useCallback, memo } from 'react'
import { Dialog } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import { useSelector } from 'react-redux'
import { detailSelector } from 'store/doc/selectors'
import { isNil } from 'shared/lib/checkers'

import Toolbar from './Toolbar/Toolbar'
import * as UI from './Table/styled'
import { DocsFooter } from './DocFooter'
import { DocTable } from './Table/Table'
import { GoodsPreview } from './components/GoodsPreview'
import { filterPositionListDebounced } from './helpers'

const DocsMemo = () => {
  const docDetail = useSelector(detailSelector)

  const [selectedGoodsId, setSelectedGoodsId] = useState<null | UniqueId>(null)
  const [rows, setRows] = useState<CalculatedReceiptDocPosition[]>(docDetail?.positions || [])

  useEffect(() => {
    if (docDetail?.positions?.length) {
      setRows(docDetail?.positions)
    }
  }, [docDetail?.positions])

  const handleOpenDialog = useCallback((row: { goodsId: UniqueId }) => {
    setSelectedGoodsId(row.goodsId)
  }, [])

  const closeGoodsPreview = () => {
    setSelectedGoodsId(null)
  }

  const handleFilterChange = useCallback((searchText: string) => {
    if (!docDetail?.positions?.length) return
    
    filterPositionListDebounced(docDetail.positions, searchText, setRows)
  }, [docDetail?.positions])

  if (isNil(docDetail?.id)) return null
  return (
    <UI.Wrapper>
      <Toolbar initialValues={docDetail} handleFilterChange={handleFilterChange} />

      {selectedGoodsId && (
        <Dialog onClose={closeGoodsPreview} aria-labelledby="simple-dialog-title" open={true}>
          <GoodsPreview goodsId={selectedGoodsId} onClose={closeGoodsPreview}/>
        </Dialog>
      )}

      {rows?.length > 0 && (
        <DocTable
          rows={rows}
          handleOpenDialog={handleOpenDialog}
        />
      )}
      <DocsFooter docDetail={docDetail} />
    </UI.Wrapper>
  )
}

export const Docs = memo(DocsMemo)