import { Tooltip } from '@mui/material'
import { getFileSizeParams } from 'shared/api/getFileSizeParams'

import { ChangelogImage, ChangelogImageWrapper, StyledLink } from './styled'

export const ChangesCell = ({ changes }: { changes: any }) => (
  <>
    {changes.map((change) => {
      if (change.field === 'photo' || change.field === 'photo360') {
        return (
          <ChangelogImageWrapper key={change.field}>
            <ChangelogImage url={`${change.url}?${getFileSizeParams({ maxWidth: 180, maxHeight: 180 })}`} />
            <Tooltip 
              title={change.value}
              placement="bottom-start"
            >
              <StyledLink href={change.url}>{change.value}</StyledLink>
            </Tooltip>
          </ChangelogImageWrapper>
        )
      }

      return (
        <span key={change.field}>
          {`${change.field} - ${change.value} ${change.url ? change.url : ''} `}
          <br />
        </span>
      )
    })}
  </>
)
