import { getBulkOperationsPath } from 'shared/config'
import AddIcon from '@mui/icons-material/Add'
import WorkIcon from '@mui/icons-material/Work'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

import PanelContentElement from '../../../adminPanel/AdminPanelRoot/Content/ui/AdminPanelContentElement'
import { BulkOperationsContainer } from '../../bulkOperationsPage/ui/styled'

export const GoodsBulkOperationsTab = () => (
  <BulkOperationsContainer>
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('goods', 'create'),
        subtitle: 'Массовое создание\nтоваров',
        label: 'Создать',
        icon: <AddIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('goods', 'supplierschange'),
        subtitle: 'Массовое изменение информации поставщиков',
        label: 'Изменить поставщиков',
        icon: <WorkIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('goods', 'archive'),
        subtitle: 'Массовая архивация товаров',
        label: 'Архивировать',
        icon: <ArchiveIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('goods', 'unarchive'),
        subtitle: 'Массовая разархивация товаров',
        label: 'Вернуть из архива',
        icon: <UnarchiveIcon/>
      }}
    />
  </BulkOperationsContainer>
)
