import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 244px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #E0E0E0;
  border-radius: 4px;
  padding: 8px 16px 12px;

  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.text.secondary};
    }

    .date-time-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      .icon {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.26);
      }

      .date-time-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4000000059604645px;
        color: ${({ theme }) => theme.palette.text.disabled};
      }
    }
  }

  .min-max-percents-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    height: 40px;
    border-top: 1px solid #E0E0E0;
    margin: auto -16px -12px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 12px;
    
    .min-max-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`

export const ProfitabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .warehouse-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .warehouse-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      padding-right: 8px;
      width: 60%;
      -webkit-box-orient: vertical;
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.text.primary};
    }

    .warehouse-values {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80px;

      .profit-value {
        margin-right: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
`