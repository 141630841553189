// @ts-nocheck
import { Table } from '@devexpress/dx-react-grid-material-ui'
import styled from 'styled-components'

export const StyledTableCell = styled(Table.Cell)`
  height: 52px;
  padding: 0px 8px 0px 8px;
  word-wrap: no-wrap;
`

export const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  white-space: nowrap;
  color: #007DFF;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-overflow: ellipsis;
  max-width: 180px;
  height: 21px;
  text-align: center;
`

interface Props {
  directionUp: boolean;
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'} !important;

  svg {
    font-size: 19px;
    margin-right: 8px;
  }
`

export const StyledIcon = styled.div<Props>`
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'}
`

interface imageProps {
  url: string;
}

export const ChangelogImage = styled.div<imageProps>`
  height: 180px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
  background-position: center;
`

export const ChangelogImageWrapper = styled.div`
  max-width: 100%;
  padding: 10px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 180px;
    width: 180px;
  }
`