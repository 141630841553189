import { useHistory } from 'react-router-dom'
import { Form } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { ADMIN_PANEL_EDIT_SUPPLIER } from 'shared/config'
import { generatePath } from 'react-router'

import { validationSchema } from './lib/validationSchema'
import CreateSupplierForm from './ui/CreateSupplierForm'

export const CreateSupplier= () => {
  const history = useHistory()

  const { mutate: createContragentMutation, isLoading } =
        pimApi.admin.useCreateContragentMutation()

  const validate = useValidationSchema(validationSchema)

  const onSubmit = (values) => {
    createContragentMutation(values, {
      onSuccess: (data) => {
        snackActions.info('Сохранено!')
        history.push(generatePath(ADMIN_PANEL_EDIT_SUPPLIER, { id: data.data.id })
        )
      },
    })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, values, errors }) => (
        <CreateSupplierForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          errors={errors}
          values={values}
        />
      )}
    />
  )
}
