import { useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'

import { APP_VERSION as LOCAL_APP_VERSION } from '../../config'
import { getToken } from '../../api/base'

export const SentryInit = () => {
  const accessToken = useMemo(() => getToken() ? JSON.parse(atob(getToken()?.split('.')[1] || '')) : '',[getToken()])
  // Sentry react
  useEffect(() => {
    Sentry.setUser(
      {
        username: accessToken.name,
        id: accessToken.sub,
        privileges: accessToken.privileges,
        roles: accessToken.roles,
        app_version: LOCAL_APP_VERSION
      })
    Sentry.setTags(
      {
        'appVersion': LOCAL_APP_VERSION,
        'customerCode': accessToken.customer_code,
        'customerName': accessToken.customer_name
      })
  },[accessToken])
  return null
}