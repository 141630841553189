import { Box } from '@mui/material'
import styled from 'styled-components'

export const DeltaValues = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  > div {
    width: 50%;
  }
`