import { tradeGen } from 'shared/lib/generated'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { Autocomplete, Button, DialogActions } from '@mui/material'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import TextField from '@mui/material/TextField'
import { LoadingButton } from '@mui/lab'
import { CircularProgressStyled } from 'shared/ui/components/CircularProgress/CircularProgress'
import React, { useMemo, useState } from 'react'
import { defaultSort } from 'shared/lib/utils/sort'
import { convertDate } from 'shared/lib/utils/DateAppearanceSettings'
import { toSimpleDate } from 'shared/lib/utils/dateToString'

import { useDistributionStore } from '../../../api/useChangeDistribution'

interface AutocompleteOptions {
  value: any
  label: string
}

interface IChangeWarehouse {
  distributionData: tradeGen.orders.Getdistributions.ResponseType
  close: () => void
  accept: (e) => void
}

interface ISelectedParameters {
  selectedWarehouse: AutocompleteOptions | null,
  selectedDate: AutocompleteOptions | null
  selectedBoxType: AutocompleteOptions | null
}

export const ChangeWarehouse = ({ distributionData, close, accept } : IChangeWarehouse) => {
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const [selectedParameters, setSelectedParameters] = useState<ISelectedParameters>({
    selectedWarehouse: null, selectedBoxType: null, selectedDate: null
  })
  
  const { data: coefficientData } =
    tradeGen.orders.Getgetacceptancecoefficients.useGetgetacceptancecoefficients({
      query: {
        dateFrom: toSimpleDate(distributionData.acceptancecoefficientsSettings.dateFrom),
        dateTo: toSimpleDate(distributionData.acceptancecoefficientsSettings.dateTo),
      } })

  const filteredCoefficients = coefficientData?.filter(el => el.coefficient !== -1)

  const uniqueValues = useMemo(() => {
    const availableDates = selectedParameters.selectedWarehouse ?
      filteredCoefficients?.filter(el => el.warehouseName === selectedParameters.selectedWarehouse?.value) :
      filteredCoefficients

    const availableBoxTypes = selectedParameters.selectedDate ?
      availableDates?.filter(el => el.date === selectedParameters.selectedDate?.value) :
      filteredCoefficients

    const uniqueWarehouses = [...new Set(filteredCoefficients?.map(item => item.warehouseName))].
      map(el => ({ label: el, value: el }))

    const uniqueDates =
      defaultSort([...new Set(availableDates?.map(item => item.date))], 'desc').map(el => ({ label: convertDate(el), value: el }))

    const uniqueBoxTypes = [...new Set(availableBoxTypes?.map(item => item.boxTypeName))].map(el=> ({ label: el, value: el }))

    const filteredWarehouse = filteredCoefficients?.find(item =>
      item.warehouseName === selectedParameters.selectedWarehouse?.value &&
      item.date === selectedParameters.selectedDate?.value &&
      item.boxTypeName === selectedParameters.selectedBoxType?.value
    )

    if (coefficientData) {
      return { uniqueWarehouses, uniqueDates, uniqueBoxTypes, filteredWarehouse }
    }
    return null

  }, [coefficientData, selectedParameters])

  const { warehouseName } = selectedProducts?.warehouse?.acceptancecoefficients as any
  
  return (
    <>
      { coefficientData && uniqueValues ?
        <>
          <DialogTitleWithBtn
            sx={ { fontSize: '20px', padding: '0px', paddingBottom: '8px' } }
            description={ warehouseName }
            onClose={ close }>
            Заменить склад
          </DialogTitleWithBtn>

          <Autocomplete
            options={ uniqueValues.uniqueWarehouses }
            isOptionEqualToValue={ isOptionEqual }
            noOptionsText="Нет фильтров"
            size="small"
            clearOnBlur={ false }
            disableClearable={ !selectedParameters?.selectedWarehouse }
            onChange={ (_, value) =>
              setSelectedParameters(prev => ({
                ...prev,
                selectedWarehouse: value,
                selectedBoxType: null,
                selectedDate: null
              })) }
            value={ selectedParameters?.selectedWarehouse || { label: '', value: 0 } }
            renderInput={ (params) =>
              <TextField { ...params } label="Склад поставки" />
            } />

          <Autocomplete
            options={ uniqueValues.uniqueDates }
            isOptionEqualToValue={ isOptionEqual }
            noOptionsText="Нет фильтров"
            size="small"
            clearOnBlur={ false }
            disabled={ !selectedParameters.selectedWarehouse }
            disableClearable={ !selectedParameters?.selectedDate }
            onChange={ (_, value) => setSelectedParameters(prev => ({ ...prev, selectedDate: value, selectedBoxType: null })) }
            value={ selectedParameters?.selectedDate || { label: '', value: 0 } }
            renderInput={ (params) =>
              <TextField { ...params } label="Дата поставки" />
            } />

          <Autocomplete
            options={ uniqueValues.uniqueBoxTypes }
            isOptionEqualToValue={ isOptionEqual }
            noOptionsText="Нет фильтров"
            disabled={ !selectedParameters.selectedDate }
            size="small"
            clearOnBlur={ false }
            disableClearable={ !selectedParameters?.selectedBoxType }
            onChange={ (_, value) => setSelectedParameters(prev => ({ ...prev, selectedBoxType: value })) }
            value={ selectedParameters?.selectedBoxType || { label: '', value: 0 } }
            renderInput={ (params) =>
              <TextField { ...params } label="Тип приемки" />
            } />

          <DialogActions sx={ { padding: '0' } }>
            <Button
              variant="text"
              onClick={ close }
            >
              ОТМЕНА
            </Button>
            { accept &&
              <LoadingButton
                variant="contained"
                onClick={ () => accept(uniqueValues.filteredWarehouse) }
                disabled={ !uniqueValues.filteredWarehouse }
              >
                Заменить
              </LoadingButton>
            }
          </DialogActions>
        </>
        :
        <div style={ { height: '316px' } }>
          <CircularProgressStyled />
        </div>
      }
    </>
  )
}