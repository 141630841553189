// @ts-nocheck
import {
  compose,
  map,
  split,
  join,
  prop,
  defaultTo,
  is,
  curry,
  zipObj,
  keys,
  values,
} from 'ramda'

// @ts-ignore
export const mapKeys = curry((fn, obj) =>
  // @ts-ignore
  zipObj(map(fn, keys(obj)), values(obj))
)

export const toSnake = (str) =>
  str.replace(/\./g, '__').replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`)

export const orderingToSnackCase = (obj) => {
  const ordering = compose(
    join(','),
    map(toSnake),
    split(','),
    // @ts-ignore
    defaultTo(''),
    prop('ordering')
    // @ts-ignore
  )(obj)

  if (ordering) {
    return { ...obj, ordering }
  }

  return obj
}

const toSnakeCase = (data) => {
  if (is(Array, data)) {
    return map(toSnakeCase, data)
  }

  if (is(Object, data)) {
    return compose(
      map(toSnakeCase),
      mapKeys(toSnake)
      // @ts-ignore
    )(data)
  }

  return data
}

export default toSnakeCase
