import { Box, ButtonGroup } from '@mui/material'
import React from 'react'
import BarChartIcon from '@mui/icons-material/BarChart'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'


interface Props {
  setDataParams: (e) => void
  dataParams: any
  chartUrl: string
}

enum Interval { Week, Month }
enum Units { Items, Currency }
export const ChartParams = ({ setDataParams, dataParams, chartUrl }: Props) => {
  
  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        return setDataParams(prev => ({ ...prev, range: n }))
      }
      case 1: {
        return setDataParams(prev => ({ ...prev, range: n }))
      }
    }
    return true
  }

  const unitsButtonClick = (n: Units) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        return setDataParams(prev => ({ ...prev,
          units: 'items',
        }))
      }
      case 1: {
        return setDataParams(prev => ({ ...prev,
          units: 'currency',
        }))
      }
    }
    return true
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {chartUrl &&
      <Box>
        <a
          style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}
          href={chartUrl}
          target="_blank" rel="noreferrer">
          <BarChartIcon sx={{ color: '#00000061' }}/>
        </a>
      </Box>
      }
      <ButtonGroup size="medium" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={dataParams.range === 0} onClick={() => rangeButtonClick(Interval.Week)}>Неделя</StyledButtonGroup>
        <StyledButtonGroup $active={dataParams.range === 1} onClick={() => rangeButtonClick(Interval.Month)}>Месяц</StyledButtonGroup>
      </ButtonGroup>
      <ButtonGroup sx={{ marginLeft: '24px' }} size="medium" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={dataParams.units === 'items'} onClick={() => unitsButtonClick(Units.Items)}>Штуки</StyledButtonGroup>
        <StyledButtonGroup
          $active={dataParams.units === 'currency'}
          onClick={() => unitsButtonClick(Units.Currency)}>
          Рубли
        </StyledButtonGroup>
      </ButtonGroup>
    </Box>
  )
}