import React from 'react'
import { Box, IconButton, Tooltip, Zoom } from '@mui/material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'

interface PreviewMediaBtnProps {
  handleBookmarkToggle?: () => void
  isActive?: boolean
  disabled?: boolean
}

export const BookmarkBtn = ({
  handleBookmarkToggle,
  isActive,
}: PreviewMediaBtnProps) => {
  const onClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    handleBookmarkToggle?.()
  }

  return (
    <Box
      sx={{ width: '28px', height: '28px', borderRadius: '50%', backgroundColor: 'white' }}
      className="bookmarkBtn"
      position="absolute"
      display={isActive ? 'flex' : 'none'}
      justifyContent="center"
      top="4px"
      left="4px">
      <Tooltip
        title={
          isActive
            ? 'Сделать частной только для данной номенклатуры'
            : 'Сделать общей для всех номенклатур'
        }
        TransitionComponent={Zoom}
      >
        <IconButton
          size="small"
          onClick={onClick}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {isActive ?
            <BookmarkIcon sx={{ color: '#2196F3' }} fontSize="small"/> :
            <BookmarkBorderIcon fontSize="small"/>}
        </IconButton>
      </Tooltip>
    </Box>
  )
}
