import { api } from 'shared/api/base'
import { useQuery } from 'react-query'

import { PackagingType } from './models'


type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/packagingtypes'] as const

const fetchPackagingTypes = ()  =>
  api.get<Array<PackagingType>>('/pim/packagingtypes').then((res) => res.data)


export const usePackagingTypesQuery = () => useQuery<
  Array<PackagingType>,
  errorType,
  Array<PackagingType>,
  QueryKey
>(QUERY_KEY, fetchPackagingTypes, { refetchOnMount: false })
