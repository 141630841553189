import { ExcelReportSettings } from 'shared/lib/exports/excel/types'

export const ExcelTemplate = <ExcelReportSettings>{
  columns: [
    { caption: 'Дата изменения', alignment: { horizontal: 'center' }, width: 20 },
    { caption: 'Название', width: 20 },
    { caption: 'Код', width: 20 }
  ],
  fileName: 'brands',
  sheetName: 'Список брендов'
}