import React, { useMemo } from 'react'
import { wmsGen } from 'shared/lib/generated'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'

export const AutocompleteCell = ({ name, elem, setInitialValues }) => {
  const { data: areaData } = wmsGen.area.GetAreaList.useGetAreaList({ query: { isStorage: true } })

  const locationOptions = useMemo(() =>
    areaData?.map(el => ({ value: el.id, label: el.location })) || [],
  [areaData])

  const handleChange = (_, value) => {
    setInitialValues(prev => prev.map(el => {
      if (el.id === elem.id) {
        return { ...el, [name]: value.value }
      }
      return el
    }))
  }

  return (
    <Autocomplete
      fullWidth={true}
      options={locationOptions}
      onChange={handleChange}
      disableClearable={true}
      value={locationOptions.find(el => el.value === elem[name]) || { value: 0, label: '' }}
      renderInput={(params) =>
        <TextField {...params}/>
      }
    />
  )
}