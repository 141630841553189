import { useQueryClient } from 'react-query'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  Box,
  DialogActions,
  Button, Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'
import React from 'react'

import { MemoGoodsSearchSection } from './GoodsSearchSection'

// import { validationSchema } from './validationSchema'
// import { serializeSubmitFormValuesToNewOzonProduct } from './serializer'

interface ProductCreateModalProps {
  close: () => void;
  accept: () => void;
  goodsId: string;
}

const MODAL_STYLES = { width: '600px', maxWidth: '600px' }

export const GoodsToPackModal = ({
  close,
  goodsId,
}: ProductCreateModalProps) => {
  const { mutate: updateGoodsToPack, isLoading } = pimApi.goods.useUpdateGoodsToPacktMutation()
  // const validate = useValidationSchema(validationSchema)
  const queryClient = useQueryClient()
  
  const onSubmit = (formValues) => {
    const updateGoodsToPackPayload = {
      goodsId,
      content: formValues.packGoods.map(({ quantity, componentId }) => ({ quantity, componentId }))
    }

    updateGoodsToPack(
      updateGoodsToPackPayload,
      {
        onSuccess: ({ data }) => {
          close()
          snackActions.info('Сохранено!')
          queryClient.setQueryData(
            getGetPimGoodsQueryKey(parseInt(goodsId, 10)),
            data
          )
        },
      }
    )
  }

  return (
    <Box p={3} pt={3} sx={MODAL_STYLES}>
      <Form
        onSubmit={onSubmit}
        // @ts-ignore
        mutators={{
          ...arrayMutators,
        }}
        // validate={validate}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" fontSize="20px" mb={3}>Преобразовать в спайку</Typography>
            <MemoGoodsSearchSection />
            <DialogActions sx={{ padding: '0' }}>
              <Box>
                <Button onClick={close} color="primary">
                  ОТМЕНА
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={isEmpty(values?.packGoods)}
                  sx={{
                    marginLeft: 2,
                  }}
                >
                  ПРОДОЛЖИТЬ
                </LoadingButton>
              </Box>
            </DialogActions>
          </form>
        )}
      />
    </Box>
  )
}
