import { api } from 'shared/api/base'
import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { Barcode, NewBarcode, EditBarcode } from '../barcodes'


interface createProductBarcodePayload {
  productId: UniqueId,
  barcode: NewBarcode
}

interface editProductBarcodePayload {
  productId: UniqueId,
  barcode: EditBarcode
}

interface deleteProductBarcodePayload {
  productId: UniqueId,
  barcode: DeletingObject
}

const createProductBarcode = ({ productId, barcode } : createProductBarcodePayload) =>
  api.post<Barcode>(`/pim/products/ozon/product/${productId}/barcodes`, barcode)
    .then((res) => res.data)

const editProductBarcode = ({ productId, barcode } : editProductBarcodePayload) =>
  api.put<Barcode>(`/pim/products/ozon/product/${productId}/barcodes/${barcode.id}`,barcode)
    .then((res) => res.data)

const deleteProductBarcode = ({ productId, barcode } : deleteProductBarcodePayload) =>
  api.delete(`/pim/products/ozon/product/${productId}/barcodes/${barcode.id}`,{ data: barcode })

export const useCreateProductBarcodeMutation =
  (options?: UseMutationOptions<Barcode,AxiosError<errorType>,createProductBarcodePayload>) =>
    useMutation<Barcode,AxiosError<errorType>,createProductBarcodePayload>
    ((createProductBarcodePayload)=>createProductBarcode(createProductBarcodePayload), options)

export const useEditProductBarcodeMutation =
  (options?: UseMutationOptions<Barcode,AxiosError<errorType>,editProductBarcodePayload>) =>
    useMutation<Barcode,AxiosError<errorType>,editProductBarcodePayload>
    ((editProductBarcodePayload)=>editProductBarcode(editProductBarcodePayload), options)

export const useDeleteProductBarcodeMutation =
  (options?: UseMutationOptions<AxiosResponse,AxiosError<errorType>,deleteProductBarcodePayload>) =>
    useMutation<AxiosResponse,AxiosError<errorType>,deleteProductBarcodePayload>
    ((deleteProductBarcodePayload)=>deleteProductBarcode(deleteProductBarcodePayload), options)
