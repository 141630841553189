/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const PhotoRequirements = () => {
  const Text = 'Формат — JPG, PNG, WEBP. Размер — не больше 10 Мбайт'
  const Element = (
    <StyledTextContainer>
      <ul>
        <li>Формат — JPG, PNG, WEBP</li>
        <li>Мин. разрешение — 700х900 px</li>
        <li>Качество сжатия — не ниже 65%</li>
        <li>Размер — до 10 Мбайт</li>
        <li>Количество — до 30 шт</li>
      </ul>
    </StyledTextContainer>
  )
  return { Text, Element }
}