import { useSSE } from 'react-hooks-sse'
import { useEffect } from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import {
  SupplyingImportErrorModal
} from 'features/stocks/goodssupplying/ui/SupplyingImportErrorModal/SupplyingImportErrorModal'
import { snackActions } from 'shared/lib/react/snackbar'

import { isNotNil } from '../../../../lib/checkers'
import { useOpenedStreamsContext } from '../context/OpenedStreamsContext'

export const ImportStream = () => {
  const { value: context } = useOpenedStreamsContext()
  const importSupplyingDone = useSSE<any | null>('importsupplyingdone', null)
  const importSupplyingClose = useSSE<null | object>('close', null)
  const importSupplyingError = useSSE<null | any>('error', null)

  useEffect(() => {
    if (isNotNil(importSupplyingClose)) {
      context.changeStreamState({ goodsSupplyingStream: false })
    }
  }, [importSupplyingClose])
  
  useEffect(() => {
    if (importSupplyingDone) {
      dialogModel.openDialog({
        component: ({ close }) => (
          <SupplyingImportErrorModal close={ close } response={ importSupplyingDone }/>
        )
      })
    }
  },[importSupplyingDone])

  useEffect(() => {
    if (isNotNil(importSupplyingError)) snackActions.error(importSupplyingError?.message)
  },[importSupplyingError])
  
  return null
}