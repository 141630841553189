import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PriceHistory } from 'features/product/priceHistory'
import { pimApi } from 'shared/api'
import { getServerReadableDateTime, toMaxDateTime, toMinDateTime } from 'shared/lib/utils'
import { PriceHistoryQueryProvider, PriceHistoryRecord } from 'features/product/priceHistory/model'
import { getPriceDiscount } from 'entities/pim/price/lib'

const currentDate = new Date()
const currentMinDate = toMinDateTime(currentDate)
const DEFAULT_MIN_DATE = Number(
  currentMinDate.setMonth(new Date().getMonth() - 1)
)

export const OzonPriceHistory = () => {
  const [fromDate, setFromDate] = useState<number>(DEFAULT_MIN_DATE)
  const [toDate, setToDate] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(productId)

  const ozonProductPriceHistoryQuery =
    pimApi.products.ozon.useFetchPriceHistoryQuery({
      productId,
      ...(fromDate ? { fromDate: getServerReadableDateTime(fromDate) } : {}),
      ...(toDate ? { toDate: getServerReadableDateTime(toDate) } : {}),
    })
  const priceHistory = useMemo(():PriceHistoryRecord[] => {
    if (ozonProductPriceHistoryQuery?.data === undefined) { return []}
    return ozonProductPriceHistoryQuery?.data.history.map(
      ({ price, oldPrice, ...rest }) => (
        {
          ...rest,
          price,
          discount: getPriceDiscount(price, oldPrice),
          priceBeforeDiscount: oldPrice,
        }
      )
    )
  }, [ozonProductPriceHistoryQuery])

  const { mutate: deleteProductPrice } = pimApi.products.ozon.useDeleteProductPriceMutation()
  const deleteFuturePriceQuery = ( priceId, versionNo, options ) =>
    deleteProductPrice(
      {
        productId,
        priceId,
        data: {
          id: priceId,
          versionNo,
        },
      }, options
    )

  const startPrice = ozonProductPriceHistoryQuery?.data?.startPrice
  const title = ozonProductQuery?.data?.name

  useEffect(() => {
    if (!toDate && ozonProductPriceHistoryQuery?.data && priceHistory?.length) {
      const maxItem =
          priceHistory[
            priceHistory.length - 1
          ]
      if (maxItem?.changeDate) {
        setToDate(
          Number(toMaxDateTime(maxItem.changeDate))
        )
      } else {
        setToDate(Number(toMaxDateTime(currentDate)))
      }
    }
  }, [ozonProductPriceHistoryQuery?.data])

  const handleTimeIntervalChange = useCallback((newTimeInterval) => {
    setFromDate(newTimeInterval[0])
    setToDate(newTimeInterval[1])
  }, [])

  return (
    <PriceHistoryQueryProvider priceHistoryQuery={ozonProductPriceHistoryQuery} deleteFuturePriceQuery={deleteFuturePriceQuery}>
      <PriceHistory
        data={priceHistory}
        title={title}
        isLoading={ozonProductPriceHistoryQuery.isLoading}
        startPrice={startPrice}
        fromDate={fromDate}
        toDate={toDate}
        onTimeIntervalChange={handleTimeIntervalChange}
      />
    </PriceHistoryQueryProvider>
  )
}
