/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyTemplateUpdateReviewTemplate, ReviewTemplate } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewTemplate>

export type IRequest = CamelCasedPropertiesDeep<{
  templateId: number,
  data: BodyTemplateUpdateReviewTemplate
}>

export const apiFunction = ({ templateId,data }: IRequest) =>
  api.put<ResponseType>(`/review/models/template/${templateId}`,data).then(res => res.data)


export const useUpdateReviewTemplate = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

