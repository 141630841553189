import type { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import type { JWTToken } from './models'

import { api } from '../base'

const BASE_URL = '/auth'

interface LoginBody {
    login: string;
    password: string;
    fingerprint: string;
    remember_me: boolean
}

interface SuLoginBody {
  login: string;
  password: string;
  customer_code: string;
  fingerprint: string;
  remember_me: boolean
}


export const sendPassword = (email) => api.post('/auth/forget_password', email)
export const SendPasswordMutation = () => useMutation<
  AxiosResponse,
  AxiosError<errorType>
  >(sendPassword)

const login = (body: LoginBody) => api.post<JWTToken>(`${BASE_URL}/login`, body)
export const useLoginMutation = () => useMutation<
  AxiosResponse<JWTToken>,
  AxiosError<errorType>,
  LoginBody
  >(login)

export const sulogin = (body: SuLoginBody) => api.post<JWTToken>(`${BASE_URL}/sulogin`, body)
export const useSuLoginMutation = () => useMutation<
  AxiosResponse<JWTToken>,
  AxiosError<errorType>,
  SuLoginBody
>(sulogin)

export interface RefreshTokenBody {
  fingerprint: string;
}
export const refreshToken = async (body: RefreshTokenBody) => api.post<JWTToken>(`${BASE_URL}/refresh_tokens`, body)

export const suRefreshToken = async ({ body, customerCode }: { body: RefreshTokenBody, customerCode: string }) =>
  api.post<JWTToken>(`${BASE_URL}/surefresh_tokens?customer_code=${customerCode}`, body)
export const useRefreshTokenMutation = () => useMutation<
  AxiosResponse<JWTToken>,
  AxiosError<errorType>,
  RefreshTokenBody
  >(refreshToken)

export const useSuRefreshTokenMutation = () => useMutation<
  AxiosResponse<JWTToken>,
  AxiosError<errorType>,
  {
    body: RefreshTokenBody,
    customerCode: string
  }
>(suRefreshToken)
export interface LogoutBody {
}
export const logout = async (body: LogoutBody) => api.post(`${BASE_URL}/logout`, body)


interface SetPassword {
    password: string;
    password_change_token: string;
}

export const setPassword = ( data: SetPassword) => api.post('/auth/set_password', data)

export const useSetPasswordMutation = () => useMutation<
  AxiosResponse,
  AxiosError<errorType>,
  SetPassword
  >(setPassword)
