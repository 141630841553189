import React from 'react'
import { SelectionState } from '@devexpress/dx-react-grid'
import { TableSelection as DXTableSelection } from '@devexpress/dx-react-grid-material-ui'
import { Products } from 'pages/product/productList'
import { pimApi } from 'shared/api'
import { availableFilters } from 'pages/analytics/charts/ui/ChartsTabs'

import { WbPriceHistory , OzonPriceHistory , SimplePriceHistory } from './marketplaces'
import { PlugMarketplacePage } from './plugMarketplace'

import { columnsConfig } from '../../OrdersAndSales/model/tableColumnsConfig'
import { useChartContext } from '../../../../lib/useChartContext'
import { ChartsPaper, StyledProducts } from '../../../../styled'
import { CustomSelectedComponent } from '../../../table/ui'

const MarketplaceIdToProductComponent: Record<number, any> = {
  1: WbPriceHistory,
  2: OzonPriceHistory
}

export const ElasticityOfDemandChart = () => {
  const { value: context } = useChartContext()
  const { searchObj,handleChartParams } = context


  const onSelChange = (sel) => {
    const row = sel[sel.length - 1]
    handleChartParams({
      productId: row
    })
  }

  const SelectionCellComponent = (props: DXTableSelection.RowProps) => (
    <CustomSelectedComponent oneSelected={!!searchObj.productId} props={props}/>)

  const commonProductQuery = searchObj.productId ?
    pimApi.products.common.useFetchCommonProductQuery(searchObj.productId) : null

  const Component =  MarketplaceIdToProductComponent[commonProductQuery?.data?.marketplaceId || 0] ?? SimplePriceHistory

  return (
    <ChartsPaper>
      { searchObj.productId && commonProductQuery?.data?.marketplaceId ?
        <Component/> :
        <PlugMarketplacePage/>
      }
      <StyledProducts>
        <Products
          showOrderStatus={true}
          columnsSettingsId="elasticity"
          columnsConfig={columnsConfig}
          filtersPresetsKey="chartsPresets"
          availableFilters={availableFilters}>
          <SelectionState
            selection={[searchObj.productId || 0]}
            onSelectionChange={onSelChange}
          />
          <DXTableSelection
            rowComponent={SelectionCellComponent}
            highlightRow={true} showSelectionColumn={false} selectByRowClick={true}/>
        </Products>
      </StyledProducts>
    </ChartsPaper>
  )
}