import React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import { useParams } from 'react-router-dom'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { pimApi } from 'shared/api'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { VideoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/WB/videoRequirements'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { useQueryClient } from 'react-query'
import { WbMediaTypes } from 'shared/types/MediaTypes'
import {
  CircularProgressStyled,
  CircularProgressWrap,
  ContentsWrapper
} from 'shared/ui/components/CircularProgress/CircularProgress'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { VideoItem } from './VideoItem'



const VIDEO_SUPPORTED_FILE_EXTENSION = ['.mp4', '.avi', '.webm', '.mpeg', '.mov']

interface VideosProps {
  videos?: any
  disabled?: boolean
}

export const Videos = ({ disabled, videos }: VideosProps) => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.products.wb.useValidateProductMediaFileMutation()
  const { mutate: mediaCreate, isLoading: mediaCreateIsLoading } =
    pimApi.products.wb.useCreateProductMediaMutation({
      onSuccess: (res) => {
        queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(pimApi.products.wb.getWBProductQueryKey(productId), (updater) => {
          const newData = { ...updater }
          newData.video = res.data
          return newData as pimApi.products.wb.WBNomenclature
        })
      }
    })
  const { mutate: deleteMediaMutation, isLoading: deleteMediaIsLoading } =
    pimApi.products.wb.useDeleteProductMediaMutation()

  const isQueriesInProgress =
    mediaCreateIsLoading ||
    deleteMediaIsLoading

  const onVideoValidate = (mediaType: WbMediaTypes) =>
    (file, onSuccess) => {
      const formFile = new FormData()
      formFile.append('file', file)
      
      validateMediaFileMutation({
        productId,
        mediaType,
        file: formFile,
      }).then(res => {
        if (res.status === 200) onSuccess(res)
      })
    }
  const onVideoUpload = (mediaType: WbMediaTypes) => 
    (files) => {
      if (files && isNotEmptyArray(files)) {

        files.forEach(el => {
          const file = new FormData()

          file.append('file', el.file || '')

          mediaCreate({
            file,
            productId,
            mediaType
          }
          )
        })
      }
    }

  const handleDelete = (deletingPhoto: DeletingObject) => {
    dialogModel.openDialog({
      component: MediaDeleteConfirmModal,
      onAccept: () => {
        deleteMediaMutation(
          { productId, mediaType: 'video', ...deletingPhoto },
          {
            onSuccess: () => {
              queryClient.setQueryData<pimApi.products.wb.WBNomenclature>(pimApi.products.wb.getWBProductQueryKey(productId), (updater) => {
                const newData = { ...updater }
                delete newData.video
                return newData as pimApi.products.wb.WBNomenclature
              }
              )
            }
          }
        )
      },
    })
  }

  const isVideoExists = !!videos

  const getModalFilePath = (mediaType: WbMediaTypes) =>
    (photoId, size) => pimApi.products.wb.getWBMediaApiPath(productId, photoId, mediaType, size)
  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          close={close}
          mediaVariation="video"
          mediaType="Видео"
          getFilePath={getModalFilePath('video')}
          clickedItem={item}
          items={[videos]}
        />
      )
    })
  }
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span style={{ fontWeight: '500', color: '#000000DE' }}>Видео</span>
        <span style={{ color: '#0000008A' }}>{ videos ? 1 : 0 }/1</span>
      </div>
      <ContentsWrapper>
        {isQueriesInProgress && (
          <CircularProgressWrap>
            <CircularProgressStyled />
          </CircularProgressWrap>
        )}
        <UploadGallery
          mediaLimit={1}
          withMainElement={true}
          largeContainer={true}
          localMedia={videos ? [videos] : []}
          mediaType="видео"
          onFileValidate={onVideoValidate('video')}
          supportedFileExtension={VIDEO_SUPPORTED_FILE_EXTENSION}
          onUploadFile={onVideoUpload('video')}
          mediaRequirement={VideoRequirements}
          addDisabled={isVideoExists || disabled }>
          {videos && 
         [videos].map((item) => 
           <VideoItem 
             key={ item?.id } 
             item={ item }
             openGallery={() => {handleOpenGallery(item)}}
             productId={ productId } 
             onFileDelete={handleDelete} 
             disabled={ disabled } 
           />
         )
          }
        </UploadGallery>
      </ContentsWrapper>
    </div>
  )
}
