import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { pimGen } from 'shared/lib/generated'
import CancelIcon from '@mui/icons-material/Cancel'
import { Autocomplete, Button, CircularProgress, DialogActions, InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Search } from '@mui/icons-material'
import debounce from 'lodash/debounce'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isSameValues } from 'shared/lib/checkers'

import { DialogTitleWithBtn } from '../../../components'


const ModalPage = styled.div`
  width: 600px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    .tag-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      background-color: #EEEEEE;
      padding: 0 5px 0 8px;

      > span {
        font-size: 13px;
      }

      svg {
        cursor: pointer;
        font-size: 16px;
        color: #BDBDBD;
      }
    }
  }
  
  .MuiInputBase-adornedEnd {
    padding-right: 12px !important;
  }
`

export const EditTagsModal = ({ close, accept, objectId }) => {
  const [ tagsList, setTagsList ] = useState<Array<string>>([])
  const [ searchString, setSearchString ] = useState<string>('')

  const debouncedGetTags = debounce((value) => setSearchString(value), 200)

  const limitsReached = useMemo(() => ({
    searchString: searchString.length > 15,
    tagsList: tagsList.length > 15
  }), [searchString, tagsList])

  const { data: tagsData, isLoading, isFetching } =
    pimGen.tags.GetTags.useGetTags({ query: { searchString } })
  const { data: objectTagsData } = pimGen.tags.GetObjectTags.useGetObjectTags({ objectId })

  const options = useMemo(() => {
    if (tagsData && !limitsReached.tagsList && !limitsReached.searchString) {
      if (!tagsData.map(tag => tag.name).includes(searchString) && searchString !== '') {
        return [
          ...tagsData.map(el => ({ value: el.name, label: el.name })),
          { label: `Добавить "${ searchString }"`, value: `${ searchString }` }
        ]
      } 
      return [
        ...tagsData.map(el => ({ value: el.name, label: el.name })),
      ]
      
    }
    return []
  }, [tagsData, searchString])

  useEffect(() => {
    if (objectTagsData && isNotEmptyArray(objectTagsData)) {
      setTagsList(objectTagsData.map(tag => tag.tagsName))
    }
  }, [objectTagsData])
  
  const acceptDisabled = useMemo(() => {
    if (objectTagsData) {
      return isSameValues(
        objectTagsData.map(el => el.tagsName).sort(),
        tagsList.sort()
      )
    }
    return true
  },[objectTagsData, tagsList])


  return (
    <ModalPage>
      <DialogTitleWithBtn onClose={ close } sx={ { fontSize: '20px', fontWeight: 500, padding: 0 } }>
        Редактировать метки
      </DialogTitleWithBtn>

      <Autocomplete
        size="small"
        value={ [...tagsList.map(el => ({ value: el, label: el }))] }
        isOptionEqualToValue={ isOptionEqual }
        onChange={ (_, value) => {
          setTagsList(value.map(el => el.value))
          setSearchString('')
        }}
        filterOptions={() => options}
        multiple={ true }
        clearOnBlur={false}
        options={options}
        renderTags={() => null}
        renderInput={ (params) =>
          <TextField
            { ...params }
            error={limitsReached.searchString}
            onChange={(e) => debouncedGetTags(e.target.value)}
            placeholder="Поиск"
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  { (isLoading || isFetching) && <CircularProgress size={ 20 }/> }
                  <Search/>
                </InputAdornment>
              ),
            } }
          />
        }
      />

      {isNotEmptyArray(tagsList) && (
        <div className="tag-wrapper">
          { tagsList.map(tag => (
            <div className="tag-item" key={ tag }>
              <span>{ tag }</span>
              <CancelIcon onClick={ () => setTagsList(prev => prev.filter(el => el !== tag)) }/>
            </div>
          )) }
        </div>
      ) }

      <DialogActions sx={ { padding: '0' } }>
        <Button
          variant="text"
          onClick={ close }
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          disabled={acceptDisabled}
          onClick={ () => accept({
            tagsList: tagsList.sort(),
            objectTagsData: objectTagsData?.map(el => el.tagsName) })?.sort() }
        >
          Сохранить
        </Button>
      </DialogActions>
    </ModalPage>
  )
}