import { CalculatedReceiptDocPosition } from 'domains/doc'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNotNil } from 'shared/lib/checkers'
import { PhotoCell } from 'shared/ui/components/Table/ui/TableCells'

import { NameCell } from './CustomCells/NameCell'
import { columnNames } from './ColumnsConfig'
import { DeleteBtn } from './CustomCells/DeleteBtn'
import { Difference } from './styled'

const getDifferenceCellValue = (value: number) => {
  if (value === 0) {
    return ' '
  }
  if (value > 0) {
    return `+${value}`
  }
  return value
}

const getImgUrl = (id: number, goodsBaseImageId?: number) => {
  if (isNotNil(goodsBaseImageId)) {
    return  `/wms/goods/${id}/image/${goodsBaseImageId}?max_height=50&max_width=50`
  }
  return undefined
}

export const getCell = (
  name: string,
  row: CalculatedReceiptDocPosition,
  value: string | number,
  handleOpenDialog: (data: CalculatedReceiptDocPosition) => void,
) => {
  switch (name) {
    case columnNames.goodsBaseImageId : {
      const onClick = () => {
        handleOpenDialog(row)
      }
      return (
        <PhotoCell
          apiUrl={getImgUrl(row.goodsId, row.goodsBaseImageId)}
          onClick={onClick}
        />
      )
    }
      

    case columnNames.goodsName :
      return (
        <NameCell
          goodsName={row.goodsName}
          rowId={row.id}
          isEmptyAcceptanceList={isEmptyArray(row.acceptance)}
        />
      )

    case columnNames.difference : {
      const cellValue = getDifferenceCellValue(value as number)
      return (
        <Difference>
          {cellValue}
          {row?.newPosition && (
            <DeleteBtn posId={row.id} versionNo={row.versionNo} goodsName={row.goodsName} />
          )}
        </Difference>
      )
    }

    case columnNames.sumDefective :
    case columnNames.sumFact :
    case columnNames.quantity :
      if (value === 0) {
        return ' '
      }
      return undefined

    default:
      return undefined
  }
}
