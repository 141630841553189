import { Attribute } from 'shared/api/pim/goods'
import { getGoodsAttributeComponent } from 'entities/pim/GoodsAttributes'
import { isNotNil } from 'shared/lib/checkers'
import { Field } from 'shared/ui/components/form'

export const LeftComponent = ({
  attribute
}: {
  attribute: Attribute
}) => {
  const leftComponent = getGoodsAttributeComponent({
    ...attribute,
    catalog: isNotNil(attribute.dictionaryId) ? `pim/dictionaries/${attribute.dictionaryId}` : undefined,
  })


  return (<Field
    name="fake"
    component={leftComponent.Component}
    {...leftComponent.props}
    label={attribute.name}
    value={attribute.value}
    disabled={true}
  />
  )
}