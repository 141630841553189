import { Edit } from '@mui/icons-material'
import styled from 'styled-components'
import { ListItem, Typography } from '@mui/material'

export const DeleteIcon = styled(Edit)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 24px;
  margin-top: 2px;
`

export const PrivilegeName = styled.span`
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden
`

export const ReportName = styled(Typography)<{ step: number }>`
  font-size: 14px;
  font-weight: 400;
  min-width: ${({ step }) => `${ 665 - (16 * (step - 1)) }px`}
`

export const StyledFileItem = styled(ListItem)<{ step: number }>`
  display: flex;
  height: 50px;
  padding-left:  ${({ step }) => `${(16 * step) + 4}px`}
`

export const StyledFolderItem = styled(ListItem)<{ step: number }>`
  height: 50px;
  padding-left:  ${({ step }) => `${ 16 * step }px`};
  
  .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 4px;
  }
  
  .MuiTypography-root {
    font-size: 16px;
    font-weight: 500;
  }
`