import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Notification } from 'shared/lib/generated/notifications/Api'

interface IUseNotificationsStore {
  webSocket: any,
  setWebSocket: (newWebSocket: any) => void
  messages: Notification[]
  setMessage: (messages: Notification[]) => void
  sendMessage: (message: any) => void
  showMessage: boolean
  setShowMessage: (show: boolean) => void
  switchShowMessage: () => void
}

export const useNotificationsStore = create<IUseNotificationsStore>()(devtools((set, get) => {
  const setMessage = (messages: Notification[]) => set({ messages })

  const sendMessage = (message: any) => {
    const ws = get().webSocket
    if (ws && ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message))
    }
  }

  return {
    messages: [],
    setWebSocket: (ws) => set(() => ({ webSocket: ws })),
    setMessage,
    sendMessage,
    webSocket: null,
    showMessage: false,
    setShowMessage: (show) => set({ showMessage: show }),
    switchShowMessage: () => set(({ showMessage }) => ({ showMessage: !showMessage })),
  }
}))