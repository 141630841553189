import { call, put } from 'redux-saga/effects'
import keyMirror from 'keymirror-nested'
import { ITnvedItem } from 'shared/services/interfaces/tnved'
import watcherCreator from 'store/watcherCreator'
import { actionsCreator, actionType } from 'store/actionsCreator'
import { getErrorType } from 'shared/lib/transform/getErrorType'
import { AxiosError } from 'axios'
import { fetchTnved } from 'shared/api/pim/dictionaries'

export interface ITnvedState {
  list: ITnvedItem[] | null
  error: errorType | null
}

export type tnvedActionType = actionType<ITnvedItem[] | string, errorType>

export const TNVED = keyMirror(
  {
    LIST: null,
  },
  '_',
  'TNVED'
)

export const tnvedList = actionsCreator(TNVED.LIST)
const tnvedListClearType = 'TNVED_LIST_CLEAR'
export const tnvedListClear = {
  type: tnvedListClearType,
  data: [],
}

const initialState = {
  list: null,
  error: null,
}

export const tnvedReducer = (
  state = initialState,
  action: tnvedActionType
): ITnvedState => {
  switch (action.type) {
    case tnvedList.getType('success'):
      return { ...state, list: <ITnvedItem[]>action.data }
    case tnvedList.getType('error'):
      return { ...state, error: <errorType>action.error }
    case tnvedListClearType:
      return { ...state, list: [] }
    default:
      return state
  }
}

export const tnvedWatcher = watcherCreator(
  'TNVED',
  function* tnvedWorker(
    { type, data }: tnvedActionType
  ) {
    if (type === tnvedList.getType('pending')) {
      try {
        const context = { fetchTnved }
        const result: SagaCall<typeof fetchTnved> = yield call<
          typeof fetchTnved
        >(context.fetchTnved, data as string)
        yield put(tnvedList.success(result.data))
      } catch (error) {
        yield put(tnvedList.error(getErrorType(error as AxiosError)))
      }
    }
  }
)
