import { Storage } from 'domains/storage'

let store: Record<string, string> = {

}

export const memoryStore = new Storage({
  get: (key: string) => store[key],
  set: (key: string, value: string) => {
    store[key] = value
  },
  rm: (key: string) => {
    delete store[key]
  },
  clear: () => {
    store = {}
  }
})
