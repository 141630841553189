import { Box, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { isNotNil } from 'shared/lib/checkers'
import { Message } from 'pages/supplyOrders/edit/ui/Settings/ui/AddSupplyOrderByFileModalContent/styled'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { StyledLink } from 'widgets/product/bulkOperations/BulkOperationsResultModal/styled'

const MODAL_STYLES = { width: '480px', overflowX: 'hidden' }
const SX_TITLE = { fontSize: '20px', minWidth: '480px', padding: '0', marginBottom: '24px' }

const statusIcons = {
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  error: <InfoOutlinedIcon />,
}

interface SupplyingImportErrorModalProps {
  close: () => void
  response: any
  methodName: string
}
export const StocksImportModal = ({ close, methodName, response }: SupplyingImportErrorModalProps) => {
  const { messages, fileUrl } = response
  return (
    <Box p={3} sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
        {methodName}
      </DialogTitleWithBtn>
      <Box>
        {isNotNil(messages) && 
          <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {messages.map((message) => (
              <Message
                key={message.text}
                messageType={message.type}
                px={2}
                py={1.5}
              >
                {statusIcons[message.type]}
                <Typography variant="body2" component="span">
                  {message.text}
                </Typography>
              </Message>
            ))}
          </Box>
        }
        {fileUrl &&
        <Box>
          <StyledLink href={fileUrl}>
            Скачать файл с результатом загрузки
          </StyledLink>
        </Box>
        }
      </Box>
    </Box>
  )
}