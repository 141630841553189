export const subStr = (string: string, length: number) => {
  if (string && string.length > length) {
    return `${string.substring(0, length)}...`
  }
  return string
}

export function paginationList<V>(list: Array<V>, page: number, rowsPerPage: number) {
  return list.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
}
