/* eslint-disable react/destructuring-assignment */
import React, { useCallback } from 'react'
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  closestCenter,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { ChipStyled } from './styled'

interface ISortableChips {
  handleChange: any
  tagValue: any
  handleDeleteChip: any
  getTagProps: any
}
export const SortableChips = ({ handleChange, tagValue, handleDeleteChip, getTagProps }: ISortableChips) => {
  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  }), useSensor(TouchSensor))

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event
    if (active && over && active.id.label !== over.id.label) {
      const oldIndex = tagValue.indexOf(active.id)
      const newIndex = tagValue.indexOf(over.id)
      handleChange(null,arrayMove(tagValue, oldIndex, newIndex))
    }
  },[tagValue])

  const SortableItem = (props) => {
    const { index } = props
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({ id: props.id })

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    }

    return (
      <div ref={setNodeRef} style={style} {...props} {...attributes} {...listeners}>
        <ChipStyled
          {...getTagProps({ index })}
          key={props.id.label}
          label={props.id.label}
          size="small"
          onDelete={() => handleDeleteChip(tagValue, props.index)}
          obsolete={props.id?.obsolete}
        />
      </div>
    )
  }
  
  return (
    <DndContext
      key={JSON.stringify(tagValue)}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={tagValue} strategy={rectSortingStrategy}>
        {tagValue.map((id, index) => (
          <SortableItem key={id.label} id={id} index={index}/>
        ))}
      </SortableContext>
    </DndContext>
  )
}