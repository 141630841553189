/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import queryString from 'query-string'

import axios from 'axios'
import { toSnakeCase } from 'shared/lib/transform'

export type ResponseType = CamelCasedPropertiesDeep<any[]>

export type IRequest = CamelCasedPropertiesDeep<{
  clientId: string,
  query?: {
  /**
   * Search String
   * Подстрока поиска
   * @default ""
   */
    search_string?: string,
  /** ID Маркетплейса */
    marketplace_id?: number,
  /** ID Поставщика */
    contragent_id?: number,
  /**
   * Дата последнего изменения
   * @format date
   */
    changed?: string,
  /** ID бренда */
    brand_ids?: number[],
  /**
   * Формат ответа: JSON или YML
   * @default "YML"
   */
    response_format?: "JSON" | "YML",
  /**
   * Номер страницы p > 0
   * @min 0
   */
    page?: number,
  /**
   * Количество элементов на странице
   * @min 0
   * @max 1000
   */
    page_size?: number,

}
}>

export const apiFunction = async ({ clientId,query }: IRequest) => {
    return axios.get(
      `/yml/goods/${clientId}`,
      {
        params: { ...query, response_format: 'JSON'
        },
        paramsSerializer: (params) => queryString.stringify(toSnakeCase(params), { skipNull: true, skipEmptyString: true }),
      }
    ).then(res => res.data)
  }


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ clientId,query }: IRequest) => ['get', `/yml/goods/${clientId}`, { clientId,query }] as const

export const useGetGoods = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

