import { useParams } from 'react-router-dom'
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { ArrowForward, OpenInNew } from '@mui/icons-material'
import { Field, InputField, MaskedInputField } from 'shared/ui/components/form'
import { PriceChange } from 'features/product/WB/PriceChange'
import { pimApi } from 'shared/api'
import { ProductHeader } from 'shared/ui/product/ProductHeader'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { ProductStatus } from 'features/product/productStatus'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useEffect } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useQueryClient } from 'react-query'
import { isNotNil } from 'shared/lib/checkers'
import { toCamelCase } from 'shared/lib/transform'
import { WbNomenclatureUpdateData } from 'shared/api/pim/products/models'
import { useFormState } from 'react-final-form'

import { Variations } from '../../Variations'
import { GridWithArrow, InputArrow } from '../../styled'
import { Wrapper } from '../../../../styled'

export const NomenclatureTab = () => {
  const queryClient = useQueryClient()
  const goods = useGoodsContext()
  const { id } = useParams<{ id: string }>()
  const { wbProductQuery } = useWBProductContext()
  const { commonProductQuery } = useCommonProductContext()

  const cardStatusStream = useSSE<WbNomenclatureUpdateData | null>
  ('wbnomenclatureupdate', null, { parser: (data) => toCamelCase(JSON.parse(data)) })

  const { initialValues } = useFormState({ subscription: { initialValues: true } })
  const { nmAttributeId, nmAttribute, marketplaceUrl, photos, variations } = initialValues

  const nmidFieldReadonly = !!wbProductQuery?.data?.nmid

  const handleNmIdClick = () => {
    if (marketplaceUrl) window.open(marketplaceUrl, '_blank')?.focus()
  }

  const goodsQuery = pimApi.goods.useFetchGoodsQuery(
    commonProductQuery?.data?.goodsId!,
    true
  )

  useEffect(() => {
    if (isNotNil(cardStatusStream)) {
      if (Object.keys(cardStatusStream).find(el =>
        JSON.stringify(cardStatusStream[el]) !== JSON.stringify(wbProductQuery?.data?.[el]))) {
        queryClient.setQueryData(pimApi.products.wb.getWBProductQueryKey(Number(id)), (updater: any) => ({
          ...updater, ...cardStatusStream
        }))
      }
    }
  }, [cardStatusStream])

  const selectedColor = goodsQuery.data?.attributes?.find(
    (attribute) => attribute.attributeId === nmAttributeId
  )?.value

  const ecomColor: string = Array.isArray(selectedColor)
    ? selectedColor[0] ?? ''
    : selectedColor ?? ''

  const archivedVariations = variations.filter(
    (variation) => variation?.inArchive
  )
  const isArchived = archivedVariations.length === variations.length

  return (
    <Wrapper>
      <Grid container={true} pb={1}>
        <Grid item={true} xs={12}>
          <ProductHeader
            goods={goods}
            title={wbProductQuery.data?.name}
            archivationReason={wbProductQuery.data?.variations[0].archivationreason}
            archivationComments={wbProductQuery.data?.variations[0].archivationComments}
            photos={photos}
            isArchived={isArchived}
          />
        </Grid>
        <Grid item={true} xs={3} mb={3} mr={2}>
          <ProductStatus
            cardStatus={wbProductQuery?.data?.cardStatus ?? {}}
            syncStatus={wbProductQuery?.data?.syncStatus ?? {}}
          />
        </Grid>
        <Grid item={true} xs={7} mb={2}>
          <PriceChange readOnly={isArchived}/>
        </Grid>
        <Grid item={true} xs={3.2} pr={2}>
          <Field
            key="nmid"
            name="nmid"
            label="Номенклатура"
            component={MaskedInputField}
            readOnly={true}
            disabled={isArchived}
            endAdornment={
              marketplaceUrl && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="link"
                    aria-haspopup="true"
                    title="Ссылка на Wildberries"
                    size="small"
                    onClick={handleNmIdClick}
                    edge="end"
                  >
                    <OpenInNew color="info" />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </Grid>
        <Grid item={true} width="224px">
          <Field
            name="vendorCode"
            label="Артикул товара"
            component={InputField}
            readOnly={nmidFieldReadonly}
            disabled={isArchived}
          />
        </Grid>
        {nmAttributeId && (
          <GridWithArrow item={true} xs={3}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              disabled={true}
              value={ecomColor}
              label={nmAttribute}
            />
            <InputArrow>
              <ArrowForward />
            </InputArrow>
          </GridWithArrow>
        )}
      </Grid>
      <Variations isArchived={isArchived}/>
    </Wrapper>
  )
}
