import { FC, useMemo, useState } from 'react'
import { getGoodsAttributeComponent } from 'entities/pim/GoodsAttributes'
import { noop } from 'shared/lib/lodash/noop'

import { AutocompleteDictionaryAttributeFieldMemo } from './AutocompleteDictinaryAttributeField'
import { WrapperRightComponent } from './styled'

import { getLabelPreparedWBAttribute } from '../../lib'
import { WBMapingAttribute } from '../../lib/getNormalizedAttributes'

interface RightComponentProps {
  right: WBMapingAttribute,
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => void,
  disabled?: boolean
}

const emptyObject = {}

type CorrectValueResult<R extends boolean = boolean> = R extends true ? Array<string | number> : string  | number

function getCorrectValue<R extends boolean>(
  multiple: R,
  value: Array<string> | string | number = ''
): CorrectValueResult<R>  {
  const isActualArray = Array.isArray(value)
  if (multiple) {
    if(isActualArray) {
      return value as CorrectValueResult<typeof multiple>
    }
    if (value) {
      return [value] as CorrectValueResult<typeof multiple>
    }
  }

  return (isActualArray ? '' : value) as CorrectValueResult<typeof multiple>
}

export const RightComponent: FC<RightComponentProps> = ({
  right,
  onChange,
  disabled
}) => {
  const { multipleValues, useOnlyDictionaryValues } = right

  const label = getLabelPreparedWBAttribute(right)

  const value = getCorrectValue(multipleValues, right.values)

  const [inputValue, setValue] = useState(value || '')
  const InputChange = (event) => {
    setValue(event.target.value)
  }

  if (right.fetchCatalog && right.multipleValues) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const input = useMemo(() => (
      {
        name: 'fake',
        value: value as string | Array<string>,
        multiple: multipleValues,
        onBlur: noop,
        onChange: (selectedValue: string | Array<string>) => onChange({
          // AutocompleteDictionaryAttributeField отдает только значение без обьекта события
          // из-за этого нужно имулировать обьект события
          // @ts-ignore
          target: { value: selectedValue }
        }),
        onFocus: noop,
      }
    ),[value])

    return (
      <WrapperRightComponent $required={right.required}>
        <AutocompleteDictionaryAttributeFieldMemo
          fetchCatalog={right.fetchCatalog}
          input={input}
          meta={emptyObject}
          label={label}
          // @ts-ignore
          maxCount={right.maxCount}
          useOnlyDictionaryValues={useOnlyDictionaryValues}
          disabled={disabled}
        />
      </WrapperRightComponent>
    )
  }

  const rightComponent = getGoodsAttributeComponent({
    multipleValues,
    attributeDataType: 'string',
  })

  return (
    <WrapperRightComponent $required={right.required}>
      <rightComponent.Component
        {...rightComponent.props}
        input={{
          name: right.attributeName,
          value: inputValue,
          onChange: InputChange,
          onFocus: noop,
          onBlur: onChange,
        }}
        disabled={disabled}
        label={label}
      />
    </WrapperRightComponent>
  )
}
