import * as yup from 'yup'

const ATTRIBUTES_SCHENA = yup.array().of(
  yup.object().shape({
    value: yup
      .mixed()
      .when('multipleValues', {
        is: true,
        then: yup.array().of(yup.string().nullable(true)),
        otherwise: yup.string().nullable(true)
      })
      .strict()
  })
)

export const SCHEMA = yup.object().shape({
  attributes: ATTRIBUTES_SCHENA,
  groups: yup.array().of(
    yup.object().shape({
      attributes: ATTRIBUTES_SCHENA
    }))
})
