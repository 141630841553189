import { Box, Grid, TextField } from '@mui/material'
import React from 'react'
import { useField } from 'react-final-form'
import {
  CatalogSelectField,
  Field,
  InputField,
} from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { pimApi } from 'shared/api'


interface SizesFormProps {
  goods: pimApi.goods.Goods | null
  disabled?: boolean
  showGoodsInfo: boolean
}

export const SizesForm: React.FC<SizesFormProps> = ({ goods, disabled, showGoodsInfo }) => {
  const productWidth = useField('width').input.value
  const productHeight = useField('height').input.value
  const productDepth = useField('depth').input.value
  const productWeight = useField('weight').input.value
  const isShowSizes =
    productDepth || productHeight || productWeight || productWidth

  return (
    <>
      {isShowSizes && (
        <InputsWrapper hideGoodsInfo={!showGoodsInfo} globalAttributeName="sizeinfoGlobal">
          <Box>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                {productWidth && (
                  <TextField
                    label="Ширина, см"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={goods?.width ?? ''}
                    disabled={true}
                  />
                )}
              </Grid>
              <Grid item={true} xs={6}>
                {productHeight && (
                  <TextField
                    label="Высота, см"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={goods?.height ?? ''}
                    disabled={true}
                  />
                )}
              </Grid>
              <Grid item={true} xs={6}>
                {productDepth && (
                  <TextField
                    label="Глубина, см"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={goods?.depth ?? ''}
                    disabled={true}
                  />
                )}
              </Grid>
              <Grid item={true} xs={6}>
                {productWeight && (
                  <TextField
                    label="Вес с упаковкой, кг"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={goods?.weight ?? ''}
                    disabled={true}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid item={true} container={true} spacing={2}>
            <Grid item={true} xs={6}>
              {productWidth && (
                <Field
                  name="width.value"
                  label={`${productWidth.label}`}
                  component={InputField}
                  disabled={disabled}
                  type="number"
                  errorAfterTouch={true}
                />
              )}
            </Grid>
            <Grid item={true} xs={6}>
              {productHeight && (
                <Field
                  name="height.value"
                  label={`${productHeight.label}`}
                  component={InputField}
                  disabled={disabled}
                  type="number"
                  errorAfterTouch={true}
                />
              )}
            </Grid>
            <Grid item={true} xs={6}>
              {productDepth && (
                <Field
                  name="depth.value"
                  label={`${productDepth.label}`}
                  component={InputField}
                  disabled={disabled}
                  type="number"
                  errorAfterTouch={true}
                />
              )}
            </Grid>
            <Grid item={true} xs={6}>
              {productWeight && (
                <Field
                  name="weight.value"
                  label={productWeight.label}
                  component={InputField}
                  disabled={disabled}
                  type="number"
                  errorAfterTouch={true}
                />
              )}
            </Grid>
          </Grid>
        </InputsWrapper>
      )}

      <InputsWrapper hideGoodsInfo={!showGoodsInfo} arrow={false}>
        <></>
        <Field
          name="basePackagingrequirement"
          component={InputField}
          label="Требования маркетплейса по упаковке"
          disabled={true}
        />
      </InputsWrapper>

      <InputsWrapper hideGoodsInfo={!showGoodsInfo} arrow={false} tooltipIconSx={{ height: 40 }}>
        <></>
        <Field
          name="packagingrequirementId"
          component={CatalogSelectField}
          catalog="pim/packagingrequirements"
          label="Упаковка*"
          errorAfterTouch={true}
          disabled={disabled}
        />
      </InputsWrapper>
    </>
  )
}
