import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
  WarningTwoTone,
  SyncOutlined,
  SvgIconComponent,
} from '@mui/icons-material'
import { Theme, AlertColor } from '@mui/material'
import { pimApi } from 'shared/api'

export type MarketplaceId = 1 | 2

interface ProductStatus {
  icon: SvgIconComponent,
  type: AlertColor
}

export const FromMarketplaceIdToName = {
  1: 'WB',
  2: 'OZON'
}

const STATUS_NAME = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const

export enum ProductsOrderStatus {
  DECREASED = 'Падение заказов',
  INCREASED = 'Рост заказов'
}

export const PRODUCT_STATUS_LIST: Record<pimApi.products.StatusType, ProductStatus> = {
  error: {
    icon: ErrorOutline,
    type: STATUS_NAME.error,
  },
  idle: {
    icon: InfoOutlined,
    type: STATUS_NAME.info,
  },
  success: {
    icon: CheckCircleOutline,
    type: STATUS_NAME.success,
  },
  warning: {
    icon: WarningTwoTone,
    type: STATUS_NAME.warning,
  },
  info: {
    icon: InfoOutlined,
    type: STATUS_NAME.info,
  },
  'info-sync': {
    icon: SyncOutlined,
    type: STATUS_NAME.info,
  },
} as const

export const getStatusTextColor = (theme: Theme, status: pimApi.products.StatusType, $isArchived?: boolean ) => {
  if($isArchived) return '#a4a4a4'
  switch (status) {
    case STATUS_NAME.error:
      return theme.palette.error.main

    case STATUS_NAME.success:
      return theme.palette.success.main

    case STATUS_NAME.warning:
      return theme.palette.warning.main

    case 'idle':
      return theme.palette.text.disabled

    case 'info-sync':
    case STATUS_NAME.info:
      return theme.palette.info.main

    default:
      return theme.palette.text.primary
  }
}