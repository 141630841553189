import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

import { StyledButton } from './styled'

const SortingIcon = ({ direction }) =>
  direction === 'asc' ? (
    <ArrowUpward style={{ fontSize: '18px' }} />
  ) : (
    <ArrowDownward style={{ fontSize: '18px' }} />
  )

export const SortLabel = ({ onSort, children, direction, column }) => (
  <>
    {children}
    {column?.sortingEnabled && (
      <StyledButton
        aria-haspopup="true"
        aria-label="sort"
        id="long-button"
        onClick={onSort}
        $alwaysshow={!!direction}
      >
        <SortingIcon direction={direction} />
      </StyledButton>
    )}
    
  </>
  
)
