import { yupTransformEmptyStringInNull } from 'shared/lib/utils'
import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  ownerId: yup.number().required().transform(yupTransformEmptyStringInNull),
  recipientId: yup.number().required().transform(yupTransformEmptyStringInNull),
  supplierId: yup.number().required().transform(yupTransformEmptyStringInNull),
  docDate: yup.string().required().transform(yupTransformEmptyStringInNull),
  supplierDocDate: yup.string().required().transform(yupTransformEmptyStringInNull),
  docNumber: yup.string().required().transform(yupTransformEmptyStringInNull),
  supplierDocNumber: yup.string().required().transform(yupTransformEmptyStringInNull),
})