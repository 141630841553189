import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormState } from 'react-final-form'
import { isSameValues } from 'shared/lib/checkers'

import { FormValuesToEditSimpleProductComparatorSerializer } from '../lib/EditSimpleProductChangesComparatorSerializer'
import { FixedFormContainer } from '../../WB/ui/styled'

export const SaveForm = ({ editSimpleProductInProgress, isArchived, isDeleteProductLoading, handleDelete }) => {
  const { values, initialValues } = useFormState({ subscription: { values: true, initialValues: true } })
  const hasChanges = !isSameValues(
    FormValuesToEditSimpleProductComparatorSerializer(initialValues as any),
    FormValuesToEditSimpleProductComparatorSerializer(values as any)
  )

  const handleLeave = (event: BeforeUnloadEvent) => {
    if (hasChanges) {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
      return 'Вы уверены, что хотите покинуть страницу? Все несохранненые изменения будут утеряны.'
    }
    return undefined
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleLeave)
    return () => window.removeEventListener('beforeunload', handleLeave)
  }, [hasChanges])
  return (
    <FixedFormContainer>
      <Box ml="auto">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={ editSimpleProductInProgress }
          disabled={ !hasChanges || isArchived }
        >
          СОХРАНИТЬ
        </LoadingButton>
      </Box>
      <Box ml={ 2 }>
        <LoadingButton
          variant="contained"
          loading={ isDeleteProductLoading }
          onClick={ handleDelete }
          color="error"
          disabled={ isArchived }
        >
          УДАЛИТЬ ЧЕРНОВИК
        </LoadingButton>
      </Box>
    </FixedFormContainer>
  )
}