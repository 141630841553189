import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }

  .TableCell-cell {
    height: 50px;
  }

  .Toolbar-toolbar {
    justify-content: space-between;
  }

  .TableContainer-root {
    border-radius: 4px;
  }

  table {
    thead tr th {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }

    tbody::after {
      content: "";
      display: block;
      height: 50px;
    }
  }

`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const TableWrapper = styled.div`
  /* margin-top: -35px; */
`