import { Box, Button, DialogActions } from '@mui/material'
import * as yup from 'yup'
import { Form } from 'react-final-form'
import * as GoodsService from 'shared/api/wms/GoodsService'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { isNotNil } from 'shared/lib/checkers'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

import { StyledTitleText } from './styled'
import { FormFields } from './components/FormFields'
import { FormValuesType } from './types'

interface NewProductProps {
  onClose: () => void;
  docId: number;
}

const validationSchema = yup.object().shape({
  reason: yup.string().required(),
  supplierCode: yup.string().required(),
  quantity: yup.number().integer().min(1).required().transform(yupTransformEmptyStringInNull),
  comments: yup.string(),
  goodsId: yup.number().transform(yupTransformEmptyStringInNull),
})

const initialValues = {
  supplierCode: '',
  goodsId: undefined,
  reason: '',
  comments: undefined,
  quantity: '',
}

export const NewProduct = ({ onClose, docId }: NewProductProps) => {
  const { mutate: createPosition, isLoading: createLoading } = GoodsService.useCreatePosition()
  const validate = useValidationSchema(validationSchema)

  const onSubmit = (
    { reason, comments, quantity, supplierCode, goodsId }: FormValuesType
  ) => {
    const body = {
      reason,
      goodsId: isNotNil(goodsId) ? +goodsId : undefined,
      comments: comments || undefined,
      quantity: +quantity,
      supplierCode,
    }

    createPosition(
      { docId, body },
      { onSuccess: onClose }
    )
  }

  return (
    <Box pt={1}>
      <DialogTitleWithBtn onClose={onClose}>
        <StyledTitleText>Добавить товар в документ</StyledTitleText>
      </DialogTitleWithBtn>

      <Form<FormValuesType>
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormFields />
  
            <Box pr={1} pb={0.5} pt={0.5}>
              <DialogActions>
                <Button onClick={onClose}>ОТМЕНА</Button>
                <Button
                  disabled={!valid || createLoading}
                  type="submit"
                >
                  ДОБАВИТЬ
                </Button>
              </DialogActions>
            </Box>
          </form>
        )}
      />
    </Box>
  )
}