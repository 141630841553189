import { SumCell } from 'shared/ui/components/Table/ui/TableCells'
import styled from 'styled-components'
import { Box } from '@mui/material'

interface ITotalColumnItem {
    type: string
    value: { value: number, colored: boolean }
    children?: string
}
interface ColoredStyleProps {
    difference: boolean;
}


function round( value: number ) {
  if (value) {
    return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(value)
  } return ''
}

const SummaryItem = styled(Box)`
  font-size: 14px;
  color: #000000DE;
`

const ColoredStyle = styled.div<ColoredStyleProps>`
  color: ${ (props) => props.difference ? '#F50057' : '#000000DE' };
`
const TotalText = styled.div`
  font-weight: 500;
`

export const SummaryColumnItem = ({ type, value }:ITotalColumnItem) => (
  <SummaryItem>
    { type === 'totalSum' && <SumCell value={value.value}/> }
    { type === 'expected' && <div>{round(value.value)}</div> }
    { type === 'actual' && <ColoredStyle difference={value.colored}>{round(value.value) }</ColoredStyle> }
    { type === 'text' && <TotalText>ИТОГО ПО ВСЕМУ ЗАКАЗУ</TotalText> }
  </SummaryItem>
)

