import { Box, Chip } from '@mui/material'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

export const Paper = styled.div<{ $height: number }>`
  background-color: white;
  padding-top: 16px;
  padding-left: 24px;
  padding-bottom: 24px;
  min-height: max-content;

  .showcase-main {
    min-height: ${({ $height }) => `${$height}px` };
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ecom-logo-img {
      width: min-content;
    }

    .title {
      line-height: 32px;
      font-size: 24px;
      color: #000000de;
    }

    .filters-container {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .search-input {
        width: 220px;
      }

      .brand-filter-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
      }

      .download-goods {
        display: flex;
        align-items: center;
        /* align-content: center; */
        margin-left: auto;
        height: 36px;

        .MuiCircularProgress-root {
          color: rgba(0, 0, 0, 0.26);
          bottom: 0;
        }
      }
    }
  }

  .content-container {
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .brand-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 260px;
      height: 228px;

      .brand-img-wrapper {
        display: flex;
        justify-content: center;
        padding: 34.5px 0;
        width: 100%;
        height: 192px;
        border-radius: 8px;
        transition: all 0.3s;

        :hover {
          box-shadow: 0 4px 0 -2px #00000033, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
          scale: 105%;
        }

        img {
          width: 228px;
          height: 95px;
        }
      }

      .brand-card-label {
        text-align: center;
        padding: 8px;
      }
    }

    .good-card {
      width: 254px;
      height: 218px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      transition: all 0.3s;
      font-size: 14px;
      cursor: pointer;

      .good-card-wrapper {
        display: flex;

        .MuiCheckbox-root {
          height: min-content;
        }
      }

      .good-card-header {
        flex-direction: column;
        padding: 8px;
        /* width: 198px; */
        /* height: 20px; */

        .good-card-label {
          display: block;
          width: 198px;
          /* height: 20px; */
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .good-img-wrapper {
        align-self: center;

        img {
          width: 150px;
          height: 150px;
        }
      }
    }

    .good-card:hover {
      box-shadow: 0px 4px 0 -2px #00000033, 0px 2px 2px 0px #00000024,
      0px 1px 5px 0px #0000001f;

      scale: 105%;
    }
  }
`

export const StyledGoodsInfo = styled.div`
  padding-top: 24px;
  .good-content-wrapper {
    .good-content {
      width: 100%;
      display: flex;
      flex-direction: row;

      .good-info {
        width: 100%;
        max-width: 900px;
      }
    }

    .tab-content-wrapper {

      .media-tab-wrapper {
        .image-container {
          span {
            font-size: 16px;
            font-weight: 500;
          }
          .media-grid-items {
            width: 88px !important;
            height: 117px !important;
            :first-child {
              width: 188px !important;
              height: 250px !important;
            }

            img {
              cursor: pointer;
              object-fit: cover;
              border: 1px solid rgba(0, 0, 0, 0.09);
              height: inherit;
              width: inherit;
              border-radius: 4px;
            }
          }
        }
      }
      .chips-autocomplete {
        width: 70%;
        .MuiInputBase-root {
          min-height: 40px;
        }

        div {
          justify-content: flex-start;
          display: flex;
          align-content: center;
        }

        .MuiAutocomplete-popupIndicator,
        .MuiAutocomplete-inputFocused {
          display: none;
        }

        .MuiAutocomplete-tagSizeMedium {
          display: inline;
          background-color: #e9f4fe;
        }

        .MuiChip-labelMedium {
          font-size: 13px;
          font-weight: 400;
          color: #000000de;
        }
      }
    }
  }

  .good-header {
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    >span {
      font-size: 20px;
      font-weight: 500;
    }
    .MuiButtonGroup-root {
      margin-left: 24px;
      height: 32px;
    }

    .download-goods {
      display: flex;
      align-items: center;
      /* align-content: center; */
      height: 36px;

      .MuiCircularProgress-root {
        color: rgba(0, 0, 0, 0.26);
        bottom: 0;
      }
    }
  }

  .tab-field {
    display: flex;
    gap: 16px;
    flex-direction: column;

    div {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 16px;

      span {
        margin-top: 8px;
        width: 30%;
        font-size: 16px;
        font-weight: 500;
        color: #00000099;
      }

      .styled-text-field {
        width: 70%;
        min-height: 40px;
        min-width: 453px;

        div {
          min-height: 40px;
          width: 100%;
        }
      }
    }
  }

  .good-photo {
    margin-right: auto;
    margin-left: auto;
    padding-top: 42px;

    .goods-photo-container {
      width: 432px;

      .imagePreloader {
        width: 432px;
        height: 432px;
      }

      .main-photo {
        width: 432px;
        height: 432px;
        object-fit: contain;
      }

      .mediaTabs {
        margin-top: auto;
        margin-bottom: 0;
      }

      .MuiTabs-indicator {
        display: none;
      }

      .MuiTab-root {
        transition: 0.1s;
        height: 104px;
        width: 104px;
        padding: 8px 8px;

        img {
          border: 1px solid #00000017;
          border-radius: 4px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        :hover {
          padding: 4px 4px;
        }
      }

      .Mui-selected {
        padding: 0;

        :hover {
          padding: 0;
        }
      }
    }
  }

  .styled-tabs {
    min-height: 0;
    height: 44px;
    border-bottom: 1px solid #007dff80;

    button {
      height: 44px;
      min-height: 0;
    }
  }

  .MuiTabs-flexContainer {
    button {
      text-transform: none;
      font-weight: 400;
      color: #007dff;
    }

    .Mui-selected {
      background-color: #ebf5ff;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .download-main-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 32px;

    span {
      font-weight: 500;
      font-size: 20px;
    }

    button {
      height: 32px;
    }
  }
`

export const StyledChipsContainer = styled(Box)`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  div {
    :last-child {
      margin-right: 0;
    }
  }
`

export const StyleChips = styled(Chip)`
  background: #ffffff;
  border: 1px solid #007dff;

  &&.MuiChip-clickable {
    &:hover {
      background: rgba(33, 150, 243, 0.08);
    }
  }

  .MuiChip-deleteIcon {
    color: #007dff;

    &:hover {
      color: #0155ad;
    }
  }
  .MuiChip-label {
    color: #0b79d0;
  }
`
export const SelectWrapper = styled.div`
  .MuiAutocomplete-root {
    width: 130px;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    .MuiFormLabel-root {
      position: absolute;
      top: -2px;
    }
    .MuiAutocomplete-inputRoot {
      height: 36px;
      padding-bottom: 3px;
      padding-top: 1px;
      padding-right: 30px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 4px;
    }
    .MuiInputBase-root {
      border: 0 solid rgba(0, 0, 0, 0);
    }
  }
`

export const LoaderWrapper = styled.div<{ height: number }>`
  position: relative;
  height: ${({ height }) => `${height}px`};
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  bottom: calc(50% - 25px);
  left: calc(50% - 30px);
  z-index: 1;
`
