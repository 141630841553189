import { Form } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Box, Button, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { diff } from 'deep-object-diff'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { ADMIN_PANEL_SUPPLIERS_LIST } from 'shared/config'
import { useSupplierContext } from 'entities/pim/admin/useSupplierEditContext'
import arrayMutators from 'final-form-arrays'
import { useQueryClient } from 'react-query'
import { getCurrentContragentsQueryKey } from 'shared/api/pim/admin'
import * as React from 'react'

import EditSupplierForm from './ui/EditSupplierForm'
import { validationSchema } from './lib/validationSchema'
import { ArchLabel, StyledLoader } from './ui/styled'
import { CheckOrderModal } from './ui/modals/CheckOrderModal'


interface IEditSupplier {
  data: pimApi.admin.CurrentContragent
}

export const EditSupplier= ({ data }: IEditSupplier) => {
  const { supplierQuery } = useSupplierContext()
  const history = useHistory()

  const supplierData = data
  
  const { mutate: editContragent } =
        pimApi.admin.useEditContragentMutation()
  
  const { mutate: editContragentBrands } =
        pimApi.admin.useEditContragentBrandsMutation()

  const { mutate:deleteContragentBrand } =
      pimApi.admin.useDeleteContragentBrandsMutation()

  const { mutate:addContragentUser } =
      pimApi.admin.useCreateManagerMutation()

  const { mutate:editContragentUser } =
      pimApi.admin.useEditContragentManagerMutation()

  const { mutate:deleteContragentUser } =
      pimApi.admin.useDeleteContragentManagerMutation()

  const { mutate: deleteContragent } =
      pimApi.admin.useDeleteContragentMutation()

  const { mutate: uploadMutation, isLoading: isUploading } =
      pimApi.admin.useUploadOrderFormFile()

  const { mutate: checkOrderForm } =
      pimApi.admin.useCheckOrderFormFileMutation()

  const { mutate: deleteOrderForm } =
      pimApi.admin.useDeleteOrderFormMutation()

  const refetchSuppliersList = pimApi.admin.useGetContragentsQuery().refetch


  const queryClient = useQueryClient()

  const handleSaveChanges = (values: pimApi.admin.CurrentContragent) => {
    if (
      supplierData
      && values
      && Object.keys(diff(values, supplierData)).length !== 0
    ) {
      editContragent( {
        contragentId: values.id,
        editedContragent: {
          id: values.id,
          versionNo: values.versionNo,
          shortName: values.shortName,
          fullName: values.fullName || '',
          externalId: values.externalId || '',
          inn: values.inn,
          kpp: values.kpp || '',
          useBoxes: values.useBoxes,
          orderformTemplateSettings: values.orderformTemplateSettings,
          invoiceTemplateSettings: values.invoiceTemplateSettings,
          inArchive: values.inArchive
        }
      },{
        onSuccess: ( response ) => {
          queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id), response.data)
          snackActions.info('Изменение сохранено!')
        },
      })
    }
  }

  const handleArchiveContragent = () => {
    if (supplierData) {      
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title={supplierData.inArchive ? 'Вернуть поставщика из архива?' : 'Перенос поставщика в архив'}
            acceptBtnText={supplierData.inArchive ? 'ПОДТВЕРДИТЬ' : 'АРХИВИРОВАТЬ'}
            acceptBtnColor="primary"
            variant="contained"
          />
        ),
        onAccept: () => {
          supplierData.inArchive = !supplierData.inArchive
          editContragent({
            contragentId: supplierData.id,
            editedContragent: {
              id: supplierData.id,
              versionNo: supplierData.versionNo,
              inArchive: supplierData.inArchive
            }
          }, {
            onSuccess: ( response ) => {
              queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id), response.data)
              refetchSuppliersList().then()
              snackActions.info('Изменение сохранено!')
            },
          }
          )
        },
      })
    }
  }

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      uploadMutation(
        { file, contragentId: supplierData.id },
        {
          onSuccess: (response) => {
            queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id), response.data)
            snackActions.info('Файл загружен!')
          },
        }
      )
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }

  const handleBrandsChange = (brandData) => {
    if (Object.keys(brandData).includes('id') && supplierData) {
      editContragentBrands( {
        contragentId: supplierData.id,
        brandId: brandData.id
      },{
        onSuccess: ( response ) => {
          const { brands } = supplierData
          brands?.push(response.data)
          queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id), { ...supplierData, brands })
          snackActions.info('Бренд закреплён за поставщиком!')
        }
      })
    }
  }

  const handleBrandsDelete = (brandData) => {
    if (supplierData && brandData) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Открепить бренд от поставщика?"
            acceptBtnText="ПОДТВЕРДИТЬ"
            acceptBtnColor="primary"
            variant="contained"
          />
        ),
        onAccept: () => {
          deleteContragentBrand({
            contragentId: supplierData.id,
            brandId: brandData.removeId,
            value: {
              id: brandData.removeId,
              versionNo: brandData.versionNo,
              comments: ''
            }
          }, {
            onSuccess: () => {
              const newBrands = supplierData.brands
              queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id),
                { ...supplierData, brands: newBrands?.filter(arr => arr.id !== brandData.removeId) })
              snackActions.info('Бренд откреплён от поставщика!')
            },
          }
          )
        },
      })
    }
  }

  const handleUserAdd = (userData) => {
    if (userData && supplierData) {
      addContragentUser( {
        contragentId: supplierData.id,
        managerData: userData
      },{
        onSuccess: ( response ) => {
          const newUser = supplierData.users
          newUser?.push(response.data)
          queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id), { ...supplierData, users: newUser })
          snackActions.info('Изменение сохранено!')
        }
      })
    }
  }

  const handleUserChange = (userData) => {
    if (userData && supplierData) {
      editContragentUser( {
        contragentId: supplierData.id,
        managerId: userData.id,
        managerEditedData: {
          id: userData.id,
          versionNo: userData.versionNo,
          dateOut: userData.dateOut
        } },{
        onSuccess: ( response ) => {
          const changeUsers = supplierData.users
          changeUsers?.map((item) => item.id === response.data.id ? response.data : item)
          queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id),
            { ...supplierData, users: changeUsers?.map((item) => item.id === response.data.id ? response.data : item) })
          snackActions.info('Изменение сохранено!')
        }
      })
    }
  }

  const handleUserDelete = (userData) => {
    if (supplierData && userData) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Открепить менеджера от поставщика?"
            acceptBtnText="ПОДТВЕРДИТЬ"
            acceptBtnColor="primary"
            variant="contained"
          />
        ),
        onAccept: () => {
          deleteContragentUser({
            contragentId: supplierData.id,
            managerId: userData.id,
            value: {
              id: userData.id,
              versionNo: userData.versionNo,
              comments: ''
            }
          },
          {
            onSuccess: () => {
              const newUser = supplierData.users
              queryClient.setQueryData( getCurrentContragentsQueryKey(supplierData.id),
                { ...supplierData, users: newUser?.filter(arr => arr.id !== userData.id) })
              snackActions.info('Изменение сохранено!')
            },
          }
          )
        },
      })
    }
  }

  const handleDeleteSupplier = () => {
    if (supplierData) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удалить поставщика?"
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
          />
        ),
        onAccept: () => {
          deleteContragent(
            {
              contragentId: supplierData?.id,
              value: {
                id: supplierData?.id,
                versionNo: supplierData?.versionNo,
                comments: 'delete'
              }
            },
            {
              onSuccess: () => {
                snackActions.info('Данные поставщика удалены')
                refetchSuppliersList().then()
                history.push(ADMIN_PANEL_SUPPLIERS_LIST)
              },
            }
          )
        },
      })
    }
  }

  const handleCheckOrderForm = () => {
    if (supplierData) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <CheckOrderModal
            close={close}
            accept={accept}
          />
        ),
        onAccept: () => {
          checkOrderForm({
            contragentId: supplierData.id
          }, {
            onSuccess: () => {
              snackActions.info('Проверка проведена!')
            },
          }
          )
        },
      })
    }
  }

  const handleDeleteOrderForm = () => {
    if (supplierData) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удалить бланк заказа?"
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
          />
        ),
        onAccept: () => {
          deleteOrderForm({ contragentId: supplierData.id }, {
            onSuccess: (response) => {
              queryClient.setQueryData(getCurrentContragentsQueryKey(supplierData.id), response.data)
              snackActions.info('Бланк удалён!')
            },
          }
          )
        },
      })
    }
  }

  const validate = useValidationSchema(validationSchema)


  return (
    <Box my = {3} >
      { (supplierQuery.isLoading || supplierQuery.isFetching) && <StyledLoader size={60} /> }
      {supplierData &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="h1"
              variant="h3"
              fontSize="1.5rem">
              {supplierData?.shortName}
            </Typography>
            { supplierData.inArchive && <ArchLabel>В архиве</ArchLabel> }

            {supplierData.deletePossible
              ? <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteSupplier}
              >УДАЛИТЬ</Button>
              :
              <Button
                variant="text"
                color="warning"
                onClick={ handleArchiveContragent }
              >
                {supplierData.inArchive
                  ? 'ВЕРНУТЬ ИЗ АРХИВА'
                  : 'АРХИВИРОВАТЬ'
                }
              </Button>
            }

          </Box>
          <Box mt={3}>
            <Form
              onSubmit={handleSaveChanges}
              validate={validate}
              initialValues={supplierData}
              mutators={{
                ...arrayMutators
              }}
              render={({ 
                handleSubmit, 
                errors,
                form: {
                  mutators: { push, pop }
                },
              }) => (
                <EditSupplierForm
                  handleSubmit={handleSubmit}
                  errors={errors}
                  push={push}
                  pop={pop}
                  values={supplierData}
                  handleBrandsChange={handleBrandsChange}
                  handleBrandsDelete={handleBrandsDelete}
                  handleUserAdd={handleUserAdd}
                  handleUserDelete={handleUserDelete}
                  handleUserChange={handleUserChange}
                  handleCheckOrderForm={handleCheckOrderForm}
                  handleDeleteOrderForm={handleDeleteOrderForm}
                  uploadFile={uploadFile}
                  isUploading={isUploading}
                />
              )}
            />
          </Box>
        </>
      }
    </Box>
  )
}
