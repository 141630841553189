import React from 'react'
import { pimApi } from 'shared/api'
import { ProductStatsWidget } from 'widgets/DashboardWidgets/ProductStatsWidget'

export const SmallSynchronizedWidget = () => {
  const { data } = pimApi.products.common.useGetProductsStatsQuery()
  return (
    <>
      {data &&
      <ProductStatsWidget title="Архивированы" data={data.inArchive}/>
      }
    </>
  )
}