import { Plugin } from '@devexpress/dx-react-core'
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid'
import {
  Table,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui'

import { CellInput } from '../TableCells'

const CustomCell = ({ children, ...props }: Table.DataCellProps) => (
  <Table.Cell {...props} sx={{ p: 0 }}>{children}</Table.Cell>
)

const EditingColumnsProvider = (props: { for: string[] }) => (
  <DataTypeProvider editorComponent={CellInput} {...props}/>
)


export const TableDetailEditing = ({ columnsConfig, onCommitChanges, rowInfo }) => {
  const editingColumns = columnsConfig
    .filter((column) => column.editingEnabled)
    .map((column) => column.columnName)

  const onCommitWithRowData = (data) => {
    onCommitChanges( { data, rowInfo } )
  }

  return (
    <>
      <Plugin>
        <EditingColumnsProvider for={editingColumns} />
        <EditingState
          onCommitChanges={onCommitWithRowData}
          columnExtensions={columnsConfig.config}
        />
        <TableInlineCellEditing
          startEditAction="click"
          cellComponent={CustomCell}
        />
      </Plugin>
    </>
  )
}
