import { TextField } from '@mui/material'

import { TextInputI } from './TextInputI'
import { useRenderInputProps } from './hooks'

const TextInput = <T extends {}>(props: TextInputI<T>) => {
  const {
    value,
    label,
    onClick,
    onBlur,
    onFocus,
    onChange,
    isError = true,
    name,
    disabled,
    margin,
    id,
    error,
    helperText,
    meta,
    placeholder,
    autoFocus,
    onKeyPress,
    className,
    multiLine,
    minRows,
    sx,
    maxRows
  } = props
  const { type, InputProps, InputLabelProps } = useRenderInputProps(props)

  return (
    <TextField
      className={className}
      id={id}
      label={label}
      variant="outlined"
      size="small"
      fullWidth={true}
      value={value}
      sx={sx}
      onChange={onChange}
      margin={margin}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      name={name}
      disabled={disabled}
      type={type}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      error={isError && (error || meta?.invalid && meta?.visited)}
      helperText={helperText}
      placeholder={placeholder}
      autoFocus={autoFocus}
      multiline={multiLine}
      minRows={minRows}
      maxRows={maxRows}
    />
  )
}

TextInput.defaultProps = {
  type: 'string',
  value: '',
}

export default TextInput
