import { memo } from 'react'
import { useStore } from 'effector-react'
import { Dialog as MuiDialog } from '@mui/material'
import omit from 'lodash/omit'

import * as dialogModel from '../model'

const Dialog = () => {
  const dialogState = useStore(dialogModel.$dialogState)
  const handleClose = () => dialogModel.closeDialog()

  const handleAccept = (params) => {
    if (dialogState.onAccept) dialogState.onAccept(params)
    if(dialogState.options?.stayOnAccept) return
    dialogModel.closeDialog()
  }

  return (
    <MuiDialog
      open={dialogState.isOpen}
      onClose={handleClose}
      maxWidth={false}
      sx={dialogState.sx}
      {...omit(dialogState.options, 'stayOnAccept')}
    >
      {dialogState.component && (
        <>
          {/* @ts-ignore */}
          <dialogState.component
            close={handleClose}
            accept={handleAccept}
          />
        </>
      )}
    </MuiDialog>
  )
}

export const DialogMemo = memo(Dialog)
