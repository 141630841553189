import { Box, Button, Paper } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { PageTitle, Preloader } from 'shared/ui/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { catalogSelector } from 'store/catalog/selectors'
import { useLocation } from 'react-router-dom'
import { warehouseIdSelector } from 'store/doc/selectors'
import { DocType, IDoc } from 'shared/services/interfaces/doc'
import { TYPES } from 'shared/services/types'
import { diContainer } from 'shared/lib'
import { setWarehouseId } from 'store/doc'
import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'
import { wmsGen } from 'shared/lib/generated'
import { snackActions } from 'shared/lib/react/snackbar'

import { TableFilters } from './Table/TableFilters'
import { DocTable } from './Table'
import { Filter, SearchObj } from './interfaces'
import { CreateDocItemModal } from './Modal/CreateDocItemModal'
import { HeaderWrapper } from './Table/style'

interface LocationState {
  from?: {
    pathname: string;
  };
  search: string
}

interface DocState {
  status: 'loading' | 'error' | 'ok';
  data: Array<DocType>;
}

const docObj = diContainer.get<IDoc>(TYPES.Doc)

export const DocList = () => {
  const { mutate: createDoc } = wmsGen.receiptDocuments.CreateReceiptdoc.useCreateReceiptdoc()
  
  const history = useHistory()
  const { search } : LocationState = useLocation<{ sortBy?: string }>()

  const searchObj: SearchObj = queryString.parse(search)
  // eslint-disable-next-line no-nested-ternary
  const activeStatuses: Array<string> = searchObj.status ? Array.isArray(searchObj.status) ?
    searchObj.status :
    [searchObj.status] : []

  const [docList, setDocList] = useState<DocState>({ status: 'loading', data: [] })
  const [filter, setFilter] = useState<Partial<Filter>>({
    dateFrom: searchObj.dateFrom,
    dateTo: searchObj.dateTo,
    recipient: searchObj.recipient,
    numberDoc: searchObj.numberDoc,
    status: activeStatuses,
    supplier: searchObj.supplier,
  })

  const dispatch = useDispatch()
  const warehouseId = useSelector(warehouseIdSelector)
  const warehouses = useSelector(catalogSelector('wms/warehouses'))

  const tableList = useMemo(() => docObj.filterList(docList.data, { ...filter }), [filter, docList.data])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async function loadDocList(warehouseId: number) {
      setDocList({
        ...docList,
        status: 'loading'
      })

      docObj.loadDocList(warehouseId).then((data) => {
        setDocList({
          status: 'ok',
          data
        })
      })
        .catch(() => {
          setDocList({
            ...docList,
            status: 'error'
          })
        })
    }

    if (warehouseId) {
      loadDocList(warehouseId)
    }
  }, [warehouseId])

  useEffect(() => {
    const isWarehouseNotExist = search.indexOf('warehouse_id') === -1

    if (isWarehouseNotExist && warehouseId) {
      history.push({
        search: `?warehouse_id=${warehouseId}`
      })
    }
  }, [search, warehouseId])
  
  useEffect(() => {
    const [expectWarehouse] = warehouses
    if (warehouseId === null && expectWarehouse) {
      dispatch(setWarehouseId(expectWarehouse.id))
    }
  }, [warehouseId, warehouses])

  function historyChange(payload: object) {
    const filterQueryString = queryString.stringify({
      ...searchObj,
      ...filter,
      page: 1,
      warehouse_id: warehouseId,
      ...payload
    }, { skipEmptyString: true })

    history.push({
      search: `?${filterQueryString}`
    })
  }

  const handleFilterChange = (filterQuery: { warehouse_id: number }) => {

    const { warehouse_id, ...filters } = filterQuery

    if (warehouseId !== warehouse_id) {
      dispatch(setWarehouseId(warehouse_id))
    }

    historyChange({
      ...filters,
      page: 1,
      warehouse_id
    })

    setFilter(filters)
  }

  const createDocItem = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <CreateDocItemModal close={close} accept={accept} />,
        onAccept: (newDocItem) => {
          createDoc({ 
            data: { 
              ...newDocItem,
              positions: [],
              sourceSystemCode: 'user',
              warehouseCode: '00-000001'
            }
          }, {
            onSuccess: (response) => {
              setDocList(prev => ({ ...prev, data: [response, ...prev.data] } as DocState))
              snackActions.info('Приход на склад успешно добавлен')
            }
          })
        }
      }
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <PageTitle>
          Приход на склад
        </PageTitle>
        <Button startIcon={<AddIcon/>} onClick={createDocItem} variant="contained" size="small">
          Добавить приход
        </Button>
      </HeaderWrapper>
      <Box component={Paper} p={3}>
        <TableFilters warehouseId={warehouseId ?? undefined} activeStatuses={activeStatuses} onChange={handleFilterChange} />
        {warehouseId && warehouses.length > 0 ? (
          <>
            {docList.status === 'loading' ? (
              <Preloader />
            ) : (
              <DocTable tableList={tableList} />
            )}
          </>
        ) : <p>Загрузка доступных складов</p>}
      </Box>
    </div>
  )
}
