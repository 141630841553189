import styled from 'styled-components'
import { Avatar, Box } from '@mui/material'

export const CabinetWrapper = styled(Box)`
  margin-top: 24px;
  min-height: 68px;
  position: relative;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  padding: 12px 0 16px 0;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
`

export const MarketplaceImg = styled(Avatar)<{ $inArchive?: boolean}>`
  border-radius: 4px;
  width: 40px;
  height: 40px;
  filter: ${({ $inArchive }) => $inArchive ? 'grayscale(100%)' : 'grayscale(0%)'};
  opacity: ${({ $inArchive }) => $inArchive ? '0.5' : '1'};
  background-color: #FFFFFF;
  img {
    width: 40px;
  }
`

export const MarketplaceName = styled(Box)<{ $inArchive?: boolean}>`
  width: 312px;
  margin-left: 48px;
  font-size: 16px;
  color: ${({ $inArchive }) => $inArchive ? 'rgba(0,0,0,0.38)' : 'rgba(0, 0, 0, 0.87)'};
  word-break: break-word;
`

export const InArchive = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin: 4px 0 0 12px;
  background-color: #ED6C02;
  color: #FFFFFF;
  height: 20px;
  min-width: 66px;
  line-height: 20px;
  border-radius: 10px;
  padding: 0 8px;
`

export const KeyWrapper = styled(Box)<{ $isHide?: boolean }>`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  display: ${({ $isHide }) => $isHide ? 'none' : 'flex'};
  justify-content: space-between;
  width: 438px;
  padding: 0 24px;
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
`

export const GoodsIsLoad = styled(Box)`
  width: 125px;
`