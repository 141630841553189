import { isNotNil } from 'shared/lib/checkers'

const ROW_HEIGHT = 50
const HEADER_HEIGHT = 56
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 64 + 56
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const useRowsPerPage = (searchObjLimit?: string, headerHeight?: number) => {
  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      (headerHeight || HEADER_HEIGHT) -
      TABLE_CONTROLS -
      TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObjLimit)
    ? parseInt(searchObjLimit, 10)
    : calculatedRowsPerPage

  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)
  return { limit, calculatedRowsPerPage }
}
