import React, { useMemo } from 'react'
import { AttributesTable } from 'features/admin/Goods/Attributes'
import { pimApi } from 'shared/api'
import { useLocation } from 'react-router-dom'
import { SearchObj, TableParams } from 'shared/ui/components/Table/model/types'
import queryString from 'query-string'
import { checkValue } from 'shared/lib/checkers'
import { StyledLoader } from 'features/admin/User/EditUser/ui/styled'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { columnsConfig } from 'features/admin/Goods/Attributes/lib/columnsConfig'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { AttributeModal } from 'features/admin/Goods/Attributes/ui/modal/AttributeModal'

import { GoodsAttributes } from '../styled'

export const AttributesTab = () => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)
  const { searchString, page }  = searchObj
  const { limit, calculatedRowsPerPage } = useRowsPerPage(searchObj.limit, 220)

  const { data, isLoading, isFetching, refetch } = pimApi.attributes.useGetAttributesQuery({
    searchString,
    page: checkValue(page),
    limit: checkValue(limit),
  })

  const { mutate: editAttribute } = pimApi.attributes.useEditAttributeMutation()
  const { mutate: deleteAttribute } = pimApi.attributes.useDeleteAttributeMutation()

  const currentPage = parseInt(page || '0', 10)

  const memoTableParams: TableParams = useMemo(
    () => ({
      sort: [],
      limit: limit >= (data?.total || 0) ? (data?.total || 0) : limit,
      page: currentPage,
      calculatedRowsPerPage
    }),
    [data, isLoading]
  )

  const settingsMenuOptions = (row: pimApi.attributes.CommonAttribute) => [
    {
      label: 'Изменить атрибут',
      divider: true,
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <AttributeModal close={close} accept={accept} initialValues={row}/>
          ), onAccept: (editedData: pimApi.attributes.CommonAttribute) => {
            editAttribute(editedData, {
              onSuccess: () => {
                refetch()
                snackActions.info('Изменение сохранено')
              }
            })
          }
        })
      }
    },
    {
      label: 'Удалить атрибут',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <ModalContent
              close={close}
              accept={accept}
              title="Удалить атрибут?"
              acceptBtnText="УДАЛИТЬ"
              acceptBtnColor="error"
              variant="text"
            />
          ),
          onAccept: () => {
            deleteAttribute({ id: row.id, versionNo: row.versionNo, comments: ''  }, {
              onSuccess: () => {
                snackActions.info('Атрибут удален')
              }
            })
          },
        })
      },
      type: 'error'
    },
  ]


  const handleCellChange = (changedCells: pimApi.attributes.CommonAttribute) => {
    editAttribute(changedCells, {
      onSuccess: () => {
        refetch()
        snackActions.info('Атрибут изменен')
      }
    })
  }

  const { mutate: addAttribute } = pimApi.attributes.useAddAttribute()

  const addAttributeFunction = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <AttributeModal close={close} accept={accept}/>,
        onAccept: (formData: pimApi.attributes.CommonAttribute) => {
          addAttribute(formData, {
            onSuccess: () => {
              refetch()
              snackActions.info('Атрибут добавлен')
            }
          })
        },
      }
    )
  }

  return (
    <GoodsAttributes>
      {((isLoading || isFetching) && !!data) && (<StyledLoader size={60} />)}
      { (data?.result) ?
        <AttributesTable
          addAttributeFunction={addAttributeFunction}
          handleCellChange={handleCellChange}
          memoTableParams={memoTableParams}
          attributesData={data.result}
          totalCount={data.total}
          settingsMenuOptions={settingsMenuOptions}
          columnsConfig={columnsConfig}/>
        :
        <div style={{ width: '100%', height: '300px', position: 'relative' }}>
          <StyledLoader size={60} />
        </div>
      }

    </GoodsAttributes>
  )
}