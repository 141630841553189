/* eslint-disable */
// @ts-nocheck

import { Storage } from 'domains/storage'

const lcAvailable = (): boolean => {
  const key = '__TEST__'
  try {
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}
const getlc =
  (availableFunc) =>
  (key: string): string | null => {
    if (availableFunc()) {
      const item = localStorage.getItem(key)

      return item
    }
  }
const setlc =
  (availableFunc) =>
  (key: string, value: string): void => {
    if (availableFunc()) {
      localStorage.setItem(key, value)
    }
  }
const clearlc = (availableFunc) => (): void => {
  if (availableFunc()) {
    localStorage.clear()
  }
}
const removelc =
  (availableFunc) =>
  (key: string): void => {
    if (availableFunc()) {
      localStorage.removeItem(key)
    }
  }

export const localStore = new Storage({
  get: getlc(lcAvailable),
  set: setlc(lcAvailable),
  rm: removelc(lcAvailable),
  clear: clearlc(lcAvailable),
})
