import { Grid, Button } from '@mui/material'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { PartialDeep } from 'type-fest'
import { GoodsSupplier } from 'domains/pim/goods'
import {
  CatalogSelectField,
  RadioGroupField,
  Field,
  FieldWithCount,
  InputField, SelectFieldWithAutoComplete,
} from 'shared/ui/components/form'
import {
  useBrandListQuery,
  useCountryList, useSeasonListQuery
} from 'shared/api/pim/dictionaries'
import { Option } from 'shared/ui/components/interface'
import { Suppliers } from 'features/goods/suppliers'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { getIsExistOnServer } from 'shared/lib/checkers/isExistOnServer'
import { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { MemoAssortmentGoodsSearchSection } from 'features/goods/assortment'
import { InputLabel } from 'shared/ui/components'

import { TnvedSelectField } from './components/TnvedSelectField'
import { CategorySelectField } from './components/CategorySelectField'
import { OptionItem } from './interface'

import { MemoGoodsSearchSection } from '../../GoodsComponent'


const VAT_RATE_OPTIONS: Array<Option> = [
  {
    value: '0',
    label: '0%'
  },
  {
    value: '0.1',
    label: '10%'
  },
  {
    value: '0.2',
    label: '20%'
  }
]
interface IInfoStepValues {
  suppliers?: Array<pimApi.goods.GoodsSupplier>
  id?: UniqueId
  categoryId?: {
    fullPathName: string;
    id: UniqueId
  }
  goodsType?: pimApi.goods.GoodsType
}

interface InfoFormProps {
  values: IInfoStepValues;
  isArchived?: boolean;
}
const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
})

export const InfoForm = ({ values, isArchived }: InfoFormProps) => {
  const { meta: vatRateMeta } = useField('vatRate')
  const goodsType = useField('goodsType').input.value
  const vatRateError = vatRateMeta.error && vatRateMeta.touched
  const isEmptyComponent = values.suppliers?.some(el => el.contragent === undefined)
  const isGoodsExist = useMemo(() => getIsExistOnServer(values), [values.id])
  const isReadOnlySuppliers = goodsType === 'bundle' || goodsType === 'pack'
  const suppliers = useField('suppliers').input.value
  const showSuppliers = isReadOnlySuppliers ? suppliers.length > 0 : true
  const { countryListQuery } = useCountryList()
  const { seasonListQuery } = useSeasonListQuery()
  const brandListQuery = useBrandListQuery()

  const dictOptions = useMemo(
    () => ({
      country: countryListQuery.data?.map(getOptions),
      brand: brandListQuery.data?.map(getOptions),
      season: seasonListQuery.data?.map(getOptions),
    }),
    [countryListQuery.data, brandListQuery.data, seasonListQuery.data]
  )

  return (
    <div>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <InputLabel label="Название" />
          <Field placeholder="Название" name="name" component={InputField} errorAfterTouch={true} disabled={isArchived} />
        </Grid>
        <Grid item={true} xs={12}>
          <InputLabel label="Категория" />
          <InputWrapper>
            <Field placeholder="Категория" name="categoryId" component={CategorySelectField} disabled={isArchived}/>
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={12}>
          <InputLabel label="Бренд" />
          <InputWrapper>
            <Field
              name="brandId"
              id="brandId"
              placeholder="Бренд"
              component={SelectFieldWithAutoComplete}
              options={dictOptions.brand}
              errorAfterTouch={true}
              fullWidth={true}
              disabled={isArchived}
            />
          </InputWrapper>
        </Grid>
        {(values.goodsType === 'bundle' || values.goodsType === 'pack' ) && (
          <Grid item={true} xs={12}>
            <MemoGoodsSearchSection disabled={isArchived} />
          </Grid>
        )}
        {(values.goodsType === 'assortment') && (
          <Grid item={true} xs={12}>
            <MemoAssortmentGoodsSearchSection disabled={isArchived} />
          </Grid>
        )}
        <Grid item={true} container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <InputLabel
              single={true}
              label="Артикулы и единица измерения упаковки товара"
            />
          </Grid>
          <Grid item={true} xs={4}>
            <Field
              name="code"
              label="Артикул"
              component={InputField}
              errorAfterTouch={true}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={4}>
            <Field
              name="auxCode"
              label="Дополнительный артикул"
              component={InputField}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={4}>
            <Field
              name="unitId"
              component={CatalogSelectField}
              catalog="pim/units?packaging_support=true"
              label="Единица измерения упаковки товара"
              disabled={isArchived}
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <InputLabel label="Описание" />
          <FieldWithCount>
            <Field
              name="description"
              component={InputField}
              placeholder="Описание"
              multiline={true}
              fullWidth={true}
              errorAfterTouch={true}
              minRows={7}
              maxRows={100}
              disabled={isArchived}
            />
          </FieldWithCount>
        </Grid>
        <Grid item={true} container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <InputLabel single={true} label="Размер и вес упаковки" />
          </Grid>
          <Grid item={true} xs={3}>
            <Field
              name="width"
              label="Ширина, см"
              component={InputField}
              errorAfterTouch={true}
              inputProps={{ inputMode: 'numeric' }}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <Field
              name="height"
              label="Высота, см"
              component={InputField}
              errorAfterTouch={true}
              inputProps={{ inputMode: 'numeric' }}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <Field
              name="depth"
              label="Глубина, см"
              component={InputField}
              errorAfterTouch={true}
              inputProps={{ inputMode: 'numeric' }}
              disabled={isArchived}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <Field
              name="weight"
              label="Вес с упаковкой, кг"
              component={InputField}
              errorAfterTouch={true}
              inputProps={{ inputMode: 'numeric' }}
              disabled={isArchived}
            />
          </Grid>
        </Grid>
        {showSuppliers &&
          <>
            <Grid item={true} xs={12} mt={1}>
              <InputLabel single={true} label="Поставщик" required={false} />
            </Grid>
            <FieldArray<PartialDeep<GoodsSupplier>> name="suppliers">
              {({ fields }) => {
                const addEmptyField = () => fields.push({ incomingPrice: undefined })
                return (
                  <Grid item={true} xs={12}>
                    {fields.map((name, index) => (
                      <Suppliers
                        name={name}
                        index={index}
                        key={name}
                        values={values}
                        // @ts-ignore
                        fields={fields}
                        isGoodsExist={isGoodsExist}
                        disabled={isArchived}
                        readOnly={isReadOnlySuppliers}
                      />
                    ))}
                    {!isReadOnlySuppliers && (
                      <Grid item={true} xs={12}>
                        <InputWrapper>
                          <Button
                            disabled={isArchived || isEmptyComponent}
                            variant="contained"
                            color="primary"
                            onClick={addEmptyField}
                          >
                            ДОБАВИТЬ ПОСТАВЩИКА
                          </Button>
                        </InputWrapper>
                      </Grid>
                    )}
                  </Grid>
                )
              }}
            </FieldArray>
          </>
        }
        <Grid item={true} xs={12}>
          <InputLabel single={true} label="Код ТН ВЭД" required={false} />
        </Grid>
        <Grid item={true} xs={12}>
          <InputWrapper>
            <Field placeholder="Код ТН ВЭД" name="tnvedCode" component={TnvedSelectField} disabled={isArchived}/>
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel label="Страна производства" />
          <InputWrapper>
            <Field
              name="madeInCountryId"
              id="madeInCountryId"
              placeholder="Страна производства"
              component={SelectFieldWithAutoComplete}
              options={dictOptions.country}
              errorAfterTouch={true}
              fullWidth={true}
              disabled={isArchived}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel label="Сезонность" required={false} />
          <InputWrapper>
            <Field
              name="seasonId"
              id="seasonId"
              placeholder="Сезонность"
              component={SelectFieldWithAutoComplete}
              options={dictOptions.season}
              errorAfterTouch={true}
              fullWidth={true}
              disabled={isArchived}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel label="Ставка НДС" error={vatRateError} />
          <InputWrapper>
            <Field
              name="vatRate"
              component={RadioGroupField}
              size="small"
              disabled={isArchived}
              options={VAT_RATE_OPTIONS}
              row={true}
            />
          </InputWrapper>
        </Grid>
      </Grid>
    </div>

  )
}
