import styled from 'styled-components'

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const CompetitorsCell = ({ children }) => (
  <ColumnWrapper>
    {children}
  </ColumnWrapper>
)