// @ts-nocheck
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@mui/material'
import styled from 'styled-components'

export const CustomRow = styled(Table.Row)`
  :hover {
    background: rgba(0, 0, 0, 0.03);
  }
`
export const CustomCell = styled(Table.Cell)`
  padding: 8px;
  :first-of-type {
    padding-left: 16px !important;
  }
  :last-of-type {
    padding-right: 16px !important;
  }
`

export const Wrapper = styled(Box)`
  position: relative;
  border: 1px solid rgba(224,224,224,1);
  border-radius: 4px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 28px;
  margin-bottom: 24px;
`
