import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeleteContragentPayload {
  contragentId: UniqueId,
  value: pimApi.DeletingObject
}
const deleteContragent = ({ contragentId, value }: DeleteContragentPayload) =>
  api.delete(
    `/pim/admin/contragents/${contragentId}`, { data: value }
  )


export const useDeleteContragentMutation = () =>
  useMutation<
        AxiosResponse,
        AxiosError<errorType>,
        DeleteContragentPayload
        >(deleteContragent)