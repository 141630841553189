import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { Box } from '@mui/material'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 680px;
    max-width: 680px;
  }
`

export const InputUrlContainer = styled(Box)`
  margin-top: 24px;
  padding: 36px 48px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' \
  height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect \
  width='100%25' height='100%25' fill='none' rx='4' ry='4' \
  stroke='%23BDBDBD' stroke-width='4' stroke-dasharray='6%2c 14' \
  stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"
    );
  border-radius: 4px;
`

export const InputSkuContainer = styled(Box)`
  display: flex;
  margin-top: 24px;
  border-radius: 4px;
  justify-content: flex-start;
`

export const InputMarket = styled(Box)`
  width: 280px;
`
export const InputSku = styled(Box)`
  width: 240px;
  margin-left: 12px;
`