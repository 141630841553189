import { AxiosError } from 'axios'
import { useQuery, QueryFunctionContext, UseQueryOptions } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

type getWBProductSearchQueryKeyType = ReturnType<typeof getWBProductSearchQueryKey>

export const getWBProductSearchQueryKey = (queryParams: {
  companymarketplaceId?: UniqueId,
  code?: string
}) => ['get', 'pim/products/wb/product', queryParams] as const

export const getWBProductSearch = (companymarketplaceId?: UniqueId, code?: string) => api
  .get<pimApi.products.wb.WBProductInfo[]>(
    '/pim/products/wb/product/search', { params: { companymarketplace_id: companymarketplaceId, code } }
  )
  .then(({ data }) => data)

export const fetchWBProductSearch = (
  context: QueryFunctionContext<getWBProductSearchQueryKeyType>
) => {
  const [, , queryParams] = context.queryKey
  return getWBProductSearch(queryParams.companymarketplaceId, queryParams.code)
}

export const useFetchWBProductSearchQuery = ({
  companymarketplaceId, code, options
}: {
  companymarketplaceId?: UniqueId,
  code?: string,
  options?: UseQueryOptions<
    pimApi.products.wb.WBProductInfo[],
    AxiosError<errorType>,
    pimApi.products.wb.WBProductInfo[],
    getWBProductSearchQueryKeyType
  >
}) =>
  useQuery<
    pimApi.products.wb.WBProductInfo[],
    AxiosError<errorType>,
    pimApi.products.wb.WBProductInfo[],
    getWBProductSearchQueryKeyType
  >(getWBProductSearchQueryKey({ companymarketplaceId, code }), fetchWBProductSearch, options)
