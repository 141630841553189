import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api'

interface GetCategoryAttributes {
  categoryId: UniqueId
  searchString?: string
}

export const getCategoryAttributesQueryKey = (searchParams: GetCategoryAttributes) =>
  ['get', '/pim/categories/attributes', searchParams] as const

type GetCategoryAttributesQueryKey = ReturnType<typeof getCategoryAttributesQueryKey>

function getCategoryAttributes({ categoryId, searchString }: GetCategoryAttributes) {
  return api.get<pimApi.categoryAttributes.CategoryAttribute[]>
  (`/pim/categories/${categoryId}/attributes`,{ params: { search_string: searchString } }).then((res) => res.data)
}

const getCategoryAttributesFn = (context: QueryFunctionContext<GetCategoryAttributesQueryKey>) => {
  const [, ,searchParams] = context.queryKey
  return getCategoryAttributes(searchParams)
}

export const useGetCategoryAttributes = (searchParams: GetCategoryAttributes) =>
  useQuery<
    pimApi.categoryAttributes.CategoryAttribute[],
    errorType,
    pimApi.categoryAttributes.CategoryAttribute[],
    GetCategoryAttributesQueryKey
  >(
    getCategoryAttributesQueryKey(searchParams),
    getCategoryAttributesFn, { refetchOnMount: false, keepPreviousData: true, staleTime: Infinity }
  )
