import { pimApi } from 'shared/api'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'


type QueryKey = ReturnType<typeof getQueryKey>

const getQueryKey = (goodsId: UniqueId) =>
  ['get', '/goods/', goodsId, '/attributes4variation'] as const

function getAttributesForVariations(
  goodsId: UniqueId
): Promise<AxiosResponse<Array<pimApi.goods.GoodsAttribute4Variation>>> {
  return api.get<Array<pimApi.goods.GoodsAttribute4Variation>>(
    `/pim/goods/${goodsId}/attributes4variation`
  )
}

function getAttributesForVariationsFn(context: QueryFunctionContext<QueryKey>) {
  const [, ,goodsId] = context.queryKey
  return getAttributesForVariations(goodsId).then(
    (res) => res.data
  )
}

export const useAttributesForVariations = (goodsId: UniqueId) =>
  useQuery<
    Array<pimApi.goods.GoodsAttribute4Variation>,
    errorType,
    Array<pimApi.goods.GoodsAttribute4Variation>,
    QueryKey
  >(getQueryKey(goodsId), getAttributesForVariationsFn, { refetchOnMount: false })
