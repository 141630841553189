import React from 'react'
import { Box } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ValueContainer } from './styled'

import { ValueDelta } from '../../components/ValueDelta/ValueDelta'

interface Props {
  title: string
  data: number
  url?: string
}
export const ProductStatsWidget = ({ title,data,url }: Props) => (
  <ValueContainer>
    <Box>
      <span>
        {title}
      </span>
      {url &&
      <Box>
        <a
          style={{ display: 'flex', alignItems: 'center' }}
          href={url}
          target="_blank" rel="noreferrer">
          <OpenInNewIcon sx={{ color: '#2196F3' }}/>
        </a>
      </Box>
      }
    </Box>
    {(data !== undefined) &&
    <ValueDelta value={data}/>
    }
  </ValueContainer>
)