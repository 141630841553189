import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api'

export const getDictionaryValuesQueryKey = (dictionaryId: UniqueId) =>
  ['get', '/pim/dictionaries/values', dictionaryId] as const

type GetDictionaryValuesQueryKey = ReturnType<typeof getDictionaryValuesQueryKey>

function getDictionaryValues(dictionaryId: UniqueId) {
  return api.get<pimApi.dictionaries.Value[]>
  (`/pim/dictionaries/${dictionaryId}/values`).then((res) => res.data)
}

const getDictionaryValuesFn = (context: QueryFunctionContext<GetDictionaryValuesQueryKey>) => {
  const [, ,categoryId] = context.queryKey
  return getDictionaryValues(categoryId)
}

export const useGetDictionaryValues = (dictionaryId: UniqueId) =>
  useQuery<
    pimApi.dictionaries.Value[],
    errorType,
    pimApi.dictionaries.Value[],
    GetDictionaryValuesQueryKey
  >(
    getDictionaryValuesQueryKey(dictionaryId),
    getDictionaryValuesFn, { refetchOnMount: false, keepPreviousData: true, staleTime: Infinity }
  )
