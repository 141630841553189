import React, { useState, useEffect, memo } from 'react'
import { Grid } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  arrayMove
} from '@dnd-kit/sortable'
import { pimApi } from 'shared/api'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { dialogModel } from 'shared/ui/components/dialog'
import { reactQueryCacheAdd, reactQueryCacheDelete, reactQueryCacheUpdate, reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import {
  CircularProgressStyled,
  CircularProgressWrap,
  ContentsWrapper
} from 'shared/ui/components/CircularProgress/CircularProgress'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { PhotoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/WB/photoRequirements'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete'
import { SettingsMenu } from 'shared/ui/components'
import { MoreVert } from '@mui/icons-material'
import { WbMediaTypes } from 'shared/types/MediaTypes'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { PhotoItem } from './ui/PhotoItem'

const PICTURE_SUPPORTED_FILE_EXTENSION = ['.jpeg', '.jpg', '.webp', '.png']

export const Photos = memo(
  ({
    photos,
    disabled,
  }: {
    photos?: Array<pimApi.products.wb.WBMedia>
    disabled?: boolean
  }) => {
    const queryClient = useQueryClient()

    const { id } = useParams<{ id: string }>()
    const productId = parseInt(id, 10)
    const [localPhotos, setPhotos] = useState<Array<pimApi.products.wb.WBMedia>>([])

    const { mutate: bulkDeleteMediaMutation } =
      pimApi.products.wb.useBulkDeleteProductMediaMutation()
    const { mutate: bulkImportMediaFromProduct } =
      pimApi.products.common.useMassImportMediaFromProduct()
    const { mutateAsync: validateMediaFileMutation } =
      pimApi.products.wb.useValidateProductMediaFileMutation()
    const { mutateAsync: validateMediaUrlMutation } =
      pimApi.products.wb.useValidateProductMediaByURLMutation()
    const { mutate: createMediaMutation, isLoading: createInProgress } =
      pimApi.products.wb.useCreateProductMediaMutation({
        onSuccess: reactQueryCacheAdd({
          queryClient,
          queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
          path: 'photos'
        }),
      })
    const {
      mutate: createMediaByURLMutation,
      isLoading: createByURLInProgress,
    } = pimApi.products.wb.useCreateProductMediaByURLMutation({
      onSuccess: reactQueryCacheAdd({
        queryClient,
        queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
        path: 'photos'
      }),
    })
    const { mutate: updateMediaMutation, isLoading: updateInProgress } =
      pimApi.products.wb.useUpdateProductMediaMutation({
        onSuccess: reactQueryCacheUpdate({
          queryClient,
          queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
          path: 'photos'
        }),
      })
    const {
      mutate: updateMediaSingleMutation,
      isLoading: updateSingleInProgress,
    } = pimApi.products.wb.useUpdateProductMediaSingleMutation({
      onSuccess: reactQueryCacheUpdateByKey({
        queryClient,
        queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
        path: 'photos'
      }),
    })
    const { mutate: deleteMediaMutation, isLoading: deleteInProgress } =
      pimApi.products.wb.useDeleteProductMediaMutation()

    useEffect(() => {
      setPhotos(photos === undefined ? [] : photos)
    }, [photos])

    const onFileValidate = (mediaType: WbMediaTypes) =>
      (file, onSuccess) => {
        if (typeof file === 'string') {
          validateMediaUrlMutation({ url: file, productId, mediaType }).then(res => {
            if (res.status === 200) onSuccess(res)
          })
        } else {
          const formFile = new FormData()
          formFile.append('file', file)
          validateMediaFileMutation({
            productId,
            mediaType,
            file: formFile,
          }).then(res => {
            if (res?.status === 200) onSuccess(res)
          })
        }
      }
    const onFileUpload =
      (mediaType: WbMediaTypes) =>
        (files) => {
          if (files && isNotEmptyArray(files)) {

            files.forEach(el => {
              if (typeof el.file === 'string') {
                createMediaByURLMutation({ url: el.file, productId, mediaType })
              } else {
                const file = new FormData()

                file.append('file', el.file || '')

                createMediaMutation({
                  productId,
                  mediaType,
                  file,
                })
              }
            })
          }
        }

    const onDragChange = (sourceId, sourceIndex, targetIndex) => {
      const swappedPhotos = arrayMove<pimApi.products.wb.WBMedia>(localPhotos, sourceIndex, targetIndex)
      const editMediaFileList = swappedPhotos.map<pimApi.products.wb.EditWBMedia>(
        (photo: pimApi.products.wb.WBMedia, index) => ({
          id: photo.id,
          versionNo: photo.versionNo,
          indexNumber: index,
          mediaGlobal: photo.mediaGlobal
        })
      )
      setPhotos(swappedPhotos)
      updateMediaMutation(
        {
          productId,
          mediaType: 'photos',
          editMediaFileList
        },
        {
          onError: () => {
            setPhotos(localPhotos)
          }
        }
      )
    }

    const handleDelete = (deletingPhoto: DeletingObject) => {
      dialogModel.openDialog({
        component: MediaDeleteConfirmModal,
        onAccept: () => {
          deleteMediaMutation(
            { productId, mediaType: 'photos', ...deletingPhoto },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
                deleteItem: deletingPhoto,
                path: 'photos'
              }),
            }
          )
        },
      })
    }

    const handleSort = () => {

      const copyOfLocalPhotos = [...localPhotos]
      const sortedPhotos = copyOfLocalPhotos.sort((a, b) => a.fileName!.localeCompare(b.fileName!, undefined, {
        numeric: true,
        sensitivity: 'base'
      }))

      const editMediaFileList = sortedPhotos.map<pimApi.products.wb.EditWBMedia>(
        (photo, index) => ({
          id: photo.id,
          versionNo: photo.versionNo,
          indexNumber: index,
          mediaGlobal: photo.mediaGlobal
        }))
      setPhotos(sortedPhotos)
      updateMediaMutation(
        {
          productId,
          mediaType: 'photos',
          editMediaFileList
        },
        {
          onError: () => {
            setPhotos(localPhotos)
          }
        }
      )
    }

    const handleBulkDelete = () => {
      dialogModel.openDialog({
        component: ({ accept, close }) => (
          <MediaDeleteConfirmModal title="Удалить все изображения?" accept={accept} close={close}/>
        ),
        onAccept: () => {
          bulkDeleteMediaMutation(
            { productId, mediaType: 'photos', value: localPhotos },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.wb.getWBProductQueryKey(productId),
                deleteItem: localPhotos,
                path: 'photos'
              }),
            }
          )
        }
      })
    }


    const handleBookmarkToggle = ({ id: updatedId, versionNo, indexNumber, mediaGlobal }: pimApi.products.wb.WBMedia) => {
      updateMediaSingleMutation(
        {
          productId,
          mediaType: 'photos',
          newProductMedia: {
            id: updatedId,
            versionNo,
            indexNumber,
            mediaGlobal: !mediaGlobal
          },
        },
      )
    }
    const handleMassImportMedia = () => {
      bulkImportMediaFromProduct({ productId, marketplaceName: 'wb' },
        {
          onSuccess: () => {
            snackActions.info('Скопировано')
          }
        })
    }

    const PhotosSorted = () => {
      const copyLocalPhotos = [...localPhotos]
      return (JSON.stringify(localPhotos) === JSON.stringify(copyLocalPhotos.sort(
        (a, b) => a.fileName!.localeCompare(b.fileName!, undefined, {
          numeric: true,
          sensitivity: 'base'
        }))))
    }

    const isQueriesInProgress =
      createByURLInProgress ||
      createInProgress ||
      updateInProgress ||
      deleteInProgress ||
      updateSingleInProgress

    const settingsOptions: Array<any> = [
      {
        label: 'Отсортировать',
        disabled: disabled || localPhotos.length < 2 || PhotosSorted(),
        icon: <SortByAlphaIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
        handler: handleSort
      },
      {
        label: 'Импорт в товар',
        icon: <UploadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        divider: true,
        handler: handleMassImportMedia
      },
      {
        label: 'Очистить',
        icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        type: 'error',
        handler: handleBulkDelete
      },
    ]

    const getFilePath = (mediaType: WbMediaTypes) =>
      (photoId, size) => pimApi.products.wb.getWBMediaApiPath(productId, photoId, mediaType, size)

    const handleOpenGallery = (item) => {
      dialogModel.openDialog({
        component: ({ close }) => (
          <MediaGalleryModal
            mediaVariation="image"
            close={close}
            mediaType="Фото"
            getFilePath={getFilePath('photos')}
            clickedItem={item}
            items={localPhotos}
          />
        )
      })
    }
    
    return (
      <div>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <span style={{ fontWeight: '500', color: '#000000DE' }}>Фото</span>
                <span style={{ color: '#0000008A' }}>{ localPhotos.length }/30</span>
              </div>
              <SettingsMenu
                className="settingsMenu"
                options={settingsOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                iconSize="small"
                iconType={<MoreVert />}
              />
              
            </div>
          </Grid>
        </Grid>
        <ContentsWrapper>
          {isQueriesInProgress && (
            <CircularProgressWrap>
              <CircularProgressStyled />
            </CircularProgressWrap>
          )}
          <UploadGallery
            mediaType="фото"
            withMainElement={true}
            mediaLimit={30}
            supportedFileExtension={PICTURE_SUPPORTED_FILE_EXTENSION}
            localMedia={localPhotos}
            addDisabled={disabled || localPhotos.length >= 30}
            mediaRequirement={PhotoRequirements}
            urlUploadingExist={true}
            onFileValidate={onFileValidate('photos')}
            onUploadFile={onFileUpload('photos')}
            onDragChange={onDragChange}>
            {localPhotos?.map((item, index) => (
              <PhotoItem
                key={item.id}
                index={index}
                openGallery={() => {handleOpenGallery(item)}}
                item={item}
                productId={productId}
                onFileDelete={handleDelete}
                onBookmarkToggle={handleBookmarkToggle}
                disabled={disabled}
              />
            ))}
          </UploadGallery>
        </ContentsWrapper>
      </div>
    )
  }
)
