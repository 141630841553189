import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-cartesian-axis-tick-value {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9E9E9E;
  }

  .recharts-cartesian-axis-line {
    stroke: #9e9e9e;
  }

  .recharts-cartesian-axis-tick-line {
    stroke: #9e9e9e;
  }

  .recharts-cartesian-grid {
    line {
      stroke: #E0E0E0;
    }
  }

  .recharts-tooltip-wrapper {
    z-index: 1;
  }
`