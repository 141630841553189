import { memo } from 'react'
import { useField } from 'react-final-form'
import { Box, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { Help } from 'shared/ui/styled/Help'
import { SxProps } from '@mui/system'

import {
  ArrowForwardStyled,
  BoxStyled,
  StyledBookmark,
  StyledBookmarkActive,
  StyledIconButton,
} from './styled'

interface InputsWrapperProps {
  children: [
    leftInput?: React.ReactNode | null,
    rightInput?: React.ReactNode | null,
    attributeOperation?: React.ReactNode | null
  ]
  arrow?: React.ReactNode
  description?: React.ReactNode
  tooltipIconSx?: SxProps
  containerSx?: SxProps
  globalAttributeName?: string
  hideGoodsInfo?: boolean
}

const SX_ARROW = { fontSize: 32 }

const ARROW_WIDTH = 50

export const InputsWrapper = memo(({
  children: [leftInput, rightInput, attributeOperation],
  arrow = <ArrowForwardStyled sx={SX_ARROW} />,
  description,
  globalAttributeName,
  tooltipIconSx,
  containerSx,
  hideGoodsInfo
}: InputsWrapperProps) => {
  const bookmarkField = useField(globalAttributeName || '_')

  const handleBookmarkToggle = () => {
    bookmarkField.input.onChange(!bookmarkField.input.value)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...containerSx }}>

      {!hideGoodsInfo &&
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            {leftInput}
          </Box>

          <Box mx="12px" height="inherit" width={ARROW_WIDTH}>
            <BoxStyled>{arrow}</BoxStyled>
          </Box>

        </Box>
      }
      
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '100%' }}>
          {rightInput}
        </Box>
        <Box minWidth="34px" maxWidth="34px" mt="3px" ml={1} height="inherit" display="flex" alignItems="center">
          {description && (
            <Tooltip title={description} placement="right" sx={tooltipIconSx}>
              <Box pl={1}>
                <Help>
                  <InfoOutlined />
                </Help>
              </Box>
            </Tooltip>
          )}
          {globalAttributeName && (
            <StyledIconButton size="small" onClick={handleBookmarkToggle}>
              {bookmarkField.input.value ? (
                <StyledBookmarkActive />
              ) : (
                <StyledBookmark />
              )}
            </StyledIconButton>
          )}
          {attributeOperation}
        </Box>
      </Box>
    </Box>
  )
}
)
