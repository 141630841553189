import { FC } from 'react'
import { getGoodsAttributeComponent } from 'entities/pim/GoodsAttributes'
import * as React from 'react'

import { AutocompleteDictionaryAttributeFieldMemo } from './AutocompleteDictinaryAttributeField'
import { WrapperRightComponent } from './styled'

import { getLabelPrepared } from '../../lib'
import { OzonMapingAttribute, ProductAttributeValue } from '../../lib/getNormalizedAttributes'

interface RightComponentProps {
  right: OzonMapingAttribute,
  onChange: (value: Array<ProductAttributeValue>) => void,
  disabled?: boolean
}

const noop = () => {}
const emptyObject = {}


export const RightComponent: FC<RightComponentProps> = ({
  right,
  onChange,
  disabled
}) => {
  const { multipleValues, valueType } = right

  const label = getLabelPrepared(right)

  const isCheckBox = valueType === 'boolean'

  const value = right.values ?? []

  if (right.fetchCatalog) {
    const input = {
      name: 'fake',
      value,
      multiple: multipleValues,
      onBlur: noop,
      onChange,
      onFocus: noop,
    }

    return (
      <WrapperRightComponent $required={right.required}>
        <AutocompleteDictionaryAttributeFieldMemo
          fetchCatalog={right.fetchCatalog}
          input={input}
          meta={emptyObject}
          label={label}
          isAspect={right.isAspect}
          maxCount={right.maxCount}
          disabled={disabled}
        />
      </WrapperRightComponent>
      
    )
  }

  const rightComponent = getGoodsAttributeComponent({
    multipleValues,
    attributeDataType: valueType,
    value,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange([{
      value: (isCheckBox ? event.target.checked : event.target.value) as string,
      dictionaryValueId: 0
    }])
  }

  return (
    <WrapperRightComponent $required={right.required}>
      <rightComponent.Component
        {...rightComponent.props}
        input={{
          name: right.attributeName,
          ...(isCheckBox ? {
            defaultChecked: value[0]?.value.toString() === 'true',
          } : {
            defaultValue: value[0]?.value || '',
          }),
          onFocus: noop,
          onBlur: handleChange,
          ...(isCheckBox ? {
            onChange: handleChange,
          } : {})
        }}
        isAspect={right.isAspect}
        disabled={disabled}
        label={label}
      />
    </WrapperRightComponent>
  )
}