import React from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { DoubleSmallWidget } from 'widgets/DashboardWidgets/DoubleSmallWidget'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { subtractDays } from 'shared/lib/utils/subtractDays'

export const DoubleSmallSalesDynamicsWidget = () => {
  const utc = new Date()

  const dateFrom = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 14)
  const dateTo = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 1)

  const { data: ordersData } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })

  const { data: salesData } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })

  const half = ordersData ? ordersData?.data.data.length / 2 : 0

  const currentOrdersQuantity = ordersData?.data.data.slice(half).reduce((sum, elem) => sum + elem.items, 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,half).reduce((sum, elem) => sum + elem.items, 0)

  const currentSalesQuantity = salesData?.data.data.slice(half).reduce((sum, elem) => sum + elem.quantity, 0)
  const prevSalesQuantity = salesData?.data.data.slice(0,half).reduce((sum, elem) => sum + elem.quantity, 0)


  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const salesDiff = (currentSalesQuantity !== undefined && prevSalesQuantity !== undefined) ?
    (currentSalesQuantity - prevSalesQuantity) : 0

  const chartUrl = generatePath('/analytics/charts?range=0&tab=1')

  return (
    <>
      {(ordersData?.data.data && salesData?.data.data) ?
        <DoubleSmallWidget
          title="Динамика продаж за неделю"
          chartUrl={chartUrl}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            name: 'Заказы'
          }}
          rightData={{
            data: currentSalesQuantity || 0,
            diff: salesDiff,
            name: 'Выкупы'
          }}
        />
        :
        <Skeleton/>
      }
    </>
  )
}