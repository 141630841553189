import { Box } from '@mui/material'
import { AlertComponent } from 'entities/pim/product'

export interface StatusInfo {
  name?: string;
  description?: string;
  status?: string;
}

export const ProductStatus = ({
  cardStatus,
  syncStatus,
}: {
  cardStatus: StatusInfo,
  syncStatus: StatusInfo,
}) => (
  <>
    <Box mb={ 0.5 }>
      { cardStatus?.status && (
        <AlertComponent
          status={ cardStatus.status }
          title={ cardStatus?.name ?? '' }
          message={ cardStatus?.description }
        />
      ) }

    </Box>
    <Box mb={ 1 }>
      { syncStatus?.status && (
        <AlertComponent
          status={ syncStatus.status }
          title={ syncStatus.name ?? '' }
          message={ syncStatus.description }
        />
      ) }
    </Box>

    {/* {!disabled && ( */ }
    {/*  <Box> */ }
    {/*    <LoadingButton */ }
    {/*      fullWidth={ true } */ }
    {/*      loading={ isLoading } */ }
    {/*      onClick={ onClick } */ }
    {/*      variant="contained" */ }
    {/*    > */ }
    {/*      Обновить статус */ }
    {/*    </LoadingButton> */ }
    {/*  </Box> */ }
    {/* )} */ }
  </>
)

