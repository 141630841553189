import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  margin-top: 28px;
  
  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }

`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledTableCell = styled(Table.Cell)<{ $isArchived?: boolean }>`
  padding: 0 8px 0 8px;
  color:  ${({ $isArchived }) => $isArchived ? '#a4a4a4' : '' };
`
export const UploadWrapper = styled(Box)`
  input {
    display: none;
  }
`

export const StyledTableHeaderWrapper = styled(TableHeaderRow.Cell)`
  .MuiTableSortLabel-icon {
    display: none;
  }

  .Title-title {
    display: none;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator {
    transform: none;
  }

  &:hover {
    .MuiTableSortLabel-root {
      color: rgba(0,0,0,0.54);
      .MuiIconButton-root {
        opacity: 1 !important;
      }
    }
  }

  .MuiSelect-select {
    width: 17px;
    padding-right: 8px !important;
    padding-left: 2px !important;
  }
  
  /* .MuiInputBase-formControl {
    margin-right: -10px;
  } */



  .ResizingControl-root {
    width: 20px;
    padding-right: 32px;
  }
/* 
  .MuiListItemIcon-root {
    width: 0;
  } */
  
  &.MuiTableCell-head {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInput-root {
    padding-bottom: 10px;
    :before {
      border: none !important;
    }

    :after {
      border: none !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 22px);
  }

  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    &.Mui-focused {
      font-weight: 400;
      color: #007DFF;
    }
  }
`

export const StyledImportProgress = styled.div`
  position: relative;
  height: 34px;
    
    .progress-text {
        color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 7px;
        left: 6px;
        font-size: 12px;
    }
    
    .custom-progress-bar {
        animation: progress-rotating 1.4s linear infinite;
    }

    @keyframes progress-rotating {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`