import { useState } from 'react'
import { Box } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styled from 'styled-components'

import { TooltipTitle, TooltipButton, CodeContainer, CodeItem } from './styled'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    border: '1px solid #dadde9',
    padding: 0,
  },
}))

interface BarcodesProps {
  barcodes: Array<any>
  disabled?: boolean
}

export const BarcodesTooltip = ({ barcodes, disabled }: BarcodesProps) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  if (barcodes.length <= 1) {
    return <span title={barcodes[0]}>{barcodes[0]}</span>
  }

  const codesList = barcodes.filter((_, index) => index >= 1).map(codeItem => (
    <CodeItem mt={2} key={codeItem} title={codeItem}>
      {codeItem}
    </CodeItem>
  ))

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box display="flex">
        <CodeItem title={barcodes[0]}>
          {barcodes[0]}
        </CodeItem>
        
        <CustomTooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          title={
            <Box p={3}>
              <Box mb={0.5}> 
                <TooltipTitle>Штрих-коды продукта</TooltipTitle>
              </Box>
              <CodeContainer ml={1.5}>
                {codesList}
              </CodeContainer>
            </Box>
          }
        >
          <TooltipButton type="button" onClick={handleTooltipOpen} $disabled={disabled}>
            +{barcodes.length - 1}
          </TooltipButton>
        </CustomTooltip>
      </Box>
    </ClickAwayListener>
  )
}
