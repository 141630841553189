import { AxiosResponse } from 'axios'
import {
  Box,
  Typography,
  Divider
} from '@mui/material'
import { history } from 'app/providers/with-router'
import { pimApi } from 'shared/api'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { downloadFile } from 'shared/lib/utils'
import React, { memo } from 'react'

export const BulkGoodsUnarchive = memo(() => {
  const { mutate: downloadMutation, isLoading: isDownloading } = pimApi.goods.useDownloadGoodsMassArchiveTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } = pimApi.goods.useUploadGoodsMassArchiveFile()

  const download = () => {
    downloadMutation({ method: 'unarchive' },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(response.headers['content-disposition']?.replace(
            'inline; filename=',
            ''
          ))
        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
        method: 'unarchive',
      },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box>
      <Typography fontSize="20px" fontWeight={400} lineHeight="160%">
        Массовая разархивация товаров
      </Typography>
      <Box display="flex" justifyContent="end" mt={7} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <Divider/>
      <Box mb="28px">
        <BulkFileActionsFooter
          download={download}
          upload={upload}
          isUploading={isUploading}
          isDownloading={isDownloading}
          onGoBack={handleGoBack}
        />
      </Box>
    </Box>
  )
}
)
