import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { Box, Grid, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { GoodsSupplierCode } from 'domains/pim/goods'
import { CheckboxField, Field } from 'shared/ui/components/form'
import { getIsUniqueElements } from 'shared/lib/utils/unique'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { snackActions } from 'shared/lib/react/snackbar'
import { pimApi } from 'shared/api'

import { ICodeExist, ICodeNew } from './inteface'

import { useGoodsCode } from '../../../../model/hooks'
import { InputActions } from '../../../../components/common'
import { InputField } from '../../../../components'

interface CodeProps {
  name: string;
  isCodeUnique: boolean;
  index: number;
  fields: FieldArrayRenderProps<
    ICodeExist | ICodeNew,
    HTMLElement
    >['fields'];
  supplier: pimApi.goods.GoodsSupplier
  productId: number
  clearCode: (index: number) => void
  disabled?: boolean
}

function isNewCode(code: ICodeExist | ICodeNew): code is ICodeNew {
  return !isExistingCode(code)
}
function isExistingCode(code: ICodeExist | ICodeNew): code is ICodeExist {
  return typeof (code as ICodeExist)?.id === 'number'
}

export const Code = ({
  name,
  index,
  isCodeUnique,
  clearCode,
  productId,
  supplier,
  fields,
  disabled,
}: CodeProps) => {

  const {
    create,
    update,
    remove
  } = useGoodsCode()
  const fieldValue = fields.value[index]
  const isEmptyField = fieldValue.code === ''

  const handleRemove = () => {
    if (supplier.id && isExistingCode(fieldValue)) {
      remove(productId, supplier.id, fieldValue)
    }
    fields.remove(index)
  }

  const isNew = isNewCode(fieldValue)

  const handleCodeChange: () => void = () => {
    if(!supplier.id) return
  
    if(fieldValue?.code?.length > 100) return

    const isUniqueElements = getIsUniqueElements(fields.value.map(({ code }) => code))
    if (!isUniqueElements) {
      // TODO: Выводить ошибку в errors?.codes через yup и поле подсвечивать красным
      snackActions.error('Код товара поставщика не уникален')

      clearCode(index)
      return
    }
    const data = {
      ...{ code: '', expiredFlag: false },
      ...fieldValue,
      supplierId: supplier.id
    }

    if(isEmptyField) return

    if (isNew) {
      create(productId, supplier.id, data as GoodsSupplierCode)
    } else {
      update(productId, supplier.id, data as ICodeExist)
    }
  }

  return (
    <Grid item={true} xs={3}>
      <div>
        <InputWrapper>
          <Field
            name={`${name}.code`}
            component={InputField}
            autoComplete="off"
            onBlur={handleCodeChange}
            error={!isCodeUnique}
            disabled={fieldValue.expiredFlag || disabled}
          />
        </InputWrapper>

        <InputActions>
          {!isNew && (
            <Field
              name={`${name}.expiredFlag`}
              label="Устаревший код"
              component={CheckboxField}
              type="checkbox"
              onBlur={handleCodeChange}
              disabled={isNew || disabled}
            />
          )}
          <Box ml="auto">
            <IconButton aria-label="delete" onClick={handleRemove} size="large" disabled={disabled}>
              <Delete/>
            </IconButton>
          </Box>
        </InputActions>
      </div>
    </Grid>
  )
}
