import { Avatar } from '@mui/material'
import styled from 'styled-components'

export const CompanyImg = styled(Avatar)<{ $inArchive?: boolean}>`
  border-radius: 4px;
  width: 42px;
  height: 42px;
  filter: ${({ $inArchive }) => $inArchive ? 'grayscale(100%)' : 'grayscale(0%)'};
  opacity: ${({ $inArchive }) => $inArchive ? '0.5' : '1'};
  background-color: #FFFFFF;
`