import { isEmpty, isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { pimApi } from 'shared/api'
import { useGoodsProductsQuery } from 'shared/api/pim/goods/getGoodsProducts'

import { GoodsEditPageStep, GOODS_EDIT_PAGE_STEPS } from '../router-helper'
import { TabOption } from '../../components/Tabber/Tabber'

export const useTabsForCurrentGoods = (goods: pimApi.goods.Goods | undefined): Array<TabOption<GoodsEditPageStep>> => {
  const GoodsSuppliers = goods?.suppliers || []
  const isEmptySuppliers = isEmpty(GoodsSuppliers)
  const isEmptyContragent = GoodsSuppliers.some(supplier => supplier.contragent === undefined)
  const hasLessThanTwoSuppliers = GoodsSuppliers.length <= 1
  const isDeliveryDisabled = isEmptySuppliers || (isEmptyContragent && hasLessThanTwoSuppliers)
  const shouldHideDelivery = goods?.goodsType === 'bundle' || goods?.goodsType === 'pack'

  const { data: goodsProducts, isFetched } = useGoodsProductsQuery(goods?.id)
  const isProductsDisabled = !isFetched || isEmptyArray(goodsProducts)

  const isExistsInDisabled = !goods?.existsInAssortment && isEmpty(goods?.existsInBundles) && isEmpty(goods?.existsInPacks)

  if(shouldHideDelivery) return [
    {
      label: 'Общее',
      value: GOODS_EDIT_PAGE_STEPS[0],
    },
    {
      label: 'характеристики',
      value: GOODS_EDIT_PAGE_STEPS[2],
    },
    {
      label: 'Медиа',
      value: GOODS_EDIT_PAGE_STEPS[3],
    },
    {
      label: 'В ДР ТОВАРАХ',
      value: GOODS_EDIT_PAGE_STEPS[5],
      disabled: isExistsInDisabled
    },
    {
      label: 'Продукты',
      value: GOODS_EDIT_PAGE_STEPS[6],
      disabled: isProductsDisabled
    },
    {
      label: 'Конкуренты',
      value: GOODS_EDIT_PAGE_STEPS[7],
      disabled: isProductsDisabled
    },
    {
      label: 'История изменений',
      value: GOODS_EDIT_PAGE_STEPS[8]
    }
  ]
  return [
    {
      label: 'Общее',
      value: GOODS_EDIT_PAGE_STEPS[0],
    },
    {
      label: 'Логистика',
      value: GOODS_EDIT_PAGE_STEPS[1],
      disabled: isDeliveryDisabled
    },
    {
      label: 'характеристики',
      value: GOODS_EDIT_PAGE_STEPS[2],
    },
    {
      label: 'Медиа',
      value: GOODS_EDIT_PAGE_STEPS[3],
    },
    {
      label: 'В ДР ТОВАРАХ',
      value: GOODS_EDIT_PAGE_STEPS[5],
      disabled: isExistsInDisabled
    },
    {
      label: 'Продукты',
      value: GOODS_EDIT_PAGE_STEPS[6],
      disabled: isProductsDisabled
    },
    {
      label: 'Конкуренты',
      value: GOODS_EDIT_PAGE_STEPS[7],
      disabled: isProductsDisabled
    },
    {
      label: 'История изменений',
      value: GOODS_EDIT_PAGE_STEPS[8]
    },
  ]
}
