import { Box, Theme } from '@mui/material'
import styled from 'styled-components'

interface StyledSummaryItemType {
  theme: Theme;
  summaryItemNotValid: boolean;
}

export const StyledSummaryItem = styled(Box)<{ summaryItemNotValid: boolean }>`
  font-weight: bold;
  color: ${({ summaryItemNotValid, theme }: StyledSummaryItemType) => (
    summaryItemNotValid
      ? theme.palette.error.main
      : theme.palette.text.primary
  )};
`

export const StyledDifference = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.palette.error.main};
`
