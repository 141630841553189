import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

export const columns = {
  contragentId: 'contragentId',
  selected: 'selected',
  shortName: 'shortName',
  dateIn: 'dateIn',
  dateOut: 'dateOut'
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<any>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.selected,
      columnName: columns.selected,
      title: 'Доступ',
      width: 128,
      sortingEnabled: false,
      cellType: CellType.RowSelect
    },
    {
      name: columns.shortName,
      columnName: columns.shortName,
      title: 'Наименование',
      width: 400,
      sortingEnabled: true,
    },
    {
      name: columns.dateIn,
      columnName: columns.dateIn,
      title: 'Доступ открыт',
      width: 260,
      sortingEnabled: false,
      cellType: CellType.UserDateIn
    },
    {
      name: columns.dateOut,
      columnName: columns.dateOut,
      title: 'Доступ закрыт',
      width: 260,
      sortingEnabled: false,
      cellType: CellType.UserDateOut
    },
    {
      name: columns.contragentId,
      columnName: columns.contragentId,
      title: ' ',
      width: 'auto',
      sortingEnabled: false,
      cellType: CellType.UserDateEdit,
    }
  ]
}
