import { SettingsMenu } from 'shared/ui/components'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'

import { ProductSettingsHandlers } from '../model/ProductSettingsHandlers'

export const TabPopupMenu = () => {
  const { settingsOptions } = ProductSettingsHandlers()
  return (
    <SettingsMenu
      options={settingsOptions}
      iconType={<MoreVert />}
    />
  )
}
