import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { customerApi } from 'shared/api'

interface EditCompany {
  companyId: UniqueId,
  editedCompany: customerApi.EditCompany
}

export const editCompany = ({ companyId, editedCompany }: EditCompany) =>
  api.put<customerApi.Company>(
    `/customer/companies/${companyId}`,
    editedCompany
  )

export const useEditCompanyMutation = () =>
  useMutation<
    AxiosResponse<customerApi.Company>,
    AxiosError<errorType>,
    EditCompany
  >(editCompany)