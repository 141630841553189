import React from 'react'
import { useField } from 'react-final-form'
import { Box, Grid, TextField } from '@mui/material'
import {
  CatalogSelectField,
  Field,
  InputField,
} from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { pimApi } from 'shared/api'

const description = {
  sizes: 'Сначала измерьте длину и высоту, оставшаяся сторона — это ширина. Перед измерением ширины: 1) Одежда, текстиль, наборы для вышив'
  + 'ания — сложите товар в упаковке пополам. 2) Карты и интерьерные наклейки — скрутите в рулон. Ширина рулона — это его диаметр. 3) Шири'
  + 'на книжного комплекта — это ширина всей стопки книг, которые входят в комплект. Высота — это наименьшая сторона упаковки товара. Пере'
  + 'д измерением высоты: 1) Одежда, текстиль, наборы для вышивания — сложите товар в упаковке пополам. 2) Карты и интерьерные наклейки — '
  + 'скрутите в рулон. Высота рулона — это его диаметр. 3) Высота книжного комплекта — это высота всей стопки книг, которые входят в компл'
  + 'ект. Длина — это наибольшая сторона упаковки товара. Перед измерением длины: 1) Одежда, текстиль, наборы для вышивания — сложите това'
  + 'р в упаковке пополам. 2) Карты и интерьерные наклейки — скрутите в рулон. Длина рулона — самая большая величина. 3) Длина книжного ко'
  + 'мплекта — это длина всей стопки книг, которые входят в комплект. Укажите вес единицы товара вместе с упаковкой в граммах. Введите тол'
  + 'ько число.',
  basePackagingRequirement: 'Требования по упаковке https://seller-edu.ozon.ru/docs/fbs/ozon-logistika/trebovaniya-k-upakovke.html',
  packagingRequirement: 'Требования по упаковке https://seller-edu.ozon.ru/docs/fbs/ozon-logistika/trebovaniya-k-upakovke.html',
}

interface SizesFormProps {
  goods: pimApi.goods.Goods | null
  disabled?: boolean
  showGoodsInfo: boolean
}

const SizesForm: React.FC<SizesFormProps> = ({ goods, disabled, showGoodsInfo }) => {
  const editPossible = useField('editPossible')
  const isDraft = !editPossible.input.value

  return (
    <>
      <InputsWrapper hideGoodsInfo={!showGoodsInfo} description={description.sizes}>
        <Box>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <TextField
                label="Ширина, см"
                variant="outlined"
                size="small"
                fullWidth={true}
                value={goods?.width ?? ''}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Высота, см"
                variant="outlined"
                size="small"
                fullWidth={true}
                value={goods?.height ?? ''}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Глубина, см"
                variant="outlined"
                size="small"
                fullWidth={true}
                value={goods?.depth ?? ''}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label="Вес с упаковкой, кг"
                variant="outlined"
                size="small"
                fullWidth={true}
                value={goods?.weight ?? ''}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid item={true} container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <Field
              name="width"
              label="Ширина, мм"
              component={InputField}
              type="number"
              errorAfterTouch={true}
              disabled={isDraft || disabled}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <Field
              name="height"
              label="Высота, мм"
              component={InputField}
              type="number"
              errorAfterTouch={true}
              disabled={isDraft || disabled}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <Field
              name="depth"
              label="Глубина, мм"
              component={InputField}
              type="number"
              errorAfterTouch={true}
              disabled={isDraft || disabled}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <Field
              name="weight"
              label="Вес с упаковкой, гр"
              component={InputField}
              type="number"
              errorAfterTouch={true}
              disabled={isDraft || disabled}
            />
          </Grid>
        </Grid>
      </InputsWrapper>

      <InputsWrapper hideGoodsInfo={!showGoodsInfo} arrow={false}>
        <></>
        <>
          <Grid item={true} container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Field
                name="volumeWeight"
                component={InputField}
                label="Объёмный вес"
                disabled={true}
              />
            </Grid>
          </Grid>
        </>
      </InputsWrapper>

      <InputsWrapper hideGoodsInfo={!showGoodsInfo} arrow={false} description={description.basePackagingRequirement}>
        <></>
        <>
          <Field
            name="basePackagingrequirement"
            component={InputField}
            label="Требования маркетплейса по упаковке"
            disabled={true}
          />
        </>
      </InputsWrapper>


      <InputsWrapper
        hideGoodsInfo={!showGoodsInfo}
        arrow={false}
        description={description.packagingRequirement}
        tooltipIconSx={{ height: 40 }}>
        <></>
        <>
          <Field
            name="packagingrequirementId"
            component={CatalogSelectField}
            catalog="pim/packagingrequirements"
            label="Упаковка*"
            errorAfterTouch={true}
            disabled={isDraft || disabled}
          />
        </>
      </InputsWrapper>
    </>
  )
}

export default SizesForm
