import React, { useRef, useState } from 'react'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import _uniqueId from 'lodash/uniqueId'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { StyledDragNDrop } from './styled'


export const DragDropFile = ({ requirement, supportedFileExtension }: { requirement: string, supportedFileExtension?: Array<string> } ) => {
  const [allFiles, setAllFiles] = useState<any>([])

  const inputRef = useRef<HTMLInputElement>(null)
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setAllFiles(prevState => {
        if (isNotEmptyArray(prevState)) {
          return prevState?.concat(Object.values(e.dataTransfer.files).map(el => ({
            id: _uniqueId('prefix-'),
            file: el
          })))
        }
        return Object.values(e.dataTransfer.files).map(el => ({
          id: _uniqueId('prefix-'),
          file: el
        }))
      })
    }
  }

  // triggers when file is selected with click
  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAllFiles(prevState => {
        if (isNotEmptyArray(prevState)) {
          return prevState?.concat(Object.values(e.target.files).map(el => ({
            id: _uniqueId('prefix-'),
            file: el
          })))
        }
        return Object.values(e.target.files).map(el => ({
          id: _uniqueId('prefix-'),
          file: el
        }))
      })
    }
  }
  const onButtonClick = () => {
    if (inputRef) {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
    // @ts-ignore
    inputRef?.current?.click()
  }

  const DragAndDropPaper = () =>
    (
      <StyledDragNDrop onClick={onButtonClick}>
        <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
          <input
            accept={supportedFileExtension?.join(',') || ''}
            ref={inputRef}
            type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <div id="label-file-upload">
            <PhotoLibraryIcon/>
            <span className="description">Выберите или перетащите файлы в эту область</span>
            {/* eslint-disable-next-line react/button-has-type */}
            <span className="upload-button">{ requirement }</span>
          </div>
          {/* eslint-disable-next-line max-len */}
          <div id="drag-file-element" onDragOver={handleDrag} onDrop={handleDrop} />
        </form>
      </StyledDragNDrop>
    )

  return { allFiles, setAllFiles, DragAndDropPaper }
}