import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, Box, Typography } from '@mui/material'
import { ReactNode, useEffect } from 'react'

import { DialogTitleWithBtn } from './DialogTitleWithBtn'

interface ModalContentProps {
  close: () => void;
  accept?: () => void;
  title?: string;
  description?: string | ReactNode
  acceptBtnText?: string;
  closeBtnText?: string;
  acceptBtnColor?: 'primary' | 'error';
  closeBtnColor?: 'primary' | 'error' | 'inherit';
  isLoading?: boolean;
  variant?: 'text' | 'contained';
}

const SX_TITLE = { fontSize: '20px', minWidth: '480px', padding: '24px 24px 16px 24px' }

export const ModalContent = ({
  close,
  accept,
  title,
  description,
  acceptBtnText,
  closeBtnText,
  acceptBtnColor = 'primary',
  closeBtnColor = 'primary',
  isLoading,
  variant = 'text'
}: ModalContentProps) => {

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && event.target.value === undefined && accept) {
        event.preventDefault()
        accept()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])
  return (
    <Box>
      <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
        {title}
      </DialogTitleWithBtn>

      {description && (
        <Box mx={3} mb={3}>
          <Typography variant="body2">
            {description}
          </Typography>
        </Box>
      )}

      <Box mb={3} mr={3} p={0}>
        <DialogActions sx={{ padding: '0' }}>
          <Button
            variant="text"
            color={closeBtnColor}
            onClick={close}
          >
            {closeBtnText || 'ОТМЕНА'}
          </Button>
          {accept &&
          <LoadingButton
            loading={isLoading}
            variant={variant}
            color={acceptBtnColor}
            onClick={accept}
          >
            {acceptBtnText}
          </LoadingButton>
          }
        </DialogActions>
      </Box>
    </Box>
  )
}