import { tradeApi } from 'shared/api'

export const getClassName = (coefficientElem: tradeApi.AcceptanceCoefficients, selectedDistributions?: Array<number>) => {

  if (coefficientElem.coefficient === -1) {
    if (selectedDistributions?.includes(coefficientElem.id)) {
      return 'box-type-coefficient disabled selected'
    }
    return 'box-type-coefficient disabled'
  }

  if (coefficientElem.coefficient === 0) {
    if (selectedDistributions?.includes(coefficientElem.id)) {
      return 'box-type-coefficient free selected'
    }
    return 'box-type-coefficient free'
  }

  if (selectedDistributions?.includes(coefficientElem.id)) {
    return 'box-type-coefficient selected'
  }

  return 'box-type-coefficient'
}