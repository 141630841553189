import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

export const DateCell = ({ date, dateFormat }: { date?: string,  dateFormat?: DateFormats}) => {
  if (!date) return null
  
  return (
    <>
      {
        dateFormat
          ?  <span>{format(new Date(date), dateFormat)}</span>
          : <>
            <span>{format(new Date(date), DateFormats.dayMonth)}</span>{' '}
            <span>{format(new Date(date), DateFormats.hours24Minutes)}</span>
          </>  
      }
    </>
  )
}
