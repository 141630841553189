import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

const downloadSupplyOrderInProductsForm = async (supplyOrderId?: UniqueId) =>
  api.get<File>(
    `/trade/orders/supplyorders/${supplyOrderId}/productsform`,
    {
      responseType: 'blob',
    }
  )

export const useDownloadSupplyOrderInProductsForm = () =>  useMutation(downloadSupplyOrderInProductsForm)