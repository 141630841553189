import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { GOODS_CREATE, GOODS_EDIT, GOODS_LIST, GOODS_VARIANT } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'

import { GoodsCreate } from './create'
import { GoodsEdit } from './edit'
import { GoodList } from './GoodList'
import { ProductVariant } from './variant'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: GOODS_CREATE,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: GoodsCreate,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: GOODS_EDIT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: GoodsEdit,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: GOODS_LIST,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: GoodList,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: GOODS_VARIANT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: ProductVariant,
  },
] as Array<Route>
