import React from 'react'
import styled from 'styled-components'


const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`
export const DateCell = ({ row }) => {
  const date = new Date(row.adviceDate)
  
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  
  const formattedDate = `${day}.${month}`
  const formattedTime = `${hours}:${minutes}`
  
  return (
    <DateWrapper>
      <span>{ formattedDate }</span>
      <span>{ formattedTime }</span>
    </DateWrapper>
  )
}