import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  .TableContainer-root {
    max-height: calc( 100vh - 380px);
  }
  table {
    border-collapse: collapse !important;
    min-width: 1440px !important;
    thead {
      white-space: pre-wrap;
    }
  }
  tfoot {
    bottom: -1px !important;
    tr {
      background-color: rgba(247,247,247,0.5);
    }
    tr td div{
      white-space: nowrap;
    }
  }
  

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }
  
  .TableCell-cell {
    height: 50px;
  }

  .Toolbar-toolbar {
    justify-content: space-between;  
  }
  
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const TableWrapper = styled.div`
  .Pager-pager {
    height: 56px;
  }
`