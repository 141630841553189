import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Box } from '@mui/material'

import { Wrapper, Title, Description } from './styled'

interface NotFoundMessageCardProps {
  title: string;
  description?: string;
}

export const NotFoundMessageCard = ({ title, description }: NotFoundMessageCardProps) => (
  <Wrapper py={1} px={2}>
    <ReportProblemOutlinedIcon color="warning" />
    <Box ml={1}>
      <Box mb={0.5}>
        <Title>{title}</Title>
      </Box>
      
      {description && (
        <Description>{description}</Description>
      )}
    </Box>
  </Wrapper>
)