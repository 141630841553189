import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api'

export const getCurrentBrandQueryKey = (brandId: UniqueId) =>
    ['get', 'brands', brandId] as const

type GetCurrentBrandQueryKey = ReturnType<typeof getCurrentBrandQueryKey>


function getCurrentBrand(brandId: UniqueId) {
  return api.get<pimApi.brands.Brand>
  (`/brands/${brandId}`).then((res) => res.data)
}

const getCurrentBrandFn = (context: QueryFunctionContext<GetCurrentBrandQueryKey>) => {
  const [, ,brandId] = context.queryKey
  return getCurrentBrand(brandId)
}

export const useGetCurrentBrandQueryKey = (brandId: UniqueId) =>
  useQuery<
        pimApi.brands.Brand,
        errorType,
        pimApi.brands.Brand,
        GetCurrentBrandQueryKey
    >(
      getCurrentBrandQueryKey(brandId),
      getCurrentBrandFn
    )
