import { Route } from 'pages/interface'
import {
  ADMIN_PANEL,
  ADMIN_PANEL_CONTENT,
  ADMIN_PANEL_CREATE_SUPPLIER,
  LAYOUT_WIDTH,
  ADMIN_PANEL_EDIT_SUPPLIER,
  ADMIN_PANEL_EDIT_USER,
  ADMIN_PANEL_DICTS_TAB,
  ADMIN_PANEL_CREATE_USER,
  ADMIN_PANEL_EDIT_COMPANY, ADMIN_GOODS_CONTENT, ADMIN_EDIT_CATEGORY
} from 'shared/config'
import { Layout } from 'shared/ui/layouts'

import { AdminPanelRoot } from './AdminPanelRoot'
import {
  AdminPanelDicts,
  CreateSupplierPage,
  EditSupplierPage,
  EditUserPage,
  CreateUserPage,
  EditCompanyPage, GoodsPanel
} from './AdminPanelRoot/Content/Pages'
import { AdminPanel } from './AdminPanelRoot/AdminPanelRoot'
import { CategoryPage } from './AdminPanelRoot/Content/Pages/Goods/ui'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: AdminPanel,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_CONTENT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: AdminPanelRoot,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_CREATE_SUPPLIER,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: CreateSupplierPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_CREATE_USER,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: CreateUserPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_EDIT_SUPPLIER,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: EditSupplierPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_EDIT_USER,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: EditUserPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_DICTS_TAB,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: AdminPanelDicts,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ADMIN_PANEL_EDIT_COMPANY,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: EditCompanyPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    strict: true,
    path: ADMIN_GOODS_CONTENT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: GoodsPanel,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    strict: true,
    path: ADMIN_EDIT_CATEGORY,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: CategoryPage
  }

] as Array<Route>