// @ts-nocheck
import styled from 'styled-components'
import {
  TableHeaderRow, Table,
} from '@devexpress/dx-react-grid-material-ui'

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  border-bottom-style: none;
  background-color: white;
`

export const StyledTableHeaderRow = styled(TableHeaderRow.Cell)`
  padding-top: 6px;
  padding-bottom: 6px;
`

export const StyledTableCell = styled(Table.Cell)`
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: ${({ iseditable }) => iseditable ? 'pointer' : 'default'};
`
export const StyledTableRow = styled(Table.Row)`
  :hover {
    .delete_action {
      display: inline-block;
    }
  }
`