import { MenuItem } from '@mui/material'
import styled from 'styled-components'

export const StyledMenuItemButton = styled(MenuItem)<{ type?: string }>`
  color: ${({ theme, type }) => {
    if (type === 'error') {
      return theme.palette.error.main
    } if (type === 'warning') {
      return theme.palette.warning.main
    }
    return ''
  }}
`