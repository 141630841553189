import styled from 'styled-components'
/* eslint-disable */

export const StyledAddButton = styled.div<{ largeContainer?: boolean }>`
  width: ${ ({ largeContainer }) => largeContainer ? '192px' : '88px' };
  height: ${ ({ largeContainer }) => largeContainer ? '192px' : '88px' };
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23BDBDBDFF' stroke-width='1' stroke-dasharray='8%2c8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");

  svg {
    width: 35px;
    height: 35px;
    color: #2196F3;
  }

  :hover {
    background-color: #f1f1f1;
  }
`

export const UrlInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  
  button {
    width: 117px;
    height: 36px;
  }
`

export const AccordionContainer = styled.div`
  .Mui-expanded {
    margin: 0;
    min-height: auto;
  }
`

export const StyledTextContainer = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  gap: 16px;
  ul {
    margin: 0;
    padding-left: 24px;
  }
`

export const StyledDragNDrop = styled.div`
  #form-file-upload {
    height: 188px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  svg {
    width: 48px;
    transition: 0.2s;
    height: 48px;
    color: #2196F3;
  }

  .description {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    border: 2px dashed #BDBDBD;
    border-radius: 4px;
    height: 188px;
    
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #00000061;
    border: none;
    background-color: transparent;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const MediaItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;

  .mediaItem {
    border-radius: 4px;
    width: 88px;
    height: 88px;
    position: relative;

    :nth-child(7n + 7) {
      .validationErrorContainer {
        .errorTooltip {
          left: auto;
          right: 10px;
        }
      }
    }

    :nth-child(7n + 6) {
      .validationErrorContainer {
        .errorTooltip {
          left: auto;
          right: 10px;
        }
      }
    }

    .progressBar {
      position: absolute;
      top: 30%;
      right: 30%;
    }

    .validationErrorContainer {
      cursor: pointer;
      visibility: hidden;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      border-radius: 50%;
      background-color: #fdf0e6;

      svg {
        width: 20px;
        height: 20px;
        color: #ed6c03;
      }

      .errorTooltip {
        z-index: 50;
        padding: 16px;
        pointer-events: none;
        position: absolute;
        width: 300px;
        background-color: #616161E5;
        border-radius: 4px;
        top: 28px;
        left: -4px;
        opacity: 0;
        transition: opacity 0.3s;

        span {
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }

      :hover {
        .errorTooltip {
          opacity: 1;
        }
      }
    }

    .deleteMediaItem {
      transition: 0.05s;
      visibility: hidden;
      position: absolute;
      top: 4px;
      right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      border-radius: 50%;
      height: 28px;
      cursor: pointer;
      background-color: white;

      svg {
        width: 20px;
        height: 20px;
        color: #0000008A;
      }

      :hover {
        svg {
          color: rgba(0, 0, 0, 0.59);
        }
      }
    }

    img {
      object-fit: contain;
      border-radius: 4px;
      width: inherit;
      height: inherit;
      border: 1px solid rgba(0, 0, 0, 0.09);
    }

    video {
      width: inherit;
      height: inherit;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.09);
    }

    :hover {
      .deleteMediaItem {
        visibility: visible;
      }
    }
  }
`