import React from 'react'

export const RepriceFinalPriceCell = ({ row }: { row: any }) => {

  const { advicePriceDetail, marketplaceId } = row
  const { price, discount, spp, oldPrice } = advicePriceDetail

  function wbFinalPrice() {
    return (
      (price * (1 - discount / 100.0) * (1 - spp / 100.0))
    )
  }
  
  function OzonDiscount() {
    if (oldPrice) {
      return (
        (oldPrice - price) / oldPrice * 100.0
      )
    }
    return 0
  }
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      gap: 16,
      paddingTop: 15,
      paddingRight: 16
    }}>
      <div style={{
        backgroundColor: 'rgba(63, 81, 181, 0.12)',
        borderRadius: 4,
        height: 28,
        justifyContent: 'space-between',
        display: 'flex',
        padding: '0 8px',
        alignItems: 'center',
        width: 'fit-content'
      }}>
        <span style={{ fontSize: 14, color: '#3F51B5' }}>
          {(Number(marketplaceId) === 1) ? Math.floor(wbFinalPrice()) || 0 : Math.abs(price).toLocaleString() || 0}
        </span>
      </div>
      <div style={{
        height: 28,
        paddingRight: 8,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        <div style={{ backgroundColor: '#F5F5F5', padding: 4, borderRadius: 4 }}>
          <span style={{ color: '#00000099' }}>
            {(Number(marketplaceId) === 1) ? `${advicePriceDetail.discount}%` : `${Math.floor(OzonDiscount())}%`}
          </span>
        </div>
        {(Number(marketplaceId) === 1) ?
          <div style={{ backgroundColor: '#F5F5F5', padding: 4, borderRadius: 4 }}>
            <span style={{ color: '#00000099' }}>
              {advicePriceDetail.spp}%
            </span>
          </div>
          : <></>
        }
      </div>
    </div>
  )
}