import React from 'react'
import { Box, ButtonGroup, Typography } from '@mui/material'
import { DatePicker } from 'shared/ui/components'
import { AnalyticsOrders } from 'shared/api/analytics'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractDays } from 'shared/lib/utils/subtractDays'

import { useChartContext } from '../../../../../lib/useChartContext'


enum Interval { Week, Month, Year, Other }
export const Header = ({ ordersData }: { ordersData?: AnalyticsOrders }) => {
  const { value: context } = useChartContext()
  const { searchObj,handleChartParams } = context
  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        return handleChartParams({
          timelineStart: subtractDays(new Date(), 7),
          timelineEnd: getPrevDay(),
          range: n,
        })
      }
      case 1: {
        return handleChartParams({
          timelineStart: subtractMonths(new Date(), 1),
          timelineEnd: getPrevDay(),
          range: n,
        })
      }
      case 2: {
        return handleChartParams({
          timelineStart: subtractMonths(new Date(), 12),
          timelineEnd: getPrevDay(),
          range: n
        })
      }
    }
    return true
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
      <Box sx={{ marginRight: '58px' }}>
        <Typography variant="h5" sx={{ fontSize: '20px' }}>
          Эластичность спроса
        </Typography>
      </Box>
      <ButtonGroup size="large" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={searchObj.range === 0} onClick={() => rangeButtonClick(Interval.Week)}>Неделя</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 1} onClick={() => rangeButtonClick(Interval.Month)}>Месяц</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 2} onClick={() => rangeButtonClick(Interval.Year)}>Год</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 3} $isOtherButton={true}>Другое</StyledButtonGroup>
      </ButtonGroup>
      <Box sx={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <Box sx={{ width: '180px' }}>
          <DatePicker
            name="dateFrom"
            label="Начало периода"
            views={['day']}
            inputHeight={36}
            showClear={false}
            disabled={!ordersData?.dateFrom}
            value={(searchObj.range === 2 && ordersData?.dateFrom) ? ordersData?.dateFrom : searchObj.startValue}
            onChange={(e) => { if (e) handleChartParams({
              timelineStart: new Date(e).toISOString().split('T')[0],
              startValue: new Date(e).toISOString().split('T')[0],
              range: 3
            })}}
            onBlur={(e) => {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.dateFrom) {
                handleChartParams({
                  startValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  timelineStart: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }}}
          />
        </Box>
        <Box sx={{ width: '180px' }}>
          <DatePicker
            name="dateTo"
            label="Конец периода"
            views={['day']}
            inputHeight={36}
            showClear={false}
            disabled={!ordersData?.dateTo}
            value={ordersData?.dateTo || searchObj.endValue}
            onChange={(e) => {if (e) handleChartParams({
              endValue: new Date(e).toISOString().split('T')[0],
              timelineEnd: new Date(e).toISOString().split('T')[0],
              range: 3
            })}}
            onBlur={(e) => {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.dateTo) {
                handleChartParams({
                  endValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  timelineEnd: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}