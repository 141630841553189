/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyOrdersAddcustomerwarehouse, Distributions } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Distributions>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  data: BodyOrdersAddcustomerwarehouse
}>

export const apiFunction = ({ copackingorderId,data }: IRequest) =>
  api.post<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/distributions/warehouse`,data).then(res => res.data)


export const useAddcustomerwarehouse = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

