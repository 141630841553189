import { Box, TextField } from '@mui/material'
import styled from 'styled-components'
import { Field } from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { DisplayedGoodsDataController } from 'pages/product/edit/main/model/DisplayedGoodsDataController'
import { MemoECOMMarketplace } from 'pages/product/edit/main/ui/WB/ui/ProductInfoTabs/ui/ECOMWB'
import React from 'react'

const Text = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`

export function Description() {
  const goods = useGoodsContext()
  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()
  return (
    <>
      <MemoECOMMarketplace
        changeDisplayedGoodsData={changeDisplayedGoodsData('description')}
        leftSideTooltip="Описание товара, к которому привязан продукт"
        displayGoodsData={displayedGoodsData.description}
      />
      <InputsWrapper hideGoodsInfo={!displayedGoodsData.description}>
        <>
          <TextField
            label="Описание"
            variant="outlined"
            name="description1"
            size="small"
            fullWidth={true}
            value={goods?.description ?? ''}
            disabled={true}
            multiline={true}
            minRows={7}
          />
          <Box mt={1}>
            <Text>Символов: {goods?.description?.length ?? 0}</Text>
          </Box>
        </>
        <Box width="100%" position="relative">
          <Field
            name="description"
            label="Описание"
            render={({ input, meta }) => (
              <>
                <TextField
                  label="Описание"
                  variant="outlined"
                  size="small"
                  {...input}
                  onChange={input.onChange}
                  value={input.value}
                  fullWidth={true}
                  multiline={true}
                  minRows={7}
                  error={meta.invalid}
                  inputProps={{
                    maxLength: 4000
                  }}
                />
                <Text>Символов: {input.value.length}/4000</Text>
              </>
            )}
          />
        </Box>
      </InputsWrapper>
    </>
  )
}
