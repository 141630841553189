import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { pimGen } from 'shared/lib/generated'
import { PriceAdviceAction } from 'shared/lib/generated/pim/Api'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { snackActions } from 'shared/lib/react/snackbar'

const StyledContainer = styled.div`
  padding: 0 12px !important;
  height: 100%;
  width: 96px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  
  svg {
    cursor: pointer;
  }
  
  .approve-button {
    color: #007DFF;
  }
  
  .decline-button {
    color: #0000008A;
  }
`

export const ApproveCell = ({ row }) => {
  const { mutate: patchPrice } = pimGen.priceWizard.PatchPriceAdvice.usePatchPriceAdvice()
  const queryClient = useQueryClient()
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { limit, page, tab, ...searchParams } = searchObj

  const handleAdvicePatch = (action: PriceAdviceAction) => {
    patchPrice({ 
      query: {
        action
      },
      data: [{ 
        productId: row.productId,
        adviceDate: row.adviceDate
      }]
    }, { onSuccess: (response) => {
      snackActions.info(action === 'approve' ? 'Рекомендация применена' : 'Рекомендация отклонена')
      queryClient.setQueryData<pimGen.priceWizard.GetPriceAdvice.ResponseType>(
        pimGen.priceWizard.GetPriceAdvice.getQueryKey({ query: searchParams }),
        (updater) => updater!.map(el => {
          if (el.productId === response[0].productId && el.adviceDate === response[0].adviceDate) {
            return response[0]
          }
          return el
        }) as pimGen.priceWizard.GetPriceAdvice.ResponseType)
    } })
  }
  
  return (
    <StyledContainer>
      <CheckIcon className="approve-button" onClick={() => handleAdvicePatch('approve' as PriceAdviceAction)}/>
      <ClearIcon className="decline-button" onClick={() => handleAdvicePatch('declined' as PriceAdviceAction)}/>
    </StyledContainer>
  )
}