import React, { useMemo, useState } from 'react'
import { tradeGen } from 'shared/lib/generated'
import { Autocomplete, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { FilterWrapper, SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { arrOfNum } from 'shared/lib/transform'

export const TableFilter = () => {
  const { data: boxTypeData } = tradeGen.orders.GetboxTypeId.useGetboxTypeId()
  const { searchObj, handleChangeParams } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })

  const [boxTypeId, setTypeId] = useState(arrOfNum(searchObj.boxTypeId) || [])

  const boxTypeOptions = useMemo(
    () => boxTypeData?.map(getOptions) || [],
    [boxTypeData])

  const handleAutocompleteBlur = () => {
    if (JSON.stringify(arrOfNum(searchObj.boxTypeId) || []) !== JSON.stringify(boxTypeId) ) {

      handleChangeParams({
        params: {
          boxTypeId
        },
        options: {
          arrayFormat: 'bracket'
        }
      })
    }
  }

  return (
    <FilterWrapper>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={searchObj.searchString}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }}
        onBlur={(e) => {
          handleChangeParams({ params: { searchString: e.target.value }, options: { skipEmptyString: true, skipNull: true } })
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }}/>
      <StyledFilterContainer>
        <SelectWrapper width={200}>
          <Autocomplete
            options={boxTypeOptions}
            noOptionsText="Нет фильтров"
            clearOnBlur={false}
            disableCloseOnSelect={true}
            size="small"
            multiple={true}
            onBlur={handleAutocompleteBlur}
            renderTags={() => null}
            value={boxTypeOptions.filter(el => boxTypeId.includes(el.value) )}
            isOptionEqualToValue={isOptionEqual}
            onChange={(_, value) => {
              setTypeId(value.map(el => el.value) || [])
            }}
            disableClearable={true}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Тип приемки"
                variant="outlined"
              />
            )}
          />
        </SelectWrapper>
      </StyledFilterContainer>
    </FilterWrapper>
  )
}