import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { SxProps } from '@mui/system'
import { Popover } from '@mui/material'

interface IMenuItem {
  label?: string,
  id?: string,
  type?: string,
  divider?: boolean,
  disabled?: boolean
  handler?: () => void,
  uploadFile?: (e) => void
}

interface MenuOptions {
  disabled?: boolean
  onClick?: () => void
  options?: Array<IMenuItem>,
  variant?: 'text' | 'outlined' | 'contained',
  label?: string,
  sx?: SxProps,
  className?: string,
  isLoading?: boolean
  size?: 'small' | 'medium' | 'large',
}

const FILE_EXTENSION =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const SplitButton = ( { variant, disabled, options, label, sx, isLoading, size, onClick, className }: MenuOptions) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonGroup className={className} size={size} variant={variant} aria-label="split button" sx={ sx }>
        <LoadingButton
          variant={variant}
          disabled={isLoading || disabled}
          loading={isLoading}
          onClick={(e) => onClick ? onClick() : handleClick(e)}
        >
          { label }
        </LoadingButton>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleClick}
          disabled={isLoading || disabled}
        >
          <ArrowDropDownIcon/>
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        role={undefined}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disablePortal={true}
      >
        <MenuList id="split-button-menu">
          {options?.map((option, index) => {
            if (option.uploadFile) {
              return (
                <label htmlFor={`excel-file-${index}`}>
                  <input
                    accept={FILE_EXTENSION}
                    id={`excel-file-${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (option.uploadFile) {
                        option.uploadFile(e)
                        handleClose()
                      }
                    }}
                  />
                  <MenuItem
                    key={option.label}
                  >
                    {option.label}
                  </MenuItem>
                </label>
              )
            }
            return (
              <MenuItem
                key={option.label}
                onClick={() => {
                  if (option.handler) {
                    option.handler()
                    handleClose()
                  }
                }}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </MenuList>
      </Popover>
    </>
  )
}