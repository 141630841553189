import { Paper } from '@mui/material'
import { customerApi, user } from 'shared/api'
import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { getUserSettingsQueryKey } from 'shared/api/user/settings'
import { Table } from 'shared/ui/components/Table'

import { StyledLoader, StyledTableBox } from './styled'
import { pagingPanelLocale } from './lib/localization'
import { columnsConfig } from './model/tableColumnsConfig'

interface ICompaniesList {
  companies: customerApi.CompanyShortInfo[]
  isLoading: boolean
  isFetching: boolean
}

const getRowId = (row: customerApi.CompanyShortInfo) => row.id

const MAX_PAGE_SIZE = 50

export const CompaniesTable = ({ companies, isLoading, isFetching }:ICompaniesList ) => {
  const { calculatedRowsPerPage } = useRowsPerPage()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0,
    }),
    [companies]
  )

  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()

  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.companiesTableHiddenColumns
  const queryClient = useQueryClient()

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          companiesTableHiddenColumns: hiddenColumns,
        },
      },{
        onSuccess: (response) => {
          queryClient.setQueryData(getUserSettingsQueryKey(), response)
        }
      })
    },
    [userSettingsQuery?.data]
  )
  return (
    <StyledTableBox component={Paper}>
      { (isFetching || isLoading) && <StyledLoader size={60} />}
      { companies && (
        <Table
          tableList={companies}
          totalCount={companies.length}
          getRowId={getRowId}
          paginationLocale={pagingPanelLocale}
          tableParams={memoTableParams}
          handleTableSettingsChange={handleTableSettingsChange}
          tableHiddenColumns={tableHiddenColumns}
          calculatedRowsPerPage={calculatedRowsPerPage}
          columnsConfig={columnsConfig}
          showInArchiveControl={false}
          showColumnsVisibility={true}
          searching="internal"
          pagination="internal"
          sorting="internal"
          filtering="internal"
        />
      )}
    </StyledTableBox>
  )
}