import React, { memo } from 'react'
import { useQuery } from 'react-query'
import { Box, Button, Typography } from '@mui/material'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ProductSearchWrapper, ResultList } from './styled'
import { CustomProductShortInfoWidget } from './CustomProductShortInfoWidget'

interface ProductAttributes {
  id?: UniqueId
  name?: string
  marketplaceCode?: string
  marketplaceUrl?: string
  marketplaceName?: string
  marketplaceCategory?: string
  barcode?: string
  price?: number
  companyName?: string
  cabinetName?: string
  autoOrdering?: boolean
}

interface SelectProductWidgetProps {
  searchFunction: () => Promise<ProductAttributes[]>
  onSelectProduct: (productId) => void
  close: () => void
}

export const CustomSelectProductWidget = memo((
  { searchFunction, onSelectProduct, close } :
            SelectProductWidgetProps) => {

  const { data } = useQuery<ProductAttributes[]>({
    queryFn: () => searchFunction(),
  })

  const handleAdd = (index) => {
    if (data !== undefined) {
      onSelectProduct(data[index]?.id)
    }
  }
  
  return (
    <ProductSearchWrapper>
      <ResultList>
        {isEmptyArray(data)
          ? <Box display="flex" flexDirection="column">
            <Typography>
              Не найдено продуктов, удовлетворяющих условиям заказа,<br/>
              которые можно было бы собрать из данного товара.
            </Typography>
            <Button
              onClick={close}
              color="primary"
              sx={{ marginLeft: 'auto' }}
            >
              ОТМЕНА
            </Button>
          </Box>
          :
          <Box >
            {data?.map((item, index) => (
              <CustomProductShortInfoWidget
                key={ `${item.id} + ${item.barcode}` }
                productId={item.id}
                name={item.name}
                marketplaceCode={item.marketplaceCode}
                marketplaceUrl={item.marketplaceUrl}
                marketplaceName={item.marketplaceName}
                marketplaceCategory={item.marketplaceCategory}
                cabinetName={item.cabinetName}
                barcode={item.barcode}
                price={item.price}
                companyName={item.companyName}
                autoOrdering={item.autoOrdering}
                onAddClick={ () => handleAdd(index) }
              />
            ))}
          </Box>
        }
      </ResultList>
    </ProductSearchWrapper>
  )
}
)