import { AlgorithmParameter } from 'shared/api/pim/products/common'
import styled from 'styled-components'
import { useField, useForm } from 'react-final-form'
import React from 'react'
import { pimGen } from 'shared/lib/generated'

interface IDefaultValue {
  parameter: AlgorithmParameter
  algorithmId: UniqueId
  productId: UniqueId
}

const ValueButton = styled.span`
  cursor: pointer;
  font-size: 12px;
  color: #2196F3;
`

export const DefaultValue = ({ parameter, algorithmId, productId }: IDefaultValue) => {
  const { change } = useForm()
  const currentValue = useField(`id${ parameter.id }`).input.value
  const { data } =
    pimGen.priceWizard.GetAlgorithmParams.useGetAlgorithmParams({
      algorithmId,
      parameterId: parameter.id,
      query: {
        productId
      }
    })

  const handleInputChange = () => {
    if (data?.defaultValue && currentValue !== data?.defaultValue) {
      change(`id${ parameter.id }`, data.defaultValue)
    }
  }

  if (data?.defaultValue && currentValue !== data?.defaultValue) {
    return <ValueButton onClick={handleInputChange}>Рекомендуем {data.defaultValue}</ValueButton>
  }

  return <></>
}