import React from 'react'
import ImagePlaceholder from 'assets/images/PlugReviewAndAsks.svg'
import { PlugReviewTypeText, ReviewsStatusesText } from 'features/reviews/lib/TypeReviewText'
import { StyledPlugContainer } from 'shared/ui/components/PlugWithAddButton/styled'

interface Props {
  reviewType: number
  processed?: boolean
  viewed?: boolean
}

export const PlugReviews = ({ reviewType, viewed, processed }: Props) => (
  <StyledPlugContainer style={{ borderTop: '1px solid #E0E0E0' }} height={window.innerHeight}>
    <img style={{ marginBottom: '24px' }} src={ImagePlaceholder} alt="placeholder" />
    <span className="plug-review-text">
      {`У вас пока нет ${ReviewsStatusesText({ viewed, processed })}\n${PlugReviewTypeText[reviewType].plugReview}`}
    </span>
  </StyledPlugContainer>
)