import { AxiosError } from 'axios'
import { useQuery, QueryFunctionContext } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

type PimProductsWBProductQueryKey = ReturnType<
  typeof getWBProductQueryKey
>

export const getWBProductQueryKey = (productId?: UniqueId) =>
  ['get', 'pim/products/wb/product', productId] as const

export const getWBProduct = (productId?: UniqueId) =>
  api
    .get<pimApi.products.wb.WBNomenclature>(
      `/pim/products/wb/product/${productId}`
    )
    .then(({ data }) => data)

export const fetchWBProduct = (
  context: QueryFunctionContext<PimProductsWBProductQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getWBProduct(queryParams)
}

export const useFetchWBProductQuery = (
  productId?: UniqueId,
) =>
  useQuery<
    pimApi.products.wb.WBNomenclature,
    AxiosError<errorType>,
    pimApi.products.wb.WBNomenclature,
    PimProductsWBProductQueryKey
  >(getWBProductQueryKey(productId), fetchWBProduct,
    {
      refetchOnMount: false, enabled: !!productId
    })
