import styled from 'styled-components'

export const StyledPanel = styled.div`
  margin-top: 28px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  .header {
    font-size: 24px;
  }
  
  .paper {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #0000001F, 0 1px 1px 0 #00000024, 0 2px 1px -1px #00000033;
  }
`