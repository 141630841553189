import React from 'react'
import styled from 'styled-components'
import * as sanitizeHtml from 'sanitize-html'

const ProductNameLink = styled.a`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  
  .nomenclature-text {
    text-decoration: none;
    color: #2196F3;
  }
`
export const RepriceCommentsCell = ({ comments }: {comments: string}) => {
  const sanitizedText = sanitizeHtml(comments, {
    allowedTags: [ 'a' ],
    allowedAttributes: {
      'a': [ 'href', 'class', 'target' ]
    },
  })

  return (
    <div style={{ height: '100%', paddingTop: 16, paddingBottom: 16 }}>
      <ProductNameLink dangerouslySetInnerHTML={{ __html: sanitizedText }}/>
    </div>
  )
}