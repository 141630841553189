import { RECEIPT_DOC_STATUS_LIST } from 'pages/docs/constants'
import { DocType } from 'shared/services/interfaces/doc'

import { StyledStatus } from './styled'


export const DocStatus = ({ row }: { row: DocType }) => {
  const ComponentIcon = RECEIPT_DOC_STATUS_LIST[row.status].icon
  return (
    <StyledStatus
      icon={<ComponentIcon />}
      $chipStyle={row.status}
      title={ RECEIPT_DOC_STATUS_LIST[row.status].title}
    />
  )
}