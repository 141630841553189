import { tradeApi } from 'shared/api'
import {
  dialogModel,
} from 'shared/ui/components/dialog'

import { SettingsMenuItem } from '../model/types'
import { PatchSupplyOrderDialogContent } from '../ui/PatchSupplyOrderDialogContent'

export const getSettingsMenuItemByMethod = ({
  method,
  onAccept,
  dialogOptions,
  options,
}: {
  method?: tradeApi.Transition
  onAccept?: (params: any) => void
  dialogOptions?: dialogModel.dialogOptions
  options?: Partial<SettingsMenuItem>
}): Partial<SettingsMenuItem> => ({
  label: method?.name,
  handler: () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <PatchSupplyOrderDialogContent
          accept={accept}
          close={close}
          method={method}
        />
      ),
      onAccept: (params) => {
        onAccept?.(params)
      },
      options: dialogOptions
    })
  },
  ...options,
})