import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

export const createGoodsPack = (newGoodsPack: pimApi.goods.NewGoodsPack) =>
  api.post<pimApi.goods.NewGoodsPack>(
    '/pim/goods/pack',
    newGoodsPack
  )

export const useCreateGoodsPackMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Goods>,
    AxiosError<errorType>,
    pimApi.goods.NewGoodsPack
    // @ts-ignore
  >(createGoodsPack)

const updateGoodsComponents = (
  goodsId: UniqueId,
  componentId: UniqueId,
  quantity: number,
) =>
  api.post<pimApi.goods.Goods>(`/pim/goods/${goodsId}/components/${componentId}`, { quantity })

export const useUpdateGoodsComponentsMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Goods>,
    errorType,
    {
      goodsId: UniqueId,
      componentId: UniqueId,
      quantity: number
    }
  >(({ goodsId, componentId, quantity }) => updateGoodsComponents(goodsId, componentId, quantity))

const updateGoodsComponentsQuantity = (
  goodsId: UniqueId,
  componentId: UniqueId,
  quantity: number,
) =>
  api.put<pimApi.goods.Goods>(`/pim/goods/${goodsId}/components/${componentId}`, { quantity })

export const useUpdateGoodsComponentsQuantityMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Goods>,
    errorType,
    {
      goodsId: UniqueId,
      componentId: UniqueId,
      quantity: number
    }
  >(({ goodsId, componentId, quantity }) => updateGoodsComponentsQuantity(goodsId, componentId, quantity))

const deleteGoodsComponents = (
  goodsId: UniqueId,
  componentId: UniqueId,
) => 
  api.delete<pimApi.goods.Goods>(`/pim/goods/${goodsId}/components/${componentId}`)

export const useDeleteGoodsComponentsMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Goods>,
    errorType,
    {
      goodsId: UniqueId,
      componentId: UniqueId,
    }
  >(({ goodsId, componentId }) => deleteGoodsComponents(goodsId, componentId))

