import { GoodsFile } from 'shared/api/pim/goods/models'
import { pimApi } from 'shared/api'
import { MediaImageItemMemo } from 'widgets'

interface PhotoItemProps {
  goodsId: UniqueId
  openGallery?: () => void
  item: GoodsFile
  onFileDelete: (item: GoodsFile) => void
  index: number
  disabled? : boolean
}

const fileThumbnailSize = { maxWidth: 350, maxHeight: 350 }

export const PhotoItem = ({ goodsId, item, onFileDelete, disabled, openGallery }: PhotoItemProps) => {
  const { downloadUrl, id, fileName } = item

  const imgPath = pimApi.goods.getFileThumbnailApiPath(goodsId, id, fileThumbnailSize)
  const handleFileDelete = () => onFileDelete(item)

  return (
    <MediaImageItemMemo
      imgPath={imgPath}
      fileName={fileName}
      openGallery={openGallery}
      onFileDelete={handleFileDelete}
      downloadUrl={downloadUrl}
      disabled={disabled}
    />
  )
}
