import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

import { StyledTable } from '../../../ui/styled'

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledTableWrapper = styled(StyledTable)`
  .table-row {
    >div {
      div:first-child {
        display: flex;
      }
      
      :first-child {
        width: 100%;
        border-right: 1px solid #E0E0E0;
      }
    }
  }
`