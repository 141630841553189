import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'

import { CoPackingOrderResult } from './models'

interface IGetCoPackingOrders {
  page?: number
  limit?: number
  sort?: string
  order?: string
  status_id?: number
  searchString?: string
}

export const getCoPackingOrdersQueryKey = (queryParams: IGetCoPackingOrders) =>
  ['get', 'copacking/copackingorders', queryParams] as const

type GetCoPackingOrdersQueryKey = ReturnType<typeof getCoPackingOrdersQueryKey>

function getCopackingOrders(queryParams: IGetCoPackingOrders) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get<CoPackingOrderResult>(
    '/copacking/copackingorders', { params: queryParamsSnake }).then(res => res.data)
}

const getAllCoPackingOrders = (context: QueryFunctionContext<GetCoPackingOrdersQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getCopackingOrders(queryParams)
}

export const useGetActivitiesQuery = (queryParams: IGetCoPackingOrders) =>
  useQuery<
    CoPackingOrderResult,
    errorType,
    CoPackingOrderResult,
    GetCoPackingOrdersQueryKey
  >(
    getCoPackingOrdersQueryKey(queryParams),
    getAllCoPackingOrders,
    { refetchOnMount: 'always', staleTime: Infinity, keepPreviousData: true }
  )
