import React from 'react'
import BarChartIcon from '@mui/icons-material/BarChart'
import { generatePath } from 'react-router'

export const ChartUrlCell = ({ row }) => (
  <a
    style={{ display: 'flex', alignItems: 'center' }}
    href={
      // eslint-disable-next-line max-len
      generatePath((row.ordersStatFromDate && row.ordersStatToDate) ? `/analytics/charts?productId=${row.productId}&startValue=${row.ordersStatFromDate}&endValue=${row.ordersStatToDate}&timelineStart=${row.ordersStatFromDate}&timelineEnd=${row.ordersStatToDate}&range=3` :
        `/analytics/charts?productId=${row.productId}`
      )}
    target="_blank" rel="noreferrer">
    <BarChartIcon sx={{ color: '#007DFF' }}/>
  </a>
)