import styled from 'styled-components'
import { Box, Card, CardContent, Chip, Typography } from '@mui/material'

export const StyledCard = styled(Card)<{ $filled?: boolean, $hasSettings?: boolean}>`
  height: 100%;
  background-color: ${({ $filled }) => $filled ? '#F5F5F5' : ''};
  box-shadow: 0 0 0 1px #E0E0E0;
  overflow: visible;
  z-index: 1;

  .title {
    color: ${({ $filled }) => $filled ? '#202020' : '#666666'};
  }
`

export const StyledCardContent = styled(CardContent)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 !important;

  .settings-menu {
    visibility: hidden;
  }

  &:hover .settings-menu {
    visibility: visible;
  }
`

export const FlexWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`

export const OldPrice = styled(Typography)`
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  align-self: start;
  margin-top: 5px;
`

export const StyledChip = styled(Chip)`
  margin: 0 8px 4px 4px;
  padding: 0;
  height: fit-content;
  font-size: 12px;
  align-self: center;
  background-color: ${({ theme }) => theme.palette.success.light };
  color: #fff;
  font-weight: 500;
  span {
    padding: 4px 6px;
  }
`

export const ChartWrapper = styled(Box)`
  width: 263px;
  height: 119px;
  align-self: center;
  .recharts-yAxis .recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-value {
    font-size: 8px;
    font-weight: 400;
  }

  .recharts-xAxis .recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-value {
    font-size: 9px;
    font-weight: 400;
  }
`