import React from 'react'
import { ButtonGroup as MuiButtonGroup } from '@mui/material'
import { formatter } from 'shared/lib/utils/numberWithSpaces'

import { DataParamsTypes, reviewsCountType } from '../../../../lib/types'
import { StyledCountButtonGroup } from '../../../styled'
import { PlugReviewTypeText } from '../../../../lib/TypeReviewText'

interface Props {
  dataParams: DataParamsTypes
  setDataParams: (e) => void
  reviewsCount: reviewsCountType
}
export const ButtonGroup = ({ dataParams, reviewsCount, setDataParams }: Props) => (
  <div style={ { padding: '16px 24px' } }>
    <MuiButtonGroup size="large" variant="text" aria-label="text button group">
      <StyledCountButtonGroup
        $active={ dataParams.viewed === false && dataParams.processed === false }
        onClick={ () => {
          setDataParams(
            prev => ({
              ...prev,
              exam: false,
              viewed: false,
              processed: false,
              page: 0
            }))
        } }
      >
        { `Новые ${ formatter(reviewsCount.new) }` }
      </StyledCountButtonGroup>

      <StyledCountButtonGroup
        $active={ dataParams.viewed === true && dataParams.processed === false }
        onClick={ () => {
          setDataParams(
            prev => ({
              ...prev,
              exam: false,
              viewed: true,
              processed: false,
              page: 0
            }))
        } }
      >
        { `Просмотренные ${ formatter(reviewsCount.viewed) }` }
      </StyledCountButtonGroup>

      <StyledCountButtonGroup
        $active={ dataParams.viewed === true && dataParams.processed === true }
        onClick={ () => {
          setDataParams(
            prev => ({
              ...prev,
              exam: false,
              viewed: true,
              processed: true,
              page: 0
            }))
        } }
      >
        { `Обработанные ${ formatter(reviewsCount.processed) }` }
      </StyledCountButtonGroup>


      <StyledCountButtonGroup
        $active={ dataParams.exam === true }
        onClick={ () => {
          setDataParams(
            prev => ({
              ...prev,
              processed: null,
              viewed: null,
              exam: true,
              page: 0
            }))
        } }
      >
        { `Не отправленные ${ formatter(reviewsCount.exam) }` }
      </StyledCountButtonGroup>

      <StyledCountButtonGroup
        $active={ dataParams.viewed === null && dataParams.processed === null && dataParams.exam === null }
        onClick={ () => {
          setDataParams(
            prev => ({
              ...prev,
              exam: null,
              viewed: null,
              processed: null,
              page: 0
            }))
        } }
      >
        { `Все ${ PlugReviewTypeText[dataParams.reviewtypeId].filter } ${ formatter(reviewsCount.allcount) }` }
      </StyledCountButtonGroup>
    </MuiButtonGroup>
  </div>
)