const TYPES = {
  User: Symbol.for('User'),
  History: Symbol.for('History'),
  LocalStorage: Symbol.for('LocalStorage'),
  CookieStorage: Symbol.for('CookieStorage'),
  MemoryStorage: Symbol.for('MemoryStorage'),
  Socket: Symbol.for('Socket'),
  Company: Symbol.for('Company'),
  Product: Symbol.for('Product'),
  Category: Symbol.for('Category'),
  Tnved: Symbol.for('Tnved'),
  Catalog: Symbol.for('Catalog'),
  ListUtils: Symbol.for('ListUtils'),
  Doc: Symbol.for('Doc'),
  Dictionary: Symbol.for('Dictionary'),
  ReceiptDocumentsService: Symbol.for('ReceiptDocumentsService'),
  GoodsWMSService: Symbol.for('GoodsWMSService'),
  GoodsPIMService: Symbol.for('GoodsPIMService'),
  RouterService: Symbol.for('RouterService'),
}

export { TYPES }
