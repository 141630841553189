import { Box, Grid } from '@mui/material'
import { useEffect, useCallback, useState, FC } from 'react'
import styled from 'styled-components'
import {
  Form, DatePickerField, InputField,
  CatalogSelectField, CheckboxField, Field,
} from 'shared/ui/components/form'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { FormSpy } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { setWarehouseId } from 'store/doc'
import { noop } from 'shared/lib/lodash/noop'

import { Status } from '../../interfaces'

interface TableFilersProps {
  warehouseId?: number;
  activeStatuses?: Array<Status['value']>;
  onChange: (filterQuery: { warehouse_id: number }) => void;
}

interface DocFilter {
  supplier?: string | string[] | null,
  recipient?: string | string[] | null,
  numberDoc?: string | string[] | null,
  dateFrom?: string | string[] | null,
  dateTo?: string | string[] | null,
  warehouse_id?: number | string | null,
  status: Array<Status['value']>
}

const GridLast = styled(Grid)`
  margin-left: auto;
`
const GridStyled = styled(Grid)`
  max-width: 160px;
  position: relative;
  margin-bottom: 15px;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Statuses: Readonly<Array<Status>> = [
  {
    label: 'Приостановлены',
    value: 'paused'
  },
  {
    label: 'Завершенные с расхождениями',
    value: 'finished_with_diff'
  },
  {
    label: 'Завершенные',
    value: 'finished'
  },
  {
    label: 'Текущие',
    value: 'started'
  },
  {
    label: 'Ожидаемые',
    value: 'new'
  },
] as const

function getInitialValues(
  warehouse_id?: number,
  activeStatuses = [] as Array<Status['value']>): DocFilter {
  return {
    supplier: '',
    recipient: '',
    numberDoc: '',
    dateFrom: '',
    dateTo: '',
    status: activeStatuses,
    warehouse_id,
  }
}

export const TableFilters: FC<TableFilersProps> = ({
  warehouseId,
  activeStatuses = [],
  onChange
}) => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const [initialValues, setInitialValues] = useState<DocFilter>(getInitialValues(warehouseId, activeStatuses))

  const {
    supplier, recipient, numberDoc, dateFrom, dateTo, warehouse_id: queryWarehouse_id, status
  } = queryString.parse(search)

  const subscribeFiltersChanged = useCallback(({ values }) => {
    const warehouse_id = values.warehouse_id && +values.warehouse_id
    const filterPayload = { ...values, warehouse_id }

    const newValues = {
      ...getInitialValues(),
      ...filterPayload,
    }

    onChange(newValues)

    const isWarehouseNotPersist = warehouse_id && warehouse_id !== warehouseId

    if (isWarehouseNotPersist) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      persistWarehouseId(warehouse_id)
    }

  }, [warehouseId])

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      supplier,
      recipient,
      numberDoc,
      dateFrom,
      dateTo,
      status: activeStatuses || status,
      warehouse_id: warehouseId || queryWarehouse_id as string,
    })
  }, [])

  function persistWarehouseId(newWarehouseId: number) {
    dispatch(setWarehouseId(newWarehouseId))
  }

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true }} onChange={subscribeFiltersChanged} />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={2}>
              <Field
                name="supplier"
                label="Отправитель"
                component={InputField}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <Field
                name="recipient"
                label="Получатель"
                component={InputField}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <Field
                name="numberDoc"
                label="№ документа"
                component={InputField}
              />
            </Grid>
            <GridStyled item={true} xs={2}>
              <Field
                name="dateFrom"
                label="Дата документа с"
                component={DatePickerField}
                views={['day']}
              />
            </GridStyled>
            <GridStyled item={true} xs={2}>
              <Field
                name="dateTo"
                label="по"
                component={DatePickerField}
                disabled={!values.dateFrom}
                views={['day']}
              />
            </GridStyled>
            <GridLast item={true} xs={2}>
              <Field
                catalog="wms/warehouses"
                label="Склад"
                name="warehouse_id"
                component={CatalogSelectField}
              />
            </GridLast>
          </Grid>
          <Box mb={2} mr={2}>
            <HeaderWrapper>
              <div>
                {Statuses.map(({ label, value }) => (
                  <Field
                    key={label}
                    name="status"
                    label={label}
                    type="checkbox"
                    value={value}
                    component={CheckboxField}
                  />
                ))}
              </div>
            </HeaderWrapper>
          </Box>
        </form>
      )}
    />
  )
}
