import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

import { Goods } from './models'

const archiveGoods = (
  goodsId: UniqueId,
  archivationInfo: pimApi.goods.ArchivationInfo
) =>
  api.put<Goods>(`/pim/goods/${goodsId}/archive`, archivationInfo)

export const useArchiveGoodsMutation = () =>
  useMutation<
    AxiosResponse<Goods>,
    errorType,
    {
      goodsId: UniqueId,
      archivationInfo: pimApi.goods.ArchivationInfo
    }
  >(({ goodsId, archivationInfo }) => archiveGoods(goodsId, archivationInfo))

const dearchiveGoods = (
  goodsId: UniqueId,
  deArchivationInfo: pimApi.goods.DeArchivationInfo,
) =>
  api.delete<Goods>(`/pim/goods/${goodsId}/archive`, { data: deArchivationInfo })

export const useUnarchiveGoodsMutation = () =>
  useMutation<
    AxiosResponse<Goods>,
    errorType,
    {
      goodsId: UniqueId,
      deArchivationInfo: pimApi.goods.DeArchivationInfo,
    }
  >(({ goodsId, deArchivationInfo }) => dearchiveGoods(goodsId, deArchivationInfo))