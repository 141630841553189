import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { dialogModel } from 'shared/ui/components/dialog'
import { customerApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { getCurrentCompanyQueryKey } from 'shared/api/customer'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'
import { useCompanyContext } from 'entities/pim/admin/useCompanyEditContext'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { customerGen } from 'shared/lib/generated'

import { EditCabinetModal } from '../modal/EditCabinetModal'
import { ProductsCatalogYmlModal } from '../modal/ProductsCatalogYmlModal'
import { GoodsSyncModalForm } from '../modal/GoodsSyncModalForm'


interface SettingsMenuItem {
  label: string,
  handler: () => void,
  disabled?: boolean
}
interface IEditCompanyOptions {
  cabinetData: customerApi.CompanyMarketplace
}


export const EditCabinetOptions = ({ cabinetData }: IEditCompanyOptions) => {
  const { companyQuery } = useCompanyContext()
  const companyData = companyQuery.data

  const queryClient = useQueryClient()

  const { mutate: editCabinet } = customerApi.useEditCabinetMutation()
  const { mutate: syncGoods } = customerGen.dictionary.SyncGoods.useSyncGoods()
  
  const editCabinetOption = {
    label: 'Редактировать кабинет',
    handler: () => {
      if (companyData && cabinetData) {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <EditCabinetModal
                close={close}
                cabinetData={cabinetData}
                handleEditCabinet={accept}
              />,

            onAccept: (editedCabinet) => {
              const newCredentials = [] as customerApi.EditCompanyMarketplaceCredential[]
              editedCabinet?.credentials?.forEach((cred: customerApi.CompanyMarketplaceCredential ) => {
                newCredentials.push(
                  { credentialId: cred.credentialId,
                    value: cred.value,
                    inArchive: cred.inArchive,
                    valueSet: cred.valueSet
                  })
              })
              editCabinet( {
                companyId: companyData.id,
                cabinetId: cabinetData.id,
                editedCabinet: {
                  id: editedCabinet.id,
                  versionNo: editedCabinet.versionNo,
                  cabinetName: editedCabinet.cabinetName,
                  credentials: newCredentials,
                  inArchive: editedCabinet.inArchive
                }
              },{
                onSuccess: ( response ) => {
                  const cabinets = companyData?.cabinets?.map( cabinet => (
                    cabinet.id === response.data.id
                      ? response.data
                      : cabinet
                  ))
                  queryClient.setQueryData( getCurrentCompanyQueryKey(companyData.id), { ...companyData, cabinets })
                  snackActions.info('Информация изменена!')
                }
              })
            }
          }
        )
      }
    } }

  const updateCabinetOption = {
    label: 'Обновить товары',
    handler: () => {
      dialogModel.openDialog(
        {
          component: () =>
            <Form
              onSubmit={({ cabinetId, companyId, ...rest }) => {
                const data = { 
                  ...rest,
                  ...((rest.vendorCodes && rest.vendorCodes.length !== 0) ?
                    { vendorCodes: rest.vendorCodes?.filter(el => el.search(/[0-9A-Fa-f-А-Я-а-я]/) !== -1)
                      .map(el => el.split(' ')
                        .filter(elem => elem !== '').join(' ')) } :
                    { vendorCodes: [] })
                }
                syncGoods({ cabinetId, companyId, data  })
              }}
              initialValues={{ companyId: companyData?.id, cabinetId: cabinetData.id }}
              mutators={{
                ...arrayMutators
              }}
              render={({ handleSubmit }) => <GoodsSyncModalForm handleSubmit={handleSubmit} /> }
            />
        }
      )
    }
  }

  const uploadGoodsCabinetOption = {
    label: 'Загрузить товары в PIM',
    divider: true,
    handler: () => {}
  }

  const productsCatalogYML =
    {
      label: 'Каталог продуктов в формате YML',
      disabled: !(cabinetData.ymlCatalogUrl),
      handler: () => {
        dialogModel.openDialog({
          component: ({ close }) =>
            <ProductsCatalogYmlModal yml={cabinetData.ymlCatalogUrl} close={close}/>
        })
      }
    }

  const archiveCabinetOption = {
    label: cabinetData.inArchive ? 'Вернуть из архива': 'Архивировать кабинет',
    type: 'warning',
    handler: () => {
      if (companyData && cabinetData) {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <DefaultModal
                close={close}
                accept={accept}
                acceptBtnColor="primary"
                variant="contained"
                acceptBtnText={cabinetData.inArchive
                  ? 'ПОДТВЕРДИТЬ'
                  : 'АРХИВИРОВАТЬ'
                }
                title={cabinetData.inArchive
                  ? 'Вернуть кабинет из архива?'
                  : 'Перенос кабинета в архив'
                }
              />,
            onAccept: () => {
              const newCredentials = [] as customerApi.EditCompanyMarketplaceCredential[]
              cabinetData?.credentials?.forEach(cred => {
                if (cred.credentialId) {
                  newCredentials.push(
                    { credentialId: cred.credentialId,
                      value: cred.value,
                      inArchive: cred.inArchive,
                      valueSet: cred.valueSet
                    })
                }
              })
              editCabinet( {
                companyId: companyData.id,
                cabinetId: cabinetData.id,
                editedCabinet: {
                  id: cabinetData.id,
                  versionNo: cabinetData.versionNo,
                  cabinetName: cabinetData.cabinetName,
                  credentials: newCredentials,
                  inArchive: !cabinetData.inArchive
                }
              },{
                onSuccess: ( response ) => {
                  const cabinets = companyData?.cabinets?.map( cabinet => (
                    cabinet.id === response.data.id
                      ? response.data
                      : cabinet
                  ))
                  queryClient.setQueryData( getCurrentCompanyQueryKey(companyData.id), { ...companyData, cabinets })
                  snackActions.info('Информация изменена!')
                }
              })
            }
          }
        )
      }
    }
  }

  const settingsOptions: Array<SettingsMenuItem> = [
    editCabinetOption,
    updateCabinetOption,
    productsCatalogYML,
    uploadGoodsCabinetOption,
    archiveCabinetOption
  ]
  
  return  (
    <SettingsMenu
      iconType={<MoreVert />}
      options={cabinetData.inArchive ? [archiveCabinetOption] : settingsOptions}
      disable={companyData?.inArchive}
    />
  )
  
}