import { IconButton } from '@mui/material'
import styled from 'styled-components'
import { ColumnChooser } from '@devexpress/dx-react-grid-material-ui'

export const StyledIconButton = styled(IconButton)<{ $open?: boolean }>`
  margin-left: auto;
  background: ${({ $open }) => ($open ? '#e2e2e2' : '')};
  visibility: ${({ $open }) => $open && 'visible !important'};
  padding: 0;
  svg {
    color: rgba(0, 0, 0, 0.38);
  }
`

export const StyledColumnChooserOverlay = styled(ColumnChooser.Overlay)`
  .MuiPopover-paper {
    transition: none !important;
  }
  margin-top: 42px;
`
