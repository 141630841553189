import React from 'react'

import { ProductBox, ProductNameLink, SkuLink } from './styled'

export const RepriceNameCell = ({ row, path }: {row: any, path: string}) => {
  const { marketplaceUrl, name, sku, adviceDate } = row
  return (
    <ProductBox>
      <SkuLink href={marketplaceUrl} target="_blank" rel="noreferrer">{sku}</SkuLink>
      <ProductNameLink adviceDate={adviceDate} href={path}>{name}</ProductNameLink>
    </ProductBox>
  )
}