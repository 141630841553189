import styled from 'styled-components'

interface Props {
  directionUp: boolean
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  color: ${({ directionUp }) => (directionUp ? 'green' : 'red')} !important;

  svg {
    font-size: 19px;
    margin-right: 8px;
  }
`
