import debounce from 'lodash.debounce'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import escapeRegExp from 'lodash.escaperegexp'

const DEBOUNCE_WAIT = 500

const SPACE_SEPARATOR = ' '
const EMPTY_SEARCH = ''

const filterPositionList = (position: CalculatedReceiptDocPosition, searchRaw: string): boolean => {
  const search = searchRaw.trim()

  if (search === EMPTY_SEARCH) {
    return true
  }

  const searchableValues: string = [
    String(position.goodsName ?? ''),
    String(position.supplierCode ?? ''),
  ].join('').replace(' ', '')

  const searchValuesGroup: string = search
    // Получает группы для поиска
    .split(SPACE_SEPARATOR)
    .map(escapeRegExp)
    // Создает текст регулярного выражения
    .reduce((groups, element) => `${groups}(?=.*${element})`, '')
    // Добавляет на конец поиск чего угодно
    .concat('.*')

  // Example: "xxпальто1151".match(/(?=.*Пальто)(?=.*1151).*/i)
  // поиск с игнорированием регистра при сопоставлении
  return !!searchableValues.match(new RegExp(searchValuesGroup, 'i'))
}

export const filterPositionListDebounced = debounce(
  (
    positions: CalculatedReceiptDocPosition[],
    filterText: string,
    cb: (positions: CalculatedReceiptDocPosition[]) => void
  ) =>
    cb(
      positions.filter((item) => filterPositionList(item, filterText))
    ),
  DEBOUNCE_WAIT
)