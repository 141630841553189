import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api/index'


interface EditCompetitorPayload {
  goodsId: UniqueId,
  editedCompetitor: pimApi.goods.EditingCompetitor
}
export const editCompetitor = ({ goodsId, editedCompetitor }: EditCompetitorPayload) =>
  api.put<pimApi.goods.Competitor>(
    `/pim/goods/${goodsId}/competitors`,
    editedCompetitor
  )


export const useEditCompetitorMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.Competitor>,
    AxiosError<errorType>,
    EditCompetitorPayload
  >(editCompetitor)
