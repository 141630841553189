import styled from 'styled-components'

export const StyledChartDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-right: 32px;

  > div:last-child{
    display: flex;
    flex-direction: row;
    gap: 30px;
    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 14px;
        color: #0000008A;
        letter-spacing: 0.15px;
      }
    }
  }
`