import { CellType } from 'shared/ui/components/Table/model/types'

export const columns = {
  available: 'available',
  code: 'code',
  location: 'location',
  name: 'name',
  photo: 'photo',
  barcode: 'barcode',
  supplierName: 'supplierName',
  recipientName: 'recipientName',
  brand: 'brand',
  acceptanceDate: 'acceptanceDate',
  docNumber: 'docNumber',
  select: 'select'
} as const

export const columnsConfig = {
  config: [
    {
      name: columns.select,
      columnName: columns.select,
      title: ' ',
      width: 52,
      sortingEnabled: false,
      cellType: CellType.RowSelect,
    },
    {
      name: columns.acceptanceDate,
      columnName: columns.acceptanceDate,
      title: 'Принят',
      width: 115,
      sortingEnabled: true,
      wordWrapEnabled: true,
      cellType: CellType.AcceptanceData,
    },
    {
      name: columns.photo,
      columnName: columns.photo,
      title: 'Фото',
      width: 86,
      sortingEnabled: false,
      cellType: CellType.Photo,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.NameLink,
    },
    {
      name: columns.barcode,
      columnName: columns.barcode,
      title: 'ШК',
      width: 170,
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
    {
      name: columns.available,
      columnName: columns.available,
      title: 'Остатки',
      width: 120,
      sortingEnabled: true,
    },
    {
      name: columns.location,
      columnName: columns.location,
      title: 'Область',
      width: 90,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.Location,
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Артикул товара',
      width: 120,
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
    {
      name: columns.supplierName,
      columnName: columns.supplierName,
      title: 'Поставщик',
      width: 140,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.WmsSuppliers
    },
    {
      name: columns.recipientName,
      columnName: columns.recipientName,
      title: 'Получатель',
      width: 140,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.WordWrapCell
    },
  ] }