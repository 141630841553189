import { injectable } from 'inversify'
import {
  IUser,
  IUserSettings,
  UserProfile,
} from 'shared/services/interfaces/user'
import { IStorage } from 'shared/services/interfaces/storage'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { History } from '../history'
import { Request } from '../request'

@injectable()
export class User implements IUser {
  private _statuses: any

  constructor(
    private readonly _request: Request,
    private readonly _history: History,
    private readonly _localStore: IStorage
  ) {
    const updateHandler = {
      set: (obj: any, prop: any, value: any) => {
        // eslint-disable-next-line no-param-reassign
        obj[prop] = value
        if (prop !== 'onUpdateFunctions') {
          obj.onUpdateFunctions.forEach((fn: () => void) => fn())
          // eslint-disable-next-line no-param-reassign
          obj.onUpdateFunctions = []
        }
        return true
      },
    }

    this._statuses = new Proxy(
      {
        isAuth: false,
        onUpdateFunctions: [],
      },
      updateHandler
    )
  }

  authWatcher(): Promise<void> {
    return new Promise((resolve) => {
      if (this._statuses.isAuth === true) resolve(undefined)
      this._statuses.onUpdateFunctions = [
        ...this._statuses.onUpdateFunctions,
        () => {
          if (this._statuses.isAuth === true) {
            resolve(undefined)
          }
        },
      ]
    })
  }

  _setAuthorized(value?: false): void {
    this._statuses.isAuth = value !== undefined ? value : true
  }

  // eslint-disable-next-line class-methods-use-this
  async getUserSettings(): Promise<IUserSettings> {
    const result = await api.get<IUserSettings>('/customer/user/settings')

    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUserSettings(settings: object): Promise<IUserSettings> {
    const result = await api.put<IUserSettings>(
      '/customer/user/settings',
      settings
    )

    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadUserBadge(): Promise<AxiosResponse<Blob>> {
    const result = await api.get('/customer/user/badge', {
      responseType: 'blob',
    })
    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUserProfile(
    profile: UserProfile
  ): Promise<AxiosResponse<UserProfile>> {
    const result = await api.put('/customer/user', profile)
    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadUserPhoto(photo: FormData): Promise<AxiosResponse<{}>> {
    const result = await api.post('/customer/user/photo', photo)
    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteUserPhoto(): Promise<AxiosResponse<{}>> {
    return api.delete('/customer/user/photo', { data: {} })
  }
}
