import React, { useMemo, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import { Grid, Checkbox, Typography, Box, Divider } from '@mui/material'
import {
  useCompanyList,
  useMarketplaceList,
  useContragentList,
  useBrandList, useCompanyMarketplaceList,
} from 'shared/api/pim/dictionaries'
import { CheckboxField, InputLabel } from 'shared/ui/components'
import {
  FieldWithCount,
  InputField,
  MaskedInputField,
  SelectFieldWithAutoComplete,
} from 'shared/ui/components/form'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { Accordion } from 'shared/ui/components/Accordion'
import { getServerReadableDateTime } from 'shared/lib/utils'
import FormDatePicker from 'shared/ui/components/DatePicker/FormDatePicker'

import { MarketplaceWarehousesField } from './MarketPlaceWarehousesField'

import { getOptions, getOptionsContragent } from '../lib/getSelectFieldOptions'

const currentDate = new Date()

const defaultCalendarMinDate = getServerReadableDateTime(new Date(currentDate).setDate(currentDate.getDate()-60))
const defaultCalendarMaxDate = getServerReadableDateTime(currentDate.setDate(currentDate.getDate()-1))

export const CreateSupplyOrdersForm = ({ handleSubmit, isLoading, invalid }) => {
  const [, setDatePeriodOpen] = useState(false)
  const { marketplaceListQuery } = useMarketplaceList()
  const { companyListQuery } = useCompanyList()
  const { contragentListQuery } = useContragentList({ onlySuppliers: true })
  const { brandListQuery } = useBrandList()

  const marketplaceId = useField('marketplaceId').input.value
  const companyId = useField('companyId').input.value
  const aClassOnly = useField('aClassOnly').input.value
  const autoOrdering = useField('autoOrdering')
  const ordersStatFromDateField = useField('ordersStatFromDate')
  const ordersStatToDateField = useField('ordersStatToDate')
  const marketplaceWarehouse = useField('marketplacewarehouseId').input.value

  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(marketplaceId, companyId)

  const brandOptions = useMemo(
    () => brandListQuery.data?.map(getOptions) || [],
    [brandListQuery.data]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptionsContragent) || [],
    [contragentListQuery.data]
  )

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data]
  )

  const marketplaceOptions = useMemo(
    () => marketplaceListQuery.data?.map(getOptions) || [],
    [marketplaceListQuery.data]
  )

  const companyMarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const handleDatePeriodExpand = () => {
    ordersStatFromDateField.input.onChange(defaultCalendarMinDate)
    ordersStatToDateField.input.onChange(defaultCalendarMaxDate)
    setDatePeriodOpen(true)
  }
  const handleDatePeriodClose = () => {
    ordersStatFromDateField.input.onChange(null)
    ordersStatToDateField.input.onChange(null)
    setDatePeriodOpen(false)
  }

  const handleCreateEmptyOrder = () => {
    handleSubmit()
  }

  const handleCreateAutoOrder = () => {
    autoOrdering.input.onChange(true)
    handleSubmit()
  }
  const [isIgnored, setIsIgnored] = useState(false)

  const handleChangeIsIgnored = (event) => {
    setIsIgnored(event.target.checked)
  }
  const label = {
    inputProps: {
      'aria-label': 'isIgnore',
    }
  }

  return (
    <form onSubmit={() => {
    }}>
      <>
        <Typography variant="h5" sx={{ fontSize: '20px' }} mb="28px">Создание заказа</Typography>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={5}>
            <InputLabel label="Поставщик"/>
            <InputWrapper>
              <Field
                name="contragentId"
                id="contragentId"
                placeholder="Поставщик"
                required={true}
                errorAfterTouch={true}
                component={SelectFieldWithAutoComplete}
                fullWidth={true}
                options={contragentOptions}
                isLoading={contragentListQuery.isLoading}
              />
            </InputWrapper>
          </Grid>
          <Grid item={true} xs={5}>
            <InputLabel label="Продавец"/>
            <InputWrapper>
              <Field
                name="companyId"
                id="companyId"
                placeholder="Продавец"
                required={true}
                errorAfterTouch={true}
                component={SelectFieldWithAutoComplete}
                fullWidth={true}
                options={companyOptions}
              />
            </InputWrapper>
          </Grid>

          <Grid item={true} xs={5}>
            <InputLabel label="Номер заказа" required={false}/>
            <InputWrapper>
              <Field
                name="orderNumber"
                placeholder="Номер заказа"
                component={InputField}
              />
            </InputWrapper>
          </Grid>

          <Grid item={true} xs={5}/>

          <Grid item={true} xs={5}>
            <InputLabel label="Дата заказа" required={false}/>
            <InputWrapper>
              <Field
                id="orderDate"
                name="orderDate"
                label="Дата заказа"
                component={FormDatePicker}
              />
            </InputWrapper>
          </Grid>
          <Grid item={true} xs={5}>
            <InputLabel label="Количество дней для расчета" required={false}/>
            <InputWrapper>
              <Field
                name="days"
                placeholder="Количество дней для расчета"
                component={MaskedInputField}
                scale={0}
                min={0}
              />
            </InputWrapper>
          </Grid>
          <Grid item={true} xs={3} display="none">  {/* !!!временно отключено */}
            <InputLabel label="Ожидаемая дата поставки" required={false}/> 
            <InputWrapper> 
              <Field
                id="estimatedSupplyDate"
                name="estimatedSupplyDate" 
                placeholder="Дата поставки"
                InputLabelProps={{ 
                  shrink: true, 
                }} 
                component={FormDatePicker}
              />
            </InputWrapper> 
          </Grid>

          <Grid item={true} xs={12}>
            <Divider/>
          </Grid>
          <Grid item={true} xs={5} ml="14px">
            <InputWrapper>
              <Field
                name="aClassOnly"
                label="Продукты А-класса"
                type="checkbox"
                component={CheckboxField}
              />
            </InputWrapper>
          </Grid>
          <Grid item={true} xs={5} ml="14px">
            <InputWrapper>
              <Field
                name="notCClassOnly"
                label="Продукты, кроме С-класса"
                type="checkbox"
                component={CheckboxField}
              />
            </InputWrapper>
          </Grid>

          <Grid container={true} pl={3} pt={3} spacing={2}>
            <Grid item={true} xs={12}>
              <Accordion
                title="Добавить комментарий"
                onOpen={handleDatePeriodExpand}
                onClose={handleDatePeriodClose}
              >
                <Grid item={true} xs={12}>
                  <FieldWithCount maxLength={255}>
                    <Field
                      name="comments"
                      placeholder="Комментарий"
                      component={InputField}
                      multiline={true}
                      fullWidth={true}
                      errorAfterTouch={true}
                      minRows={1}
                      maxRows={10}
                    />
                  </FieldWithCount>
                </Grid>
              </Accordion>
            </Grid>
            <Grid item={true} xs={12}>
              <Accordion
                title="Бренд"
                onOpen={handleDatePeriodExpand}
                onClose={handleDatePeriodClose}
              >
                <Grid item={true} xs={12}>
                  <InputWrapper>
                    <Field
                      name="brandId"
                      id="brandId"
                      placeholder="Бренд"
                      required={false}
                      errorAfterTouch={true}
                      component={SelectFieldWithAutoComplete}
                      fullWidth={true}
                      options={brandOptions}
                      isLoading={brandListQuery.isLoading}
                    />
                  </InputWrapper>
                </Grid>
              </Accordion>
            </Grid>

            <Grid item={true} xs={12}>
              <Accordion
                title="Анализировать заказы за период"
                onOpen={handleDatePeriodExpand}
                onClose={handleDatePeriodClose}
              >
                <Grid container={true} spacing={10} mb={2}>
                  <Grid item={true} xs={6} pb={0}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={6} pb={0}>
                        <Field
                          id="ordersStatFromDate"
                          name="ordersStatFromDate"
                          label="Начало периода"
                          component={FormDatePicker}
                        />
                      </Grid>
                      <Grid item={true} xs={6} pb={0}>
                        <Field
                          id="ordersStatToDate"
                          name="ordersStatToDate"
                          label="Конец периода"
                          component={FormDatePicker}
                        />
                      </Grid>
                    </Grid>
                  </Grid>


                  <Grid item={true} xs={6} pb={0} position="relative">
                    <Box sx={
                      { position: 'absolute',
                        top: '27px',
                        left: '30px',
                        display: 'flex' }
                    }>
                      <Checkbox
                        {...label}
                        onChange={ handleChangeIsIgnored }
                        checked={isIgnored}
                        sx={ { mr: '8px' } }
                      />
                      <Typography
                        lineHeight={2.6}
                        sx={ isIgnored
                          ? { color: '#000000DE' }
                          : { color: '#00000060' }
                        }
                      >
                        Исключить период
                      </Typography>
                    </Box>


                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={6} pb={0}>
                        <Field
                          id="ignoreOrdersStatFromDate"
                          name="ignoreOrdersStatFromDate"
                          label="Начало периода"
                          disabled={!isIgnored}
                          required={isIgnored}
                          component={FormDatePicker}
                        />
                      </Grid>
                      <Grid item={true} xs={6} pb={0}>
                        <Field
                          id="ignoreOrdersStatToDate"
                          name="ignoreOrdersStatToDate"
                          label="Конец периода"
                          disabled={!isIgnored}
                          required={isIgnored}
                          component={FormDatePicker}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>

            <Grid item={true} xs={14}>
              <Accordion
                title="Маркетплейс"
                onOpen={handleDatePeriodExpand}
                onClose={handleDatePeriodClose}
              >
                <Grid container={true} spacing={2}>
                  <Box sx={
                    { position: 'absolute',
                      top: '3px',
                      left: '372px',
                      display: 'flex' }
                  }>
                    <Typography lineHeight={2.6} sx={{ color: '#000000DE' }}>
                      Кабинет
                    </Typography>
                  </Box>
                  <Grid item={true} xs={3.8} mr={1}>
                    <InputWrapper>
                      <Field
                        name="marketplaceId"
                        id="marketplaceId"
                        placeholder="Маркетплейс"
                        required={true}
                        errorAfterTouch={true}
                        component={SelectFieldWithAutoComplete}
                        fullWidth={true}
                        options={marketplaceOptions}
                        isLoading={marketplaceListQuery.isLoading}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item={true} xs={4.4}>
                    <InputWrapper>
                      <Field
                        name="companymarketplaceId"
                        id="companymarketplaceId"
                        placeholder="Кабинет"
                        required={false}
                        errorAfterTouch={true}
                        component={SelectFieldWithAutoComplete}
                        fullWidth={true}
                        options={companyMarketplaceOptions}
                        isLoading={companyMarketplaceListQuery.isLoading}
                      />
                    </InputWrapper>
                  </Grid>
                  {marketplaceId &&
                  <>
                    <Grid item={true} xs={8.3} mr={2}>
                      <MarketplaceWarehousesField marketplaceId={marketplaceId}/>
                    </Grid>
                    <Grid item={true} xs={3.4}>
                      <InputWrapper>
                        <Field
                          name="multipleOrders"
                          label="Создать несколько заказов"
                          type="checkbox"
                          disabled={marketplaceWarehouse.length < 2}
                          component={CheckboxField}
                        />
                      </InputWrapper>
                    </Grid>
                  </>
                  }
                </Grid>
              </Accordion>
            </Grid>
          </Grid>

          <Grid container={true} item={true} xs={12} mt={3} justifyContent="space-between">
            <Grid item={true} xs={4} justifyContent="space-between">
              <LoadingButton
                color="primary"
                variant="outlined"
                fullWidth={false}
                onClick={handleCreateEmptyOrder}
                loading={isLoading}
                disabled={aClassOnly}
              >
                ПУСТОЙ ЗАКАЗ
              </LoadingButton>
            </Grid>
            <Grid item={true}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleCreateAutoOrder}
                fullWidth={false}
                disabled={invalid}
                loading={isLoading}
              >
                СОЗДАТЬ
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </form>
  )
}
