import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'


type ColumnNameKey = keyof tradeApi.SupplyOrderHistoryRecord
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  actionDate: 'actionDate',
  actionName: 'actionName',
  comments: 'comments',
  fileName: 'fileName',
  fileUrl: 'fileUrl',
  fromStatusId: 'fromStatusId',
  toStatusId: 'toStatusId',
  userName: 'userName',
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: false,
  config: [
    {
      name: columns.actionDate,
      columnName: columns.actionDate,
      title: 'Дата',
      width: 176,
      sortingEnabled: true,
      cellType: CellType.Date,
      dataFormat: DateFormats.dayMonthYearHoursMinutes,
    },
    {
      name: columns.userName,
      columnName: columns.userName,
      title: 'Пользователь',
      width: 208,
      sortingEnabled: false,
    },
    {
      name: columns.actionName,
      columnName: columns.actionName,
      title: 'Действие',
      width: 'auto',
      sortingEnabled: false,
    },
    {
      name: columns.toStatusId,
      columnName: columns.toStatusId,
      title: 'Статус',
      width: 359,
      sortingEnabled: false,
      cellType: CellType.Status,
    },
    {
      name: columns.comments,
      columnName: columns.comments,
      title: 'Комментарий',
      width: 320,
      sortingEnabled: false,
    },
    {
      name: columns.fileName,
      columnName: columns.fileName,
      title: 'Документ',
      width: 200,
      sortingEnabled: false,
      cellType: CellType.DocumentWithLink
    }
  ],
}
