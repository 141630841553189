import * as yup from 'yup'

export const validationSchema = yup.object({
  variationAttributes: yup.array().of(
    yup.object().shape({
      value: yup.string().required(),
    }).required()
  ),
  code: yup.string().required()
})
