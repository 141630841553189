import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosError } from 'axios'

function getUsers(privilegeId: number | null)
{
  return api.get<pimApi.admin.AppAdminModelUsers[]>('/customer/admin/users', {
    params: { with_privilege_id: privilegeId }
  }).then((res) => res.data)
}
type QueryKey = ReturnType<typeof QUERY_KEY>

const QUERY_KEY = (privilegeId: number | null) => ['get', 'customer/admin/users', privilegeId] as const

export const useGetUsersQuery = (privilegeId: number | null) =>
  useQuery<
    pimApi.admin.AppAdminModelUsers[],
    AxiosError<errorType>,
    pimApi.admin.AppAdminModelUsers[],
    QueryKey
  >(
    QUERY_KEY(privilegeId),
    () => getUsers(privilegeId)
  )
