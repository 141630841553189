import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyledWrapper } from './styled'

export const PercentsDiffCell = ({
  directionUp,
  percentsDiff
}: {
  directionUp?: boolean
  percentsDiff?: number
}) => (
  <>
    {percentsDiff && (
      <StyledWrapper directionUp={directionUp || false}>
        <FontAwesomeIcon icon={directionUp ? faCaretUp : faCaretDown} />
        <span>
          {percentsDiff}%
        </span>
      </StyledWrapper>
    )}
  </>
)
