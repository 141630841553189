/* eslint-disable */
// @ts-nocheck

import keyMirror from 'keymirror-nested'
import { put } from 'redux-saga/effects'
import watcherCreator from 'store/watcherCreator'
import { actionType } from 'store/actionsCreator'

export const PENDING = keyMirror(
  {
    SAVE: null,
    REMOVE: null,
  },
  '_',
  'PENDING'
)

export const saveAction = (actionType: string): actionType<string> => ({
  type: PENDING.SAVE,
  data: actionType,
})
export const removeAction = (actionType: string): actionType<string> => ({
  type: PENDING.REMOVE,
  data: actionType,
})

export interface pendingStateType {
  pendingActions: string[]
}

export const initialState: pendingStateType = {
  pendingActions: [],
}

export const pendingSelector = (state: {
  pendingReducer: pendingStateType
}): pendingStateType => state.pendingReducer

export const pendingReducer = (
  state = initialState,
  action: actionType<string, errorType>
): pendingStateType => {
  switch (action.type) {
    case PENDING.SAVE:
      return {
        ...state,
        pendingActions: [
          ...new Set([...state.pendingActions, <string>action.data]),
        ],
      }
    case PENDING.REMOVE:
      return {
        ...state,
        pendingActions: state.pendingActions.filter(
          (item) => item !== action.data
        ),
      }
    default:
      return state
  }
}

export const pendingWatcher = watcherCreator(
  '*',
  function* ({ type }: actionType<string, errorType>) {
    if (
      type !== PENDING.SAVE &&
      type !== PENDING.REMOVE &&
      type.indexOf('_PENDING') !== -1
    ) {
      yield put(saveAction(type.replace(/\[\d*] /, '')))
    }
    if (type.indexOf('_SUCCESS') !== -1) {
      yield put(
        removeAction(
          type.replace('_SUCCESS', '_PENDING').replace(/\[\d*] /, '')
        )
      )
    }
    if (type.indexOf('_ERROR') !== -1) {
      yield put(
        removeAction(type.replace('_ERROR', '_PENDING').replace(/\[\d*] /, ''))
      )
    }
  }
)
