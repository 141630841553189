import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { SUPPLY_ORDER_CREATE } from 'shared/config/routes'

export const Header = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={3}
    mt="28px"
  >
    <Typography component="h1" variant="h3" fontSize="1.5rem">
      Заказы поставщикам
    </Typography>
    <Box mr="auto" ml={2}>
      <Button
        variant="contained"
        component={Link}
        to={SUPPLY_ORDER_CREATE}
        color="primary"
      >
        СОЗДАТЬ ЗАКАЗ
      </Button>
    </Box>
  </Box>
)
