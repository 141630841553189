import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'

export const getCurrentCompanyQueryKey = (companyId: UniqueId) =>
  ['get', 'customer/companies', companyId] as const

type GetCurrentCompanyQueryKey = ReturnType<typeof getCurrentCompanyQueryKey>


function getCurrentCompany(companyId: UniqueId) {
  return api.get<customerApi.Company>
  (`/customer/companies/${companyId}`).then((res) => res.data)
}

const getCurrentCompanyFn = (context: QueryFunctionContext<GetCurrentCompanyQueryKey>) => {
  const [, ,companyId] = context.queryKey
  return getCurrentCompany(companyId)
}


export const useGetCurrentCompanyQuery = (companyId: UniqueId) =>
  useQuery<
    customerApi.Company,
    errorType,
    customerApi.Company,
    GetCurrentCompanyQueryKey
  >(
    getCurrentCompanyQueryKey(companyId),
    getCurrentCompanyFn, { refetchOnMount: false }
  )
