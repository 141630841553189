import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'

import { ProductPriceHistory } from './models'


export interface ProductPriceHistoryParams {
  fromDate?: string
  toDate?: string
  searchString?: string
  marketplaceId?: number;
  brandId?: number;
  companyId?: number
  companymarketplaceId?: number;
  contragentId?: number;
  changerId?: number;
  range?: number;
  trend?: string;
  trendPercentThreshold?: number;
}

const getProductPriceHistoryQueryKey = (queryParams: ProductPriceHistoryParams) => {
  Object.keys(queryParams).forEach(key => {
    if (queryParams[key] === null || queryParams[key] === undefined || queryParams[key] === '') {
      // eslint-disable-next-line no-param-reassign
      delete queryParams[key]
    }
  })
  const { range, ...rest } = queryParams
  return (['get', 'pim/products/pricehistory', rest] as const )
}

type GetProductPriceHistoryQueryKey = ReturnType<typeof getProductPriceHistoryQueryKey>

function getProductPriceHistory(queryParams: ProductPriceHistoryParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  const { range, ...rest } = queryParamsSnake
  return api.get<Array<ProductPriceHistory>>(
    '/pim/products/pricehistory', { params: rest })
}

const getAllProductPriceHistory = (context: QueryFunctionContext<GetProductPriceHistoryQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getProductPriceHistory(queryParams)
}

export const useProductPriceHistoryQuery = (queryParams: ProductPriceHistoryParams) =>
  useQuery<
    AxiosResponse<Array<ProductPriceHistory>>,
    errorType,
    AxiosResponse<Array<ProductPriceHistory>>,
    GetProductPriceHistoryQueryKey
  >(
    getProductPriceHistoryQueryKey(queryParams),
    getAllProductPriceHistory,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
