import React, { PropsWithChildren } from 'react'

import { Chart } from './Chart'
import { StyledChartContainer } from './styled'

import { ChartParams } from '../../components/ChartParams'

interface WidgetData {
  name: string
  valueField: string
  data: number
  diff: number
  color: string
  areaColor?: {
    fillId: string
  }
}

interface Props {
  title: string
  setDataParams: (e) => void
  dataParams: any
  leftData: WidgetData
  rightData: WidgetData
  isLoading: boolean
  chartUrl: string
  chartData: any
}
export const MultiSeriesLargeSizeWidget = ({
  title,
  dataParams,
  chartUrl,
  isLoading,
  leftData,
  rightData,
  setDataParams,
  children,
  chartData
}: PropsWithChildren<Props>) => (
  <StyledChartContainer>
    <div id="data-params-box">
      <span>{title}</span>
      <ChartParams chartUrl={chartUrl} dataParams={dataParams} setDataParams={setDataParams}/>
    </div>
    <div>
      {children}
    </div>
    <Chart dataParams={dataParams} isLoading={isLoading} chartData={chartData} leftData={leftData} rightData={rightData}/>
  </StyledChartContainer>
)