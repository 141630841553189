import styled from 'styled-components'
import CreateIcon from '@mui/icons-material/Create'
import { DialogTitle, DialogActions } from '@mui/material'

export const StyledCreateIcon = styled(CreateIcon)`
  color: #BDBDBD;
`

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px;
  font-size: 20px;
`

export const StyledDialogActions = styled(DialogActions)`
  padding: 16px 0 0 0;
`