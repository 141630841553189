import React from 'react'
import { Link as RouterLink, Route, Switch, useParams } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { getGoodsPanelContentPath } from 'shared/config'
import { TabPanel } from 'shared/ui/components'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { GoodsPanelPaper , Header , AttributesTab , CategoriesTab } from './ui'

import { AdminGoodsPageTabs } from '../model/types'

const TABS = [
  {
    label: 'Категории',
    Component: CategoriesTab,
    value: 'categories',
  },
  {
    label: 'Атрибуты',
    Component: AttributesTab,
    value: 'attributes',
  }
] as const

export const GoodsPanel = () => {
  const { tab } = useParams<{ tab: AdminGoodsPageTabs }>()
  return (
    <GoodsPanelPaper>
      <Header/>
      <Tabs value={ tab }>
        { TABS.map(({ label, value }, index) => (
          <Tab
            key={ value }
            label={ label }
            value={ TABS[index].value }
            to={ getGoodsPanelContentPath(value) }
            component={ RouterLink }
            { ...a11yProps(index) }
          />
        )) }
      </Tabs>
      <TabPanel>
        <Switch>
          { TABS.map(({ value, Component }) => (
            <Route key={value} path={ getGoodsPanelContentPath(value) } exact={ true } component={ Component } />
          )) }
        </Switch>
      </TabPanel>
    </GoodsPanelPaper>
  )
}