import React, { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { InputAdornment } from '@mui/material'
import { Settings } from '@mui/icons-material'
import { SettingsMenu } from 'shared/ui/components'

import { EditBarcodeFieldStyled } from './styled'


interface EditBarcodeFieldProps {
  barcode: pimApi.products.Barcode
  isMainBarcode: boolean
  disabled?: boolean
  totalBarcodesCount: number
  editBarcode: (barcode: pimApi.products.EditBarcode) => void
  deleteBarcode: (barcode: DeletingObject) => void
  makeMainBarcode: (barcodeId: UniqueId) => void
  barcodeType?: string
}

function getLabel(
  expiredFlag: Boolean,
  isMainBarcode: Boolean
) {
  if (isMainBarcode) {
    return 'Основной штрих-код'
  }
  if (expiredFlag) {
    return 'Устаревший штрих-код'
  }
  return 'Дополнительный штрих-код'
}

export const EditBarcodeField = (
  {
    barcode,
    isMainBarcode,
    disabled,
    totalBarcodesCount,
    editBarcode,
    deleteBarcode,
    makeMainBarcode,
    barcodeType = 'number',
  } : EditBarcodeFieldProps
) => {
  const handleEditBarcodeBlur = ({ target } ) => {
    if (target.value.length>0) {
      if (barcode.barcode!==target.value) {
        editBarcode({
          id: barcode.id,
          versionNo: barcode.versionNo,
          barcode: target.value,
          expiredFlag: barcode.expiredFlag
        })
      }
    }
  }
  const getBarcodeSettings = useMemo(() => [
    {
      label: 'Сделать основным',
      handler: () => {
        makeMainBarcode(barcode.id)
      },
      disabled: isMainBarcode || barcode.expiredFlag,
    },
    {
      label: barcode.expiredFlag
        ? 'Сделать актуальным'
        : 'Сделать устаревшим',
      handler: () => {
        editBarcode({
          id: barcode.id,
          versionNo: barcode.versionNo,
          barcode: barcode.barcode,
          expiredFlag: !barcode.expiredFlag
        })
      },
      divider: true,
      disabled: isMainBarcode,
    },
    {
      label: 'Удалить',
      type: 'error',
      handler: () => {
        deleteBarcode({ id: barcode.id, versionNo: barcode.versionNo })
      },
      disabled: isMainBarcode || !barcode.editPossible,
    },
  ]
  , [barcode, isMainBarcode])

  return (
    <EditBarcodeFieldStyled
      label={getLabel(barcode.expiredFlag, isMainBarcode)}
      variant="outlined"
      size="small"
      type={barcodeType}
      fullWidth={true}
      $boldLabel={isMainBarcode}
      disabled={!barcode.editPossible || disabled}
      defaultValue={barcode.barcode}
      onBlur={handleEditBarcodeBlur}
      InputProps={{
        endAdornment: (
          <>
            { !isMainBarcode && totalBarcodesCount > 1 && (
              <InputAdornment position="end">
                <SettingsMenu
                  options={getBarcodeSettings}
                  iconType={<Settings />}
                  iconSize="small"
                />
              </InputAdornment>
            )}
          </>
        )
      }}
    />
  )
}