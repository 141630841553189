import React, { useState } from 'react'
import {
  Button,
  Box,
  Switch, Typography
} from '@mui/material'
import { InputField, Form, Field } from 'shared/ui/components/form'
import { useLoginMutation, useSuLoginMutation } from 'shared/api/auth'
import { getFingerprint } from 'shared/lib/browser/getFingerprint'
import { api } from 'shared/api'
import { Link } from 'react-router-dom'
import { USER_PASSWORD_RECOVERY } from 'shared/config'

import {
  Wrapper,
  TypographyStyled,
  HelperTextSupply,
  FormControlLabelStyled,
  CircularProgressStyled
} from './styles'

interface Credentials {
  login: string
  password: string
}

interface SuCredentials {
  login: string
  password: string
  customer_code: string
}

interface AuthorizationFormUIProps {
  remember: boolean
  setRemember: any
  onSubmit: (values) => void
  errorMessage?: string
  isLoading: boolean,
  showCustomerCodeField: boolean,
}

function AuthorizationFormUI({ 
  onSubmit, 
  errorMessage,
  isLoading,
  showCustomerCodeField, 
  remember,
  setRemember } : AuthorizationFormUIProps) {

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemember(event.target.checked)
  }
  return (
    <Wrapper showCustomerCodeField={showCustomerCodeField}>
      <TypographyStyled>Добро пожаловать</TypographyStyled>
      <Typography variant="body1" fontSize={14} mb={4}>Авторизуйтесь чтобы продолжить</Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          const disabledButton = () => {
            if (values && values.login && values.password) {
              if (showCustomerCodeField && values.customer_code) {
                return (values.login.length && values.password.length && values.customer_code.length) === 0
              }
              return (values.login.length && values.password.length) === 0
            }
            return true
          }

          return (
            <form onSubmit={handleSubmit}>
              <HelperTextSupply>
                <Field
                  type="text"
                  component={InputField}
                  margin="normal"
                  fullWidth={true}
                  label="Логин"
                  name="login"
                  autoComplete="email"
                  isError={errorMessage === 'Пользователь не найден!'}
                  helperText={errorMessage === 'Пользователь не найден!' && errorMessage}
                  parse={(value: string) => value.trim()}
                  InputProps={{ autoComplete: 'on' }}
                />
              </HelperTextSupply>
              <HelperTextSupply>
                <Field
                  type="password"
                  component={InputField}
                  margin="normal"
                  fullWidth={true}
                  label="Пароль"
                  name="password"
                  isPassword={true}
                  isError={errorMessage === 'Неверный пароль!'}
                  helperText={errorMessage === 'Неверный пароль!' && errorMessage}
                  autoComplete="password"
                />
              </HelperTextSupply>
              {showCustomerCodeField &&
                <HelperTextSupply>
                  <Field
                    type="text"
                    component={InputField}
                    margin="normal"
                    fullWidth={true}
                    label="Код клиента"
                    name="customer_code"
                    isError={errorMessage === 'Клиент с таким кодом не найден!'}
                    helperText={errorMessage === 'Клиент с таким кодом не найден!' && errorMessage}
                    parse={(value: string) => value.trim()}
                  />
                </HelperTextSupply>
              }
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mt={3}
                mb={3}>
                <FormControlLabelStyled
                  name="rememberMe"
                  value={remember}
                  control={<Switch value="rememberMe" checked={remember} onChange={handleSwitchChange} color="primary"/>}
                  label="Запомнить меня"
                />
                <Typography
                  component={Link}
                  to={USER_PASSWORD_RECOVERY}
                  variant="body1"
                  fontSize={14}
                  sx={{ textDecoration: 'none' }}
                  color="#2196F3">Забыли пароль</Typography>
              </Box>
              <Box mb={1} mt={1}>
                <Button
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  disabled={disabledButton()}
                >
                  {isLoading && <CircularProgressStyled size={20}/>}
                  Войти
                </Button>
              </Box>
            </form>
          )
        }}
      />
    </Wrapper>
  )
}

export const AuthorizationForm = () => {
  const [ remember, setRemember ] = useState(false)
  const { mutate: login, isLoading, error } = useLoginMutation()

  const handleSubmit = async (credentials: Credentials) => {
    const fingerprint = await getFingerprint()
    login({
      ...credentials,
      remember_me: remember,
      fingerprint
    }, {
      onSuccess({ data }) {
        api.setToken(data.accessToken)
      }
    })
  }

  return (
    <AuthorizationFormUI
      remember={remember}
      setRemember={setRemember}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      errorMessage={error?.response?.data.message}
      showCustomerCodeField={false}
    />
  )
}

export const SuAuthorizationForm = () => {
  const [ remember, setRemember ] = useState(false)
  const { mutate: login, isLoading, error } = useSuLoginMutation()

  const handleSubmit = async (credentials: SuCredentials) => {
    const fingerprint = await getFingerprint()
    login({
      ...credentials,
      remember_me: remember,
      fingerprint
    }, {
      onSuccess({ data }) {
        api.setToken(data.accessToken)
      }
    })
  }

  return (
    <AuthorizationFormUI
      remember={remember}
      setRemember={setRemember}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      errorMessage={error?.response?.data.message}
      showCustomerCodeField={true}
    />
  )
}
