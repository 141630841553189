import { Option } from 'shared/ui/components/interface'

interface OptionItem {
  name?: string
  id: number
}

export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
})

interface IOptionItem {
  shortName?: string
  id: number
}

export const getOptionsContragent = (optionItem: IOptionItem): Option<number> => ({
  label: optionItem.shortName || '',
  value: optionItem.id
})
