import { useQuery, QueryFunctionContext } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

type QueryKey = ReturnType<typeof getGoodsQueryKey>

export const getGoodsQueryKey = (
  goodsId?: UniqueId,
  withAttributes = false
) => ['get', 'pim/goods', goodsId, withAttributes] as const

function getGoods(context: QueryFunctionContext<QueryKey, any>) {
  return api
    .get<pimApi.goods.Goods>(
      `/pim/goods/${context.queryKey[2]}?get_attributes=${context.queryKey[3]}`
    )
    .then((res) => res.data)
}

export const useFetchGoodsQuery = (goodsId?: UniqueId, withAttributes?: boolean) =>
  useQuery<pimApi.goods.Goods, errorType, pimApi.goods.Goods, QueryKey>(
    getGoodsQueryKey(goodsId, withAttributes),
    getGoods,
    {
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      enabled: !!goodsId
    }
  )
