import { Method } from 'shared/lib/generated/wms/Api'

import { ValueCell , AutocompleteCell , InputCell , PhotoCell } from '../ui/modalCells'

export const columnsConfig = (method: Method) => [
  {
    label: 'Область',
    value: 'location',
    width: '90px',
    component: ValueCell
  },
  {
    label: 'Фото',
    value: 'photo',
    width: '75px',
    component: PhotoCell
  },
  {
    label: 'ШК',
    value: 'barcode',
    width: '170px',
    component: ValueCell
  },
  {
    label: 'Владелец',
    value: 'ownerName',
    width: '120px',
    component: ValueCell
  },
  {
    label: 'Номер заказа',
    value: 'docNumber',
    width: '95px',
    component: ValueCell
  },
  ...((method === 'move_stocks' && [{
    label: 'Область перемещения',
    value: 'newAreaId',
    width: '127px',
    component: AutocompleteCell
  }]) || []),
  {
    label: 'Кол-во товаров',
    value: 'available',
    width: '120px',
    component: InputCell
  },
]