import { Category } from 'shared/api/pim/categories/models'
import { isNotNil } from 'shared/lib/checkers'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export function filterCategories(array, fn) {
  return array.reduce((prev, curr) => {
    const children = filterCategories(curr.children || [], fn)
    if (fn(curr) || children.length) prev.push({ ...curr, ...children.length && { children } })
    return prev
  }, [])
}

export function addCategory(arr, id, val) {
  if (id === null) {
    arr.push(val)
    return arr
  }
  return arr.map(item => ({
    ...item,
    children: item.id === id ? addCategory([...item.children, val], id, val) : addCategory(item.children, id , val)
  }))
}

export function deleteCategoryItem(arr, id) {
  const index = arr.findIndex(item => item.id === id)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr.map(item => ({
    ...item,
    children: deleteCategoryItem(item.children, id)
  }))
}

export function flatCategories(arr: Category[]) {
  const stack: Array<string> = []

  arr.forEach(el => {
    if (isNotNil(el.children) && isNotEmptyArray(el.children)) {
      stack.push(el.id.toString())
      stack.push(...flatCategories(el.children))
    }
  })

  return stack
}