import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: 100%;
  }
`