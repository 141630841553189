import { useParams } from 'react-router-dom'
import { customerApi } from 'shared/api'
import { CompanyContextProvider } from 'entities/pim/admin/useCompanyEditContext'
import { EditCompany } from 'features/admin/Company/EditCompany/EditCompany'

export const EditCompanyPage = () => {
  const { id } = useParams<{id: string}>()
  const companyId = parseInt(id, 10)
  const companyQuery = customerApi.useGetCurrentCompanyQuery(companyId)
  const companyData = companyQuery.data

  return (
    <CompanyContextProvider companyQuery={companyQuery}>
      { companyData && <EditCompany data={companyData} /> }
    </CompanyContextProvider>
  )
}
