import { productModel } from 'entities/pim/product'
import { pimApi } from 'shared/api'
import { replaceEditorTags } from 'shared/lib/utils'
import { isNil } from 'shared/lib/checkers'

export const EditOzonProductChangesComparatorSerializer = ({
  // поля, которые не нужны
  attributes,
  brandName,
  cardStatus,
  categoryId,
  categoryName,
  changed,
  changer,
  companyId,
  ozonProductId,
  packagingrequirement,
  syncStatus,
  photos,
  photos360,
  videos,
  pdfs,
  futurePrices,
  price,
  oldPrice,
  priceRecentHistory,
  versionNo,
  action,
  // конец полей, которые не нужны
  ...formValues
}: productModel.ozonModel.EditSubmitFormValues): Partial<pimApi.products.EditOzonCard> => ({
  ...formValues,
  attributes: attributes.map(({ values, type, ...rest }) => ({
    ...rest,
    type,
    values: values.map(({ dictionaryValueId, value, ...args }) => ({
      ...args,
      value: type === 'Boolean' ? value?.toString().toLowerCase() : value,
      dictionaryValueId,
    })),
  })),
  height: isNil(formValues.height) ? undefined : Number(formValues.height),
  weight: isNil(formValues.weight) ? undefined : Number(formValues.weight),
  width: isNil(formValues.width) ? undefined : Number(formValues.width),
  depth: isNil(formValues.depth) ? undefined : Number(formValues.depth),
  brandId: formValues.brandId?.value,
  description: replaceEditorTags(formValues.description),
})
