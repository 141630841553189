import React, { memo } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { Image } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { Field, MaskedInputField } from 'shared/ui/components/form'
import { Delete } from '@mui/icons-material'
import imagePlaceholder from 'assets/images/imagePlaceholder.svg'
import { generatePath } from 'react-router'
import { PRODUCT_EDIT } from 'shared/config'
import { ProductAuxCode, ProductInfo } from 'pages/goods/components/GoodsComponent/styled'

import { CustomInput, ProductImage, ProductTitle, ResultItem } from './styled'


interface ProductShortInfoWidgetProps {
  productId?: UniqueId
  name?: string
  marketplaceCode?: string
  marketplaceUrl?: string
  marketplaceName?: string
  cabinetName?: string
  marketplaceCategory?: string
  barcode?: string
  price?: number
  companyName?: string
  autoOrdering?: boolean
  addInfo?: boolean
  disabled?: boolean
  quantityFieldName?: string
  onRemoveClick?: () => void
  onAddClick?: () => void
}

export const ProductShortInfoWidget = memo(({
  productId,
  name,
  marketplaceCode,
  marketplaceUrl,
  price,
  autoOrdering,
  addInfo,
  cabinetName,
  marketplaceName,
  companyName,
  disabled,
  quantityFieldName,
  onRemoveClick,
  onAddClick
}: ProductShortInfoWidgetProps) => {
  const Placeholder = () => (
    <img src={imagePlaceholder} alt="placeholder"/>
  )

  const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

  function getApiUrl(prodId) {
    return pimApi.products.getFileThumbnailApiPath(
      prodId,
      fileThumbnailSize
    )
  }

  return (
    <ResultItem className="result-item">
      <Box display="flex" alignItems="center" pr={3} width="470px">
        <ProductImage>
          <Image src={getApiUrl(productId)} onError={Placeholder} />
        </ProductImage>
        <ProductInfo>
          <ProductTitle
            addInfo={addInfo!}
            title={name}
            target="_blank"
            href={generatePath(PRODUCT_EDIT, { id: productId! })}>
            {name}
          </ProductTitle>
          {addInfo &&
            <Box display="flex" flexDirection="row" sx={{ fontSize: '12px', color: 'rgba(0,0,0,0.54)' }}>
              {marketplaceName && <span style={{ marginRight: '4px' }}>{`${marketplaceName}`}</span>}
              {companyName && <span style={{ marginRight: '4px' }}>{`| ${companyName}`}</span>}
              {cabinetName && <span>{`| ${cabinetName}`}</span>}
            </Box>
          }
          <Box display="flex" flexDirection="row">
            <ProductAuxCode>
              Номенклатура{' '}
              <a href={marketplaceUrl} target="_blank" rel="noreferrer"
                style={{ textDecoration: 'none', color: '#2196F3', marginRight: '3px' }}>
                {`${marketplaceCode}`}
              </a>
            </ProductAuxCode>
            <ProductAuxCode>{` | Цена, р.: ${price}`}</ProductAuxCode>
            {autoOrdering ? <ProductAuxCode> | Автозаказ</ProductAuxCode> : <></>}
          </Box>
        </ProductInfo>
      </Box>
      <Box display="inline-flex" alignItems="center" pl={2}>
        {quantityFieldName && (
          <CustomInput>
            <Field
              name={quantityFieldName}
              label="шт"
              component={MaskedInputField}
              scale={0}
              disabled={disabled}
              min={1}
              defaultValue="1"
            />
          </CustomInput>
        )}
        <Box display="inline-flex" ml={2} pr={1} >
          {onRemoveClick && (
            <IconButton size="small" sx={{ marginRight: '14px', color: 'rgba(0, 0, 0, 0.26)' }} disabled={disabled} onClick={onRemoveClick}>
              <Delete/>
            </IconButton>
          )}
          {onAddClick && (
            <Box pr={1.5}>
              <Button onClick={ onAddClick } variant="outlined" disabled={disabled}>ВЫБРАТЬ</Button>
            </Box>
          )}
        </Box>
      </Box>
    </ResultItem>
  )
}
)