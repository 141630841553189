// @ts-nocheck
import { QueryClient, QueryClientProvider } from 'react-query'
import { QUERY_CLIENT_OPTIONS } from 'shared/config'

const queryClient = new QueryClient(QUERY_CLIENT_OPTIONS)

export const withQueryClient = (component: () => React.ReactNode) => () => (
  <QueryClientProvider client={queryClient}>
    {component()}
  </QueryClientProvider>
)
