import styled from 'styled-components'
import { EditableInput } from 'shared/ui/components'
import { Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

export const StyledTd = styled.td`
  padding-left: 20px;
`

export const EditableStyledInput = styled(EditableInput)`
  input {
    min-width: 103px;
    color: #007DFF;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 6px;
    border-style: none;
    font-size: 0.875rem;
    line-height: 1.43;
    cursor: pointer;
    :hover {
      ::placeholder {
        opacity: 1;
      }
    }
  }
`

export const StyledButton = styled(Button)`
  padding: 2px 10px 0 10px;
  box-shadow: none;
`

export const StyledSaveIcon = styled(SaveIcon)`
  margin-top: -2px;
  margin-right: 10px;
`

export const AddAcceptanceViewer = styled.span`
  display: inline-block;
  color: #007DFF;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.43;
`
