import { useCallback } from 'react'
import {
  EditingState,
  DataTypeProvider,
  ChangeSet,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui'
import { noop } from 'shared/lib/lodash/noop'
import { CalculatedReceiptDocPosition, ReceiptDocAcceptance } from 'domains/doc'
import { useDispatch, useSelector } from 'react-redux'
import { detailIdSelector, detailStatusSelector } from 'store/doc/selectors'
import { changeAcceptance, removeAcceptance } from 'pages/docs/model'
import { dialogModel } from 'shared/ui/components/dialog'
import { addDocAcceptance, changeDocAcceptance, removeDocAcceptance } from 'store/doc'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { tableLocale } from 'shared/ui/components/Table/lib'

import { StyledTableCell, StyledTableHeaderRow, StyledTableRow, Wrapper } from './styled'
import { CellInput } from './CellInput'
import { getCell } from './Cells'
import { NewAcceptance } from './NewAcceptance'
import { nestedTableColumns, nestedTableColumnExtensions } from './ColumnsConfig'
import { transformAcceptanceChanges } from './helpers'

import { checkOnEditable } from '../Table/helpers'

const editableColumns = ['quantityAccepted', 'quantityDefective']
const customRow = [{ new: 'new', id: -1 }]

const getRowId = (row: ReceiptDocAcceptance) => row.id
const HeaderCellComponent = (props: TableHeaderRow.CellProps) => (<StyledTableHeaderRow {...props} />)
/* @ts-ignore */
const EditingColumnsProvider = (props: { for: string[]}) => ( <DataTypeProvider editorComponent={CellInput} {...props} /> )

interface Props {
  row: CalculatedReceiptDocPosition;
}

export const NestedTable = ({ row }: Props) => {
  const dispatch = useDispatch()
  const docDetailStatus = useSelector(detailStatusSelector)
  const docDetailId = useSelector(detailIdSelector)
  

  const isEditable = checkOnEditable(docDetailStatus)

  const docAndPositionIds = {
    docId: docDetailId,
    positionId: row.id,
  }

  const commitChanges = async ({ changed }: ChangeSet) => {
    if (!changed) return

    const acceptanceId = +Object.keys(changed)[0]
    const changeData = changed[acceptanceId]
    const currentAcceptance = row?.acceptance?.find((item) => (item.id === acceptanceId))
    if (!currentAcceptance || !changeData) return

    const acceptedTotal = currentAcceptance?.quantityAccepted * currentAcceptance?.quantityInPackaging
    const isDeffectiveMoreThenAccepted = changeData?.quantityDefective > acceptedTotal
    if (isDeffectiveMoreThenAccepted) return

    const transformedAcceptanceChanges = transformAcceptanceChanges(changeData)
    
    const requestParams = {
      ...docAndPositionIds,
      acceptance: {
        id: acceptanceId,
        versionNo: currentAcceptance.versionNo,
        ...transformedAcceptanceChanges,
      },
    }

    try {
      const result = await changeAcceptance(requestParams)

      if (result.status !== 200) return

      dispatch(changeDocAcceptance({ resData: result.data, editedPosId: row.id }))
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Произошла ошибка при отправке Кол-во упаковок, изменение отменено')
    }
  }

  const save = async (value: string) => {
    const transformedAcceptanceChanges = transformAcceptanceChanges({ quantityAccepted: value })
    const requestParams = {
      ...docAndPositionIds,
      acceptance: { versionNo: 0, ...transformedAcceptanceChanges },
    }

    try {
      const res = await changeAcceptance(requestParams)

      if (res.status !== 200) return

      dispatch(addDocAcceptance({ resData: res.data, editedPosId: row.id }))
    } catch(error) {
      // eslint-disable-next-line no-alert
      alert('Что то пошло не так')
    }
  }

  const removeAcpt = (acpt: { barcode?: string, id: number, versionNo: number }) => {
    const { barcode, id, versionNo } = acpt

    const requesParams = {
      ...docAndPositionIds,
      acceptance: { id, versionNo },
    }

    const remove = async () => {
      try {
        const result = await removeAcceptance(requesParams)

        if (result.status !== 200) return

        dispatch(removeDocAcceptance({ editedPosId: row.id, deletedAcptId: id }))
        
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert('Произошла ошибка при отправке Кол-во упаковок, изменение отменено')
      }
    }

    dialogModel.openDialog(
      {
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удаление информации о приемке"
            description={`Вы собираетесь удалить информацию о приёмке товара ${barcode && `по штрих-коду "${barcode}".`} `}
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
          />
        ),
        onAccept: remove,
      }
    )
  }

  // @ts-ignore
  const TableCellComponent = useCallback(({ onClick, ...rest }:  Table.DataCellProps) => {
    const { column, value, row: rowData } = rest
    const isEditableCell = isEditable && (column.name === 'quantityAccepted' || column.name === 'quantityDefective')

    return (
      <StyledTableCell
        {...rest}
        onClick={isEditableCell ? onClick : noop}
        iseditable={isEditableCell ? 'true' : undefined}
      >
        {getCell(value, column.name, rowData, isEditableCell, removeAcpt)}
      </StyledTableCell>
    )
  }, [isEditable])

  const TableRowComponent = ({ row: rowData, ...rest }: Table.DataRowProps) => {
    if (rowData.new) {
      return <NewAcceptance save={save} />
    }
    return <StyledTableRow {...rest} row={rowData} />
  }

  // eslint-disable-next-line no-nested-ternary
  const rows = row.acceptance?.length
    ? row.acceptance
    : isEditable ? customRow : []

  const columns =
    isEditable
      ? nestedTableColumns
      : nestedTableColumns.filter(column => column.name !== 'actionColumn')

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingColumnsProvider for={editableColumns}/>

        {/* @ts-ignore */}
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={nestedTableColumnExtensions}
        />
        {/* @ts-ignore */}
        <Table
          cellComponent={TableCellComponent}
          rowComponent={TableRowComponent}
          columnExtensions={nestedTableColumnExtensions}
          messages={tableLocale}
        />

        {/* @ts-ignore */}
        <TableHeaderRow cellComponent={HeaderCellComponent}/>

        {/* @ts-ignore */}
        {isEditable && ( <TableInlineCellEditing startEditAction="click" /> )}
      </Grid>
    </Wrapper>
  )
}
