import React from 'react'
import { pimApi } from 'shared/api'
import { ProductStatsWidget } from 'widgets/DashboardWidgets/ProductStatsWidget'
import { generatePath } from 'react-router'

export const SmallActiveProductsWidget = () => {
  const { data } = pimApi.products.common.useGetProductsStatsQuery()
  const url = generatePath('/products?autoOrdering=true')
  return (
    <>
      {data &&
      <ProductStatsWidget title="Активные" data={data.autoOrdering} url={url}/>
      }
    </>
  )
}