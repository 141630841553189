import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

interface CreateNewManagerPayload {
  contragentId: UniqueId,
  managerData: pimApi.admin.NewContragentUser,
}


const createNewManager = ({ contragentId, managerData }: CreateNewManagerPayload) =>
  api.post<pimApi.admin.ContragentUser>(
    `/pim/admin/contragents/${contragentId}/users`,
    managerData
  )

export const useCreateManagerMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.ContragentUser>,
        AxiosError<errorType>,
        CreateNewManagerPayload
        >(createNewManager)