import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import usePending from 'shared/lib/hooks/PendingHook'
import { catalogSelector } from 'store/catalog/selectors'
import { addCatalog } from 'store/catalog'
import { makeStyles } from '@mui/styles'
import { InputSimpleWrapper } from 'pages/goods/components/common'

import { ICatalogSelect } from './ICatatlogSelect'

import { renderSelectMultipleChips } from '../renderSelectMultipleChips'

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: '35vh',
  }
}))

const CatalogSelect = ({
  catalog,
  label,
  value,
  name,
  optionFieldName,
  onChange,
  disabled,
  error,
  onBlur,
  onFocus,
  multiple,
  withReset,
  size = 'small',
  isDownloadOptionsWhenDisabled,
  catalogFilterPredicate
}: ICatalogSelect) => {
  const el = useRef<HTMLSelectElement>(null)
  const [getCatalog] = usePending(addCatalog)
  const classes = useStyles()
  const catalogData = useSelector(catalogSelector(catalog))
  const list = catalogFilterPredicate ? catalogData.filter(catalogFilterPredicate) : catalogData

  useEffect(() => {
    const shouldLoadData = (!disabled || isDownloadOptionsWhenDisabled) && list && !list.length

    if (shouldLoadData) {
      getCatalog({ catalog })
    }
  }, [catalog])

  let selectValue = ''
  const valueItem = list.find((item) => value === item.value)

  if (valueItem) {
    selectValue = value
  }

  return (
    <InputSimpleWrapper>
      <FormControl variant="outlined" size={size} fullWidth={true}>
        {label && <InputLabel htmlFor={`${catalog}Id`}>{label}</InputLabel>}
        <Select
          ref={el}
          disabled={disabled}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.menuPaper },
          }}
          // @ts-ignore
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={selectValue}
          name={name || `${catalog}_id`}
          label={label}
          error={error}
          multiple={multiple}
          renderValue={multiple ?
            renderSelectMultipleChips :
            undefined
          }
        >
          {withReset && !multiple && (
            <MenuItem
              value={undefined}
            >
              Не выбрано
            </MenuItem>
          )}
          {list.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {/* @ts-ignore */}
              {optionFieldName ? item[optionFieldName] : item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputSimpleWrapper>
  )
}

CatalogSelect.defaultProps = {
  disabled: false,
}

export default CatalogSelect
