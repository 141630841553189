import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'
import { AxiosResponse } from 'axios'


export const getCompaniesQueryKey = () =>
  ['get', 'customer/companies'] as const

type GetCompaniesQueryKey = ReturnType<typeof getCompaniesQueryKey>

export function getCompanies(): Promise<AxiosResponse<customerApi.CompanyShortInfo[]>> {
  return api.get<customerApi.CompanyShortInfo[]>('/customer/companies')
}

function getCompaniesFn() {
  return getCompanies().then((res) => res.data)
}

export const useGetCompaniesQuery = () =>
  useQuery<
    customerApi.CompanyShortInfo[],
    errorType,
    customerApi.CompanyShortInfo[],
    GetCompaniesQueryKey
  >(
    getCompaniesQueryKey(),
    getCompaniesFn
  )
