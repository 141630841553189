import styled from 'styled-components'
import { Typography } from '@mui/material'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: 100%;
  }
`
export const Wrapper = styled.div`
  height: 344px;
.MuiFormControl-marginNormal {
  margin-top: 8px;
}
`

export const TypographyStyled = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 22px;
  line-height: 133.4%;
  white-space: break-spaces;
`

export const ExplanTypography = styled(Typography)`
  margin-bottom: 32px;
  margin-top: 55px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  white-space: break-spaces;
`

export const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    left: -15px;
  }
`