/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Distributions, EditCopackingDistribution } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Distributions>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  data: EditCopackingDistribution[]
}>

export const apiFunction = ({ copackingorderId,data }: IRequest) =>
  api.put<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}/distributions`,data).then(res => res.data)


export const useChangecopackingdistribution = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

