import { useState } from 'react'

import { IOpenedStreams } from '../../../ui/components/StreamEvents/context/OpenedStreamsContext'

export const useOpenedEventStream = () => {
  const [openedStreams, setOpenedStreams] = useState<IOpenedStreams>({ goodsSupplyingStream: false })

  const changeStreamState = (stream: IOpenedStreams) => {
    setOpenedStreams(prevState => ({
      ...prevState,
      ...stream
    }))
  }

  return { openedStreams, changeStreamState }
}