/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PriceAdvice, PriceAdviceAction, PriceAdviceIdentifier } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<PriceAdvice[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Возможные действия, совершаемые над рекомендованной ценой */
    action: PriceAdviceAction,

},
  data: PriceAdviceIdentifier[]
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.patch<ResponseType>(`/pim/pricecontrol/priceadvice`,data, { params: query }).then(res => res.data)


export const usePatchPriceAdvice = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

