import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { InfoOutlined } from '@mui/icons-material'
import styled from 'styled-components'

import { HelpWithMargin } from './styled'

const StyledPrivilege = styled.span<{ description: string }>`
  overflow: hidden;
  width: 450px;
  display: block;
  text-overflow: ellipsis;
  margin-left: ${({ description }) => description ? '' : '34px' };
`
export const PrivilegesNameCell = ({ row }) => {
  const { name, description } = row
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {description &&
      <Tooltip title={description} placement="top">
        <HelpWithMargin>
          <InfoOutlined />
        </HelpWithMargin>
      </Tooltip>
      }
      <StyledPrivilege title={name} description={description}>{name}</StyledPrivilege>
    </Box>
  )
}