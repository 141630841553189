import { useEffect, useRef } from 'react'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'

import { isDevEnv } from '../../config'
import { isNotNil } from '../checkers'
import { Notification } from '../generated/notifications/Api'
import { downloadFileWithPath } from '../utils'

const triggerNotification = () => {
  const bellElement = document.getElementById('notification-bell-icon')
  if (bellElement) {
    bellElement.classList.add('shaking')
    setTimeout(() => {
      bellElement.classList.remove('shaking')
    }, 500)
  }
}

const autoDownload = (message: Notification) => {
  if (message.data.media?.download) {
    const url = new URL(message.data.media?.url)
    downloadFileWithPath(url.pathname, message.data.media!.title || '')
  }
}

export const useNotifications = () => {
  const ws = useRef<any>(null)
  const setWebSocket = useNotificationsStore((state) => state.setWebSocket)
  const setMessage = useNotificationsStore((state) => state.setMessage)
  const sendMessage = useNotificationsStore((state) => state.sendMessage)
  const setShowMessage = useNotificationsStore((state) => state.setShowMessage)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') setShowMessage(false)
      if (document.visibilityState === 'visible' && ws.current?.readyState !== WebSocket.OPEN) {
        connect()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  const connect = () => {
    if (document.visibilityState === 'visible') {
      const pingFunction = setInterval(() => sendMessage({ type: 'ping' }), 30000)
      if (ws.current) {
        ws.current.close() // Закрыть предыдущее соединение перед подключением
      }

      // ws.current = new WebSocket('/api/notifications/ws')
      ws.current = new WebSocket(`wss://${window.location.hostname}/api/notifications/ws`)
      // сделано для проги теста puppetry, поскольку он явно не видит wss протокол, локально сокет работать не будет

      ws.current.onopen = () => {
        setWebSocket(ws.current)
      }

      ws.current.onclose = () => {
        clearInterval(pingFunction)
        setTimeout(() => {
          connect()
        }, isDevEnv ? 60000 : 5000)
      }

      ws.current.onerror = () => {
        ws.current.close()
      }

      ws.current.onmessage = (event) => {
        const notification = JSON.parse(event.data)
        if (notification.type !== 'pong' && typeof notification !== 'string') {
          if (ws.current?.readyState === WebSocket.OPEN) {

            const messages = () => useNotificationsStore.getState().messages

            const isNewMessage =
              (notification.length === 1 && notification?.[0].status === 'created') ||
              isNotNil(notification[0]?.data?.task)


            if (isNewMessage) {
              triggerNotification()
              autoDownload(notification[0])

              if (messages().map(el => el.uuid).includes(notification[0].uuid) ||
                messages().map(el => el.id).includes(notification[0].id)) {
                setMessage(messages().map(el => {
                  if (el.uuid === notification[0].uuid || el.id === notification[0].id) return notification[0]
                  return el
                }))
              } else {
                setMessage(notification.concat(messages()))
              }
            } else {
              setMessage(notification)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    connect() // первое подключение при монтировании компонента

    return () => {
      if (ws.current) {
        ws.current.close(1000, 'Closing normally') // закрыть соединение при размонтировании компонента
      }
    }
  }, [setWebSocket, setMessage])

  return ws.current
}