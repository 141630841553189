interface ReviewsStatuses {
  processed?: boolean
  viewed?: boolean
}

interface ReviewTypeTextRecord {
  plugReview: string
  filter: string
}
export const PlugReviewTypeText: Record<number, ReviewTypeTextRecord> = {
  1: { plugReview: 'отзывов', filter: 'отзывы' },
  2: { plugReview: 'вопросов', filter: 'вопросы' },
}

export const ReviewsStatusesText = ({ viewed, processed }: ReviewsStatuses) => {
  if ((viewed !== undefined && processed !== undefined) && (!viewed && !processed)) {
    return 'новых'
  }
  if (viewed && !processed) {
    return 'просмотренных'
  }
  if (viewed && processed) {
    return 'обработанных'
  }
  return ''
}