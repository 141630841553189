import React from 'react'
import styled from 'styled-components'

const TabPanelItem = styled.div`
  width: 100%;
`

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <TabPanelItem
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </TabPanelItem>
  )
}

export default TabPanel
