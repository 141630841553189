import { api } from 'shared/api/base'
import { useMutation, UseMutationOptions, useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { GoodsSupplyingResult as GoodsSupplyingResultModel, GoodsSupplying as GoodsSupplyingModel,
  ImportMetadata as ImportMetadataModel } from 'shared/lib/generated/trade/Api'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'


export interface GetGoodsSupplyingParams {
  searchString?: string
  page: number
  limit?: number
  sort?: string
  order?: string
}

export type GoodsSupplyingResult = CamelCasedPropertiesDeep<GoodsSupplyingResultModel>
export type GoodsSupplying = CamelCasedPropertiesDeep<GoodsSupplyingModel>

type ImportMetadata = CamelCasedPropertiesDeep<ImportMetadataModel>


function getGoodsSupplyling  (
  queryParams: GetGoodsSupplyingParams
): Promise<AxiosResponse<GoodsSupplyingResult>>  {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  return api.get<GoodsSupplyingResult>(
    'trade/stocks/goodssupplying',
    { params: queryParamsSnake }
  )
}

const getQueryKey = (queryParams: GetGoodsSupplyingParams) =>
  ['get', 'trade/stocks/goodssupplying', queryParams]

type QueryKey = ReturnType<typeof getQueryKey>

export const useGoodsSupplyingQuery = (queryParams: GetGoodsSupplyingParams) =>
  useQuery<
    AxiosResponse<GoodsSupplyingResult>,
    AxiosError<errorType>,
    AxiosResponse<GoodsSupplyingResult>,
    QueryKey
    >(getQueryKey(queryParams), (
      context) => getGoodsSupplyling(context.queryKey[2] as GetGoodsSupplyingParams))


export interface ExcelResponse {
  file: File | null
  meta: ImportMetadata
}

const uploadGoodsSupplyingFile = async ( queryParams: { file: File | null }): Promise<any> => {
  const body = new FormData()
  body.append('file', queryParams.file || '')

  return api.post(
    '/trade/stocks/goodssupplying',
    body,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  ).then(res => res)
}

export const useUploadGoodsSupplyingFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    { file: File }
    >
) => useMutation(uploadGoodsSupplyingFile, options)