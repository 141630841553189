import { FieldRenderProps } from 'react-final-form'

import { withFieldEventAdapter } from './FieldAdapter'

import CatalogSelect from '../CatalogSelect/CatalogSelect'
import { ICatalogSelect } from '../CatalogSelect/ICatatlogSelect'

interface CatalogSelectFieldI extends FieldRenderProps<string>, ICatalogSelect {
  label?: string
}

const CatalogSelectField = ({ label, input, ...rest }: CatalogSelectFieldI) => (
  <CatalogSelect {...input} label={label} {...rest} />
)

export default withFieldEventAdapter<CatalogSelectFieldI>(CatalogSelectField)
