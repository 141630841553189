import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import { useState } from 'react'
import { localStore } from 'shared/services/localStore'

import { ColumnConfig } from '../model/types'

interface ITableColumnSettings {
  columnsSettingsId: string
  columnsConfig:  {
    path?: string
    config: Array<ColumnConfig<string>>
    isEditable?: boolean
  }
}
export const useTableColumnSettings = ({ columnsSettingsId, columnsConfig }: ITableColumnSettings) => {
  const localStorageSettings = localStore.get('columnsSettings')

  const parsedColumnsSettings = localStorageSettings ? JSON.parse(localStorageSettings) : undefined

  const getColumnsSettings = () => {
    if (parsedColumnsSettings?.[columnsSettingsId]) {

      columnsConfig.config.forEach((el, index) => {
        if (!parsedColumnsSettings?.[columnsSettingsId].map(elem => elem.columnName).includes(el.columnName)) {
          parsedColumnsSettings?.[columnsSettingsId].splice(index, 0, el)
        }
      })

      return parsedColumnsSettings?.[columnsSettingsId].map(el => ({
        columnName: el.columnName,
        width: el.width === 'auto' ? 150 : (el?.width || 100)
      })) as Array<TableColumnWidthInfo>
    }
    return columnsConfig.config.map(el => ({
      columnName: el.columnName,
      width: el.width === 'auto' ? 150 : (el?.width || 100)
    }))
  }

  const handleColumnsWidthChange = (nextColumnWidths: Array<TableColumnWidthInfo>) => {
    localStore.set('columnsSettings',
      JSON.stringify({ ...parsedColumnsSettings, [columnsSettingsId]: nextColumnWidths })
    )
    setColumnsSettings(nextColumnWidths.map(el => ({
      ...el,
      width: el.width === 'auto' ? 150 : (el?.width || 100)
    })))
  }

  const handleColumnsOrderChange = (nextColumnOrder: Array<string>) => {
    const newOrder = nextColumnOrder.map(el => columnsSettings.find(elem => elem.columnName === el )!)
    localStore.set('columnsSettings',
      JSON.stringify({ ...parsedColumnsSettings, [columnsSettingsId]: newOrder })
    )
    setColumnsSettings(newOrder)
  }

  const [columnsSettings, setColumnsSettings] = useState(getColumnsSettings())

  return { handleColumnsOrderChange, handleColumnsWidthChange, columnsSettings }
}