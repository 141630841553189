import { generatePath, useHistory } from 'react-router-dom'
import { PriceCard } from 'entities/pim/price'
import {
  DialogContentWithTitle,
  dialogModel,
} from 'shared/ui/components/dialog'
import { pimApi } from 'shared/api'
import { PRODUCT_PRICE_HISTORY } from 'shared/config'
import { getProductsPriceQueryKey, SPP } from 'shared/api/pim/price'
import { useQueryClient } from 'react-query'
import queryString from 'query-string'
import { snackActions } from 'shared/lib/react/snackbar'
import debounce from 'lodash/debounce'

interface PriceCardWithMenuProps {
  productId: number
  readOnly?: boolean
  id?: number
  spp?: SPP | null
  versionNo?: number
  name?: string
  onRemove?: () => void
  discount?: number
  priceRecentHistory?: Array<any>
  price?: number
  showOldPrice?: boolean
  oldPrice?: number
  title?: string
}


const getTabPosition = () => (Number(document.getElementById('ScrollToPriceControlWB')?.offsetTop) - 56) 

const debouncedScrollToBottom = debounce(() => window.scrollTo({ top: getTabPosition(), behavior: 'smooth' }), 200)

export const PriceCardWithMenu = ({
  id,
  versionNo,
  discount,
  priceRecentHistory,
  price,
  oldPrice,
  spp,
  productId,
  readOnly,
  name,
  onRemove,
  ...props
}: PriceCardWithMenuProps) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const hasHistory = !!priceRecentHistory?.length

  const { mutate: deleteProductPrice } =
    pimApi.products.wb.useDeleteProductPriceMutation()

  const showPriceHistory = () => {
    const path = `${generatePath(PRODUCT_PRICE_HISTORY, { id: productId })}`
    history.push(path)
  }

  const editPrice = () => {
    const historyStr = queryString.stringify(
      { ...{ tab: 'price' } },
      { skipEmptyString: true, skipNull: true }
    )
    history.replace(`?${historyStr}`)
    debouncedScrollToBottom()
  }

  const deletePrice = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <DialogContentWithTitle
          close={close}
          accept={accept}
          title="Вы уверены, что хотите удалить это запланированное изменение цены?"
          acceptBtnText="ПОДТВЕРДИТЬ"
        />
      ),
      onAccept: () => {
        deleteProductPrice(
          {
            productId,
            priceId: id,
            data: {
              id: id?.toString() || '',
              versionNo,
            },
          },
          {
            onSuccess: () => {
              queryClient.setQueryData<pimApi.price.WBPriceDetail>(
                getProductsPriceQueryKey(productId),
                (priceDetail) => {
                  const newObj = { ...priceDetail }
                  newObj.futurePrices = newObj?.futurePrices?.filter(
                    (el) => el.id !== id
                  )
                  return newObj as pimApi.price.WBPriceDetail
                }
              )
              snackActions.info('Цена успешно удалена!')
            },
          }
        )
      },
    })
  }

  return (
    <PriceCard
      showPriceHistory={!hasHistory ? undefined : showPriceHistory}
      editPrice={(!hasHistory && !!price) || readOnly ? undefined : editPrice}
      deletePrice={hasHistory || !price || readOnly ? undefined : deletePrice}
      priceRecentHistory={priceRecentHistory}
      lastChangeDate={spp?.lastChangeDate}
      price={price}
      oldPrice={oldPrice}
      sppValue={spp?.value}
      readOnly={readOnly}
      discount={discount}
      {...props}
    />
  )
}
