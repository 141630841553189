import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnNameKey = keyof tradeApi.SupplyOrderActualPosition
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  positionNumber: 'positionNumber',
  goodsCode: 'goodsCode',
  goodsName: 'goodsName',
  actualQuantity: 'actualQuantity',
  expectedQuantity: 'expectedQuantity',
  cost: 'cost',
  goodsId: 'goodsId',
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.positionNumber,
      columnName: columns.positionNumber,
      title: '№',
      width: 74,
      sortingEnabled: true,
    },
    {
      name: columns.goodsCode,
      columnName: columns.goodsCode,
      title: 'Артикул товара',
      wordWrapEnabled: true,
      width: 184,
      sortingEnabled: false,
      cellType: CellType.WordWrapCell,
    },
    {
      name: columns.goodsName,
      columnName: columns.goodsName,
      title: 'Наименование товара',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.NameLinkBlank,
    },
    {
      name: columns.expectedQuantity,
      columnName: columns.expectedQuantity,
      title: 'Количество\nв плане',
      width: 200,
      wordWrapEnabled: true,
      sortingEnabled: false,
    },
    {
      name: columns.actualQuantity,
      columnName: columns.actualQuantity,
      title: 'Количество\nв поставке',
      width: 200,
      wordWrapEnabled: true,
      sortingEnabled: false,
      quantityData: columns.expectedQuantity,
      cellType: CellType.QuantityInSupply,
    },
    {
      name: columns.cost,
      columnName: columns.cost,
      title: 'Стоимость',
      width: 200,
      align: 'right',
      sortingEnabled: true,
      cellType: CellType.Sum,
    },
  ],
}
