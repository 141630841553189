import React from 'react'
import { useQueryClient } from 'react-query'
import { dialogModel } from 'shared/ui/components/dialog'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { pimApi } from 'shared/api'
import { MediaImageItemMemo } from 'widgets'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { PictureSampleRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Ozon/PictureSampleRequirements'
import { reactQueryCacheAdd } from 'shared/lib/reactQuery'
import { OzonMediaTypes } from 'shared/types/MediaTypes'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

const PICTURE_SUPPORTED_FILE_EXTENSION = ['.jpeg', '.jpg', '.png']

interface PictureSampleProps {
  productId: number
  picture?: pimApi.products.MediaFile
  disabled: boolean
}

const fileThumbnailSize = { maxWidth: 350, maxHeight: 350 }

export const PictureSample = ({ productId, picture, disabled }: PictureSampleProps ) => {
  const queryClient = useQueryClient()
  const { mutate: createMediaMutation, isLoading: addingInProgress  } =
    pimApi.products.ozon.useCreateProductMediaMutation({
      onSuccess: reactQueryCacheAdd({
        queryClient,
        queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
        path: 'picture'
      })
    })

  const {
    mutate: createMediaByURLMutation,
  } = pimApi.products.ozon.useCreateProductMediaByURLMutation({
    onSuccess: reactQueryCacheAdd({
      queryClient,
      queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
      path: 'picture'
    })
  })

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.products.ozon.useValidateProductMediaFileMutation()
  const { mutateAsync: validateMediaUrlMutation } =
    pimApi.products.ozon.useValidateProductMediaByURLMutation()

  const { mutate: pictureSampleDelete, isLoading: deletingInProgress } =
    pimApi.products.ozon.useDeleteProductMediaMutation()

  const onFileValidate = (mediaType: OzonMediaTypes) =>
    (file, onSuccess) => {
      if (typeof file === 'string') {
        validateMediaUrlMutation({ url: file, productId, mediaType }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      } else {
        const formFile = new FormData()
        formFile.append('file', file)
        validateMediaFileMutation({
          productId,
          mediaType,
          file: formFile,
        }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      }
    }
  const onFileUpload =
    (mediaType: OzonMediaTypes) =>
      (files) => {
        if (files && isNotEmptyArray(files)) {

          files.forEach(el => {
            if (typeof el.file === 'string') {
              createMediaByURLMutation({ url: el.file, productId, mediaType })
            } else {
              const file = new FormData()

              file.append('file', el.file || '')

              createMediaMutation({
                productId,
                mediaType,
                file,
              }, {
                onSuccess: (res) => {
                  queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
                    pimApi.products.ozon.getOzonProductQueryKey(productId),
                    (curr) => {
                      const newObj = { ...curr }
                      newObj.picture = res.data
                      return newObj as pimApi.products.ozon.OzonProduct
                    }
                  )
                },
              })
            }
          })
        }
      }

  const handleDelete = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) =>
        <MediaDeleteConfirmModal accept={accept} close={close} title="Удалить образец цвета?"/>,
      onAccept: () => {
        if (picture !== undefined) {
          pictureSampleDelete({
            productId,
            id: picture?.id,
            mediaType: 'picture',
            versionNo: picture?.versionNo
          }, {
            onSuccess: () => {
              queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
                pimApi.products.ozon.getOzonProductQueryKey(productId),
                (curr) => {
                  const newObj = { ...curr }
                  newObj.picture = undefined
                  return newObj as pimApi.products.ozon.OzonProduct
                }
              )
            },
          })
        }
      }
    })
  }

  const isPictureSampleExists = !!picture
  const imgPath = pimApi.products.ozon.getOzonMediaApiPath(productId, picture?.id, 'picture', fileThumbnailSize)

  const getModalFilePath = (mediaType: OzonMediaTypes) =>
    (photoId, size) => pimApi.products.ozon.getOzonMediaApiPath(productId, photoId, mediaType, size)

  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          mediaVariation="image"
          close={close}
          mediaType="Образец цвета"
          getFilePath={getModalFilePath('picture')}
          clickedItem={item}
          items={[picture]}
        />
      )
    })
  }
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span style={{ fontWeight: '500', color: '#000000DE' }}>Образец цвета</span>
        <span style={{ color: '#0000008A' }}>{ picture ? 1 : 0 }/1</span>
      </div>
      <UploadGallery
        mediaLimit={1}
        localMedia={picture ? [picture] : []}
        mediaType="фото"
        urlUploadingExist={true}
        supportedFileExtension={PICTURE_SUPPORTED_FILE_EXTENSION}
        onUploadFile={onFileUpload('picture')}
        onFileValidate={onFileValidate('picture')}
        mediaRequirement={PictureSampleRequirements}
        addDisabled={disabled || addingInProgress || deletingInProgress || isPictureSampleExists}>
        {picture && (
          [picture].map(el => (
            <MediaImageItemMemo
              imgPath={imgPath}
              openGallery={() => {handleOpenGallery(el)}}
              fileName={el.fileName}
              onFileDelete={handleDelete}
              downloadUrl={picture.downloadUrl}
              disabled={disabled || addingInProgress || deletingInProgress}
            />
          ))
        )
        }
      </UploadGallery>
    </div>
  )
}
