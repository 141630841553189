import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'
import { pimApi } from 'shared/api'

interface WBProductContextProviderProps {
  wbProductQuery: QueryObserverResult<pimApi.products.wb.WBNomenclature>,
}

const WBProductContext = React.createContext<WBProductContextProviderProps | undefined>(undefined)

function WBProductContextProvider({ children, wbProductQuery }: PropsWithChildren<WBProductContextProviderProps>) {
  return (
    <WBProductContext.Provider value={{ wbProductQuery }}>{children}</WBProductContext.Provider>
  )
}

function useWBProductContext() {
  const context = React.useContext(WBProductContext)

  if (context === undefined) {
    throw new Error('useWBContext must be used within a WBProvider')
  }

  return context
}

export { WBProductContextProvider, useWBProductContext }