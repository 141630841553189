import React, { useMemo } from 'react'
import { tradeApi } from 'shared/api'
import { getGoodsEditPath } from 'shared/config'
import { isNotNil } from 'shared/lib/checkers'
import { ProfitabilityContainer } from 'shared/ui/components/ProfitabilityContainer'

import {
  TableBody,
  GoodsItems,
  ItemBox,
  StyledBox,
  StyledTableHeader,
  TableHeaderTitle,
  WidgetWrapper,
  TableBodyCell,
  CustomLinkName, StatsContainer
} from '../styled'

interface StatisticData {
  name: string,
  orders?: number
  stocks?: number,
  daysWithStocks?: number
  ignore?: boolean
  priority?: boolean
  use?: boolean
  profitability?: {
    profit?: number
    profitPercent?: number
  }
}


const NestedWidget = (row: tradeApi.SupplyOrderProductPosition) => {
  // const hist = useHistory()
  // const queryClient = useQueryClient()
  // const ordersId = parseInt(hist.location.pathname.split('/')[3], 10)
  // const { mutate: editSupplyOrder } =
  //   tradeApi.orders.useEditSupplyOrderMutation()
  
  const { goods,
    ordersStatInfo, 
    stocksStatInfo, 
    daysWithStocks,
    profitability
    // id: positionId,
    // versionNo,
    // quantity
  } = row

  const profitByWarehouse = profitability?.byWarehouse
  const statistic = useMemo(() =>  {
    const stat: StatisticData[] = []
    ordersStatInfo?.forEach(order => {
      const i = stat.findIndex(s => s.name===order.name)
      if (i<0) {
        stat.push({
          name: order.name,
          orders: order.value,
          stocks: 0,
          daysWithStocks: 0,
          ignore: order.ignore,
          priority: order.priority,
          use: order.use,
          profitability: {
            profit: profitByWarehouse?.find(el => el.name === order.name)?.profit,
            profitPercent: profitByWarehouse?.find(el => el.name === order.name)?.profitPercent
          }
        })
      }
      else {
        stat[i].orders=order.value
      }
    })
    daysWithStocks?.byWarehouse?.forEach(stock => {
      const i = stat.findIndex(s => s.name===stock.name)
      if (i<0) {
        stat.push({
          name: stock.name,
          orders: 0,
          stocks: 0,
          daysWithStocks: stock.value,
          profitability: {
            profit: profitByWarehouse?.find(el => el.name === stock.name)?.profit,
            profitPercent: profitByWarehouse?.find(el => el.name === stock.name)?.profitPercent
          }
        })
      }
      else {
        stat[i].daysWithStocks=stock.value
      }
    })
    stocksStatInfo?.forEach(stock => {
      const i = stat.findIndex(s => s.name===stock.name)
      if (i<0) {
        if (stock.name === 'В поставке') {
          stat.push({
            name: stock.name,
            stocks: stock.value,
            profitability: {
              profit: profitByWarehouse?.find(el => el.name === stock.name)?.profit,
              profitPercent: profitByWarehouse?.find(el => el.name === stock.name)?.profitPercent
            }
          })
        } else {
          stat.push({
            name: stock.name,
            orders: 0,
            stocks: stock.value,
            daysWithStocks: 0,
            profitability: {
              profit: profitByWarehouse?.find(el => el.name === stock.name)?.profit,
              profitPercent: profitByWarehouse?.find(el => el.name === stock.name)?.profitPercent
            }
          })
        }
      }
      else {
        stat[i].stocks=stock.value
      }
    })
    return stat
  }, [ordersStatInfo, stocksStatInfo, daysWithStocks])

  // const handleCellChange = (cellName, ignore) => {
  //   editSupplyOrder(
  //     {
  //       positionId,
  //       supplyOrderId: ordersId,
  //       editedSupplyOrder: {
  //         id: positionId,
  //         versionNo,
  //         quantity,
  //         ordersStatInfo: [{ name: cellName, ignore }] }
  //     },
  //     {
  //       onSuccess: () => {
  //         queryClient.refetchQueries({ queryKey: tradeApi.orders.getSupplyOrderQueryKey(ordersId) })
  //       }
  //     }
  //   )
  // }

  const TableHeader = () => (
    <StyledTableHeader>
      <TableHeaderTitle width={160}>Артикул товара</TableHeaderTitle>
      <TableHeaderTitle width={160}>Количество</TableHeaderTitle>
      <TableHeaderTitle width={448}>Товары в продукте</TableHeaderTitle>
      <TableHeaderTitle width={269}>РЦ</TableHeaderTitle>
      <TableHeaderTitle width={116}>Доходность</TableHeaderTitle>
      <TableHeaderTitle width={92}>Дней с остатками</TableHeaderTitle>
      <TableHeaderTitle width={72}>В день</TableHeaderTitle>
      <TableHeaderTitle width={94}>Остатки</TableHeaderTitle>
    </StyledTableHeader>
  )

  return (
    <WidgetWrapper >
      <TableHeader/>
      <TableBody>
        <Goods goodItems={goods} />
        <Stats statistic={statistic}/>
      </TableBody>
    </WidgetWrapper>
  )
}
export default NestedWidget

const Stats = ({ statistic }) => (
  <StatsContainer>
    {
      statistic.map((item) => (
        <ItemBox use={item.use}>
          <StyledBox
            width={269}
            priority={item.priority}
            use={item.use}
            ignore={item.ignore}
          >
            {item.name}
          </StyledBox>
          <StyledBox
            style={{ display: 'flex', flexDirection: 'row' }}
            width={116}
            priority={item.priority}
            use={item.use}
            ignore={item.ignore}
          >
            <ProfitabilityContainer profit={item.profitability.profit} profitPercent={item.profitability.profitPercent}/>
          </StyledBox>
          <StyledBox
            width={92}
            priority={item.priority}
            use={item.use}
            ignore={item.ignore}
          >
            {item.daysWithStocks}
          </StyledBox>
          <StyledBox
            width={72}
            priority={item.priority}
            use={item.use}
            ignore={item.ignore}
            lineThrough={item.ignore}
          >
            {item.orders}
          </StyledBox>
          <StyledBox
            width={94}
            priority={item.priority}
            use={item.use}
            ignore={item.ignore}
          >
            {item.stocks}
            {/* {(item.use && !item.ignore) && */}
            {/*   <RemoveIcon onClick={() => {handleCellChange(item.name, true)}}/> */}
            {/* } */}
            {/* {(item.use && item.ignore) && */}
            {/*   <AddIcon onClick={() => {handleCellChange(item.name, false)}}/> */}
            {/* } */}
          </StyledBox>
        </ItemBox>
      ))
    }
  </StatsContainer>
)

const GoodLink = ({ goodsId, name }) => {
  const path = getGoodsEditPath(goodsId)

  return (
    <CustomLinkName target="_blank" to={path}>{name}</CustomLinkName>
  )
}

const Goods = ({ goodItems }) => {
  if (isNotNil(goodItems)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { goodItems.map(item =>
          <GoodsItems key={ item.goodsId }>
            <TableBodyCell style={{ wordBreak: 'break-all' }} width={ 160 }>{ item.goodsCode }</TableBodyCell>
            <TableBodyCell width={ 160 }>{ item.quantityInBundle }</TableBodyCell>
            <TableBodyCell width={ 448 } style={{ paddingRight: '24px' }}>
              <GoodLink goodsId={ item.goodsId } name={ item.goodsName } />
            </TableBodyCell>
          </GoodsItems>
        ) }
      </div>
    )
  }
  return <></>
}