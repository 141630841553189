import { memo } from 'react'
import { Table as DXTable } from '@devexpress/dx-react-grid-material-ui'
import { TemplateConnector } from '@devexpress/dx-react-core'
import styled from 'styled-components'
import { Theme } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import { getColorForRow } from 'pages/docs/DocDetail/Table/helpers'

const ColoredRowCollapsed = styled(DXTable.Row)<{
    theme: Theme, row: CalculatedReceiptDocPosition, isrowexpanded?: boolean
}>`
  ${({ theme, row, isrowexpanded }) => {
    const { bgColor = 'rgba(255, 255, 255, 0.01)' } = getColorForRow(theme, row, isrowexpanded)
    return `
      background-color: ${bgColor};
      &:hover {
         background-color:  ${bgColor} !important;
      }
    `
  }}
`

const RowComponent = memo(({
  row,
  expandedDetailRowIds,
  ...restProps
}: DXTable.DataRowProps & { expandedDetailRowIds: Array<number> }) => {
  const isRowExpanded = expandedDetailRowIds ? expandedDetailRowIds.includes(row.id) : undefined
  return (
    <ColoredRowCollapsed
      {...restProps}
      row={row}
      isrowexpanded={isRowExpanded ? 'true' : undefined}
    />
  )
})

const TableRow = (props: DXTable.DataRowProps) => (
  <TemplateConnector>
    {({ expandedDetailRowIds }) => (
      <RowComponent expandedDetailRowIds={expandedDetailRowIds} {...props}  />
    )}
  </TemplateConnector>
)

export const TableExpanded = memo(TableRow)