import React, { PropsWithChildren, useCallback, useState } from 'react'
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  SelectProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { InputSimpleWrapper } from 'pages/goods/components/common'
import { StyledDot } from 'widgets/product/Common/abcClass/ui/styled'

import { StyledSelect, StyledMenuItem } from './styled'

import { Option } from '../interface'

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: '35vh',
  },
}))

interface ISelectProps extends Omit<SelectProps, 'input'> {
  label?: string
  value?: string
  name: string
  options: Array<Option<string | number>>
  IconComponent?: any
}

export const Select = ({
  label,
  value,
  name,
  children,
  options,
  IconComponent,
  ...selectMuiProps
}: PropsWithChildren<ISelectProps>) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const id = `${name}-select`

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const IconRender = useCallback(() => <IconComponent 
    onClick={handleClick}/>
  , [IconComponent])
  const optionalIconComponent = IconComponent ? { IconComponent: IconRender } : {}

  return (
    <InputSimpleWrapper>
      <FormControl variant="outlined" size="small" fullWidth={true}>
        {label && <InputLabel focused={open} shrink={open || !!value} htmlFor={id}>{label}</InputLabel>}
        <StyledSelect
          id={id}
          MenuProps={{
            // @ts-ignore
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.menuPaper },
          }}
          
          value={value}
          name={name || `${name}_id`}
          label={label}
          open={open}
          onOpen={handleClick}
          onClose={handleClick}

          {...optionalIconComponent}
          {...selectMuiProps}
        >
          {options.map((option) => {
            if (option.icon) {
              return (
                <StyledMenuItem value={option.value} key={option.label}>
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                </StyledMenuItem>
              )
            }
            if (option.color) {
              return (
                <StyledMenuItem
                  key={option.label}
                  value={option.value}
                  style={{ paddingLeft: '6px', paddingRight: '6px', fontSize: '14px' }}
                >
                  <StyledDot style={{ marginTop: '0', marginRight: '5px' }} color={option.color}/>{option.label}
                </StyledMenuItem>
              )
            }
            return (
              <StyledMenuItem
                key={option.label}
                value={option.value}
              >{option.label}</StyledMenuItem>
            )
          })}
          {children}
        </StyledSelect>
      </FormControl>
    </InputSimpleWrapper>
  )
}
