import { Paper } from '@mui/material'
import { ReactElement } from 'react'

// const PageTitleStyled = styled(PageTitle)`
//   button {
//     color: rgba(0, 0, 0, 0.87);
//     font-size: 24px;
//     border: none;
//     background: transparent;
//     cursor: pointer;
//     display: inline-flex;
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//     justify-content: center;
//     align-items: center;
//     &:hover {
//       text-decoration: none;
//     }
//   }
//   span {
//     svg {
//       font-size: 20px;
//     }
//   }
//   h2 {
//       margin: 24px 0;
//       padding: 0;
//   }
// `
const PAPER_SX = () => (
  { p: '24px', mt: '28px', maxWidth: 'inherit' }
)

interface EditCardProps {
  title?: string
  children: ReactElement | Array<ReactElement>
  backUrl?: string
}

export const EditCard = ({ children }: EditCardProps) => (
  <>
    <Paper {...({ sx: PAPER_SX() })}>{children}</Paper>
  </>
)

