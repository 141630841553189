import React, { memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Tabs, Typography } from '@mui/material'
import { pimApi } from 'shared/api'
import { Image, TabPanel } from 'shared/ui/components'
import { getProductEditPath } from 'shared/config'
import { useFileUrl } from 'pages/goods/model/hooks'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { ProductSettings } from 'widgets/product/OZON/SettingsMenu'

import { MemoProductCardForm } from './ProductCardForm'

import { NomenclatureTabsNav, StyledTab } from '../../../styled'

interface ProductCardProps {
  commonProductData?: pimApi.products.common.Product
  goodsData: pimApi.goods.Goods
}

export const ProductCard = ({ commonProductData, goodsData }: ProductCardProps) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const productSearchList = pimApi.products.common.useFetchCommonProductSearchQuery({
    goodsId: goodsData.id,
    companymarketplaceId: commonProductData?.companymarketplaceId as UniqueId,
  })
  const handleChangeTab = (_: React.ChangeEvent<{}>, tabsProductId: number) => {
    if (tabsProductId !== productId) {
      history.push(getProductEditPath(tabsProductId as number))
    }
  }

  const media = pimApi.products.getFileThumbnailApiPath(productId, { maxWidth: 50, maxHeight: 50 })
  const { data } = useFileUrl(media)

  const selectedVar = productSearchList?.data?.find(
    (variation) => variation?.productId === productId
  )

  return (
    <>
      <NomenclatureTabsNav $isArchived={selectedVar?.inArchive} $isMedia={!data}>
        <Tabs value={productId} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
          {productSearchList?.data?.map((tab) => {
            const apiUrl = pimApi.products.getFileThumbnailApiPath(tab.productId!, { maxWidth: 50, maxHeight: 50 })
            return (
              <StyledTab
                key={tab.productId}
                value={tab.productId}
                icon={
                  <Box display="flex" alignItems="center" justifyContent="flex-end" flex="auto">
                    <Image style={{
                      filter: tab.inArchive ? 'grayscale(1)' : '',
                      opacity: tab.inArchive ? '0.6' : '1'
                    }} src={apiUrl}/>
                    <Typography sx={{
                      fontSize: '15px',
                      fontWeight: '500',
                      lineHeight: '26px',
                      color: tab.inArchive ? 'rgba(0, 0, 0, 0.38)' : '#007DFF'
                    }}>{tab.productNumber}</Typography>
                    <ProductSettings/>
                  </Box>}
                iconPosition="end"
                {...a11yProps(tab.productId)}
              />
            )
          })}
        </Tabs>
      </NomenclatureTabsNav>

      {productSearchList?.data?.map((product) => (
        <TabPanel key={product.productId} value={productId} index={product.productId}>
          <MemoProductCardForm />
        </TabPanel>
      ))}
    </>
  )
}

export const MemoProductCard = memo(ProductCard)
