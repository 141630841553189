import { Delete } from '@mui/icons-material'
import styled from 'styled-components'


export const DeleteIcon = styled(Delete)`
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  margin-top: 2px;
`

export const StyledButton = styled.button`
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
`

export const BoldText = styled.span`
  font-weight: 600;
`
