import { Route } from 'react-router-dom'

import { Header } from './ui/Header'
import { AdminPanelContent } from './Content'
import { adminPanelElements } from './model/adminPanelElements'

export const AdminPanel = () => (
  <>
    <Header />
    <AdminPanelContent/>
  </>
)

export const AdminPanelRoot = () => (
  adminPanelElements.map(({ Component, ...el }) => (
    <Route
      key={ `${ el.value }rout` }
      path={ el.value }
      exact={ false } 
      component={Component}
    />
  ))
)
