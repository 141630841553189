/* eslint-disable */
// @ts-nocheck

import React, { useState, ReactNode, useMemo } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { TextInputI } from '../TextInputI'

const useRenderEndAdorment = (props: TextInputI<{}>) => {
  const { type, endAdornment, isPassword, startAdornment, readOnly, InputProps, InputLabelProps } = props
  const [fieldType, setFieldType] = useState(type)
  const [showPassword, setShowPassword] = useState(false)
  const renderEndAdornment = useMemo((): ReactNode => {
    if (endAdornment) return endAdornment
    if (isPassword) {
      const handleMouseDownPassword = (event) => {
        event.preventDefault()
      }
      const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
        setFieldType(showPassword ? 'password' : 'string')
      }
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            size="large">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    }
  }, [isPassword, showPassword, endAdornment])
  const renderStartAdornment = useMemo((): ReactNode => {
    if (startAdornment) return startAdornment
  }, [isPassword, showPassword])
  return {
    type: fieldType,
    InputProps: {
      autoComplete: 'off',
      ...InputProps,
      endAdornment: renderEndAdornment,
      startAdornment: renderStartAdornment,
      readOnly,
    },
    InputLabelProps
  }
}

export default useRenderEndAdorment
