import React, { useMemo } from 'react'
import styled from 'styled-components'
import { pimApi } from 'shared/api'
import { generatePath } from 'react-router'
import { round } from 'shared/lib/utils/round'

const Title = styled.span`
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  table {
    border-collapse: collapse;
  }
  tr {
    td {
      border-top: 1px solid #E0E0E0;
      border-collapse: collapse;

      a {
        font-size: 14px;
        color: #2196F3;
        font-weight: 400;
        text-decoration: none;
      }
      :first-child {
        border-right: 1px solid #E0E0E0;
      }
    }
  }
  
  th {
    :first-child {
      border-right: 1px solid #E0E0E0;
    }
  }
  
  thead {
    th {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      padding: 0 0 0 16px;
    }
  }
  
  tbody {
    tr {
      td {
        padding: 0 0 0 16px;
        :first-child {
          width: 93px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  
  tr {
    height: 46px;
  }
`
export const MediumAbcXyzWidget = () => {
  const { data } = pimApi.products.common.useGetProductsStatsQuery()

  const pivotData = (classData) => {
    const classes = [...new Set(classData.map((item) => item.abcClass))]
    const xyzClasses = [...new Set(classData.map((item) => item.xyzClass))]

    const pivot = xyzClasses.sort().map((xyzClass) => {
      const row = { xyzClass }
      classes.sort().forEach((abcClass: any) => {
        const found = classData.find(
          (item) => item.abcClass === abcClass && item.xyzClass === xyzClass
        )
        row[abcClass] = found ? found.count : 0
      })
      return row
    })

    return { classes, pivot }
  }

  const table = useMemo(() => {
    if (data) {
      return pivotData(data.classifications)
    }
    return null
  },[data])

  const classifiedProducts = data?.classifications.reduce((sum, elem) => sum + elem.count, 0)
  const nonClassifiedProducts = (classifiedProducts && data) ? data?.autoOrdering - classifiedProducts : 0

  return (
    <>
      <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' } }>
        <Title>ABC/XYZ-анализ</Title>
        <span
          style={ {
            color: '#00000061',
            fontSize: '14px',
          } }>
          неклассифицированы { round(nonClassifiedProducts, 0) }
        </span>
      </div>
      { table &&
        <StyledContainer>
          <table>
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                { table.classes.map((abcClass: any) => (
                  <th key={ abcClass }>{ abcClass }</th>
                )) }
              </tr>
            </thead>
            <tbody>
              { table.pivot.map((row: any) => (
                <tr key={ row.xyzClass }>
                  <td>{ row.xyzClass }</td>
                  { table.classes.map((abcClass: any) => (
                    <td key={ abcClass }>
                      <a
                        id="value-text"
                        href={generatePath(`/products?abcClass[]=${abcClass}&xyzClass[]=${row.xyzClass}&autoOrdering=true`)}
                        target="_blank"
                        rel="noreferrer">
                        { row[abcClass] }
                      </a>
                    </td>
                  )) }
                </tr>
              )) }
            </tbody>
          </table>
        </StyledContainer>
      }
    </>
  )
}