import { getImage, getImageInfo } from 'shared/services/imageService'
import { useQuery } from 'react-query'

const blobToSrcObjectURL = (buffer: File) => URL.createObjectURL(buffer)
const getProductImage = (url: string) =>
  getImage(url)
    .then(({ data : value }) => value)
    .then(blobToSrcObjectURL)


export const useFileUrl = (url: string) => useQuery(
  [url],
  (context) => getProductImage(context.queryKey[0]),
  { staleTime: Infinity, retry: 0, refetchOnMount: false, retryOnMount: false }
)

export const useFileInfoUrl = (url: string) => useQuery(
  [url, 'imageInfo'],
  context => getImageInfo(context.queryKey[0]),
  { staleTime: Infinity, retry: 0, refetchOnMount: false, retryOnMount: false }
)