import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

interface ICompaniesHeader {
  handleAddCompany: () => void
  isEmptyCompanies?: boolean
}

export const Header = ({ handleAddCompany, isEmptyCompanies }: ICompaniesHeader) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    my={3}
  >
    <Typography component="h1" variant="h3" fontSize="1.5rem">
      Компании
    </Typography>
    <Box>
      {
        !isEmptyCompanies &&
        <Button
          variant="contained"
          startIcon={<AddIcon/>}
          color="primary"
          onClick={handleAddCompany}
        >
          ДОБАВИТЬ
        </Button>
      }
    </Box>
  </Box>
)