import { MarketplaceFormWB } from '../../bulkProductCreation/ui/MarketplaceFormWB'
import { MarketplaceFormOzon } from '../../bulkProductCreation/ui/MarketplaceFormOzon'

export enum MarketplaceId {
  WB = 1,
  OZON = 2,
}

export const MarketplaceIdToMarketplaceForm = {
  [MarketplaceId.WB]: MarketplaceFormWB,
  [MarketplaceId.OZON]: MarketplaceFormOzon,
} as const
