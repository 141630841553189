import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'
import { pimApi } from 'shared/api'

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),
})

export const categoryAttributeSchema = yup.object().shape({
  attribute: yup
    .object()
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),
})

export const attributesValuesSchema = (attributesValues: pimApi.dictionaries.Value[]) => yup.object().shape({
  name: yup
    .string()
    .required('Обязательное поле')
    .notOneOf(attributesValues.map(el => el.name), 'Такое значение уже существует в атрибуте'),
})