import styled from 'styled-components'
import { Box } from '@mui/material'
import { TabPanel } from 'shared/ui/components'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
`

export const StyledTabPanel = styled(TabPanel)`
  padding: 0 24px;
  .MuiPaper-root {
    overflow: visible;
  }
  .MuiTabs-root {
    .MuiTab-root {
      text-transform: none;
      font-weight: 400;
      font-size: 16px;
    }
    
    .Mui-selected {
      color: #2196F3;
    }
    .MuiTabs-scroller .MuiTabs-flexContainer a{
      &:last-child {
        margin-left: auto;
      }
    }
  } 
`