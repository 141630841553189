import * as yup from 'yup'

export const validationSchema = yup.object({
  category: yup.object().when('cardType', {
    is: 'new',
    then: yup.object().required('oshibka')
  }),
  nomenclatureCode: yup.string().when('cardType', {
    is: 'old',
    then: yup.string().when('wbnomenclatureId', {
      is: undefined,
      then: yup.string().test('wbnomenclatureId', 'Не выбрана номенклатура', () => false)
    })
  })
})