import { StyledAccessHeader } from '../styled'

interface UserAccordionProps {
  title?: string
  subtitle?: string
}

export const AccessHeader = ({ title, subtitle }: UserAccordionProps) => (
  <StyledAccessHeader>
    <span>{title}</span>
    <span>{subtitle}</span>
  </StyledAccessHeader>
)
