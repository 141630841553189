import { AxiosError } from 'axios'
import { snackActions } from 'shared/lib/react/snackbar'

const DEFAULT_ERROR_MESSAGE = 'Что-то пошло не так'

export const withServerMessageNotifyResponse = async (error: AxiosError) => {
  const isRefreshRequest = error.config?.url?.includes('refresh')
  const isForbidden = error.response?.status === 403
  const isCanceled = error.message === 'canceled'
  const requiredSulogin = error.response?.data?.message?.includes('вы вообще-то админ')


  if (!isRefreshRequest && !isCanceled && !requiredSulogin) {
    let message = 'Проблема связи с Интернет'
    if (error.response?.status) {
      message = `${error.response?.status}: ${DEFAULT_ERROR_MESSAGE}`
    }

    // Иногда данные трансформированы в Blob, но тип имеют 'application/json'
    if (error.response?.data instanceof Blob && error.response?.data.type === 'application/json') {
      const text = JSON.parse(await error.response?.data.text())

      if (text.message) {
        message = text.message
      }
    }

    if (error.response?.data.message) {
      message = error.response?.data.message
    }

    if (isForbidden && error.response?.data.detail) {
      message = error.response?.data.detail
    }

    const dontShowMessageNotify = error.config?.dontShowMessageNotify

    if (!dontShowMessageNotify) {
      snackActions.error(message, { preventDuplicate: true })
    }

  }

  return Promise.reject(error)
}
