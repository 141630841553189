import { isNotNil } from './isNil'

export function isNotEmptyArray<T = Array<any>>(
  array: Readonly<undefined | null | T>
): boolean {
  return !isEmptyArray(array) && isNotNil(array) && Array.isArray(array)
}

export function isEmptyArray<T = Array<any>>(
  array: Readonly<undefined | null | T>
): boolean {
  return isEmpty(array)
}

export function isEmpty<T extends { length?: number } = { length?: number}>(
  array: Readonly<undefined | null | T>
): boolean {
  return (array?.length ?? 0) === 0
}