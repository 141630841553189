import React from 'react'
import { Button, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import * as UI from './styled'

interface StatusChangeI {
  setStatusStart: (event: React.MouseEvent<HTMLButtonElement>) => void,
  defaultStorageId: number | null,
}

const StatusChange = ({ setStatusStart, defaultStorageId }: StatusChangeI) => (
  <UI.ToolbarButtonStart container={true} item={true} spacing={2}>
    <Grid item={true}>
      <Button
        variant="contained"
        color="primary"
        onClick={setStatusStart}
        disabled={!defaultStorageId}
        size="large"
      >
        НАЧАТЬ ПРИЕМКУ
      </Button>
    </Grid>
    {!defaultStorageId && (
      <UI.MessageStorageIdWrapper item={true}>
        <UI.Info>
          <FontAwesomeIcon icon={faInfo} />
          Выберите место хранения
        </UI.Info>
      </UI.MessageStorageIdWrapper>
    )}
  </UI.ToolbarButtonStart>
)

export default StatusChange
