import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

interface DeleteOrderForm {
  contragentId: UniqueId,
}
const deleteOrderForm = ({ contragentId }: DeleteOrderForm) =>
  api.delete(
    `/pim/admin/contragents/${contragentId}/orderform`
  )


export const useDeleteOrderFormMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.CurrentContragent>,
        AxiosError<errorType>,
        DeleteOrderForm
        >(deleteOrderForm)