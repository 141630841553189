import {
  GoodsSupplier as GoodsSupplierSnake,
  NewGoodsSupplier as NewGoodsSupplierSnake,
  EditGoodsSupplier as EditGoodsSupplierSnake
} from 'shared/lib/generated/pim/Api'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

export type GoodsSupplier = CamelCasedPropertiesDeep<GoodsSupplierSnake>
export type NewGoodsSupplier = CamelCasedPropertiesDeep<NewGoodsSupplierSnake>
export type EditGoodsSupplier = CamelCasedPropertiesDeep<EditGoodsSupplierSnake>

export function createGoodsSupplier(
  goodsId: UniqueId,
  supplier: NewGoodsSupplier
): Promise<AxiosResponse<GoodsSupplier>> {
  return api.post<GoodsSupplier>(`/pim/goods/${goodsId}/suppliers`, supplier)
}

export function updateGoodsSupplier(
  goodsId: UniqueId,
  supplier: EditGoodsSupplier
): Promise<AxiosResponse<GoodsSupplier>> {
  return api.put<GoodsSupplier>(
    `/pim/goods/${goodsId}/suppliers/${supplier.id}`,
    supplier
  )
}

export function deleteGoodsSupplier(
  goodsId: UniqueId,
  supplier: DeletingObject
): Promise<AxiosResponse<GoodsSupplier>> {
  return api.delete<GoodsSupplier>(
    `/pim/goods/${goodsId}/suppliers/${supplier.id}`,
    { data: supplier }
  )
}
