import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { urlCheck } from 'shared/lib/checkers/urlCheck'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import _uniqueId from 'lodash/uniqueId'

import { AccordionContainer, MediaItemsContainer, UrlInputContainer } from './styled'
import { DragDropFile } from './DragAndDrop'
import { ValidateMediaItem } from './Validate/ValidateMediaItem'

import { IUploadMediaModal } from '../lib/types'

export const UploadMediaModal = ({
  close,
  onUploadFile,
  onFileValidate,
  mediaType,
  mediaLimit,
  existingMediaCount,
  supportedFileExtension,
  mediaRequirement,
  urlUploadingExist
}: IUploadMediaModal) => {
  const [urlLink, setUrlLink] = useState<string | null>('')

  const { Element, Text } = mediaRequirement()

  const { allFiles, setAllFiles: changeFiles , DragAndDropPaper } = DragDropFile({ requirement: Text, supportedFileExtension })
  
  const handleDeleteMediaItem = useCallback((item) => {
    changeFiles(prev => prev.filter(el => el.id !== item.id))
  },[])

  const uploadUrlFile = () => {
    changeFiles(prev => {
      if (isNotEmptyArray(prev)) {
        return prev?.concat([urlLink].map(el => ({
          id: _uniqueId('prefix-'),
          file: el
        })))
      }
      return [urlLink].map(el => ({
        id: _uniqueId('prefix-'),
        file: el
      }))
    })
    setUrlLink('')
  }

  const onPaste = (event) => {
    if (event.target.tagName !== 'INPUT') {
      const { clipboardData } = event
      const { items } = clipboardData

      Object.keys(items).forEach(key => {
        const file = items[key].getAsFile()
        processImage(file)
      })
    }
  }

  const processImage = (file) => {
    if (file) {
      changeFiles(prevState => {
        if (isNotEmptyArray(prevState)) {
          return prevState?.concat([file].map(el => ({
            id: _uniqueId('prefix-'),
            file: el
          })))
        }
        return [file].map(el => ({
          id: _uniqueId('prefix-'),
          file: el
        }))
      })
    }
  }

  useEffect(() => {
    document.addEventListener('paste', onPaste)

    return () => {
      document.removeEventListener('paste', onPaste)
    }
  }, [])

  return (
    <div style={{ width: '768px' }}>
      <DialogTitleWithBtn onClose={close} sx={{ fontSize: '20px' }}>
        {`Добавить ${mediaType}`}
      </DialogTitleWithBtn>
      <div style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <AccordionContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ height: '40px', minHeight: '40px' }}
            >
              <span style={{ color: '#00000099', fontSize: '16px' }}>{`Требования к ${mediaType}`}</span>
            </AccordionSummary>
            <AccordionDetails>
              { Element }
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
        {allFiles && isNotEmptyArray(allFiles) &&
        <MediaItemsContainer>
          {allFiles?.map((el, index) => (
            <ValidateMediaItem
              file={ el.file }
              mediaLimit={mediaLimit}
              existingMediaCount={existingMediaCount}
              index={index}
              deleteFile={ handleDeleteMediaItem }
              onFileValidate={ onFileValidate }
              itemIndex={ el.id }
              key={ el.id }/>
          )
          )}
        </MediaItemsContainer>
        }
        <DragAndDropPaper/>
        { urlUploadingExist && (
          <UrlInputContainer>
            <TextField
              fullWidth={true}
              value={urlLink}
              onChange={(e) => {
                setUrlLink(e.target.value)
              }}
              placeholder={`Ссылка на ${mediaType}`}
              size="small"/>

            { urlLink && urlCheck(urlLink) && (
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  // onUploadUrl(urlLink)
                  // close()
                  uploadUrlFile()
                }}>
                Загрузить
              </Button>
            ) }
          </UrlInputContainer>
        ) }
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: '8px' }}>
          <Button
            variant="text"
            onClick={close}
          >
            ОТМЕНА
          </Button>
          <Button
            sx={{ width: '109px' }}
            disabled={!(allFiles && isNotEmptyArray(allFiles))}
            variant="contained"
            onClick={() => {
              if (onUploadFile) {
                onUploadFile(allFiles)
              }
              close()
            }}
          >
            Добавить
          </Button>
        </div>
      </div>
    </div>
  )
}