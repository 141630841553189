import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { Preloader, Image } from 'shared/ui/components'
import { wmsApi } from 'shared/api'
import { isNil } from 'shared/lib/checkers'

import * as UI from './styled'

import { useGoods } from '../../../model/hooks'

interface GoodsPreviewProps {
  goodsId: UniqueId;
  onClose: () => void;
}

const fileThumbnailSize = { maxWidth: 300, maxHeight: 400 }

export const GoodsPreview = ({ goodsId, onClose }: GoodsPreviewProps) => {
  const { goods, openGoods } = useGoods()
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  useEffect(() => {
    openGoods(goodsId)
  }, [goodsId])

  const handleNext = () => {
    setActiveImageIndex((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveImageIndex((prevActiveStep) => prevActiveStep - 1)
  }

  if (isNil(goods)) {
    return (
      <UI.Content>
        <Preloader/>
      </UI.Content>
    )
  }

  return (
    <UI.Content>
      <UI.GoodClose onClick={onClose}>
        <FontAwesomeIcon icon={faWindowClose} />
      </UI.GoodClose>
      {
        goods.images && goods.images.length > 0 && (
          <>
            <UI.ImageWrapper>
              <Image
                src={wmsApi.getFileThumbnailApiPath(goods.id, goods.images[activeImageIndex].id, fileThumbnailSize)}
                loadingIconSize={60}
              />
            </UI.ImageWrapper>
            {
              goods.images.length > 1 && (
                <>
                  <UI.ButtonPrev size="small" onClick={handleBack} disabled={activeImageIndex === 0}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </UI.ButtonPrev>
                  <UI.ButtonNext size="small" onClick={handleNext} disabled={activeImageIndex === goods.images.length - 1}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </UI.ButtonNext>
                </>
              )
            }
          </>
        )
      }
      <UI.PreviewTitle>
        {goods.name}
      </UI.PreviewTitle>
      <UI.PreviewSubTitle>
        Информация о товаре
      </UI.PreviewSubTitle>
      <UI.PreviewCodeItem>
        <span>Бренд</span>
        {goods.brand}
      </UI.PreviewCodeItem>
    </UI.Content>
  )
}