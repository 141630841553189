import React from 'react'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import { StyledTableHeader } from './styled'

import { getCellWidth } from '../lib/getCellWidth'

interface ITableCell {
  name: string
  description?: string
  width: string
  padding?: string
}

interface ITableHeader {
  headerStyle?: React.CSSProperties
  tableCells: ITableCell[]
}

export const TableHeader = ({ headerStyle, tableCells }: ITableHeader) => (
  <StyledTableHeader style={ headerStyle }>
    <tr>
      { tableCells.map((el, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <th key={index} style={ { padding: el.padding, paddingTop: '0', paddingBottom: '0', ...getCellWidth(el.width) } }>
          { el.name }
          { el.description &&
            <Tooltip title={ el.description } placement="top">
              <HelpWithMargin>
                <InfoOutlined />
              </HelpWithMargin>
            </Tooltip>
          }
        </th>
      )) }
    </tr>
  </StyledTableHeader>
)