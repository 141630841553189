import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Theme } from '@mui/system'

export const ProductTitle = styled.div`
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 32px;
  font-weight: 400;
`
export const ProductVatCode = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  // margin-bottom: 24px;
`
export const ProductHeaderImg = styled.div<{ $isArchived?: boolean }>`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img{
    max-width: 100%;
    max-height: 100%;
    filter: ${({ $isArchived }) => $isArchived ? 'grayscale(100%)' : 'grayscale(0%)'};
    opacity: ${({ $isArchived }) => $isArchived ? '0.5' : '1'};  }
`
export const ProductHeaderImgPlaceholder = styled.img`
  width: 100%;
  height: 100%;
`
export const GoodsIcon = styled.span`
  width: 15px;
  height: 16px;
  margin-right: 10px;
  svg{
    min-width: 15px;
    min-height: 16px;
    color: #8D8D8D;
  }
`

export const LinkToGoods = styled(Link)<{theme: Theme}>`
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main}
`