import { FieldRenderProps } from 'react-final-form'
import { TextField, InputAdornment, Box } from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import React from 'react'
import { BaseInputFieldI } from 'shared/ui/components/TextInput/TextInputI'
import styled from 'styled-components'
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff'

const KeyInputFieldStyle = styled(Box)<{ $inActive?: boolean}>`
  position: relative;
  .MuiInputAdornment-positionStart {
    svg {
      color: ${({ $inActive }) => $inActive ? '#2196F3' : 'rgba(0,0,0,0.12)' };
    }
  }
`

interface CatalogSelectFieldI<T> extends FieldRenderProps<T>, BaseInputFieldI {
  label?: string,
}

const KeyInputField = <T extends {}>({ input, fieldData }: CatalogSelectFieldI<T>) => {
  const handleChange = (event) => {
    input.onChange(event)
  }
  const { secretFlag, valueSet, value, credentialName } = fieldData

  const isShowKeyIcon = () => secretFlag
    ? {
      startAdornment: (
        <InputAdornment position="start">
          {
            valueSet
              ? <VpnKeyIcon />
              : <VpnKeyOffIcon />
          }
        </InputAdornment>
      ),
    }
    : {}

  // @ts-ignore
  return (
    <KeyInputFieldStyle $inActive={valueSet}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth={true}
        value={input.value}
        onChange={handleChange}
        disabled={secretFlag && valueSet && value === null}
        type={secretFlag ? 'password' : 'text' }
        InputProps={isShowKeyIcon()}
        placeholder={secretFlag && valueSet ? '\u2022'.repeat(10) : credentialName}
      />
    </KeyInputFieldStyle>
  )
}

export default KeyInputField
