import { Box, Button } from '@mui/material'
import React from 'react'

import { Subtitle, Title } from '../styled'

export const Header = ({ activeCompetitors, addCompetitor }: { activeCompetitors?: number, addCompetitor: () => void }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Title>Конкуренты продукта</Title>
      <Button onClick={addCompetitor}>Добавить конкурента</Button>
    </div>
    {activeCompetitors ?
      <Subtitle>Отметьте конкурентов, которые относятся к продукту и будут участвовать в волшебнике цен</Subtitle> : <></>
    }
  </Box>
)