import React from 'react'
import { Edit } from '@mui/icons-material'

import { CheckboxContainer } from './styled'

export const UserDateEditCell = ({ row, onModalOpen }) => {
  if (row.selected)
    return (
      <CheckboxContainer>
        <Edit onClick={() => onModalOpen(row)} />
      </CheckboxContainer>
    )

  return <></>
}
