import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PriceHistory } from 'features/product/priceHistory'
import { pimApi } from 'shared/api'
import { getServerReadableDateTime, toMaxDateTime, toMinDateTime } from 'shared/lib/utils'
import { PriceHistoryQueryProvider, PriceHistoryRecord } from 'features/product/priceHistory/model'


const currentDate = new Date()
const currentMinDate = toMinDateTime(currentDate)
const DEFAULT_MIN_DATE = Number(
  currentMinDate.setMonth(new Date().getMonth() - 1)
)

export const SimpleProductPriceHistory = () => {
  const [fromDate, setFromDate] = useState<number>(DEFAULT_MIN_DATE)
  const [toDate, setToDate] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const simpleProductQuery = pimApi.products.simple.useFetchSimpleProductQuery(productId)

  const simpleProductPriceHistoryQuery =
    pimApi.products.simple.useFetchPriceHistoryQuery({
      productId,
      ...(fromDate ? { fromDate: getServerReadableDateTime(fromDate) } : {}),
      ...(toDate ? { toDate: getServerReadableDateTime(toDate) } : {}),
    })
  const priceHistory = useMemo(():PriceHistoryRecord[] => {
    if (simpleProductPriceHistoryQuery?.data === undefined) { return []}
    return simpleProductPriceHistoryQuery?.data.history
  }, [simpleProductPriceHistoryQuery])

  const { mutate: deleteProductPrice } = pimApi.products.simple.useDeleteProductPriceMutation()
  const deleteFuturePriceQuery = ( priceId, versionNo, options ) =>
    deleteProductPrice(
      {
        productId,
        priceId,
        data: {
          id: priceId,
          versionNo,
        },
      }, options
    )

  const startPrice = simpleProductPriceHistoryQuery?.data?.startPrice
  const title = simpleProductQuery?.data?.name

  useEffect(() => {
    if (!toDate && simpleProductPriceHistoryQuery?.data && priceHistory?.length) {
      const maxItem =
          priceHistory[
            priceHistory.length - 1
          ]

      if (maxItem?.changeDate) {
        setToDate(
          Number(toMaxDateTime(maxItem.changeDate))
        )
      } else {
        setToDate(Number(toMaxDateTime(currentDate)))
      }
    }
  }, [simpleProductPriceHistoryQuery?.data])

  const handleTimeIntervalChange = useCallback((newTimeInterval) => {
    setFromDate(newTimeInterval[0])
    setToDate(newTimeInterval[1])
  }, [])

  return (
    <PriceHistoryQueryProvider priceHistoryQuery={simpleProductPriceHistoryQuery} deleteFuturePriceQuery={deleteFuturePriceQuery}>
      <PriceHistory
        data={priceHistory}
        title={title}
        isLoading={simpleProductPriceHistoryQuery.isLoading}
        startPrice={startPrice}
        fromDate={fromDate}
        toDate={toDate}
        onTimeIntervalChange={handleTimeIntervalChange}
      />
    </PriceHistoryQueryProvider>
  )
}
