import React, { useEffect, useState } from 'react'
import { pimApi } from 'shared/api'
import { TreeView } from '@mui/x-tree-view/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { highlight } from 'shared/lib/transform/highlightText'

import { CategoryItemSettings } from './CategoryItemSettings'

import { StyledLoader } from '../../../../User/EditUser/ui/styled'
import { filterCategories, flatCategories } from '../../lib/CategoriesTreeOperations'

export const CategoriesTree = () => {
  const { data: categoriesTreeData, isFetching, isLoading } = pimApi.categories.useGetCategoriesTreeQuery()
  const [searchString, setSearchString] = useState<string>('')
  const [expanded, setExpanded] = useState<Array<string>>([])

  useEffect(() => {
    const expandedCategories = searchString ? flatCategories(result) : []
    if (expandedCategories.length <= 15) {
      setExpanded(expandedCategories)
    } else {
      setExpanded([])
    }
  },[searchString])

  useEffect(() => {
    highlight(searchString,'category-item-text')
  }, [expanded])

  const renderTree = (nodes) => (
    <TreeItem
      key={ nodes?.id ? nodes.id.toString() : nodes.name }
      nodeId={ nodes?.id ? nodes.id.toString() : nodes.name }
      label={
        <CategoryItemSettings nodes={nodes}/>
      }>
      {nodes?.children.map(el => renderTree(el))}
    </TreeItem>
  )

  const result = categoriesTreeData ? filterCategories(categoriesTreeData, ({ name }) => name.toLowerCase().includes(searchString)) : []

  const handleNodeToggle = (_, nodeIds) => {
    setExpanded(nodeIds)
  }


  return (
    <>
      {((isLoading || isFetching) && !!categoriesTreeData) && (<StyledLoader size={60} />)}
      { categoriesTreeData ?
        <div className="category-container">
          <TextField
            variant="standard"
            value={ searchString }
            placeholder="Поиск" fullWidth={ true }
            InputProps={ {
              endAdornment: <SearchIcon sx={ { color: 'rgba(0,0,0,0.54)', marginRight: '8px' } } />,
              style: { width: '220px', color: 'rgba(0,0,0,0.54)' }, autoComplete: 'off'
            } }
            onChange={ (e) => setSearchString(e.target.value) } />
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={ <ExpandMoreIcon /> }
            defaultExpandIcon={ <ChevronRightIcon /> }
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
          >
            { (result)?.map(el => renderTree(el)) }
          </TreeView>
        </div>
        :
        <div style={ { width: '100%', height: '300px', position: 'relative' } }>
          <StyledLoader size={ 60 } />
        </div>
      }
    </>
  )
}