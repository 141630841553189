import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const validationSchema = yup.object().shape({

  dateIn: yup
    .date()
    .nullable()
    .typeError('Данные введены неверно')
    .transform(yupTransformEmptyStringInNull),

  dateOut: yup
    .date()
    .nullable()
    .typeError('Данные введены неверно')
    .transform(yupTransformEmptyStringInNull),
})
