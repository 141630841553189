import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import { Field, Form, InputField } from 'shared/ui/components'
import { SendPasswordMutation } from 'shared/api/auth'

import { TypographyStyled, ExplanTypography, HelperTextSupply, Wrapper } from './styled'
import { validationSchema } from './lib/validationSchema'
import { CorrectEmail } from './correctEmail'

export const PasswordRecoveryForm = () => {
  const validation = useYupValidation(validationSchema)
  const { mutate: sendPass } = SendPasswordMutation()
  const [validEmail, setValidEmail] = useState(false)
  const [Email, setEmail] = useState('')
  const handleSendPassword = (email) => {
    sendPass(email, {
      onSuccess: () => {
        setValidEmail(true)
        setEmail(email?.email)
      },
    })
  }
  if (validEmail) {
    return (
      <CorrectEmail email={Email}/>
    )
  }
  return (
    <Wrapper>
      <TypographyStyled>Восстановление пароля</TypographyStyled>
      <Form
        onSubmit={handleSendPassword}
        validate={validation}
        render={({ handleSubmit, errors, valid, values, visited }) => {
          const disabledButton = () => {
            if (values && values.email) {
              return (values.email.length) === 0
            }
            return true
          }
          return (
            <form onSubmit={handleSubmit}>
              <HelperTextSupply>
                <Field
                  type="text"
                  component={InputField}
                  margin="normal"
                  fullWidth={true}
                  label="E-mail"
                  name="email"
                  InputProps={ { autoComplete: 'on' } }
                  helperText={visited?.email && errors?.email}
                />
              </HelperTextSupply>
              <ExplanTypography>
                {'Мы пришлем вам инструкцию\nпо восстановлению пароля'}
              </ExplanTypography>
              <Box>
                <Button
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  disabled={!valid || disabledButton()}
                >
                  Отправить
                </Button>
              </Box>
            </form>)
        }}
      />
    </Wrapper>
  )
}
