import React, { useContext } from 'react'
import {
  ActivityContext,
  IActivityContext
} from 'features/analytics/activities/model/ActivityContext'
import { CircularProgress, Paper as MuiPaper } from '@mui/material'
import { Header } from 'features/analytics/activities/ui/EditActivity/Header'
import { ActivityProducts } from 'features/analytics/activities/ui/EditActivity/ActivityProducts'
import styled from 'styled-components'

import { Paper } from './styled'

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50%);
  z-index: 1;
`
export const EditActivityPage = () => {
  const { detailActivity } = useContext(ActivityContext) as IActivityContext
  if (detailActivity) {
    return (
      <Paper component={MuiPaper}>
        <Header/>
        <ActivityProducts/>
      </Paper>
    )
  }
  return <StyledLoader size={60}/>
}