import { HeaderStyle, HeaderWrapper } from './styled'

export const Header = () => (
  <HeaderWrapper>
    <HeaderStyle>
      Страница администратора
    </HeaderStyle>
    {/* <ArticleStyle> */}
    {/*  Панель управления сайтом */}
    {/* </ArticleStyle> */}
  </HeaderWrapper>
)
