import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'

export const getMarketplaceInfoQueryKey = (marketplaceId: UniqueId) =>
  ['get', 'customer/marketplaces', marketplaceId] as const

type GetMarketplaceInfoQueryKey = ReturnType<typeof getMarketplaceInfoQueryKey>

export function getMarketplaceInfo(marketplaceId: UniqueId) {
  return api.get<customerApi.Marketplace>
  (`/customer/marketplaces/${marketplaceId}`).then((res) => res.data)
}

export const useGetMarketplaceInfoQuery = (marketplaceId: UniqueId) =>
  useQuery<
    customerApi.Marketplace,
    errorType,
    customerApi.Marketplace,
    GetMarketplaceInfoQueryKey
  >(
    getMarketplaceInfoQueryKey(marketplaceId),
    (context) => getMarketplaceInfo(context.queryKey[2] as UniqueId)
  )
