/* eslint-disable no-param-reassign */

// Decorator pattern
export class Label {
  constructor(public label: string) {}
}

export const withUnits = (labelInstance: Label, units: string): void => {
  labelInstance.label = `${labelInstance.label}, ${units}`
}

export const withMaxCount = (labelInstance: Label, maxcount: number): void => {
  labelInstance.label = `${labelInstance.label} (максимум ${maxcount})`
}

export const withRequired = (labelInstance: Label): void => {
  labelInstance.label = `${labelInstance.label}*`
}
