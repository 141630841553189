import React from 'react'
import { Box, Typography } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'

import { DeltaValues } from './styled'

interface WidgetData {
  name: string
  data: number
  diff: number
}
interface DiffDataParams {
  title: string
  leftData: WidgetData
  rightData: WidgetData
  chartUrl?: string
}

export const DoubleSmallWidget = ({ title, leftData, rightData, chartUrl }: DiffDataParams) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: '#00000099' }}>
        {title}
      </Typography>
      <Box sx={{ marginLeft: 'auto', marginRight: '0' }}>
        {chartUrl &&
        <Box>
          <a
            style={{ display: 'flex', alignItems: 'center' }}
            href={chartUrl}
            target="_blank" rel="noreferrer">
            <BarChartIcon sx={{ color: '#00000061' }}/>
          </a>
        </Box>
        }
      </Box>
    </Box>
    <DeltaValues>
      <Box>
        <ValueDelta value={leftData.data} delta={leftData.diff} name={leftData.name}/>
      </Box>
      <Box>
        <ValueDelta value={rightData.data} delta={rightData.diff} name={rightData.name}/>
      </Box>
    </DeltaValues>
  </Box>
)