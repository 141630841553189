import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'

const PriceHistoryContext = React.createContext<any | undefined>(undefined)

interface PriceHistoryProviderProps {
  priceHistoryQuery: QueryObserverResult,
  deleteFuturePriceQuery: any,
}

function PriceHistoryQueryProvider({ children, priceHistoryQuery, deleteFuturePriceQuery }: PropsWithChildren<PriceHistoryProviderProps>) {
  return (
    <PriceHistoryContext.Provider value={{ priceHistoryQuery, deleteFuturePriceQuery }}>{children}</PriceHistoryContext.Provider>
  )
}

function usePriceHistoryContext() {
  const context = React.useContext(PriceHistoryContext)

  if (context === undefined) {
    throw new Error('useGoodsContext must be used within a GoodsProvider')
  }

  return context
}

export { PriceHistoryQueryProvider, usePriceHistoryContext }