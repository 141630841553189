export const transformAcceptanceChanges = (
  changesData: {
    quantityAccepted?: string,
    quantityDefective?: string,
  }) => {
  const quantityAccepted = changesData.quantityAccepted
    ? parseInt(changesData.quantityAccepted, 10)
    : undefined
  const quantityDefective = changesData.quantityDefective
    ? parseInt(changesData.quantityDefective, 10)
    : undefined

  return { quantityAccepted, quantityDefective }
}