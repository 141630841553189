import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

const archiveProduct = (
  productId: UniqueId,
  archivationInfo: pimApi.products.ProductArchivationInfo
) =>
  api.put<pimApi.products.common.Product>(`/pim/products/common/product/${productId}/archive`, archivationInfo)

export const useArchiveProductMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.common.Product>,
    errorType,
    {
      productId: UniqueId,
      archivationInfo: pimApi.products.ProductArchivationInfo
    }
  >(({ productId, archivationInfo }) => archiveProduct(productId, archivationInfo))

const dearchiveProduct = (
  productId: UniqueId,
  deArchivationInfo: pimApi.products.ProductDeArchivationInfo,
) =>
  api.delete<pimApi.products.common.Product>(`/pim/products/common/product/${productId}/archive`, { data: deArchivationInfo })

export const useUnarchiveProductMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.common.Product>,
    errorType,
    {
      productId: UniqueId,
      deArchivationInfo: pimApi.products.ProductDeArchivationInfo,
    }
  >(({ productId, deArchivationInfo }) => dearchiveProduct(productId, deArchivationInfo))