import React, { useEffect } from 'react'
import { customerApi } from 'shared/api'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import imagePlaceholder from 'assets/images/photo-size-select-actual.svg'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff'
import { useGetMarketplaceInfoQuery } from 'shared/api/customer/companies/getMarketplaceInfo'

import {
  CabinetWrapper,
  GoodsIsLoad,
  InArchive,
  KeyWrapper,
  MarketplaceImg,
  MarketplaceName,
} from './styled'

import { EditCabinetOptions } from '../EditCabinetOption/EditCabinetOptions'

interface ICabinetData {
  cabinetData: customerApi.CompanyMarketplace
  companyInArchive?: boolean
}
interface ICredentialData {
  keyData: customerApi.CompanyMarketplaceCredential
}

const Cabinet = ({ cabinetData, companyInArchive = false }: ICabinetData) => {
  const { cabinetName, inArchive, marketplaceId } = cabinetData

  const marketplaceLogo = customerApi.useMarketplaceLogoQuery(marketplaceId)
  const urlLogo = marketplaceLogo?.data?.data as Blob

  const { data } = useGetMarketplaceInfoQuery(marketplaceId)
  const marketplaceInfo = data

  const presentKey = cabinetData.credentials

  useEffect(() => {
    marketplaceInfo?.credentials?.forEach(key => {
      const findKey = cabinetData.credentials?.find((secretKey) => key.id === secretKey.credentialId)
      if (!findKey) {
        presentKey?.push({
          credentialId: key.id,
          credentialName: key.name ?? '',
          inArchive: false,
          secretFlag: key.secretFlag,
          value: '',
          valueSet: false
        })
      }
    })
  }, [cabinetData])



  const Keys = ({ keyData }: ICredentialData) => (
    <KeyWrapper $isHide={!keyData.value && !keyData.secretFlag} >
      <>
        <div>
          { keyData.secretFlag
            ? `${keyData.credentialName}`
            : <span>{keyData.value ? `${keyData.credentialName}` : ''}</span>
          }
          { keyData.secretFlag
            ? ''
            : <span>{keyData.value ? `: ${ keyData.value}` : ''}</span>
          }
        </div>
        {
          keyData.secretFlag && <>
            {
              keyData.valueSet
                ? <VpnKeyIcon sx={{ color: '#2196F3' }}/>
                : <VpnKeyOffIcon sx={{ color: 'rgba(0, 0, 0, 0.12)' }}/>
            }
          </>
        }
      </>
    </KeyWrapper>
  )

  return (
    <CabinetWrapper>
      <Box width={490} display="flex">
        <Tooltip
          title={cabinetData.marketplaceName}
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                position: 'relative',
                top: '-36px',
                left: '-36px'
              }
            }
          }}
        >
          {
            !marketplaceLogo.isSuccess
              ? <CircularProgress/>
              : <MarketplaceImg src={URL.createObjectURL(urlLogo)} $inArchive={inArchive || companyInArchive}>
                <img src={imagePlaceholder} alt="default icon"/>
              </MarketplaceImg>
          }
        </Tooltip>
        <MarketplaceName $inArchive={inArchive}>
          { cabinetName }
        </MarketplaceName>
        { inArchive && <InArchive>В архиве</InArchive> }
      </Box>

      <Box>
        { !companyInArchive && !inArchive && presentKey && presentKey.map((keyData, index) =>
          // eslint-disable-next-line react/no-array-index-key
          <Keys keyData={keyData} key={index}/>)
        }
      </Box>
      <GoodsIsLoad />
      <EditCabinetOptions
        cabinetData={cabinetData}
      />
    </CabinetWrapper>
  )
}

export default Cabinet