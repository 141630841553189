import { Box, CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const CircularProgressStyled = styled(CircularProgress)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const CircularProgressWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0.7;
  z-index: 99;
`

export const ContentsWrapper = styled(Box)<{ width?: number }>`
  width: ${({ width }) => width ? `${width}px` : '920px'};
  position: relative;
`