import { Option } from 'shared/ui/components/interface'

export const PRODUCT_STATUS: Array<Option> = [
  {
    value: 'newProduct',
    label: 'Новый продукт'
  },
  {
    value: 'copyProduct',
    label: 'Скопировать существующий продукт'
  }
]
export const PRODUCT_STATUS_WB: Array<Option> = [
  {
    value: 'new',
    label: 'Новая карточка на WB'
  },
  {
    value: 'old',
    label: 'Добавить в существующую карточку'
  }
]

export const PRODUCT_STATUS_OZON: Array<Option> = [
  {
    value: 'new',
    label: 'Новая карточка'
  },
  {
    value: 'old',
    label: 'Связать с существующей карточкой на OZON'
  }
]

