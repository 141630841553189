import { memo, useMemo } from 'react'
import { Box, Skeleton, Theme, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'

import { GoodsCardSkeletonWrapper } from './styled'

export const GoodsCardSkeleton = () => {
  const theme = useTheme()

  const sx = useMemo<SxProps<Theme>>(() => ({
    bgcolor: theme.palette.action.hover,
    transform: 'none',
  }), [theme])

  return (
    <GoodsCardSkeletonWrapper>
      <Skeleton variant="rectangular" width={80} height={80} animation={false} sx={sx} />
      <Box
        display="flex"
        flexDirection="column" 
        justifyContent="space-between"
        p={2}
      >
        <Skeleton variant="text" width={403} height={16} sx={sx} animation={false} />
        <Skeleton variant="text" width={196.5} height={16} animation={false} sx={sx} />
      </Box>
    </GoodsCardSkeletonWrapper>
  )
}

export const GoodsCardSkeletonMemo = memo(GoodsCardSkeleton)
