import styled from 'styled-components'

export const OrderDeltaContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  .missing {
    color: #F50057;
  }
  .extra {
    color: #4CAF50;
  }
`