import { Goods } from 'domains/wms/goods'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import {
  DeletePositionBodyParams, GoodsResponse,
  NewPositionBodyParams, ReceiptDocPositionResponse,
} from './models'

import { api } from '../../base'

const basePath = '/wms/goods'

export function getOneGoods(goodId: UniqueId) {
  return api.get<Goods>(`${basePath}/${goodId}`)
}

export function getGoodsImage(goodId: UniqueId, imageId: UniqueId) {
  return api.get<File>(
    `${basePath}/${goodId}/image/${imageId}`,
    {
      responseType: 'blob'
    }
  )
}

interface CreateParams {
  docId: number;
  body: NewPositionBodyParams;
}

function createPosition(docId: number, body: NewPositionBodyParams) {
  const path = `/wms/receiptdocs/${docId}/positions`
  return api.post<Array<ReceiptDocPositionResponse>>(path, body)
}
export const useCreatePosition = () => useMutation<
  AxiosResponse<Array<ReceiptDocPositionResponse>>,
  errorType,
  CreateParams
>(({ docId, body }: CreateParams) => createPosition(docId, body))


interface DeleteParams {
  docId: number;
  posId: number;
  body: DeletePositionBodyParams;
}

function deletePosition(docId: number, posId: number, body: DeletePositionBodyParams) {
  const path = `/wms/receiptdocs/${docId}/positions/${posId}`
  return api.delete<DeletePositionBodyParams>(path, { data: body })
}
export const useDeletePosition = () => useMutation<
  AxiosResponse<DeletePositionBodyParams>,
  errorType,
  DeleteParams
>(({ docId, posId, body }: DeleteParams) => deletePosition(docId, posId, body))


function getGoods(barcodeSeatchStr?: string) {
  const path = barcodeSeatchStr
    ? `${basePath}?barcode=${barcodeSeatchStr}`
    : basePath
  return api.get<Array<GoodsResponse>>(path)
}
export const useGoodsSearch = () => useMutation<
  AxiosResponse<Array<GoodsResponse>>,
  errorType,
  { barcodeSeatchStr: string }
>(({ barcodeSeatchStr }) => getGoods(barcodeSeatchStr))