import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface EditSupplyOrderPayload {
  positionId: UniqueId,
  supplyOrderId?: UniqueId,
  editedSupplyOrder: tradeApi.EditSupplyOrderProductPosition
}

export const editSupplyOrder = ({ positionId, supplyOrderId, editedSupplyOrder }: EditSupplyOrderPayload) =>
  api.put<tradeApi.SupplyOrderProductPosition>(
    `/trade/orders/supplyorders/${supplyOrderId}/productpositions/${positionId}`,
    editedSupplyOrder
  )

export const useEditSupplyOrderMutation = () =>
  useMutation<
    AxiosResponse<tradeApi.SupplyOrderProductPosition>,
    AxiosError<errorType>,
    EditSupplyOrderPayload
  >(editSupplyOrder)