import { InputAdornment, Box, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material'
import { Search } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import React, { FC, useState } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { NewComponentCard } from 'entities/pim/goodsComponents'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { InputLabel } from 'shared/ui/components'
import { isEmpty, isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import {
  ProductSearchWrapper,
  ResultList
} from './styled'

import { GoodsAlertMessage } from '../../alertMessages'


interface AssortmentGoodsComponentSearchProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onAddComponent: (newGoodsComponent:  AppPimGoodsModelsGoodsGoodsComponent) => void
  disabled?: boolean
}

const MAX_GOODS_COMPONENTS = 5

export const AssortmentGoodsComponentSearch: FC<AssortmentGoodsComponentSearchProps> = ({
  goodsComponents,
  onAddComponent,
  disabled
}) => {
  const [maxGoodsComponentsCount, setMaxGoodsComponentsCount] = useState<number>(MAX_GOODS_COMPONENTS)
  const [value, setValue] = useState<string>('')
  const [withVariations, setWithVariations] = useState<boolean>(false)
  const debouncedValue = useDebounce(value, 200)
  const { data, isLoading } = pimApi.goods.useGoodsByCodeQuery(debouncedValue, withVariations)

  const goodsComponentsFiltered = data?.data.filter(
    item => !goodsComponents.find(component => component.componentId === item.id)
  ) ?? []

  const goodsComponentsFilteredAndSliced = goodsComponentsFiltered.slice(0, maxGoodsComponentsCount)

  const isShowMoreButton = goodsComponentsFiltered.length !== goodsComponentsFilteredAndSliced.length

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleAdd = (goods: pimApi.goods.GoodsShortInfo) => {
    const newComponent: AppPimGoodsModelsGoodsGoodsComponent = {
      componentId: goods.id,
      name: goods.name,
      code: goods.code,
      auxCode: goods.auxCode,
      goodsBaseImageId: goods.goodsBaseImageId,
      goodsType: goods.goodsType,
      unitCode: goods.unitCode,
      unitPartible: goods.unitPartible,
    }
    
    onAddComponent(newComponent)
  }

  const handleShowAllGoodsComponents = () => {
    setMaxGoodsComponentsCount(goodsComponentsFiltered.length)
  }

  return (
    <ProductSearchWrapper>
      <InputLabel label="Поиск товаров" />
      <Box>
        <TextField
          variant="outlined"
          size="small"
          fullWidth={true}
          onChange={handleChange}
          value={value}
          placeholder="Введите артикул товара"
          disabled={disabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {isLoading &&
              <CircularProgress size={20} />}
              <Search/>
            </InputAdornment>,
          }}
        />
        <FormControlLabel
          sx={{ marginTop: 1, color: 'rgba(0, 0, 0, 0.6)' }}
          control={
            <Checkbox
              color="primary"
              onChange={() => setWithVariations(!withVariations)}
              checked={withVariations}
              disabled={disabled}
              size="small"
            />
          }
          label="Показывать все вариации товаров выбранного артикула"
        />
      </Box>
      <ResultList>
        <Box height={(isEmptyArray(goodsComponentsFiltered) && isEmpty(goodsComponents) && value.length > 0) ? '40px' : ''}>
          { (isEmptyArray(goodsComponentsFiltered) && isEmpty(goodsComponents) && value.length > 0) &&
          <GoodsAlertMessage message="Товар по данному артикулу не найден"/>
          }
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {goodsComponentsFilteredAndSliced.map((goodsComponent) => (
            <NewComponentCard
              key={goodsComponent.id}
              goods={goodsComponent}
              onAddClick={handleAdd}
              isAddDisabled={!!disabled}
              isInputDisabled={true}
            />
          ))}
        </Box>
      </ResultList>

      {isShowMoreButton && (
        <Box display="flex" width="100%" justifyContent="center" mt={1}>
          <Button onClick={handleShowAllGoodsComponents} variant="outlined" size="medium" >
            Показать еще {goodsComponentsFiltered.length - goodsComponentsFilteredAndSliced.length}
          </Button>
        </Box>
      )}
    </ProductSearchWrapper>
  )
}