import styled from 'styled-components'
import { DialogTitle, IconButton } from '@mui/material'

export const DialogTitleUI = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 500;
  padding: 24px;
`

export const IconCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
`