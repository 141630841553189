import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete , CircularProgress, TextField } from '@mui/material'
import debounce from 'lodash.debounce'
import Box from '@mui/material/Box'
import usePending from 'shared/lib/hooks/PendingHook'
import { catalogSelector } from 'store/catalog/selectors'
import { addCatalog } from 'store/catalog'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { Option } from 'shared/ui/components/interface'

import * as UI from './styled'

import { withFieldEventAdapter } from '../FieldAdapter'

const checkToEqualValue = (option: Option<string | number>, currentValue: Option<string | number>) => (
  option.value === currentValue.value
)
export interface SmartSearchInputI<T> extends FieldRenderProps<T> {
  catalog: string
  catalogSort?: (a: unknown, b: unknown) => number
  optionLabelPath: string;
  catalogFilterPredicate?: (element: Option) => boolean
  getOptionLabel?: (element: Option) => string
  input: FieldInputProps<T, HTMLElement> & {
    onChange: (value: T | null) => void
  }
}

const ONE_SECOND = 1000
const SmartSearchInput = ({
  catalog = '',
  catalogSort,
  optionLabelPath = 'label' as 'label',
  getOptionLabel,
  onChange,
  input: {
    label = '',
    value,
    onBlur,
    onFocus
  },
  meta: { invalid, touched },
  catalogFilterPredicate,
  ...rest
}: SmartSearchInputI<Option>) => {
  const [getCatalog, isLoading] = usePending(addCatalog)
  const catalogData = useSelector(catalogSelector(catalog)) // as Array<Option>

  const list = catalogFilterPredicate ? catalogData.filter(catalogFilterPredicate) : catalogData
  const [isOpen, setIsOpen] = useState(false)
  const [fieldValue, setFieldValue] = useState('')

  const renderOptionDefault = useCallback(
    (props, option) => (
      <UI.Item {...props}>
        <Box display="flex" alignItems="center">
          <UI.ItemText variant="h6">{option[optionLabelPath]}</UI.ItemText>
        </Box>
      </UI.Item>
    ),
    [optionLabelPath]
  )

  const renderOptionProp = renderOptionDefault

  const handleChange = (_: any, option: Option | null) => {
    setIsOpen(false)
    onChange(option ?? null)
  }

  useEffect(() => {
    if (list && !list.length) {
      getCatalog({ catalog })
    }
  }, [catalog])

  const debouncedGetCatalog = debounce(getCatalog, ONE_SECOND)

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = target

    setFieldValue(targetValue)
    debouncedGetCatalog({
      catalog,
      sort: catalogSort,
      qParams: {
        value: targetValue,
      },
    })
  }, [])

  return (
    <Autocomplete<Option>
      onBlur={onBlur}
      onFocus={onFocus}
      size="small"
      clearOnBlur={false}
      blurOnSelect={true}
      // @ts-ignore
      disableClearable={true}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      ListboxProps={{ style: { maxHeight: '35vh' } }}
      // @ts-ignore
      getOptionLabel={getOptionLabel ?? ((option) => option[optionLabelPath] ?? '')}
      options={list}
      isOptionEqualToValue={checkToEqualValue}
      value={value}
      fullWidth={true}
      loading={isLoading}
      onChange={handleChange}
      noOptionsText="Введите строку для поиска"
      // @ts-ignore
      renderOption={renderOptionProp}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleInputChange}
          value={fieldValue}
          label={label}
          variant="outlined"
          size="small"
          error={invalid && touched}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

SmartSearchInput.displayName = 'UI_Autocomplete'

// @ts-ignore
export default withFieldEventAdapter(SmartSearchInput)
