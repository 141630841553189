import { ComponentType, memo } from 'react'

export const shallowCompare = (prev: {[key: string]: any}, next: {[key: string]: any}): boolean => {
  // eslint-disable-next-line
  for (const key in next) {
    const isNotSame = next[key] !== prev[key]
    if (isNotSame) {
      // eslint-disable-next-line no-console
      console.log(`Ререндер произошел из-за изменения пропса ${key}`)
      return false
    }
  }

  return true
}

export const watcher = (prevProps: {[key: string]: any}, nextProps: {[key: string]: any}) => {
  const propsAreEqual = shallowCompare(prevProps, nextProps)

  if (!propsAreEqual) {
    // eslint-disable-next-line no-console
    console.log({
      prevProps,
      nextProps,
    })
  }

  return propsAreEqual
}

/**
 * 
 * @example export const FormComponent = memoWatcher(FormComponent)
 */
export const memoWatcher = (Component: ComponentType<any>) => memo(Component, watcher)