import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'
import { CardMedia, Typography } from '@mui/material'

export const AutocompleteStyled = styled(Autocomplete)``
export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
`
export const ItemImage = styled(CardMedia)`
  height: 50px;
  width: 50px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`
export const ItemText = styled(Typography)`
  max-width: 100%;
  text-overflow: ellipsis;
`
