import { DocType } from 'shared/services/interfaces/doc'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

export type ColumnName = keyof DocType

const columns = {
  name: 'name',
  actionColumn: 'userId',
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<any>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование значения',
      width: 'auto',
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      width: 40,
      sortingEnabled: false,
      wordWrapEnabled: false,
      cellType: CellType.SettingsMenuCell
    },
  ]
}