import { useCallback, useMemo, useRef } from 'react'
import { requestActionType } from 'store/actionsCreator'
import { pendingSelector } from 'store/shared/pending'
import { useDispatch, useSelector } from 'react-redux'

let previousAction = ''

export const WhenDone = (cb: () => void, action: requestActionType): void => {
  const { pendingActions } = useSelector(pendingSelector)
  const status = pendingActions.includes(
    action.getType('pending').replace(/\[\d*] /, '')
  )
  const ref = useRef(0)

  let count = useMemo(() => {
    if (status === false) ref.current += 1
    return ref.current
  }, [status])

  if (status === false && count === 2 && ref.current === 2) {
    ref.current = 1
    count = 1
    cb()
  }
}

export default <T>(
  action: requestActionType,
  skip?: boolean
): [(data?: T | any) => void, boolean] => {
  const dispatch = useDispatch()
  const { pendingActions } = useSelector(pendingSelector)
  const status = pendingActions.includes(
    action.getType('pending').replace(/\[\d*] /, '')
  )

  const request = useCallback(
    (...args) => {
      if (skip) {
        if (previousAction !== action.getType('pending')) {
          dispatch(action.pending(...args))
        }
        previousAction = action.getType('pending')
      } else {
        dispatch(action.pending(...args))
      }
    },
    [action, dispatch, skip]
  )

  return [request, status]
}
