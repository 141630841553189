import React, { memo, useState } from 'react'
import defaultWarehouses from 'assets/images/defaultWarehouses.svg'
import wbLogo from 'assets/images/wbLogo.svg'
import ozonLogo from 'assets/images/ozonLogo.svg'
import yandexMarketLogo from 'assets/images/yandexMarketLogo.svg'

import { MarketplaceWarehouses } from './ui/MarketplaceWarehouses'
import { MarketplacesList } from './ui/MarketplacesList'
import { EmptyPlug, PriorityWarehousesBox } from './styled/styled'

interface MarketplacesParams {
  marketplaceId: number;
  name: string
  icon: string
}

const marketplaces: Array<MarketplacesParams> = [
  {
    marketplaceId: 1,
    name: 'Wildberries',
    icon: wbLogo
  },
  {
    marketplaceId: 2,
    name: 'OZON',
    icon: ozonLogo
  },
  {
    marketplaceId: 3,
    name: 'Яндекс Маркет',
    icon: yandexMarketLogo
  },
]

export const PriorityWarehousesPage = memo(() => {
  const [ marketplaceId, setMarketplaceId ] = useState<number>()

  return (
    <PriorityWarehousesBox>
      <MarketplacesList marketplaces={marketplaces} marketplaceId={marketplaceId} setMarketplaceId={setMarketplaceId}/>
      {marketplaceId ?
        <MarketplaceWarehouses marketplaces={marketplaces} marketplaceId={marketplaceId}/>
        :
        <EmptyPlug>
          <img src={defaultWarehouses} alt="alt"/>
        </EmptyPlug>
      }
    </PriorityWarehousesBox>
  )
}
)