// @ts-nocheck
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Slider from '@mui/material/Slider'
import { useField } from 'react-final-form'

const SliderYear = styled(Slider)`
  .MuiSlider-rail {
    color: #b8b8b8;
  }
`
const SliderRangeWrapper = styled.div`
  padding: 0 15px;
`

const SliderField = ({ input, label, name = 'range', marks, ...rest }) => {
  const range = useField(name)
  const handleChange = (event, value) => {
    range.input.onChange(value)
  }
  useEffect(() => {
    range.input.onChange([1950, 2030])
  }, [range.input])

  return (
    <SliderRangeWrapper>
      <SliderYear
        min={1959}
        max={2021}
        marks={marks}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        onChange={handleChange}
        value={typeof range.input.value !== 'string' ? range.input.value : []}
        {...rest}
      />
    </SliderRangeWrapper>
  )
}

export default SliderField
