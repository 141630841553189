import { Box } from '@mui/material'
import styled from 'styled-components'

export const CopyIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  svg{
    min-width: 20px;
    min-height: 20px;
    color: #8D8D8D;
  }
`

export const VariationTitle = styled.div`
  font-size: 15px;
  color: #8D8D8D;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  font-weight: 500;
  background: #fff;
  position: relative;
  z-index: 2;
  padding-right: 10px;
`

export const StyledProductHeaderInfo = styled(Box)`
  p:first-child {
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.15px;
    margin-bottom: 5px;
  }
  p:last-child {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const VariationLabelWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 15px;
`
export const MarketplaceLabelWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
`