import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

import { GetDictionaryvaluesDictionariesDictionaryIdFilterSearchStringGet } from './models'

export * from './archive'
export * from './brand'
export * from './cardStatus'
export * from './company'
export * from './season'
export * from './contragent'
export * from './countries'
export * from './marketplace'
export * from './marketplaceWarehouses'
export * from './models'
export * from './priceChangeReasons'
export * from './tnved'
export * from './syncStatus'
export * from './orderStatus'
export * from './packagingtype'
export * from './values/index'


export const fetchDictionaryFiltered = (
  dictionaryId: UniqueId,
  search: string,
): Promise<AxiosResponse<GetDictionaryvaluesDictionariesDictionaryIdFilterSearchStringGet>> => api.get(
  `/pim/dictionaries/${dictionaryId}/filter/${search}`
)
