import { useEffect, useMemo, useState } from 'react'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { Autocomplete, Button, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { IBrand, useBrandquadStore } from 'features/showcase/lib/store'
import { isNil, isSameValues } from 'shared/lib/checkers'
import DownloadIcon from '@mui/icons-material/Download'
import { brandquadApi } from 'shared/api'
import { useParams } from 'react-router'
import { downloadFile } from 'shared/lib/utils'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import {
  LoaderWrapper,
  SelectWrapper,
  StyleChips,
  StyledChipsContainer,
  StyledLoader,
} from '../../styled'

interface BrandItem {
  id: UniqueId
  name: string
}

const getOptions = (optionItem: BrandItem) => ({
  label: optionItem.name,
  value: optionItem.id,
})

export const Filters = ({ goodsStack }) => {
  const { clientId: id } = useParams<{ clientId: string }>()
  const { data: brandsData } = brandquadApi.brands.getBrands.useGetBrands({
    clientId: id,
  })

  const { handleChangeParams, searchObj } = useQueryParams({
    parseNumbers: true,
    arrayFormat: 'bracket',
  })

  const [ selectedBrands, setSelectedBrands ] = useState<Array<number>>(searchObj?.brandIds || [])
  const [searchString, setSearchString] = useState(searchObj.searchString)

  const brandsOptions = useMemo(
    () => brandsData?.map(getOptions) || [],
    [brandsData]
  )
  const selectedBrandsOptions = useMemo(() => {
    if (brandsData) {
      return brandsData.filter(el => selectedBrands.includes(el.id)).map(getOptions)
    }
    return []
  },[brandsData, selectedBrands])

  const selectedGoods = useBrandquadStore((state) => state.selectedGoods)

  const { mutate: downloadGoodsInfoAll, isLoading } =
    brandquadApi.goods.getGoodsInfoAll.useGetGoodsInfoAll()

  const goodsIdsArr = goodsStack?.map((el) => el.goods_id)


  useEffect(() => {
    if (searchObj?.brandIds?.length! !== selectedBrands.length) {
      setSelectedBrands(searchObj?.brandIds! || [])
    }
  }, [JSON.stringify(searchObj.brandIds), brandsData])

  const handleDownloadGoods = () => {
    downloadGoodsInfoAll(
      {
        clientId: id,
        query: {
          goodsIds:
            (isNotEmptyArray(selectedGoods) && selectedGoods) || goodsIdsArr,
        },
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName = decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'attachment; filename=',
              ''
            )
          )
          downloadFile(file, fileName)
        },
      }
    )
  }

  const handleDeleteAllBrands = () => {
    handleChangeParams({
      params: {
        brandIds: null,
      },
      options: {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: 'bracket',
      },
    })
  }

  const handleDeleteBrand = (brand: IBrand) => {
    handleChangeParams({
      params: {
        brandIds: selectedBrands
          .filter((el) => el !== brand.value)
          .map((el) => el),
      },
      options: {
        arrayFormat: 'bracket',
      },
    })
  }

  const handleBlur = () => {
    if (
      !isSameValues(selectedBrands.sort(), (searchObj?.brandIds || []).sort())
    ) {
      handleChangeParams({
        params: {
          brandIds: selectedBrands,
        },
        options: {
          arrayFormat: 'bracket',
        },
        isPush: true
      })
    }
  }

  const downloadButtonRendering = searchObj?.brandIds?.length > 0 || searchObj.searchString

  return (
    <div className="filters-container">
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }}
        onBlur={() => {
          handleChangeParams({
            params: {
              searchString,
              goodsId: null
            },
            options: { skipEmptyString: true, skipNull: true },
          })
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />,
        }}
      />
      <div className="brand-filter-wrapper">
        <SelectWrapper>
          {isNil(searchObj?.goodsId) && (
            <Autocomplete
              options={brandsOptions}
              noOptionsText="Нет фильтров"
              value={selectedBrandsOptions}
              clearOnBlur={false}
              disableCloseOnSelect={true}
              disableClearable={true}
              blurOnSelect={false}
              multiple={true}
              isOptionEqualToValue={isOptionEqual}
              size="small"
              onBlur={handleBlur}
              onChange={(_, value) => {
                setSelectedBrands(value.map(el => el.value))
              }}
              renderOption={(props: object, option: any) => (
                <div {...props}>{option.label}</div>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Бренд" />
              )}
            />
          )}
        </SelectWrapper>
        {selectedBrandsOptions.length > 0 && isNil(searchObj?.goodsId) && (
          <StyledChipsContainer>
            <>
              {selectedBrandsOptions.map((el) => (
                <StyleChips
                  size="small"
                  key={el.value}
                  label={el.label}
                  onDelete={() => handleDeleteBrand(el)}
                />
              ))}
              <StyleChips
                size="small"
                label="Сбросить все"
                onClick={handleDeleteAllBrands}
              />
            </>
          </StyledChipsContainer>
        )}
      </div>
      {downloadButtonRendering && (
        <div className="download-goods">
          {isLoading && (
            <LoaderWrapper height={20}>
              <StyledLoader size={20} color="inherit" />
            </LoaderWrapper>
          )}
          <Button
            variant="contained"
            size="small"
            disabled={
              isLoading ||
              (selectedBrands.length > 1 && selectedGoods.length < 1)
            }
            onClick={handleDownloadGoods}
            startIcon={<DownloadIcon />}
          >
            Скачать
          </Button>
        </div>
      )}
    </div>
  )
}
