import { StocksCard } from 'entities/pim/stocks'
import { tradeApi } from 'shared/api'

export const Stocks = ({ productId }) => {
  const stocksQuery = tradeApi.stocks.useStocksProductQuery(productId)

  if (!stocksQuery.data) {
    return null
  }

  return (
    <StocksCard stocksData={stocksQuery.data}/>
  )
}
