import { FileActions } from 'features/wms'
import React from 'react'

import { ISelectedState } from '../lib/types'

export const Header = ({ selectedRows, setSelectedRows }:ISelectedState) => (
  <div className="header">
    <span>Склад</span>
    <FileActions selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
  </div>
)