import styled from 'styled-components'
import { DialogTitle, IconButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Link } from 'react-router-dom'

export const DialogTitleUI = styled(DialogTitle)`
  .MuiTypography-h6{
    font-size: 20px;
    font-weight: 700;
  }
`

export const IconCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

export const ProductSearchWrapper = styled.div`
  margin-bottom: 20px;
`
export const SearchInput = styled(TextField)<any>`

`
export const ResultItem = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
`
export const ResultList = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin-top: 16px;
`
export const ProductType = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`
export const ProductTitle = styled(Link)`
  color: #007DFF;
  font-size: 16px;
  text-decoration: none;
`
export const ProductAuxCode = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
`
export const ProductImage = styled.div`
  width: 80px;
  height: 80px;
`
export const ProductInfo = styled.div`
  width: 100%;
`
export const CountInput = styled(TextField)`
  width: 60px;
`
export const NoItems = styled.div`
  font-size: 16px;
  color: #000;
  padding-left: 8px;
  margin-top: 15px;
`
export const ErrorText = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`

export const InfoText = styled.div`
`