import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'


export function getContentRating(productId: UniqueId): Promise<AxiosResponse> {
  return api.get(`/pim/products/ozon/product/${productId}/rating`)
}

export const getContentRatingQueryKey = (productId: UniqueId) =>
  ['get', '/pim/products/ozon/rating', productId] as const

type GetContentRatingQueryKey = ReturnType<typeof getContentRatingQueryKey>

const getContentRatingFn = (context: QueryFunctionContext<GetContentRatingQueryKey>) => {
  const [, ,productId] = context.queryKey
  return getContentRating(productId)
}

export const useGetContentRatingQuery = (productId: UniqueId) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    GetContentRatingQueryKey
  >(
    getContentRatingQueryKey(productId),
    getContentRatingFn, {
      refetchOnMount: false, keepPreviousData: true, staleTime: Infinity
    }
  )
