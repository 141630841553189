import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Brand } from './models'

import { api } from '../../base'



export function fetchBrands(): Promise<AxiosResponse<Brand[]>> {
  return api.get<Brand[]>('/pim/brands')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/brands'] as const

function getBrands() {
  return fetchBrands().then((res) => res.data)
}

export const useBrandListQuery = () => useQuery<
    Array<Brand>,
    errorType,
    Array<Brand>,
    QueryKey
  >(QUERY_KEY, getBrands, {
    refetchOnMount: false,
  })

export function useBrandList() {
  const brandListQuery = useQuery<
    Array<Brand>,
    errorType,
    Array<Brand>,
    QueryKey
  >(QUERY_KEY, getBrands)

  return {
    brandListQuery,
  } as const
}