import { QueryFunctionContext, useQuery } from 'react-query'
import { isNotNil } from 'shared/lib/checkers'

import { GoodsProduct } from './models'

import { api } from '../../base'

const getGoodsProductsQueryKey = (goodsId?: UniqueId) =>
  ['get', 'pim/goods/products', goodsId] as const

type GetProductsQueryKey = ReturnType<typeof getGoodsProductsQueryKey>

function getGoodsProducts(goodsId?: UniqueId) {
  return api.get<Array<GoodsProduct>>(
    `/pim/goods/${goodsId}/products`,
  )
}

const fetchGoodsProducts = (context: QueryFunctionContext<GetProductsQueryKey>) =>
  getGoodsProducts(context.queryKey[2]).then((res) => res.data)

export const useGoodsProductsQuery = (goodsId?: UniqueId) =>
  useQuery<
    Array<GoodsProduct>,
    errorType,
    Array<GoodsProduct>,
    GetProductsQueryKey
  >(
    getGoodsProductsQueryKey(goodsId),
    fetchGoodsProducts, {
      enabled: isNotNil(goodsId)
    }
  )
