import { Button, Box } from '@mui/material'
import { BottomButtonWrapper } from 'shared/ui/goods/common'
import { GoodsChangelogTable } from 'features/goods/changelog'

export const ChangelogStep = ({ handlePrev }: { handlePrev: () => void }) => (
  <>
    <Box mb={2}>
      <GoodsChangelogTable />
    </Box>
    <BottomButtonWrapper>
      <div>
        <Button color="primary" onClick={handlePrev}>
          НАЗАД
        </Button>
      </div>
    </BottomButtonWrapper>
  </>
)
