import { useQuery } from 'react-query'

import { Category } from './models'

import { api } from '../../base'

export const getCategoriesTreeQueryKey = () =>
  ['get', 'pim/categories/tree'] as const

type GetCategoriesTreeQueryKey = ReturnType<typeof getCategoriesTreeQueryKey>

const getCategoriesTree = () => api.get<Category[]>('/pim/categories/tree').then(res => res.data)

export const useGetCategoriesTreeQuery = () =>
  useQuery<
    Category[],
    errorType,
    Category[],
    GetCategoriesTreeQueryKey
  >(
    getCategoriesTreeQueryKey(),
    getCategoriesTree,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )