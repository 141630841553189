import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export const findArea = (
  tree: any,
  targetReportId: number,
  path?: Array<string>
) => {
  const defaultPath = path || []
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (node.id.toString() === targetReportId) {
      return defaultPath
    }
    if (node.children && isNotEmptyArray(node.children)) {
      const desiredNode = findArea(node.children, targetReportId, defaultPath)
      if (desiredNode) {
        defaultPath.push(node.id.toString())
        return findArea(node.children, targetReportId, defaultPath)
      }
    }
  }
  return false
}