import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { Avatar, Paper } from '@mui/material'

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const PaperStyle = styled(Paper)`
  box-shadow: 0 0 0 1px #E4E4E4;
`
export const EditPhoto = styled.div`
  width: 30px;
  height: 30px;

  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 2px 5px grey;
  display: none;
  position: absolute;
  top: 60px;
  left: 62px;
`

export const CompanyImg = styled(Avatar)<{ $inArchive?: boolean}>`
  border-radius: 4px;
  width: 96px;
  height: 96px;
  filter: ${({ $inArchive }) => $inArchive ? 'grayscale(100%)' : 'grayscale(0%)'};
  opacity: ${({ $inArchive }) => $inArchive ? '0.5' : '1'};
  background-color: #FFFFFF;
  position: relative;
  cursor: pointer;
  &:hover {
    ${EditPhoto} {
      display: block;
    }
  }
  }
`

export const InArchive = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin: 4px 60px 0 0;
  background-color: #ED6C02;
  color: #FFFFFF;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  padding: 0 8px;
`
