import styled from 'styled-components'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)<{ $expand: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > span {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .MuiPaper-root {
    display: flex;
    flex-direction: column;


    .filter-container {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      display: flex;
      flex-direction: row;
      align-items: start;
      padding-left: 24px;
      gap: 24px;

      .searchInput {
        width: 190px;
      }
      .MuiFormControlLabel-root {
        margin-top: 8px;
        width: 160px;
        .MuiSwitch-root {
          margin-right: 8px;
        }
        .MuiFormControlLabel-label {
          white-space: nowrap;
          margin-right: 16px;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .filters-wrap-container {
        .MuiAutocomplete-inputRoot {
          height: 36px;
          padding-bottom: 3px;
          padding-top: 1px;
          padding-right: 30px;
        }

        .MuiFormLabel-root {
          top: -2px;
          position: absolute;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;

      .tree-container {
        width: 20%;
        max-width: 280px;
        display: ${ ({ $expand }) => $expand ? 'flex' : 'none' };
        border-right: 1px solid rgba(224, 224, 224, 1);
        flex-direction: column;

        .tree-header {
          display: flex;
          align-items: center;
          padding-left: 36px;
          width: 100%;
          height: 56px;
          background-color: rgba(247, 247, 247, 0.5);
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          font-size: 14px;
          font-weight: 500;
        }

        .MuiTreeItem-content {
          height: 32px;

          svg, span {
            color: rgba(0, 125, 255, 1);
          }
        }
        
      }

      .table-container {
        position: relative;
        width: ${ ({ $expand }) => $expand ? '80%' : '100%' };
        flex: 1;

        thead {
          height: 56px;
        }

        .MuiToolbar-root {
          display: none;
        }

        .MuiTableCell-head, .MuiTableCell-root {
          padding-left: 16px;
          padding-right: 16px;
        }

        .TableCell-cell:first-of-type {
          padding-left: 16px;
        }

        .CellLayout-cell:first-of-type {
          padding-left: 16px;
        }
        
        .Pager-pager {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
        
        
        .TableContainer-root:has(.TableNoDataCell-cell) {
          overflow-x: hidden;
        }
        
        .table-component-wrapper:has(.TableNoDataCell-cell),.Layout-root:has(.TableNoDataCell-cell) {
          height: 100%;
        }
        
        .TableContainer-root:has(.TableNoDataCell-cell) {
          >div {
            height: 100%;
            
            table {
              height: 100%;
              
              td {
                pointer-events: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
`

export const ModalWrapper = styled.div`
  padding: 24px;
  
  .MuiDialogTitle-root {
    padding: 0;
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .table-header {
    display: flex;
    flex-direction: row;
    box-shadow: 0 -1px 0 0 rgba(224, 224, 224, 1) inset;
    
    >div {
      height: 56px;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 500;
      align-content: center;
    }
  }
  
  .table-rows-container {
    display: flex;
    flex-direction: column;
    
    .row-item {
      height: 52px;
      display: flex;
      flex-direction: row;
      box-shadow: 0 -1px 0 0 rgba(224, 224, 224, 1) inset;
      
      >div {
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-size: 14px;
        
        >span {
          line-height: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%; 
          word-break: break-all;
          overflow: hidden;
        }


        >svg {
          cursor: pointer;
          color: rgba(0, 125, 255, 1);
        }
        
        .MuiInputBase-root {
          height: 35px;
        }
        
        .MuiAutocomplete-root {
          .MuiOutlinedInput-input {
            padding-top: 3px;
          }
          .MuiOutlinedInput-root {
            height: 28px;
            padding: 0 8px;
          }
        }
        
      }
    }
  }
  
  .MuiDialogActions-root {
    margin-top: 24px;
    padding: 0;
  }
`