import styled from 'styled-components'
import { Box, Grid, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { isNotNil } from 'shared/lib/checkers/isNil'
import { Field } from 'shared/ui/components/form'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { pimApi } from 'shared/api'
import { AutocompleteCatalog } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalog'
import { contragentsCatalogSort } from 'shared/lib/dictionary'
import React, { useCallback, useMemo } from 'react'
import { Option } from 'shared/ui/components/interface'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { InputField } from 'shared/ui/components/form/GoodsInputField/GoodsInputField'
import { getIsExistOnServer } from 'shared/lib/checkers/isExistOnServer'
import { useField } from 'react-final-form'

import { contragentCatalogFilterPredicate } from '../lib/filter'
import { useGoodsSupplier } from '../model/hooks'


interface IInfoStepValues {
  suppliers?: Array<pimApi.goods.GoodsSupplier>
  id?: UniqueId
  categoryId?: {
    fullPathName: string;
    id: UniqueId
  }
  goodsType?: pimApi.goods.GoodsType
}

interface SuppliersProps {
  isGoodsExist: boolean
  name: string
  index: number
  values: IInfoStepValues
  fields: FieldArrayRenderProps<
    pimApi.goods.GoodsSupplier & { meta?: pimApi.dictionaries.Contragent },
    HTMLElement
  >['fields']
  disabled?: boolean
  readOnly?: boolean
}

const catalogMapper = (el: pimApi.dictionaries.Contragent) => ({
  label: el.shortName!,
  value: el.id
})

export const StyledDelete = styled(Delete)`
  color: #BDBDBD;
`


export const Suppliers = ({ name, index, values, fields, isGoodsExist, disabled, readOnly }: SuppliersProps) => {
  const { create, update, remove } = useGoodsSupplier()
  const suppliers = values.suppliers ? values.suppliers.filter(isNotNil) : []
  const predicate = contragentCatalogFilterPredicate(suppliers)
  const catalogFilterPredicate = (el: { id: UniqueId, fullName: string }) => {
    const isPredicated = predicate(el)
    const isUnique = !suppliers.some(supplier => supplier?.id === el.id)

    return isPredicated && isUnique
  }
  const { meta } = useField(name)

  const currentSupplier = fields.value[index]
  const isCurrentSupplierExistedOnServer = useMemo(() => getIsExistOnServer(currentSupplier), [currentSupplier.id])

  const handleRemoveSupplier = () => {
    if (isCurrentSupplierExistedOnServer && currentSupplier.id && currentSupplier.versionNo) {
      remove(
        values.id as UniqueId,
        {
          id: currentSupplier.id,
          versionNo: currentSupplier.versionNo
        },
        () => fields.remove(index)
      )
    } else {
      fields.remove(index)
    }
  }

  const handleChange = useCallback((newContragent: Option<number>) => {
    if (isGoodsExist) {
      create(
        values.id as UniqueId,
        {
          contragentId: newContragent.value,
          incomingPrice: currentSupplier.incomingPrice,
          rrp: currentSupplier.rrp
        },
        (newSupplier) => {
          fields.update(index, {
            ...currentSupplier,
            id: newSupplier.id,
            versionNo: newSupplier.versionNo,
            contragent: newSupplier.contragent,
            contragentId: newSupplier.contragentId,
          })
        }
      )
    } else {
      fields.update(index, {
        ...currentSupplier,
        contragent: newContragent.label,
        contragentId: newContragent.value,
      })
    }
  }, [currentSupplier?.incomingPrice])

  const handleIncomingPriceBlur = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCurrentSupplierExistedOnServer) return

    const incomingPrice = parseFloat(target.value)

    if(currentSupplier.id && currentSupplier.versionNo) {
      update(
        values.id as UniqueId,
        {
          id: currentSupplier.id,
          incomingPrice,
          versionNo: currentSupplier.versionNo
        },
        (editedSupplier) => {
          fields.update(index, {
            ...currentSupplier,
            id: editedSupplier.id,
            versionNo: editedSupplier.versionNo
          })
        }
      )
    }
    
  }, [currentSupplier, isCurrentSupplierExistedOnServer])

  const handleRRPBlur = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCurrentSupplierExistedOnServer) return

    const rrp = parseFloat(target.value)

    if(currentSupplier.id && currentSupplier.versionNo) {
      update(
        values.id as UniqueId,
        {
          id: currentSupplier.id,
          rrp,
          versionNo: currentSupplier.versionNo
        },
        (editedSupplier) => {
          fields.update(index, {
            ...currentSupplier,
            id: editedSupplier.id,
            versionNo: editedSupplier.versionNo
          })
        }
      )
    }

  }, [currentSupplier, isCurrentSupplierExistedOnServer])

  return (
    <Grid container={true} spacing={2} mb={2}>
      <Grid item={true} xs={true}>
        <InputWrapper>
          <AutocompleteCatalog
            catalog="pim/contragents"
            catalogMapper={catalogMapper}
            catalogSort={contragentsCatalogSort}
            catalogFilterPredicate={catalogFilterPredicate}
            placeholder="Поставщик"
            disabled={isCurrentSupplierExistedOnServer || disabled}
            value={{
              label: currentSupplier?.contragent ?? currentSupplier?.meta?.fullName ?? '',
              value: currentSupplier?.id
            }}
            onChange={handleChange}
            error={meta.invalid && meta.touched}
            readOnly={readOnly}
          />
        </InputWrapper>
      </Grid>
      <Grid item={true} xs={2}>
        <Field
          name={`${name}.incomingPrice`}
          label="Входящая цена, р."
          type="number"
          component={InputField}
          onBlur={handleIncomingPriceBlur}
          disabled={disabled}
          InputProps={{
            readOnly
          }}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <Field
          name={`${name}.rrp`}
          label="РРЦ, р."
          type="number"
          helpText="Рекомендуемая розничная цена"
          component={InputField}
          onBlur={handleRRPBlur}
          disabled={disabled}
          InputProps={{
            readOnly,
          }}
        />
      </Grid>
      {!readOnly && 
        <Grid item={true} xs="auto">
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="delete" onClick={handleRemoveSupplier} size="medium" disabled={disabled}>
              <StyledDelete />
            </IconButton>
          </Box>
        </Grid>
      }
    </Grid>
  )
}
