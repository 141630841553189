import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const Title = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.688%;
`

export const Subtitle = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
`
export const StyledCompetitorItem = styled.div`
  display: flex;
  width: 536px;
  height: 80px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`

export const ProductImage = styled.div<{ $photoBase64?: boolean }>`
  position: relative;
  min-width: 80px;
  min-height: 78px;
  max-width: 80px;
  max-height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEAEA;
  img {
    width: ${({ $photoBase64 }) => $photoBase64 ? '80px' : '60px'};
    height: ${({ $photoBase64 }) => $photoBase64 ? '78px' : '60px'};
    object-fit: cover;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  > div:first-child{
    height: 80px;
    width: 80px;
  }
`

export const CompetitorsContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`

export const StyledLoader = styled(CircularProgress)`
  position: relative;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const CompetitorName = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.15px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: #007DFF;
`

export const ShortInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    height: 10px;
    padding: 0 5px;
    display: flex;
    font-size: 12px;
    border-left: 1px solid rgba(0, 0, 0, 0.60);
    align-items: center;
    
    :first-child {
      padding: 0 5px 0 0;
      border-left: none;
    }
    
    :last-child {
      padding: 0 0 0 5px;
    }
  }
`

export const InputTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    margin-left: 0;
    bottom: -22px;
  }
  
  .MuiFormControlLabel-root {
    width: 171px;
    height: 28px;
    margin-left: 0;
    margin-right: 0;
    .MuiCheckbox-root {
      padding: 0;
      margin-right: 9px;
    }
    .MuiFormControlLabel-label {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      .MuiCheckbox-root {
        padding: 0;
      }
    }
  }

  .description-container {
    min-width: 27px;
    max-width: 27px;
    display: flex;
  }
`

export const FormWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 48px;
    
    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      label {
        font-size: 14px;
        margin-right: 0;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      color: #000000DE;
      margin-right: 0;
    }
    
    > div {
      height: min-content;
      width: 55%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 16px;

      .settings-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 16px;

        .full-width-container {
          width: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: calc(50% - 8px);
        }
      }

      .full-width-container {
        margin-bottom: 0;
        width: 100%;
      }

      .submit-cancel-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: end;

        button {
          height: 32px;
        }
      }
    }
  }
`

