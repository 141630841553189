import * as ROUTES from 'shared/config/routes'
import { getBulkOperationsPath } from 'shared/config/routes'
import { Review } from 'shared/lib/generated/review/Api'

import { isNil, isNotNil } from '../../../lib/checkers'
import { privilegeCheck } from '../../../api/base'

interface IRouteItem {
  title?: string
  href?: string
  icon: string
  privilegeRequired?: Object
}

interface IParentRoute extends Omit<IRouteItem, 'icon'> {
  icon?: string
  child: IRouteItem[]
}

export const routesList: () => IParentRoute[] = () => filterSidebarRoutes([
  {
    child: [
      {
        title: 'Верстак',
        href: ROUTES.HOME,
        icon: 'home'
      },
    ]
  },
  {
    title: 'Торговля',
    href: '/',
    icon: 'ShoppingCart',
    child: [
      {
        title: 'Каталог товаров',
        href: ROUTES.GOODS_LIST,
        icon: 'goodsList'
      },
      {
        title: 'Каталог продуктов',
        href: ROUTES.PRODUCT_LIST,
        icon: 'productList'
      },
      {
        title: 'Массовые операции',
        href: getBulkOperationsPath('products'),
        icon: 'bulkOperations'
      },
      {
        title: 'Заказы поставщикам',
        href: ROUTES.SUPPLY_ORDER_LIST,
        icon: 'supplyOrders'
      },
      {
        title: 'История цен',
        href: ROUTES.PRICE_HISTORY,
        icon: 'priceHistory'
      },
      {
        title: 'Отзывы и вопросы',
        href: ROUTES.REVIEWS,
        privilegeRequired: Review.ReviewGetReviews.XPrivilegeCheck,
        icon: 'reviews'
      },
    ],
  },
  {
    title: 'Логистика',
    icon: 'Box',
    child: [
      {
        title: 'Приход на склад',
        href: ROUTES.DOC_LIST,
        icon: 'docList'
      },
      {
        title: 'Задания на упаковку',
        href: ROUTES.COPACKING_ORDERS,
        icon: 'copackingOrders'
      },
      {
        title: 'Товары в поставке',
        href: ROUTES.STOCKS_GOODSSUPPLYING,
        icon: 'stocksGoodsSupplying'
      },
      {
        title: 'Склад',
        href: ROUTES.WMS,
        icon: 'warehouse'
      }
    ],
  },
  {
    title: 'Аналитика',
    icon: 'Analytics',
    child: [
      {
        title: 'Графики',
        href: ROUTES.ANALYTICS_CHARTS,
        icon: 'charts'
      },
      // {
      //   title: 'Кампании',
      //   href: ROUTES.ANALYTICS_ACTIVITIES,
      //   icon: 'activity'
      // },
      {
        title: 'Волшебник цен',
        href: ROUTES.ANALYTICS_PRICE_ADVICER,
        icon: 'priceWizard'
      },
      {
        title: 'Отчеты',
        href: ROUTES.REPORTS,
        icon: 'reports'
      },
    ]
  },
  {
    title: 'Настройки',
    icon: 'Settings',
    child: [
      {
        title: 'Администрирование',
        href: ROUTES.ADMIN_PANEL,
        icon: 'adminPanel'
      },
      {
        title: 'Профиль',
        href: ROUTES.SETTINGS_PROFILE,
        icon: 'settingsProfile'
      },
    ],
  },
])

function filterSidebarRoutes(array) {
  return array.reduce((prev, curr) => {
    const child = filterSidebarRoutes(curr.child || [])
    if (
      isNil(curr.privilegeRequired) ||
      (isNotNil(curr.privilegeRequired) && privilegeCheck(curr.privilegeRequired)) ||
      child.length) prev.push({ ...curr, ...child.length && { child } })
    return prev
  }, [])
}
