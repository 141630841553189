import React, { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SearchIcon from '@mui/icons-material/Search'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import { FilterWrapper, SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

const sortOptions = [
  {
    label: 'По возрастанию цены',
    value: 0,
    sort: 'price',
    order: 'asc'
  },
  {
    label: 'По убыванию цены',
    value: 1,
    sort: 'price',
    order: 'desc'
  },
  {
    label: 'По возрастанию остатков',
    value: 2,
    sort: 'stocks',
    order: 'asc'
  },
  {
    label: 'По убыванию остатков',
    value: 3,
    sort: 'stocks',
    order: 'desc'
  },
]

export const TableFilters = () => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })

  const { data: brandListQuery } = pimApi.dictionaries.useBrandListQuery()
  const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { contragentListQuery } = pimApi.dictionaries.useContragentList({ onlySuppliers: true })
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(searchObj.marketplaceId, searchObj.companyId)
  
  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery]
  )
  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data])
  
  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const brandOptions = useMemo(
    () => brandListQuery?.map(getOptions) || [],
    [brandListQuery]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptions) || [],
    [contragentListQuery.data])

  return (
    <>
      <FilterWrapper>
        <TextField
          className="searchInput"
          size="small"
          id="searchInput"
          variant="standard"
          autoComplete="off"
          placeholder="Поиск"
          defaultValue={searchObj.searchString}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.getElementById('searchInput')?.blur()
            }
          }}
          onBlur={(e) => {
            handleChangeParams({
              params: {
                searchString: e.target.value,
                limit: null,
                page: 0
              },
              options: {
                skipEmptyString: true,
                skipNull: true
              }
            })
          }}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
          }}/>
        <StyledFilterContainer>
          <SelectWrapper width={200}>
            <Autocomplete
              options={sortOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={sortOptions.find(el => (searchObj.sort === el.sort) && (searchObj.order === el.order)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({
                  params: {
                    sort: value?.sort,
                    order: value?.order
                  },
                  options: {
                    skipNull: true,
                    skipEmptyString: true } })
              }}
              disableClearable={!searchObj.order}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Сортировка"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SwapVertIcon sx={{ color: '#BDBDBD' }}/>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={marketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={marketplaceOptions.find(el => (searchObj.marketplaceId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({ params: { marketplaceId: value?.value }, options: { skipNull: true } })
              }}
              disableClearable={!searchObj.marketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Маркетплейс"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companyOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={companyOptions.find(el => (searchObj.companyId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({ params: { companyId: value?.value }, options: { skipNull: true } })
              }}
              disableClearable={!searchObj.companyId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Продавец"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companymarketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disabled={companymarketplaceOptions.length === 0}
              disableCloseOnSelect={true}
              size="small"
              value={companymarketplaceOptions.find(el => (searchObj.companymarketplaceId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({ params: { companymarketplaceId: value?.value }, options: { skipNull: true } })
              }}
              disableClearable={!searchObj.companymarketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Кабинет"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={brandOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={brandOptions.find(el => (searchObj.brandId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({ params: { brandId: value?.value }, options: { skipNull: true } })
              }}
              disableClearable={!searchObj.brandId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Бренд"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={180}>
            <Autocomplete
              options={contragentOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={contragentOptions.find(el => (searchObj.supplierId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                handleChangeParams({ params: { supplierId: value?.value }, options: { skipNull: true } })
              }}
              disableClearable={!searchObj.supplierId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик"/>
              )}
            />
          </SelectWrapper>
        </StyledFilterContainer>
      </FilterWrapper>
    </>
  )
}