
import Autocomplete from '@mui/material/Autocomplete'
import { TextField, Popper } from '@mui/material'
import styled from 'styled-components'

export const AutocompleteStyled = styled(Autocomplete)`
width: 100%;
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #007dff;
}

.Mui-focused.MuiOutlinedInput-root {
  box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
}
&.Mui-focused + button {
  right: calc(100% - 50px);
}


`
export const Item = styled.li`
display: flex;
align-items: center;
width: 100%;
padding: 11px 16px;
padding-left: 44px !important;
`
export const Wrapper = styled.div`
width: 100%;
position: relative;
display: flex;
&:hover {
  button {
    color: #007dff;
  }
}
`
export const SearchButton = styled.button<{ $isActive?: boolean }>`
background: transparent;
border: none;
position: absolute;
transition: 0.3s;
display: flex;
align-items: center;
height: 100%;
cursor: pointer;
right: ${(props) => (props.$isActive ? 'calc(100% - 50px)' : '9px')};
`

export const ItemSpan = styled.span<{ $isActive?: boolean }>`
display: inline-block;
color: ${(props) => (props.$isActive ? '#000' : 'rgba(0, 0, 0, 0.54)')};
&:after {
  content: '/';
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.54);
}
&:last-child:after {
  content: '';
}
`

export const PopperStyled = styled(Popper) <any>`
.MuiPaper-elevation1 {
  box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  margin-top: 0;
}
.MuiAutocomplete-listbox {
  padding-top: ${(props) => (props.$isActive ? '60px' : '0')};
  li[aria-selected='true'] {
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 10px;
    background: #fff;
  }
}
`
export const ActiveIconWrapper = styled.div`
position: absolute;
left: 15px;
`

export const ActiveRemove = styled.button`
  display: inline-flex;
  position: absolute;
  right: 15px;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  z-index: 10;
  background: transparent;
  border:none;
  cursor: pointer;
  > svg {
    margin-left: 5px;
  }
`

export const TextFieldStyled = styled(TextField)`
.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
  transition: 0.3s;
  padding-left: 6px;
}
.Mui-focused{
  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
  padding-left: 44px;
}
`