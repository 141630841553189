import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  
  table {
    min-width: 960px !important;
  }

  .MuiTableCell-head {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiTableCell-body {
    padding-left: 0;
    padding-right: 0;
  }

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }
  
  .TableCell-cell {
    height: 50px;
  }
  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
  
  .Toolbar-toolbar {
    padding: 0;
    justify-content: space-between;  
    border: none;
  }
    
  .TableContainer-root {
    border: 1px solid #E0E0E0;
    border-radius: 4px 4px 0 0 ;
    border-bottom: none;
  }
  
  .Pager-pager {
    border: 1px solid #E0E0E0;
    border-radius: 0 0 4px 4px;
    border-top: none;
    background-color: rgba(247,247,247,0.5);
    height: 50px;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const ToolBar = styled.div`
  position: absolute;
  top: 18px;
  right: 0;
  z-index: 1;
`

export const TableWrapper = styled.div`

`
