/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  reviewId: number
}>

export const apiFunction = ({ reviewId }: IRequest) =>
  api.patch<ResponseType>(`/review/review/${reviewId}/view`).then(res => res.data)


export const useViewReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

