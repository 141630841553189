import { memo } from 'react'
import { customerApi, pimApi } from 'shared/api'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { getUserPhotoQueryKey } from 'shared/api/customer/admin'
import { snackActions } from 'shared/lib/react/snackbar'
import { Avatar } from 'shared/ui/components/Avatar'


interface AvatarProps {
  values: pimApi.admin.CurrentUser
  isClosed: boolean
}


export const UserAvatar = memo(({ values, isClosed }: AvatarProps) => {
  const { id } = useParams<{ id: string }>()
  const userId = parseInt(id, 10)
  const queryClient = useQueryClient()
  
  const { data: userPhoto } = customerApi.admin.useGetUserPhotoQuery(userId)
  const { mutate: deletePhoto } = customerApi.admin.useDeletePhotoUser()
  const { mutate: addPhoto } = customerApi.admin.useAddAdminUserPhoto()

  const handleDeletePhoto = () => {
    const emptyBlob = new Blob([], { type: 'image/jpeg' })
    deletePhoto({
      userId
    }, {
      onSuccess: () => {
        queryClient.setQueryData(getUserPhotoQueryKey(userId), { data: emptyBlob })
        snackActions.info('Аватар пользователя удалён')
      },
    })
  }

  const handleUploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const formData = new FormData()
      formData.append('users_photo', event.target.files[0] || '')
      addPhoto({
        userId,
        file: formData
      }, {
        onSuccess: () => {
          if (event.target.files?.[0]) {
            const file = event.target.files[0]
            file.arrayBuffer().then((arrayBuffer) => {
              const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type })
              queryClient.setQueryData(getUserPhotoQueryKey(userId), { data: blob })
              // eslint-disable-next-line no-param-reassign
              event.target.value = ''
            })
          }
          snackActions.info('Аватар пользователя изменен')
        },
      })
    }
  }
  
  return (
    <Avatar
      disabled={isClosed}
      altName={values.name}
      uploadPhoto={handleUploadAvatar}
      deletePhoto={handleDeletePhoto}
      photo={userPhoto?.data}/>
  )}
)
