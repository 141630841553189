import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { CloseHeaderBtn } from 'entities/pim/MediaFilePreview/ui/MediaPreviewDialogContent/styled'
import { isNil } from 'shared/lib/checkers'


export const MediaModal = ({ close, image, imageList }: { close: () => void, image: string, imageList: Array<string> }) => {
  const [ selectedImage, setSelectedImage ] = useState(image)
  
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'ArrowRight') {
        setSelectedImage(prev => {
          const mediaItemIndex = imageList.findIndex(el => el === prev)
          if (isNil(imageList[mediaItemIndex + 1])) {
            return imageList[0]
          }
          return imageList[mediaItemIndex + 1]
        })
      }
      if (event.key === 'ArrowLeft') {
        setSelectedImage(prev => {
          const mediaItemIndex = imageList.findIndex(el => el === prev)
          if (isNil(imageList[mediaItemIndex - 1])) {
            return imageList[imageList.length - 1]
          }
          return imageList[mediaItemIndex - 1]
        })
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  return (
    <div style={ { display: 'flex', flexDirection: 'column' } }>
      <CloseHeaderBtn
        aria-label="close-modal"
        onClick={ close }
        size="small"
      >
        <CloseIcon/>
      </CloseHeaderBtn>
      <img src={ selectedImage } alt=""/>
    </div>
  )
}