import { AxiosResponse } from 'axios'
import {
  Box,
  Typography,
  Autocomplete,
  TextField, Divider
} from '@mui/material'
import { history } from 'app/providers/with-router'
import { pimApi } from 'shared/api'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { downloadFile } from 'shared/lib/utils'
import React, { memo, useMemo, useState } from 'react'
import { getOptions } from 'shared/ui/components/ArchiveModal/ui/ArchiveModalFormRender'
import { Accordion } from 'shared/ui/components/Accordion'
import { useCompanyMarketplaceList, useContragentList } from 'shared/api/pim/dictionaries'
import { getOptionsContragent } from 'pages/goods/GoodList/ui/Filters/normalizers'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'

interface QueryParametersTypes {
  marketplaceId?: number
  companyId?: number
  companymarketplaceId?: number
  supplierId?: number
  brandId?: number
}

interface AccordionVariables {
  marketplace?: boolean
  company?: boolean
  supplier?: boolean
  brand?: boolean
}
export const BulkProductParamsEdit = memo(() => {
  const [queryParameters, setQueryParameters]= useState<QueryParametersTypes>({})
  const [openedAccordions, setOpenedAccordions] = useState<AccordionVariables>({})

  const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { companyMarketplaceListQuery } =
    useCompanyMarketplaceList(queryParameters.marketplaceId || null, queryParameters.companyId || null)
  
  const { brandListQuery } = pimApi.dictionaries.useBrandList()
  const { contragentListQuery } = useContragentList({ onlySuppliers: true })
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()

  const { mutate: downloadMutation, isLoading: isDownloading } = pimApi.products.useDownloadProductsMassParamsEditTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } = pimApi.products.useUploadProductsMassParamsEditFile()

  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery]
  )

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data])
  
  const brandOptionsList = useMemo(
    () => brandListQuery.data?.map(getOptions) || [],
    [brandListQuery.data])

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptionsContragent) || [],
    [contragentListQuery.data])

  const download = () => {
    downloadMutation({ 
      marketplaceId: openedAccordions.marketplace ? queryParameters.marketplaceId : undefined,
      companyId: openedAccordions.company ? queryParameters.companyId : undefined,
      supplierId: openedAccordions.supplier ? queryParameters.supplierId : undefined,
      companymarketplaceId: openedAccordions.company ? queryParameters.companymarketplaceId : undefined,
      brandId: openedAccordions.brand ? queryParameters.brandId : undefined
    },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
            decodeURIComponent(response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ))
        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const blankTemplateDownload = () => {
    downloadMutation({
      blankTemplate: true
    },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName = decodeURIComponent(response.headers['content-disposition']?.replace('inline; filename=', ''))
        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
      },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box>
      <Typography fontSize="20px" fontWeight={400} lineHeight="160%">
        Изменение внутренних параметров продуктов
      </Typography>
      <Box mt={4}>
        <Box mt={3}>
          <Accordion
            sx={{ width: 'auto', marginTop: '24px' }}
            title="Маркетплейс"
            onOpen={() => {
              setOpenedAccordions(prev => ({ ...prev, marketplace: true }))
            }}
            onClose={() => {
              setOpenedAccordions(prev => ({ ...prev, marketplace: false }))
            }}
          >
            <Autocomplete
              options={marketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              sx={{ width: '437px' }}
              size="small"
              value={marketplaceOptions.find(el => (queryParameters.marketplaceId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, marketplaceId: value?.value }))
              }}
              disableClearable={!queryParameters.marketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Маркетплейс"/>
              )}
            />
          </Accordion>
        </Box>
        <Box mt={2}>
          <Accordion
            sx={{ width: 'auto', marginTop: '24px' }}
            title="Продавец"
            onOpen={() => {
              setOpenedAccordions(prev => ({ ...prev, company: true }))
            }}
            onClose={() => {
              setOpenedAccordions(prev => ({ ...prev, company: false }))
            }}
          >
            <Box sx={
              { position: 'absolute',
                top: '3px',
                left: '533px',
                display: 'flex' }
            }>
              <Typography lineHeight={2.6} sx={{ color: '#000000DE' }}>
                Кабинет
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '48px' }}>
              <Autocomplete
                options={companyOptions}
                noOptionsText="Нет фильтров"
                clearOnBlur={false}
                sx={{ width: '437px' }}
                size="small"
                value={companyOptions.find(el => (queryParameters.companyId === el.value)) || { label: '', value: 0 }}
                isOptionEqualToValue={isOptionEqual}
                disableClearable={!queryParameters.companyId}
                onChange={(event, value) => {
                  setQueryParameters(prev =>({ ...prev, companyId: value?.value }))
                }}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Продавец"/>
                )}
              />
              <Autocomplete
                options={companymarketplaceOptions}
                noOptionsText="Нет фильтров"
                sx={{ width: '437px' }}
                clearOnBlur={false}
                size="small"
                value={companymarketplaceOptions.find(el => (queryParameters.companymarketplaceId === el.value)) || { label: '', value: 0 }}
                isOptionEqualToValue={isOptionEqual}
                onChange={(event, value) => {
                  setQueryParameters(prev =>({ ...prev, companymarketplaceId: value?.value }))
                }}
                disableClearable={!queryParameters.companymarketplaceId}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Кабинет"/>
                )}
              />
            </Box>
          </Accordion>
        </Box>
        <Box mt={2}>
          <Accordion
            sx={{ width: 'auto', marginTop: '24px' }}
            title="Поставщик"
            onOpen={() => {
              setOpenedAccordions(prev => ({ ...prev, supplier: true }))
            }}
            onClose={() => {
              setOpenedAccordions(prev => ({ ...prev, supplier: false }))
            }}
          >
            <Autocomplete
              options={contragentOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              sx={{ width: '437px' }}
              size="small"
              value={contragentOptions.find(el => (queryParameters.supplierId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, supplierId: value?.value }))
              }}
              disableClearable={!queryParameters.supplierId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик"/>
              )}
            />
          </Accordion>
        </Box>
        <Box mt={2}>
          <Accordion
            sx={{ width: 'auto', marginTop: '24px' }}
            title="Бренд"
            onOpen={() => {
              setOpenedAccordions(prev => ({ ...prev, brand: true }))
            }}
            onClose={() => {
              setOpenedAccordions(prev => ({ ...prev, brand: false }))
            }}
          >
            <Autocomplete
              options={brandOptionsList}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              sx={{ width: '437px' }}
              size="small"
              value={brandOptionsList.find(el => (queryParameters.brandId === el.value)) || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setQueryParameters(prev =>({ ...prev, brandId: value?.value }))
              }}
              disableClearable={!queryParameters.brandId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Бренд"/>
              )}
            />
          </Accordion>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" mt={3} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <Divider/>
      <Box mb="28px">
        <BulkFileActionsFooter
          download={download}
          blankTemplateDownload={blankTemplateDownload}
          upload={upload}
          isUploading={isUploading}
          isDownloading={isDownloading}
          onGoBack={handleGoBack}
        />
      </Box>
    </Box>
  )
}
)
