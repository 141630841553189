import { Box, Grid, CircularProgress } from '@mui/material'
import React, { memo, useEffect } from 'react'
import { AlertComponent } from 'entities/pim/product'
import { ozonproxyApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { Field, useField } from 'react-final-form'
import FormInputField from 'shared/ui/components/form/InputField'

import { MemoCardComponent } from './CardComponent'
import OzonInfo from './OzonInfo'

export const ExistingOzonCard = memo(() => {
  const sku = useField('sku').input.value
  const nameChange = useField('name').input.onChange

  const debouncedSearchQuery = useDebounce(sku, 600)
  const { data, isLoading, isError, error } = ozonproxyApi.useFetchProductSkuQuery(debouncedSearchQuery)

  useEffect(() => {
    nameChange(data?.data.title ?? undefined)
  }, [data])

  return (
    <>
      <Box mb={1} mt={2.5}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Поиск карточки</span>
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              component={FormInputField}
              label="Код товара"
              name="sku"
              type="number"
              endAdornment={isLoading ? <CircularProgress /> : undefined}
            />
          </Grid>
        </Grid>
      </Box>
      {isError && (
        <Box mt={1.5}>
          <AlertComponent
            title="Критическая ошибка!"
            message={error?.response?.status === 404 ? 'Товар не найден' : 'Что-то пошло не так, попробуйте другой sku'}
            status="error"
            maxLength={false}
          />
        </Box>
      )}
      {data?.data && (
        <>
          <MemoCardComponent {...data.data} />
          <OzonInfo />
        </>
      )}
    </>
  )
})