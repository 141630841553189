import {
  InputAdornment,
  CircularProgress, IconButton,
} from '@mui/material'
import { useField, useForm } from 'react-final-form'
import { Search } from '@mui/icons-material'
import { noop } from 'shared/lib/lodash/noop'
import { TextInput } from 'shared/ui/components'

import { FormValuesType } from '../types'

interface SearchFieldProps {
  search: (barcodeSeatchStr: string) => void;
  isLoading: boolean;
}

export const SearchField = ({ search, isLoading }: SearchFieldProps) => {
  const supplierCode = useField('supplierCode').input.value
  const { change } = useForm<FormValuesType>()

  const onKeyPressSearch = (event:  React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      // @ts-ignore
      const { value } = event.target
      if(value.length > 1) {
        search(value)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === '') {
      search('-')
    }
    change('supplierCode', event.target.value)
  }

  const handleProductSearch = () => {
    search(supplierCode)
  }

  return (
    <TextInput
      value={supplierCode}
      onBlur={noop}
      onChange={handleChange}

      onKeyPress={onKeyPressSearch}
      onFocus={noop}
      name="supplierCode"
      label="Артикул или штрих-код"
      endAdornment={
        <InputAdornment position="end">
          {isLoading && <CircularProgress size={20} />}
          <IconButton
            onClick={handleProductSearch}
            disabled={!(supplierCode?.length > 1)}
            size="small"
          >
            <Search/>
          </IconButton>
        </InputAdornment>
      }
    />
  )
}