import styled from 'styled-components'

export const AnswerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  
  button {
    height: 36px;
  }
`