import { FC, memo, useCallback, useMemo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { AutocompleteValue } from '@mui/material/useAutocomplete'

export interface CountryProps extends FieldRenderProps<string> {
  companyId: number,
}

const getOptionFromCountry= ({ name }: { name: string }) => ({
  label: name,
  value: name
})
export const Country: FC<CountryProps> = ({ companymarketplaceId, ...props }) => {
  const fetchCatalog = useCallback<(steach: string) => Promise<Array<Option<any>>>>(
    (search: string) => wbproxyApi.fetchCountries(encodeURIComponent(search), companymarketplaceId)
      .then(
        data => data.data.map(
          getOptionFromCountry
        )
      ),
  [companymarketplaceId]
  )

  const handleChange = (value: AutocompleteValue<Option<any>, false, undefined, undefined>) => {
    props.input.onChange(value?.value || null)
  }
  const input = useMemo(() => ({
    ...props.input,
    value: { 
      label: props.input.value,
      value: props.input.value
    }
  }), [props.input])

  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      input={input}
      // @ts-ignore
      onChange={handleChange}
      fetchCatalog={fetchCatalog}
      disableClearable={false}
      error={props.error.toString()}
      noOptionsText="Введите строку для поиска"
    />
  )
}

export const CountryMemo = memo(Country)