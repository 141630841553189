import styled from 'styled-components'
import { EditableInput, TextInput } from 'shared/ui/components'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

export const EditableStyledInput = styled(EditableInput)`
  input {
    color: #007DFF;
    padding: 0;
    margin: 0;
    border-style: none;
    font-size: 0.875rem;
    line-height: 1.43;
  }
`

const reg = new RegExp('^[0-9]+$')

export const CellInput = ({
  onValueChange,
  onBlur,
  onFocus,
  value,
  autoFocus,
  disabled,
}: DataTypeProvider.ValueEditorProps) => {
  const onChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    if (reg.test(event.target.value) || event.target.value === '') {
      onValueChange(event.target.value)
    }
  }

  return (
    <EditableStyledInput> 
      <TextInput
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={onChange}
        name="customImput"
      />
    </EditableStyledInput>
  )
}
