import React, { useCallback } from 'react'
import { pimApi } from 'shared/api'
import { reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { dialogModel } from 'shared/ui/components/dialog'
import { pimGen } from 'shared/lib/generated'
import { AddCompetitorModal } from 'pages/goods/edit/CompetitorsStep/ui/AddCompetitorModal/AddCompetitorModal'

import { Header } from './Header'
import { CompetitorsList } from './CompetitorsList'
import { EmptyCompetitorsPage } from './EmptyCompetitorsPage'

import { StyledLoader } from '../styled'


export const Competitors = ({ commonProduct }: { commonProduct: pimApi.products.common.Product }) => {
  const queryClient = useQueryClient()
  const { data: competitorsData, isLoading } =
    pimGen.product.GetProductCompetitors.useGetProductCompetitors({ productId: commonProduct.productId })
  const { mutate: editCompetitor } = pimGen.product.EditProductCompetitor.useEditProductCompetitor()
  const { mutate: addCompetitor } = pimGen.goods.CreateGoodscompetitors.useCreateGoodscompetitors()

  const handleEditCompetitor = useCallback((competitor: pimApi.goods.Competitor) => {
    editCompetitor({
      productId: commonProduct.productId,
      data: {
        id: competitor.id,
        versionNo: competitor.versionNo,
        reckonedFlag: competitor.reckonedFlag,
      }
    }, {
      onSuccess:
        reactQueryCacheUpdateByKey({
          queryClient,
          withoutData: true,
          queryCacheKey: pimGen.product.GetProductCompetitors.getQueryKey({ productId: commonProduct.productId }),
          onSuccess: () => snackActions.info('Успешно')
        })
    })
  },[])

  const handleAddCompetitor = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <AddCompetitorModal
          close={close}
          accept={accept}
        />
      ),
      onAccept: (newCompetitorObject) => {
        addCompetitor({
          goodsId: commonProduct.goodsId,
          data: {
            url: newCompetitorObject.url,
            marketplaceId: newCompetitorObject.marketplaceId,
            sku: newCompetitorObject.sku,
            analogueFlag: newCompetitorObject.analogueFlag,
            reckonedFlag: true
          }
        }, {
          onSuccess: () => {
            queryClient.refetchQueries(pimGen.product.GetProductCompetitors.getQueryKey({ productId: commonProduct.productId }))
            snackActions.info('Товар конкурента добавлен')
          },
        }
        )
      },
    })
  }

  return (
    <>
      <Header activeCompetitors={commonProduct.activeCompetitors} addCompetitor={handleAddCompetitor}/>
      <>
        {!isLoading ?
          <>
            {isNotEmptyArray(competitorsData) && competitorsData ?
              <CompetitorsList handleEditCompetitor={handleEditCompetitor} competitorsData={competitorsData}/>
              :
              <EmptyCompetitorsPage goodsId={commonProduct.goodsId}/>
            }
          </>
          :
          <StyledLoader size={60} />
        }
      </>
    </>
  )
}