import styled from 'styled-components'

export const ProductNameLink = styled.a<{ adviceDate: string }>`
  color: #007DFF;
  text-decoration: none;
  width: ${p => (p.adviceDate ? '80px' : '100%')};
  overflow: hidden;
  margin-top: 14px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const SkuLink = styled.a`
  color: #007DFF;
  text-decoration: none;
  margin-top: 19px;
  width: fit-content;
`

export const ProductBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`