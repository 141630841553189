import React, { useState, useEffect, memo } from 'react'
import { Grid } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  arrayMove
} from '@dnd-kit/sortable'
import { pimApi } from 'shared/api'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { dialogModel } from 'shared/ui/components/dialog'
import { reactQueryCacheAdd, reactQueryCacheDelete, reactQueryCacheUpdate } from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import {
  CircularProgressStyled,
  CircularProgressWrap,
  ContentsWrapper
} from 'shared/ui/components/CircularProgress/CircularProgress'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { SimpleMediaTypes } from 'shared/types/MediaTypes'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete'
import { MoreVert } from '@mui/icons-material'
import { SettingsMenu } from 'shared/ui/components'
import { PhotoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Simple/photoRequirements'

import { PhotoItem } from './ui/PhotoItem'


const PICTURE_SUPPORTED_FILE_EXTENSION = ['.jpeg', '.jpg', '.webp', '.png']

export const Photos = memo(
  ({
    photos,
    disabled,
  }: {
    photos: Array<pimApi.products.MediaFile>,
    disabled: boolean
  }) => {
    const queryClient = useQueryClient()
    const { id } = useParams<{ id: string }>()
    const productId = parseInt(id, 10)
    const [localPhotos, setPhotos] = useState<Array<pimApi.products.MediaFile>>([])
    const { mutate: bulkImportMediaFromProduct, isLoading: bulkImportMediaInProgress } =
      pimApi.products.common.useMassImportMediaFromProduct()
    const { mutate: createMediaMutation, isLoading: createInProgress } =
      pimApi.products.simple.useCreateProductMediaMutation({
        onSuccess: reactQueryCacheAdd({
          queryClient,
          queryCacheKey: pimApi.products.simple.getSimpleProductQueryKey(productId),
          path: 'photos'
        })
      })
    const { mutateAsync: validateMediaFileMutation } =
      pimApi.products.simple.useValidateProductMediaFileMutation()
    const { mutateAsync: validateMediaUrlMutation } =
      pimApi.products.simple.useValidateProductMediaByURLMutation()
    const {
      mutate: createMediaByURLMutation,
      isLoading: createByURLInProgress,
    } = pimApi.products.simple.useCreateProductMediaByURLMutation({
      onSuccess: reactQueryCacheAdd({
        queryClient,
        queryCacheKey: pimApi.products.simple.getSimpleProductQueryKey(productId),
        path: 'photos'
      })
    })
    const { mutate: updateMediaMutation, isLoading: updateInProgress } =
      pimApi.products.simple.useUpdateProductMediaMutation({
        onSuccess: reactQueryCacheUpdate({
          queryClient,
          queryCacheKey: pimApi.products.simple.getSimpleProductQueryKey(productId),
          path: 'photos'
        }),
      })
    const { mutate: deleteMediaMutation, isLoading: deleteInProgress } =
      pimApi.products.simple.useDeleteProductMediaMutation()

    const { mutate: bulkDeleteMediaMutation, isLoading: bulkDeleteInProgress } =
      pimApi.products.simple.useBulkDeleteProductMediaMutation()



    useEffect(() => {
      setPhotos(photos === undefined ? [] : photos)
    }, [photos])

    const onFileValidate = (mediaType: SimpleMediaTypes) =>
      (file, onSuccess) => {
        if (typeof file === 'string') {
          validateMediaUrlMutation({ url: file, productId, mediaType }).then(res => {
            if (res.status === 200) onSuccess(res)
          })
        } else {
          const formFile = new FormData()
          formFile.append('file', file)
          validateMediaFileMutation({
            productId,
            mediaType,
            file: formFile,
          }).then(res => {
            if (res.status === 200) onSuccess(res)
          })
        }
      }

    const onFileUpload =
      (mediaType: SimpleMediaTypes) =>
        (files) => {
          if (files && isNotEmptyArray(files)) {

            files.forEach(el => {
              if (typeof el.file === 'string') {
                createMediaByURLMutation({ url: el.file, productId, mediaType })
              } else {
                const file = new FormData()

                file.append('file', el.file || '')

                createMediaMutation({
                  productId,
                  mediaType,
                  file,
                })
              }
            })
          }
        }

    const onDragChange = (sourceId, sourceIndex, targetIndex) => {
      const swappedPhotos = arrayMove<pimApi.products.MediaFile>(localPhotos, sourceIndex, targetIndex)
      const editMediaFileList = swappedPhotos.map<pimApi.products.EditMediaFile>(
        (photo: pimApi.products.MediaFile, index) => ({
          id: photo.id,
          versionNo: photo.versionNo,
          indexNumber: index
        })
      )
      setPhotos(swappedPhotos)
      updateMediaMutation(
        {
          productId,
          mediaType: 'photos',
          editMediaFileList
        },
        {
          onError: () => {
            setPhotos(localPhotos)
          }
        }
      )
    }

    const handleDelete = (deletingPhoto: DeletingObject) => {
      dialogModel.openDialog({
        component: MediaDeleteConfirmModal,
        onAccept: () => {
          deleteMediaMutation(
            { productId, mediaType: 'photos', ...deletingPhoto },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.simple.getSimpleProductQueryKey(productId),
                deleteItem: deletingPhoto,
                path: 'photos'
              }),
            }
          )
        },
      })
    }

    const handleBulkDelete = () => {
      dialogModel.openDialog({
        component: ({ accept, close }) => (
          <MediaDeleteConfirmModal title="Удалить все изображения?" accept={accept} close={close}/>
        ),
        onAccept: () => {
          bulkDeleteMediaMutation(
            { productId, mediaType: 'photos', value: localPhotos },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.simple.getSimpleProductQueryKey(productId),
                deleteItem: localPhotos,
                path: 'photos'
              }),
            }
          )
        }
      })
    }
    const handleMassImportMedia = () => {
      bulkImportMediaFromProduct({ productId, marketplaceName: 'simple' },
        {
          onSuccess: () => {
            snackActions.info('Скопировано')
          }
        })
    }

    const isQueriesInProgress =
      createByURLInProgress ||
      createInProgress ||
      updateInProgress ||
      deleteInProgress ||
      bulkDeleteInProgress ||
      bulkImportMediaInProgress

    const settingsOptions: Array<any> = [
      {
        label: 'Импорт в товар',
        icon: <UploadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        divider: true,
        handler: handleMassImportMedia
      },
      {
        label: 'Очистить',
        icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        type: 'error',
        handler: handleBulkDelete
      },
    ]
    return (
      <div>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <span style={{ fontWeight: '500', color: '#000000DE' }}>Фото</span>
                <span style={{ color: '#0000008A' }}>{ localPhotos.length }/30</span>
              </div>
              <SettingsMenu
                className="settingsMenu"
                options={settingsOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                iconSize="small"
                iconType={<MoreVert />}
              />

            </div>
          </Grid>
        </Grid>
        <ContentsWrapper>
          {isQueriesInProgress && (
            <CircularProgressWrap>
              <CircularProgressStyled />
            </CircularProgressWrap>
          )}
          {(localPhotos || isQueriesInProgress) && (
            <UploadGallery
              mediaType="фото"
              mediaLimit={30}
              withMainElement={true}
              supportedFileExtension={PICTURE_SUPPORTED_FILE_EXTENSION}
              addDisabled={disabled || localPhotos.length >= 30}
              localMedia={localPhotos}
              urlUploadingExist={true}
              onFileValidate={onFileValidate('photos')}
              onUploadFile={onFileUpload('photos')}
              mediaRequirement={PhotoRequirements}
              onDragChange={onDragChange}>
              {localPhotos?.map((item, index) => (
                <PhotoItem
                  index={index}
                  item={item}
                  productId={productId}
                  onFileDelete={handleDelete}
                  disabled={disabled}
                />
              ))}
            </UploadGallery>
          )}
        </ContentsWrapper>
      </div>
    )
  }
)
