import { isNotNil } from 'shared/lib/checkers'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export function filterPrivileges(array, fn) {
  return array.reduce((prev, curr) => {
    const privileges = filterPrivileges(curr.privileges || [], fn)
    const groups = filterPrivileges(curr.groups || [], fn)
    if (fn(curr)) {
      prev.push(curr)
    } else if (privileges.length !== 0 || groups.length !== 0) {
      prev.push({ ...curr, privileges: privileges.length !== 0 ? privileges : [], groups: groups.length !== 0 ? groups : [] })
    }

    return prev
  }, [])
}

export function flatPrivileges(arr: Array<any>) {
  const stack: Array<string> = []

  arr.forEach(el => {
    const children = [...el.privileges || [], ...el.groups || []]
    if (isNotNil(children) && isNotEmptyArray(children)) {
      stack.push(el?.id ? el.id.toString() : el.name)
      stack.push(...flatPrivileges(children))
    }
  })

  return stack
}