import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLinkName = styled(Link)`
  color: #007DFF;
  text-decoration: none;
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`