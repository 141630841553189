import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosResponse } from 'axios'

import { toSnakeCase } from '../../../lib/transform'
import { toSnake } from '../../../lib/transform/toSnakeCase'


export const getPriceAdviceQueryKey = (queryParams) =>
  ['get', 'pim/pricecontrol/priceadvice', queryParams] as const

type GetProductPriceAdviceQueryKey = ReturnType<typeof getPriceAdviceQueryKey>
function getPriceAdvice(queryParams): Promise<AxiosResponse<pimApi.price.ProductPriceAdvice[]>> {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  return api.get<pimApi.price.ProductPriceAdvice[]>('/pim/pricecontrol/priceadvice', {
    params: queryParamsSnake
  })
}

export function getPriceAdviceFn(context: QueryFunctionContext<GetProductPriceAdviceQueryKey>) {
  const [, , queryParams] = context.queryKey
  return getPriceAdvice(queryParams).then((res) => res.data)
}

export const useGetPriceAdviceQuery = (queryParams) =>
  useQuery<
    pimApi.price.ProductPriceAdvice[],
    errorType,
    pimApi.price.ProductPriceAdvice[],
    GetProductPriceAdviceQueryKey
  >(
    getPriceAdviceQueryKey(queryParams),
    getPriceAdviceFn, {
      refetchOnMount: false
    }
  )
