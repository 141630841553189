import React from 'react'

import { OzonProduct, SimpleProduct, WbProduct } from './mpVariations'

const MarketplaceComponent = {
  1: WbProduct,
  2: OzonProduct
}
export const CurrentPriceCell = ({ ...props }) => {
  const Component = MarketplaceComponent[props.row.marketplaceId] || SimpleProduct
  return (
    <Component {...props}/>
  )
}