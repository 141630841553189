import { WrapperTabs, ElementLi, TabsButton } from './styled'

import { ICompetitorsTabs } from '../types/types'

export function CompetitorsTabs({
  options,
  currentOption,
  onOptionClick,
  quantities
}: ICompetitorsTabs) {
  return (
    <WrapperTabs>
      {options.map((option) => (
        <ElementLi key={option.value}>
          <TabsButton
            isActive={option.value === currentOption.value}
            disabled={option.disabled}
            onClick={() => onOptionClick(option)}
          >
            {option.label} {quantities[option.value] === undefined ? '' : ` ${ quantities[option.value]}` }
          </TabsButton>
        </ElementLi>
      ))}
    </WrapperTabs>
  )
}
