import { Field } from 'react-final-form'
import { Autocomplete, Box, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { history } from 'app/providers/with-router'
import {
  InputField,
  InputLabel,
} from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'

const Title = styled(Box)`
  font-size: 24px;
  border-bottom: 1px solid #E0E0E0;
  padding: 16px 24px;
`

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiAutocomplete-inputRoot{
    padding-top: 0;
    padding-bottom: 0;
    .MuiAutocomplete-clearIndicator {
      display: none;
    }
  }
`

const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`

const CreateSupplierForm = ({ handleSubmit, isLoading, errors, values }) => {

  const orderGenerationOptions = [
    { label: 'Не кратно упаковке', value: false },
    { label: 'Кратно упаковке', value: true },
  ]

  const handleBack = () => {
    history.goBack()
  }

  const handleCreateSupplier = () => {
    handleSubmit()
  }

  const isDisabledConfirm = () => Object.keys(errors).length !== 0
        || !Object.keys(values).includes('shortName')
        || !Object.keys(values).includes('inn')

  return (
    <form onSubmit={() => {}}>
      <Title>Основная информация</Title>
      <Grid container={true} spacing={2} padding={3} pt={2}>
        <Grid item={true} xs={3}>
          <InputLabel label="Наименование поставщика" required={true}/>
          <HelperTextSupply>
            <Field
              name="shortName"
              id="shortName"
              placeholder="Наименование поставщика"
              component={InputField}
              required={true}
              errorAfterTouch={true}
              fullWidth={true}
              helperText={errors && errors?.shortName ? errors?.shortName : ''}
              // error={!!errors?.shortName}
            />
          </HelperTextSupply>
        </Grid>
       
        <Grid item={true} xs={6}>
          <InputLabel label="Полное наименование" required={false}/>
          <InputWrapper>
            <Field
              name="fullName"
              placeholder="Полное наименование"
              required={false}
              component={InputField}
            />
          </InputWrapper>
        </Grid>

        <Grid item={true} xs={3}>
          <InputLabel label="Код для интеграции" required={false}/>
          <InputWrapper>
            <Field
              name="externalId"
              placeholder="Код для интеграции"
              required={false}
              component={InputField}
            />
          </InputWrapper>
        </Grid>
        
        <Grid item={true} xs={4}>
          <InputLabel label="ИНН" required={true}/>
          <HelperTextSupply>
            <Field
              name="inn"
              id="inn"
              helperText={errors && errors?.inn ? errors?.inn : ''}
              // error={!!errors?.inn}
              placeholder="ИНН"
              component={InputField}
              required={true}
              fullWidth={true}
            />
          </HelperTextSupply>
        </Grid>

        <Grid item={true} xs={4}>
          <InputLabel label="КПП" required={false}/>
          <HelperTextSupply>
            <Field
              name="kpp"
              id="kpp"
              helperText={errors && errors?.kpp ? errors?.kpp : ''}
              error={!!errors?.kpp}
              placeholder="КПП"
              component={InputField}
              required={false}
              fullWidth={true}
            />
          </HelperTextSupply>
        </Grid>

        <Grid item={true} xs={4}>
          <InputLabel label="Формирование заказа" required={false}/>
          <SelectWrapper>
            <Field name="useBoxes"> 
              {({ input }) => (
                <Autocomplete
                  sx={{ width: '100%' }}
                  id="orderIsBoxSelector"
                  onChange={(event, selectedValue) => input.onChange(selectedValue?.value)}
                  defaultValue={orderGenerationOptions[0]}
                  options={orderGenerationOptions} 
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => 
                    <TextField {...params} required={true}/> 
                  } 
                /> 
              ) 
              } 
            </Field>
          </SelectWrapper>
        </Grid>

        <Grid container={true} item={true} xs={12} mt={3} justifyContent="flex-end">
          <LoadingButton
            color="primary"
            variant="text"
            fullWidth={false}
            onClick={handleBack}
            loading={isLoading}
            sx={{ mr: 2 }}
          >
            ОТМЕНА
          </LoadingButton>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleCreateSupplier}
            fullWidth={false}
            loading={isLoading}
            disabled={isDisabledConfirm()}
          >
            СОХРАНИТЬ
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateSupplierForm
