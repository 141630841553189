import { MoreVert } from '@mui/icons-material'
import React from 'react'
import {
  VideoContent, YoutubeLink, DescMedia,
} from 'shared/ui/goods/common'
import { Image, SettingsMenu } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadFileWithPath } from 'shared/lib/utils'

interface VideoItemProps {
  productId: UniqueId
  openGallery?: () => void
  item: pimApi.products.MediaFile
  onCoverDelete: () => void
  disabled?: boolean
}

export const VideoCoverItem = ({ productId, item, onCoverDelete, disabled, openGallery }: VideoItemProps) => {
  const settingsOptions: Array<any> = [
    {
      label: item?.downloadUrl?.length! > 1 ? 'Скачать' : 'Перейти по ссылке',
      divider: true,
      icon: <DownloadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        if (item.downloadUrl) {
          const url = new URL(item.downloadUrl)
          downloadFileWithPath(url.pathname, item.fileName || '')
        }
      }
    },
    {
      label: 'Удалить',
      disabled,
      icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
      type: 'error',
      handler: onCoverDelete
    },
  ]
  const apiUrl = `/pim/products/ozon/product/${productId}/videocover/thumbnail/${item.id}?max_height=500&max_width=500`

  return (
    <>
      <VideoContent onClick={() => {if (openGallery) openGallery()} }>
        <Image src={apiUrl} />
        <SettingsMenu
          className="settingsMenu"
          options={settingsOptions}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          iconSize="small"
          iconType={<MoreVert />}
        />
      </VideoContent>
      <YoutubeLink
        target={item?.downloadUrl?.length! > 1 ? '' : '_blank'} title={item?.fileName}
        href={item?.downloadUrl?.length! > 1 ? item.downloadUrl : item.fileName}>
        <DescMedia>{item?.fileName}</DescMedia>
      </YoutubeLink>
    </>
  )
}
