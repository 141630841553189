import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

const dateFormat = (date) => format(new Date(date), DateFormats.dayMonthYearHoursMinutes)

export const UserDateInCell = ({ values }: { values?: string }) => {
  if (!values) return null
  if (new Date(values) > new Date()) {
    return(
      <>
        <span>Начало доступа {dateFormat(values)}</span>
      </>
    )
  }
  return(
    <>
      <span>{dateFormat(values)}</span>
    </>
  )
}
