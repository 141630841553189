import { create } from 'zustand'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export interface IBrand {
  label: string
  value?: UniqueId
}

interface BrandsState {
  selectedGoods: UniqueId[]
  addGood: (good: UniqueId) => void
  deleteGood: (good: UniqueId) => void
  deleteAllSelectedGoods: () => void
}

export const useBrandquadStore = create<BrandsState>((set) => ({
  selectedGoods: [],
  addGood: (good) =>
    set(({ selectedGoods }) => ({
      selectedGoods: isEmptyArray(selectedGoods) ? Array.of(good) : selectedGoods.concat(good),
    })),
  deleteGood: (good) =>
    set(({ selectedGoods }) => ({
      selectedGoods: selectedGoods.filter((el) => el !== good),
    })),
  deleteAllSelectedGoods: () => set(({ selectedGoods: [] })),
}))
