import { Box, Rating, Tooltip, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import React, { memo } from 'react'
import { pimApi } from 'shared/api'
import imagePlaceholder from 'assets/images/photo-size-select-actual.svg'
import styled from 'styled-components'
import { tooltipClasses } from '@mui/material/Tooltip'
import { COLORS } from 'shared/ui/components/PieChart'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { isNotNil } from 'shared/lib/checkers'

import {
  ResultItem,
  ProductTitle,
  ProductImage,
  BorderedInfo,
  ComponentInfo, Status,
} from './styled'

import { CompetitorsSettings } from '../CompetitorsSettings'

interface CompetitorCardProps {
  competitor: pimApi.goods.Competitor
  handleEditCompetitor: ({ competitor }:{competitor: pimApi.goods.Competitor}) => void
  handleDeleteCompetitor: ({ competitorId, versionNo }) => void
}

interface InfoTexProps {
  title: string
  text?: string | number
  type?: string
  $isDarkened?: boolean
}

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 6px 9px -7px rgba(0, 0, 0, 0.2), 0px 19px 30px 3px rgba(0, 0, 0, 0.14), 0px 9px 16px 8px rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    padding: '12px'
  },
}))

export const CompetitorCard = ({
  competitor,
  handleEditCompetitor,
  handleDeleteCompetitor
}: CompetitorCardProps) => {
  const ImageBase64 = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} alt="competitor_image"/>

  const competitorActive = competitor.status === 1
  const totalStocks = competitor?.stocks?.reduce((sum, elem) => sum + elem.available, 0)

  const priceConverter = ( value ) => {
    if (value) {
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(value).replace(',', '.')
    }
    return ''
  }
  const InfoText = ({ title, text, type, $isDarkened }: InfoTexProps) => {
    if(!text) return null
    if (type === 'sku') {
      return <BorderedInfo $isDarkened={$isDarkened}>
        {title}
        <a href={competitor.url} target="_blank" rel="noreferrer">{text}</a>
      </BorderedInfo>
    }
    return <BorderedInfo $isDarkened={$isDarkened}>{`${title} ${text}`}</BorderedInfo>
  }

  return (
    <>
      {competitor && 
      <ResultItem mb={2} $status={competitorActive}>
        <ProductImage $photoBase64={!!competitor.photoBase64} $status={competitorActive}>
          {competitor.photoBase64
            ? <ImageBase64 data={competitor.photoBase64}/>
            :
            <img src={imagePlaceholder} alt="placeholder"/>
          }
        </ProductImage>
        <ComponentInfo>
          <ProductTitle>{competitor.name}</ProductTitle>
          {
            (competitor.status === -1 || competitor.status === null)
              ? <Box display="flex">
                <InfoText title="" text={competitor.marketplaceName}/>
                <InfoText title="Номенклатура: " text={competitor.sku} type="sku"/>
              </Box>
              : <>
                <Box display="flex">
                  <InfoText title="" text={competitor.marketplaceName}/>
                  <InfoText title="" text={competitor.seller}/>
                  <InfoText title="" text={competitor.brand}/>
                  <InfoText title="Категория: " text={competitor.category}/>
                </Box>

                <Box display="flex">
                  <InfoText title="Номенклатура: " text={competitor.sku} type="sku"/>
                  <InfoText title="" text={ competitor.analogueFlag ? 'Похожий товар' : 'Такой же товар' }/>
                  {totalStocks !== 0 &&
                  <BorderedInfo style={{ display: 'flex', alignItems: 'center', paddingTop: '1px' }}
                    $isDarkened={true}>
                    {`Остатки: ${totalStocks} `}
                    <CustomTooltip title={
                      <Box display="flex" flexDirection="column" gap="5px">
                        {competitor.stocks!.map((el, ind) => (
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography
                              fontSize={14}
                              fontWeight="400"
                              textAlign="left"
                              color="rgba(0, 0, 0, 0.87)"
                            >
                              {el.marketplacewarehouseName}
                            </Typography>
                            <Typography
                              fontSize={14}
                              ml={3}
                              fontWeight="500"
                              color={COLORS[ind + 3 % COLORS.length]}
                            >
                              {el.available}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    }
                    placement="right">
                      <HelpWithMargin style={{ marginLeft: '4px', marginRight: '0' }}>
                        <InfoOutlined />
                      </HelpWithMargin>
                    </CustomTooltip>
                  </BorderedInfo>
                  }
                  <InfoText title="Цена, р.: " text={priceConverter(competitor.price)} $isDarkened={true}/>
                </Box>
              </>
          }
        </ComponentInfo>
        {isNotNil(competitor.rating) &&
        <Box display="flex" alignItems="center">
          <Rating readOnly={true} value={competitor.rating} precision={0.01} size="small"/>
        </Box>
        }
        <Box display="flex" alignItems="center" ml={2}>
          {competitor.status === 1 && <Status $status={competitorActive} >В продаже</Status>}
          {competitor.status === 0 && <Status $status={competitorActive}>Нет в наличии</Status>}
          {(competitor.status === -1 || competitor.status === null) && <Status $status={competitorActive}>Пока не обработан</Status>}
        </Box>
        <Box display="inline-flex" alignItems="center" pl={2} mr={1}>
          <CompetitorsSettings handleEdit={handleEditCompetitor} handleDelete={handleDeleteCompetitor} competitor={competitor}/>
        </Box>
      </ResultItem>
      }
    </>
  )
}

export const MemoCompetitorCard = memo(CompetitorCard)