import { GoodsSupplying } from 'shared/api/trade/stocks'
import { ColumnConfig } from 'shared/ui/components/Table/model/types'

type DataSetColumnName =
  keyof Omit<GoodsSupplying, 'goodsId'>

type ColumnNames = Record<string, DataSetColumnName>
type ColumnsConfig = Array<ColumnConfig<DataSetColumnName>>

export const columns: ColumnNames = {
  code: 'code',
  quantity: 'quantity',
  supplyInfo: 'supplyInfo',
  marketplaceName: 'marketplaceName',
  goodsName: 'goodsName',
  goodsType: 'goodsType',
  hasPhoto: 'hasPhoto',
  marketplacewarehouseName: 'marketplacewarehouseName',
} as const


export const columnsConfig: ColumnsConfig = [
  {
    name: columns.marketplaceName,
    columnName: columns.marketplaceName,
    title: 'Маркетплейс',
    width: 144,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.marketplacewarehouseName,
    columnName: columns.marketplacewarehouseName,
    title: 'Склад маркетплейса',
    width: 160,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.supplyInfo,
    columnName: columns.supplyInfo,
    title: 'Номер поставки',
    width: 172,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.quantity,
    columnName: columns.quantity,
    title: 'Кол-во',
    width: 100,
    sortingEnabled: true,
  },
  {
    name: columns.code,
    columnName: columns.code,
    title: 'Артикул',
    width: 165,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.goodsType,
    columnName: columns.goodsType,
    title: 'Тип',
    width: 60,
    sortingEnabled: false,
    wordWrapEnabled: false,
  },
  {
    name: columns.hasPhoto,
    columnName: columns.hasPhoto,
    title: 'Фото',
    width: 74,
    sortingEnabled: false,
    align: 'center',
  },
  {
    name: columns.goodsName,
    columnName: columns.goodsName,
    title: 'Наименование',
    width: 'auto',
    sortingEnabled: true,
    wordWrapEnabled: true,
  }
]