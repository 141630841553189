import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'

import { WBPriceDetail } from './models'

export const getProductsPriceQueryKey = (productId: UniqueId) =>
  ['get', 'pim/products/wb/product/price', productId] as const

type GetProductsPriceQueryKey = ReturnType<typeof getProductsPriceQueryKey>

function getProductsPrice(productId: UniqueId) {
  return api.get<WBPriceDetail>
  (`pim/products/wb/product/${productId}/price`).then((res) => res.data)
}

const getProductsPriceFn = (context: QueryFunctionContext<GetProductsPriceQueryKey>) => {
  const [, ,productId] = context.queryKey
  return getProductsPrice(productId)
}

export const useGetProductsPriceQuery = (productId: UniqueId) =>
  useQuery<
    WBPriceDetail,
    errorType,
    WBPriceDetail,
    GetProductsPriceQueryKey
  >(
    getProductsPriceQueryKey(productId),
    getProductsPriceFn, { refetchOnMount: false, keepPreviousData: true, staleTime: Infinity }
  )
