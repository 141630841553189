import { tradeGen } from 'shared/lib/generated'
import { create } from 'zustand'
import { tradeApi } from 'shared/api'
import { devtools } from 'zustand/middleware'
import { useQueryClient } from 'react-query'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { checkValue } from 'shared/lib/checkers'

export interface ISelectedProducts {
  type: 'row' | 'column' | 'cell',
  distributions: Array<tradeApi.CopackingDistribution>
  warehouse?: tradeApi.CopackingDistributionWarehouse
}

export interface SelectedProductsState {
  selectedProducts: ISelectedProducts | null
  setSelectedProducts: (e: ISelectedProducts | null) => void
}

interface IUseDistributionStore extends SelectedProductsState{
  changeDistributionIsLoading: boolean
  setIsLoading: (e: boolean) => void
}
interface IChangeFunction {
  copackingorderId: number
  data: Array<any>
  onSuccess?: (e) => void
  onError?: () => void
}
export const useChangeDistribution = () => {
  const { searchObj } = useQueryParams()
  const queryClient = useQueryClient()
  const {
    mutate: changeDistribution
  } = tradeGen.orders.Changecopackingdistribution.useChangecopackingdistribution()

  const handleChangeDistribution = ({ copackingorderId, data, onSuccess, onError }: IChangeFunction ) => {
    changeDistribution({ copackingorderId, data }, {
      onSuccess, onError
    })
  }
  
  const setDistributionCache = ({ supplyOrderQuery, response }) => {
    queryClient.setQueryData(tradeGen.orders.Getdistributions.getQueryKey({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchObj?.searchString) }
    }), response)
  }
  
  const invalidateDistributionCache = ({ supplyOrderQuery }) => {
    queryClient.invalidateQueries({ queryKey: tradeGen.orders.Getdistributions.getQueryKey({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchObj?.searchString) }
    }) })
  }
  return ({ handleChangeDistribution, setDistributionCache, invalidateDistributionCache })
}

export const useDistributionStore = create<IUseDistributionStore>()(devtools((set) => ({
  selectedProducts: null,
  setSelectedProducts: (value) => set(() => ({ selectedProducts: value })),
  changeDistributionIsLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ changeDistributionIsLoading: isLoading }))
})))