import React, { useState } from 'react'
import {
  Box,
  Button,
  DialogActions,
  Radio,
  Tooltip,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { InfoOutlined } from '@mui/icons-material'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'

import { HelpWithMargin, ModalContainer, RadioBox, RadioList } from './styled'

export const EditReportsAccessModal = ({ close, file, privileges, accept }) => {
  const [selectedValue, setSelectedValue] = useState(file.privilegeId)

  const handleChange = (event) => {
    setSelectedValue(event.target.id || null)
  }

  const handleClick = () => {
    accept(selectedValue)
    close()
  }
  
  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon"/>
        </IconCloseButton>
        <Box fontSize={20} mb={1} p={0} fontWeight={500}>
          Настройка привилегии доступа к отчету
        </Box>
        <Box mb={4}>
          {file?.name}
        </Box>
        <RadioList>
          <RadioBox
            checked={selectedValue === null}
            onClick={handleChange}
          >
            <Typography>Открыт всем пользователям</Typography>
            <Radio
              checked={selectedValue === null}
              value={null}
            />
          </RadioBox>
          {privileges.map((el) => (
            <RadioBox
              key={el.id} 
              id={String(el.id)}
              onClick={handleChange}
              checked = {String(selectedValue) === String(el.id)}
              description={el.description}>
              {el.description &&
                <Tooltip title={el.description} placement="top">
                  <HelpWithMargin>
                    <InfoOutlined />
                  </HelpWithMargin>
                </Tooltip>
              }
              <Typography>
                {el.name}
              </Typography>
              <Radio
                checked={String(selectedValue) === String(el.id)}
                value={el.id}
              />
            </RadioBox>
          ))}
        </RadioList>
        <DialogActions sx={{ marginTop: '24px', padding: 0 }}>
          <Box>
            <Button
              onClick={close}
              color="primary"
            >
              ОТМЕНА
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={handleClick}
              sx={{
                marginLeft: 2
              }}
            >
              Подтвердить
            </LoadingButton>
          </Box>
        </DialogActions>
      </Box>
    </ModalContainer>
  )
}