import {  useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { EditSupplier } from 'features/admin/EditSupplier/EditSupplier'
import { SupplierContextProvider } from 'entities/pim/admin/useSupplierEditContext'


export const EditSupplierPage = () => {
  const { id } = useParams<{id: string}>()
 
  const supplierId = parseInt(id, 10)
  const supplierQuery = pimApi.admin.useGetCurrentContragentQuery(supplierId)
  const supplierData = supplierQuery.data
    
  return (
    <SupplierContextProvider supplierQuery={supplierQuery}>
      {
        supplierData && <EditSupplier data={supplierData}/>
      }
    </SupplierContextProvider>
  )
}