import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

import { EditValue } from '../../../../lib/generated/pim/Api'

interface EditValueProps {
  dictionaryId: UniqueId
  valueId: UniqueId
  value: EditValue
}

export const editValue = (editedValue: EditValueProps) =>
  api.put<pimApi.dictionaries.Value>(
    `/pim/dictionaries/${editedValue.dictionaryId}/values/${editedValue.valueId}`,
    editedValue.value
  )


export const useEditValueMutation = () =>
  useMutation<
    AxiosResponse<pimApi.dictionaries.Value>,
    AxiosError<errorType>,
    EditValueProps
  >(editValue)
