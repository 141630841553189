import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table as DXTable,
  TableColumnReordering,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui'
import {
  GridRootComponent,
  TableComponent,
} from 'shared/ui/components/Table/ui'
import { TableWrapper } from 'shared/ui/components/Table/ui/styled'
import {
  CustomPaging,
  PagingState,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  GetGoodsSupplyingParams,
  GoodsSupplyingResult,
} from 'shared/api/trade/stocks'
import debounce from 'lodash/debounce'
import { TableColumnVisibility } from 'shared/ui/components/Table/ui/TableColumnVisibility'
import { getRowsPerPageOptions, pagingPanelLocale, tableHeaderRowLocale, tableLocale } from 'shared/ui/components/Table/lib'

import { TableCellComponent } from './TableCellComponent'
import { MemoTableHeaderCellComponent } from './TableHeaderCellComponent'

interface TableProps {
  tableData: GoodsSupplyingResult
  tableParams: GetGoodsSupplyingParams
  calculatedRowsPerPage: number
  columnsConfig: any
  tableParamsChange: any
  paginationLocale?: any
}

const ASC = 'asc'
const DESC = 'desc'
interface SortSettings {
  columnName: string
  direction: typeof ASC | typeof DESC
}

const DEBOUNCE_WAIT = 500

const debouncedSearch = debounce((cb: () => void) => cb(), DEBOUNCE_WAIT)

const getDefaultColumnsOrder = (columnsConfig) =>
  columnsConfig.map((column) => column.columnName)

export const Table = ({
  tableData,
  tableParams,
  calculatedRowsPerPage,
  columnsConfig,
  tableParamsChange,
  paginationLocale
}: TableProps) => {
  const pageSizes = getRowsPerPageOptions(
    tableData.total,
    calculatedRowsPerPage
  )
  const { searchString, page, limit, sort, order } = tableParams

  const onPageSizeChange = (currentPageSize: number) => {
    tableParamsChange({ limit: currentPageSize, page: 0 })
  }

  const onCurrentPageChange = (currentPageNumber: number) => {
    tableParamsChange({ page: currentPageNumber })
  }

  const onSortingChange = (sortSettings: SortSettings[]) => {
    const prevSort = sort
    const nextSort = sortSettings[0]

    const isRemoveSortSettings =
      prevSort === nextSort?.columnName && order === DESC

    if (isRemoveSortSettings) {
      tableParamsChange({ sort: undefined, order: undefined })
      return
    }
    tableParamsChange({ sort: nextSort.columnName, order: nextSort.direction })
  }

  const HeaderCell = (renderProps) => (
    <>
      <MemoTableHeaderCellComponent
        {...renderProps}
        onSort={onSortingChange}
        sort={[{ columnName: sort, direction: order }]}
        onFilterChange={tableParamsChange}
      />
    </>
  )

  const handleSearchValueChange = (value) =>
    debouncedSearch(() => {
      tableParamsChange({ searchString: value })
    })
  return (
    <TableWrapper>
      <Grid
        rows={tableData.result}
        columns={columnsConfig}
        rootComponent={GridRootComponent}
      >
        <PagingState
          pageSize={limit}
          currentPage={page}
          onCurrentPageChange={onCurrentPageChange}
          onPageSizeChange={onPageSizeChange}
        />

        <CustomPaging totalCount={tableData.total} />

        <SearchState
          defaultValue={searchString}
          onValueChange={handleSearchValueChange}
        />
        <SortingState
          columnExtensions={columnsConfig}
          onSortingChange={onSortingChange}
          sorting={
            sort
              ? [
                {
                  columnName: sort,
                  direction: order === ASC ? 'asc' : 'desc',
                },
              ]
              : []
          }
        />
        <DXTable
          cellComponent={TableCellComponent}
          columnExtensions={columnsConfig}
          messages={tableLocale}
          tableComponent={TableComponent}
        />

        <Toolbar />
        <SearchPanel messages={{ searchPlaceholder: 'Поиск' }} />

        <TableColumnVisibility />

        <TableColumnReordering
          defaultOrder={getDefaultColumnsOrder(columnsConfig)}
        />

        <TableHeaderRow
          cellComponent={HeaderCell}
          messages={tableHeaderRowLocale}
          showSortingControls={true}
        />
        <PagingPanel pageSizes={pageSizes} messages={paginationLocale || pagingPanelLocale} />
      </Grid>
    </TableWrapper>
  )
}
