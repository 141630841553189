import { DOC_LIST } from 'shared/config'

import { StyledLink } from './styled'

export const DocNumber = ({ docId, value }: { docId: number, value: string }) => (
  <StyledLink
    to={`${DOC_LIST}/${docId}`}
    title={value}
  >
    {value}
  </StyledLink>
)
