import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'


export const validationSchemaOzon = yup.object({
  name: yup.string().max(255).required(),
  description: yup.string().nullable(),
  vatRate: yup.number().required().transform(yupTransformEmptyStringInNull),
  price: yup.number().required(),
  brandId: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required().transform(yupTransformEmptyStringInNull),
  }).required(),
})

export const validationSchemaPIM = yup.object({
  packagingrequirementId: yup.number().required().transform(yupTransformEmptyStringInNull),
})

export const validationSchemaAll = validationSchemaOzon.concat(validationSchemaPIM)
