import React from 'react'
import CallMadeIcon from '@mui/icons-material/CallMade'

export const RepriceNewPriceCell = ({ row }: { row: any }) => {
  function round(value?: number | string) {
    if (value) {
      const toNumber = Number(value)
      return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(toNumber).replace(',', '.')
    } return '0'
  }

  const { price, advicePrice, newMargin, newMarginPercents, advicePriceWithoutDiscount } = row
  const diff = price - advicePrice
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      gap: 16,
      paddingTop: 15,
      paddingRight: 16
    }}>
      <div style={{
        backgroundColor: 'rgba(63, 81, 181, 0.12)',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        height: 28,
        margin: '0 8px 0 -8px',
        justifyContent: 'space-between',
        gap: 5,
        display: 'flex',
        alignItems: 'center'
      }}>
        <div>
          <span style={{ color: '#3F51B5', marginLeft: '8px' }}>{advicePrice?.toLocaleString() || 0}</span>
          <span
            style={{
              textDecoration: 'line-through',
              color: 'rgba(0, 0, 0, 0.38)',
              marginLeft: '5px',
              position: 'relative',
              bottom: '5px'
            }}>{advicePriceWithoutDiscount?.toLocaleString() || 0}</span>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px'
        }}>
          <CallMadeIcon sx={{
            fontSize: 12,
            color: '#757575',
            rotate: diff > 0 ?'90deg' : ''
          }}/>
          <span style={{ fontSize: 12, color: '#757575' }}>
            {Math.abs(diff).toLocaleString() || 0}
          </span>
        </div>
      </div>
      <div style={{
        height: 28,
        paddingRight: 8,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}
      >
        <span>{round(newMargin) || 0}</span>
        <div style={{ backgroundColor: '#F5F5F5', padding: 4, borderRadius: 4 }}>
          <span style={{ color: '#00000099' }}>
            {+newMarginPercents?.toFixed(1) || 0}%
          </span>
        </div>
      </div>
    </div>
  )
}