import styled from 'styled-components'


export const StyledLink = styled.a`
  color:  #2196F3;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-right: 13px;
    color: rgba(33, 150, 243, 0.5);
  }
  div {
    margin-top: 3px;
    text-decoration: none;
    white-space: nowrap;
    color: #2196F3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`