import React from 'react'
import { Grid } from '@mui/material'
import { GridSize } from '@mui/material/Grid'
import { Field } from 'shared/ui/components/form'
import { Attribute } from 'pages/goods/edit/CharacteristicStep/interface'
import { Label, withUnits, withRequired } from 'shared/lib/transform/Label'

import { getGoodsAttributeComponent } from '../../lib/getAttributeComponent'

interface AttributeFieldProps extends Attribute {
  fieldName: string;
  onChange: (target: {
    name: string;
    value: string | Array<string>;
  }) => void;
  disabled?: boolean;
}

enum ComponentSizeToGridSize {
  tiny = 3,
  small = 4,
  medium = 6,
  large = 12
}

export const _AttributeField = ({
  fieldName,
  name,
  unit,
  required,
  componentSize,
  dictionaryId,
  dictionaryValues,
  multipleValues,
  attributeDataType,
  onChange,
  disabled
}: AttributeFieldProps) => {
  const { Component, props } = getGoodsAttributeComponent({
    catalog: dictionaryId ? `pim/dictionaries/${dictionaryId}` : undefined,
    dictionaryValues,
    multipleValues,
    attributeDataType,
    dictionaryId,
    changeValue: onChange
  })

  const labelInstance = new Label(name)

  if (unit) {
    withUnits(labelInstance, unit)
  }

  if (required) {
    withRequired(labelInstance)
  }

  return (
    <Grid item={true} xs={ComponentSizeToGridSize[componentSize] as unknown as GridSize}>
      <Field
        name={fieldName}
        component={Component}
        label={labelInstance.label}
        disabled={disabled}
        {...props}
      />
    </Grid>
  )
}

export const AttributeField = React.memo(_AttributeField)
