import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { toSnakeCase } from 'shared/lib/transform'

import { Barcode, Category, Collection, Color, TNVEDCode } from './models'

import { api } from '../base'

export * as model from './models'

const BASE_URL = '/wbproxy'

export const fetchCategories = async (
  searchString: string,
  companymarketplaceId: UniqueId,
  // timeout?: number
) => api.get<Array<Category>>(
  `${BASE_URL}/categories?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
)

export const fetchColors = async (
  searchString: string,
  companymarketplaceId: UniqueId,
  // timeout?: number
) => api.get<Array<Color>>(
  `${BASE_URL}/colors?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
)


export const fetchBrands = async (
  searchString: string,
  companymarketplaceId: UniqueId,
  // timeout?: number
) => api.get<Array<Collection>>(
  `${BASE_URL}/brands?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
)

export const fetchCountries = async (
  searchString: string,
  companymarketplaceId: UniqueId,
  // timeout?: number
) => api.get<Array<Collection>>(
  `${BASE_URL}/countries?search_string=${searchString}&companymarketplace_id=${companymarketplaceId}`
)

export const fetchTNVED = async (
  productObject: string,
  companymarketplaceId: UniqueId,
  subjectId: UniqueId,
) => api.get<Array<TNVEDCode>>(
  `${BASE_URL}/tnved`,
  {
    params: {
      tnved: productObject,
      subjectid: subjectId,
      companymarketplace_id:  companymarketplaceId,
    }
  }
)

type FetchTNVEDQueryKey = ReturnType<typeof getWBProxyTNVEDQueryKey>
export const getWBProxyTNVEDQueryKey = (
  productObject: string,
  companymarketplaceId: UniqueId,
  subjectId: UniqueId,
) =>
  ['get', `${BASE_URL}/tnved`, productObject, subjectId, companymarketplaceId] as const

export const useFetchTNVEDQuery = <R = AxiosResponse<Array<TNVEDCode>>>(
  productObject: string,
  companymarketplaceId: UniqueId,
  subjectId: UniqueId,
  options: UseQueryOptions<
    AxiosResponse<Array<TNVEDCode>>,
    errorType,
    R,
    FetchTNVEDQueryKey
  >
) => useQuery<
  AxiosResponse<Array<TNVEDCode>>,
  errorType,
  R,
  FetchTNVEDQueryKey
>(
  getWBProxyTNVEDQueryKey(productObject, companymarketplaceId, subjectId),
  (context) => fetchTNVED(context.queryKey[2], context.queryKey[4], context.queryKey[3]),
  options
)

export const fetchBarcode = async (
  companymarketplaceId: number,
) => api.get<Barcode>(
  `${BASE_URL}/barcodes`,
  {
    params: {
      companymarketplace_id: companymarketplaceId,
    }
  }
)

export const useFetchBarcodeMutation = (
  options?: UseMutationOptions<
    AxiosResponse<Barcode>,
    errorType,
    {
      companymarketplaceId: number,
    }
  >
) => useMutation(
  ({ companymarketplaceId }) => fetchBarcode(companymarketplaceId),
  options
)

export const fetchDictionary = (
  companymarketplaceId: UniqueId,
  dictionary: string,
  searchString: string,
  option?: string,
) => api.get<Array<string>>(
  `${BASE_URL}/${dictionary}`,
  {
    params: toSnakeCase({
      companymarketplaceId,
      option,
      searchString,
    })
  }
)
interface AttributesPayload {
  searchString?: string
  category?: string
  name?: string
  companymarketplaceId?: UniqueId
}

export const fetchAttributes = (queryParams?: AttributesPayload) =>
  api.get<Array<any>>(
    `${BASE_URL}/dictionary`,
    {
      params: toSnakeCase(queryParams)
    }
  )
