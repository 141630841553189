import styled from 'styled-components'
import { Grid, Tab } from '@mui/material'
import { TabsNavWithMargin } from 'pages/goods/components/common'

export const ParamsTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  span{
    display: inline-flex;
    align-items: center;
    padding-right: 5px;
    background: #fff;
    position: relative;
    z-index: 2;
  }
  &:after{
    content: "";
    border-top: 1px dashed rgba(0, 0, 0, 0.23);
    width: 100%;
    position: absolute;
    z-index: 0;
  }
`
export const NoMarginGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`
export const DashedAction = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  color: #007DFF;
  border-bottom: 1px dashed #007DFF;
  cursor: pointer;
`
export const Wrapper = styled.div`
  border: 1px solid rgba(0, 125, 255, 0.5);
  border-top: 0;
  margin-top: -36px;
  padding: 24px;
`
export const SizeTabsNav = styled(TabsNavWithMargin)`
  border-bottom-style: dashed;
`
export const GridWithArrow = styled(Grid)`
  position: relative;
`
export const InputArrow = styled.div`
  position: absolute;
  right: -7px;
  top: 50%;
  margin-top: -8px;
  svg{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: 100%;
  }
`

export const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    left: -15px;
  }
`

export const StyledTab = styled(Tab)`
  padding: 8px 12px;
  border: none;
  .MuiSvgIcon-root {
    height: 22px;
  }
  border-radius: 4px 4px 0 0;
  min-height: 48px !important;
`

export const NomenclatureTabsNav = styled(TabsNavWithMargin)<{ $isArchived?: boolean, $isMedia?: boolean }>`
  border-bottom: 1px solid rgba(0, 125, 255, 0.5);
  padding-left: 25px;
  .MuiTabs-indicator {
    display: none !important;
  }
  .MuiTab-root {
    &:first-child {
      border-left: none;
    }
    border: none;
    div {
      margin-right: 40px;
      img {
        object-fit: cover;
        border-radius: 4px;
        height: 44px;
        width: 44px;
        margin-right: 10px;
      }
      div {
        display: none;
      }
    }
  }
  .Mui-selected {
    background-color: ${({ $isArchived }) => $isArchived ? '#FAFAFA' : ''};
    div {
      margin-right: 0;
      img {
        filter: ${({ $isMedia }) => (!$isMedia) ?
    '' :
    'invert(0%) sepia(0%) saturate(17%) hue-rotate(324deg) brightness(95%) contrast(105%)'
};
        opacity: ${({ $isArchived }) => $isArchived ? '0.6' : ''};
        height: 44px;
        width: 44px;
      }
      p {
        color: ${({ $isArchived }) => $isArchived ? '#ED6C02' : ''};
      }
      div {
        display: flex;
      }

      button {
        padding: 0;
        margin-left: 16px;
      }
    }
  }
`