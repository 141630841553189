import { memo } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { hasRoleAccess } from 'shared/api/base'

import PanelContentElement from './ui/AdminPanelContentElement'

import { adminPanelElements } from '../model/adminPanelElements'

const ElementsWrapper = styled(Grid) `
  margin: 0;
`

const AdminPanelContent = () =>
  (
    <ElementsWrapper container={true} spacing={2}>
      {
        adminPanelElements.map(el => {
          if ((!el.checkAdminRole) || (hasRoleAccess('ADMIN'))) {
            return (
              <PanelContentElement
                element={el}
                key={el.value}
              />
            )
          }
          return (<></>)
        }
        )
      }
    </ElementsWrapper>
  )


export const MemoAdminPanelContent = memo(AdminPanelContent)
