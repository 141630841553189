import { SearchState } from '@devexpress/dx-react-grid'
import { SearchPanel } from '@devexpress/dx-react-grid-material-ui'
import { Plugin } from '@devexpress/dx-react-core'
import debounce from 'lodash/debounce'
import { searchPanelLocale } from 'features/supplyOrders/CopackingOrders/lib/localization'

import { TableFiltering } from '../TableFiltering'

const DEBOUNCE_WAIT = 500

export const debouncedSearch = debounce((cb: () => void) => cb(), DEBOUNCE_WAIT)

export const TableSearch = ({ searchString,
  handleTableParams, 
  searching,
  filtering,
  customSearchInput,
}) => {
  const isInternalSearching = searching && (searching === 'internal')
  const hasIntegratedFiltering = filtering && (filtering === 'internal')

  const handleSearchValueChange = (value) =>
    debouncedSearch(() => {
      handleTableParams({ searchString: value })
    })

  return (
    <Plugin>
      <SearchState
        defaultValue={isInternalSearching ? undefined : searchString}
        onValueChange={handleSearchValueChange}
      />
      {(hasIntegratedFiltering && isInternalSearching)
          && <TableFiltering filtering={filtering}/>}
      {customSearchInput
        ? <SearchPanel
          inputComponent={customSearchInput}
        />
        : <SearchPanel
          messages={searchPanelLocale}
        />
      }
    </Plugin>
  )
}
