// @ts-nocheck
import styled from 'styled-components'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Theme } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'

import { getColorForCell } from '../helpers'
import { columnNames } from '../ColumnsConfig'

const getPadding = (columnName: string) => {
  if (columnName === columnNames.goodsBaseImageId) {
    return '0'
  }
  if (columnName === columnNames.number) {
    return '0px 8px 0px 22px'
  }
  return '0px 8px 0px 16px'
}

export const StyledTableCellComponent = styled(Table.Cell)<{
  theme: Theme, row: CalculatedReceiptDocPosition, columnname: string, value: number,
}>`
  height: 50px;

  ${({ theme, row, value, columnname }) => {
    const { color, bgColor } = getColorForCell(theme, row, columnname, value)
    return `
      padding: ${getPadding(columnname)} !important;
      ${color && `color: ${color}`};
      ${bgColor && `background-color: ${bgColor}`};
    `
  }}
`