import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface AddProductInOrder {
  supplyOrderId?: UniqueId,
  addedProduct: tradeApi.NewSupplyOrderProductPosition
}

export const addProductInOrder = ({ supplyOrderId, addedProduct }: AddProductInOrder) =>
  api.post<tradeApi.SupplyOrderProductPosition>(
    `/trade/orders/supplyorders/${supplyOrderId}/productpositions`,
    addedProduct
  )

export const useAddProductInOrderMutation = () =>
  useMutation<
    AxiosResponse<tradeApi.SupplyOrderProductPosition>,
    AxiosError<errorType>,
    AddProductInOrder
  >(addProductInOrder)