import { useCallback } from 'react'
import { Attribute, GoodsAttributes } from 'domains/pim/goods'
import { IGoodsPIMService } from 'shared/services/interfaces/IGoodsPIMService'
import { diContainer } from 'shared/lib'
import { TYPES } from 'shared/services/types'
import { getIsDataLoaded, useStatus } from 'shared/lib/hooks/useStatus'
import get from 'lodash.get'
import set from 'lodash.set'
import { isNil } from 'shared/lib/checkers/isNil'
import { snackActions } from 'shared/lib/react/snackbar'

const goodsService = diContainer.get<IGoodsPIMService>(TYPES.GoodsPIMService)
const VALUE_PATH_LENGTH = '.value'.length

function serverSave(goodsId: UniqueId, goodsAttribute: Attribute, value: string | Array<string>) {
  if (isNil(goodsAttribute.id)) {
    return goodsService.saveAttribute(goodsId, {
      attributeId: goodsAttribute.attributeId,
      value
    })
  }

  return goodsService.editAttribute(
    goodsId,
    goodsAttribute.id as UniqueId,
    { id: goodsAttribute.id as UniqueId, versionNo: goodsAttribute.versionNo as number, value }
  )
}

export function useGoodsAttribute() {
  const [
    goodsAttributes,
    {
      setData,
      setLoading,
      setError,
    }
  ] = useStatus()

  const loadGoodsAttributes = useCallback((goodId: UniqueId) => {
    setLoading()
    goodsService.getAttributes(goodId)
      .then<GoodsAttributes, GoodsAttributes>(({ data }: { data: GoodsAttributes }) => data)
      .then(setData)
      .catch(() => {
        setError('Произошла ошибка при загрузке аттрибутов')
        snackActions.error('Произошла ошибка при загрузке аттрибутов')
      })
  }, [])

  const changeGoodsAttributes = useCallback(async (goodsId: UniqueId, path: string, value: string | Array<string>) => {
    if (!getIsDataLoaded(goodsAttributes)) return

    const pathWithoutValueField = path.slice(0, -VALUE_PATH_LENGTH)

    const goodsAttribute: Attribute | undefined = get(goodsAttributes.data, pathWithoutValueField)
    if (!goodsAttribute || goodsAttribute.value === value) return
    if(!value && isNil(goodsAttribute.id)) {
      return
    }
    const saveRes = await serverSave(goodsId, goodsAttribute, value)

    if (!saveRes) {
      snackActions.error(`Произошла ошибка при сохранении поля ${saveRes ?? ''}`)
      return
    }

    const newAttribute = saveRes.data

    const newData = {
      ...goodsAttributes.data
    }

    set(newData, pathWithoutValueField, newAttribute)

    setData(newData)
  }, [goodsAttributes])

  return {
    goodsAttributes,
    loadGoodsAttributes,
    changeGoodsAttributes,
  } as const
}