// @ts-nocheck
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import styled from 'styled-components'
import { IconButton } from '@mui/material'

export const StyledTableRowDetailCell = styled(TableRowDetail.Cell)`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent !important;
`

export const BorderRight = styled.div`
  width: 0;
  height: 16px;
  border-right: 1px solid #E0E0E0;
`

export const StyledIconButton = styled(IconButton)<{ expanded?: string }>`
  > svg {
    transition: transform 0.2s ease-out;
    ${({ expanded }) => (expanded && `
      transform: rotate(90deg);
    `)}
  }
`