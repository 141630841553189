import React, { PropsWithChildren } from 'react'
import { pimApi } from 'shared/api'

const GoodsContext = React.createContext<pimApi.goods.Goods | undefined>(undefined)

interface GoodsProviderProps {
  value: pimApi.goods.Goods
}
function GoodsProvider({ children, value }: PropsWithChildren<GoodsProviderProps>) {
  return (
    <GoodsContext.Provider value={value}>{children}</GoodsContext.Provider>
  )
}

function useGoodsContext() {
  const context = React.useContext(GoodsContext)

  if (context === undefined) {
    throw new Error('useGoodsContext must be used within a GoodsProvider')
  }

  return context
}

export { GoodsProvider, useGoodsContext }