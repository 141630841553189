import React from 'react'
import { thousandthValues } from 'shared/lib/utils'

export const PositionsDiff = ({ row }) => {
  const { expectedQuantity, quantity } = row
  const quantityDiff = expectedQuantity - quantity

  const spanColor = () => {
    if (quantityDiff === 0) {
      return '#00000042'
    }
    if (quantityDiff > 0) {
      return '#000000DE'
    }
    return '#F50057'
  }
  return (
    <span style={{
      color: spanColor()
    }}>
      {thousandthValues(Math.abs(quantityDiff))}
    </span>
  )
}