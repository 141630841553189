import { CamelCasedPropertiesDeep } from 'type-fest'
import {
  EditGoodsSupplierCode as EditGoodsSupplierCodeSnake,
  GoodsSupplierCode as GoodsSupplierCodeSnake,
} from 'shared/lib/generated/pim/Api'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

type GoodsSupplierCode = CamelCasedPropertiesDeep<GoodsSupplierCodeSnake>
type EditGoodsSupplierCode =
  CamelCasedPropertiesDeep<EditGoodsSupplierCodeSnake>

export function createGoodsCode(
  productId: UniqueId,
  supplierId: UniqueId,
  code: GoodsSupplierCode
): Promise<AxiosResponse<GoodsSupplierCode>> {
  return api.post(
    `/pim/goods/${productId}/suppliers/${supplierId}/codes`,
    code
  )
}

export function updateGoodsCode(
  productId: UniqueId,
  supplierId: UniqueId,
  code: EditGoodsSupplierCode
): Promise<AxiosResponse<GoodsSupplierCode>> {
  return api.put(
    `/pim/goods/${productId}/suppliers/${supplierId}/codes/${code.id}`,
    code
  )
}

export function deleteGoodsCode(
  productId: UniqueId,
  supplierId: UniqueId,
  { id, versionNo }: GoodsSupplierCode
): Promise<AxiosResponse<void>> {
  return api.delete(
    `/pim/goods/${productId}/suppliers/${supplierId}/codes/${id}`,
    { data: { id, versionNo, comments: '' } }
  )
}
