import { memo, useEffect, useState } from 'react'
import { format } from 'date-fns'
import isArray from 'lodash/isArray'
import { DateFormats } from 'shared/config/dateFormats'

import { StyledSlider } from './styled'

function valueLabelFormat(value) {
  if(new Date(value).toDateString() === new Date().toDateString()) {
    return 'Сегодня'
  }
  return value ? `${format(new Date(value), DateFormats.dayMonthYear)}` : ''
}

export const Slider = memo(
  ({
    options = [],
    onChange,
    startColor,
    endColor,
  }: {
    options: boolean | Array<any> | undefined
    onChange: (value: any) => void
    startColor?: string
    endColor?: string
  }) => {
    const [value, setValue] = useState<Array<number>>([])
    const min = isArray(options) ? options[0].value : 0
    const max = isArray(options) ? options[options.length - 1].value : 1

    const marks = [
      {
        value: min,
        label: format(new Date(min), DateFormats.dayMonthYear)
      },
      {
        value: max,
        label: format(new Date(max), DateFormats.dayMonthYear)
      },
    ]

    const handleChange = (event, newValue) => {
      setValue(newValue)
      onChange(newValue)
    }

    useEffect(() => {
      if (isArray(options))
        setValue([options[0]?.value, options[options.length - 1]?.value])
    }, [options])

    const todayDatePoint = Number(new Date) - min
    const range = max - min
    const linearGradientPercent = Number((todayDatePoint / range).toFixed(4)) * 100

    return (
      <StyledSlider
        name="price-history-slider"
        aria-labelledby="track-inverted-slider"
        $linearGradientPercent={linearGradientPercent}
        $startColor={startColor}
        $endColor={endColor}
        size="small"
        defaultValue={[min, max]}
        min={min}
        max={max}
        marks={marks}
        value={value}
        onChange={handleChange}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="auto"
      />
    )
  }
)
