import styled from 'styled-components'

interface EditQuantityPropsType {
  value?: string
  quantityData?: string
  isLoading?: boolean
  isShowGreen?: boolean
}

interface QuantityInSupplyStyleProps {
  difference: boolean;
}

export const QuantityInSupplyStyle = styled.div<QuantityInSupplyStyleProps>`
  color: ${(props) => props.difference ? '#F50057' : props.color};
`

function round( value: string | undefined ) {
  if (value) {
    return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(Number(value))
  } return '0'
}

export const QuantityInSupply = ({ value, quantityData, isShowGreen }: EditQuantityPropsType) => {
  const difColor = isShowGreen ? '#4CAF50' : '#000000DE'
  const showDifference = value !== quantityData

  return (
    <QuantityInSupplyStyle difference={showDifference} color={difColor}>
      { round(value) }
    </QuantityInSupplyStyle>
  )
}
