import {
  InputLabel,
  Select as SelectUI,
  MenuItem,
  FormControl,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import { VariationAttribute, GoodsVariation } from 'domains/pim/goods'
import { snackActions } from 'shared/lib/react/snackbar'

import { VariationSelectWrapper } from './styled'

interface SelectVariationProps {
  attribute: VariationAttribute
  value: string;
  currentGoodsAttribute: GoodsVariation
  goods: GoodsVariation[]
  index: number
  onChange?: (goodsId: UniqueId) => void
}

export const SelectVariation = ({
  attribute,
  value,
  index,
  currentGoodsAttribute,
  onChange,
  goods
}: SelectVariationProps) => {
  const goodsForCurrentSelect = goods.map((currentSelectItem) => {
    const resItem = currentSelectItem.attributes.sort((a, b) => a.orderNumber - b.orderNumber)
      .filter((inAttribute) =>
        inAttribute.orderNumber <= attribute.orderNumber
      )
    return { ...currentSelectItem, attributes: resItem }
  })

  const handleChange = (event: SelectChangeEvent): void => {
    const attributeValue = event.target.value as string

    const newGoods = goodsForCurrentSelect.find(
      ({ attributes: goodsAttributes }) => goodsAttributes.every((inAttribute, i) =>
        i === index ? inAttribute.value === attributeValue : inAttribute.value === currentGoodsAttribute.attributes[i].value
      )
    )

    if (!newGoods) {
      snackActions.error('Такой комбинации нет')
      return
    }

    onChange?.(newGoods.goodsId)
  }

  return (
    <Grid item={true} xs={3} key={attribute.attributeId}>
      <VariationSelectWrapper>
        <FormControl variant="outlined" fullWidth={true}>
          <InputLabel id="attributes">{attribute.attributeName}</InputLabel>
          <SelectUI
            value={value}
            label={attribute.attributeName}
            onChange={handleChange}
            size="small"
          >
            {attribute.values.map((possibleValue) => {
              const newVariations = goodsForCurrentSelect.find(({ attributes: goodsAttributes }) =>
                goodsAttributes.every((inAttribute, i) => i === index ?
                  inAttribute.value === possibleValue : inAttribute.value === currentGoodsAttribute.attributes[i].value
                )
              )
              const isDisabled = newVariations?.attributes?.some((inAttribute) => inAttribute.value === possibleValue)

              return (
                <MenuItem
                  key={possibleValue}
                  value={possibleValue}
                  disabled={attribute.orderNumber > 0 && !isDisabled}
                >
                  {possibleValue}
                </MenuItem>
              )
            })}
          </SelectUI>
        </FormControl>
      </VariationSelectWrapper>
    </Grid>
  )
}
