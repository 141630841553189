import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { BULK_OPERATIONS_MAIN, getBulkOperationsPath } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'

import { BulkOperations } from './bulkOperationsPage/ui/BulkOperations'
import { BulkGoodsArchivation, BulkGoodsUnarchivation, GoodsBulkCreation, GoodsBulkSuppliersChange } from './tabs/goods'
import { BulkProductEdit, BulkProductParamsEdit, BulkProductPriceChange, BulkProductUnarchivation,  } from './tabs/products'
import { BulkProductArchivation } from './tabs/products/archive'
import { BulkProductCreation } from './tabs/products/create'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    strict: true,
    path: BULK_OPERATIONS_MAIN,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: BulkOperations,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'create'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductCreation,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'archive'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductArchivation,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'paramsedit'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductParamsEdit,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'unarchive'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductUnarchivation,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('goods', 'unarchive'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkGoodsUnarchivation,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('goods', 'archive'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkGoodsArchivation,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'edit'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductEdit,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('products', 'pricechange'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: BulkProductPriceChange,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('goods', 'supplierschange'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: GoodsBulkSuppliersChange,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: getBulkOperationsPath('goods', 'create'),
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: GoodsBulkCreation,
  },
] as Array<Route>
