import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import { useSelector } from 'react-redux'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { detailStatusSelector } from 'store/doc/selectors'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import { ToggleCell } from 'shared/ui/components/Table/ui/TableCells'

import { checkOnEditable } from '../../helpers'

type ToggleCellComponentProps = Omit<TableRowDetail.ToggleCellProps, 'row'> & { row: CalculatedReceiptDocPosition }

export const ToggleCellComponent = (props: ToggleCellComponentProps) => {
  const docDetailStatus = useSelector(detailStatusSelector)
  const  { row } = props
  const { acceptance } = row

  const isEmptyAcceptanceList = isEmptyArray(acceptance)
  const isEditable = checkOnEditable(docDetailStatus)

  const disabled = !isEditable && isEmptyAcceptanceList

  return (
    <ToggleCell
      {...props}
      disabled={disabled}
    />
  )
}
