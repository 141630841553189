import React from 'react'
import { Button } from '@mui/material'
import PlugTemplateImage from 'assets/images/icons/PlugReviewTemplate.svg'

import { StyledPlugContainer } from './styled'

export const PlugWithAddButton = ({ title, subTitle, addFunction }: { title: string, subTitle?: string, addFunction?: () => void }) => (
  <StyledPlugContainer height={window.innerHeight}>
    <img src={PlugTemplateImage} alt="placeholder"/>
    <span className="title">{title}</span>
    {subTitle && <span className="subTitle">{subTitle}</span>}
    {addFunction && <Button onClick={addFunction} variant="contained">Добавить</Button>}
  </StyledPlugContainer>
)
