import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { StyledTabHeader } from 'pages/adminPanel/AdminPanelRoot/Content/Pages/Goods/ui'

interface TabHeaderProps {
  title: string
  addButtonTitle: string
  addFunction: () => void
}

export const TabHeader = ({ title, addButtonTitle, addFunction }: TabHeaderProps) => (
  <StyledTabHeader>
    <span>{title}</span>
    <Button onClick={ addFunction } size="small" variant="contained" startIcon={ <AddIcon /> }>
      { addButtonTitle }
    </Button>
  </StyledTabHeader>
)