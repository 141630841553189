import { useEffect, useRef, useState } from 'react'

export const useElementSize= () => {
  const ref: any = useRef()
  const [widthEl, setWightEl] = useState<null | number>(null)
  const [heightEl, setHeightEl] = useState<null | number>(null)


  const observer = useRef(
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect
      const { height } = entries[0].contentRect
      setWightEl(width)
      setHeightEl(height)
    })
  )
  useEffect(() => {
    observer.current.observe(ref.current)
    return () => {
      observer.current.disconnect()
    }
  }, [ref, observer])
  return [ref, widthEl, heightEl]
}

