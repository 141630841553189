export function getServerReadableDateTime(date: Date | number) {
  const currentDate = new Date()

  if (!date) return currentDate.toISOString().substring(0, 10)

  return new Date(
    new Date(date).getTime() - currentDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 10)
}

export function getCalendarReadableDateTime(date: Date | number | string) {
  const currentDate = new Date()

  return new Date(
    new Date(date).getTime() - currentDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 16)
}

export const formatDateToDDMMYY = (inputDate: string) => {
  // Разбиваем строку на составляющие
  const [year, month, day] = inputDate.split('-')
  // Формируем новый формат dd.mm.yy
  const formattedDate = `${day}.${month}.${year.slice(2)}`
  return formattedDate
}
