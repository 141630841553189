// @ts-nocheck
import { Table } from '@devexpress/dx-react-grid-material-ui'
import styled from 'styled-components'
import { Dialog } from '@mui/material'

export const StyledTableStubCell = styled(Table.StubCell)`
  background-color: #F6F6F6;
  padding-left: 6px !important;
`

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    max-width: 700px;
  }
`