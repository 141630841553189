import { useLocation } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import {
  TypographyStyled, Wrapper
} from 'features/auth/ui/styles'
import { Field, Form, InputField } from 'shared/ui/components'
import { HOME, USER_PASSWORD_RECOVERY } from 'shared/config'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import { useSetPasswordMutation } from 'shared/api/auth'

import { validationSchema } from './lib/validationSchema'

import { HelperTextSupply } from '../passRecovery/styled'

export const SetPasswordForm = () => {
  const { search } = useLocation()
  const history = useHistory()
  const validation = useYupValidation(validationSchema)
  const [passAccess, setPassAccess] = useState(false)
  const { mutate: setPass } = useSetPasswordMutation()
  const changePasswordToken = search.length>0 ? search.substring(1) : ''
  
  const handleSetPassword = (data) => {
    setPass({
      password: data.password,
      password_change_token: changePasswordToken
    }, {
      onSuccess: () => {
        setPassAccess(true)
      }
    })
  }

  const isLinkExpired = useMemo(() => {
    if (!changePasswordToken) return true
    const base64String = changePasswordToken.split('.')[1]
    try {
      const { exp } = JSON.parse(atob(base64String))
      const currentTime = Math.round(new Date().getTime() /1000)
      return currentTime >= exp
    }
    catch (e) {
      return true
    }
  }, [changePasswordToken])

  if (isLinkExpired) {
    return (
      <Wrapper>
        <TypographyStyled>{'Ссылка\nнедействительна'}</TypographyStyled>
        <Typography mb={5} fontSize={14}>Срок действия ссылки истек</Typography>
        <Box marginBottom="79px">
          <Typography
            marginBottom="42px"
            color="rgba(0, 0, 0, 0.6)">
            Необходимо запросить новую ссылку для установки пароля
          </Typography>
        </Box>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={() => {history.push(USER_PASSWORD_RECOVERY)}}>
          Далее
        </Button>
      </Wrapper>
    )
  }

  if (passAccess) {
    return (
      <Wrapper >
        <TypographyStyled>Ваш пароль установлен</TypographyStyled>
        <Typography
          marginTop="24px"
          color="rgba(0, 0, 0, 0.6)"
          whiteSpace="break-spaces"
          marginBottom="144px"
        >
          {'Теперь вы можете войти в аккаунт\nс установленным паролем'}</Typography>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={() => {history.push(HOME)}}>
          Далее
        </Button>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <TypographyStyled>Установка пароля</TypographyStyled>
      <Typography variant="body1" fontSize={14} mb={3}>Придумайте надежный пароль</Typography>
      <Form
        validate={validation}
        onSubmit={handleSetPassword}
        render={({ handleSubmit, errors, valid,dirty, dirtyFields }) => (
          <form onSubmit={handleSubmit}>
            <HelperTextSupply>
              <Field
                type="password"
                isPassword={true}
                component={InputField}
                margin="normal"
                fullWidth={true}
                label="Новый пароль"
                name="password"
                helperText={errors?.password}
              />
            </HelperTextSupply>
            <Box marginBottom="52px">
              <HelperTextSupply>
                <Field
                  type="password"
                  isPassword={true}
                  component={InputField}
                  margin="normal"
                  fullWidth={true}
                  label="Подтвердите пароль"
                  name="repeatPassword"
                  errorAfterTouch={true}
                  helperText={dirtyFields.repeatPassword && errors?.repeatPassword}
                />
              </HelperTextSupply>
            </Box>
            <Box mb={1}>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                disabled={!valid || !dirty}
              >
                Установить пароль
              </Button>
            </Box>
          </form>
        )}
      />
    </Wrapper>
  )
}