import React from 'react'
import { copackingApi } from 'shared/api'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomAccordion, OrderInfo } from 'pages/supplyOrders/edit/ui/Header/ui/styled'
import { InfoText } from 'pages/supplyOrders/edit/ui/Header/ui/InfoText'

import { OrdersTransitions } from './OrdersTransitions'
import { OrdersProgress } from './OrdersProgress'

import { StatusVariations } from '../../lib'

export const OrdersHeader = ({ orderDetailData }: { orderDetailData: copackingApi.CoPackingOrder }) => {
  const sumQuantity = orderDetailData.positions ?
    orderDetailData.positions?.reduce((sum, elem) => sum + elem.quantity, 0) : 0
  const sumExpectedQuantity = orderDetailData.positions ?
    orderDetailData.positions?.reduce((sum, elem) => sum + elem.expectedQuantity, 0) : 0

  const percents = (sumQuantity / sumExpectedQuantity) * 100

  return (
    <div className="copacking-order-header">

      <div className="creator-info">
        <div>Автор: { orderDetailData.creator }</div>
        {orderDetailData.created ?
          <div>Дата создания: { format(new Date(orderDetailData.created) , DateFormats.dayMonthYear) }</div> :
          null
        }
      </div>

      <div className="orders-info">
        <div className="orders-status">
          <span className="orders-name">Задание { orderDetailData.id }</span>
          { StatusVariations[orderDetailData.statusId].chip }
        </div>

        <div className="orders-transition">
          <OrdersTransitions order={ orderDetailData } />
        </div>

      </div>
      <div style={ { paddingLeft: '24px', paddingRight: '24px' } }>
        <CustomAccordion>
          <Accordion>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
            />
            <AccordionDetails>
              <OrderInfo>
                <InfoText title="ID" text={ orderDetailData.id } />
                <InfoText title="Задание" text={ orderDetailData.externalId } />
                <InfoText title="Автор" text={ orderDetailData.creator } />
                <InfoText title="Тип поставки" text={ orderDetailData.supplyType } />
                <InfoText title="Дата создания" text={ orderDetailData.created } />
                <InfoText title="Адрес РЦ" text={ orderDetailData.marketplacewarehouseAddress } />
                <InfoText title="Адрес сдачи груза" text={ orderDetailData.targetMarketplacewarehouseAddress } />
              </OrderInfo>
            </AccordionDetails>
          </Accordion>
        </CustomAccordion>
      </div>
      <OrdersProgress percents={percents}/>
    </div>
  )
}