import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import { StyledCheckbox, StyledMuiAccordion } from './styled'

export const Accordion = ({
  title,
  onOpen,
  onClose,
  children,
  single,
  sx,
  defaultExpanded=false
}: {
  title: string,
  onOpen?: () => void,
  onClose?: () => void,
  children: any,
  single?: boolean,
  defaultExpanded?: boolean
  sx?: any
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleChange = (event, newExpanded) => {
    if (newExpanded) {
      onOpen?.()
    } else {
      // trigger after ux animation end
      setTimeout(() => onClose?.(), 0)
    }
    setExpanded(!!newExpanded)
  }

  return (
    <StyledMuiAccordion
      disableGutters={true}
      expanded={expanded}
      onChange={handleChange}
      $single={single}
      $expanded={expanded}
      sx={sx}
    >
      <AccordionSummary aria-controls="dateFrom-content" id="dateFrom-header">
        <StyledCheckbox
          color="primary"
          onChange={handleChange}
          checked={expanded}
        />
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box pl={4} pr={6}>
          {children}
        </Box>
      </AccordionDetails>
    </StyledMuiAccordion>
  )
}
