/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const VideoCoverRequirements = () => {
  const Text = 'Формат — MP4, MOV. Размер — не больше 20 Мбайт'
  const Element = (
    <StyledTextContainer>
      <ul>
        <li>Формат — MP4, MOV</li>
        <li>Размер — до 20 Мбайт</li>
        <li>Без рамок и полей. Вертикальное — для категорий «Одежда», «Дом и сад», квадратное — для остальных категорий</li>
        <li>Не дольше 30 секунд. Длинные ролики загружайте в раздел Видео</li>
      </ul>
    </StyledTextContainer>
  )
  return { Text, Element } 
}