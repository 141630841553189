import React, { memo } from 'react'
import { FormControlLabel, Radio } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

type CatalogSelectFieldI = FieldRenderProps<string | number>

const RadioField = memo(({
  label,
  meta,
  input,
  ...rest
}: CatalogSelectFieldI) => (
  <FormControlLabel
    control={<Radio color="primary" {...input} {...rest} />}
    label={label}
  />
))

export default RadioField
