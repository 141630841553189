import React, { useMemo, useState } from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { registerGradient } from 'devextreme/common/charts'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { MultiSeriesLargeSizeWidget } from 'widgets/DashboardWidgets/MultiSeriesLargeSizeWidget'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'
import { mergeArrayByKey } from 'shared/lib/utils/mergeArrayByKey'

import { StyledChartDetails } from '../../../OrdersAndStocks/large/ui/styled'

interface DataParamsTypes {
  dateFrom?: string
  dateTo?: string
  units: string
  range: number
}

interface WidgetData {
  name: string
  valueField: string
  data: number
  diff: number
  color: string
  areaColor?: {
    fillId: string
  }
}

interface dataType {
  orders: number
  stocks: number
  orderDate: string
}

const TimelineRange: Record<number, any> = {
  0: {
    startValue: subtractDays(new Date(), 14),
    endValue: subtractDays(new Date(), 1),
    timelineText: 'за неделю',
  },
  1: {
    startValue: subtractDays(new Date(subtractMonths(new Date(), 2)), 0),
    endValue:  subtractDays(new Date(), 1),
    timelineText: 'за месяц'
  },
}


export const LargeSalesDynamicsWidget = () => {
  const [ dataParams, setDataParams ] = useState<DataParamsTypes>({
    dateFrom: TimelineRange[0].startValue,
    dateTo: TimelineRange[0].endValue,
    units: 'items',
    range: 0
  })

  const { data: ordersData, isFetching: ordersFetching } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom: TimelineRange[dataParams.range].startValue,
        dateTo: TimelineRange[dataParams.range].endValue,
        units: dataParams.units,
      })

  const { data: salesData, isFetching: salesFetching } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom: TimelineRange[dataParams.range].startValue,
        dateTo: TimelineRange[dataParams.range].endValue,
        units: dataParams.units,
      })

  const ordersHalf = ordersData ? ordersData?.data?.data?.length / 2 : 0

  const currentOrdersQuantity = ordersData?.data.data.slice(ordersHalf).reduce((sum, elem) => sum + elem[dataParams.units], 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,ordersHalf).reduce((sum, elem) => sum + elem[dataParams.units], 0)

  const currentSalesQuantity = salesData?.data.data.slice(ordersHalf).reduce((sum, elem) => sum + elem.quantity, 0)
  const prevSalesQuantity = salesData?.data.data.slice(0,ordersHalf).reduce((sum, elem) => sum + elem.quantity, 0)

  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const salesDiff = (currentSalesQuantity !== undefined && prevSalesQuantity !== undefined) ?
    (currentSalesQuantity - prevSalesQuantity) : 0

  const chartUrl = generatePath(`/analytics/charts?range=${dataParams.range}&tab=1`)

  const chartData: Array<dataType> | any = useMemo(() =>
    mergeArrayByKey('orderDate', ordersData?.data?.data || [], salesData?.data?.data.map((elem) => ({
      orderDate: elem.saleDate,
      quantity: elem.quantity
    })) || []).map((el) => ({
      orders: el[dataParams.units!],
      sales: salesData?.data.data.filter((elem) => elem.saleDate === el.orderDate).reduce((sum, elem) => sum + elem.quantity, 0),
      orderDate:el.orderDate
    })), [ordersData, salesData])

  const ordersSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(76, 175, 80, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(76,175,80,0.02)'
      }],
      rotationAngle: 100
    })
  }

  const salesSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(33, 150, 243, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(33,150,243,0.02)'
      }],
      rotationAngle: 100
    })
  }
  
  return (
    <>
      {(ordersData?.data.data && salesData?.data.data) ?
        <MultiSeriesLargeSizeWidget
          title={`Динамика продаж ${TimelineRange[dataParams.range].timelineText}`}
          chartData={chartData.slice(ordersHalf)}
          chartUrl={chartUrl}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            color: '#4CAF50',
            name: 'Заказы',
            valueField: 'orders',
            areaColor: ordersSeriesColor
          }}
          rightData={{
            data: currentSalesQuantity || 0,
            diff: salesDiff,
            name: 'Выкупы',
            color: '#007DFF',
            valueField: 'sales',
            areaColor: salesSeriesColor
          }}
          dataParams={dataParams}
          isLoading={ordersFetching || salesFetching}
          setDataParams={setDataParams}>
          <ChartDetail
            half={ordersHalf}
            chartData={chartData}
            leftData={{
              data: currentOrdersQuantity || 0,
              diff: ordersDiff,
              color: '#4CAF50',
              name: 'Заказы',
              valueField: 'orders',
            }}
            rightData={{
              data: currentSalesQuantity || 0,
              diff: salesDiff,
              name: 'Выкупы',
              color: '#007DFF',
              valueField: 'sales',
            }}
          />
        </MultiSeriesLargeSizeWidget>
        :
        <Skeleton/>
      }
    </>
  )
}


const ChartDetail = ({
  leftData,
  rightData }: { leftData: WidgetData, rightData: WidgetData, chartData: any, half: number }) => 

  // const returns = leftData.data - rightData.data
  // const diffReturns = leftData.diff - rightData.diff
  (
    <StyledChartDetails>
      <div>
        <ValueDelta shortValue={true} color={leftData.color} value={leftData.data} delta={leftData.diff} name={leftData.name}/>
      </div>
      <div>
        <ValueDelta shortValue={true} color={rightData.color} value={rightData.data} delta={rightData.diff} name={rightData.name}/>
      </div>
      {/* <div> */}
      {/*  <ValueDelta shortValue={true} value={returns} delta={-diffReturns} name="Возвраты"/> */}
      {/* </div> */}
    </StyledChartDetails>
  )
