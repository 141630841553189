import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

const innValueCheck = (inn) => {
  if (inn !== undefined) {
    return inn?.length === 10 || inn?.length === 12
  }
  return true
}

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  inn: yup
    .string()
    .required('Обязательное поле')
    .test('InnCheck', 'Длина ИНН должна быть 10 или 12 цифр', value => innValueCheck(value))
    .matches(/^[0-9]+$/, 'ИНН может содержать только цифры')
    .transform(yupTransformEmptyStringInNull),
})
