import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { InputLabel } from 'shared/ui/components'
import { GoodsAttributes } from 'pages/goods/edit/CharacteristicStep/interface'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Form } from 'shared/ui/components/form'

import { SCHEMA } from './validationSchema'

import { AttributeLayout } from '../AttributeLayout'

interface AttributesProps {
  attributes: GoodsAttributes
  onChange: (target: {
    name: string;
    value: string | Array<string>;
  }) => void;
  onSubmit: () => void;
  disabled?: boolean;
}


export const Attributes = ({
  attributes,
  onChange,
  onSubmit,
  disabled
}: AttributesProps) => {
  const validate = useValidationSchema(SCHEMA)

  return (
    <Form<GoodsAttributes>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={attributes}
      validate={validate}
      render={() => (
        <>
          <AttributeLayout onChange={onChange} disabled={disabled} />

          <FieldArray name="groups">
            {({ fields: groups }) => groups.map((groupName, index) => (
              <div key={groupName}>
                <InputLabel label={groups.value[index].name} required={false} />
                <AttributeLayout prefixName={groupName} onChange={onChange} disabled={disabled} />
              </div>
            ))}
          </FieldArray>
        </>
      )}
    />
  )
}
