import React, { useMemo } from 'react'
import { Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { pimApi } from 'shared/api'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { Field, Form } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { InputField, InputLabel, SelectFieldWithAutoComplete } from 'shared/ui/components'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import isEmpty from 'lodash/isEmpty'

import { ReceivedData } from '../../lib/types'
import { validationSchema } from '../../lib/validationSchema'

interface AddCategoryModalProps {
  close: () => void;
  accept: (receiveData: ReceivedData) => void
  initialValues?: any
}

export const CategoryModal = ({ accept, close, initialValues }: AddCategoryModalProps) => {

  const { data: categoriesData } = pimApi.categories.useGetCategoriesQuery()
  
  const onSubmit = (data: ReceivedData) => {
    accept(data)
  }

  const categoriesOptions = useMemo(
    () => categoriesData?.data?.map(getOptions) || [],
    [categoriesData])

  const validate = useValidationSchema(validationSchema)
  
  return (
    <Box sx={ { width: '640px' } }>
      <IconCloseButton aria-label="close" onClick={ close }>
        <img src={ CloseIcon } alt="close icon" />
      </IconCloseButton>
      <DialogTitle fontSize={ 20 } whiteSpace="pre-line">
        {initialValues?.name ? 'Изменить категорию' : 'Добавить категорию'}
      </DialogTitle>
      <Box px={3} mt={1}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            errors,
            error,
          }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Box>
                <InputLabel
                  label="Название категории"
                  required={false}
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="name"
                      placeholder="Название категории"
                      component={InputField}
                      fullWidth={true}
                      errorAfterTouch={true}
                      error={error}
                    />
                  </InputWrapper>
                </StyledField>
              </Box>
              <Box mt={3}>
                <InputLabel
                  label="Родительская категория"
                  required={false}
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="parentcategoryId"
                      options={categoriesOptions}
                      placeholder="Родительская категория"
                      component={SelectFieldWithAutoComplete}
                      errorAfterTouch={true}
                      fullWidth={true}
                      error={!!errors?.type_id}
                    />
                  </InputWrapper>
                </StyledField>
              </Box>

              <DialogActions sx={{
                marginTop: '24px',
                padding: 0,
                marginBottom: '24px'
              }}>

                <Box>
                  <Button
                    onClick={close}
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={false}
                    disabled={!isEmpty(errors)}
                    sx={{ marginLeft: 2 }}
                  >
                    {initialValues?.name ? 'Изменить' : 'Добавить'}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )
          }
        />
      </Box>
    </Box>
  )
}