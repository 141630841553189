import React from 'react'
import styled from 'styled-components'

import { getProductEditPath } from '../../../../../../config'
import { StyledLinkName } from '../ActivityProductLink/styled'

const StyledSpan = styled.span`
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const PositionsProductLink = ({ row }) => {
  const path = row?.productId ? getProductEditPath(row?.productId) : null
  if (path)
    return (
      <StyledLinkName to={path} title={row.detail.name}>{row.detail.name}</StyledLinkName>
    )
  return <StyledSpan>{row.detail.name}</StyledSpan>
}