import { CheckCircleOutline, Timer, SaveAlt, Pause } from '@mui/icons-material'

export const RECEIPT_DOC_STATUS_LIST = {
  new: {
    title: 'Ожидание',
    icon: Timer,
  },
  started: {
    title: 'Идет приемка',
    icon: SaveAlt,
  },
  paused: {
    title: 'Приостановлена',
    icon: Pause,
  },
  finished: {
    title: 'Завершена',
    icon: CheckCircleOutline,
  },
  finished_with_diff: {
    title: 'Завершена с расхождениями',
    icon: CheckCircleOutline,
  },
}
