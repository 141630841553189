import React, { memo, useState } from 'react'
import { useQuery } from 'react-query'
import { Alert, Box, CircularProgress, InputAdornment } from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { Search } from '@mui/icons-material'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import useDebounce from 'shared/lib/hooks/useDebounce'
import TextField from '@mui/material/TextField'

import { ProductShortInfoWidget } from './ProductShortInfoWidget'
import { ProductSearchWrapper, ResultList } from './styled'

interface ProductAttributes {
  id?: UniqueId
  name?: string
  marketplaceCode?: string
  marketplaceUrl?: string
  marketplaceName?: string
  cabinetName?: string
  marketplaceCategory?: string
  barcode?: string
  price?: number
  companyName?: string
  autoOrdering?: boolean
}

interface SelectProductWidgetProps {
  captionForSelection?: string
  searchInputRequired: boolean
  searchFunction: (searchString: string) => Promise<ProductAttributes[]>
  quantityRequired: boolean
  onSelectProduct: (productId) => void
  onClearSelection: () => void
  productId?: string
  addInfo?: boolean
}

export const SelectProductWidget = memo((
  { captionForSelection, searchInputRequired, searchFunction, quantityRequired, onSelectProduct, onClearSelection, productId, addInfo } :
      SelectProductWidgetProps) => {
  const [searchString, setSearchString] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<ProductAttributes|undefined>(undefined)
  const debouncedValue = useDebounce(searchString, 200)
  const handleSearchInputStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  const { data, isLoading, isFetching } = useQuery<ProductAttributes[]>({
    queryKey: ['searchString', debouncedValue, productId ],
    queryFn: () => searchFunction(debouncedValue),
    enabled: !! debouncedValue || !searchInputRequired,
  })

  const showingProducts = data?.filter(prod => prod.id !== selectedProduct?.id) ?? []

  const handleAdd = (index) => {
    if (showingProducts !== undefined) {
      setSelectedProduct(showingProducts[index])
      onSelectProduct(showingProducts[index]?.id)
    }
  }
  const handleRemove = () => {
    setSelectedProduct(undefined)
    onClearSelection()
  }

  return (
    <ProductSearchWrapper>
      {(searchInputRequired && !selectedProduct) &&
        <>
          <InputLabel
            label="Поиск продукта"
            required={false}
          />
          <Box mb={1}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={handleSearchInputStringChange}
              value={searchString}
              placeholder="Введите артикул или номенклатуру продукта"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && <CircularProgress size={20} />}
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2} height={(showingProducts.length > 0 || searchString.length === 0 || selectedProduct) ? '' : '40px'}>
              { (data?.length === 0 && searchString.length > 0 && !isFetching) &&
                <Alert
                  sx={{
                    height: '40px',
                    padding: '0',
                    paddingLeft: '18px',
                    alignItems: 'center'
                  }} severity="error">Товары по данному артикулу не найдены</Alert>
              }
            </Box>
          </Box>
        </>
      }
      <ResultList>
        {isEmptyArray(data)
          ? <></>
          :
          <Box mt={1}>
            {showingProducts?.map((item, index) => (
              <ProductShortInfoWidget
                key={item.id}
                productId={item.id}
                name={item.name}
                marketplaceCode={item.marketplaceCode}
                marketplaceUrl={item.marketplaceUrl}
                marketplaceName={item.marketplaceName}
                marketplaceCategory={item.marketplaceCategory}
                barcode={item.barcode}
                cabinetName={item.cabinetName}
                price={item.price}
                addInfo={addInfo}
                companyName={item.companyName}
                autoOrdering={item.autoOrdering}
                disabled={item.id === selectedProduct?.id}
                onAddClick={ () => handleAdd(index) }
              />
            ))}
          </Box>
        }
      </ResultList>
      {selectedProduct &&
        <ResultList>
          {captionForSelection && <InputLabel label={captionForSelection}/>}
          <InputLabel
            label="Выбранный продукт"
            required={false}
          />
          <Box mb={5}>
            <ProductShortInfoWidget
              productId={selectedProduct?.id}
              name={selectedProduct?.name}
              marketplaceCode={selectedProduct?.marketplaceCode}
              marketplaceUrl={selectedProduct?.marketplaceUrl}
              marketplaceName={selectedProduct?.marketplaceName}
              marketplaceCategory={selectedProduct?.marketplaceCategory}
              barcode={selectedProduct?.barcode}
              cabinetName={selectedProduct?.cabinetName}
              price={selectedProduct?.price}
              addInfo={addInfo}
              companyName={selectedProduct?.companyName}
              autoOrdering={selectedProduct?.autoOrdering}
              disabled={false}
              quantityFieldName={quantityRequired ? 'quantity' : undefined}
              onRemoveClick={() => handleRemove()}
            />
          </Box>
        </ResultList>
      }
    </ProductSearchWrapper>
  )
}
)