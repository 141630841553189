import { History as HistoryType } from 'history'
import { IHistory, historyType, pageLiteralType } from 'shared/services/interfaces/history'

export type {
  IHistory,
  historyType,
  pageLiteralType,
  pagesType,
} from 'shared/services/interfaces/history'

export class History implements IHistory {
  pagesMap: Map<pageLiteralType, string> = new Map([['/project', '/project']])

  constructor(private _history: historyType) {}

  get history(): HistoryType {
    return this._history.getHistory()
  }

  goToLocation(location: string): void {
    this._history.push(location)
    this.getLocationPage(location)
  }

  goTo(location: string): void {
    this._history.push(this.getLocationPage(location))
  }

  // eslint-disable-next-line class-methods-use-this
  paramReplacer(
    [param, value]: [string, string | number],
    url: string,
    path: string
  ): string {
    const paramIndex = path
      .split('/')
      .findIndex((str: string) => str.match(param))
    const result = url.split('/').reduce((prev, curr, index, arr) => {
      const slash = index === 0 ? '' : '/'
      const lastIndex = index === arr.length - 1 ? index : null

      if (index === paramIndex) return `${prev}${slash}${value}`
      if (lastIndex && lastIndex < paramIndex)
        return `${prev}${slash}${curr}/${value}`

      return `${prev}${slash}${curr}`
    }, '')

    return result
  }

  getLocationPage(location: string): pageLiteralType | string {
    const { pathname: currentLocation } = this.history.location
    const currentPageKeys = [...this.pagesMap.keys()].filter((key) =>
      currentLocation.startsWith(key)
    )
    const pageKeys = [...this.pagesMap.keys()].filter((key) =>
      location.startsWith(key)
    )

    if (
      pageKeys &&
      pageKeys.length &&
      currentPageKeys &&
      currentPageKeys.length >= 2
    ) {
      const previousPage: string =
        this.pagesMap.get(pageKeys[1] || pageKeys[0]) || location

      pageKeys.forEach((key) =>
        this.pagesMap.set(
          key,
          previousPage.startsWith(location) ? previousPage : location
        )
      )

      return currentPageKeys[1] !== pageKeys[1] ||
        !this.pagesMap.get(pageKeys[1])
        ? previousPage
        : this.pagesMap.get(pageKeys[1] || pageKeys[0]) || ''
    }

    return location
  }
}
