import { ColumnChooser } from '@devexpress/dx-react-grid-material-ui'
import { Settings as SettingsIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { RefObject } from 'react'

type Ref = ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement> | null | undefined

export const ToggleButtonComponent = ({ onToggle, buttonRef }: ColumnChooser.ToggleButtonProps) => (
  <IconButton
    onClick={onToggle}
    href=""
    aria-label="open"
    ref={buttonRef as Ref}
    title="Настроить столбцы в таблице"
  >
    <SettingsIcon />
  </IconButton>
)
