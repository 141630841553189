import { compose } from 'redux'

import { withQueryClient } from './with-query-client'
import { withRedux } from './with-redux'
import { withTheme } from './with-theme'
import { withRouter } from './with-router'
import { withSnackbar } from './with-snackbar'
import { withDateLocalization } from './with-date-localization'
import { withApolloClient } from './with-apollo-client'
import { withSseStream } from './with-sse-stream'


export const withProviders = compose<() => JSX.Element>(
  withDateLocalization,
withSnackbar,
withSseStream,
withApolloClient,
withQueryClient,
withRedux,
withTheme,
withRouter,
)