import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box } from '@mui/material'

const arrowStyle = {
  color: '#007DFF',
}

export const ExpandButtonCell = ({ expandNested, setExpandNested }) => {
  const handleExpandChange = () => {
    setExpandNested(prev => !prev)
  }
  return (
    <Box
      height="100%"
      width="100%"
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={{ cursor: 'pointer' }}
      onClick={handleExpandChange}>
      {expandNested ?
        <KeyboardArrowDownIcon style={arrowStyle} /> :
        <KeyboardArrowRightIcon style={arrowStyle} />
      }
    </Box>
  )
}