import { Button } from '@mui/material'
import { useFormState } from 'react-final-form'

export const SaveButtonCell = ({ dirty, row, algorithmId, handleSubmit }) => {
  const isDirty = dirty || row.advisor.selectedAlgorithmId !== algorithmId
  const { valid } = useFormState()
  if (isDirty) return (
    <Button 
      onClick={handleSubmit}
      disabled={!valid}
      sx={{ 
        height: '42px',
        marginTop: 'auto', 
        marginBottom: 'auto',
        marginLeft: 'auto'
      }} 
      variant="text">Сохранить</Button>
  )

  return <></>
}