import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const validationSchema = yup.object({
  name: yup.string().max(1000).required(),
  categoryId: yup.object().shape({
    fullPathName: yup.string().required(),
    id: yup.number().required().transform(yupTransformEmptyStringInNull),
  }).required(),
  brand: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required().transform(yupTransformEmptyStringInNull),
  }).required(),
  code: yup.string().required().max(100),
  auxCode: yup.string().nullable(),
  unitId: yup.string().nullable(),
  description: yup.string().required(),
  width: yup.number().min(0).transform(yupTransformEmptyStringInNull).required(),
  height: yup.number().min(0).transform(yupTransformEmptyStringInNull).required(),
  depth: yup.number().min(0).transform(yupTransformEmptyStringInNull).required(),
  weight: yup.number().min(0).transform(yupTransformEmptyStringInNull).required(),
  suppliers: yup.array().of(
    yup.object().shape({
      contragentId: yup.number().required().transform(yupTransformEmptyStringInNull),
      incomingPrice: yup.number().transform(yupTransformEmptyStringInNull).nullable(),
    }).required()
  ),
  tnvedCode: yup.object().shape({
    code: yup.string().nullable(),
    name: yup.string().nullable(),
  }).nullable(),
  goodsType: yup.string(),
  // TODO https://github.com/final-form/final-form/issues/35#issuecomment-453778061
  // components: yup.array().when('goodsType', {
  //   is: 'bundle',
  //   then: (schema) => schema.min(2, 'Выберите минимум 2 разных товара, чтобы создать комплект')
  // }).when('goodsType', {
  //   is: 'pack',
  //   then: yup.array().min(1, 'Выберите минимум один товар, чтобы создать спайку')
  // }),
  madeInCountryId: yup.number().required().transform(yupTransformEmptyStringInNull),
  vatRate: yup.string().required(),
  assortment: yup.array().when('goodsType', {
    is: 'assortment',
    then: yup.array().min(2, 'Выберите минимум 2 разных товара, чтобы создать ассортимент')
  })
  // tnvedCode: yup.number().min(0),
  // shipping: yup.object({
  //   name: yup.string(),
  //   phone: yup.object({
  //     code: yup.string().matches(/^\+\d+$/i),
  //     number: yup.number().max(10),
  //   }),
  //   address: yup.string(),
  //   zip: yup.string(),
  // }),
  // billing: yup.object({
  //   name: yup.string(),
  //   address: yup.string(),
  //   zip: yup.string(),
  // }),
  // items: yup.array().of(
  //   yup.object({
  //     id: yup.number(),
  //     price: yup.number(),
  //     quantity: yup.number(),
  //   })
  // ),
})
