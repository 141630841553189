const ROW_HEIGHT = 52
const HEADER_HEIGHT = 56
const UPPER_PAGE_CONTROLS = 94
const UPPER_TABLE_CONTROLS = 82 + 16 + 48 + 64
const TABLE_HEADER_FOOTER = 45 + 56
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const useRowsPerPage = () => {
  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      HEADER_HEIGHT -
      UPPER_PAGE_CONTROLS -
      UPPER_TABLE_CONTROLS -
      TABLE_HEADER_FOOTER - 10 ) /
      ROW_HEIGHT
  )

  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT

  return { calculatedRowsPerPage }
}
