/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const VideoRequirements = () => {
  const Text = 'Формат — MP4, MOV. Размер — до 70 Мбайт'
  const Element = (<StyledTextContainer>
    <ul>
      <li>формат — MP4, MOV</li>
      <li>размер — до 70 Мбайт</li>
    </ul>
  </StyledTextContainer>
  )

  return { Element, Text }
}