import Slider from '@mui/material/Slider'
import styled from 'styled-components'

const DEFAULT_START_COLOR = '#979797'
const DEFAULT_END_COLOR = '#f980ab'

export const StyledSlider = styled(Slider)<{
  $linearGradientPercent: number
  $startColor?: string
  $endColor?: string
}>`
  .MuiSlider-valueLabel {
    background-color: rgba(0, 0, 0, 0);
    .MuiSlider-valueLabelLabel {
      color: #2197f3;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .MuiSlider-track {
    height: 3px;
  }

  .MuiSlider-thumb {
  }

  .MuiSlider-rail {
    opacity: 0.8;
    background: ${({ $linearGradientPercent, $startColor, $endColor }) =>
    `linear-gradient(to right, ${
      $startColor || DEFAULT_START_COLOR
    } ${$linearGradientPercent}%, ${
      $startColor || DEFAULT_START_COLOR
    } , ${
      $endColor || DEFAULT_END_COLOR
    } ${$linearGradientPercent}%, ${
      $endColor || DEFAULT_END_COLOR
    });`};
  }

  /* .MuiSlider-markLabel {
    transform: rotate(314deg);
    margin-left: -35px;
    margin-top: 11px;
  } */
`
