import { copackingApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import { DateFormats } from 'shared/config/dateFormats'

type ColumnName = keyof Omit<copackingApi.CoPackingOrderWithoutPositions,
  'versionNo' |
  'changed' |
  'statusId'
>
type ColumnNames = Record<ColumnName, ColumnName>

const columns: ColumnNames = {
  id: 'id',
  externalId: 'externalId',
  changer: 'changer',
  created: 'created',
  creator: 'creator',
  marketplacewarehouseAddress: 'marketplacewarehouseAddress',
  supplyType: 'supplyType',
  targetMarketplacewarehouseAddress: 'targetMarketplacewarehouseAddress',
  status: 'status'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.id,
      columnName: columns.id,
      title: 'ID',
      width: 128,
      sortingEnabled: false,
    },
    {
      name: columns.externalId,
      columnName: columns.externalId,
      title: 'Задание',
      width: 128,
      sortingEnabled: false,
      cellType: CellType.CoPackingOrderLink
    },
    {
      name: columns.status,
      columnName: columns.status,
      title: 'Время с момента начала',
      width: 250,
      sortingEnabled: true,
      cellType: CellType.RelativeDateCell
    },
    {
      name: columns.creator,
      columnName: columns.creator,
      title: 'Автор',
      width: 144,
      sortingEnabled: true,
    },
    {
      name: columns.supplyType,
      columnName: columns.supplyType,
      title: 'Тип поствки',
      width: 123,
      sortingEnabled: false,
    },
    {
      name: columns.created,
      columnName: columns.created,
      title: 'Дата создания',
      width: 'auto',
      sortingEnabled: false,
      dataFormat: DateFormats.dayMonthYear,
      cellType: CellType.Date
    },
    {
      name: columns.targetMarketplacewarehouseAddress,
      columnName: columns.targetMarketplacewarehouseAddress,
      title: 'Адрес сдачи груза',
      width: 250,
      sortingEnabled: false,
    },
    {
      name: columns.marketplacewarehouseAddress,
      columnName: columns.marketplacewarehouseAddress,
      title: 'Адрес РЦ',
      width: 250,
      sortingEnabled: false,
    },
  ],
}
