import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { pimApi } from 'shared/api'


const downloadGoodsFile = async (queryParams) => {
  const { sort, order, ...rest } = queryParams
  return (
    api.post<File>('/goods/excel/goods', toSnakeCase(rest), { params: { sort, order }, responseType: 'blob' })
  )
}

export const useDownloadGoodsFile = (
  options?: UseMutationOptions<
    AxiosResponse<File>,
    errorType,
    pimApi.goods.DownloadGoodsFileParams
    >
) => useMutation(
  queryParams => downloadGoodsFile(queryParams),
  options,
)