import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api/index'

interface CopackingOrdersSearchQueryParams {
  copackingordersId: number
  code?: string
  goodsId?: number
}

const getOrderForPackingSearchQueryKey = (
  queryParams: CopackingOrdersSearchQueryParams
) =>
  [
    'get',
    `trade/orders/copackingorders/${queryParams.copackingordersId}/positions/newproducts`,
    queryParams,
  ] as const

 type GetOrderForPackingSearchQueryKey = ReturnType<
  typeof getOrderForPackingSearchQueryKey
>

export function getOrderForPackingSearch(copackingordersId: number, code?: string, goodsId?: number) {
  // eslint-disable-next-line consistent-return
  const searchString = () => {
    if (code?.length) return `?code=${code}`
    if (goodsId) return `?goods_id=${goodsId}`
  }
  return api.get(`trade/orders/copackingorders/${copackingordersId}/positions/newproducts${searchString()}`)
    .then((res) => res.data)
}

export const fetchOrderForPackingSearch = (
  context: QueryFunctionContext<GetOrderForPackingSearchQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getOrderForPackingSearch(queryParams.copackingordersId, queryParams.code, queryParams.goodsId)
}

export const useFetchOrderForPackingSearch = (queryParam: CopackingOrdersSearchQueryParams) =>
  useQuery<
    tradeApi.CoPackingOrder,
    errorType,
    tradeApi.CoPackingOrder,
    GetOrderForPackingSearchQueryKey
  >(getOrderForPackingSearchQueryKey(queryParam), fetchOrderForPackingSearch)