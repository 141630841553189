import { pimApi } from 'shared/api'
import {
  faLink,
  faDiceD6,
  faDiceFour,
  IconDefinition,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'

export const GoodsTypeName: Record<pimApi.goods.GoodsType, string> = {
  'common': 'товар',
  'bundle': 'комплект',
  'pack': 'спайка',
  'assortment': 'ассортимент'
}

export const goodsTypeIcon: Record<pimApi.goods.GoodsType, IconDefinition> = {
  common: faDiceD6,
  bundle: faDiceFour,
  pack: faLink,
  assortment: faLayerGroup,
}
