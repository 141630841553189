import { memo, useCallback } from 'react'
import { Box, Grid } from '@mui/material'
import { Field } from 'shared/ui/components/form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { wbproxyApi } from 'shared/api'
import styled from 'styled-components'

export const getOptionFromCategory = (
  category: wbproxyApi.model.Category
) => ({
  label: `${category.parentName}/${category.subjectName}`,
  value: category.subjectId
})

const StyledTextField = styled(Box)`
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiAutocomplete-input {
    position: relative;
    top: 1px;
  }
`

export const NewWBCard = memo(({ companymarketplaceId } : { companymarketplaceId: number }) => {
  const fetchCatalog = useCallback(
    (search: string) =>
      wbproxyApi
        .fetchCategories(search, companymarketplaceId)
        .then((data) => data.data.map(getOptionFromCategory)),
    [companymarketplaceId]
  )

  return (
    <>
      <Box mb={1} mt={2.5}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Категория</span>
          </Grid>
          <Grid item={true} xs={12}>
            <StyledTextField>
              <Field
                name="category"
                component={AutocompleteCatalogSearchField}
                fetchCatalog={fetchCatalog}
                allowEmptyString={true}
                placeholder="Категория"
              />
            </StyledTextField>
          </Grid>
        </Grid>
      </Box>
    </>
  )
})
