import { Form } from 'react-final-form'
import { Box } from '@mui/material'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { DialogTitleWithBtn } from 'shared/ui/components'

import { PriceChangeModalFormRender } from './PriceChangeModalFormRender'

import { validationSchema, creationValidationSchema } from '../lib'

const DIALOG_TITLE_WITH_BTN_SX = {
  padding: '0',
  fontSize: 20,
  fontWeight: 500,
}

const MODAL_STYLES = { width: '423px', paddingTop: '32px', padding: '24px' }

interface PriceChangeModalProps {
  close: () => void
  discount?: number
  discountPrice?: number
  isLoading?: boolean
  price?: number
  nomenclatureId?: UniqueId
  productId: number
  onAdd?: (any) => void
}

export const PriceChangeModal = ({
  close,
  isLoading,
  price,
  discount,
  productId,
  onAdd,
}: PriceChangeModalProps) => {
  const { mutate: createProductPrice, isLoading: isCreating } =
    pimApi.products.simple.useCreateProductPriceChangeMutation()

  const isPriceChangeable = !!price

  const validate = useValidationSchema(isPriceChangeable ? validationSchema : creationValidationSchema)
  const onSubmit = (values) => {
    createProductPrice(
      {
        productId,
        newProductPrice: {
          ...(values.comments ? { comments: values.comments } : {}),
          price: values.newPrice,
          pricechangereasonId: values.pricechangereasonId,
          ...(values.revertDate ? { revertDate: values.revertDate } : {}),
          ...(values.changeDate ? { changeDate: values.changeDate } : {}),
        },
      },
      {
        onSuccess: (data) => {
          const res = data?.data

          if(res) {
            onAdd?.({
              price: res.price,
              futurePrices: res.futurePrices,
              versionNo: res.versionNo,
              priceRecentHistory: res.priceRecentHistory,
            })
          }
          
          snackActions.info('Цена успешно сохранена!')
          close()
        },
      }
    )
  }

  const handleClose = () => {
    close()
  }

  const initialValues = {
    price,
    discount,
  }

  return (
    <Box sx={MODAL_STYLES}>
      <DialogTitleWithBtn onClose={handleClose} sx={DIALOG_TITLE_WITH_BTN_SX}>
        {isPriceChangeable ? 'Изменить цену' : 'Установить цену'}
      </DialogTitleWithBtn>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <PriceChangeModalFormRender
            handleSubmit={handleSubmit}
            isLoading={isLoading || isCreating}
          />
        )}
      />
    </Box>
  )
}
