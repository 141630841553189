// @ts-nocheck
import CircularProgress from '@mui/material/CircularProgress'
import { useFileUrl } from 'pages/goods/model/hooks'
import imagePlaceholder from 'assets/images/defaultGoodImage.svg'

import { PreloaderWrapper } from './styled'

interface RequireProps {
  src: string
  loadingIconSize?: number
  defaultImgSrc?: string
  defaultImgParams?: {
    width?: number | string
    height?: number | string
  }
}

const CIRCULAR_PROGRESS_SIZE = 20

export function withImage<T extends RequireProps = RequireProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const ComponentWithImage = ({
    loadingIconSize = CIRCULAR_PROGRESS_SIZE,
    defaultImgSrc,
    style,
    defaultImgParams,
    showPlaceholder,
    ...rest
  }: T) => {
    const { isLoading, data, status } = useFileUrl(rest.src)
    const isAddDefaultImgParams = Boolean(!data) && Boolean(defaultImgSrc)

    if (isLoading) {
      return (
        <PreloaderWrapper
          className="imagePreloader"
          onDragStart={(e) => {
            e.preventDefault()
          }}
        >
          <CircularProgress size={loadingIconSize} />
        </PreloaderWrapper>
      )
    }

    if (status === 'error') {
      return (
        <img style={style} src={imagePlaceholder} alt="placeholder"/>
      )
    }

    return (
      <WrappedComponent
        {...(rest as T)}
        style={style}
        src={data || defaultImgSrc}
        {...(isAddDefaultImgParams ? defaultImgParams : {})}
      />
    )
  }

  return ComponentWithImage
}
