import { Box, Grid, TextField } from '@mui/material'
import {
  CatalogSelectField,
  Field,
  InputField,
} from 'shared/ui/components/form'
import { pimApi } from 'shared/api'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { useFormState } from 'react-final-form'

import { MemoECOMMarketplace } from '../../WB/ui/ProductInfoTabs/ui/ECOMWB'
import { DisplayedGoodsDataController } from '../../../model/DisplayedGoodsDataController'

interface GeneralInformationProps {
  editingDisabled?: boolean,
  goods: pimApi.goods.Goods | null
}

export const GeneralInformation = ({ editingDisabled, goods } : GeneralInformationProps ) => {
  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()
  const { values } = useFormState()
  return (
    <>
      <MemoECOMMarketplace
        leftSideTooltip="Атрибуты товара, к которому привязан продукт"
        changeDisplayedGoodsData={changeDisplayedGoodsData('generalInformation')}
        displayGoodsData={displayedGoodsData.generalInformation}
      />
      <Box sx={ { display: 'flex', flexDirection: 'column', gap: '16px' } }>
        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description="text">
          <TextField
            label="Название"
            variant="outlined"
            size="small"
            fullWidth={ true }
            value={ goods?.name ?? '' }
            disabled={ true }
          />
          <Field
            name="name"
            label="Название*"
            component={ InputField }
            disabled={ editingDisabled }
          />
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description="text">
          <TextField
            label="Категория"
            variant="outlined"
            size="small"
            fullWidth={ true }
            value={ goods?.category ?? '' }
            disabled={ true }
          />

          <Field
            component={ InputField }
            name="categoryName"
            label="Категория*"
            variant="outlined"
            size="small"
            fullWidth={ true }
            disabled={ editingDisabled }
          />
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} description="text">
          <TextField
            label="Артикул"
            variant="outlined"
            size="small"
            fullWidth={ true }
            value={ goods?.code ?? '' }
            disabled={ true }
          />
          <Field
            component={ InputField }
            name="code"
            label="Артикул поставщика на маркетплейсе"
            variant="outlined"
            size="small"
            fullWidth={ true }
            disabled={ editingDisabled }
          />
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} arrow={ null } description="text">
          { null }
          <>
            <Field
              name="packagingrequirementId"
              component={ CatalogSelectField }
              catalog="pim/packagingrequirements"
              label="Требования по упаковке*"
              errorAfterTouch={ true }
              disabled={ editingDisabled }
            />
          </>
        </InputsWrapper>

        <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation}>
          <Box>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <TextField
                  label="Ширина, см"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={goods?.width ?? ''}
                  disabled={true}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  label="Высота, см"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={goods?.height ?? ''}
                  disabled={true}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  label="Глубина, см"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={goods?.depth ?? ''}
                  disabled={true}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  label="Вес с упаковкой, кг"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={goods?.weight ?? ''}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid item={true} container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <Field
                name="width"
                label={`Ширина${values?.dimensionUnitRus ? `, ${values?.dimensionUnitRus}` : ''}`}
                component={InputField}
                type="number"
                errorAfterTouch={true}
                disabled={editingDisabled}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Field
                name="height"
                label={`Высота${values?.dimensionUnitRus ? `, ${values?.dimensionUnitRus}` : ''}`}
                component={InputField}
                type="number"
                errorAfterTouch={true}
                disabled={editingDisabled}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Field
                name="depth"
                label={`Глубина${values?.dimensionUnitRus ? `, ${values?.dimensionUnitRus}` : ''}`}
                component={InputField}
                type="number"
                errorAfterTouch={true}
                disabled={editingDisabled}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Field
                name="weight"
                label={`Вес с упаковкой${values?.weightUnitRus ? `, ${values?.weightUnitRus}` : ''}`}
                component={InputField}
                type="number"
                errorAfterTouch={true}
                disabled={editingDisabled}
              />
            </Grid>
          </Grid>
        </InputsWrapper>
        {/* <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} arrow={ null }> */}
        {/*   { null } */}
        {/*   <> */}
        {/*     <InputWrapper> */}
        {/*       <Field */}
        {/*         name="newBox" */}
        {/*         label="Новый короб" */}
        {/*         type="checkbox" */}
        {/*         component={ CheckboxField } */}
        {/*         disabled={ editingDisabled } */}
        {/*       /> */}
        {/*     </InputWrapper> */}
        {/*   </> */}
        {/* </InputsWrapper> */}
      </Box>
    </>
  )
}

