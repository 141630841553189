import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Company } from './models'

import { api } from '../../base'



export function fetchCompanies(): Promise<AxiosResponse<Company[]>> {
  return api.get<Company[]>('/customer/companies')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'customer/companies'] as const

function getCompanies() {
  return fetchCompanies().then((res) => res.data)
}

export function useCompanyList() {
  const companyListQuery = useQuery<
    Array<Company>,
    errorType,
    Array<Company>,
    QueryKey
  >(QUERY_KEY, getCompanies, {
    refetchOnMount: false
  })

  return {
    companyListQuery,
  } as const
}