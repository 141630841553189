/* eslint-disable */
// @ts-nocheck
import { call, put } from 'redux-saga/effects'
import { AxiosError } from 'axios'
import { getErrorType } from 'shared/lib/transform/getErrorType'
import keyMirror from 'keymirror-nested'
import { companyType } from 'shared/services/interfaces/company'
import watcherCreator from 'store/watcherCreator'
import { actionsCreator, actionType } from 'store/actionsCreator'
import { getCompanies } from 'shared/api/customer'

export interface companyStateType {
  company: companyType | null | undefined
  list: companyType[] | null | undefined
  error: errorType | null | undefined
}

export type companyActionType = actionType<
  companyType | companyType[],
  errorType
>

export const COMPANY = keyMirror(
  {
    GET: null,
    LIST: null,
  },
  '_',
  'COMPANY'
)

export const company = actionsCreator(COMPANY.GET)
export const companyList = actionsCreator(COMPANY.LIST)

export const companySelector = (state: {
  userReducer: companyStateType
}): companyStateType => state.userReducer

const initialState = {
  company: null,
  list: null,
  error: null,
}

export const companyReducer = (
  state = initialState,
  action: companyActionType
): companyStateType => {
  switch (action.type) {
    case company.getType('success'):
      return { ...state, company: <companyType>action.data }
    case companyList.getType('error'):
      return { ...state, list: <companyType[]>action.data }
    default:
      return state
  }
}

export const companyWatcher = watcherCreator(
  'COMPANY',
  function* ({ type, data }: companyActionType) {
    if (type === companyList.getType('pending')) {
      try {
        const context = { getCompanies }
        const result: SagaCall<typeof getCompanies> = yield call<
          typeof getCompanies
        >(context.getCompanies, data as string)
          yield put(companyList.success(result.data))
      } catch (error) {
        yield put(companyList.error(getErrorType(error as AxiosError)))
      }
    }
    // if (type === company.getType('pending')) {
    //   try {
    //     const context = { getCompany }
    //     const result: SagaCall<typeof getCompany> = yield call<
    //       typeof getCompany
    //     >(context.getCompany, data as string)
    //       yield put(company.success(result.data))
    //   } catch (error) {
    //     yield put(company.error(getErrorType(error as AxiosError)))
    //   }
    // }
  }
)
