import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { Photo } from './Photo'

export const SortablePhoto = (props) => {
  const { items, url, index, withMainElement, largeContainer, disabled } = props
  const sortable = useSortable({ id: url })
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition, active,
  } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Photo
      disabled={disabled}
      withMainElement={withMainElement}
      ref={setNodeRef}
      largeContainer={largeContainer}
      isDragging={isDragging}
      isActive={!!active}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    >
      {items[index]}
    </Photo>
  )
}
