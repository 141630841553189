import { Avatar } from '@mui/material'
import React, { memo } from 'react'

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string
  userName: string
  size: number
  fontSize: number
}

export const UserImage = memo(({ src, userName, size, fontSize }: ImageProps) => {
  let altName: string
  if (userName.split(/\s+/).length === 1) {
    // eslint-disable-next-line prefer-destructuring
    altName = userName.split(/\s+/)[0][0].toUpperCase()
  } else {
    altName = userName.split(/\s+/)[0][0].toUpperCase() + userName.split(/\s+/)[1][0].toUpperCase()
  }
  return (
    <Avatar src={src} sx={{ width: size, height: size, fontSize, alignItems: 'center' }}>
      <span style={{ marginTop: '1px' }}>{altName}</span></Avatar>)
}
)
