import {  useState } from 'react'
import { Box } from '@mui/material'
import { ModalContent } from 'shared/ui/components/ModalComponents'

interface DialogProps {
  close: () => void
  accept: () => void
  title?: string
  acceptBtnText?: string
  acceptBtnColor?: 'primary';
  variant?: 'text' | 'contained';
}

const DEFAULT_CONFIRM = 'Действительно удалить?'
const DEFAULT_ACCEPT_BTN_TEXT = 'Удалить'

export const DialogContentWithTitle = ({ close, accept, title, acceptBtnText, acceptBtnColor, variant }: DialogProps) => {
  const [isLoading, setIsloading] = useState(false)
  const hadleAccept = () => {
    setIsloading(true)
    accept()
  }
  
  return (
    <Box >
      <ModalContent
        close={close}
        accept={hadleAccept}
        title={title || DEFAULT_CONFIRM}
        acceptBtnText={acceptBtnText || DEFAULT_ACCEPT_BTN_TEXT}
        acceptBtnColor={acceptBtnColor || 'error'}
        isLoading={isLoading}
        variant={variant}
      />
    </Box>
  )
}
