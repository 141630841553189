import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'

type GetContentRatingQueryKey = ReturnType<typeof getContentRatingQueryKey>

export const getContentRatingQueryKey = (productId?: UniqueId) =>
  ['get', 'pim/products/wb/rating', productId] as const

export const getContentRating = (productId?: UniqueId) =>
  api
    .get(
      `/pim/products/wb/product/${productId}/rating`,
      {
        params: { method: 'content-card' }
      }
    )

export const fetchContentRating = (
  context: QueryFunctionContext<GetContentRatingQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getContentRating(queryParams)
}

export const useGetContentRatingQuery = (
  productId?: UniqueId,
) =>
  useQuery(getContentRatingQueryKey(productId), fetchContentRating,
    {
      refetchOnMount: false, keepPreviousData: true, staleTime: Infinity
    })
