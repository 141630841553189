import { QueryFunctionContext, useMutation, useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

export interface GetPriceHistoryParams {
  productId?: UniqueId
  fromDate?: string,
  toDate?: string
}

export const getPriceHistoryQueryKey = (
  queryParams: GetPriceHistoryParams
) =>
  [
    'get',
    `/pim/products/wb/product/${queryParams.productId}/pricehistory`,
    queryParams
  ] as const

type PriceHistoryQueryKey = ReturnType<typeof getPriceHistoryQueryKey>


function getPriceHistoryFunc (queryParams: GetPriceHistoryParams) {
  const {
    productId,
    fromDate,
    toDate
  } = queryParams
  return api
    .get<pimApi.products.WBPriceHistory>(
      `/pim/products/wb/product/${productId}/pricehistory`, { params: { from_date: fromDate, to_date: toDate } }
    )
    .then((res) => res.data)
}

export const getPriceHistory = (context: QueryFunctionContext<PriceHistoryQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getPriceHistoryFunc(queryParams)
}

export const useFetchPriceHistoryQuery = (
  params: { 
    productId: UniqueId,
    fromDate?: string,
    toDate?: string,
  },
) =>
  useQuery<
    pimApi.products.WBPriceHistory,
    AxiosError<errorType>,
    pimApi.products.WBPriceHistory,
    PriceHistoryQueryKey
  >(
    getPriceHistoryQueryKey(params),
    getPriceHistory, { refetchOnMount: false, keepPreviousData: true }
  )

interface CreateProductPriceChangePayload {
  productId: number
  newProductPrice: pimApi.products.SetWBPriceInfo
}

export const createProductPriceChange = ({
  productId,
  newProductPrice,
}: CreateProductPriceChangePayload) =>
  api.post<pimApi.price.WBPriceDetail>(
    `/pim/products/wb/product/${productId}/price`,
    newProductPrice
  )

export const useCreateProductPriceChangeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.price.WBPriceDetail>,
    AxiosError<errorType>,
    CreateProductPriceChangePayload
  >(createProductPriceChange)

interface DeleteProductPricePayload {
  productId: number
  priceId?: number
  data: {
    id: string
    versionNo?: number
    comments?: string
  }
}

export const deleteProductPrice = ({
  productId,
  priceId,
  data,
}: DeleteProductPricePayload) =>
  api.delete<DeletingObject>(
    `/pim/products/wb/product/${productId}/price/${priceId}`,
    {
      data,
    }
  )

export const useDeleteProductPriceMutation = () =>
  useMutation<
    AxiosResponse<DeletingObject>,
    AxiosError<errorType>,
    DeleteProductPricePayload
  >(deleteProductPrice)
