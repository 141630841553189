import { useQuery } from 'react-query'
import { api } from 'shared/api/base'

import { CoPackingOrder } from './models'

export const getOrderDetailsQueryKey = (copackingOrderId: number) =>
  ['get', `copacking/copackingorders/${copackingOrderId}`] as const

type GetOrderDetailsQueryKey = ReturnType<typeof getOrderDetailsQueryKey>

const getOrderDetails = (copackingOrderId: number) => api.get<CoPackingOrder>(
  `/copacking/copackingorders/${copackingOrderId}`).then(res => res.data)

export const useGetOrderDetailsQuery = (copackingOrderId: number) =>
  useQuery<
    CoPackingOrder,
    errorType,
    CoPackingOrder,
    GetOrderDetailsQueryKey
  >(
    getOrderDetailsQueryKey(copackingOrderId),
    () => getOrderDetails(copackingOrderId),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
