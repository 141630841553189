import { memo } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useSelector } from 'react-redux'
import { useToggle } from 'shared/lib/hooks/useToggle'
import { detailIdSelector, detailStatusSelector } from 'store/doc/selectors'
import { ReceiptDocStatusEnum } from 'shared/lib/generated/wms/Api'

import { StyledTableStubCell, StyledDialog } from './styled'
import { NewProduct } from './NewProduct'

const StubHeaderCellComponentMemo = () => {
  const [isShowDialog, setIsShowDialog] = useToggle()
  const docDetailStatus = useSelector(detailStatusSelector)
  const docDetailId = useSelector(detailIdSelector)

  const isDisabled = docDetailStatus !== ReceiptDocStatusEnum.Started

  return (
    <StyledTableStubCell>
      <Tooltip title={isDisabled ? '' : 'Добавить товар в документ'}>
        <IconButton
          onClick={setIsShowDialog}
          disabled={isDisabled}
        >
          <MoreVertOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <StyledDialog
        onClose={setIsShowDialog}
        aria-labelledby="add-new-product"
        open={isShowDialog}
      >
        <NewProduct onClose={setIsShowDialog} docId={docDetailId} />
      </StyledDialog>
    </StyledTableStubCell>
  )
}

export const StubHeaderCellComponent = memo(StubHeaderCellComponentMemo)