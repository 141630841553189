import { CreateSupplier } from 'features/admin/CreateSupplier/CreateSupplier'
import { Typography, Box, Paper } from '@mui/material'

export const CreateSupplierPage = () => (
  <Box my={3}>
    <Typography component="h1" variant="h3" fontSize="1.5rem">
      Создание нового поставщика
    </Typography>
    <Box mt={3}>
      <Paper>
        <CreateSupplier />
      </Paper>
    </Box>
  </Box>
)