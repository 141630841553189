import React, { useCallback, useRef } from 'react'
import { UserImage } from 'features/admin/User/EditUser/ui/UserImage'
import DeleteIcon from '@mui/icons-material/Delete'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { CircularProgress } from '@mui/material'

import { Avatar, DeleteAvatar, EditPhoto } from './styled'

import { IAvatarProps } from '../lib/types'

export const AvatarWidget = ({ photo, altName, disabled, deletePhoto, uploadPhoto, isLoading }: IAvatarProps) => {

  const inputRef = useRef<HTMLInputElement>(null)
  const handleAvatarPlaceHolderClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [inputRef])

  return (
    <Avatar>
      { !isLoading && photo ?
        <>
          <UserImage fontSize={ 36 } size={ 96 } src={ URL.createObjectURL(photo) } userName={altName}/>
          { !disabled &&
            <>
              { photo.size > 0 &&
                <DeleteAvatar onClick={ deletePhoto }>
                  <DeleteIcon sx={ { margin: '4px 5px 5px 3px', color: 'rgba(0, 0, 0, 0.38)' } }/>
                </DeleteAvatar> }
              <EditPhoto onClick={ handleAvatarPlaceHolderClick }>
                <CameraAltIcon sx={ { margin: '4px 5px 5px 3px', color: 'rgba(0, 0, 0, 0.38)' } }/>
                <input
                  ref={ inputRef }
                  accept="image/jpg, image/jpeg, image/png, image/webp"
                  type="file"
                  multiple={ true }
                  onChange={ uploadPhoto }
                  style={ { display: 'none' } }
                />
              </EditPhoto>
            </>
          }
        </> :
        <CircularProgress/>
      }
    </Avatar>
  )
}