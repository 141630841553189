import { Button } from '@mui/material'
import styled from 'styled-components'

export const StyledButtonGroup = styled(Button)<{ $active: boolean, $isOtherButton?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
  letter-spacing: 1px;
  color: ${({ $active, $isOtherButton }) => (!$active && $isOtherButton) ? 'rgba(0, 0, 0, 0.54)' : '' };
  background-color: ${({ $active }) => $active ? 'rgba(33,150,243,0.15)' : '' };
  pointer-events: ${({ $active, $isOtherButton }) => $active || $isOtherButton ? 'none' : '' };
`