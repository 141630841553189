import {
  AdviceCell,
  ChartCell, CommentCell,
  CurrentPriceCell, DateCell,
  ExpandButtonCell,
  NewPriceCell,
  ProductInfoCell,
  SelectedAlgorithmCell,
} from '../../../ui/tableCells'

export const PriceAdviceCells = [
  {
    name: '',
    width: '48px',
    padding: '0px',
    Component: ExpandButtonCell
  },
  {
    name: 'Дата',
    width: '80px',
    padding: '10px 16px 8px 16px',
    Component: DateCell
  },
  {
    name: 'Продукт',
    width: '220px',
    padding: '0px 16px 0px 16px',
    Component: ProductInfoCell
  },
  {
    name: '',
    width: '40px',
    padding: '17px 8px 16px 8px',
    Component: ChartCell
  },
  {
    name: 'Алгоритм',
    width: '200px',
    padding: '6px 16px 6px 16px',
    Component: SelectedAlgorithmCell
  },
  {
    name: 'Текущая цена',
    width: '194px',
    padding: '4px 16px 8px 16px',
    Component: CurrentPriceCell
  },
  {
    name: '',
    width: '48px',
    padding: '18px 12px 8px 12px',
    Component: AdviceCell
  },
  {
    name: 'Новая цена',
    width: '194px',
    padding: '4px 16px 8px 16px',
    Component: NewPriceCell
  },
  {
    name: 'Причина',
    width: 'auto',
    padding: '8px 16px 8px 16px',
    Component: CommentCell
  },
]