
/* eslint-disable class-methods-use-this */

import { IRouterService } from 'shared/services/interfaces/IRouterService'
import { browserHistory } from 'shared/services/browserHistory'

const { history } = browserHistory

class RouterService<P extends string> implements IRouterService<P> {
  private getSearchParams(): URLSearchParams {
    const queryString = window.location.search

    return new URLSearchParams(queryString)
  }

  public getParam(paramName: P): string | null {
    const params = this.getSearchParams()
    
    return params.get(paramName)
  }

  public setParam(name: P, value: string) {
    const params = this.getSearchParams()

    params.set(name, value)

    history.push({
      search: params.toString()
    })
  }
}

export const routerService = new RouterService()