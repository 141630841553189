import { user } from 'shared/api'

import { DashboardPage } from './DashboardPage/DashboardPage'

export const Home = () => {
  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()
  return (
    <>
      {userSettingsQuery?.data &&
      <DashboardPage userSettingsQuery={userSettingsQuery?.data}/>
      }
    </>
  )
}
