import React from 'react'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { useForm, useFormState } from 'react-final-form'
import _ from 'lodash'
import styled from 'styled-components'
import maskedBookmark from 'assets/images/icons/BookmarkHalf.svg'
import bookmarkActive from 'assets/images/icons/BookmarkFilled.svg'
import bookmark from 'assets/images/icons/BookmarkBorder.svg'
import bookmarkGrey from 'assets/images/icons/BookmarkBorderGrey.svg'
import { isNil } from 'shared/lib/checkers'

const IconWrapper = styled.div<{ isEditable?: boolean }>`
  cursor: ${ ({ isEditable }) => isEditable ? 'pointer' : 'default' };
  display: flex;
  align-items: center;
  img {
    object-fit: cover;
    width: 18px;
    height: 15px;
  }
`
export const CommonParametersChecker = ({
  parametersName,
  isEditable,
  className }: { parametersName?: Array<string>, isEditable?: boolean, className?: string }) => {
  const { values } = useFormState()
  const { change } = useForm()

  const bookmarkVariations = {
    ...( isNil(isEditable) ? { 'mixed': maskedBookmark } : { 'mixed': bookmark }),
    'allTrue': bookmarkActive,
    ...( isNil(isEditable) ? { 'allFalse': bookmark } : { 'allFalse': bookmarkGrey }),
  }

  function evaluateParameters(array) {
    return array?.reduce(
      (acc, path) => {
        if (acc === 'mixed' || acc !== (_.get(values, path) ? 'allTrue' : 'allFalse')) {
          return 'mixed'
        }
        return acc
      },
      _.get(values, array[0]) ? 'allTrue' : 'allFalse'
    )
  }
  
  const handleGlobalChange = () => {
    parametersName?.forEach(param => {
      if (evaluateParameters(parametersName) === 'mixed' || evaluateParameters(parametersName) === 'allFalse') {
        change(param, true)
      } else {
        change(param, false)
      }
    })
  }

  if (isNotEmptyArray(parametersName)) {
    return (
      <>
        {isEditable ?
          <IconWrapper onClick={handleGlobalChange} className={className} isEditable={isEditable}>
            <img src={bookmarkVariations[evaluateParameters(parametersName)]} alt=""/>
          </IconWrapper>
          :
          <IconWrapper className={className} isEditable={isEditable}>
            <img src={bookmarkVariations[evaluateParameters(parametersName)]} alt=""/>
          </IconWrapper>
        }
      </>
    )
  }

  return <></>
}