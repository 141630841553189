import { Grid } from '@mui/material'
import { Field } from 'react-final-form'
import React from 'react'
import styled from 'styled-components'
import { InputField, InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { ValidationErrors } from 'final-form'
import { customerApi } from 'shared/api'


const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`
interface ICompanyForm {
  handleSubmit: () => void
  errors: ValidationErrors
  values: customerApi.Company
}

const EditCompanyForm = ({
  handleSubmit,
  errors,
  values,
}: ICompanyForm) => {

  const handleCompanyEdit = () => {
    handleSubmit()
  }
  const { inArchive } = values
  return (
    <form onSubmit={() => {}}>
      <Grid container={true} spacing={3} px={3} pb={1}>
        <Grid item={true} xs={4}>
          <InputLabel label="Наименование компании" required={true}/>
          <HelperTextSupply>
            <Field
              name="name"
              id="name"
              helperText={errors && errors?.name ? errors?.name : ''}
              error={!!errors?.name}
              placeholder="Наименование компании"
              component={InputField}
              required={true}
              errorAfterTouch={true}
              fullWidth={true}
              onBlur={handleCompanyEdit}
              disabled={inArchive}
            />
          </HelperTextSupply>
        </Grid>

        <Grid item={true} xs={4}>
          <InputLabel label="ИНН" required={true}/>
          <HelperTextSupply>
            <Field
              name="inn"
              placeholder="ИНН"
              helperText={errors && errors?.inn ? errors?.inn : ''}
              error={!!errors?.inn}
              required={true}
              errorAfterTouch={true}
              component={InputField}
              onBlur={handleCompanyEdit}
              disabled={inArchive}
            />
          </HelperTextSupply>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel label="URL сайта компании" required={false}/>
          <HelperTextSupply>
            <Field
              name="siteUrl"
              placeholder="URL"
              errorAfterTouch={true}
              component={InputField}
              onBlur={handleCompanyEdit}
              disabled={inArchive}
            />
          </HelperTextSupply>
        </Grid>
      </Grid>
    </form>
  )
}

export default EditCompanyForm
