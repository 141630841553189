import { Box, Checkbox } from '@mui/material'
import defaultGoodImage from 'assets/images/defaultGoodImage.svg'
import { Image } from 'shared/ui/components'

import { useBrandquadStore } from '../../lib/store'

interface IProduct {
  goodsElement: any
  addGood: (good) => void
  deleteGood: (good) => void
  handleMoveToGood: (good) => void
}

export const GoodsCard = ({
  goodsElement,
  addGood,
  deleteGood,
  handleMoveToGood,
}: IProduct) => {
  const selectedGoods = useBrandquadStore((state) => state.selectedGoods)
  const checked = selectedGoods.includes(goodsElement.goods_id)
  return (
    <Box className="good-card" onClick={ () => handleMoveToGood(goodsElement) }>
      <div className="good-card-wrapper">
        <Checkbox
          checked={checked}
          onClick={ (e) => {
            e.stopPropagation()
          } }
          onChange={ (e) =>
            e.target.checked
              ? addGood(goodsElement.goods_id)
              : deleteGood(goodsElement.goods_id)
          }
        />
        <div className="good-card-header">
          <div className="good-card-label">{ goodsElement.name }</div>
          <div>{ goodsElement.external_id }</div>
        </div>
      </div>
      <div className="good-img-wrapper">
        <Image
          src={ goodsElement.images!.length > 0
            ? goodsElement.images![0].url
            : defaultGoodImage }
          style={ { objectFit: (goodsElement.images!.length > 0 ? 'contain' : 'none') } }
        />
      </div>
    </Box>
  )
}