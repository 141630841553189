import { useQuery } from 'react-query'

import { Marketplace, CompanyMarketplace } from './models'

import { api } from '../../base'



type GetMarketplacesQueryKey = typeof GET_MARKETPLACES_QUERY_KEY
type GET_COMPANYMARKETPLACES_QUERY_KEY = ReturnType<typeof getCompanyMarketplacesQueryKey>

const GET_MARKETPLACES_QUERY_KEY =
  ['get', 'pim/marketplaces'] as const
const getCompanyMarketplacesQueryKey = (marketplaceId: number | null, companyId: number | null) =>
  ['get', 'pim/companymarketplaces', marketplaceId, companyId] as const

const getMarketplaces = () => api.get<Array<Marketplace>>('/pim/marketplaces').then((res) => res.data)

const getCompanyMarketplaces = (marketplaceId: number, companyId: number) =>
  api.get<Array<CompanyMarketplace>>(
    `/pim/companymarketplaces?marketplace_id=${marketplaceId}&company_id=${companyId}`
  ).then((res) => res.data)

export const useMarketplaceList = () => {
  const marketplaceListQuery = useQuery<
    Array<Marketplace>,
    errorType,
    Array<Marketplace>,
    GetMarketplacesQueryKey
  >(GET_MARKETPLACES_QUERY_KEY, getMarketplaces, {
    refetchOnMount: false
  })
  return {
    marketplaceListQuery,
  } as const
}

export const useCompanyMarketplaceList = (marketplaceId: number | null, companyId: number | null) => {
  const companyMarketplaceListQuery = useQuery<
    Array<CompanyMarketplace>,
    errorType,
    Array<CompanyMarketplace>,
    GET_COMPANYMARKETPLACES_QUERY_KEY
  >(getCompanyMarketplacesQueryKey(marketplaceId,companyId), (context) =>
    getCompanyMarketplaces(context.queryKey[2] as number,context.queryKey[3] as number),
  {
    enabled: !!marketplaceId && !!companyId,
    refetchOnMount: false,
    staleTime: Infinity
  }
  )

  return {
    companyMarketplaceListQuery,
  } as const
}