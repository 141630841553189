/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const PhotoRequirements = () => {
  const Text = 'Формат — JPEG, JPG, PNG'
  const Element = (
    <StyledTextContainer>
      <ul>
        <li>Минимальное разрешение — 450х450</li>
        <li>Максимальное количество — 30</li>
      </ul>
    </StyledTextContainer>
  )
  return { Text, Element }
}