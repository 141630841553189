import React, { PropsWithChildren } from 'react'

export interface IOpenedStreams {
  goodsSupplyingStream?: boolean
}
export interface IOpenedStreamsContext {
  value: {
    openedStreams: IOpenedStreams
    changeStreamState: (stream: IOpenedStreams) => void
  }
}
export const OpenedStreamsContext = React.createContext<IOpenedStreamsContext | undefined>(undefined)
function OpenedStreamsProvider({ children, value }: PropsWithChildren<IOpenedStreamsContext>) {
  return (
    <OpenedStreamsContext.Provider value={{ value }}>{children}</OpenedStreamsContext.Provider>
  )
}

function useOpenedStreamsContext() {
  const context = React.useContext(OpenedStreamsContext)

  if (context === undefined) {
    throw new Error('useOpenedStreamsContext must be used within a OpenedStreamsProvider')
  }

  return context
}

export { OpenedStreamsProvider, useOpenedStreamsContext }