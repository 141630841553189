/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'

export type IRequest = CamelCasedPropertiesDeep<{
  docId: number
}>

export const apiFunction = ({ docId }: IRequest) =>
  api.get(`/wms/receiptdocs/${docId}/positions/template`, { responseType: 'blob' })

export const useGetCreateReceiptdocPositionFile = () =>
  useMutation<
    AxiosResponse,
    errorType,
    IRequest
  >(apiFunction)

