import { Grid } from '@mui/material'
import styled from 'styled-components'

export const GridInputWrapperStyled = styled(Grid)`
  flex-grow: 1;
`

export const POrgStyled = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: 0.15px;
`
