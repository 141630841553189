import React, { useMemo, useState } from 'react'
import { tradeGen } from 'shared/lib/generated'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { Autocomplete, Button, DialogActions } from '@mui/material'
import TextField from '@mui/material/TextField'
import { LoadingButton } from '@mui/lab'
import { CircularProgressStyled } from 'shared/ui/components/CircularProgress/CircularProgress'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { DialogTitleWithBtn } from 'shared/ui/components'

import { StyledModalContainer } from '../styled'

interface ISelectedWarehouse {
  label: string
  value: number
}

export const AddCustomWarehouseModal = ({ close, accept }) => {
  const { data: warehousesData } = tradeGen.orders.Getwarehouse.useGetwarehouse()

  const warehouseList = useMemo(
    () => warehousesData?.map(getOptions) || [],
    [warehousesData]
  )
  
  const [ selectedWarehouse, setSelectedWarehouse ] = useState<ISelectedWarehouse | null>(null)
  
  return (
    <StyledModalContainer>
      { warehousesData ?
        <>
          <DialogTitleWithBtn
            sx={ { fontSize: '20px', padding: '0px' } }
            onClose={ close }>
            Добавить собственный склад
          </DialogTitleWithBtn>

          <Autocomplete
            options={warehouseList}
            isOptionEqualToValue={ isOptionEqual }
            noOptionsText="Нет фильтров"
            size="small"
            clearOnBlur={ false }
            disableClearable={ !selectedWarehouse }
            onChange={ (_, value) =>
              setSelectedWarehouse(value) }
            value={ selectedWarehouse || { label: '', value: 0 } }
            renderInput={ (params) =>
              <TextField { ...params } label="Склад поставки" />
            } />

          <DialogActions sx={ { padding: '0' } }>
            <Button
              variant="text"
              onClick={ close }
            >
              ОТМЕНА
            </Button>
            { accept &&
              <LoadingButton
                variant="contained"
                onClick={ () => accept(selectedWarehouse) }
                disabled={ !selectedWarehouse }
              >
                Добавить
              </LoadingButton>
            }
          </DialogActions>
        </>
        :
        <div style={{ height: '158px' }}>
          <CircularProgressStyled />
        </div>
      }
    </StyledModalContainer>
  )
}