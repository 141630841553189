import { FC } from 'react'
import { pimApi } from 'shared/api'

import { OzonProductEdit } from './Ozon'
import { SimpleProductEdit } from './Simple'
import { WBProductEdit } from './WB'

interface ContentProps {
  marketplaceId: number
  productId: number
  commonProduct: pimApi.products.common.Product
}

const ProductIdToProductComponent: Record<number, any> = {
  1: WBProductEdit,
  2: OzonProductEdit
}

export const EditContent: FC<ContentProps> = ({
  marketplaceId,
  productId,
  commonProduct,
}) => {
  const Component =  ProductIdToProductComponent[marketplaceId] ?? SimpleProductEdit
  return (
    <Component
      productId={productId}
      commonProduct={commonProduct}
    />
  )
}
