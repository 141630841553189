import React from 'react'
import {
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Chart as DxChart,
  Label,
  Legend,
  Point,
  Series,
  Tick, Tooltip,
  ValueAxis, Margin
} from 'devextreme-react/chart'
import { registerGradient } from 'devextreme/common/charts'
import { formatter } from 'shared/lib/utils/numberWithSpaces'

import { Skeleton } from '../../components/Skeleton/Skeleton'

interface Props {
  chartData: any
  isLoading: boolean
  dataParams: any
}

export const Chart = ({ chartData, isLoading, dataParams }: Props) => {
  const currentSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(33, 150, 243, 0.08)'
      },
      {
        offset: '80%',
        color: '#2196F300'
      }],
      rotationAngle: 90
    })
  }

  const prevSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(0, 0, 0, 0.02)'
      },
      {
        offset: '90%',
        color: 'rgba(255,255,255,0)'
      }],
      rotationAngle: 90
    })
  }
  
  if (isLoading) {
    return (
      <Skeleton style={{
        paddingLeft: '16px',
        paddingRight: '32px',
        margin: '0px',
        backgroundColor: 'rgb(245,245,245)',
        width: 'auto',
        marginTop: '16px'
      }}/>
    )
  }
  return (
    <DxChart
      palette="Ocean"
      dataSource={chartData}
    >
      <Margin top={36} right={9} left={9} bottom={24}/>
      <CommonSeriesSettings
        argumentField="date"
        hoverMode="none"
      />
      <Series
        type="stackedspline"
        color="#E0E0E0"
        width={1.7}
        valueField="prevSale">
        <Point visible={false}/>
      </Series>
      <Series
        type="splinearea"
        color={prevSeriesColor}
        width={1.4}
        opacity={0.1}
        valueField="prevSale">
        <Point visible={false}/>
      </Series>
      <Series
        type="splinearea"
        color={currentSeriesColor}
        width={1.4}
        valueField="currentSale">
        <Point visible={false}/>
      </Series>
      <Series
        type="spline"
        color="#2196F3"
        width={1.35}
        opacity={0.3}
        valueField="currentSale">
        <Point visible={false}/>
      </Series>
      <ValueAxis
        visible={false}
      >
        <Tick visible={false}/>
        <Label visible={true} customizeText={(x) => formatter(x.value)}/>
        <Grid visible={false}/>
      </ValueAxis>
      <ArgumentAxis
        valueMarginsEnabled={false}
        visible={true}
        argumentType="string"
        discreteAxisDivisionMode="crossLabels"
        color="#E0E0E0"
        endOnTick={false}
      >
        <Tick visible={false}/>
        <Label alignment="left" font={{ color: '#00000061' }} customizeText={dataParams.range === 0 ? customDayOfWeek : customDay}/>
        <Grid visible={false}/>
      </ArgumentAxis>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="bottom"
        visible={false}
      />
      <Tooltip enabled={false}/>
    </DxChart>
  )
}

export const customDayOfWeek = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    return days[utc.getDay()]
  }
  return ''
}

export const customDay = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    return utc.getDate()
  }
  return ''
}