import styled from 'styled-components'

export const DeleteAvatar = styled.div`
  width: 30px;
  height: 30px;

  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0 2px 5px grey;
  display: none;
  position: absolute;
  top: 0;
  left: 65px;
`
export const EditPhoto = styled.div`
  width: 30px;
  height: 30px;
  
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 2px 5px grey;
  display: none;
  position: absolute;
  top: 65px;
  left: 65px;
`

export const Avatar = styled.div`
  cursor: pointer;
  position: relative;
  margin-right: 32px;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 96px;
    height: 96px;
  }

  &:hover {
    ${DeleteAvatar}, ${EditPhoto} {
      display: block;
    }
  }
`