import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { memo } from 'react'

interface UserSettingsProps {
  userData: pimApi.admin.CurrentUser
  handleBlock: () => void
  handleUnblock: () => void
  handleClose: () => void
}

export const UserSettings = memo(({ userData, handleBlock, handleUnblock, handleClose }: UserSettingsProps) => {
  const userQuery = userData
  const isBlocked = userQuery?.state === 'blocked'

  const DEFAULT_SETTINGS = [
    {
      label: 'Заблокировать доступ',
      disabled: isBlocked,
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <div style={{ width: '560px' }}>
                <ModalContent
                  close={close}
                  accept={accept}
                  variant="contained"
                  title={ `Заблокировать доступ пользователю \n${userQuery?.name}?` }
                  acceptBtnText="ЗАБЛОКИРОВАТЬ"
                />
              </div>,
            onAccept: () => {
              handleBlock()
              snackActions.info('Пользователь заблокирован')
            },
          }
        )
      }
    },
    {
      label: 'Разблокировать доступ',
      disabled: !isBlocked,
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <div style={{ width: '560px' }}>
                <ModalContent
                  close={close}
                  accept={accept}
                  variant="contained"
                  title={ `Разблокировать доступ пользователю \n${userQuery?.name}?` }
                  acceptBtnText="РАЗБЛОКИРОВАТЬ"
                />
              </div>,
            onAccept: () => {
              handleUnblock()
              snackActions.info('Пользователь разблокирован')
            },
          }
        )
      }
    },
    {
      label: 'Закрыть доступ',
      type: 'error',
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <ModalContent
                close={close}
                accept={accept}
                title={ `Закрыть доступ пользователю \n${userQuery?.name}?` }
                description="Восстановить доступ будет нельзя"
                acceptBtnText="ВСЁ РАВНО ЗАКРЫТЬ"
                acceptBtnColor="error"
              />,
            onAccept: () => {
              handleClose()
              snackActions.info('Доступ закрыт для пользователя')
            },
          }
        )
      }
    },
  ]

  return (
    <div style={{ marginRight: '15px' }}>
      <SettingsMenu
        options={DEFAULT_SETTINGS}
        iconType={<MoreVert />}
      />
    </div>
  )
}
)
