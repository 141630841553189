import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Link } from 'react-router-dom'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  
  table {
    min-width: 1640px !important;
  }

  .MuiTableCell-head {
    white-space: break-spaces;
  }

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }
  
  .TableCell-cell {
    height: 50px;
  }

  .Toolbar-toolbar {
    justify-content: space-between;  
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const TableWrapper = styled.div<{ $isEditable?: boolean }>`
  .TableDetailCell-active {
    table {
      tbody {
        width: 100%;

        tr td:last-child {
          margin-left: auto;
        }
      }

      tbody::after {
        content: "";
        display: block;
        height: 0;
      }
    }
  }

  .Pager-pager {
    height: 56px;
  }

  [isrowexpanded]{
    background-color: #fff;
    td {
      border-bottom: 0;
    }
    &:hover {
      background-color: #fff !important;
    }
  }

  tbody {
    .MuiTableRow-root:not(.TableRow-with-detailBtn) .TableDetailCell-active {
     background-color: #fff;
    }  
  }
`

export const ExtendedInfoSwitch = styled(Box)`
  position: absolute;
  top: 18px;
  left: 600px;
  z-index: 1;
  span {
    font-size: 14px;
    line-height: 14px;
  }
`

export const WidgetWrapper = styled.div `
  width: min-content;
  padding: 3px 0;
`
export const TableBody = styled.div `
  background-color: #F6F6F6B2;
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const GoodsItems = styled.div `
  min-height: 26px;
  max-height: 52px;
  display: flex;
  align-items: flex-start;
  hyphens: manual;
`


export const StyledBox = styled.div<{ priority?: boolean, use?: boolean, ignore?: boolean, lineThrough?: boolean, width: number }>`
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  margin-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 16px;
  ${({ ignore }) => ignore && `
    color: rgba(0,0,0,0.38);
  `}
  ${({ priority }) => priority && `
    color: #2196F3;
  `}
  ${({ lineThrough }) => lineThrough && `
    text-decoration: line-through;
  `}
  svg {
    transition: 0.2s;
    color: rgba(0, 0, 0, 0);
    font-size: 20px;
    margin-left: auto;
    margin-right: 8px;
    cursor: pointer;
  }

  &:hover {
    svg {
      color: #007DFF;
    }
  }
`

export const ItemBox = styled.div<{ use?: boolean }>`
  display: flex;
  width: 100%;
  max-height: 26px;
  min-height: 26px;
  align-items: center;
  background-color: ${({ use }) => use ?
    '#e9f4fe' : ''};
`

export const StyledTableHeader = styled.div`
  border-bottom: 1px solid #E0E0E0;
  height: 52px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
`

export const TableHeaderTitle = styled.span<{ width: number }>`
  padding-left: 16px;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
`

export const TableBodyCell = styled.div<{ width: number }>`
  padding-left: 16px;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const CustomLinkName = styled(Link)`
  text-decoration: none;
  color: #007DFF;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const StatsContainer = styled.div`
  flex-direction: column;
  display: flex;
`