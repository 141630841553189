import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  border-top: 1px solid #E0E0E0;

  table {
    min-width: 960px !important;
  }

  thead {
    height: 50px;
  }

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }

  .TableCell-cell {
    height: 50px;
  }

  .MuiTableCell-head {
    padding-right: 12px;
    padding-left: 12px;
    :nth-last-child(-n+2) {
          padding-left: 0;
          padding-right: 0;
    }
  }

  .MuiTableCell-body {
    padding-right: 12px;
    padding-left: 12px;
    :nth-last-child(-n+3) {
        padding-left: 0;
        padding-right: 0;
    }

    :first-child {
      padding-left: 24px;
    }
  }


  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }

  .Toolbar-toolbar {
    padding: 0 24px;
    justify-content: space-between;
    border: none;

    .MuiIconButton-root {
      display: none;
    }
  }

  .TableContainer-root {
    border: 1px solid #E0E0E0;
    border-bottom: none;
  }

  .Pager-pager {
    border: 1px solid #E0E0E0;
    border-top: none;
    background-color: rgba(247, 247, 247, 0.5);
    height: 50px;
  }
`

export const ToolBar = styled.div`
  position: absolute;
  top: 84px;
  right: 0;
  z-index: 1;
`