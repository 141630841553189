import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
} from 'recharts'
import { Tooltip as MuiTooltip } from '@mui/material'
import sumBy from 'lodash/sumBy'
import { ViewBox } from 'recharts/types/util/types'

interface ChartProps {
  data?: Array<any>
  width?: number
  height?: number
  innerRadius?: number
  outerRadius?: number
  min?: number
  max?: number
  showTooltip?: boolean
  showValuesSumInTheCenter?: boolean
}

const emptyDataFallback = [{ name: 'Нет данных', value: 1 }]

export const COLORS = ['#3F51B5', '#64B6F7', '#4CAF50', '#e356c7', '#FFB547', '#F50057']

function formatterFunc(value, name, props) {
  return [
    `Доступно - ${value} / Зарезервировано - ${props.payload.reserved} `,
    name,
  ]
}

function getChartDataSum(chartData) {
  return chartData.reduce((acc, point) => acc + point.value, 0)
}

export const PieChartComponent = ({
  data,
  width,
  height,
  innerRadius,
  outerRadius,
  showTooltip,
}: ChartProps) => {
  const CustomLabel = ({ viewBox }: { viewBox?: ViewBox }) => (
    <MuiTooltip
      title={`Доступно: ${sumBy(data, 'value')} / Зарезервировано: ${sumBy(
        data,
        'reserved'
      )}`}
    >
      <text
        // @ts-ignore
        x={viewBox?.cx}
        // @ts-ignore
        y={viewBox?.cy}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {getChartDataSum(data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      </text>
    </MuiTooltip>
  )

  return (
    <>
      {isEmpty(data) || getChartDataSum(data) === 0 ? (
        <PieChart width={88} height={88}>
          <Pie
            dataKey="value"
            data={emptyDataFallback}
            fill="#eeeeee"
            {...{
              width,
              height,
              innerRadius,
              outerRadius,
            }}
          >
            <Label style={{ fontSize: '18px', fontWeight: '500' }} offset={0} position="center" content={CustomLabel} />
          </Pie>
        </PieChart>
      ) : (
        <PieChart width={88} height={88}>
          <Pie
            data={data}
            dataKey="value"
            {...{
              width,
              height,
              innerRadius,
              outerRadius,
            }}
          >
            <Label offset={0} position="center" content={CustomLabel} />
            {data?.map((entry, index) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>

          {showTooltip && (
            <Tooltip
              allowEscapeViewBox={{ x: true, y: true }}
              filterNull={true}
              formatter={formatterFunc}
            />
          )}
        </PieChart>
      )}
    </>
  )
}
