import {
  IProductMediaDelete,
  IProductMediaUpdate,
  IProductMediaCreate,
  TProductMediaType
} from 'shared/services/interfaces/product'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import * as ProductOzonMedia from 'shared/api/pim/products/ozon/media'
import { pimApi } from 'shared/api'

export const useMedia = () => {
  const { id } = useParams<{ id: string }>()
  const productIdUrl = parseInt(id, 10)
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(productIdUrl)
  const queryClient = useQueryClient()

  const { mutate: mediaCreate, isLoading: isCreateLoading } = useMutation<
    AxiosResponse<pimApi.products.MediaFile>,
    errorType,
    {
      file: Blob | null,
      productId: UniqueId, mediaType: string
    }
    >(({ file, productId, mediaType }) => {
      const data = new FormData()

      data.append('file', file || '')
      return ProductOzonMedia.createProductMedia({ file: data, productId, mediaType })
        .then((res) => res.data)
    }, {
      onSuccess: (response, variables) => {
        const newObj = ozonProductQuery?.data?.[variables.mediaType] || []
        newObj?.push(response)
        queryClient.setQueryData( pimApi.products.ozon.getOzonProductQueryKey(productIdUrl),
          { ...ozonProductQuery?.data, [variables.mediaType]: newObj })
      }
    })

  const { mutate: mediaUpdate, isLoading: isUpdateLoading } = useMutation<
    AxiosResponse<IProductMediaUpdate>,
    errorType,
    {
      value: IProductMediaUpdate,
      productId: UniqueId,
      mediaType: TProductMediaType
    }
    >(({ value, productId, mediaType }) =>
      ProductOzonMedia.updateProductMedia(
        { ...value, productId, mediaType }
      )
    , {
      onSuccess: () => {
        ozonProductQuery.refetch()
      }
    })

  const { mutate: mediaDelete, isLoading: isDeleteLoading } = useMutation<
    AxiosResponse<IProductMediaDelete>,
    errorType,
    {
      value: any,
      productId: UniqueId,
      mediaType: string,
      callback?: () => void,
        }
        >(({ value, productId, mediaType }) =>
          ProductOzonMedia.deleteProductMedia({ ...value, productId, mediaType })
        , {
          onSuccess: () => {
            const newObj = ozonProductQuery?.data || {}
            queryClient.setQueryData( pimApi.products.ozon.getOzonProductQueryKey(productIdUrl),
              { ...ozonProductQuery, data: newObj })
          },
        })

  const { mutate: mediaUrlCreate, isLoading: isUrlUploadLoading } = useMutation<
    AxiosResponse<IProductMediaCreate>,
    errorType,
    {
      url?: string
      productId: UniqueId,
      mediaType: TProductMediaType
    }
    >(({ url, productId, mediaType }) =>
      ProductOzonMedia.createUrlProductMedia({ url, productId, mediaType })
    , {
      onSuccess: (response, variables) => {
        const newObj = ozonProductQuery?.data?.[variables.mediaType] || []
        newObj?.push(response.data)
        queryClient.setQueryData( pimApi.products.ozon.getOzonProductQueryKey(productIdUrl),
          { ...ozonProductQuery?.data, [variables.mediaType]: newObj })
      }
    })

  const { mutate: mediaSwap } = useMutation<
    [AxiosResponse<IProductMediaUpdate>, AxiosResponse<IProductMediaUpdate>],
    void,
    {
      leftMedia: IProductMediaUpdate,
      rightMedia: IProductMediaUpdate,
      productId: UniqueId,
      mediaType: TProductMediaType
    }
    >(({ leftMedia, rightMedia, productId, mediaType }) => Promise.all([
      ProductOzonMedia.updateProductMedia({
        ...leftMedia,
        indexNumber: rightMedia.indexNumber,
        productId,
        mediaType
      }),
      ProductOzonMedia.updateProductMedia({
        ...rightMedia,
        indexNumber: leftMedia.indexNumber,
        productId,
        mediaType
      })
    ]), {
      onSuccess: () => {
        ozonProductQuery.refetch()
      }
    })
  const isLoading = isCreateLoading || isDeleteLoading || isUpdateLoading || isUrlUploadLoading

  return { mediaCreate, mediaUpdate, mediaDelete, mediaUrlCreate, mediaSwap, isLoading }
}
