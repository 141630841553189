import styled from 'styled-components'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  display: flex;
  background-color: #FFF5E5;
  border-radius: 4px;
  max-width: 260px;
`

export const Title = styled.span`
  font-weight: 500;
`

export const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
`
