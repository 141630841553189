import { Suspense } from 'react'
import { Router } from 'react-router-dom'
import { browserHistory } from 'shared/services/browserHistory'

export const { history } = browserHistory

export const withRouter = (component: () => React.ReactNode) => () => (
  <Router history={history}>
    <Suspense fallback="Loading...">
      {component()}
    </Suspense>
  </Router>
)