/* eslint-disable */
import { api } from 'shared/api/base'
import axios, { AxiosResponse, AxiosTransformer } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import queryString from 'query-string'
import toSnakeCase, { toSnake } from 'shared/lib/transform/toSnakeCase'


export type ResponseType = any

export type IRequest = CamelCasedPropertiesDeep<{
  clientId: string
  goodsId: number
}>

export const apiFunction = async ({ clientId, goodsId }: IRequest) => {
  return axios
    .get(
      `/yml/goods/${clientId}/info/all/${goodsId}`,
      {
        params: {responseType: 'blob'},
        paramsSerializer: (params) => {
          if (params.sort) {
            // eslint-disable-next-line no-param-reassign
            params.sort = toSnake(params.sort)
          }
          return queryString.stringify(toSnakeCase(params), { skipNull: true, skipEmptyString: true })
        },
        transformRequest: [
          // toSnakeCase должен идти перед axios.defaults.transformRequest
          // т к в defaults.transformRequest есть JSON.stringify
          (data) => data instanceof FormData ? data : toSnakeCase(data),
          // https://github.com/axios/axios/blob/master/lib/defaults.js#L39
          ...axios.defaults.transformRequest as AxiosTransformer[],
        ],
      })
}

type QueryKey = ReturnType<typeof getQueryKey>
export const getQueryKey = ({ clientId, goodsId }: IRequest) =>
  [
    'get',
    `/yml/goods/${clientId}/info/all/${goodsId}`,
    { clientId, goodsId },
  ] as const

// export const getSingleGoodInfoAll = (queryParams: IRequest, options?: any) =>
//   useQuery<AxiosResponse, errorType, AxiosResponse, QueryKey>(
//     getQueryKey(queryParams),
//     (context) => apiFunction(context.queryKey[2]),
//     {
//       staleTime: Infinity,
//       keepPreviousData: true,
//       refetchOnMount: false,
//       ...options,
//     }
//   )

export const useGetSingleGoodInfoAll = () => useMutation<ResponseType, errorType, IRequest>(apiFunction)
