import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface InputIsDisabledProps {
    isDisabled: boolean;
}

interface StylizationProps {
    inArchive?: boolean;
    templateSet? : boolean;
}


export const Title = styled(Box)`
  font-size: 24px;
  border-bottom: 1px solid #E0E0E0;
  padding: 16px 24px;
`
export const SubTitle = styled(Box)`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
`
export const FormArea = styled(Box)`
  border: 1px solid #E0E0E0;
  border-radius: 4px;
`

export const AreaTitle = styled(Box)`
  background: linear-gradient(0deg, rgba(247, 247, 247, 0.5), rgba(247, 247, 247, 0.5)), #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 16px 0 0 16px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  div:first-child {
    margin-bottom: 4px;
  }
  svg {
    position: absolute;
    top: 22px;
    right: 22px;
  }
`

export const AreaContent = styled(Box)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  min-height: 56px;
  padding: 0 16px 0 14px;
  display: flex;
  align-items: center;
  border-top: 1px solid #E0E0E0;
`

export const AreaContentNoBorder = styled(AreaContent)`
  border-top: none;
`

export const SubHeader = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 9px;

`

export const InputInSettings = styled.div`
  margin-left: auto;
  width: 36px;
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: 100%;
  }
  input {
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
  }
  label {
    max-width: none;
    left: -2px;
  }
`

export const BrandName = styled(Box)`
  width: 100%;
  margin-right: 21px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  display: flex;
  align-items: center;
`

export const BrandSelectWrapper = styled.div<InputIsDisabledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  pointer-events: ${(props) => props.isDisabled && 'none' };
  margin-bottom: 16px;
  label {
    top: -8px
  }
  .MuiInputLabel-shrink {
    top: 0
  }
  .MuiAutocomplete-inputRoot{
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiInputBase-root{
    color: ${(props) => props.isDisabled && 'rgba(0,0,0,0.38)' };
  }
`


export const ManagerSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  label {
    top: -8px
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
  .MuiAutocomplete-popupIndicator {
    transform: none;
  }
  .MuiInputLabel-shrink {
    top: 0
  }
  .MuiAutocomplete-inputRoot{
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const ArchLabel = styled(Box)`
  margin-left: 14px;
  margin-right: auto;
  background-color: #ED6C02;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #FFFFFFFF;
  padding: 4px 6px;
  height: 20px;
`


export const UploadWrapper = styled(Box)<StylizationProps>`
  label {
    pointer-events: ${(props) => props.inArchive && 'none' };
  }
  margin-right: 8px;
  input {
    display: none;
  }
`

export const Footer = styled(Box)<StylizationProps>`
  display: flex;
  align-items: center;
  border-top: ${(props)=> props.templateSet ? '1px solid #E0E0E0' : ''};
  padding: ${(props) => props.templateSet ? '8px 16px 8px 14px' : '24px'};
  button {
    margin: 0;
  }
  a {
    width: 220px;
    height: 24px;
    margin-right: auto;
    font-size: 16px;
    text-decoration: none;
    pointer-events: ${(props) => props.inArchive && 'none' };
    color: ${(props) => props.inArchive && 'rgba(0, 0, 0, 0.38)'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const IconStyle = styled(Box)<StylizationProps>`
  font-size: 24px;
  line-height: 0;
  pointer-events: ${(props) => props.inArchive && 'none' };
  color: ${(props) => props.inArchive ? 'rgba(0,0,0,0.12)' : 'rgba(0, 0, 0, 0.38)'};

  svg{
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`

export const UserName = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`

export const Dates = styled(Box)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  span:nth-child(2) {
    margin-right: 8px;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`