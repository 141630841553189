import {
  AccessTime,
  CheckCircle,
  CheckCircleOutline,
  Done,
  DoneAll,
  DoneOutline,
  History,
  Refresh, LocalShipping, Warehouse
} from '@mui/icons-material'


export const statusIcons = [
  { CheckCircle: <CheckCircle/>,
    color: '#3B873E' },
  { Agreed: <DoneOutline/>,
    color: '#3F51B5', },
  { Done: <Done/>,
    color: '#3F51B5' },
  { LocalShipping: <LocalShipping/>,
    color: '#3B873E' },
  { Warehouse: <Warehouse/>,
    color: '#3B873E' },
  { DoneAll: <DoneAll/>,
    color: '#3F51B5' },
  { AccessTime: <AccessTime/>,
    color: '#3B873E' },
  { Refresh: <Refresh/>,
    color: '#3B873E' },
  { History: <History/>,
    color: '#3B873E' },
  { CheckCircleOutline: <CheckCircleOutline/>,
    color: '#3B873E' },
]