import { reviewApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'


interface ColumnName
  extends Omit<
    reviewApi.ReviewTemplate,
    | 'versionNo'
    | 'changed'
    | 'changer'
    | 'created'
    | 'deleted'
    | 'reviewType'
    | 'changerName'
  > {
  actionColumn?: string
  padding?: number
  removeButton?: string
  editButton?: string
}


type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  name: 'name',
  templatemodelName: 'templatemodelName',
  templatemodelId: 'templatemodelId',
  reviewtypeId: 'reviewtypeId',
  value: 'value',
  text: 'text',
  id: 'id',
  removeButton: 'removeButton',
  editButton: 'editButton',
  actionColumn: 'actionColumn',
  padding: 'padding'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование шаблона',
      width: 'auto',
      wordWrapEnabled: true,
      sortingEnabled: true,
    },
    {
      name: columns.templatemodelName,
      columnName: columns.templatemodelName,
      title: 'Модель шаблона',
      width: 250,
      sortingEnabled: false,
    },
    {
      name: columns.text,
      columnName: columns.text,
      title: 'Текст',
      width: 600,
      sortingEnabled: false,
    },
    {
      name: columns.editButton,
      columnName: columns.editButton,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.PrivilegeEdit,
    },
    {
      name: columns.removeButton,
      columnName: columns.removeButton,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.PrivilegeRemove,
    },
    {
      name: columns.padding,
      columnName: columns.padding,
      title: ' ',
      sortingEnabled: false,
      width: 10,
    }
  ],
}
