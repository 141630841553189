/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PatchUserInfo, UserInfo, UserMethod } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserInfo>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  query: {
  /** Операция над пользователем */
    method: UserMethod,

},
  data: PatchUserInfo
}>

export const apiFunction = ({ userId,query,data }: IRequest) =>
  api.patch<ResponseType>(`/customer/admin/users/${userId}`,data, { params: query }).then(res => res.data)


export const usePatchUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

