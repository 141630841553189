export function expandPrivileges(arr) {
  const stack = JSON.parse(JSON.stringify(arr))
  const result: any[] = []

  while (stack.length > 0) {
    const node = stack.pop()

    if (node.id) {
      result.push(node)
    }

    if (node.privileges || node.groups) {
      const children = node.groups.concat(node.privileges)
      if (children.length > 0) {
        stack.push(...children)
      }
    }
  }
  return result
}