import { Box, TextField, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { pimApi } from 'shared/api'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { useMemo } from 'react'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'

import { CostBlock,
  Cost,
  Incoming,
  Packing,
  Help,
  Title,
  PackingItem } from './ui/styled'



interface CostsProps {
  copackingCostInfo?: pimApi.products.CoPackingCostInfo[];
}

export const Costs = ({ copackingCostInfo }: CostsProps) => {

  const goodData = useGoodsContext()
  const productData = useCommonProductContext()

  const actualSupplier = useMemo(() => {
    if (goodData?.suppliers !== undefined) {
      if (goodData.suppliers.length > 0) {
        return goodData.suppliers.reduce((a, b) => (a.id || 0) > (b.id || 0) ? a : b)
      }
    }
    return null
  }, [goodData])

  const CopackingCosts = ({ data }) => (
    data.map(e => (
      <PackingItem key={e}>
        <TextField
          label={e.copackingCenter}
          variant="outlined"
          size="small"
          fullWidth={true}
          value={e.cost}
          disabled={true}
        />
        <Tooltip
          title={e.costDescription}
          placement="right"
        >
          <Help>
            <InfoOutlined />
          </Help>
        </Tooltip>
      </PackingItem>
    ))
  )

  return (
    <CostBlock>
      <Cost>
        <TextField
          label="Себестоимость, р."
          variant="outlined"
          size="small"
          fullWidth={true}
          value={productData?.commonProductQuery?.data?.netCost}
          disabled={true}
          InputLabelProps={{ shrink: true }}
          sx={{ width: '136px' }}
        />
        <TextField
          label="Мин. цена, р."
          variant="outlined"
          size="small"
          fullWidth={true}
          value={null}
          disabled={true}
          InputLabelProps={{ shrink: true }}
          sx={ { width: '104px' } }
        />
      </Cost>
      <Incoming>
        <Title>Входящая информация</Title>
        <Box display="flex">
          <TextField
            label="Входящая цена, р."
            variant="outlined"
            size="small"
            fullWidth={true}
            value={actualSupplier?.incomingPrice}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
          <TextField
            label="РРЦ, р."
            variant="outlined"
            size="small"
            fullWidth={true}
            value={actualSupplier?.rrp || null}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Box>
      </Incoming>
      <Packing>
        { copackingCostInfo && copackingCostInfo.length
          ? <>
            <Title>Стоимость упаковки, р.</Title>
            <CopackingCosts data={copackingCostInfo} />
          </>
          : <></>
        }
      </Packing>
    </CostBlock>
  )
}
