import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'
import { pimGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'
import { AlgorithmWithValue } from 'shared/api/pim/products/common'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { AddProductModal } from './AddProductModal'

export const AddProduct = () => {
  const { mutate: createPriceSettings } = pimGen.priceWizard.CreatePriceSettings.useCreatePriceSettings()
  const queryClient = useQueryClient()
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { limit, page, tab, ...searchParams } = searchObj

  const addPriceSettings = (value, selectedProduct) => {
    const { adviceInterval } = value
    const { autoChangePrice } = value

    const newParams = (value.algorithm?.parameters || []).map(el => ({
      ...el,
      value: value[`id${ el.id }`]
    }))

    createPriceSettings({
      data: {
        adviceInterval,
        autoChangePrice,
        productId: selectedProduct.productId,
        advisor: {
          selectedAlgorithmId: value.algorithm?.id!,
          algorithms: [
            { ...value.algorithm, parameters: newParams } as AlgorithmWithValue
          ]
        }
      }
    }, {
      onSuccess: (response) => {
        snackActions.info('Продукт добавлен в волшебник цен')
        queryClient.setQueryData<pimGen.priceWizard.GetPriceSettings.ResponseType>(pimGen.priceWizard.GetPriceSettings.getQueryKey({
          query: searchParams
        }), (updater) => updater!.concat([response as any]) as pimGen.priceWizard.GetPriceSettings.ResponseType )
      }
    })
  }
  const handleAddButtonClick = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <AddProductModal
            close={close}
            accept={accept}
          />,
        onAccept: ({ value, selectedProduct }) => {
          addPriceSettings(value, selectedProduct)
        },
      }
    )
  }
  
  return (
    <Button
      size="small"
      style={{ height: '36px' }}
      variant="contained"
      onClick={handleAddButtonClick}
      startIcon={<AddIcon/>}
    >Добавить</Button>
  )
}