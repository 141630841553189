import React from 'react'
import { pimApi } from 'shared/api'

export const VideoVariation = ({
  selectedMedia,
}: {
  selectedMedia: pimApi.products.MediaFile,
}) => {
  const getVideoSrc = (url) => {
    if (url.includes('youtube.com')) {
      const youtubeUrl = new URL(url)
      return `https://youtube.com/embed/${youtubeUrl.searchParams.get('v')}?autoplay=0`
    }
    return url
  }

  const videoSrc = getVideoSrc((selectedMedia.downloadUrl === ' ' || selectedMedia.downloadUrl === '')
    ? selectedMedia.fileName : selectedMedia.downloadUrl)

  return (
    <div style={{ height: '496px', paddingTop: '16px' }}>
      { selectedMedia?.fileName?.includes('youtube.com') ?
        <iframe
          style={{ height: '100%', width: '100%', borderRadius: '4px', border: 'none', boxShadow: '0 0 0 1px #E0E0E0' }}
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={ videoSrc }/>
        :
        /* eslint-disable-next-line jsx-a11y/media-has-caption */
        <video
          controls={true}
          style={{ height: '100%', width: '100%', borderRadius: '4px', border: 'none', boxShadow: '0 0 0 1px #E0E0E0' }}
          title={ selectedMedia.fileName } src={ videoSrc }>
          <source src={ videoSrc } type="video/mp4"/>
        </video>
      }
    </div>
  )
}