import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'

const OzonProductContext = React.createContext<any | undefined>(undefined)

interface OzonProductContextProviderProps {
  ozonProductQuery: QueryObserverResult,
}

function OzonProductContextProvider({ children, ozonProductQuery }: PropsWithChildren<OzonProductContextProviderProps>) {
  return (
    <OzonProductContext.Provider value={{ ozonProductQuery }}>{children}</OzonProductContext.Provider>
  )
}

function useOzonProductContext() {
  const context = React.useContext(OzonProductContext)

  if (context === undefined) {
    throw new Error('useGoodsContext must be used within a GoodsProvider')
  }

  return context
}

export { OzonProductContextProvider, useOzonProductContext }