import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

import { StyledDateCell } from './styled'

export const DateCell = ({ date }: { date?: string }) => {
  if (!date) return null

  const isFutureDate = new Date(date) > new Date()

  return (
    <StyledDateCell $isFutureDate={isFutureDate}>
      <span>{format(new Date(date), DateFormats.dayMonthYear)}</span>{' '}
      <span>{format(new Date(date), DateFormats.hours24Minutes)}</span>
    </StyledDateCell>
  )
}
