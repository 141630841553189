import styled from 'styled-components'
import { Button } from '@mui/material'

export const Content = styled.div`
    min-width: 394px;
    max-width: 410px;
    padding: ${({ theme }) => theme.spacing(2)};
    padding-top: 51px;
    position: relative;
`

export const ImageWrapper = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(11)};
    justify-content: center;
    align-items: center;
    min-height: 400px;
    img {
      max-width: 300px;
    }
`

export const PreviewTitle = styled.p`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    font-size: 14px;
    font-weight: bold;
`

export const PreviewSubTitle = styled.p`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const PreviewCodeItem = styled.p`
    font-size: 12px;
    > span{
    display: inline-block;
    min-width: 120px;
    }
`

export const GoodClose = styled.div`
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 18px;
    color: #CFCFCF;
    cursor: pointer;
`

export const NavButton = styled(Button)`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    top: 200px;
`

export const ButtonPrev = styled(NavButton)`
    position: absolute;
    left: 5px;
`

export const ButtonNext = styled(NavButton)`
    position: absolute;
    right: 5px;
`