import styled from 'styled-components'
import { TabPanel } from 'shared/ui/components'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { CircularProgress } from '@mui/material'
import { FilterWrapper as SharedFilterWrapper } from 'shared/ui/components/DataFilters/styled'

export const StyledTabPanel = styled(TabPanel)`
  padding: 0 24px;
  .MuiPaper-root {
    overflow: visible;
  }
`

export const StyledCountButtonGroup = styled(StyledButtonGroup)`
  letter-spacing: 0.15px;
  font-size: 14px;
  padding: 6px 16px;
`

export const StyledLoader = styled(CircularProgress)`
`

export const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E0E0E0;
  transition: transform 1s 0s;

  .info-container {
    height: 100%;
    width: calc(50%);
    min-width: 700px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .main-product-info {
      display: flex;
      flex-direction: row;
      width: 100%;

      .product-name-container {
        display: flex;
        min-width: 250px;
        max-width: min-content;
        margin-right: 24px;
      }

      .product-nomenclature-info {
        gap: 4px;
        display: flex;
        flex-direction: column;
        width: 140px;
        margin-right: 24px;

        .cabinet-name-text {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .evaluation-rating {
        gap: 4px;
        display: flex;
        flex-direction: column;

        .rating {
          display: flex;
          flex-direction: row;
          align-items: center;

          .rating-icon {
            color: #FFB400;
            font-size: 18px;
            margin-right: 4px;
          }

          .rating-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #0000008A;
          }
        }

        .evaluation-count {
          color: #0000008A;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.15000000596046448px;
          text-align: left;

        }
      }

      .marketplace-icon {
        margin-right: 0;
        margin-left: auto;

        .icon-container {
          border-radius: 50%;
        }
      }
    }

    .rating-info {
      display: flex;
      flex-direction: row;

      .user-info {
        min-width: 148px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;

        span {
          :first-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            color: #000000DE;
          }

          :last-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #0000008A;
          }
        }
      }

      .review-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .review-rating {
          display: flex;
          flex-direction: row;
          align-items: center;

          .review-rating-title {
            min-width: 107px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #00000099;
          }
        }

        .review-text-container {
          display: flex;
          flex-direction: row;

          .review-rating-title {
            min-width: 107px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #00000099;
          }

          .review-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #000000DE;
          }
        }

        .review-media-container {
          display: flex;
          flex-direction: row;

          .review-rating-title {
            min-width: 107px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.15000000596046448px;
            color: #00000099;
          }

          .media-image-list {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .review-image {
              min-width: 48px;
              min-height: 48px;
              max-width: 48px;
              max-height: 48px;
              border-radius: 4px;
              object-fit: cover;
              transition: all 0.3s;
              cursor: pointer;

              :hover {
                opacity: 0.85;
              }
            }
          }
        }
      }
    }
  }

  .answer-container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: #FAFAFA;
    width: calc(50%);
    padding: 16px 16px 30px;

    > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 16px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .settings-menu {
      button {
        height: 32px;
        width: 32px;
      }
    }
    button {
      height: 32px;
    }
    .MuiAutocomplete-root {
      background-color: #FFFFFF;
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
      }
    }
    .input-container {

      .MuiInputLabel-root {
        top: -5px;
      }

      .MuiInputLabel-shrink {
        top: 0;
      }
      .MuiFormLabel-filled {
        top: 0;
      }
      .MuiTextField-root {
        background-color: #FFFFFF;

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 4px;
        }

        .MuiInputBase-multiline {
          padding: 12px 12px 10px;
        }
      }
    }
  }
`

export const FilterWrapper = styled(SharedFilterWrapper)`
  padding: 16px 24px;
`