import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import {
  AppPimGoodsModelsGoodsGoodsComponent,
} from 'domains/pim/goods'
import { ComponentCard } from 'entities/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import { InputLabel } from 'shared/ui/components'

import { ResultList } from './styled'


interface GoodsInComponentProps {
  withRemove: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const GoodsInComponent: FC<GoodsInComponentProps> = ({
  withRemove,
}) => {
  const componentsField = useField('selectedGoods')

  if(isEmpty(componentsField.input.value)) return null

  return (
    <Box mb={3}>
      <ResultList>
        <InputLabel
          label="Выбранный товар"
          required={false}
        />
        <Grid container={true} spacing={2}>
          <FieldArray<AppPimGoodsModelsGoodsGoodsComponent> name="selectedGoods">
            {({ fields }) =>
              !isEmpty(fields) && (
                fields.value.map((goodsComponent, index) => {
                  const imgUrl = pimApi.goods.getGoodsThumbnailApiPath(
                    goodsComponent.componentId,
                    fileThumbnailSize
                  )

                  return (
                    <Grid item={true} xs={12} key={goodsComponent.componentId}>
                      <ComponentCard
                        imgUrl={goodsComponent.goodsBaseImageId ? imgUrl : undefined}
                        goodsType={goodsComponent.goodsType}
                        componentId={goodsComponent.componentId}
                        name={goodsComponent.name}
                        code={goodsComponent.code}
                        unitCode={goodsComponent.unitCode}
                        auxCode={goodsComponent.auxCode}
                        unitPartible={goodsComponent.unitPartible}
                        onRemoveClick={() => {
                          if(withRemove) {
                            fields.remove(index)
                          }
                        }}
                      />
                    </Grid>
                  )
                })
              )
            }
          </FieldArray>
        </Grid>
      </ResultList>
    </Box>
  )
}
