import styled from 'styled-components'

export const TabsWrapper = styled.div`
  .MuiTabs-root {
    padding: 0 24px;
    
    :has(.MuiTabScrollButton-root) {
      padding: 0;
    }
    
    .MuiTabs-scrollButtons {
      width: 24px;
    }
    .MuiTab-root {
      min-width: 40px;
      max-width: inherit;
      text-transform: none;
      font-weight: 400;
      font-size: 16px;
    }
  }
`