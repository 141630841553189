import { useCallback } from 'react'
import { ozonproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { AutocompleteValue } from '@mui/material/useAutocomplete'
import { BrandOption } from 'shared/api/ozonproxy/models'

export interface BrandProps extends FieldRenderProps<string> {
  companymarketplaceId: number,
}

const getOptionFromBrand= ({ value, id, picture }: BrandOption) => ({
  label: value,
  value: id,
  picture,
})
export const OzonBrandSelectField = ({ companymarketplaceId, ...props } : BrandProps) => {
  const fetchCatalog = useCallback(
    (search: string) => ozonproxyApi.fetchBrands(search, companymarketplaceId)
      .then(
        (data) => data.data.map(
          getOptionFromBrand
        )
      ),
    [companymarketplaceId]
  )

  const handleChange = (value: AutocompleteValue<Option<any>, false, undefined, undefined>) => {
    props.input.onChange(value)
  }
  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      // @ts-ignore
      input={props.input}
      // @ts-ignore
      onChange={handleChange}
      withImage={true}
      fetchCatalog={fetchCatalog}
      error={props.error.toString()}
      noOptionsText="Введите строку для поиска"
    />
  )
}

