import { OptionsObject, ProviderContext, useSnackbar, VariantType } from 'notistack'
import { FC } from 'react'

interface InnerSnackbarUtilsConfiguratorProps {
  setUseSnackbarRef: (ctx: ProviderContext) => void 
}

type OtherSnackbarOptionsProps = Omit<OptionsObject, 'variant'>

const InnerSnackbarUtilsConfigurator: FC<InnerSnackbarUtilsConfiguratorProps> = ({
  setUseSnackbarRef
}) => {
  setUseSnackbarRef(useSnackbar())

  return null
}

let useSnackbarRef: ProviderContext
const setUseSnackbarRef = (useSnackbarRefProp: ProviderContext) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />

export const snackActions = {
  success(msg: string, options?: OtherSnackbarOptionsProps) {
    this.toast(msg, 'success', options)
  },
  warning(msg: string, options?: OtherSnackbarOptionsProps) {
    this.toast(msg, 'warning', options)
  },
  info(msg: string, options?: OtherSnackbarOptionsProps) {
    this.toast(msg, 'info', options)
  },
  error(msg: string, options?: OtherSnackbarOptionsProps) {
    this.toast(msg, 'error', options)
  },
  toast(msg: string, variant: VariantType = 'default', otherOptions?: OtherSnackbarOptionsProps) {
    useSnackbarRef.enqueueSnackbar(msg, { variant, ...otherOptions })
  }
}