import { memo, useCallback } from 'react'
import { Box, Grid } from '@mui/material'
import { Field } from 'shared/ui/components/form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { Option } from 'shared/ui/components/interface'
import { ozonproxyApi } from 'shared/api'

export const getOptionFromCategory = (
  category: ozonproxyApi.model.Category
): Option<number> => ({
  label: `${category.categoryName}/${category.typeName}`,
  value: category.typeId,
  descriptionCategoryId: category.descriptionCategoryId
})


export const NewOzonCard = memo(( { companymarketplaceId } : { companymarketplaceId : number}) => {
  const fetchCatalog = useCallback((search: string) =>
    ozonproxyApi
      .fetchCategories(search, companymarketplaceId)
      .then((data) => data.data.map(getOptionFromCategory)),
  [companymarketplaceId]
  )

  return (
    <>
      <Box mb={1} mt={2.5}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Категория</span>
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              name="category"
              component={AutocompleteCatalogSearchField}
              fetchCatalog={fetchCatalog}
              label="Категория"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
})
