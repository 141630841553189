import { useParams } from 'react-router-dom'
import { dialogModel } from 'shared/ui/components/dialog'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { pimApi } from 'shared/api'
import {
  arrayMove
} from '@dnd-kit/sortable'
import { useQueryClient } from 'react-query'
import { reactQueryCacheDelete, reactQueryCacheUpdate } from 'shared/lib/reactQuery'
import React, { useEffect, useState } from 'react'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import {
  VideoRequirements
} from 'widgets/Media/DragDropWidget/ui/Requirements/Ozon/videoRequirements'
import { OzonMediaTypes } from 'shared/types/MediaTypes'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'
import { CircularProgressStyled, CircularProgressWrap, ContentsWrapper } from 'shared/ui/components/CircularProgress/CircularProgress'

import { VideoItem } from './VideoItem'

import { useMedia } from '../../../model'

interface VideosProps {
  ozonProduct?: pimApi.products.ozon.OzonProduct
  disabled?: boolean
}

const VIDEO_SUPPORTED_FILE_EXTENSION = ['.mp4', '.avi', '.webm', '.mpeg', '.mov']

export const Videos = ({ ozonProduct, disabled }: VideosProps) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const queryClient = useQueryClient()
  const maxVideoCount = 5
  const mediaLimited = ozonProduct?.videos?.length === maxVideoCount

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.products.ozon.useValidateProductMediaFileMutation()
  const { mutateAsync: validateMediaUrlMutation } =
    pimApi.products.ozon.useValidateProductMediaByURLMutation()
  const { mutate: deleteMediaMutation, isLoading: isVideoDeleting } =
    pimApi.products.ozon.useDeleteProductMediaMutation()

  const { mediaCreate, mediaUrlCreate, isLoading } = useMedia()

  const onFileValidate = (mediaType: OzonMediaTypes) =>
    (file, onSuccess) => {
      if (typeof file === 'string') {
        validateMediaUrlMutation({ url: file, productId, mediaType }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      } else {
        const formFile = new FormData()
        formFile.append('file', file)
        validateMediaFileMutation({
          productId,
          mediaType,
          file: formFile,
        }).then(res => {
          if (res.status === 200) onSuccess(res)
        })
      }
    }
    
  const onFileUpload =
    (mediaType: any) =>
      (files) => {
        if (files && isNotEmptyArray(files)) {

          files.forEach(el => {
            if (typeof el.file === 'string') {
              mediaUrlCreate({ url: el.file, productId, mediaType })
            } else {
              mediaCreate({
                productId,
                mediaType,
                file: el.file,
              })
            }
          })
        }
      }

  const [localVideos, setVideos] = useState<Array<pimApi.products.MediaFile>>([])

  const { mutate: updateMediaMutation } =
    pimApi.products.ozon.useUpdateProductMediaMutation({
      onSuccess: reactQueryCacheUpdate({
        queryClient,
        queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
        path: 'videos'
      }),
    })

  const handleDelete = (deletingVideo: DeletingObject) => {
    dialogModel.openDialog({
      component: MediaDeleteConfirmModal,
      onAccept: () => {
        deleteMediaMutation(
          { productId, mediaType: 'videos', ...deletingVideo },
          {
            onSuccess: reactQueryCacheDelete({
              queryClient,
              queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
              deleteItem: deletingVideo,
              path: 'videos'
            }),
          }
        )
      },
    })
  }

  useEffect(() => {
    setVideos(ozonProduct?.videos === undefined ? [] : ozonProduct?.videos)
  }, [ozonProduct?.videos])

  const onDragChange = (sourceId, sourceIndex, targetIndex) => {
    const swappedVideos = arrayMove<pimApi.products.MediaFile>(localVideos, sourceIndex, targetIndex)
    const editMediaFileList = swappedVideos.map<pimApi.products.EditMediaFile>(
      (video: pimApi.products.MediaFile, index) => ({
        id: video.id,
        versionNo: video.versionNo,
        indexNumber: index
      })
    )
    setVideos(swappedVideos)
    updateMediaMutation(
      {
        productId,
        mediaType: 'videos',
        editMediaFileList
      },
      {
        onError: () => {
          setVideos(localVideos)
        }
      }
    )
  }

  const getModalFilePath = (mediaType: OzonMediaTypes) =>
    (photoId, size) => pimApi.products.ozon.getOzonMediaApiPath(productId, photoId, mediaType, size)

  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          close={close}
          mediaVariation="video"
          mediaType="Видео"
          getFilePath={getModalFilePath('videos')}
          clickedItem={item}
          items={ozonProduct?.videos || []}
        />
      )
    })
  }

  const isQueriesInProgress =
    isVideoDeleting ||
    isLoading

  return (
    <div style={{ marginBottom: '24px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span style={{ fontWeight: '500', color: '#000000DE' }}>Видео</span>
        <span style={{ color: '#0000008A' }}>{`${ozonProduct?.videos?.length || 0}/5`}</span>
      </div>
      <ContentsWrapper>
        {isQueriesInProgress && (
          <CircularProgressWrap>
            <CircularProgressStyled />
          </CircularProgressWrap>
        )}
        <UploadGallery
          mediaType="видео"
          largeContainer={true}
          addDisabled={mediaLimited}
          mediaLimit={5}
          boxesPerRow={maxVideoCount}
          localMedia={localVideos} 
          mediaRequirement={VideoRequirements}
          supportedFileExtension={VIDEO_SUPPORTED_FILE_EXTENSION}
          onUploadFile={onFileUpload('videos')}
          onFileValidate={onFileValidate('videos')}
          urlUploadingExist={true}
          onDragChange={onDragChange}>
          {localVideos?.map((item, index) => (
            <VideoItem
              productId={productId}
              openGallery={() => {handleOpenGallery(item)}}
              index={index}
              item={item}
              onFileDelete={handleDelete}
              disabled={disabled}
            />
          ))}
        </UploadGallery>
      </ContentsWrapper>
    </div>
  )
}
