import { pimApi } from 'shared/api'

const GoodsTypeToText: Record<pimApi.goods.GoodsType, string> = {
  common: 'товаре',
  bundle: 'комплекте',
  pack: 'спайке',
  assortment: 'ассортименте',
}

const GoodsTypeButton: Record<pimApi.goods.GoodsType, string> = {
  common: 'ТОВАР',
  bundle: 'КОМПЛЕКТ',
  pack: 'СПАЙКУ',
  assortment: 'АССОРТИМЕНТ',
}

export const getGoodsTitleByType = (GoodsType: pimApi.goods.GoodsType) => 
  `Информация ${GoodsType === 'assortment' ? 'об' : 'о'} ${GoodsTypeToText[GoodsType]}`

export const getGoodsLabelByType = (GoodsType: pimApi.goods.GoodsType) => 
  `Товары в ${GoodsTypeToText[GoodsType]}`

export const getGoodsButtonByType = (GoodsType: pimApi.goods.GoodsType) =>
  `СОЗДАТЬ ${GoodsTypeButton[GoodsType]}`
