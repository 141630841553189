import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

import { ProductsResultArgs, QueryProductsArgs } from '../../../lib/generated/products/Api'

export interface ProductPayload extends QueryProductsArgs, ProductsResultArgs {}

const downloadProductFile = (queryParams) => (
  api.post<File>('/products/excel/products', queryParams))

export const useDownloadProductsFile = () =>
  useMutation<
    AxiosResponse<File>,
    AxiosError<errorType>,
    ProductPayload
  >(downloadProductFile)