import { pimApi } from 'shared/api'
import { isNil } from 'shared/lib/checkers/isNil'

import { serializeSizeInfoToEditSizeInfo } from './serializeSizeInfoToEditSizeInfo'
import { serializeWBCardAttributeToEditWBCardAttribute } from './serializeWBCardAttributeToEditWBCardAttribute'

const serializeWBCardVariations = (
  variations?: Array<pimApi.products.wb.WBVariation>
) => variations?.map(({ 
  barcodes,
  mainBarcodeId,
  changed,
  ...rest
}) => rest)

export const serializeEditSubmitFormValuesToEditWBCard = ({
  // поля, которые не нужны
  photos,
  video,
  variations,
  futurePrices,
  priceRecentHistory,
  changed,
  price,
  discount,
  syncStatus,
  syncPossible,
  sizeSupport,
  packagingrequirement,
  otherNomenclatures,
  inArchive,
  colorsEnabled,
  changer,
  category,
  cardStatus,
  basePackagingrequirement,
  tnvedEnabled,
  marketplaceUrl,
  // конец полей, которые не нужны
  ...formValues
}: pimApi.products.wb.WBNomenclature): pimApi.products.wb.EditWBNomenclature => ({
  ...formValues,
  attributes: formValues.attributes?.map(
    serializeWBCardAttributeToEditWBCardAttribute
  ),
  width: isNil(formValues.width) || (formValues.width.value as unknown as string ?? '') === ''
    ? undefined : serializeSizeInfoToEditSizeInfo(formValues.width),
  height: isNil(formValues.height) || (formValues.height.value as unknown as string ?? '') === ''
    ? undefined : serializeSizeInfoToEditSizeInfo(formValues.height),
  depth: isNil(formValues.depth) || (formValues.depth.value as unknown as string ?? '') === ''
    ? undefined : serializeSizeInfoToEditSizeInfo(formValues.depth),
  weight: isNil(formValues.weight) || (formValues.weight.value as unknown as string ?? '') === ''
    ? undefined : serializeSizeInfoToEditSizeInfo(formValues.weight),
  colors: formValues.colors,
  variations: serializeWBCardVariations(variations),
})
