import { pimGen } from 'shared/lib/generated'
import { Checkbox } from '@mui/material'
import { StyledLink } from 'features/product/common/CompetitorsTab/ui/styled'
import React from 'react'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'

import { CompetitorsCell } from '../index'

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  
  span {
    padding: 0;
  }
`
export const IdCell = ({ row }) => {
  const { data } = pimGen.product.GetProductCompetitors.useGetProductCompetitors({ productId: row.productId })
  const { mutate: editCompetitor } = pimGen.product.EditProductCompetitor.useEditProductCompetitor()
  const queryClient = useQueryClient()
  
  const handleCheck = (item, checked) => {
    editCompetitor({
      productId: row.productId,
      data: {
        ...item,
        reckonedFlag: checked
      }
    }, {
      onSuccess: (response) => {
        snackActions.info(response.reckonedFlag ? 'Конкурент учитывается в алгоритме' : 'Конкурент не учитывается в алгоритме')
        queryClient.setQueryData<pimGen.product.GetProductCompetitors.ResponseType>
        (pimGen.product.GetProductCompetitors.getQueryKey({ productId: row.productId }),
          (updater) => updater?.map(el => {
            if (el.id === response.id) return response
            return el
          }) as pimGen.product.GetProductCompetitors.ResponseType)
      }
    })
  }

  if (data) return (
    <CompetitorsCell>
      { data.map(competitor => (
        <WrapperContainer key={competitor.id}>
          <Checkbox onChange={(_, checked) => handleCheck(competitor, checked)} size="small" checked={competitor.reckonedFlag}/>
          <StyledLink href={competitor.url} target="_blank" rel="noreferrer">{competitor.sku}</StyledLink>
        </WrapperContainer>
      )) }
    </CompetitorsCell>
  )

  return <></>
}