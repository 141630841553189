import ExcelJS, { TableColumnProperties } from 'exceljs'

import { ExcelReportSettings, ExcelReportCellType } from './types'

import { downloadFile } from '../../utils'


export async function saveDataToExcel(
  data: ExcelReportCellType[][],
  reportSettings: ExcelReportSettings
) {
  const workbook = new ExcelJS.Workbook()
  const ws = workbook.addWorksheet(reportSettings.sheetName)
  ws.addTable({
    name: 'report',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: reportSettings.tableStyleProperties?.theme || 'TableStyleMedium2',
      showRowStripes: reportSettings.tableStyleProperties?.showRowStripes || true,
    },
    columns: reportSettings.columns.map((column) => <TableColumnProperties>{ name: column.caption, filterButton: true }),
    rows: data
  })
  reportSettings.columns.forEach((column,index) => {
    if (!(column.width === undefined)) { ws.getColumn(index+1).width = column.width }
    if (!(column.alignment === undefined)) { ws.getColumn(index+1).alignment = column.alignment }
  })
  ws.getRow(1).alignment = { horizontal: 'center' }
  const buf = await workbook.xlsx.writeBuffer()
  downloadFile(new File([buf], `${reportSettings.fileName}.xlsx`), `${reportSettings.fileName}.xlsx`)
}