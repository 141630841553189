import { useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { Skeleton } from '@mui/material'

import { PriceCardWithMenu } from './PriceCardWithMenu'
import { PriceChangeWrapper } from './styled'


interface PriceChangeProps {
  readOnly?: boolean
}

export const PriceChange = ({
  readOnly
}: PriceChangeProps) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const { data: priceDetail } = pimApi.price.useGetProductsPriceQuery(productId)

  return (
    <PriceChangeWrapper>
      {priceDetail ?
        <>
          <PriceCardWithMenu
            productId={productId}
            spp={priceDetail.spp}
            readOnly={readOnly}
            priceRecentHistory={[1]}
            price={priceDetail.price}
            discount={priceDetail.discount}
          />
          {isNotEmptyArray(priceDetail.futurePrices) && priceDetail.futurePrices &&
              priceDetail.futurePrices.map(({ changeDate, ...rest }) => (
                <PriceCardWithMenu
                  key={rest.id}
                  title={`Цена ${changeDate.split('-')[2].split('T')[0]} ${DateAppearanceSettings[changeDate.split('-')[1]].month}`}
                  productId={productId}
                  spp={priceDetail.spp}
                  {...rest}
                />
              ))
          }
        </>
        :
        <Skeleton
          sx={{ bgcolor: '#D3D3D360' }}
          animation="wave"
          variant="rounded"
          width={178}
          height={114}
        />
      }
    </PriceChangeWrapper>
  )
}
