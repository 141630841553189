import { useEffect } from 'react'
import {
  Link as RouterLink,
  Route,
  Switch,
  useHistory, useLocation,
  useParams,
} from 'react-router-dom'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import { getSupplyOrderEditPath } from 'shared/config/routes'
import { FlexWrapper } from 'shared/ui/components'
import { tradeGen } from 'shared/lib/generated'
import { SupplyOrderContextProvider } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { isNil } from 'shared/lib/checkers'

import { SupplyOrderEditPageTab } from './model/types'
import { SupplyOrderProductTab } from './ui/SupplyOrderProductTab'
import { SupplyOrderGoodsTab } from './ui/SupplyOrderGoodsTab'
import { SupplyOrderImplementTab } from './ui/SupplyOrderImplementTab'
import { CopackingOrdersTab } from './ui/CopackingOrdersTab'
import { OrderHistoryTab } from './ui/OrderHistoryTab'
import { StyledTabPanel } from './ui/styled'
import { Header } from './ui/Header'
import { SupplyOrdersCoefficientTab } from './ui/SupplyOrdersCoefficientTab'
import { SupplyOrderDistributionTab } from './ui/SupplyOrderDistributionTab'

const TabsComponents: Record<string, any> = {
  'productspositions': {
    Component: SupplyOrderProductTab
  },
  'goodspositions': {
    Component: SupplyOrderGoodsTab
  },
  'implementorders': {
    Component: SupplyOrderImplementTab
  },
  'copackingorders': {
    Component: CopackingOrdersTab
  },
  'coefficient': {
    Component: SupplyOrdersCoefficientTab
  },
  'distribution': {
    Component: SupplyOrderDistributionTab
  },
  'history': {
    Component: OrderHistoryTab
  }
}

export const EditSupplyOrderPage = () => {
  const { id, tab } = useParams<{ id: string; tab?: SupplyOrderEditPageTab }>()
  const history = useHistory()

  const location = useLocation().pathname.indexOf('copackingorders')

  const supplyOrderQuery = tradeGen.orders.GetSupplyOrder.useGetSupplyOrder({ supplyorderId: parseInt(id, 10) })

  useEffect(() => {
    if (!tab) history.replace(getSupplyOrderEditPath(id, 'productspositions'))
  }, [history, tab])

  if (!tab) return null

  if (isNil(supplyOrderQuery?.data)) return null

  const TablesBody = () => {
    if (supplyOrderQuery?.data?.tabs) {
      return (
        <>
          <Box>
            <Box>
              <FlexWrapper>
                <StyledTabPanel>
                  <Tabs value={ tab } scrollButtons="auto" variant="scrollable">
                    { supplyOrderQuery.data.tabs.map((tabData, index) => (
                      <Tab
                        key={ `${ tabData.label }tab` }
                        label={ tabData.label }
                        value={ tabData.value }
                        component={ RouterLink }
                        to={ getSupplyOrderEditPath(id, tabData.value as any) }
                        { ...a11yProps(index) }
                      />
                    )) }

                  </Tabs>
                </StyledTabPanel>
              </FlexWrapper>
            </Box>
          </Box>
          <Box position="relative">
            <Switch>
              {
                supplyOrderQuery.data.tabs.map((tabData) => (
                  <Route
                    key={ `${ tabData.value }rout` }
                    path={ getSupplyOrderEditPath(id, tabData.value as any) }
                    exact={ true }
                    component={ TabsComponents[tabData.value].Component }
                  />
                ))
              }
            </Switch>
          </Box>
        </>
      )
    }
    return null
  }

  return (
    <SupplyOrderContextProvider 
      supplyOrderQuery={supplyOrderQuery}
    >
      <Box mt={5}>
        <Paper sx={{ boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
          <Header {...supplyOrderQuery.data} />
        </Paper>
        {location === -1 
          ? <Paper sx={{ marginTop: '16px' }} >
            <TablesBody />
          </Paper>
          : <Box sx={{ marginTop: '16px' }}>
            <Paper sx={{ height: '114px', boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
              <TablesBody />
            </Paper>
          </Box>
        }
      </Box>
    </SupplyOrderContextProvider>
  )
}
