import { useCallback } from 'react'
import { Goods } from 'shared/api/pim/goods'
import { NewGoodsInfoValuesSubmit } from 'pages/goods/components/Steps/InfoStep/interface'
import { serializeNewGoodsFromInfoValues } from 'features/goods/getGoods/serializers'
import * as GoodsService from 'shared/api/pim/goods'
import { snackActions } from 'shared/lib/react/snackbar'

export function useCreateGoods() {
  const createGoods = useCallback(
    (newGoods: NewGoodsInfoValuesSubmit) =>
      GoodsService.createOneGoods(serializeNewGoodsFromInfoValues(newGoods))
        .then<Goods, Goods>((res) => res.data)
        .catch((err) => {
          snackActions.error('Произошла ошибка при создании товара')
          throw (err)
        }),
    []
  ) as (newGoods: NewGoodsInfoValuesSubmit) => Promise<Goods>

  return {
    createGoods,
  } as const
}
