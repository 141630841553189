import React from 'react'
import ImagePlaceholder from 'assets/images/notFoundImage/default/notFound.svg'
import { StyledPlugContainer } from 'shared/ui/components/PlugWithAddButton/styled'

export const PlugHistoryContainer = () => (
  <StyledPlugContainer height={window.innerHeight - 80}>
    <img
      className="styled-img"
      src={ImagePlaceholder}
      alt="placeholder"
    />
    <span className="title">
      {'У вас пока не было\nмассовых операций'}
    </span>
    <span id="subTitle-styled" className="subTitle">
      {
        'Здесь будет информация\nи подробности реализации\nмассовых операций'
      }
    </span>
  </StyledPlugContainer>
)