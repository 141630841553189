import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import imagePlaceholder from 'assets/images/CreateUser.svg'
import { ADMIN_PANEL_CREATE_USER } from 'shared/config'

export const EmptyUsersPage = () => (
  <Box>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={3}
      marginBottom="120px"
    >
      <Typography component="h1" variant="h3" fontSize="1.5rem">
        Пользователи
      </Typography>
    </Box>
    <div style={{ textAlign: 'center', height: '190px', marginBottom: '24px' }}>
      <img src={imagePlaceholder} alt="placeholder"/>
    </div>

    <Typography
      variant="body1"
      marginBottom="4px"
      style={{ textAlign: 'center', fontWeight: 500, fontSize: '16px' }}>У вас пока нет пользователей</Typography>
    <Typography
      mb={3}
      style={{
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '12px',
        whiteSpace: 'break-spaces',
        color: 'rgba(0, 0, 0, 0.6)'
      }}>
      {'Чтобы начать работу добавьте\n нового пользователя'}
    </Typography>
    <div style={{ textAlign: 'center' }}>
      <Button
        variant="contained"
        component={Link}
        to={ADMIN_PANEL_CREATE_USER}
        color="primary"
      >
        ДОБАВИТЬ
      </Button>
    </div>
  </Box>
)
