import { isNil, isNotNil } from 'shared/lib/checkers'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export const checkElement = (el) => (isNotNil(el) && isNotEmptyArray(el))

export const expandAllChildren = (node: Array<any>, stack?: Array<any>) => {
  const arr = stack || []

  // eslint-disable-next-line consistent-return
  node.forEach(el => {
    if (isNil(el.privileges) && isNil(el.groups)) {
      arr.push(el)
      return arr
    }
    if (checkElement(el.groups) || checkElement(el.privileges)) {
      const desired = expandAllChildren(el.groups.concat(el.privileges), arr)

      if (desired) {
        return desired
      }
    }
  })

  return arr
}