import { DOC_LIST, DOC_DETAIL_URL, COPACKING_ORDERS, COPACKING_ORDERS_DETAIL } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'
import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'

import { DocList } from './DocList'
import { WarehouseDetail } from './DocDetail'
import { CopackingOrdersPanel , OrdersDetailPanel } from './CopackingOrders'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: DOC_LIST,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: DocList
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: COPACKING_ORDERS,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: CopackingOrdersPanel
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: COPACKING_ORDERS_DETAIL,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: OrdersDetailPanel
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: DOC_DETAIL_URL,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: WarehouseDetail
  }
] as Array<Route>