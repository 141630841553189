import { useCallback } from 'react'
import { FieldRenderProps, useForm } from 'react-final-form'

import { DatePickerI } from '../DatePicker/types'
import { DatePicker } from '../DatePicker'

interface DatePickerFieldI extends FieldRenderProps<string>, DatePickerI {}

const DatePickerField = (props: DatePickerFieldI) => {
  const { label, input, InputProps, ...rest } = props
  const { name } = input
  const { change } = useForm()
  const onChange = useCallback(
    (value) => {
      change(name, value)
      if (typeof rest.onChange === 'function') {
        rest.onChange(value)
      }
    },
    [change, name, rest]
  )

  return <DatePicker {...input} label={label} {...rest} {...InputProps} onChange={onChange} />
}

export default DatePickerField
