import styled from 'styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import { AccordionSummary, Box } from '@mui/material'
import { Button } from 'shared/ui/components'
import AvatarPlaceholder from 'assets/images/UserAvatarUploadPlaceholder.svg'

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
`

export const Info = styled.div`
  color: #000000;
  font-size: 14px;
`

export const BadgeDownload = styled(Box)`
  background: #e9f4fe;
  border-radius: 4px;
  max-height: 523px;
  max-width: 500px;
`

export const Block = styled(Box)`
  img {
    width: 156px;
    height: 138px;
  }
`

export const ExpandedAccordion = styled(AccordionSummary)`
  .Mui-expanded {
    margin: 12px 0;
  }
`

export const ButtonDownloadBadge = styled(Button)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
`

export const Form = styled.form`
  ${Title}, ${Info} {
    margin-bottom: 20px;
  }
`

export const InfoAttention = styled.div`
  background-color: transparent;
  color: #0d3c61;
  font-size: 14px;
  padding-left: 34px;
`

export const InfoAttentionTitle = styled.div`
  font-size: 16px;
  color: #0d3c61;
  display: inline;
  vertical-align: super;
  margin-left: 10px;
`

export const AvatarPlaceHolder = styled.div`
  position: absolute;
  background-image: url(${AvatarPlaceholder});
  width: 40px;
  height: 40px;
  display: none;
  bottom: 35px;
  left: 30px;
  background-repeat: no-repeat;
  background-position: center center;

  input {
    display: none;
  }
`

export const DeleteAvatar = styled(DeleteIcon)`
  display: none;
  position: absolute;
  top: -10px;
  left: 80px;
  color: rgba(0, 0, 0, 0.26);
`

export const Avatar = styled.div`
  cursor: pointer;
  position: relative;

  img {
    width: 100px;
    height: 100px;
  }

  &:hover {
    ${AvatarPlaceHolder}, ${DeleteAvatar} {
      display: block;
    }
  }
`

export const AvatarImage = styled.div<{ bgUrl: string }>`
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
`

export const SepareteLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.26);
  margin: 10px 0 40px 0;
`