
const ROWS_PER_RAGE = [
  1,
  5,
  10,
  20,
  50,
  // 100
]

export const getRowsPerPageOptions = (dataLength: number, calculatedRowsPerPage?: number, limit?: number): Array<number> => {
  const getExtraRowsPerPageOptions = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ROWS_PER_RAGE.length; i++) {
      const count = ROWS_PER_RAGE[i]
  
      if (dataLength <= count) {
        return ROWS_PER_RAGE.slice(0, i + 1)
      } 
    }
  
    return ROWS_PER_RAGE
  }

  const maxPerPage = getExtraRowsPerPageOptions()

  if (calculatedRowsPerPage) {
    maxPerPage.unshift(calculatedRowsPerPage)
  }

  if (limit) {
    return [...new Set([...maxPerPage, limit].sort((a, b) => a - b))]
  }
  return [...new Set(maxPerPage.sort((a, b) => a - b))]
}
