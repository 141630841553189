import { memo } from 'react'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { DisplayedGoodsDataController } from 'pages/product/edit/main/model/DisplayedGoodsDataController'
import { useNormalizedAttributes } from 'features/product/WB/MapAttributes/model/useNormalizedAttributes'
import { ProductAttributeValue } from 'features/product/WB/MapAttributes/lib/getNormalizedAttributes'
import { SelectChangeEvent } from '@mui/material'
import { MemoECOMMarketplace } from 'pages/product/edit/main/ui/WB/ui/ProductInfoTabs/ui/ECOMWB'

import { NormalizedAttributesMapped } from './NormalizedAttributesMapped'
import { MemoNormalizedAttributesRight } from './NormalizedAttributesRight'
import { NormalizedAttributesLeft } from './NormalizedAttributesLeft'


export function CharacteristicsAndAttributes({ isArchived }: { isArchived?: boolean }) {
  const {
    normalizedAttributes,
    changeWBAttributeProperty,
  } = useNormalizedAttributes()

  const attributesWithGlobalFlag = [ ...normalizedAttributes.normalizedAttributesMapped, ...normalizedAttributes.normalizedAttributesRight ]

  const parametersName = attributesWithGlobalFlag.map(el => `attributes[${el.right.index}].attributeGlobal`)

  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()
  const removeLeftWaitFilled = (index: number) => () => {
    const conditionAttributeId =
      normalizedAttributes.normalizedAttributesMapped[index].right.id

    changeWBAttributeProperty(conditionAttributeId, { attributeId: null })
  }

  const handleSelectMapAttributeChange =
    (index: number) => (event: SelectChangeEvent) => {

      const rightIndex = parseInt(event.target.value, 10)
      const conditionAttributeId =
        normalizedAttributes.normalizedAttributesRight[rightIndex].right.id

      const { attributeId } =
        normalizedAttributes.normalizedAttributesLeft[index].left

      changeWBAttributeProperty(conditionAttributeId, { attributeId })
    }

  const handleAttributeValueChange =
    (id: number) =>
      (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { value } = event.target
        changeWBAttributeProperty(id, {
          values: value as ProductAttributeValue
        })
      }

  return (
    <>
      <MemoECOMMarketplace
        leftSideTooltip="Характеристики товара, к которому привязан продукт"
        changeDisplayedGoodsData={changeDisplayedGoodsData('characteristicsAndAttributes')}
        displayGoodsData={displayedGoodsData.characteristicsAndAttributes}
        parametersName={parametersName}
      />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {isNotEmptyArray(normalizedAttributes.normalizedAttributesMapped) && (
          <NormalizedAttributesMapped
            normalizedAttributesMappeds={
              normalizedAttributes.normalizedAttributesMapped
            }
            showGoodsInfo={displayedGoodsData.characteristicsAndAttributes}
            onUnmapAttribute={removeLeftWaitFilled}
            onAttributeValueChange={handleAttributeValueChange}
            disabled={isArchived}
          />
        )}

        {isNotEmptyArray(normalizedAttributes.normalizedAttributesRight) && (
          <MemoNormalizedAttributesRight
            normalizedAttributesRights={
              normalizedAttributes.normalizedAttributesRight
            }
            showGoodsInfo={displayedGoodsData.characteristicsAndAttributes}
            onAttributeValueChange={handleAttributeValueChange}
            disabled={isArchived}
          />
        )}

        {isNotEmptyArray(normalizedAttributes.normalizedAttributesLeft) && (displayedGoodsData.characteristicsAndAttributes) && (
          <NormalizedAttributesLeft
            normalizedAttributesLefts={
              normalizedAttributes.normalizedAttributesLeft
            }
            normalizedAttributesRights={
              normalizedAttributes.normalizedAttributesRight
            }
            onSelectMapAttributeChange={handleSelectMapAttributeChange}
            disabled={isArchived}
          />
        )}
      </div>
    </>
  )
}

export const CharacteristicsAndAttributesMemo = memo(
  CharacteristicsAndAttributes
)
