import { Paper } from '@mui/material'
import React from 'react'
import { PriorityWarehousesPage } from 'features/admin/Warehouses/PriorityWarehousesPage'

import { Header } from '../../Header'

export const WarehousesPage = () => (
  <Paper sx={{ height: '870px', marginTop: '28px' }}>
    <Header/>
    <PriorityWarehousesPage />
  </Paper>
)