import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage, faFilePdf, faLink } from '@fortawesome/free-solid-svg-icons'
import { IconButton, TextField, Dialog, DialogActions, Box, Button } from '@mui/material'
import { Create, Delete } from '@mui/icons-material'
import { useToggle } from 'shared/lib/hooks/useToggle'
import { getFileExtension, copyTextFromElem } from 'shared/lib/utils'
import {
  DeleteDoc, DocContent, DocDesc, DocHover,
  DocIcon, DocName, DocItemWrapper, DescMedia, MediaActions, DocUrl
} from 'shared/ui/goods/common'
import { GoodsFile } from 'shared/api/pim/goods/models'
import { pimApi } from 'shared/api'
import { Image } from 'shared/ui/components'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { MediaPreviewDialogContent } from 'entities/pim/MediaFilePreview'

interface DocItemProps {
  goodsId: number
  openGallery?: () => void
  item: GoodsFile
  onFileDelete: () => void
  onFileUpdate: (description: string, callback: () => void) => void
  disabled?: boolean
}

const CREATE_BTN_SX = { borderRadius: '50%', minWidth: 'auto', p: '5px' }
const FILE_THUMBNAIL_SIZE = { maxWidth: 400, maxHeight: 400 }
const FILE_THUMBNAIL_SIZE_LARGE = { maxWidth: 600, maxHeight: 600 }

export const DocItem = ({ goodsId, item, onFileDelete, onFileUpdate, disabled, openGallery }: DocItemProps) => {
  const [isEditing, toggleIsEditing ] = useToggle()
  const [isOpenDialog, toggleOpenDialog] = useToggle()
  const [isLoading, setIsLoading ] = useState(false)

  const urlOrFile = item.fileName || item.downloadUrl
  const extension = getFileExtension(urlOrFile)
  const isDocPdf = extension.includes('pdf')

  const getFilePath = pimApi.goods.getFileThumbnailApiPath
  const fileThumbnailSrc = getFilePath(goodsId, item.id, FILE_THUMBNAIL_SIZE)
  const fileThumbnailSrcLarge = getFilePath(goodsId, item.id, FILE_THUMBNAIL_SIZE_LARGE)

  const handleCopyLink = () => {
    copyTextFromElem(`${item.id}`)
  }

  const handleUpdate = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsLoading(true)

    const callback = () => {
      setIsLoading(false)
      toggleIsEditing()
    }

    onFileUpdate(event.target.value, callback)
  }

  const handleFileDelete: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onFileDelete()
  }

  return (
    <DocItemWrapper onClick={() => {
      if (openGallery) openGallery()}
    }>
      <DocContent onClick={toggleOpenDialog}>
        <Image src={fileThumbnailSrc} />
        <DocIcon>
          <FontAwesomeIcon icon={isDocPdf ? faFilePdf : faFileImage} />
          <span>{extension.toUpperCase()}</span>
        </DocIcon>
        <DocHover>
          <DeleteDoc>
            {!disabled && (
              <IconButton size="small" onClick={handleFileDelete}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            )}
          </DeleteDoc>
        </DocHover>
      </DocContent>

      {item.fileName && (
        <DocName>{item.fileName}</DocName>
      )}

      <DocUrl id={`${item.id}`}>
        {item.downloadUrl}
      </DocUrl>

      <DocDesc>
        {isEditing ? (
          <DescMedia>
            <TextField
              size="small"
              variant="outlined"
              autoFocus={true}
              fullWidth={true}
              onBlur={handleUpdate}
              defaultValue={item.description}
              disabled={disabled}
            />
          </DescMedia>
        )
          : item.description && <DescMedia>{item.description}</DescMedia>
        }

        <MediaActions>
          <LoadingButton
            size="small"
            onClick={toggleIsEditing}
            disabled={isEditing || disabled}
            loading={isLoading}
            color="inherit"
            sx={CREATE_BTN_SX}
          >
            <Create />
          </LoadingButton>

          {item.downloadUrl && (
            <IconButton size="small" color="inherit" onClick={handleCopyLink} >
              <FontAwesomeIcon icon={faLink} />
            </IconButton>
          )}
        </MediaActions>
      </DocDesc>

      <Dialog
        onClose={toggleOpenDialog}
        aria-labelledby="slow-product-doc-dialog"
        open={isOpenDialog}
      >
        <MediaPreviewDialogContent
          imgPath={fileThumbnailSrcLarge}
          onClose={toggleOpenDialog}
          actionBlock={
            <DialogActions>
              {item.downloadUrl && (
                <Box mr={3}>
                  <Button
                    component="a"
                    download={true}
                    href={item.downloadUrl}
                    variant="contained"
                    color="primary"
                  >
                    СКАЧАТЬ
                  </Button>
                </Box>
              )}
              
              <Button
                variant="contained"
                color="error"
                onClick={onFileDelete}
                disabled={disabled}
              >
                УДАЛИТЬ
              </Button>
            </DialogActions>
          }
        />
      </Dialog>
    </DocItemWrapper>
  )
}
