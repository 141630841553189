import React from 'react'
import {
  DescMedia,
  VideoContent,
  YoutubeImage,
  YoutubeLink,
} from 'shared/ui/goods/common'
import { pimApi } from 'shared/api'
import { Image, SettingsMenu } from 'shared/ui/components'
import { MoreVert } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadFileWithPath } from 'shared/lib/utils'
import LinkIcon from '@mui/icons-material/Link'

interface VideoItemProps {
  openGallery?: () => void
  item: pimApi.products.MediaFile
  onFileDelete: (item: pimApi.products.MediaFile) => void
  index: number
  productId: UniqueId
  disabled?: boolean
}

export const VideoItem = ({ item, onFileDelete, disabled, productId, openGallery }: VideoItemProps) => {
  const settingsOptions: Array<any> = [
    {
      label: 'Скопировать ссылку',
      disabled: !item.downloadUrl || !item.fileName,
      icon: <LinkIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        if (item?.downloadUrl?.length! > 1) {
          navigator.clipboard.writeText(item?.downloadUrl || '')
        } else {
          navigator.clipboard.writeText(item.fileName || '')
        }
      }
    },
    {
      label: item?.downloadUrl?.length! > 1 ? 'Скачать' : 'Перейти по ссылке',
      divider: true,
      disabled,
      icon: <DownloadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        const element = document.createElement('a')
        if (item?.downloadUrl?.length! > 1) {
          if (item.downloadUrl) {
            const url = new URL(item.downloadUrl)
            downloadFileWithPath(url.pathname, item.fileName || '')
          }
        } else {
          element.target = '_blank'
          element.href = item.fileName || ''
          element.click()
        }
      }
    },
    {
      label: 'Удалить',
      disabled,
      icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
      type: 'error',
      handler: () => {
        onFileDelete(item)
      }
    },
  ]

  const videoId = item.fileName?.split('=')?.[1]?.split('&')?.[0]
  const apiUrl = `/pim/products/ozon/product/${productId}/videos/thumbnail/${item.id}?max_height=450&max_width=450`
  const previewUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  return (
    <>
      <VideoContent
        onClick={() => {
          if (openGallery) openGallery()}
        }
        onDragStart={(e) => {e.preventDefault()}}>
        {item?.downloadUrl?.length! > 1 ?
          <Image src={apiUrl}/>
          :
          <YoutubeImage src={previewUrl}/>
        }
        <SettingsMenu
          className="settingsMenu"
          options={settingsOptions}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          iconSize="small"
          iconType={<MoreVert />}
        />
      </VideoContent>
      <YoutubeLink download={true}
        target={item?.downloadUrl?.length! > 1 ? '' : '_blank'} title={item?.fileName}
        href={item?.downloadUrl?.length! > 1 ? item.downloadUrl : item.fileName}> 
        <DescMedia>{item?.fileName}</DescMedia> 
      </YoutubeLink> 
    </>
  )
}
