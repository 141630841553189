import { gql } from '@apollo/client'
import { IAvailableFilters } from 'pages/product/productList/model/types'

export const getFiltersList = (filtersConfig?: IAvailableFilters) => {
  const generateFilters = (config) => {
    if (!config) {
      return `
        abcClass { label value count }
        xyzClass { label value count }
        marketplaceId { label value count }
        autoOrdering { label value count }
        category { label value count }
        goodsBrandId { label value count }
        companyId { label value count }
        contragentId { label value count }
        syncStatusId { label value count }
        tags { label value count }
      `
    }

    return Object.keys(config)
      .filter((key) => config[key] === true)
      .map((key) => `${key} { value label count }`)
      .join('\n')
  }

  const filters = generateFilters(filtersConfig)

  return gql`
  query GetFiltersList(
    $abcClass: [ABCClass!] = []
    $autoOrdering: Boolean = null
    $cardStatusId: [Int!] = []
    $category: [String!] = []
    $companyId: [Int!] = []
    $companymarketplaceId: [Int!] = []
    $contragentId: [Int!] = []
    $orderStatus: OrderStatus = null
    $orderStatusInterval: Int = 30
    $tags: [String!] = null
    $excludeInArchive: Boolean = false
    $goodsBrandId: [Int!] = []
    $goodsType: [GoodsType!] = null
    $marketplaceId: [Int!] = []
    $productId: Int = null
    $searchString: String = null
    $stocksStatus: StocksStatus = null
    $syncStatusId: [Int!] = []
    $xyzClass: [XYZClass!] = []
  ) {
    products(
      searchString: $searchString
      excludeInArchive: $excludeInArchive
      abcClass: $abcClass
      autoOrdering: $autoOrdering
      category: $category
      cardStatusId: $cardStatusId
      companyId: $companyId
      companymarketplaceId: $companymarketplaceId
      contragentId: $contragentId
      goodsBrandId: $goodsBrandId
      goodsType: $goodsType
      marketplaceId: $marketplaceId
      productId: $productId
      stocksStatus: $stocksStatus
      orderStatus: $orderStatus
      orderStatusInterval: $orderStatusInterval
      syncStatusId: $syncStatusId
      xyzClass: $xyzClass
      tags: $tags
    ) {
      filters {
        ${filters}
      }
    }
  }
`
}
