import React from 'react'

import { DeleteIcon } from './styled'


export const PrivilegeRemoveCell = ({ row, onRowDelete }) => {
  const { usersQuantity } = row
  if (row.globalPrivilege) {
    return (<div/>)
  }
  return (
    <span title="Удалить">
      <DeleteIcon
        usersQuantity={usersQuantity || 0}
        className="delete_action"
        onClick={(usersQuantity || 0) === 0 ? (e) => {
          e.stopPropagation()
          onRowDelete?.(row.id)
        } :
          () => {}}
      />
    </span>
  )
}