import { useQuery } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

export const getSimpleProductQueryKey = (productId?: UniqueId) =>
  ['get', 'pim/products/simple/product', productId] as const

type PimProductsSimpleProductQueryKey = ReturnType<
  typeof getSimpleProductQueryKey
>

export const fetchSimpleProduct = (productId: UniqueId) =>
  api
    .get<pimApi.products.simple.SimpleProduct>(`/pim/products/simple/product/${productId}`)
    .then(({ data }) => data)

export const useFetchSimpleProductQuery = (productId?: UniqueId) =>
  useQuery<
    pimApi.products.simple.SimpleProduct,
    errorType,
    pimApi.products.simple.SimpleProduct,
    PimProductsSimpleProductQueryKey
  >(
    getSimpleProductQueryKey(productId),
    (context) => fetchSimpleProduct(context.queryKey[2] as UniqueId),
    {
      enabled: !!productId,
      refetchOnMount: false
    }
  )
