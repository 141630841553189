import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Accordion } from 'shared/ui/components/Accordion'
import {
  Box,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { FormDateTimePicker } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'

import { validationSchema } from './lib/validationSchema'

interface ManagerAddModalProps {
  close: () => void;
  category: string
  handleUserAdd?: (userData) => void
  handleUserChange?: (userData) => void
  manager: any
  name: string
}

export const UserAccessModal = ({
  close,
  category,
  handleUserAdd,
  name,
  handleUserChange,
  manager
}: ManagerAddModalProps) => {

  const onSubmit = (userData) => {
    if (manager?.dateIn) {
      if (handleUserChange) handleUserChange(userData)
    }
    else if (handleUserAdd) handleUserAdd(userData)
    close()
  }


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && event.target.value === undefined) {
        const button = document.getElementById('submit')
        event.preventDefault()
        button?.click()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const [, setDatePeriodOpen] = useState(false)

  const handleDatePeriodExpand = () => {
    setDatePeriodOpen(true)
  }
  const handleDatePeriodClose = () => {
    setDatePeriodOpen(false)
  }

  const validate = useValidationSchema(validationSchema)

  const AddManagerDate = ({ errors, values }) => (
    <>
      <Grid item={true} xs={12} mt={4}>
        <Accordion
          title="Открыть доступ позднее"
          single={false}
          onOpen={handleDatePeriodExpand}
          onClose={handleDatePeriodClose}
          sx={{ borderBottomRightRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}

        >
          <Grid container={true} item={true} xs={12} spacing={2} pb={2}>
            <StyledField item={true} xs={8}>
              <Field
                name="dateIn"
                label="Дата Время"
                value={values?.dateIn}
                helperText={errors && errors?.dateIn ? errors?.dateIn : ''}
                error={!!errors?.dateIn}
                InputLabelProps={{
                  shrink: true,
                }}
                component={FormDateTimePicker}
              />
            </StyledField>
          </Grid>
        </Accordion>
      </Grid>

      <Grid item={true} xs={12}>
        <Accordion
          title="Закрыть доступ"
          onOpen={handleDatePeriodExpand}
          onClose={handleDatePeriodClose}
          sx={{ borderTopRightRadius: '0 !important', borderTopLeftRadius: '0 !important' }}
        >
          <Grid container={true} item={true} xs={12} spacing={2} pb={2}>
            <StyledField item={true} xs={8}>
              <Field
                name="dateOut"
                label="Дата Время"
                value={values?.dateOut}
                helperText={errors && errors?.dateOut ? errors?.dateOut : ''}
                error={!!errors?.dateOut}
                InputLabelProps={{
                  shrink: true,
                }}
                component={FormDateTimePicker}
              />
            </StyledField>
          </Grid>
        </Accordion>
      </Grid>
    </>)

  const EditManagerDate = ({ values, errors }) => (
    <Grid container={true} item={true} xs={12} mt={4}>
      <Typography fontSize={14} fontWeight={500} mb={2}>
        Закрыть доступ позднее
      </Typography>
      <StyledField item={true} xs={8}>
        <Field
          name="dateOut"
          label="Дата Время"
          value={values.dateOut}
          helperText={errors && errors?.dateOut ? errors?.dateOut : ''}
          error={!!errors?.dateOut}
          InputLabelProps={{
            shrink: true,
          }}
          component={FormDateTimePicker}
        />
      </StyledField>
    </Grid>)

  return (
    <Box width="480px">
      <IconCloseButton aria-label="close" onClick={close}>
        <img src={CloseIcon} alt="close icon" />
      </IconCloseButton>
      <Typography variant="h6" fontSize="20px" style={{ whiteSpace: 'break-spaces', maxWidth: '440px' }} px={3} pt={3} pb={1}>
        {manager.dateIn ? `Изменить настройки доступа к\u00A0${category}?` : `Открыть доступ к ${category}?`}
      </Typography>
      <Typography variant="subtitle1" px={3}>
        {name}
      </Typography>
      <Box px={3}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={manager}
          render={({ handleSubmit, values, errors, valid }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              {
                manager?.dateIn
                  ? <EditManagerDate errors={errors} values={values}/>
                  : <AddManagerDate errors={errors} values={values}/>
              }

              <DialogActions sx={{
                marginTop: '32px',
                padding: 0,
                marginBottom: '24px'
              }}>

                <Box>
                  <Button
                    onClick={close}
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    id="submit"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={manager.dateIn ? !values?.dateOut || !valid : !valid}
                    loading={false}
                    sx={{
                      marginLeft: 2,
                    }}
                  >
                    {!manager.dateIn ? 'ОТКРЫТЬ' : 'ИЗМЕНИТЬ'}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )
          }
        />
      </Box>
    </Box>
  )
}



