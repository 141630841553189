import { Box, Grid, Typography } from '@mui/material'
import { ParamsTitle } from 'pages/product/edit/main/ui/styled'
import React from 'react'
import {
  MaskedInputField,
  Field,
  InputField,
  SelectField,
} from 'shared/ui/components/form'
import { Option } from 'shared/ui/components/interface'
import styled from 'styled-components'

interface OzonInfoProps {}

const TypographyStyled = styled(Typography)`
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.15px;
`

const VAT_RATE_OPTIONS: Array<Option> = [
  {
    value: '0',
    label: 'Не облагается',
  },
  {
    value: '0.1',
    label: '10%',
  },
  {
    value: '0.2',
    label: '20%',
  },
]

const OzonInfo: React.FC<OzonInfoProps> = () => (
  <>
    <Box mt={3}>
      <ParamsTitle>
        <span>Артикул, Цена и НДС</span>
      </ParamsTitle>
    </Box>
    <Box mb={1}>
      <TypographyStyled>
        Артикул нужно выбрать сейчас, а цену и НДС можно добавить позднее
      </TypographyStyled>
    </Box>
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={4}>
        <Field
          name="offerId"
          label="Артикул"
          component={InputField}
          errorAfterTouch={true}
        />
      </Grid>
      <Grid item={true} xs={4}>
        <Field
          name="price"
          label="Цена, р."
          component={MaskedInputField}
          errorAfterTouch={true}
          scale={2}
          min={0}
        />
      </Grid>
      <Grid item={true} xs={4}>
        <Field
          name="vat"
          label="НДС"
          component={SelectField}
          options={VAT_RATE_OPTIONS}
          errorAfterTouch={true} 
        />
      </Grid>
    </Grid>
  </>
)

export default OzonInfo
