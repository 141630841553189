import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider as StyledProvider } from 'styled-components'
import { theme } from 'shared/config'

export const withTheme = (component: () => React.ReactNode) => () => (
  <StyledEngineProvider injectFirst={true}>
    <ThemeProvider theme={theme}>
      <StyledProvider theme={theme}>
        <CssBaseline />
        {component()}
      </StyledProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)