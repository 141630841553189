import React, { useCallback } from 'react'
import { AxiosResponse } from 'axios'
import { useField } from 'react-final-form'
import { Box, Grid, Typography, Divider } from '@mui/material'
import { history } from 'app/providers/with-router'
import { OzonBrandSelectField } from 'features/product/OZON/GeneralInformation/field/OzonBrandSelectField'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { Field } from 'shared/ui/components/form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { ozonproxyApi, pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { downloadFile } from 'shared/lib/utils'
import { InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'

import { MarketplaceFormProps } from '../../config'
import { getSyncAvailability } from '../../lib/getSyncAvailability'

const DEFAULT_FILE_NAME = 'template.xlsx'

export const getOptionFromCategory = (
  category: ozonproxyApi.model.Category
): any => ({
  label: `${category.categoryName}/${category.typeName}`,
  value: category.typeId,
  descriptionCategoryId: category.descriptionCategoryId
})

export const MarketplaceFormOzon: React.FC<MarketplaceFormProps> = ({
  companymarketplaceId,
}) => {
  const {
    mutate: uploadMutation,
    isLoading: isUploading,
  } = pimApi.products.ozon.useUploadOzonProductBulkCreationFile()
  const { mutate: downloadMutation, isLoading: isDownloading } =
    pimApi.products.ozon.useDownloadOzonProductBulkCreationTemplateFile()

  const categoryIdField = useField('categoryOzon').input.value
  const brandIdField = useField('brandId').input.value
  const useGoodsMedia = useField('useGoodsMedia').input.value

  const download = () => {
    downloadMutation(
      {
        companymarketplaceId,
        categoryId: categoryIdField?.value,
        parentCategoryId: categoryIdField?.descriptionCategoryId,
        templateType: categoryIdField?.value ? 'by_category' : 'minimal',
        brand: brandIdField?.value ? 'fixed' : 'variable',
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName =
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ) || DEFAULT_FILE_NAME

          downloadFile(file, decodeURIComponent(fileName.replace(/%2C/g, ',')))
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }


  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
        companymarketplaceId,
        useGoodsMedia: useGoodsMedia || false,
        categoryId: categoryIdField?.value,
        parentCategoryId: categoryIdField?.descriptionCategoryId,
        ...(brandIdField?.value ? { brandId: brandIdField?.value } : {}),
        templateType: categoryIdField?.value ? 'by_category' : 'minimal',
      },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={getSyncAvailability(data?.data.responseType)}
              />
            ),
            onAccept: () => {
            },
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const getCategories = useCallback(
    (searchString: string) =>
      ozonproxyApi
        .fetchCategories(searchString, companymarketplaceId)
        .then((data) => data.data.map(getOptionFromCategory)),
    [companymarketplaceId]
  )

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box mb={1}>
      <Grid>
        <Grid container={true}  spacing={2} mb={6}>
          <Grid item={true} xs={4.9}>
            <InputLabel label="Выберите категорию" required={false}/>
            <InputWrapper>
              <Field
                name="categoryOzon"
                component={AutocompleteCatalogSearchField}
                fullWidth={true}
                fetchCatalog={getCategories}
                placeholder="Категория"
                disableClearable={false}
              />
            </InputWrapper>
          </Grid>
          <Grid item={true} xs={4.9}>
            <InputLabel label="Выберите бренд" required={false}/>
            <InputWrapper>
              <Field
                name="brandId"
                placeholder="Бренд"
                errorAfterTouch={true}
                fullWidth={true}
                component={OzonBrandSelectField}
                disableClearable={false}
                companymarketplaceId={companymarketplaceId}
              />
            </InputWrapper>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Box display="flex" justifyContent="end" mb={1}>
            <Typography variant="body2" gutterBottom={true} component="span">
              Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
            </Typography>
          </Box>
          <Divider/>
        </Grid>
        <Grid item={true} xs={12} p={0}>
          <BulkFileActionsFooter
            download={download}
            upload={upload}
            isUploading={isUploading}
            isDownloading={isDownloading}
            onGoBack={handleGoBack}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
