import styled from 'styled-components'

export const SettingsMenuCellWrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiIconButton-root {
    padding: 0;
    svg {
      color:${({ theme }) => `${theme.palette.action.disabled}`};
    }
  }
`