import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  >div {
    background-color: #FFFFFF;
    border-radius: 4px;
  }
  
  .copacking-order-header {
    position: relative;
    box-shadow: 0 0 0 1px #E0E0E0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .creator-info {
      padding-right: 24px;
      padding-left: 24px;
      padding-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      color: ${({ theme }) => theme.palette.text.disabled};
    }
    
    .orders-info {
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      .orders-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .orders-name {
          font-size: 24px;
          color: #000000DE;
        }
      }

      .orders-transition {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }
    }
  }
  
  .copacking-order-main {
    box-shadow: 0 1px 3px 0 #0000001F, 0 1px 1px 0 #00000024, 0 2px 1px -1px #00000033;
  }
`