import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'

import {

  NewWBProductResponse,
} from '../models'
import { api } from '../../../base'

export const createProductWB = (newWBProduct:
  pimApi.products.AddWBProduct |
  pimApi.products.CopyWBProduct |
  pimApi.products.CreateWBProduct) =>
  api.post<NewWBProductResponse>('/pim/products/wb/product', newWBProduct)

export const useCreateProductWBMutation = () =>
  useMutation<
    AxiosResponse<NewWBProductResponse>,
    errorType,
    pimApi.products.AddWBProduct | pimApi.products.CopyWBProduct | pimApi.products.CreateWBProduct
  >(createProductWB)


export const updateProductWB = (productId: UniqueId, editedWBCard: pimApi.products.wb.EditWBNomenclature) =>
  api.put<pimApi.products.wb.WBNomenclature>(`/pim/products/wb/product/${productId}`, editedWBCard)

export const useUpdateProductWBMutation = (productId: UniqueId) => useMutation<
  AxiosResponse<pimApi.products.wb.WBNomenclature>,
  AxiosError<errorType>,
  pimApi.products.wb.EditWBNomenclature
  >((editedWBCard) => updateProductWB(productId, editedWBCard))


export * from './media'
export * from './delete'
export * from './massPriceChange'
export * from './bulkCreation'
export * from './bulkEdit'
export * from './content'
export * from './variation'
export * from './price'
export * from './model'
export * from './get'
export * from './search'
export * from './barcodes'
export * from './getContentRating'