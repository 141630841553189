import { Box } from '@mui/material'

import { StyledDot, StyledProductXyzClass } from './styled'

export const ProductXyzClass = ({ value }) => {

  const productClass = (data: string | null) => {
    if (data?.length) {
      switch (data[0]) {
        case 'X':
          return '#4CAF50'
        case 'Y':
          return '#FFB547'
        case 'Z':
          return '#F50057'
        default:
          return ''
      }
    } else return ''
  }

  if (value === null || !value) {
    return <></>
  }

  return (
    <Box>
      <StyledProductXyzClass>
        <StyledDot color={productClass(value)}/> {value}
      </StyledProductXyzClass>
    </Box>
  )
}
