import { useState } from 'react'
import { TextInput } from 'shared/ui/components'
import { Grid } from '@mui/material'
import { noop } from 'shared/lib/lodash/noop'

import {
  EditableStyledInput, StyledTd,
  StyledButton, AddAcceptanceViewer,
  StyledSaveIcon,
} from './styled'

const onlyNumberTest = new RegExp('^[0-9]+$')

interface Props {
  save: (value: string) => Promise<void>;
}

export const NewAcceptance = ({ save }: Props) => {
  const [value, setValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (onlyNumberTest.test(ev.target.value) || ev.target.value === '') {
      setValue(ev.target.value)
    }
    
  }
  const onSave = async () => {
    if (!parseInt(value, 10)) return
    await save(value)
    finishEditInput()
  }

  const startEditInput = () => {
    setIsEdit(true)
  }

  const finishEditInput = () => {
    setValue('')
    setIsEdit(false)
  }

  return (
    <tr>
      <StyledTd colSpan={5}>
        {isEdit ? (
          <Grid container={true} wrap="nowrap" spacing={2} alignItems="center">
            <Grid item={true}>
              <EditableStyledInput> 
                <TextInput
                  onBlur={noop}
                  onFocus={noop}
                  onChange={onChange}
                  name="customImput"
                  placeholder="Введите кол-во"
                  value={value}
                />
              </EditableStyledInput>
            </Grid>
            <Grid item={true}  container={true} spacing={1}>
              <Grid item={true}>
                <StyledButton variant="contained" size="small" onClick={onSave}>
                  <StyledSaveIcon fontSize="small" />
                  СОХРАНИТЬ
                </StyledButton>
              </Grid>
              <Grid item={true}>
                <StyledButton size="small" onClick={finishEditInput}>ОТМЕНА</StyledButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <AddAcceptanceViewer onClick={startEditInput}>
            Добавить товар без ШК
          </AddAcceptanceViewer>
        )}
      </StyledTd>
    </tr>
  )
}
