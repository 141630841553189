import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
  extends Omit<
    tradeApi.SupplyOrderGoodsPosition,
    | 'price'
    | 'products'
    | 'goodsId'
  > {
    goodsPrice: number;
    id: number
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  positionNumber: 'positionNumber',
  packagingInfo: 'packagingInfo',
  goodsName: 'goodsName',
  goodsCode: 'goodsCode',
  goodsPrice: 'goodsPrice',
  quantity: 'quantity',
  id: 'id',
  quantityAdded: 'quantityAdded',
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.positionNumber,
      columnName: columns.positionNumber,
      title: '№',
      width: 74,
      sortingEnabled: true,
    },
    {
      name: columns.goodsCode,
      columnName: columns.goodsCode,
      title: 'Артикул товара',
      width: 184,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.WordWrapCell,
    },
    {
      name: columns.goodsName,
      columnName: columns.goodsName,
      title: 'Наименование товара',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.NameLinkBlank,
    },
    {
      name: columns.packagingInfo,
      columnName: columns.packagingInfo,
      title: 'Информация об упаковке',
      width: 320,
      wordWrapEnabled: true,
      sortingEnabled: true,
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'Количество товара',
      width: 100,
      wordWrapEnabled: true,
      sortingEnabled: false,
    },
    {
      name: columns.quantityAdded,
      columnName: columns.quantityAdded,
      title: 'Добавлено для кратности упаковки',
      width: 180,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.IsEmptyNull,
    },
    {
      name: columns.goodsPrice,
      columnName: columns.goodsPrice,
      title: 'Стоимость товара',
      width: 200,
      sortingEnabled: true,
      align: 'right',
      cellType: CellType.Sum
    }
  ],
}
