import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'

import { ActivityProductType } from './model'

import { toSnakeCase } from '../../lib/transform'
import { toSnake } from '../../lib/transform/toSnakeCase'

interface IActivityParams {
  activityId: UniqueId
  searchString?: string
  page?: number
  pageSize?: number
}

export const getActivityProductsQueryKey = (queryParams: IActivityParams) =>
  ['get', `activity/${queryParams.activityId}/products`, queryParams] as const

type GetActivityInfoQueryKey = ReturnType<typeof getActivityProductsQueryKey>

function getActivityProducts(queryParams: IActivityParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  const { activity_id, ...params } = queryParamsSnake
  return api.get<ActivityProductType>(
    `/activity/activity/${queryParams.activityId}/products`, { params }).then(res => res.data)
}

const getAllActivityProducts = (context: QueryFunctionContext<GetActivityInfoQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getActivityProducts(queryParams)
}

export const useGetActivityProductsQuery = (queryParams: IActivityParams) =>
  useQuery<
    ActivityProductType,
    errorType,
    ActivityProductType,
    GetActivityInfoQueryKey
  >(
    getActivityProductsQueryKey(queryParams),
    getAllActivityProducts,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
