import { Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { pimGen } from 'shared/lib/generated'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { hasRoleAccess } from 'shared/api/base'

import {
  FailedStatus,
  HistoryDataWrap,
  HistoryItemInfo,
  ListItemWrap,
  SettingsBox,
  StatusWrapper,
  SuccsesfulStatus,
  TitleStatusWrap,
} from  '../../../bulkOperationsPage/ui/styled'

interface IListItem {
  bulkOperationData: pimGen.bulkOperation.GetBulkOperation.ResponseType
  historyId: number | null
  handleChooseHistoryItem: (e: number) => void
}

export const HistoryListItem = ({
  bulkOperationData,
  handleChooseHistoryItem,
  historyId
}: IListItem) => (
  <ListItemWrap
    onClick={() =>
      handleChooseHistoryItem(bulkOperationData.id)
    }
    $isActive={historyId === bulkOperationData.id}
  >
    <SettingsBox>
      <AddIcon className="styled-icons" fontSize="medium" />
    </SettingsBox>
    <TitleStatusWrap>
      <HistoryItemInfo>
        <Typography className="styled-typography" variant="subtitle2">
          {bulkOperationData.bulkoperationtypeName}
        </Typography>
        <StatusWrapper>
          {bulkOperationData.error ? (
            <FailedStatus>
              <span>
                <ClearIcon className="styled-icon" fontSize="small" />
              </span>
              Не успешно
            </FailedStatus>
          ) : (
            <SuccsesfulStatus>
              <span>
                <DoneIcon className="styled-icon" fontSize="small" />
              </span>
              Успешно
            </SuccsesfulStatus>
          )}
        </StatusWrapper>
      </HistoryItemInfo>
      <HistoryDataWrap>
        <span>ID {bulkOperationData.id}</span>
        {bulkOperationData.created ? (
          <span>
            {format(
              new Date(bulkOperationData.created),
              DateFormats.dayMonthYearHoursMinutes
            )}
          </span>
        ) : null}
        {hasRoleAccess('ADMIN') ? (
          <span>{bulkOperationData.changer}</span>
        ) : null}
      </HistoryDataWrap>
    </TitleStatusWrap>
  </ListItemWrap>
)
