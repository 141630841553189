import { memo, useMemo, useCallback } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useMarketplaceList } from 'shared/api/pim/dictionaries'

interface Option {
  label: string
  value: number
}

interface SelectProps {
  onChange?: (value: number | null) => void
  currentValue?: number | null
  disabled?: boolean
  disableClearable?: boolean
}


const Select = ({
  onChange,
  currentValue,
  disabled,
  disableClearable = true,
}: SelectProps) => {

  const { marketplaceListQuery } = useMarketplaceList()

  const options = useMemo(
    () => marketplaceListQuery.data?.map((m) => ({
      label: m.name || '',
      value: m.id, })) || [],
    [marketplaceListQuery.data]
  )

  const handleChange = useCallback((_, value: Option | null) => {
    if (onChange) {
      const newSelectedElement = options.find(
        item => item.value === value?.value
      )
      if (newSelectedElement === undefined) {
        onChange(null)
      }
      else  {
        onChange(newSelectedElement?.value)
      }
    }
  }, [options, onChange])

  return (
    <Autocomplete<Option, false, typeof disableClearable>
      disabled={disabled}
      disableClearable={disableClearable}
      options={options}
      size="small"
      noOptionsText="Не найдено"
      clearOnBlur={false}
      blurOnSelect={true}
      autoHighlight={true}
      value={options.find(item => item.value === currentValue) || null}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Маркетплейс"
          placeholder="Введите текст"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {marketplaceListQuery.isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export const SelectMarketplace = memo(Select)