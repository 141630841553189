import React from 'react'

import { ProductPriceHistory } from '../../../../../../../api/pim/price'
import { StyledContainer } from '../NomenclatureCell/styled'

export const CopackingCostCell = ({ row }: { row: ProductPriceHistory }) => {
  const { copackingCost } = row
  return (
    <StyledContainer>
      <div style={{ width: '100%' }}/>
      <span>{copackingCost}</span>
    </StyledContainer>
  )
}