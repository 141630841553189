import { Box, Typography } from '@mui/material'
import { round } from 'shared/lib/utils/round'
import { useMemo } from 'react'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { getPreviousElements } from 'features/analytics/chart/lib/getPreviousElements'
import { convertDate } from 'shared/lib/utils/DateAppearanceSettings'

export const ChartDetails = ({ 
  visualRange,
  timelineData,
  groupby
}: { timelineData: any, visualRange: any, groupby?: string }) => {

  const startDateString = visualRange?.[0] || visualRange.startValue

  const endDateString = visualRange?.[1] || visualRange.endValue

  const { currentElements, previousElements } = useMemo(() =>
    getPreviousElements(
      timelineData,
      startDateString,
      endDateString), 
  [timelineData, startDateString, endDateString, groupby])

  const prevTotalOrdersUnits = previousElements.reduce((sum, elem) => sum + (elem.orders || 0), 0)
  const prevIntervalOrdersItems = previousElements.reduce((sum, elem) => sum + (elem.ordersItems || 0), 0)
  const prevIntervalOrdersCurrency = previousElements.reduce((sum, elem) => sum + (elem.ordersCurrency || 0), 0)
  const prevIntervalNumberOfOrders = previousElements.reduce((sum, elem) => sum + (elem.numberOfOrders || 0), 0)

  const currentOrdersUnits = currentElements.reduce((sum, elem) => sum + (elem.orders || 0), 0)
  const currentOrdersItems = currentElements.reduce((sum, elem) => sum + (elem.ordersItems || 0), 0)
  const currentOrdersCurrency = currentElements.reduce((sum, elem) => sum + (elem.ordersCurrency || 0), 0)
  const lastDayTick = currentElements?.[currentElements.length - 1]
  const currentNumberOfOrders = currentElements.reduce((sum, elem) => sum + (elem.numberOfOrders || 0), 0)

  const unitsOrdersDelta = prevTotalOrdersUnits ? currentOrdersUnits - prevTotalOrdersUnits : undefined
  const averagePriceDelta = (prevIntervalOrdersItems && prevIntervalOrdersCurrency) ?
    (currentOrdersCurrency / currentOrdersItems) - (prevIntervalOrdersCurrency / prevIntervalOrdersItems) : undefined
  const numberOfOrdersDelta = (prevIntervalOrdersItems && prevIntervalNumberOfOrders && currentOrdersItems && currentNumberOfOrders) ?
    (currentOrdersItems / currentNumberOfOrders) - (prevIntervalOrdersItems / prevIntervalNumberOfOrders) : undefined
  // неизвестно, как отображать разницу остатков
  // const stocksDelta = intervalStocks - prevIntervalStocks

  return (
    <Box sx={{ height: '88px', display: 'flex', flexDirection: 'row', gap: '50px' }}>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Заказы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(currentOrdersUnits, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsOrdersDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsOrdersDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsOrdersDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsOrdersDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      {lastDayTick &&
        <Box
          sx={{
            height: '88px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
            {`Остатки на ${convertDate(lastDayTick?.endDate || lastDayTick?.period)}`}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
              {lastDayTick.stocks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </Typography>
          </Box>
        </Box>
      }
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Ср. цена за ед. продукта
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round((currentOrdersCurrency / currentOrdersItems) || '0', 0)} ₽
          </Typography>
          {averagePriceDelta ?
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px'
            }}>
              <CallMadeIcon sx={{
                fontSize: 14,
                color: averagePriceDelta > 0 ?' #757575' : '#F50057',
                rotate: averagePriceDelta > 0 ? '' : '90deg'
              }}/>
              <span style={{ fontSize: 12, color: averagePriceDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
                {Math.abs(Number(Math.round(averagePriceDelta || 0))).toLocaleString()}
              </span>
            </div>
            :
            <></>
          }
        </Box>
      </Box>
      <Box
        sx={{
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Кол-во продуктов в корзине
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round((currentOrdersItems / currentNumberOfOrders) || '0', 2)}
          </Typography>
          {numberOfOrdersDelta ?
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px'
            }}>
              <CallMadeIcon sx={{
                fontSize: 14,
                color: numberOfOrdersDelta > 0 ?' #757575' : '#F50057',
                rotate: numberOfOrdersDelta > 0 ? '' : '90deg'
              }}/>
              <span style={{ fontSize: 12, color: numberOfOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
                {Math.abs(numberOfOrdersDelta).toLocaleString() || 0}
              </span>
            </div>
            :
            <></>
          }
        </Box>
      </Box>
      {/* <Box */}
      {/*  sx={{ */}
      {/*    width: '206px', */}
      {/*    height: '88px', */}
      {/*    display: 'flex', */}
      {/*    flexDirection: 'column', */}
      {/*    justifyContent: 'center', */}
      {/*    paddingTop: '16px', */}
      {/*    paddingLeft: '16px', */}
      {/*    paddingRight: '16px' */}
      {/*  }}> */}
      {/*  <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}> */}
      {/*    Процент выкупа */}
      {/*  </Typography> */}
      {/*  <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}> */}
      {/*    {round((totalOrdersItems / ordersBuyoutSum) * 100 || '0', 2)}% */}
      {/*  </Typography> */}
      {/* </Box> */}
    </Box>
  )
}
