import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { StyledLink } from './styled'

interface IDocumentWithLink {
  fileName: string
  fileUrl: string
}

export const DocumentWithLinkCell = ({ fileName, fileUrl }: IDocumentWithLink) => {
  if (!fileName || !fileUrl) return <></>
  return (
    <StyledLink href={fileUrl} title={fileName}>
      <InsertDriveFileIcon />
      <div>
        {fileName}
      </div>
    </StyledLink>

  )
}