import { useCallback } from 'react'
import { Button } from '@mui/material'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import { ChipEcom } from 'shared/ui/components'
import { OneCModal } from 'shared/ui/components/Modals'
import { DocType, docNextStatus } from 'shared/services/interfaces/doc'
import usePending from 'shared/lib/hooks/PendingHook'
import { docChangeStatus } from 'store/doc'
import { dialogModel } from 'shared/ui/components/dialog'
import { ReceiptDocStatusEnum } from 'shared/lib/generated/wms/Api'

import * as UI from './Table/styled'
import { checkOnEditable } from './Table/helpers'
import { ConfirmModal } from './ConfirmModal'

interface DocsFooterProps {
  docDetail: DocType;
}

export const DocsFooter = ({ docDetail }: DocsFooterProps) => {
  const { id, versionNo, status } = docDetail || { id: null, versionNo: null }
  const [changeStatus, isChangeStatusLoading] = usePending(docChangeStatus)

  const shouldShowFooterButtons = checkOnEditable(status)

  const changeStatusParams = {
    id,
    version_no: versionNo,
  }

  const ChangeStatusButtons = useCallback(() => {
    if (shouldShowFooterButtons) {
      const setPauseStatus = () => {
        changeStatus({ ...changeStatusParams, operation: docNextStatus.pause })
      }
      const setFinishStatus = () => {
        dialogModel.openDialog(
          {
            component: ConfirmModal,
            onAccept: () => {
              changeStatus({ ...changeStatusParams, operation: docNextStatus.finish })
            }
          }
        )
      }
      const setUnPauseStatus = () => {
        changeStatus({ ...changeStatusParams, operation: docNextStatus.resume })
      }

      if (isChangeStatusLoading) {
        return <OneCModal />
      }

      return <UI.ChangeStatusWrapper>
        {status === ReceiptDocStatusEnum.Started ? (
          <Button
            variant="contained"
            color="primary" onClick={setPauseStatus}>Приостановить приемку
          </Button>
        ) : <Button
          variant="contained"
          color="primary" onClick={setUnPauseStatus}>Возобновить приемку
        </Button>
        }
        <UI.Or>или</UI.Or>
        <Button
          variant="contained"
          color="primary" onClick={setFinishStatus}>Завершить приемку
        </Button>
      </UI.ChangeStatusWrapper>
    }
    return null
  }, [changeStatusParams, isChangeStatusLoading])

  return (
    <UI.FooterWrapper style={{ justifyContent: 'flex-end' }}>
      {status === ReceiptDocStatusEnum.FinishedWithDiff ? <ChipEcom
        icon={<PriorityHighOutlinedIcon />}
        label="Есть расхождения в количестве товаров!"
        $chipStyle={status}
      /> : <ChangeStatusButtons />}
    </UI.FooterWrapper>
  )
}
