import React, { memo } from 'react'
import { LoadingButton } from '@mui/lab'
import { customerApi } from 'shared/api'
import { saveAs } from 'file-saver'

const BadgeDownload = () => {
  const { isLoading, mutate: getBadge } = customerApi.user.badge.useGetUsersBadge()
  const handleBadgeDownloadClick = () => {
    getBadge({} as any, { onSuccess: (response) => saveAs(response.data, 'badge.pdf') })
  }
  return (
    <LoadingButton sx={{ height: '32px', width: '177px', padding: '0 10px' }}
      variant="outlined" onClick={handleBadgeDownloadClick} loading={isLoading}>
      Распечатать бейдж
    </LoadingButton>
  )
}

export default memo(BadgeDownload)
