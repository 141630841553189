import { productModel } from 'entities/pim/product'
import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

const CardTypeToSchema: Record<productModel.ozonModel.CardType, yup.BaseSchema> = {
  'new': yup.object().shape({
    category: yup.object().required(),
  }),
  'old': yup.object().shape({
    sku: yup.string().required(),
    name: yup.string().required(),
    offerId:  yup.string().max(255).required(),
    price: yup.string().required(),
    vat: yup.string().required()
  })
}

export const validationSchema = yup.object().shape({
  marketplaceId: yup.number().required().transform(yupTransformEmptyStringInNull),
  companyId: yup.number().required().transform(yupTransformEmptyStringInNull),
})
  .when(
    // на самом деле undefined так как вне верхнего обьекта
    'cardType',
    // @ts-ignore
    (_: any, __: any, { value }: { value: productModel.ozonModel.CreateFormValues }) => {
      const { cardType } = value

      return CardTypeToSchema[cardType]
    }
  
  )
