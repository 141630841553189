import styled from 'styled-components'
import { CircularProgressStyled as SharedCircularProgress } from 'shared/ui/components/CircularProgress/CircularProgress'

export const Header = styled.div`
  padding: 16px 24px 16px 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  color:${({ theme }) => `${theme.palette.text.primary}`};
`

export const CircularProgressStyled = styled(SharedCircularProgress)`
  margin: 0;
  left: 50%;
  top: 300px;
`

export const StyledTable = styled.div<{ $isLoading: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 14px;
  overflow-x: auto;
  pointer-events: ${ ({ $isLoading }) => $isLoading && 'none' };

  > div {
    width: 100%;
    min-width: max-content;

    .table-header {
      height: 100px;
      border-top: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      display: flex;
      flex-direction: row;

      .product-header-cell {
        font-weight: 500;
        cursor: default;
        padding-top: 26px;
        padding-left: 74px;
        width: 280px;
      }

      .total-header-cell {
        width: 80px;
        padding: 26px 16px 0;
        font-weight: 500;
        cursor: default;
      }
    }

    .table-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      opacity: ${ ({ $isLoading }) => $isLoading ? '0.6' : '1' };

      > div {
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        flex-direction: row;
        font-size: 16px;

        .product-info-cell {
          font-size: 14px;
          padding-left: 8px;
          display: flex;
          flex-direction: row;
          width: 280px;
          cursor: pointer;

          .image-container {
            min-width: 50px;
            min-height: 50px;
            padding-top: 3px;
            padding-bottom: 3px;
            display: flex;
            justify-content: center;
          }

          .product-name-container {
            width: 220px;
            padding: 0 16px;
            justify-content: center;
            display: flex;
            flex-direction: column;

            span {
              line-height: 18px;
            }

            a {
              line-height: 18px;
              text-decoration: none;
              color: #007DFF;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .marketplace-url {
              width: min-content;
            }
          }
        }

        .total-cell {
          width: 80px;
          padding: 16px 16px 0;
          font-size: 14px;
        }

        .quantity-cell {
          width: 180px;
          padding-top: 8px;
          padding-left: 16px;
          gap: 8px;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            input {
              padding-left: 8px;
              font-size: 16px;
              font-family: inherit;
              width: 90px;
              height: 35px;
              border-radius: 4px;
              border: 1px solid #0000003B;
            }

            .hover-lock-icon {
              transition: opacity 0.1s ease-in-out;
              opacity: 0;
            }

            svg {
              cursor: pointer;
            }
          }

          .quantity-short-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90px;
            font-size: 12px;
            padding-bottom: 8px;
            line-height: 20px;
          }

          :hover {
            background-color: #FAFAFA;

            .hover-lock-icon {
              opacity: 0.12;
            }
          }
        }
      }
    }
  }
`

export const StyledWarehouseHeader = styled.div<{ $isSelected: boolean }>`
  width: 180px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: ${({ $isSelected }) => ($isSelected ? '#edf7fe' : '')};
  
  .status-text {
    font-size: 12px;
    color: #00000099;
  }
  .warehouse-name {
    font-weight: 500;
  }
  
  .box-type-coefficient {
    display: flex;
    flex-direction: column;
    color: #F44336;
    font-size: 12px;
    
    .box-type-name {
      display: flex;
      flex-direction: row;
      gap: 8px;

      span:first-child {
        width: 80px;
        text-overflow: clip;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    
    .coefficient-progress-bar {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      
      >div {
        .front-side {
          background-color: #F44336;
        }
      }
    }
  }
  
  .box-type-coefficient.free {
    color: #4CAF50;

    >div {
      .front-side {
        background-color: #4CAF50;
      }
    }
  }

  .box-type-coefficient.disabled {
    color: #00000061;

    >div {
      .front-side {
        background-color: #00000061;
      }
    }
  }
`

export const StyledMenu = styled.div<{ width?: number }>`
  height: 100px;
  width: max-content !important;
  padding: 0 40px;
  box-shadow: 0 1px 14px 0 #0000001F,0 5px 8px 0 #00000024, 0 3px 5px -1px #00000033;
  background-color: #FFFFFF;
  border-radius: 16px;

  position: fixed;
  left: ${({ width }) => `calc(50% - ${width}px)`};
  bottom: 16px;
  //transform: translate(-50%);
  
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  
  .clear-icon {
    color: #0000008A;
    cursor: pointer;
  }
  
  svg {
    cursor: pointer;
  }
`

export const StyledProductsPlug = styled.div`
  text-align: center;
  align-content: center;
  font-size: 17px;
  height: 250px;
  width: 100%;
`

export const HeaderSettings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  .table-transition-group {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-right: 24px;
    
    button {
      height: 32px;
    }
  }
`


export const StyledModalContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

export const SelectedContainer = styled.div<{ $selected?: boolean }>`
  background-color: ${({ $selected }) => ($selected ? '#edf7fe' : '')};
  
  :hover {
    background-color: ${({ $selected }) => ($selected ? '#edf7fe' : '')} !important;
  }
`