import React from 'react'

import { ReportsPage, Header } from './ui'

export const AdminPanelReports = () => (

  <>
    <Header />
    <ReportsPage/>
  </>
)
