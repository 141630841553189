import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { HOME } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'

import { Home } from './Home'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: HOME,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.dashboard,
    component: Home,
  }
] as Array<Route>
