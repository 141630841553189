import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'


const uploadOrderFormFile = async (queryParams: {
    file: File | null
    contragentId?: UniqueId
}) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')
  return api.post(
    `/pim/admin/contragents/${queryParams.contragentId}/orderform`,
    body
  )
}
export const useUploadOrderFormFile = (
  options?: UseMutationOptions<
        AxiosResponse,
        errorType,
        {
            file: File
            contragentId?: UniqueId
        }
        >
) => useMutation(uploadOrderFormFile, options)
