import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'


interface CheckOrderForm {
    contragentId: UniqueId,
}
const checkOrderFormFile = ({ contragentId } : CheckOrderForm) =>
  api.patch(
    `/pim/admin/contragents/${contragentId}/orderform`
  )


export const useCheckOrderFormFileMutation = () =>
  useMutation<
        AxiosResponse,
        AxiosError<errorType>,
        CheckOrderForm
        >(checkOrderFormFile)