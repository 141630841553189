import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'
import { customerApi } from 'shared/api'

interface CompanyContextProviderProps {
    companyQuery: QueryObserverResult<customerApi.Company>,
}


const CompanyContext = React.createContext<CompanyContextProviderProps | undefined>(undefined)

function CompanyContextProvider({
  children,
  companyQuery,
}: PropsWithChildren<CompanyContextProviderProps>) {

  return (
    <CompanyContext.Provider
      value={{
        companyQuery,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

function useCompanyContext() {
  const context = React.useContext(CompanyContext)

  if (context === undefined) {
    throw new Error('useSupplierContext must be used within a SupplierContextProvider')
  }
  return context
}

export { CompanyContextProvider, useCompanyContext }