import { memo } from 'react'
import { Grid } from '@mui/material'
import { Image } from 'shared/ui/components'
import { VariationInfo } from 'domains/pim/goods'
import { MemoIcon } from 'entities/pim/goods/ui'
import defaultGoodImage from 'assets/images/defaultGoodImage.svg'
import { VariationAndMarketPlace } from 'widgets/VariationAndMarketplace'
import { pimApi } from 'shared/api'
import { noop } from 'shared/lib/lodash/noop'
import { ArchiveBadge } from 'widgets/ArchiveBadge'

import {
  ProductTitle,
  ProductVatCode,
  ProductHeaderImg,
  ProductHeaderImgPlaceholder,
  GoodsIcon
} from './common'


interface OnChangePayload {
  goodsId: UniqueId,
  companyId: UniqueId | null,
  marketplaceId: UniqueId | null
}

interface GoodsHeaderProps {
  title?: string | null
  name?: string
  imgId?: number
  code?: string
  auxCode?: string
  externalId?: string
  goodsId: UniqueId
  initialMarketplaceValues?: {
    companyId: UniqueId | null,
    marketplaceId: UniqueId | null
    companymarketplaceId: UniqueId | null
  },
  variations?: VariationInfo
  goodsType: pimApi.goods.GoodsType
  isArchived?: boolean
  archivationComments?: string
  archivationReason?: string
  onChangeVariation?: (
    payload: OnChangePayload
  ) => void
}

const replaceLastSymbol = (str: string | undefined) =>
  str ? str.replace(/\/$/, '') : ''

const fileThumbnailSize = { maxWidth: 100, maxHeight: 100 }

export const GoodsHeader = ({
  name,
  title,
  imgId,
  code,
  auxCode,
  externalId,
  goodsId,
  goodsType,
  isArchived,
  variations,
  archivationComments,
  onChangeVariation = noop,
  initialMarketplaceValues,
  archivationReason,
}: GoodsHeaderProps) => (
  <>
    <VariationAndMarketPlace
      variations={variations}
      goodsId={goodsId}
      initialMarketplaceValues={initialMarketplaceValues}
      onChange={onChangeVariation}
      withMarketplaces={false}
    />
    <Grid container={true} justifyContent="space-between">
      <Grid item={true} xs={10}>
        <ProductTitle>{title || name}
          {isArchived &&
            <ArchiveBadge reason={archivationReason} comments={archivationComments} />
          }
        </ProductTitle>
        <ProductVatCode>
          <GoodsIcon>
            <MemoIcon goodsType={goodsType} />
          </GoodsIcon>
          Артикул: {replaceLastSymbol(code)}{' '}
          {auxCode ? `| Доп. артикул: ${auxCode}` : ''}
          {externalId ? `| Внешний идентификатор: ${externalId}` : ''}
        </ProductVatCode>
      </Grid>
      <Grid item={true} justifyContent="flex-end">
        <ProductHeaderImg $isArchived={isArchived}>
          {imgId ? (
            <Image src={pimApi.goods.getGoodsThumbnailApiPath(
              goodsId,
              fileThumbnailSize
            )} />
          ) : (
            <ProductHeaderImgPlaceholder
              src={defaultGoodImage}
              alt="placeholder"
            />
          )}
        </ProductHeaderImg>
      </Grid>
    </Grid>
  </>
)

export const MemoGoodsHeader = memo(GoodsHeader)
