import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'

import { api } from '../../base'


const deleteAttribute = (value: pimApi.DeletingObject) =>
  api.delete(
    `/pim/attributes/${value.id}`,
    { data: value }
  )


export const useDeleteAttributeMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    pimApi.DeletingObject
  >(deleteAttribute)