import { ReportParameterDataType } from '../../../lib/generated/customer/Api'
import { CheckboxField, InputField, SelectSearchMultipleField } from '../form'
import { FormDatePicker } from '../DatePicker'
import { ReportsSelectFieldWithAutoComplete } from '../form/reportsSelectField/ReportsSelectWithAutocomplete'
import { FileSelectField } from '../form/FileSelectField'

interface ParametersType {
  dataType: ReportParameterDataType;
  component: any;
}

export const ParametersArray: Array<ParametersType> = [
  {
    dataType: ReportParameterDataType.String,
    component: InputField,
  },
  {
    dataType: ReportParameterDataType.Number,
    component: InputField,
  },
  {
    dataType: ReportParameterDataType.Date,
    component: FormDatePicker,
  },
  {
    dataType: ReportParameterDataType.Object,
    component: ReportsSelectFieldWithAutoComplete,
  },
  {
    dataType: ReportParameterDataType.File,
    component: FileSelectField,
  },
  {
    dataType: ReportParameterDataType.NumberList,
    component: SelectSearchMultipleField
  },
  {
    dataType: ReportParameterDataType.Bool,
    component: CheckboxField
  },
]