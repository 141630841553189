import { Edit } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'

export const EditInModalIcon = styled(Edit)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 24px;
  margin-top: 2px;
`

export const EditTemplateCell = ({ row, onModalOpen }) => {
  if (row.globalPrivilege)
    return (
      <div/>
    )
  return (
    <span title="Редактировать">
      <EditInModalIcon
        className="openModal_action"
        onClick={(e) => {
          e.stopPropagation()
          onModalOpen?.(row)
        }}
      />
    </span>
  )
}