import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

const downloadSupplyOrderCommonForm = async (supplyOrderId?: UniqueId) =>
  api.get<File>(
    `/trade/orders/supplyorders/${supplyOrderId}/commonform`,
    {
      responseType: 'blob',
    }
  )

export const useDownloadSupplyOrderCommonForm = () =>
  useMutation(downloadSupplyOrderCommonForm)