import React from 'react'
import { pimApi } from 'shared/api'
import { Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import isEmpty from 'lodash/isEmpty'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { CheckboxField, InputField, InputLabel, SelectFieldWithAutoComplete } from 'shared/ui/components'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { GoodsAlertMessage } from 'features/goods/alertMessages'

import { validationSchema } from '../../lib/validationSchema'


interface AttributeModalProps {
  close: () => void;
  accept: (receiveData: pimApi.attributes.CommonAttribute | pimApi.attributes.CommonNewAttribute) => void
  initialValues?: pimApi.attributes.CommonAttribute
}

const CheckboxContainer = styled.div`
  margin-top: 24px;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px;

  .form-checkbox-container {
    display: flex;
    align-items: center;
    gap: 29px;

    svg {
      width: 18px;
      height: 18px;
    }

    label {
      gap: 12px;
      margin-left: 0;
      padding: 0;

      .MuiCheckbox-root {
        padding: 0;
      }

      .MuiFormControlLabel-label {
        color: ${ ({ theme }) => theme.palette.text.secondary };
      }
    }

    label:has(> .Mui-checked) {
      .MuiFormControlLabel-label {
        color: ${ ({ theme }) => theme.palette.text.primary };
      }
    }
  }
`

const ErrorMessageContainer = styled.div`
  .MuiPaper-root {
    height: auto;
    padding: 0 16px 0 16px;
    border-radius: 4px;
  }
`

export const AttributeModal = ({ accept, close, initialValues }: AttributeModalProps) => {

  const onSubmit = (data) => {
    if (initialValues) {
      accept(data)
    } else {
      accept(data)
    }
  }

  const validate = useValidationSchema(validationSchema)

  const categoriesOptions = [
    {
      label: 'Строка',
      value: 'string'
    },
    {
      label: 'Число',
      value: 'number'
    },    
    {
      label: 'Дата',
      value: 'date'
    },    
    {
      label: 'Логический',
      value: 'logical'
    },
    
    
  ]
  
  return (
    <Box sx={ { width: '640px' } }>
      <IconCloseButton aria-label="close" onClick={ close }>
        <img src={ CloseIcon } alt="close icon" />
      </IconCloseButton>
      <DialogTitle fontSize={ 20 } whiteSpace="pre-line" sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        { initialValues ? 'Изменить атрибут' : 'Добавить атрибут' }
        { initialValues && <span style={{ fontSize: '16px', fontWeight: '400', paddingBottom: '8px' }}>{ initialValues?.name }</span>}
      </DialogTitle>
      <Box px={ 3 } mt={ 1 }>
        <Form
          onSubmit={ onSubmit }
          validate={ validate }
          initialValues={ initialValues }
          render={ ({
            handleSubmit,
            errors,
            error, values: formValues
          }) => (
            <form onSubmit={ handleSubmit } noValidate={ true }>
              <Box>
                <InputLabel
                  label="Название атрибута"
                  required={ false }
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="name"
                      placeholder="Название атрибута"
                      component={ InputField }
                      fullWidth={ true }
                      errorAfterTouch={ true }
                      error={ error }
                    />
                  </InputWrapper>
                </StyledField>
              </Box>
              <Box mt={ 3 }>
                <InputLabel
                  label="Тип данных"
                  required={ false }
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="dataType"
                      options={ categoriesOptions }
                      placeholder="Тип данных"
                      component={ SelectFieldWithAutoComplete }
                      errorAfterTouch={ true }
                      fullWidth={ true }
                    />
                  </InputWrapper>
                </StyledField>
              </Box>
              <CheckboxContainer>
                <div className="form-checkbox-container">
                  <Tooltip placement="top-start" title="Вариативный атрибут позволяет объединить похожие товары">
                    <InfoOutlinedIcon sx={ { color: 'rgba(0, 0, 0, 0.38)' } } />
                  </Tooltip>
                  <Field
                    name="variationSupport"
                    label="Вариативный"
                    type="checkbox"
                    size="small"
                    component={ CheckboxField }
                  />
                </div>
                <div className="form-checkbox-container">
                  <Tooltip placement="top-start" title="Параметр влияет на количество значений внутри атрибута">
                    <InfoOutlinedIcon sx={ { color: 'rgba(0, 0, 0, 0.38)' } } />
                  </Tooltip>
                  <Field
                    name="multipleValues"
                    label="Множественный выбор"
                    type="checkbox"
                    size="small"
                    component={ CheckboxField }
                  />
                </div>
                { !initialValues &&
                  <>
                    <div className="form-checkbox-container">
                      <Tooltip placement="top-start" title="Параметр, определяющий, прикреплен ли к атрибуту словарь значений">
                        <InfoOutlinedIcon sx={ { color: 'rgba(0, 0, 0, 0.38)' } } />
                      </Tooltip>
                      <Field
                        name="useDictionary"
                        label="Словарь значений"
                        type="checkbox"
                        size="small"
                        component={ CheckboxField }
                      />
                    </div>
                    <div className="form-checkbox-container">
                      <Tooltip placement="top-start" title="Параметр позволяет использовать только те значения, которые внесены в словарь">
                        <InfoOutlinedIcon sx={ { color: 'rgba(0, 0, 0, 0.38)' } } />
                      </Tooltip>
                      <Field
                        name="fixedValues"
                        label="Использовать значения только из словаря"
                        type="checkbox"
                        size="small"
                        component={ CheckboxField }
                      />
                    </div>
                  </>
                }
                { (initialValues?.multipleValues && !formValues.multipleValues) &&
                  <ErrorMessageContainer>
                    <GoodsAlertMessage
                      message="При отключении параметра Множественный выбор заполненные
                   значениями атрибуты товаров будут содержать только одно значение " />
                  </ErrorMessageContainer>
                }
              </CheckboxContainer>

              <DialogActions sx={ {
                marginTop: '24px',
                padding: 0,
                marginBottom: '24px'
              } }>

                <Box>
                  <Button
                    onClick={ close }
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={ false }
                    disabled={ !isEmpty(errors) || (JSON.stringify(initialValues) === JSON.stringify(formValues)) }
                    sx={ { marginLeft: 2 } }
                  >
                    { initialValues ? 'Изменить' : 'Добавить' }
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )
          }
        />
      </Box>
    </Box>
  )
}