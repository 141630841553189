import styled from 'styled-components'

export const StyledMediaGalleryModal = styled.div`
  max-width: 840px;
  max-height: 704px;
  min-width: 840px;
  min-height: 704px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .selectedMediaContainer {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    .preloaderContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      max-width: 520px;
      min-width: 390px;
      border-radius: 4px;
      height: 480px;
      .selectedImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 4px;
      }
      .mediaPreloader {
        width: inherit;
        height: inherit;
      }
    }
    
    .selectedMediaInfo {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      .selectedMediaOptions {
        display: flex;
        flex-direction: row;
        gap: 8px;

        >div {
          width: 56px;
          height: 56px;
        }

        img {
          width: 56px;
          height: 56px;
          border-radius: 4px;
          border: 1px solid #00000017;
          object-fit: cover;
        }
        
        .fileName {
          font-size: 16px;
          line-height: 24px;
          color: #000000DE;
        }
      }
      
      .selectedMediaFetchedInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #000000DE;
          margin-bottom: 8px;
        }
        
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          
          .paramTitle {
            font-size: 14px;
            line-height: 20px;
            color: #00000099;
          }
          .paramValue {
            font-size: 12px;
            line-height: 20px;
            color: #000000DE;
          }
        }
      }
    }
  }
  .mediaTabs {
    margin-top: auto;
    margin-bottom: 0;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    transition: 0.1s;
    height: 104px;
    width: 104px;
    padding: 8px 8px;

    img {
      border: 1px solid #00000017;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    :hover {
      padding: 4px 4px;
    }
  }
  .Mui-selected {
    padding: 0;
    
    :hover {
      padding: 0;
    }
  }
`