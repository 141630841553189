import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useParams } from 'react-router-dom'

import { getMarketplaceForm } from '../lib/getMarketplaceForm'

export const MarketplaceForm = () => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  
  const companyId = useField('companyId').input.value
  const marketplaceId = useField('marketplaceId').input.value
  const companymarketplaceId = useField('companymarketplaceId').input.value

  const MarketplaceFormByCompanyId = useMemo(
    () =>
      (companyId && marketplaceId && companymarketplaceId && getMarketplaceForm(marketplaceId)) ||
      null,
    [companyId, marketplaceId, companymarketplaceId]
  )

  if(!MarketplaceFormByCompanyId) return null
  
  return (
    <MarketplaceFormByCompanyId 
      goodsId={goodsId}
      companymarketplaceId={companymarketplaceId!}
      marketplaceId={marketplaceId!}
    />
  )
}