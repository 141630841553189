import { Typography, Box, Paper } from '@mui/material'
import { CreateUser } from 'features/admin/User/CreateUser/CreateUser'

export const CreateUserPage = () => (
  <Box my={3}>
    <Typography variant="h5" fontSize="24px" fontWeight="400">
      Создание нового пользователя
    </Typography>
    <Box mt={3}>
      <Paper>
        <CreateUser />
      </Paper>
    </Box>
  </Box>
)