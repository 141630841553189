import { Box } from '@mui/material'
import { ModalContent } from 'shared/ui/components/ModalComponents'

interface ConfoirmModalProps {
  close: () => void
  accept: () => void
}

export const ConfirmModal = ({ close, accept }: ConfoirmModalProps) => (
  <Box width="400px">
    <ModalContent
      close={close}
      accept={accept}
      title="Хотите завершить приемку?"
      acceptBtnText="ЗАВЕРШИТЬ ПРИЕМКУ"
    />
  </Box>
)
