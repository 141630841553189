// @ts-nocheck
import { Attribute } from 'shared/api/pim/goods'
import { getGoodsAttributeComponent } from 'entities/pim/GoodsAttributes'
import { isNotNil } from 'shared/lib/checkers'

import { getLabelPreparedLeft } from '../../lib'

export const LeftComponent = ({
  attribute
}: {
  attribute: Attribute
}) => {
  const leftComponent = getGoodsAttributeComponent({
    ...attribute,
    catalog: isNotNil(attribute.dictionaryId) ? `pim/dictionaries/${attribute.dictionaryId}` : undefined,
  })

  const { Component } = leftComponent

  const label = getLabelPreparedLeft(attribute)

  return (<Component
    input={{
      ...leftComponent.props,
      value: attribute.value
    }}
    meta={{
      invalid: false
    }}
    label={label}
    disabled={true}
  />
  )
}