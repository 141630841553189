import React, { useCallback } from 'react'
import { Box, Button, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { reviewApi } from 'shared/api'
import { reviewGen } from 'shared/lib/generated'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { Field, InputField, InputLabel } from 'shared/ui/components'
import { Option } from 'shared/ui/components/interface'
import { Form } from 'react-final-form'
import { AutocompleteSearchField } from 'shared/ui/components/form/AutocompleteSearchField'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import { isSameValues } from 'shared/lib/checkers'

import { validationSchema } from './validationSchema'


export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 480px;
  }
`

interface PrivilegeAddModalProps {
  close: () => void;
  accept: (e) => void
  changedCell?: reviewApi.ReviewTemplate
}

const getOptionFromAttribute= ({ name, id }: { name: string, id: number }) => ({
  label: name,
  value: id
})

export const EditTemplateModal = ({
  close,
  accept,
  changedCell,
}: PrivilegeAddModalProps) => {

  const fetchCatalog = useCallback<(e: string) => Promise<Array<Option<any>>>>(
    (search: string) => reviewGen.template.GetReviewTemplateModels.apiFunction({ query: { searchstring: encodeURIComponent(search) } })
      .then(
        data => data.map(
          getOptionFromAttribute
        )
      ),
  []
  )

  const initialValues = {
    ...changedCell,
    templatemodelId: { label: changedCell?.templatemodelName, value: changedCell?.templatemodelId }
  }

  const submitForm = (values) => {
    accept(values)
    close()
  }

  const validation = useYupValidation(validationSchema)

  return (
    <Form
      onSubmit={submitForm}
      validate={validation}
      initialValues={initialValues}
      render={({
        handleSubmit,
        valid,
        values
      }) =>
        (
          <Box width="480px">
            <IconCloseButton aria-label="close" onClick={close}>
              <img src={CloseIcon} alt="close icon" />
            </IconCloseButton>
            <Box fontSize={20} m={3} p={0} fontWeight={500}>
              {changedCell
                ? changedCell.name
                : 'Новый шаблон'
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: '0 24px 24px' }}>
              <Box>
                <InputLabel label="Наименование" required={true}/>
                <Field
                  component={InputField}
                  fullWidth={true}
                  label="Наименование"
                  name="name"
                  isError={false}
                />
              </Box>
              <Box>
                <InputLabel label="Модель" required={true}/>
                <Field
                  name="templatemodelId"
                  placeholder="Модель"
                  errorAfterTouch={ true }
                  component={ AutocompleteSearchField }
                  fetchCatalog={fetchCatalog}
                  disableClearable={ false }
                  fullWidth={ true }
                />
              </Box>
              <Box>
                <InputLabel label="Текст" required={false}/>
                <Field
                  component={InputField}
                  fullWidth={true}
                  isError={false}
                  label="Описание"
                  name="text"
                />
                <Box mt="4px" textAlign="right">
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      lineHeight: '20px',
                    }}>
                    Символов: {values.text?.length || 0}/512
                  </span>
                </Box>
              </Box>

              <DialogActions sx={{ padding: 0 }}>

                <Box>
                  <Button
                    onClick={close}
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    loading={false}
                    disabled={!valid || isSameValues(values, initialValues)}
                    onClick={handleSubmit}
                    sx={{
                      marginLeft: 2,
                    }}
                  >
                    {changedCell
                      ? 'ИЗМЕНИТЬ'
                      : 'ДОБАВИТЬ'
                    }
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Box>
          </Box>
        )}
    />
  )
}



