import React from 'react'
import { useField } from 'react-final-form'
import { useParams } from 'react-router'
import { useQueryClient } from 'react-query'
import { Grid, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { pimApi } from 'shared/api'
import { Field } from 'shared/ui/components/form'
import { InputField } from 'pages/goods/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { DeleteConfirmModal } from 'entities/pim/goodsComponents'
import { reactQueryCacheAdd, reactQueryCacheUpdateByKey, reactQueryCacheDelete } from 'shared/lib/reactQuery'

interface CreateContentPayload {
  goodsId: UniqueId
  content: pimApi.goods.NewGoodsComponent
}

export const CompositionComponent = ({
  index,
  deletable,
  isShowPercent,
  createContent,
  updateContent,
  deleteContent,
  disabled,
}: {
  index: number
  deletable: boolean
  isShowPercent: boolean
  disabled: boolean
  createContent: ({ goodsId, content }: CreateContentPayload, callback) => void
  updateContent: ({ goodsId, contentId, content }, callback) => void
  deleteContent: ({ goodsId, contentId, content }, callback) => void
}) => {
  const componentFieldName = `content.${index}.component`
  const percentsFieldName = `content.${index}.percents`
  const componentField = useField(componentFieldName)
  const percentsField = useField(percentsFieldName)
  const {
    input: { value: content },
  } = useField(`content.${index}`)

  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)

  const queryClient = useQueryClient()

  const isDeletable = deletable && content.id !== 0

  const handleChange = (value?) => {
    if (content?.id === 0) {
      createContent(
        { goodsId, content: { component: value || content.component, percents: content.percents } },
        {
          onSuccess: reactQueryCacheAdd({
            queryClient,
            queryCacheKey: pimApi.goods.getGoodsContentQueryKey(goodsId),
          }),
        }
      )
    } else {
      updateContent(
        { goodsId, contentId: content.id, content: { ...content, component: value || content.component } },
        {
          onSuccess: reactQueryCacheUpdateByKey({
            queryClient,
            queryCacheKey: pimApi.goods.getGoodsContentQueryKey(goodsId),
          }),
        }
      )
    }
  }

  const handleRemove = () => {
    deleteContent(
      {
        goodsId,
        contentId: content.id,
        content,
      },
      {
        onSuccess: reactQueryCacheDelete({
          queryClient,
          queryCacheKey: pimApi.goods.getGoodsContentQueryKey(goodsId),
          deleteItem: content,
        }),
      }
    )
  }

  const handleComponentBlur = () => {
    const {
      meta: { valid },
    } = componentField

    if (valid) handleChange()
  }

  const handlePercentsBlur = () => {
    const {
      meta: { valid },
    } = percentsField

    if (valid) handleChange()
  }

  const handleRemoveDialog = () => {
    dialogModel.openDialog(
      {
        component: DeleteConfirmModal,
        onAccept: handleRemove,
      }
    )
  }

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={10} style={{ flexGrow: 1 }}>
          <Field name={componentFieldName} component={InputField} onBlur={handleComponentBlur} disabled={disabled} />
        </Grid>
        {isShowPercent && (
          <Grid item={true} xs={1}>
            <Field
              name={percentsFieldName}
              component={InputField}
              label="%"
              type="number"
              onBlur={handlePercentsBlur}
              disabled={disabled}
            />
          </Grid>
        )}
        {isDeletable && (
          <Grid item={true} xs={1}>
            <IconButton aria-label="delete" onClick={handleRemoveDialog} size="large">
              <Delete />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  )
}
