import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'
import { ListSubheader, Popper, TextField } from '@mui/material'

export const AutocompleteStyled = styled(Autocomplete)`
  width: 100%;
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #007dff;
  }
  .Mui-focused.MuiOutlinedInput-root {
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
  }
  &.Mui-focused + button {
    right: calc(100% - 50px);
  }
` as typeof Autocomplete

export const Item = styled.li`
  display: flex;
  width: 100%;
`
export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`
export const SearchButton = styled.button<{ $isActive?: boolean, disabled: boolean }>`
  background: transparent;
  border: none;
  position: absolute;
  transition: 0.3s;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  right: ${({ $isActive }) => ($isActive ? 'calc(100% - 50px)' : '9px')};
  color: ${({ disabled }) => (disabled ? 'unherit' : '#007DFF') };
`
export const ItemSpan = styled.span<{ $isActive?: boolean }>`
  display: inline-block;
  font-size: 12px;
  color: ${(props) => (props.$isActive ? '#000' : 'rgba(0, 0, 0, 0.54)')};
`
export const ItemText = styled(ItemSpan)<{ $isActive?: boolean }>`
  margin-left: 10px;
  padding-right: 100px;
  > div {
    padding-left: 12px;
  }
`
export const PopperStyled = styled(Popper)<any>`
  .MuiPaper-elevation1 {
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    margin-top: 0;
  }
  .MuiAutocomplete-listbox {
    > ul {
      margin: 0;
    }
    padding: 0;
    li[aria-selected='true'] {
      position: absolute;
      width: 100%;
      top: 0;
      padding-top: 10px;
      background: #fff;
      padding-right: 120px;
    }
  }
`
export const ActiveRemove = styled.div`
  position: absolute;
  right: 15px;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  z-index: 10;
  > svg {
    margin-left: 5px;
  }
`
export const TextFieldStyled = styled(TextField)`
  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    transition: 0.3s;
    padding-left: 6px;
  }
  .Mui-focused .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    padding-left: 44px;
  }
  .Mui-focused {
    .searchBtn {
      right: calc(100% - 50px) !important;
    }
  }
`
export const ListSubheaderStyled = styled(ListSubheader)<any>`
  padding: 5px 0;
`
