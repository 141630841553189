import { pimApi } from 'shared/api'
import { pimGen } from 'shared/lib/generated'
import { CircularProgressStyled } from 'shared/ui/components/CircularProgress/CircularProgress'

import { PriceSettingsForm } from './PriceSettingsForm'

export const PriceSettings = ({ commonProduct }: { commonProduct: pimApi.products.common.Product }) => {
  const { data: productSettingsData } = pimGen.priceWizard.GetPriceSettings.useGetPriceSettings({
    query: {
      productId: commonProduct.productId
    }
  })

  const { data: algorithmsData } = pimGen.priceWizard.GetAlgorithms.useGetAlgorithms()

  return (
    <>
      { productSettingsData && algorithmsData ?
        <PriceSettingsForm
          productId={commonProduct.productId}
          productPriceSettings={productSettingsData?.[0]}
          algorithmsData={algorithmsData}
        />
        :
        <CircularProgressStyled size={60} />
      }
    </>
  )
}