import styled from 'styled-components'

export const ContentRatingContainer = styled.div`
  width: 90px;
  height: 86px;
  padding-left: 16px;
  padding-right: 8px;
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  border: 1px solid #E0E0E0;
  .title-span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #00000099;
  }
  
  .rating-data-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .rating-text {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }
  }
  
  svg {
    height: 18px;
    width: 18px;
  }
`

export const ContentRatingTooltip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  
  .tooltip-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  
  .rating-item {
    >span {
      font-size: 14px;
      font-weight: 500;
    }
    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 19px;

      li {
        font-size: 12px;
        font-weight: 400;
        color: #00000099;
      }
    }
  }
  
`