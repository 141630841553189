import { useEffect, useState } from 'react'
import {
  PagingState,
  CustomPaging,
  IntegratedPaging,
} from '@devexpress/dx-react-grid'
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { Plugin } from '@devexpress/dx-react-core'

import { pagingPanelLocale, getRowsPerPageOptions } from '../../lib'
import { TableParams } from '../../model/types'

interface TablePaginationProps {
  totalCount?: number
  calculatedRowsPerPage?: number
  tableParams: TableParams
  handleTableParams: (params: Partial<TableParams>) => void
  tableListLength: number
  pagination: 'internal' | 'external'
  allRows?: boolean
  paginationLocale?: any
}

export const TablePagination = ({
  calculatedRowsPerPage = 5,
  tableParams,
  tableListLength,
  totalCount,
  handleTableParams,
  pagination,
  allRows,
  paginationLocale,
}: TablePaginationProps) => {
  const pageSizes = getRowsPerPageOptions(totalCount || tableListLength, calculatedRowsPerPage, tableParams.limit)
  const { page, limit = 5 } = tableParams
  const checkLimit = () => {
    if (limit === 0 || limit) {
      return limit
    } return calculatedRowsPerPage
  }
  const [currentPage, setCurrentPage] = useState(page)
  const [pageSize, setPageSize] = useState(checkLimit())
  const externalPagination = pagination && pagination === 'external'

  const onPageSizeChange = (currentPageSize: number) => {
    if (externalPagination)
      handleTableParams({ limit: currentPageSize, page: 0 })
    setPageSize(currentPageSize)
    handleTableParams( { limit: currentPageSize } )
  }

  const onCurrentPageChange = (currentPageNumber: number) => {
    if (externalPagination) handleTableParams({ page: currentPageNumber })
    setCurrentPage(currentPageNumber)
    handleTableParams( { page: currentPageNumber } )
  }

  const showPagingPanel =
    pagination === 'internal'
      ? Boolean(calculatedRowsPerPage &&
        tableListLength &&
        calculatedRowsPerPage < tableListLength)
      : true

  if(pagination === 'internal' && allRows) {
    pageSizes.push(0)
  }

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page)
    }
  }, [page])

  useEffect(() => {
    if (pageSize !== limit) {
      setPageSize(limit)
    }
  },[limit])

  return (
    <Plugin>
      <PagingState
        pageSize={pageSize}
        currentPage={currentPage}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      {pagination === 'internal' && <IntegratedPaging />}
      {pagination === 'external' && <CustomPaging totalCount={totalCount || tableListLength} />}
      {showPagingPanel && (
        <PagingPanel pageSizes={pageSizes} messages={paginationLocale || pagingPanelLocale} />
      )}
    </Plugin>
  )
}
