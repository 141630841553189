import { FC, memo, useCallback, useMemo } from 'react'
import { Option } from 'shared/ui/components/interface'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { AutocompleteValue } from '@mui/material/useAutocomplete'
import { pimApi } from 'shared/api'
import { isNil } from 'shared/lib/checkers'

import { ProductAttributeValue } from '../../lib/getNormalizedAttributes'

const EMPTY_VALUE = undefined
type EmptyValue = typeof EMPTY_VALUE

export interface AutocompleteDictionaryAttributeProps extends FieldRenderProps<Array<ProductAttributeValue>> {
  fetchCatalog: (steach: string) => Promise<Array<Option<ProductAttributeValue>>>,
  maxCount?: number
}

const getOptionFromDictionary = ({
  value,
  dictionaryValueId,
  obsolete
}: pimApi.products.OzonProductAttributeValue): Option<ProductAttributeValue> => ({
  label: value!,
  value: {
    value: value!,
    dictionaryValueId,
    obsolete,
  }
})

const getDictionaryFromOption = ({ value }: Option<ProductAttributeValue>) => value

function getCorrectValue<R extends boolean>(
  multiple: R,
  value: Array<ProductAttributeValue>
): Option<ProductAttributeValue> | Array<Option<ProductAttributeValue>> | EmptyValue {
  if (multiple) {
    return value.map(getOptionFromDictionary)
  }

  return isNil(value[0]) ? EMPTY_VALUE : getOptionFromDictionary(value[0])
}


export const AutocompleteDictionaryAttributeField: FC<AutocompleteDictionaryAttributeProps> = ({
  fetchCatalog,
  maxCount,
  disabled,
  ...props
}) => {
  
  const handleFetch = useCallback((steach: string) => fetchCatalog(steach), [])

  const handleChange = (value: AutocompleteValue<Option<ProductAttributeValue>, boolean, undefined, undefined>) => {
    if (value === null) {
      (props.input.onChange as (value: Array<ProductAttributeValue>) => void) (
        []
      )

      return
    }
    
    const values: Array<Option<ProductAttributeValue>> = Array.isArray(value) ? value : [value];

    (props.input.onChange as (value: Array<ProductAttributeValue>) => void) (
      values.map(getDictionaryFromOption)
    )
  }


  const value = getCorrectValue(props.input.multiple!, props.input.value)
  const input: FieldInputProps<
  Array<Option<ProductAttributeValue>> | Option<ProductAttributeValue> | EmptyValue
  > = useMemo(() => ({
    ...props.input,
    value
  }), [props.input])

  const multipleMaxCountDisabled: boolean =
    typeof maxCount === 'number' && maxCount > 0 &&
    props.input.multiple! &&
    (value as Array<any>).length >= maxCount

  return (
    <AutocompleteCatalogSearchField<ProductAttributeValue, boolean>
      {...props as FieldRenderProps<any>}
      // @ts-ignore
      input={input}
      // @ts-ignore
      onChange={handleChange}
      fetchCatalog={handleFetch}
      multiple={props.input.multiple}
      disableClearable={false}
      disabled={disabled}
      maxCount={maxCount}
      prefetch={true}
      dragChips={true}
      allowEmptyString={true}
      getOptionDisabled={(optionItem: Option) => (
        multipleMaxCountDisabled &&
        // @ts-ignore
        !props.input.value.find(el => el.value === optionItem.label)
      )
      }
      isOptionEqualToValue={
        (option: Option<ProductAttributeValue>, selectedValue: EmptyValue | Option<ProductAttributeValue>) =>
          selectedValue && option.value.value === selectedValue.value.value
      }
    />
  )
}

export const AutocompleteDictionaryAttributeFieldMemo = memo(AutocompleteDictionaryAttributeField)