import { memo } from 'react'
import { Option } from 'shared/ui/components/interface'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  Popper,
  PopperProps,
  TextField,
} from '@mui/material'

const checkToEqualValue = (option, value) => option.value === value.value

const styles = {
  popper: {
    maxWidth: 'fit-content',
  },
}

const PopperMy = memo((props: PopperProps) => (
  <Popper {...props} style={styles.popper} placement="bottom-start" />
))

interface AutocompleteSelectProps {
  onChange: (value: Option<string | number> | null) => void
  options: any
  isLoading?: boolean
  label?: string
  id?: string
  fullWidth?: boolean
  value?: Option<string | number>
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  popupIcon?: React.ReactNode
}

const AutocompleteSelect = ({
  onChange,
  options,
  isLoading,
  label,
  id,
  value,
  fullWidth,
  variant,
  popupIcon,
}: AutocompleteSelectProps) => {
  const handleChange = (_, val) => {
    onChange(val)
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      placeholder="Введите текст"
      variant={variant}
      InputLabelProps={{
        shrink: value ? true : undefined,
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )

  return (
    <Autocomplete
      id={id}
      options={options}
      fullWidth={fullWidth}
      PopperComponent={PopperMy}
      popupIcon={popupIcon}
      size="small"
      noOptionsText="Не найдено"
      clearOnBlur={false}
      blurOnSelect={true}
      autoHighlight={true}
      value={value || null}
      onChange={handleChange}
      isOptionEqualToValue={checkToEqualValue}
      renderInput={renderInput}
    />
  )
}

export const MemoAutocompleteSelect = memo(AutocompleteSelect)
