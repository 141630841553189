import { Checkbox } from '@mui/material'

interface IRowSelectCell {
  row: any
  rowSelection?: {
    selectedCondition: (e) => boolean
    onRowSelect: (id: UniqueId) => void
  }
}

export const RowSelectCell = ({ row, rowSelection }: IRowSelectCell) => {
  const selected = rowSelection?.selectedCondition(row)

  return (
    <Checkbox
      className="selected-checkbox"
      checked={selected}
      size="small"
      onChange={() => rowSelection?.onRowSelect(row)}
      sx={{ padding: 0 }}
    />
  )
}