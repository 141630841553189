import React from 'react'

import { ImportStream } from './events/ImportStream'
import { useOpenedStreamsContext } from './context/OpenedStreamsContext'

export const StreamEvents = () => {
  const { value: context } = useOpenedStreamsContext()
  return (
    <>
      { context.openedStreams.goodsSupplyingStream &&
        <ImportStream/>
      }
    </>
  )
}