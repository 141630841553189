import styled from 'styled-components'

export const ProgressBar = styled.div<{ $progress: number }>`
  width: 100%;
  height: 4px;
  background-color: rgba(0,125,255,0.2);
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ $progress }) => $progress}%;
    background-color: rgb(0,125,255);
    transition: width 0.4s ease;  
    border-radius: inherit;
  }
`