import React from 'react'
import styled from 'styled-components'

const FrontSide = styled.div<{ coefficient: number }>`
  width: 80px;
  position: relative;

  div {
    border-radius: 4px;
    height: 2px;
  }

  .back-side {
    background-color: #E0E0E0;
    width: 100%
  }

  .front-side {
    background-color: inherit;
    width: ${ ({ coefficient }) => coefficient > 200 ? '100%' : `${ coefficient / 2 }%` };
    position: absolute;
    bottom: 0;
  }
`

export const CoefficientBar = ({ coefficient }: { coefficient: number }) => (
  <FrontSide coefficient={coefficient}>
    <div className="front-side" />
    <div className="back-side" />
  </FrontSide>
)