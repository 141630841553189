
export const changeColumnConfig = (params, inTabsSettingHandler, position) => {
  const newSettings = {
    tabLabel: position,
    tabSettings: params,
    paginationRow: params.limit,
    searchString: params.searchString
  }
  if (inTabsSettingHandler) {
    inTabsSettingHandler( newSettings )
  }
}

export const tableParams = (tabsSettingObject, position, columnsSettingsId?: string) => {
  const newSetting = tabsSettingObject?.inTabsObjectInit.filter(e => e.label === position)[0]
  return (
    {
      sort: newSetting?.sort || [],
      limit: tabsSettingObject?.rowInPagination as number,
      page: newSetting?.page || 0,
      searchString: newSetting?.searchString || '',
      columnsSettingsId
    })
}