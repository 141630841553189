import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/material'


const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  min-width: 1142px;
  margin: auto;
`

interface LayoutProps {
  width: string
}

export const ShowcaseLayout = ({ children, width }: PropsWithChildren<LayoutProps>) => (
  <Wrapper>
    <Box>
      <Content width={width}>{children}</Content>
    </Box>
  </Wrapper>
)
