import { useMutation } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosError, AxiosResponse } from 'axios'

interface PostCompanyLogo {
  companyId: UniqueId
  file: FormData | null
}

export const postCompanyLogo = ({ companyId, file }: PostCompanyLogo) =>
  api.post(
    `/customer/companies/${companyId}/logo`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const usePostCompanyLogoMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    PostCompanyLogo
  >(postCompanyLogo)