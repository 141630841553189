import { Theme } from '@mui/material'
import styled from 'styled-components'

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`


export const Text = styled.span`
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.54);
`

export const VatRateWrapper = styled.div`
  .MuiFormGroup-root{
    flex-direction: row;
  }
  .MuiFormControl-root{
    flex-direction: row;
  }
`
export const TextError = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.palette.error.main};
`

export const TabsNav = styled.div`
  border-bottom: 1px solid rgba(0, 125, 255, 0.5);
  .MuiTabs-indicator {
    display: none !important;
  }
  .MuiTab-root {
    font-size: 15px;
    color: #007dff;
    white-space: nowrap;
    border: 1px solid rgba(0, 125, 255, 0.5);
    border-bottom: 0;
    border-left: 0;
    &:first-child {
      border-top-left-radius: 4px;
      border-left: 1px solid rgba(0, 125, 255, 0.5);
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }
  .Mui-selected {
    background: #ebf5ff;
  }
  .Mui-selected.Mui-disabled{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF;
    border-color: #C4C4C4;
  }
  .Mui-disabled{
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.26);
    border-color: #C4C4C4;
  }
`
