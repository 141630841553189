import { ModalFormMarketplaceOzon } from '../ui/ModalFormMarketplaceOzon'
import { ModalFormMarketplaceWB } from '../ui/ModalFormMarketplaceWB'

export enum MarketplaceId {
  WB = 1,
  OZON = 2,
}

export const MarketplaceIdToModalFormMarketplace = {
  [MarketplaceId.WB]: ModalFormMarketplaceWB,
  [MarketplaceId.OZON]: ModalFormMarketplaceOzon,
} as const
