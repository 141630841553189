import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'
import { pimApi } from 'shared/api'

interface SupplierContextProviderProps {
    supplierQuery: QueryObserverResult<pimApi.admin.CurrentContragent>,
}


const SupplierContext = React.createContext<SupplierContextProviderProps | undefined>(undefined)

function SupplierContextProvider({
  children,
  supplierQuery,
}: PropsWithChildren<SupplierContextProviderProps>) {

  return (
    <SupplierContext.Provider
      value={{
        supplierQuery,
      }}
    >
      {children}
    </SupplierContext.Provider>
  )
}

function useSupplierContext() {
  const context = React.useContext(SupplierContext)

  if (context === undefined) {
    throw new Error('useSupplierContext must be used within a SupplierContextProvider')
  }
  return context
}

export { SupplierContextProvider, useSupplierContext }