import styled from 'styled-components'
import { Tab, Grid, Theme } from '@mui/material'

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: 5px;
  font-size: 14px;
`

export const ParamsTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  span{
    display: inline-flex;
    align-items: center;
    padding-right: 5px;
    background: #fff;
    position: relative;
    z-index: 2;
  }
  &:after{
    content: "";
    border-top: 1px dashed rgba(0, 0, 0, 0.23);
    width: 100%;
    position: absolute;
    z-index: 0;
  }
`
export const NoMarginGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`

export const NoPaddingGrid = styled(Grid)`
  padding: 0 !important;
`

export const DashedAction = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  color: #007DFF;
  border-bottom: 1px dashed #007DFF;
  cursor: pointer;
`

export const SizeTabsNav = styled.div<{ $isArchived?: boolean }>`
  border-bottom: ${({ $isArchived }) => $isArchived ? '1px solid #E0E0E0' : '1px solid #80BEFF' };
  height: 42px;
  .MuiTabs-indicator {
    display: none !important;
  }
  .MuiTab-root {
    font-size: 15px;
    color: ${({ theme, $isArchived }) => $isArchived
    ? theme.palette.warning.main
    : '#007dff'
};
    white-space: nowrap;
    border: none;

    div {
      display: none;
    }
  }
}

.Mui-selected {
  background: ${({ theme, $isArchived }) => $isArchived
    ? theme.palette.warning.lightBg
    : '#ebf5ff' };
  display: flex;
  padding: 0 0 0 16px;
  gap: 8px;
  justify-content: space-evenly;
  div {
    display: flex;

    button {
      padding: 0;
      margin-right: 8px;
    }
  }
}
.Mui-selected.Mui-disabled{
  background: ${({ theme, $isArchived }) => $isArchived
    ? theme.palette.warning.lightBg
    : '#C4C4C4' };
}
.Mui-disabled{
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.26);
}
}`

export const SizeTab = styled(Tab)<{ $isArchived?: boolean }>`

`

export const GridWithArrow = styled(Grid)`
  position: relative;
`

export const GridColors = styled(Grid)`
  padding-left: 8px!important;
`
export const InputArrow = styled.div`
  position: absolute;
  right: -15px;
  top: 50%;
  svg {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
  }
`
export const SpanStarStyled = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
`

export const SideBlock = styled.div<{ $isSeparate?: boolean }>`
  .MuiTypography-root {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 25px;
  }
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-right: 15px;
  width: ${({ $isSeparate }) => $isSeparate ? '277px' : '263px' } ;
  min-width: ${({ $isSeparate }) => $isSeparate ? '277px' : '263px' } ;
  border-right: ${({ $isSeparate }) => $isSeparate ? '1px solid #E0E0E0' : 'none' };
`

export const BarcodeBlock = styled.div<{ $isSeparate?: boolean }>`
  padding-left: ${({ $isSeparate }) => $isSeparate ? '20px' : '0' };
  display: flex;
  align-items: flex-end;
`

export const VarBlock = styled.div`
  margin-bottom: 16px;
  display: flex;
`

export const Sizes = styled.div`
  padding-top: 16px;
  display: flex;
  .MuiFormControl-root {
    margin-right: 8px;
  }
`

export const FixedFormContainer = styled.div`
    z-index: 100;
    width: 100%;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -4px 4px 0 #E0E0E07A, 0 1px 3px 0 rgba(0,0,0,0.12);
    max-width: inherit;
    position: fixed;
    bottom: 0;
    margin-left: -24px;
    margin-right: -24px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    flex-wrap: wrap;
    background-color: white;
`