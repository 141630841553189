// @ts-nocheck
import { Container } from 'inversify'
import { TYPES } from 'shared/services/types'
import { IHistory } from 'shared/services/interfaces/history'
import { IStorage } from 'shared/services/interfaces/storage'
import { ISocket } from 'shared/services/interfaces/socket'
import { User } from 'domains/user'
import { IUser } from 'shared/services/interfaces/user'
import { Doc } from 'domains/doc'
import { IDoc } from 'shared/services/interfaces/doc'
import { Catalog } from 'domains/catalog'
import { ICatalog } from 'shared/services/interfaces/catalog'
import { ListUtils } from 'domains/list-utils'
import { IListUtils } from 'shared/services/interfaces/list-utils'
import { IReceiptDocumentsService } from 'shared/services/interfaces/IReceiptDocumentsService'
import { IGoodsPIMService } from 'shared/services/interfaces/IGoodsPIMService'
import { IGoodsWMSService } from 'shared/services/interfaces/IGoodsWMSService'
import { IRouterService } from 'shared/services/interfaces/IRouterService'

import { cookieStore } from '../services/cookieStore'
import { localStore } from '../services/localStore'
import { browserHistory } from '../services/browserHistory'
import { socketGateway } from '../services/socket'
import { request } from '../services/request'
import { ReceiptDocumentsService } from '../api/wms/ReceiptDocumentsService'
import * as GoodsPIMService from '../api/pim/goods'
import * as GoodsWMSService from '../api/wms/GoodsService'
import { routerService } from '../services/RouterService/RouterService'
import { memoryStore } from '../services/memoryStorу'

const diContainer = new Container()


const user = new User(request, browserHistory, localStore)

diContainer.bind<IStorage>(TYPES.LocalStorage).toConstantValue(localStore)
diContainer.bind<IStorage>(TYPES.CookieStorage).toConstantValue(cookieStore)
diContainer.bind<IStorage>(TYPES.MemoryStorage).toConstantValue(memoryStore)
diContainer.bind<IHistory>(TYPES.History).toConstantValue(browserHistory)
diContainer.bind<ISocket>(TYPES.Socket).toConstantValue(socketGateway)
diContainer.bind<IUser>(TYPES.User).toConstantValue(user)

diContainer.bind<IReceiptDocumentsService>(TYPES.ReceiptDocumentsService).toConstantValue(ReceiptDocumentsService)
diContainer.bind<IGoodsPIMService>(TYPES.GoodsPIMService).toConstantValue(GoodsPIMService)
diContainer.bind<IGoodsWMSService>(TYPES.GoodsWMSService).toConstantValue(GoodsWMSService)
diContainer.bind<IRouterService<string>>(TYPES.RouterService).toConstantValue(routerService)
diContainer.bind<IDoc>(TYPES.Doc).to(Doc).inSingletonScope()
diContainer.bind<ICatalog>(TYPES.Catalog).to(Catalog).inSingletonScope()
diContainer.bind<IListUtils>(TYPES.ListUtils).to(ListUtils).inSingletonScope()

export { diContainer, TYPES }

export * as DIInterfaces from '../services/interfaces'