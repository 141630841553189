const EMPTY_EXTENSION = ''

const getPossibleExtension = (fileName: string): string => {
  const arr = fileName.split('.')
  return arr[arr.length - 1].trim()
}
const removeVersionInName = (fileName: string): string => {
  const hasVersion = fileName.includes('?')

  return hasVersion ?
    fileName.split('?')[0] :
    fileName
}

export const getFileExtension = (fileName?: string): string => {
  if(!fileName) return EMPTY_EXTENSION
  if(!fileName.includes('.')) return EMPTY_EXTENSION
  const possibleExtension = getPossibleExtension(fileName)
  return removeVersionInName(possibleExtension)
}