import { DialogTitleWithBtn, InputLabel } from 'shared/ui/components'
import { Box, Button, CircularProgress, DialogActions, InputAdornment, TextField } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Search } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { ProductPayload } from 'shared/api/pim/excel/products'
import { GET_PRODUCT_LIST } from 'shared/api/products'
import { ProductShortInfoWidget } from 'features/product/widgets'
import { pimGen } from 'shared/lib/generated'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isNil, isNotNil } from 'shared/lib/checkers'
import { LoadingButton } from '@mui/lab'
import { PriceSettingsForm } from 'features/product/common/CompetitorsTab/ui/priceAdviceSettings/PriceSettingsForm'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'

import { ModalContainer } from '../styled'

export const AddProductModal = ({ close, accept }) => {
  const [ searchString, setSearchString ] = useState('')
  const [ selectedProduct, setSelectedProducts ] = useState<any | null>(null)
  const { data: algorithmsData } = pimGen.priceWizard.GetAlgorithms.useGetAlgorithms()
  const { data, loading, previousData } = useQuery<any, ProductPayload>(GET_PRODUCT_LIST, {
    variables: {
      page: 0,
      limit: 10,
      searchString,
      excludeInArchive: true
    },
    context: { apiName: 'products' },
    skip: searchString.length === 0
  })
  const { data: priceSettingsArray, isLoading } =
    pimGen.priceWizard.GetPriceSettings.useGetPriceSettings({ }, { enabled: searchString.length !== 0 })
  
  const productsList = useMemo(() => data?.products?.result || previousData?.products?.result, [data, previousData])

  const onSubmit = (value) => accept({ value, selectedProduct })
  const onClose = () => close()

  return (
    <ModalContainer>
      <DialogTitleWithBtn sx={{ padding: 0 }} onClose={ close }>
        Добавить продукт
      </DialogTitleWithBtn>

      {isNotNil(selectedProduct) &&
        <>
          <Box className="selected-product-container">
            <InputLabel
              label="Выбранный продукт"
              required={ false }
            />
            <ProductShortInfoWidget
              key={selectedProduct.productId}
              onRemoveClick={() => setSelectedProducts(null)}
              {...selectedProduct}
            />
          </Box>

          {algorithmsData &&
            <PriceSettingsForm
              productPriceSettings={null}
              productId={selectedProduct.productId}
              algorithmsData={algorithmsData}
              externalOnSubmit={onSubmit}
              externalOnCancel={onClose}
            />
          }
        </>
      }

      {isNil(selectedProduct) && 
        <>
          <Box>
            <InputLabel
              label="Поиск продукта"
              required={ false }
            />

            <TextField 
              defaultValue={searchString}
              onChange={(e) => debouncedSearch(() => setSearchString(e.target.value))}
              size="small"
              fullWidth={true}
              placeholder="Поиск"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {(loading || isLoading) && <CircularProgress size={20} />}
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            {productsList && isNotEmptyArray(productsList) && priceSettingsArray &&
            <Box className="products-container">
              {productsList.map(item => (
                <ProductShortInfoWidget
                  key={item.productId}
                  productId={item.productId}
                  name={item.name!}
                  marketplaceCode={item.marketplaceCode!}
                  marketplaceUrl={item.marketplaceUrl!}
                  marketplaceName={item.marketplaceName}
                  marketplaceCategory={item.marketplaceCategory!}
                  cabinetName={item.cabinetName!}
                  price={item.price!}
                  addInfo={true}
                  companyName={item.companyName!}
                  autoOrdering={item.autoOrdering}
                  disabled={priceSettingsArray.map(el => el.productId).includes(item.productId)}
                  onAddClick={ () => setSelectedProducts(item) }
                />
              ))}
            </Box>
            }

          </Box>

          <DialogActions sx={{ padding: '0' }}>

            <Button
              variant="text"
              onClick={onClose}
            >
              ОТМЕНА
            </Button>

            <LoadingButton
              variant="contained"
              disabled={true}
            >
              Добавить
            </LoadingButton>
          </DialogActions>
        </>
        
        
      }

    </ModalContainer>
  )
}