import React from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { DoubleSmallWidget } from 'widgets/DashboardWidgets/DoubleSmallWidget'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { subtractDays } from 'shared/lib/utils/subtractDays'

export const DoubleSmallOrdersAndStocksWidget = () => {
  const utc = new Date()
  const stocksDateFrom = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 2)
  const stocksDateTo = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 1)

  const dateFrom = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 14)
  const dateTo = subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 1)

  const { data: stocksData } =
    analyticsApi.useAnalyticsStocksQuery(
      {
        dateFrom: stocksDateFrom,
        dateTo: stocksDateTo,
        units: 'items',
      })
  const { data: ordersData } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })

  const currentOrdersQuantity = ordersData?.data.data.slice(7).reduce((sum, elem) => sum + elem.items, 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,7).reduce((sum, elem) => sum + elem.items, 0)

  const currentStocksQuantity =
    stocksData?.data.data.filter(el => el.stocksDate === stocksDateTo).reduce((sum, elem) => sum + elem.available, 0)
  const prevStocksQuantity =
    stocksData?.data.data.filter(el => el.stocksDate === stocksDateFrom).reduce((sum, elem) => sum + elem.available, 0)

  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const stocksDiff = (currentStocksQuantity !== undefined && prevStocksQuantity !== undefined) ?
    (currentStocksQuantity - prevStocksQuantity) : 0

  const chartUrl = generatePath('/analytics/charts?range=0&tab=0')

  return (
    <>
      {(ordersData?.data.data && stocksData?.data.data) ?
        <DoubleSmallWidget
          title="Заказы и остатки за неделю"
          chartUrl={chartUrl}
          rightData={{
            data: currentStocksQuantity || 0,
            diff: stocksDiff,
            name: 'Остатки'
          }}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            name: 'Заказы'
          }}
        />
        :
        <Skeleton/>
      }
    </>
  )
}