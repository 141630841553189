import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'


interface editSimpleProductPayload {
  productId: UniqueId,
  editedSimpleProduct: pimApi.products.EditSimpleProduct
}

const editSimpleProduct = ({ productId, editedSimpleProduct } : editSimpleProductPayload) =>
  api.put<pimApi.products.SimpleProduct>(`/pim/products/simple/product/${productId}`, editedSimpleProduct)
    .then((res) => res.data)

export const useEditSimpleProductMutation =
  (options?: UseMutationOptions<pimApi.products.SimpleProduct,AxiosError<errorType>,editSimpleProductPayload>) =>
    useMutation<pimApi.products.SimpleProduct,AxiosError<errorType>,editSimpleProductPayload>
    ((editSimpleProductPayload)=>editSimpleProduct(editSimpleProductPayload), options)
