import styled from 'styled-components'
import { Paper } from '@mui/material'
import { PageTitle } from 'shared/ui/components'
import { Link } from 'react-router-dom'
import { Theme } from '@mui/system'

export const Wrapper = styled.div``

export const PaperTitle = styled.div`
  font-size: 20px;
  margin-bottom: 24px;
  color: #000;
`


export const PaperWrapper = styled(Paper)`
  padding: 40px 28px;
`
export const InputSimpleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    font-size: 16px;
    height: 100%;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: calc(120% - 10px);
  }
`
export const BottomSection = styled.div`
  position: relative;
  bottom: 0;
  box-shadow: inset 0 1px 0 rgba(196, 196, 196, 0.25);
  padding-top: 24px;
  margin-top: 72px;
`
export const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const PageTitleStyled = styled(PageTitle)`
  button {
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    margin-left: 16px;

    svg {
      font-size: 20px;
    }
  }
`
export const SecondNDSSpan = styled.span`
  display: inline-flex;
  margin-right: 20px;
`
export const InputActions = styled.div`
 
  display: flex;
  justify-content: space-between;
`

export const TabsNav = styled.div`
  .MuiTabs-indicator {
    display: none !important;
  }
  .MuiTab-root {
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
    min-width: 10px;
    color: #007dff;
    white-space: nowrap;
    border-bottom: 0;
    border-left: 0;
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }
  .Mui-selected {
    background: #ebf5ff;
  }
  .Mui-selected.Mui-disabled{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF;
    border-color: #C4C4C4;
  }
  .Mui-disabled{
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.26);
    border-color: #C4C4C4;
  }
`

export const TabsNavWithIcon = styled.div`
  .MuiTabs-indicator {
    display: none !important;
  }
  
  .MuiTabs-root {
    min-height: 44px;
    overflow: visible !important;

    .MuiTabs-scroller {
      overflow: visible !important;

      .MuiTabs-flexContainer {
        overflow: visible;
        .MuiButtonBase-root {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          max-height: 44px;
          min-height: 44px;
          overflow: visible;
          >.competitors-icon-label {
            position: relative;

            >div {
              top: -20px;
              right: -23px;
              
            }
          }
        }

      }
    }
  }
  .MuiTab-root {
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 10px;
    color: #007dff;
    white-space: nowrap;
    border-bottom: 0;
    border-left: 0;
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }
  .Mui-selected {
    background: #ebf5ff;
  }
  .Mui-selected.Mui-disabled{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF;
    border-color: #C4C4C4;
  }
  .Mui-disabled{
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.26);
    border-color: #C4C4C4;
  }
`
export const TabsNavWithMargin = styled(TabsNav)`
  margin-bottom: 36px;
`

export const BoxTypeItem = styled.div`
  width: 100%;
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 20px;
`
export const BoxTypeTitle = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #000;
`
export const UploadWrapper = styled.div`
  background: #F4F4F4;
  border: 2px dashed #BDBDBD;
  border-radius: 4px;
  padding: 36px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  > input{
    display: none;
  }
  span {
    svg {
      margin-right: 10px;
    }
  }
`
export const IsMainMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  svg {
    font-size: 18px;
    color: #FFB400;
  }
`
export const FillWidthForm = styled.form`
  width: 100%;
`
export const DocItemWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 35px;
  margin-bottom: 24px;
  width: 262px;
`
export const DocIcon = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  padding: 4px;
  background: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FF505F;
  svg {
    font-size: 18px;
    color: #FF505F;
  }
`
export const DeleteDoc = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    font-size: 24px;
    color: #F44336;
  }
`
export const ProductTitle = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`
export const ProductVatCode = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 24px;
`
export const ProductHeaderImg = styled.div<{ $isArchived?: boolean }>`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 100%;
    filter: ${({ $isArchived }) => $isArchived && 'grayscale(1)'};
    opacity: ${({ $isArchived }) => $isArchived && 0.8 };
  }
`
export const ProductHeaderImgPlaceholder = styled.img`
  width: 100%;
  height: 100%;
`
export const GoodsIcon = styled.span`
  width: 15px;
  height: 16px;
  margin-right: 10px;
  svg{
    min-width: 15px;
    min-height: 16px;
    color: #8D8D8D;
  }
`
export const LinkToGoods = styled(Link)<{theme: Theme}>`
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main}
`
export const VariantItem = styled.div`
  width: 100%;
`
export const GreyInputWrapper= styled.div`
  width: 100%;
  input{
    background: #fff;
  }
`
export const DocUrl = styled.div`
  opacity: 0;
  position: absolute;
`

export const PhotoWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 24px;
`
