import React, { memo } from 'react'
import { Tooltip, TooltipProps } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { HelpWithMargin } from 'shared/ui/styled/Help'

import { LabelType, InputLabel as InputLabelWrapper, FormLabelStyled } from './styled'

interface InputLabelProps {
  label?: string,
  style?: React.CSSProperties
  helpText?: string,
  helpDirection?: TooltipProps['placement'],
  required?: boolean,
  single?: boolean;
  error?: boolean
}

export const InputLabel: React.FC<InputLabelProps> = ({
  label = '',
  helpText = '',
  helpDirection = 'right',
  required = true,
  single = false,
  error,
  style
}) => (
  <InputLabelWrapper style={style} $isSingle={single}>
    <FormLabelStyled error={error}>{label}</FormLabelStyled>
    {required && <LabelType>*</LabelType>}
    {helpText && <Tooltip title={helpText} placement={helpDirection}>
      <HelpWithMargin>
        <InfoOutlined />
      </HelpWithMargin>
    </Tooltip>}
  </InputLabelWrapper>
)

export const InputLabelMemo = memo(InputLabel)