import { useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { generatePath } from 'react-router'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { GOODS_VARIANT } from 'shared/config/routes'
import { BottomButtonWrapper, BottomSection } from 'shared/ui/goods/common'
import { pimApi } from 'shared/api'
import { MemoComponentCard } from 'entities/pim/goods/ui/GoodsCard/ComponentCard'

interface ProductStepProps {
  handlePrev: () => void
  handleNext: () => void
  goodsType: pimApi.goods.GoodsType
  isArchived?: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const ExistsInStep = ({
  handlePrev,
  handleNext,
  goodsType,
  isArchived,
}: ProductStepProps) => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)

  const goodsQuery = pimApi.goods.useFetchGoodsQuery(goodsId, false)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const hasAttributesForVariation =
    goodsAttributesForVariationsQuery.data?.length !== 0
  const goodsInAssortment = goodsQuery?.data?.existsInAssortment

  return (
    <>
      <Box mb={2} mt={1}>
        {!isEmpty(goodsQuery?.data?.existsInBundles) && (
          <>
            <Box mb={2}>
              <Typography fontWeight={500} fontSize={14}>
                Товар входит в комплекты
              </Typography>
            </Box>
            <Box mb={4}>
              {goodsQuery?.data?.existsInBundles?.map((goodsInBundle) => {
                const fileThumbnailSrc = pimApi.goods.getGoodsThumbnailApiPath(
                  goodsInBundle.goodsId,
                  fileThumbnailSize
                )

                return (
                  <Box mb={2} key={goodsInBundle.goodsId}>
                    <MemoComponentCard
                      imgUrl={fileThumbnailSrc}
                      goodsType={goodsType}
                      componentId={goodsInBundle.goodsId}
                      name={goodsInBundle.name}
                      code={goodsInBundle.code}
                      auxCode={goodsInBundle.auxCode}
                      disabled={goodsInBundle.inArchive}
                    />
                  </Box>
                )
              })}
            </Box>
          </>
        )}
        {!isEmpty(goodsQuery?.data?.existsInPacks) && (
          <>
            <Box mb={2}>
              <Typography fontWeight={500} fontSize={14}>
                Из товара созданы спайки
              </Typography>
            </Box>
            <Box mb={4}>
              {goodsQuery?.data?.existsInPacks?.map((goodsInPack) => {
                const fileThumbnailSrc = pimApi.goods.getGoodsThumbnailApiPath(
                  goodsInPack.goodsId,
                  fileThumbnailSize
                )

                return (
                  <Box mb={2} key={goodsInPack.goodsId}>
                    <MemoComponentCard
                      imgUrl={fileThumbnailSrc}
                      goodsType={goodsType}
                      componentId={goodsInPack.goodsId}
                      name={goodsInPack.name}
                      code={goodsInPack.code}
                      auxCode={goodsInPack.auxCode}
                      disabled={goodsInPack.inArchive}
                    />
                  </Box>
                )
              })}
            </Box>
          </>
        )}
        {goodsInAssortment && (
          <>
            <Box mb={2}>
              <Typography fontWeight={500} fontSize={14}>
                Ассортиментная карточка по товару
              </Typography>
            </Box>
            <Box mb={4}>
              {goodsInAssortment && (
                <Box mb={2} key={goodsInAssortment.goodsId}>
                  <MemoComponentCard
                    imgUrl={pimApi.goods.getGoodsThumbnailApiPath(
                      goodsInAssortment.goodsId,
                      fileThumbnailSize
                    )}
                    goodsType={goodsType}
                    componentId={goodsInAssortment.goodsId}
                    name={goodsInAssortment.name}
                    code={goodsInAssortment.code}
                    auxCode={goodsInAssortment.auxCode}
                    disabled={goodsInAssortment.inArchive}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      <BottomSection>
        <BottomButtonWrapper>
          <div>
            <Button color="primary" onClick={handlePrev}>
              НАЗАД
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isArchived}
            >
              ДАЛЕЕ
            </Button>
          </div>
          <div>
            {goodsId && hasAttributesForVariation && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generatePath(GOODS_VARIANT, { id: goodsId })}
                disabled={isArchived}
              >
                ДОБАВИТЬ ВАРИАЦИЮ
              </Button>
            )}
            <Box display="inline-flex" pl={2}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleOpenCreateModal}
                disabled={isArchived}
              >
                СОЗДАТЬ ПРОДУКТ
              </Button>
              <ProductCreateModal
                isOpen={openCreateModal}
                setIsOpen={setOpenCreateModal}
              />
            </Box>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
