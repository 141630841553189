import { useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'

interface DeleteProductPayload {
  productId: UniqueId,
  id?: UniqueId,
  versionNo?: UniqueId,
  comments?: string
}

export const deleteProduct = ({ productId, id, versionNo }: DeleteProductPayload) => 
  api.delete(
    `/pim/products/wb/product/${productId}`,  {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )
  

export const useDeleteProductMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteProductPayload
  >(deleteProduct)