import { BrandInfo } from 'shared/api/brandquad/models'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { Box } from '@mui/material'
import defaultGoodImage from 'assets/images/defaultGoodImage.svg'
import { Image } from 'shared/ui/components'

export const BrandCard = ({ brandItem }: { brandItem: BrandInfo }) => {
  const { handleChangeParams } = useQueryParams({
    parseNumbers: true,
    arrayFormat: 'bracket',
  })

  const handleBrandClick = () => {
    handleChangeParams({
      params: { brandIds: [brandItem.id] },
      options: {
        arrayFormat: 'bracket',
      },
      isPush: true
    })
  }

  return (
    <Box className="brand-card" onClick={handleBrandClick}>
      <div className="brand-img-wrapper">
        <Image
          src={ brandItem.iconUrl || defaultGoodImage}
          style={{ objectFit: (brandItem.iconUrl ? 'contain' : 'none') }}
        />
      </div>
      <span className="brand-card-label">{ brandItem.name }</span>
    </Box>
  )
}