import styled from 'styled-components'

export const TableWrapper = styled.div`
  position: relative;

  .MuiTableCell-paddingCheckbox {
    padding: 0 !important;
  }

  .MuiToolbar-root {
    >div:first-child {
      display: none;
    }
    .MuiInputAdornment-root {
      display: none;
    }
  }

  .TableDetailCell-active {
    .TableCell-cell {
      padding: 16px 16px 16px 8px;

      &:first-of-type {
        padding-left: 16px;
      }
    }
  }

  .MuiTableCell-head {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 56px;

    &:hover {
      .MuiButtonBase-root {
        opacity: 1;
      }
    }

  }

  .TableCell-cellRightAlign {
    padding-right: 32px;
  }
  
`

export const VirtualTableWrapper = styled.div<{tableHeight?: number}>`
  .TableContainer-root {
    position: relative;
    overflow: auto;
  }
  thead tr {
    position: sticky !important;
    top: 0;
    z-index: 1;
    th {
      background-color: #fbfbfb;
    }
  }

  [isrowexpanded]+tr {
    thead tr {
      z-index: 0;
    }
  }
  
  tr td div .TableContainer-root {
    height: auto;
  }

  .MuiTableCell-paddingCheckbox {
    padding: 0 !important;
  }

  .MuiToolbar-root {
    >div:first-child {
      display: none;
    }
    
    .MuiInputAdornment-root {
      display: none;
    }
  }

  .TableDetailCell-active {
    .TableCell-cell {
      padding: 16px 16px 16px 8px;

      &:first-of-type {
        padding-left: 16px;
      }
    }
  }

  .MuiTableCell-head {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 56px;

    &:hover {
      .MuiButtonBase-root {
        opacity: 1;
      }
    }
  }

  .TableCell-cellRightAlign {
    padding-right: 32px;
  }

  .SearchPanelInput-root {
    margin-left: 54px;
    width: 336px;
  }
`


export const VirtualTableWrapperDX = styled.div<{tableHeight?: number}>`
  .MuiTableCell-paddingCheckbox {
    padding: 0 !important;
  }
  
  .MuiToolbar-root {
    div:first-child {
      display: none;
    }
  }

  .TableDetailCell-active {
    .TableCell-cell {
      padding: 16px 16px 16px 8px;

      &:first-of-type {
        padding-left: 16px;
      }
    }
  }

  .MuiTableCell-head {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 56px;

    &:hover {
      .MuiButtonBase-root {
        opacity: 1;
      }
    }
  }

  .TableCell-cellRightAlign {
    padding-right: 32px;
  }

  .SearchPanelInput-root {
    margin-left: 54px;
    width: 336px;
  }
`