import styled from 'styled-components'

export const Help = styled.span`
  color: rgba(0, 0, 0, 0.38);
  display: inline-flex;
  align-items: center;
  height: 100%;
  svg {
    font-size: 19px;
  }
`

export const HelpWithMargin = styled(Help)`
  margin-right: 15px;
`