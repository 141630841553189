import { memo, useCallback, useMemo } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { pimApi, user } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import { useRowsPerPage } from 'shared/ui/components/Table/lib/useRowsPerPage'
import { useQueryClient } from 'react-query'
import { getUserSettingsQueryKey } from 'shared/api/user/settings'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { ADMIN_PANEL_CREATE_USER } from 'shared/config'
import { customerGen } from 'shared/lib/generated'

import { pagingPanelLocale } from './lib/localization'
import { columnsConfig } from './model/tableColumnsConfig'
import { StyledTableBox, StyledLoader } from './styled'
import { EmptyUsersPage } from './EmptyUsersPage'

const getRowId = (row: pimApi.admin.AppAdminModelUsers) => row.id

const MAX_PAGE_SIZE = 50

const AdminUsersTable = () => {
  const { calculatedRowsPerPage } = useRowsPerPage()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage)

  const { data, isLoading, isFetching } =
    customerGen.administration.GetUsers.useGetUsers({ query: undefined })

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0,
    }),
    [data]
  )

  const { data: userSettingsQuery } = user.settings.useFetchUserSettingsQuery()

  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.usersTableHiddenColumns
  const queryClient = useQueryClient()

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings(
        {
          settings: {
            ...userSettingsQuery?.data,
            usersTableHiddenColumns: hiddenColumns,
          },
        },
        {
          onSuccess: (response) => {
            queryClient.setQueryData(getUserSettingsQueryKey(), response)
          },
        }
      )
    },
    [userSettingsQuery?.data]
  )
  if (data?.length === 0) {
    return <EmptyUsersPage />
  }
  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Typography component="h1" variant="h3" fontSize="1.5rem">
          Пользователи
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          component={Link}
          to={ADMIN_PANEL_CREATE_USER}
          color="primary"
        >
          ДОБАВИТЬ
        </Button>
      </Box>
      <StyledTableBox component={Paper}>
        {(isFetching || isLoading) && <StyledLoader size={60} />}
        {data && (
          <Table
            tableList={data}
            totalCount={data.length}
            getRowId={getRowId}
            paginationLocale={pagingPanelLocale}
            tableParams={memoTableParams}
            handleTableSettingsChange={handleTableSettingsChange}
            tableHiddenColumns={tableHiddenColumns}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={columnsConfig}
            showColumnsVisibility={true}
            searching="internal"
            pagination="internal"
            sorting="internal"
            filtering="internal"
          />
        )}
      </StyledTableBox>
    </div>
  )
}

export const MemoAdminUsersTable = memo(AdminUsersTable)
