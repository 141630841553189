import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

const downloadSimpleProductBulkEditTemplateFile = async ({
  companymarketplaceId,
  dateFrom,
  dateTo,
}: {
  companymarketplaceId?: UniqueId
  dateFrom?: string
  dateTo?: string
}) =>
  api.get<File>(
    `/pim/ops/products/simple/change/product?companymarketplace_id=${companymarketplaceId}&template_format=excel` +
    `${dateFrom ? `&date_from=${dateFrom}` : ''}${dateTo ? `&date_to=${dateTo}` : ''}`,
    {
      responseType: 'blob',
    }
  )

export const useDownloadSimpleProductBulkEditTemplateFile = (options?) =>
  useMutation(downloadSimpleProductBulkEditTemplateFile, options)


const uploadSimpleProductBulkEditFile = async ({
  file,
  companymarketplaceId,
}: {
  file: File | null
  companymarketplaceId: UniqueId
}) => {
  const body = new FormData()
  body.append('file', file || '')

  return api.post(
    `/pim/ops/products/simple/change/product?companymarketplace_id=${companymarketplaceId}`,
    body,
  )
}

export const useUploadSimpleProductBulkEditFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      file: File
      companymarketplaceId: UniqueId
    }
  >
) => useMutation(uploadSimpleProductBulkEditFile, options)
