import { Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { ButtonNavigation } from 'shared/ui/components/navigation'
import { GOODS_LIST, PRODUCT_LIST } from 'shared/config'
import icon from 'assets/images/icons/excel.svg'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { useDownloadFile } from '../../model/downloadFile'


const NAV_PATH = [
  { path: PRODUCT_LIST, title: 'ПРОДУКТЫ' },
  { path: GOODS_LIST, title: 'ТОВАРЫ' },
]

export const Header = ({ downloadParams }) => {
  const { download, isLoading } = useDownloadFile()
  const downloadGoodsFile = () => {
    Object.keys(downloadParams).forEach((key) => {
      if (key === 'sort') {
        // eslint-disable-next-line no-param-reassign
        downloadParams[key] =
          downloadParams[key]
            ? toSnake(downloadParams[key])
            : undefined
      }
    })
    download(downloadParams)
  }

  return (
    <Box className="products-header" display="flex" justifyContent="space-between" alignItems="center" mb={3} mt="28px">
      <Typography variant="h3" fontSize="24px">Мои продукты</Typography>

      <Box ml={2} display="flex" alignItems="center">
        <LoadingButton
          variant="contained"
          color="primary"
          loadingPosition="start"
          startIcon={<ExcelIcon src={icon} alt="excelImg" />}
          onClick={downloadGoodsFile}
          loading={isLoading}
        >
          ЭКСПОРТ
        </LoadingButton>
        <Box ml={3}>
          <ButtonNavigation paths={NAV_PATH}/>
        </Box>
      </Box>
    </Box>  
  )
}
