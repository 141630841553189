import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { useHistory } from 'react-router'

import { ElasticityOfDemandChart } from './chart'

import { ChartsDataTypes } from '../../../types/types'
import { ChartsProvider } from '../../../lib/useChartContext'

const TimelineRange: Record<number, any> = {
  0: {
    length: {
      startValue: subtractDays(new Date(), 7),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 1),
    endValue:  getPrevDay()
  },
  1: {
    length: {
      startValue: subtractMonths(new Date(), 1),
      endValue: getPrevDay()
    },
    startValue: subtractMonths(new Date(), 4),
    endValue:  getPrevDay()
  },
  2: {
    length: {
      startValue: subtractMonths(new Date(), 12),
      endValue: getPrevDay()
    },
    startValue: undefined,
    endValue:  getPrevDay()
  },
  3: {
    length: {}
  }
}

export const ElasticityOfDemandPage = () => {
  const { search } = useLocation()
  const history = useHistory()
  const searchObj: ChartsDataTypes = queryString.parse(search, { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' })
  const historySearch = queryString.parse(window.location.search)

  const range = searchObj.range || 0
  const units = searchObj.units || 'items'
  const chartType = searchObj.chartType || 'chart'

  const startValue = range === 3 ?
    (searchObj?.startValue || TimelineRange[0].startValue) :
    TimelineRange[searchObj?.range?.toString() || 0].startValue
  const endValue = range === 3 ?
    (searchObj?.endValue || TimelineRange[0].endValue) :
    TimelineRange[searchObj?.range?.toString() || 0].endValue

  const timelineStart = historySearch.timelineStart?.toString() || TimelineRange[searchObj?.range?.toString() || 0].length.startValue
  const timelineEnd = historySearch.timelineEnd?.toString() || TimelineRange[searchObj?.range?.toString() || 0].length.endValue

  const handleChartParams = (params) => {
    const searchParams = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...searchParams, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace(`?${historyStr}`)
  }

  const contextSearchObj = { ...searchObj, startValue, chartType, endValue, timelineEnd, timelineStart, range, units }

  return (
    <ChartsProvider value={{
      searchObj: contextSearchObj,
      handleChartParams
    }}>
      <ElasticityOfDemandChart/>
    </ChartsProvider>
  )
}