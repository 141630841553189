import React from 'react'
import { TasksTable } from 'features/copackingOrders'

import { StyledPanel } from './styled'

export const CopackingOrdersPanel = () => (
  <StyledPanel>
    <span className="header">Задания на упаковку</span>
    <div className="paper">
      <TasksTable/>
    </div>
  </StyledPanel>
)