export function getWeekDates(weekDate) {
  const [weekString, yearMonth] = weekDate.split(' ')
  const weekNumber = weekString.replace(/\D/g, '')

  const year = 2000 + Number(yearMonth.split('/')[1])
  // Дата 1 января года в UTC
  const date = new Date(Date.UTC(year, 0, 1))

  // Получаем день недели для 1 января (0 - воскресенье, 1 - понедельник и т.д.)
  const dayOfWeek = date.getUTCDay()

  // Сдвигаем на нужное количество дней, чтобы начать с понедельника
  const daysToAdd = (Number(weekNumber) - 1) * 7 + (1 - dayOfWeek + 7) % 7

  // Устанавливаем дату на начало недели
  date.setUTCDate(date.getUTCDate() + daysToAdd)

  // Получаем startDate (начало недели)
  const startDate = new Date(date)

  // Получаем endDate (конец недели)
  const endDate = new Date(startDate)
  endDate.setUTCDate(startDate.getUTCDate() + 6)

  // Форматируем в строковый вид для удобства
  const startFormatted = startDate.toISOString().split('T')[0]
  const endFormatted = endDate.toISOString().split('T')[0]

  return {
    startDate: startFormatted,
    endDate: endFormatted
  }
}