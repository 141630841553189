export const getTimeOfDay = () => {
  const today = new Date()
  const currentHour = today.getHours()

  if (currentHour >= 4 && currentHour <= 11) {
    return 'Доброе утро, '
  } if (currentHour > 11 && currentHour <= 17) {
    return 'Добрый день, '
  } if (currentHour > 17 && currentHour <= 22) {
    return 'Добрый вечер, '
  }
  return 'Доброй ночи, '
}