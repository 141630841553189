/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import axios, { AxiosTransformer } from "axios";
import { toCamelCase, toSnakeCase } from "shared/lib/transform";
import queryString from 'query-string'
import { BrandInfo } from 'shared/lib/generated/brandquad/Api'


export type ResponseType = CamelCasedPropertiesDeep<BrandInfo[]>

export type IRequest = CamelCasedPropertiesDeep<{
  clientId: string
}>

export const apiFunction = async ({ clientId }: IRequest) => {
  return axios.get(`/yml/brands/${clientId}`,
    {
        paramsSerializer: (params) => queryString.stringify(toSnakeCase(params), { skipNull: true, skipEmptyString: true }), transformResponse: [
        ...axios.defaults.transformResponse as AxiosTransformer[],
        (data) => data instanceof Blob ? data: toCamelCase(data),
      ]
    }
  ).then(res => res.data)
}

type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ clientId }: IRequest) => ['get', `/yml/brands/${clientId}`, { clientId }] as const

export const useGetBrands = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

