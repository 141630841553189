import { tradeGen } from 'shared/lib/generated'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { checkValue } from 'shared/lib/checkers'

import { CoefficientTable } from './CoefficientTable'

import { Header } from '../styled'
import { TableFilter } from '../TableFilter'

export const SupplyOrdersCoefficient = () => {
  const { searchObj } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })
  const { searchString }  = searchObj
  const { supplyOrderQuery } = useSupplyOrderContext()

  const {
    data: distributionData,
    isLoading: distributionIsLoading } =
    tradeGen.orders.Getdistributions.useGetdistributions({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchString) }
    })


  return (
    <>
      <Header>Выбор склада</Header>
      <TableFilter/>
      { distributionData &&
        <CoefficientTable distributionData={distributionData} distributionIsLoading={distributionIsLoading}/>
      }
    </>
  )
}