import { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { productModel } from 'entities/pim/product'

const DEFAULT_NOMENCLATURE_INDEX = 0

export function useFormInitialValues(
  productId: UniqueId,
  {
    ozonProductQueryData,
    editOzonProductInProgress,
    ozonProductQueryLoading,
    companyId,
    brandId,
  }: {
    ozonProductQueryData?: pimApi.products.OzonProduct
    ozonProductQueryLoading?: boolean
    editOzonProductInProgress?: boolean
    companyId?: UniqueId
    brandId?: object
  }
): productModel.ozonModel.EditInitialFormValues {
  const currentNomenclatureIndex: number = DEFAULT_NOMENCLATURE_INDEX

  return useMemo<productModel.ozonModel.EditInitialFormValues>(
    () => ({
      ...(ozonProductQueryData ?? {}),
      companyId,
      currentNomenclatureIndex,
      brandId,
    }),
    [ozonProductQueryLoading, editOzonProductInProgress]
  )
}
