import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import {
  AppPimGoodsModelsGoodsGoodsComponent,
} from 'domains/pim/goods'
import { ComponentCard, goodsLib } from 'entities/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import { InputLabel } from 'shared/ui/components'

import { ResultList, ErrorText, InfoText } from './styled'


interface GoodsInComponentProps {
  goodsType: pimApi.goods.GoodsType
  withRemove: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const GoodsInComponent: FC<GoodsInComponentProps> = ({
  withRemove,
}) => {
  const componentsField = useField('packGoods')

  if(isEmpty(componentsField.input.value)) return null

  return (
    <ResultList>
      <InputLabel
        label={goodsLib.getGoodsLabelByType(pimApi.goods.GoodsType.Pack)}
        required={false}
      />
      <Grid container={true} spacing={2}>
        <FieldArray<AppPimGoodsModelsGoodsGoodsComponent> name="packGoods">
          {({ fields }) =>
            !isEmpty(fields) && (
              fields.value.map((goodsComponent, index) => {
                const imgUrl = pimApi.goods.getGoodsThumbnailApiPath(
                  goodsComponent.componentId,
                  fileThumbnailSize
                )

                return (
                  <Grid item={true} xs={12} key={goodsComponent.componentId}>
                    <ComponentCard
                      imgUrl={goodsComponent.goodsBaseImageId ? imgUrl : undefined}
                      goodsType={goodsComponent.goodsType}
                      componentId={goodsComponent.componentId}
                      name={goodsComponent.name}
                      code={goodsComponent.code}
                      unitCode={goodsComponent.unitCode}
                      auxCode={goodsComponent.auxCode}
                      unitPartible={goodsComponent.unitPartible}
                      quantityFieldName={`packGoods.${index}.quantity`}
                      onRemoveClick={() => {
                        if(withRemove) {
                          fields.remove(index)
                        }
                      }}
                    />
                  </Grid>
                )
              })
            )
          }
        </FieldArray>
      </Grid>
      {componentsField.meta.touched && componentsField.meta.error ? (
        <Box mt={2}>
          <ErrorText>
            {componentsField.meta.error}
          </ErrorText>
        </Box>
      ) : (
        <Box mt={2}>
          <InfoText>
            {componentsField.meta.error}
          </InfoText>
        </Box>
      )}
    </ResultList>
  )
}
