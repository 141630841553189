interface IRatingPercent {
  name: number;
}

export const RatingPercent = ({ name }: IRatingPercent) => {
  if (!name) return null 
 
  return ( 
    <div>
      {name}%
    </div>
  )
}
 