import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { Category } from './models'

import { api } from '../../base'

export const getCategoriesQueryKey = () =>
  ['get', 'pim/categories'] as const

type GetCategoriesQueryKey = ReturnType<typeof getCategoriesQueryKey>

export const fetchCategories = (categoryName?: string) =>
  api.get<Category[]>('/pim/categories', { params: { search_string: categoryName } })

export const useGetCategoriesQuery = () =>
  useQuery<
    AxiosResponse<Category[]>,
    errorType,
    AxiosResponse<Category[]>,
    GetCategoriesQueryKey
  >(
    getCategoriesQueryKey(),
    () => fetchCategories(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
