import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

const downloadWBProductBulkCreationTemplateFile = async (queryParams: {
  category?: string
  companymarketplaceId?: UniqueId
  templateType,
  brand
}) =>
  api.get<File>(
    '/pim/ops/products/wb/product',
    {
      responseType: 'blob',
      params: {
        companymarketplace_id: queryParams.companymarketplaceId,
        template_type: queryParams.templateType,
        category: queryParams.category,
        brand: queryParams.brand
      }
    }
  )

export const useDownloadWBProductBulkCreationTemplateFile = (options?) =>
  useMutation(downloadWBProductBulkCreationTemplateFile, options)

const uploadWBProductBulkCreationFile = async (queryParams: {
  brand?: UniqueId,
  file: File | null,
  category?: string
  useGoodsMedia: boolean
  companymarketplaceId: UniqueId
  templateType?: string
}) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')

  return api.post(
    '/pim/ops/products/wb/product',
    body, { params: { 
      companymarketplace_id: queryParams.companymarketplaceId,
      use_goods_media: queryParams.useGoodsMedia,
      template_type: queryParams.templateType,
      category: queryParams.category,
      brand: queryParams.brand
    } }
  )
}

export const useUploadWBProductBulkCreationFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      brand?: UniqueId,
      file: File | null,
      category?: string
      useGoodsMedia: boolean
      companymarketplaceId: UniqueId
      templateType?: string
    }
  >
) => useMutation(uploadWBProductBulkCreationFile, options)
