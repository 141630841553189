import { Breadcrumbs } from '@mui/material'
import React, { useContext, useState } from 'react'
import { format } from 'date-fns'
import { dayTitle } from 'shared/lib/utils/DayTitle'
import { getDatesLength } from 'shared/lib/utils/getDatesLength'

import { Description } from './Description'

import { ActivityContext, IActivityContext } from '../../model/ActivityContext'
import { DetailActivityHeader } from '../styled'

export const Header = () => {
  const { detailActivity } = useContext(ActivityContext) as IActivityContext


  const activityInterval = {
    fromDate: format(new Date(detailActivity?.fromDate || ''), 'dd.MM.yyyy HH:mm'),
    toDate: format(new Date(detailActivity?.toDate || ''), 'dd.MM.yyyy HH:mm'),
    delta: (detailActivity?.toDate && detailActivity.fromDate) ?
      getDatesLength(new Date(detailActivity.fromDate), new Date(detailActivity.toDate)) :
      undefined
  }

  const [diff, setDiff] = useState('')
  setInterval(timer, 1000)

  function timer() {
    const now = new Date().getTime()
    const timeLeft = detailActivity?.freezeTime ? new Date(detailActivity?.freezeTime).getTime() - now : 0

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    const dateStr = `${days} ${dayTitle(days)} ${hours}:${minutes}:${seconds}`

    setDiff(dateStr)
  }

  return (
    <DetailActivityHeader>
      <Breadcrumbs>
        { detailActivity?.companyName && <span color="inherit">{ detailActivity.companyName }</span>}
        { detailActivity?.typeName && <span color="inherit">{detailActivity.typeName} {detailActivity.marketplaceName}</span>}
        { detailActivity?.name && <span color="text.primary">{detailActivity.name}</span>}
      </Breadcrumbs>
      <div className="detail-info-container">
        <span className="activity-name">{detailActivity?.name}</span>
        <div className="activity-time-container">
          { (activityInterval.toDate && activityInterval.fromDate) &&
          <span className="activity-time-interval">
            {activityInterval?.fromDate} — {activityInterval?.toDate} ({activityInterval.delta} {dayTitle(activityInterval.delta)})
          </span>
          }
          { detailActivity?.freezeTime && (new Date(detailActivity.freezeTime) > new Date()) &&
          <div className="timer-span">
            <span>|</span>
            <span>На редактирование списка осталось</span>
            <span>{diff}</span>
          </div>
          }
        </div>
        {detailActivity?.description &&
        <Description description={detailActivity.description}/>
        }
      </div>
    </DetailActivityHeader>
  )
}