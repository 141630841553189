import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'
import { pimApi } from 'shared/api'

interface CommonProductContextProviderProps {
  commonProductQuery: QueryObserverResult<pimApi.products.common.Product>,
}

const CommonProductContext = React.createContext<CommonProductContextProviderProps | undefined>(undefined)

function CommonProductContextProvider({ children, commonProductQuery }:
  PropsWithChildren<CommonProductContextProviderProps>) {
  return (
    <CommonProductContext.Provider value={{ commonProductQuery }}>{children}</CommonProductContext.Provider>
  )
}

function useCommonProductContext() {
  const context = React.useContext(CommonProductContext)

  if (context === undefined) {
    throw new Error('useCommonContext must be used within a CommonProvider')
  }

  return context
}

export { CommonProductContextProvider, useCommonProductContext }