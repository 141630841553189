import { tradeGen } from 'shared/lib/generated'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { checkValue } from 'shared/lib/checkers'

import { ChangeWarehouse } from './ChangeWarehouse'

import { StyledModalContainer } from '../../styled'

export const ChangeWarehouseModal = ({ supplyOrderQuery, close, accept }) => {
  const { searchObj } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })
  const { searchString }  = searchObj

  const { data: distributionData } =
    tradeGen.orders.Getdistributions.useGetdistributions({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchString) }
    })

  return (
    <StyledModalContainer>
      { distributionData &&
        <ChangeWarehouse distributionData={distributionData} close={close} accept={accept}/>
      }
    </StyledModalContainer>
  )
}