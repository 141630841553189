import { MoreVert } from '@mui/icons-material'
import React from 'react'
import { pimApi } from 'shared/api'
import {
  DescMedia,
  VideoContent,
  YoutubeLink
} from 'shared/ui/goods/common'
import { Image, SettingsMenu } from 'shared/ui/components'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadFileWithPath } from 'shared/lib/utils'

interface VideoItemProps {
  productId: UniqueId
  openGallery?: () => void
  item: pimApi.products.wb.WBMedia
  onFileDelete: (item: pimApi.products.MediaFile) => void
  disabled?: boolean
}

export const VideoItem = ({ productId, item, onFileDelete, disabled, openGallery }: VideoItemProps) => {
  const handleFileDelete = () => onFileDelete(item)
  const settingsOptions: Array<any> = [
    {
      label: item?.downloadUrl?.length! > 1 ? 'Скачать' : 'Перейти по ссылке',
      divider: true,
      disabled,
      icon: <DownloadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        const element = document.createElement('a')
        if (item?.downloadUrl?.length! > 1) {
          if (item.downloadUrl) {
            const url = new URL(item.downloadUrl)
            downloadFileWithPath(url.pathname, item.fileName || '')
          }
        } else {
          element.target = '_blank'
          element.href = item.fileName || ''
          element.click()
        }
      }
    },
    {
      label: 'Удалить',
      disabled,
      icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
      type: 'error',
      handler: handleFileDelete
    },
  ]
  const apiUrl = `/pim/products/wb/product/${productId}/video/thumbnail/${item.id}?max_height=400&max_width=400`

  return (
    <>
      <VideoContent onClick={() => {
        if (openGallery) openGallery()}
      }>
        <Image src={apiUrl} />
        <SettingsMenu
          className="settingsMenu"
          options={settingsOptions}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          iconSize="small"
          iconType={<MoreVert />}
        />
      </VideoContent>
      <YoutubeLink
        target={item?.downloadUrl?.length! > 1 ? '' : '_blank'} title={item?.fileName}
        href={item?.downloadUrl?.length! > 1 ? item.downloadUrl : item.fileName}>
        <DescMedia>{item?.fileName}</DescMedia>
      </YoutubeLink>
    </>
  )
}
