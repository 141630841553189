import { Button } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { Nav } from './styled'

interface Path {
  path: string
  title: string
}
interface ButtonNavigationProps {
  paths: Array<Path>
}
export const ButtonNavigation = ({ paths }: ButtonNavigationProps) => (
  <Nav>
    {paths.map(pathItem => (
      <Button
        key={pathItem.path}
        variant="contained"
        color="primary"
        component={NavLink}
        to={pathItem.path}
      >
        {pathItem.title}
      </Button>
    ))}
  </Nav>
)
