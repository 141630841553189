import styled from 'styled-components'
import { getSupplyOrderStatusInfo, useSupplyOrderStatusQuery } from 'shared/api/pim/dictionaries'

const StatusHistoryCell = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`

const StatusIcon = styled.div<{ customColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.customColor};
  border-radius: 50%;
  color: ${props => props.customColor};
  width: 28px;
  height: 28px;
  svg {
    width: 22px;
  }
`

export const HistoryStatus = ({ statusId }: { statusId: number | undefined }) => {

  const { data, isLoading } = useSupplyOrderStatusQuery()
  const { currentStatus, currentObject, icon } = getSupplyOrderStatusInfo(data,statusId)

  return (
    <StatusHistoryCell>
      {!isLoading && data && statusId!==undefined  &&
        <>
          <StatusIcon customColor={currentObject[0].color}>
            {icon}
          </StatusIcon>
          <span>{currentStatus?.name}</span>
        </>}
    </StatusHistoryCell>
  )
}