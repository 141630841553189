import { FC, memo, useCallback, useMemo, useState } from 'react'
import { Option } from 'shared/ui/components/interface'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import {
  AutocompleteValue,
  FilterOptionsState,
} from '@mui/material/useAutocomplete'

export interface AutocompleteDictionaryAttributeProps
  extends FieldRenderProps<Array<string> | string> {
  fetchCatalog: (steach: string) => Promise<Array<Option<any>>>
  maxCount?: number
  useOnlyDictionaryValues?: boolean
}

const getOptionFromDictionary = (value: string): Option => ({
  label: value,
  value,
})

const getDictionaryFromOption = (option: any) => option?.value || option

function getCorrectValue<R extends boolean>(
  multiple: R,
  value: Array<string> | string = ''
): Option | Array<Option> {
  if (multiple) {
    return (Array.isArray(value) ? value : []).map(getOptionFromDictionary)
  }

  return getOptionFromDictionary(Array.isArray(value) ? '' : value)
}

export const AutocompleteDictionaryAttributeField: FC<AutocompleteDictionaryAttributeProps> =
  ({ fetchCatalog, maxCount, useOnlyDictionaryValues, ...props }) => {
    const [isFirstRequest, setIsFirstRequest] = useState(true)

    const handleFetch = useCallback((steach: string) => {
      setIsFirstRequest(false)

      return fetchCatalog(steach)
    }, [])

    const handleChange = (
      values: AutocompleteValue<Option<any>, boolean, undefined, undefined>
    ) => {
      props.input.onChange(
        props.input.multiple
          ? (
            values as AutocompleteValue<
              Option<any>,
              true,
              undefined,
              undefined
            >
          ).map(getDictionaryFromOption)
          : getDictionaryFromOption(
            values as NonNullable<
              AutocompleteValue<Option<any>, false, undefined, undefined>
            >
          )
      )
    }

    const value = getCorrectValue(props.input.multiple!, props.input.value)
    const input: FieldInputProps<Array<Option> | Option> = useMemo(
      () => ({
        ...props.input,
        value,
      }),
      [props.input]
    )

    const multipleMaxCountDisabled: boolean =
      typeof maxCount === 'number' &&
      props.input.multiple! &&
      (value as Array<Option>).length >= maxCount

    const filterOptions = useCallback(
      () =>
        (
          options: Array<Option<string>>,
          state: FilterOptionsState<Option<string>>
        ) => {
          const filtered = options

          if (!useOnlyDictionaryValues && state.inputValue !== '') {
            filtered.push({
              value: state.inputValue,
              label: `Добавить "${state.inputValue}"`,
            })
          }

          return filtered
        },
      []
    )

    return (
      <AutocompleteCatalogSearchField<string, boolean>
        {...(props as FieldRenderProps<any>)}
        // @ts-ignore
        input={input}
        // @ts-ignore
        onChange={handleChange}
        dragChips={true}
        fetchCatalog={handleFetch}
        allowEmptyString={true}
        maxCount={maxCount}
        multiple={props.input.multiple}
        noOptionsText={
          isFirstRequest ? 'Введите строку для поиска' : 'Ничего не найдено'
        }
        getOptionDisabled={(optionItem: Option) =>
          multipleMaxCountDisabled &&
          !props.input.value.includes(optionItem.value)
        }
        filterOptions={filterOptions}
        freeSolo={!useOnlyDictionaryValues}
      />
    )
  }

export const AutocompleteDictionaryAttributeFieldMemo = memo(
  AutocompleteDictionaryAttributeField
)
