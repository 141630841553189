import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, useMutation, UseMutationOptions } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

import {
  GoodsShortInfo,
  Goods,
  NewGoods,
  EditGoods,
} from './models'

export interface GetGoodsParams {
  limit?: number
  page: number
  searchString?: string
  excludeInArchive?: boolean
  goodsType?: string
  sort?: string
  order?: string
  contragentId?: string;
  marketplaceId?: string;
  goodsBrandId?: string;
  inArchive?: string;
}

export type DownloadGoodsFileParams = Omit<GetGoodsParams, 'limit' | 'page'>

export function getGoods(searchString?: string) {
  const basePath = '/pim/goods'
  const path = searchString
    ? `${basePath}?search_string=${searchString}`
    : basePath
  return api.get<Array<GoodsShortInfo>>(path)
}

export const getGoodsByCodeQueryKey = (code: string, withVariations = false) =>
  ['get', 'pim/goods/goodsbycode', code, withVariations] as const

type QueryKey = ReturnType<typeof getGoodsByCodeQueryKey>

export const getGoodsByCode = (code: string, withVariations = false) =>
  api.get('pim/goods/goodsbycode', {
    params: { code, with_variations: withVariations },
  })

export const useGoodsByCodeQuery = (code: string, withVariations?: boolean) =>
  useQuery<
    AxiosResponse<Array<GoodsShortInfo>>,
    errorType,
    AxiosResponse<Array<GoodsShortInfo>>,
    QueryKey
  >(getGoodsByCodeQueryKey(code, withVariations), ({ queryKey }) =>
    getGoodsByCode(queryKey[2], queryKey[3]),
  { enabled: !!code }
  )

export function createOneGoods(goods: NewGoods): Promise<AxiosResponse<Goods>> {
  return api.post<Goods>('/pim/goods', goods)
}

export function editOneGoods(goods: EditGoods): Promise<AxiosResponse<Goods>> {
  return api.put<Goods>(`/pim/goods/${goods.id}`, goods)
}

const downloadTemplateFile = async () => (
  api.get<File>('/pim/ops/goods', {
    responseType: 'blob',
  })
)

export const useDownloadTemplateFileMutation = () => useMutation(
  downloadTemplateFile,
)

export type GoodsResponseMessageType = 'info' | 'success' | 'error'

const uploadGoodsFile = async ( queryParams: { file: File }) => {
  const body = new FormData()
  body.append('file', queryParams.file)

  return api.post<pimApi.products.ImportMetadata>(
    '/pim/ops/goods',
    body,
  ).then((res)=> res.data)
}

export const useUploadGoodsFile = (
  options?: UseMutationOptions<
  pimApi.products.ImportMetadata,
  errorType,
  { file: File }
  >
) => useMutation(
  uploadGoodsFile,
  options,
)

interface DeleteGoodsPayload {
  goodsId: string,
  data: {
    id: string,
    versionNo?: number,
    comments?: string
  }
}

export const deleteGoods = ({
  goodsId,
  data
}: DeleteGoodsPayload) =>
  api.delete<pimApi.goods.GoodsComponent>(
    `/pim/goods/${goodsId}`,
    { 
      data
    }
  )

export const useDeleteGoodsMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    DeleteGoodsPayload
  >(deleteGoods)
