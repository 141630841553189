// @ts-nocheck

import React, { useCallback } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table as TableMaterial,
  TableContainer,
} from '@mui/material'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useHistory } from 'react-router'

import Pagination from './Pagination'
import { Preloader } from './Preloader'

const TableMaterialStyled = styled(TableMaterial)`
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
`
const PaginationSettings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;

  .MuiFormControl-root {
    width: 97px;
  }
`
const PaginationInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`

const FooterWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 30px;
`
const SelectStyled = styled(Select)`
  .MuiSelect-iconOutlined {
    right: 5px;
  }
`

interface TableContainerEcomI {
  isLoading?: boolean,
  tableList: Array<any>,
  bonusCols: number,
  renderTableFooter?: any,
  children: React.ReactNode,
  calculatedRowsPerPage?: number;
}

const ROWS_PER_RAGE = [
  10,
  20,
  50,
  100
]

function getRowsPerPageOptions(dataLength: number, calculatedRowsPerPage?: number): Array<number> {
  function getExtraRowsPerPageOptions() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ROWS_PER_RAGE.length; i++) {
      const count = ROWS_PER_RAGE[i]
  
      if (dataLength <= count) {
        return ROWS_PER_RAGE.slice(0, i + 1)
      } 
    }
  
    return ROWS_PER_RAGE
  }

  const maxPerPage = getExtraRowsPerPageOptions()

  if (calculatedRowsPerPage) {
    maxPerPage.unshift(calculatedRowsPerPage)
  }

  return [...new Set(maxPerPage.sort((a, b) => a - b))] 
}

export const TableContainerEcom = ({
  children,
  calculatedRowsPerPage,
  isLoading = false,
  tableList,
  bonusCols,
  renderTableFooter
}: TableContainerEcomI) => {
  const renderFooterDefault = useCallback((Component) => <Component />, [])
  const renderFooter = renderTableFooter || renderFooterDefault
  const { search } = useLocation()
  const history = useHistory()
  const searchObj: any = queryString.parse(search)

  const page = parseInt(searchObj.page || '1', 10)
  const rowsPerPageCounts = getRowsPerPageOptions(tableList.length, calculatedRowsPerPage)
  const initialCols: number = calculatedRowsPerPage || rowsPerPageCounts[0]

  const rowsPerPage = parseInt(searchObj.limit, 10) ? parseInt(searchObj.limit, 10) : initialCols
  const initialValues = {
    ...searchObj,
  }

  const setLimit = (limit: number) => {
    const filterStr = queryString.stringify({
      ...initialValues,
      limit,
      page: 1
    })
    history.push({
      search: `?${filterStr}`,
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setLimit(parseInt(event.target.value as string, 10))
  }

  const currentPageItemsFrom = ((page - 1) * rowsPerPage) + 1
  const currentPageItemsTo = (page - 1) * rowsPerPage + rowsPerPage

  const TableFooter = useCallback(({ width = 100 }) => (
    <Pagination
      count={tableList && Math.ceil(tableList.length / rowsPerPage)}
      page={page}
      shape="rounded"
      width={width}
    >
      <PaginationSettings>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="rows-per-page">Показывать</InputLabel>
          <SelectStyled
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }}
            onChange={handleChangeRowsPerPage}
            value={rowsPerPage}
            label="Показывать"
          >
            {rowsPerPageCounts.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
      </PaginationSettings>
      <PaginationInfo>
        {rowsPerPage} позиций документа
        на странице: {currentPageItemsFrom}-
        {currentPageItemsTo < tableList?.length
          ?
          currentPageItemsTo
          :
          tableList?.length} из {tableList?.length}
      </PaginationInfo>
    </Pagination>
  ), [tableList, rowsPerPage, page, handleChangeRowsPerPage, bonusCols])

  return (
    <div>
      {isLoading ?
        <Preloader />
        :
        <>
          <TableContainer>
            <TableMaterialStyled size="medium">{children}</TableMaterialStyled>
          </TableContainer>
          <FooterWrapper>{renderFooter(TableFooter)}</FooterWrapper>
        </>
      }
    </div>
  )
}