import { tradeGen } from 'shared/lib/generated'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import React, { useEffect, useMemo } from 'react'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { checkValue, isNotNil } from 'shared/lib/checkers'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { TableFilter } from './TableFilter'
import { TableTransitions } from './TableTransitions'
import { DistributionsPlug } from './PlugComponents/DistributionsPlug'
import { ProductsPlug } from './PlugComponents/ProductsPlug'

import { ProductRow } from '../TableComponents/ProductRow'
import { WarehouseHeader } from '../TableComponents/WarehouseHeader'
import { groupByKey } from '../../../SupplyOrdersCoefficient/lib'
import { Header, HeaderSettings, StyledTable } from '../styled'
import { ContextMenu } from '../ContextMenu/ContextMenu'
import { CircularProgressStyled } from '../../../SupplyOrdersCoefficient/ui/styled'
import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'

export const DistributionTable = () => {
  const { invalidateDistributionCache } = useChangeDistribution()
  const { searchObj } = useQueryParams()
  const { supplyOrderQuery } = useSupplyOrderContext()
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const changeDistributionIsLoading = useDistributionStore(state => state.changeDistributionIsLoading)

  const {
    data: distributionData,
    isLoading: distributionIsLoading } =
    tradeGen.orders.Getdistributions.useGetdistributions({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchObj?.searchString) }
    })

  const groupedDistribution = useMemo(() => {
    if (distributionData) {
      const expandedArray: Array<any> = []
      distributionData?.copackingdistributions?.forEach(obj => {
        if (obj.data) expandedArray.push(...obj.data)
      })
      if (isNotEmptyArray(expandedArray)) {
        return groupByKey(expandedArray, 'productId')
      }
    }
    return null
  },[distributionData])

  // useEffect(() => {
  //   if (distributionData?.status?.code === 429) {
  //     invalidateDistributionCache({ supplyOrderQuery })
  //   }
  // }, [distributionData])

  useEffect(() => {
    setSelectedProducts(null)
    invalidateDistributionCache({ supplyOrderQuery })
  },[supplyOrderQuery?.data])

  useEffect(() => () => {
    setSelectedProducts(null)
  }, [])

  return (
    <>
      <Header>Распределение по РЦ</Header>

      {distributionIsLoading || changeDistributionIsLoading ? <CircularProgressStyled size={60} /> : null}
      { ( distributionData) ?
        <>

          {isNotEmptyArray(distributionData.copackingdistributions) ?
            <>
              <HeaderSettings>
                <TableFilter/>
                <TableTransitions/>
              </HeaderSettings>

              <StyledTable $isLoading={changeDistributionIsLoading}>

                { isNotNil(groupedDistribution) ?
                  <div>
                    <div className="table-header">
                      <div className="product-header-cell">Продукт</div>
                      <div className="total-header-cell">Всего</div>
                      { distributionData?.copackingdistributions?.map(distributionWarehouse =>
                        <WarehouseHeader
                          key={ JSON.stringify(distributionWarehouse) }
                          distributionWarehouse={ distributionWarehouse }
                        />
                      ) }
                    </div>

                    <div className="table-body">
                      { Object.keys(groupedDistribution).map(productId =>
                        <ProductRow
                          key={ JSON.stringify(groupedDistribution[productId]) }
                          groupedDistribution={ groupedDistribution }
                          productId={ parseInt(productId, 10) }
                        />) }
                    </div>
                  </div>
                  :
                  <ProductsPlug />
                }

                <ContextMenu />
              </StyledTable>
            </>
            :
            <DistributionsPlug/>
          }
        </>
        :
        <></>
      }
    </>
  )
}