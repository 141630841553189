import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface EditSupplyOrderNumber {
    supplyOrderId: UniqueId,
    data: tradeApi.EditSupplyOrder
}

export const editSupplyOrderNumber = ({ supplyOrderId, data }: EditSupplyOrderNumber) =>
  api.put<tradeApi.SupplyOrder>(
    `/trade/orders/supplyorders/${supplyOrderId}`, data
  )

export const useEditSupplyOrderNumberMutation = () =>
  useMutation<
        AxiosResponse<tradeApi.SupplyOrder>,
        AxiosError<errorType>,
        EditSupplyOrderNumber
        >(editSupplyOrderNumber)