import React from 'react'

import { ProfitabilityContainer } from '../../../../ProfitabilityContainer'

export const ProfitabilitySum = ({ cellValue }) => {
  const profit = cellValue?.profit
  const profitPercent = cellValue?.profitPercent
  
  return (
    <>
      { cellValue ? 
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ProfitabilityContainer profit={profit} profitPercent={profitPercent}/>
        </div>
        :
        null }
    </>
  )
}