import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

interface EditSupplyOrderPayload {
  positionId?: UniqueId,
  supplyOrderId?: UniqueId,
  deletingPosition?: Partial<DeletingObject>
}

export const deleteSupplyOrderPosition = ({ positionId, supplyOrderId, deletingPosition }: EditSupplyOrderPayload) =>
  api.delete(
    `/trade/orders/supplyorders/${supplyOrderId}/productpositions/${positionId}`,
    { data: deletingPosition }
  )

export const useDeleteSupplyOrderPositionMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    EditSupplyOrderPayload
  >(deleteSupplyOrderPosition)