import { StyledLinkName } from './styled'

interface LinkNameProps {
  name: string
  fontSize?: number
  path: string
  isArchived?: boolean
}


export const OrderLinkCell = ({ name, path, isArchived, fontSize }: LinkNameProps) => (
  <StyledLinkName style={{ fontSize: fontSize ? `${fontSize}px` : '' }} to={path} title={name} $isArchived={isArchived}>
    {name}
  </StyledLinkName>
)
