import React from 'react'
import { copackingApi } from 'shared/api'
import { DialogContentWithTitle, dialogModel } from 'shared/ui/components/dialog'
import { COPACKING_ORDERS } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { useHistory } from 'react-router'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQueryClient } from 'react-query'
import { SettingsMenuItem } from 'pages/supplyOrders/edit/ui/Settings/model/types'
import { getSettingsMenuItemByMethod } from 'pages/supplyOrders/edit/ui/Settings/lib/getSettingsMenuByItem'
import { SplitButton } from 'shared/ui/components'
import { downloadFile } from 'shared/lib/utils'

export const OrdersTransitions = ({ order }: { order: copackingApi.CoPackingOrder }) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { mutate: downloadOrderFile, isLoading: downloadingInProgress } = copackingApi.useDownloadCopackingOrderFile()
  const { mutate: downloadExecutionFile, isLoading: downloadingExecutionInProgress } = copackingApi.useDownloadExecutionFile()
  const { mutate: downloadPositionServiceFile, isLoading: downloadPositionServiceInProgress } =
    copackingApi.useDownloadPositionServiceFile()
  const { mutate: deleteOrder } = copackingApi.useDeleteCopackingOrderMutation()
  const { mutate: patchOrder } = copackingApi.usePatchCopackingOrderMutation()

  const deleteCopackingOrder = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <DialogContentWithTitle
          close={close}
          accept={accept}
          title="Вы уверены, что хотите удалить это задание?"
        />
      ),
      onAccept: () => {
        deleteOrder(
          {
            copackingorderId: order.id,
            deletingOrder: {
              id: order.id,
              versionNo: order.versionNo,
            },
          },
          {
            onSuccess: () => {
              history.push(COPACKING_ORDERS)
              dialogModel.closeDialog()
              snackActions.info('Задание успешно удалено!')
            },
          }
        )
      },
    })
  }

  const handleDownloadOrdersFile = () => {
    downloadOrderFile(order.id, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || 'file.xlsx'

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }

  const handleDownloadExecutionFile = () => {
    downloadExecutionFile(order.id, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || 'file.xlsx'

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }

  const handleDownloadPositionServiceFile = () => {
    downloadPositionServiceFile(order.id, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || 'file.xlsx'

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }

  const downloadingOperations: Array<Partial<SettingsMenuItem>> = [
    {
      label: 'Выгрузить в excel',
      handler: handleDownloadOrdersFile
    },
    {
      label: 'Факт выполнения для 1С',
      handler: handleDownloadExecutionFile
    },
    {
      label: 'Отчет о выполнении работ',
      handler: handleDownloadPositionServiceFile
    },
  ]
  
  const copackingOrdersTransitions: Array<Partial<SettingsMenuItem>> = order.transitions ?
    order.transitions.map((method) =>
      getSettingsMenuItemByMethod({
        method: method as any,
        onAccept: (params) => {
          patchOrder(
            {
              copackingorderId: order.id,
              ...(method.action === 'export' && {
                responseType: 'blob'
              }),
              patchBody: {
                action: method.action,
                versionNo: order.versionNo,
                ...(params?.file ? { file: params.file } : {}),
                ...(params?.comments ? { comments: params.comments } : {}),
              },
            },
            {
              onSuccess: (response: any) => {
                if (method.action === 'export') {
                  const file = response.data
                  const fileName =
                    decodeURIComponent(response.headers['content-disposition']?.replace(
                      'inline; filename=',
                      ''
                    ))
                  downloadFile(file, fileName)
                } else {
                  queryClient.setQueryData<copackingApi.CoPackingOrder>(
                    copackingApi.getOrderDetailsQueryKey(order.id), response.data
                  )
                  snackActions.info('Операция выполнена успешно!')
                }
                dialogModel.closeDialog()
              },
              onError: () => {
                dialogModel.setDialogIsLoading({ isLoading: false })
              }
            }
          )
        },
        dialogOptions: {
          stayOnAccept: true
        }
      })
    ) : []
  
  return (
    <>
      <SplitButton
        variant="contained"
        options={downloadingOperations}
        label="ЭКСПОРТ"
        sx={{ marginRight: '16px' }}
        isLoading={
          downloadingInProgress ||
          downloadingExecutionInProgress ||
          downloadPositionServiceInProgress
        }
        size="small"
      />
      { copackingOrdersTransitions.length
        ? <SplitButton
          variant="outlined"
          options={copackingOrdersTransitions}
          label="ДЕЙСТВИЯ"
          size="small"
        />
        : <></>
      }
      {order.deletePossible ?
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={ deleteCopackingOrder }
          size="small"
        >
          УДАЛИТЬ
        </Button>
        :
        <></>
      }
    </>
  )
}