import { Box, Button } from '@mui/material'
import { dialogModel } from 'shared/ui/components/dialog'
import * as React from 'react'
import { Edit, Delete } from '@mui/icons-material'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { pimApi } from 'shared/api'

import { IconStyle, UserName, Dates } from './styled'
import { ManagersModal } from './modals/ManagersModal'


const dateFormat = (date) => format(new Date(date), DateFormats.dayMonthYearHoursMinutes)

export const ManagersSupplierForm = ({ 
  handleUserAdd, 
  handleUserDelete,
  handleUserChange,
  values,
  inArchive
}) => {

  const selectedManagers = values.users


  const handleOpenCreateManagerModalClick = (manager?: pimApi.admin.ContragentUser) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <ManagersModal
          handleUserAdd={handleUserAdd}
          handleUserChange={handleUserChange}
          selectedManagers={selectedManagers}
          manager={manager}
          close={close}
        />
      )
    })
  }

  const UserItem = ({ usersData }) => {

    const isShowStartDate = new Date(usersData.dateIn) > new Date()

    return (
      <Box mb={2}>
        <UserName>
          {usersData.userName}
          <IconStyle inArchive={inArchive}>
            <Edit
              onClick={() => handleOpenCreateManagerModalClick(usersData)}
              sx={{ marginLeft: 'auto' }}
            />
            <Delete
              onClick={() => handleUserDelete(usersData)}
              sx={{ marginLeft: '8px' }}
            />
          </IconStyle>
        </UserName>
        <Dates>
          {
            (usersData.dateIn && isShowStartDate) &&
              <>
                <span>Начало доступа  </span>
                <span>{dateFormat(usersData.dateIn)}</span>
              </>
          }
          {usersData.dateOut && <>
            <span>Окончание доступа  </span><span>{dateFormat(usersData.dateOut)}</span></>}
        </Dates>
      </Box>
    )
  }

  return (
    <Box p={3} >
      {values.users.map(user => <UserItem key={user.id} usersData={user}/>)}
      <Button
        variant="contained"
        onClick={() => handleOpenCreateManagerModalClick()}
        disabled={inArchive}
      >
        ВЫБРАТЬ МЕНЕДЖЕРА
      </Button>
    </Box>
  )
}

