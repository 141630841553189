import React from 'react'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { StyledTableBody } from './styled'

import { StyledProductsPlug } from '../../../supplyOrders/SupplyOrdersDistribution/ui/styled'

interface ITableBody {
  tableCells: Array<any>
  tableData: Array<any>
  tableRow: any
  settings?: Object
}
export const TableBody = ({ tableCells, tableData, tableRow, settings }:ITableBody) => {
  const Component = tableRow
  return (
    <StyledTableBody>
      {isNotEmptyArray(tableData) ?
        <>
          { tableData.map(el => (
            <Component key={JSON.stringify(el)} row={el} tableCells={tableCells} settings={settings} />
          )) }
        </>
        :
        <StyledProductsPlug>
          Нет данных
        </StyledProductsPlug>
      }
    </StyledTableBody>
  )
}