import { useEffect, useState } from 'react'
import { highlight } from 'shared/lib/transform/highlightText'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { TreeView } from '@mui/x-tree-view/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { TreeViewWrapper } from './styled'
import { TreeItemLabel } from './TreeItemLabel'

import { checkElement } from '../../../../lib/expandNestedPrivileges'
import { flatPrivileges } from '../../../../lib/filterPrivileges'

export const PrivilegesTree = ({ data, searchString, setSearchString, filterByChecked }) => {
  const [ path, setPath ] = useState<Array<any>>([])

  const handleNodeToggle = (_, nodeIds) => {
    setPath(nodeIds)
  }

  useEffect(() => {
    if (searchString.length !== 0 || filterByChecked) {
      setPath(flatPrivileges(data))
    } else {
      setPath([])
    }
    highlight(searchString,'privileges-title')
  },[searchString, filterByChecked])

  return (
    <TreeViewWrapper>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        onKeyPress={ (e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        } }
        value={ searchString }
        onChange={ (e) => {
          setSearchString(e.target.value)
        } }
        InputProps={ {
          endAdornment: <SearchIcon sx={ { color: 'rgba(0, 0, 0, 0.26)' } } />
        } } />
      <div className="header">
        <span>Наименование</span>
        <span>Доступ</span>
        <span>Открыт</span>
        <span>Закрыт</span>
      </div>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={ <ExpandMoreIcon /> }
        defaultExpandIcon={ <ChevronRightIcon /> }
        expanded={path}
        onNodeToggle={handleNodeToggle}
      >
        { data?.map(el => renderTree(el)) }
      </TreeView>
    </TreeViewWrapper>
  )
}

const renderTree = (nodes) => {
  const defineFolder = (elem) => {
    if (checkElement(elem.groups) && checkElement(elem.privileges)) {
      return elem.groups.concat(elem.privileges).map(el => renderTree(el))
    }
    if (checkElement(elem.groups) || checkElement(elem.privileges)) {
      if (checkElement(elem.groups)) {
        return elem.groups.map(el => renderTree(el))
      }
      if (checkElement(elem.privileges)) {
        return elem.privileges.map(el => renderTree(el))
      }
    }
    return null
  }

  return (
    <TreeItem
      key={ nodes?.id ? nodes.id.toString() : nodes.name }
      nodeId={ nodes?.id ? nodes.id.toString() : nodes.name }
      label={<TreeItemLabel node={nodes}/>}>
      { defineFolder(nodes) }
    </TreeItem>
  )
}