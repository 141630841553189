import { productModel } from 'entities/pim/product'
import { pimApi } from 'shared/api'

export const FormValuesToEditSimpleProductComparatorSerializer = (
  {
    // поля, которые не нужны
    price,
    futurePrices,
    priceRecentHistory,
    versionNo,
    // конец полей, которые не нужны
    ...formValues
  }: productModel.simpleModel.EditSubmitFormValues
): Partial<pimApi.products.EditSimpleProduct> => ({
  ...formValues,
})
