/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyReviewEditAnswerReview, Review } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Review>

export type IRequest = CamelCasedPropertiesDeep<{
  reviewId: number,
  answerId: number,
  data: BodyReviewEditAnswerReview
}>

export const apiFunction = ({ reviewId,answerId,data }: IRequest) =>
  api.put<ResponseType>(`/review/review/${reviewId}/answer/${answerId}`,data).then(res => res.data)


export const useEditAnswerReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

