import styled from 'styled-components'
import { Toolbar, Grid } from '@mui/material'
import { ChipEcom } from 'shared/ui/components'

export const TableWrapper = styled.div`
  margin-bottom: 36px;
  border: 1px solid rgba(224,224,224,1);
  border-radius: 4px;
  overflow: hidden;
`

export const Wrapper = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`

export const ProductImg = styled.img`
  max-width: 70px;
  max-height: 50px;
`

export const ImgWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 50px;
  align-items: center;
  min-height: 50px;
`

export const ChangeStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const GoodInfo = styled.span`
  display: inline-flex;
  width: 15px;
  height: 15px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  border: 1px solid #007DFF;
  color: #007DFF;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
`
export const Or = styled.div`
  font-size: 14px;
  margin: 0 23px;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

export const Index = styled.p`
  text-align: center;
`

export const ToolbarStyled = styled(Toolbar)`
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

export const StyledInfoGrid = styled(Grid)`
  flex-grow: 1;
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledStatus = styled(ChipEcom)`
  width: 46px;
  height: 40px;
  border-radius: 4px;
  padding-left: 12px;
`

export const Difference = styled.span`
  display: inline-block;
  width: 100%;
  position: relative;
`