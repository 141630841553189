import { QueryFunctionContext, useMutation, useQuery, UseQueryOptions } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'


export interface GetPriceHistoryParams {
  productId?: UniqueId
  fromDate?: string,
  toDate?: string
}

export const getPriceHistoryQueryKey = (
  queryParams: GetPriceHistoryParams
) =>
  [
    'get',
    `/pim/products/simple/product/${queryParams.productId}/pricehistory`,
    queryParams
  ] as const

type PriceHistoryQueryKey = ReturnType<typeof getPriceHistoryQueryKey>


function getPriceHistoryFunc (queryParams: GetPriceHistoryParams) {
  const {
    productId,
    fromDate,
    toDate
  } = queryParams
  return api
    .get<pimApi.products.SimpleProductPriceHistory>(
      `/pim/products/simple/product/${productId}/pricehistory?from_date=${fromDate}${
        toDate ? `&to_date=${toDate}` : ''
      }`,
    )
    .then((res) => res.data)
}

export const getPriceHistory = (context: QueryFunctionContext<PriceHistoryQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getPriceHistoryFunc(queryParams)
}

export const useFetchPriceHistoryQuery = (
  params: {
    productId: UniqueId,
    fromDate?: string,
    toDate?: string,
  },
  options?: UseQueryOptions<
    pimApi.products.SimpleProductPriceHistory,
    AxiosError<errorType>,
    pimApi.products.SimpleProductPriceHistory,
    PriceHistoryQueryKey
    >
) =>
  useQuery<
    pimApi.products.SimpleProductPriceHistory,
    AxiosError<errorType>,
    pimApi.products.SimpleProductPriceHistory,
    PriceHistoryQueryKey
    >(
      getPriceHistoryQueryKey(params),
      getPriceHistory,
      options
    )

interface CreateProductPriceChangePayload {
  productId: number
  newProductPrice: pimApi.products.SetSimpleProductPriceInfo
}

export const createProductPriceChange = ({
  productId,
  newProductPrice,
}: CreateProductPriceChangePayload) =>
  api.post<pimApi.products.SimpleProduct>(
    `/pim/products/simple/product/${productId}/price`,
    newProductPrice
  )

export const useCreateProductPriceChangeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.products.SimpleProduct>,
    AxiosError<errorType>,
    CreateProductPriceChangePayload
    >(createProductPriceChange)

interface DeleteProductPricePayload {
  productId: number
  priceId?: number
  data: {
    id: string
    versionNo?: number
    comments?: string
  }
}

export const deleteProductPrice = ({
  productId,
  priceId,
  data,
}: DeleteProductPricePayload) =>
  api.delete<void>(
    `/pim/products/simple/product/${productId}/price/${priceId}`,
    {
      data,
    }
  )

export const useDeleteProductPriceMutation = () =>
  useMutation<
    AxiosResponse<void>,
    AxiosError<errorType>,
    DeleteProductPricePayload
    >(deleteProductPrice)
