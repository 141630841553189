import React, { useState, useEffect } from 'react'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import isNil from 'lodash/isNil'

const checkToEqualValue = (
  option: Option<string | number>,
  value: Option<string | number>
) => option.value === value.value

type SelectProps = FieldRenderProps<string | number> & {
  options: Option<string | number>[]
  selectSingleOption?: boolean
}

export const SelectFieldWithAutoComplete = ({
  input,
  onChange,
  options,
  isLoading,
  disabled,
  label,
  error,
  selectSingleOption = true,
  placeholder,
  id,
  fullWidth,
  meta: { invalid, touched },
  input: { onBlur, onFocus },
  disableClearable,
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<null | Option<
    string | number
  >>(null)
  const [selectedInputValue, setSelectedInputValue] = useState<string>(
    selectedValue?.label || ''
  )

  const handleChange = (_, value: Option<string | number> | null) => {
    setSelectedValue(value)
    setSelectedInputValue(value?.label || '')
    input.onChange(value?.value)
    onChange?.(value)
  }


  useEffect(() => {
    if (options !== undefined) {
      if (!isNil(input.value) && input.value !== '') {
        const newSelectedElement = options.find(
          (item) => item.value === input.value
        )
        if (!isNil(newSelectedElement)) {
          handleChange(null, newSelectedElement)
          return
        }
      }
      if (options.length === 1 && selectSingleOption) {
        handleChange(null, options[0])
      } else {
        handleChange(null, null)
      }
    }
  }, [options, input.value])

  return (
    <Autocomplete<Option<string | number>>
      id={id}
      onBlur={onBlur}
      onFocus={onFocus}
      options={options}
      fullWidth={fullWidth}
      size="small"
      noOptionsText="Не найдено"
      clearOnBlur={false}
      blurOnSelect={true}
      autoHighlight={true}
      value={selectedValue}
      disabled={disabled}
      onChange={handleChange}
      isOptionEqualToValue={checkToEqualValue}
      disableClearable={disableClearable}
      inputValue={selectedInputValue}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.value}>
          {option.label}
        </Box>
      )}
      onInputChange={(_, value) => {
        setSelectedInputValue(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || (invalid && touched)}
          label={label}
          placeholder={placeholder || 'Введите текст'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
