import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface EditMarketplaceWarehousePayload {
  marketplaceId: UniqueId,
  warehouseId: UniqueId
  editedWarehouse: pimApi.admin.EditMarketplaceWarehouse
}
export const editWarehouse = ({ marketplaceId,warehouseId, editedWarehouse }: EditMarketplaceWarehousePayload) =>
  api.put<pimApi.admin.CurrentContragent>(
    `/pim/admin/marketplaces/${marketplaceId}/marketplacewarehouses/${warehouseId}`,
    editedWarehouse
  )


export const useEditMarketplaceWarehouseMutation = () =>
  useMutation<
    AxiosResponse<pimApi.admin.MarketplaceWarehouse>,
    AxiosError<errorType>,
    EditMarketplaceWarehousePayload
  >(editWarehouse)
