import { Box, TextField } from '@mui/material'
import styled from 'styled-components'
import { Field } from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { pimApi } from 'shared/api'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'

import { DisplayedGoodsDataController } from '../../../../../../model/DisplayedGoodsDataController'
import { MemoECOMMarketplace } from '../ECOMWB'

const Text = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  background-color: white;
  padding: 0 5px;
  position: absolute;
  top: -9px;
  right: 8px;
`

export function Description({ isArchived }: { isArchived?: boolean }) {
  const goods = useGoodsContext() as pimApi.goods.Goods
  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()
  return (
    <>
      <MemoECOMMarketplace
        changeDisplayedGoodsData={changeDisplayedGoodsData('description')}
        leftSideTooltip="Описание товара, к которому привязан продукт"
        displayGoodsData={displayedGoodsData.description}
      />
      <InputsWrapper
        hideGoodsInfo={!displayedGoodsData.description}
        containerSx={{ alignItems: 'start' }}
        globalAttributeName="descriptionGlobal">
        <Box position="relative">
          <TextField
            label="Описание"
            variant="outlined"
            name="description1"
            size="small"
            fullWidth={true}
            value={goods?.description ?? ''}
            disabled={true}
            multiline={true}
            minRows={7}
          />
          <Text>Символов: {goods?.description?.length ?? 0}</Text>
        </Box>
        <Box width="100%" position="relative">
          <Field
            name="description"
            label="Описание"
            render={({ input, meta }) => (
              <>
                <TextField
                  label="Описание"
                  variant="outlined"
                  size="small"
                  {...input}
                  onChange={input.onChange}
                  value={input.value}
                  fullWidth={true}
                  multiline={true}
                  minRows={7}
                  error={meta.invalid}
                  disabled={isArchived}
                  inputProps={{
                    maxLength: 4000
                  }}
                />
                <Text>Символов: {input.value.length}/4000</Text>
              </>
            )}
          />
        </Box>
      </InputsWrapper>
    </>
  )
}
