import { Field as FinalFormField, FieldProps, FieldRenderProps, useField } from 'react-final-form'

interface CustomFieldProps {
  errorAfterTouch?: boolean
  onSelect?: (value: string) => void
}

// хак https://github.com/marmelab/react-admin/issues/3551
const identity = (value) => value

export function Field<
FieldValue = any,
T extends HTMLElement = HTMLElement,
InputValue = FieldValue,
RP extends FieldRenderProps<FieldValue, T, InputValue> = FieldRenderProps<
    FieldValue,
    T,
    InputValue
  >,
> ({ errorAfterTouch, ...props }: FieldProps<FieldValue, RP, T, InputValue> & CustomFieldProps) {
  const { name } = props
  const { meta } = useField(name)

  const error = meta.invalid && (errorAfterTouch ? meta.touched : true)

  return (
    <FinalFormField<FieldValue, T, InputValue, RP>
      parse={identity}
      {...props}
      error={error}
    />
  )
}
