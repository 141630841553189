import {  useParams } from 'react-router-dom'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { Table } from 'shared/ui/components/Table'
import { customerGen, pimGen } from 'shared/lib/generated'
import { useMemo } from 'react'
import { isNil } from 'shared/lib/checkers'

import { columnsConfig } from './columnsConfig'
import { StyledTableBox } from './styled'

import { UserAccessModal } from '../../../modals/UserAccessModal'
import { AccessHeader } from '../../AccessHeader'

const getRowId = (row) => row.id

export const UsersContragentsTable = ({ userData }: { userData: customerGen.administration.GetUserInfo.ResponseType }) => {
  const { id } = useParams<{ id: string }>()
  const userId = parseInt(id, 10)
  const queryClient = useQueryClient()

  const { mutate: deleteUserContragent } =
      pimGen.administration.DeleteUserContragent.useDeleteUserContragent()

  const { mutate: addUserContragent } =
      pimGen.administration.CreateUserContragent.useCreateUserContragent()

  const { mutate: editUserContragent } =
    pimGen.administration.UpdateUserContragent.useUpdateUserContragent()

  const { data: contragentsData } = pimGen.administration.GetContragents.useGetContragents()
  const { data: userContragentsData } = pimGen.administration.GetUserContragents.useGetUserContragents({ userId })
  
  const handleContragentDelete = (contragentData) => {
    if (contragentData.contragentuserId) {
      deleteUserContragent(
        {
          contragentuserId: contragentData.contragentuserId,
          userId: userData.id,
          data: {
            id: contragentData.contragentuserId,
            versionNo: contragentData.contragentuserVersionNo,
            comments: '',
          },
        },
        {
          onSuccess: () => {
            queryClient.setQueryData<pimGen.administration.GetUserContragents.ResponseType>(
              pimGen.administration.GetUserContragents.getQueryKey({ userId: userData.id }),
              (userContragents) => {
                const newObj = userContragents?.filter(
                  (arr) => arr.id !== contragentData.contragentuserId
                )
                return newObj as pimGen.administration.GetUserContragents.ResponseType
              }
            )
            snackActions.info('Поставщик успешно удален')
          },
        }
      )
    }
  }
  
  const handleContragentAdd = (contragentData: { id: number, dateIn?: string, dateOut?: string }) => {
    if (contragentData) {
      addUserContragent(
        {
          userId: userData.id,
          data: {
            dateIn: contragentData.dateIn,
            dateOut: contragentData.dateOut,
            contragentId: contragentData.id
          }
        },
        {
          onSuccess: (response) => {

            queryClient.setQueryData<pimGen.administration.GetUserContragents.ResponseType>(
              pimGen.administration.GetUserContragents.getQueryKey({ userId: userData.id }),
              (userContragents) => userContragents?.concat([response]) as pimGen.administration.GetUserContragents.ResponseType
            )
            snackActions.info('Поставщик успешно добавлен')
          },
        }
      )
    }
  }

  const handleContragentChange = (contragentData) => {
    if (contragentData) {
      editUserContragent(
        {
          userId: userData.id,
          contragentuserId: contragentData.contragentuserId,
          data: {
            id: contragentData.contragentuserId,
            versionNo: contragentData.contragentuserVersionNo,
            dateOut: contragentData.dateOut,
          },
        },
        {
          onSuccess: (response) => {
            queryClient.setQueryData<pimGen.administration.GetUserContragents.ResponseType>(
              pimGen.administration.GetUserContragents.getQueryKey({ userId: userData.id }),
              (userContragents) => {
                const newObj = userContragents?.map((item) =>
                  item.id === contragentData.contragentuserId ? response : item
                )
                return newObj as pimGen.administration.GetUserContragents.ResponseType
              }
            )
            snackActions.info('Изменение сохранено')
          },
        }
      )
    }
  }

  const sortedContragents: any = useMemo(() => {
    if (contragentsData && userContragentsData) {
      
      const selectedContragents = contragentsData.map(item => {
        const userContragent = userContragentsData.find(el => el.contragentName === item.shortName)

        if (isNil(userContragent)) {
          return { ...item, selected: false }
        }
        return {
          ...item,
          selected: true,
          dateIn: userContragent.dateIn,
          dateOut: userContragent.dateOut,
          contragentuserId: userContragent.id,
          contragentuserVersionNo: userContragent.versionNo
        }
      })

      return selectedContragents.sort((a, b) => {
        const selectedA = a.selected
        const selectedB = b.selected

        if (selectedA && !selectedB) {
          return -1
        } if (!selectedA && selectedB) {
          return 1
        }
        return 0
      })
    }
    return null
  }, [ contragentsData, userContragentsData ])

  const handleOpenCreateManagerModalClick = (category, contragentData) => {
    dialogModel.openDialog({
      component: ({ close }) =>
        <UserAccessModal
          close={close}
          name={contragentData.shortName}
          manager={contragentData}
          category={category}
          handleUserAdd={handleContragentAdd}
        />
    })
  }

  const onRowSelect = (row) => {
    if (userContragentsData?.map(el => el.contragentName).includes(row.shortName)) {
      handleContragentDelete(row)
    } else {
      handleOpenCreateManagerModalClick('поставщику',row)
    }
  }

  const onModalOpen = (row) => {
    const contragentuserData = sortedContragents?.find(el => el.contragentuserId === row.contragentuserId)

    dialogModel.openDialog({
      component: ({ close }) => (
        <UserAccessModal
          close={close}
          manager={contragentuserData}
          name={contragentuserData.shortName}
          category="поставщику"
          handleUserChange={handleContragentChange}
        />
      ),
    })
  }

  const selectedCondition = (row) => row.selected

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: 0,
      page: 0
    }),
    [sortedContragents]
  )

  return (
    <>
      <AccessHeader title="Поставщики" subtitle="Управление правами доступа к поставщикам товаров"/>
      <StyledTableBox disabled={!!userData.dateOut}>

        { sortedContragents &&
          <Table
            tableList={sortedContragents}
            totalCount={sortedContragents.length}
            getRowId={getRowId}
            onModalOpen={onModalOpen}
            rowSelection={{ selectedCondition, onRowSelect }}
            tableParams={memoTableParams}
            columnsConfig={columnsConfig}
            searching="internal"
            sorting="internal"
            filtering="internal"
          />
        }
      </StyledTableBox>
    </>
  )
}
