import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { FormControlLabel, Checkbox, Grid } from '@mui/material'
import { Field, InputField } from 'shared/ui/components/form'
import { InputLabel } from 'shared/ui/components'

import { MemoGoodsSearchSection } from './GoodsSearchSection'

export const CreatePackForm = () => {
  const [autoGenerateCode, setAutoGenerateCode] = useState<boolean>(true)
  const packGoodsField = useField('packGoods')
  const packGoods = packGoodsField.input.value
  const codeField = useField('code')
  const auxCodeField = useField('auxCode')

  const handleAutoGenerateCode = () => {
    if(!autoGenerateCode) {
      codeField.input.onChange(`${packGoods[0]?.code}/S${packGoods[0]?.quantity}`)
      auxCodeField.input.onChange(packGoods[0]?.auxCode)
    }
    setAutoGenerateCode(!autoGenerateCode)
  }

  useEffect(() => {
    if(autoGenerateCode && codeField.input && packGoods && packGoods.length > 0) {
      codeField.input.onChange(`${packGoods[0]?.code}/S${packGoods[0]?.quantity}`)
      auxCodeField.input.onChange(packGoods[0]?.auxCode)
    }
    if(packGoods.length === 0) {
      codeField.input.onChange('')
      auxCodeField.input.onChange('')
    }
  }, [packGoods])

  return (
    <div>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <MemoGoodsSearchSection />
        </Grid>
        {(packGoods.length > 0) && (
          <>
            <Grid item={true} xs={12}>
              <InputLabel single={true} label="Артикулы спайки товара" />
            </Grid>
            <Grid item={true} xs={4}>
              <Field
                name="code"
                label="Артикул"
                component={InputField}
                errorAfterTouch={true}
                disabled={autoGenerateCode}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Field
                name="auxCode"
                label="Дополнительный артикул"
                component={InputField}
                disabled={autoGenerateCode}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleAutoGenerateCode}
                    checked={autoGenerateCode}
                    disabled={packGoods?.length === 0}
                  />
                }
                label="Автогенерация артикула"
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}
