import React, { useState } from 'react'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { Grid } from './Grid'
import { SortablePhoto } from './SortablePhoto'
import { Photo } from './Photo'
import { AddButton } from './AddButton'

import { IUploadGallery } from '../lib/types'

export const UploadGallery = ({
  localMedia,
  largeContainer,
  supportedFileExtension,
  onDragChange,
  mediaLimit,
  urlUploadingExist,
  onFileValidate,
  onUploadFile,
  boxesPerRow,
  withMainElement,
  mediaRequirement,
  mediaType,
  addDisabled,
  children
}: IUploadGallery) => {
  const items = localMedia.map(el => JSON.stringify(el))
  const [activeId, setActiveId] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  }), useSensor(TouchSensor))

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext disabled={!onDragChange} items={items} strategy={rectSortingStrategy}>
        <Grid columns={boxesPerRow || 9}>
          {items.map((url, index) => (
            <SortablePhoto
              disabled={!onDragChange}
              largeContainer={largeContainer}
              withMainElement={withMainElement}
              key={url}
              url={url} 
              index={index} 
              items={children}
            />
          ))}
          {!addDisabled &&
            <AddButton
              existingMediaCount={localMedia.length}
              mediaLimit={mediaLimit}
              mediaType={mediaType}
              urlUploadingExist={urlUploadingExist}
              onUploadFile={onUploadFile}
              onFileValidate={onFileValidate}
              mediaRequirement={mediaRequirement}
              supportedFileExtension={supportedFileExtension}
              largeContainer={largeContainer}/>
          }
        </Grid>
      </SortableContext>

      <DragOverlay style={{ pointerEvents: 'none' }} adjustScale={true}>
        {activeId ? (
          <Photo
            isDragging={!activeId}
            largeContainer={largeContainer}
            withMainElement={withMainElement}
            url={activeId}
            index={items.indexOf(activeId)}>
            {children[items.indexOf(activeId)]}
          </Photo>
        ) : null}
      </DragOverlay>
    </DndContext>
  )

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id)
      const newIndex = items.indexOf(over.id)
      onDragChange(items, oldIndex, newIndex)
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }
}
