import { Theme } from '@mui/material'
import styled from 'styled-components'

export const Nav = styled.nav`
  border-radius: 4px;
  overflow: hidden;
  a {
    border-radius: 0px;
  }
  .active {
    background: ${({ theme }: { theme: Theme }) => theme.palette.primary.dark};
  }
`