import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  DialogTitleWithBtn,
  Field,
  FormDateTimePicker,
  InputField,
  SelectFieldWithAutoComplete
} from 'shared/ui/components'
import { Form } from 'react-final-form'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Button, DialogActions } from '@mui/material'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { pimGen } from 'shared/lib/generated'
import { getCalendarReadableDateTime } from 'shared/lib/utils'
import { isNil } from 'shared/lib/checkers'

import { validationSchema } from '../../model/validationSchema'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 685px;
  gap: 24px;

  .MuiDialogTitle-root {
    font-size: 20px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    
    .values-fields-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      
      >div {
        width: calc(50% - 8px);
      }
    }
    
    .MuiDialogActions-root {
      padding: 0;
      height: 36px;
    }
  }
`

export const CreateDocItemModal = ({ close, accept }) => {
  const { data: contragentsData } = pimGen.administration.GetContragents.useGetContragents()

  const contragentsOptions = useMemo(
    () => contragentsData?.map(getOptions) || [],
    [contragentsData]
  )
  const validate = useValidationSchema(validationSchema)

  const toDayDateTime = getCalendarReadableDateTime(new Date())

  const initialValues = {
    supplierDocDate: toDayDateTime,
    docDate: toDayDateTime,
  }

  const handleFormSubmit = (values) => {
    const { ownerId, recipientId, supplierId, ...formValues } = values

    const supplierItem = contragentsData?.find(el => el.id === values.supplierId)
    const recipientItem = contragentsData?.find(el => el.id === values.recipientId)
    const ownerItem = contragentsData?.find(el => el.id === values.ownerId)
    
    const supplierInn = supplierItem?.inn
    const supplierKpp = supplierItem?.kpp
    const recipientInn = recipientItem?.inn
    const recipientKpp = recipientItem?.kpp
    const ownerInn = ownerItem?.inn
    const ownerKpp = ownerItem?.kpp
    
    accept({ ...formValues, supplierInn, supplierKpp, recipientInn, recipientKpp, ownerInn, ownerKpp })
  }


  return (
    <ModalWrapper>
      <DialogTitleWithBtn sx={{ padding: 0 }} onClose={ close }>
        Создать приход на склад
      </DialogTitleWithBtn>

      <Form
        onSubmit={handleFormSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          handleSubmit,
          valid,
          values, form
        }) => {
          const ownerOptions = (values.recipientId && values.supplierId) ?
            contragentsData?.filter(el => el.id === values.recipientId || el.id === values.supplierId).map(getOptions) :
            []

          const onRecipientOptionChange = (value) => {
            form.change('ownerId', value?.value)
          }

          return (
            <div className="form-container">
              <div className="values-fields-wrapper">
                <Field
                  name="supplierDocNumber"
                  label="Номер документа поставщика"
                  component={InputField}
                  isError={false}
                />
                <Field
                  name="docNumber"
                  label="Номер внутреннего документ"
                  component={InputField}
                  isError={false}
                />

                <Field
                  name="supplierDocDate"
                  label="Дата документа поставщика"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={FormDateTimePicker}
                />
                <Field
                  name="docDate"
                  label="Дата создания документа"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={FormDateTimePicker}
                />

                <Field
                  name="supplierId"
                  id="supplierId"
                  placeholder="Отправитель"
                  component={SelectFieldWithAutoComplete}
                  options={contragentsOptions}
                  error={false}
                />

                <Field
                  name="recipientId"
                  id="recipientId"
                  placeholder="Получатель"
                  onChange={onRecipientOptionChange}
                  component={SelectFieldWithAutoComplete}
                  options={contragentsOptions}
                  error={false}
                />

                <Field
                  name="ownerId"
                  id="ownerId"
                  placeholder="Владелец товара"
                  component={SelectFieldWithAutoComplete}
                  disabled={isNil(values.supplierId) || isNil(values.recipientId)}
                  options={ownerOptions}
                />

                <Field
                  name="extraDocNumber"
                  label="Дополнительный номер документа"
                  component={InputField}
                  isError={false}
                />

              </div>

              <DialogActions>
                <Button onClick={close}>Отмена</Button>
                <Button
                  disabled={!valid}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Создать
                </Button>
              </DialogActions>
            </div>
          )}}
      />
    </ModalWrapper>
  )
}