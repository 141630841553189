import { LAYOUT_WIDTH, WMS } from 'shared/config'
import { Layout } from 'shared/ui/layouts'

import { WarehousePage } from './ui'

import { Route } from '../interface'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: false,
    path: WMS,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: WarehousePage,
  }
] as Array<Route>