export const arrOfNum = (value) => {
  if (typeof value === 'string') {
    return [JSON.parse(value)]
  }
  if (typeof value === 'object') {
    return value.map(str => parseInt(str, 10))
  }
  return undefined
}

export const arrOfString = (value) => {
  if (typeof value === 'string') {
    return [value]
  }
  if (typeof value === 'object') {
    return value
  }
  return undefined
}