import { ActivitiesParams } from 'shared/api/activity'
import { ActivitiesType, Activity } from 'shared/api/activity/model'
import React from 'react'

export interface IActivitiesListContext {
  activityParams: ActivitiesParams,
  activityQuery: {
    data: ActivitiesType | undefined
    isFetching: boolean
    isLoading: boolean
  },
  handleAddActivity: () => void
  handleEditParams: (e) => void
  currentRowsPerPage: number
  pageSizes: number[]
}

export interface IActivityContext {
  detailActivity: Activity | undefined
}
export const ActivityContext = React.createContext<IActivityContext | undefined>(undefined)
export const ActivitiesListContext = React.createContext<IActivitiesListContext | undefined>(undefined)