import React, { useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { reportsApi } from 'shared/api'
import styled from 'styled-components'

import { isNotEmptyArray } from '../../../lib/checkers/isNotEmptyArray'

const checkToEqualValue = (option: Option<string | number>, value: Option<string | number>) => (
  option.value === value.value
)

type SelectProps = FieldRenderProps<Array<number>> & {
  options: Option<string | number>[]
}

const StyledChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  
  ::-webkit-scrollbar {
    display: none;
  }

  background-image: /* Shadows */ linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
    /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0)),
  linear-gradient(to left, rgba(0, 0, 0, .25), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 10px 100%, 10px 100%, 5px 100%, 5px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`

const AutocompleteWrapper = styled.div<{ selected: boolean }>`
  .Mui-focused {
    .chips-container {
      display: none;
    }
    & .MuiAutocomplete-input {
      max-width: max-content;
    }
  }
  
  .MuiAutocomplete-inputRoot {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    .MuiAutocomplete-input {
      min-width: 0;
    }
  }
  
  & .MuiAutocomplete-input:focus {
    opacity: 1
  }; 
  & .MuiAutocomplete-input {
    opacity: ${({ selected }) => selected ? '0' : '1'};
    max-width: ${({ selected }) => selected ? 0 : 'max-content'};
  };
`

export const SelectSearchMultipleField = ({
  input,
  onChange,
  label,
  placeholder,
  payload,
  options,
  sx,
  id,
  onFocus,
  onBlur,
  fullWidth,
  meta: { invalid, touched },
  disableClearable
}: SelectProps) => {

  const getOptions = (optionItem): Option<number> => ({
    label: optionItem.value || '',
    value: optionItem.id,
  })

  const paramsListQuery = payload ? reportsApi.useGetParametersListQuery(payload) : { data: [] }

  const defaultOptions = useMemo(
    () => paramsListQuery?.data?.map(getOptions) || [],
    [paramsListQuery?.data]
  )

  const defaultValue = options.filter(el => input.value.includes(el.value as any))

  const [selectedValue, setSelectedValue] = useState<Array<Option<string | number>>>([])

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [options])

  return (
    <AutocompleteWrapper selected={isNotEmptyArray(selectedValue)}>
      <Autocomplete
        id={id}
        onOpen={onFocus}
        multiple={true}
        limitTags={1}
        options={options || defaultOptions}
        fullWidth={fullWidth}
        size="small"
        onBlur={(event) => {
          const events: any = event
          if (events?.nativeEvent?.sourceCapabilities) {
            onBlur(selectedValue?.map(el => el.value))
          }
        }}
        noOptionsText="Не найдено"
        blurOnSelect={true}
        disableCloseOnSelect={false}
        value={selectedValue}
        onChange={(event: any, value) => {
          setSelectedValue(value)
          input.onChange(value?.map(el => el.value))
          onChange?.(value?.map(el => el.value))
          onBlur(value?.map(el => el.value))
        }}
        renderOption={(props: any, option, state) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            {...props}
            key={option.value}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '46px',  }}
          >
            {option.label}
            <Checkbox
              size="small"
              checked={state.selected}
              onClick={(e) => {
                const selectedOptions: Array<any> = selectedValue !== null ? [...selectedValue] : []
                if (state.selected) {
                  setSelectedValue(selectedOptions.filter(el => el.value !== option.value))
                  input.onChange(selectedOptions.filter(el => el.value !== option.value)?.map(el => el.value))
                } else {
                  setSelectedValue(prev => [...prev, option])
                  input.onChange([...selectedValue, option].map(el => el.value))
                }
                e.stopPropagation()
                e.preventDefault()
              }}
            />
          </div>
        )}
        isOptionEqualToValue={checkToEqualValue}
        disableClearable={disableClearable}
        renderInput={(params) => {
          const { InputProps, ...restParams } = params
          const { startAdornment, endAdornment, ...restInputProps } = InputProps
          return (
            <TextField
              { ...restParams }
              error={invalid && touched}
              sx={sx}
              label={label}
              placeholder={placeholder || 'Введите текст'}
              InputProps={{
                ...restInputProps,
                startAdornment: (
                  <StyledChipsContainer className="chips-container">
                    {startAdornment}
                  </StyledChipsContainer>
                ),
                endAdornment: <>{endAdornment}</>
              }}
            />
          )
        }}
      />
    </AutocompleteWrapper>
  )
}