import imagePlaceholder from 'assets/images/defaultGoodImage.svg'
import filePlaceholder from 'assets/images/defaultFileImage.svg'

export const getFileSrc = (file) => {
  if (typeof file === 'string') {
    if (file.includes('youtube.com')) {
      const url = new URL(file)
      return `https://img.youtube.com/vi/${url.searchParams.get('v')}/hqdefault.jpg`
    }
    return file
  }
  if (file?.type?.includes('image') || file?.type?.includes('video')) {
    return URL.createObjectURL(file)
  }
  if (file?.type?.includes('application') || file?.type?.includes('text')) {
    return filePlaceholder
  }
  return imagePlaceholder
}