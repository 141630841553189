import { useParams } from 'react-router-dom'
import { customerGen } from 'shared/lib/generated'
import { EditUser } from 'features/admin/User/EditUser/EditUser'
import { StyledLoader } from 'features/admin/User/EditUser/ui/styled'
import * as React from 'react'

export const EditUserPage = () => {
  const { id } = useParams<{ id: string }>()

  const userId = parseInt(id, 10)
  const { data: userData } =
    customerGen.administration.GetUserInfo.useGetUserInfo({ userId })

  return (
    <>{userData ? <EditUser userData={userData} /> : <StyledLoader size={60} />}</>
  )
}
