import { sort } from './sort'

export const mergeArrayByKey = ( key, ...lists ) => 
  sort(Object.values(
    lists.reduce(
      (idx, list) => {
        if (list) {
          list.forEach((record) => {
            if (idx[record[key]])
            // eslint-disable-next-line no-param-reassign
              idx[record[key]] = Object.assign(idx[record[key]], record)
            else
            // eslint-disable-next-line no-param-reassign
              idx[record[key]] = record
          })
          return idx
        }
        return null
      },
      {}
    )
  ), key, 'desc') as any