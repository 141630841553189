import React from 'react'
import styled from 'styled-components'

const StyledTextWrapper = styled.div`
  font-size: 14px;
  
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
export const SimpleTextCell = ({ text }) => (
  <StyledTextWrapper title={text}>
    <span>{text}</span>
  </StyledTextWrapper>
)