import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { Table } from 'shared/ui/components/Table'
import { customerGen } from 'shared/lib/generated'
import { useMemo } from 'react'
import { isNil } from 'shared/lib/checkers'

import { columnsConfig } from './columnsConfig'
import { StyledTableBox } from './Styled'

import { UserAccessModal } from '../../../modals/UserAccessModal'
import { AccessHeader } from '../../AccessHeader'

const getRowId = (row) => row.id

export const UsersRolesTable = ({ userData }: { userData: customerGen.administration.GetUserInfo.ResponseType }) => {
  const queryClient = useQueryClient()
  const userRoleData = userData.roles

  const { mutate: deleteUserRole } =
    customerGen.administration.DeleteRoleFromUser.useDeleteRoleFromUser()

  const { mutate: addUserRole } =
    customerGen.administration.AddRoleToUser.useAddRoleToUser()

  const { mutate: editUserRole } =
    customerGen.administration.CloseRoleOfUser.useCloseRoleOfUser()

  const { data: rolesData } = customerGen.administration.GetRoles.useGetRoles()

  const handleRoleDelete = (roleData) => {
    if (roleData.userroleId) {
      deleteUserRole(
        {
          userroleId: roleData.userroleId,
          userId: userData.id,
          data: {
            id: roleData.userroleId,
            versionNo: roleData.userroleVersionNo,
            comments: '',
          },
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(
              customerGen.administration.GetUserInfo.getQueryKey({ userId: userData.id }),
            )
            snackActions.info('Роль успешно удалена')
          },
        }
      )
    }
  }

  const handleRoleAdd = (roleData: { id: number, dateIn?: string, dateOut?: string }) => {
    if (roleData) {
      addUserRole(
        {
          userId: userData.id,
          data: {
            dateIn: roleData.dateIn,
            dateOut: roleData.dateOut,
            roleId: roleData.id
          }
        },
        {
          onSuccess: () => {

            queryClient.refetchQueries(
              customerGen.administration.GetUserInfo.getQueryKey({ userId: userData.id }),
            )
            snackActions.info('Роль успешно добавлена')
          },
        }
      )
    }
  }

  const handleRoleChange = (roleData) => {
    if (roleData) {
      editUserRole(
        {
          userId: userData.id,
          userroleId: roleData.userroleId,
          data: {
            id: roleData.userroleId,
            versionNo: roleData.userroleVersionNo,
            dateOut: roleData.dateOut,
          },
        },
        {
          onSuccess: (response) => {
            queryClient.setQueryData<customerGen.administration.GetUserInfo.ResponseType>(
              customerGen.administration.GetUserInfo.getQueryKey({ userId: userData.id }),
              (updater) => 
                ({ 
                  ...updater,
                  roles: updater?.roles?.map(el => {
                    if (el.roleId === roleData.id) {
                      return response
                    } 
                    return el
                  })
                } as customerGen.administration.GetUserInfo.ResponseType)
            )
            snackActions.info('Изменение сохранено')
          },
        }
      )
    }
  }

  const sortedRoles: any = useMemo(() => {
    if (rolesData && userRoleData) {

      const selectedRoles = rolesData.map(item => {
        const userRole = userRoleData.find(el => el.roleId === item.id)

        if (isNil(userRole)) {
          return { ...item, selected: false }
        }
        return {
          ...item,
          selected: true,
          dateIn: userRole.dateIn,
          dateOut: userRole.dateOut,
          userroleId: userRole.id,
          userroleVersionNo: userRole.versionNo
        }
      })

      return selectedRoles.sort((a, b) => {
        const selectedA = a.selected
        const selectedB = b.selected

        if (selectedA && !selectedB) {
          return -1
        } if (!selectedA && selectedB) {
          return 1
        }
        return 0
      })
    }
    return null
  }, [ userRoleData, rolesData ])

  const handleOpenCreateManagerModalClick = (category, roleData) => {
    dialogModel.openDialog({
      component: ({ close }) =>
        <UserAccessModal
          close={close}
          name={roleData.name}
          manager={roleData}
          category={category}
          handleUserAdd={handleRoleAdd}
        />
    })
  }

  const onRowSelect = (row) => {
    if (userRoleData?.map(el => el.roleId).includes(row.id)) {
      handleRoleDelete(row)
    } else {
      handleOpenCreateManagerModalClick('роли',row)
    }
  }

  const onModalOpen = (row) => {
    const userroleData = sortedRoles?.find(el => el.userroleId === row.userroleId)

    dialogModel.openDialog({
      component: ({ close }) => (
        <UserAccessModal
          close={close}
          manager={userroleData}
          name={userroleData.name}
          category="роли"
          handleUserChange={handleRoleChange}
        />
      ),
    })
  }

  const selectedCondition = (row) => row.selected

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: 0,
      page: 0
    }),
    [sortedRoles]
  )

  return (
    <>
      <AccessHeader title="Роли" subtitle="Управление правами доступа к функционалу"/>
      <StyledTableBox isDisabled={!!userData.dateOut}>

        { sortedRoles &&
          <Table
            tableList={sortedRoles}
            totalCount={sortedRoles.length}
            getRowId={getRowId}
            onModalOpen={onModalOpen}
            rowSelection={{ selectedCondition, onRowSelect }}
            tableParams={memoTableParams}
            columnsConfig={columnsConfig}
            sorting="internal"
            filtering="internal"
          />
        }
      </StyledTableBox>
    </>
  )
}
