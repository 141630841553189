import React from 'react'
import styled from 'styled-components'
import { reviewApi } from 'shared/api'
import { ReviewsContainer } from 'features/reviews/ui/styled'

import { DataParamsTypes } from '../../../../../../lib/types'
import { ReviewsInfo } from '../../../../../ReviewsInfo'
import { ReviewsAnswer } from '../../../../../ReviewsAnswer'

interface Props {
  dataParams: DataParamsTypes
  data: Array<reviewApi.Review>
}

const StyledContainer = styled.div`
  .removed{
    transform: translateX(100vw);
  }
  
  > div {
    &:first-child {
      border-top: 1px solid #E0E0E0;
    }
  }
`

export const ListOfReviews = ({ dataParams, data }: Props) => (
  <StyledContainer style={{ display: 'flex', flexDirection: 'column' }}>
    {data.map(item => (
      <ReviewsContainer id={`review-item-${item.id}`} key={item.id}>
        <ReviewsInfo item={item}/>
        <ReviewsAnswer dataParams={dataParams} item={item}/>
      </ReviewsContainer>
    ))}
  </StyledContainer>
)