import styled from 'styled-components'

export const CostBlock = styled.div`
  display: flex;
  border-bottom: 1px solid #E0E0E0;
  padding: 12px 0;
  margin-bottom: 12px;
  .MuiFormControl-root {
    margin-right: 8px;
  }
`

export const Cost = styled.div`
  display: flex;
  align-items: flex-end;
  border-right: 1px solid #E0E0E0;
  padding-right: 8px;
`

export const Incoming = styled.div`
  margin-left: 16px;
  .MuiFormControl-root {
    width: 128px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-right: 1px solid #E0E0E0;
  padding-right: 8px;
`

export const Packing = styled.div`
  margin-left: 16px;
  .MuiInputBase-formControl {
    width: 114px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 297px;
`

export const PackingItem = styled.div`
  margin-right: 16px;
  .MuiInputBase-formControl {
    width: 114px;
  }
  display: flex;
  max-width: 150px;
`
export const Help = styled.span`
  color: rgba(0, 0, 0, 0.26);
  display: inline-flex;
  height: 100%;
  margin: 10px 12px 0 0;
  svg {
    font-size: 17px;
  }
`
export const Title = styled.div`
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
`