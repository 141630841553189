import React from 'react'
import { Tooltip, Zoom } from '@mui/material'
import { isNotNil } from 'shared/lib/checkers'
import { PRODUCT_STATUS_LIST } from 'shared/config/product'

import { AlertMessageStyled, AlertStyled, AlertTitleStyled } from './styled'

interface AlertComponentProps {
  title: string
  message?: string
  status: string
  maxLength?: Boolean
}

const MAX_LENGTH = 80

export const AlertComponent: React.FC<AlertComponentProps> = ({
  title,
  message,
  status,
  maxLength = true,
}) => {
  const { icon: AlertIcon, type } = PRODUCT_STATUS_LIST[status]
  const isIdle = status === 'idle'
  const isManySymbols = message && message.length > MAX_LENGTH
  const hasMessage = isNotNil(message)
  const messagePrepared = message && (isManySymbols ? `${message.slice(0, MAX_LENGTH)}...` : message)

  if (isManySymbols && message && maxLength) {
    return (
      <Tooltip title={message} TransitionComponent={Zoom}>
        <AlertStyled disabled={isIdle} icon={<AlertIcon />} severity={type} $hasMessage={hasMessage}>
          <AlertTitleStyled disabled={isIdle}>{title}</AlertTitleStyled>
          <AlertMessageStyled variant="body2" disabled={isIdle}>
            {messagePrepared}
          </AlertMessageStyled>
        </AlertStyled>
      </Tooltip>
    )
  }

  return (
    <AlertStyled disabled={isIdle} icon={<AlertIcon />} severity={type} $hasMessage={hasMessage}>
      <AlertTitleStyled disabled={isIdle}>{title}</AlertTitleStyled>
      <AlertMessageStyled disabled={isIdle}>
        {message}
      </AlertMessageStyled>
    </AlertStyled>
  )
}

