import { TableHeaderRow as DXTableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import styled from 'styled-components'

import { SortLabel } from './ui/SortLabel'

import { tableHeaderRowLocale } from '../../lib'
import { ColumnsConfigType, SortDirection, TableParams } from '../../model/types'
import { TableHeaderCellComponent } from '../TableHeaderCellComponent'

const TableHeaderRowCell = styled(DXTableHeaderRow.Cell)`
  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`

interface TableHeaderRowProps {
  handleTableParams: (params: Partial<TableParams>) => void
  sort: Array<{
    columnName: string
    direction: SortDirection
  }>
  columnsConfig?: ColumnsConfigType
  filtering?: 'internal' | 'external'
  sorting?: 'internal' | 'external'
}

export const TableHeaderRow = ({
  handleTableParams,
  filtering,
  columnsConfig
}: TableHeaderRowProps) => {
  const externalSorting = filtering && (filtering === 'external')

  const HeaderCell = ({ ...props } : DXTableHeaderRow.ContentProps) => (
    <>
      <TableHeaderCellComponent
        {...props}
        onFilterChange={handleTableParams}
      />
    </>
  )

  const CellComponent = (props: DXTableHeaderRow.CellProps) => {
    const { children, column } = props
    return (
      <TableHeaderRowCell { ...props }>
        {columnsConfig?.config.find(el => el.name === column.name)?.icon}
        {children}
      </TableHeaderRowCell>
    )
  }

  return (
    <DXTableHeaderRow
      messages={tableHeaderRowLocale}
      showSortingControls={true}
      cellComponent={CellComponent}
      sortLabelComponent={SortLabel}
      {...(externalSorting ? {
        contentComponent: HeaderCell
      } : {})}
    />
  )
}
