import React, { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as sanitizeHtml from 'sanitize-html'
import { Typography } from '@mui/material'

const ShowMoreButton = styled(Typography)`
  font-size: 14px;
  color: #007DFF;
  cursor: pointer;
  width: min-content;
`
const DescriptionContainer = styled.div<{ showMore: boolean, contentHeight: number }>`
  width: 60%;
  height: ${({ showMore, contentHeight }) => showMore ? `${contentHeight}px` : '60px'};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  transition: height 0.5s;
  span {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    color: #00000099;
    a {
      text-decoration: none;
      color: #2196F3;
    }
  }
`
export const Description = memo(({ description }: { description: string }) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const content = useRef<any>(null)
  const btn = useRef<any>(null)

  useEffect(() => {
    if (btn) {
      if (content) {
        if (content.current) {
          if (content?.current?.offsetHeight > 60) {
            btn.current.style.display = ''
          } else {
            btn.current.style.display = 'none'
          }
        }
      }
    }
  },[content])

  const sanitizedText = sanitizeHtml(description, {
    allowedTags: [ 'a' ],
    allowedAttributes: {
      'a': [ 'href', 'class', 'target' ]
    },
  })
  return (
    <>
      <DescriptionContainer contentHeight={content?.current?.offsetHeight} showMore={showMore}>
        {/* eslint-disable-next-line react/no-danger */}
        <span ref={content} dangerouslySetInnerHTML={{ __html: sanitizedText }}/>
      </DescriptionContainer>
      <ShowMoreButton
        ref={btn}
        onClick={() => {
          setShowMore(prevState => !prevState)
        }}>
        {showMore ? 'Скрыть' : 'Подробнее'}
      </ShowMoreButton>
    </>
  )
}
)