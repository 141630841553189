import styled from 'styled-components'

export const StyledChartContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  #data-params-box {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    span {
      font-size: 16px;
      color: #00000099;
    }
  }
`

export const StyledTooltip = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 30px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
`