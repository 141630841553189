import React, { useState } from 'react'
import { copackingApi } from 'shared/api'
import styled from 'styled-components'
import { thousandthValues } from 'shared/lib/utils'

import { EditQuantityStyle } from '../EditQuantity/styled'

interface EditQuantityPropsType {
  value?: number
  row?: copackingApi.CoPackingOrderPosition
  isLoading?: boolean
  onCellChange?: (changes) => void
  isTableEditable?: boolean
}

export const EditableInput = styled.input`
  font-family: "Ubuntu Mono", monospace;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 72px;
  font-size: 14px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
`
export const PositionsEditQuantity = ({ value, row, isLoading, onCellChange, isTableEditable }: EditQuantityPropsType) => {
  const reg = new RegExp('^[0-9]+$')
  const [inputValue, setInputValue] = useState<any>(value)
  const MAX_QUANTITY = 99999

  const onChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = event.target.value.replace(/\s/g, '')

    if ((reg.test(unmaskedValue) || unmaskedValue === '') && Number(unmaskedValue) <= MAX_QUANTITY) {
      setInputValue(Number(unmaskedValue))
    }
  }
  
  const handleQuantityChange = (event) => {
    const newValue = Number(event.target.value.replace(/\s/g, ''))

    if (onCellChange) {
      if (Number(value) !== newValue) {
        onCellChange({ ...row, quantity: newValue })
      }
    }
  }
  return (
    <EditQuantityStyle isLoading={ isLoading }>
      <EditableInput
        onFocus={ (e) => e.target.select() }
        onChange={ onChange }
        disabled={!isTableEditable}
        value={ thousandthValues(inputValue) }
        onBlur={ handleQuantityChange } />
    </EditQuantityStyle>
  )
}