import { Plugin } from '@devexpress/dx-react-core'
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid'

export const TableFiltering = ({ filtering }) => (
  <>
    {(filtering && filtering === 'internal') && (
      <Plugin>
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />    
      </Plugin>
    )}
  </>
  
)