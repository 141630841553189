import { Autocomplete, Button, TextField } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { isArray } from 'lodash'

export const CharacteristicsTab = ({ goodsInfo, handleDownload }) => (
  <div className="tab-content-wrapper">
    <div className="download-main-wrapper">
      <span>Характеристики</span>
      <Button
        onClick={handleDownload}
        variant="outlined"
        startIcon={<DownloadIcon />}
      >
        Скачать
      </Button>
    </div>
    <div className="tab-field">
      {goodsInfo?.attributes.map(({ name, value, attribute_id }) =>
        isArray(value) ? (
          <div>
            <span>{ name }</span>
            <Autocomplete
              fullWidth={ true }
              key={ attribute_id }
              className="chips-autocomplete"
              multiple={ true }
              value={ value }
              options={ [] }
              readOnly={ true }
              renderInput={ (params) => (
                <TextField { ...params } />
              ) }
            />
          </div>
        ) : (
          <div key={attribute_id}>
            <span>{name}</span>
            <TextField
              className="styled-text-field"
              size="small"
              value={value}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        )
      )}
    </div>
  </div>
)