import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnNameKey = keyof tradeApi.GoodsOfProduct
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  goodsCode: 'goodsCode',
  goodsId: 'goodsId',
  goodsName: 'goodsName',
  quantity: 'quantity',
}

interface NestedColumnsConfigProps {
  path: string
  config: Array<ColumnConfig<ColumnNameKey>>
}

export const nestedColumnsConfig: NestedColumnsConfigProps = {
  path: 'goods',
  config: [
    {
      name: columns.goodsCode,
      columnName: columns.goodsCode,
      title: 'Артикул',
      width: 114,
      sortingEnabled: false,
    },
    {
      name: columns.goodsName,
      columnName: columns.goodsName,
      title: 'Наименование',
      width: 'auto',
      sortingEnabled: true,
      wordWrapEnabled: true,
      cellType: CellType.NameLinkBlank,
      isSingleString: true
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'Количество продукта',
      width: 82,
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
  ],
}
