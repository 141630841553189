import React from 'react'

import { Link } from '../MarketplaceLinkCell/styled'

export const MarketplaceWithNm = ({ row }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Link
      style={{ whiteSpace: 'inherit', width: 'min-content' }}
      href={row.marketplaceUrl}
      target="_blank"
      rel="noreferrer"
    >
      {row.marketplace}
    </Link>
    <span>{row.sku}</span>
  </div>
)