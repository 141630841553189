export const copyTextFromElem = (elemId: string) => {
  // @ts-ignore
  const range = document.createRange()
  // @ts-ignore
  range.selectNode(document.getElementById(elemId))
  // @ts-ignore
  window.getSelection().removeAllRanges()
  // @ts-ignore
  window.getSelection().addRange(range)
  document.execCommand('copy')
  // @ts-ignore
  window.getSelection().removeAllRanges()
}