import React from 'react'
import noRemainPlugIco from 'assets/images/noRemainPlugIco.svg'
import { Typography } from '@mui/material'
import styled from 'styled-components'

export const NoRemainIco = styled.img`
  margin-bottom: 24px;
  height: 160px;
`

NoRemainIco.defaultProps = {
  src: noRemainPlugIco,
  alt: 'no remain'
}

export const PlugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  height: calc(100% - 80px);
  text-align: center;
`

export const Caption = styled(Typography)`
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
`

const NoRemainsPlug = () => (
  <PlugWrapper>
    <NoRemainIco />
    <Typography fontSize={16} mb={1}>
      Остатков нет
    </Typography>
    <Caption>
      В поставке нет товаров,<br/>не относящихся к продуктам
    </Caption>
  </PlugWrapper>
)

export default NoRemainsPlug