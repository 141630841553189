import {
  EditGoodsSupplierPackaging,
  Goods,
  GoodsSupplier,
  GoodsSupplierPackaging,
  NewGoodsSupplierPackaging,
} from 'shared/api/pim/goods'
import { useQueryClient } from 'react-query'
import * as GoodsService from 'shared/api/pim/goods'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'

type GoodsWithSupplier = Omit<Goods, 'suppliers'> & {
  suppliers: Array<GoodsSupplier>
}

export const useGoodsPackaging = () => {
  const queryClient = useQueryClient()

  function changeGoodsEditData(
    productId: UniqueId,
    getUpdatedGoods: (goods: GoodsWithSupplier) => Goods
  ) {
    const key = getGetPimGoodsQueryKey(productId)

    queryClient.setQueryData<Goods>(key, (data) => {
      if (!data?.suppliers) {
        return data as Goods
      }

      return getUpdatedGoods(data as GoodsWithSupplier)
    })
  }

  const create = (
    goodsId: UniqueId,
    supplierId: UniqueId,
    supplierPackaging: NewGoodsSupplierPackaging
  ) => {
    GoodsService.createGoodsPackaging(
      goodsId,
      supplierId,
      supplierPackaging
    ).then((res) => {
      const supplierPackagingServer = res.data
      changeGoodsEditData(
        goodsId,
        (goods) =>
          ({
            ...goods,
            suppliers: goods.suppliers.map((supplier) => supplier.id !== supplierId ? supplier : ({
              ...supplier,
              packaging: supplier.packaging?.concat(supplierPackagingServer),
            })),
          } as Goods)
      )
    })
  }

  const update = (
    goodsId: UniqueId,
    supplierId: UniqueId,
    supplierPackaging: EditGoodsSupplierPackaging
  ) => {
    GoodsService.updateGoodsPackaging(
      goodsId,
      supplierId,
      supplierPackaging
    ).then((res) => {
      const supplierPackagingServer = res.data
      changeGoodsEditData(
        goodsId,
        (goods) =>
          ({
            ...goods,
            suppliers: goods.suppliers.map((supplier) => ({
              ...supplier,
              packaging: supplier.packaging?.map((code) =>
                code.id === supplierPackagingServer.id
                  ? supplierPackagingServer
                  : code
              ),
            })),
          } as Goods)
      )
    })
  }

  const remove = (
    goodsId: UniqueId,
    supplierId: UniqueId,
    supplierPackaging: GoodsSupplierPackaging
  ) => {
    const deletingObject: DeletingObject = {
      id: supplierPackaging.id,
      versionNo: supplierPackaging.versionNo,
    }

    GoodsService.deleteGoodsPackaging(goodsId, supplierId, deletingObject).then(
      () => {
        changeGoodsEditData(
          goodsId,
          (goods) =>
            ({
              ...goods,
              suppliers: goods.suppliers.map((supplier) => ({
                ...supplier,
                packaging: supplier.packaging?.filter(
                  (code) => code.id !== deletingObject.id
                ),
              })),
            } as Goods)
        )
      }
    )
  }

  return {
    create,
    update,
    remove,
  }
}
