import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ReviewsTab } from 'features/reviews/ui/Tabs/ReviewsTab'
import { AsksTab } from 'features/reviews/ui/Tabs/AsksTab'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { StyledTabPanel } from '../../styled'
import { TemplateTab } from '../../Tabs/TemplateTab'


const TABS = [
  {
    label: 'Отзывы',
    Component: ReviewsTab,
    value: 1
  },
  {
    label: 'Вопросы',
    Component: AsksTab,
    value: 2
  },  
  {
    label: 'Шаблоны',
    Component: TemplateTab,
    value: 3
  },
] as const

export const ReviewsTabs = memo(() => {
  const { search } = useLocation()
  const searchObj = queryString.parse(search)
  const history = useHistory()

  const handleChartParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true }
    )
    history.replace({ search: `?${historyStr}` })
  }

  const [tabValue, setTabValue] = React.useState(Number(searchObj?.tab) || TABS[0].value)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleChartParams({ tab: newValue, page: undefined, pageSize: undefined } )
    setTabValue(newValue)
  }

  return (
    <>
      <Paper style={{ marginTop: '28px' }}>
        <StyledTabPanel>
          <Box sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              {TABS.map((value, index) => (
                <Tab
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  value={value.value}
                  sx={{ padding: '12 24', minWidth: '185px' }}
                  label={value.label}
                  {...a11yProps(value.value)}
                />
              ))}
            </Tabs>
          </Box>
        </StyledTabPanel>

        {TABS.map(({ Component, value }, index) => (
          <TabPanel
            value={tabValue}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={value}
          >
            <Component/>
          </TabPanel>
        ))}
      </Paper>
    </>
  )
}
)