import styled from 'styled-components'

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 14px;
      line-height: 20.02px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
` 