import React, { useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  Box,
  DialogActions,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { tradeApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { getProductsByCodeFunc } from 'shared/api/trade/orders'
import { useAddProductInOrderMutation } from 'shared/api/trade/orders/supplyorder/addProduct'
import { SelectProductWidget } from 'features/product/widgets/SelectProductWidget'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 740px;
    max-width: 740px;
  }
`

interface ProductCreateModalProps {
  close: () => void;
  accept?: () => void;
  supplyOrderQuery: QueryObserverResult<tradeApi.SupplyOrder>
  title?: string
}

const SX_TITLE = { fontSize: '20px', paddingTop: '24px' }

export const AddSpecificProductModalContent = ({
  close,
  supplyOrderQuery,
  title
}: ProductCreateModalProps) => {

  const { mutate: editSupplyOrder, isLoading  } = useAddProductInOrderMutation()

  const [selectedProduct, setSelectedProduct] = useState('')

  const productId = supplyOrderQuery.data?.id as number
  const handleAddComponent = (newComponent) => {
    setSelectedProduct(newComponent)
  }
  const cleanSelected = () => {
    setSelectedProduct('')
  }

  const onSubmit = (formValues) => {
    if(!selectedProduct) return
    editSupplyOrder(
      {
        supplyOrderId: supplyOrderQuery.data?.id,
        addedProduct: {
          productId: Number(selectedProduct),
          quantity: Number(formValues.quantity),
          comments: ''
        },
      },
      {
        onSuccess: () => {
          supplyOrderQuery.refetch()
          snackActions.info('Продукт успешно добавлен в заказ')
          close()
        }
      }
    )
  }

  const searchFunction = (searchString: string) => getProductsByCodeFunc(productId, searchString)


  return (
    <ModalContainer open={true}>
      <Box>
        <DialogTitleWithBtn onClose={close} sx={SX_TITLE}>
          {title}
        </DialogTitleWithBtn>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            // @ts-ignore
            mutators={{
              ...arrayMutators,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <Box mt={1}>
                  <SelectProductWidget
                    searchFunction={searchFunction}
                    searchInputRequired={true}
                    quantityRequired={true}
                    addInfo={true}
                    onSelectProduct={handleAddComponent}
                    onClearSelection={cleanSelected}
                  />
                </Box>
                <DialogActions sx={{
                  padding: 0,
                  marginBottom: '24px'
                }}>
                  <Box >
                    <Button onClick={close} color="primary">
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isLoading}
                      disabled={!(selectedProduct.length !== 0)}
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      ДОБАВИТЬ
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )}
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}
