import styled from 'styled-components'
import { Box, Paper } from '@mui/material'

export const ElementWrapper = styled(Paper) `
  width: 250px;
  height: 250px;
  border: 1px solid #E0E0E0;
  background-color: #FAFAFA;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  overflow: hidden;
  a {
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
  }
  :hover {
    background-color: white;
    a {
      div {
        svg {
          transition: all 0.4s ease-in-out;
          color: #2196F3;
        }
      }
    }
  }
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
`

export const Content = styled(Box) `
  padding: 55px 27px 0 27px;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  flex-direction: column;
  svg {
    width: 40px;
    height: 40px;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 30px;
  }

  div:last-child {
    margin-top: 8px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 143%;
  }
`

export const ElementFooter = styled(Box)<{ secondaryColor: string }> `
  height: 56px;
  background-color: ${props => props.secondaryColor};
  color: #FFFFFF;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
