import {
  Box,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionDetails
} from '@mui/material'
import { Form, InputField, Field } from 'shared/ui/components/form'
import { Avatar, Button, InputLabel } from 'shared/ui/components'
import * as yup from 'yup'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { HelperTextSupply } from 'features/auth/ui/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { customerGen } from 'shared/lib/generated'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import { useCallback } from 'react'

import * as UI from './styles'
import { ExpandedAccordion } from './styles'
import BadgeDownload from './BadgeDownload'

const yupValidation = yup.object().shape({
  repeatPassword: yup.string().test('password match', 'Пароли не совпадают',
    (password, { parent }) => password === parent.newPassword),
  newPassword: yup.string().min(8),
})

const Profile = () => {
  const { data: userInfo } = customerGen.currentUser.GetCurrentUserInfo.useGetCurrentUserInfo()
  const { mutate: editUserInfo } = customerGen.currentUser.EditCurrentUserInfo.useEditCurrentUserInfo()
  const { data: avatarUrl, isLoading: requestUserPhotoIsLoading } = customerGen.currentUser.GetUsersPhoto.useGetUsersPhoto()
  const { mutate: addPhoto, isLoading: addingPhotoIsLoading } = customerGen.currentUser.SaveUsersPhoto.useSaveUsersPhoto()
  const queryClient = useQueryClient()

  const validation = useYupValidation(yupValidation)

  const onSubmit = (values, form) => {
    if (JSON.stringify(values) !== JSON.stringify(userInfo)) {
      editUserInfo({ data: values }, {
        onSuccess: (response) => {
          queryClient.setQueryData<customerGen.currentUser.GetCurrentUserInfo.ResponseType>(
            customerGen.currentUser.GetCurrentUserInfo.getQueryKey(),
            (updater) => ({ ...updater, ...response }))
          snackActions.info('Профиль успешно изменен')
          form.restart()
        }
      })
    }
  }

  const handleDeletePhoto = useCallback(() => {
    const emptyBlob = new Blob([], { type: 'image/jpeg' })

    customerGen.currentUser.DeleteUsersPhoto.apiFunction().then(() => {
      queryClient.setQueryData(customerGen.currentUser.GetUsersPhoto.getQueryKey(), { data: emptyBlob })
      snackActions.info('Аватар пользователя удалён')
    })
  },[userInfo])

  const handleUploadAvatar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const formData = new FormData()

      formData.append('users_photo', event.target.files?.[0])
      addPhoto({ data: formData as any }, {
        onSuccess: () => {
          if (event.target.files?.[0]) {
            const file = event.target.files[0]
            file.arrayBuffer().then((arrayBuffer) => {
              const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type })
              queryClient.setQueryData(customerGen.currentUser.GetUsersPhoto.getQueryKey(), { data: blob })
              // eslint-disable-next-line no-param-reassign
              event.target.value = ''
            })
          }
          snackActions.info('Аватар пользователя изменен')
        },
      })
    }
  }, [userInfo])

  return (
    <>
      {userInfo &&

      <Form
        onSubmit={(values, form) => onSubmit(values, form)}
        id="userForm"
        initialValues={userInfo}
        validate={validation}
        render={({ visited, valid, handleSubmit, errors, values, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Box component={Paper} pt={3} pr={3} pl={3} mt="28px" mb={3} pb={4}>
              <Grid container={true} justifyContent="space-between">
                <Grid item={true} xs={12}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box>
                      <UI.Block marginBottom={3}>
                        <Typography fontSize="20px" mb={3}>{userInfo.name}</Typography>
                        <Avatar
                          altName={userInfo.name}
                          isLoading={requestUserPhotoIsLoading || addingPhotoIsLoading}
                          uploadPhoto={handleUploadAvatar}
                          deletePhoto={handleDeletePhoto}
                          photo={avatarUrl?.data}
                        />
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <InputLabel label="Имя пользователя" required={true}/>
                        <Field
                          sx={{ width: '350px' }}
                          name="name"
                          component={InputField}
                          onBlur={handleSubmit}
                        />
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <InputLabel label="Логин" required={true}/>
                        <Field
                          sx={{ width: '350px' }}
                          name="login"
                          component={InputField}
                          onBlur={handleSubmit}
                        />
                      </UI.Block>
                      <UI.Block>
                        <InputLabel label="Telegram" required={false} />
                        <Field
                          sx={{ width: '350px' }}
                          name="telegramCode"
                          component={InputField}
                          onBlur={handleSubmit}
                        />
                      </UI.Block>
                    </Box>
                    <BadgeDownload/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Accordion>
                <ExpandedAccordion sx={{ padding: '13px 24px' }} expandIcon={<ExpandMoreIcon />}>
                  <Typography fontSize="20px">Обновление пароля</Typography>
                </ExpandedAccordion>
                <AccordionDetails sx={{ padding: '0 24px' }}>
                  <Box display="flex" flexDirection="row" gap={3} pb={3}>
                    <Box width={240} height="auto" borderRadius="4px" padding="12px 16px" style={{ backgroundColor: '#FAFAFA' }}>
                      <Box marginBottom="14px">
                        <span style={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: '12px', fontWeight: '500'
                        }}>Требование к паролю</span>
                      </Box>
                      <Box display="flex" flexDirection="row" gap="13px" mb={1}>
                        {!values.newPassword &&
                        <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
                        {(!!values?.newPassword && values?.newPassword?.length < 8) &&
                        <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
                        {(values?.newPassword?.length >= 8) &&
                        <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                          Не менее 8 символов
                        </span>
                      </Box>
                      <Box display="flex" flexDirection="row" gap="13px">
                        {!values.newPassword &&
                        <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
                        {(dirty && values?.newPassword?.length > 30) &&
                        <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
                        {(!!values?.newPassword && values?.newPassword?.length <= 30) &&
                        <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                          Не более 30 символов
                        </span>
                      </Box>
                    </Box>
                    <UI.Block mt={1}>
                      <Typography fontSize={16} fontWeight={500} mb={3}>Пароль</Typography>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="oldPassword"
                            sx={{ width: '320px' }}
                            component={InputField}
                            errorAfterTouch={true}
                            helperText=""
                            label="Действующий пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="newPassword"
                            sx={{ width: '320px' }}
                            errorAfterTouch={true}
                            helperText=""
                            component={InputField}
                            label="Новый пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <UI.Block marginBottom={3}>
                        <HelperTextSupply>
                          <Field
                            name="repeatPassword"
                            sx={{ width: '320px' }}
                            errorAfterTouch={true}
                            helperText={visited?.repeatPassword ? errors?.repeatPassword : ''}
                            component={InputField}
                            required={true}
                            InputProps={{ autoComplete: 'off' }}
                            label="Подтвердите пароль"
                            type="password"
                          />
                        </HelperTextSupply>
                      </UI.Block>
                      <Button
                        disabled={!valid || values?.oldPassword?.length === 0}
                        type="submit"
                      >
                        Сохранить
                      </Button>
                    </UI.Block>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </form>
        )}
      />
      }
    </>
  )
}

export default Profile
