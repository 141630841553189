import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { ADMIN_PANEL_CREATE_SUPPLIER } from 'shared/config/routes'
import AddIcon from '@mui/icons-material/Add'


interface ISuppliersHeader {
  isEmptySuppliers?: boolean
}
export const Header = ({ isEmptySuppliers }: ISuppliersHeader) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    my={3}
  >
    <Typography component="h1" variant="h3" fontSize="1.5rem">
      Поставщики
    </Typography>
    <Box mr="auto" ml={2}>
      {
        !isEmptySuppliers &&
        <Button
          variant="contained"
          startIcon={<AddIcon/>}
          component={Link}
          to={ADMIN_PANEL_CREATE_SUPPLIER}
          color="primary"
        >
          ДОБАВИТЬ
        </Button>
      }
    </Box>
  </Box>
)