import { hasRoleAccess } from 'shared/api/base'

interface ITab {
  id: number
  name: string
}

const sellerStatusId = [ -1, 0, 60 ]
const logisticianStatusId = [ -1, 21, 30, 40, 50, 60, 70, 80, 90 ]
const sellerChiefStatusId = [ -1, 0, 10, 20, 30, 40, 50, 60, 70, 90, 100 ]
const CFOStatusId = [ -1, 0, 10, 20, 30, 40, 50, 60, 70, 90, 100 ]

const allRolesCheck = () =>
  hasRoleAccess('SELLER') &&
  hasRoleAccess('LOGISTICS') &&
  hasRoleAccess('CFO') &&
  hasRoleAccess('SELLER_CHIEF')

const noRolesCheck = () =>
  !hasRoleAccess('SELLER') &&
  !hasRoleAccess('LOGISTICS') &&
  !hasRoleAccess('CFO') &&
  !hasRoleAccess('SELLER_CHIEF')

export const filteringTabsByRoles = (tab: ITab) => {
  if (allRolesCheck() || noRolesCheck()) return true

  if (hasRoleAccess('SELLER')) {
    if (sellerStatusId.includes(tab.id)) {
      return true
    }
  }

  if (hasRoleAccess('LOGISTICS')) {
    if (logisticianStatusId.includes(tab.id)) {
      return true
    }
  }

  if (hasRoleAccess('CFO')) {
    if (CFOStatusId.includes(tab.id)) {
      return true
    }
  }

  if (hasRoleAccess('SELLER_CHIEF')) {
    if (sellerChiefStatusId.includes(tab.id)) {
      return true
    }
  }

  return null
}