import React from 'react'

export const AttributeTypeCell = ({ cellValue }: { cellValue: string }) => {
  const DataTypeRecord: Record<string, string> = {
    'string': 'Строка',
    'number': 'Число',
    'date': 'Дата',
    'logical': 'Логический'
  }

  return (
    <>{DataTypeRecord[cellValue]}</>
  )
}