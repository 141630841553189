import styled from 'styled-components'

export const StyledTable = styled.div`
  width: 100%;
  overflow-x: auto;
  
  table {
    width: 100%;
    min-width: min-content;
    border-spacing: 0;
  }
`
export const StyledTableHeader = styled.thead`
  min-width: min-content;
  width: 100%;
  height: 56px;
  box-shadow: 0 -1px 0 0 #E0E0E0 inset, 0 1px 0 0 #E0E0E0 inset;
  background-color: #F6F6F680;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  
  th {
    text-align: start;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px 8px 16px;
  }
`

export const StyledTableBody = styled.tbody`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0 -1px 0 0 #E0E0E0 inset;
  
    
  .table-row {
    box-shadow: 0 1px 0 0 #E0E0E0 inset;
    min-height: 76px;
    min-width: min-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    :first-child {
      box-shadow: none;
    }

    > td {
      height: 76px;
      padding: 0 16px 0 16px;
      display: flex;
    }
  }
`

export const StyledNestedWidget = styled.td`
  width: 100%;

  thead {
    height: 52px;
    background-color: #FFFFFF;
    box-shadow: 0 -1px 0 0 #E0E0E0 inset;

    th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  tbody {
    box-shadow: none;
    background-color: #FAFAFA;
    tr {
      padding-bottom: 16px;
    }
  }

  td {
    vertical-align: top;
    align-content: start;
    padding: 0;
  }
`