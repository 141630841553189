/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewUserRole, UserRole } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserRole>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  data: NewUserRole
}>

export const apiFunction = ({ userId,data }: IRequest) =>
  api.post<ResponseType>(`/customer/admin/users/${userId}/roles`,data).then(res => res.data)


export const useAddRoleToUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

