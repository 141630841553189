import React from 'react'
import { StyledDiscount } from 'entities/pim/price/ui/styled'
import { getPriceDiscount } from 'entities/pim/price/lib'

import { isNotNil } from '../../../../../../lib/checkers'

export const PercentContainer = ({ row }) => {
  const discount = getPriceDiscount(row?.detail?.actionPrice, row?.price)
  if (isNotNil(discount))
    return (
      <StyledDiscount style={{ width: 'min-content', marginLeft: '0' }}>{discount}%</StyledDiscount>
    )
  return <></>
}