import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosError } from 'axios'

interface UserListType {
  id: number
  name: string
}
function getUsers()
{
  return api.get<UserListType[]>('/pim/admin/users').then((res) => res.data)
}
type QueryKey = ReturnType<typeof QUERY_KEY>

const QUERY_KEY = () => ['get', 'pim/admin/users'] as const

export const useGetUsersListQuery = () =>
  useQuery<
    UserListType[],
    AxiosError<errorType>,
    UserListType[],
    QueryKey
  >(
    QUERY_KEY(),
    getUsers
  )
