import { useCallback, useMemo } from 'react'
import { Paper } from '@mui/material'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { pimApi, user } from 'shared/api'
import { isNotNil } from 'shared/lib/checkers'
import { Table } from 'shared/ui/components/Table'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { useQueryClient } from 'react-query'
import { getUserSettingsQueryKey } from 'shared/api/user/settings'
import { checkValue } from 'shared/lib/checkers/checkValue'
import { useQuery } from '@apollo/client'
import { Query as GoodsQuery, GoodsResultArgs, QueryGoodsArgs } from 'shared/lib/generated/goods/Api'
import { GET_GOODS_LIST } from 'shared/api/goods/getGoodsList'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { arrOfNum, arrOfString } from 'shared/lib/transform'
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core'
import { SearchResultType } from 'shared/api/goods/models'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { TableFilter } from 'pages/product/productList/ui/Filter/TableFilter'

import { nestedTableColumnsConfig, tableColumnsConfig } from './model'
import { StyledTableBox, StyledLoader } from './ui/styled'
import { Header } from './ui/Header/Header'
import { pagingPanelLocale } from './lib/localization'
import { GoodsFilters } from './model/types'
import { availableFilters } from './lib/availableFilters'

import { getFiltersList } from '../../../shared/api/goods/getAllFilters'


export interface GoodsPayload extends QueryGoodsArgs, GoodsResultArgs {}

const ROW_HEIGHT = 52
const HEADER_HEIGHT = 56
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

const getRowId = (row: SearchResultType) => row.id

export const GoodList = () => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search,{ arrayFormat: 'bracket', parseBooleans: true })

  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      HEADER_HEIGHT -
      TABLE_CONTROLS -
      TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.limit)
    ? parseInt(searchObj.limit, 10)
    : calculatedRowsPerPage

  const page = parseInt(searchObj.page || '0', 10)
  const { searchString, contragentId, category,season, goodsBrandId, goodsType, inArchive } = searchObj
  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)
  const sort =
    searchObj.sort && searchObj.order
      ? [{ columnName: searchObj.sort, direction: searchObj.order }]
      : []

  const downloadGoodsFileParams: pimApi.goods.DownloadGoodsFileParams = {
    ...(isNotEmptyArray(arrOfNum(contragentId)) && {
      contragentId: arrOfNum(contragentId)
    }),
    ...(isNotEmptyArray(arrOfNum(goodsBrandId)) && {
      goodsBrandId: arrOfNum(goodsBrandId)
    }),
    ...(isNotEmptyArray(arrOfString(goodsType)) && {
      goodsType: arrOfString(goodsType)
    }),
    ...(isNotEmptyArray(arrOfString(category)) && {
      category: arrOfString(category)
    }),
    ...(isNotEmptyArray(arrOfString(season)) && {
      season: arrOfString(season)
    }),
    searchString: checkValue(searchString),
    sort: checkValue(sort?.[0]?.columnName),
    inArchive: checkValue(inArchive),
    order: checkValue(sort?.[0]?.direction),
  }

  const filtersListParams: GoodsFilters = {
    category: arrOfString(category) || [],
    contragentId: arrOfNum(contragentId) || [],
    season: arrOfString(season),
    goodsBrandId: arrOfNum(goodsBrandId) || [],
    goodsType: arrOfString(goodsType) || [],
  }
  
  const goodsListParams: pimApi.goods.GetGoodsParams = {
    ...downloadGoodsFileParams,
    limit,
    page,
  }

  const { data, loading, previousData } = useQuery<GoodsQuery, GoodsPayload>(GET_GOODS_LIST, {
    variables: {
      ...filtersListParams,
      sort: goodsListParams.sort ? toSnake(goodsListParams.sort) : null,
      order: checkValue(goodsListParams?.order?.toUpperCase()),
      page: goodsListParams.page,
      limit: goodsListParams.limit,
      searchString: goodsListParams.searchString,
      excludeInArchive: !goodsListParams.inArchive
    }, context: { apiName: 'goods' }  })

  const { data: userSettingsQuery, isLoading: settingsLoading } = user.settings.useFetchUserSettingsQuery()
  const { mutate: updateUserSettings } = user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.goodsTableHiddenColumns

  const queryClient = useQueryClient()

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          goodsTableHiddenColumns: hiddenColumns,
        },
      },{
        onSuccess: (response) => {
          queryClient.setQueryData(getUserSettingsQueryKey(), response)
        }
      })
    },
    [userSettingsQuery?.data]
  )

  // memo для того что бы новые параметры для таблицы не обновляли компонет DocTable до того как пришли
  // новые данные для таблицы
  const memoTableParams = useMemo(
    () => ({ sort, limit, page, searchString, columnsSettingsId: 'goods' }),
    [data?.goods?.result]
  )

  const tableData = useMemo(() => data || previousData, [data, previousData])

  const filtersQuery = getFiltersList()
  return (
    <>
      <Header downloadParams={downloadGoodsFileParams} />
      <StyledTableBox component={Paper}>
        {(loading || settingsLoading) && (<StyledLoader size={60} />)}
        {tableData && userSettingsQuery?.data && (
          <Table
            tableList={tableData?.goods?.result}
            totalCount={tableData?.goods?.total}
            getRowId={getRowId}
            tableParams={memoTableParams}
            handleTableSettingsChange={handleTableSettingsChange}
            tableHiddenColumns={tableHiddenColumns}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={tableColumnsConfig}
            nestedTableColumnsConfig={nestedTableColumnsConfig}
            showInArchiveControl={true}
            showColumnsVisibility={true}
            searching="external"
            pagination="external"
            sorting="external"
            filtering="internal"
            paginationLocale={pagingPanelLocale}
          >
            <Plugin dependencies={[{ name: 'Toolbar' }]}>
              <Template name="toolbarContent">
                <TemplatePlaceholder/>
                <TableFilter
                  filtersQuery={filtersQuery}
                  filtersListParams={filtersListParams}
                  queryContext="goods"
                  availableFilters={availableFilters}/>
              </Template>
            </Plugin>
          </Table>
        )}
      </StyledTableBox>
    </>
  )
}
