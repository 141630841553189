/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Stocks } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Stocks[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** Поиск по строке */
    search_string?: string,
  /** Поиск по поставщику */
    supplier_id?: number,
  /** Поиск по получателю */
    recipient_id?: number,
  /** Поиск по владельцу товара */
    owner_id?: number,
  /** Поиск по бренду */
    brand_id?: number,
  /** Поиск по области */
    area_id?: number,
  /**
   * Возвращать остатки только из данной области, не включая остатки из подобластей
   * @default false
   */
    only_this_area?: boolean,
  /** Возвращать только остатки FBS */
    is_fbs?: boolean,
  /** Ограничение на количество возвращаемых записей */
    limit?: number,
  /**
   * Смещение
   * @default 0
   */
    offset?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wms/stocks/list`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wms/stocks/list`, { query }] as const

export const useGetAreaStocksList = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

