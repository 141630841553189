import { TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { FilterWrapper } from 'shared/ui/components/DataFilters/styled'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

export const TableFilter = () => {
  const { searchObj, handleChangeParams } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })

  return (
    <FilterWrapper>
      <TextField
        className="searchInput"
        size="small"
        id="searchInput"
        variant="standard"
        autoComplete="off"
        placeholder="Поиск"
        defaultValue={searchObj.searchString}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            document.getElementById('searchInput')?.blur()
          }
        }}
        onBlur={(e) => {
          handleChangeParams({ params: { searchString: e.target.value }, options: { skipEmptyString: true, skipNull: true } })
        }}
        InputProps={{
          endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }}/>
    </FilterWrapper>
  )
}