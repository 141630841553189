import React from 'react'
import { copackingApi } from 'shared/api'
import { generatePath } from 'react-router'

import { COPACKING_ORDERS_DETAIL } from '../../../../../../config'
import { StyledLink } from '../../../../../styled/StyledLink'

export const CopackingOrderLink = ({ row }: { row: copackingApi.CoPackingOrderWithoutPositions } ) => {
  const path = generatePath(COPACKING_ORDERS_DETAIL, { id: row.id })
  return (
    <StyledLink to={path}>{row.externalId || row.id}</StyledLink>
  )
}