import styled from 'styled-components'

export const FlexWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 125, 255, 0.5);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

export const TabsNav = styled.div`
  .MuiTabs-indicator {
    display: none !important;
    min-height: 42px;
    min-width: 46px;
  }
  .MuiTab-root {
    padding: 8px 16px;
    font-size: 15px;
    min-height: 42px;
    color: #007dff;
    white-space: nowrap;
    border-bottom: 0;
    border-left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
     
    :last-child {
      min-width: 46px;
      padding: 8px 12px;
    }
  }
  .Mui-selected {
    background: #ebf5ff;
  }
  .Mui-selected.Mui-disabled{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF;
    border-color: #C4C4C4;
  }
  .Mui-disabled{
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.26);
    border-color: #C4C4C4;
  }
`
