import React, { useState } from 'react'
import { Box, Paper } from '@mui/material'
import { TableFilter } from 'features/wms'

import { Header } from './Header'
import { Wrapper } from './styled'
import { WmsTree } from './WmsTree'
import { WmsTable } from './WmsTable'

export const WarehousePage = () => {
  const [ expandTree, setExpandTree ] = useState<boolean>(true)
  const [selectedRows, setSelectedRows] = useState<Array<any>>([])

  return (
    <Wrapper $expand={expandTree}>
      <Header selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
      <Box component={ Paper }>
        <TableFilter expandTree={expandTree} setExpandTree={setExpandTree} />
        <div className="row">
          <div className="tree-container">
            <WmsTree/>
          </div>
          <div className="table-container">
            <WmsTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
          </div>
        </div>
      </Box>
    </Wrapper>
  )
}