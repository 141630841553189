import { withImage } from 'shared/lib/hooks/withImage'
import { Avatar } from '@mui/material'

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string
  userName: string
}

const S: React.FC<ImageProps> = ({ src, userName }) => {
  let altName: string
  if (userName.split(/\s+/).length === 1) {
    // eslint-disable-next-line prefer-destructuring
    altName = userName.split(/\s+/)[0][0].toUpperCase()
  } else {
    altName = userName.split(/\s+/)[0][0].toUpperCase() + userName.split(/\s+/)[1][0].toUpperCase()
  }
  return (
    <Avatar src={src} sx={{ width: 32, height: 32, fontSize: 14, alignItems: 'center' }}>
      <span style={{ marginTop: '1px' }}>{altName}</span></Avatar>)}

export const UserImage = withImage(S)