import { isEmptyArray } from '../checkers/isNotEmptyArray'

export const yupTransformEmptyStringInNull = (
  value: string,
  originalValue: string | number,
) => originalValue === '' ? null : value

export const yupTransformEmptyArrayInNull = (
  value: Array<any>,
  originalValue: Array<any>,
) => isEmptyArray(originalValue) ? null : value