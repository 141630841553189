import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api'
import { AxiosResponse } from 'axios'



export function getContragents(): Promise<AxiosResponse<pimApi.admin.ContragentShortInfo[]>> {
  return api.get<pimApi.admin.ContragentShortInfo[]>('/pim/admin/contragents')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/admin/contragents'] as const


function getContragentsFn() {
  return getContragents().then((res) => res.data)
}

export const useGetContragentsQuery = () =>
  useQuery<
        pimApi.admin.ContragentShortInfo[],
        errorType,
        pimApi.admin.ContragentShortInfo[],
        QueryKey
        >(
          QUERY_KEY,
          getContragentsFn, {
            refetchOnMount: false
          }
        )
