import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete , AutocompleteProps, AutocompleteRenderInputParams, TextField } from '@mui/material'
import debounce from 'lodash.debounce'
import Box from '@mui/material/Box'
import usePending from 'shared/lib/hooks/PendingHook'
import { catalogSelector } from 'store/catalog/selectors'
import { addCatalog } from 'store/catalog'
import { Option } from 'shared/ui/components/interface'

import * as UI from './styled'

const getOptionLabel = <V extends Option<any>>(option: V) => option.label
const getOptionSelected = <V extends Option<any>>(option: V, value: V) => option.label === value.label

export interface SmartSearchInputProps<OptionValue> extends Omit<
  AutocompleteProps<Option<OptionValue>, undefined, undefined, undefined>,
  'onChange' |
  'renderInput'
  > {
  onChange?: (option: any) => void
  error?: boolean
  onSelfInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
}


const renderOption = (props: any, option: { label: string }) => (
  <UI.Item {...props}>
    <Box display="flex" alignItems="center">
      <UI.ItemText variant="h6">{option.label}</UI.ItemText>
    </Box>
  </UI.Item>
)

const ListboxProps = { style: { maxHeight: '35vh' } }

const ONE_SECOND = 1000
function SmartSearchInputReal<OptionValue = string>({
  onChange,
  onBlur,
  onFocus,
  value,
  error,
  placeholder,
  onInputChange,
  onSelfInputChange,
  readOnly,
  ...rest
}: SmartSearchInputProps<OptionValue>) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const [fieldValue, setFieldValue] = useState<OptionValue | undefined>(value?.value)
  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = event.target

    // @ts-ignore
    setFieldValue(targetValue)

    onSelfInputChange?.(event)
  }, [])

  const handleChange = useCallback((_: any, option: Option | null) => {
    setIsOpen(false)
    onChange?.(option ?? null)
  }, [])


  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        onChange={handleInputChange}
        value={fieldValue}
        variant="outlined"
        placeholder={placeholder}
        size="small"
        error={error}
        InputProps={{
          ...params.InputProps,
          readOnly,
          endAdornment: (
            <>
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [rest.loading, error]
  )

  return (
    <Autocomplete<Option>
      size="small"
      fullWidth={true}
      clearOnBlur={false}
      blurOnSelect={true}
      // @ts-ignore
      disableClearable={true}
      ListboxProps={ListboxProps}
      open={isOpen}
      onOpen={readOnly ? () => {} : handleOpen}
      onClose={handleClose}
      noOptionsText="Введите строку для поиска"
      // @ts-ignore
      getOptionLabel={getOptionLabel}
      // @ts-ignore
      isOptionEqualToValue={getOptionSelected}
      onChange={readOnly ? () => {} : handleChange}
      // @ts-ignore
      renderOption={renderOption}
      // @ts-ignore
      value={value}
      {...rest}
      renderInput={renderInput}
    />
  )
}

interface AutocompleteCatalogProps<OptionValue = string> extends Omit<SmartSearchInputProps<OptionValue>, 'onSelfInputChange' | 'options'> {
  catalog: string
  catalogMapper: (value: any) => Option<OptionValue>
  catalogFilterPredicate?: (value: any) => boolean
  catalogSort?: (a: any, b: any) => number
  value?: Option<OptionValue> | null
}

export function AutocompleteCatalog<OptionValue = string> ({ 
  catalog,
  catalogMapper,
  catalogFilterPredicate,
  value,
  catalogSort,
  ...props
}: AutocompleteCatalogProps<OptionValue>) {
  const [getCatalog, isLoading] = usePending(addCatalog)
  const catalogData = useSelector(catalogSelector(catalog)) // as Array<Option>

  const options: Array<Option<OptionValue>> = (
    catalogFilterPredicate ? catalogData.filter(catalogFilterPredicate) : catalogData
  ).map(catalogMapper)

  const debouncedGetCatalog = debounce(getCatalog, ONE_SECOND)

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = target

    debouncedGetCatalog({
      catalog,
      sort: catalogSort,
      qParams: {
        value: targetValue,
      },
    })
  }, [])

  useEffect(() => {
    if (options && !options.length) {
      getCatalog({ catalog })
    }
  }, [catalog])

  return (
    <SmartSearchInputReal<OptionValue>
      onSelfInputChange={handleInputChange}
      options={options}
      loading={isLoading}
      value={value}
      {...props}
    />
  )
}