import { generatePath, useHistory } from 'react-router-dom'
import { PriceCard, PriceCardProps } from 'entities/pim/price'
import { DialogContentWithTitle, dialogModel } from 'shared/ui/components/dialog'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { PRODUCT_PRICE_HISTORY } from 'shared/config'

import { PriceChangeModal } from '../PriceChangeModal'

interface PriceCardWithMenuProps extends PriceCardProps {
  productId: number
  nomenclatureId?: UniqueId
  readOnly?: boolean
  id?: number
  versionNo?: number
  name?: string
  onRemove?: () => void
  onAdd?: (data: pimApi.products.SetSimpleProductPriceInfo) => void
}

export const PriceCardWithMenu = ({
  id,
  versionNo,
  discount,
  priceRecentHistory,
  price,
  nomenclatureId,
  productId,
  readOnly,
  name,
  onRemove,
  onAdd,
  ...props
}: PriceCardWithMenuProps) => {
  const history = useHistory()
  const hasHistory = !!priceRecentHistory?.length

  const { mutate: deleteProductPrice } = pimApi.products.simple.useDeleteProductPriceMutation()

  const showPriceHistory = () => {
    const path = `${generatePath(PRODUCT_PRICE_HISTORY, { id: productId })}`
    history.push(path)
  }

  const editPrice = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <PriceChangeModal
          close={close}
          price={price}
          discount={discount}
          productId={productId}
          nomenclatureId={nomenclatureId}
          onAdd={onAdd}
        />
      ),
      onAccept: () => {},
    })
  }

  const deletePrice = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <DialogContentWithTitle
            close={close}
            accept={accept}
            title="Вы уверены, что хотите удалить это запланированное изменение цены?"
            acceptBtnText="ПОДТВЕРДИТЬ"
          />,
        onAccept: () => {
          deleteProductPrice(
            {
              productId,
              priceId: id,
              data: {
                id: id?.toString() || '',
                versionNo,
              }
            },
            {
              onSuccess: () => {
                onRemove?.()
                snackActions.info('Цена успешно удалена!')
              }
            }
          )
        },
      }
    )
  }

  return (
    <PriceCard
      showOldPrice={false}
      showPriceHistory={!hasHistory ? undefined : showPriceHistory}
      editPrice={((!hasHistory && !!price) || readOnly) ? undefined : editPrice}
      deletePrice={(hasHistory || !price || readOnly) ? undefined : deletePrice}
      priceRecentHistory={priceRecentHistory}
      price={price}
      discount={discount}
      {...props}
    />
  )
}
