import styled, { css } from 'styled-components'
import { EditableInput } from 'shared/ui/components'

export const EditableStyledInput = styled(EditableInput)`
  ${props => (props.customStyle === 'editQuantity' || props.customStyle === 'editField') && css `
    margin: 0;
    width: 72px;
      input {
      display: flex;
      align-items: center;
      padding: 0 12px;
      width: 72px;
      height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      font-size: 0.85rem;
      background-color: rgb(255, 255, 255);
  `}
  input {
    color: #007DFF;
    padding: 0;
    margin: 0;
    border-style: none;
    font-size: 0.875rem;
    line-height: 1.43;
  }
`
