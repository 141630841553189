import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { GoodsComponent } from 'domains/pim/goods'
import { pimApi } from 'shared/api'

import { api } from '../../base'

export function getComponents(
  goodsId: UniqueId
): Promise<AxiosResponse<Array<GoodsComponent>>> {
  return api.get<Array<GoodsComponent>>(`/pim/goods/${goodsId}/content`)
}

type GoodsContentQueryKey = ReturnType<typeof getGoodsContentQueryKey>

export const getGoodsContentQueryKey = (goodsId?: UniqueId) =>
  ['get', `/pim/goods/${goodsId}/content`, goodsId] as const

export const fetchGoodsContent = (
  goodsId: UniqueId
) => api.get<Array<GoodsComponent>>(`/pim/goods/${goodsId}/content`).then((res) => res.data)

export const useFetchGoodsContentQuery = (goodsId?: UniqueId) => useQuery<
  Array<GoodsComponent>,
  AxiosError<errorType>,
  Array<GoodsComponent>,
  GoodsContentQueryKey
>(
  getGoodsContentQueryKey(goodsId),
  (context) => fetchGoodsContent(context.queryKey[2] as UniqueId),
)

interface CreateContentPayload {
  goodsId: UniqueId
  content: pimApi.goods.NewGoodsComponent
}

export const createContent = ({ goodsId, content }: CreateContentPayload) =>
  api.post<pimApi.goods.GoodsComponent>(
    `/pim/goods/${goodsId}/content`,
    content
  )

export const useCreateContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    CreateContentPayload
  >(createContent)

interface UpdateContentPayload {
  goodsId: UniqueId
  contentId: UniqueId
  content: pimApi.goods.EditGoodsComponent
}

export const updateContent = ({
  goodsId,
  contentId,
  content,
}: UpdateContentPayload) =>
  api.put<pimApi.goods.GoodsComponent>(
    `/pim/goods/${goodsId}/content/${contentId}`,
    content
  )

export const useUpdateContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    UpdateContentPayload
  >(updateContent)

interface DeleteContentPayload {
  goodsId: UniqueId
  contentId: UniqueId
  content: pimApi.goods.DeleteGoodsComponent
}

export const deleteContent = ({
  goodsId,
  contentId,
  content,
}: DeleteContentPayload) =>
  api.delete<pimApi.goods.GoodsComponent>(
    `/pim/goods/${goodsId}/content/${contentId}`,
    { data: content }
  )

export const useDeleteContentMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.GoodsComponent>,
    AxiosError<errorType>,
    DeleteContentPayload
  >(deleteContent)
