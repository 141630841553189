import { SortDirection, TableParams } from 'shared/ui/components/Table/model/types'

export type SupplyOrderEditPageTab =
    'goodspositions' |
    'productspositions' |
    'implementorders' |
    'copackingorders' |
    'coefficient' |
    'distribution' |
    'history'

export const SUPPLY_ORDER_EDIT_PAGE_TABS: Array<SupplyOrderEditPageTab> = [
  'goodspositions',
  'productspositions',
  'implementorders',
  'copackingorders',
  'coefficient',
  'distribution',
  'history'
]

export interface tabsSettingType  {
  rowInPagination: number,
  inTabsObjectInit: {
    label: string,
    searchString: string,
    page: number,
    sort: Array<{ columnName: string; direction: SortDirection; }>
  }[]
}

export interface tabsSettingObjectType  {
  tabLabel?: string,
  valueInInput?: string,
  paginationRow?: number,
  tabSettings?: TableParams
}