import styled from 'styled-components'

export const StyledChartContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  #data-params-box {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    span {
      font-size: 16px;
      color: #00000099;
    }
  }
`