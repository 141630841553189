import { StyledPrice, StyledDiscount } from './styled'

export const PriceCell = ({
  price,
  discount,
  priceBeforeDiscount
}: {
  price?: number
  discount?: number
  priceBeforeDiscount?: number
}) => (
  <>
    {price && <span>{price}</span>}
    {priceBeforeDiscount && <StyledPrice>{priceBeforeDiscount}</StyledPrice>}
    {discount && <StyledDiscount>{`${discount}%`}</StyledDiscount>}
  </>
)

