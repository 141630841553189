import React, { useState } from 'react'
import { Box, Button, Dialog, DialogTitle, TextField } from '@mui/material'
import styled from 'styled-components'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { snackActions } from 'shared/lib/react/snackbar'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 680px;
    max-width: 680px;
  }
`

export const ProductsCatalogYmlModal = ({ close, yml }) => {
  const [open, setOpen] = useState(true)
  return (
    <ModalContainer open={open} onClose={() => {
      setOpen(false)
      close()
    }}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon"/>
        </IconCloseButton>
        <DialogTitle sx={{ padding: '24px' }} fontSize={20} whiteSpace="pre-line">
          Каталог продуктов компании в формате YML
        </DialogTitle>
        <Box px={3} pb="40px" sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
          <TextField
            disabled={true}
            size="small"
            label="URL"
            defaultValue={yml}
            sx={{ width: '424px' }}
          />
          <Button
            onClick={() => {
              navigator.clipboard.writeText(yml).then(() => {snackActions.info('Ссылка скопирована')})
            }}
            variant="contained"
            startIcon={<FileCopyIcon/>}
            sx={{ height: '36px' }}>
            Скопировать YML
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}