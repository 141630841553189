import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface CopackingOrdersQueryParams {
  copackingordersId?: UniqueId
  positionId?: UniqueId
  editedOrderPositionPack:  tradeApi.EditCoPackingOrderPosition
}


const changeOrderPositionPacking = ({ copackingordersId, positionId, editedOrderPositionPack }: CopackingOrdersQueryParams) =>
  api.put<tradeApi.EditCoPackingOrderPosition>(
    `trade/orders/copackingorders/${copackingordersId}/positions/${positionId}`,
    editedOrderPositionPack
  )

export const useChangeOrderPositionPacking = () =>
  useMutation<
        AxiosResponse<tradeApi.EditCoPackingOrderPosition>,
        AxiosError<errorType>,
        CopackingOrdersQueryParams
  >(changeOrderPositionPacking)