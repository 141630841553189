import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeleteContragentManagerPayload {
    contragentId: UniqueId,
    managerId: UniqueId,
    value: pimApi.DeletingObject
}

const deleteContragentManager = ({ contragentId, managerId, value }: DeleteContragentManagerPayload) => 
  api.delete(`/pim/admin/contragents/${contragentId}/users/${managerId}`, { data: value })

export const useDeleteContragentManagerMutation = () =>
  useMutation<AxiosResponse,
        AxiosError<errorType>,
        DeleteContragentManagerPayload>(deleteContragentManager)