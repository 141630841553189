interface StateAppearance {
  month: string
}

export const DateAppearanceSettings: Record<string, StateAppearance> = {
  '01': { month: 'Янв' },
  '02': { month: 'Февр' },
  '03': { month: 'Март' },
  '04': { month: 'Апр' },
  '05': { month: 'Май' },
  '06': { month: 'Июнь' },
  '07': { month: 'Июль' },
  '08': { month: 'Авг' },
  '09': { month: 'Сент' },
  '10': { month: 'Окт' },
  '11': { month: 'Нояб' },
  '12': { month: 'Дек' },
}

export const convertDate = (date) => `${date.split('-')[2]} ${DateAppearanceSettings[date.split('-')[1]].month}`

export const convertDateWithYear = (date) =>
  `${date?.split('-')[2]} ${DateAppearanceSettings[date?.split('-')[1]].month}, ${date?.split('-')[0]}`