import React, { useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { Box, Grid } from '@mui/material'
import { useMarketplaceList, useCompanyList, useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import { CheckboxField, InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { SelectFieldWithAutoComplete } from 'shared/ui/components/form'

import { getOptions } from '../lib/getSelectFieldOptions'

export const CommonFields = () => {

  const companyId = useField('companyId').input.value
  const marketplaceId = useField('marketplaceId').input.value

  const { marketplaceListQuery } = useMarketplaceList()
  const { companyListQuery } = useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(marketplaceId, companyId)

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data]
  )
  const marketPlaceOptions = useMemo(
    () => marketplaceListQuery.data?.map(getOptions) || [],
    [marketplaceListQuery.data]
  )
  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  return (
    <Box mb={3} mt={3}>
      <Grid container={true} rowSpacing={3} columnSpacing={{ xs: 2 }}>
        <Grid item={true} xs={4.9}>
          <InputLabel label="Маркетплейс" />
          <InputWrapper>
            <Field
              name="marketplaceId"
              id="marketplaceId"
              placeholder="Маркетплейс"
              required={true}
              errorAfterTouch={true}
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              options={marketPlaceOptions}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4.9}>
          <InputLabel label="Продавец" />
          <InputWrapper>
            <Field
              name="companyId"
              id="companyId"
              placeholder="Продавец"
              required={true}
              errorAfterTouch={true}
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              options={companyOptions}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4.9} >
          <InputLabel label="Кабинет" />
          <InputWrapper>
            <Field
              name="companymarketplaceId"
              id="companymarketplaceId"
              placeholder="Кабинет"
              required={true}
              errorAfterTouch={true}
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              disabled={companymarketplaceOptions.length === 0}
              options={companymarketplaceOptions}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={4.9} alignSelf="end">
          <Field
            name="useGoodsMedia"
            id="useGoodsMedia"
            label="Использовать медиа товара"
            size="small"
            component={CheckboxField}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
