import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeleteContragentBrandsPayload {
  contragentId: UniqueId,
  brandId: UniqueId,
  value: pimApi.DeletingObject
}
const deleteContragentBrands = ({ contragentId, brandId, value }: DeleteContragentBrandsPayload) =>
  api.delete(
    `/pim/admin/contragents/${contragentId}/brands/${brandId}`,
    { data: value }
  )


export const useDeleteContragentBrandsMutation = () =>
  useMutation<
        AxiosResponse,
        AxiosError<errorType>,
        DeleteContragentBrandsPayload
        >(deleteContragentBrands)