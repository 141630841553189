import React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'

import { StyledText } from './styled'

import { PrivilegesUsersModal } from '../../../../ModalComponents/PrivilegesUsersModal'

export const PrivilegeUsersQuantityCell = ({ row }) => {
  const { usersQuantity } = row
  const text_forms = ['пользователь', 'пользователя', 'пользователей']
  function declOfNum(n: number) {
    // eslint-disable-next-line no-param-reassign
    n = Math.abs(n) % 100
    const n1 = n % 10
    if (n > 10 && n < 20) { return text_forms[2] }
    if (n1 > 1 && n1 < 5) { return text_forms[1] }
    if (n1 === 1) { return text_forms[0] }
    return text_forms[2]
  }

  const handleOpenDialog = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <PrivilegesUsersModal
          close={close}
          row={row}
        />
      ),
    })
  }

  if (usersQuantity < 1) {
    return (<div/>)
  }
  
  return (
    <StyledText onClick={handleOpenDialog}>{usersQuantity} {declOfNum(usersQuantity)}</StyledText>
  )
}