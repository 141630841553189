import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, memo } from 'react'
import { pimApi } from 'shared/api'
import { goodsTypeIcon } from 'shared/config/goods'

interface IconProps {
  goodsType?: pimApi.goods.GoodsType,
  color?: string
}

export const Icon: FC<IconProps> = ({ goodsType, color }) => (
  <>
    {goodsType ? <FontAwesomeIcon
      icon={goodsTypeIcon[goodsType]}
      color={color}
    /> : null}
  </>
)

export const MemoIcon = memo(Icon)