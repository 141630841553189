export const SINGLE_PACKAGING_TYPE_ID = 1
export const BOX_PACKAGING_TYPE_ID = 10

export const SINGLE_PACKAGING = {
  packagingtypeId: SINGLE_PACKAGING_TYPE_ID,
  unitQuantity: 1
}

export function getIsSinglePackagingType({ packagingtypeId }: { packagingtypeId?: number }): boolean { 
  return packagingtypeId === SINGLE_PACKAGING_TYPE_ID
}

export function getIsBoxPackagingType({ packagingtypeId }: { packagingtypeId?: number }): boolean { 
  return packagingtypeId === BOX_PACKAGING_TYPE_ID
}