import React from 'react'
import {
  useSimpleProductContext
} from 'entities/pim/product/model/simple/useSimpleProductContext'
import { Photos } from 'features/product/Simple/media/photos'

export const Media = () => {
  const { simpleProductQuery } = useSimpleProductContext()
  const isArchived = simpleProductQuery.data?.inArchive
  
  return (
    <Photos disabled={isArchived} photos={simpleProductQuery?.data?.photos}/>
  )
}