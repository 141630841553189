import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { api } from '../base'
import { ReportParameterWithValues } from '../../lib/generated/reports/Api'

interface Parameters {
  id: number,
  value: any
}

interface getParametersListPayload {
  reportId : UniqueId;
  params?: Array<Parameters>
  enabled?: boolean
}

export const getParametersListQueryKey = ({ reportId, params }: getParametersListPayload) =>
  ['get', `/reports/report/${reportId}/parameters/values`, { reportId, params }] as const

type ParametersListQueryKey = ReturnType<typeof getParametersListQueryKey>

function getParametersList( { reportId, params } : getParametersListPayload ) {
  return api.post<ReportParameterWithValues[]>
  (`/reports/report/${reportId}/parameters/values`, params).then((res) => res.data)
}
export const useGetParametersListQuery = (payload: getParametersListPayload) =>
  useQuery<
    ReportParameterWithValues[],
    AxiosError<errorType>,
    ReportParameterWithValues[],
    ParametersListQueryKey
  >(getParametersListQueryKey(payload),
    (context) => getParametersList(context.queryKey[2] as getParametersListPayload),
    { refetchOnMount: false, staleTime: Infinity, enabled: payload.enabled, keepPreviousData: true })
