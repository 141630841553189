import { useMemo, useState } from 'react'
import { ChangeSet } from '@devexpress/dx-react-grid'
import { tradeApi } from 'shared/api'

import {
  Same,
  Difference,
  EditQuantityStyle, EditableInput
} from './styled'

import { isNotNil } from '../../../../../../lib/checkers'

interface EditQuantityPropsType {
  value?: number
  row?: tradeApi.SupplyOrderProductPosition
  isLoading?: boolean
  onCellChange?: (changes: ChangeSet) => void
}

function round(value?: number | string) {
  if (value) {
    const toNumber = Number(value)
    return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(toNumber)
  } return '0'
}

export const EditQuantity = ({ value, row, isLoading, onCellChange }: EditQuantityPropsType) => {
  const MAX_QUANTITY = 100000
  const reg = new RegExp('^[0-9]+$')
  const [inputValue, setInputValue] = useState<any>(value)
  const calcQuantity = row?.calculatedQuantity

  const difference = useMemo(() => {
    let dif = 0
    if (isNotNil(value) && isNotNil(calcQuantity)) {
      dif = (inputValue !== value ? +inputValue : +value) - +calcQuantity
      if (dif === 0) {
        return null
      }
      return String(dif > 0 ? `+${round(dif)}` : round(dif))
    }
    return null
  }, [value, calcQuantity, isLoading, inputValue])

  const handleQuantityChange = (event) => {
    const newValue = Number(event.target.value.replace(/\s/g, ''))

    if (onCellChange) {
      if (Number(value) !== newValue) {
        onCellChange({
          changed: {
            [row!.id]: {
              'quantity': newValue
            }
          }
        })
      }
    }
  }

  const onChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = event.target.value.replace(/\s/g, '')

    if ((reg.test(unmaskedValue) || unmaskedValue === '') && Number(unmaskedValue) < MAX_QUANTITY) {
      setInputValue(Number(unmaskedValue))
    }
  }


  return (
    <EditQuantityStyle isLoading={isLoading}>
      <EditableInput
        onFocus={(e) => e.target.select()}
        onChange={onChange}
        value={inputValue.toLocaleString('ru-RU')}
        onBlur={handleQuantityChange}/>
      { difference
        ? <Difference> { difference }</Difference>
        : <Same/>
      }
    </EditQuantityStyle>
  )
}
