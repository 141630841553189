/**
 * Модуль инициализации env-переменных
 * @remark Если не найдено значение хоть одной переменной,
 * Приложение сразу выбросит ошибку, при инициализации модуля
 * @module
 */

/**
 * Получение env-переменной
 * @throwable
 */
const getEnvVar = (key: string) => process.env[key] || null

/** WSS entrypoint */
export const SOCKET_URL = getEnvVar('REACT_APP_SOCKET_URL')

export const ENV_TAG = getEnvVar('REACT_APP_ENV_TAG') || 'dev'

/** Режим запуска программы */
export const NODE_ENV = getEnvVar('NODE_ENV')
/** Режим разработки */
export const isDevEnv = ENV_TAG === 'dev'
/** Режим продакшена */
export const isProdEnv = ENV_TAG === 'prod'