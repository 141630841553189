/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CoPackingOrder } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CoPackingOrder>

export type IRequest = CamelCasedPropertiesDeep<{
  copackingorderId: number,
  query?: {
  /**
   * Only Diffs
   * Только расхождения
   * @default true
   */
    only_diffs?: boolean,

}
}>

export const apiFunction = ({ copackingorderId,query }: IRequest) =>
  api.get<ResponseType>(`/trade/orders/copackingorders/${copackingorderId}`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ copackingorderId,query }: IRequest) => ['get', `/trade/orders/copackingorders/${copackingorderId}`, { copackingorderId,query }] as const

export const useGetCopackingOrder = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

