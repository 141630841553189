import { Form } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { Box, Paper, Typography } from '@mui/material'
import { diff } from 'deep-object-diff'
import { snackActions } from 'shared/lib/react/snackbar'
import arrayMutators from 'final-form-arrays'
import { useQueryClient } from 'react-query'
import * as React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import useYupValidation from 'shared/lib/hooks/useYupValidation'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { customerGen } from 'shared/lib/generated'
import { UserMethod } from 'shared/lib/generated/customer/Api'

import { EditUserForm } from './ui/EditUserForm'
import { PasswordEditForm } from './ui/PasswordEditForm'
import { passwordValidation, validationSchema } from './lib/validationSchema'
import { AccessSection } from './ui/AccessSection'

export const EditUser = ({ userData }: { userData: customerGen.administration.GetUserInfo.ResponseType }) => {
  const validation = useYupValidation(validationSchema)
  const passValidation = useYupValidation(passwordValidation)
  const queryClient = useQueryClient()

  const { mutate: editUser } = customerGen.administration.EditUser.useEditUser()

  const { mutate: patchUser, isLoading: isSending } =
    customerGen.administration.PatchUser.usePatchUser()

  const handleSaveChanges = (values: pimApi.admin.EditingUser) => {
    if (
      userData &&
      values &&
      Object.keys(diff(values, userData)).length !== 0
    ) {
      editUser(
        {
          userId: values.id,
          data: {
            id: values.id,
            versionNo: values.versionNo,
            name: values.name,
            login: values.login,
            email: values.email,
            password: values.password,
            passwordExpired: values.passwordExpired,
            telegramCode: values.telegramCode
          },
        },
        {
          onSuccess: (response) => {
            queryClient.setQueryData(
              customerGen.administration.GetUserInfo.getQueryKey({
                userId: userData.id,
              }),
              response
            )
            if (values.password) {
              snackActions.info('Пароль установлен')
            } else if (userData.passwordExpired !== values.passwordExpired) {
              snackActions.info('Дата истечения пароля установлена')
            } else {
              snackActions.info('Изменение сохранено!')
            }
          },
        }
      )
    }
  }

  const handleBlock = () => {
    patchUser(
      {
        userId: userData.id,
        data: {
          id: userData.id,
          versionNo: userData.versionNo,
        },
        query: { method: UserMethod.Block },
      },
      {
        onSuccess: (response) => {
          queryClient.setQueryData(
            customerGen.administration.GetUserInfo.getQueryKey({
              userId: userData.id,
            }),
            response
          )
        },
      }
    )
  }

  const handleUnblock = () => {
    patchUser(
      {
        userId: userData.id,
        data: {
          id: userData.id,
          versionNo: userData.versionNo,
        },
        query: { method: UserMethod.Unblock },
      },
      {
        onSuccess: (response) => {
          queryClient.setQueryData(
            customerGen.administration.GetUserInfo.getQueryKey({
              userId: userData.id,
            }),
            response
          )
        },
      }
    )
  }

  const handleSendPassword = () => {
    patchUser(
      {
        userId: userData.id,
        data: {
          id: userData.id,
          versionNo: userData.versionNo,
        },
        query: { method: UserMethod.SendPassword },
      },
      {
        onSuccess: (response) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <ModalContent
                close={close}
                variant="contained"
                closeBtnText="Закрыть"
                title={`Мы отправили ссылку для создания\nнового пароля ${userData.email}`}
              />
            ),
            onAccept: () => {},
          })
          queryClient.setQueryData(
            customerGen.administration.GetUserInfo.getQueryKey({
              userId: userData.id,
            }),
            response
          )
        },
      }
    )
  }

  const handleClose = () => {
    patchUser(
      {
        userId: userData.id,
        data: {
          id: userData.id,
          versionNo: userData.versionNo,
        },
        query: { method: UserMethod.Close },
      },
      {
        onSuccess: (response) => {
          queryClient.setQueryData(
            customerGen.administration.GetUserInfo.getQueryKey({
              userId: userData.id,
            }),
            response
          )
        },
      }
    )
  }

  return (
    <Box my={3}>
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          mb={3}
        >
          <Typography component="h1" variant="h3" fontSize="1.5rem">
            Настройка пользователя
          </Typography>
        </Box>
        <Box>
          <Form
            onSubmit={handleSaveChanges}
            validate={validation}
            initialValues={userData}
            mutators={{
              ...arrayMutators,
            }}
            render={({ handleSubmit, errors, dirtyFields }) => (
              <EditUserForm
                handleSubmit={handleSubmit}
                handleBlock={handleBlock}
                handleUnblock={handleUnblock}
                handleClose={handleClose}
                values={userData}
                errors={errors}
                dirtyFields={dirtyFields}
              />
            )}
          />

          <Form
            onSubmit={handleSaveChanges}
            validate={passValidation}
            initialValues={userData}
            mutators={{
              ...arrayMutators,
            }}
            render={({ handleSubmit, valid, dirty, errors, values }) => (
              <PasswordEditForm
                errors={errors}
                userData={userData}
                isSending={isSending}
                values={values}
                dirty={dirty}
                valid={valid}
                handleSendPassword={handleSendPassword}
                handleUserEdit={handleSubmit}
              />
            )}
          />

          <Paper
            sx={{ marginTop: '24px' }}
            elevation={0}
          >
            <AccessSection userData={userData}/>
          </Paper>
        </Box>
      </>
    </Box>
  )
}
