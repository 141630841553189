import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { EditCard } from 'shared/ui/layouts/EditCard'
import { Preloader } from 'shared/ui/components'
import { getProductEditPath } from 'shared/config/routes'

import { WBPriceHistory } from './WB'
import { OzonPriceHistory } from './Ozon'
import { SimpleProductPriceHistory } from './Simple'
import { MarketplaceId } from './config/types'

export const PriceHistory = () => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const [marketplaceId, setMarketplaceId] = useState<UniqueId | null>(null)
  const fetchCommonProductQuery =
    pimApi.products.common.useFetchCommonProductQuery(productId)

  useEffect(() => {
    if (fetchCommonProductQuery?.data?.productId === productId) {
      setMarketplaceId(fetchCommonProductQuery?.data?.marketplaceId)
    }
  }, [fetchCommonProductQuery?.data])

  if (marketplaceId === null) return <Preloader />

  const mapProductPriceHistoryComponentToMarketplace = {
    [MarketplaceId.WB]: WBPriceHistory,
    [MarketplaceId.OZON]: OzonPriceHistory,
  }
  const ProductPriceHistory =
    mapProductPriceHistoryComponentToMarketplace[marketplaceId] ??
    SimpleProductPriceHistory

  return (
    <EditCard title="История изменения цены" backUrl={getProductEditPath(productId)}>
      <ProductPriceHistory />
    </EditCard>
  )
}
