import React from 'react'
import { pimApi } from 'shared/api'
import { SettingsMenu } from 'shared/ui/components'
import { MoreVert } from '@mui/icons-material'

import { CategoryHeader } from '../styled'
import { CategorySettings } from '../../lib/CategorySettings'

export const Header = ({ categoryData }: { categoryData: pimApi.categories.Category }) => {
  const settingsOptions = CategorySettings(categoryData)

  const fullPathArray = categoryData?.fullPath?.split('/')
  return (
    <CategoryHeader>
      <div className="info-container">
        <span className="path-text">{fullPathArray?.join(' / ')}</span>
        <span className="category-name-text">{ categoryData.name }</span>
      </div>
      <div className="options-container">
        <SettingsMenu
          options={settingsOptions}
          iconType={<MoreVert />}
          style={{ width: '190px' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      </div>
    </CategoryHeader>
  )
}