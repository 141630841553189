import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid'
import { Plugin } from '@devexpress/dx-react-core'

import { SortSettings } from '../../model/types'

const ASC = 'asc'

export const TableSorting = ({
  columnsConfig,
  handleTableParams,
  sort,
  sorting,
  isStoredSettings,
  defaultSorting
}) => {

  const onSortingChange = (sortSettings: SortSettings[]) => {
    const prevSort = sort[0]
    const nextSort = sortSettings[0]

    if (prevSort?.columnName !== nextSort?.columnName && nextSort.direction === 'asc') {
      // eslint-disable-next-line no-param-reassign
      sortSettings[0].direction = 'desc'
    }

    const isRemoveSortSettings =
      prevSort?.columnName === nextSort?.columnName &&
      prevSort?.direction === ASC

    if (isRemoveSortSettings) {
      handleTableParams({ sort: [] })
      return
    }
    handleTableParams({ sort: sortSettings })
  }

  const internalSortingChange = (sortSettings: SortSettings[]) => {
    handleTableParams({ sort: sortSettings })
  }

  const internalSorting = sorting === 'internal'
  const externalSorting = sorting === 'external'

  return (
    <Plugin>
      <SortingState
        {...(externalSorting
          ? {
            columnExtensions: columnsConfig,
            sorting: sort,
            onSortingChange,
          }
          : isStoredSettings && {
            sorting: sort,
            onSortingChange: internalSortingChange,
          }
        )}
        defaultSorting={defaultSorting}
      />
      {internalSorting && <IntegratedSorting />}
    </Plugin>
  )
}
