import React, { memo } from 'react'

import { IAvatarProps } from './lib/types'
import { AvatarWidget } from './ui/AvatarWidget'


export const Avatar = memo(({ photo, altName, disabled, deletePhoto, uploadPhoto, isLoading }: IAvatarProps) => (
  <AvatarWidget
    photo={photo}
    altName={altName}
    isLoading={isLoading}
    disabled={disabled}
    deletePhoto={deletePhoto}
    uploadPhoto={uploadPhoto}
  />
))