import React, { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { Autocomplete, Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import {
  SelectWrapper,
  StyledFilterContainer,
} from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'

import { FilterWrapper } from './styled'

export const TableFilters = ({ dataParams, setDataParams }) => {
  const { data: brandListQuery } = pimApi.dictionaries.useBrandListQuery()
  const { data: marketplaceListQuery } =
    pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { contragentListQuery } = pimApi.dictionaries.useContragentList({
    onlySuppliers: true,
  })
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(
    dataParams.marketplaceId,
    dataParams.companyId
  )
  const { data: userListQuery } = pimApi.admin.useGetUsersListQuery()

  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery]
  )

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data]
  )

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const brandOptions = useMemo(
    () => brandListQuery?.map(getOptions) || [],
    [brandListQuery]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptions) || [],
    [contragentListQuery.data]
  )

  const userOptions = useMemo(
    () => userListQuery?.map(getOptions) || [],
    [userListQuery]
  )

  return (
    <>
      <FilterWrapper>
        <TextField
          className="searchInput"
          size="small"
          id="searchInput"
          variant="standard"
          autoComplete="off"
          placeholder="Поиск"
          defaultValue={dataParams.searchString}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.getElementById('searchInput')?.blur()
            }
          }}
          onBlur={(e) => {
            setDataParams((prevState) => ({
              ...prevState,
              searchString: e.target.value,
            }))
          }}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />,
          }}
        />
        <StyledFilterContainer>
          <SelectWrapper width={200}>
            <Autocomplete
              options={marketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={
                marketplaceOptions.find(
                  (el) => dataParams.marketplaceId === el.value
                ) || null
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setDataParams((prev) => ({
                    ...prev,
                    marketplaceId: null,
                    companymarketplaceId: null,
                  }))
                } else {
                  setDataParams((prev) => ({
                    ...prev,
                    marketplaceId: value?.value,
                  }))
                }
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.marketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Маркетплейс" />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companyOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={
                companyOptions.find(
                  (el) => dataParams.companyId === el.value
                ) || null
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setDataParams((prev) => ({
                    ...prev,
                    companyId: null,
                    companymarketplaceId: null,
                  }))
                } else {
                  setDataParams((prev) => ({
                    ...prev,
                    companyId: value?.value,
                  }))
                }
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.companyId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Продавец" />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companymarketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disabled={companymarketplaceOptions.length === 0}
              disableCloseOnSelect={true}
              size="small"
              value={
                companymarketplaceOptions.find(
                  (el) => dataParams.companymarketplaceId === el.value
                ) || { label: '', value: 0 }
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams((prev) => ({
                  ...prev,
                  companymarketplaceId: value?.value,
                }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.companymarketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Кабинет" />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={brandOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={
                brandOptions.find((el) => dataParams.brandId === el.value) ||
                null
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams((prev) => ({ ...prev, brandId: value?.value }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.brandId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => <TextField {...params} label="Бренд" />}
            />
          </SelectWrapper>
          <SelectWrapper width={180}>
            <Autocomplete
              options={contragentOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={
                contragentOptions.find(
                  (el) => dataParams.contragentId === el.value
                ) || null
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams((prev) => ({
                  ...prev,
                  contragentId: value?.value,
                }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.contragentId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик" />
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={180}>
            <Autocomplete
              options={userOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={
                userOptions.find((el) => dataParams.changerId === el.value) ||
                null
              }
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams((prev) => ({ ...prev, changerId: value?.value }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.changerId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Пользователь" />
              )}
            />
          </SelectWrapper>
        </StyledFilterContainer>
      </FilterWrapper>
    </>
  )
}
