import { Wrapper } from 'shared/ui/goods/common'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'

import { Photos, Videos } from './ui'

export const Media = ({ isArchived }: { isArchived?: boolean }) => {
  const { wbProductQuery } = useWBProductContext()

  const currentNomenclaturePhotos =
    wbProductQuery?.data?.photos
  const currentNomenclatureVideo = wbProductQuery?.data?.video

  // const archivedVariations = wbProductQuery?.data?.nomenclatures[
  //   currentNomenclatureIndex
  // ].variations.filter((variation) => variation?.inArchive)

  // const isArchived = archivedVariations?.length === wbProductQuery?.data?.nomenclatures[
  //   currentNomenclatureIndex
  // ].variations.length

  return (
    <Wrapper>
      <Photos
        photos={currentNomenclaturePhotos}
        disabled={isArchived}
      />
      <Videos
        videos={currentNomenclatureVideo}
        disabled={isArchived}
      />
    </Wrapper>
  )
}
