import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

export const DateCell = ({ date }: { date?: string }) => {
  if (!date) return null

  // const currentDate = new Date(date)
  // const dateWithLocaleOffset = new Date(currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset()))

  return (
    <>
      <span>{format(new Date(date), DateFormats.dayMonthYear)}</span>{' '}
      <span>{format(new Date(date), DateFormats.hours24Minutes)}</span>
    </>
  )
}
