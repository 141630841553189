import { pimApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { api } from 'shared/api/base'

interface IAddNewCompetitor {
  goodsId: UniqueId,
  newCompetitor: pimApi.goods.NewCompetitor
}

const postNewCompetitor = ({ goodsId, newCompetitor }: IAddNewCompetitor ) =>
  api.post<pimApi.goods.Competitor>(
    `/pim/goods/${goodsId}/competitors`,
    newCompetitor
  )

export const usePostNewCompetitor = () => 
  useMutation<
        AxiosResponse<pimApi.goods.Competitor>,
        AxiosError<errorType>,
        IAddNewCompetitor
    >(postNewCompetitor)
