import {
  TMediaType
} from 'shared/services/interfaces/product'
import { QueryKey, useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import * as GoodsMediaApi from 'shared/api/pim/goods/goodsMedia'
import { pimApi } from 'shared/api'
import { GoodsMedia, EditGoodsFile, GoodsFile, NewGoodsFileURL } from 'shared/api/pim/goods/models'

export const getGetPimGoodsMediaQueryKey = (goodsId?: UniqueId) =>
  ['get', 'pim/goods/media', goodsId] as const

function fetchGoodsMedia(goodsId: UniqueId) {
  return pimApi.goods.getGoodsMedia(goodsId).then((res) => res.data)
}

export const useGetGoodsMedia = (goodsId: UniqueId) => useQuery<GoodsMedia, errorType, GoodsMedia, QueryKey>(
  getGetPimGoodsMediaQueryKey(goodsId),
  (context) => fetchGoodsMedia(context.queryKey[2] as UniqueId)
)

export const useMediaCreate = () => {
  const { id } = useParams<{ id: string }>()
  const goodsIdUrl = parseInt(id, 10)
  const getGoodsMedia = useGetGoodsMedia(goodsIdUrl)

  const { mutate, isLoading } = useMutation<
    GoodsFile,
    errorType,
    {
      file: Blob | null,
      goodsId: UniqueId,
      mediaType: TMediaType,
      callback?: (newGoodsId: UniqueId, createdId?: UniqueId) => void
        }
        >(({ file, goodsId, mediaType }) => {
          const data = new FormData()

          data.append('file', file || '')
          return GoodsMediaApi.createGoodsMedia({ file: data, goodsId, mediaType })
            .then((res) => res.data)
        }, {
          onSuccess: (data, context) => {
            context.callback?.(context.goodsId, data.id)
            getGoodsMedia.refetch()
          }
        })

  return { mediaCreate: mutate, mediaCreateInProgress: isLoading }
}

export const useMediaUpdate = () => {
  const { id } = useParams<{ id: string }>()
  const goodsIdUrl = parseInt(id, 10)
  const getGoodsMedia = useGetGoodsMedia(goodsIdUrl)

  const { mutate } = useMutation<
    AxiosResponse<EditGoodsFile>,
    errorType,
    {
      value: EditGoodsFile,
      goodsId: UniqueId
    }
    >(({ value, goodsId }) =>
      GoodsMediaApi.updateGoodsMedia(
        // @ts-ignore
        { ...value, goodsId }
      )
    , {
      onSuccess: () => {
        getGoodsMedia.refetch()
      }
    })

  return { mediaUpdate: mutate }
}

export const useMediaDelete = () => {
  const { id } = useParams<{ id: string }>()
  const goodsIdUrl = parseInt(id, 10)
  const getGoodsMedia = useGetGoodsMedia(goodsIdUrl)

  const { mutate } = useMutation<
    AxiosResponse<GoodsFile>,
    errorType,
    {
      value: GoodsFile,
      goodsId: UniqueId,
      callback?: () => void
        }
        >(({ value, goodsId }) =>
          GoodsMediaApi.deleteGoodsMedia({ ...value, goodsId })
        , {
          onSuccess: (data, context) => {
            context.callback?.()
            getGoodsMedia.refetch()
          }
        })

  return { mediaDelete: mutate }
}

export const useMediaUrlCreate = () => {
  const { id } = useParams<{ id: string }>()
  const goodsIdUrl = parseInt(id, 10)
  const getGoodsMedia = useGetGoodsMedia(goodsIdUrl)

  const { mutate } = useMutation<
    AxiosResponse<NewGoodsFileURL>,
    errorType,
    {
      formValues: NewGoodsFileURL,
      goodsId: UniqueId,
      mediaType: TMediaType
    }
    >(({ formValues, goodsId, mediaType }) =>
      GoodsMediaApi.createUrlGoodsMedia({ ...formValues, goodsId, mediaType })
    , {
      onSuccess: () => {
        getGoodsMedia.refetch()
      }
    })

  return { mediaUrlCreate: mutate }
}

export const useMediaSwap = () => {
  const { id } = useParams<{ id: string }>()
  const goodsIdUrl = parseInt(id, 10)
  const getGoodsMedia = useGetGoodsMedia(goodsIdUrl)

  const { mutate } = useMutation<
    [AxiosResponse<EditGoodsFile>, AxiosResponse<EditGoodsFile>],
    void,
    {
      leftMedia: EditGoodsFile,
      rightMedia: EditGoodsFile,
      goodsId: UniqueId
    }
      >(({ leftMedia, rightMedia, goodsId }) => Promise.all([
        GoodsMediaApi.updateGoodsMedia({
          ...leftMedia,
          // @ts-ignore
          indexNumber: rightMedia.indexNumber,
          goodsId
        }),
        GoodsMediaApi.updateGoodsMedia({
          ...rightMedia,
          // @ts-ignore
          indexNumber: leftMedia.indexNumber,
          goodsId
        })
      ]
      ), {
        onSuccess: () => {
          getGoodsMedia.refetch()
        }
      })

  return { mediaSwap: mutate }
}