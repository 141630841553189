import { smallOrdersLayout } from './Orders/small/SmallOrdersLayout'
import { doubleSmallOrdersAndStocksLayout } from './OrdersAndStocks/doubleSmall/DoubleSmallOrdersAndStocksLayout'
import { mediumOrdersAndStocksLayout } from './OrdersAndStocks/medium/MediumOrdersAndStocksLayout'
import { largeOrdersAndStocksLayout } from './OrdersAndStocks/large'
import { doubleSmallSalesDynamicsLayout } from './SalesDynamics/doubleSmall/DoubleSmallSalesDynamicsLayout'
import { mediumSalesDynamicsLayout } from './SalesDynamics/medium/MediumSalesDynamicsLayout'
import { smallStocksLayout } from './Stocks/small/SmallStocksLayout'
import { smallWeeklySalesLayout } from './WeeklySales/small/SmallWeeklySalesLayout'
import { doubleSmallWeeklySalesLayout } from './WeeklySales/doubleSmall/DoubleSmallWeeklySalesLayout'
import { mediumWeeklySalesLayout } from './WeeklySales/medium/MediumWeeklySalesLayout'
import { largeWeeklySalesLayout } from './WeeklySales/large/LargeWeeklySalesLayout'
import { largeSalesDynamicsLayout } from './SalesDynamics/large'
import { mediumAbcXyzLayout } from './AbcXyz/medium'
import { smallSynchronizedLayout } from './SynchronizedProducts/small'
import { smallActiveProductsLayout } from './activeProducts/small'

export const WidgetList: Record<string, any> = {
  'orders': {
    smallSize: smallOrdersLayout,
  },
  'ordersAndStocks': {
    doubleSmallSize: doubleSmallOrdersAndStocksLayout,
    mediumSize: mediumOrdersAndStocksLayout,
    largeSize: largeOrdersAndStocksLayout,
  },
  'salesDynamics': {
    doubleSmallSize: doubleSmallSalesDynamicsLayout,
    mediumSize: mediumSalesDynamicsLayout,
    largeSize: largeSalesDynamicsLayout,
  },
  'abcXyz': {
    mediumSize: mediumAbcXyzLayout,
  },
  'stocks': {
    smallSize: smallStocksLayout
  },
  'weeklySales': {
    smallSize: smallWeeklySalesLayout,
    doubleSmallSize: doubleSmallWeeklySalesLayout,
    mediumSize: mediumWeeklySalesLayout,
    largeSize: largeWeeklySalesLayout
  },
  'archived': {
    smallSize: smallSynchronizedLayout
  },
  'active': {
    smallSize: smallActiveProductsLayout
  }
}