import { ExcelReportSettings } from 'shared/lib/exports/excel/types'

export const ExcelTemplate = <ExcelReportSettings>{
  columns: [
    { caption: 'Дата изменения', alignment: { horizontal: 'center' }, width: 20 },
    { caption: 'Цена', width: 10 },
    { caption: 'Скидка', width: 10 },
    { caption: 'Цена до скидки', width: 10 },
    { caption: 'Причина изменения', width: 40 },
    { caption: 'Комментарии', width: 60 },
    { caption: 'Пользователь', width: 20 }
  ],
  fileName: 'pricehistory',
  sheetName: 'История изменения цены'
}