import { tradeGen } from 'shared/lib/generated'
import { EmptyContainer } from 'shared/ui/components/EmptyContainer'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import * as React from 'react'
import { sort } from 'shared/lib/utils/sort'
import { isNotNil } from 'shared/lib/checkers'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { TabsWrapper } from './styled'

import { filteringTabsByRoles } from '../lib/FilteringTabsByRoles'

export const SupplyOrdersTabs = () => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })

  const { tabStatusId, statusId, page, ...queryParams } = searchObj

  const { data: supplyOrderStatuses } = tradeGen.orders.GetStatSupplyOrders.useGetStatSupplyOrders({ query: queryParams })

  const handleChangeTabValue = (_, newValue) => {
    handleChangeParams({
      params: {
        tabStatusId: newValue !== -1 ? newValue : null,
        statusId: null
      },
      options: {
        skipEmptyString: true,
        skipNull: true,
        encode: true
      }
    })
  }

  if (supplyOrderStatuses) return (
    <TabsWrapper>
      <Tabs
        allowScrollButtonsMobile={true}
        variant="scrollable"
        scrollButtons="auto"
        value={isNotNil(tabStatusId) ? tabStatusId : -1}
        onChange={handleChangeTabValue}
        aria-label="basic tabs example">
        {sort(supplyOrderStatuses, 'id', 'desc').map((status, index) => {
          if (filteringTabsByRoles(status)) {
            return (
              <Tab
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                value={status.id}
                sx={{ padding: '12 24', minWidth: '185px' }}
                label={`${status.name} ${status.count}`}
                { ...a11yProps(index) }
              />
            )
          }
          return null
        })}
      </Tabs>
    </TabsWrapper>
  )
  return <EmptyContainer height={48}/>
}