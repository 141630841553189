import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'


export const cabinetValidationSchema = yup.object().shape({

  id: yup
    .string()
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  cabinetName: yup
    .string()
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),
})
