import { Form } from 'react-final-form'
import {
  Box,
  Typography,
} from '@mui/material'

import { MarketplaceForm } from './ui/MarketplaceForm'
import { CommonFields } from './ui/CommonFields'
import { StyledLink } from './ui/styled'

const INSTRUCTION_LINK = 'https://customer.e-comportal.com/cdn/media/products_edit_instruction'

export const BulkProductEdit = () => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize="24px" fontWeight={400}>
        Массовое изменение продуктов
      </Typography>
      <StyledLink href={INSTRUCTION_LINK} color="inherit">Инструкция массового изменения продуктов</StyledLink>
    </Box>

    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CommonFields />
          <MarketplaceForm />
        </form>
      )}
    />
  </Box>
)
