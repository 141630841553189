export const ChangesCell = ({ changes }: { changes: any }) => (
  <>
    {changes.map((change) => (
      <span key={change.field}>
        {`${change.field} - ${change.value} ${change.url ? change.url : ''} `}
        <br />
      </span>
    ))}
  </>
)
