import styled from 'styled-components'

export const StyledPrice = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;

  #current-price {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.15px;
    margin-top: auto;
    margin-bottom: 0;
  }

  #old-price-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    gap: 8px;

    #old-price-text {
      color: #00000099;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4000000059604645px;
    }

    #discount-container {
      width: auto;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #2196F3;
      padding: 0 6px;
      border-radius: 64px;
      border: 1px solid #007DFF;
      margin-top: auto;
      margin-bottom: 4px;
    }
  }
`