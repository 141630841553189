import { Typography , Alert } from '@mui/material'
import styled from 'styled-components'

export const AlertTitleStyled = styled(Typography)<{ disabled: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  ${({ disabled }) => disabled && 'color: rgba(0, 0, 0, 0.38)'};
`
export const AlertStyled = styled(Alert)<{ disabled: boolean, $hasMessage: boolean }>`
  padding: 8px 16px 8px 10px;
  ${({ $hasMessage }) => $hasMessage && 'padding-bottom: 12px'};
  ${({ disabled }) => disabled && 'background-color: #EBEBEB'};
  .MuiAlert-icon {
    margin-right: 8px;
    padding: 0;
    ${({ disabled }) => disabled && 'color: rgba(0, 0, 0, 0.38)'};
  };
  .MuiAlert-message { 
    padding: 0;
  }
`
export const AlertMessageStyled = styled(Typography)<{ disabled: boolean }>`
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.15px;
  ${({ disabled }) => disabled && 'color: rgba(0, 0, 0, 0.38)'};
`
