import React from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'

import { Wrapper } from './styled'


export const TypographyStyled = styled(Typography)`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 22px;
  line-height: 133.4%;
  white-space: break-spaces;
`

interface Props {
  email: string
}
export const CorrectEmail = ({ email }: Props) => (
  <Wrapper>
    <TypographyStyled>{'Ссылка отправлена\nна вашу почту'}</TypographyStyled>
    <Typography mb={5} fontSize={14}>{email}</Typography>
    <Typography
      marginBottom="42px"
      color="rgba(0, 0, 0, 0.6)">
      Для создание нового пароля перейдите по ссылке в письме</Typography>
  </Wrapper>
)
