type List = Array<any>

export function sort<L extends List = List>(list: L, field: string, sortDirection: 'asc' | 'desc' = 'asc'): L {
  function sortBy(a: { [x: string]: number }, b: { [x: string]: number }) {
    if (sortDirection === 'asc') {
      if (a[field] < b[field]) {
        return 1
      }
      if (a[field] > b[field]) {
        return -1
      }
      return 0
    }
    if (a[field] < b[field]) {
      return -1
    }
    if (a[field] > b[field]) {
      return 1
    }
    return 0
  }

  return list.sort(sortBy)
}

export function defaultSort<L extends List = List>(list: L, sortDirection: 'asc' | 'desc' = 'asc'): L {
  function sortBy(a: { [x: string]: number }, b: { [x: string]: number }) {
    if (sortDirection === 'asc') {
      if (a < b) {
        return 1
      }
      if (a > b) {
        return -1
      }
      return 0
    }
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  return list.sort(sortBy)
}