import { useState, useEffect, useRef, useMemo } from 'react'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { useField, useForm } from 'react-final-form'
import { productModel } from 'entities/pim/product'
import { ozonproxyApi, pimApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { Attribute } from 'shared/api/pim/goods'

import { NormalizedAttributes, getNormalizedAttributes, OzonMapingAttribute, ProductAttributeValue } from '../lib/getNormalizedAttributes'

const getOptionFromDictionary = ({
  value,
  id
}: ozonproxyApi.model.AttributeValue): Option<ProductAttributeValue> => ({
  label: value,
  value: {
    value,
    dictionaryValueId: id
  }
})


export const useNormalizedAttributes = () => {
  const goods = useGoodsContext()
  const { commonProductQuery: commonProduct } = useCommonProductContext()
  const goodsAttributes: Array<Attribute> = goods?.attributes ?? []

  const { change } = useForm<productModel.ozonModel.EditFormValues>()

  const attributes = useField<Array<pimApi.products.OzonProductAttribute>>('attributes').input.value
  const categoryId = useField<UniqueId>('categoryId').input.value
  const parentCategoryId = useField('parentCategoryId').input.value

  const productAttributes: Array<OzonMapingAttribute> = useMemo(() =>
    attributes
      .map((ozonProductAttribute) => ({
        id: ozonProductAttribute.id,
        attributeId: ozonProductAttribute?.attributeId,
        attributeName: ozonProductAttribute.name,
        values: ozonProductAttribute.values as Array<ProductAttributeValue>,
        required: ozonProductAttribute?.isRequired,
        multipleValues: ozonProductAttribute.isCollection,
        maxCount: ozonProductAttribute.maxValueCount,
        isAspect: ozonProductAttribute.isAspect,
        description: ozonProductAttribute.description,
        valueType: ozonProductAttribute.type?.toLowerCase(),
        fetchCatalog: ozonProductAttribute.isDictionary ?
          (search: string) => ozonproxyApi.fetchAttributeValues({
            searchString: search,
            attributeId: ozonProductAttribute.ozonAttributeId,
            descriptionCategoryId: parentCategoryId,
            typeId: categoryId,
            companymarketplaceId: commonProduct?.data?.companymarketplaceId!
          })
            .then(
              data => data.data.map(
                getOptionFromDictionary
              )
            )
          : undefined
      }
      )),
  [attributes]
  )

  const [normalizedAttributes, setNormalizedAttributes] = useState<NormalizedAttributes>(
    getNormalizedAttributes(
      goodsAttributes,
      productAttributes
    )
  )


  const firstMount = useRef<boolean>(true)

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false
      return
    }

    const newNormalizedAttributes = getNormalizedAttributes(goodsAttributes, productAttributes)

    setNormalizedAttributes(newNormalizedAttributes)
  }, [goodsAttributes, productAttributes])


  const changeOzonAttributeProperty = (
    conditionAttributeId: number,
    productAttributeProperties: { attributeId?: null | number, values?: Array<ProductAttributeValue> }
  ) => {
    const index = attributes!.findIndex((attribute) => attribute!.id! === conditionAttributeId)

    Object.entries(productAttributeProperties).forEach(([key, value]) => {
      // @ts-ignore
      change(`attributes.${index}.${key}`, value)
    })
  }

  return {
    normalizedAttributes,
    setNormalizedAttributes,
    changeOzonAttributeProperty
  } as const
}