import { ButtonGroup } from '@mui/material'
import React from 'react'

import { StyledCountButtonGroup } from '../../../../styled'

export const TemplateFilter = ({ reviewType, setReviewType }: { reviewType: number, setReviewType: (e) => void }) => (
  <div style={{ padding: '16px 24px' }}>
    <ButtonGroup size="large" variant="text" aria-label="text button group">
      <StyledCountButtonGroup
        $active={reviewType === 1}
        onClick={() => {
          setReviewType(1)
        }}
      >
        Шаблоны для отзывов
      </StyledCountButtonGroup>

      <StyledCountButtonGroup
        $active={reviewType === 2}
        onClick={() => {
          setReviewType(2)
        }}
      >
        Шаблоны для вопросов
      </StyledCountButtonGroup>
    </ButtonGroup>
  </div>
)