import React, { useMemo, useState } from 'react'
import Chart, {
  Series,
  Legend,
  CommonSeriesSettings,
  Point,
  Label,
  Tooltip,
  Tick,
  ArgumentAxis,
  ZoomAndPan,
  ValueAxis,
  Grid,
  Font
} from 'devextreme-react/chart'
import RangeSelector, {
  Size,
  Chart as ChartOptions,
  Margin,
  Label as RangeLabel,
  Scale,
  Behavior,
  SliderMarker, MinorTick, Marker
} from 'devextreme-react/range-selector'
import { Box, Typography } from '@mui/material'
import { convertDateWithYear } from 'shared/lib/utils/DateAppearanceSettings'
import { round } from 'shared/lib/utils/round'
import queryString from 'query-string'
import { mergeArrayByKey } from 'shared/lib/utils/mergeArrayByKey'
import { useHistory } from 'react-router'
import { isNil } from 'shared/lib/checkers'
import { useChartContext } from 'features/analytics/chart/lib/useChartContext'
import { aggregateSalesData } from 'features/analytics/chart/lib/aggregateData'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ChartDetails } from './ChartDetails'
import { StyledDot, StyledLoader, StyledTooltip } from './styled'

import { customDate } from '../../components/customDate'


interface dataType {
  orders: number
  sales: number
  orderDate: string
}

export const Charts = ({ isLoading }: { isLoading: boolean }) => {
  const history = useHistory()
  const { value: context } = useChartContext()
  const { searchObj, ordersData, salesData } = context

  const { timelineStart, timelineEnd } = searchObj

  const rangeSelector = {
    startValue: timelineStart, endValue: timelineEnd
  }

  const handleChangeTimelineParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace(`?${historyStr}`)
  }

  const timelineData: Array<dataType> | any = useMemo(() => {
    const data = mergeArrayByKey('orderDate', ordersData?.data?.data, salesData?.data?.data.map((elem) => ({
      orderDate: elem.saleDate,
      quantity: elem.quantity
    }))).map((el) => ({
      orders: el[searchObj.units! === 'items' ? 'items' : 'currency'],
      sales: salesData?.data.data.filter((elem) => elem.saleDate === el.orderDate).reduce((sum, elem) => sum + elem.quantity, 0),
      orderDate:el.orderDate,
      numberOfOrders: el.numberOfOrders,
      ordersItems: el.items,
      ordersCurrency: el.currency,
    }))
    return data && isNotEmptyArray(data) ? aggregateSalesData(data, searchObj.groupby || 'day') : []
  }, [ordersData, salesData, searchObj.groupby])

  const rangeData: Array<dataType> | any = useMemo(() => timelineData.map((el) => ({
    orders: el.orders,
    sales: el.sales,
    period: el.period
  }
  )), [ordersData, salesData, searchObj.groupby])


  const [hiddenSeries , setHiddenSeries] = useState({ orders: true, sales: true })

  const updateVisualRange = (e) => {
    const startValue = Array.isArray(e) ? e[0] : e.startValue
    const endValue = Array.isArray(e) ? e[1] : e.endValue
    if (Array.isArray(e) || isNil(e.categories)) {
      handleChangeTimelineParams({
        timelineStart: startValue,
        timelineEnd: endValue,
      })
    }
  }

  const renderTooltip = (props) =>
    <CustomTooltip
      x={props}
      timelineData={timelineData}
    />

  return (
    <>
      {isLoading && <StyledLoader size={60}/>}
      <ChartDetails
        setHiddenSeries={setHiddenSeries}
        groupby={searchObj.groupby}
        timelineData={timelineData}
        visualRange={rangeSelector}
        hiddenSeries={hiddenSeries}
      />
      <Chart
        palette="Harmony Light"
        dataSource={timelineData}
      >
        <Series
          argumentField="period"
          visible={hiddenSeries.orders}
          valueField="orders"
          type={searchObj.chartType === 'diagram' ? 'bar' : 'spline'}
          color="#7BC67E"
          hoverMode="none"
          name="Заказы"
        >
          <Point hoverMode="none"/>
        </Series>
        <Series
          argumentField="period"
          visible={hiddenSeries.sales}
          valueField="sales"
          axis="sales"
          type={searchObj.chartType === 'diagram' ? 'bar' : 'splinearea'}
          color="#bcdffb"
          name="Выкупы">
          <Point hoverMode="none"/>
        </Series>
        <ArgumentAxis
          color="#E0E0E0"
          argumentType="string"
          onVisualRangeChange={updateVisualRange}
          visualRange={rangeSelector}
          discreteAxisDivisionMode="crossLabels"
        >
          <Label customizeText={customDate}/>
          <Tick color="#E0E0E0"/>
        </ArgumentAxis>
        <Margin top={24}/>
        <ValueAxis name="orders" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#4CAF50"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Tooltip
          enabled={true}
          shared={true}
          interactive={false}
          cornerRadius={4}
          border={{ opacity: 0 }}
          color="#FFFFFF00"
          paddingLeftRight={0}
          paddingTopBottom={0}
          shadow={{ blur: 0, offsetY: 0, offsetX: 0, opacity: 0 }}
          contentRender={renderTooltip}
        />
        <ValueAxis position="right" name="sales" showZero={false} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#2196F3"/>
          </Label>
          <Grid visible={true} color="#E0E0E0"/>
        </ValueAxis>
        <Legend visible={false}/>
        <ZoomAndPan
          argumentAxis="zoom"
          allowTouchGestures={true}
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="shift"/>
        <CommonSeriesSettings cornerRadius={2} aggregation={{ enabled: false }} argumentField="orderDate" hoverMode="none">
          <Point size={6}/>
        </CommonSeriesSettings>
      </Chart>

      <RangeSelector
        dataSource={rangeData}
        value={rangeSelector}
        onValueChange={updateVisualRange}
      >
        <Size height={100} />
        <Scale 
          type="continuous"
          valueType="string">
          <MinorTick visible={false}/>
          <Marker visible={false}/>
          <RangeLabel customizeText={customDate} />
        </Scale>
        <SliderMarker
          paddingTopBottom={4}
          paddingLeftRight={8}
          color="#F5F5F5"
          customizeText={customDate}>
          <Font color="rgba(0, 0, 0, 0.6)" size={11} weight={600}/>
        </SliderMarker>
        <Behavior callValueChanged="onMovingComplete"/>
        <ChartOptions>
          <CommonSeriesSettings type="spline" />
          <Series visible={hiddenSeries.orders} argumentField="period" valueField="orders" color="#4CAF50"/>
          <Series visible={hiddenSeries.sales} type="splinearea" argumentField="period" valueField="sales" color="#BCDFFB"/>
          <ValueAxis/>
          <ValueAxis/>
        </ChartOptions>
      </RangeSelector>
    </>
  )
}

const numberWithSpaces = (x) => x.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const CustomTooltip = ({ x, timelineData }) => {
  const tick = timelineData.filter((el) => x.argumentText === el.period)[0]

  const orders = x.points.find(el => el.seriesName === 'Заказы')
  const sales = x.points.find(el => el.seriesName === 'Выкупы')

  return (
    <StyledTooltip>
      {((tick?.startDate && tick?.endDate) || tick?.period) &&
        <Typography sx={{ fontSize: '12px', marginBottom: '12px' }}>
          {tick?.startDate && tick?.endDate ?
            `${convertDateWithYear(tick.startDate)} - ${convertDateWithYear(tick.endDate)}` :
            `${convertDateWithYear(tick?.period)}`
          }
        </Typography>
      }
      {orders &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
          <StyledDot color="#4CAF50"/>
          <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Заказы</Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#4CAF50',
              marginLeft: 'auto'
            }}>
            {round(orders?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
      }
      {sales &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
          <StyledDot color="#2196F3"/>
          <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Выкупы</Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#2196F3',
              marginLeft: 'auto'
            }}>
            {round(sales?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
      }
    </StyledTooltip>
  )
}