import { useState } from 'react'
import { Box } from '@mui/material'

import { ModalContent } from './ModalContent'

interface ModalProps {
  close: () => void
  accept: () => void
  title?: string,
  acceptBtnText?: string,
  acceptBtnColor?: 'error' | 'primary' | undefined,
  variant?: 'text' | 'contained' | undefined
}

export const DefaultModal = ({ 
  close,
  accept,
  title = 'Вы уверены, что хотите продолжить?',
  acceptBtnText = 'ПРОДОЛЖИТЬ',
  acceptBtnColor = 'error',
  variant = 'text'
  
}: ModalProps) => {
  const [isLoading, setIsloading] = useState(false)
  const handleAccept = () => {
    setIsloading(true)
    accept()
  }
  
  return (
    <Box width="500px">
      <ModalContent
        close={close}
        accept={handleAccept}
        title={title}
        acceptBtnText={acceptBtnText}
        acceptBtnColor={acceptBtnColor}
        isLoading={isLoading}
        variant={variant}
      />
    </Box>
  )
}
