import React from 'react'

export const RepriceCurrentPrice = ({ row }: { row: any }) => {
  const { price, margin, marginPercents, adviceDate } = row
  function round(value?: number | string, dig?: number) {
    if (value) {
      let toNumber = Number(value)
      if (dig === 1) {
        toNumber = Math.floor(toNumber * 10) / 10
      }
      return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: dig || 2,  }).format(toNumber).replace(',', '.')
    } return '0'
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      gap: 16,
      paddingTop: 15
    }}>
      <div style={{
        backgroundColor: adviceDate ? 'rgba(63, 81, 181, 0.12)' : '',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        paddingLeft: 8,
        height: 28,
        width: '140px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <span style={{ color: adviceDate ? '#3F51B5' : '' }}>{round(price) || 0}</span>
      </div>
      <div style={{
        height: 28,
        paddingLeft: 8,
        width: '100%',
        display: 'flex',
        justifyContent: adviceDate ? 'space-between' : '',
        gap: adviceDate ? '' : '4px',
        alignItems: 'center',
      }}
      >
        <span>{round(margin) || 0}</span>
        <div style={{
          backgroundColor: '#F5F5F5',
          padding: 4, borderRadius: 4, display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ color: '#00000099' }}>
            {round(marginPercents, 1) || 0}%
          </span>
        </div>
      </div>
    </div>
  )
}