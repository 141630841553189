import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface EditContragentBrandsPayload {
    contragentId: UniqueId,
    brandId: UniqueId,
}
export const editContragentBrands = ({ contragentId, brandId }: EditContragentBrandsPayload) =>
  api.post<pimApi.admin.ContragentBrand>(
    `/pim/admin/contragents/${contragentId}/brands`,
    { brandId }
  )


export const useEditContragentBrandsMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.ContragentBrand>,
        AxiosError<errorType>,
        EditContragentBrandsPayload
        >(editContragentBrands)
