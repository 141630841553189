import { InputAdornment, Box, CircularProgress, Button } from '@mui/material'
import { Search } from '@mui/icons-material'
import React, { FC, memo, useState } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { isEmpty, isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { NewComponentCard } from 'entities/pim/goodsComponents'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { InputLabel } from 'shared/ui/components'

import {
  ProductSearchWrapper, SearchInput,
  ResultList
} from './styled'

import { GoodsAlertMessage } from '../../../../features/goods/alertMessages'

interface GoodsComponentSearchProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onAddComponent: (newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent) => void
  disabled?: boolean
}

const MAX_GOODS_COMPONENTS = 5

export const GoodsComponentSearch: FC<GoodsComponentSearchProps> = ({
  goodsComponents,
  onAddComponent,
  disabled,
}) => {
  const [maxGoodsComponentsCount, setMaxGoodsComponentsCount] = useState<number>(MAX_GOODS_COMPONENTS)
  const goodsTypeField = useField<pimApi.goods.GoodsType>('goodsType')
  const goodsType = goodsTypeField.input.value
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 200)
  const { data, isLoading } = pimApi.goods.useGoodsByCodeQuery(debouncedValue, false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleAdd = (goods: pimApi.goods.GoodsShortInfo, quantity: number) => {
    const newComponent: AppPimGoodsModelsGoodsGoodsComponent = {
      componentId: goods.id,
      quantity,
      name: goods.name,
      code: goods.code,
      auxCode: goods.auxCode,
      goodsBaseImageId: goods.goodsBaseImageId,
      goodsType: goods.goodsType,
      unitCode: goods.unitCode,
      unitPartible: goods.unitPartible,
    }

    onAddComponent(newComponent)
  }

  const handleShowAllGoodsComponents = () => {
    setMaxGoodsComponentsCount(productListFiltered.length)
  }

  const isAddDisabled = goodsType === 'pack' && !isEmpty(goodsComponents)
  const productListFiltered = data?.data.filter(
    item => !goodsComponents.find(component => component.componentId === item.id)
  ) ?? []

  const goodsComponentsFilteredAndSliced = productListFiltered.slice(0, maxGoodsComponentsCount)
  const isShowMoreButton = productListFiltered.length !== goodsComponentsFilteredAndSliced.length

  return (
    <ProductSearchWrapper>
      <InputLabel label="Поиск товаров" />
      <Box mb="4px">
        <SearchInput
          variant="outlined"
          size="small"
          fullWidth={true}
          onChange={handleChange}
          autocomplete="off"
          placeholder="Введите артикул товара"
          value={value}
          disabled={disabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {isLoading &&
              <CircularProgress size={20} />}
              <Search/>
            </InputAdornment>,
          }}
        />
      </Box>

      <ResultList>
        <Box height={(isEmptyArray(productListFiltered) && isEmpty(goodsComponents) && value.length > 0) ? '40px' : ''}>
          { (isEmptyArray(productListFiltered) && isEmpty(goodsComponents) && value.length > 0) &&
          <GoodsAlertMessage message="Товар по данному артикулу не найден"/>
          }
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {goodsComponentsFilteredAndSliced.map((item) => (
            <NewComponentCard
              key={item.id}
              goods={item}
              onAddClick={handleAdd}
              isAddDisabled={isAddDisabled || !!disabled}
              isInputDisabled={!!disabled}
            />
          ))}
        </Box>

        {isShowMoreButton && (
          <Box display="flex" width="100%" justifyContent="center" mt={1}>
            <Button onClick={handleShowAllGoodsComponents} variant="outlined" size="medium" >
              Показать еще {productListFiltered.length - goodsComponentsFilteredAndSliced.length}
            </Button>
          </Box>
        )}

      </ResultList>
    </ProductSearchWrapper>
  )
}

export const MemoGoodsComponentSearch = memo(GoodsComponentSearch)
