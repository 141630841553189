import React from 'react'
import {
  DescMedia,
  VideoContent,
  YoutubeImage,
  YoutubeLink,
} from 'shared/ui/goods/common'
import { pimApi } from 'shared/api'
import { Image, SettingsMenu } from 'shared/ui/components'
import { MoreVert } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { GoodsFile } from 'shared/api/pim/goods'
import { downloadFileWithPath } from 'shared/lib/utils'

interface VideoItemProps {
  openGallery?: () => void
  item: GoodsFile
  onFileDelete: (item: pimApi.products.MediaFile) => void
  goodsId: UniqueId
  disabled?: boolean
}

const fileThumbnailSize = { maxWidth: 350, maxHeight: 350 }

export const VideoItem = ({ item, onFileDelete, disabled, goodsId, openGallery }: VideoItemProps) => {
  const settingsOptions: Array<any> = [
    {
      label: item?.downloadUrl?.length! > 1 ? 'Скачать' : 'Перейти по ссылке',
      divider: true,
      disabled,
      icon: <DownloadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
      handler: () => {
        if (item.downloadUrl) {
          const url = new URL(item.downloadUrl)
          downloadFileWithPath(url.pathname, item.fileName || '')
        }
      }
    },
    {
      label: 'Удалить',
      disabled,
      icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
      type: 'error',
      handler: () => {
        onFileDelete(item)
      }
    },
  ]

  const videoId = item.fileName?.split('=')?.[1]?.split('&')?.[0]
  const apiUrl = pimApi.goods.getFileThumbnailApiPath(
    goodsId,
    item.id,
    fileThumbnailSize
  )
  const previewUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  return (
    <>
      <VideoContent
        onClick={() => {
          if (openGallery) openGallery()}
        }
        onDragStart={(e) => {e.preventDefault()}}>
        {item?.downloadUrl?.length! > 1 ?
          <Image src={apiUrl}/>
          :
          <YoutubeImage src={previewUrl}/>
        }
        <SettingsMenu
          className="settingsMenu"
          options={settingsOptions}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          iconSize="small"
          iconType={<MoreVert />}
        />
      </VideoContent>
      <YoutubeLink download={true}
        target={item?.downloadUrl?.length! > 1 ? '' : '_blank'} title={item?.fileName}
        href={item?.downloadUrl?.length! > 1 ? item.downloadUrl : item.fileName}>
        <DescMedia>{item?.fileName}</DescMedia>
      </YoutubeLink>
    </>
  )
}
