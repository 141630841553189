import React from 'react'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { DatePicker } from 'shared/ui/components'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'

import { CheckboxControl } from './ui/CheckboxControl'
import { HistoryHeader, StyledSpace } from './ui/styled'

import { dataParamsTypes } from '../types'

enum Interval { Day, Week, Month, Year}

export const Header = ({ dataParams, setDataParams }: { dataParams: dataParamsTypes, setDataParams: any }) => {
  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        setDataParams(prev => ({ ...prev,
          fromDate: subtractDays(new Date(), 0),
          toDate: subtractDays(new Date(), 0),
          range: n,
        }))
        return null
      }
      case 1: {
        setDataParams(prev => ({ ...prev,
          fromDate: subtractDays(new Date(), 7),
          toDate: subtractDays(new Date(), 0),
          range: n,
        }))
        return null
      }
      case 2: {
        setDataParams(prev => ({ ...prev,
          fromDate: subtractMonths(new Date(), 1),
          toDate: subtractDays(new Date(), 0),
          range: n,
        }))
        return null
      }
      case 3: {
        setDataParams(prev => ({ ...prev,
          fromDate: subtractMonths(new Date(), 12),
          toDate: subtractDays(new Date(), 0),
          range: n
        }))
        return null
      }
    }
    return true
  }

  return (
    <HistoryHeader>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ marginRight: '58px' }}>
          <Typography variant="h5" sx={{ fontSize: '20px' }}>
            История цен
          </Typography>
        </Box>
        <ButtonGroup size="large" variant="text" aria-label="text button group">
          <StyledButtonGroup $active={dataParams.range === 0} onClick={() => rangeButtonClick(Interval.Day)}>День</StyledButtonGroup>
          <StyledButtonGroup $active={dataParams.range === 1} onClick={() => rangeButtonClick(Interval.Week)}>Неделя</StyledButtonGroup>
          <StyledButtonGroup $active={dataParams.range === 2} onClick={() => rangeButtonClick(Interval.Month)}>Месяц</StyledButtonGroup>
          <StyledButtonGroup $active={dataParams.range === 3} onClick={() => rangeButtonClick(Interval.Year)}>Год</StyledButtonGroup>
          <Button
            disableElevation={true}
            disableTouchRipple={true}
            sx={{
              pointerEvents: 'none',
              fontWeight: 400,
              textTransform: 'none',
              fontSize: '12px',
              color: dataParams.range === 4 ? '' : 'rgba(0, 0, 0, 0.54)',
              backgroundColor: dataParams.range === 4 ? 'rgba(33,150,243,0.15)' : ''
            }}>Другое</Button>
        </ButtonGroup>
        <Box sx={{ marginLeft: '24px', display: 'flex', flexDirection: 'row', gap: '16px', marginRight: '24px' }}>
          <Box sx={{ width: '180px' }}>
            <DatePicker
              name="fromDate"
              label="Начало периода"
              views={['day']}
              inputHeight={36}
              showClear={false}
              value={dataParams.fromDate}
              onChange={(e) => { if (e) setDataParams(prev => ({
                ...prev,
                fromDate: new Date(e).toISOString().split('T')[0],
                range: 4
              }))}}
              onBlur={(e) => {
                const [day, month, year] = e.target.value.split('.')
                if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== dataParams?.fromDate) {
                  setDataParams(prev => ({
                    ...prev,
                    fromDate: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                    range: 4
                  }))
                }}}
            />
          </Box>
          <Box sx={{ width: '180px' }}>
            <DatePicker
              name="toDate"
              label="Конец периода"
              views={['day']}
              inputHeight={36}
              showClear={false}
              value={dataParams.toDate}
              onChange={(e) => {if (e) setDataParams(prev => ({
                ...prev,
                toDate: new Date(e).toISOString().split('T')[0],
                range: 4
              }))}}
              onBlur={(e) => {
                const [day, month, year] = e.target.value.split('.')
                if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== dataParams?.toDate) {
                  setDataParams(prev => ({
                    ...prev,
                    toDate: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                    range: 4
                  }))
                }
              }}
            />
          </Box>
        </Box>
        <StyledSpace/>
      </div>
      <>
        <CheckboxControl setDataParams={setDataParams} dataParams={dataParams}/>
      </>
    </HistoryHeader>
  )
}