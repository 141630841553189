import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { generatePath, useParams } from 'react-router'
import { Box, Button } from '@mui/material'
import { Attributes } from 'entities/pim/GoodsAttributes/ui/Attributes/Attributes'
import { GoodsContent } from 'features/goods/editCharacteristicsContent'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { GOODS_VARIANT } from 'shared/config/routes'
import { Preloader } from 'shared/ui/components'
import { getIsDataLoaded } from 'shared/lib/hooks/useStatus'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { pimApi } from 'shared/api'

import { GoodsAttributes } from './interface'

import { useGoodsAttribute } from '../../model/hooks'
import {
  BottomButtonWrapper,
  BottomSection,
} from '../../components/common'
import { allElementsAreNull } from '../../../../shared/lib/checkers'


interface CharacteristicStepProps {
  handleNext: () => void;
  handlePrev: () => void;
  onSubmit: () => void;
  goods: { id: UniqueId, hasContent: boolean };
  isArchived?: boolean;
}

export const CharacteristicStep = ({
  goods,
  onSubmit,
  isArchived,
}: CharacteristicStepProps) => {
  const {
    goodsAttributes,
    loadGoodsAttributes,
    changeGoodsAttributes,
  } = useGoodsAttribute()
  const { hasContent } = goods
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  useEffect(() => {
    loadGoodsAttributes(goods.id)
  }, [loadGoodsAttributes, goods])

  const hasAttributesForVariation = isNotEmptyArray(goodsAttributesForVariationsQuery.data)

  const isGoodsAttributesReady = getIsDataLoaded(goodsAttributes)
  const isShowPreload = !isGoodsAttributesReady

  const processedAttributes = ({
    ...goodsAttributes.data,
    attributes: goodsAttributes.data?.attributes?.map(el => ({
      ...el,
      value: allElementsAreNull(el.value) ? null : el.value
    }))
  })

  return (
    <>
      <Box pt={2}>
        {isShowPreload ?
          <Preloader /> : (
            <>
              <Attributes
                attributes={processedAttributes as GoodsAttributes}
                disabled={isArchived}
                onChange={({ name, value }) => changeGoodsAttributes(goods.id, name, value)}
                onSubmit={onSubmit}
              />
              {hasContent && (
                <Box mt={2}>
                  <GoodsContent disabled={isArchived} />
                </Box>
              )}
              
            </>
          )
        }
      </Box>

      <BottomSection>
        <BottomButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isArchived}
          >
            СОХРАНИТЬ
          </Button>
          <div>
            {hasAttributesForVariation && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generatePath(GOODS_VARIANT, { id: goodsId })}
                disabled={isArchived}
              >
                ДОБАВИТЬ ВАРИАЦИЮ
              </Button>
            )}
            <Box display="inline-flex" pl={2}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleOpenCreateModal}
                disabled={isArchived}
              >
                СОЗДАТЬ ПРОДУКТ
              </Button>
              <ProductCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} />
            </Box>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
