import { Storage } from 'domains/storage'

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`
  const parts: string[] = value.split(`; ${name}=`)
  return parts && parts.length === 2
    ? parts[parts.length - 1].split(';').shift()
    : ''
}

const setCookie = (
  name: string,
  valueArg: string,
  optionsArg?: {
    expires?: number | string | Date
    path?: string
    domain?: string
    secure?: string
  }
): void => {
  let options = optionsArg || {}

  let { expires } = options

  if (expires) {
    if (typeof expires === 'number') {
      const d = new Date()
      d.setTime(d.getTime() + expires * 1000)
      expires = options.expires || d
    }
    if (expires instanceof Date) {
      options = { ...options, expires: expires.toUTCString() }
    }
  }

  const value = encodeURIComponent(valueArg)
  let updatedCookie = `${name}=${value}`

  Object.keys(options).forEach((propName) => {
    if (Object.prototype.hasOwnProperty.call(options, propName)) {
      updatedCookie += `; ${propName}`
      // @ts-ignore
      const propValue: string = options[propName]

      if (propValue) {
        updatedCookie += `=${propValue}`
      }
    }
  })

  document.cookie = updatedCookie
}

const deleteCookie = (name: string, path?: string): void => {
  setCookie(name, '', {
    expires: -1,
    path,
  })
}

const clearCookies = (): void => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
  })
}

export const cookieStore = new Storage({
  get: getCookie,
  set: setCookie,
  rm: deleteCookie,
  clear: clearCookies,
})
