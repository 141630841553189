import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

interface EditSupplyOrderPayload {
  supplyOrderId?: UniqueId,
  deletingOrder?: Partial<DeletingObject>
}

export const deleteSupplyOrder = ({ supplyOrderId, deletingOrder }: EditSupplyOrderPayload) =>
  api.delete(
    `/trade/orders/supplyorders/${supplyOrderId}`,
    { data: deletingOrder }
  )

export const useDeleteSupplyOrderMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    EditSupplyOrderPayload
  >(deleteSupplyOrder)