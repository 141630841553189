import queryString, { ParseOptions, StringifyOptions } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

export const useQueryParams = ( searchObjOptions?: ParseOptions) => {
  const { search } = useLocation()
  const searchObj: any = queryString.parse(search, searchObjOptions)
  const history = useHistory()
  const handleChangeParams = ({ params, options, isPush }: { params: Object, options?: StringifyOptions, isPush?: boolean }) => {
    const searchParams = queryString.parse(window.location.search, options)
    const historyStr = queryString.stringify(
      { ...searchParams, ...params },
      options
    )
    if (isPush) {
      history.push(`${history.location.pathname}?${historyStr}`)
    } else {
      history.replace({ search: `?${historyStr}` })
    }
  }
  return { handleChangeParams, searchObj  }
}