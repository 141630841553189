import { useState } from 'react'
import { DialogTitleWithBtn } from 'shared/ui/components/ModalComponents'
import styled from 'styled-components'
import { InputLabel } from 'shared/ui/components/InputLabel'
import { Box, Button, DialogActions, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { HelperTextSupply } from 'features/auth/ui/styles'


const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  
  .text-input-container {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    
    .MuiTextField-root {
      width: 442px;
    }
  }
`

export const SavePresetModal = ({ close, accept, filtersPresets }) => {
  const [ value, setValue ] = useState('')

  const nameIsExist = !!Object.keys(filtersPresets).find(el => el === value.trim())

  return (
    <ModalWrapper>
      <DialogTitleWithBtn onClose={ close } sx={{ padding: '0', marginBottom: '24px' }}>
        Добавить установку
      </DialogTitleWithBtn>

      <div className="text-input-container">
        <InputLabel label="Название закладки" required={ false }/>
        <HelperTextSupply>
          <TextField
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Название закладки"
            error={nameIsExist}
            helperText={nameIsExist && 'Установка с таким названием уже существует'}
            InputProps={{
              autoComplete: 'off'
            }}
          />
        </HelperTextSupply>
      </div>

      <DialogActions sx={{ padding: 0 }}>
        <Box >
          <Button onClick={close} color="primary">
            ОТМЕНА
          </Button>
          <LoadingButton
            onClick={() => accept(value)}
            disabled={value.length === 0}
            color="primary"
            variant="contained"
            sx={{
              marginLeft: 2,
            }}
          >
            ДОБАВИТЬ
          </LoadingButton>
        </Box>
      </DialogActions>

    </ModalWrapper>
  )
}