import { memo, useCallback, useMemo } from 'react'
import { Paper } from '@mui/material'
import { pimApi, user } from 'shared/api'
import { isNotNil } from 'shared/lib/checkers'
import { Table } from 'shared/ui/components/Table'
import { useRowsPerPage } from 'shared/ui/components/Table/lib/useRowsPerPage'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { useQueryClient } from 'react-query'
import { getUserSettingsQueryKey } from 'shared/api/user/settings'

import { columnsConfig } from './model/tableColumnsConfig'
import { StyledTableBox, StyledLoader } from './styled'
import { pagingPanelLocale } from './lib/localization'


const MAX_PAGE_SIZE = 50

const getRowId = (row: pimApi.admin.ContragentShortInfo) => row.id

interface IAdminSupplierTable {
  suppliersData: pimApi.admin.ContragentShortInfo[]
  isLoading: boolean
  isFetching: boolean
}

const AdminSupplierTable = ({ suppliersData, isLoading, isFetching }: IAdminSupplierTable) => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)
  const { calculatedRowsPerPage } = useRowsPerPage()
  const { limit, page } = searchObj
  const currentPage = parseInt(page || '0', 10)

  const currentRowsPerPage = isNotNil(limit)
    ? parseInt(limit, 10)
    : calculatedRowsPerPage

  const pageSize = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)
    

  const memoTableParams = useMemo(
    () => ({ 
      sort: [],
      limit: pageSize,
      page: currentPage,
    }),
    [suppliersData]
  )
    
  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()

  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.supplyOrderListTableHiddenColumns
  const queryClient = useQueryClient()
    
  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          supplyOrderListTableHiddenColumns: hiddenColumns,
        },
      },{
        onSuccess: (response) => {
          queryClient.setQueryData(getUserSettingsQueryKey(), response)
        }
      })
    },
    [userSettingsQuery?.data]
  )

  return (
    <>
      <StyledTableBox component={Paper}> 
        { (isFetching || isLoading) && <StyledLoader size={60} />} 
        { suppliersData && (
          <>
            <Table 
              tableList={suppliersData}
              totalCount={suppliersData.length}
              getRowId={getRowId} 
              handleTableSettingsChange={handleTableSettingsChange} 
              tableHiddenColumns={tableHiddenColumns} 
              tableParams={memoTableParams}
              calculatedRowsPerPage={calculatedRowsPerPage} 
              columnsConfig={columnsConfig} 
              showInArchiveControl={false} 
              showColumnsVisibility={true}
              paginationLocale={pagingPanelLocale}
              searching="internal" 
              pagination="internal" 
              sorting="internal" 
              filtering="internal" 
            />
          </>
        )} 
      </StyledTableBox> 
    </>
  )
}

export const MemoAdminSupplierTable = memo(AdminSupplierTable)
