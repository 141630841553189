import React, { useMemo, useState } from 'react'
import { sort } from 'shared/lib/utils/sort'
import { mergeArrays } from 'features/product/common/CompetitorsTab/lib/mergeArrays'
import { Form } from 'react-final-form'
import { pimGen } from 'shared/lib/generated'
import { getValidationSchema } from 'features/product/common/CompetitorsTab/lib/getValidationSchema'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { useQueryClient } from 'react-query'
import { AlgorithmWithValue } from 'shared/api/pim/products/common'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { getCellWidth } from 'features/analytics/reprice/lib/getCellWidth'

import { NestedWidget } from './NestedWidget'


export const PriceSettingsTableRow = ({ row, tableCells }) => {
  const [ expandNested, setExpandNested ] = useState(false)
  const [ algorithmId, setAlgorithmId ] = useState<number | null>(row?.advisor.selectedAlgorithmId || null)
  const { data: algorithmsData } = pimGen.priceWizard.GetAlgorithms.useGetAlgorithms()
  const { mutate: changePriceSettings } = pimGen.priceWizard.PutPriceSettings.usePutPriceSettings()
  const { searchObj } = useQueryParams({ parseNumbers: true })
  const { limit, page, tab, ...searchParams } = searchObj

  const queryClient = useQueryClient()

  const settings = useMemo(() => {
    if (algorithmsData) {
      const advisor = row?.advisor
      const algorithm =  algorithmsData.map(el => {
        const addedAlgorithm = advisor?.algorithms.find(alg => alg.id === el.id)
        if (addedAlgorithm) {
          return { ...el, parameters: sort(mergeArrays(addedAlgorithm.parameters, el.parameters), 'indexNumber', 'desc') as any[] }
        }
        return el
      }).find(el => el.id === algorithmId)

      return {
        initialValues: {
          ...(algorithm?.parameters as any)?.reduce((acc, param) => {
            acc[`id${param.id}`] = param.value ?? null
            return acc
          }, {}),
          adviceInterval: row?.adviceInterval,
          autoChangePrice: row?.autoChangePrice },
        algorithm
      }
    }
    return {}
  }, [row, algorithmsData, algorithmId])

  const validationSchema = getValidationSchema(settings.algorithm?.parameters)
  const validate = useValidationSchema(validationSchema)

  const onSubmit = (value) => {
    const { adviceInterval } = value
    const { autoChangePrice } = value

    const newParams = (settings?.algorithm?.parameters || []).map(el => ({
      ...el,
      value: value[`id${ el.id }`]
    }))

    const index = row?.advisor.algorithms.findIndex(item => item.id === settings?.algorithm?.id)
    if (index !== -1) {
      // eslint-disable-next-line no-param-reassign
      row.advisor.algorithms[index] = { ...settings.algorithm, parameters: newParams } as AlgorithmWithValue
    } else {
      row?.advisor.algorithms.push({ ...settings.algorithm, parameters: newParams } as AlgorithmWithValue)
    }
    changePriceSettings({
      data: {
        ...row,
        adviceInterval,
        autoChangePrice,
        advisor: {
          selectedAlgorithmId: algorithmId!,
          algorithms: row.advisor.algorithms
        }
      }
    }, {
      onSuccess: (response) => {
        snackActions.info('Настройки продукта изменены')
        queryClient.setQueryData<pimGen.priceWizard.GetPriceSettings.ResponseType>(pimGen.priceWizard.GetPriceSettings.getQueryKey({
          query: searchParams
        }), (updater) => updater!.map(el => {
          if (el.id === response.id) {
            return { ...el, ...response }
          }
          return el
        }) as pimGen.priceWizard.GetPriceSettings.ResponseType)
      }
    })
  }
  
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={settings.initialValues}
      render={({ handleSubmit, dirty }) => (
        <>
          <tr className="table-row">
            { tableCells.map(({ padding, width, Component  }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <td key={index} style={ { padding, ...getCellWidth(width) } }>
                <Component
                  handleSubmit={handleSubmit}
                  row={row}
                  dirty={dirty}
                  algorithmId={algorithmId}
                  setAlgorithmId={setAlgorithmId}
                  expandNested={expandNested}
                  setExpandNested={setExpandNested}
                />
              </td>
            )) }
          </tr>
          {expandNested &&
            <tr style={{ display: 'flex' }}>
              <NestedWidget row={row} settings={settings}/>
            </tr>
          }
        </>
      ) }
    />
  )
}