// @ts-nocheck

import { takeEvery, put } from 'redux-saga/effects'

import { requestActionType } from './actionsCreator'

export type requesterFunctionType = <T, C, Args extends any[] = undefined[]>(
  context: C,
  method: string,
  ...args: Args
) => {
  result: { data: T | null; error: errorType | null }
  getResult: () => Generator
  callActions: (action: requestActionType) => Generator
}

const requester: requesterFunctionType = (context, method, ...args) => {
  const obj = {
    result: { data: null, error: null },
    *getResult() {
      const result = yield context[method](...args)
      this.result = result
      return this
    },
    *callActions(actions: requestActionType) {
      const result = yield context[method](...args)
      if (!result) {
        yield put(actions.error(result))
      } else {
        yield put(actions.success(result))
      }
      this.result = result
      return this
    },
  }

  return obj
}

function sagaWrapper(saga) {
  // eslint-disable-next-line func-names
  return function* (action) {
    try {
      yield saga(action, requester)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error)
    }
  }
}

// eslint-disable-next-line func-names
export default function* (actionType: string, ...sagas) {
  // eslint-disable-next-line no-restricted-syntax
  for (const saga of sagas) {
    yield takeEvery(
      actionType === '*'
        ? '*'
        : (action: { type: string }): boolean =>
          action.type.startsWith(actionType),
      sagaWrapper(saga)
    )
  }
}