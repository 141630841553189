import { memo, useCallback } from 'react'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { SortLabel } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/ui/SortLabel'

import { StyledTableHeaderWrapper } from './styled'


export const TableHeaderCellComponent = ({
  column,
  resizingEnabled,
  onWidthChange,
  onWidthDraft,
  onWidthDraftCancel,
  getCellWidth,
  draggingEnabled,
  tableRow,
  tableColumn,
  align,
  getMessage,
  onSort,
  disabled,
  sort,
}) => {
  const currentSort = sort?.find((item) => item.columnName === column.name)
  const currentSortDirection = currentSort?.direction
  const nextSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc'


  const showSort = column?.sortingEnabled

  const handleSort = useCallback(() => {
    onSort([{ columnName: column.name, direction: nextSortDirection }])
  }, [column.name, nextSortDirection])

  return (
    <StyledTableHeaderWrapper
      column={column}
      resizingEnabled={resizingEnabled}
      onWidthChange={onWidthChange}
      onWidthDraft={onWidthDraft}
      onWidthDraftCancel={onWidthDraftCancel}
      getCellWidth={getCellWidth}
      draggingEnabled={draggingEnabled}
      tableRow={tableRow}
      tableColumn={tableColumn}
    >
      <Typography
        variant="body1"
        component="span"
        fontSize={14}
        fontWeight={500}
      >
        {column.title}
      </Typography>
      {showSort && (
        <TableHeaderRow.SortLabel
          align={align}
          column={column}
          direction={currentSort?.direction}
          disabled={disabled}
          getMessage={getMessage || (() => 'Сортировать')}
          onSort={handleSort}
        >
          <SortLabel direction={currentSort?.direction} />
        </TableHeaderRow.SortLabel>
      )}
    </StyledTableHeaderWrapper>
  )
}

export const MemoTableHeaderCellComponent = memo(TableHeaderCellComponent)
