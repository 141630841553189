import { tradeGen } from 'shared/lib/generated'
import { snackActions } from 'shared/lib/react/snackbar'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'

import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'

export const ChangeWarehouseStatus = () => {
  const { setDistributionCache } = useChangeDistribution()
  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const { supplyOrderQuery } = useSupplyOrderContext()
  
  const { mutate: changeWarehouseMutation } = tradeGen.orders.
    Changecopackingdistributionacceptancecoefficients.
    useChangecopackingdistributionacceptancecoefficients()

  const changeWarehouseStatus = ({ statusId, onSuccessCallback }: { statusId: number, onSuccessCallback?: () => void }) => {
    setIsLoading(true)
    changeWarehouseMutation({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
      copackingdistributionwarehouseId: selectedProducts?.warehouse?.id!,
      data: {
        id: selectedProducts?.warehouse?.id!,
        versionNo: selectedProducts?.warehouse?.versionNo!,
        statusId,
      }
    }, {
      onSuccess: (response) => {
        setIsLoading(false)
        setSelectedProducts(null)
        setDistributionCache({ supplyOrderQuery, response })
        snackActions.info('Статус склада изменен')
        if (onSuccessCallback) onSuccessCallback()
      },
      onError: () => setIsLoading(false)
    })
  }

  return ({ changeWarehouseStatus })
}