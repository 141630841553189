import React, { memo, useMemo } from 'react'
import { Box, Button, Stack } from '@mui/material'
import {  pimApi } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import AddIcon from '@mui/icons-material/Add'
import icon from 'assets/images/icons/excel.svg'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { useRowsPerPage } from '../../lib/useRowsPerPage'
import { columnsConfig } from '../../model/tableColumnsConfig'
import { pagingPanelLocale } from '../../lib/localization'
import { StyledLoader, StyledTableBox, TableWrapper, ToolBar } from '../styled'

const MAX_PAGE_SIZE = 50
const getRowId = (row: pimApi.dictionaries.Brand) => row.id

interface IBrandsDictTable {
  brandsList?: pimApi.dictionaries.Brand[]
  isLoading: boolean
  isFetching: boolean
  exportToExcelClick: ()=> void
  isExportToExcel: boolean
  handleAddBrand: ()=> void
  handleCellChange: (data: any)=> void
  handleRowDelete: (id: number)=> void
}

const BrandsDictTable = ({
  brandsList,
  isLoading,
  isFetching,
  exportToExcelClick,
  isExportToExcel,
  handleAddBrand,
  handleCellChange,
  handleRowDelete
}: IBrandsDictTable ) => {

  const { calculatedRowsPerPage } = useRowsPerPage()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage + 2)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0
    }),
    [brandsList]
  )

  return (
    <>
      <StyledTableBox component={Box}>
        {(isLoading || isFetching ) && <StyledLoader size={60} />}
        {brandsList &&  (
          <TableWrapper >
            <ToolBar>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  size="small"
                  disabled={isExportToExcel}
                  onClick={exportToExcelClick}
                  startIcon={<ExcelIcon src={icon} alt="excelImg" />}
                >
                  ЭКСПОРТ
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleAddBrand}
                >
                  ДОБАВИТЬ
                </Button>
              </Stack>
            </ToolBar>
            <Table
              tableList={brandsList}
              totalCount={brandsList.length}
              getRowId={getRowId}
              paginationLocale={pagingPanelLocale}
              tableParams={memoTableParams}
              calculatedRowsPerPage={pageSize}
              onModalOpen={handleCellChange}
              columnsConfig={columnsConfig}
              isTableEditable={false}
              onRowDelete={handleRowDelete}
              showInArchiveControl={false}
              showColumnsVisibility={false}
              searching="internal"
              pagination="internal"
              sorting="internal"
              filtering="internal"
            />
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoBrandsDictTable = memo(BrandsDictTable)
