import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledVirtualTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  .TableContainer-root {
    max-height: calc( 100vh - 380px);
  }

  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }

  .TableCell-cell {
    height: 50px;
  }

  .Toolbar-toolbar {
    justify-content: space-between;
  }

  table {
    tbody::after {
      content: "";
      display: block;
      height: 50px !important;
    }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 10;
`

export const TableWrapper = styled.div`

  td.TableDetailCell-active {
    min-width: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    
    table {
      min-width: 0 !important;
      .TableInvisibleRow-row {
         display: none;
      }
      tr td{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 50px;
      }
    }
  }

  table {
    min-width: 900px !important;
    tbody::after {
      content: "";
      display: block;
      height: 0;
    }
  }

  .TableDetailCell-active {
    table {
      tbody {
        width: 100%;
        tr td:last-child {
          margin-left: auto;
        }
      }
    }
    .TableContainer-root {
      border-radius: 4px;
    }
  }
  [isrowexpanded]{
    td {
      border-bottom: 0;
    }
  }
  
  [isrowexpanded]+tr{
    .MuiTableCell-root.TableDetailCell-active {
      box-shadow: inset  0 -1px 0 #64B6F7;
    }
    thead {
      .CellLayout-cellDraggable {
        cursor: auto;
      }
    }
    tbody tr:hover {
      background-color: #fff !important;
    }
  }
  
  [isrowexpanded]+tr tr:last-child{
    td {
      border-bottom: 0;
    }
  }
    .Pager-pager {
    height: 56px;
  }

`