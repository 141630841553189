import { useEffect } from 'react'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { useParams } from 'react-router'
import { useBrandquadStore } from 'features/showcase/lib/store'
import { useInView } from 'react-intersection-observer'
import { brandquadApi } from 'shared/api'

import { GoodsCard } from './GoodsCard'

import { LoaderWrapper, StyledLoader } from '../styled'

const PAGE_SIZE = 15

export const Goods = ({ goodsStack, currentPage, setGoodsStack, setCurrentPage }) => {

  const addGood = useBrandquadStore((state) => state.addGood)
  const deleteGood = useBrandquadStore((state) => state.deleteGood)
  const { clientId: id } = useParams<{ clientId: string }>()
  const { searchObj, handleChangeParams } = useQueryParams({
    parseNumbers: true,
    arrayFormat: 'bracket',
  })

  const {
    data: goodsData,
    isFetching,
    isLoading,
  } = brandquadApi.goods.getGoods.useGetGoods({
    clientId: id,
    query: {
      brandIds: searchObj.brandIds,
      pageSize: PAGE_SIZE,
      page: currentPage,
    },
  })

  const handleMoveToGood = (goodsElem) => {
    handleChangeParams({
      params: { brandIds: undefined, goodsId: goodsElem.goods_id },
      options: {
        arrayFormat: 'bracket',
        skipNull: true,
      },
      isPush: true
    })
  }

  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (!isFetching && inView && goodsData?.length !== 0) {
      setCurrentPage(prevState => prevState + 1)
    }
  }, [inView, goodsData])


  useEffect(() => {
    if (goodsData && goodsData?.length > 0) {
      setGoodsStack(prev => prev.concat(goodsData))
    }
  }, [goodsData, JSON.stringify(searchObj.brandIds), JSON.stringify(searchObj.searchString)])

  return (
    <>
      {goodsData && !isLoading && (
        <div className="content-container">
          {goodsStack?.map((el) => (
            <GoodsCard
              key={el.external_id}
              goodsElement={el}
              addGood={addGood}
              deleteGood={deleteGood}
              handleMoveToGood={handleMoveToGood}
            />
          ))}
        </div>
      )}
      {isFetching && (
        <LoaderWrapper height={100}>
          <StyledLoader size={40} />
        </LoaderWrapper>
      )}
      {goodsData?.length === 0 && goodsStack.length === 0 && !isFetching && <>Ничего нет</>}
      <div ref={ref} className="lazy-loading" />
    </>
  )
}
