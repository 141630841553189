import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeleteBrand {
  brandId: UniqueId,
  value: pimApi.DeletingObject
}
const deleteBrand = ({ brandId, value }: DeleteBrand) =>
  api.delete(
    `/pim/brands/${brandId}`,
    { data: value }
  )


export const useDeleteBrandMutation = () =>
  useMutation<
        AxiosResponse,
        AxiosError<errorType>,
        DeleteBrand
        >(deleteBrand)