import { pimGen } from 'shared/lib/generated'
import { Skeleton } from '@mui/material'
import { PriceCard } from 'entities/pim/price'

import { PriceWrapper, SkeletonWrapper } from '../styled'

export const OzonProduct = ({ row }) => {
  const { data: priceData } = pimGen.ozonProduct.GetPrice.useGetPrice({ productId: row.productId })

  if (priceData) return (
    <PriceWrapper>
      <PriceCard
        price={priceData.price}
        oldPrice={priceData.oldPrice}
        readOnly={false}
      />
    </PriceWrapper>
  )

  return (
    <SkeletonWrapper>
      <Skeleton
        sx={{ bgcolor: '#D3D3D360' }}
        animation="wave"
        variant="rounded"
      />
    </SkeletonWrapper>
  )
}