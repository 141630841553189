import { Theme } from '@mui/material'
import styled from 'styled-components'

export const Link = styled.a`
  width: 100%;
  color: ${({ theme, $isArchived }: { theme: Theme, $isArchived?: boolean  }) => $isArchived ? '#a4a4a4' : theme.palette.primary.main };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`