import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName = keyof Omit<
  pimApi.admin.ContragentShortInfo,
  | 'id'
  | 'versionNo'
  | 'changed'
  | 'changer'
  | 'fullName'
  | 'inArchive'
>


type ColumnNames = Record<ColumnName, ColumnName>

// @ts-ignore
export const columns: ColumnNames = {
  rating: 'rating',
  shortName: 'shortName',
  inn: 'inn',
  kpp: 'kpp'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.shortName,
      columnName: columns.shortName,
      title: 'Поставщик',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.SupplierLink
    },
    {
      name: columns.inn,
      columnName: columns.inn,
      title: 'ИНН',
      width: 240,
      sortingEnabled: false,
    },
    {
      name: columns.kpp,
      columnName: columns.kpp,
      title: 'КПП',
      width: 216,
      sortingEnabled: false,
    }
  ],
}
