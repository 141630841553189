export const toSimpleDate = (date) => {
  if (date) {
    return date.split('T')[0]
  }
  return ''
}

export function getDateTimeFromIso(dateStr) {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  let res = ''
  const dd = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`
  const MM = `${
    date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  }`
  const yyyy = date.getFullYear()

  const hh = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}`
  const mm = `${
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  }`
  res = `${dd}.${MM}.${yyyy} ${hh}:${mm}`

  return res
}

export function getDateFromIso(dateStr, separator?, dayFirst?) {
  if (!dateStr) return ''
  const sep = separator || '.'
  const date = new Date(dateStr)
  const dd = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`
  const MM = `${
    date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  }`
  const yyyy = date.getFullYear()
  if (!dayFirst) return `${dd}${sep}${MM}${sep}${yyyy}`
  return `${MM}${sep}${dd}${sep}${yyyy}`
}

export function getTimeFromIso(dateStr) {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  const hh = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}`
  const mm = `${
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  }`

  return `${hh}:${mm}`
}

export const isToday = (someDate) => {
  const today = new Date()
  const dateTime = new Date(someDate)
  return (
    dateTime.getDate() === today.getDate() &&
    dateTime.getMonth() === today.getMonth() &&
    dateTime.getFullYear() === today.getFullYear()
  )
}
