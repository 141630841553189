import React, { useState } from 'react'
import { Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { getIsSinglePackagingType } from 'entities/pim/packging'
import { pimApi } from 'shared/api'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'

import { NewBarcodeField, MarketPlaceAdvicedBarcode } from './NewBarcodeField'
import { EditBarcodeField } from './EditBarcodeField'
import { PlusButton, BarcodesInput, AlignWrapper } from './styled'


interface ProductBarcodesProps {
  marketPlaceAdvicedBarcode?: MarketPlaceAdvicedBarcode,
  barcodes?: pimApi.products.Barcode[]
  mainBarcodeId?: UniqueId
  editingDisabled?: boolean
  createNewBarcode: (barcode: pimApi.products.NewBarcode, onCreate: () => void) => void
  editBarcode: (barcode: pimApi.products.EditBarcode) => void
  deleteBarcode: (barcode: DeletingObject) => void
  makeMainBarcode: (barcodeId: UniqueId) => void
  barcodeType?: string
}

export const ProductBarcodes = (
  {
    marketPlaceAdvicedBarcode,
    barcodes,
    mainBarcodeId,
    editingDisabled,
    createNewBarcode,
    editBarcode,
    deleteBarcode,
    makeMainBarcode,
    barcodeType
  } : ProductBarcodesProps,
) => {
  const [addingBarcodeInProgress, setAddingBarcodeInProgress] = useState<boolean>(false)
  const goods = useGoodsContext()
  const goodsAdvicedBarcode = goods?.suppliers
    ?.reduce<Array<pimApi.goods.GoodsSupplierPackaging>>(
      (acc, next) => acc.concat(...(next.packaging ?? [])),
      []
    )
    .filter((packaging) => {
      const notInUse = barcodes?.every(b => b.barcode !== packaging.barcode)
      const isSinglePackaging = getIsSinglePackagingType(packaging)
      return notInUse && isSinglePackaging && !packaging.expiredFlag
    })
    .shift()

  const sortedBarcodes = [...(barcodes??[])].sort((a, b) => {
    if (a.id === mainBarcodeId) {
      return -1
    }
    if (b.id === mainBarcodeId) {
      return 1
    }
    if (a.id === undefined) {
      return 1
    }
    if (b.id === undefined) {
      return -1
    }
    if (a.expiredFlag) {
      if (b.expiredFlag) {
        return a.id<b.id ? -1 : 1
      }
      return 1
    }
    if (b.expiredFlag) {
      return -1
    }
    return a.id<b.id ? -1 : 1
  })

  const handleCreateNewBarcode = (barcode: string) => {
    createNewBarcode({ barcode }, () => {
      setAddingBarcodeInProgress(false)
    })
  }
  const handleAddBarcodeClick = () =>
    setAddingBarcodeInProgress(true)

  return (
    <AlignWrapper>
      <BarcodesInput>
        {sortedBarcodes?.map((barcode) => (
          <EditBarcodeField
            key={barcode.id}
            barcode={barcode}
            isMainBarcode={mainBarcodeId === barcode.id}
            disabled={editingDisabled}
            totalBarcodesCount={sortedBarcodes.length}
            editBarcode={editBarcode}
            deleteBarcode={deleteBarcode}
            makeMainBarcode={makeMainBarcode}
            barcodeType={barcodeType}
          />
        ))}
        {(barcodes?.length === 0 || addingBarcodeInProgress) &&
        <NewBarcodeField
          marketPlaceAdvicedBarcode={marketPlaceAdvicedBarcode}
          goodsAdvicedBarcode={goodsAdvicedBarcode?.barcode}
          createNewBarcode={handleCreateNewBarcode}
          disabled={editingDisabled}
          barcodeType={barcodeType}
          setAddingBarcodeInProgress={setAddingBarcodeInProgress}
        />
        }
      </BarcodesInput>
      {
        barcodes !==undefined && barcodes.length > 0 && !addingBarcodeInProgress && (
          <Box display="flex" alignContent="center" height="100%" minWidth="88px">
            <PlusButton
              variant="text"
              color="primary"
              onClick={handleAddBarcodeClick}
              disabled={editingDisabled}
            >
              <AddIcon />
            </PlusButton>
          </Box>
        )
      }
    </AlignWrapper>
  )
}
