import { Form } from 'react-final-form'
import {
  Box,
  Typography,
} from '@mui/material'

import { StyledLink } from './ui/styled'
import { MarketplaceForm } from './ui/MarketplaceForm'
import { CommonFields } from './ui/CommonFields'

const INSTRUCTION_LINK = 'https://customer.e-comportal.com/cdn/media/products_create_instruction'

export const BulkProductCreation = () => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize="20px" fontWeight={400} mb={1}>
        Массовое создание продуктов
      </Typography>
      <StyledLink href={INSTRUCTION_LINK} color="inherit">Инструкция массового создания продуктов</StyledLink>
    </Box>
    
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CommonFields/>
          <MarketplaceForm/>
        </form>
      )}
    />
  </Box>
)
