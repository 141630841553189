import { Option } from 'shared/ui/components/interface'

interface OptionItem {
  shortName?: string
  id: number
}

export const getOptionsContragent = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.shortName || '',
  value: optionItem.id
})
