import { ArrowForwardStyled } from 'shared/ui/product/LeftRightInputWrapper/ui/styled'
import styled, { css } from 'styled-components'

const IconButtonSelector = '*:nth-child(2)'

export const UnmapArrowStyled = styled.div<{ $disabled?: boolean }>`
flex: 0 0 auto;
width: 56px;
height: 40px;

position: relative;

& > ${ArrowForwardStyled} {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 1;
}

& > ${IconButtonSelector} {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
}

${props => !props.$disabled && css`
  &:hover {
    & > ${ArrowForwardStyled} {
      opacity: 0;
    }
    
    & > ${IconButtonSelector} {
      opacity: 1;
    }
  `
}
`