import { createBreakpoints, lighten } from '@mui/system'
import { createTheme } from '@mui/material/styles'

const breakpoints = createBreakpoints({})

export const LAYOUT_WIDTH = {
  content: '1142px',
  table: '100%',
  dashboard: '100%'
} as const

export const SIDEBAR_OPEN = '250px' as const
export const SIDEBAR_CLOSE = '56px' as const

export const theme = createTheme({
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.04)',
      disabled: 'rgba(0, 0, 0, 0.26)'
    },
    primary: {
      main: '#007DFF',
      dark: '#0057B2',
      light: '#3397FF',
      lightBg: lighten('#3397FF', 0.85),
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FF505F',
      dark: '#B23842',
      light: '#FF737F',
      lightBg: lighten('#FF737F', 0.85),
      contrastText: '#ffffff',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      lightBg: lighten('#7BC67E', 0.85),
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      lightBg: lighten('#64B6F7', 0.85),
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      lightBg: lighten('#ED6C02', 0.8),
      contrastText: '#ffffff',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      lightBg: lighten('#F88078', 0.85),
      contrastText: '#ffffff',
    },
  },
  spacing: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#efefef',
          height: '100%',
          [breakpoints.down('md')]: {
            fontSize: '13px',
          },
        },
        html: {
          height: '100%',
          overflow: 'inherit',
          // 'overflow-x': 'hidden',
          '::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
          },
        },

        '#root': {
          height: '100%',

        },
        'div.Toastify__toast': {
          fontFamily: 'inherit',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
    // @ts-ignore
    fontWeightBold: '700',
    // @ts-ignore
    fontWeightMedium: '500',
    // @ts-ignore
    fontWeightRegular: '400',
    // @ts-ignore
    fontWeightLight: '300',
    h1: {
      fontSize: '2.5rem',
      [breakpoints.down('md')]: {
        fontSize: '2.1rem',
      },
    },
    h2: {
      fontSize: '2rem',
      [breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontSize: '1.7rem',
      [breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '1.3rem',
      lineHeight: '1.3',
      [breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
    h5: {
      fontSize: '1.1rem',
      lineHeight: '1.3',
      [breakpoints.down('md')]: {
        fontSize: '1.0rem',
      },
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.3',
      [breakpoints.down('md')]: {
        fontSize: '0.9rem',
      },
    },
    body2: {
      [breakpoints.down('md')]: {
        fontSize: '0.775rem',
      },
    },
  },
})
