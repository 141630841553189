import { useMutation } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'

interface PostNewCabinet {
  companyId: UniqueId,
  addedCabinet: customerApi.NewCompanyMarketplace
}

export const postNewCabinet = ({ companyId, addedCabinet }: PostNewCabinet) =>
  api.post<customerApi.CompanyMarketplace>(
    `/customer/companies/${companyId}/cabinets`,
    addedCabinet
  )

export const usePostNewCabinetMutation = () =>
  useMutation<
    AxiosResponse<customerApi.CompanyMarketplace>,
    AxiosError<errorType>,
    PostNewCabinet
  >(postNewCabinet)