import React, { memo, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { checkValue, getInitialNumberValue, isNotNil } from 'shared/lib/checkers'
import { stringToBoolean } from 'shared/lib/utils'
import { reviewGen } from 'shared/lib/generated'
import { Filters } from 'features/reviews/ui/Filters'
import { isEmptyArray, isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ListOfReviews } from './List'

import { DataParamsTypes } from '../../../../lib/types'
import { ReviewsPagination } from '../../../ReviewsPagination/ReviewsPagination'
import { pagingPanelLocale } from '../lib/localization'
import { PlugReviews } from '../../../PlugReviews'
import { StyledLoader } from '../../../styled'

const ROW_HEIGHT = 160
const HEADER_HEIGHT = 300
const TABLE_CONTROLS = 0
const TABLE_HEADER_FOOTER = 0
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const ReviewsTab = memo(() => {
  const { search } = useLocation()
  const searchObj: any = queryString.parse(search)
  const history = useHistory()

  const bonusCols = Math.floor(
    (window.innerHeight -
        DEFAULT_ROWS_COUNT * ROW_HEIGHT -
        HEADER_HEIGHT -
        TABLE_CONTROLS -
        TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
      bonusCols > MIN_ROWS_COUNT
        ? DEFAULT_ROWS_COUNT + bonusCols
        : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.pageSize)
    ? parseInt(searchObj.pageSize.toString(), 10)
    : calculatedRowsPerPage

  const handleReviewsParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, encode: true }
    )
    history.replace({ search: `?${historyStr}` })
  }
  
  const [ dataParams, setDataParams ] = useState<DataParamsTypes>({
    reviewId: getInitialNumberValue(searchObj?.reviewId),
    sortedValue: stringToBoolean(searchObj?.sortedValue),
    searchString: checkValue(searchObj?.searchString),
    suppliersId: getInitialNumberValue(searchObj?.suppliersId),
    brandId: getInitialNumberValue(searchObj?.brandId),
    companyId: getInitialNumberValue(searchObj?.companyId),
    companymarketplaceId: getInitialNumberValue(searchObj?.companymarketplaceId),
    marketplaceId: getInitialNumberValue(searchObj?.marketplaceId),
    page: getInitialNumberValue(searchObj?.page) || 0,
    pageSize: getInitialNumberValue(searchObj?.pageSize) || currentRowsPerPage,
    reviewtypeId: 1,
    viewed: searchObj?.viewed !== undefined ? stringToBoolean(searchObj?.viewed) : false,
    processed: searchObj?.processed !== undefined ? stringToBoolean(searchObj?.processed) : false,
    exam: searchObj?.exam !== undefined ? stringToBoolean(searchObj?.exam) : false,
  })

  useEffect(() => handleReviewsParams(dataParams),[dataParams])

  const { data: reviewsData, isFetching, isLoading } =
    reviewGen.review.GetReviews.useGetReviews({ query: dataParams }, { keepPreviousData: false })
  const { viewed, processed, ...rest } = dataParams
  const { data: reviewsStatData } = reviewGen.review.GetReviewStats.useGetReviewStats({ query: rest },
    { refetchOnMount: true, staleTime: 0 })
  const reviewsCount = useMemo( () => ({
    allcount: reviewsStatData?.allcount,
    exam: reviewsStatData?.exam,
    new: reviewsStatData?.new,
    viewed: reviewsStatData?.viewed,
    processed: reviewsStatData?.processed,
  }), [reviewsStatData, reviewsData])

  return (
    <div style={{ borderTop: '1px solid #E0E0E0' }}>
      <Filters reviewsCount={reviewsCount} setDataParams={setDataParams} dataParams={dataParams}/>
      { (isLoading || isFetching) &&
      <div style={{ minHeight: '300px', minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <StyledLoader size={60}/>
      </div>
      }
      { (reviewsData && isNotEmptyArray(reviewsData) ) &&
        <ListOfReviews key={JSON.stringify(dataParams)} data={reviewsData} dataParams={dataParams}/>
      }
      { (!(isLoading || isFetching) && reviewsData && isEmptyArray(reviewsData)) &&
        <PlugReviews
          reviewType={dataParams.reviewtypeId}
          processed={dataParams.processed}
          viewed={dataParams.viewed}
        />
      }
      { (reviewsData && isNotEmptyArray(reviewsData)) &&
      <ReviewsPagination
        pagingPanelLocale={pagingPanelLocale}
        currentRowsPerPage={currentRowsPerPage}
        dataParams={dataParams}
        setDataParams={setDataParams}
        reviewsCount={reviewsCount}
      />
      }
    </div>
  )
}
)