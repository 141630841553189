import { isNotNil } from 'shared/lib/checkers'

export const replaceEditorTags = (str: string | null | undefined) => {
  let result

  if(str === '<p><br></p>') return ''

  if(isNotNil(str)) {
    result = str.replace(/<p><br><\/p>/gi, '<br/>')
    result = result.replace(/<\/p>(.)(?!\/br>$)/gi, '<br/>$1')
    result = result.replace(/<\/*p>/gi, '')
    result = result.replace(/(<br\/>)+$/gi, '')
  }

  return result
}
