import { AxiosError } from 'axios'
import { useQuery, QueryFunctionContext, UseQueryOptions } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

type PimProductsOzonProductQueryKey = ReturnType<
  typeof getOzonProductQueryKey
  >

export const getOzonProductQueryKey = (productId?: UniqueId) =>
  ['get', 'pim/products/ozon/product', productId] as const

export const getOzonProduct = (productId?: UniqueId) =>
  api
    .get<pimApi.products.ozon.OzonProduct>(
      `/pim/products/ozon/product/${productId}`
    )
    .then(({ data }) => data)

export const fetchOzonProduct = (
  context: QueryFunctionContext<PimProductsOzonProductQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getOzonProduct(queryParams)
}

export const useFetchOzonProductQuery = (
  productId?: UniqueId,
  options?: UseQueryOptions<
    pimApi.products.ozon.OzonProduct,
    AxiosError<errorType>,
    pimApi.products.ozon.OzonProduct,
    PimProductsOzonProductQueryKey
    >
) =>
  useQuery<
    pimApi.products.ozon.OzonProduct,
    AxiosError<errorType>,
    pimApi.products.ozon.OzonProduct,
    PimProductsOzonProductQueryKey
    >(getOzonProductQueryKey(productId), fetchOzonProduct, { ...options, refetchOnMount: false })
