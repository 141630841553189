import React from 'react'

import { StyledTextContainer } from '../../styled'

export const PictureSampleRequirements = () => {
  const Text = 'Формат — JPEG, JPG, PNG. Размер — не больше 10 Мбайт'
  const Element = (
    <StyledTextContainer>
      <ul>
        <li>формат — JPEG, JPG, PNG</li>
        <li>размер — не больше 10 Мбайт</li>
        <li>максимум одно изображение</li>
      </ul>
    </StyledTextContainer>
  )
  return { Text, Element }
}