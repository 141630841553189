import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { tradeGen } from 'shared/lib/generated'

import { ChangeWarehouseModal } from '../../ui/modal/ChangeWarehouseModal'
import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'

export const ChangeWarehouse = () => {
  const { setDistributionCache } = useChangeDistribution()
  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const { supplyOrderQuery } = useSupplyOrderContext()

  const { mutate: changeWarehouse } = tradeGen.orders.
    Changecopackingdistributionacceptancecoefficients.
    useChangecopackingdistributionacceptancecoefficients()
  
  const changeWarehouseFunc = () => dialogModel.openDialog({
    component: ({ close, accept }) =>
      <ChangeWarehouseModal supplyOrderQuery={supplyOrderQuery} accept={accept} close={close} />,
    onAccept: (newWarehouse) => {
      setIsLoading(true)
      changeWarehouse({
        copackingorderId: supplyOrderQuery.data?.copackingorderId!,
        copackingdistributionwarehouseId: selectedProducts?.warehouse?.id!,
        data: {
          id: selectedProducts?.warehouse?.id!,
          versionNo: selectedProducts?.warehouse?.versionNo!,
          statusId: selectedProducts?.warehouse?.statusId,
          pacceptancecoefficientsId: newWarehouse.id
        }
      }, {
        onSuccess: (response) => {
          setIsLoading(false)
          setSelectedProducts(null)
          setDistributionCache({ supplyOrderQuery, response })
          snackActions.info('Склад успешно заменен')
        },
        onError: () => setIsLoading(false)
      })
    } })

  return { changeWarehouseFunc }
}