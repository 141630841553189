import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface TableIsDisabledProps {
  isDisabled: boolean;
}
export const StyledTableBox = styled(Box)<TableIsDisabledProps>`
  position: relative;
  overflow: hidden;
  pointer-events: ${(props) => props.isDisabled && 'none' };
  border-radius: 4px;
  box-shadow: 0 0 0 1px #E0E0E0;

  .MuiTableCell-head {
    background: #F6F6F680;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledPrivilegeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  
  .checkbox-container {
    margin-left: auto;
    width: 150px;
    
    span {
      padding: 0
    }
  }
  
  .date-container {
    width: 160px;
    height: 100%;
    padding-left: 16px;
  }
  
  .edit-icon-container {
    width: 60px;
    display: flex;
    justify-content: end;
    svg {
      color: rgba(0, 0, 0, 0.26);
      cursor: pointer;
    }
  }
`

export const TreeViewWrapper = styled.div`
  min-width: 320px;
  padding-top: 18px;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 400;

  .searchInput {
    padding-left: 24px !important;
    >div {
      width: 220px;
    }
  }
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F6F6F680;
    box-shadow: 0 -1px 0 0 #E0E0E0 inset, 0 1px 0 0 #E0E0E0 inset;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      padding-left: 16px;
      width: 160px;
      :first-child {
        width: 582px;
        padding-left: 60px;
      }
      :last-child {
        width: 220px;
      }
    }
  }
  .searchInput {
    color: rgba(0, 0, 0, 0.26);
    display: flex;
    min-width: 100px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
  }

  .MuiTreeView-root {
    padding-left: 20px;
    .MuiTreeItem-group {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      margin-left: 22px;
    }
    
    .MuiTreeItem-iconContainer {
      svg {
        font-size: 24px;
        color: #007DFF;
      }
    }
    
    
    .MuiTreeItem-content {
      border-radius: 4px;
      height: 42px;
      padding: 0 16px;
      background-color: rgba(255, 255, 255, 0) !important;
  
      :hover {
        background-color: #e9f4ff !important;
      }
      
      .MuiTreeItem-label {
        font-size: 16px;
        font-weight: 400;
        padding-left: 4px;
  
        highlight {
          background-color: yellow;
        }
      }

      .MuiTreeItem-iconContainer:not(:has(svg)) {
        width: 0;
      }
    }
  }
`