/* eslint-disable */
// @ts-nocheck

import React from 'react'
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material'

interface IButton extends ButtonProps {
  isLoading?: boolean
  isDisabled?: boolean
}

const Button = (props: IButton) => {
  const { isLoading, children, ...rest } = props
  const disabled = isLoading || props.disabled
  return (
    <MuiButton {...rest} disabled={disabled}>
      {isLoading ? <CircularProgress /> : children}
    </MuiButton>
  )
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
}

export default Button
