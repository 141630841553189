import { StyledNestedWidget } from 'features/analytics/reprice/ui/styled'

import { TableHeader } from '../../../../ui/TableHeader'
import { TableBody } from '../../../../ui/TableBody'
import { getCellWidth } from '../../../../lib/getCellWidth'
import { NestedWidgetCells } from '../../lib/nestedWidgetCells'

export const NestedWidget = ({ row }) => (
  <StyledNestedWidget style={{ padding: '8px 16px 8px 128px' }}>
    <table>
      <TableHeader tableCells={NestedWidgetCells} />
      <TableBody tableCells={NestedWidgetCells} tableData={[row]} tableRow={TableRow}/>
    </table>
  </StyledNestedWidget>
)

const TableRow = ({ row, tableCells }) => (
  <tr>
    { tableCells.map(({ padding, width, Component }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <td key={index} style={ { padding, ...getCellWidth(width) } }>
        <Component row={ row }/>
      </td>
    )) }
  </tr>
)