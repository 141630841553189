import { memo, useCallback } from 'react'
import { Box } from '@mui/material'
import { tradeApi } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { columnsConfig } from 'features/supplyOrders/OrderHistory/model/tableColumnsConfig'
import { tableParams } from 'features/supplyOrders/lib/tableParamsSetting'

import { StyledTableBox, StyledLoader, TableWrapper } from '../styled'

const getRowId = (row: tradeApi.SupplyOrderHistoryRecord) => row.actionDate + row.actionName

const OrderHistoryTable = () => {
  const { supplyOrderQuery, tabsSettingObject  } = useSupplyOrderContext()
  const orderId = supplyOrderQuery.data?.id as number

  const memoTableParams = useCallback(
    () => tableParams(tabsSettingObject, 'history'),
    [supplyOrderQuery?.data, tabsSettingObject?.inTabsObjectInit]
  )
  if (!orderId) return null

  const { data, isLoading, isFetching } = tradeApi.orders.useOrderHistoryQuery(orderId)

  return (
    <>
      <StyledTableBox component={Box}>
        {(isLoading || isFetching) && <StyledLoader size={60} />}
        {data && (
          <TableWrapper>
            <Table
              tableList={data}
              getRowId={getRowId}
              tableParams={memoTableParams()}
              columnsConfig={columnsConfig}
              showInArchiveControl={false}
              showColumnsVisibility={false}
              sorting="internal"
              isStoredSettings={false}
            />
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoOrderHistoryTable = memo(OrderHistoryTable)
