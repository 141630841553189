
export type DictsPageTab =
    'brands' |
    'second' |
    'third' |
    'privileges'


export const DICTS_TAB: Array<DictsPageTab> = [
  'brands',
  'privileges',
  'second',
  'third'
]