import React, { useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { Image as SharedImage } from 'shared/ui/components'
import { GoodsInformation } from 'shared/lib/generated/brandquad/Api'

export const MediaContainer = ({ goodsInfo }: { goodsInfo: GoodsInformation }) => {
  const [ selectedMedia, setSelectedMedia ] = useState(JSON.stringify(goodsInfo.images![0]))
  
  return (
    <div className="good-photo">
      <div className="goods-photo-container">
        <SharedImage className="main-photo" src={ JSON.parse(selectedMedia).url } />
        <Tabs
          className="mediaTabs"
          variant="scrollable"
          scrollButtons="auto"
          onChange={ (event, value) => {
            if (event.type === 'click') {
              setSelectedMedia(value)
            }
          } }
          selectionFollowsFocus={ true }
          value={ selectedMedia }>
          { goodsInfo.images!.map(el => (
            <Tab
              key={JSON.stringify(el)}
              value={ JSON.stringify(el) }
              disableRipple={ true }
              label={ <SharedImage src={ el.url } /> }
            />
          )) }
        </Tabs>
      </div>
    </div>
  )
}