import styled from 'styled-components'

export const StyledModalPage = styled.div`
  width: 640px;
  
  .modal-title {
    font-size: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .input-component {
      
    }
  }
`

export const CategoryHeader = styled.div`
  height: 128px;
  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid rgba(224, 224, 224, 1);

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .path-text {
    font-size: 14px;
    color: ${ ({ theme }) => theme.palette.text.secondary };
  }

  .category-name-text {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .options-container {
    display: flex;
    align-items: end;

    .MuiButtonBase-root {
      padding: 0;

      :hover {
        background: rgba(255, 255, 255, 0);
      }
    }
  }
`