import React from 'react'
import { Box } from '@mui/material'

import { StyledPlugMarketplace } from './styled'

export const PlugMarketplace = () => (
  <StyledPlugMarketplace>
    <span>Эластичность спроса</span>
    <Box>
      <span>
        {'Выберите продукт для отображения \nграфика эластичности спроса'}
      </span>
    </Box>
  </StyledPlugMarketplace>
)