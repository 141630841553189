import { useMemo } from 'react'
import Paper from '@mui/material/Paper'
import { TablePagination } from '@mui/material'
import { isNotNil } from 'shared/lib/checkers'
import { pimGen } from 'shared/lib/generated'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { getRowsPerPageOptions } from 'shared/ui/components/Table/lib'

import { StyledLoader, StyledTableWrapper } from '../styled'
import { PriceAdviceTableRow } from '../PriceAdviceTableRow'
import { pagingPanelLocale } from '../../lib/localization'
import { TableFilters } from '../../../../filter/tableFilters'
import { TableHeader } from '../../../../ui/TableHeader'
import { TableBody } from '../../../../ui/TableBody'
import { PriceAdviceCells } from '../../lib/priceAdviceCells'
import { StyledTableBox } from '../../../priceSettings/ui/styled'


const ROW_HEIGHT = 76
const HEADER_HEIGHT = 160
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

const bonusCols = Math.floor(
  (window.innerHeight -
    DEFAULT_ROWS_COUNT * ROW_HEIGHT -
    HEADER_HEIGHT -
    TABLE_CONTROLS -
    TABLE_HEADER_FOOTER) /
  ROW_HEIGHT
)
const calculatedRowsPerPage =
  bonusCols > MIN_ROWS_COUNT
    ? DEFAULT_ROWS_COUNT + bonusCols
    : DEFAULT_ROWS_COUNT


export const PriceAdviceTable = () => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })
  const { limit, page, tab, ...searchParams } = searchObj
  const { data: priceAdviceArray, isFetching, isLoading } = pimGen.priceWizard.GetPriceAdvice.useGetPriceAdvice({ query: searchParams })

  const filteredPriceAdviceArray = priceAdviceArray?.filter(el => !el.priceSet && !el.declined) || []

  const currentRowsPerPage = isNotNil(limit)
    ? parseInt(limit, 10)
    : calculatedRowsPerPage

  const limits = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const pageSizes = getRowsPerPageOptions(filteredPriceAdviceArray?.length || 0, currentRowsPerPage)

  const startIndex = (page ?? 0) * limits

  const tableData = useMemo(
    () => filteredPriceAdviceArray?.slice(startIndex, startIndex + limits),
    [filteredPriceAdviceArray, page, limit])

  const handlePaginationChange = (field: 'page' | 'limit', value) => {
    handleChangeParams({ params: { [field]: value }, options: { skipNull: true } })
  }

  return (
    <StyledTableBox component={Paper}>
      <TableFilters/>
      {(isLoading || isFetching) && (<StyledLoader size={60} />)}
      {tableData && (
        <StyledTableWrapper>
          <table>
            <TableHeader tableCells={PriceAdviceCells}/>
            <TableBody
              tableCells={PriceAdviceCells}
              tableData={tableData}
              tableRow={PriceAdviceTableRow}
            />
          </table>
        </StyledTableWrapper>) }
      {(isNotEmptyArray(filteredPriceAdviceArray) && filteredPriceAdviceArray && filteredPriceAdviceArray.length > calculatedRowsPerPage) &&
        <div style={ { width: '100%', display: 'flex', justifyContent: 'end' } }>
          <TablePagination
            count={filteredPriceAdviceArray.length}
            onPageChange={(_, value) => handlePaginationChange('page', value)}
            onRowsPerPageChange={(e) => handlePaginationChange('limit', e.target.value)}
            page={page ?? 0}
            rowsPerPageOptions={pageSizes}
            rowsPerPage={limits}
            labelDisplayedRows={ pagingPanelLocale.info }
            labelRowsPerPage={ pagingPanelLocale.rowsPerPage }
          />
        </div>
      }
    </StyledTableBox>
  )
}