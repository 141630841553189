import {  useState } from 'react'
import { Box } from '@mui/material'
import { ModalContent } from 'shared/ui/components/ModalComponents'

interface ConfoirmModalProps {
  close: () => void
  accept: () => void
}

export const DeleteConfirmModal = ({ close, accept }: ConfoirmModalProps) => {
  const [isLoading, setIsloading] = useState(false)
  const hadleAccept = () => {
    setIsloading(true)
    accept()
  }
  
  return (
    <Box width="500px">
      <ModalContent
        close={close}
        accept={hadleAccept}
        title="Действительно удалить?"
        acceptBtnText="Удалить"
        acceptBtnColor="error"
        isLoading={isLoading}
      />
    </Box>
  )
}
