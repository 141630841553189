import { Box } from '@mui/material'
import { useField, useForm } from 'react-final-form'
import { ComponentCard } from 'entities/pim/goods'
import { NotFoundMessageCard } from 'shared/ui/components/NotFoundMessageCard'
import { isNotNil } from 'shared/lib/checkers'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import * as GoodsService from 'shared/api/wms/GoodsService'
import { wmsApi } from 'shared/api'

import { InfoFields } from './InfoFields'
import { SearchField } from './SearchField'

import { FormValuesType } from '../types'

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const FormFields = () => {
  const goodsId = useField('goodsId').input.value || undefined
  const { change, batch } = useForm<FormValuesType>()

  const { data: productData,  mutate: productSearch, isLoading, isIdle : notLoadedYet } = GoodsService.useGoodsSearch()
  const productList = productData?.data

  const clearFields = (goodsIdValue?: number) => {
    batch(() => {
      change('goodsId', goodsIdValue)
      change('reason', '')
      change('comments', '')
      change('quantity', '')
    })
  }

  const search = (barcodeSeatchStr: string) => {
    productSearch({ barcodeSeatchStr })
    clearFields()
  }

  const selectProduct = (id?: number) => () => {
    clearFields(id)
  }

  const isEmptyList = !notLoadedYet && !isLoading && isEmpty(productList)
  const filtredProductList = productList?.filter(item => (
    !isNotNil(goodsId) || item.id === goodsId))

  return (
    <>
      <Box mx={3} my={0.5}>
        <SearchField search={search} isLoading={isLoading} />
      </Box>

      {isEmptyList && (
        <Box mx={3} my={2}>
          <NotFoundMessageCard
            title="Товар не найден"
            description="Введите параметры вручную"
          />
        </Box>
      )}

      <Box mx={3} my={2}>
        {filtredProductList?.map((goodsComponent) => {
          const imgUrl = wmsApi.getFileThumbnailApiPath(
            goodsComponent.id,
            goodsComponent.images?.[0]?.id as number,
            fileThumbnailSize
          )

          return (
            <ComponentCard
              key={goodsComponent.id}
              imgUrl={imgUrl}
              goodsType={goodsComponent.goodsType}
              componentId={goodsComponent.id}
              name={goodsComponent.name}
              code={goodsComponent.code}
              auxCode={goodsComponent.auxCode}
              onRemoveClick={
                goodsId === goodsComponent.id
                  ? selectProduct(undefined)
                  : undefined
              }
              onAddClick={
                goodsId
                  ? undefined
                  : selectProduct(goodsComponent.id)
              }
            />
          )
        })}
      </Box>

      <InfoFields
        isShowFields={!!goodsId || isEmptyList}
        isShowCommentsField={!goodsId}
      />
    </>
  )
}
