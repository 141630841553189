import React from 'react'
import imagePlaceholder from 'assets/images/defaultGoodImage.svg'
import { customerApi } from 'shared/api'

import { CompanyImg } from './styled'

export const CompanyPhotoCell = ({ row }) => {
  const { id, inArchive } = row
  const imageData = customerApi.useCompanyLogoQuery(id)
  const companyImage = imageData?.data?.data as Blob
  if (companyImage) {
    return (
      <CompanyImg
        src={URL.createObjectURL(companyImage)}
        alt="company icon"
        $inArchive={inArchive}
      >
        <img src={imagePlaceholder} alt="default icon"/>
      </CompanyImg>
    )
  }
  return (
    <img src={imagePlaceholder} alt="default icon"/>
  )
}
