import styled from 'styled-components'
import TextField from '@mui/material/TextField'

export const InputStyled = styled(TextField)<any>`
.MuiFormLabel-root{
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
}
`