import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import LayersIcon from '@mui/icons-material/Layers'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

interface ColumnName
  extends Omit<
    pimApi.categoryAttributes.CategoryAttribute,
    | 'id'
    | 'versionNo'
    | 'changer'
    | 'componentSize'
    | 'categoryId'
    | 'changed'
    | 'dictionaryId'
    | 'attributegroupId'
    | 'attributeId'
    | 'orderNumber'
  > {
  actionColumn?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  attributeName: 'attributeName',
  variationSupport: 'variationSupport',
  multipleValues: 'multipleValues',
  required: 'required',
  actionColumn: 'actionColumn'
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.attributeName,
      columnName: columns.attributeName,
      title: 'Наименование атрибута',
      width: 250,
      sortingEnabled: false,
    },
    {
      name: columns.variationSupport,
      columnName: columns.variationSupport,
      title: 'Вариативный',
      icon: <LayersIcon/>,
      width: 160,
      sortingEnabled: false,
      editingEnabled: false,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.multipleValues,
      columnName: columns.multipleValues,
      title: 'Множественный',
      icon: <LibraryAddIcon/>,
      sortingEnabled: false,
      editingEnabled: false,
      width: 165,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.required,
      columnName: columns.required,
      title: 'Обязательный для категории',
      sortingEnabled: false,
      width: 'auto',
      editingEnabled: true,
      cellType: CellType.BooleanCell
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 40,
      cellType: CellType.SettingsMenuCell
    }
  ],
}
