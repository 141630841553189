import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosError } from 'axios'

import { CoPackingOrderStat } from './models'

const getCopackingStat = () => api.get<CoPackingOrderStat>('/copacking/copackingorders/stat').then((res) => res.data)

type QueryKey = ReturnType<typeof QUERY_KEY>

const QUERY_KEY = () => ['get', 'copacking/copackingorders/stat'] as const

export const useGetCopackingStatQuery = () =>
  useQuery<
    CoPackingOrderStat,
    AxiosError<errorType>,
    CoPackingOrderStat,
    QueryKey
  >(
    QUERY_KEY(),
    () => getCopackingStat(),
    { refetchOnMount: 'always', staleTime: Infinity }
  )