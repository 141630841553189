import { Table } from '@devexpress/dx-react-grid-material-ui'
import { pimApi } from 'shared/api'

import { ActionsCell } from './actionsCell'
import { StyledTableCell } from './styled'
import { PriceCell } from './priceCell'
import { DateCell } from './dateCell'

import { columns } from '../../columnsConfig'
import { PriceHistoryRecord } from '../../../../model'

const getCell = (name: string, row: PriceHistoryRecord) => {
  switch (name) {
    case columns.changeDate: {
      return <DateCell date={row.changeDate} />
    }

    case columns.price: {
      return <PriceCell price={row.price} discount={row.discount} priceBeforeDiscount={row.priceBeforeDiscount}/>
    }

    case columns.actions: {
      return <ActionsCell fileUrl={row.batchFileUrl} date={row.changeDate} priceChangeId={row.id} versionNo={row.versionNo} />
    }

    default:
      return <span title={row[name]}>{row[name]}</span>
  }
}

type TableCellComponentProps = Omit<Table.DataCellProps, 'row'> & { row: pimApi.products.PriceInfo }

export const TableCellComponent = (props: TableCellComponentProps) => {
  const { column, row } = props
  return (
    <StyledTableCell {...props}>
      {getCell(column.name, row)}
    </StyledTableCell>
  )
}
