import { StyledLinkName } from './styled'

interface LinkNameProps {
  name: string
  path: string
  isArchived?: boolean
  isSingleString?: boolean
}

export const LinkNameBlankCell = ({ name, path, isArchived, isSingleString }: LinkNameProps) => (
  <StyledLinkName to={path} target="_blank" title={name} $isArchived={isArchived} $isSingleString={isSingleString}>
    {name}
  </StyledLinkName>       
)
