import { getBulkOperationsPath } from 'shared/config'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import ArchiveIcon from '@mui/icons-material/Archive'
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { BulkOperationsContainer } from 'pages/bulkOperations/bulkOperationsPage/ui/styled'

import PanelContentElement from '../../../adminPanel/AdminPanelRoot/Content/ui/AdminPanelContentElement'


export const ProductsBulkOperationsTab = () => (
  <BulkOperationsContainer>
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'create'),
        subtitle: 'Массовое создание продуктов',
        label: 'Создать',
        icon: <AddIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'edit'),
        subtitle: 'Массовое изменение продуктов',
        label: 'Изменить характеристики',
        icon: <EditIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'pricechange'),
        subtitle: 'Массовое изменений цен продуктов',
        label: 'Изменить цены',
        icon: <CurrencyRubleIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'paramsedit'),
        label: 'Изменить внутренние параметры',
        icon: <AppRegistrationIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'archive'),
        subtitle: 'Массовая архивация продуктов',
        label: 'Архивировать',
        icon: <ArchiveIcon/>
      }}
    />
    <PanelContentElement
      element={{
        value: getBulkOperationsPath('products', 'unarchive'),
        subtitle: 'Массовая разархивация продуктов',
        label: 'Вернуть из архива',
        icon: <UnarchiveIcon/>
      }}
    />
  </BulkOperationsContainer>
)
