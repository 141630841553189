import React from 'react'

export const Grid = ({ children, columns }) => (
  <div
    className="mediaGrid"
    style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridGap: 16,
      padding: '16px 0px'
    }}
  >
    {children}
  </div>
)