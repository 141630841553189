import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Season } from './models'

import { api } from '../../base'



export function fetchSeasons(): Promise<AxiosResponse<Season[]>> {
  return api.get<Season[]>('/pim/seasons')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', '/pim/seasons'] as const

function getSeasons() {
  return fetchSeasons().then((res) => res.data)
}

export function useSeasonListQuery() {
  const seasonListQuery = useQuery<
    Array<Season>,
    errorType,
    Array<Season>,
    QueryKey
  >(QUERY_KEY, getSeasons, {
    refetchOnMount: false
  })

  return {
    seasonListQuery,
  } as const
}