import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

export const createSupplyOrder = (newSupplyOrder: tradeApi.NewSupplyOrder) =>
  api.post<tradeApi.NewSupplyOrderResponse>(
    '/trade/orders/supplyorders',
    newSupplyOrder
  )

export const useCreateSupplyOrderMutation = () =>
  useMutation<
    AxiosResponse<tradeApi.NewSupplyOrderResponse>,
    AxiosError<errorType>,
    tradeApi.NewSupplyOrder
  >(createSupplyOrder)