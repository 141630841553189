import { useMutation } from 'react-query'
import { productVariationCreate } from 'shared/api/pim/goods/goodsVariation'
import { FormValues } from 'pages/goods/variant/interface'
import { pimApi } from 'shared/api'

export const useVariation = () => {
  const { mutate: createVariationCreate } = useMutation<
    pimApi.goods.Goods,
    errorType,
    { goodsId: number; variationFormValues: FormValues }
  >(({ goodsId, variationFormValues }) =>
    productVariationCreate(goodsId, {
      ...variationFormValues,
      variationAttributes: variationFormValues.variationAttributes.map(
        (item, index) => ({
          ...item.variation,
          value: item.value,
          orderNumber: index,
        })
      ),
    }).then((res) => res.data)
  )

  return { create: createVariationCreate } as const
}
