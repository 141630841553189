import { Breakpoint } from '@mui/material'
import { createStore, createEvent } from 'effector'
import { RootStore } from 'store'
import { SxProps } from '@mui/system'

import { DialogConfirmContent } from '../lib/interface'

type ConfirmCallback = () => void

interface State_old {
  confirmCallback: Nullable<ConfirmCallback>
  Content: Nullable<DialogConfirmContent>
}

export interface dialogOptions {
  stayOnAccept?: boolean
  fullWidth?: boolean
  maxWidth?: false | Breakpoint
}

interface State {
  onAccept?: Nullable<(params?: any) => void>
  component: Nullable<any>
  options?: Nullable<dialogOptions>
  sx?: SxProps
  isOpen: boolean
  isLoading?: boolean
}

const initialState_old: State_old = {
  confirmCallback: null,
  Content: null,
}

const initialState: State = {
  onAccept: null,
  component: null,
  options: null,
  sx: null,
  isOpen: false,
  isLoading: false,
}

export const $dialogState = createStore(initialState)

export const openDialog = createEvent<Omit<State, 'isOpen'>>()
export const closeDialog = createEvent()
export const setDialogIsLoading = createEvent<Pick<State, 'isLoading'>>()

$dialogState.on(openDialog, (_, { component, options, onAccept, sx }) => ({
  isOpen: true,
  options,
  component,
  sx,
  onAccept,
}))

$dialogState.on(setDialogIsLoading, (state, payload) => ({
  ...state,
  isLoading: payload.isLoading,
})
)

$dialogState.reset(closeDialog)

/* Selectors */
const isOpenDialogSelector = (state: RootStore): boolean =>
  state.dialogConfirm.confirmCallback !== null &&
  state.dialogConfirm.Content !== null
const confirmCallbackSelector = (state: RootStore): Nullable<ConfirmCallback> =>
  state.dialogConfirm.confirmCallback
const ContentSelector = (state: RootStore): Nullable<DialogConfirmContent> =>
  state.dialogConfirm.Content

/* Actions */
const SHOW_DIALOG_CONFIRM = 'pim/dialogConfirm/showDialogConfirm' as const
const HIDE_DIALOG_CONFIRM = 'pim/dialogConfirm/hideDialogConfirm' as const

/* Action Creators */
interface ShowDialogConfirmAction {
  type: typeof SHOW_DIALOG_CONFIRM
  confirmCallback: ConfirmCallback
  renderContent: DialogConfirmContent
}
type ShowDialogConfirm = (
  confirmCallback: ConfirmCallback,
  renderContent: DialogConfirmContent
) => ShowDialogConfirmAction
const showDialogConfirm: ShowDialogConfirm = (
  confirmCallback,
  renderContent
) => ({
  type: SHOW_DIALOG_CONFIRM,
  confirmCallback,
  renderContent,
})

interface HideDialogConfirmAction {
  type: typeof HIDE_DIALOG_CONFIRM
  confirmCallback: null
  renderContent: null
}
const hideDialogConfirm: () => HideDialogConfirmAction = () => ({
  type: HIDE_DIALOG_CONFIRM,
  confirmCallback: null,
  renderContent: null,
})

type Action = ShowDialogConfirmAction | HideDialogConfirmAction

/* Reducer */
export function reducer(state = initialState_old, action: Action): State_old {
  switch (action.type) {
    case SHOW_DIALOG_CONFIRM:
      return {
        ...state,
        confirmCallback: action.confirmCallback,
        Content: action.renderContent,
      }
    case HIDE_DIALOG_CONFIRM:
      return {
        ...state,
        confirmCallback: action.confirmCallback,
      }
    default:
      return state
  }
}

/* Sagas */

/* public api */
export const actions = {
  showDialogConfirm,
  hideDialogConfirm,
}

export const selectors = {
  isOpenDialog: isOpenDialogSelector,
  confirmCallback: confirmCallbackSelector,
  Content: ContentSelector,
}
