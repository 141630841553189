import { format } from 'date-fns'

import { StyledContainer } from './styled'

export const DateAndUserNameCell = ({ row }) => {
  const { changeDate, changer } = row
  const utc = new Date(changeDate)
  const dateStr = format(new Date(utc), 'dd.MM.yyyy HH:mm')

  return (
    <StyledContainer>
      <span>{dateStr}</span>
      <span title={changer} id="changer-name-text">{changer}</span>
    </StyledContainer>
  )
}