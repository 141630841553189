import styled from 'styled-components'
import { FormLabel } from '@mui/material'

export const LabelType = styled.span`
  line-height: initial;
  color: #007dff;
`

export const InputLabel = styled.div<{ $isSingle?: boolean }>`
  display: flex;
  margin-bottom: ${({ $isSingle  }) => ($isSingle ? '0px;' : '16px;')};
  
  align-items: center;
`


export const FormLabelStyled = styled(FormLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
`