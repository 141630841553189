import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { getFileSizeParams } from 'shared/api/getFileSizeParams'
import { pimApi } from 'shared/api'

interface CreateProductMediaPayload {
  productId: UniqueId
  mediaType?: string
  file: FormData | null
  mediaIndex?: string
}

interface createProductMediaByURLPayload {
  productId: UniqueId
  mediaType: string
  url?: string
}

export const validateProductMediaByURL = ({
  productId,
  mediaType,
  url,
}: createProductMediaByURLPayload) =>
  api.post(
    `/pim/products/wb/product/${productId}/${mediaType}/url/validator`,
    { url }
  )

export const useValidateProductMediaByURLMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    createProductMediaByURLPayload
  >(validateProductMediaByURL)

export const validateMediaFile = (queryParams: CreateProductMediaPayload) =>
  api.post<pimApi.products.MediaFile>(
    `/pim/products/wb/product/${queryParams.productId}/${queryParams.mediaType}/file/validator`,
    queryParams.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useValidateProductMediaFileMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    CreateProductMediaPayload
  >(validateMediaFile)

export const createMedia = ({
  productId,
  mediaType,
  file,
}: CreateProductMediaPayload) =>
  api.post<pimApi.products.MediaFile>(
    `/pim/products/wb/product/${productId}/${mediaType}/file`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useCreateProductMediaMutation = (options?) =>
  useMutation<
    AxiosResponse<pimApi.goods.MediaFile>,
    AxiosError<errorType>,
    CreateProductMediaPayload
  >(createMedia, options)

export const createProductMediaByURL = ({
  productId,
  mediaType,
  url,
}: createProductMediaByURLPayload) =>
  api.post(
    `/pim/products/wb/product/${productId}/${mediaType}/url`,
    { url }
  )

export const useCreateProductMediaByURLMutation = (options) =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    createProductMediaByURLPayload
  >(createProductMediaByURL, options)

interface UpdateProductMediaPayload {
  productId: UniqueId
  mediaType: string
  editMediaFileList: Array<pimApi.products.wb.EditWBMedia>
}

export const updateMedia = ({
  productId,
  mediaType,
  editMediaFileList,
}: UpdateProductMediaPayload) =>
  api.put<Array<pimApi.products.wb.EditWBMedia>>(
    `/pim/products/wb/product/${productId}/${mediaType}`,
    editMediaFileList
  )

export const useUpdateProductMediaMutation = (options?: UseMutationOptions<
  AxiosResponse<Array<pimApi.products.wb.EditWBMedia>>,
  AxiosError<errorType>,
  UpdateProductMediaPayload
>) =>
  useMutation<
    AxiosResponse<Array<pimApi.products.wb.EditWBMedia>>,
    AxiosError<errorType>,
    UpdateProductMediaPayload
  >(updateMedia, options)


  interface UpdateProductMediaSinglePayload {
    productId: UniqueId
    mediaType: string
    newProductMedia: pimApi.products.wb.EditWBMedia
  }
  
export const updateMediaSingle = ({
  productId,
  mediaType,
  newProductMedia,
}: UpdateProductMediaSinglePayload) =>
  api.put<pimApi.products.wb.WBMedia>(
    `/pim/products/wb/product/${productId}/${mediaType}/${newProductMedia.id}`,
    newProductMedia
  )

export const useUpdateProductMediaSingleMutation = (options?: UseMutationOptions<
  AxiosResponse<pimApi.products.wb.WBMedia>,
  AxiosError<errorType>,
  UpdateProductMediaSinglePayload
>) =>
  useMutation<
    AxiosResponse<pimApi.products.wb.WBMedia>,
    AxiosError<errorType>,
    UpdateProductMediaSinglePayload
  >(updateMediaSingle, options)

export const getWBMediaApiPath = (
  productId: UniqueId,
  photoId?: UniqueId,
  mediaType?: string,
  size?: { maxWidth?: number; maxHeight?: number }
) => {
  const fileThumbnailPath = `/pim/products/wb/product/${productId}/${mediaType}/thumbnail/${photoId}`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}

interface DeleteProductMediaPayload {
  productId: UniqueId,
  id?: UniqueId,
  mediaType: string,
  versionNo?: UniqueId,
  comments?: string
}

export const deleteMedia = ({ productId, id, mediaType, versionNo }: DeleteProductMediaPayload) => 
  api.delete(
    `/pim/products/wb/product/${productId}/${mediaType}/${id}`,  {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )

export const useDeleteProductMediaMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteProductMediaPayload
  >(deleteMedia)

interface BulkDeleteProductMediaPayload {
  mediaType: string
  productId: UniqueId
  value: Array<pimApi.DeletingObject>
}

export const bulkDeleteMedia = ({ productId, mediaType, value }: BulkDeleteProductMediaPayload) =>
  api.delete(
    `/pim/products/wb/product/${productId}/${mediaType}`,  {
      data: value,
    }
  )


export const useBulkDeleteProductMediaMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    BulkDeleteProductMediaPayload
  >(bulkDeleteMedia)
