import { Autocomplete, Box, Button, DialogActions, TextField } from '@mui/material'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { LoadingButton } from '@mui/lab'
import React, { useMemo, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { pimGen } from 'shared/lib/generated'
import { isNotNil } from 'shared/lib/checkers'

const MODAL_STYLES = { width: '400px', overflowX: 'hidden', display: 'flex', flexDirection: 'column', gap: '24px' }
const SX_TITLE = { fontSize: '20px', width: '400px', padding: '0' }

export const UploadFileModal = ({ close, accept, methodName }) => {
  const { data: contragentsData } = pimGen.administration.GetContragents.useGetContragents()
  const [fileState, setFileState ] = useState<any>(null)
  const [ contragentValue, setContragentValue ] = useState<any | null>(null)

  const contragentsOptions = useMemo(
    () => contragentsData?.map(getOptions) || [],
    [contragentsData]
  )

  const onClearFile = () => {
    setFileState(null)
  }

  const changeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileState(file)
    }
    // Чтобы очищать значение инпута и можно будет закидывать одинаковые файлы
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }
  
  return (
    <Box p={ 3 } sx={ MODAL_STYLES }>
      <DialogTitleWithBtn onClose={ close } sx={ SX_TITLE }>
        { methodName }
      </DialogTitleWithBtn>

      <StyledFilterContainer>
        <Autocomplete
          options={contragentsOptions}
          noOptionsText="Нет фильтров"
          size="small"
          fullWidth={true}
          value={contragentValue}
          isOptionEqualToValue={isOptionEqual}
          onChange={(_, value) => {
            setContragentValue(value)
          }}
          disableClearable={isNotNil(contragentValue)}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Владелец"/>
          )}
        />
      </StyledFilterContainer>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
        <label htmlFor="file-upload"
          style={ {
            width: fileState ? '' : '64px',
            display: 'flex',
            alignItems: 'center'
          } }>
          <input id="file-upload" type="file" onChange={changeFile} style={ { display: 'none' } }/>
          <LoadingButton
            color="primary"
            variant="outlined"
            component="span"
            size="small"
          >
            Обзор
          </LoadingButton>
        </label>
        {fileState ?
          <div style={{
            marginLeft: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden' }}>
            <span style={ {
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '12px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginRight: '12px'
            } }>
              {fileState.name}
            </span>
            <ClearIcon sx={ { cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' } } onClick={ onClearFile } />
          </div>
          :
          <></>
        }
      </div>

      <DialogActions sx={ { padding: '0' } }>

        <Button
          variant="text"
          onClick={ close }
        >
          ОТМЕНА
        </Button>

        <LoadingButton
          variant="contained"
          disabled={!fileState}
          onClick={ () => accept({ file: fileState, contragentId: contragentValue.value }) }
        >
          Добавить
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}