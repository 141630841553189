import { pimGen } from 'shared/lib/generated'
import excel from 'assets/images/icons/excel.svg'
import excelInvert from 'assets/images/icons/excelInvert.svg'
import { Button, Typography, Link } from '@mui/material'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { hasRoleAccess } from 'shared/api/base'
import { downloadFileWithPath } from 'shared/lib/utils'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import {
  ButtonsContainer,
  DataWrap,
  HistoryDataWrap,
  HistoryItemDetailWrap,
} from '../../../bulkOperationsPage/ui/styled'

export const HistoryItemDetail = ({ historyId }: { historyId: UniqueId }) => {
  const { data } = pimGen.bulkOperation.GetBulkOperation.useGetBulkOperation({
    bulkoperationId: historyId,
  })

  const handleDownloadFile = (fileUrl) => {
    if (fileUrl) {
      const url = new URL(fileUrl)
      downloadFileWithPath(url.pathname, 'file')
    }
  }

  return (
    <HistoryItemDetailWrap>
      <Typography className="styled-typography" variant="h6">
        {data?.bulkoperationtypeName}
      </Typography>
      <HistoryDataWrap>
        <span>ID {data?.id}</span>
        {data?.created ? (
          <span>
            {format(
              new Date(data?.created),
              DateFormats.dayMonthYearHoursMinutes
            )}
          </span>
        ) : null}
      </HistoryDataWrap>
      <DataWrap>
        <div>
          <span>Статус</span>
          <span>{data?.error ? 'Не успешно' : 'Успешно'}</span>
        </div>
        {hasRoleAccess('ADMIN') ? (
          <div>
            <span>Пользователь</span>
            <Link
              href={`/admin/users/edit/${data?.systemuserId}`}
              target="__blank"
              underline="none"
            >
              {data?.changer}
            </Link>
          </div>
        ) : null}
        <div>
          <span>Всего товаров</span>
          <span>{data?.totalCount}</span>
        </div>
        {data?.errorCount ?
          <div>
            <span>Товаров с ошибкой</span>
            <span>{data?.errorCount}</span>
          </div>
          :
          <></>
        }
      </DataWrap>
      <ButtonsContainer>
        {data?.sourceFileUrl && (
          <Button
            variant="contained"
            size="large"
            // disabled={isExportToExcel}
            onClick={() => handleDownloadFile(data?.sourceFileUrl)}
            startIcon={<ExcelIcon src={excel} alt="excelImg" />}
          >
            входящий файл
          </Button>
        )}
        {data?.resultFileUrl && (
          <Button
            variant="outlined"
            size="large"
            // disabled={isExportToExcel}
            onClick={() => handleDownloadFile(data?.resultFileUrl)}
            startIcon={<ExcelIcon src={excelInvert} alt="invertExcelImg" />}
          >
            обработанный файл
          </Button>
        )}
      </ButtonsContainer>
    </HistoryItemDetailWrap>
  )
}
