import React from 'react'
import { pimApi } from 'shared/api'
import { ColumnsConfigType, TableParams } from 'shared/ui/components/Table/model/types'
import { Table } from 'shared/ui/components/Table'
import { IMenuItem } from 'shared/ui/components'

import { DictionaryValuesWidget } from './NestedWidget/ui/DictionaryValues'
import { StyledTableBox } from './styled'

import { pagingPanelLocale } from '../lib/localization'
import { TabHeader } from '../../ui/TabHeader'


interface AttributesTableProps {
  attributesData: pimApi.attributes.CommonAttribute[] | pimApi.attributes.CategoryAttribute[]
  columnsConfig: ColumnsConfigType
  handleCellChange: (changes) => void
  addAttributeFunction: () => void
  internalPagination?: boolean
  memoTableParams: TableParams
  settingsMenuOptions: (e) => Array<IMenuItem>
  totalCount: number
}

const getRowId = (row) => row.id

export const AttributesTable = ({ 
  attributesData,
  addAttributeFunction,
  handleCellChange,
  columnsConfig,
  memoTableParams,
  settingsMenuOptions,
  internalPagination,
  totalCount }: AttributesTableProps) => 
  (
    <>
      <TabHeader title="Атрибуты" addButtonTitle="Добавить атрибут" addFunction={ addAttributeFunction } />
      <StyledTableBox>
        <Table
          tableList={ attributesData }
          totalCount={ totalCount }
          getRowId={ getRowId }
          nestedWidget={DictionaryValuesWidget}
          onCellChange={handleCellChange}
          isTableEditable={true}
          settingsMenuOptions={settingsMenuOptions}
          paginationLocale={ pagingPanelLocale }
          tableParams={ memoTableParams }
          calculatedRowsPerPage={ memoTableParams.calculatedRowsPerPage }
          columnsConfig={ columnsConfig }
          searching={internalPagination ? 'internal': 'external'}
          pagination={internalPagination ? 'internal': 'external'}
          sorting="internal"
          filtering="internal"
        />
      </StyledTableBox>
    </>
  )