import { IReceiptDocumentsService } from 'shared/services/interfaces/IReceiptDocumentsService'
import { diContainer } from 'shared/lib'
import { TYPES } from 'shared/services/types'
import { AxiosResponse } from 'axios'
import {
  ChangeDocAcceptanceResponse,
  DeleteReceiptDocAcceptanceParams,
  EditReceiptDocAcceptanceParams,
} from 'shared/api/wms/ReceiptDocumentsService/models'

const receiptDocumentsService = diContainer.get<IReceiptDocumentsService>(TYPES.ReceiptDocumentsService)

export function changeAcceptance(
  requestParams: EditReceiptDocAcceptanceParams,
): Promise<AxiosResponse<ChangeDocAcceptanceResponse>> {
  return receiptDocumentsService.changeAcceptance(requestParams)
}

export function removeAcceptance(requestParams: DeleteReceiptDocAcceptanceParams
): Promise<AxiosResponse<ChangeDocAcceptanceResponse>> {
  return receiptDocumentsService.removeAcceptance(requestParams)
}

