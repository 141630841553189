import { Grid } from '@mui/material'
import { InputField, InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import React, { memo } from 'react'


const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    margin-left: 0;
  }
`

interface IBaseInfoUserForm {
  handleUserEdit: () => void
  isClosed?: boolean
  errors: any
  dirtyFields: any
}

export const BaseInfoUserForm = memo(({
  handleUserEdit,
  isClosed,
  errors
}: IBaseInfoUserForm) => (
  <Grid container={true} spacing={2} padding="24px 24px 16px 24px">
    <Grid item={true} xs={4}>
      <InputLabel label="Имя пользователя" required={true}/>
      <HelperTextSupply>
        <Field
          name="name"
          id="name"
          placeholder="Имя"
          component={InputField}
          required={true}
          helperText={errors && errors?.name ? errors?.name : ''}
          error={!!errors?.name}
          fullWidth={true}
          onBlur={handleUserEdit}
          disabled={isClosed}
          InputProps={{ autoComplete: 'off' }}
        />
      </HelperTextSupply>
    </Grid>
    <Grid item={true} xs={4}>
      <InputLabel label="Почта" required={true}/>
      <HelperTextSupply>
        <Field
          name="email"
          id="email"
          placeholder="Почта"
          component={InputField}
          required={true}
          fullWidth={true}
          errorAfterTouch={true}
          error={!!errors?.email}
          helperText={errors?.email}
          onBlur={handleUserEdit}
          disabled={isClosed}
          InputProps={{ autoComplete: 'off' }}
        />
      </HelperTextSupply>
    </Grid>
    <Grid item={true} xs={4}>
      <InputLabel label="Логин" required={true}/>
      <HelperTextSupply>
        <Field
          name="login"
          id="login"
          placeholder="Логин"
          component={InputField}
          helperText={errors && errors?.login ? errors?.login : ''}
          error={!!errors?.login}
          required={true}
          fullWidth={true}
          onBlur={handleUserEdit}
          disabled={isClosed}
          InputProps={{ autoComplete: 'off' }}
        />
      </HelperTextSupply>
    </Grid>
    <Grid item={true} xs={4}>
      <InputLabel label="Telegram" required={false}/>
      <HelperTextSupply>
        <Field
          name="telegramCode"
          id="telegramCode"
          placeholder="Telegram"
          component={InputField}
          helperText={errors && errors?.telegramCode ? errors?.telegramCode : ''}
          error={!!errors?.telegramCode}
          required={true}
          fullWidth={true}
          onBlur={handleUserEdit}
          disabled={isClosed}
          InputProps={{ autoComplete: 'off' }}
          type="number"
          step="1"
        />
      </HelperTextSupply>
    </Grid>
  </Grid>
)
)

