import { Accordion, AccordionProps, Checkbox } from '@mui/material'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'

export const StyledCheckbox = styled(Checkbox)`
  width: 18px;
  height: 23px;
  margin-right: 18px;
`

interface IAccordionProps extends AccordionProps, ThemeProps<DefaultTheme> {
  $single?: boolean; $expanded?: boolean;
}

export const StyledMuiAccordion = styled(Accordion)<IAccordionProps>`
  border-radius: ${({ $single }) => ($single ? '4px' : '')};
  color: ${({ $expanded }) => ($expanded ? '' : '#9e9e9e;')};
  margin-top: 5px;
  margin-bottom: 5px;
  .MuiCheckbox-root .MuiSvgIcon-root {
    color: ${({ $expanded }) => ($expanded ? '' : '#9e9e9e;')};
  }

  &:before {
    opacity: ${({ $single }) => ($single ? '0' : '0')};
  }
`
