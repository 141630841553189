/* eslint-disable indent */
import { OutlinedInputProps, TextField } from '@mui/material'
import { SetRequired } from 'type-fest'

import { MaskedInputFieldI } from './MaskedInputI'
import { MaskInputMemo } from './MaskInput'

import { withFieldEventAdapter } from '../FieldAdapter'

type InputPropsCorrect = Partial<SetRequired<OutlinedInputProps, 'name'>>

const _MaskedInputField = <T extends {}>({
  error,
  margin,
  helperText,
  label,
  input,
  input: { value, name },
  onBlur,
  onFocus,
  onChange,
  autoComplete,
  sx,
  disabled,
  readOnly,
  type = 'text',
  size = 'small',
  scale,
  min,
  max,
  meta,
  MaskComponent = MaskInputMemo,
  endAdornment,
}: MaskedInputFieldI<T>) => (
  <TextField
    label={label}
    variant="outlined"
    size={size}
    fullWidth={true}
    value={value}
    onChange={onChange}
    sx={sx}
    margin={margin}
    onBlur={onBlur}
    onFocus={onFocus}
    autoComplete={autoComplete}
    name={name}
    disabled={disabled || input?.disabled}
    type={type}
    error={error || (meta?.invalid && meta?.visited)}
    helperText={helperText}
    InputLabelProps={value ? { shrink: true } : {}}
    // @ts-ignore
    InputProps={
      {
        endAdornment,
        inputComponent: MaskComponent,
        readOnly,
      } as InputPropsCorrect
    }
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{
      scale,
      min,
      max,
    }}
  />
)

export const MaskedInputField =
  withFieldEventAdapter<MaskedInputFieldI>(_MaskedInputField)
