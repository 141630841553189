import React from 'react'
import { useNotifications } from 'shared/lib/hooks/useNotifications'

import { NotificationsContainer } from './ui/NotificationsContainer'

export const Notifications = () => {
  useNotifications()
  return (
    <NotificationsContainer />
  )
}