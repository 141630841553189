import { AxiosResponse } from 'axios'
import { QueryFunctionContext, useQuery } from 'react-query'

import { api } from '../../base'

export const getMarketplaceLogoQueryKey = (marketplaceId: UniqueId) =>
  ['get', `customer/marketplaces/${marketplaceId}/logo`, marketplaceId] as const

type GetMarketplaceLogoQueryKey = ReturnType<typeof getMarketplaceLogoQueryKey>

export async function getMarketplaceLogo(marketplaceId: UniqueId): Promise<AxiosResponse<Blob>> {
  return api.get(
    `/customer/marketplaces/${marketplaceId}/logo`,
    {
      responseType: 'blob'
    }
  )
}

const getMarketplaceLogoFn = (context: QueryFunctionContext<GetMarketplaceLogoQueryKey>) => {
  const [, ,marketplaceId] = context.queryKey
  return getMarketplaceLogo(marketplaceId)
}

export const useMarketplaceLogoQuery = (marketplaceId: UniqueId) =>
  useQuery(
    getMarketplaceLogoQueryKey(marketplaceId),
    getMarketplaceLogoFn
  )

