import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ElasticityOfDemandPage } from 'features/analytics/chart/ui/charts/ElasticityOfDemand'
import { OrdersAndSalesPage } from 'features/analytics/chart/ui/charts/OrdersAndSales'
import { OrdersAndStocksPage } from 'features/analytics/chart/ui/charts/OrdersAndStocks'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { StyledTabPanel } from './styled'

import { IAvailableFilters } from '../../../product/productList/model/types'


const TABS = [
  {
    label: 'Заказы и остатки',
    Component: OrdersAndStocksPage
  },
  {
    label: 'Динамика продаж',
    Component: OrdersAndSalesPage
  },
  {
    label: 'Эластичность спроса',
    Component: ElasticityOfDemandPage
  }
] as const

export const availableFilters: IAvailableFilters = {
  abcClass: true,
  xyzClass: true,
  marketplaceId: true,
  autoOrdering: true,
  category: true,
  goodsBrandId: true,
  companyId: true,
  contragentId: true,
  companymarketplaceId: true,
  stocksStatus: true,
  orderStatus: true,
  tags: true
}

export const ChartsTabs = memo(() => {
  const { search } = useLocation()
  const searchObj = queryString.parse(search, { parseNumbers: true })
  const history = useHistory()

  const handleChartParams = (params) => {
    const historySearch = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    const historyStr = queryString.stringify(
      { ...historySearch, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    history.replace(`?${historyStr}`)
  }

  const handleChange = (_, newValue: number) => {
    if (newValue === 2 || searchObj.tab === 2) {
      handleChartParams( {
        tab: newValue,
        timelineStart: null,
        timelineEnd: null,
      })
    } else {
      handleChartParams({
        tab: newValue,
      })
    }
  }

  return (
    <>
      <Paper style={{ marginTop: '28px' }}>
        <StyledTabPanel>
          <Box sx={{ width: '100%' }}>
            <Tabs value={searchObj.tab || 0} onChange={handleChange} aria-label="basic tabs example">
              {TABS.map(({ label }, index) => (
                <Tab
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  sx={{ padding: '12 24', minWidth: '185px' }}
                  label={label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </StyledTabPanel>

        {TABS.map(({ Component }, index) => (
          <TabPanel
            value={searchObj.tab || 0}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
          >
            <Component/>
          </TabPanel>
        ))}
      </Paper>
    </>
  )
}
)