/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_bot-addProductToReviewsBot */
export interface BodyBotAddProductToReviewsBot {
  /** product_id */
  product_id: number
  /** template_model_id */
  templatemodel_id: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id: number
  /** status */
  status: boolean
}

/** Body_bot-updatereviewsbot */
export interface BodyBotUpdatereviewsbot {
  /** id */
  id: number
  /** id */
  version_no: number
  /** product_id */
  product_id?: number
  /** template_model_id */
  templatemodel_id?: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id?: number
  /** status */
  status?: boolean
}

/** Body_review-answerReview */
export interface BodyReviewAnswerReview {
  /** ID отзыва */
  id: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_review-editAnswerReview */
export interface BodyReviewEditAnswerReview {
  /** ID ответа */
  id: number
  /** version_no ответа */
  version_no: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_review-editAnswerReviewExam */
export interface BodyReviewEditAnswerReviewExam {
  /** ID ответа */
  id: number
  /** version_no ответа */
  version_no: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_review-sentAnswerReviewExam */
export interface BodyReviewSentAnswerReviewExam {
  /** ID отзыва */
  id: number
  /**
   * Текст ответа (максимум 512 символов)
   * @maxLength 512
   */
  answer: string
}

/** Body_template-createReviewTemplate */
export interface BodyTemplateCreateReviewTemplate {
  /**
   * Наименование (максимум 128)
   * @maxLength 128
   */
  name: string
  /**
   * Текс шаблона (максимум 512)
   * @maxLength 512
   */
  text: string
  /** ID модели */
  templatemodel_id: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id: number
  /** Рейтинг отзыва */
  value?: number
}

/** Body_template-delete_review_template */
export interface BodyTemplateDeleteReviewTemplate {
  /** ID обьекта */
  id: number
  /** version_no */
  version_no: number
  /**
   * Комментарий (максимум 128)
   * @maxLength 128
   */
  comment?: string
}

/** Body_template-delete_review_templates_model */
export interface BodyTemplateDeleteReviewTemplatesModel {
  /** ID обьекта */
  id: number
  /** version_no */
  version_no: number
  /**
   * Комментарий (максимум 128)
   * @maxLength 128
   */
  comment?: string
}

/** Body_template-updateReviewTemplate */
export interface BodyTemplateUpdateReviewTemplate {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /**
   * Наименование (максимум 128)
   * @maxLength 128
   */
  name?: string
  /**
   * Текс шаблона (максимум 1024)
   * @maxLength 1024
   */
  text?: string
  /** ID модели */
  templatemodel_id?: number
  /**
   * Тип
   * 1 - Отзывы 2 - Вопросы
   */
  reviewtype_id?: number
  /** Рейтинг отзыва */
  value?: number
}

/** Body_template-updateReviewTemplateModel */
export interface BodyTemplateUpdateReviewTemplateModel {
  /** ID обьекта */
  id: number
  /** version_no */
  version_no: number
  /**
   * Наименование модели (максимум 128)
   * @maxLength 128
   */
  name: string
}

/** CeleryTaskStart */
export interface CeleryTaskStart {
  /** ID задачи */
  task_id: string
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /**
   * Текст с описанием ошибки
   * @maxLength 4000
   */
  message?: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** Product */
export interface Product {
  /** ID продукта */
  product_id: number
  /** Категория МП */
  marketplace_category: string
  /** Номенклатура */
  marketplace_code?: string
  /** Маркетплейс */
  marketplace_name: string
  /** Автозаказ */
  auto_ordering: boolean
  /** Штрихкоды */
  barcodes: any[]
  /** Компания */
  company_name: string
  /** Ссылка на 1 фото */
  local_cdn_id?: string
  /** Наименование */
  name: string
  /** Цена */
  price: number
}

/** ResponseModelId */
export interface ResponseModelId {
  /** Id */
  id: number
}

/** Review */
export interface Review {
  /** ID отзыва */
  id: number
  /** ID типа */
  reviewtype_id: number
  /** Наименование типа */
  reviewtype_name: string
  /**
   * Дата создания
   * @format date-time
   */
  created: string
  /** Пользователь */
  user_name?: string
  /** Просмотренный */
  viewed: boolean
  /** Обработанный */
  processed: boolean
  /** Оценка */
  value?: number
  /** Список фото */
  media?: any[]
  /**
   * Количество отзывов на продукте
   * @default 0
   */
  count?: number
  /**
   * Рейтинг продукта
   * @default 0
   */
  rating?: number
  /** Is Able Return */
  is_able_return: boolean
  /** Exam */
  exam: boolean
  /** Список текстов */
  texts?: ReviewText[]
  /** Список ответов */
  answers?: ReviewAnswer[]
  /** ID продукта */
  product_id?: number
  /** ID маркетплейса */
  marketplace_id?: number
  /** Наименование маркетплейса */
  marketplace_name?: string
  /** Ссылка на первое фото продукта */
  marketplace_url?: string
  /** Наименование продукта */
  name?: string
  /** ID кабинета */
  companymarketplace_id?: number
  /** Наименование кабинета */
  cabinet_name?: string
  /** Артикул маркетплейса */
  product_code?: number
}

/** ReviewAnswer */
export interface ReviewAnswer {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /** Text */
  text?: string
  /** Sent */
  sent: boolean
  /** Changer Name */
  changer_name?: string
  /**
   * Created
   * @format date-time
   */
  created?: string
}

/** ReviewBot */
export interface ReviewBot {
  /** version_no */
  id: number
  /** version_no */
  version_no: number
  /**
   * created
   * @format date-time
   */
  created: string
  /**
   * changed
   * @format date-time
   */
  changed: string
  /**
   * deleted
   * @format date-time
   */
  deleted?: string
  /** changer_id */
  changer_id: number
  /** changer_name */
  changer_name: string
  /** ID продукта */
  product_id: number
  /** Модель */
  template_model_id: number
  /** Модель */
  template_model_name: string
  /** Тип */
  review_type_id: number
  /** Тип */
  review_type_name: string
  /** Тип */
  review_type_rus_name: string
  /** Статус */
  status: boolean
}

/** ReviewTemplate */
export interface ReviewTemplate {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /** ID типа */
  reviewtype_id: number
  /** ID модели */
  templatemodel_id: number
  /** Наименование модели */
  templatemodel_name: string
  /** Категория */
  name: string
  /** Текст шаблона */
  text: string
  /** value */
  value?: number
}

/** ReviewTemplateModel */
export interface ReviewTemplateModel {
  /** id */
  id: number
  /** version_no */
  version_no: number
  /** Наименование */
  name: string
}

/** ReviewText */
export interface ReviewText {
  /** ID шаблона */
  id: number
  /** version_no */
  version_no: number
  /** Text */
  text?: string
  /** Changer Name */
  changer_name?: string
  /**
   * Created
   * @format date-time
   */
  created?: string
}

/** ReviewType */
export interface ReviewType {
  /** ID */
  id: number
  /** name */
  name: string
  /** name */
  rus_name: string
}

/** ReviewsStat */
export interface ReviewsStat {
  /**
   * Новые
   * @default 0
   */
  new?: number
  /**
   * На проверке
   * @default 0
   */
  exam?: number
  /**
   * Просмотренные
   * @default 0
   */
  viewed?: number
  /**
   * Обработанные
   * @default 0
   */
  processed?: number
  /**
   * Все отзывы
   * @default 0
   */
  allcount?: number
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: any[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

export namespace Review {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить типы review
   * @tags Review
   * @name ReviewGetReviewType
   * @summary Получить типы review
   * @request GET:/review/type
   * @secure
   */
  export namespace ReviewGetReviewType {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewType[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить список Review
   * @tags Review
   * @name ReviewGetReviews
   * @summary Получить список Review
   * @request GET:/review
   * @secure
   */
  export namespace ReviewGetReviews {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Тип
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** Просмотренный */
      viewed?: boolean
      /** Обработанность */
      processed?: boolean
      /** Поиск по строке */
      search_string?: string
      /** Поставщик */
      suppliers_id?: number
      /** Бренд */
      brand_id?: number
      /** Компания */
      company_id?: number
      /** Кабинет */
      companymarketplace_id?: number
      /** Маркетплейс */
      marketplace_id?: number
      /**
       * Номер страницы (p > 0)
       * @min 0
       * @default 0
       */
      page?: number
      /**
       * Количество элементов на странице
       * @min 0
       * @max 1000
       * @default 100
       */
      page_size?: number
      /** Приоритетность */
      sorted_value?: boolean
      /**
       * Известный/Неизвестный продукт
       * true - показать отзывы с неизвестным продуктом
       *  false - показать отзывы с известным продуктом
       *  None - все отзывы
       */
      unknown?: boolean
      /** Можно ли вернуть товар */
      is_able_return?: boolean
      /** На проверке */
      exam?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Review[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить статистику
   * @tags Review
   * @name ReviewGetReviewStats
   * @summary Получить статистику
   * @request GET:/review/stats
   * @secure
   */
  export namespace ReviewGetReviewStats {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Тип
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** Поиск по строке */
      search_string?: string
      /** Поставщик */
      suppliers_id?: number
      /** Бренд */
      brand_id?: number
      /** Компания */
      company_id?: number
      /** Кабинет */
      companymarketplace_id?: number
      /** Маркетплейс */
      marketplace_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewsStat
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить Review по ID
   * @tags Review
   * @name ReviewGetReview
   * @summary Получить Review по ID
   * @request GET:/review/{review_id}
   * @secure
   */
  export namespace ReviewGetReview {
    export type RequestParams = {
      /** ID */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Просмотреть review
   * @tags Review
   * @name ReviewViewReview
   * @summary Просмотреть review
   * @request PATCH:/review/{review_id}/view
   * @secure
   */
  export namespace ReviewViewReview {
    export type RequestParams = {
      /** ID review */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_answer</code> </h2> Ответить на отзыв/вопрос
   * @tags Review
   * @name ReviewAnswerReview
   * @summary Ответить на отзыв/вопрос
   * @request PATCH:/review/{review_id}/answer
   * @secure
   */
  export namespace ReviewAnswerReview {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewAnswerReview
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_answer'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_answer</code> </h2> Изменить ответ на отзыв/вопрос
   * @tags Review
   * @name ReviewEditAnswerReview
   * @summary Изменить ответ на отзыв/вопрос
   * @request PUT:/review/{review_id}/answer/{answer_id}
   * @secure
   */
  export namespace ReviewEditAnswerReview {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
      /** ID ответа */
      answerId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewEditAnswerReview
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_answer'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <b>(<code>review_examanswer</code> &or; <code>review_answer</code>)</b> </h2> Записать ответ
   * @tags Review
   * @name ReviewSentAnswerReviewExam
   * @summary Записать ответ
   * @request PATCH:/review/{review_id}/examanswer
   * @secure
   */
  export namespace ReviewSentAnswerReviewExam {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewSentAnswerReviewExam
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_examanswer'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_answer'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <b>(<code>review_examanswer</code> &or; <code>review_answer</code>)</b> </h2> Изменить записанный ответ
   * @tags Review
   * @name ReviewEditAnswerReviewExam
   * @summary Изменить записанный ответ
   * @request PUT:/review/{review_id}/examanswer/{answer_id}
   * @secure
   */
  export namespace ReviewEditAnswerReviewExam {
    export type RequestParams = {
      /** ID отзыва */
      reviewId: number
      /** ID ответа */
      answerId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyReviewEditAnswerReviewExam
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          or: [
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_examanswer'] },
              ],
            },
            {
              some: [
                { var: 'privileges' },
                { '==': [{ var: '' }, 'review_answer'] },
              ],
            },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_return_product</code> </h2> Возврат товара по ID отзыва
   * @tags Review
   * @name ReviewReturnProductfromReview
   * @summary Возврат товара по ID отзыва
   * @request PATCH:/review/{review_id}/return
   * @secure
   */
  export namespace ReviewReturnProductfromReview {
    export type RequestParams = {
      /** Review Id */
      reviewId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Review
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_return_product'] },
          ],
        },
      ],
    }
  }
}

export namespace Template {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить шаблоны
   * @tags Template
   * @name TemplateGetReviewTemplates
   * @summary Получить шаблоны
   * @request GET:/models/templates
   * @secure
   */
  export namespace TemplateGetReviewTemplates {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Тип
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** Тип модели */
      templatemodel_id?: number
      /** Наименование */
      name?: string
      /** Поиск по наименованию */
      search_string?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplate[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить шаблон
   * @tags Template
   * @name TemplateGetReviewTemplate
   * @summary Получить шаблон
   * @request GET:/models/templates/{template_id}
   * @secure
   */
  export namespace TemplateGetReviewTemplate {
    export type RequestParams = {
      /** Получить шаблон */
      templateId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplate
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Создать шаблон
   * @tags Template
   * @name TemplateCreateReviewTemplate
   * @summary Создать шаблон
   * @request POST:/models/template
   * @secure
   */
  export namespace TemplateCreateReviewTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateCreateReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplate
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Изменить шаблон
   * @tags Template
   * @name TemplateUpdateReviewTemplate
   * @summary Изменить шаблон
   * @request PUT:/models/template/{template_id}
   * @secure
   */
  export namespace TemplateUpdateReviewTemplate {
    export type RequestParams = {
      /** ID обьекта */
      templateId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateUpdateReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplate
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Удалить шаблон
   * @tags Template
   * @name TemplateDeleteReviewTemplate
   * @summary Удалить шаблон
   * @request DELETE:/models/template/{template_id}
   * @secure
   */
  export namespace TemplateDeleteReviewTemplate {
    export type RequestParams = {
      /** ID модели */
      templateId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateDeleteReviewTemplate
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить модели шаблонов
   * @tags Template
   * @name TemplateGetReviewTemplateModels
   * @summary Получить модели шаблонов
   * @request GET:/models
   * @secure
   */
  export namespace TemplateGetReviewTemplateModels {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поиск по названию */
      searchstring?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplateModel[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Создать модель шаблонов
   * @tags Template
   * @name TemplateCreateReviewTemplateModel
   * @summary Создать модель шаблонов
   * @request POST:/models
   * @secure
   */
  export namespace TemplateCreateReviewTemplateModel {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = string
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplateModel
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить модель
   * @tags Template
   * @name TemplateGetReviewTemplateModel
   * @summary Получить модель
   * @request GET:/models/{model_id}
   * @secure
   */
  export namespace TemplateGetReviewTemplateModel {
    export type RequestParams = {
      /** ID модели */
      modelId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplateModel
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Изменить модель шаблона
   * @tags Template
   * @name TemplateUpdateReviewTemplateModel
   * @summary Изменить модель шаблона
   * @request PUT:/models/{model_id}
   * @secure
   */
  export namespace TemplateUpdateReviewTemplateModel {
    export type RequestParams = {
      /** ID модели */
      modelId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateUpdateReviewTemplateModel
    export type RequestHeaders = {}
    export type ResponseBody = ReviewTemplateModel
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_template_edit</code> </h2> Удалить модель шаблона
   * @tags Template
   * @name TemplateDeleteReviewTemplatesModel
   * @summary Удалить модель шаблона
   * @request DELETE:/models/{model_id}
   * @secure
   */
  export namespace TemplateDeleteReviewTemplatesModel {
    export type RequestParams = {
      /** ID модели */
      modelId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyTemplateDeleteReviewTemplatesModel
    export type RequestHeaders = {}
    export type ResponseBody = ResponseModelId
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_template_edit'] },
          ],
        },
      ],
    }
  }
}

export namespace Bot {
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить продукты из бот-листа
   * @tags Bot
   * @name BotGetreviewsbot
   * @summary Получить продукты из бот-листа
   * @request GET:/bot
   * @secure
   */
  export namespace BotGetreviewsbot {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID продукта */
      product_id?: number
      /** ID модели */
      templatemodel_id?: number
      /**
       * ID типа
       * 1 - Отзывы 2 - Вопросы
       */
      reviewtype_id?: number
      /** ID кабинета */
      companymarketplace_id?: number
      /**
       * Номер страницы (p > 0)
       * @min 0
       * @default 0
       */
      page?: number
      /**
       * Количество элементов на странице
       * @min 0
       * @max 1000
       * @default 100
       */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot[]
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_bot_edit</code> </h2> Добавить продукт в бот-лист
   * @tags Bot
   * @name BotAddProductToReviewsBot
   * @summary Добавить продукт в бот-лист
   * @request POST:/bot
   * @secure
   */
  export namespace BotAddProductToReviewsBot {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyBotAddProductToReviewsBot
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_bot_edit'] },
          ],
        },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> </h2> Получить продукт из бот-листа
   * @tags Bot
   * @name BotGetreviewbot
   * @summary Получить продукт из бот-листа
   * @request GET:/bot/{id}
   * @secure
   */
  export namespace BotGetreviewbot {
    export type RequestParams = {
      /** ID записи */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
      ],
    }
  }
  /**
   * @description <h2><b>Access</b>: <code>review</code> <b>&and;</b> <code>review_bot_edit</code> </h2> Изменить настройки продукта
   * @tags Bot
   * @name BotUpdatereviewsbot
   * @summary Изменить настройки продукта
   * @request PUT:/bot/{rec_id}
   * @secure
   */
  export namespace BotUpdatereviewsbot {
    export type RequestParams = {
      /** id */
      recId: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyBotUpdatereviewsbot
    export type RequestHeaders = {}
    export type ResponseBody = ReviewBot
    export const XPrivilegeCheck = {
      and: [
        { some: [{ var: 'privileges' }, { '==': [{ var: '' }, 'review'] }] },
        {
          some: [
            { var: 'privileges' },
            { '==': [{ var: '' }, 'review_bot_edit'] },
          ],
        },
      ],
    }
  }
}

export namespace Common {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Получить продукты
   * @tags Common
   * @name CommonGetProducts
   * @summary Получить продукты
   * @request GET:/common/products
   * @secure
   */
  export namespace CommonGetProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      /** ID продукта */
      product_id?: number
      /** Поиск */
      search_string?: string
      /** ID кабинета */
      companymarketplace_id?: number
      /**
       * Лимит (макс 10)
       * @default 10
       */
      limit?: number
      /**
       * Параметр пагинации
       * @default 0
       */
      page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Product[]
    export const XPrivilegeCheck = { and: [] }
  }
}

export namespace Celery {
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск скрапинга отзывов
   * @tags Celery
   * @name CeleryScrapingFeedbacks
   * @summary Запуск скрапинга отзывов
   * @request PATCH:/celery_settings/task/scraping/feedbacks
   * @secure
   */
  export namespace CeleryScrapingFeedbacks {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Review Type
       * Тип review
       */
      review_type: 'new' | 'part' | 'all'
      /**
       * Companymarketplace Id
       * ID кабинета
       */
      companymarketplace_id?: number
      /**
       * Part Bulk Size
       * Кол-во
       */
      part_bulk_size: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStart
    export const XPrivilegeCheck = { and: [] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск скрапинга вопросов
   * @tags Celery
   * @name CeleryScrapingQuestions
   * @summary Запуск скрапинга вопросов
   * @request PATCH:/celery_settings/task/scraping/questions
   * @secure
   */
  export namespace CeleryScrapingQuestions {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Review Type
       * Тип review
       */
      review_type: 'new' | 'part' | 'all'
      /**
       * Companymarketplace Id
       * ID кабинета
       */
      companymarketplace_id?: number
      /**
       * Part Bulk Size
       * Кол-во
       */
      part_bulk_size: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStart
    export const XPrivilegeCheck = { and: [] }
  }
  /**
   * @description <h2><b>Access</b>: <b>No access check</b> </h2> Запуск бота по ответам
   * @tags Celery
   * @name CeleryBotAnswerReview
   * @summary Запуск бота по ответам
   * @request PATCH:/celery_settings/task/bot/answer
   * @secure
   */
  export namespace CeleryBotAnswerReview {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Marketplace Id
       * ID маркетплейса
       */
      marketplace_id: number
      /**
       * Companymarketplace Id
       * ID кабинета
       */
      companymarketplace_id?: number
      /**
       * Count
       * Кол-во
       */
      count: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CeleryTaskStart
    export const XPrivilegeCheck = { and: [] }
  }
}

//none
