import React from 'react'

import { DictsPage, Header } from './ui'

export const AdminPanelDicts = () => (

  <>
    <Header />
    <DictsPage />
  </>
)
