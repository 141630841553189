import styled from 'styled-components'

export const StyledProductAbcClass = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 8px;
`