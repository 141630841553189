import React, { useMemo } from 'react'
import { Mutator } from 'final-form'
import { useParams } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Grid, Button, IconButton, Box } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { getIsSinglePackagingType, SINGLE_PACKAGING_TYPE_ID } from 'entities/pim/packging'
import { SINGLE_PACKAGING } from 'entities/pim/packging/lib'
import { pimApi } from 'shared/api'
import { CheckboxField, Form, Field, MaskedInputField, SelectFieldWithAutoComplete } from 'shared/ui/components/form'
import { sort } from 'shared/lib/utils/sort'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { noop } from 'shared/lib/lodash/noop'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { GridArrayWrapper } from 'shared/ui/styled/GridArrayWrapper'
import { useGoodsPackaging } from 'pages/goods/model/hooks'
import {
  BoxTypeItem, BoxTypeTitle,
} from 'pages/goods/components/common'
import { InputField } from 'pages/goods/components'
import { Option } from 'shared/ui/components/interface'

import { validationSchema } from './validationSchema'

import { OptionItem } from '../../../../components/Steps/InfoStep/interface'

export interface GoodsSupplierPackaging {
  id: number;
  versionNo: number;

  /** @format date-time */
  changed: string;
  changer: string;
  packagingtype: string;
  packagingtypeId: number;
  barcode: string;
  unitQuantity: number;
  weight?: number;
  width?: number;
  height?: number;
  depth?: number;
  expiredFlag: boolean;
  barcodeRequired: boolean;
}

type NewGoodsSupplierPackaging = Partial<GoodsSupplierPackaging>

interface InitialValues {
  packaging: Array<GoodsSupplierPackaging | NewGoodsSupplierPackaging>
}

interface PackagingFormProps {
  supplier: pimApi.goods.GoodsSupplier;
  disabled?: boolean;
}

const getIsExistedPackaging = (
  code: GoodsSupplierPackaging | NewGoodsSupplierPackaging
): code is GoodsSupplierPackaging =>
  typeof code?.id === 'number'


const mutators: Record<string, Mutator<InitialValues>> = {
  ...arrayMutators,
  setSingleUnitQuantity([index], state, utils) {
    utils.changeValue(
      state,
      `packaging.${index}.unitQuantity`,
      () => 1
    )
  }
}
const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
})
export const PackagingForm = ({ supplier, disabled }: PackagingFormProps) => {
  const { id } = useParams<{ id: string }>()
  const productId: number = parseInt(id, 10)
  const packagingTypesQuery = pimApi.dictionaries.usePackagingTypesQuery()
  const packagingTypes = useMemo(
    () => packagingTypesQuery.data?.map(getOptions),
    [packagingTypesQuery.data]
  )
  const packagingTypeWithBarcodeNoRequired = useMemo(() => {
    const exception: number[] = []
    if (packagingTypesQuery.data !== undefined) {
      packagingTypesQuery.data.forEach(e => {
        if (!e.barcodeRequired) exception.push(e.id)
      })
    }
    return exception
  }, [packagingTypesQuery])

  const validate = useValidationSchema(validationSchema, packagingTypeWithBarcodeNoRequired)

  const { create, update, remove } = useGoodsPackaging()

  const initialValues: InitialValues = useMemo(() => ({
    packaging: supplier.packaging ?
      sort(supplier.packaging, 'id', 'desc') :
      []
  }), [supplier.packaging])

  return (
    <Form<InitialValues>
      onSubmit={noop}
      mutators={mutators}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, values, form: { mutators: { setSingleUnitQuantity } }, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray<InitialValues['packaging'][number]> name="packaging">
            {({ fields }) => {
              const handleAdd = () => {
                const isFirstPackaging: boolean = fields.length === 0
                fields.push(isFirstPackaging ? SINGLE_PACKAGING : {} as NewGoodsSupplierPackaging)
              }

              return (
                <GridArrayWrapper container={true} spacing={2}>
                  {fields.map((name, index) => {
                    const fieldValue = fields.value[index]
                    const isDisableAllFields: boolean = !!fieldValue.expiredFlag
                    const isPackagingtypeIdDisabled = fields.value.findIndex(getIsSinglePackagingType) === index || isDisableAllFields

                    const handleRemove = () => {
                      if (supplier.id && getIsExistedPackaging(fieldValue)) {
                        remove(productId, supplier.id, fieldValue)
                      }
                      fields.remove(index)
                    }

                    const handleSimpleRemove = () => fields.remove(index)
                    const handleCreate = () => {
                      if (invalid || !supplier.id) return

                      create(productId, supplier.id, fieldValue as GoodsSupplierPackaging)
                    }
                    const handleUpdate = () => {
                      if (invalid || !supplier.id) return
                      update(productId, supplier.id, fieldValue as GoodsSupplierPackaging)
                    }

                    const handleExpiredChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
                      if (invalid || !supplier.id) return

                      update(
                        productId,
                        supplier.id,
                        {
                          ...fieldValue,
                          expiredFlag: event.target.checked
                        } as GoodsSupplierPackaging
                      )
                    }

                    const handlePackagingtypeIdChange = ( target ) => {
                      if (target === null) { return }
                      const isSinglePackaging = parseInt(target.value, 10) === SINGLE_PACKAGING_TYPE_ID
                      fieldValue.packagingtypeId = parseInt(target.value, 10)
                      if (isSinglePackaging) {
                        setSingleUnitQuantity(index)
                      }
                      if (isExistedPackaging) {
                        handleUpdate()
                      }
                    }

                    const isExistedPackaging = getIsExistedPackaging(fieldValue)

                    const getPackagingLabel = () => {
                      let packType
                      if (packagingTypesQuery.data!==undefined) {
                        packType = packagingTypesQuery.data.find((e) => fieldValue.packagingtypeId === e.id)?.name
                        if (packType!==undefined) {
                          packType = fieldValue.unitQuantity ? `${packType} (${fieldValue.unitQuantity})` : packType
                        }
                      }
                      return packType===undefined ? 'Выберите тип упаковки' : packType
                    }

                    const isRequiredBarcodeLabel = () => {
                      if (packagingTypesQuery.data!==undefined) {
                        if (packagingTypesQuery.data.find((e) => fieldValue.packagingtypeId === e.id)?.barcodeRequired) {
                          return 'Штрих-код*'
                        }
                      }
                      return 'Штрих-код'
                    }
                    const isNewPackagingAdding = supplier.packaging && supplier.packaging.length < fields.value.length

                    const isCurrentPackagingAdding = isNewPackagingAdding && fields.value.length !== index + 1

                    return (
                      <div key={name}>
                        <BoxTypeTitle>
                          { getPackagingLabel() }
                        </BoxTypeTitle>
                        <BoxTypeItem>
                          <div>
                            <GridArrayWrapper container={true} spacing={2}>
                              <Grid item={true} xs={3}>
                                <InputWrapper>
                                  <Field
                                    name={`${name}.packagingtypeId`}
                                    id={`${name}.packagingtypeId`}
                                    label="Тип упаковки*"
                                    required={true}
                                    errorAfterTouch={true}
                                    component={SelectFieldWithAutoComplete}
                                    fullWidth={true}
                                    options={packagingTypes}
                                    isLoading={packagingTypesQuery.isLoading}
                                    isDownloadOptionsWhenDisabled={true}
                                    disabled={isPackagingtypeIdDisabled || isCurrentPackagingAdding || disabled}
                                    onChange={handlePackagingtypeIdChange}
                                    disableClearable={true}
                                  />
                                </InputWrapper>
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.barcode`}
                                  label={isRequiredBarcodeLabel()}
                                  component={InputField}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={isDisableAllFields || isCurrentPackagingAdding || disabled}
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.unitQuantity`}
                                  label="Кол-во товара*"
                                  component={MaskedInputField}
                                  scale={0}
                                  min={0}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={
                                    fieldValue.packagingtypeId === SINGLE_PACKAGING_TYPE_ID ||
                                    isDisableAllFields ||
                                    isCurrentPackagingAdding ||
                                    disabled
                                  }
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.weight`}
                                  label="Масса, кг"
                                  component={MaskedInputField}
                                  scale={3}
                                  min={0}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={isDisableAllFields || isCurrentPackagingAdding || disabled}
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.width`}
                                  label="Ширина, см"
                                  type="number"
                                  component={MaskedInputField}
                                  scale={3}
                                  min={0}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={isDisableAllFields || isCurrentPackagingAdding || disabled}
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.height`}
                                  label="Высота, см"
                                  type="number"
                                  component={MaskedInputField}
                                  scale={3}
                                  min={0}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={isDisableAllFields || isCurrentPackagingAdding || disabled}
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                <Field
                                  name={`${name}.depth`}
                                  label="Глубина, см"
                                  type="number"
                                  component={MaskedInputField}
                                  scale={3}
                                  min={0}
                                  onBlur={isExistedPackaging ? handleUpdate : undefined}
                                  disabled={isDisableAllFields || isCurrentPackagingAdding || disabled}
                                />
                              </Grid>
                              <Grid item={true} xs={3}>
                                {isExistedPackaging ? (
                                  <Grid container={true} justifyContent="space-between">
                                    <Field
                                      name={`${name}.expiredFlag`}
                                      label="Устаревший код"
                                      component={CheckboxField || isCurrentPackagingAdding}
                                      type="checkbox"
                                      disabled={disabled}
                                      onChange={isExistedPackaging ? handleExpiredChange : undefined}
                                    />
                                    <IconButton aria-label="delete" onClick={handleRemove} size="large" disabled={disabled}>
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                ) : (
                                  <Grid container={true}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleCreate}
                                      type="submit"
                                      disabled={invalid || disabled}
                                    >
                                      СОХРАНИТЬ
                                    </Button>
                                    <Box display="inline-flex" ml={2}>
                                      <Button
                                        color="primary"
                                        onClick={handleSimpleRemove}
                                        disabled={disabled}
                                      >
                                        ОТМЕНИТЬ
                                      </Button>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            </GridArrayWrapper>
                          </div>
                        </BoxTypeItem>
                      </div>
                    )
                  })}
                  <Grid xs={12}>
                    <Box ml={2}>
                      <InputWrapper>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                          disabled={(initialValues.packaging.length !== values?.packaging.length) || disabled}
                        >
                          ДОБАВИТЬ УПАКОВКУ
                        </Button>
                      </InputWrapper>
                    </Box>
                  </Grid>
                </GridArrayWrapper>
              )
            }}
          </FieldArray>
        </form>
      )}
    />
  )
}
