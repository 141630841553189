
import { Link } from './styled'


interface MarketplaceLinkProps {
  url: string;
  title: string;
  isArchived?: boolean
}

export const MarketplaceLinkCell = ({ url, title, isArchived }: MarketplaceLinkProps) => (
  <Link
    style={{ whiteSpace: 'inherit' }}
    href={url}
    title={title}
    target="_blank"
    rel="noreferrer"
    $isArchived={isArchived}
  >
    {title}
  </Link>
)
 