import React, { PropsWithChildren } from 'react'
import { AxiosResponse } from 'axios'
import {
  AnalyticsDataSale,
  AnalyticsOrders,
  AnalyticsStocks
} from 'shared/api/analytics'

import { ChartsDataTypes } from '../types/types'


interface ChartsProviderProps {
  value: {
    searchObj: ChartsDataTypes
    handleChartParams: (e) => void
    ordersData?: AxiosResponse<AnalyticsOrders>
    salesData?: AxiosResponse<AnalyticsDataSale>
    stocksData?: AxiosResponse<AnalyticsStocks>
  }
}

const ChartsContext = React.createContext<ChartsProviderProps | undefined>(undefined)
function ChartsProvider({ children, value }: PropsWithChildren<ChartsProviderProps>) {
  return (
    <ChartsContext.Provider value={{ value }}>{children}</ChartsContext.Provider>
  )
}

function useChartContext() {
  const context = React.useContext(ChartsContext)

  if (context === undefined) {
    throw new Error('useChartContext must be used within a ChartsProvider')
  }

  return context
}

export { ChartsProvider, useChartContext }