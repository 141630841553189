import { pimApi } from 'shared/api'
import { MediaImageItemMemo } from 'widgets'
import { snackActions } from 'shared/lib/react/snackbar'

interface PhotoItemProps {
  productId: UniqueId
  openGallery?: () => void
  item: pimApi.products.wb.WBMedia
  onFileDelete: (item: pimApi.products.MediaFile) => void
  onBookmarkToggle:  (item: pimApi.products.wb.WBMedia) => void
  index: number
  disabled?: boolean
}

const fileThumbnailSize = { maxWidth: 350, maxHeight: 350 }

export const PhotoItem = ({ productId, item, onFileDelete, onBookmarkToggle, openGallery, disabled }: PhotoItemProps) => {
  const { downloadUrl, id, fileName } = item

  const getFilePath = pimApi.products.wb.getWBMediaApiPath
  const { mutate: importMediaFromProduct, isLoading: importMediaInProgress } =
    pimApi.products.common.useImportMediaFromProduct()
  const imgPath = getFilePath(productId, id, 'photos', fileThumbnailSize)
  const handleFileDelete = () => onFileDelete(item)
  const handleBookmarkToggle = () => onBookmarkToggle(item)
  const handleImportPhotoFromProduct = () => {
    importMediaFromProduct({ productId, mediaId: id, marketplaceName: 'wb' },
      {
        onSuccess: () => {
          snackActions.info('Успешно')
        }
      }
    )
  }

  return (
    <MediaImageItemMemo
      imgPath={imgPath}
      openGallery={openGallery}
      fileName={fileName}
      isLoading={importMediaInProgress}
      bookmarkActive={item.mediaGlobal}
      onFileDelete={handleFileDelete}
      importPhotoFromProduct={handleImportPhotoFromProduct}
      onBookmarkToggle={handleBookmarkToggle}
      downloadUrl={downloadUrl}
      disabled={disabled}
    />
  )
}
