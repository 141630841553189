import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { SUPPLY_ORDER_LIST, SUPPLY_ORDER_CREATE, SUPPLY_ORDER_EDIT } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'

import { CreateSupplyOrderPage } from './create/CreateSupplyOrder'
import { EditSupplyOrderPage } from './edit/EditSupplyOrder'
import { SupplyOrderListPage } from './supplyOrderList/SupplyOrderList'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: SUPPLY_ORDER_LIST,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: SupplyOrderListPage,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: SUPPLY_ORDER_CREATE,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: CreateSupplyOrderPage,
  },
  {
    protectedStatus: 'authorized',
    exact: false,
    path: SUPPLY_ORDER_EDIT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: EditSupplyOrderPage,
  }
] as Array<Route>