import React from 'react'

import { OrderDeltaContainer } from './styled'

export const OrderDeltaItems = ({ row }) => {
  const { extra, missing } = row
  return (
    <OrderDeltaContainer>
      {missing && <span className="missing">{missing}</span>}
      {extra && <span className="extra">+{extra}</span>}
    </OrderDeltaContainer>
  )
}