import { InfoOutlined } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { InArchive } from 'shared/ui/components/InArchiveBadge/InArchiveBadge'

import {
  HtmlTooltip,
  AlertStyled,
  AlertTitleStyled,
  AlertMessageStyled,
} from './styled'

export const ArchiveBadge = ({
  reason,
  comments,
}: {
  reason?: string
  comments?: string
}) => (
  <HtmlTooltip
    title={
      <>
        <AlertStyled icon={<InfoOutlined />} severity="info">
          <AlertTitleStyled>Причина</AlertTitleStyled>
          <AlertMessageStyled>{reason}</AlertMessageStyled>
        </AlertStyled>
        {comments && (
          <TextField
            label="Комментарий"
            disabled={true}
            size="small"
            defaultValue={comments}
            fullWidth={true}
            multiline={true}
          />
        )}
      </>
    }
    placement="right"
  >
    <InArchive $spacing="16px">В архиве</InArchive>
  </HtmlTooltip>
)
