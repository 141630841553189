/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AcceptanceShortInfo */
export interface AcceptanceShortInfo {
  /**
   * Идентификатор документа
   * @exclusiveMin 0
   * @example 1000
   */
  id: number
  /** Positions */
  positions: ReceiptDocPositionShortInfo[]
}

/** Area */
export interface Area {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Parent Area Id */
  parent_area_id?: number
  /** Name */
  name: string
  /** Code */
  code: string
  /** Is Storage */
  is_storage: boolean
  /** Is Fbs */
  is_fbs: boolean
  /** Is Warehouse */
  is_warehouse: boolean
  /** Is Receipt */
  is_receipt: boolean
  /** Is Shipment */
  is_shipment: boolean
  /** Index */
  index?: number
  /** Location */
  location?: string
}

/** AreaGoodsStocks */
export type AreaGoodsStocks = GoodsStocks[]

/** AreaNode */
export interface AreaNode {
  /** Id */
  id: number
  /** Version No */
  version_no: number
  /**
   * Created
   * @format date-time
   */
  created: string
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /**
   * Deleted
   * @format date-time
   */
  deleted: string
  /** Changer */
  changer: string
  /** Parent Area Id */
  parent_area_id: number
  /** Name */
  name: string
  /** Code */
  code: string
  /** Is Storage */
  is_storage: boolean
  /** Is Fbs */
  is_fbs: boolean
  /** Is Warehouse */
  is_warehouse: boolean
  /** Is Receipt */
  is_receipt: boolean
  /** Is Shipment */
  is_shipment: boolean
  /** Index */
  index: number
  /** Children */
  children: AreaNode[]
}

/** Areas */
export type Areas = Area[]

/** Body_receipt_documents-create_receiptdocposition_from_file */
export interface BodyReceiptDocumentsCreateReceiptdocpositionFromFile {
  /**
   * Файл с входными данными
   * @format binary
   */
  file: File
}

/** Body_stocks-patch_stocks_excel */
export interface BodyStocksPatchStocksExcel {
  /**
   * Файл с входными данными
   * @format binary
   */
  file: File
}

/** ChangeMovementDocStatus */
export interface ChangeMovementDocStatus {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Comments
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /**
   * Метод над документом
   * @example "start"
   */
  operation: AppWmsDocsModelOperationsEnum
}

/** ChangeReceiptDocStatus */
export interface ChangeReceiptDocStatus {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Метод над документом
   * @example "start"
   */
  operation: AppWmsModelOperationsEnum
  /** Место приемки по умолчанию */
  storage_id?: number
}

/** DeletingObject */
export interface DeletingObject {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
}

/** EditReceiptDoc */
export interface EditReceiptDoc {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Ожидаемая дата прибытия
   * @format date-time
   */
  expected_arrival_date?: string
  /**
   * Количество паллет
   * @exclusiveMin 0
   * @max 100
   * @example 4
   */
  pallet_quantity?: number
  /**
   * Количество коробок
   * @exclusiveMin 0
   * @max 100
   * @example 27
   */
  box_quantity?: number
  /**
   * Транспортная информация
   * @maxLength 255
   * @example "Телефон водителя 123"
   */
  transport_info?: string
  /**
   * Комментарии
   * @maxLength 255
   * @example "По приезду сообщить в отдел логистики"
   */
  comments?: string
  /** Место приемки по умолчанию */
  default_storage_id?: number
}

/** EditReceiptDocAcceptance */
export interface EditReceiptDocAcceptance {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Количество упаковок факт
   * @exclusiveMin 0
   * @example 10
   */
  quantity_accepted?: number
  /**
   * Количество упаковок брак
   * @min 0
   * @example 0
   */
  quantity_defective?: number
}

/** EditingMovementDocCarriage */
export interface EditingMovementDocCarriage {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Comments
   * Комментарий к операции
   * @maxLength 255
   */
  comments?: string
  /** Перемещенное количество */
  quantity: number
}

/** ErrorMessage */
export interface ErrorMessage {
  /** Код ошибки */
  code?: number
  /**
   * Текст с описанием ошибки
   * @maxLength 4000
   */
  message?: string
}

/** Goods */
export interface Goods {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование бренда
   * @maxLength 255
   * @example "Coca Cola"
   */
  brand: string
  /**
   * Артикул
   * @maxLength 100
   */
  code: string
  /**
   * Дополнительный артикул
   * @maxLength 100
   */
  aux_code?: string
  /**
   * Наименование товара
   * @maxLength 1000
   * @example "Fanta напиток газированый 2л"
   */
  name: string
  /** Тип товара */
  goods_type: GoodsType
  /** Список изображений товара */
  images?: GoodsImage[]
  /** Информация о штрих кодах товара */
  barcodes?: GoodsPackaging[]
}

/** GoodsImage */
export interface GoodsImage {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Порядковый номер изображения
   * @example "0"
   */
  index: number
}

/** GoodsPackaging */
export interface GoodsPackaging {
  /**
   * Поставщик товара
   * @maxLength 255
   * @example "ООО Поставщик"
   */
  supplier: string
  /**
   * Вид упаковки
   * @maxLength 255
   * @example "Единичный товар"
   */
  packagingtype: string
  /**
   * Штрих-код
   * @maxLength 255
   * @example "2221114443"
   */
  barcode: string
}

/** GoodsStocks */
export interface GoodsStocks {
  /** Goods Id */
  goods_id: number
  /** Location */
  location?: string
  /** Area Id */
  area_id: number
  /** Receiptdoc Id */
  receiptdoc_id?: number
  /** Available */
  available: number
  /** Reserved */
  reserved: number
}

/**
 * GoodsType
 * An enumeration.
 */
export enum GoodsType {
  Common = 'common',
  Bundle = 'bundle',
  Pack = 'pack',
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** ImportMessage */
export interface ImportMessage {
  /**
   * Текст сообщения
   * @maxLength 255
   */
  text?: string
  /** Тип сообщения */
  type: MessageEnum
}

/** ImportMetadata */
export interface ImportMetadata {
  /** Тип ответа */
  response_type: ModalEnum
  /** Сообщения для пользователя */
  messages: ImportMessage[]
  /** Ссылка на файл с результатом массовой операции */
  file_url?: string
}

/** ManualNewReceiptDocPosition */
export interface ManualNewReceiptDocPosition {
  /**
   * Код поставшика
   * @maxLength 100
   * @example "FTH-1022/B"
   */
  supplier_code: string
  /**
   * Идентификатор товара
   * @example 1001
   */
  goods_id?: number
  /**
   * Причина добавления товара
   * @maxLength 255
   * @example "доложено поставщиком"
   */
  reason: string
  /**
   * Наименование товара
   * @maxLength 255
   * @example "Зубная щетка XXL"
   */
  comments?: string
  /**
   * Количество товара по документу
   * @example 420
   */
  quantity: number
}

/**
 * MessageEnum
 * An enumeration.
 */
export enum MessageEnum {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

/**
 * Method
 * An enumeration.
 */
export enum Method {
  MoveStocks = 'move_stocks',
  WriteOffStocks = 'write_off_stocks',
}

/**
 * ModalEnum
 * An enumeration.
 */
export enum ModalEnum {
  SuccessfulImport = 'successful_import',
  PartialSuccessfulImport = 'partial_successful_import',
  FailedImport = 'failed_import',
  WrongTemplate = 'wrong_template',
}

/** MoveStocksParams */
export interface MoveStocksParams {
  /**
   * Method
   * Действие
   */
  method: 'move_stocks'
  /**
   * Goods Id
   * ID товара
   */
  goods_id: number
  /**
   * Receiptdoc Id
   * ID документа
   */
  receiptdoc_id: number
  /**
   * Owner Id
   * ID владельца
   */
  owner_id: number
  /**
   * From Area Id
   * ID области (откуда)
   */
  from_area_id: number
  /**
   * To Area Id
   * ID области (куда)
   */
  to_area_id: number
  /**
   * Quantity
   * Количество
   * @min 1
   */
  quantity: number
}

/** MovementDoc */
export interface MovementDoc {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /**
   * Статус
   * @example "new"
   */
  status: WarehouseDocStatusEnum
  /**
   * Комментарии
   * @maxLength 255
   * @example "Обработать в первую очередь"
   */
  comments?: string
  /** Список позиций по документу */
  positions: MovementDocPosition[]
}

/** MovementDocCarriage */
export interface MovementDocCarriage {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Перемещенное количество */
  quantity: number
}

/** MovementDocCarriages */
export type MovementDocCarriages = MovementDocCarriage[]

/** MovementDocPosition */
export interface MovementDocPosition {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /** Идентификатор товара */
  goods_id: number
  /** Планируемое к перемещению количество */
  quantity: number
  /** Перемещение из зоны */
  from_area_id: number
  /** Перемещение в зону */
  to_area_id: number
  /** Идентификатор документа приемки */
  receiptdoc_id?: number
  /** Информация о перемещенном количестве */
  carriage?: MovementDocCarriages
}

/** MovementDocShort */
export interface MovementDocShort {
  /**
   * Id
   * Идентификатор объекта
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * Версия объекта
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Created
   * Дата и время создания
   * @format date-time
   */
  created: string
  /**
   * Changed
   * Дата и время последнего изменения
   * @format date-time
   */
  changed: string
  /**
   * Changer
   * Имя пользователя, внесшего последнее изменение
   */
  changer: string
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /**
   * Статус
   * @example "new"
   */
  status: WarehouseDocStatusEnum
  /**
   * Комментарии
   * @maxLength 255
   * @example "Обработать в первую очередь"
   */
  comments?: string
}

/** MovementDocsShort */
export type MovementDocsShort = MovementDocShort[]

/** NewMovementDoc */
export interface NewMovementDoc {
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /**
   * Комментарии
   * @maxLength 255
   * @example "Обработать в первую очередь"
   */
  comments?: string
}

/** NewMovementDocCarriage */
export interface NewMovementDocCarriage {
  /** Перемещенное количество */
  quantity: number
}

/** NewMovementDocPosition */
export interface NewMovementDocPosition {
  /** Идентификатор товара */
  goods_id: number
  /** Планируемое к перемещению количество */
  quantity: number
  /** Перемещение из зоны */
  from_area_id: number
  /** Перемещение в зону */
  to_area_id: number
  /** Идентификатор документа приемки */
  receiptdoc_id?: number
}

/** NewReceiptDoc */
export interface NewReceiptDoc {
  /**
   * Для обновления ранее созданного документа необходим его идентификатор
   * @exclusiveMin 0
   */
  id?: number
  /**
   * Код склада
   * @maxLength 100
   * @example "00-000001"
   */
  warehouse_code: string
  /**
   * Код исходной системы
   * @maxLength 100
   * @example "1C-ERP"
   */
  source_system_code: string
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /** Дополнительный номер документа */
  extra_doc_number?: string
  /**
   * Дата документа отправителя
   * @format date-time
   */
  supplier_doc_date: string
  /**
   * Номер документа отправителя
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  supplier_doc_number: string
  /**
   * ИНН отправителя
   * @maxLength 12
   * @example "1658159352"
   */
  supplier_inn: string
  /**
   * КПП отправителя
   * @maxLength 9
   * @example "165801001"
   */
  supplier_kpp?: string
  /**
   * ИНН получателя
   * @maxLength 12
   * @example "1658159352"
   */
  recipient_inn: string
  /**
   * КПП получателя
   * @maxLength 9
   * @example "165801001"
   */
  recipient_kpp?: string
  /**
   * ИНН владельца
   * @maxLength 12
   * @example "1658159352"
   */
  owner_inn?: string
  /**
   * КПП владельца
   * @maxLength 9
   * @example "165801001"
   */
  owner_kpp?: string
  /**
   * Комментарии
   * @maxLength 255
   * @example "По приезду сообщить в отдел логистики"
   */
  comments?: string
  /** Список позиций по документу */
  positions: NewReceiptDocPosition[]
}

/** NewReceiptDocAcceptance */
export interface NewReceiptDocAcceptance {
  /**
   * Штрих код упаковки
   * @maxLength 100
   * @example "12345678909"
   */
  barcode?: string
  /**
   * Количество упаковок факт
   * @exclusiveMin 0
   * @example 10
   */
  quantity_accepted: number
  /**
   * Количество упаковок брак
   * @min 0
   * @example 0
   */
  quantity_defective?: number
}

/** NewReceiptDocPosition */
export interface NewReceiptDocPosition {
  /**
   * Код поставшика
   * @maxLength 100
   * @example "FTH-1022/B"
   */
  supplier_code: string
  /**
   * Идентификатор товара
   * @example 1001
   */
  goods_external_id: number
  /**
   * Цена
   * @example 99.99
   */
  price: number
  /**
   * Количество товара по документу
   * @example 420
   */
  quantity: number
}

/** NotAuthenticatedError */
export interface NotAuthenticatedError {
  /**
   * Текст с описанием ошибки
   * @maxLength 255
   */
  detail?: string
}

/** ReceiptDoc */
export interface ReceiptDoc {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /**
   * Отправитель
   * @maxLength 100
   * @example "ООО Отправитель"
   */
  supplier: string
  /**
   * Идентификатор отправителя
   * @example 2011
   */
  supplier_id: number
  /**
   * Получатель
   * @maxLength 100
   * @example "ООО Получатель"
   */
  recipient: string
  /**
   * Дата документа отправителя
   * @format date-time
   */
  supplier_doc_date: string
  /**
   * Номер документа отправителя
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  supplier_doc_number: string
  /**
   * Ожидаемая дата прибытия
   * @format date-time
   */
  expected_arrival_date?: string
  /**
   * Дата начала приемки
   * @format date-time
   */
  receipt_start_date?: string
  /**
   * Дата завершения приемки
   * @format date-time
   */
  receipt_finish_date?: string
  /**
   * Количество паллет
   * @example 4
   */
  pallet_quantity?: number
  /**
   * Количество коробок
   * @example 27
   */
  box_quantity?: number
  /**
   * Транспортная информация
   * @maxLength 255
   * @example "Телефон водителя 123"
   */
  transport_info?: string
  /**
   * Комментарии
   * @maxLength 255
   * @example "По приезду сообщить в отдел логистики"
   */
  comments?: string
  /**
   * Идентификатор места приемки по умолчанию
   * @example 8890
   */
  default_storage_id?: number
  /**
   * Статус
   * @example "new"
   */
  status: ReceiptDocStatusEnum
  /** Список позиций по документу */
  positions?: ReceiptDocPosition[]
}

/** ReceiptDocAcceptance */
export interface ReceiptDocAcceptance {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Вид упаковки
   * @maxLength 100
   * @example "Малая коробка"
   */
  packaging_type: string
  /**
   * Штрих код упаковки
   * @maxLength 100
   * @example "12345678909"
   */
  barcode: string
  /**
   * Количество товара в упаковке
   * @example 18
   */
  quantity_in_packaging: number
  /**
   * Количество упаковок факт
   * @example 10
   */
  quantity_accepted: number
  /**
   * Количество упаковок брак
   * @example 0
   */
  quantity_defective: number
}

/** ReceiptDocPosition */
export interface ReceiptDocPosition {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Код поставшика
   * @maxLength 100
   * @example "FTH-1022/B"
   */
  supplier_code: string
  /**
   * Идентификатор товара
   * @example 1001
   */
  goods_id: number
  /**
   * Наименование товара
   * @maxLength 255
   * @example "Зубная щетка XXL"
   */
  goods_name: string
  /**
   * Идентификатор товара во внешней системе
   * @maxLength 255
   * @example "902221"
   */
  goods_external_id?: string
  /**
   * Идентификатор основного изображения товара
   * @example 1001
   */
  goods_base_image_id?: number
  /**
   * Цена
   * @example 99.99
   */
  price?: number
  /**
   * Количество товара по документу
   * @example 420
   */
  quantity: number
  /** Признак того, что позиция была введена вручную и остутствовала в исходном документе */
  new_position?: boolean
  /** Информация о принятом количестве */
  acceptance?: ReceiptDocAcceptance[]
}

/** ReceiptDocPositionShortInfo */
export interface ReceiptDocPositionShortInfo {
  /**
   * Идентификатор позиции по документу
   * @exclusiveMin 0
   * @example 1001
   */
  id: number
  /** Информация о принятом количестве */
  acceptance?: ReceiptDocAcceptance[]
}

/** ReceiptDocShortInfo */
export interface ReceiptDocShortInfo {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Дата документа
   * @format date-time
   */
  doc_date: string
  /**
   * Номер документа
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  doc_number: string
  /**
   * Отправитель
   * @maxLength 100
   * @example "ООО Отправитель"
   */
  supplier: string
  /**
   * Получатель
   * @maxLength 100
   * @example "ООО Получатель"
   */
  recipient: string
  /**
   * Дата документа отправителя
   * @format date-time
   */
  supplier_doc_date: string
  /**
   * Номер документа отправителя
   * @maxLength 100
   * @example "ТН-2011/6"
   */
  supplier_doc_number: string
  /**
   * Ожидаемая дата прибытия
   * @format date-time
   */
  expected_arrival_date?: string
  /**
   * Дата начала приемки
   * @format date-time
   */
  receipt_start_date?: string
  /**
   * Дата завершения приемки
   * @format date-time
   */
  receipt_finish_date?: string
  /**
   * Транспортная информация
   * @maxLength 255
   * @example "Телефон водителя 123"
   */
  transport_info?: string
  /**
   * Идентификатор места приемки по умолчанию
   * @example 8890
   */
  default_storage_id?: number
  /**
   * Статус
   * @example "new"
   */
  status: ReceiptDocStatusEnum
}

/**
 * ReceiptDocStatusEnum
 * An enumeration.
 */
export enum ReceiptDocStatusEnum {
  New = 'new',
  Started = 'started',
  Paused = 'paused',
  Finished = 'finished',
  FinishedWithDiff = 'finished_with_diff',
}

/** Stocks */
export interface Stocks {
  /** Goods Id */
  goods_id: number
  /** Name */
  name: string
  /** Code */
  code: string
  /** Brand */
  brand: string
  /** Receiptdoc Id */
  receiptdoc_id: number
  /**
   * Acceptance Date
   * @format date
   */
  acceptance_date: string
  /** Doc Number */
  doc_number: string
  /** Extra Doc Number */
  extra_doc_number: string
  /** Supplier Doc Number */
  supplier_doc_number: string
  /** Supplier Name */
  supplier_name: string
  /** Recipient Name */
  recipient_name: string
  /** Owner Name */
  owner_name: string
  /** Owner Id */
  owner_id: number
  /** Barcode */
  barcode: string
  /** Area Id */
  area_id: number
  /** Is Fbs */
  is_fbs: boolean
  /** Area Code */
  area_code: string
  /** Location */
  location: string
  /** Available */
  available: number
  /** Reserved */
  reserved: number
}

/** Storage */
export interface Storage {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование места хранения
   * @maxLength 100
   * @example "Ряд 1"
   */
  name?: string
  /**
   * Штрих код
   * @maxLength 100
   * @example "898899992"
   */
  barcode?: string
  /**
   * Вид места хранения
   * @maxLength 100
   * @example "Зона приёмки"
   */
  storagetype?: string
  /** Входит в место */
  parentstorage_id?: number
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: any[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** Warehouse */
export interface Warehouse {
  /**
   * Id
   * @exclusiveMin 0
   */
  id: number
  /**
   * Version No
   * @exclusiveMin 0
   */
  version_no: number
  /**
   * Changed
   * @format date-time
   */
  changed: string
  /** Changer */
  changer: string
  /**
   * Наименование склада
   * @maxLength 100
   */
  name?: string
  /**
   * Код склада
   * @maxLength 100
   */
  code?: string
}

/**
 * WarehouseDocStatusEnum
 * An enumeration.
 */
export enum WarehouseDocStatusEnum {
  New = 'new',
  Started = 'started',
  Paused = 'paused',
  Finished = 'finished',
  FinishedWithDiff = 'finished_with_diff',
}

/** WriteOffStocksParams */
export interface WriteOffStocksParams {
  /**
   * Method
   * Действие
   */
  method: 'write_off_stocks'
  /**
   * Goods Id
   * ID товара
   */
  goods_id: number
  /**
   * Owner Id
   * ID владельца
   */
  owner_id: number
  /**
   * Area Id
   * ID области (откуда)
   */
  area_id: number
  /**
   * Quantity
   * Количество
   * @min 1
   */
  quantity: number
}

/**
 * OperationsEnum
 * An enumeration.
 */
export enum AppWmsDocsModelOperationsEnum {
  Start = 'start',
  Pause = 'pause',
  Resume = 'resume',
  Finish = 'finish',
}

/**
 * OperationsEnum
 * An enumeration.
 */
export enum AppWmsModelOperationsEnum {
  Start = 'start',
  Pause = 'pause',
  Resume = 'resume',
  Finish = 'finish',
}

export namespace Dictionaries {
  /**
   * @description Получение списка всех складов в системе
   * @tags Dictionaries
   * @name DictionariesGetWarehouses
   * @summary Получение списка складов
   * @request GET:/warehouses
   * @secure
   */
  export namespace DictionariesGetWarehouses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Warehouse[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение информации о складе по переданному идентификатору
   * @tags Dictionaries
   * @name DictionariesGetWarehouse
   * @summary Получение информации о складе
   * @request GET:/warehouses/{warehouse_id}
   * @secure
   */
  export namespace DictionariesGetWarehouse {
    export type RequestParams = {
      /** Warehouse Id */
      warehouseId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Warehouse
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение списка мест хранения по указанному складу
   * @tags Dictionaries
   * @name DictionariesGetStorages
   * @summary Получение списка мест хранения
   * @request GET:/storages
   * @secure
   */
  export namespace DictionariesGetStorages {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор склада
       * Для получения списка мест хранения необходимо выбрать склад
       */
      warehouse_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Storage[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение информации о месте хранения по переданному идентификатору
   * @tags Dictionaries
   * @name DictionariesGetStorage
   * @summary Получение информации о месте хранения
   * @request GET:/storages/{storage_id}
   * @secure
   */
  export namespace DictionariesGetStorage {
    export type RequestParams = {
      /** Storage Id */
      storageId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Storage
    export const XPrivilegeCheck = undefined
  }
}

export namespace ReceiptDocuments {
  /**
   * @description Получение списка всех документов прихода товара по указанному складу
   * @tags Receipt documents
   * @name ReceiptDocumentsGetReceiptdocs
   * @summary Получение списка документов прихода товара
   * @request GET:/receiptdocs
   * @secure
   */
  export namespace ReceiptDocumentsGetReceiptdocs {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Идентификатор склада
       * Для получения списка документов прихода товара необходимо указать склад, на который приходит товар
       */
      warehouse_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDocShortInfo[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание документа прихода товара с указанием всех позиций документа
   * @tags Receipt documents
   * @name ReceiptDocumentsCreateReceiptdoc
   * @summary Создание документа прихода товара
   * @request POST:/receiptdocs
   * @secure
   */
  export namespace ReceiptDocumentsCreateReceiptdoc {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewReceiptDoc
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение полной информации по документу прихода товара, включая список позиций и фактическое количество оприходованного товара
   * @tags Receipt documents
   * @name ReceiptDocumentsGetReceiptdoc
   * @summary Получение полной информации по конкретному документу прихода товара
   * @request GET:/receiptdocs/{doc_id}
   * @secure
   */
  export namespace ReceiptDocumentsGetReceiptdoc {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Редактирование атрибутов документа прихода товара
   * @tags Receipt documents
   * @name ReceiptDocumentsChangeReceiptdoc
   * @summary Редактирование атрибутов документа прихода товара
   * @request PUT:/receiptdocs/{doc_id}
   * @secure
   */
  export namespace ReceiptDocumentsChangeReceiptdoc {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditReceiptDoc
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Применение метода изменения статуса над документом прихода товара. Проверяет возможность применения метода в зависимости от текущего статуса документа
   * @tags Receipt documents
   * @name ReceiptDocumentsChangeReceiptdocStatus
   * @summary Изменение статуса документа прихода товара
   * @request PATCH:/receiptdocs/{doc_id}
   * @secure
   */
  export namespace ReceiptDocumentsChangeReceiptdocStatus {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ChangeReceiptDocStatus
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDocShortInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Регистрация приёмки товара по его штрих-коду. Метод будет доступен с ТСД.
   * @tags Receipt documents
   * @name ReceiptDocumentsAcceptBarcode
   * @summary Приём товара по документу прихода товара
   * @request POST:/receiptdocs/{doc_id}/accept
   * @secure
   */
  export namespace ReceiptDocumentsAcceptBarcode {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {
      /**
       * Штрих-код
       * Используется для идентификации товара
       */
      barcode: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AcceptanceShortInfo
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Добавление позиции по новой номенклатуре в документ прихода товара
   * @tags Receipt documents
   * @name ReceiptDocumentsCreateReceiptdocposition
   * @summary Добавление позиции в документ прихода товара
   * @request POST:/receiptdocs/{doc_id}/positions
   * @secure
   */
  export namespace ReceiptDocumentsCreateReceiptdocposition {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ManualNewReceiptDocPosition
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDocPosition
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Добавление позиции по новой номенклатуре в документ прихода товара через файл
   * @tags Receipt documents
   * @name ReceiptDocumentsCreateReceiptdocpositionFromFile
   * @summary Добавление позиции в документ прихода товара через файл
   * @request POST:/receiptdocs/{doc_id}/positions/file
   * @secure
   */
  export namespace ReceiptDocumentsCreateReceiptdocpositionFromFile {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody =
      BodyReceiptDocumentsCreateReceiptdocpositionFromFile
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Шаблон добавления позиций в документ прихода товара через файл
   * @tags Receipt documents
   * @name ReceiptDocumentsTemaplteCreateReceiptdocpositionFromFile
   * @summary Шаблон добавления позиций в документ прихода товара через файл
   * @request GET:/receiptdocs/{doc_id}/positions/template
   * @secure
   */
  export namespace ReceiptDocumentsTemaplteCreateReceiptdocpositionFromFile {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Удаление позиции из документа прихода товара, которая ранее была создана вручную
   * @tags Receipt documents
   * @name ReceiptDocumentsDeleteReceiptdocposition
   * @summary Удаление позиции из документа прихода товара
   * @request DELETE:/receiptdocs/{doc_id}/positions/{position_id}
   * @secure
   */
  export namespace ReceiptDocumentsDeleteReceiptdocposition {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Добавление информации о принятом количестве по позиции документа прихода товара
   * @tags Receipt documents
   * @name ReceiptDocumentsCreateReceiptdocacceptance
   * @summary Добавление информации о принятом количестве по позиции документа прихода товара
   * @request POST:/receiptdocs/{doc_id}/positions/{position_id}/acceptance
   * @secure
   */
  export namespace ReceiptDocumentsCreateReceiptdocacceptance {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewReceiptDocAcceptance
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDocAcceptance
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение информации о принятом количестве по позиции документа прихода товара
   * @tags Receipt documents
   * @name ReceiptDocumentsEditReceiptdocacceptance
   * @summary Изменение информации о принятом количестве по позиции документа прихода товара
   * @request PUT:/receiptdocs/{doc_id}/positions/{position_id}/acceptance/{acceptance_id}
   * @secure
   */
  export namespace ReceiptDocumentsEditReceiptdocacceptance {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
      /** Acceptance Id */
      acceptanceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditReceiptDocAcceptance
    export type RequestHeaders = {}
    export type ResponseBody = ReceiptDocAcceptance
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Удаление информации о принятом количестве по позиции документа прихода товара
   * @tags Receipt documents
   * @name ReceiptDocumentsDeleteReceiptdocacceptance
   * @summary Удаление информации о принятом количестве по позиции документа прихода товара
   * @request DELETE:/receiptdocs/{doc_id}/positions/{position_id}/acceptance/{acceptance_id}
   * @secure
   */
  export namespace ReceiptDocumentsDeleteReceiptdocacceptance {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
      /** Acceptance Id */
      acceptanceId: number
    }
    export type RequestQuery = {}
    export type RequestBody = DeletingObject
    export type RequestHeaders = {}
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
}

export namespace Goods {
  /**
   * @description Получение краткой информации по запрошенной номенклатуре товара
   * @tags Goods
   * @name GoodsGetGoods
   * @summary Получение информации по товару
   * @request GET:/goods/{goods_id}
   * @secure
   */
  export namespace GoodsGetGoods {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Поиск товара по его штрих-коду
   * @tags Goods
   * @name GoodsSearchGoods
   * @summary Поиск товара
   * @request GET:/goods
   * @secure
   */
  export namespace GoodsSearchGoods {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Штрих-код
       * Поиск товара по его штрих-коду
       */
      barcode: string
      /**
       * Идентификатор поставщика
       * Ограничить поиск по товарам определённого поставщика
       */
      supplier_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Goods[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение изображения товара по идентификатору изображения
   * @tags Goods
   * @name GoodsGetGoodsfile
   * @summary Получение изображения товара
   * @request GET:/goods/{goods_id}/image/{image_id}
   */
  export namespace GoodsGetGoodsfile {
    export type RequestParams = {
      /** Goods Id */
      goodsId: number
      /** Image Id */
      imageId: number
    }
    export type RequestQuery = {
      /**
       * Максимальная высота изображения
       * Изображение будет пропорционально уменьшено, чтобы по высоте не превышать переданное значение
       */
      max_height?: number
      /**
       * Максимальная ширина изображения
       * Изображение будет пропорционально уменьшено, чтобы по ширине не превышать переданное значение
       */
      max_width?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /** Authorization */
      authorization?: string
    }
    export type ResponseBody = void
    export const XPrivilegeCheck = undefined
  }
}

export namespace Area {
  /**
   * @description Получение списка всех областей в системе
   * @tags Area
   * @name AreaGetAreaList
   * @summary Получение списка областей
   * @request GET:/area/list
   * @secure
   */
  export namespace AreaGetAreaList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Только области для хранения FBS */
      is_fbs?: boolean
      /** Только области для хранения */
      is_storage?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Areas
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение дерева всех областей в системе
   * @tags Area
   * @name AreaGetAreaTree
   * @summary Получение дерева областей
   * @request GET:/area/tree
   * @secure
   */
  export namespace AreaGetAreaTree {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AreaNode
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение остатков области (и её подобластей)
   * @tags Area
   * @name AreaGetAreaStocks
   * @summary Получение остатков области (и её подобластей)
   * @request GET:/area/tree/{area_id}/stocks
   * @secure
   */
  export namespace AreaGetAreaStocks {
    export type RequestParams = {
      /** Area Id */
      areaId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AreaGoodsStocks
    export const XPrivilegeCheck = undefined
  }
}

export namespace Stocks {
  /**
   * @description Получение списка всех остатков
   * @tags Stocks
   * @name StocksGetAreaStocksList
   * @summary Получение списка всех остатков
   * @request GET:/stocks/list
   * @secure
   */
  export namespace StocksGetAreaStocksList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поиск по строке */
      search_string?: string
      /** Поиск по поставщику */
      supplier_id?: number
      /** Поиск по получателю */
      recipient_id?: number
      /** Поиск по владельцу товара */
      owner_id?: number
      /** Поиск по бренду */
      brand_id?: number
      /** Поиск по области */
      area_id?: number
      /**
       * Возвращать остатки только из данной области, не включая остатки из подобластей
       * @default false
       */
      only_this_area?: boolean
      /** Возвращать только остатки FBS */
      is_fbs?: boolean
      /** Ограничение на количество возвращаемых записей */
      limit?: number
      /**
       * Смещение
       * @default 0
       */
      offset?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Stocks[]
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение списка всех остатков в виде файла
   * @tags Stocks
   * @name StocksGetAreaStocksFile
   * @summary Получение списка всех остатков в виде файла
   * @request GET:/stocks/file
   * @secure
   */
  export namespace StocksGetAreaStocksFile {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Поиск по токену */
      search_string?: string
      /** Поиск по поставщику */
      supplier_id?: number
      /** Поиск по получателю */
      recipient_id?: number
      /** Поиск по владельцу товара */
      owner_id?: number
      /** Поиск по бренду */
      brand_id?: number
      /** Поиск по области */
      area_id?: number
      /**
       * Возвращать остатки только из данной области, не включая остатки из подобластей
       * @default false
       */
      only_this_area?: boolean
      /** Возвращать только остатки FBS */
      is_fbs?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Применить действие к остаткам (excel)
   * @tags Stocks
   * @name StocksPatchStocksExcel
   * @summary Применить действие к остаткам (excel)
   * @request PATCH:/stocks/excel
   * @secure
   */
  export namespace StocksPatchStocksExcel {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Действие */
      method: Method
      /** Параметры к действию */
      method_param?: string
    }
    export type RequestBody = BodyStocksPatchStocksExcel
    export type RequestHeaders = {}
    export type ResponseBody = ImportMetadata
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Применить действие к остаткам
   * @tags Stocks
   * @name StocksPatchStocks
   * @summary Применить действие к остаткам
   * @request PATCH:/stocks
   * @secure
   */
  export namespace StocksPatchStocks {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody =
      | MoveStocksParams[]
      | WriteOffStocksParams[]
      | (MoveStocksParams[] & WriteOffStocksParams[])
    export type RequestHeaders = {}
    export type ResponseBody = Stocks[]
    export const XPrivilegeCheck = undefined
  }
}

export namespace MovementDocuments {
  /**
   * @description Получение списка документов на перемещение
   * @tags Movement documents
   * @name MovementDocumentsGetMovementdocs
   * @summary Получение списка документов на перемещение
   * @request GET:/movementdocs
   * @secure
   */
  export namespace MovementDocumentsGetMovementdocs {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MovementDocsShort
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание документа на перемещение
   * @tags Movement documents
   * @name MovementDocumentsCreateMovementdocs
   * @summary Создание документа на перемещение
   * @request POST:/movementdocs
   * @secure
   */
  export namespace MovementDocumentsCreateMovementdocs {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = NewMovementDoc
    export type RequestHeaders = {}
    export type ResponseBody = MovementDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Получение списка документов на перемещение
   * @tags Movement documents
   * @name MovementDocumentsGetMovementdoc
   * @summary Получение списка документов на перемещение
   * @request GET:/movementdocs/{doc_id}
   * @secure
   */
  export namespace MovementDocumentsGetMovementdoc {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = MovementDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменить статус документа на перемещение
   * @tags Movement documents
   * @name MovementDocumentsPatchMovementdoc
   * @summary Изменить статус документа на перемещение
   * @request PATCH:/movementdocs/{doc_id}
   * @secure
   */
  export namespace MovementDocumentsPatchMovementdoc {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = ChangeMovementDocStatus
    export type RequestHeaders = {}
    export type ResponseBody = MovementDoc
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание позиции документа на перемещение
   * @tags Movement documents
   * @name MovementDocumentsCreateMovementdocpositions
   * @summary Создание позиции документа на перемещение
   * @request POST:/movementdocs/{doc_id}/positions
   * @secure
   */
  export namespace MovementDocumentsCreateMovementdocpositions {
    export type RequestParams = {
      /** Doc Id */
      docId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewMovementDocPosition
    export type RequestHeaders = {}
    export type ResponseBody = MovementDocPosition
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Создание исполнения позиции документа на перемещение
   * @tags Movement documents
   * @name MovementDocumentsCreateMovementdoccarriages
   * @summary Создание исполнения позиции документа на перемещение
   * @request POST:/movementdocs/{doc_id}/positions/{position_id}/carriage
   * @secure
   */
  export namespace MovementDocumentsCreateMovementdoccarriages {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
    }
    export type RequestQuery = {}
    export type RequestBody = NewMovementDocCarriage
    export type RequestHeaders = {}
    export type ResponseBody = MovementDocCarriage
    export const XPrivilegeCheck = undefined
  }
  /**
   * @description Изменение исполнения позиции документа на перемещение
   * @tags Movement documents
   * @name MovementDocumentsChangeMovementdoccarriages
   * @summary Изменение исполнения позиции документа на перемещение
   * @request PUT:/movementdocs/{doc_id}/positions/{position_id}/carriage/{carriage_id}
   * @secure
   */
  export namespace MovementDocumentsChangeMovementdoccarriages {
    export type RequestParams = {
      /** Doc Id */
      docId: number
      /** Position Id */
      positionId: number
      /** Carriage Id */
      carriageId: number
    }
    export type RequestQuery = {}
    export type RequestBody = EditingMovementDocCarriage
    export type RequestHeaders = {}
    export type ResponseBody = MovementDocCarriage
    export const XPrivilegeCheck = undefined
  }
}

//none
