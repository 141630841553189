import { ListItemButton } from '@mui/material'
import styled from 'styled-components'

export const ListItemStyled = styled(ListItemButton)<{ $includes: boolean }>`
  padding-left: 8px;
  padding-right: 8px;
  max-height: 38px;
  color: rgba(0, 0, 0, 0.6);

  .MuiListItemIcon-root {
    min-width: 0;
    margin-right: 16px;
  }
  .MuiListItemText-root {
    > span {
      transition: all 0.3s ease-out 0.05s;
      font-size: 14px;
      color: ${({ $includes }) => $includes ? '#2196F3' : '' };
    }
  }

  :hover {
    background-color: rgba(255, 255, 255, 0);

    img {
      opacity: 0.5;
    }
    
    svg {
      opacity: 0.5;
    }
  }

  svg {
    transition: all 0.3s ease-out 0.05s;;
    color: ${({ $includes }) => $includes ? '#2196F3' : '#00000042' };
  }
  img {
    filter: ${({ $includes }) => $includes ? 'invert(61%) sepia(52%) saturate(5269%) hue-rotate(183deg) brightness(95%) contrast(100%)'
    : '' };
  }
`

export const SidebarWrapper = styled.div`
  
  .sidebar-container {
    .MuiPaper-root {
      width: 250px;
      transition: width 0.2s ease-out 0.05s;
      overflow-x: hidden;
      overflow-y: auto;
      background: #FAFAFA;
      border-right: #FAFAFA;
    }

    .material-list {
      overflow-y: auto;
      overflow-x: hidden;
      transition: all 0.2s ease-out 0.05s;
      padding: 0 18px;
      height: calc(100% - 56px);

      .menu-link-active {
        text-decoration: none;
        color: #2196F3;
        background-color: #e9f4fe;

        img {
          filter: invert(52%) sepia(37%) saturate(4129%) hue-rotate(184deg) brightness(96%) contrast(99%);
        }
      }

      .MuiListItemText-root {
        transition: opacity 0.2s ease-out 0.05s;
        opacity: 1;
      }

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 50%;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 40px;
      }
    }
    
    .MuiCollapse-root {
      margin-bottom: 10px;

      .MuiList-root {
        display: flex;
        justify-content: center;
      }
      
      .MuiListItemButton-root {
        transition: all 0.2s ease-out 0.05s;
        color: rgba(0, 0, 0, 0.6);
        max-height: 38px !important;
        cursor: pointer;
        margin-bottom: 2px;
        gap: 16px;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;

        .MuiListItemText-root {
          margin: 2px 0;
          flex-shrink: 0;
          opacity: 1;
        }

        .MuiListItemIcon-root {
          min-width: 20px;
        }

        span {
          font-size: 14px;
        }

        :hover {
          transition: all 0.1s ease-out 0.05s;
          text-decoration: none;
        }
      }
    }
    .logo-wrapper {
      transition: all 0.2s ease-out 0.05s;
      width: min-content;
      pointer-events: none;
      height: 50px;
      min-height: 50px;
      padding-top: 18px;
      margin-bottom: 8px;
      padding-left: 29px;
      position: relative;

      img {
        position: absolute;
      }
      .full-img {
        transition: all 0.2s ease-out 0.05s;
        opacity: 1;
      }
    }
  }
  
  .closed {
    .MuiPaper-root {
      width: 56px;
    }
    
    .material-list {
      padding: 0 8px;

      .MuiListItemText-root {
        opacity: 0;
      }
    }
    
    .MuiCollapse-root {
      .MuiListItemButton-root {
        padding-left: 9px;
        padding-right: 7px;
        transition: 0.3s;

        .MuiListItemText-root {
          opacity: 0;
        }
      }
    }
    
    .logo-wrapper {
      padding-left: 12px;

      .full-img {
        opacity: 0;
      }
    }
  }
`