import { Button } from '@mui/material'
import { tradeGen } from 'shared/lib/generated'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { hasRoleAccess } from 'shared/api/base'

import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'
import { AddCustomWarehouseModal } from '../modal/AddCustomWarehouseModal'

export const TableTransitions = () => {
  const { invalidateDistributionCache, setDistributionCache } = useChangeDistribution()
  const { supplyOrderQuery } = useSupplyOrderContext()
  const { mutate: refreshDistributions  } = tradeGen.orders.RefreshDistributions.useRefreshDistributions()
  const { mutate: addCustomWarehouse } = tradeGen.orders.Addcustomerwarehouse.useAddcustomerwarehouse()
  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  
  const handleAddCustomWarehouse = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) => (
          <AddCustomWarehouseModal
            close={close}
            accept={accept}
          />
        ),
        onAccept: (selectedWarehouse: { value: number, label: string }) => {
          setIsLoading(true)
          addCustomWarehouse({
            copackingorderId: supplyOrderQuery.data?.copackingorderId!,
            data: {
              id: supplyOrderQuery.data?.copackingorderId!,
              warehouseId: selectedWarehouse.value
            }
          }, {
            onSuccess: (response) => {
              setIsLoading(false)
              setSelectedProducts(null)
              setDistributionCache({ supplyOrderQuery, response })
              snackActions.info('Собственный склад добавлен')
            }, onError: () => setIsLoading(false)
          })
        },
      }
    )
  }
  
  const handleRefreshDistribution = () => {
    setIsLoading(true)
    refreshDistributions({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
    }, {
      onSuccess: () => {
        snackActions.info('Успешно ')
        setSelectedProducts(null)
        setIsLoading(false)
        invalidateDistributionCache({ supplyOrderQuery })
      }
    })
  }

  return ( 
    <div className="table-transition-group">
      {hasRoleAccess('LOGISTICS') &&
        <Button variant="outlined" onClick={handleAddCustomWarehouse}>Добавить собственный склад</Button>
      }
      <Button variant="outlined" onClick={handleRefreshDistribution}>Пересчитать</Button>
    </div>
  )
}
