import { memo } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { TabsNavWithIcon } from 'pages/goods/components/common'
import { CharacteristicsAndAttributes } from 'features/product/WB/MapAttributes'
import { Media } from 'features/product/WB/TabMedia'
import { pimApi } from 'shared/api'
import { ProductChangelogTable as Changelog } from 'features/product/changelog'
import HistoryIcon from '@mui/icons-material/History'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { CompetitorsTab } from 'features/product/common/CompetitorsTab'
import { useNormalizedAttributes } from 'features/product/WB/MapAttributes/model/useNormalizedAttributes'
import { CommonParametersChecker } from 'shared/ui/product/GlobalParametersChecker'
import { useField } from 'react-final-form'

import { GeneralInformation } from './ui/GeneralInformationTab'
import { CompetitorBadge, FlexWrapper } from './ui/styled'
import { Description } from './ui/DescriptionTab'
import { PriceTab } from './ui/PriceTab'

const TABS = [
  {
    label: 'Основное',
    Component: GeneralInformation,
    value: 'general',
  },
  {
    label: 'Характеристики',
    Component: CharacteristicsAndAttributes,
    value: 'characteristics'
  },
  {
    label: 'Цена',
    Component: PriceTab,
    value: 'price'
  },
  {
    label:  'Медиа',
    Component: Media,
    value: 'media'
  },
  {
    label:  'Описание',
    Component: Description,
    value: 'description'
  },
  {
    label:  'Оптимизация',
    Component: CompetitorsTab,
    value: 'competitors'
  },
  {
    label:  'История изменений',
    Component: Changelog,
    value: 'changelog'
  },
  
] as const

interface ProductInfoTabsProps {
  commonProduct: pimApi.products.common.Product
  isArchived?: boolean
}

export const ProductInfoTabs = ({
  commonProduct,
  isArchived,
}: ProductInfoTabsProps) => {
  const tnvedEnabled = useField('tnvedEnabled').input.value
  const { normalizedAttributes } = useNormalizedAttributes()
  const attributesWithGlobalFlag = [ ...normalizedAttributes.normalizedAttributesMapped, ...normalizedAttributes.normalizedAttributesRight ]

  const tabsParametersName = {
    'general' : ['brandGlobal', 'nameGlobal', ...(tnvedEnabled ? ['tnvedGlobal'] : []), 'countryproductionGlobal', 'sizeinfoGlobal'],
    'characteristics': attributesWithGlobalFlag.map(el => `attributes[${el.right.index}].attributeGlobal`),
    'description': ['descriptionGlobal']
  }

  const { search } = useLocation()
  const history = useHistory()
  const { tab } = queryString.parse(search)

  const handleTabChange = (_: React.ChangeEvent<{}>, newTabValue: string) => {
    handleTabParamsChange({ tab: newTabValue })
  }

  const handleTabParamsChange = (params) => {
    const historyStr = queryString.stringify(
      { ...params },
      { skipEmptyString: true, skipNull: true }
    )
    history.replace(`?${historyStr}`)
  }

  return (
    <>
      <Box id="ScrollToPriceControlWB" mb={ 3 } sx={ { borderBottom: '1px solid rgba(0, 125, 255, 0.5)' } }>
        <FlexWrapper>
          <TabsNavWithIcon>
            <Tabs value={ tab || TABS[0].value } onChange={ handleTabChange }>
              { TABS.map(({ label, value }, index) => {
                if (label === 'История изменений') {
                  return (
                    <Tab
                      key={value}
                      value={value}
                      label={ <HistoryIcon/> }
                      { ...a11yProps(index) }
                    />
                  )
                }
                
                if (label === 'Оптимизация' && commonProduct.activeCompetitors !== 0) {
                  return (
                    <Tab
                      key={ value }
                      value={value}
                      label={
                        <div className="competitors-icon-label">
                          { label }
                          <CompetitorBadge>
                            <span>{ commonProduct.activeCompetitors }</span>
                          </CompetitorBadge>
                        </div> }
                      { ...a11yProps(index) }
                    />
                  )
                }
                return (
                  <Tab
                    key={ value }
                    value={value}
                    label={
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                        <CommonParametersChecker parametersName={tabsParametersName[value]}/>
                        { label }
                      </div> }
                    { ...a11yProps(index) }
                  />
                )
              }) }
            </Tabs>
          </TabsNavWithIcon>
        </FlexWrapper>
      </Box>

      { TABS.map(({ Component, value }) => (
        <TabPanel
          key={ value }
          value={ tab || TABS[0].value }
          index={ value }
        >
          <Component isArchived={ isArchived } commonProduct={ commonProduct }/>
        </TabPanel>
      )) }
    </>
  )
}

export const MemoProductInfoTabs = memo(ProductInfoTabs)
