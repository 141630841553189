import { DocType } from 'shared/services/interfaces/doc'
import { ColumnConfig } from 'shared/ui/components/Table/model/types'

export type ColumnName = keyof DocType

export const columns = {
  docNumber: 'docNumber',
  docDate: 'docDate',
  supplierDocNumber: 'supplierDocNumber',
  supplierDocDate: 'supplierDocDate',
  status: 'status',
  supplier: 'supplier',
  recipient: 'recipient',
  expectedArrivalDate: 'expectedArrivalDate',
  receiptStartDate: 'receiptStartDate',
  receiptFinishDate: 'receiptFinishDate'
} as const

export const columnsConfig: Array<ColumnConfig<ColumnName>> = [
  {
    name: columns.docNumber,
    columnName: columns.docNumber,
    title: 'Документ внутренний',
    width: 130,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.docDate,
    columnName: columns.docDate,
    title: 'Дата вн. док.',
    width: 95,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.supplierDocNumber,
    columnName: columns.supplierDocNumber,
    title: 'Документ поставщика',
    width: 125,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.supplierDocDate,
    columnName: columns.supplierDocDate,
    title: 'Дата док. п.',
    width: 88,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.status,
    columnName: columns.status,
    title: 'Статус',
    width: 90,
    sortingEnabled: false,
  },
  {
    name: columns.supplier,
    columnName: columns.supplier,
    title: 'Отправитель',
    width: 'auto',
    sortingEnabled: true,
  },
  {
    name: columns.recipient,
    columnName: columns.recipient,
    title: 'Получатель',
    width: 'auto',
    sortingEnabled: true,
  },
  {
    name: columns.expectedArrivalDate,
    columnName: columns.expectedArrivalDate,
    title: 'Ожидается приемка',
    width: 120,
    sortingEnabled: true,
    wordWrapEnabled: true,
  },
  {
    name: columns.receiptStartDate,
    columnName: columns.receiptStartDate,
    title: 'Началась',
    width: 120,
    sortingEnabled: true,
  },
  {
    name: columns.receiptFinishDate,
    columnName: columns.receiptFinishDate,
    title: 'Завершена',
    width: 130,
    sortingEnabled: true,
  },
]