import React from 'react'
import styled from 'styled-components'
import { Check } from '@mui/icons-material'
import { useHistory } from 'react-router'

const Wrapper = styled.div`
  padding: 0 40px 20px;
  display: flex;
  margin-bottom: 20px;
`
const Step = styled.div`
  padding: 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    width: 36px;
    height: 1px;
    background: #bdbdbd;
    position: relative;
    top: 2px;
    left: 8px;
  }
`
const StepNumber = styled.div<{ $isActive?: boolean }>`
  width: 24px;
  height: 24px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  margin-right: 6px;
  background: ${(props) =>
    props.$isActive ? '#007DFF;' : 'rgba(0, 0, 0, 0.38);'};
`
const StepName = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
`
const CheckWrapper = styled.div`
  display: inline-flex;
  height: 100%;
  align-items: center;
  margin-top: 3px;
  > svg {
    font-size: 16px;
  }
`

export const Stepper = ({ step }: { step: number }) => {
  const history = useHistory()
  const handleChangeStep = (nextStep: number) => {
    history.push({
      search: `?step=${nextStep}`,
    })
  }
  return (
    <Wrapper>
      <Step onClick={() => handleChangeStep(1)}>
        <StepNumber $isActive={step >= 1}>
          <span>
            {step > 1 ? (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            ) : (
              '1'
            )}
          </span>
        </StepNumber>
        <StepName>Тип и маркет-плейс</StepName>
      </Step>
      <Step onClick={() => handleChangeStep(2)}>
        <StepNumber $isActive={step >= 2}>
          <span>
            {step > 2 ? (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            ) : (
              '2'
            )}
          </span>
        </StepNumber>
        <StepName>Информация</StepName>
      </Step>
      <Step onClick={() => handleChangeStep(3)}>
        <StepNumber $isActive={step >= 3}>
          <span>
            {step > 3 ? (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            ) : (
              '3'
            )}
          </span>
        </StepNumber>
        <StepName>Логистика</StepName>
      </Step>
      <Step onClick={() => handleChangeStep(4)}>
        <StepNumber $isActive={step >= 4}>
          <span>
            {step > 4 ? (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            ) : (
              '4'
            )}
          </span>
        </StepNumber>
        <StepName>Характеристики</StepName>
      </Step>
      <Step onClick={() => handleChangeStep(5)}>
        <StepNumber $isActive={step >= 5}>
          <span>
            {step > 5 ? (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            ) : (
              '5'
            )}
          </span>
        </StepNumber>
        <StepName>Медиа</StepName>
      </Step>
    </Wrapper>
  )
}
