import { History } from 'domains/history'
import { createBrowserHistory } from 'history'

const customHistory = createBrowserHistory({
  basename: '/',
  forceRefresh: !('pushState' in window.history),
})

export const browserHistory = new History({
  currentLocation: customHistory.location.pathname,
  push: customHistory.push,
  getHistory: () => customHistory,
})
