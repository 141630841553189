import React, { useState } from 'react'
import { generatePath } from 'react-router'
import { Link, useParams } from 'react-router-dom'
import { Grid, Button, Tabs, Tab, Box } from '@mui/material'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { TabPanel, InputLabel } from 'shared/ui/components'
import { GOODS_VARIANT } from 'shared/config'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { pimApi } from 'shared/api'
import {
  BottomButtonWrapper,
  TabsNavWithMargin,
  BottomSection
} from 'pages/goods/components/common'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { CodesForm } from './components/CodesForm'
import { PackagingForm } from './components/PackagingForm'

interface DeliveryStepProps {
  handleNext: () => void;
  handlePrev: () => void;
  suppliers: Array<pimApi.goods.GoodsSupplier>;
  isArchived?: boolean;
}

export const DeliveryStep = ({ suppliers, handleNext, handlePrev, isArchived }: DeliveryStepProps) => {
  const [tabShown, setTabShown] = useState(0)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)

  const hasAttributesForVariation = isNotEmptyArray(goodsAttributesForVariationsQuery.data)

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabShown(newValue)
  }

  const handleSaveNext = () => {
    handleNext()
  }
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  return (
    <>
      <div>
        {suppliers.length > 1 && (
          <TabsNavWithMargin>
            <Tabs
              value={tabShown}
              onChange={handleChangeTab}
            >
              {suppliers.map((supplier, index) => supplier?.contragent && (
                <Tab key={supplier.id} label={supplier?.contragent} {...a11yProps(index)} />
              ))}
            </Tabs>
          </TabsNavWithMargin>
        )}
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <InputLabel single={true} label="Код товара поставщика" />
          </Grid>
          {suppliers.map((supplier, contrIndex) => supplier && (
            <TabPanel key={supplier.id} value={tabShown} index={contrIndex}>
              <CodesForm supplier={supplier} disabled={isArchived} />
              <Grid item={true} xs={12}>
                <Box display="inline-flex" ml={2}>
                  <InputLabel label="Информация по упаковке" />
                </Box>
              </Grid>
              <PackagingForm supplier={supplier} disabled={isArchived}/>
            </TabPanel>
          ))}
        </Grid>
      </div>
      <BottomSection>
        <BottomButtonWrapper>
          <div>
            <Button color="primary" onClick={handlePrev}>
              НАЗАД
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSaveNext}
              disabled={isArchived}
            >
              СОХРАНИТЬ И ДАЛЕЕ
            </Button>
          </div>
          <div>
            {hasAttributesForVariation && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generatePath(GOODS_VARIANT, { id: goodsId })}
              >
                ДОБАВИТЬ ВАРИАЦИЮ
              </Button>
            )}
            <Box display="inline-flex" pl={2}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleOpenCreateModal}
                disabled={isArchived}
              >
                СОЗДАТЬ ПРОДУКТ
              </Button>
              <ProductCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} />
            </Box>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
