import { useEffect } from 'react'

import { useGetHeaderQuery } from '../../../api/version/getHeader'
import { isNotNil } from '../../../lib/checkers'
import { APP_VERSION as LOCAL_APP_VERSION } from '../../../config'
import { snackActions } from '../../../lib/react/snackbar'

export const VersionControl = () => {
  const { data: appVersion, isFetching } = useGetHeaderQuery()
  const notify = () => snackActions.info('Вышло обновление, обновите страницу')
  
  useEffect(() => {
    if (!isFetching) {
      if (isNotNil(appVersion?.['x-version'])) {
        if (Number(appVersion['x-version']) !== 0) {
          if (appVersion['x-version'] !== LOCAL_APP_VERSION) {
            notify()
          }
        }
      }
    }
  },[isFetching])
  return null
}