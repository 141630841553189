import React from 'react'

import { StyledTextContainer } from '../../styled'

export const Photos360Requirements = () => {
  const Text = 'Формат — JPEG, JPG, PNG. Размер — не больше 1 Мбайт'
  const Element = (<StyledTextContainer>
    <ul>
      <li>формат — JPEG, JPG, PNG</li>
      <li>максимальный размер каждого изображения — до 1 Мбайт, рекомендуемый – 200-300 Кбайт</li>
      <li>минимальное количество — 15</li>
      <li>максимальное количество — 70</li>
      <li>рекомендуемое количество – от 36 до 60</li>
    </ul>
  </StyledTextContainer>
  )
  return { Text, Element }
}