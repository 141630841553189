import { AxiosError, AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'
import { pimApi } from 'shared/api'

export function getCommonProductSearch(
  goodsId?: UniqueId,
  companymarketplaceId?: UniqueId
): Promise<AxiosResponse<pimApi.products.common.Product>> {
  return api.get<pimApi.products.common.Product>(
    `/pim/products/common/search?goods_id=${goodsId}&companymarketplace_id=${companymarketplaceId}`
  )
}

type QuerySearchKey = ReturnType<typeof getQuerySearchKey>

type QueryKey = ReturnType<typeof getFetchCommonProductQueryKey>

export const getFetchCommonProductQueryKey = (productId: UniqueId) =>
  ['get', '/products/common/product', productId] as const

const fetchCommonProduct = (context: QueryFunctionContext<QueryKey>) =>
  api
    .get<pimApi.products.common.Product>(
      `/pim/products/common/product/${context.queryKey[2]}`
    )
    .then((res) => res.data)

const getQuerySearchKey = (
  productId?: UniqueId,
  companyMarketplaceId?: UniqueId,
) =>
  [
    'get',
    '/products/common/search',
    productId,
    companyMarketplaceId,
  ] as const

export const useFetchCommonProductQuery = (productId: UniqueId) =>
  useQuery<
    pimApi.products.common.Product,
    errorType,
    pimApi.products.common.Product,
    QueryKey
  >(getFetchCommonProductQueryKey(productId), fetchCommonProduct, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

export const useFetchCommonProductSearchQuery = ({
  goodsId,
  companymarketplaceId,
  options,
}: {
  goodsId?: UniqueId
  companymarketplaceId?: UniqueId
  options?: UseQueryOptions<
    pimApi.products.common.Product,
    AxiosError<errorType>,
    pimApi.products.common.Product[],
    QuerySearchKey
  >
}) =>
  useQuery<
    pimApi.products.common.Product,
    AxiosError<errorType>,
    pimApi.products.common.Product[],
    QuerySearchKey
  >(
    getQuerySearchKey(goodsId, companymarketplaceId),
    (context) =>
      pimApi.products.common
        .getCommonProductSearch(
          context.queryKey[2] as UniqueId,
          context.queryKey[3]
        )
        .then((res) => res.data),
    {
      enabled: !!goodsId,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    }
  )
