import { generatePath, useHistory } from 'react-router-dom'
import { PriceCard, PriceCardProps } from 'entities/pim/price'
import { DialogContentWithTitle, dialogModel } from 'shared/ui/components/dialog'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { PRODUCT_PRICE_HISTORY } from 'shared/config'
import { useQueryClient } from 'react-query'
import queryString from 'query-string'
import debounce from 'lodash/debounce'




interface PriceCardWithMenuProps extends PriceCardProps {
  productId: number
  readOnly?: boolean
  id?: number
  versionNo?: number
  name?: string
  onRemove?: () => void
  onAdd?: (data: pimApi.products.SetOzonPriceInfo) => void
}

const getTabPosition = () => (Number(document.getElementById('ScrollToPriceControlOZON')?.offsetTop) - 56) 

const debouncedScrollToBottom = debounce(() => window.scrollTo({ top: getTabPosition(), behavior: 'smooth' }), 200)

export const PriceCardWithMenu = ({
  id,
  versionNo,
  discount,
  priceRecentHistory,
  price,
  oldPrice,
  productId,
  readOnly,
  name,
  onRemove,
  onAdd,
  ...props
}: PriceCardWithMenuProps) => {
  const history = useHistory()
  const hasHistory = !!priceRecentHistory?.length
  const queryClient = useQueryClient()

  const { mutate: deleteProductPrice } = pimApi.products.ozon.useDeleteProductPriceMutation()

  const showPriceHistory = () => {
    const path = `${generatePath(PRODUCT_PRICE_HISTORY, { id: productId })}`
    history.push(path)
  }

  const editPrice = () => {
    const historyStr = queryString.stringify(
      { ...{ tab: 'price' } },
      { skipEmptyString: true, skipNull: true }
    )
    history.replace(`?${historyStr}`)
    debouncedScrollToBottom()
  }

  const deletePrice = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <DialogContentWithTitle
            close={close}
            accept={accept}
            title="Вы уверены, что хотите удалить это запланированное изменение цены?"
            acceptBtnText="ПОДТВЕРДИТЬ"
          />,
        onAccept: () => {
          deleteProductPrice(
            {
              productId,
              priceId: id,
              data: {
                id: id?.toString() || '',
                versionNo,
              }
            },
            {
              onSuccess: () => {
                queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(pimApi.products.ozon.getOzonProductQueryKey(productId),
                  (updater) => ({
                    ...updater,
                    futurePrices: updater?.futurePrices?.filter(el => el.id !== id)
                  } as pimApi.products.ozon.OzonProduct)
                )
                snackActions.info('Цена успешно удалена!')
              }
            }
          )
        },
      }
    )
  }

  return (
    <PriceCard
      priceRecentHistory={priceRecentHistory}
      price={price}
      showPriceHistory={!hasHistory ? undefined : showPriceHistory}
      editPrice={((!hasHistory && !!price) || readOnly) ? undefined : editPrice}
      deletePrice={(hasHistory || !price || readOnly) ? undefined : deletePrice}
      oldPrice={oldPrice}
      discount={discount}
      {...props}
    />
  )
}
