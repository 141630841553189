import { CircularProgress } from '@mui/material'
import React from 'react'
import { useSSE } from 'react-hooks-sse'
import { round } from 'shared/lib/utils/round'

import { StyledImportProgress } from '../styled'

export const ImportProgress = () => {
  const importSupplyingStep = useSSE<any | null>('importsupplyingstep', null)
  const progress = importSupplyingStep ? Number(round(importSupplyingStep.current/ (importSupplyingStep.total/100), 0)) : 0
  return (
    <StyledImportProgress>
      {progress !== 100 &&
        <>
          <CircularProgress className="custom-progress-bar" size={33} variant="determinate" value={progress}/>
          <span className="progress-text">{progress}%</span>
        </>
      }
    </StyledImportProgress>
  )
}