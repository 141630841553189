import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { tradeApi, user } from 'shared/api'
import { VirtualTableDX } from 'shared/ui/components/Table'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { columnsConfig } from 'features/supplyOrders/EditSupplyOrderImplement/model/tableColumnsConfig'
import { TableParams } from 'shared/ui/components/Table/model/types'
import { changeColumnConfig, tableParams } from 'features/supplyOrders/lib/tableParamsSetting'
import CustomInput from 'features/supplyOrders/ui/CustomInput'
import { useElementSize } from 'shared/lib/hooks/useElementSize'

import { StyledTableBox, StyledLoader, TableWrapper } from '../styled'
import { summaryProperty } from '../SummaryColumnItem/SummaryProperty'

const getRowId = (row: tradeApi.SupplyOrderActualPosition) => row.positionNumber

const EditSupplyOrderImplementTable = () => {
  const { supplyOrderQuery, tabsSettingObject, inTabsSettingHandler  } = useSupplyOrderContext()

  const memoTableParams = useMemo(
    () => tableParams(tabsSettingObject, 'implementorders'),
    [supplyOrderQuery?.data, tabsSettingObject?.inTabsObjectInit]
  )

  const [refTableContainer, width, ] = useElementSize()

  const { data: userSettingsQuery, isLoading: settingsLoading } =
    user.settings.useFetchUserSettingsQuery()
  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns =
    userSettingsQuery?.data?.editSupplyOrderTableHiddenColumns

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          editSupplyOrderTableHiddenColumns: hiddenColumns,
        },
      })
    },
    [userSettingsQuery?.data]
  )

  useEffect(()=>{
    const filteredData: Array<any> = []

    // eslint-disable-next-line array-callback-return
    tableData?.map(data => {
      if (data.goodsName.toLowerCase().includes(memoTableParams.searchString.toLowerCase())) {
        const filteredProduct = tableData?.find(e => e.goodsId === data.goodsId)
        filteredData?.push(filteredProduct)
      }
    })

    if (memoTableParams.searchString.length) {
      setFilteredTableData(filteredData)
    } else setFilteredTableData(tableData)

  }, [memoTableParams])

  const changeColumnHandler = (params: TableParams) => {
    changeColumnConfig(params, inTabsSettingHandler, 'implementorders')
  }
    
  const tableData = supplyOrderQuery.data?.actualpositions
  const [filteredTableData, setFilteredTableData] = useState<any>(supplyOrderQuery.data?.actualpositions)

  const summaryRows = summaryProperty(tableData)

  return (
    <>
      <StyledTableBox ref={refTableContainer} component={Box}>
        {(supplyOrderQuery.isLoading ||
          supplyOrderQuery.isFetching ||
          settingsLoading) && <StyledLoader size={60} />}
        {tableData && userSettingsQuery?.data && (
          <TableWrapper>
            <VirtualTableDX
              tableList={filteredTableData}
              getRowId={getRowId}
              handleTableSettingsChange={handleTableSettingsChange}
              tableHiddenColumns={tableHiddenColumns}
              tableParams={memoTableParams}
              columnsConfig={columnsConfig}
              showInArchiveControl={false}
              showColumnsVisibility={true}
              searching="internal"
              sorting="internal"
              columnsForSummary={summaryRows}
              changeColumnConfig={changeColumnHandler}
              isStoredSettings={true}
              customSearchInput={CustomInput}
              tableWidth={width}
            />
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoEditSupplyOrderImplementTable = memo(EditSupplyOrderImplementTable)
