import { AxiosResponse, AxiosError, ResponseType } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { copackingApi } from 'shared/api'
import snakeCase from 'lodash/snakeCase'

interface PatchCopackingOrderPayload {
  copackingorderId?: UniqueId,
  patchBody: {
    action?: string
    versionNo?: number,
    comments?: string,
    file?: Blob
  },
  responseType?: ResponseType
}

export const patchCopackingOrder = ({ copackingorderId, patchBody, responseType }: PatchCopackingOrderPayload) => {
  const body = new FormData()

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(patchBody)) {
    // @ts-ignore
    body.append(snakeCase(key), value || '')
  }
  return api.patch<copackingApi.CoPackingOrder>(
    `/copacking/copackingorders/${ copackingorderId }`,
    body, { responseType }
  )
}



export const usePatchCopackingOrderMutation = () =>
  useMutation<
    AxiosResponse<copackingApi.CoPackingOrder>,
    AxiosError<errorType>,
    PatchCopackingOrderPayload
  >(patchCopackingOrder)