import React, { useState } from 'react'
import { DateFormats, RuDateFormats } from 'shared/config/dateFormats'
import { DateTimePicker as MuiDatePicker } from '@mui/lab'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import { TextField } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

import { BaseInputFieldI } from '../TextInput/TextInputI'

interface IFormDatePicker<T> extends FieldRenderProps<T>, BaseInputFieldI  {
  label?: string
}

const toISOStringWithTimezone = (date: Date) => {
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0')

  return `${date.getFullYear()
  }-${  pad(date.getMonth() + 1)
  }-${  pad(date.getDate())
  }T${  pad(date.getHours())
  }:${  pad(date.getMinutes())}:00`
}

const InputLabelProps = { shrink: true }

const FormDateTimePicker = <T extends {}>({
  label,
  value,
  disabled,
  isError,
  ...rest
}: IFormDatePicker<T>) => {

  const [selectedDate, setSelectedDate] = useState<T | string>(rest.input.value)

  const selectDateChange = (date) => {
    const result = toISOStringWithTimezone(date)
    setSelectedDate(result)
    rest.input.onChange(result)
  }

  const handleDateChange = (date) => {

    if (date && date.toString() !== 'Invalid Date') {
      const result = toISOStringWithTimezone(date)
      setSelectedDate(result)
      rest.input.onChange(result)
    } else {
      setSelectedDate(date)
      rest.input.onChange(date)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <MuiDatePicker
        value={selectedDate}
        label={label}
        disabled={disabled}
        inputFormat={DateFormats.dayMonthYearHoursMinutes}
        mask="__.__.____ __:__"
        onChange={handleDateChange}
        onAccept={selectDateChange}
        renderInput={(renderProps) => (
          <TextField
            {...renderProps}
            size="small"
            autoComplete="off"
            {...rest}
            InputLabelProps={InputLabelProps}
            error={isError || (Boolean(rest.error))}
            helperText={rest.error && rest.helperText}
            inputProps={{
              ...renderProps.inputProps,
              placeholder: RuDateFormats.dayMonthYearHoursMinutes
            }}
          />
        )} />
    </LocalizationProvider>
  )
}

export default FormDateTimePicker
