import * as yup from 'yup'

export const validationSchema = yup.object({
  // name: yup.string().required(),
  // categoryId: yup.object().shape({
  //   fullPathName: yup.string().required(),
  //   id: yup.number().required(),
  // }).required(),
  // brandId: yup.number().required(),
  // companyId: yup.number().required(),
  // code: yup.string().required(),
  // auxCode: yup.string(),
  // unitId: yup.string(),
  // description: yup.string(),
  // width: yup.number().min(0),
  // height: yup.number().min(0),
  // depth: yup.number().min(0),
  // weight: yup.number().min(0),
  // suppliers: yup.array().of(
  //   yup.object().shape({
  //     contragent_id: yup.number().required(),
  //     incomingPrice: yup.number().required(),
  //   }).nullable()
  // ),
  // tnvedCode: yup.object().shape({
  //   code: yup.string().required(),
  //   name: yup.string().required(),
  // }).required(),
  // madeInCountryId: yup.number().required(),
  // vatRate: yup.string().required()
  // tnvedCode: yup.number().min(0),
  // shipping: yup.object({
  //   name: yup.string(),
  //   phone: yup.object({
  //     code: yup.string().matches(/^\+\d+$/i),
  //     number: yup.number().max(10),
  //   }),
  //   address: yup.string(),
  //   zip: yup.string(),
  // }),
  // billing: yup.object({
  //   name: yup.string(),
  //   address: yup.string(),
  //   zip: yup.string(),
  // }),
  // items: yup.array().of(
  //   yup.object({
  //     id: yup.number(),
  //     price: yup.number(),
  //     quantity: yup.number(),
  //   })
  // ),
})