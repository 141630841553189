import React, { memo } from 'react'
import { pimApi } from 'shared/api'

import { CompetitorItem } from './CompetitorItem'

import { CompetitorsContainer } from '../styled'

export const CompetitorsList = memo(({
  competitorsData,
  handleEditCompetitor 
}: { competitorsData: pimApi.goods.Competitor[], handleEditCompetitor: (e) => void }) => {
  const reducedStocksCompetitors = competitorsData.map(el => ({
    ...el,
    totalStocks: el?.stocks?.reduce((sum, elem) => sum + elem.available, 0)
  }
  ))

  const sortedCompetitors = reducedStocksCompetitors
    .sort((a, b) => {
      if (a.status === -1) {
        return -1
      }
      if (b.status === -1) {
        return 1
      }
      if (b.reckonedFlag === false) {
        return 1
      }
      if (a.reckonedFlag === false) {
        return -1
      }
      if (a.totalStocks! < b.totalStocks!) {
        return -1
      }
      if (a.totalStocks! > b.totalStocks!) {
        return 1
      }
      return 0
    }).reverse()

  return (
    <CompetitorsContainer>
      {sortedCompetitors.map((competitor) => (
        <CompetitorItem key={competitor.id} competitor={competitor} handleEditCompetitor={handleEditCompetitor}/>
      ))}
    </CompetitorsContainer>
  )
}
)