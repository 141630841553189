import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'

import { api } from '../../base'


const deleteCategory = (value: pimApi.DeletingObject) =>
  api.delete(
    `/pim/categories/${value.id}`,
    { data: value }
  )


export const useDeleteCategoryMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    pimApi.DeletingObject
  >(deleteCategory)