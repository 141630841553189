import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import { tradeApi } from 'shared/api'
import { useOpenedStreamsContext } from 'shared/ui/components/StreamEvents/context/OpenedStreamsContext'

import { ImportProgress } from './ImportProgress'

import { UploadWrapper } from '../styled'

const FILE_EXTENSION =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const ImportButton = ({ isLoading }: { isLoading: boolean } ) => {
  const { value: context } = useOpenedStreamsContext()
  const { mutate: uploadMutation } = tradeApi.stocks.useUploadGoodsSupplyingFile()
  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      context.changeStreamState({ goodsSupplyingStream: true })
      uploadMutation({ file }, {
        onError: () => {
          context.changeStreamState({ goodsSupplyingStream: false })
        }
      })
    }
    // Чтобы очищать значение инпута и можно будет закидывать одинаковые файлы
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }
  return (
    <UploadWrapper>
      <Box gap="16px" display="flex" alignItems="center" justifyContent="center">
        {context.openedStreams.goodsSupplyingStream &&
          <ImportProgress/>
        }
        <label htmlFor="excel-file">
          <input
            accept={FILE_EXTENSION}
            id="excel-file"
            type="file"
            onChange={uploadFile}
            disabled={isLoading || context.openedStreams.goodsSupplyingStream}
          />
          <LoadingButton
            sx={{ height: '36px' }}
            color="primary"
            variant="contained"
            disabled={isLoading || context.openedStreams.goodsSupplyingStream}
            component="span"
            startIcon={<UploadIcon />}
          >
            ИМПОРТ
          </LoadingButton>
        </label>
      </Box>
    </UploadWrapper>
  )
}