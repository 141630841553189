/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyStocksPatchStocksExcel, ImportMetadata, Method } from 'shared/lib/generated/wms/Api'

export type ResponseType = CamelCasedPropertiesDeep<ImportMetadata>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
    /** Действие */
    method: Method,
    method_param?: string,

  },
  data: BodyStocksPatchStocksExcel
}>

export const apiFunction = ({ query,data }: IRequest) => {
  const body = new FormData()
  body.append('file', data.file || '')

  return api.patch<ResponseType>(`/wms/stocks/excel`, body, { params: query }).then(res => res.data)
}


export const usepatchStocksStocksPatch = () =>
  useMutation<
    ResponseType,
    errorType,
    IRequest
  >(apiFunction)

