import { pimGen } from 'shared/lib/generated'
import { PriceCard } from 'entities/pim/price'
import { Skeleton } from '@mui/material'

import { PriceWrapper, SkeletonWrapper } from '../styled'

export const WbProduct = ({ row }) => {
  const { data: priceData } = pimGen.wildberriesProduct.GetPrice.useGetPrice({ productId: row.productId })
  
  if (priceData) return (
    <PriceWrapper>
      <PriceCard
        lastChangeDate={priceData.spp?.lastChangeDate}
        price={priceData.price}
        sppValue={priceData.spp?.value}
        readOnly={false}
        discount={priceData.discount}
      />
    </PriceWrapper>
  )

  return (
    <SkeletonWrapper>
      <Skeleton
        sx={{ bgcolor: '#D3D3D360' }}
        animation="wave"
        variant="rounded"
      />
    </SkeletonWrapper>
  )
}