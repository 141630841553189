import { DOC_LIST } from 'shared/config/routes'
import React, { useEffect, useRef } from 'react'
import { prop } from 'ramda'
import styled from 'styled-components'
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle, Preloader } from 'shared/ui/components'
import { Breadcrumbs, Link, Typography, Paper, Button } from '@mui/material'
import usePending from 'shared/lib/hooks/PendingHook'
import { dialogModel } from 'shared/ui/components/dialog'
import { docDetail as docDetailAction, docSelector, initSocketListener } from 'store/doc'
import { docStatus } from 'shared/services/interfaces/doc'
import { getDateFromIso } from 'shared/lib/utils/dateToString'
import AddIcon from '@mui/icons-material/Add'

import { Docs } from './Docs'

import { DocDetailLocationState } from '../interfaces'
import { HeaderWrapper } from '../DocList/Table/style'
import { AddGoodsToDocModal } from '../DocList/Modal/AddGoodsToDocModal'


const BreadcrumbsStyled = styled(Breadcrumbs)`
  font-size: inherit;
  p{
    font-size: inherit;
  }
`
interface RouteParams {
  id: string
}
// ms * sec * min
const fifteenMinutesInterval = 1000 * 60 * 15

export const WarehouseDetail = () => {
  const dispatch = useDispatch()
  const { id } = useParams<RouteParams>()
  const { state } = useLocation<DocDetailLocationState>()
  const [getDocDetail, isDocDetailLoading] = usePending(docDetailAction)
  const document = useSelector(docSelector)
  const docDetail = prop('detail', document)
  const socketStarted = useRef(false)
  const interval = useRef<null | ReturnType<typeof setInterval>>(null)

  useEffect(() => { getDocDetail(id) }, [])
  useEffect(() => {
    const isStarted = docDetail?.status === docStatus.started

    if (!socketStarted.current && isStarted) {
      interval.current = setInterval(() => {
        dispatch(initSocketListener(id))
      }, fifteenMinutesInterval)

      dispatch(initSocketListener(id))
      socketStarted.current = true
    } else if (!isStarted) {
      socketStarted.current = false
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      socketStarted.current = false
    }
  }, [docDetail])

  const backLinkTo = state?.docListFilterQuery ? `${DOC_LIST}?${state.docListFilterQuery}` : DOC_LIST

  const handleAddGoods = () => {
    dialogModel.openDialog(
      {
        component: ({ close }) =>
          <AddGoodsToDocModal docId={Number(id)} close={close}/>,
      }
    )
  }
  return (
    <div>
      <PageTitle>
        <HeaderWrapper>
          <BreadcrumbsStyled aria-label="breadcrumb">
            <Link color="primary" component={RouterLink} to={backLinkTo}>
              Приход на склад
            </Link>
            <Typography color="textPrimary">Приемка товара по документу № {docDetail && docDetail.docNumber}
            &nbsp;от {getDateFromIso(docDetail.docDate, '.')}</Typography>
          </BreadcrumbsStyled>

          {docDetail.status === 'new' &&
          <Button startIcon={<AddIcon/>} onClick={handleAddGoods} variant="contained" size="small">
            Добавить товары
          </Button>
          }

        </HeaderWrapper>
      </PageTitle>
      <Paper>
        {!isDocDetailLoading ? <Docs /> : <Preloader />}
      </Paper>
    </div>
  )
}