/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewUserInfo, UserInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserInfo>

export type IRequest = CamelCasedPropertiesDeep<{
  data: NewUserInfo
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/customer/admin/users`,data).then(res => res.data)


export const useCreateUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

