import styled from 'styled-components'
import { Box, CircularProgress } from '@mui/material'

export const StyledTooltip = styled.div`
  min-width: 148px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 30px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
`
export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: 320px;
  left: calc(50%);
  z-index: 1;
`
export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`

export const StyledLegendContainer = styled(Box)`
  width: 206px;
  height: 88px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  gap: 10px;
  padding-top: 26px;
  
  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54)
    }
  }
`

export const SelectWrapper = styled.div<{ width: number }>`
  .MuiAutocomplete-root {
    min-width: ${ ({ width }) => `${ width }px` };
    color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

    .MuiFormLabel-root {
      position: absolute;
      top: -2px;
    }

    .MuiAutocomplete-inputRoot {
      height: 36px;
      padding-bottom: 3px;
      padding-top: 1px;
      padding-right: 30px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 4px;
    }

    .MuiInputBase-root {
      border: 0 solid rgba(0, 0, 0, 0.0);
    }
  }

  input {
    ::placeholder {
      color: rgba(0, 0, 0, 0.60);
      opacity: 1;
    }
  }
`

export const StyledLegend = styled(Box)`
  cursor: pointer;
  padding: 4px 10px 4px 4px;
  border-radius: 4px;

  :hover {
    background-color: #FAFAFA;
  }
`