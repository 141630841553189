import { copackingApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName = keyof copackingApi.CoPackingOrderPosition
type ColumnNames = Record<ColumnName, ColumnName>

const columns: ColumnNames = {
  id: 'id',
  externalId: 'externalId',
  changer: 'changer',
  created: 'created',
  operations: 'operations',
  detail: 'detail',
  expectedQuantity: 'expectedQuantity',
  quantity: 'quantity',
  productId: 'productId',
  changed: 'changed',
  versionNo: 'versionNo',
  packagingrequirement: 'packagingrequirement',
  packagingrequirementId: 'packagingrequirementId'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.expectedQuantity,
      columnName: columns.expectedQuantity,
      title: 'Кол-во\nв\nплане',
      width: 78,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.RoundedNumber
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'Выполнено',
      width: 110,
      sortingEnabled: false,
      cellType: CellType.PositionsEditQuantity
    },
    {
      name: columns.externalId,
      columnName: columns.externalId,
      title: 'Раз\nница',
      width: 79,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PositionsDiff
    },
    {
      name: columns.detail,
      columnName: columns.detail,
      title: 'Артикул',
      width: 90,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PositionsVendorCode,
    },
    {
      name: columns.id,
      columnName: columns.id,
      title: 'Фото',
      width: 70,
      align: 'center',
      sortingEnabled: false,
      cellType: CellType.Photo,
    },
    {
      name: columns.changed,
      columnName: columns.changed,
      title: 'Наименование\nпродукта',
      width: 150,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PositionsProductLink
    },
    {
      name: columns.changer,
      columnName: columns.changer,
      title: 'ШК',
      width: 90,
      sortingEnabled: false,
      cellType: CellType.PositionsBarcode,
      wordWrapEnabled: true
    },
    {
      name: columns.versionNo,
      columnName: columns.versionNo,
      title: 'Требования\nк упаковке',
      width: 'auto',
      sortingEnabled: false,
      cellType: CellType.PackagingRequirement
    },
  ],
}
