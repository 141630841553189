import React, { useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { Button, DialogActions, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Option } from 'shared/ui/components/interface'
import { pimApi } from 'shared/api'
import { SelectFieldWithAutoComplete } from 'shared/ui/components'
import { useCompanyList, useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'

import { getOptions } from '../../../bulkOperations/bulkProductCreation/lib/getSelectFieldOptions'
import { MemoGoodsSearchSection } from '../../../ProductChangeGoodsModal/ui/GoodsSearchSection'

interface Props {
  handleSubmit: (e) => void
  ProductCopyOptions: Array<Option>
  close: () => void
  commonProductData: pimApi.products.common.Product;
  isLoading: boolean
  valid: boolean
}

export const ProductCopyForm = ({ handleSubmit, ProductCopyOptions, close, isLoading, valid, commonProductData }: Props) => {
  const productCopy = useField('productCopy').input.value
  const companyId = useField('companyId').input.value
  
  const { companyListQuery } = useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(1, companyId || commonProductData.companyId)

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data]
  )

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Field defaultValue={ProductCopyOptions[0].value} name="productCopy" component={(props) => (
        <RadioGroup
          name="productCopy"
          sx={{ display: 'flex', gap: '10px', marginTop: '24px', marginBottom: '24px' }}
          row={true}
          value={props.input.value}
          onChange={(event, value) => {
            props.input.onChange(value)
          }}
        >
          {ProductCopyOptions.map(el => (
            <FormControlLabel value={el.value} control={<Radio size="small"/>} label={el.label}/>
          ))}
        </RadioGroup>
      )}/>
      { productCopy === 'newCard' &&
        <Grid container={true} rowGap={3} mt={3} columnSpacing={{ sm: 2, md: 2 }}>
          <Grid item={true} xs={6}>
            <Field
              name="companyId"
              id="companyId"
              label="Продавец"
              required={true}
              errorAfterTouch={true}
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              options={companyOptions}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <Field
              name="companymarketplaceId"
              id="companymarketplaceId"
              label="Кабинет"
              required={true}
              errorAfterTouch={true}
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              disabled={companymarketplaceOptions.length === 0}
              options={companymarketplaceOptions}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MemoGoodsSearchSection />
          </Grid>
        </Grid>
      }
      <DialogActions sx={{ padding: '0' }}>
        <Button onClick={close}>ОТМЕНА</Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !valid}
        >
          СОЗДАТЬ
        </Button>
      </DialogActions>
    </form>
  )
}