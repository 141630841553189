import React from 'react'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

export const TreeItemLabel = ({ node }) => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })

  const { areaId } = searchObj

  const handleNodeSelect = (nodeId) => {
    handleChangeParams({ params: {
      areaId: nodeId === areaId ? null : nodeId,
    }, options: { skipNull: true, skipEmptyString: true } })
  }

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
  return <span
    onClick={(e) => {
      e.stopPropagation()
      handleNodeSelect(node.id)
    }}>({ node.code }) { node.name }</span>
}