import { useQueryClient } from 'react-query'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { Option } from 'shared/ui/components/interface'
import { ArchiveModalForm } from 'shared/ui/components/ArchiveModal'

interface ArchiveModalProps {
  close: () => void
  accept: () => void
  goods: pimApi.goods.Goods
}

interface OptionItem {
  name?: string
  id: number
  commentsRequired?: boolean
}


export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
  commentsRequired: optionItem.commentsRequired,
})


export const ArchiveModal = ({ close, goods }: ArchiveModalProps) => {
  const queryClient = useQueryClient()
  
  const { mutate: archiveGoods, isLoading } =
    pimApi.goods.useArchiveGoodsMutation()
  

  const onSubmit = (formValues) => {
    const archiveGoodsPayload = {
      goodsId: goods.id,
      archivationInfo: {
        id: goods.id,
        versionNo: goods.versionNo,
        archivationreasonId: formValues.archivationReasonId,
        archivationComments: formValues.archivationComments,
      },
    }

    archiveGoods(archiveGoodsPayload, {
      onSuccess: ({ data }) => {
        close()
        snackActions.info('Сохранено!')
        queryClient.setQueryData(getGetPimGoodsQueryKey(goods.id), data)
      },
    })
  }

  return (
    <ArchiveModalForm onSubmit={onSubmit} close={close} isLoading={isLoading}/>
  )
}
