import { GOODS_VARIANT } from 'shared/config/routes'
import { useMemo, useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { generatePath } from 'react-router'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { BottomButtonWrapper, BottomSection } from 'shared/ui/goods/common'
import { useGoodsProductsQuery } from 'shared/api/pim/goods/getGoodsProducts'
import { pimApi } from 'shared/api'
import { MemoComponentCard } from 'entities/pim/goods/ui/GoodsCard/ComponentCard'

interface ProductStepProps {
  handlePrev: () => void
  handleNext: () => void
  goodsType: pimApi.goods.GoodsType
  isArchived?: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const ProductsStep = ({ handlePrev, handleNext, goodsType, isArchived } : ProductStepProps) => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const { data: goodsProducts } = useGoodsProductsQuery(goodsId)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const marketplaceList = useMemo(() => {
    const resultList: Array<string> = []

    goodsProducts?.forEach((goodsProduct) => {
      if(!resultList.includes(goodsProduct.marketplaceName)) {
        resultList.push(goodsProduct.marketplaceName)
      }
    })
    return resultList
  }, [goodsProducts])

  const hasAttributesForVariation = goodsAttributesForVariationsQuery.data?.length !== 0

  return (
    <>
      <Box mb={2}>
        {marketplaceList?.map((marketplace) => (
          <div key={marketplace}>
            <Box mb={2}>
              <Typography fontWeight={500}>
                {marketplace}
              </Typography>
            </Box>
            <Box mb={4}>
              {goodsProducts?.map((goodsProduct) => {
                const fileThumbnailSrc = pimApi.products.getFileThumbnailApiPath(
                  goodsProduct.id,
                  fileThumbnailSize
                )
                return (
                  goodsProduct.marketplaceName === marketplace) && (
                  <Box mb={2} key={goodsProduct.id}>
                    <MemoComponentCard
                      imgUrl={fileThumbnailSrc}
                      goodsType={goodsType}
                      name={goodsProduct?.name}
                      marketplaceUrl={goodsProduct?.marketplaceUrl}
                      marketplaceCode={goodsProduct?.marketplaceCode}
                      cabinetName={goodsProduct?.cabinetName}
                      price={goodsProduct?.price}
                      productId={goodsProduct?.id}
                      marketplaceCategory={goodsProduct?.marketplaceCategory}
                      barcode={goodsProduct?.barcode}
                      disabled={goodsProduct?.inArchive}
                      companyName={goodsProduct.companyName}
                      autoOrdering={goodsProduct.autoOrdering}
                    />
                  </Box>
                )
              })}
            </Box>
          </div>
        ))}
      </Box>
      <BottomSection>
        <BottomButtonWrapper>
          <div>
            <Button color="primary" onClick={handlePrev}>
              НАЗАД
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isArchived}
            >
              ДАЛЕЕ
            </Button>
          </div>
          <div>
            {goodsId && hasAttributesForVariation && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={generatePath(GOODS_VARIANT, { id: goodsId })}
                disabled={isArchived}
              >
                ДОБАВИТЬ ВАРИАЦИЮ
              </Button>
            )}
            <Box display="inline-flex" pl={2}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleOpenCreateModal}
                disabled={isArchived}
              >
                СОЗДАТЬ ПРОДУКТ
              </Button>
              <ProductCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} />
            </Box>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
