import { Field, useFormState } from 'react-final-form'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { InputTextWrapper } from 'features/product/common/CompetitorsTab/ui/styled'
import styled from 'styled-components'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { DefaultValue } from 'features/product/common/CompetitorsTab/ui/priceAdviceSettings/DefaultValue'
import { ParametersArray } from 'shared/ui/components/DataType/ParametersArray'

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 16px;

  > div {
    width: calc(50% - 16px);

    .MuiInputBase-root {
      height: 28px;
    }
    
    .MuiInputBase-input {
      padding: 0 8px;
    }

    .MuiInputLabel-root {
      font-size: 14px;
      top: -6px  // Настройка вертикального выравнивания label
    };

    .MuiInputLabel-shrink {
      transform: translate(17px, -3px) scale(0.75);  // Смещение и уменьшение label при фокусе
    }
  }
`

export const ParametersCell = ({ settings, row } ) => {
  const { errors } = useFormState()

  const parameters = settings.algorithm?.parameters
  return (
    <FieldsWrapper>
      
      { parameters?.map(el => (
        <div>
          <InputTextWrapper key={el.id}>
            <Field
              {...el.dataType === 1 && { type: 'number' }}
              {...el.dataType === 6 && { type: 'checkbox' }}
              component={ ParametersArray[el.dataType].component }
              error={ !!errors?.[`id${ el.id }`] }
              fullWidth={ true }
              size="small"
              label={ el.name }
              name={ `id${ el.id }` }
              InputProps={ { autoComplete: 'off' } }
            />
            <div className="description-container">
              { el.description &&
                <Tooltip title={ el.description } placement="right">
                  <HelpWithMargin>
                    <InfoOutlined/>
                  </HelpWithMargin>
                </Tooltip>
              }
            </div>
          </InputTextWrapper>
          <DefaultValue
            parameter={ el }
            algorithmId={ settings.algorithm?.id! }
            productId={ row.productId }
          />
        </div>
      )) }
    </FieldsWrapper>
  )
}