import { all, call, put } from 'redux-saga/effects'
import { api, authApi } from 'shared/api'
import { diContainer, TYPES, DIInterfaces } from 'shared/lib'
import { getFingerprint } from 'shared/lib/browser/getFingerprint'
import { cookieStore } from 'shared/services/cookieStore'
import { ViewerStatus } from 'shared/types'
import { RootStore } from 'store'
import { sagaMiddleware } from 'store/saga'

interface State {
  status: ViewerStatus;
}

const initialState: State = {
  // Изначально не известено, авторизован ли пользователь или нет
  status: 'unknown',
}

/* Actions */
const SET_STATUS = 'shell/viewer/setStatus' as const


/* Action Creators */
interface SetStatusAction {
  type: typeof SET_STATUS
  status: ViewerStatus
}
export const setStatus: (status: ViewerStatus) => SetStatusAction = (status) => ({
  type: SET_STATUS,
  status
})

type Action = SetStatusAction

/* Reducer */
export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        status: action.status,
      }
    default:
      return state
  }
}

/* selector */
export const viewerStatusSelector = (state: RootStore): ViewerStatus => state.viewer.status


/* Sagas */
function* getViewerStatus() {
  const fingerprint: SagaCall<typeof getFingerprint> = yield call(getFingerprint)
  try {
    const { data }: SagaCall<typeof authApi.refreshToken> = yield call(authApi.refreshToken, { fingerprint })
    api.setToken(data.accessToken)
  } catch(error) {
    yield put(setStatus('unauthorized'))
  }
}

export function* rootSaga() {
  yield all([
    getViewerStatus(),
  ])
}

/* API TOKEN CHANGE SUBSCRIBE */
const user = diContainer.get<DIInterfaces.IUser>(TYPES.User)

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
}

function* login(token: string) {
  yield put(setStatus('authorized'))
  user._setAuthorized()

  deleteCookie('X-Authorization')
  cookieStore.set('X-Authorization', token, { expires: 'Session' ,path: '/api/wms/ws' })
  cookieStore.set('X-Authorization', token, { expires: 'Session' ,path: '/api/notifications/ws', sameSite: 'Lax' })
}
function* logout() {
  yield put(setStatus('unauthorized'))
  user._setAuthorized(false)
}

function* tokenChangeWorker(token: string | null) {
  if (token) {
    yield login(token)
  } else {
    yield logout()
  }
}

// Подписка на изменение токена api
function handleTokenChange(token: string | null) {
  sagaMiddleware.run(tokenChangeWorker, token)
}

api.tokenChangeSubscribe(handleTokenChange)