import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api'

import { EditOzonCard } from '../models'

export const syncProductsOzon =  (
  productId: UniqueId,
  method: string,
  editedOzonCard: EditOzonCard
) =>
  api.patch<pimApi.products.ozon.OzonProduct>(
    `/pim/products/ozon/product/${productId}?method=${method}`,
    editedOzonCard
  )

export const useSyncProductsOzonMutation = (productId: UniqueId, method: string) =>
  useMutation<
    AxiosResponse<pimApi.products.ozon.OzonProduct>,
    AxiosError<errorType>,
    EditOzonCard
  >(
    (editedOzonCard) => syncProductsOzon(productId, method, editedOzonCard)
  )
