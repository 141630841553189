import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'


const downloadGoodsMassArchiveTemplateFile = async (queryParams: { method: 'archive' | 'unarchive' }) => (
  api.get<File>('/pim/ops/goods/template', {
    responseType: 'blob',
    params: { method: queryParams.method }
  })
)

const uploadGoodsMassArchiveFile = async (
  queryParams: { file: File | null, archivationreason_id?: UniqueId, method: 'archive' | 'unarchive', archivation_comment?: string }
) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')
  return api.patch(
    '/pim/ops/goods',
    body,
    {
      params: {
        method: queryParams.method,
        archivationreason_id: queryParams.archivationreason_id,
        archivation_comment: queryParams.archivation_comment
      }
    }
  )
}

export const useDownloadGoodsMassArchiveTemplateFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, { method: 'archive' | 'unarchive' }>
) => useMutation(
  downloadGoodsMassArchiveTemplateFile,
  options
)

export const useUploadGoodsMassArchiveFile = (
  options?: UseMutationOptions<AxiosResponse,errorType,
    { file: File | null, archivationreason_id?: UniqueId, method: 'archive' | 'unarchive', archivation_comment?: string }>
) => useMutation(
  uploadGoodsMassArchiveFile,
  options,
)
