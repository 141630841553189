import React, { memo } from 'react'
import { useField, useFormState } from 'react-final-form'
import { IconButton, Tooltip, TextField } from '@mui/material'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { Field } from 'shared/ui/components/form'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { pimApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'

import { BrandMemo } from './Brand'
import { TNVEDCodeMemo } from './TNVEDCode'
import { ProductName } from './ProductName'
import { StyledCreateIcon } from './styled'
import { ChangeWBCategoryWindow } from './ChangeWBCategoryWindow'

import { ColorMemo } from '../../../../Nomenclatures/ui/Color'


interface MainParamsFormProps {
  goods: pimApi.goods.Goods
  disabled?: boolean
  showGoodsInfo: boolean
}

const getTNVED = ({
  tnvedCode,
  tnved,
}: {
  tnvedCode?: string
  tnved?: string
}) => (tnvedCode && tnved ? `${tnvedCode} - ${tnved}` : '')

export const MainParamsForm: React.FC<MainParamsFormProps> = memo(({ goods, disabled, showGoodsInfo }) => {
  const { commonProductQuery } = useCommonProductContext()
  const { wbProductQuery } = useWBProductContext()
  const category = useField<UniqueId | null>('category').input.value
  const tnvedEnabled = useField('tnvedEnabled').input.value

  const { initialValues } = useFormState({ subscription: { initialValues: true } })
  const { colorsEnabled } = initialValues

  const onChangeCategoryClick = () => {
    if (commonProductQuery.data?.productId &&
      commonProductQuery.data?.companyId) {
      dialogModel.openDialog({
        component: ({ close }) => (
          <ChangeWBCategoryWindow
            close={close}
            companymarketplaceId={commonProductQuery.data?.companymarketplaceId}
            productId={commonProductQuery.data?.productId}
            onlyOneNomenclature={wbProductQuery.data?.otherNomenclatures?.length===0}
          />
        ),
        onAccept: () => {
        },
      })
    }
  }

  return (
    <>
      <InputsWrapper hideGoodsInfo={!showGoodsInfo} globalAttributeName="nameGlobal" containerSx={{ alignItems: 'start' }}>
        <TextField
          label="Название"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={goods?.name ?? ''}
          disabled={true}
        />
        <Field
          name="name"
          label="Название*"
          maxLength="60"
          component={ProductName}
          disabled={disabled}
        />
      </InputsWrapper>
      <InputsWrapper hideGoodsInfo={!showGoodsInfo}>
        <TextField
          label="Категория"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={goods?.category ?? ''}
          disabled={true}
        />
        <TextField
          label="Категория*"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={category}
          disabled={true}
        />
        <IconButton size="small" onClick={onChangeCategoryClick}>
          <Tooltip
            title="Изменить категорию"
            placement="right"
          >
            <StyledCreateIcon/>
          </Tooltip>
        </IconButton>
      </InputsWrapper>
      <InputsWrapper hideGoodsInfo={!showGoodsInfo} globalAttributeName="brandGlobal">
        <TextField
          label="Бренд"
          variant="outlined"
          size="small"
          fullWidth={true}
          value={goods?.brand ?? ''}
          disabled={true}
        />
        <Field
          name="brand"
          label="Бренд*"
          component={BrandMemo}
          companymarketplaceId={commonProductQuery?.data?.companymarketplaceId}
          disabled={disabled}
        />
      </InputsWrapper>

      {colorsEnabled && (
        <InputsWrapper hideGoodsInfo={!showGoodsInfo}>
          {null}
          <Field
            name="colors"
            label="Цвет"
            component={ColorMemo}
            maxCount={5}
            multiple={true}
            disabled={disabled}
            companymarketplaceId={commonProductQuery?.data?.companymarketplaceId}
          />
        </InputsWrapper>
      )}

      {tnvedEnabled && (
        <InputsWrapper hideGoodsInfo={!showGoodsInfo} globalAttributeName="tnvedGlobal">
          <TextField
            label="Код ТН ВЭД"
            variant="outlined"
            size="small"
            fullWidth={true}
            value={getTNVED(goods)}
            disabled={true}
          />
          <Field
            name="tnvedCode"
            label="Код ТН ВЭД*"
            component={TNVEDCodeMemo}
            companymarketplaceId={commonProductQuery?.data?.companymarketplaceId}
            disabled={disabled}
            subjectId={wbProductQuery?.data?.categoryId}
          />
        </InputsWrapper>
      )}
    </>
  )
}
)
