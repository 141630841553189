import { useParams } from 'react-router-dom'
import { Box, SelectChangeEvent } from '@mui/material'
import produce from 'immer'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { pimApi } from 'shared/api'
import { DisplayedGoodsDataController } from 'pages/product/edit/main/model/DisplayedGoodsDataController'
import { MemoECOMMarketplace } from 'pages/product/edit/main/ui/WB/ui/ProductInfoTabs/ui/ECOMWB'

import { NormalizedAttributesMapped } from './NormalizedAttributesMapped'
import { NormalizedAttributesLeft } from './NormalizedAttributesLeft'
import { NormalizedAttributesRight } from './NormalizedAttributesRight'
import { NormalizedAttributesVariation } from './NormalizedAttributesVariation'

import { ProductAttributeValue } from '../../lib/getNormalizedAttributes'
import { useNormalizedAttributes } from '../../model/useNormalizedAttributes'


export function CharacteristicsAndAttributes() {
  const { id } = useParams<{ id: string }>()
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(parseInt(id, 10))
  const isArchived = ozonProductQuery.data?.inArchive

  const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()

  const {
    normalizedAttributes,
    setNormalizedAttributes,
    changeOzonAttributeProperty
  } = useNormalizedAttributes()

  const createLeftWaitFilled = (index: number) => () => {
    setNormalizedAttributes(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.normalizedAttributesLeft[index].type = 'leftWaitFilled'
      })
    )
  }

  const removeLeftWaitFilled = (index: number) => () => {
    const conditionAttributeId = normalizedAttributes.normalizedAttributesMapped[index].right.id

    changeOzonAttributeProperty(conditionAttributeId, { attributeId: null })
  }

  const handleSelectMapAttributeChange = (index: number) => (event: SelectChangeEvent) => {
    const rightIndex = parseInt(event.target.value, 10)
    const conditionAttributeId = normalizedAttributes.normalizedAttributesRight[rightIndex].right.id

    const { attributeId } = normalizedAttributes.normalizedAttributesLeft[index].left

    changeOzonAttributeProperty(conditionAttributeId, { attributeId })
  }

  const handleAttributeValueChange = (attrId: number) => (productAttributeValues: Array<ProductAttributeValue>) => {
    changeOzonAttributeProperty(attrId, { values: productAttributeValues })
  }

  return (
    <>
      <MemoECOMMarketplace
        leftSideTooltip="Характеристики товара, к которому привязан продукт"
        changeDisplayedGoodsData={changeDisplayedGoodsData('characteristicsAndAttributes')}
        displayGoodsData={displayedGoodsData.characteristicsAndAttributes}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>


        {isNotEmptyArray(normalizedAttributes.normalizedAttributesVariation) && (
          <NormalizedAttributesVariation
            normalizedAttributesVariations={normalizedAttributes.normalizedAttributesVariation}
            onUnmapAttribute={removeLeftWaitFilled}
            onAttributeValueChange={handleAttributeValueChange}
            showGoodsInfo={displayedGoodsData.characteristicsAndAttributes}
            disabled={isArchived}
          />
        )}

        {isNotEmptyArray(normalizedAttributes.normalizedAttributesMapped) && (
          <NormalizedAttributesMapped
            normalizedAttributesMappeds={normalizedAttributes.normalizedAttributesMapped}
            onUnmapAttribute={removeLeftWaitFilled}
            onAttributeValueChange={handleAttributeValueChange}
            showGoodsInfo={displayedGoodsData.characteristicsAndAttributes}
            disabled={isArchived}
          />
        )}

        {isNotEmptyArray(normalizedAttributes.normalizedAttributesRight) && (
          <NormalizedAttributesRight
            normalizedAttributesRights={normalizedAttributes.normalizedAttributesRight}
            onAttributeValueChange={handleAttributeValueChange}
            showGoodsInfo={displayedGoodsData.characteristicsAndAttributes}
            disabled={isArchived}
          />
        )}

        {isNotEmptyArray(normalizedAttributes.normalizedAttributesLeft) && (displayedGoodsData.characteristicsAndAttributes) && (
          <NormalizedAttributesLeft
            normalizedAttributesLefts={normalizedAttributes.normalizedAttributesLeft}
            normalizedAttributesRights={normalizedAttributes.normalizedAttributesRight}
            createLeftWaitFilled={createLeftWaitFilled}
            onSelectMapAttributeChange={handleSelectMapAttributeChange}
            disabled={isArchived}
          />
        )}

      </Box>
    </>
  )
}