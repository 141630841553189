
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { NewOzonProductRequest, NewOzonProductResponse } from '../models'
import { api } from '../../../base'


export const createProductOzon = (newOzonProduct: NewOzonProductRequest) =>
  api.post<NewOzonProductResponse>('/pim/products/ozon/product', newOzonProduct)

export const useCreateProductOzonMutation = () =>
  useMutation<
    AxiosResponse<NewOzonProductResponse>,
    errorType,
    NewOzonProductRequest
  >(createProductOzon)
