import React from 'react'
import { Box, Typography } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'

interface DiffDataParams {
  title: string
  data?: number
  diff?: number
  chartUrl?: string
}
export const DifferenceDataWidget = ({ title, data, diff, chartUrl }: DiffDataParams) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
        {title}
      </Typography>
      {chartUrl &&
      <Box>
        <a
          style={{ display: 'flex', alignItems: 'center' }}
          href={chartUrl}
          target="_blank" rel="noreferrer">
          <BarChartIcon sx={{ color: '#00000061' }}/>
        </a>
      </Box>
      }
    </Box>
    {(data !== undefined && diff !== undefined) &&
    <ValueDelta value={data} delta={diff}/>
    }
  </Box>
)