import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { generatePath } from 'react-router'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { Box, Button } from '@mui/material'
import { GOODS_VARIANT } from 'shared/config/routes'
import { Form } from 'shared/ui/components/form'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { ProductCreateModal } from 'features/product/CreateProductModal/ui'
import { pimApi } from 'shared/api'

import { InfoForm, validationSchema } from '../../components/Steps/InfoStep'
import { GoodsInitialValues, GoodsInfoValuesSubmit } from '../../components/Steps/InfoStep/interface'
import { BottomButtonWrapper, BottomSection } from '../../components/common'

interface InfoWrapperStepProps<InitialFormValues> {
  initialValues: InitialFormValues;
  onSubmit: (GoodsInfoValuesSubmit) => void;
  isArchived?: boolean;
}

const focusOnError = createDecorator()
const decorators = [focusOnError]

export function InfoWrapperStep<
  InitialFormValues extends GoodsInitialValues = GoodsInitialValues
>({
  initialValues,
  onSubmit,
  isArchived
}: InfoWrapperStepProps<InitialFormValues>) {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const validate = useValidationSchema(validationSchema)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const hasAttributesForVariation = isNotEmptyArray(goodsAttributesForVariationsQuery.data)

  return (
    <Form<GoodsInfoValuesSubmit, GoodsInitialValues>
      onSubmit={onSubmit}
      // @ts-ignore
      mutators={{
        ...arrayMutators,
      }}
      decorators={decorators}
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={true}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <InfoForm values={values} isArchived={isArchived}/>
          <BottomSection>
            <BottomButtonWrapper>
              <div>
                <Button variant="contained" color="primary" type="submit" disabled={isArchived}>
                  СОХРАНИТЬ
                </Button>
              </div>
              {(values.goodsType !== 'assortment') && (
                <Box display="flex" alignItems="center">                  
                  {hasAttributesForVariation && (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={generatePath(GOODS_VARIANT, { id: goodsId })}
                      disabled={isArchived}
                    >
                      ДОБАВИТЬ ВАРИАЦИЮ
                    </Button>
                  )}
                  <Box display="inline-flex" pl={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleOpenCreateModal}
                      disabled={isArchived}
                    >
                      СОЗДАТЬ ПРОДУКТ
                    </Button>
                    <ProductCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} />
                  </Box>
                </Box>
              )}
            </BottomButtonWrapper>
          </BottomSection>
        </form>
      )}
    />
  )
}
