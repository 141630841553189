import React from 'react'

import { CompanyStateText } from './styled'

interface StateAppearance {
  color: string
  labelText: string
}

const StateAppearanceSettings: Record<string, StateAppearance> = {
  false: { color: '#2196F3', labelText: 'Активен' },
  true: { color: '#ED6C02', labelText: 'В архиве' },
}
export const CompaniesStateCell = ({ value }) => {
  const current_status = StateAppearanceSettings[value]?.labelText
  const current_color = StateAppearanceSettings[value]?.color
  return (
    <CompanyStateText label={current_status} sx={{ backgroundColor: current_color }}/>
  )
}