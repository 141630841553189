import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api'

export const getCurrentCategoryQueryKey = (categoryId: UniqueId) =>
  ['get', '/pim/categories', categoryId] as const

type GetCurrentCategoryQueryKey = ReturnType<typeof getCurrentCategoryQueryKey>

function getCurrentCategory(categoryId: UniqueId) {
  return api.get<pimApi.categories.Category>
  (`/pim/categories/${categoryId}`).then((res) => res.data)
}

const getCurrentCategoryFn = (context: QueryFunctionContext<GetCurrentCategoryQueryKey>) => {
  const [, ,categoryId] = context.queryKey
  return getCurrentCategory(categoryId)
}

export const useGetCurrentCategory = (categoryId: UniqueId) =>
  useQuery<
    pimApi.categories.Category,
    errorType,
    pimApi.categories.Category,
    GetCurrentCategoryQueryKey
  >(
    getCurrentCategoryQueryKey(categoryId),
    getCurrentCategoryFn, { refetchOnMount: false, keepPreviousData: true, staleTime: Infinity }
  )
