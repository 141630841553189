import styled from 'styled-components'
import { CardMedia, Chip, Typography } from '@mui/material'

export const ChipStyled = styled(Chip)<{obsolete?: boolean}>`
  user-select: auto;
  height: 24px;
  padding: 4px 4px 4px 4px;
  margin: 4px 0 0 8px !important;
    // margin: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${props => props.obsolete ? '#f9c7c7' : '#e9f4fe'};
  svg {
    height: 16px;
  }
  
  span {
    max-width: 420px;
  }
  
  :hover {
    background-color: #e9f4fe;
  }
  
  :after {
    box-shadow: none;
  }
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
`
export const ItemImage = styled(CardMedia)`
  height: 50px;
  width: 50px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`

interface ItemImageScaledProp { 
  url?: string;
}

export const ItemImageScaled = styled.div<ItemImageScaledProp>`
  width: 44px;
  height: 44px;
  padding: 2px;
  background-color: #F5F5F5;
  border-radius: 8px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${ ({ url }) => url });
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
`

export const ItemText = styled(Typography)`
  max-width: 100%;
  text-overflow: ellipsis;
`

export const StyledLimitValues = styled.div`
  position: absolute;
  right: 5px;
  top: -10px;
  padding: 0 3px;
  opacity: 1;
  display: flex;
  z-index: 1;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  color: #00000099;
`
