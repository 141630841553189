import React from 'react'
import { PriceHistoryType } from 'features/priceHistory/Table/model/types'
import CallMadeIcon from '@mui/icons-material/CallMade'

export const DeltaIconCell = ({ row }: { row: PriceHistoryType }) => {
  const { newCurrentPrice, oldCurrentPrice } = row
  const Diff = newCurrentPrice - oldCurrentPrice
  return (
    <div style={{ height: '100%', display: 'flex', paddingBottom: '20px' }}>
      <CallMadeIcon sx={{
        marginTop: 'auto',
        marginBottom: '0',
        color: '#007DFF',
        rotate: Diff >= 0 ? '' : '90deg'
      }}/>
    </div>
  )
}