import React, { useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import { CheckboxContainer, StyledSlider } from '../styled'
import { dataParamsTypes } from '../../../types'

export const CheckboxControl = ({ 
  dataParams,
  setDataParams
}: {
  dataParams: dataParamsTypes,
  setDataParams: any }) => {
  const [sliderValue, setSliderValue] = useState(dataParams.trendPercentThreshold || 0)

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100%',
    },
  ]

  const { trend } = dataParams

  const handleCheckedChange = (name: string, checked: boolean) => {
    if (checked) {
      setDataParams(prev => ({ ...prev,
        trend: name
      }))
    } else {
      setDataParams(prev => ({ ...prev,
        trend: undefined,
      }))
    }
  }

  const handleSliderChange = (value) => {
    if (value !== 0) {
      setDataParams(prev => ({
        ...prev,
        trendPercentThreshold: value
      }))
    } else {
      setDataParams(prev => ({ ...prev,
        trendPercentThreshold: undefined
      }))
    }
  }
  return (
    <div className="settings-container">
      <CheckboxContainer>
        <FormControlLabel
          id="checkbox-form"
          checked={trend === 'increased'}
          control={<Checkbox size="small"/>}
          label="Увеличение цены"
          onChange={(event, checked) => {handleCheckedChange('increased', checked)}}
        />

        <FormControlLabel
          id="checkbox-form"
          checked={trend === 'decreased'}
          control={<Checkbox size="small"/>}
          label="Уменьшение цены"
          onChange={(event, checked) => {handleCheckedChange('decreased', checked)}}
        />
      </CheckboxContainer>
      <div style={{ width: '170px' }}>
        <StyledSlider
          size="small"
          marks={marks}
          value={sliderValue}
          onChange={(event, value) => {setSliderValue(value as number)}}
          onChangeCommitted={() => handleSliderChange(sliderValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
        />
      </div>
    </div>
  )
}