import { memo, useEffect, useMemo, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { tradeApi } from 'shared/api'
import { tradeGen } from 'shared/lib/generated'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Table } from 'shared/ui/components/Table'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { dialogModel } from 'shared/ui/components/dialog'
import { AddPositions } from 'pages/supplyOrders/edit/ui/Settings/ui/AddPositions'
import {
  AddPositionsGoodsCopackingModalContent
} from 'pages/supplyOrders/edit/ui/Settings/ui/AddPositionsInCopackingModalContent/AddPositionsGoodsCopackingModalContent'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { snackActions } from 'shared/lib/react/snackbar'
import { TableParams } from 'shared/ui/components/Table/model/types'

import { columnsConfig } from '../../model/tableColumnsConfig'
import { nestedColumnsConfig } from '../../model/nestedTableColumnsConfig'
import { StyledTableBox, StyledLoader, TableWrapper, SideTable, ShortTable, CustomHeader } from '../styled'
import { sideColumnsConfig } from '../../model/sideTableColumnsConfig'
import NoRemainsPlug from '../Plugs/NoRemainsPlug'
import NoDifferencePlug from '../Plugs/NoDifferencePlug'
import { changeColumnConfig, tableParams } from '../../../lib/tableParamsSetting'
import CustomInput from '../../../ui/CustomInput'

const getRowId = (row: tradeApi.CoPackingOrderPosition) => row.id
const getSideRowId = (row: tradeApi.CoPackingOrderSource) => row.goodsId

const CopackingOrdersTable = () => {

  const { supplyOrderQuery, tabsSettingObject, inTabsSettingHandler } = useSupplyOrderContext()
  const copackingordersId = supplyOrderQuery.data?.copackingorderId as number

  const [isShowDifferentDataTable, setIsShowDifferentDataTable] = useState(true)

  const packingData =
    tradeGen.orders.GetCopackingOrder.useGetCopackingOrder({
      copackingorderId: copackingordersId,
      query: {
        onlyDiffs: isShowDifferentDataTable
      }
    })
  const tableData = packingData.data?.positions
  const [filteredTableData, setFilteredTableData] = useState<Array<tradeApi.CoPackingOrderPosition> | any>(tableData)
  const sideTableData = packingData.data?.source


  const refetchPage = () => packingData.refetch()

  const { mutate: editSupplyOrder, isLoading } =
    tradeApi.orders.useChangeOrderPositionPacking()
  const { mutate: deletePosition } =
    tradeApi.orders.useDeleteOrderPositionPacking()

  const isTableEditable = packingData.data?.editPossible

  const memoTableParams = useMemo(
    () => tableParams(tabsSettingObject, 'copackingorders'),
    [supplyOrderQuery?.data, tabsSettingObject?.inTabsObjectInit]
  )

  const handleCellChange = (changedCells) => {
    const editedCell = changedCells.changed
    const editedCellId = Number(Object.keys(changedCells.changed)?.[0])

    if(!editedCell[editedCellId]) return

    const editedCellSource = packingData.data?.positions?.find(
      (position) => position.id === editedCellId
    )

    editSupplyOrder(
      {
        positionId: editedCellId,
        copackingordersId,
        editedOrderPositionPack: {
          id: editedCellId as number,
          versionNo: editedCellSource?.versionNo as number,
          quantity: editedCell[editedCellId].quantity
        },
      },
      {
        onSuccess: () => {
          packingData.refetch().then( () =>
            snackActions.info('Успешно сохранено!')
          )
        },
        onError: () => {
        }
      }
    )
  }

  useEffect(()=>{
    const filteredData: Array<any> = []

    if (tableData) {
      // eslint-disable-next-line array-callback-return
      tableData.map(data => {
        if (data.productName.toLowerCase().includes(memoTableParams.searchString.toLowerCase())) {
          const filteredProduct = tableData?.find(e => e.id === data.id)
          filteredData?.push(filteredProduct)
        }
      })
    }

    if (memoTableParams.searchString.length) {
      setFilteredTableData(filteredData)
    } else setFilteredTableData(tableData)

  }, [memoTableParams, tableData])

  const handleRowDelete = (id) => {
    const deleteObject = packingData.data?.positions.find( obj => obj.id === id)
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <ModalContent
          close={close}
          accept={accept}
          title="Удалить позицию?"
          acceptBtnText="УДАЛИТЬ"
          acceptBtnColor="error"
        />
      ),
      onAccept: () => {
        if (deleteObject) {
          deletePosition(
            {
              copackingordersId,
              positionId: id,
              requestBody: {
                id,
                versionNo: deleteObject.versionNo
              }
            },
            {
              onSuccess: () => {
                snackActions.info('Продукт успешно удален из заказа')
                packingData.refetch()
              },
            }
          )
        }
      },
    })
  }

  const handleSideTableModal = (id) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <AddPositionsGoodsCopackingModalContent
          close={close}
          copackingordersId={copackingordersId}
          goodId={id}
          refetchPage={refetchPage}
        />
      )
    })
  }


  const handleChangeShowDifference = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowDifferentDataTable(event.target.checked)
  }

  const changeColumnHandler = (params: TableParams) => {
    changeColumnConfig(params, inTabsSettingHandler, 'copackingorders')
  }

  return (
    <>
      <StyledTableBox>
        {(packingData.isLoading || packingData.isFetching ) && <StyledLoader size={60} />}
        {filteredTableData && (
          <TableWrapper>
            <ShortTable>
              <Paper sx={{ height: '100%' }}>
                <CustomHeader>
                  <Typography variant="h3" fontSize="1.5rem">Продукты</Typography>
                  <FormControlLabel
                    value="Только расхождения"
                    control={<Switch
                      size="small"
                      color="primary"
                      checked={isShowDifferentDataTable}
                      onChange={handleChangeShowDifference}
                      sx={{ marginRight: '8px' }}
                    />}
                    label="Только расхождения"
                    labelPlacement="end"
                    sx={{ marginLeft: 'auto', marginRight: '74px' }}
                  />
                  <AddPositions copackingordersId={copackingordersId} refetchPage={refetchPage} />
                </CustomHeader>{
                  filteredTableData.length
                    ? <Table
                      tableList={filteredTableData}
                      getRowId={getRowId}
                      tableParams={memoTableParams}
                      nestedTableColumnsConfig={nestedColumnsConfig}
                      onCellChange={handleCellChange}
                      onRowDelete={handleRowDelete}
                      isTableEditable={isTableEditable}
                      isLoading={isLoading || packingData.isFetching}
                      columnsConfig={columnsConfig}
                      showInArchiveControl={false}
                      showColumnsVisibility={false}
                      searching="custom"
                      sorting="internal"
                      changeColumnConfig={changeColumnHandler}
                      isStoredSettings={true}
                      customSearchInput={CustomInput}
                      selectTextOnEditStart={true}
                    />
                    : <NoDifferencePlug/>}
              </Paper>
            </ShortTable>
            <Paper sx={{ marginTop: '16px', marginLeft: '16px', marginBottom: '12px' }}>
              <SideTable>
                <CustomHeader>
                  <Typography variant="h3" fontSize="1.5rem">Остатки товаров в поставке</Typography>
                </CustomHeader>
                { sideTableData?.length
                  ? <Table
                    tableList={sideTableData}
                    getRowId={getSideRowId}
                    tableParams={memoTableParams}
                    onModalOpen={handleSideTableModal}
                    columnsConfig={sideColumnsConfig}
                    showInArchiveControl={false}
                    showColumnsVisibility={false}
                    sorting="external"
                  />
                  : <NoRemainsPlug />
                }
              </SideTable>
            </Paper>
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoCopackingOrdersTable = memo(CopackingOrdersTable)