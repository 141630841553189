import { isNotNil } from '../checkers'

export function formatter(num) {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(0)  } млрд`
  } if (num >= 1000000) {
    return `${(num / 1000000).toFixed(0)  } млн`
  } if (num >= 1000) {
    return `${(num / 1000).toFixed(0)  } тыс`
  }
  return isNotNil(num) ? num.toString() : ''
}