
import { AxiosResponse } from 'axios'
import { isNotNil } from 'shared/lib/checkers'

import {
  ChangeDocAcceptanceResponse,
  DeleteReceiptDocAcceptanceParams,
  EditReceiptDocAcceptanceParams,
} from './models'

import { api } from '../../base'

export function changeAcceptance(
  {
    docId,
    positionId,
    acceptance: {
      id,
      versionNo,
      quantityAccepted,
      quantityDefective,
    },
  }: EditReceiptDocAcceptanceParams
): Promise<AxiosResponse<ChangeDocAcceptanceResponse>> {
  if (isNotNil(id)) {
    const body = { id, versionNo, quantityAccepted, quantityDefective }
    return api.put(
      `/wms/receiptdocs/${docId}/positions/${positionId}/acceptance/${id}`,
      body
    )
  }

  const body = { quantityAccepted }
  return api.post(
    `/wms/receiptdocs/${docId}/positions/${positionId}/acceptance`,
    body
  )
}

export function removeAcceptance({
  docId,
  positionId,
  acceptance: { id, versionNo }
}: DeleteReceiptDocAcceptanceParams
) {
  const body = { id, versionNo }

  return api.delete(
    `/wms/receiptdocs/${docId}/positions/${positionId}/acceptance/${id}`,
    { data: body }
  )
}
