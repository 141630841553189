import { Goods } from 'domains/wms/goods'
import { IGoodsWMSService } from 'shared/services/interfaces/IGoodsWMSService'
import { diContainer } from 'shared/lib'
import { TYPES } from 'shared/services/types'
import { useState } from 'react'
import { snackActions } from 'shared/lib/react/snackbar'

const goodsService = diContainer.get<IGoodsWMSService>(TYPES.GoodsWMSService)

export function useGoods() {
  const [goods, setGoods] = useState<null | Goods>(null)
  function openGoods(goodId: UniqueId) {
    goodsService.getOneGoods(goodId)
      .then<Goods>(({ data: value }: { data: Goods }) => value)
      .then(setGoods)
      .catch(() => {
        snackActions.error('Произошла ошибка при открытии товара')
      })
  }

  return {
    goods,
    openGoods,
  }
}