import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

export const getProductChangelogQueryKey = (productId?: UniqueId) =>
  ['get', `/pim/products/${productId}/changelog`, productId] as const

type GoodsChangelogQueryKey = ReturnType<typeof getProductChangelogQueryKey>

export const getProductChangelog = (productId?: UniqueId) =>
  api.get<pimApi.products.ChangeLog>(`/pim/products/${productId}/changelog`)

export const useFetchProductChangelogQuery = (productId?: UniqueId) => 
  useQuery<
    AxiosResponse,
    AxiosError<errorType>,
    AxiosResponse,
    GoodsChangelogQueryKey
  >(
    getProductChangelogQueryKey(productId),
    (context) => getProductChangelog(context.queryKey[2] as UniqueId),
    {
      refetchOnMount: false
    }
  )