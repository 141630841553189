import {
  AdviceIntervalCell, CompetitorsStocksCell,
  DaysStocks,
  IdCell,
  ParametersCell, PriceCell,
  RrpCell
} from '../../../ui/tableCells'

export const NestedWidgetCells = [
  {
    name: 'Настройка',
    width: '460px',
    padding: '16px 16px 0px 16px',
    Component: ParametersCell
  },
  {
    name: 'В день',
    width: '280px',
    padding: '20px 16px 8px 16px',
    Component: DaysStocks
  },
  {
    name: 'РРЦ',
    width: '140px',
    padding: '20px 16px 8px 16px',
    Component: RrpCell
  },
  {
    name: 'Проверка',
    width: '180px',
    padding: '15px 16px 8px 16px',
    Component: AdviceIntervalCell
  },
  {
    name: 'Конкурент',
    width: '126px',
    padding: '17px 16px 8px 16px',
    Component: IdCell
  },
  {
    name: 'Цена',
    width: '86px',
    padding: '17px 16px 8px 16px',
    Component: PriceCell
  },
  {
    name: 'Остатки',
    width: 'auto',
    padding: '17px 16px 8px 16px',
    Component: CompetitorsStocksCell
  },
]