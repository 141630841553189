import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

interface updateVariationPayload {
  productId: UniqueId
  variationId: UniqueId
  editedVariation: pimApi.products.EditWBVariation
}
const updateVariation = ({
  productId,
  variationId,
  editedVariation,
}: updateVariationPayload) =>
  api.put<pimApi.products.wb.WBVariation>(
    `pim/products/wb/product/${productId}/variations/${variationId}`,
    editedVariation
  ).then((res) => res.data)

export const useUpdateVariationMutation =
  (options?: UseMutationOptions<pimApi.products.wb.WBVariation,AxiosError<errorType>,updateVariationPayload>) =>
    useMutation<pimApi.products.wb.WBVariation,AxiosError<errorType>,updateVariationPayload>
    ((updateVariationPayload) => updateVariation(updateVariationPayload), options)