import * as React from 'react'
import { useField } from 'react-final-form'
import { Box, Tab, Tabs } from '@mui/material'
import { pimApi } from 'shared/api'
import { TabPanel } from 'shared/ui/components'
import { GeneralInformation } from 'features/product/OZON/GeneralInformation'
import { ProductChangelogTable as Changelog } from 'features/product/changelog'
import HistoryIcon from '@mui/icons-material/History'
import { TabsNav } from 'pages/goods/components/common'
import {
  CharacteristicsAndAttributes
} from 'features/product/OZON/MapAttributes/ui/CharacteristicsAndAttributes/CharacteristicsAndAttributes'
import { Description } from 'features/product/OZON/Description/Description'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { PriceTab } from 'features/product/OZON/PriceTab'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { CompetitorsTab } from 'features/product/common/CompetitorsTab'

import { FlexWrapper } from './styled'

import { Media } from '../Media'
import { CompetitorBadge } from '../../../WB/ui/ProductInfoTabs/ui/styled'

export const TABS = [
  {
    label: 'Основное',
    Component: GeneralInformation,
    value: 'general',
  },
  {
    label: 'Характеристики',
    Component: CharacteristicsAndAttributes,
    value: 'characteristics',
  },
  {
    label: 'Цена',
    Component: PriceTab,
    value: 'price'
  },
  {
    label:  'Описание',
    Component: Description,
    value: 'description',
  },
  {
    label: 'Медиа',
    Component: Media,
    value: 'media',
  },
  {
    label:  'Конкуренты',
    Component: CompetitorsTab,
    value: 'competitors',
  },
  {
    label: 'ИСТОРИЯ ИЗМЕНЕНИЙ',
    Component: Changelog,
    value: 'changelog',
  }
]

interface ProductInfoTabsProps {
  commonProduct: pimApi.products.common.Product;
}

export const ProductInfoTabs = React.memo(
  ({ commonProduct }: ProductInfoTabsProps) => {
    const { search } = useLocation()
    const history = useHistory()
    const { tab } = queryString.parse(search)
    
    const editPossible = useField('editPossible')
    const isDraft = !editPossible.input.value

    const handleTabChange = (_: React.ChangeEvent<{}>, newTabValue: string) => {
      handleTabParamsChange({ tab: newTabValue })
    }

    const handleTabParamsChange = (params) => {
      const historyStr = queryString.stringify(
        { ...params },
        { skipEmptyString: true, skipNull: true }
      )
      history.replace(`?${historyStr}`)
    }

    return (
      <>
        <Box id="ScrollToPriceControlOZON" mb={3} sx={{ borderBottom: '1px solid rgba(0, 125, 255, 0.5)' }}>
          <FlexWrapper>
            <TabsNav>
              <Tabs value={ tab || TABS[0].value } onChange={handleTabChange}>
                {TABS.map(({ label, value }) => {
                  if (label === 'ИСТОРИЯ ИЗМЕНЕНИЙ') {
                    return (
                      <Tab
                        key={ value }
                        value={value}
                        label={<HistoryIcon/>}
                        {...a11yProps(value)}
                      />
                    )
                  }
                  return (
                    <Tab
                      key={ value }
                      value={value}
                      label={(label === 'Конкуренты' && commonProduct.activeCompetitors !== 0) ?
                        <div>
                          {label}
                          <CompetitorBadge>
                            <span>{commonProduct.activeCompetitors}</span>
                          </CompetitorBadge>
                        </div> : label}
                      disabled={isDraft}
                      {...a11yProps(value)}
                    />
                  )
                })}
              </Tabs>
            </TabsNav>
          </FlexWrapper>
        </Box>

        {TABS.map(({ Component, value }) => (
          <TabPanel
            key={ value }
            value={ tab || TABS[0].value }
            index={ value }
            disabled={isDraft}
          >
            <Component commonProduct={commonProduct} />
          </TabPanel>
        ))}
      </>
    )
  }
)