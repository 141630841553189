import { memo } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { TnvedSelect } from '../TnvedSelect'


interface TN_VED_VALUE {
  code: string
  name: string
}

interface TN_VED_VALUE_EMPTY {
  code: null
  name?: null
}


interface TnvedSelectFieldProps extends FieldRenderProps<TN_VED_VALUE_EMPTY | TN_VED_VALUE> {
  label?: string
  disabled?: boolean
  placeholder?: string
}

export const TnvedSelectField = memo(({
  input,
  meta,
  label = '',
  placeholder,
  disabled
}: TnvedSelectFieldProps ) => (
  <TnvedSelect
    label={label}
    input={input}
    meta={meta}
    placeholder={placeholder}
    disabled={disabled}
  />
))
