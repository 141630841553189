/* eslint-disable */
// @ts-nocheck

import styled from 'styled-components'
import Chip from '@mui/material/Chip'

export const ChipEcom = styled(Chip)<any>`
  padding-left: 5px;
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
  svg {
    color: inherit;
    font-size: 21px;
  }
  ${(props) =>
    props.$chipStyle === 'started' &&
    'background: #E9F4FE;' + ' color: #0D3C61;'};
  ${(props) =>
    props.$chipStyle === 'finished' &&
    'background: #EDF7ED;' + ' color: #1E4620'};
  svg {
    ${(props) => props.$chipStyle === 'started' && 'color: #64B6F7'};
  }
  svg {
    ${(props) => props.$chipStyle === 'finished' && 'color: #4CAF50'};
  }
  ${(props) =>
    props.$chipStyle === 'finished_with_diff' &&
    'background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800;' +
      ' color: #663D00;'};
  svg {
    ${(props) =>
    props.$chipStyle === 'finished_with_diff' && 'color: #FF9800;'};
  }
`
