import { Box, Checkbox, Typography } from '@mui/material'
import { pimApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { memo, useCallback } from 'react'

import { StyledWarehouseCheckbox, WarehousesListBox } from '../styled/styled'

interface MarketplacesParams {
  marketplaceId: number;
  name: string
  icon: string
}
export const MarketplaceWarehouses = 
  ({ 
    marketplaceId,
    marketplaces 
  }: {
    marketplaceId: number,
    marketplaces: Array<MarketplacesParams>
  }) => {
    const queryClient = useQueryClient()
    const { data } = pimApi.admin.useGetMarketplacesWarehousesQuery(marketplaceId)
    const currentMarketplace = marketplaces.filter(el => el.marketplaceId === marketplaceId)[0]
    const { mutate: editWarehouse } =
      pimApi.admin.useEditMarketplaceWarehouseMutation()

    const handleEditWarehouse = useCallback((warehouse: pimApi.admin.MarketplaceWarehouse) => {
      const { priority, ...rest } = warehouse
      editWarehouse({
        marketplaceId: currentMarketplace.marketplaceId,
        warehouseId: warehouse.id,
        editedWarehouse: { ...rest, priority: !priority }
      },{
        onSuccess: reactQueryCacheUpdateByKey({
          queryClient,
          queryCacheKey: pimApi.admin.getMarketplacesWarehousesQueryKey(marketplaceId),
        }),
      })
    },[currentMarketplace])
    return (
      <>
        {data &&
        <WarehousesListBox>
          <Box>
            <Typography>{`Распределительные центры ${currentMarketplace.name}`}</Typography>
          </Box>
          <Box>
            {data.map(el => (
              <WarehouseContainer key={el.id} el={el} handleEditWarehouse={handleEditWarehouse}/>
            ))}
          </Box>
        </WarehousesListBox>
        }
      </>
    )
  }
  
const WarehouseContainer = memo(({ el, handleEditWarehouse }: { el: any, handleEditWarehouse: any }) => (
  <StyledWarehouseCheckbox onClick={() => handleEditWarehouse(el)} checked={el.priority || false}>
    <Checkbox checked={el.priority}/>
    <Typography>{el.name}</Typography>
  </StyledWarehouseCheckbox>
)
)