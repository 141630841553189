import { memo, useEffect } from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import { Link as RouterLink, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { getAdminPanelDictsPath } from 'shared/config'
import { FlexWrapper } from 'shared/ui/components'
import { hasRoleAccess } from 'shared/api/base'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { StyledTabPanel } from '../styled'
import { DictsPageTab } from '../../model/types'
import { BrandsDictTab } from '../Tabs/BrandsDictTab'
import { PrivilegesDictTab } from '../Tabs/PrivilegesDictTab'

const TABS = [
  {
    label: 'Бренды',
    Component: BrandsDictTab,
    value: 'brands',
    checkAdminRole: false
  },
  {
    label: 'Привилегии',
    Component: PrivilegesDictTab,
    value: 'privileges',
    checkAdminRole: true
  }
] as const

const DictsPage = () => {

  const { step } = useParams<{ step?: DictsPageTab }>()

  const history = useHistory()

  useEffect(() => {
    if (!step) history.replace(getAdminPanelDictsPath( 'brands'))
  }, [history, step])

  if (!step) return null

  return (
    <>
      <Paper>
        <FlexWrapper>
          <StyledTabPanel>
            <Tabs value={step}>
              {TABS.map((tabData, index) => {
                if ((!tabData.checkAdminRole) || (hasRoleAccess('ADMIN'))) {
                  return (
                    <Tab
                      key={`${tabData.label}tab`}
                      label={tabData.label}
                      value={tabData.value}
                      component={RouterLink}
                      to={getAdminPanelDictsPath(tabData.value)}
                      {...a11yProps(index)}
                    />
                  )
                }
                return (<></>)
              })}

            </Tabs>
          </StyledTabPanel>
        </FlexWrapper>
        <Switch>
          {
            TABS.map((tabData) => {
              if ((!tabData.checkAdminRole) || (hasRoleAccess('ADMIN'))) {
                return (
                  <Route
                    key={`${tabData.value}rout`}
                    path={getAdminPanelDictsPath(tabData.value)}
                    exact={true}
                    component={tabData.Component}
                  />
                )
              }
              return (<></>)
            })
          }
        </Switch>
      </Paper>
    </>
  )
}

export const MemoDictsPage = memo(DictsPage)
