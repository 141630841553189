import { isShowBackArrowException, LOGIN } from 'shared/config/routes'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import {
  AppBar,
  IconButton,
  CircularProgress,
  Select, MenuItem
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LogoutIcon from '@mui/icons-material/Logout'
import { clearToken, getToken } from 'shared/api/base'
import { SIDEBAR_CLOSE, SIDEBAR_OPEN } from 'shared/config'
import { UserImage } from 'features/admin/User/EditUser/ui/UserImage'
import { customerGen } from 'shared/lib/generated'

import { NotificationIcon } from './NotificationIcon'

import { logout, useSuRefreshTokenMutation } from '../../../api/auth'
import { ButtonArrowBack } from '../navigation'
import { getFingerprint } from '../../../lib/browser/getFingerprint'
import { useSidebarStore } from '../../../../store/sidebar/useSidebarStore'

const Grow = styled.div`
  height: 50px;
  width: 100%;
  transition: 0.3s;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 4;
  header {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
    z-index: 10;
    
    .user-info-container {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      
      >a {
        height: 100%;
        color: black;
        display: flex;
        gap: 16px;
        padding: 0 25px;
      }
      
      >button {
        height: 100%;
      }
    }
  }
`
const AppBarStyled = styled(AppBar)`
  width: 100%;
  background: #fff;
  box-shadow: none;
  transition: 0.3s;
  .MuiToolbar-regular {
    height: 100%;
    max-height: 50px !important;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
  }
  .MuiIconButton-root {
    border-radius: 0;
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-right: 0;
    }
    span {
      border-radius: 0;
    }
    .MuiBadge-badge {
      border-radius: 50%;
      height: 24px;
      width: 24px;
    }
  }
`

export const SelectWrapper = styled.div`
  box-shadow: none;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const IconButtonTitle = styled.div`
  font-size: 14px;
`

const NavButton = styled.div<{ $isActive: boolean }>`
  position: relative;
  margin-left: 16px;
  transition: all 0.2s ease-out 0.05s;
  left: ${(props) => (props.$isActive ? SIDEBAR_OPEN: SIDEBAR_CLOSE)};
`


export const Header = memo(() => {
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen)
  const { data: userInfo, isLoading: isUserInfoLoading } = customerGen.currentUser.GetCurrentUserInfo.useGetCurrentUserInfo()
  const { data: avatarUrl, isLoading: avatarIsLoading } = customerGen.currentUser.GetUsersPhoto.useGetUsersPhoto()

  const accessToken = useMemo(() => getToken() ? JSON.parse(atob(getToken()?.split('.')[1] || '')) : '',[getToken()])

  const { mutate: suRefresh } = useSuRefreshTokenMutation()
  const location = useLocation()

  const name = userInfo?.name
  const avatar = avatarUrl

  const profileBlock = useMemo(() => {
    if (isUserInfoLoading || avatarIsLoading) {
      return <CircularProgress />
    }
    if (!name) {
      return (
        <IconButton
          aria-label="Sign in"
          aria-haspopup="true"
          title="Sign in"
          color="primary"
          component={Link}
          to={LOGIN}
          size="large">
          <FontAwesomeIcon icon={faLock} />
          <IconButtonTitle>Вход</IconButtonTitle>
        </IconButton>
      )
    }

    const handleLogOut = () => {
      logout({ })
        .then(() => clearToken())
    }

    const handleCustomerChange = async (customer: string) => {
      const fingerprint = await getFingerprint()
      suRefresh({ body: { fingerprint }, customerCode: customer }, {
        onSuccess: () => {
          window.location.reload()
        }
      })
    }

    return (
      <div className="user-info-container">
        {(accessToken && accessToken.customers) && 
        <SelectWrapper> 
          <Select 
            value={accessToken.customer_code} 
            label="Age" 
            onChange={ (e) => handleCustomerChange(e.target.value)} 
          > 
            {accessToken.customers.map(el => 
              <MenuItem key={el.code} value={el.code}>{el.name}</MenuItem>
            )} 
          </Select> 
        </SelectWrapper> 
        }
        <NotificationIcon/>
        <IconButton
          aria-label="account of current user"
          aria-haspopup="true"
          title={name}
          component={Link}
          to="/settings/profile"
          size="large">
          {avatar &&
            <UserImage fontSize={14} size={32} userName={name} src={URL.createObjectURL(avatar.data) as any}/>
          }
          <IconButtonTitle>{name}</IconButtonTitle>
        </IconButton>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <IconButton
          aria-label="log out user"
          aria-haspopup="true"
          title="Выйти"
          onClick={handleLogOut}
          size="small"
          sx={{ width: 40, marginRight: '25px', color: 'rgba(0, 0, 0, 0.38)' }}
        >
          <LogoutIcon style={{ fontSize: '20px' }}/>
        </IconButton>
      </div>
    )
  }, [name, isUserInfoLoading, avatar])

  return (
    <Grow>
      <AppBarStyled position="static">
        {
          isShowBackArrowException.some(except => except === location.pathname)
            ? <span/>
            :
            <NavButton $isActive={sidebarOpen}>
              <ButtonArrowBack />
            </NavButton>
        }

        {profileBlock}
      </AppBarStyled>
    </Grow>
  )
}
)
