import { Route } from 'pages/interface'
import { LAYOUT_WIDTH, PRICE_HISTORY } from 'shared/config'
import { Layout } from 'shared/ui/layouts'

import { PriceHistory } from './PriceHistoryPage'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: PRICE_HISTORY,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: PriceHistory,
  },
] as Array<Route>
