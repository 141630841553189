import { Field, useField } from 'react-final-form'
import { Grid, Box, DialogActions, Button } from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import {
  SelectFieldWithAutoComplete,
  InputField,
  FieldWithCount,
} from 'shared/ui/components/form'
import { Option } from 'shared/ui/components/interface'
import { pimApi } from 'shared/api'
import { useMemo } from 'react'
import { LoadingButton } from '@mui/lab'

interface OptionItem {
  name?: string
  id: number
  commentsRequired?: boolean
}

export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
  commentsRequired: optionItem.commentsRequired,
})

export const ArchiveModalFormRender = ({ handleSubmit, close, isLoading, valid }) => {
  const { archivationReasonListQuery } =
    pimApi.dictionaries.useArchivationReasonList()
  const archivationReasonOptionList = useMemo(
    () => archivationReasonListQuery.data?.map(getOptions) || [],
    [archivationReasonListQuery.data]
  )

  const isCommentsRequiredField = useField('commentsRequired')

  const handleSelect = (val) => {
    isCommentsRequiredField.input.onChange(val?.commentsRequired)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <InputLabel label="Причина переноса в архив" />
          <InputWrapper>
            <Field
              name="archivationReasonId"
              id="archivationReasonId"
              placeholder="Причина архивации"
              component={SelectFieldWithAutoComplete}
              fullWidth={true}
              onChange={handleSelect}
              options={archivationReasonOptionList}
              isLoading={archivationReasonListQuery.isLoading}
            />
            <Field 
              name="commentsRequired"
              // TODO this is hack
              component={() => (<div />)}
            />
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={12}>
          <InputLabel label="Комментарий" required={false} />
          <FieldWithCount>
            <Field
              name="archivationComments"
              component={InputField}
              multiline={true}
              fullWidth={true}
              placeholder="Комментарий"
              errorAfterTouch={true}
              minRows={1}
              maxRows={10}
              max={255}
            />
          </FieldWithCount>
        </Grid>
      </Grid>
      <DialogActions sx={{ padding: 0 }}>
        <Box pt={1} display="flex" gap="16px">
          <Button onClick={close} color="primary">
            ОТМЕНА
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isLoading}
            disabled={!valid}
          >
            АРХИВИРОВАТЬ
          </LoadingButton>
        </Box>
      </DialogActions>
    </form>
  )
}