import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import { PaperProps } from '@mui/material'
import Menu from '@mui/material/Menu'
import { PopoverOrigin } from '@mui/material/Popover/Popover'
import { ReactNode } from 'react'

import { StyledMenuItem, IconWrapper } from './styled'

const ITEM_HEIGHT = 48

const MENU_LIST_PROPS = {
  'aria-labelledby': 'long-button',
}

const PAPER_PROPS = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5,
    minWidth: '10ch',
  },
}

interface IMenuItem {
  label?: string,
  id?: string,
  icon?: ReactNode,
  type?: string,
  divider?: boolean,
  disabled?: boolean
  handler?: () => void,
}

interface MenuOptions {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  options?: Array<IMenuItem>,
  iconType: React.ReactElement,
  iconSize?: 'small' | 'medium' | 'large' | undefined,
  className?: string,
  style?: PaperProps<'div'>['style'],
  disable?: boolean
}

export const SettingsMenu = ({ options, iconType, iconSize, className, style, disable, transformOrigin, anchorOrigin }: MenuOptions) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event, option) => {
    event.stopPropagation()
    setAnchorEl(null)
    if(option?.handler) option?.handler()
  }
  const paperPropsStyle = {
    ...PAPER_PROPS,
    style,
  }

  return (
    <IconWrapper $open={open} className={className}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size={iconSize || 'medium'}
        disabled={disable}
      >
        {iconType}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={MENU_LIST_PROPS}
        anchorEl={anchorEl}
        open={open}
        sx={{ '& .MuiPaper-root': {
          boxShadow: '0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033',
        } }}
        onClose={handleClose}
        PaperProps={paperPropsStyle}
        anchorOrigin={ anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={ transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options?.map((option) => {
          if (option.handler)
            return (
              <StyledMenuItem
                key={option.label}
                disabled={option.disabled}
                onClick={(event) => handleClose(event, option)}
                type={option.type}
                divider={option.divider}
              >
                {option.icon}
                {option.id || option.label}
              </StyledMenuItem>
            )
          return null
        })}
      </Menu>
    </IconWrapper>
  )
}

export type { IMenuItem }
