import React, { useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { Button, DialogActions, Grid } from '@mui/material'
import { SelectFieldWithAutoComplete } from 'shared/ui/components'
import { useCompanyList, useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'

import { getOptions } from '../../../bulkOperations/bulkProductCreation/lib/getSelectFieldOptions'
import { MemoGoodsSearchSection } from '../../../ProductChangeGoodsModal/ui/GoodsSearchSection'

interface Props {
  handleSubmit: (e) => void
  close: () => void
  isLoading: boolean
  valid: boolean
}

export const ProductCopyForm = ({ handleSubmit, close, isLoading, valid }: Props) => {
  const companyId = useField('companyId').input.value
  
  const { companyListQuery } = useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(2, companyId)

  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data]
  )

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} rowGap={3} mt={3} columnSpacing={{ sm: 2, md: 2 }}>
        <Grid item={true} xs={6}>
          <Field
            name="companyId"
            id="companyId"
            label="Продавец"
            required={true}
            errorAfterTouch={true}
            component={SelectFieldWithAutoComplete}
            fullWidth={true}
            options={companyOptions}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <Field
            name="companymarketplaceId"
            id="companymarketplaceId"
            label="Кабинет"
            required={true}
            errorAfterTouch={true}
            component={SelectFieldWithAutoComplete}
            fullWidth={true}
            disabled={companymarketplaceOptions.length === 0}
            options={companymarketplaceOptions}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <MemoGoodsSearchSection />
        </Grid>
      </Grid>
      <DialogActions sx={{ padding: '0' }}>
        <Button onClick={close}>ОТМЕНА</Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !valid}
        >
          СОЗДАТЬ
        </Button>
      </DialogActions>
    </form>
  )
}