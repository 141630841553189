import AddIcon from '@mui/icons-material/Add'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { dialogModel } from 'shared/ui/components/dialog'

import { CreateNewModal } from './ui/CreateNewModal'
import { StyledTab } from './ui/styled'

export const CreateNewTab = ({ newVariation }: { newVariation?: boolean }) => {
  const { commonProductQuery } = useCommonProductContext()
  const { wbProductQuery } = useWBProductContext()

  const handleNewNomenclature = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <CreateNewModal
          close={close}
          newVariation={newVariation}
          companymarketplaceId={commonProductQuery?.data?.companymarketplaceId}
          wbProductQuery={wbProductQuery}
        />
      ),
      onAccept: () => {},
    })
  }

  return (
    <StyledTab
      key="createNew"
      icon={<AddIcon />}
      iconPosition="end"
      onClick={handleNewNomenclature}
    />
  )
}
