import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

export const getValidationSchema = (parameters) => {
  const params = {}
  if (parameters) {
    parameters.forEach(
      param => {
        if (param.required) {
          params[`id${param.id.toString()}`] =
            yup.
              mixed().
              nullable()
              .required('Обязательное поле')
        }
        if (param.dataType === 2) {
          params[`id${param.id.toString()}`] =
            yup.
              date().
              nullable().
              typeError('Данные введены неверно').
              transform(yupTransformEmptyStringInNull)
        }
        if (param.required && param.dataType === 2) {
          params[`id${param.id.toString()}`] =
            yup.
              date().
              nullable().
              typeError('Данные введены неверно').
              transform(yupTransformEmptyStringInNull).
              required('Обязательное поле')
        }
      }
    )
  }
  return yup.object().shape(params)
}