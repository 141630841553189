import styled from 'styled-components'


interface IinArchiveBadge {
  spacing?: string
}
export const InArchive = styled.span<{ $spacing?: string }>`
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ED6C02;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px 8px;
  vertical-align: middle;
  margin-left: ${({ $spacing }) => $spacing || 0};
`

export const InArchiveBadge = ({ spacing }: IinArchiveBadge) => (
  <InArchive $spacing={spacing}>В архиве</InArchive>
)
