import { useState } from 'react'
import { localStore } from 'shared/services/localStore'
import { isNotNil } from 'shared/lib/checkers'

interface IState{
  generalInformation: boolean
  characteristicsAndAttributes: boolean
  description: boolean
}

type TabsVariables = 'generalInformation' | 'characteristicsAndAttributes' | 'description'
export const DisplayedGoodsDataController = () => {
  const localStorageGoodsData = localStore.get('displayedGoodsData')
  const [ displayedGoodsData, setDisplayedGoodsData ] = useState<IState>(
    isNotNil(localStorageGoodsData) ? JSON.parse(localStorageGoodsData) :
      { characteristicsAndAttributes: false, generalInformation: false, description: false }
  )
  const changeDisplayedGoodsData = (e: TabsVariables) => (value: boolean) => {
    setDisplayedGoodsData(prevState => {
      const newObj = { ...prevState, ...{ [e]: value } }
      localStore.set('displayedGoodsData', JSON.stringify(newObj))
      return newObj
    })
  }

  return { displayedGoodsData, changeDisplayedGoodsData }
}