import { Box, Typography } from '@mui/material'
import React from 'react'

export const Header = () => (
  <Box sx={{ height: '112px', padding: '24px', borderBottom: '1px solid #E0E0E0'  }}>
    <Typography sx={{ fontSize: '24px', lineHeight: '32px' }}>
      Приоритетные РЦ
    </Typography>
    <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.54)' }}>
      Выбор приоритетных распределительных центров для маркетплейсов
    </Typography>
  </Box>
)