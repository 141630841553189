import styled from 'styled-components'
import { Button, TextField, Box } from '@mui/material'


export const NewBarcodeWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
`
export const AddBarcodeContainer = styled(Box)`
`

export const AutoCreateBarcodeButton = styled.button<{ $disabled?: boolean}>`
all: unset;
cursor: pointer;
display: block;
font-size: 12px;
line-height: 20px;
letter-spacing: 0.4px;
color: ${({ theme, $disabled }) => $disabled ? 'rgba(0,0,0,0.38)' : theme.palette.primary.main };
`

export const EditBarcodeFieldStyled = styled(TextField)`
  .MuiFormLabel-root {
    font-weight: ${({ $boldLabel }: { $boldLabel: boolean }) => $boldLabel ? 500 : '' };
    color: ${({ $boldLabel, theme }: { $boldLabel: boolean, theme: any }) => $boldLabel ? theme.palette.success.dark : '' };
  }

  .MuiInputBase-root {
    padding-right: 14px;
  }

  .MuiIconButton-root {
    color: #aeadad;
    visibility: hidden;
  }

  &:hover {
    .MuiIconButton-root {
      visibility: visible;
      z-index: 1;
      &:hover {
        background-color: #f5f5f5;
      }
      
    }
  }
`

export const PlusButton = styled(Button)`
  min-width: unset;
  width: 40px;
  height: 40px;
`

export const BarcodesInput = styled.div`
  display: flex;
  // align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 648px;
  .MuiTextField-root {
    :last-child {
      margin-bottom: 0;
    }
    width: 208px;
  }
`

export const AlignWrapper = styled.div`
  display: flex;
  padding-top: 16px;
`
export const Close = styled.div`
  position: absolute;
  top: 8px;
  right: 14px;
  color: rgba(0,0,0,0.38);
  cursor: pointer;
`
