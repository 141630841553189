import React from 'react'

export const OrdersProgress = ({ percents }: { percents: number }) => (
  <>
    <div style={ {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      backgroundColor: 'rgba(0,125,255,0.2)',
      height: '4px',
      width: '100%'
    } }
    />
    <div style={ {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      backgroundColor: 'rgb(0,125,255)',
      height: '4px',
      width: `${ percents }%`,
      position: 'absolute',
      bottom: '0'
    } }
    />
  </>
)