import React from 'react'
import { useTableFiltersPresets } from 'shared/lib/hooks/useTableFiltersPresets'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { SelectWrapper } from 'features/analytics/chart/ui/charts/OrdersAndSales/ui/styled'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ClearIcon from '@mui/icons-material/Clear'
import queryString from 'query-string'
import styled from 'styled-components'

const StyledOption = styled.div`
  
  li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    
    :hover:has(svg:hover) {
      background-color: white !important;
    }
  }
  
  svg {
    cursor: pointer; 
    color: #757575;
  }
`
export const PresetsList = ({ filtersPresetsKey }: { filtersPresetsKey: string }) => {
  const { filtersPresets, editFiltersPresets } = useTableFiltersPresets({ filtersPresetsKey })

  const handlePresetsPick = (value) => {
    // handleChangeParams({ params: value, options: { arrayFormat: 'bracket', skipNull: true }, isPush: true })
    const searchParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
    const historyStr = queryString.stringify(
      { ...searchParams, ...value },
      { arrayFormat: 'bracket', skipNull: true }
    )

    window.location.href = `${window.location.pathname}?${historyStr}`
  }

  const handleDeletePreset = (e, label) => {
    e.stopPropagation()
    const newObj = JSON.parse(JSON.stringify(filtersPresets))
    delete newObj[label]
    editFiltersPresets(newObj)
  }

  return (
    <SelectWrapper width={170}>
      <Autocomplete
        options={Object.keys(filtersPresets).map(el => ({ label: el, value: filtersPresets[el] }))}
        onChange={(_, value) => handlePresetsPick(value[0].value)}
        disableClearable={true}
        multiple={true}
        renderTags={() => null}
        renderOption={(props, option) => (
          <StyledOption>
            <li
              {...props}
              key={option.value}>
              {option.label}
              <ClearIcon fontSize="small" onClick={(e) => handleDeletePreset(e, option.label)}/>
            </li>
          </StyledOption>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            value={null}
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <BookmarkIcon fontSize="small" sx={{ color: '#00000042' }}/>
            }}
            placeholder="Закладки"
          />
        )}
      />
    </SelectWrapper>
  )
}