import { Button } from '@mui/material'
import { goodsLib } from 'entities/pim/goods'
import { pimApi } from 'shared/api'
import { CreatePackForm } from 'features/goods/createPack'

import { InfoForm } from './InfoForm'

import {
  PaperTitle,
  BottomButtonWrapper,
  BottomSection,
} from '../../common'

interface IInfoStepValues {
  suppliers?: Array<pimApi.goods.GoodsSupplier>
  categoryId?: {
    fullPathName: string;
    id: UniqueId
  }
  goodsType?: pimApi.goods.GoodsType
}

export interface Packaging {
  weight?: number;
  width?: number;
  height?: number;
  depth?: number;
}

interface InfoStepProps {
  onBackButtonClick: () => void;
  values: IInfoStepValues;
  valid: boolean
}

export const InfoStep = ({ onBackButtonClick, values, valid }: InfoStepProps) => {
  const isPack = values?.goodsType === pimApi.goods.GoodsType.Pack
  return (
    <>
      <PaperTitle>{goodsLib.getGoodsTitleByType(values.goodsType!)}</PaperTitle>

      {isPack ? <CreatePackForm /> : <InfoForm values={values} />}

      <BottomSection>
        <BottomButtonWrapper>
          <div style={{ marginLeft: 'auto', marginRight: '0', gap: '16px', display: 'flex' }}>
            <Button color="primary" onClick={onBackButtonClick}>
              НАЗАД
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!valid}
            >
              {goodsLib.getGoodsButtonByType(values.goodsType!)}
            </Button>
          </div>
        </BottomButtonWrapper>
      </BottomSection>
    </>
  )
}
