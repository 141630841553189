/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Review } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Review>

export type IRequest = CamelCasedPropertiesDeep<{
  reviewId: number
}>

export const apiFunction = ({ reviewId }: IRequest) =>
  api.patch<ResponseType>(`/review/review/${reviewId}/return`).then(res => res.data)


export const useReturnProductfromReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

