import { FormLabel } from '@mui/material'
import styled from 'styled-components'

export const PriceTabWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const PriceWrap = styled.div`
  padding-right: 24px;
  width: 100%;
`

export const H1PriceControl = styled.div`
  display: flex;
  min-height: 28px;
  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
`

export const TabsWrap = styled.div`
  .MuiTabs-flexContainer {
    #styled-card {
      :last-child {
        border-right: none;
      }
      border: none;
      border-radius: 0;
      border-right: 1px solid #e0e0e0;

      .spp-container {
        border: none;
      }
    }
  }

  .MuiTabs-scrollButtonsHideMobile {
    max-width: 16px;
  }

  .MuiTabs-root {
    min-width: 640px;
    max-width: 670px;
  }

  .MuiTabs-indicator {
    display: none;
  }

  button {
    opacity: 1;
    text-transform: none;
    cursor: default;
  }
`

export const AccordionWrap = styled.div`
  margin-bottom: 16px;

  .MuiAccordionSummary-gutters {
    min-height: 60px;
  }

  .MuiAccordionSummary-contentGutters {
    align-items: center;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`
export const IconWrap = styled.span`
  display: inline-block;
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
  height: 20px;

  svg {
    transition: all 0.1s ease-out 0.1s;
    :hover {
      color: #007dff;
    }
  }
`

export const PseudoHeader = styled.div`
  display: flex;
  min-height: 36px;
  font-weight: 500;
  font-size: 14px;

  span {
    padding: 6px 16px;
  }

  span:first-child {
    min-width: 74px;
  }

  span:nth-child(2) {
    min-width: 130px;
  }

  span:last-child {
    min-width: 148px;
  }
`

export const WarehouseList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
`

export const PriceList = styled(WarehouseList)`
  padding-bottom: 32px;
`

export const WarehouseDataRowWrap = styled.li`
  display: flex;
  align-items: center;
  min-height: 32px;
  border-bottom: 1px solid lightgray;
  text-wrap: nowrap;
`

export const FirstCell = styled.span`
  padding: 6px 0 6px 16px;
  width: 74px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const SecondCell = styled.span`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 130px;

  justify-content: space-between;
`
export const ThirdCell = styled(SecondCell)`
  width: 148px;
  padding-right: 22px;
`

export const HoverWarehouse = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
  height: 353px;
  overflow-y: auto;
`

export const HoverHeaderWarehouse = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  width: 74px;
  padding-bottom: 5px;
`
export const HoverHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 5px;
`

export const HoverWarehouseColumn = styled.div`
  max-width: 74px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
`

export const HoverProfitabilityColumns = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 80px;
`

export const HoverFbo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const OtherWarehousesSpan = styled.span`
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 16px;
`

export const OtherPricesRowWrap = styled.li`
  display: flex;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  align-items: center;
  min-height: 32px;

  justify-content: space-between;

  span:first-child {
    padding-left: 16px;
    min-width: clamp(100px, 10vw, 120px);
    max-width: clamp(100px, 10vw, 120px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:nth-child(3) {
    min-width: 94px;
  }

  a:nth-child(2) {
    min-width: 94px;
  }

  a:last-child {
    max-height: 20px;
    padding-right: 20px;
  }
`

export const StyledDiscount = styled.div`
  display: inline;
  height: 20px;
  font-size: 12px;
  padding: 1px 6px 1px 6px;
  color: #2196f3;
  border-radius: 64px;
  border: 1px solid #007dff;
`

export const FormLabelStyled = styled(FormLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
`
export const DependencyWrap = styled.div`
  columns: 2;
  column-gap: 5%;
  padding-bottom: 32px;
`

export const AccordionSppRangesContainer = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  min-height: 32px;
  min-width: 164px;
  justify-content: start;
  padding: 6px 16px;

  span {
    order: -1;
    margin-right: auto;
  }
`

export const SaveFormWrap = styled.div`
  display: flex;
  min-height: 32px;
  justify-content: flex-end;
  margin-top: 16px;
`
