import { SummaryColumnItem } from './SummaryColumnItem'

export const summaryProperty = (tableData) => {

  const costSum = tableData?.reduce((sum, number) => sum + number.cost, 0)
  const expectedSum = tableData?.reduce((sum, number) => sum + number.expectedQuantity, 0)
  const actualSum = tableData?.reduce((sum, number) => sum + number.actualQuantity, 0)


  return {
    itemComponent: SummaryColumnItem,
    formatlessSummaryTypes: ['totalSum', 'expected', 'actual', 'text'],

    formatlessSummaryFunc: (type) => {
      switch (type) {
        case 'totalSum':
          return { value: costSum, colored: false }
        case 'expected':
          return { value: expectedSum, colored: false }
        case 'actual':
          return { value: actualSum, colored: expectedSum !== actualSum }
        case 'text':
          return { value: '', colored: false }
        default:
          return { value: '', colored: false }
      }
    },

    summaryRows: [
      { columnName: 'actualQuantity', type: 'actual' },
      { columnName: 'expectedQuantity', type: 'expected' },
      { columnName: 'cost', type: 'totalSum' },
      { columnName: 'goodsCode', type: 'text' },
    ]
  }
}