import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'


const createNewBrand = (newBrand: pimApi.brands.NewBrand) =>
  api.post('/pim/brands', newBrand)


export const useCreateNewBrand = () => useMutation<
    AxiosResponse<pimApi.brands.Brand>,
    AxiosError<errorType>,
    pimApi.brands.NewBrand
    >(createNewBrand)
