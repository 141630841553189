import styled from 'styled-components'
import { Paper } from '@mui/material'

export const GoodsPanelPaper = styled(Paper)`
  margin-top: 28px;

  .header {
    padding: 16px 24px 16px 24px;

    .title {
      font-size: 24px;
      font-weight: 400;
      color: ${ ({ theme }) => theme.palette.text.primary };
    }
  }

  .MuiTabs-root {
    padding: 0 24px;

    .MuiTabs-flexContainer {
      border-bottom: 1px solid rgba(224, 224, 224, 1)
    }

    .MuiButtonBase-root {
      width: 160px;
    }

  }
`

export const GoodsCategories = styled.div`

  .MuiTreeItem-group {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 17px;
  }

  .MuiTreeItem-iconContainer {
    margin-right: 5px;
    color: rgba(0, 125, 255, 1);

    svg {
      font-size: 24px;
    }
  }

  .MuiTreeItem-root {
    .MuiTreeItem-label {
      width: 100%;
      color: rgba(0, 125, 255, 1);
        
      div {
        .category-item-text {
          highlight {
            background-color: yellow;
          }
        }

        .icon-container {
          visibility: hidden;
          cursor: pointer;
          gap: 8px;

          svg {
            //transition: all 0.1s;
            color: ${ ({ theme }) => theme.palette.text.disabled };

            :hover {
              color: ${ ({ theme }) => theme.palette.info.main };
            }
          }
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .MuiTreeItem-content {
      border-radius: 4px;
      &:hover {
        .icon-container {
          visibility: visible;
        }
        background-color: #e9f4fe;
      }

      padding-top: 4px;
      padding-bottom: 4px;
    }

    .Mui-selected {
      background-color: rgba(255, 255, 255, 0);
    }

    .Mui-focused {
      background-color: rgba(255, 255, 255, 0);
    }
  }

  .category-container {
    margin-top: 15px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 16px;
  }
`

export const GoodsAttributes = styled.div`
  position: relative;
  
`

export const StyledTabHeader = styled.div`
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    font-size: 20px;
    font-weight: 500;
  }
`
