import { pimGen } from 'shared/lib/generated'
import React from 'react'
import { round } from 'shared/lib/utils/round'

import { CompetitorsCell } from '../index'

export const PriceCell = ({ row }) => {
  const { data } = pimGen.product.GetProductCompetitors.useGetProductCompetitors({ productId: row.productId })
  if (data) return (
    <CompetitorsCell>
      { data.map(competitor => (
        <span key={competitor.id} style={{ fontSize: '14px' }}>{round(competitor.price, 0)}</span>
      )) }
    </CompetitorsCell>
  )

  return <></>
}