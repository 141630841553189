import React, { PropsWithChildren, useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { tradeApi } from 'shared/api'
import { tabsSettingObjectType, tabsSettingType } from 'pages/supplyOrders/edit/model/types'


interface SupplyOrderContextProviderProps {
  supplyOrderQuery: QueryObserverResult<tradeApi.SupplyOrder>,
  tabsSettingObject?: tabsSettingType,
  inTabsSettingHandler?: ({ tabLabel, valueInInput, paginationRow, tabSettings }: tabsSettingObjectType) => void
  rowOpenedInGoods?: Array<number>
  rowOpenedInProducts?: Array<number>
  setRowOpenedInProducts?:  React.Dispatch<React.SetStateAction<Array<number>>>
  setRowOpenedInGoods?:  React.Dispatch<React.SetStateAction<Array<number>>>
  extendedOrdersOn?: boolean
  setExtendedOrdersOn?:  React.Dispatch<React.SetStateAction<boolean>>
}


const SupplyOrderContext = React.createContext<SupplyOrderContextProviderProps | undefined>(undefined)

function SupplyOrderContextProvider({
  children,
  supplyOrderQuery,
}: PropsWithChildren<SupplyOrderContextProviderProps>) {

  const tabsSetting: tabsSettingType = {
    rowInPagination: 50,
    inTabsObjectInit: [
      {
        label: 'productspositions',
        searchString: '',
        page: 0,
        sort: []
      },
      {
        label: 'goodspositions',
        searchString: '',
        page: 0,
        sort: []
      },
      {
        label: 'implementorders',
        searchString: '',
        page: 0,
        sort: []
      },
      {
        label: 'copackingorders',
        searchString: '',
        page: 0,
        sort: []
      },
      {
        label: 'history',
        searchString: '',
        page: 0,
        sort: []
      },
    ]
  }

  const [tabsSettingObject, setTabsSettingObject] = useState(tabsSetting)
  const [rowOpenedInGoods, setRowOpenedInGoods] = useState<Array<number>>([])
  const [rowOpenedInProducts, setRowOpenedInProducts] = useState<Array<number>>([])
  const [extendedOrdersOn, setExtendedOrdersOn] = useState(false)

  // eslint-disable-next-line consistent-return
  const inTabsSettingHandler = ({ tabLabel, tabSettings }: tabsSettingObjectType) => {

    if (tabSettings?.limit !== undefined && tabSettings?.limit !== tabsSettingObject.rowInPagination) {
      setTabsSettingObject({ ...tabsSettingObject, rowInPagination: tabSettings?.limit as number })
      return null
    }
    if (tabLabel && tabSettings && !tabSettings.limit) {
      const currentTab = tabsSettingObject.inTabsObjectInit.filter( tabs => tabs.label === tabLabel)[0]

      const changingFieldKey = Object.keys(tabSettings)[0]
      const changingFieldValue = Object.values(tabSettings)[0]

      currentTab[changingFieldKey] = changingFieldValue

      const newArr = tabsSettingObject.inTabsObjectInit.map((search) =>
        search.label === tabLabel ? currentTab : search
      )
      setTabsSettingObject({ ...tabsSettingObject, inTabsObjectInit: newArr })
    }
  }

  return (
    <SupplyOrderContext.Provider
      value={{ supplyOrderQuery, 
        tabsSettingObject, 
        inTabsSettingHandler,
        rowOpenedInGoods,
        rowOpenedInProducts,
        setRowOpenedInProducts,
        setRowOpenedInGoods,
        extendedOrdersOn,
        setExtendedOrdersOn
      }}
    >
      {children}
    </SupplyOrderContext.Provider>
  )
}

function useSupplyOrderContext() {
  const context = React.useContext(SupplyOrderContext)

  if (context === undefined) {
    throw new Error('useSupplyOrderContext must be used within a SupplyOrderContextProvider')
  }

  return context
}

export { SupplyOrderContextProvider, useSupplyOrderContext }