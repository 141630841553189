import React from 'react'
import { AppLayout } from 'shared/ui/layouts'
import { DialogMemo } from 'shared/ui/components/dialog'
import { Routing } from 'pages'
import 'processes/auth'
import ErrorBoundary from 'pages/ErrorBoundary/ErrorBoundary'

import { withProviders } from './providers'

const App = () => (
  <>
    <ErrorBoundary>
      <AppLayout>
        <Routing/>
      </AppLayout>
      <DialogMemo/>
    </ErrorBoundary>
  </>
)

export default withProviders(App)
