import { Box } from '@mui/material'
import { Field } from 'react-final-form'
import { InputField, SelectField } from 'shared/ui/components/form'
import { Option } from 'shared/ui/components/interface'

const options: Array<Option> = [
  { value: 'Замена с одинаковыми ШК', label: 'Замена с одинаковыми ШК' },
  { value: 'Неопознанная номенклатура', label: 'Неопознанная номенклатура' },
  { value: 'Доложено поставщиком', label: 'Доложено поставщиком' },
]

interface ProductFormProps {
  isShowFields: boolean;
  isShowCommentsField: boolean;
}

export const InfoFields = ({
  isShowFields,
  isShowCommentsField,
}: ProductFormProps) => (
  <>
    {isShowFields && (
      <Box mx={3} my={2}>
        <h3>Параметры добавляемого товара</h3>
        <Box mb={2}>
          <Field
            name="reason"
            component={SelectField}
            options={options}
            label="Причина"
          />
        </Box>

        {isShowCommentsField && (
          <Box mb={2}>
            <Field
              name="comments"
              label="Наименование товара"
              component={InputField}
            />
          </Box>
        )}
                
        <Box mb={2}>
          <Field
            name="quantity"
            label="Количество"
            component={InputField}
          />
        </Box>
      </Box>
    )}
  </>
)