import { Box, Input } from '@mui/material'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import { searchPanelLocale } from '../CopackingOrders/lib/localization'

const ariaLabel = { 'aria-label': 'description' }

export const CustomInputStyled = styled(Box)`
  min-width: 220px;
  display: flex;
  position: relative;
  .MuiInput-root {
    display: block !important;
    width: 100%;
    input {
      width: calc(100% - 28px);
      color: rgba(0,0,0,0.38);
    }
  }
  svg {
    position: absolute;
    right: 0;
    bottom: 5px;
    color: rgba(0,0,0,0.38);
  }
`

const CustomInput=({ onValueChange, value })=> {
  const handleChange = (e: any) => onValueChange(e.target.value)
  const handleClean = () => onValueChange('')

  return (
    <CustomInputStyled>
      <Input
        onChange={handleChange}
        value={value}
        inputProps={ariaLabel}
        placeholder={searchPanelLocale.searchPlaceholder}
      />
      {value.length 
        ?  <CloseIcon 
          onClick={handleClean}
          sx={ { cursor: 'pointer' } }
        /> : <SearchIcon/>}
    </CustomInputStyled>
  )
}

export default CustomInput