// @ts-nocheck
import React from 'react'
import { FieldRenderProps } from 'react-final-form'

export interface InjectedProps<
  T extends HTMLElement = HTMLElement,
  V = Array<string> | string
> extends FieldRenderProps<V> {
  onBlur?: React.FocusEventHandler<T>;
  onChange?: React.ChangeEventHandler<T>;
  onFocus?: React.FocusEventHandler<T>;
}

export const withFieldEventAdapter = <P extends InjectedProps>(
  Component: React.ComponentType<P>
) =>
    (class FieldAdapter extends React.PureComponent<P> {
      render() {
        const { props } = this

        return (
          <Component
            {...props as P}
            onFocus={(event) => {
              props.input.onFocus(event)
              props.onFocus?.(event)
            }}
            onChange={(event) => {
              props.input.onChange(event)
              props.onChange?.(event)
            }}
            onBlur={(event) => {
              props.input.onBlur(event)
              props.onBlur?.(event)
            }}
          />
        )
      }
    })