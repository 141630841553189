import { useState, useEffect } from 'react'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { isNil } from 'shared/lib/checkers'

const checkToEqualValue = (option: Option<string | number>, value: Option<string | number>) => (
  option.value === value.value
)

type SelectProps = FieldRenderProps<string | number> & {
  options: Option<string | number>[]
}

export const ReportsSelectFieldWithAutoComplete = ({
  input,
  onChange,
  isLoading,
  label,
  onFocus,
  placeholder,
  options,
  sx,
  onBlur,
  id,
  fullWidth,
  meta: { invalid, touched },
  disableClearable
}: SelectProps) => {
  const defaultValue = options.find(el => input.value === el.value)

  const [selectedValue, setSelectedValue] = useState<null | Option<string | number>>(defaultValue || null)

  useEffect(() => {
    const isEmpyOptionsOrValue = isNil(options) || isNil(input.value)
    if (isEmpyOptionsOrValue) return

    const newSelectedElement = options.find(
      item => item.value === input.value
    )

    if (isNil(newSelectedElement)) return

    setSelectedValue({
      value: newSelectedElement.value,
      label: newSelectedElement.label
    })
  }, [options])

  return (
    <Autocomplete<Option<string | number>>
      id={id}
      onOpen={onFocus}
      options={options}
      fullWidth={fullWidth}
      size="small"
      noOptionsText="Не найдено"
      clearOnBlur={false}
      blurOnSelect={true}
      disableCloseOnSelect={false}
      autoHighlight={true}
      value={selectedValue}
      onChange={(event, value) => {
        setSelectedValue(value)
        onBlur(value?.value || null)
        input.onChange(value?.value)
      }}
      isOptionEqualToValue={checkToEqualValue}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          error={invalid && touched}
          label={label}
          sx={sx}
          onFocus={onChange}
          placeholder={placeholder || 'Введите текст'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}