import React from 'react'
import { reviewGen } from 'shared/lib/generated'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { PlugWithAddButton } from 'shared/ui/components'

import { EditTemplateModal } from '../../TemplateModals/EditTemplateModal/EditTemplateModal'

export const PlugTemplate = ({ reviewType }: { reviewType: number }) => {
  const queryClient = useQueryClient()
  
  const { mutate: createModel } =
    reviewGen.template.CreateReviewTemplateModel.useCreateReviewTemplateModel()
  const { mutate: createTemplate } =
    reviewGen.template.CreateReviewTemplate.useCreateReviewTemplate()

  const handleAddTemplate = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <EditTemplateModal
          close={close}
          accept={accept}
        />
      ),
      onAccept: (createdTemplate) => {
        if (createdTemplate.templatemodelId.value === createdTemplate.templatemodelId.label ) {
          createModel({ data: createdTemplate.templatemodelId.value },
            {
              onSuccess: (newModel) => {
                createTemplate({
                  data: {
                    name: createdTemplate.name!,
                    text: createdTemplate.text!,
                    reviewtypeId: reviewType,
                    templatemodelId: newModel.id
                  } },
                {
                  onSuccess: (response) => {
                    queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
                      reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
                      (template) => {
                        template?.unshift(response)
                        return template as reviewGen.template.GetReviewTemplates.ResponseType
                      })
                    snackActions.info('Шаблон добавлен')
                  }
                }
                )
              } })
        }
        else createTemplate({
          data: {
            name: createdTemplate.name!,
            text: createdTemplate.text!,
            reviewtypeId: reviewType,
            templatemodelId: createdTemplate.templatemodelId.value
          } }, {
          onSuccess: (response) => {
            queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
              reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
              (template) => {
                template?.unshift(response)
                return template as reviewGen.template.GetReviewTemplates.ResponseType
              })
            snackActions.info('Шаблон добавлен')
          }
        }
        )
      },
    })
  }
  return (
    <PlugWithAddButton
      title="У вас пока нет шаблонов" 
      subTitle={'Чтобы начать работу добавьте\n новый шаблон'} 
      addFunction={handleAddTemplate}/>
  )
}