import * as UI from './styled'

interface InfoItemProps {
  title: string
  children: any
}

const InfoItem = ({
  title,
  children,
}: InfoItemProps) => (
  <UI.InfoItem>
    <UI.ItemTitle>{title}</UI.ItemTitle>
    <UI.ItemValue>{children}</UI.ItemValue>
  </UI.InfoItem>
)

export default InfoItem
