import styled from 'styled-components'
import { LoadingButton } from '@mui/lab'

export const ProductWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
`

export const StyledLoadingButton = styled(LoadingButton)`
  margin-left: 14px;
`
