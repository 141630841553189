import styled from 'styled-components'
import { LoadingButton } from '@mui/lab'
import { Checkbox } from '@mui/material'

export const StyledLoadingButton = styled(LoadingButton)`
  margin-left: 2px;
`

export const StyledCheckbox = styled(Checkbox)`
  width: 18px;
  height: 23px;
  margin-right: 18px;
`

export const PriceContainer = styled.div`
  #styled-card {
    opacity: 1;
  }
  #old-price-container {
    width: auto;
  }
  #discount-container {
    margin-left: 0;
    margin-right: 0;
  }
`
export const StyledAccordionBox = styled.div`
  .MuiPaper-root {
    margin: 0;
  }
`

export const SppRangesContainer = styled.div`
  min-width: 105px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  > div {
    margin: 0;
  }

  > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #00000099;
  }
`