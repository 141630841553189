import { useParams, Link as RouterLink, Switch, Route } from 'react-router-dom'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { getBulkOperationsPath } from 'shared/config'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { StyledTableBox } from './styled'

import { GoodsBulkOperationsTab , ProductsBulkOperationsTab , HistoryBulkOperationsTab } from '../../tabs'
import { BulkOperationsPageTab } from '../model/types'

const TABS = [
  {
    label: 'Продукты',
    Component: ProductsBulkOperationsTab,
    value: 'products'
  },
  {
    label: 'Товары',
    Component: GoodsBulkOperationsTab,
    value: 'goods',
  },
  {
    label: 'История',
    Component: HistoryBulkOperationsTab,
    value: 'history',
  },
] as const

export const BulkOperations = () => {
  const { tab } = useParams<{ tab: BulkOperationsPageTab }>()

  return (
    <>
      <StyledTableBox>
        <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h3" fontSize="24px">
            Массовые операции
          </Typography>
        </Box>
        <Tabs value={tab}>
          {TABS.map(({ label }, index) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{ width: '160px' }}
              label={label}
              value={TABS[index].value}
              component={RouterLink}
              to={getBulkOperationsPath(TABS[index].value)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </StyledTableBox>
      <TabPanel>
        <Switch>
          {TABS.map(({ Component, value }) => (
            <Route
              path={getBulkOperationsPath(value)} exact={true} component={Component}/>
          ))}
        </Switch>
      </TabPanel>
    </>
  )
}