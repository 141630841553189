import React from 'react'
import { AccordionDetails, Paper, IconButton, Tooltip, Typography, Box } from '@mui/material'
import { Field } from 'react-final-form'
import { pimApi } from 'shared/api'
import MailIcon from '@mui/icons-material/Mail'
import Accordion from '@mui/material/Accordion'
import { FormDateTimePicker, InputField, Button } from 'shared/ui/components'
import { StyledInfoIcon } from 'widgets/product/Common/abcClass/ui/styled'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { snackActions } from 'shared/lib/react/snackbar'
import styled from 'styled-components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`

interface PasswordEditProps {
  userData: pimApi.admin.CurrentUser
  handleUserEdit: () => void
  errors?: any
  values: any
  valid: boolean
  isSending: boolean
  dirty: boolean
  handleSendPassword: () => void
}

export const PasswordEditForm = ({ 
  handleUserEdit,
  valid,
  dirty,
  errors,
  handleSendPassword,
  userData,
  values,
  isSending
}: PasswordEditProps) => (
  <Paper sx={{ marginTop: '24px' }} elevation={0}>
    <Accordion style={{ padding: '8px' }} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '90px', alignItems: 'center' }}>
          <div>
            <Typography
              component="h1"
              variant="h3"
              fontSize="20px">
              Пароль
            </Typography>
            <Typography
              style={{ color: '#000000', opacity: '0.38', whiteSpace: 'break-spaces' }}
              variant="body2"
              fontSize={12}
            >
              {'Отправьте ссылку для установки пароля на почту\nили скопируйте ссылку для отправки на другие сервисы'}
            </Typography>
          </div>
          { userData.dateOut ?
            <div>
              <Button variant="text" disabled={true} startIcon={<FileCopyIcon/>}>Скопировать ссылку</Button>
              <Button variant="outlined"
                style={{ marginLeft: '16px' }} disabled={true} startIcon={<MailIcon/>}>Отправить ссылку на почту</Button>
            </div>
            :
            <div>
              <Button onClick={(event) => {
                snackActions.info('Ссылка для установки пароля скопирована')
                navigator.clipboard.writeText(userData.passwordChangeUrl!)
                event.stopPropagation()
              }} variant="text" startIcon={<FileCopyIcon/>}>Скопировать ссылку</Button>
              <Button variant="outlined"
                style={{ marginLeft: '16px' }}
                onClick={(event) => {
                  handleSendPassword()
                  event.stopPropagation()
                }}
                disabled={isSending}
                startIcon={<MailIcon/>}>Отправить ссылку на почту</Button>
            </div>
          }
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px 16px 16px 16px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>

          <Box width={240} height={115} borderRadius="4px" padding="12px 16px" style={{ backgroundColor: '#FAFAFA' }}>
            <Box marginBottom="14px">
              <span style={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '12px', fontWeight: '500'
              }}>Требование к паролю</span>
            </Box>
            <Box display="flex" flexDirection="row" gap="13px" mb={1}>
              {!values.password &&
                <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
              {(dirty && errors?.password === 'Не менее 8 символов') &&
                <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
              {(!!values.password && errors?.password !== 'Не менее 8 символов') &&
                <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
              <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                Не менее 8 символов
              </span>
            </Box>
            <Box display="flex" flexDirection="row" gap="13px">
              {!values.password &&
                <InfoOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '17px', height: '17px' }}/>}
              {(dirty && errors?.password === 'Не более 30 символов') &&
                <ReportProblemOutlinedIcon sx={{ color: '#F44336', width: '17px', height: '17px' }}/>}
              {(!!values.password && errors?.password !== 'Не более 30 символов') &&
                <CheckCircleOutlineOutlinedIcon sx={{ color: '#4CAF50', width: '17px', height: '17px' }}/>}
              <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', fontWeight: '500' }}>
                Не более 30 символов
              </span>
            </Box>
          </Box>

          <div style={{ width: '295px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px', alignItems: 'center' }}>
              <Typography variant="subtitle2">Установить пароль</Typography>
              <Tooltip title="Установить пароль вручную" placement="right">
                <IconButton>
                  <StyledInfoIcon/>
                </IconButton>
              </Tooltip>
            </div>
            <HelperTextSupply style={{ paddingBottom: '5px' }}>
              <Field
                name="password"
                id="password"
                label="Новый пароль"
                component={InputField}
                disabled={!!userData.dateOut}
                required={true}
                fullWidth={true}
                InputProps={{ autoComplete: 'off' }}
              />
            </HelperTextSupply>
          </div>

          <div style={{ width: '205px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px', alignItems: 'center' }}>
              <Typography variant="subtitle2">Дата истечения пароля</Typography>
              <Tooltip
                title={<span>При достижении указанной даты<br/> пароль станет не действительным</span>}
                placement="top" style={{ whiteSpace: 'break-spaces' }}>
                <IconButton>
                  <StyledInfoIcon/>
                </IconButton>
              </Tooltip>
            </div>
            <HelperTextSupply>
              <Field
                name="passwordExpired"
                required={true}
                label="Дата Время"
                helperText={errors && errors?.passwordExpired ? errors?.passwordExpired : ''}
                error={!!errors?.passwordExpired}
                disabled={!!userData.dateOut}
                InputLabelProps={{
                  shrink: true,
                }}
                component={FormDateTimePicker}
              />
            </HelperTextSupply>
          </div>

          <Box display="flex">
            <Button
              onClick={handleUserEdit}
              disabled={!valid || !dirty}
              sx={{
                top: '45px',
                width: '117px',
                height: '36px',
              }}
            >
              Сохранить
            </Button>
          </Box>

        </div>
      </AccordionDetails>
    </Accordion>
  </Paper>
)
