import React, { useMemo, useState } from 'react'
import { copackingApi } from 'shared/api'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { Table } from 'shared/ui/components/Table'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { snackActions } from 'shared/lib/react/snackbar'
import { theme } from 'shared/config'
import { reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { useQueryClient } from 'react-query'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Stack } from '@mui/material'
import styled from 'styled-components'

import { columnsConfig, pagingPanelLocale } from '../../lib'
import { StyledTableBox } from '../styled'
import { ToolBar } from '../../../../admin/Dicts/Privileges/ui/styled'

const ToolbarWrapper = styled(ToolBar)`
  left: 50%;
  
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`

const MAX_PAGE_SIZE = 50
const getRowId = (row: copackingApi.CoPackingOrder) => row.id
export const PositionsTable = ({ orderDetailData }: { orderDetailData: copackingApi.CoPackingOrder }) => {
  const [ expanded, setExpanded ] = useState(false)
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const copackingorderId = parseInt(id, 10)
  const { calculatedRowsPerPage } = useRowsPerPage()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage)
  const { mutate: editPosition, isLoading } = copackingApi.useEditOrdersPositionMutation()

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0,
    }),
    [orderDetailData.positions]
  )

  const handleCellChange = (changedCells) => {
    editPosition({ positionId: changedCells.id, copackingorderId, data: {
      id: changedCells.id,
      versionNo: changedCells.versionNo,
      comments: '',
      quantity: changedCells.quantity,
      expectedQuantity: changedCells.expectedQuantity
    } },
    {
      onSuccess:
        reactQueryCacheUpdateByKey({
          queryClient,
          queryCacheKey: copackingApi.getOrderDetailsQueryKey(copackingorderId),
          path: 'positions',
          key: 'id',
          onSuccess: () => {snackActions.info('Успешно сохранено')}
        }),
      onError: () => {
      }
    }
    )
  }

  if (orderDetailData.positions) {
    return (
      <ThemeProvider theme={createTheme({ ...theme, typography: { fontFamily: 'inherit' } })}>
        <StyledTableBox>
          <ToolbarWrapper>
            <Stack spacing={2} direction="row">
              <FormControlLabel
                value="Расширенная информация"
                control={<Switch
                  size="small"
                  color="primary"
                  checked={expanded}
                  onChange={() => {setExpanded(prev => !prev)}}
                  sx={{ marginRight: '8px' }}
                />}
                label="Расширенная информация"
                labelPlacement="end"
              />
            </Stack>
          </ToolbarWrapper>
          <Table
            tableList={ orderDetailData?.positions }
            totalCount={ orderDetailData.positions.length }
            getRowId={ getRowId }
            paginationLocale={ pagingPanelLocale }
            tableParams={ memoTableParams }
            onCellChange={handleCellChange}
            allRows={expanded}
            isTableEditable={orderDetailData.editPossible}
            calculatedRowsPerPage={ calculatedRowsPerPage }
            columnsConfig={ columnsConfig }
            isLoading={isLoading}
            showInArchiveControl={ false }
            showColumnsVisibility={ false }
            searching="internal"
            pagination="internal"
            sorting="internal"
            filtering="internal"
          />
        </StyledTableBox>
      </ThemeProvider>
    )
  }
  return <></>
}