/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BulkOperationType } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<BulkOperationType[]>



export const apiFunction = () =>
  api.get<ResponseType>(`/pim/ops/types`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', `/pim/ops/types`] as const

export const useGetBulkOperationTypes = (options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

