import { docStateType } from 'store/doc'
import { DocType } from 'shared/services/interfaces/doc'

export const warehouseIdSelector = (state: {
  docReducer: docStateType
}): number | null => state.docReducer.warehouseId

export const docListSelector = (
  state: { docReducer : docStateType }
): DocType[] | null | undefined => state.docReducer.list

export const detailSelector = (state: {
  docReducer: docStateType
}): DocType => state.docReducer.detail

export const detailStatusSelector = (state: {
  docReducer: docStateType
}): string => state.docReducer.detail.status

export const detailIdSelector = (state: {
  docReducer: docStateType
}): number => state.docReducer.detail.id