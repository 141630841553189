import { activityApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'


type ColumnName =
  | keyof Omit<
  activityApi.ActivityProduct,
  | 'name'
  | 'versionNo'
  | 'barcodes'
  | 'marketplaceUrl'
  | 'stocksDate'
  | 'suppliers'
>


type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  productName: 'productName',
  price: 'price',
  marketplaceId: 'marketplaceId',
  productCode: 'productCode',
  productId: 'productId',
  changed: 'changed',
  stocks: 'stocks',
  changer: 'changer',
  created: 'created',
  detail: 'detail',
  id: 'id',
  fromDate: 'fromDate',
  toDate: 'toDate'
}

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.productId,
      columnName: columns.productId,
      title: 'Фото',
      width: 70,
      align: 'left',
      sortingEnabled: false,
      cellType: CellType.Photo,
    },
    {
      name: columns.productName,
      columnName: columns.productName,
      title: 'Наименование продукта',
      width: 400,
      sortingEnabled: false,
      cellType: CellType.ActivityProductLink,
    },
    {
      name: columns.price,
      columnName: columns.price,
      title: 'Текущая\nцена',
      width: 160,
      sortingEnabled: false,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.detail,
      columnName: columns.detail,
      title: 'Акционная\nскидка',
      width: 110,
      sortingEnabled: false,
      cellType: CellType.PercentContainer
    },
    {
      name: columns.toDate,
      columnName: columns.toDate,
      title: 'Акционная\nцена',
      width: 130,
      sortingEnabled: false,
      cellType: CellType.ActionPriceCell
    },
    {
      name: columns.stocks,
      columnName: columns.stocks,
      title: 'Остатки',
      width: 'auto',
      sortingEnabled: false,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.id,
      columnName: columns.id,
      title: ' ',
      width: 80,
      sortingEnabled: false,
      cellType: CellType.ActionRemove
    },
  ],
}

