import { Autocomplete, Grid } from '@mui/material'
import { InputField, InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import React from 'react'
import { ValidationErrors } from 'final-form'

interface InputIsDisabledProps {
    isDisabled: boolean;
}

const SelectWrapper = styled.div<InputIsDisabledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  pointer-events: ${(props) => props.isDisabled && 'none' };
  .MuiAutocomplete-inputRoot{
    padding-top: 0;
    padding-bottom: 0;
    .MuiAutocomplete-clearIndicator {
      display: none;
    }
  }
  .MuiInputBase-root{
    color: ${(props) => props.isDisabled && 'rgba(0,0,0,0.38)' };
  }
`

const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`

interface IBaseInfoSupplierForm {
    handleSupplierEdit: () => void
    errors: ValidationErrors
    orderGenerationOptions: {label: string, value: boolean}[]
    inArchive: boolean    
}

export const BaseInfoSupplierForm = ({
  handleSupplierEdit,
  errors,
  orderGenerationOptions,
  inArchive
}: IBaseInfoSupplierForm) => (
  <Grid container={true} spacing={2} padding={3} pt={2}>
    <Grid item={true} xs={3}>
      <InputLabel label="Наименование поставщика"/>
      <HelperTextSupply>
        <Field
          name="shortName"
          id="shortName"
          helperText={errors && errors?.shortName ? errors?.shortName : ''}
          error={!!errors?.shortName}
          placeholder="Наименование поставщика"
          component={InputField}
          required={true}
          errorAfterTouch={true}
          fullWidth={true}
          onBlur={handleSupplierEdit}
          disabled={inArchive}
        />
      </HelperTextSupply>
    </Grid>

    <Grid item={true} xs={6}>
      <InputLabel label="Полное наименование" required={false}/>
      <InputWrapper>
        <Field
          name="fullName"
          placeholder="Полное наименование"
          required={false}
          component={InputField}
          onBlur={handleSupplierEdit}
          disabled={inArchive}
        />
      </InputWrapper>
    </Grid>

    <Grid item={true} xs={3}>
      <InputLabel label="Код для интеграции" required={false}/>
      <InputWrapper>
        <Field
          name="externalId"
          placeholder="Код для интеграции"
          required={false}
          component={InputField}
          onBlur={handleSupplierEdit}
          disabled={inArchive}
        />
      </InputWrapper>
    </Grid>

    <Grid item={true} xs={4}>
      <InputLabel label="ИНН" required={true}/>
      <HelperTextSupply>
        <Field
          name="inn"
          id="inn"
          helperText={errors && errors?.inn ? errors?.inn : ''}
          error={!!errors?.inn}
          placeholder="ИНН"
          component={InputField}
          required={true}
          fullWidth={true}
          onBlur={handleSupplierEdit}
          disabled={inArchive}
        />
      </HelperTextSupply>
    </Grid>

    <Grid item={true} xs={4}>
      <InputLabel label="КПП" required={false}/>
      <HelperTextSupply>
        <Field
          name="kpp"
          id="kpp"
          helperText={errors && errors?.kpp ? errors?.kpp : ''}
          error={!!errors?.kpp}
          placeholder="КПП"
          component={InputField}
          required={true}
          fullWidth={true}
          onBlur={handleSupplierEdit}
          disabled={inArchive}
        />
      </HelperTextSupply>
    </Grid>

    <Grid item={true} xs={4}>
      <InputLabel label="Формирование заказа" required={false}/>
      <SelectWrapper isDisabled={inArchive}>
        <Field name="useBoxes">
          {({ input }) => (
            <Autocomplete
              sx={{ width: '100%' }}
              value={orderGenerationOptions.find(obj => obj.value === input.value)}
              onChange={(event, selectedValue) => input.onChange(selectedValue?.value)}
              options={orderGenerationOptions}
              getOptionLabel={(option) => option.label}
              onBlur={handleSupplierEdit}
              renderInput={(params) =>
                <TextField {...params} required={true}/>
              }
            />
          )
          }
        </Field>
      </SelectWrapper>
    </Grid>
  </Grid>
)

