import { Box, Button, Typography } from '@mui/material'
import styled from 'styled-components'
import plugIco from 'assets/images/noBrandsPlug.svg'


const TITLE = 'У вас пока нет брендов'
const SUBTITLE = 'Чтобы начать работу добавьте \n новый бренд'

const WrapperPlug = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 120px;
`

const Plug = styled.img`
  width: 216px;
`

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`

const Subtitle = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  margin-bottom: 24px;
  white-space: pre-line
`
export const BrandsPlug = ({ handleAddBrand }) => (
  <WrapperPlug>
    <Box textAlign="center">
      <Plug
        src={plugIco}
        alt="placeholder"
      />
      <Title>
        {TITLE}
      </Title>
      <Subtitle>
        {SUBTITLE}
      </Subtitle>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddBrand}
      >ДОБАВИТЬ
      </Button>
    </Box>


  </WrapperPlug>
)