// @ts-nocheck
import { compose, curry, is, keys, map, values, zipObj } from 'ramda'

export const mapKeys = curry((fn, obj) =>
  zipObj(map(fn, keys(obj)), values(obj))
)

export const camelize = (str) =>
  str
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(/(?:^\w|[A-Z]|_|\b\w)/g, (letter, index) =>
      index === 0 || index === 1 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '')

const toCamelCase = (data) => {
  if (is(Array, data)) {
    return map(toCamelCase, data)
  }

  if (is(Object, data)) {
    return compose(map(toCamelCase), mapKeys(camelize))(data)
  }

  return data
}

export default toCamelCase
