import React, { useMemo } from 'react'
import {  customerApi } from 'shared/api'
import { Box, Button, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Table } from 'shared/ui/components/Table'

import { StyledLoader, StyledTableBox, TableWrapper, ToolBar } from '../styled'
import { pagingPanelLocale } from '../../lib/localization'
import { useRowsPerPage } from '../../lib/useRowsPerPage'
import { columnsConfig } from '../../model/ColumnsConfig'


const MAX_PAGE_SIZE = 50
const getRowId = (row: customerApi.Privilege) => row.id
interface PrivilegesTableProps {
  privilegesList?: customerApi.Privilege[]
  isLoading: boolean
  isFetching: boolean
  handleAddPrivilege: () => void
  handleCellChange: (data: any) => void
  handleRowDelete: (id: number) => void
}
export const PrivilegesDictTable = ({ 
  privilegesList,
  isFetching,
  isLoading,
  handleAddPrivilege,
  handleRowDelete,
  handleCellChange }: PrivilegesTableProps) => {
  
  const { calculatedRowsPerPage } = useRowsPerPage()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage + 2)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0
    }),
    [privilegesList]
  )

  return (
    <>
      <StyledTableBox component={Box}>
        {(isLoading || isFetching ) && <StyledLoader size={60} />}
        {privilegesList &&  (
          <TableWrapper >
            <ToolBar>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleAddPrivilege}
                >
                  ДОБАВИТЬ
                </Button>
              </Stack>
            </ToolBar>
            <Table
              tableList={privilegesList}
              totalCount={privilegesList.length}
              getRowId={getRowId}
              paginationLocale={pagingPanelLocale}
              tableParams={memoTableParams}
              calculatedRowsPerPage={pageSize}
              onModalOpen={handleCellChange}
              columnsConfig={columnsConfig}
              isTableEditable={false}
              onRowDelete={handleRowDelete}
              showInArchiveControl={false}
              showColumnsVisibility={false}
              searching="internal"
              pagination="internal"
              sorting="internal"
              filtering="internal"
            />
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}