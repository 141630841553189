import React from 'react'
import { CircularProgress } from '@mui/material'
import { Image as SharedImage } from 'shared/ui/components'
import { useFileInfoUrl } from 'pages/goods/model/hooks'

export const ImageVariation = ({
  selectedMedia,
  getFilePath, 
  fileThumbnailSize 
}: {
  selectedMedia: any,
  getFilePath: any,
  fileThumbnailSize: any
}
) => {
  const { data: mediaInfo } = useFileInfoUrl(selectedMedia.url || selectedMedia.downloadUrl || '')

  return (
    <div className="selectedMediaContainer">
      <div className="preloaderContainer">
        { mediaInfo?.blobResponse ?
          <img key={ selectedMedia.id } className="selectedImg" src={ URL.createObjectURL(mediaInfo?.blobResponse.image) } alt=""/>
          :
          <CircularProgress/>
        }
      </div>
      <div className="selectedMediaInfo">
        <div className="selectedMediaOptions">
          <SharedImage src={ selectedMedia?.url || getFilePath(selectedMedia.id, fileThumbnailSize) }/>
          <span className="fileName">{ selectedMedia.fileName }</span>
        </div>
        <div className="selectedMediaFetchedInfo">
          <span className="title">Информация</span>
          <>
            <div>
              <span className="paramTitle">Формат</span>
              { mediaInfo?.blobResponse?.type &&
                <span className="paramValue">{ mediaInfo?.blobResponse?.type }</span>
              }
            </div>
            <div>
              <span className="paramTitle">Разрешение</span>
              { mediaInfo?.blobResponse?.blobResolution &&
                <span className="paramValue">{ mediaInfo?.blobResponse.blobResolution }</span>
              }
            </div>
            <div>
              <span className="paramTitle">Размер</span>
              { mediaInfo?.blobResponse?.size &&
                <span className="paramValue">{ mediaInfo?.blobResponse?.size }</span>
              }
            </div>
          </>
        </div>
      </div>
    </div>
  )
}