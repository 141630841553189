import React from 'react'
import { PriceHistoryType } from 'features/priceHistory/Table/model/types'

import { PriceContainer } from '../../../../../PriceContainer'

export const OldPriceCell = ({ row }: { row: PriceHistoryType }) => {
  const { oldCurrentPrice, oldPrevPrice, oldDiscount }: any = row

  return (
    <div style={{ height: '62px', display: 'flex', alignItems: 'end' }}>
      <PriceContainer
        currentPrice={oldCurrentPrice}
        prevPrice={oldPrevPrice}
        discount={oldDiscount}/>
    </div>
  )
}