/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BulkOperation } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<BulkOperation>

export type IRequest = CamelCasedPropertiesDeep<{
  bulkoperationId: number
}>

export const apiFunction = ({ bulkoperationId }: IRequest) =>
  api.get<ResponseType>(`/pim/ops/${bulkoperationId}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ bulkoperationId }: IRequest) => ['get', `/pim/ops/${bulkoperationId}`, { bulkoperationId }] as const

export const useGetBulkOperation = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

