/* eslint-disable no-param-reassign */
import { customerGen } from 'shared/lib/generated'
import { useEffect, useMemo, useState } from 'react'

import { PrivilegesTree } from './ui/PrivilegesTree'

import { AccessHeader } from '../../AccessHeader'
import { filterPrivileges } from '../../../lib/filterPrivileges'


export const UsersPrivilegesTable = ({ userData }: { userData: customerGen.administration.GetUserInfo.ResponseType }) => {
  const userPrivilegeData = userData.privileges
  
  const { data: privilegesData } = customerGen.administration.GetPrivileges.useGetPrivileges(({ query: {} }))
  const [ searchString, setSearchString ] = useState<string>('')
  const [filterByChecked, setFilterByChecked] = useState<boolean>(false)
  
  function updatePrivileges(arr1, arr2) {
    const copyArr1 = JSON.parse(JSON.stringify(arr1))
    const copyArr2 = JSON.parse(JSON.stringify(arr2))
    const privilegeDict = {}
    copyArr2.forEach(priv => {
      privilegeDict[priv.privilegeId] = priv
    })

    function updatePrivilegesInObject(obj) {
      let allSelected = true
      let hasSelectedPrivileges = false
      let allDisabled = true

      obj.privileges.forEach(priv => {
        const privInfo = privilegeDict[priv.id]
        if (privInfo) {
          Object.assign(priv, {
            selected: true,
            userPrivilegeId: privInfo.id,
            userPrivilegeVersionNo: privInfo.versionNo,
            dateIn: privInfo.dateIn,
            dateOut: privInfo.dateOut,
            disabled: !!privInfo.privilegeOfTheRole,
          })
          hasSelectedPrivileges = true
          if (!privInfo.privilegeOfTheRole) {
            allDisabled = false
          }
        } else {
          Object.assign(priv, {
            selected: false,
            disabled: false,
          })
          allSelected = false
          allDisabled = false
        }
      })

      obj.selected = hasSelectedPrivileges && allSelected
      obj.disabled = allDisabled
    }

    // Обновляем привилегии и группы в arr1
    copyArr1.forEach(section => {
      updatePrivilegesInObject(section)
      section.groups.forEach(group => {
        updatePrivilegesInObject(group)
        if (!group.selected) section.selected = false
        if (!group.disabled) section.disabled = false
      })
    })

    return copyArr1
  }

  const updatedUserPrivileges = useMemo(() => {
    if (privilegesData && userPrivilegeData) {
      if (filterByChecked) {
        return filterPrivileges(updatePrivileges(privilegesData, userPrivilegeData), ({ selected }) => selected)
      }
      const filteredPrivilegesData = filterPrivileges(privilegesData,
        ({ name }) => searchString !== '' ? name.toLowerCase().includes(searchString.toLowerCase()) : true)
      return updatePrivileges(filteredPrivilegesData, userPrivilegeData)
    }
    return []
  }, [privilegesData, userPrivilegeData, searchString, filterByChecked])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Control') {
        setFilterByChecked(prev => !prev)
      }
    }

    document.addEventListener('keydown', keyDownHandler)


    return () => {
      document.removeEventListener('keyup', keyDownHandler)
    }
  }, [])

  return (
    <>
      <AccessHeader title="Привилегии" subtitle="Управление правами доступа к бизнес-операциям"/>
      <PrivilegesTree
        data={updatedUserPrivileges}
        searchString={searchString}
        setSearchString={setSearchString}
        filterByChecked={filterByChecked}
      />
    </>
  )
}