import React from 'react'
import { pimApi } from 'shared/api'
import { Image } from 'shared/ui/components'

const fileThumbnailSize = { maxWidth: 70, maxHeight: 70 }

export const PhotoCell = ({ elem }) => {
  const apiUrl = pimApi.goods.getGoodsThumbnailApiPath(elem.goodsId, fileThumbnailSize)
  return (
    <div style={{ display: 'flex', width: '50px', height: '50px' }}>
      <Image src={apiUrl}/>
    </div>
  )
}