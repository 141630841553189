import React, { useMemo } from 'react'
import { copackingApi } from 'shared/api'
import { checkValue } from 'shared/lib/checkers'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { StyledLoader } from 'pages/goods/GoodList/ui/styled'
import { Table } from 'shared/ui/components/Table'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { TasksStatusFilter } from './TasksStatusFilter'
import { StyledTableBox } from './styled'

import { pagingPanelLocale , columnsConfig } from '../lib'

const getRowId = (row: copackingApi.CoPackingOrder) => row.id
export const TasksTable = () => {
  const { searchObj } : any = useQueryParams({ parseNumbers: true })

  const { calculatedRowsPerPage, limit } = useRowsPerPage(checkValue(searchObj.limit), 110)
  
  const { order, searchString, statusId } = searchObj

  const sort =
    searchObj.sort && searchObj.order
      ? [{ columnName: searchObj.sort, direction: searchObj.order }]
      : []

  const page = parseInt(checkValue(searchObj?.page) || '0', 10)

  const coPackingOrdersParameters = {
    page,
    limit: checkValue(limit),
    sort: sort?.[0]?.columnName,
    order: checkValue(order),
    statusId: checkValue(statusId) || 0,
    searchString: checkValue(searchString),
  }
  
  const { data: coPackingOrdersData, isLoading, isFetching } = copackingApi.useGetActivitiesQuery(coPackingOrdersParameters)

  const memoTableParams = useMemo(
    () => (
      {
        page,
        limit: limit >= (coPackingOrdersData?.total || 0) ? (coPackingOrdersData?.total || 0) : limit,
        sort,
        searchString,
        columnsSettingsId: 'copackingOrders'
      }),
    [coPackingOrdersData]
  )

  return (
    <>
      <TasksStatusFilter/>
      <StyledTableBox>
        {(isLoading || isFetching) && (<StyledLoader size={60} />)}
        {coPackingOrdersData && (
          <Table
            tableList={coPackingOrdersData.result}
            totalCount={coPackingOrdersData.total}
            getRowId={getRowId}
            tableParams={memoTableParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={columnsConfig}
            searching="external"
            pagination="external"
            sorting="external"
            filtering="external"
            paginationLocale={pagingPanelLocale}
          />
        )}
      </StyledTableBox>
    </>
  )
}