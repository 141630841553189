import React from 'react'

import { StyledTextContainer } from '../../styled'

export const VideoRequirements = () => {
  const Text = 'Формат — MP4, MOV. Размер — не больше 100 Мбайт'
  const Element = (
    <StyledTextContainer>
      <ul>
        <li>Формат — MP4, MOV</li>
        <li>Объём — до 100 Мбайт</li>
      </ul>
    </StyledTextContainer>
  )
  return { Text, Element }
}