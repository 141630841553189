import { Box } from '@mui/material'

import { isNotNil } from '../../../../../../lib/checkers'

function round({ value }) {
  if (value) {
    return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(value).replace(',', '.')
  } return '0.00'
}

export const SumCell = (value) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (isNotNil(value.value)) {
    return (<Box>{round(value)} ₽</Box>)
  }
  return null
}
