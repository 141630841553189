import { Box, Typography } from '@mui/material'
import ruLocale from 'date-fns/locale/ru'
import format from 'date-fns/format'
import { DateFormats } from 'shared/config/dateFormats'
import { Chart } from 'shared/ui/components'
import { Props as XAxisProps } from 'recharts/types/cartesian/XAxis'
import UpdateIcon from '@mui/icons-material/Update'
import { getStocksDate } from 'entities/pim/stocks/ui/StocksCard'

import {
  ChartWrapper,
  TitleWrapper,
  StyledCard,
  StyledCardContent,
} from './styled'

export interface StocksAndOrdersCardProps {
  stocksDate: any
  stocksAndOrdersData?: Array<any>
  min: number
  max: number
}

const CHART_COLORS = ['#4CAF50', '#3F51B5']
const CHART_OPTIONS = {
  xAxisOptions: {
    interval: 11,
    minTickGap: 0,
    scale: 'time',
    tickMargin: 6,
    tickFormatter: (tickItem) =>
      format(new Date(tickItem), DateFormats.dayMonthW, {
        locale: ruLocale,
      }).slice(0, -1),
  } as Partial<XAxisProps>,
  yAxisOptions: {
    axisLine: false,
    tickSize: 0,
  },
  cartesianGridOptions: {
    vertical: false,
    strokeDasharray: '0',
  },
  lineOptions: {
    strokeWidth: 1,
    dot: { strokeWidth: 1, fillOpacity: 1, r: 2 },
  },
  chartDataKey: ['quantity', 'stocks']
}

export const StocksAndOrdersCard = ({
  stocksDate,
  stocksAndOrdersData,
  min,
  max,
}: StocksAndOrdersCardProps) => {
  const stocksUpdatedData = stocksDate?.data?.lastChangeDate
    ? getStocksDate(stocksDate?.data?.lastChangeDate)
    : ''
  
  return (
    <StyledCard>
      <StyledCardContent>
        <TitleWrapper>  
          <Box display="flex">
            <Typography mt={1} fontSize={12} color="rgba(0, 0, 0, 0.6)">
              Заказы
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} pr="10px">
            <UpdateIcon sx={{ fontSize: '14px',marginRight: '5px', color: 'rgba(0, 0, 0, 0.26)' }}/>
            <Typography fontSize={12} color="#9e9e9e">
              {stocksUpdatedData}
            </Typography>
          </Box>
        </TitleWrapper>
        <Box display="flex" flexGrow="1" mt="16px">
          <ChartWrapper>
            <Chart
              data={stocksAndOrdersData}
              min={min}
              max={max}
              xAxisDataKey="date"
              isBiaxial={true}
              colors={CHART_COLORS}
              {...CHART_OPTIONS}
            />
          </ChartWrapper>
        </Box>
      </StyledCardContent>
    </StyledCard>
  )
}
