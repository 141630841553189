// @ts-nocheck
import { CatalogLiteralType, CatalogType } from 'shared/services/interfaces/catalog'
import { createSelector, Selector } from 'reselect'
import { Option } from 'shared/ui/components/interface'

import { CatalogStateType } from './index'

export const catalogsSelector = (state: {
  catalogReducer: CatalogStateType
}): { [key in CatalogLiteralType]?: CatalogType[] } =>
  state.catalogReducer.catalogs

type extendedCatalogType = CatalogType & Option // { value: string; label: string }

export const catalogSelector = (
  catalogType: CatalogLiteralType
): Selector<{ catalogReducer: CatalogStateType }, extendedCatalogType[]> => {
  if (!catalogType) return () => []

  return createSelector<
    { catalogReducer: CatalogStateType },
    { [key in CatalogLiteralType]?: CatalogType[] },
    extendedCatalogType[]
  >(
    catalogsSelector,
    (catalogs): any => {
      const catalog = catalogs[catalogType]

      if (!Array.isArray(catalog)) return []

      return catalog.map((item) => typeof item === 'object' ? ({
        ...item,
        value: item.id,
        label: item.name,
      }) : ({
        id: item,
        value: item,
        label: item,
      }))
      
    })
}

export {}
