import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { Delete, InfoOutlined } from '@mui/icons-material'
import { CatalogSelectField, SelectField, Field } from 'shared/ui/components/form'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { isNil } from 'shared/lib/checkers'

import { IVariation } from '../interface'
import { VariantItem } from '../../components/common'

interface VariantFieldsProps {
  name: string
  dictionaryId?: UniqueId
  variations: IVariation[]
  deletable: boolean
  onRemove: () => void
  dictionaryFilterPredicate?: (
    dictionaryElement: {
      id: string;
      value: string;
      label: string
    }
  ) => boolean
}

export const VariantFields = ({
  name,
  variations,
  deletable,
  dictionaryId,
  onRemove,
  dictionaryFilterPredicate,
}: VariantFieldsProps) => {
  const variationName = `${name}.variation`
  const variationField = useField(variationName)

  const valueName = `${name}.value`
  const valueField = useField(valueName)

  useEffect(() => {
    valueField.input.onChange('')
  }, [variationField.input.value])

  return (
    <VariantItem key={name}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={3}>
          <InputWrapper>
            <Field
              name={variationName}
              component={SelectField}
              options={variations.map((value) => ({
                label: value.attributeName,
                value,
              }))}
              label="Параметр"
            />
            <Tooltip title="Тип товара и текст небольшой" placement="right">
              <HelpWithMargin>
                <InfoOutlined />
              </HelpWithMargin>
            </Tooltip>
          </InputWrapper>
        </Grid>
        <Grid item={true} xs={3}>
          <InputWrapper>
            <Field
              name={valueName}
              label="Значение"
              component={CatalogSelectField}
              catalog={`pim/dictionaries/${dictionaryId}`}
              disabled={isNil(dictionaryId)}
              catalogFilterPredicate={dictionaryFilterPredicate}
              errorAfterTouch={true}
            />
            <Tooltip title="Тип товара и текст небольшой" placement="right">
              <HelpWithMargin>
                <InfoOutlined />
              </HelpWithMargin>
            </Tooltip>
          </InputWrapper>
        </Grid>
        {deletable && (
          <Grid item={true} xs={3}>
            <Grid container={true} justifyContent="space-between">
              <IconButton aria-label="delete" onClick={onRemove} size="large">
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </VariantItem>
  )
}
