import { Checkbox, FormControlLabel } from '@mui/material'

import { withFieldEventAdapter } from './FieldAdapter'

const CheckboxField = ({ input, meta, label, checked, error, ...rest }) => {
  const hasDefaultChecked = Object.keys(input).includes('defaultChecked')
  const parsedValue = (input?.value?.toString() === 'true' || checked?.toString() === 'true') && true
  const parsedDefaultValue = input?.defaultChecked?.toString() === 'true'

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          {...hasDefaultChecked ? {
            defaultChecked: parsedDefaultValue
          } : {
            checked: parsedValue
          }}
          {...input}
          {...rest} />
      }
      label={label}
    />
  )
}

export default withFieldEventAdapter(CheckboxField)
