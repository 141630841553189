import { getFileSizeParams } from 'shared/api/getFileSizeParams'


export const getFileThumbnailApiPath = (
  productId: UniqueId,
  size?: { maxWidth?: number, maxHeight?: number },
) => {
  const fileThumbnailPath = `/pim/products/${productId}/thumbnail`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}


