import { productModel } from 'entities/pim/product'
import { pimApi } from 'shared/api'
import { replaceEditorTags } from 'shared/lib/utils'

export const serializeEditSubmitFormValuesToEditOzonCard = ({
  // поля, которые не нужны
  attributes,
  brandName,
  cardStatus,
  categoryId,
  categoryName,
  changed,
  changer,
  companyId,
  ozonProductId,
  packagingrequirement,
  syncStatus,
  photos,
  photos360,
  videos,
  pdfs,
  // конец полей, которые не нужны
  ...formValues
}: productModel.ozonModel.EditSubmitFormValues): pimApi.products.EditOzonCard => ({
  ...formValues,
  attributes: attributes.map(({ id, versionNo, attributeId, values, type   }) => ({
    id,
    versionNo,
    attributeId,
    values: values.map(({ dictionaryValueId, value, obsolete }) => ({
      obsolete,
      value: type === 'Boolean' ? value?.toString().toLowerCase() : value,
      dictionaryValueId:
        dictionaryValueId === undefined ? 0 : dictionaryValueId,
    })),
  })),
  brandId: formValues.brandId?.value,
  description: replaceEditorTags(formValues.description),
})
