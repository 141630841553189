import { pimApi } from 'shared/api'

interface Contragent {
    id: number
  }

export function contragentCatalogFilterPredicate(suppliers: Array<pimApi.goods.GoodsSupplier>): (contragent: Contragent) => boolean {
  return (contragent: Contragent) => suppliers.findIndex(
    ({ contragentId }) => contragentId === contragent.id) === -1
}
