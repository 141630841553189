import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'

import { StyledAddButton } from './styled'
import { UploadMediaModal } from './UploadMediaModal'

import { IAddButton } from '../lib/types'


export const AddButton = ({
  largeContainer,
  onFileValidate,
  supportedFileExtension,
  onUploadFile,
  existingMediaCount,
  mediaLimit,
  urlUploadingExist,
  mediaType,
  mediaRequirement
}: IAddButton) => {
  const handleAddMedia = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <UploadMediaModal
          existingMediaCount={existingMediaCount}
          close={close}
          mediaLimit={mediaLimit}
          mediaType={mediaType}
          mediaRequirement={mediaRequirement}
          supportedFileExtension={supportedFileExtension}
          onUploadFile={onUploadFile}
          onFileValidate={onFileValidate}
          urlUploadingExist={urlUploadingExist}/>
      )
    })
  }
  return (
    <StyledAddButton largeContainer={largeContainer} onClick={handleAddMedia}>
      <AddIcon/>
    </StyledAddButton>
  )
}