import { Box } from '@mui/material'
import styled from 'styled-components'

export const TooltipTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 160%;
  font-weight: 500;
`

export const TooltipButton = styled.button<{ $disabled?: boolean}>`
  background-color: transparent;
  border-style: none;
  color: ${({ $disabled }) => $disabled ? 'inherit' : '#007DFF'};
  cursor: pointer;
  padding: 0;
  padding-left: 4px;
`

export const CodeContainer = styled(Box)`
  overflow-y: auto;
`

export const CodeItem = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
`
