import React, { useMemo } from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { TwinMediumChartWidget } from 'widgets/DashboardWidgets'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'

interface dataType {
  orders: number
  stocks: number
  orderDate: string
}

export const MediumOrdersAndStocksWidget = () => {
  const subtractDays = (days) => {
    const utc = new Date()
    const date = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000))
    date.setDate(date.getDate() - days)
    return date.toISOString().split('T')[0]
  }

  const dateFrom = subtractDays(14)
  const dateTo = subtractDays(1)
  const currentDay = subtractDays(1)
  const prevDay = subtractDays(2)

  const { data: ordersData } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })

  const { data: stocksData } =
    analyticsApi.useAnalyticsStocksQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })


  const currentOrdersQuantity = ordersData?.data.data.slice(7).reduce((sum, elem) => sum + elem.items, 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,7).reduce((sum, elem) => sum + elem.items, 0)

  const currentStocksQuantity =
    stocksData?.data.data.filter(el =>
      el.stocksDate === currentDay).
      reduce((sum, elem) => sum + elem.available, 0)

  const prevStocksQuantity =
    stocksData?.data.data.filter(el =>
      el.stocksDate === prevDay).
      reduce((sum, elem) => sum + elem.available, 0)

  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const stocksDiff = (currentStocksQuantity !== undefined && prevStocksQuantity !== undefined) ?
    (currentStocksQuantity - prevStocksQuantity) : 0

  const chartUrl = generatePath('/analytics/charts?range=0&tab=0')

  const holder = {}
  stocksData?.data.data.forEach((el) => {
    // eslint-disable-next-line no-prototype-builtins
    if (holder.hasOwnProperty(el.stocksDate)) {
      holder[el.stocksDate] =+ holder[el.stocksDate] + el.available
    } else {
      holder[el.stocksDate] = el.available
    }
  })


  const maxOrder = Math.max.apply(null, ordersData ? ordersData?.data.data.map(el => el.items) : [])
  const maxStock = Math.max.apply(null, Object.keys(holder).map(el => holder[el]))

  const chartData: Array<dataType> | any = useMemo(() => ordersData?.data.data.map((el) => ({
    orders: el.items / maxOrder,
    stocks: stocksData ?
      stocksData?.data.data.filter((elem) => elem.stocksDate === el.orderDate).
        reduce((sum, elem) => sum + elem.available, 0) / maxStock : [],
    orderDate:el.orderDate
  })), [ordersData, stocksData])

  return (
    <>
      {(ordersData?.data.data && stocksData?.data.data) ?
        <TwinMediumChartWidget
          title="Заказы и остатки за неделю"
          chartData={chartData.slice(7)}
          chartUrl={chartUrl}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            color: '#4CAF50',
            name: 'Заказы',
            valueField: 'orders',
          }}
          rightData={{
            data: currentStocksQuantity || 0,
            diff: stocksDiff,
            name: 'Остатки',
            color: '#3F51B5',
            valueField: 'stocks'
          }}
        />
        :
        <Skeleton/>
      }
    </>
  )
}