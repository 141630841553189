export type GoodsEditPageStep = 'info'
    | 'delivery'
    | 'characteristic'
    | 'media'
    | 'variant'
    | 'products'
    | 'competitors'
    | 'changelog'
    | 'existsIn'

export const GOODS_EDIT_PAGE_STEPS: Array<GoodsEditPageStep> = [
  'info',
  'delivery',
  'characteristic',
  'media',
  'variant',
  'existsIn',
  'products',
  'competitors',
  'changelog',
]

export const GOODS_WITHOUT_DELIVERY_EDIT_PAGE_STEPS: Array<GoodsEditPageStep> = [
  'info',
  'characteristic',
  'media',
  'variant',
  'existsIn',
  'products',
  'competitors',
  'changelog',
]
