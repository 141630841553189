import React from 'react'

import { formatDate } from '../../../../../../lib/transform/relativeDateFormatting'

export const RelativeDateCell = ({ stringDate }: { stringDate?: string }) => {
  const formattedDate = formatDate({
    date: new Date(stringDate || ''),
    includeDayDiff: true
  }).replace('назад', '')
  return (
    <div>{formattedDate}</div>
  )
}