import { Typography } from '@mui/material'
import React from 'react'
import { ReportsTable } from 'features/admin/ReportsAccess/ui/ReportsTable'
import { customerGen } from 'shared/lib/generated'

import {
  ReportsTableBody,
  ReportsTableHeader,
  StyledReportsTable,
} from './styled'

export const ReportsPage = () => {
  const { data, refetch } =
    customerGen.administration.GetReportsList.useGetReportsList()
  return (
    <StyledReportsTable>
      <ReportsTableHeader>
        <Typography>Привилегия</Typography>
      </ReportsTableHeader>

      <ReportsTableBody>
        <ReportsTable groupData={data} refetch={refetch} />
      </ReportsTableBody>
    </StyledReportsTable>
  )
}
