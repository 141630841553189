import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { AxiosResponse } from 'axios'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { downloadFile } from 'shared/lib/utils'
import { history } from 'app/providers/with-router'
import { getBulkOperationsPath } from 'shared/config'
import { BulkOperationsResultModal } from 'widgets/product'
import { dialogModel } from 'shared/ui/components/dialog'
import { useField } from 'react-final-form'

import { MarketplaceFormProps } from '../../config'

const DEFAULT_FILE_NAME = 'template.xlsx'

export const MarketplaceFormSimple: React.FC<MarketplaceFormProps> = ({
  companymarketplaceId,
}) => {
  const { mutate: uploadMutation, isLoading: isUploading } =
      pimApi.products.simple.useUploadSimpleProductBulkCreationFile()
  const { mutate: downloadMutation, isLoading: isDownloading } =
      pimApi.products.simple.useDownloadSimpleProductBulkCreationTemplateFile()

  const useGoodsMedia = useField('useGoodsMedia').input.value

  const download = () => {
    downloadMutation(undefined, {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ) || DEFAULT_FILE_NAME

        downloadFile(file, decodeURIComponent(fileName.replace(/%2C/g, ',')))
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      },
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      { file, companymarketplaceId, useGoodsMedia: useGoodsMedia || false },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('products'))
  }

  return (
    <Box mb={1}>
      <Grid item={true} xs={12}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Typography variant="body2" gutterBottom={true} component="span">
            Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
          </Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item={true} xs={12} p={0}>
        <BulkFileActionsFooter
          download={download}
          upload={upload}
          isUploading={isUploading}
          isDownloading={isDownloading}
          onGoBack={handleGoBack}
        />
      </Grid>
    </Box>
  )
}
