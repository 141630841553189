import { Box, Typography } from '@mui/material'
import { round } from 'shared/lib/utils/round'
import React, { useEffect, useMemo } from 'react'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { getPreviousElements } from 'features/analytics/chart/lib/getPreviousElements'

import { StyledLegend, StyledLegendContainer } from './styled'


export const ChartDetails = ({
  visualRange,
  groupby, 
  timelineData,
  hiddenSeries,
  setHiddenSeries,
}: {
  visualRange: any,
  timelineData: any,
  setHiddenSeries: (e) => void,
  groupby?: string
  hiddenSeries: any
}) => {

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'o' && event.target.value === undefined) {
        event.preventDefault()

        handleLegendChange('orders')
      }

      if (event.key === 's' && event.target.value === undefined) {
        event.preventDefault()

        handleLegendChange('sales')
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const handleLegendChange = (value) => {
    setHiddenSeries(prev => {
      const { ...rest } = prev
      rest[value] = !prev[value]
      return rest
    })
  }
  
  const startDateString = visualRange?.[0] || visualRange.startValue
  const endDateString = visualRange?.[1] || visualRange.endValue

  const { currentElements, previousElements } = useMemo(() =>
    getPreviousElements(
      timelineData,
      startDateString,
      endDateString),
  [timelineData, startDateString, endDateString, groupby])

  const totalOrders = currentElements.reduce((sum, elem) => sum + (elem.orders || 0), 0)
  const totalSales = currentElements.reduce((sum, elem) => sum + (elem.sales || 0), 0)
  
  const prevTotalOrders = previousElements.reduce((sum, elem) => sum + (elem.orders || 0), 0)
  const prevTotalSales = previousElements.reduce((sum, elem) => sum + (elem.sales || 0), 0)

  const unitsOrdersDelta = prevTotalOrders ? totalOrders - prevTotalOrders : undefined
  const unitsSalesDelta = prevTotalSales ? totalSales - prevTotalSales : undefined
  
  return (
    <Box sx={{ height: '88px', display: 'flex', flexDirection: 'row', gap: '48px' }}>
      <Box
        sx={{
          width: '200px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Заказы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(totalOrders, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsOrdersDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsOrdersDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsOrdersDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsOrdersDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsOrdersDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      <Box
        sx={{
          width: '200px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
        <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
          Выкупы
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}>
            {round(totalSales, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
          {unitsSalesDelta &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px'
          }}>
            <CallMadeIcon sx={{
              fontSize: 14,
              color: unitsSalesDelta > 0 ?' #757575' : '#F50057',
              rotate: unitsSalesDelta > 0 ? '' : '90deg'
            }}/>
            <span style={{ fontSize: 12, color: unitsSalesDelta > 0 ?' #757575' : '#F50057', fontWeight: '500' }}>
              {Math.abs(unitsSalesDelta).toLocaleString() || 0}
            </span>
          </div>
          }
        </Box>
      </Box>
      <Box
        sx={{
          width: '202px',
          height: '88px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '16px',
        }}>
        {/* До выяснения обстоятельств */}
        {/* <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}> */}
        {/*  Возвраты */}
        {/* </Typography> */}
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}> */}
        {/*  <Typography sx={{ fontSize: '24px', lineHeight: '133.4%' }}> */}
        {/*    {round(totalReturns, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
        {/*  </Typography> */}
        {/*  {totalReturnsDelta && */}
        {/*  <div style={{ */}
        {/*    display: 'flex', */}
        {/*    alignItems: 'center', */}
        {/*    marginLeft: '8px' */}
        {/*  }}> */}
        {/*    <CallMadeIcon sx={{ */}
        {/*      fontSize: 14, */}
        {/*      color: totalReturnsDelta < 0 ?' #757575' : '#F50057', */}
        {/*      rotate: totalReturnsDelta > 0 ? '' : '90deg' */}
        {/*    }}/> */}
        {/*    <span style={{ fontSize: 12, color: totalReturnsDelta < 0 ?' #757575' : '#F50057', fontWeight: '500' }}> */}
        {/*      {Math.abs(totalReturnsDelta).toLocaleString() || 0} */}
        {/*    </span> */}
        {/*  </div> */}
        {/*  } */}
        {/* </Box> */}
      </Box>
      <StyledLegendContainer>
        <StyledLegend onClick={() => {handleLegendChange('orders')}}>
          <Box sx={{ width: '16px', height: '16px', borderRadius: '4px', backgroundColor: hiddenSeries.orders ? '#4CAF50' : '#E0E0E0' }}/>
          <Typography sx={{ color: hiddenSeries.orders ? 'rgba(0, 0, 0, 0.54)' : '#E0E0E0' }}>Заказы</Typography>
        </StyledLegend>
        <StyledLegend sx={{ cursor: 'pointer' }} onClick={() => {handleLegendChange('sales')}}>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '4px',
              backgroundColor: hiddenSeries.sales ? 'rgba(33, 150, 243, 0.16)' : '#E0E0E0' }}/>
          <Typography sx={{ color: hiddenSeries.sales ? 'rgba(0, 0, 0, 0.54)' : '#E0E0E0' }}>Выкупы</Typography>
        </StyledLegend>
      </StyledLegendContainer>
    </Box>
  )
}
