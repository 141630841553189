import React from 'react'
import { Box, DialogTitle, List, Typography, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import questions from 'assets/images/questions.svg'
import { FlexWrapper } from 'shared/ui/components'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 720px;
    max-width: 720px;
  }
`

export const Bullet = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #007DFF;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const dataInfo = [
  {
    primary: 'Импорт бланка заказа',
  },
  {
    primary: 'Ввод номеров столбцов',
    secondary: 'Укажите номера столбцов, соответствующие указанным данным. Это необходимо для автоматизации заполнения бланка'
  },
  {
    primary: 'Проверка бланка заказа',
    // eslint-disable-next-line max-len
    secondary: 'Используя номера столбцов, алгоритм проводит проверку. Убедитесь, что все товары были успешно обработаны, для этого скачайте файл с результатами проверки'
  }
]

export const UploadNoteModal = ({ close }) => {
  
  const primaryListText = {
    fontSize: '14px',
    fontWeight: '500',
  }
  
  const secondaryListText = {
    fontSize: '12px',
    fontWeight: '400',
  }
  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon"/>
        </IconCloseButton>
        <DialogTitle fontSize={20}>
          Инструкция по настройке бланка заказа
        </DialogTitle>
        <Box px={3}>

          <Typography fontSize={14}>
            Настройка используется для выгрузки заказов в фирменном бланке поставщика
          </Typography>
          <FlexWrapper>
            <List sx={{ marginBottom: '20px', marginTop: '16px' }}>
              {
                dataInfo.map((item, index) => (
                  <ListItem key={item.primary} sx={{ paddingLeft: '0' }}>
                    <ListItemIcon>
                      <Bullet>{index + 1}</Bullet>
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={primaryListText}
                      secondaryTypographyProps={secondaryListText}
                      primary={item.primary}
                      secondary={item.secondary ? item.secondary : null}
                    />
                  </ListItem>
                )
                )
              }
            </List>
            <Box mr={3}>
              <img src={questions} alt="questions background"/>
            </Box>
          </FlexWrapper>
        </Box>
      </Box>
    </ModalContainer>
  )
  
}



