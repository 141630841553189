import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { PriceChangeReason } from './models'

import { api } from '../../base'

export function fetchPriceChangeReasons(): Promise<AxiosResponse<PriceChangeReason[]>> {
  return api.get<PriceChangeReason[]>('/pim/pricechangereasons')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/pricechangereasons'] as const

function getPriceChangeReasons() {
  return fetchPriceChangeReasons().then((res) => res.data)
}

export const usePriceChangeReasonsQuery = () => useQuery<
    Array<PriceChangeReason>,
    errorType,
    Array<PriceChangeReason>,
    QueryKey
  >(QUERY_KEY, getPriceChangeReasons, {
    refetchOnMount: false,
  })
