import * as yup from 'yup'
import { useParams } from 'react-router'
import { Mutator } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { Form } from 'shared/ui/components/form'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { noop } from 'shared/lib/lodash/noop'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'
import { Preloader } from 'shared/ui/components'
import { pimApi } from 'shared/api'

import { CompositionFormRenderComponent } from './ui/CompositionFormRender'

export interface GoodsComponent {
  id: number
  versionNo: number

  /** @format date-time */
  changed: string
  changer: string
  component: string
  percents?: number
  disabled?: boolean
}

interface InitialValues {
  content: Array<GoodsComponent>
  isShowPercents: boolean
}

const IS_SHOW_PERCENT_INIT = true

const SCHEMA = yup.object().shape({
  content: yup.array().of(
    yup.object().shape({
      component: yup.string().required().min(1),
      percents: yup
        .number()
        .transform(yupTransformEmptyStringInNull)
        .nullable()
        .lessThan(101),
    })
  ),
})

const mutators: Record<string, Mutator<InitialValues>> = {
  ...arrayMutators,
}

export const GoodsContent = ({ disabled }) => {
  const validate = useValidationSchema(SCHEMA)

  const { id: goodsIdString } = useParams<{ id: string }>()
  const goodsId = parseInt(goodsIdString, 10)
  const { data: goodsContent = [], isLoading: isGoodsContentLoading } =
    pimApi.goods.useFetchGoodsContentQuery(goodsId)

  return isGoodsContentLoading ? (
    <Preloader />
  ) : (
    <Form<InitialValues>
      onSubmit={noop}
      validate={validate}
      initialValues={{
        content: goodsContent,
        isShowPercents: IS_SHOW_PERCENT_INIT,
      }}
      mutators={mutators}
      render={() => <CompositionFormRenderComponent disabled={disabled} />}
    />
  )
}
