import React from 'react'
import styled from 'styled-components'
import { Box, Typography } from '@mui/material'
import DangerIcon from 'assets/images/ic_danger.svg'

const ErrorMessageBlock = styled(Box)`
  display: inline-flex;
  border-radius: 4px;
  //max-width: 256px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f44336;
  padding: 10px 18px;
`
const ErrorText = styled(Typography)`
  //background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #F44336, #F44336);
  padding-left: 10px;
  font-size: 14px;
`

export const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => (
  <ErrorMessageBlock>
    <img src={DangerIcon} alt="" />
    <ErrorText>{errorMessage}</ErrorText>
  </ErrorMessageBlock>
)
