import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import WorkIcon from '@mui/icons-material/Work'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import DescriptionIcon from '@mui/icons-material/Description'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import StoreIcon from '@mui/icons-material/Store'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import { getAdminPanelContentPath, getGoodsPanelContentPath } from 'shared/config'

import {
  AdminPanelUsers,
  AdminPanelCompanies,
  AdminPanelDicts,
  AdminPanelReports,
  AdminPanelSuppliers,
  AdminPanelWarehouses,
  GoodsPanel
} from '../Content/Pages'


export const adminPanelElements = [
  {
    label: 'Пользователи',
    subtitle: 'Управление пользователями и\u00A0их\u00A0доступом',
    icon: <SupervisorAccountIcon />,
    value: getAdminPanelContentPath('users'),
    primaryColor: '#64B6F7',
    secondaryColor: '#2196F3',
    Component: AdminPanelUsers,
    checkAdminRole: true
  },
  {
    label: 'Компании',
    subtitle: 'Настройка API ключей и\u00A0интеграция кабинетов',
    icon: <VpnKeyIcon />,
    value: getAdminPanelContentPath('companies'),
    primaryColor: '#33C9DC',
    secondaryColor: '#00BCD4',
    Component: AdminPanelCompanies,
    checkAdminRole: true
  },
  {
    label: 'Поставщики',
    subtitle: 'Создание и\u00A0настройка поставщиков товаров',
    icon: <WorkIcon />,
    value: getAdminPanelContentPath('suppliers'),
    primaryColor: '#35BAF6',
    secondaryColor: '#03A9F4',
    Component: AdminPanelSuppliers,
    checkAdminRole: false
  },
  {
    label: 'Справочники',
    subtitle: ' Справочники привилегий, ролей и\u00A0брендов',
    icon: <DescriptionIcon />,
    value: getAdminPanelContentPath('dicts'),
    primaryColor: '#6573C3',
    secondaryColor: '#3F51B5',
    Component: AdminPanelDicts,
    checkAdminRole: false
  },
  {
    label: 'Доступы к отчетам',
    subtitle: 'Управление доступом\nпользователей к отчетам',
    icon: <FileCopyIcon />,
    value: getAdminPanelContentPath('reports'),
    primaryColor: '#64B6F7',
    secondaryColor: '#2196F3',
    Component: AdminPanelReports,
    checkAdminRole: true
  },
  {
    label: 'Приоритетные РЦ',
    subtitle: 'Выбор приоритетных \nраспределительных центров \n на маркетплейсах',
    icon: <StoreIcon />,
    value: getAdminPanelContentPath('warehouses'),
    primaryColor: '#64B6F7',
    secondaryColor: '#2196F3',
    Component: AdminPanelWarehouses,
    checkAdminRole: true
  },
  {
    label: 'Дерево категорий',
    subtitle: 'Управление категориями\nи атрибутами товаров',
    icon: <AccountTreeIcon />,
    value: getGoodsPanelContentPath('categories'),
    primaryColor: '#64B6F7',
    secondaryColor: '#2196F3',
    Component: GoodsPanel,
    checkAdminRole: true
  },
] as const