import React, { ReactElement } from 'react'

import { StyledChip } from '../styled'

export const StatusChips = ({ 
  backgroundColor,
  textColor,
  iconColor,
  title,
  icon
}: {
  backgroundColor: string,
  title: string,
  textColor: string
  iconColor: string
  icon: ReactElement
}) => (
  <StyledChip style={{ backgroundColor }} iconColor={iconColor} textColor={textColor}>
    {icon}
    <span>{title}</span>
  </StyledChip>
)