import { Theme } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'

import { columnNames } from '../ColumnsConfig'

const PERCENT = 10

export const getColorForCell = (theme: Theme, row: CalculatedReceiptDocPosition, columnName: string, value: number) => {
  const colorData: Partial<{ color: string, bgColor: string }> = {}

  const percent10OfQuantity = row.quantity / PERCENT

  const notValidTextColor = theme.palette.error.main
  const notValidBgColor = theme.palette.error.lightBg
  const partialValidTextColor = theme.palette.warning.main

  switch (columnName) {
    case columnNames.sumFact :
      if (row.sumFact === row.quantity) {
        break
      }
      if (row.sumFact > row.quantity) {
        colorData.color = notValidTextColor
        break
      }
      if (percent10OfQuantity > (row.quantity - row.sumFact)) {
        colorData.color = partialValidTextColor
      }
      break

    case columnNames.sumDefective :
      if (value > 0) {
        colorData.color = notValidTextColor
        colorData.bgColor = notValidBgColor
      }
      break

    case columnNames.difference :
      if (value > 0) {
        colorData.color = notValidTextColor
        colorData.bgColor = notValidBgColor
        break
      }
      if (percent10OfQuantity > (row.quantity - row.sumFact)) {
        colorData.color = partialValidTextColor
      }
      break

    default: break
  }

  return colorData
}