import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { tradeApi } from 'shared/api'
import { api } from 'shared/api/base'

const downloadSupplyOrdersPositionsTemplateFile = async () =>
  api.get<File>(
    '/trade/orders/supplyorders/positions/template',
    {
      responseType: 'blob',
    }
  )

export const useDownloadSupplyOrdersPositionsTemplateFile = () =>
  useMutation(downloadSupplyOrdersPositionsTemplateFile)

const uploadSupplyOrdersPositionsTemplateFile = async (queryParams: {
  file: File | null
  supplyOrderId?: UniqueId
}) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')
  return api.post<tradeApi.ImportMetadata>(
    `/trade/orders/supplyorders/${queryParams.supplyOrderId}/positions/file`,
    body
  )
}
export const useUploadSupplyOrdersPositionsTemplateFile = (
  options?: UseMutationOptions<
    AxiosResponse<tradeApi.ImportMetadata>,
    errorType,
    {
      file: File
      supplyOrderId?: UniqueId
    }
  >
) => useMutation(uploadSupplyOrdersPositionsTemplateFile, options)
