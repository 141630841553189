import React, { useMemo } from 'react'
import { reviewApi } from 'shared/api'
import { reviewGen } from 'shared/lib/generated'
import { Table } from 'shared/ui/components/Table'
import { useRowsPerPage } from 'shared/ui/components/Table/lib'
import { dialogModel } from 'shared/ui/components/dialog'
import { Box, Button, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useQueryClient } from 'react-query'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { snackActions } from 'shared/lib/react/snackbar'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { TemplateFilter } from './TemplateFilter'
import { columnsConfig } from './model/columnsConfig'
import { pagingPanelLocale } from './model/localization'

import { StyledTableBox, ToolBar } from '../styled'
import { EditTemplateModal } from '../TemplateModals/EditTemplateModal/EditTemplateModal'
import { PlugTemplate } from '../PlugTemplate'

const MAX_PAGE_SIZE = 50
const getRowId = (row) => row.id
export const TemplateTable = ({ 
  templateData,
  reviewType,
  setReviewType }: { templateData: Array<reviewApi.ReviewTemplate>, reviewType: number, setReviewType: (e) => void }) => {
  const { calculatedRowsPerPage } = useRowsPerPage(undefined, 100)
  const queryClient = useQueryClient()
  const pageSize = Math.min(MAX_PAGE_SIZE, calculatedRowsPerPage)

  const { mutate: createModel } =
    reviewGen.template.CreateReviewTemplateModel.useCreateReviewTemplateModel()

  const { mutate: createTemplate } =
    reviewGen.template.CreateReviewTemplate.useCreateReviewTemplate()
  
  const { mutate: editTemplate } =
    reviewGen.template.UpdateReviewTemplate.useUpdateReviewTemplate()
  
  const { mutate: deleteTemplate } =
    reviewGen.template.DeleteReviewTemplate.useDeleteReviewTemplate()

  const handleRowDelete = (id) => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <ModalContent
          close={close}
          accept={accept}
          title="Удалить шаблон?"
          acceptBtnText="УДАЛИТЬ"
          acceptBtnColor="error"
          variant="text"
        />
      ),
      onAccept: () => {
        const deletedTemplate = templateData.find(el => el.id === id)!
        deleteTemplate({
          templateId: id, 
          data: {
            id,
            versionNo: deletedTemplate.versionNo
          }
        },
        {
          onSuccess: () => {
            queryClient.setQueryData<reviewApi.ReviewTemplate[]>(
              reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
              (templates) => templates!.filter(el => el.id !== id) as reviewApi.ReviewTemplate[])
            snackActions.info('Шаблон удален')
          }
        }
        )
      },
    })
  }

  const handleCellChange = (template) => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <EditTemplateModal
          close={close}
          accept={accept}
          changedCell={template}
        />
      ),
      onAccept: (createdTemplate) => {
        if (createdTemplate.templatemodelId.value === createdTemplate.templatemodelId.label ) {
          createModel({ data: createdTemplate.templatemodelId.value },
            {
              onSuccess: (newModel) => {
                editTemplate({
                  templateId: template.id,
                  data: {
                    id: template.id,
                    versionNo: template.versionNo,
                    reviewtypeId: reviewType,
                    name: createdTemplate.name,
                    text: createdTemplate.text,
                    templatemodelId: newModel.id,
                  }
                },
                {
                  onSuccess: (response) => {
                    queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
                      reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
                      (templates) => {
                        const newObj = templates?.map(el => {
                          if (el.id === response.id) {
                            return response
                          }
                          return el
                        })
                        return newObj as reviewGen.template.GetReviewTemplates.ResponseType
                      })
                    snackActions.info('Шаблон изменен')
                  }
                }
                )
              } })
        }
        else
          editTemplate({
            templateId: template.id,
            data: {
              id: template.id,
              versionNo: template.versionNo,
              reviewtypeId: reviewType,
              name: createdTemplate.name,
              text: createdTemplate.text,
              templatemodelId: createdTemplate.templatemodelId.value,
            }
          },
          {
            onSuccess: (response) => {
              queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
                reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
                (templates) => {
                  const newObj = templates?.map(el => {
                    if (el.id === response.id) {
                      return response
                    }
                    return el
                  })
                  return newObj as reviewGen.template.GetReviewTemplates.ResponseType
                })
              snackActions.info('Шаблон изменен')
            }
          }
          )
      },
    })
  }

  const handleAddTemplate = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <EditTemplateModal
          close={close}
          accept={accept}
        />
      ),
      onAccept: (createdTemplate) => {
        if (createdTemplate.templatemodelId.value === createdTemplate.templatemodelId.label ) {
          createModel({ data: createdTemplate.templatemodelId.value },
            {
              onSuccess: (newModel) => {
                createTemplate({
                  data: {
                    name: createdTemplate.name!,
                    text: createdTemplate.text!,
                    reviewtypeId: reviewType,
                    templatemodelId: newModel.id
                  } },
                {
                  onSuccess: (response) => {
                    queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
                      reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
                      (template) => {
                        template?.unshift(response)
                        return template as reviewGen.template.GetReviewTemplates.ResponseType
                      })
                    snackActions.info('Шаблон добавлен')
                  }
                }
                )
              } })
        }
        else createTemplate({
          data: {
            name: createdTemplate.name!,
            text: createdTemplate.text!,
            reviewtypeId: reviewType,
            templatemodelId: createdTemplate.templatemodelId.value
          } }, {
          onSuccess: (response) => {
            queryClient.setQueryData<reviewGen.template.GetReviewTemplates.ResponseType>(
              reviewGen.template.GetReviewTemplates.getQueryKey({ query: { reviewtypeId: reviewType } }),
              (template) => {
                template?.unshift(response)
                return template as reviewGen.template.GetReviewTemplates.ResponseType
              })
            snackActions.info('Шаблон добавлен')
          }
        }
        )
      },
    })
  }
  
  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0,
    }),
    [templateData]
  )
  return (
    <StyledTableBox component={Box}>
      <TemplateFilter reviewType={reviewType} setReviewType={setReviewType}/>
      {isNotEmptyArray(templateData) ?
        <>
          <ToolBar style={{ paddingRight: '24px' }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAddTemplate}
              >
                ДОБАВИТЬ
              </Button>
            </Stack>
          </ToolBar>
          <Table
            tableList={templateData}
            totalCount={templateData.length}
            getRowId={getRowId}
            paginationLocale={pagingPanelLocale}
            tableParams={memoTableParams}
            onRowDelete={handleRowDelete}
            onModalOpen={handleCellChange}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={columnsConfig}
            showInArchiveControl={false}
            showColumnsVisibility={true}
            searching="internal"
            pagination="internal"
            sorting="internal"
            filtering="internal"
          />
        </>
        :
        <PlugTemplate reviewType={reviewType}/>
      }
    </StyledTableBox>
  )
}
