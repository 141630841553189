export type BulkOperationsPageTab = 'goods' | 'products' | 'history'
export type BulkOperationsPageOperationType =
  | 'create'
  | 'edit'
  | 'pricechange'
  | 'supplierschange'
  | 'paramsedit'
  | 'archive'
  | 'unarchive'

export const BULK_OPERATIONS_PAGE_TABS: Array<BulkOperationsPageTab> = [
  'products',
  'goods',
  'history',
]

export const BULK_OPERATIONS_PAGE_OPERATION_TYPES: Array<BulkOperationsPageOperationType> =
  [
    'create',
    'archive',
    'unarchive',
    'paramsedit',
    'edit',
    'pricechange',
    'supplierschange',
  ]
