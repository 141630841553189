import { Attribute } from 'shared/api/pim/goods'
import { isEmptyArray, isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { Option } from 'shared/ui/components/interface'
import { sortAttributesByNameAndRequired } from 'shared/lib/utils/sortAttributesByNameAndRequired'

export interface NormalizedAttributesVariation {
  type: 'variation';
  left: null | Attribute;
  right: OzonMapingAttribute;
}

export interface NormalizedAttributesLeft {
  type: 'left';
  left: Attribute;
  right: null;
}
export interface NormalizedAttributesLeftWaitFilled {
  type: 'leftWaitFilled';
  left: Attribute;
  right: null;
}
export interface NormalizedAttributesRight {
  type: 'right';
  left: null;
  right: OzonMapingAttribute;
}
export interface NormalizedAttributesMapped {
  type: 'mapped';
  left: Attribute;
  right: OzonMapingAttribute;
}

export interface NormalizedAttributes {
  normalizedAttributesVariation: Array<NormalizedAttributesVariation>;
  normalizedAttributesMapped: Array<NormalizedAttributesMapped>;
  normalizedAttributesLeft: Array<NormalizedAttributesLeft | NormalizedAttributesLeftWaitFilled>;
  normalizedAttributesRight: Array<NormalizedAttributesRight>;
}

export interface ProductAttributeValue {
  value: string;
  dictionaryValueId?: UniqueId;
  obsolete?: boolean;
}

export interface OzonMapingAttribute {
  id: number;
  attributeId?: number;
  attributeName: string;
  values?: Array<ProductAttributeValue>;
  units?: string;
  required: boolean;
  fetchCatalog?: (steach: string) => Promise<Array<Option<ProductAttributeValue>>>;
  maxCount?: number;
  multipleValues: boolean;
  isAspect?: boolean;
  description?: string;
  valueType?: string;
}

const INIT_POINTER = 0

export function getNormalizedAttributes(
  _goodsAttributes: Array<Attribute>,
  _productAttributes: Array<OzonMapingAttribute>
): NormalizedAttributes {
  const normalizedAttributesVariation: Array<NormalizedAttributesVariation> = []
  const normalizedAttributesLeft: Array<NormalizedAttributesLeft> = []
  const normalizedAttributesRight: Array<NormalizedAttributesRight> = []
  const normalizedAttributesMapped: Array<NormalizedAttributesMapped> = []

  // left
  const goodsAttributes = [ ..._goodsAttributes ]
  // right
  // могут иметь связь с goodsAttributes
  const productAttributes = [ ..._productAttributes ]

  // сначала идут аттрибуты c возможным маппингом
  // attributeId указывает что возможно есть маппинга, но в productAttributes может отсутсвовать такой же attributeId
  // Возможно сортировка не нужна
  productAttributes.sort((_, b) => b.attributeId ? 1 : -1)

  // while нужен так как нет заранее известного кол-ва проходов цикла
  while(isNotEmptyArray(goodsAttributes) || isNotEmptyArray(productAttributes)) {
    // goodsAttributes не пустой и остались только левые значение
    if (isEmptyArray(productAttributes)) {
      const index = 0
      const leftAttribute = goodsAttributes[index]

      goodsAttributes.splice(index, 1)

      normalizedAttributesLeft.push({
        type: 'left',
        left: leftAttribute,
        right: null
      })

      // eslint-disable-next-line no-continue
      continue
    }

    const productIndexPointer = INIT_POINTER
    const rightAttribute = productAttributes[productIndexPointer]
    const goodsAttributesHasMappingId: boolean = typeof rightAttribute.attributeId === 'number'
    const leftAttributeOfRightAttributeIndex = goodsAttributesHasMappingId ?
      goodsAttributes.findIndex((goodsAttribute) => goodsAttribute.attributeId === rightAttribute.attributeId) :
      // Если в продукте нет маппинга, то и левого аттрибута для правого нет (нет смаппленных полей)
      -1

    // Атрибуты объединения
    if (rightAttribute.isAspect) {
      const leftAttribute = leftAttributeOfRightAttributeIndex === -1 ? null : goodsAttributes[leftAttributeOfRightAttributeIndex]
      
      if (leftAttribute) {
        goodsAttributes.splice(leftAttributeOfRightAttributeIndex, 1)
      }

      productAttributes.splice(productIndexPointer, 1)

      normalizedAttributesVariation.push({
        type: 'variation',
        left: leftAttribute,
        right: rightAttribute
      })

      // eslint-disable-next-line no-continue
      continue
    }

    // правое значение
    if (leftAttributeOfRightAttributeIndex === -1) {
      productAttributes.splice(productIndexPointer, 1)

      normalizedAttributesRight.push({
        type: 'right',
        left: null,
        right: rightAttribute
      })

      // eslint-disable-next-line no-continue
      continue
    }

    // Условие должно срабатывать если есть оба и левый аттрибут(товара) от правого и правый аттрибут(продукта)
    const hasMapping = leftAttributeOfRightAttributeIndex > -1 && rightAttribute
    if (hasMapping) {
      const leftAttribute = goodsAttributes[leftAttributeOfRightAttributeIndex]

      normalizedAttributesMapped.push({
        type: 'mapped',
        left: leftAttribute,
        right: rightAttribute
      })

      goodsAttributes.splice(leftAttributeOfRightAttributeIndex, 1)
      productAttributes.splice(productIndexPointer, 1)

      // eslint-disable-next-line no-continue
      continue
    } else {
      // eslint-disable-next-line no-console
      console.error('Что-то не так с алгоритмом характеристик')
    }
  }

  return {
    normalizedAttributesVariation: sortAttributesByNameAndRequired(normalizedAttributesVariation),
    normalizedAttributesMapped: sortAttributesByNameAndRequired(normalizedAttributesMapped),
    normalizedAttributesLeft: sortAttributesByNameAndRequired(normalizedAttributesLeft),
    normalizedAttributesRight: sortAttributesByNameAndRequired(normalizedAttributesRight),
  }
}