import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { useMarketplaceWarehouseList } from 'shared/api/pim/dictionaries'
import { Autocomplete, TextField } from '@mui/material'

import { getOptions } from '../lib/getSelectFieldOptions'

export const MarketplaceWarehousesField = ({ marketplaceId }) => {
  const { marketplaceWarehouseListQuery } =
    useMarketplaceWarehouseList(marketplaceId)

  const marketplaceWarehousesOptions = useMemo(
    () => marketplaceWarehouseListQuery.data?.filter((el => el.priority !== false)).map(getOptions) || [],
    [marketplaceWarehouseListQuery .data]
  )
  return (
    <InputWrapper>
      <Field
        name="marketplacewarehouseId"
        id="marketplacewarehouseId"
        placeholder="Распределительный центр"
        options={marketplaceWarehousesOptions}
        fullWidth={true}
        isLoading={marketplaceWarehouseListQuery.isLoading}
      >
        {props => (
          <Autocomplete
            size="small"
            multiple={true}
            disableCloseOnSelect={true}
            onChange={(event, value) => {
              props.input.onChange(value.map((el) => (el.value)))
            }}
            fullWidth={props.fullWidth}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Распределительный центр"
              />
            )}
            options={marketplaceWarehousesOptions}
          />
        )}
      </Field>
    </InputWrapper>
  )
}
