import { Box, Tooltip } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DeleteIcon from '@mui/icons-material/Delete'
import { snackActions } from 'shared/lib/react/snackbar'
import {
  DialogContentWithTitle,
  dialogModel,
} from 'shared/ui/components/dialog'
import { usePriceHistoryContext } from 'features/product/priceHistory/model/usePriceHistoryContext'

import { StyledFileLink, StyledIconButton } from './styled'

export const ActionsCell = ({
  fileUrl,
  date,
  priceChangeId,
  versionNo,
}: {
  fileUrl?: string
  date?: string
  priceChangeId?: UniqueId
  versionNo?: number
}) => {

  const { priceHistoryQuery, deleteFuturePriceQuery } = usePriceHistoryContext()

  const handleDelete = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <DialogContentWithTitle
          close={close}
          accept={accept}
          title="Вы уверены, что хотите удалить это запланированное изменение цены?"
          acceptBtnText="ПОДТВЕРДИТЬ"
        />
      ),
      onAccept: () => {
        deleteFuturePriceQuery(priceChangeId, versionNo,
          {
            onSuccess: () => {
              priceHistoryQuery.refetch()
              snackActions.info('Цена успешно удалена!')
            },
          }
        )
      },
    })
  }

  const isFutureDate = date && new Date(date) > new Date()

  return (
    <Box display="flex" justifyContent="end">
      {fileUrl && (
        <Tooltip title={`Файл - ${fileUrl}`} placement="bottom-start">
          <StyledFileLink href={fileUrl} type="file">
            <UploadFileIcon />
          </StyledFileLink>
        </Tooltip>
      )}
      {isFutureDate && (
        <Tooltip
          title="Удалить запланированное изменение цены"
          placement="bottom-start"
        >
          <StyledIconButton onClick={handleDelete}>
            <DeleteIcon />
          </StyledIconButton>
        </Tooltip>
      )}
    </Box>
  )
}
