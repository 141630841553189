import { Theme } from '@mui/material'
import styled from 'styled-components'

export const Nav = styled.nav`
  overflow: hidden;
  color: rgba(0,0,0,0.87);
  border-radius: 50%;
  .active {
    background: ${({ theme }: { theme: Theme }) => theme.palette.primary.dark};
  }
`