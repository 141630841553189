import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

const downloadWBChangePriceTemplateFile = async ({ companymarketplaceId, contragentId, companyId }) =>
  api.post<File>(
    '/pim/ops/products/wb/change/price/template', { companymarketplaceId, contragentId, companyId },
    {
      responseType: 'blob',
    }
  )

export const useDownloadWBChangePriceTemplateFile = () =>
  useMutation(downloadWBChangePriceTemplateFile)

const uploadWBChangePriceFile = async ({
  file,
  pricechangereasonId,
  changeDate,
  revertDate,
  comments,
}: {
  file: File | null
  pricechangereasonId: UniqueId
  changeDate?: string
  revertDate?: string
  comments?: string
}) => {
  const body = new FormData()
  body.append('file', file || '')
  if (comments) { body.append('comments', comments) }

  return api.post(
    `/pim/ops/products/wb/change/price?pricechangereason_id=${pricechangereasonId}` +
      `${changeDate ? `&change_date=${changeDate}` : ''}${
        revertDate ? `&revert_date=${revertDate}` : ''
      }`,
    body
  )
}

export const useUploadWBChangePriceFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      file: File
      pricechangereasonId: UniqueId
      changeDate?: string
      revertDate?: string
      comments?: string
    }
  >
) => useMutation(uploadWBChangePriceFile, options)
