import { PropsWithChildren } from 'react'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'

interface ModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  paperProps?: object
}

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }

  .MuiDialogContent-root {
    overflow-y: visible;
  }
`

export const Modal = ({
  children,
  isOpen,
  setIsOpen,
  disableBackdropClick,
  disableEscapeKeyDown,
  paperProps
}: PropsWithChildren<ModalProps>) => {
  const handleClose = (event: object, reason: string) => {
    if(disableBackdropClick && reason === 'backdropClick' ) {
      return false
    }
    if(disableEscapeKeyDown && reason === 'escapeKeyDown' ) {
      return false
    }
    return setIsOpen(false)
  }

  return (
    <DialogStyled
      open={isOpen}
      onClose={handleClose}
      PaperProps={paperProps}
    >
      {children}
    </DialogStyled>
  )
}
