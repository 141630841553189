import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { Typography } from '@mui/material'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 400px;
    max-width: 400px;
  }
`

export const HelperTextSupply = styled(InputWrapper)`
  position: relative;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`
export const Header = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  white-space: pre-wrap;
`
export const Subheader = styled(Typography)`
  margin-top: 8px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);`


export const MarketplaceSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  label {
    top: -8px
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
  .MuiInputLabel-shrink {
    top: 0
  }
  .MuiAutocomplete-inputRoot{
    padding-top: 0;
    padding-bottom: 0;
  }
`