import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
  extends Omit<
      pimApi.dictionaries.Brand,
    | 'id'
    | 'versionNo'
    | 'changer'
  > {
  actionColumn?: string
  padding?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  name: 'name',
  code: 'code',
  padding: 'padding',
  changed: 'changed',
  actionColumn: 'actionColumn',
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование бренда',
      width: 'auto',
      sortingEnabled: false,
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Код бренда',
      width: 232,
      sortingEnabled: false,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.EditInModal,
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 35,
      cellType: CellType.ActionRemove,
    },
    {
      name: columns.padding,
      columnName: columns.padding,
      title: ' ',
      sortingEnabled: false,
      width: 10,
    }
  ],
}
