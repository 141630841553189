/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditUserRole, UserRole } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserRole>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  userroleId: number,
  data: EditUserRole
}>

export const apiFunction = ({ userId,userroleId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/admin/users/${userId}/roles/${userroleId}`,data).then(res => res.data)


export const useCloseRoleOfUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

