import { Box, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import { pimApi } from 'shared/api'


interface UserDateProps {
  values: pimApi.admin.CurrentUser
}
const dateFormat = (date) => format(new Date(date), DateFormats.dayMonthYear)
const UserDate = ({ values }: UserDateProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Typography
      style={{ color: '#000000', opacity: '0.38' }}
      variant="body2">
      Начало доступа {dateFormat(values.dateIn)}&nbsp;
    </Typography>
    {values.blockDate &&
      <Typography
        style={{ color: '#000000', opacity: '0.38' }}
        variant="body2">
        |&nbsp;Заблокирован {dateFormat(values.blockDate)}&nbsp;
      </Typography>
    }
    {values.dateOut &&
      <Typography
        style={{ color: '#000000', opacity: '0.38' }}
        variant="body2">
        |&nbsp;Закрыт доступ {dateFormat(values.dateOut)}
      </Typography>
    }
  </Box>
)

export default UserDate