import { Socket } from 'domains/socket/doc'
import { SOCKET_URL } from 'shared/config'

const URL: string = SOCKET_URL || ''

const connect = (url: string) => (connectUrl: string) => new WebSocket(url + connectUrl)

const socket = { connect: connect(URL) }

export const socketGateway = new Socket(socket, '/websocket')
