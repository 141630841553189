import { memo, useCallback, useMemo } from 'react'
import { Paper } from '@mui/material'
import queryString from 'query-string'
import { tradeGen } from 'shared/lib/generated'
import { useLocation } from 'react-router-dom'
import { tradeApi, user } from 'shared/api'
import { isNotNil } from 'shared/lib/checkers'
import { Table } from 'shared/ui/components/Table'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { useRowsPerPage } from 'shared/ui/components/Table/lib/useRowsPerPage'
import { checkValue } from 'shared/lib/checkers/checkValue'

import { columnsConfigWithStatus, columnsConfigWithoutStatus } from './model/tableColumnsConfig'
import { StyledTableBox, StyledLoader } from './ui/styled'

import {  SupplyOrdersTabs as StatusTabs } from '../SupplyOrdersTabs/ui/SupplyOrdersTabs'

const MAX_PAGE_SIZE = 50

const getRowId = (row: tradeApi.SupplyOrderWithoutPositions) => row.id

const SupplyOrdersTable = () => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)

  const { calculatedRowsPerPage } = useRowsPerPage()

  const { searchString, sort, order, limit, page, brandId, companyId, marketplaceId, contragentId, statusId, tabStatusId } = searchObj

  const currentPage = parseInt(page || '0', 10)

  const currentRowsPerPage = isNotNil(limit)
    ? parseInt(limit, 10)
    : calculatedRowsPerPage


  const pageSize = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)
    
  const supplyOrderListParams = {
    searchString: checkValue(searchString),
    sort: checkValue(sort),
    order: checkValue(order),
    brandId: checkValue(brandId),
    companyId: checkValue(companyId),
    marketplaceId: checkValue(marketplaceId),
    contragentId: checkValue(contragentId),
    statusId: checkValue(tabStatusId) ? checkValue(tabStatusId) : checkValue(statusId),
    limit: pageSize,
    page: currentPage,
  }


  const { data: supplyOrderListData, isFetching, isLoading } = 
    tradeGen.orders.GetSupplyOrders.useGetSupplyOrders({ query: supplyOrderListParams })

  const sortTableParams =
    searchObj.sort && searchObj.order
      ? [{ columnName: searchObj.sort, direction: searchObj.order }]
      : []

  const memoTableParams = useMemo(
    () => ({
      sort: sortTableParams,
      limit: pageSize,
      page: currentPage,
      brandId,
      companyId,
      marketplaceId,
      contragentId,
      searchString,
    }),
    [supplyOrderListData]
  )

  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()

  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.supplyOrderListTableHiddenColumns
    
  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          supplyOrderListTableHiddenColumns: hiddenColumns,
        },
      })
    },
    [userSettingsQuery?.data]
  )
  return (
    <>
      <StyledTableBox component={Paper}>
        <StatusTabs/>
        {(isFetching || isLoading) && <StyledLoader size={60} />}
        {supplyOrderListData && (
          <Table
            tableList={supplyOrderListData.result}
            totalCount={supplyOrderListData.total}
            getRowId={getRowId}
            handleTableSettingsChange={handleTableSettingsChange}
            tableHiddenColumns={tableHiddenColumns}
            tableParams={memoTableParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={checkValue(tabStatusId) ? columnsConfigWithoutStatus : columnsConfigWithStatus}
            showInArchiveControl={false}
            showColumnsVisibility={true}
            searching="external"
            pagination="external"
            sorting="external"
            filtering="external"
          />
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoSupplyOrdersTable = memo(SupplyOrdersTable)
