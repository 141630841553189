import { pimApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName = keyof Omit<
  pimApi.price.ProductPriceHistory,
 | 'productId'
>


type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  changeDate: 'changeDate',
  marketplaceId: 'marketplaceId',
  changer: 'changer',
  comments: 'comments',
  name: 'name',
  rrp: 'rrp',
  marketplaceUrl: 'marketplaceUrl',
  marketplaceCode: 'marketplaceCode',
  marketplaceName: 'marketplaceName',
  copackingCost: 'copackingCost',
  incomingPrice: 'incomingPrice',
  priceInfo: 'priceInfo',
  pricechangereason: 'pricechangereason'
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.changeDate,
      columnName: columns.changeDate,
      title: 'Дата\nПользователь',
      width: 170,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PriceHistoryDate
    },
    {
      name: columns.changer,
      columnName: columns.changer,
      title: 'Маркетплейс\nНоменклатура',
      width: 130,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PriceHistoryNomenclature
    },
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: '\nНаименование',
      width: 200,
      sortingEnabled: false,
      cellType: CellType.PriceHistoryName,
      wordWrapEnabled: true,
    },
    {
      name: columns.incomingPrice,
      columnName: columns.incomingPrice,
      title: 'Входящая цена\nРРЦ',
      width: 135,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.PriceHistoryIncomingPrice
    },
    {
      name: columns.copackingCost,
      columnName: columns.copackingCost,
      title: '\nСтоимость упаковки',
      wordWrapEnabled: true,
      width: 170,
      sortingEnabled: false,
      cellType: CellType.PriceHistoryCopackingCost
    },
    {
      name: columns.pricechangereason,
      columnName: columns.pricechangereason,
      title: '\nСтарая цена',
      width: 170,
      sortingEnabled: false,
      cellType: CellType.PriceHistoryOldPrice
    },
    {
      name: columns.marketplaceCode,
      columnName: columns.marketplaceCode,
      title: ' ',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.PriceHistoryDeltaIcon
    },
    {
      name: columns.marketplaceId,
      columnName: columns.marketplaceId,
      title: '\nНовая цена',
      width: 180,
      sortingEnabled: false,
      cellType: CellType.PriceHistoryNewPrice
    },
    {
      name: columns.comments,
      columnName: columns.comments,
      title: '\nКомментарий',
      width: 300,
      sortingEnabled: false,
      cellType: CellType.RepriceComments,
      wordWrapEnabled: true
    },
  ],
}
