import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  min-height: 560px;
  width: 100%;
  .CellLayout-cell {
    height: 50px;
    line-height: 18px;
  }
  
  .TableCell-cell {
    height: 50px;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const TableWrapper = styled.div`
  margin-top: 64px;
  display: flex;
`

export const SideTable = styled(Box)`
  width: 460px;
  height: 100%;
  .TableContainer-root {
    border-radius: 4px;
  }
  table {
    min-width: 460px!important;
    tbody::after {
      content: "";
      display: block;
      height: 50px;
    }
  }
`

export const ShortTable = styled(Box)`
  margin: 16px 0 12px 0;
  width: calc(100% - 476px);
  position: relative;


  .MuiToolbar-root {
    position: absolute;
    top: -161px;
    border-bottom: none;

    div:first-child {
      display: block !important;
    }
  }

  .TableContainer-root {
    border-radius: 4px;
  }

  td.TableDetailCell-active {
    min-width: 0;
    padding-left: 110px;
    padding-right: 170px;

    .MuiTableHead-root {
      display: none;
    }

    table {
      min-width: 0 !important;

      tr td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 36px;
      }
    }
  }

  td.TableDetailCell-active button {
    margin-left: -110px;
  }

  table {
    min-width: 900px !important;
    tbody::after {
      content: "";
      display: block;
      height: 50px;
    }
  }

  .TableDetailCell-active {
    table {
      tbody {
        width: 100%;

        tr td:last-child {
          margin-left: auto;
        }
      }

      tbody::after {
        content: "";
        display: block;
        height: 0;
      }
    }
  }

  .Pager-pager {
    height: 56px;
  }

  .PageSizeSelector-pageSizeSelector, .Pagination-pagination {
    display: none;
  }
  [isrowexpanded]+tr{
    .MuiTableCell-root.TableDetailCell-active {
      box-shadow: inset  0 -1px 0 #64B6F7;
    }
  }

  [isrowexpanded]{
    td {
      border-bottom: 0;
    }

  [isrowexpanded]+tr tr:last-child{
    td {
      border-bottom: 0;
    }
  }
  
`

export const CustomHeader = styled(Box)`
  height: 80px;
  border-bottom: 1px solid #E0E0E0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    line-height: 14px;
  }
`