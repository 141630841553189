import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

import { StyledButton } from './styled'

const SortingIcon = ({ direction }) =>
  direction === 'desc' ? (
    <ArrowDownward style={{ fontSize: '18px' }} />
  ) : (
    <ArrowUpward style={{ fontSize: '18px' }} />
  )

export const SortLabel = ({ direction }) => (
  <StyledButton
    aria-haspopup="true"
    aria-label="sort"
    id="long-button"
    $alwaysshow={!!direction}
  >
    <SortingIcon direction={direction} />
  </StyledButton>
)
