import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface EditContragentManagerPayload {
    contragentId: UniqueId,
    managerId: UniqueId,
    managerEditedData: pimApi.admin.EditContragentUser
}
export const editContragentManager = ({ contragentId, managerId, managerEditedData }: EditContragentManagerPayload) =>
  api.put<pimApi.admin.EditContragentUser>(
    `/pim/admin/contragents/${contragentId}/users/${managerId}`,
    managerEditedData

  )


export const useEditContragentManagerMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.EditContragentUser>,
        AxiosError<errorType>,
        EditContragentManagerPayload
        >(editContragentManager)
