import React, { useState } from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { LargeSizeWidget } from 'widgets/DashboardWidgets/LargeSizeWidget'
import { ValueLegendDot } from 'widgets/DashboardWidgets/components/ValueDelta/styled'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { subtractDays } from 'shared/lib/utils/subtractDays'

import { StyledChartDetails } from './styled'


const TimelineRange: Record<number, any> = {
  0: {
    startValue: subtractDays(new Date(), 14),
    endValue: subtractDays(new Date(), 1),
    timelineText: {
      title: 'за неделю',
      legendText: {
        currentPeriod: 'на этой неделе',
        prevPeriod: 'на прошлой неделе'
      }
    }
  },
  1: {
    startValue: subtractDays(new Date(subtractMonths(new Date(), 2)), 0),
    endValue:  subtractDays(new Date(), 1),
    timelineText: {
      title: 'за месяц',
      legendText: {
        currentPeriod: 'в этом месяце',
        prevPeriod: 'в прошлом месяце'
      }
    }
  },
}
interface DataParamsTypes {
  dateFrom?: string
  dateTo?: string
  units: string
  range: number
}
export const LargeWeeklySalesWidget = () => {
  const [ dataParams, setDataParams ] = useState<DataParamsTypes>({
    dateFrom: TimelineRange[0].startValue,
    dateTo: TimelineRange[0].endValue,
    units: 'items',
    range: 0
  })

  const { data: salesData, isFetching: salesFetching } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom: TimelineRange[dataParams.range].startValue,
        dateTo: TimelineRange[dataParams.range].endValue,
        units: dataParams.units,
      })

  const half = salesData ? salesData?.data?.data?.length / 2 : 0
  const currentData = salesData?.data.data.slice(half)
  const prevData = salesData?.data.data.slice(0,half)

  const chartData: Array<any> | undefined = currentData?.map((el, index) => ({
    currentSale: el?.quantity || 0,
    prevSale: prevData?.[index]?.quantity || 0,
    date: new Date(el.saleDate),
  }
  ))

  const currentSalesQuantity = currentData?.reduce((sum, elem) => sum + elem.quantity, 0)
  const prevSalesQuantity = prevData?.reduce((sum, elem) => sum + elem.quantity, 0)
  const salesDiff = (currentSalesQuantity && prevSalesQuantity) ? currentSalesQuantity - prevSalesQuantity : 0

  const chartUrl = generatePath(`/analytics/charts?range=${dataParams.range}&tab=1`)
  return (
    <>
      {salesData?.data.data ?
        <LargeSizeWidget
          title={`Продажи ${TimelineRange[dataParams.range].timelineText.title}`}
          setDataParams={setDataParams}
          dataParams={dataParams}
          isLoading={salesFetching}
          chartUrl={chartUrl}
          chartData={chartData}
        >
          <ChartDetail dataParams={dataParams} salesDiff={salesDiff} salesValue={currentSalesQuantity || 0}/>
        </LargeSizeWidget>
        :
        <Skeleton/>
      }
    </>
  )
}

const ChartDetail = ({ dataParams, salesValue, salesDiff }: { dataParams: DataParamsTypes, salesValue: number, salesDiff: number }) => (
  <StyledChartDetails>
    <ValueDelta value={salesValue} delta={salesDiff} shortValue={true}/>
    <div>
      <div>
        <ValueLegendDot color="#007DFF"/>
        <span>Продажи {TimelineRange[dataParams.range].timelineText.legendText.currentPeriod}</span>
      </div>
      <div>
        <ValueLegendDot color="#BDBDBD"/>
        <span>Продажи {TimelineRange[dataParams.range].timelineText.legendText.prevPeriod}</span>
      </div>
    </div>
  </StyledChartDetails>
)