import { QueryFunctionContext, useQuery } from 'react-query'

import { AttributesSearchResult } from './models'

import { api } from '../../base'
import toSnakeCase from '../../../lib/transform/toSnakeCase'


interface QueryParams {
  searchString?: string
  page?: number
  limit?: number
}
export const getAttributesQueryKey = (queryParams?: QueryParams) =>
  ['get', 'pim/attributes', queryParams] as const

type GetAttributesQueryKey = ReturnType<typeof getAttributesQueryKey>

export const fetchAttributes = (queryParams?: QueryParams) =>
  api.get<AttributesSearchResult>('/pim/attributes', { params: { ...toSnakeCase(queryParams) } }).then(res => res.data)

const getAllAttributes = (context: QueryFunctionContext<GetAttributesQueryKey>) => {
  const [, , queryParams] = context.queryKey
  const snakeQueryParams = toSnakeCase(queryParams)
  return fetchAttributes(snakeQueryParams)
}

export const useGetAttributesQuery = (queryParams?: QueryParams) =>
  useQuery<
    AttributesSearchResult,
    errorType,
    AttributesSearchResult,
    GetAttributesQueryKey
  >(
    getAttributesQueryKey(queryParams),
    getAllAttributes,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
