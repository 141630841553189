import React from 'react'
import { Checkbox } from '@mui/material'
import styled from 'styled-components'

interface BooleanCellProps {
  flag: any
  onCellChange?: (changes) => void
  editingEnabled?: boolean
  cellColumnName: string
  row: any
}

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  svg {
    display: inherit !important;
  }
`

export const BooleanCell = ({ flag, row, onCellChange, editingEnabled, cellColumnName }: BooleanCellProps) => {
  const handleChange = (_, value: boolean) => {
    if (onCellChange) {
      onCellChange({ ...row, [cellColumnName]: value })
    }
  }

  return <StyledCheckbox checked={ flag } disabled={ !editingEnabled } size="small" onChange={handleChange} />
}
