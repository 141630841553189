import { isNil } from 'shared/lib/checkers'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import queryString from 'query-string'

export const customDate = (x) => {
  const historySearch = queryString.parse(window.location.search)
  const { groupby } = historySearch
  if (x.value) {
    if (groupby === 'day' || isNil(groupby)) {
      const utc = new Date(x.value)
      const stringDate = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      return (
        `${stringDate.split('-')[2]} ${DateAppearanceSettings[stringDate.split('-')[1]].month}`
      )
    }
    return x.value
  }
  return ''
}