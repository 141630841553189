import React, { useMemo, useState } from 'react'
import 'react-grid-layout/css/styles.css'

import { StyledWidthProviderResponsive } from './styled'

interface GridProps {
  className?: string | undefined;
  rowHeight?: number | undefined;
  cols: any
  margin?: [number, number] | { [P: string]: [number, number] } | undefined;
}
export interface ShowcaseLayout {
  children
  gridProps: GridProps
  layouts: any
  setChildren: (e) => void
  editable: boolean
  onLayoutChange?: (layout, layouts) => void
  compactType?: 'vertical' | 'horizontal' | null | undefined;
  width?: number | undefined;
}



export const ShowcaseLayoutFunctional = (
  {
    children,
    gridProps,
    editable,
    onLayoutChange,
    layouts,
    compactType,
  } : ShowcaseLayout,
) => {
  
  const [colsWidth, setColsWidth] = useState(0)
  const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
    if (layoutItem.h === 2) {
      // eslint-disable-next-line no-param-reassign
      layoutItem.w = 2
      // eslint-disable-next-line no-param-reassign
      placeholder.w = 2
    }
    if (layoutItem.w > 2 || layoutItem.h > 2) {
      // eslint-disable-next-line no-param-reassign
      layoutItem.h = 4
      // eslint-disable-next-line no-param-reassign
      placeholder.h = 4
      // eslint-disable-next-line no-param-reassign
      layoutItem.w = 4
      // eslint-disable-next-line no-param-reassign
      placeholder.w = 4
    }
  }

  const ResponsiveReactGridLayout = useMemo(() => StyledWidthProviderResponsive, [])
  // const ResponsiveReactGridLayout = StyledWidthProviderResponsive
  return (
    <ResponsiveReactGridLayout
      {...gridProps}
      layouts={{ lg: layouts, md: layouts }}
      onResize={onResize}
      // breakpoints={{ lg: 1616, md: 996, sm: 768, xs: 480, xxs: 0 }}
      onLayoutChange={onLayoutChange}
      useCSSTransforms={false}
      onWidthChange={(containerWidth) => {setColsWidth((containerWidth - 16) / 6)}}
      height={126}
      editable={editable}
      width={colsWidth}
      isDraggable={editable}
      isResizable={editable}
      isBounded={editable}
      // measureBeforeMount={true}
      compactType={compactType}
    >
      {children}
    </ResponsiveReactGridLayout>
  )
}
