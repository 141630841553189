import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Box, Button, DialogTitle } from '@mui/material'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { FieldWithCount, InputField, InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { ValidationErrors } from 'final-form'
import { isEmpty } from 'lodash'

import { validationSchema } from './lib/validationSchema'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 566px;
    max-width: 566px;
  }
`

export const InputWithCounter = styled(InputWrapper)`
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0;
  p {
    margin-top: 3px;
    margin-bottom: 0;
  }
`
interface IFormValue {
    orderNumber: string | null
    comments: string | null
}

interface ManagerAddModalProps {
    close: () => void;
    handleOrderChange: (IFormValue) => void
    comments?: string | null
    orderNumber?: string | null
}

export const EditOrderModal = ({
  close,
  handleOrderChange,
  comments = null,
  orderNumber = null
}: ManagerAddModalProps) => {

  const [formValue, setFormValue] = useState<IFormValue>({ orderNumber, comments })
  const [isDisabled, setIsDisabled] = useState(true)
  const [isError, setIsError] = useState<ValidationErrors>({})

  const onSubmit = (newData) => {
    const changedData = {
      orderNumber: newData.orderNumber,
      comments: newData.comments || ''
    }
    handleOrderChange(changedData)
    close()
  }

  const initValue = {
    orderNumber, comments
  }

  const validate = useValidationSchema(validationSchema)

  useEffect(() => {
    if ((formValue.orderNumber === orderNumber &&
            (formValue.comments === comments || (!formValue.comments && !comments)))
        || !isEmpty(isError)
    ) {
      setIsDisabled(true)
    } else { setIsDisabled(false) }
  }, [formValue, isError])

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20}>
          Изменение информации о заказе
        </DialogTitle>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            initialValues={initValue || null}
            validate={validate}
            render={({ handleSubmit, errors, values }) => {
              setFormValue(values)
              setIsError(errors)  
              return (
                <form onSubmit={handleSubmit}>
                  <Box mt={1}>
                    <InputLabel
                      label="Номер заказа" required={false}
                    />
                    <InputWrapper>
                      <Field
                        name="orderNumber"
                        label={errors && errors?.orderNumber ? errors?.orderNumber : ''}
                        placeholder="Номер заказа"
                        component={InputField}
                        fullWidth={true}
                      />
                    </InputWrapper>
                  </Box>
                  <Box mt={3}>
                    <InputLabel
                      label="Комментарий" required={false}
                    />
                    <InputWithCounter>
                      <FieldWithCount maxLength={255}>
                        <Field
                          name="comments"
                          placeholder="Комментарий"
                          label={errors && errors?.comments ? errors?.comments : ''}
                          multiLine={true}
                          minRows={1}
                          maxRows={5}
                          max={255}
                          component={InputField}
                          fullWidth={true}
                        />
                      </FieldWithCount>
                    </InputWithCounter>
                  </Box>

                  <Box sx={{
                    marginTop: '24px',
                    padding: 0,
                    marginBottom: '24px',
                    textAlign: 'right'
                  }}>
                    <Button
                      onClick={close}
                      color="primary"
                    >
                      ОТМЕНА
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isDisabled}
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      ИЗМЕНИТЬ
                    </Button>
                  </Box>
                </form>
              )
            }
            }
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



