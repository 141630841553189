import styled from 'styled-components'
import { Theme } from '@mui/material'


export const Help = styled.span`
  color: rgba(0, 0, 0, 0.38);
  display: inline-flex;
  align-items: center;
  height: 100%;
  svg {
    font-size: 17px;
  }
`

export const HelpWithMargin = styled(Help)`
  margin-left: 10px;
`

export const HelpWithMarginDND = styled(Help)`
  cursor: pointer;
  position: absolute;
  left: 4px;
  bottom: 4px;
  height: 17px;

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.palette.info.main};
  }
`
