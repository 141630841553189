import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import {
  AppPimGoodsModelsGoodsGoodsComponent,
} from 'domains/pim/goods'
import { ComponentCard, goodsLib } from 'entities/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import { InputLabel } from 'shared/ui/components'

import { ResultList, ErrorText, InfoText } from './styled'


interface GoodsInComponentProps {
  goodsType: pimApi.goods.GoodsType
  withRemove: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const GoodsInComponent: FC<GoodsInComponentProps> = ({
  withRemove,
}) => {
  const componentsField = useField('bundleGoods')

  if(isEmpty(componentsField.input.value)) return null

  return (
    <ResultList>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <InputLabel
          label={`${goodsLib.getGoodsLabelByType(pimApi.goods.GoodsType.Bundle)} `}
        />
        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.38)' }}>(не менее двух)</Typography>
      </Box>
      <Box>
        <Box mb={3} display="flex" flexDirection="column" gap={2}>
          <FieldArray<AppPimGoodsModelsGoodsGoodsComponent> name="bundleGoods">
            {({ fields }) =>
              !isEmpty(fields) && (
                fields.value.map((goodsComponent, index) => {
                  const imgUrl = pimApi.goods.getGoodsThumbnailApiPath(
                    goodsComponent.componentId,
                    fileThumbnailSize
                  )

                  return (
                    <ComponentCard
                      imgUrl={goodsComponent.goodsBaseImageId ? imgUrl : undefined}
                      goodsType={goodsComponent.goodsType}
                      componentId={goodsComponent.componentId}
                      name={goodsComponent.name}
                      code={goodsComponent.code}
                      unitCode={goodsComponent.unitCode}
                      auxCode={goodsComponent.auxCode}
                      unitPartible={goodsComponent.unitPartible}
                      onRemoveClick={() => {
                        if(withRemove) {
                          fields.remove(index)
                        }
                      }}
                    />
                  )
                })
              )
            }
          </FieldArray>
        </Box>
      </Box>
      {componentsField.meta.touched && componentsField.meta.error ? (
        <Box mt={2}>
          <ErrorText>
            {componentsField.meta.error}
          </ErrorText>
        </Box>
      ) : (
        <Box mt={2}>
          <InfoText>
            {componentsField.meta.error}
          </InfoText>
        </Box>
      )}
    </ResultList>
  )
}
