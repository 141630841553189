import { useEffect, useState } from 'react'

export const useTableFiltersPresets = ({ filtersPresetsKey }: { filtersPresetsKey: string }) => {
  const filtersPresetsLocalStore = JSON.parse(localStorage.getItem(filtersPresetsKey) || '{}')

  const [ filtersPresets, setFiltersPresets ] = useState(filtersPresetsLocalStore)

  const editFiltersPresets = (filtersParams) => {
    localStorage.setItem(filtersPresetsKey, JSON.stringify(filtersParams))
    setFiltersPresets(filtersParams)
    window.dispatchEvent(new Event('localStorageUpdate'))
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setFiltersPresets(JSON.parse(localStorage.getItem(filtersPresetsKey) || '{}'))
    }

    window.addEventListener('storage', handleStorageChange)
    window.addEventListener('localStorageUpdate', handleStorageChange)

    return () => {
      window.addEventListener('storage', handleStorageChange)
      window.removeEventListener('localStorageUpdate', handleStorageChange)
    }
  }, [filtersPresetsKey])
  return { editFiltersPresets, filtersPresets }
}