import { Table } from '@devexpress/dx-react-grid-material-ui'
import { pimApi } from 'shared/api'

import { StyledTableCell } from './styled'
import { ChangesCell } from './changesCell'
import { DateCell } from './dateCell'

import { columns } from '../../columnsConfig'

const getCell = (name: string, row: pimApi.goods.ChangeLog) => {
  switch (name) {
    case columns.changeDate: {
      return <DateCell date={row.changeDate} />
    }

    case columns.changes: {
      return <ChangesCell changes={row.changes} />
    }

    default:
      return <span title={row[name]}>{row[name]}</span>
  }
}

type TableCellComponentProps = Omit<Table.DataCellProps, 'row'> & { row: pimApi.goods.ChangeLog }

export const TableCellComponent = (props: TableCellComponentProps) => {
  const { column, row } = props
  return (
    <StyledTableCell {...props}>
      {getCell(column.name, row)}
    </StyledTableCell>
  )
}
