import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

const createNewContragent = (newContragent: pimApi.admin.NewContragent) =>
  api.post<pimApi.admin.CurrentContragent>(
    '/pim/admin/contragents',
    newContragent
  )

export const useCreateContragentMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.CurrentContragent>,
        AxiosError<errorType>,
        pimApi.admin.NewContragent
        >(createNewContragent)