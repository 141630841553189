import { generatePath, useHistory } from 'react-router-dom'
import { Form } from 'shared/ui/components'
import { tradeApi } from 'shared/api'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { getServerReadableDateTime } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { SUPPLY_ORDER_EDIT, SUPPLY_ORDER_LIST } from 'shared/config/routes'

import { CreateSupplyOrdersForm } from './ui/CreateSupplyOrdersForm'
import { validationSchema } from './lib/validationSchema'

const defaultCalendarMaxDate = getServerReadableDateTime(new Date())

const initialValues = {
  orderDate: defaultCalendarMaxDate,
  estimatedSupplyDate: null,
  days: 30,
  autoOrdering: false,
  notCClassOnly: true
}

export const CreateSupplyOrder = () => {
  const history = useHistory()
  const { mutate: createSupplyOrderMutation, isLoading } =
    tradeApi.orders.useCreateSupplyOrderMutation()
  const validate = useValidationSchema(validationSchema)

  const onSubmit = (values) => {
    createSupplyOrderMutation(values, {
      onSuccess: (data) => {
        snackActions.info('Сохранено!')
        const path = values.multipleOrders === true ? SUPPLY_ORDER_LIST :
          `${generatePath(SUPPLY_ORDER_EDIT, {
            id: data.data?.orderId,
          })}` 
        history.push(path)
      },
    })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, invalid }) => (
        <CreateSupplyOrdersForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          invalid={invalid}
        />
      )}
    />
  )
}
