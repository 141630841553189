import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { generatePath, useHistory } from 'react-router'
import arrayMutators from 'final-form-arrays'
import {
  Box,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Field, RadioGroupField } from 'shared/ui/components'
import { Option } from 'shared/ui/components/interface'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { MemoGoodsSearchSection } from 'features/product/WB/CreateNewTab/ui/CreateNewModal/ui/GoodsSearchSection'
import { PRODUCT_EDIT } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { SelectProductWidget } from 'features/product/widgets/SelectProductWidget'
import { getWBProductSearch } from 'shared/api/pim/products/wb'
import { GlobalParametersSelect } from 'features/product/widgets'
import { pimGen } from 'shared/lib/generated'
import { localStore } from 'shared/services/localStore'


const ADD_NOMENCLATURE_ACTION_TYPE: Array<Option> = [
  {
    value: 'create_new_nomenclature',
    label: 'Новая карточка на WB'
  },
  {
    value: 'use_existing_nomenclature',
    label: 'Добавить существующую номенклатуру'
  }
]

export const CreateNewNomenclatureWindow = (
  { close, companymarketplaceId, wbnomenclatureId, productId }:
    { close: () => void; wbnomenclatureId: UniqueId; companymarketplaceId: UniqueId; productId: UniqueId; }) =>
{

  const localStoreInitialValues = localStore.get('formInitialValues')
  const parsedStoreInitialValues = localStoreInitialValues ? JSON.parse(localStoreInitialValues) : null
  const formInitialValues = {
    actionType: 'create_new_nomenclature',
    attributeType: parsedStoreInitialValues?.attributeType || 'general'
  }
  const history = useHistory()
  const [ relatedProductId, setRelatedProductId ] = useState<number|undefined>(undefined)
  const { mutate: createProductWB, isLoading: createProductWBInProgress } =
    pimGen.wildberriesProduct.CreateProduct.useCreateProduct()
  const { mutate: moveProductToAnotherCard, isLoading: MoveProductToAnotherCardInProgress }
    = pimGen.wildberriesProduct.PatchProduct.usePatchProduct()

  const onSubmit = (formValues) => {
    const goodsId = formValues?.selectedGoods?.[0]?.componentId
    if (formValues.actionType === 'create_new_nomenclature') {
      createProductWB({ data: { 
        companymarketplaceId,
        attributeType: formValues.attributeType,
        wbnomenclatureId,
        goodsId,
        useGoodsMedia: false
      } },
      {
        onSuccess: (response) => {
          localStore.set('formInitialValues', JSON.stringify({ ...parsedStoreInitialValues, attributeType: formValues.attributeType }))
          close()
          snackActions.info('Сохранено!')
          history.push(generatePath(PRODUCT_EDIT, { id: response.productId }))
        },
      }
      )
    }
    if (formValues.actionType === 'use_existing_nomenclature') {
      if (relatedProductId) {
        moveProductToAnotherCard({ 
          productId, data: {
            method: 'move_to_card',
            attributeType: formValues.attributeType,
            productId: relatedProductId
          } },
        {
          onSuccess: () => {
            localStore.set('formInitialValues', JSON.stringify({ ...parsedStoreInitialValues, attributeType: formValues.attributeType }))
            close()
            snackActions.info('Сохранено!')
            history.push(generatePath(PRODUCT_EDIT, { id: relatedProductId }))
          },
        }
        )
      }
    }
  }
  const searchFunction = (searchString) => getWBProductSearch(companymarketplaceId, searchString )
  const onSelectProduct = (p) => {
    setRelatedProductId(p)
  }
  const onClearSelection = () => {
    setRelatedProductId(undefined)
  }
  const submitImpossible = (values) => {
    if (values.actionType === 'create_new_nomenclature') {
      return !(values.selectedGoods?.length>0)
    }
    return values.actionType === 'use_existing_nomenclature' && relatedProductId === undefined
  }

  return (
    <Box sx={{ width: '640px', maxWidth: '640px' }}>
      <IconCloseButton aria-label="close" onClick={close}>
        <img src={CloseIcon} alt="close icon" />
      </IconCloseButton>
      <DialogTitle sx={{ paddingTop: '24px', paddingBottom: '0' }} fontSize={20}>Новая номенклатура</DialogTitle>
      <Box p={3}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={formInitialValues}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Field
                  name="actionType"
                  component={RadioGroupField}
                  options={ADD_NOMENCLATURE_ACTION_TYPE}
                  row={true}
                />
                <Box>
                  {(values.actionType === 'create_new_nomenclature') && <MemoGoodsSearchSection />}
                  {(values.actionType === 'use_existing_nomenclature') &&
                    <SelectProductWidget
                      searchFunction={searchFunction}
                      searchInputRequired={true}
                      quantityRequired={false}
                      onSelectProduct={onSelectProduct}
                      onClearSelection={onClearSelection}
                    />}
                  <GlobalParametersSelect/>
                </Box>
              </Box>
              <DialogActions sx={{ paddingRight: '0', paddingTop: '24px' }}>
                <Box>
                  <Button onClick={close} color="primary">
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={createProductWBInProgress || MoveProductToAnotherCardInProgress}
                    disabled={submitImpossible(values)}
                    sx={{ marginLeft: 2 }}
                  >
                    ДОБАВИТЬ
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )}
        />
      </Box>
    </Box>
  )
}
