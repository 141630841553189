import { Divider, Theme } from '@mui/material'
import styled from 'styled-components'

export const GridTitleStyled = styled.div`
  margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
  white-space: nowrap;
`

export const DividerStyled = styled(Divider)`
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.23) 0%,
    rgba(0, 0, 0, 0.23) 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: 4px 1px;
  border: none;
`
