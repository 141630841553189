import { api } from '../api/base'
import { formatBytes } from '../lib/utils/formatBytes'

export const getImage = (url: string) => api.get<File>(
  url,
  {
    responseType: 'blob',
    dontShowMessageNotify : true,
  }
)

const getImageResolution: any = (blob) => new Promise((resolve, reject) => {
  const element = document.createElement('img')
  element.src = URL.createObjectURL(blob)

  element.onload = () => resolve(element)
  element.onerror = () => reject
})

export const getImageInfo = async (url: string) => {
  const blobResponse = await fetch(url).then((res) => res.blob()).then(async (blob) => {
    const blobResolution = await getImageResolution(blob).then(img => `${ img.width }×${ img.height }`)
    return ({
      blobResolution,
      image: blob,
      size: formatBytes(blob.size),
      type: blob.type.split('/')[1]
    })
  })
  return { blobResponse }
}