import { dialogModel } from 'shared/ui/components/dialog'
import { SettingsMenu } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { memo } from 'react'

interface CompetitorSettingsProps {
  competitor: pimApi.goods.Competitor
  handleEdit: ({ competitor }:{competitor: pimApi.goods.Competitor}) => void
  handleDelete: ({ competitorId, versionNo }) => void
}

export const CompetitorsSettings = memo(({ competitor, handleEdit, handleDelete }: CompetitorSettingsProps) => {
  const isBlocked = !competitor.reckonedFlag

  const DEFAULT_SETTINGS = [
    {
      label: 'Игнорировать',
      disabled: isBlocked,
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <div style={{ width: '560px' }}>
                <ModalContent
                  close={close}
                  accept={accept}
                  variant="contained"
                  title="Игнорировать конкурента?"
                  acceptBtnText="Подтвердить"
                />
              </div>,
            onAccept: () => {
              handleEdit({ competitor })
            },
          }
        )
      }
    },
    {
      label: 'Участвует в волшебнике цен',
      disabled: !isBlocked,
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <div style={{ width: '560px' }}>
                <ModalContent
                  close={close}
                  accept={accept}
                  variant="contained"
                  title="Учитывать в волшебнике цен?"
                  acceptBtnText="Подствердить"
                />
              </div>,
            onAccept: () => {
              handleEdit({ competitor })
            },
          }
        )
      }
    },
    {
      label: 'Удалить конкурента',
      type: 'error',
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <ModalContent
                close={close}
                accept={accept}
                title="Удалить конкурента?"
                acceptBtnText="Удалить"
                acceptBtnColor="error"
              />,
            onAccept: () => {
              handleDelete({ competitorId: competitor.id, versionNo: competitor.versionNo })
            },
          }
        )
      }
    },
  ]

  return (
    <div>
      <SettingsMenu
        iconSize="small"
        options={DEFAULT_SETTINGS}
        iconType={<MoreVert />}
      />
    </div>
  )
}
)
