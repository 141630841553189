import { SearchResultType } from 'shared/api/goods/models'
import {
  CellType,
  FilterType,
  ColumnConfig,
} from 'shared/ui/components/Table/model/types'

type ColumnName = keyof Omit<SearchResultType, 'products' | 'id'>
type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  goodsType: 'goodsType',
  hasPhoto: 'hasPhoto',
  name: 'name',
  category: 'category',
  brand: 'brand',
  season: 'season',
  code: 'code',
  auxCode: 'auxCode',
  inArchive: 'inArchive',
  suppliers: 'suppliers',
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.goodsType,
      columnName: columns.goodsType,
      title: 'Тип',
      width: 86,
      sortingEnabled: false,
      align: 'center',
      cellType: CellType.GoodsType,
      filterType: FilterType.GoodsType,
    },
    {
      name: columns.hasPhoto,
      columnName: columns.hasPhoto,
      title: 'Фото',
      width: 86,
      sortingEnabled: false,
      align: 'center',
      cellType: CellType.Photo,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 'auto',
      sortingEnabled: true,
      cellType: CellType.NameLink,
      isSingleString: true
    },
    {
      name: columns.category,
      columnName: columns.category,
      title: 'Категория',
      width: 150,
      sortingEnabled: true,
    },
    {
      name: columns.season,
      columnName: columns.season,
      title: 'Сезонность',
      width: 150,
      sortingEnabled: true,
    },
    {
      name: columns.brand,
      columnName: columns.brand,
      title: 'Бренд',
      width: 150,
      sortingEnabled: true,
      filterType: FilterType.Brand,
    },
    {
      name: columns.suppliers,
      columnName: columns.suppliers,
      title: 'Поставщик',
      width: 180,
      sortingEnabled: true,
      filterType: FilterType.Contragent
    },
    {
      name: columns.code,
      columnName: columns.code,
      title: 'Артикул',
      width: 170,
      sortingEnabled: true,
    },
    {
      name: columns.auxCode,
      columnName: columns.auxCode,
      title: 'Доп. артикул',
      width: 160,
      sortingEnabled: true,
    },
  ],
}
