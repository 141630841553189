import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  table {
    min-width: 1300px !important;
  }

  .TableCell-cell {
    height: 50px;
  }
  
  .CellLayout-cellRight {
    >div {
      >div {
        padding-right: 0;
      }
    }
    //.CellLayout-cellRight {
    //  background-color: red;
    //}
  }
  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`