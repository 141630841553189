import React, { useMemo, useState } from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { registerGradient } from 'devextreme/common/charts'
import { MultiSeriesLargeSizeWidget } from 'widgets/DashboardWidgets/MultiSeriesLargeSizeWidget'
import { ValueDelta } from 'widgets/DashboardWidgets/components/ValueDelta/ValueDelta'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'

import { StyledChartDetails } from './styled'


const TimelineRange: Record<number, any> = {
  0: {
    startValue: subtractDays(new Date(), 14),
    endValue: subtractDays(new Date(), 1),
    timelineText: {
      title: 'за неделю',
    }
  },
  1: {
    startValue: subtractDays(new Date(subtractMonths(new Date(), 2)), 0),
    endValue:  subtractDays(new Date(), 1),
    timelineText: {
      title: 'за месяц',
    }
  },
}

interface dataType {
  orders: number
  stocks: number
  orderDate: string
}

interface WidgetData {
  name: string
  valueField: string
  data: number
  diff: number
  color: string
  areaColor?: {
    fillId: string
  }
}

interface DataParamsTypes {
  dateFrom?: string
  dateTo?: string
  units: string
  range: number
}

export const LargeOrdersAndStocksWidget = () => {
  const currentDay = subtractDays(new Date(), 1)
  const prevDay = subtractDays(new Date(), 2)
  const [ dataParams, setDataParams ] = useState<DataParamsTypes>({
    dateFrom: TimelineRange[0].startValue,
    dateTo: TimelineRange[0].endValue,
    units: 'items',
    range: 0
  })

  const { data: ordersData, isFetching: ordersFetching } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom: TimelineRange[dataParams.range].startValue,
        dateTo: TimelineRange[dataParams.range].endValue,
        units: dataParams.units,
      })

  const { data: stocksData, isFetching: stocksFetching } =
    analyticsApi.useAnalyticsStocksQuery(
      {
        dateFrom: TimelineRange[dataParams.range].startValue,
        dateTo: TimelineRange[dataParams.range].endValue,
        units: dataParams.units,
      })

  const ordersHalf = ordersData ? ordersData?.data?.data?.length / 2 : 0

  const currentOrdersQuantity = ordersData?.data.data.slice(ordersHalf).reduce((sum, elem) => sum + elem[dataParams.units], 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,ordersHalf).reduce((sum, elem) => sum + elem[dataParams.units], 0)

  const currentStocksQuantity =
    stocksData?.data.data.filter(el =>
      el.stocksDate === currentDay).
      reduce((sum, elem) => sum + elem.available, 0)

  const prevStocksQuantity =
    stocksData?.data.data.filter(el =>
      el.stocksDate === prevDay).
      reduce((sum, elem) => sum + elem.available, 0)

  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const stocksDiff = (currentStocksQuantity !== undefined && prevStocksQuantity !== undefined) ?
    (currentStocksQuantity - prevStocksQuantity) : 0

  const chartUrl = generatePath(`/analytics/charts?range=${dataParams.range}&tab=0`)

  const holder = {}
  stocksData?.data.data.forEach((el) => {
    // eslint-disable-next-line no-prototype-builtins
    if (holder.hasOwnProperty(el.stocksDate)) {
      holder[el.stocksDate] =+ holder[el.stocksDate] + el.available
    } else {
      holder[el.stocksDate] = el.available
    }
  })

  const chartData: Array<dataType> | any = useMemo(() => ordersData?.data.data.map((el) => ({
    orders: el[dataParams.units],
    numberOfOrders: el.numberOfOrders,
    stocks: stocksData ?
      stocksData?.data.data.filter((elem) => elem.stocksDate === el.orderDate).
        reduce((sum, elem) => sum + elem.available, 0) : [],
    orderDate:el.orderDate
  })), [ordersData, stocksData])

  const ordersSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(76, 175, 80, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(76,175,80,0.02)'
      }],
      rotationAngle: 90
    })
  }

  const stocksSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '30%',
        color: 'rgba(63, 81, 181, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(33,150,243,0.02)'
      }],
      rotationAngle: 90
    })
  }
  
  return (
    <>
      {(ordersData?.data.data && stocksData?.data.data) ?
        <MultiSeriesLargeSizeWidget
          title="Заказы и остатки"
          chartData={chartData.slice(ordersHalf)}
          chartUrl={chartUrl}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            color: '#4CAF50',
            name: 'Заказы',
            valueField: 'orders',
            areaColor: ordersSeriesColor
          }}
          rightData={{
            data: currentStocksQuantity || 0,
            diff: stocksDiff,
            name: 'Остатки',
            color: '#3F51B5',
            valueField: 'stocks',
            areaColor: stocksSeriesColor
          }}
          dataParams={dataParams}
          isLoading={ordersFetching || stocksFetching}
          setDataParams={setDataParams}>
          <ChartDetail
            half={ordersHalf}
            chartData={chartData}
            leftData={{
              data: currentOrdersQuantity || 0,
              diff: ordersDiff,
              color: '#4CAF50',
              name: 'Заказы',
              valueField: 'orders',
              areaColor: ordersSeriesColor
            }}
            rightData={{
              data: currentStocksQuantity || 0,
              diff: stocksDiff,
              name: 'Остатки',
              color: '#3F51B5',
              valueField: 'stocks',
              areaColor: stocksSeriesColor
            }}
          />
        </MultiSeriesLargeSizeWidget>
        :
        <Skeleton/>
      }
    </>
  )
}


const ChartDetail = ({ 
  leftData,
  rightData,
  chartData,
  half }: { leftData: WidgetData, rightData: WidgetData, chartData: any, half: number }) => {
  const currentData = chartData.slice(half)
  const prevData = chartData.slice(0, half)

  const currentOrdersCurrency = currentData.reduce((sum, elem) => sum + elem.ordersCurrency, 0)
  const prevOrdersCurrency = prevData.reduce((sum, elem) => sum + elem.ordersCurrency, 0)

  const currentOrdersItems = currentData.reduce((sum, elem) => sum + elem.orders, 0)
  const prevOrdersItems = prevData.reduce((sum, elem) => sum + elem.orders, 0)

  const currentAveragePrice = currentOrdersCurrency / currentOrdersItems
  const prevAveragePrice = prevOrdersCurrency / prevOrdersItems

  const diffAveragePrice = currentAveragePrice - prevAveragePrice

  return (
    <StyledChartDetails>
      <div>
        <ValueDelta shortValue={true} color={leftData.color} value={leftData.data} delta={leftData.diff} name={leftData.name}/>
      </div>
      <div>
        <ValueDelta shortValue={true} color={rightData.color} value={rightData.data} delta={rightData.diff} name={rightData.name}/>
      </div>
      <div>
        <ValueDelta shortValue={true} value={currentAveragePrice || 0} delta={diffAveragePrice || 0} name="Ср. цена за ед. продукта"/>
      </div>
    </StyledChartDetails>
  )
}