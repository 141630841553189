import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'

import { ProductProfitability } from '../../models'

interface DataParamsTypes {
  productId: number
  price: number
  priority: boolean
}

export const getProductsProfitabilityQueryKey = (queryParams: DataParamsTypes) =>
  ['get', '/pim/products/common/profitability', queryParams] as const

type GetProductsProfitabilityQueryKey = ReturnType<typeof getProductsProfitabilityQueryKey>

function getProductsProfitability(queryParams: DataParamsTypes) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get(
    '/pim/products/common/profitability', { params: queryParamsSnake }
  ).then(res => res.data)
}

const getAllProductsProfitability = (context: QueryFunctionContext<GetProductsProfitabilityQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getProductsProfitability(queryParams)
}

export const useProductsProfitabilityQuery = (queryParams: DataParamsTypes) =>
  useQuery<
    Array<ProductProfitability>,
    errorType,
    Array<ProductProfitability>,
    GetProductsProfitabilityQueryKey
  >(
    getProductsProfitabilityQueryKey(queryParams),
    getAllProductsProfitability,
    { refetchOnMount: false, staleTime: Infinity }
  )
