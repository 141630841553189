// @ts-nocheck
import styled from 'styled-components'
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import { Theme } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'

import { getColorForRow } from '../helpers'

const StyleRowDetailCellComponent = styled(TableRowDetail.Cell)<{
  theme: Theme, row: CalculatedReceiptDocPosition
}>`
  position: relative;
  
  ${({ theme, row }) => {
    const { bgColor = '#EBF5FF', borderColor = '#007DFF' } = getColorForRow(theme, row)

    return `
      background-color: ${bgColor} !important;
      box-shadow: inset 2px 0px 0px ${borderColor};
      border-bottom: 1px solid ${borderColor};
      ::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: calc(100% + 50px);
        box-shadow: inset -1px 1px 0px ${borderColor};
      }
    `
  }}
`

export const RowDetailCellComponent = (props: TableRowDetail.CellProps) => (
  <StyleRowDetailCellComponent {...props} />
)
