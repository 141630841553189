import React from 'react'

import { ProfitabilityContainer } from '../../../../ProfitabilityContainer'

export const Profitability = ({ cellValue }) => {
  const profitability = cellValue?.anywhere
  const profit = profitability?.profit
  const profitPercent = profitability?.profitPercent

  return (
    <>
      { cellValue ?
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <ProfitabilityContainer profit={ profit } profitPercent={ profitPercent } />
        </div>
        :
        null }
    </>
  )
}