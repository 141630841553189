import React, { useMemo } from 'react'
import { analyticsApi } from 'shared/api'
import { generatePath } from 'react-router'
import { TwinMediumChartWidget } from 'widgets/DashboardWidgets'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'
import { registerGradient } from 'devextreme/common/charts'


interface dataType {
  orders: number
  stocks: number
  orderDate: string
}

export const MediumSalesDynamicsWidget = () => {
  const subtractDays = (days) => {
    const utc = new Date()
    const date = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000))
    date.setDate(date.getDate() - days)
    return date.toISOString().split('T')[0]
  }

  const dateFrom = subtractDays(14)
  const currentDay = subtractDays(1)

  const { data: ordersData } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom,
        dateTo: currentDay,
        units: 'items',
      })

  const { data: salesData } =
    analyticsApi.useAnalyticsSalesQuery(
      {
        dateFrom,
        dateTo: currentDay,
        units: 'items',
      })


  const half = ordersData ? ordersData?.data.data.length / 2 : 0
  const currentOrdersQuantity = ordersData?.data.data.slice(half).reduce((sum, elem) => sum + elem.items, 0)
  const prevOrdersQuantity = ordersData?.data.data.slice(0,half).reduce((sum, elem) => sum + elem.items, 0)

  const currentSalesQuantity = salesData?.data.data.slice(half).reduce((sum, elem) => sum + elem.quantity, 0)
  const prevSalesQuantity = salesData?.data.data.slice(0,half).reduce((sum, elem) => sum + elem.quantity, 0)

  const ordersDiff = (currentOrdersQuantity !== undefined && prevOrdersQuantity !== undefined) ?
    (currentOrdersQuantity - prevOrdersQuantity) : 0

  const salesDiff = (currentSalesQuantity !== undefined && prevSalesQuantity !== undefined) ?
    (currentSalesQuantity - prevSalesQuantity) : 0

  const chartUrl = generatePath('/analytics/charts?range=0&tab=1')

  const chartData: Array<dataType> | any = useMemo(() => ordersData?.data.data.map((el) => ({
    orders: el.items,
    sales: salesData?.data.data.filter((elem) => elem.saleDate === el.orderDate).reduce((sum, elem) => sum + elem.quantity, 0),
    orderDate:el.orderDate
  })), [ordersData, salesData])

  const ordersSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '20%',
        color: 'rgba(76, 175, 80, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(76,175,80,0.02)'
      }],
      rotationAngle: 90
    })
  }

  const salesSeriesColor = {
    fillId: registerGradient('linear', {
      colors: [{
        offset: '20%',
        color: 'rgba(33, 150, 243, 0.08)'
      },
      {
        offset: '70%',
        color: 'rgba(33,150,243,0.02)'
      }],
      rotationAngle: 90
    })
  }

  return (
    <>
      {(ordersData?.data.data && salesData?.data.data) ?
        <TwinMediumChartWidget
          title="Динамика продаж за неделю"
          chartData={chartData.slice(half)}
          chartUrl={chartUrl}
          leftData={{
            data: currentOrdersQuantity || 0,
            diff: ordersDiff,
            color: '#4CAF50',
            name: 'Заказы',
            valueField: 'orders',
            areaColor: ordersSeriesColor
          }}
          rightData={{
            data: currentSalesQuantity || 0,
            diff: salesDiff,
            name: 'Выкупы',
            color: '#007DFF',
            valueField: 'sales',
            areaColor: salesSeriesColor
          }}
        />
        :
        <Skeleton/>
      }
    </>
  )
}