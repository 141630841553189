import styled from 'styled-components'
import { Box, Card, CardContent, CardMedia } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  height: 109px;
  overflow: hidden;
  padding: 0 0 2px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 28px;
  margin-bottom: 24px;

  .MuiTab-root {
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
    :last-child {
      max-width: 120px;
      margin-left: auto;
    }
  }
`
export const StyledBox = styled(Box)`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 16px;
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  bottom: calc(50% - 25px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledCard = styled(Card)`
  width: 348px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding-bottom: 8px;

  .MuiSvgIcon-root {
    color: #fff;
    width: 60px;
    height: 60px;
  }
`

export const StyledCardMedia = styled(CardMedia)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  background: #2196f3;
`

export const StyledCardContent = styled(CardContent)`
  min-height: 104px;
`

export const BulkOperationsContainer = styled(Box)`
  display: inline-flex;
  flex-wrap: wrap;
`

export const SettingsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  height: 56px;
  border-radius: 4px;
  background-color: #2196f314;
  margin-right: 16px;

  .styled-icons {
    color: #ffffff;
  }
`
export const HistoryDataWrap = styled.div`
  height: 20px;
  margin-top: 6.5px;

  span {
    margin-right: 16px;
    color: #00000099;
    font-size: 12px;
  }
`

export const DataWrap = styled.div`
  padding-top: 24px;
  div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px 0;
  }
`

export const SuccsesfulStatus = styled.span`
  align-items: center;
  color: #3b873e;
  font-size: 13px;
  border-radius: 16px;
  margin-left: auto;
  padding: 4px 14px 4px 5px;
  height: 18px;
  background-color: white;

  span {
    position: relative;
    top: 3px;
    align-self: center;
  }

  .styled-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

export const FailedStatus = styled(SuccsesfulStatus)`
  color: #00000099;
`
export const HistoryListWrap = styled.div`
  width: 57%;
  padding: 8px;
  border-right: 1px solid #e0e0e0;

  .styled-img {
    margin-bottom: 24px;
  }

  #subTitle-styled {
    color: #00000099;
  }
`
export const FlexWrap = styled.div`
  display: flex;

  .red {
    background-color: red;
  }
`

export const HistoryItemDetailWrap = styled.div`
  width: 43%;
  padding: 24px 24px 8px 24px;
  position: sticky;
  top: 56px;
  height: 500px;
  font-size: 14px;

  .styled-typography {
    font-size: 20px;
  }
`
export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const HistoryFiltersWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 16px 32px;
  border-bottom: 1px solid #e0e0e0;

  .searchInput {
    width: 220px;
    margin-right: 24px;
  }
`

export const ListItemWrap = styled.div<{
  $isActive: boolean
}>`
  border-radius: 4px;
  display: flex;
  padding: 8px 24px;
  background-color: ${({ $isActive }) =>
    $isActive ? '#EBF5FF' : ''} !important;
  cursor: pointer;
  font-size: 14px;

  :hover {
    background-color: #fafafa;
  }
`

export const SelectWrapper = styled.div<{ width: number }>`
  .MuiAutocomplete-root {
    min-width: ${({ width }) => `${width}px`};
    color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    .MuiFormLabel-root {
      position: absolute;
      top: -2px;
    }
    .MuiAutocomplete-inputRoot {
      height: 40px;
      padding-bottom: 3px;
      padding-top: 1px;
      .MuiAutocomplete-input {
        width: 0;
        min-width: max-content;
        ::-webkit-input-placeholder {
          opacity: 1;
          color: #0000008a;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 4px;
    }
    .MuiInputBase-root {
      border: 0 solid rgba(0, 0, 0, 0);
    }
    .MuiAutocomplete-popupIndicator {
      color: #00000042;
    }
    .MuiAutocomplete-popupIndicatorOpen {
      color: #00000042;
    }
  }
`

export const LoaderWrapper = styled.div<{ height: number }>`
  position: relative;
  height: ${({ height }) => `${height}px`};
`

export const StatusWrapper = styled.div`
  margin-left: auto;
  width: 240px;
  height: 27px;
`

export const StyledLoaderExtra = styled(StyledLoader)`
  bottom: 0 !important;
`
export const HistoryItemInfo = styled.div`
  display: flex;
  .styled-typography {
    height: 22px;
    width: 316px;
  }
`

export const TitleStatusWrap = styled.div`
  width: 100%;
`
