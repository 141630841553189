import { dialogModel } from 'shared/ui/components/dialog'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import styled from 'styled-components'
import * as React from 'react'

import { AddPositionsInCopackingModalContent } from '../AddPositionsInCopackingModalContent'

export const AddButton = styled.div`
  display: flex;
  & button {
    z-index: 2;
  }
`

export const AddPositions = ( { copackingordersId, refetchPage }: {copackingordersId: UniqueId, refetchPage: () => void}) => {

  const handleClick = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <AddPositionsInCopackingModalContent
          close={close}
          copackingordersId={copackingordersId}
          refetchPage={refetchPage}
        />
      )
    })
  }

  return (
    <AddButton>
      <Button
        variant="outlined"
        id="add-button"
        disableElevation={true}
        onClick={handleClick}
        startIcon={<AddIcon />}
        size="small"
      >
        ДОБАВИТЬ ПОЗИЦИИ
      </Button>

    </AddButton>
  )
}
