
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { NewSimpleProductRequest, NewSimpleProductResponse } from '../models'
import { api } from '../../../base'


export const createProductSimple = (newOzonProduct: NewSimpleProductRequest) =>
  api.post<NewSimpleProductResponse>('/pim/products/simple/product', newOzonProduct)

export const useCreateProductSimpleMutation = () =>
  useMutation<
    AxiosResponse<NewSimpleProductResponse>,
    errorType,
    NewSimpleProductRequest
  >(createProductSimple)
