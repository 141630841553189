import { Grid, Paper } from '@mui/material'

import { Title, SubTitle } from './styled'
import { BrandsSupplierForm } from './BrandsSupplierForm'
import { BaseInfoSupplierForm } from './BaseInfoSupplierForm'
import { UploadSettingsSupplierForm } from './UploadSettingsSupplierForm'
import { ManagersSupplierForm } from './ManagersSupplierForm'

const EditSupplierForm = ({ 
  handleSubmit, 
  errors, 
  values, 
  push,
  pop,
  handleBrandsChange,
  handleBrandsDelete,
  handleUserAdd,
  handleUserDelete,
  handleUserChange,
  handleCheckOrderForm,
  handleDeleteOrderForm,
  uploadFile,
  isUploading
}) => {

  const orderGenerationOptions = [
    { label: 'Не кратно упаковке', value: false },
    { label: 'Кратно упаковке', value: true },
  ]

  const handleSupplierEdit = () => {
    handleSubmit()
  }
  const { inArchive } = values
  return (
    <form onSubmit={() => {}}>
      <Paper>
        <Title>Основная информация</Title>
        <BaseInfoSupplierForm
          handleSupplierEdit={handleSupplierEdit}
          errors={errors}
          orderGenerationOptions={orderGenerationOptions}
          inArchive={inArchive}
        />
      </Paper>
      
      <Paper sx={{ marginTop: '24px' }}>
        <Title>
          <span>Настройка загрузки файлов</span>
          <SubTitle>Настройка загрузки файлов используется для упрощения обработки и анализа файлов поставщика </SubTitle>
        </Title>
        <UploadSettingsSupplierForm
          orderGenerationOptions={orderGenerationOptions}
          handleSupplierEdit={handleSupplierEdit}
          uploadFile={uploadFile}
          handleCheckOrderForm={handleCheckOrderForm}
          handleDeleteOrderForm={handleDeleteOrderForm}
          values={values}
          isUploading={isUploading}
          inArchive={inArchive}
        />
      </Paper>

      <Grid container={true} spacing={3} mt={0}>
        <Grid item={true} xs={5}>
          <Paper>
            <Title>Бренды поставщика</Title>
            <BrandsSupplierForm
              values={values}
              handleBrandsChange={handleBrandsChange}
              handleBrandsDelete={handleBrandsDelete}
              push={push}
              pop={pop}
              inArchive={inArchive}
            />
          </Paper>
        </Grid>

        <Grid item={true} xs={7}>
          <Paper>
            <Title>Менеджеры поставщика</Title>
            <ManagersSupplierForm
              handleUserAdd={handleUserAdd}
              handleUserDelete={handleUserDelete}
              handleUserChange={handleUserChange}
              values={values}
              inArchive={inArchive}
            />
          </Paper>
        </Grid>
      </Grid>
    
    </form>
  )
}

export default EditSupplierForm
