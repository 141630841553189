import React from 'react'
import { Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import isEmpty from 'lodash/isEmpty'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { InputField, InputLabel } from 'shared/ui/components'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import { pimApi } from 'shared/api'

import { attributesValuesSchema } from '../../lib/validationSchema'


interface AttributesValuesModalProps {
  close: () => void
  accept: (receiveData: any) => void
  attributesValues: pimApi.dictionaries.Value[]
  initialValues?: any
}
export const AttributesValuesModal = ({ accept, close, initialValues, attributesValues }: AttributesValuesModalProps) => {
  const validate = useValidationSchema(attributesValuesSchema(attributesValues))

  return (
    <Box sx={ { width: '640px' } }>
      <IconCloseButton aria-label="close" onClick={ close }>
        <img src={ CloseIcon } alt="close icon" />
      </IconCloseButton>
      <DialogTitle fontSize={ 20 } whiteSpace="pre-line" sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        { initialValues ? 'Изменить значение в атрибуте' : 'Добавить значение в атрибут' }
        { initialValues && <span style={{ fontSize: '16px', fontWeight: '400', paddingBottom: '8px' }}>{ initialValues?.name }</span>}
      </DialogTitle>
      <Box px={ 3 } mt={ 1 }>
        <Form
          onSubmit={ accept }
          validate={ validate }
          initialValues={ initialValues }
          render={ ({
            handleSubmit,
            errors,
            error,
            values: formValues
          }) => (
            <form onSubmit={ handleSubmit } noValidate={ true }>
              <Box>
                <InputLabel
                  label="Название значения"
                  required={ false }
                />
                <StyledField>
                  <InputWrapper>
                    <Field
                      name="name"
                      placeholder="Название значения"
                      component={ InputField }
                      fullWidth={ true }
                      error={ error }
                    />
                  </InputWrapper>
                </StyledField>
              </Box>

              <DialogActions sx={ {
                marginTop: '24px',
                padding: 0,
                marginBottom: '24px'
              } }>

                <Box>
                  <Button
                    onClick={ close }
                    color="primary"
                  >
                    ОТМЕНА
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={ false }
                    disabled={ !isEmpty(errors) || (JSON.stringify(initialValues) === JSON.stringify(formValues)) }
                    sx={ { marginLeft: 2 } }
                  >
                    { initialValues ? 'Изменить' : 'Добавить' }
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          )
          }
        />
      </Box>
    </Box>
  )
}