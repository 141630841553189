import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'

const downloadSimpleProductBulkCreationTemplateFile = async () =>
  api.get<File>('/pim/ops/products/simple/product', {
    responseType: 'blob',
  })

export const useDownloadSimpleProductBulkCreationTemplateFile = () =>
  useMutation(downloadSimpleProductBulkCreationTemplateFile)

const uploadSimpleProductBulkCreationFile = async (queryParams: {
  file: File | null
  companymarketplaceId: UniqueId
  useGoodsMedia: boolean
}) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')

  return api.post(
    `/pim/ops/products/simple/product?companymarketplace_id=${queryParams.companymarketplaceId}
&use_goods_media=${queryParams.useGoodsMedia}`,
    body
  )
}

export const useUploadSimpleProductBulkCreationFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    { file: File; companymarketplaceId: UniqueId, useGoodsMedia: boolean }
  >
) => useMutation(uploadSimpleProductBulkCreationFile, options)
