import { gql } from '@apollo/client'

export const GET_FILTERS_QUERY = gql(`
  query MyQuery(
    $companyId: [Int!] = []
    $cardStatusId: [Int!] = []
    $companymarketplaceId: [Int!] = []
    $contragentId: [Int!] = []
    $marketplaceId: [Int!] = []) {
    products(
      companyId: $companyId,
      companymarketplaceId: $companymarketplaceId,
      contragentId: $contragentId,
      cardStatusId: $cardStatusId,
      marketplaceId: $marketplaceId,
      excludeInArchive: true
      ) {
      filters {
        marketplaceId {
          label
          value
          count
        }
        companyId {
          label
          value
          count
        }
        companymarketplaceId {
          label
          value
          count
        }
        contragentId {
          label
          value
          count
        }
      } 
    }
  }
`
)
