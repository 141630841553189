import { TextField, TextFieldProps } from '@mui/material'

import { MaskInputMemo } from '../form/MaskedInputField/MaskInput'

type MaskInputProps = TextFieldProps & {
  scale?: number,
  min?: number,
  max?: number,
}

export const MaskedInput = ({
  value,
  margin,
  helperText,
  label,
  error,
  onBlur,
  onFocus,
  onChange,
  disabled,
  name,
  type = 'text',
  scale,
  min,
  max,
}: MaskInputProps) => (
  <TextField
    label={label}
    variant="outlined"
    size="small"
    fullWidth={true}
    value={value}
    onChange={onChange}
    margin={margin}
    onBlur={onBlur}
    onFocus={onFocus}
    name={name}
    disabled={disabled}
    type={type}
    error={error}
    helperText={helperText}
    InputLabelProps={value ? { shrink: true } : {}}
    InputProps={{
      inputComponent: MaskInputMemo,
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{
      scale,
      min,
      max,
    }}
  />
)
