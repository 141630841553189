import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api/index'

import { api } from '../../base'

export const getMarketplacesWarehousesQueryKey = (marketplaceId: UniqueId) =>
  ['get', '/pim/admin/marketplaces/marketplacewarehouses', marketplaceId] as const

type GetMarketplacesWarehousesQueryKey = ReturnType<typeof getMarketplacesWarehousesQueryKey>

function getMarketplacesWarehouses(marketplaceId: UniqueId) {
  return api.get<Array<pimApi.admin.MarketplaceWarehouse>>
  (`/pim/admin/marketplaces/${marketplaceId}/marketplacewarehouses`).then((res) => res.data)
}

const getMarketplacesWarehousesFn = (context: QueryFunctionContext<GetMarketplacesWarehousesQueryKey>) => {
  const [, ,marketplaceId] = context.queryKey
  return getMarketplacesWarehouses(marketplaceId)
}

export const useGetMarketplacesWarehousesQuery = (marketplaceId: UniqueId) =>
  useQuery<
    Array<pimApi.admin.MarketplaceWarehouse>,
    errorType,
    Array<pimApi.admin.MarketplaceWarehouse>,
    GetMarketplacesWarehousesQueryKey
  >(
    getMarketplacesWarehousesQueryKey(marketplaceId),
    getMarketplacesWarehousesFn, { keepPreviousData: true, refetchOnMount: false, staleTime: Infinity }
  )