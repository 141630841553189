import { customerApi } from 'shared/api'
import { useCompanyContext } from 'entities/pim/admin/useCompanyEditContext'
import { Box, Button, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { Form } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { getCurrentCompanyQueryKey } from 'shared/api/customer'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { isEqual } from 'lodash'

import { StyledLoader, InArchive, PaperStyle } from './ui/styled'
import { validationSchema } from './lib/validationSchema'
import EditCompanyForm from './ui/EditCompanyForm'
import Cabinet from './ui/Cabinet/Cabinet'
import { AddCabinetModal } from './ui/modal/AddCabinetModal'
import { EditCompanyOptions } from './ui/EditCompanyOption/EditCompanyOptions'
import { CompanyLogo } from './ui/CompanyLogo/CompanyLogo'



interface IEditCompany {
  data: customerApi.Company
}


export const EditCompany= ({ data }: IEditCompany) => {

  const { companyQuery } = useCompanyContext()

  const companyData = data

  const queryClient = useQueryClient()

  const { mutate: addNewCabinet } =
    customerApi.usePostNewCabinetMutation()

  const { mutate: editCompany } =
    customerApi.useEditCompanyMutation()

  const { mutate: uploadLogo } =
    customerApi.usePostCompanyLogoMutation()

  const companyLogo = customerApi.useCompanyLogoQuery(companyData.id)

  const handleSaveChanges = (editedCompany) => {
    if (editedCompany && companyData && !isEqual(editedCompany, companyData)) {
      editCompany( {
        companyId: companyData.id,
        editedCompany: {
          id: editedCompany.id,
          siteUrl: editedCompany.siteUrl,
          versionNo: editedCompany.versionNo,
          name: editedCompany.name,
          inn: editedCompany.inn,
          inArchive: editedCompany.inArchive
        }
      },{
        onSuccess: (response ) => {
          queryClient.setQueryData( getCurrentCompanyQueryKey(companyData.id), response.data )
          snackActions.info('Информация изменена!')
        }
      })
    }
  }

  const handleAddCabinet = (cabinetsData) => {
    if (cabinetsData && companyData) {
      const newCredentials = [] as customerApi.NewCompanyMarketplaceCredential[]
      cabinetsData.credentials.forEach(cred => {
        if (cred.value) {
          newCredentials.push({ credentialId: cred.id, value: cred.value })
        }
      })
      addNewCabinet( {
        companyId: companyData.id,
        addedCabinet: {
          marketplaceId: cabinetsData.id,
          cabinetName: cabinetsData.cabinetName,
          credentials: newCredentials
        }
      },{
        onSuccess: ( response ) => {
          const cabinets = companyData?.cabinets as customerApi.CompanyMarketplace[]
          cabinets.push(response.data)
          queryClient.setQueryData( getCurrentCompanyQueryKey(companyData.id), {  ...companyData, cabinets })
          snackActions.info('Кабинет добавлен!')
        }
      })
    }
  }

  const handleOpenAddCabinetModal = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <AddCabinetModal
          handleAddCabinet={handleAddCabinet}
          companyName={companyData.name}
          cabinets={companyData.cabinets}
          close={close}
        />
      )
    })
  }

  const handleUploadCompanyLogo = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && isNotEmptyArray(event.target.files)) {
      for (let i = 0; i < event.target.files.length; i+=1) {
        const file = new FormData()
        file.append('company_logo', event.target.files[i] || '')
        uploadLogo({
          companyId: companyData.id,
          file
        }, {
          onSuccess: () => {
            companyLogo.refetch()
            snackActions.info('Логотип компании загружен')
          },
        })
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = ''
    }
  }, [uploadLogo])

  const validate = useValidationSchema(validationSchema)

  return (
    <Box my = {3} >
      { (companyQuery.isLoading || companyQuery.isFetching) && <StyledLoader size={60} /> }
      {companyData &&
        <>
          <PaperStyle>
            <Box p={3} display="flex" alignItems="center">
              <CompanyLogo
                companyLogo={companyLogo}
                handleUploadLogo={handleUploadCompanyLogo}
                inArchive={companyData.inArchive}
              />
              <Typography fontSize={24} mx={6} >
                { companyData.name }
              </Typography>
              { companyData.inArchive && <InArchive>В архиве</InArchive> }
              <Box height={100} ml="auto">
                <EditCompanyOptions data={companyData} />
              </Box>
            </Box>
            <Box  pb={1} >
              <Form
                onSubmit={handleSaveChanges}
                validate={validate}
                initialValues={companyData}
                render={({
                  handleSubmit,
                  errors,
                }) => (
                  <EditCompanyForm
                    handleSubmit={handleSubmit}
                    errors={errors}
                    values={companyData}
                  />
                )}
              />
            </Box>
          </PaperStyle>
          <PaperStyle>
            <Box mt={3} p={3}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={20} fontWeight={500}>Кабинеты</Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon/>}
                  disabled={companyData.inArchive}
                  onClick={handleOpenAddCabinetModal}
                >Добавить
                </Button>
              </Box>
              { companyData.cabinets 
                && companyData.cabinets.map(cabinetData =>
                  <Cabinet key={cabinetData.id} cabinetData={cabinetData} companyInArchive={companyData.inArchive}/>)
              }
            </Box>
          </PaperStyle>
        </>
      }
    </Box>
  )
}
