import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledTableBox = styled(Box)`
  min-height: 250px;
  position: relative;
  border: none;
  box-shadow: none;

  table {
    border-top: 1px solid #E0E0E0;
  }
  
  .MuiTableCell-head {
    max-height: 80px;
    white-space: break-spaces;
    background: rgba(247, 247, 247, 0.01);
    line-height: 40px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .MuiTableCell-body {
    height: 96px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .MuiToolbar-root {
      display: none;
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
`