import React, { useMemo } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import { Box, Tabs, Typography } from '@mui/material'
import { pimApi } from 'shared/api'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { PRODUCT_EDIT } from 'shared/config/routes'
import { Image, TabPanel } from 'shared/ui/components'
import { useFileUrl } from 'pages/goods/model/hooks'
import { a11yProps } from 'shared/lib/TabsA11yProps'

import { NomenclatureTab } from './ui/NomenclatureTab'
import { TabPopupMenu } from './ui/TabPopupMenu'
import { CreateNewNomenclatureTab } from './ui/CreateNewNomenclatureTab'

import { NoMarginGrid } from '../styled'
import { NomenclatureTabsNav, StyledTab } from '../../../styled'


const getTabLabel = (
  tab
) => {
  let label = 'НОВАЯ'
  if (tab.nmid) {
    label = tab.nmid
  } else if (tab.vendorCode) {
    label = tab.vendorCode
  }

  return label
}

export const Nomenclatures = ({ isVariationsArchived }) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  const history = useHistory()

  const { wbProductQuery } = useWBProductContext()

  const SelectedVar = wbProductQuery?.data?.variations?.find(
    (variation) => variation?.productId === productId
  )
  const media = pimApi.products.getFileThumbnailApiPath(SelectedVar?.productId || 0, { maxWidth: 50, maxHeight: 50 })
  const { data } = useFileUrl(media)


  const tabs = useMemo(() => {
    const variations = wbProductQuery?.data?.variations
    const variationSelected = variations?.find(
      (variation) => variation?.productId === productId
    )
    const currentProductId = variationSelected?.productId || productId

    const labeledTabs = [
      ...(wbProductQuery?.data?.otherNomenclatures
        ? wbProductQuery?.data?.otherNomenclatures
        : []),
      {
        nmid: wbProductQuery?.data?.nmid,
        productId: currentProductId,
        vendorCode: wbProductQuery?.data?.vendorCode,
        inArchive: isVariationsArchived,
      },
    ].map((tab) => ({
      ...tab,
      label: getTabLabel(
        tab,
      ),
    }))
    return sortBy(labeledTabs, ['productId'])
  }, [
    wbProductQuery?.data?.otherNomenclatures,
    productId,
    wbProductQuery?.data?.inArchive,
    wbProductQuery?.data?.vendorCode,
    wbProductQuery?.data?.nmid,
  ])

  const handleChangeTab = (_: React.ChangeEvent<{}>, value: number) => {
    history.push(generatePath(PRODUCT_EDIT, { id: value }))
  }

  return (
    <>
      <NomenclatureTabsNav $isArchived={isVariationsArchived} $isMedia={!data}>
        <Tabs
          value={productId}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => {
            const apiUrl = pimApi.products.getFileThumbnailApiPath(tab.productId!, { maxWidth: 50, maxHeight: 50 })
            return (
              <StyledTab
                key={tab.productId}
                value={tab.productId}
                icon={
                  <Box display="flex" alignItems="center" justifyContent="flex-end" flex="auto">
                    <Image style={{
                      filter: tab.inArchive ? 'grayscale(1)' : '',
                      opacity: tab.inArchive && '0.6'
                    }} src={apiUrl}/>
                    <Typography sx={{
                      fontSize: '15px',
                      fontWeight: '500',
                      lineHeight: '26px',
                      color: tab.inArchive ? 'rgba(0, 0, 0, 0.38)' : '#007DFF'
                    }}>{tab.label}</Typography>
                    <TabPopupMenu/>
                  </Box>}
                iconPosition="end"
                {...a11yProps(tab.productId || index)}
              />
            )
          })}
          <CreateNewNomenclatureTab />
        </Tabs>
      </NomenclatureTabsNav>
      <NoMarginGrid container={true} spacing={2}>
        {tabs.map((tab) => (
          <TabPanel key={tab.productId} value={productId} index={tab.productId}>
            <NomenclatureTab />
          </TabPanel>
        ))}
      </NoMarginGrid>
    </>
  )
}
