import styled from 'styled-components'
import { Grid, Theme } from '@mui/material'
import { TabsNav, TabsNavWithMargin } from 'pages/goods/components/common'

export const ProductTitle = styled.div`
  font-size: 34px;
  margin-bottom: 10px;
`

export const GridInputWrapperStyled = styled(Grid)`
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`

export const POrgStyled = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: 0.15px;
`


export const ParamsTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  span{
    display: inline-flex;
    align-items: center;
    padding-right: 5px;
    background: #fff;
    position: relative;
    z-index: 2;
  }
  &:after{
    content: "";
    border-top: 1px dashed rgba(0, 0, 0, 0.23);
    width: 100%;
    position: absolute;
    z-index: 0;
  }
`
export const NoMarginGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`
export const DashedAction = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  color: #007DFF;
  border-bottom: 1px dashed #007DFF;
  cursor: pointer;
`
export const Wrapper = styled.div`
  border: 1px solid rgba(0, 125, 255, 0.5);
  border-top: 0;
  margin-top: -36px;
  padding: 18px 16px 16px;
`
export const NomenclatureTabsNav = styled(TabsNavWithMargin)`
  padding-left: 25px;
`
export const SizeTabsNav = styled(TabsNavWithMargin)`
  border-bottom-style: dashed;
`
export const GridWithArrow = styled(Grid)`
  position: relative;
`
export const InputArrow = styled.div`
  position: absolute;
  right: -7px;
  top: 50%;
  margin-top: -8px;
  svg{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
  }
`
export const SpanStarStyled = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
`
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
  }
  .MuiFormControlLabel-root{
    margin-right: 9.5px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: 100%;
  }
`


export const ProductTabsNav = styled(TabsNav)`
  margin-top: -18px;
  border-bottom: none;
  padding-left: 23px;
  padding-right: 23px;
  .MuiTab-root{
    border: 1px solid rgba(0, 0, 0, 0.26);
    background: #FAFAFA;
    border-left: none;
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 0;
    min-width: 0;
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiTab-root.Mui-selected{
    background: #F4F4F4;
  }
  .MuiTab-textColorInherit{
    opacity: 1;
    color: #FAFAFA;
  }
  .MuiTab-root:first-child{
    border-color: rgba(0, 0, 0, 0.26);
    border-left: 1px solid rgba(0, 0, 0, 0.26);
    border-bottom-left-radius: 4px;
  }
  .MuiTab-root:last-child{
    border-color: rgba(0, 0, 0, 0.26);
    border-bottom-right-radius: 4px;
  }


  .Mui-selected{
    color: #000;
  }
`
