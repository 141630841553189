import { customerApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'


type ColumnName =
  | keyof Omit<
  customerApi.CompanyShortInfo,
  | 'id'
  | 'versionNo'
  | 'changed'
  | 'changer'
  | 'siteUrl'
  >
  | 'id'


type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  name: 'name',
  inArchive: 'inArchive',
  inn: 'inn',
  id: 'id'
}

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 'auto',
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.CompaniesLink
    },
    {
      name: columns.inn,
      columnName: columns.inn,
      title: 'ИНН',
      width: 320,
      sortingEnabled: false,
    },
    {
      name: columns.inArchive,
      columnName: columns.inArchive,
      title: 'Статус',
      width: 400,
      sortingEnabled: true,
      cellType: CellType.CompaniesState
    }
  ],
}
