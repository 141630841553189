import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface DeleteCopackingOrdersQueryParams {
  copackingordersId: number
  positionId: string
  requestBody: tradeApi.DeletingObject
}

export const deleteOrderPositionPacking = ({ copackingordersId, positionId, requestBody }: DeleteCopackingOrdersQueryParams) =>
  api.delete<tradeApi.DeletingObject>(
    `trade/orders/copackingorders/${copackingordersId}/positions/${positionId}`,
    { data: requestBody }
  )

export const useDeleteOrderPositionPacking = () =>
  useMutation<
        AxiosResponse,
        AxiosError<errorType>,
        DeleteCopackingOrdersQueryParams
  >(deleteOrderPositionPacking)