import { Grid, Paper, Box, Typography } from '@mui/material'
import { pimApi } from 'shared/api'
import React, { memo } from 'react'
import { UserAvatar } from 'shared/ui/components/Admin/Users/UserAvatar'
import UserDate from 'shared/ui/components/Admin/Users/UserDate'

import { BaseInfoUserForm } from './BaseInfoUserForm'
import { UserState } from './UserState'
import { UserSettings } from './UserSettings'

interface IEditUser {
  values: pimApi.admin.CurrentUser
  handleSubmit: () => void
  dirtyFields: any
  errors: any
  handleBlock: () => void
  handleUnblock: () => void
  handleClose: () => void
}

export const EditUserForm = memo(({
  values,
  handleSubmit,
  errors,
  dirtyFields,
  handleBlock,
  handleUnblock,
  handleClose }: IEditUser) => {

  const handleUserEdit = () => {
    handleSubmit()
  }
  const isClosed = values?.state === 'closed'
  return (
    <form onSubmit={() => {}}>
      <Paper elevation={0}>
        <Grid item={true} pt={2} pl={3} pb={1}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' , alignItems: 'center' }}>
              <UserAvatar
                isClosed={isClosed}
                values={values}
              />
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '12px' }} gap={2}>
                  <Typography
                    component="h1"
                    variant="h3"
                    fontSize="24px">
                    {values.name}
                  </Typography>
                  <UserState value={values.state}/>
                </Box>
                <UserDate values={values}/>
              </Box>
            </div>
            {!isClosed &&
            <UserSettings handleBlock={handleBlock} handleUnblock={handleUnblock} handleClose={handleClose} userData={values}/>
            }
          </div>
        </Grid>
        <BaseInfoUserForm
          handleUserEdit={handleUserEdit}
          isClosed={isClosed}
          errors={errors}
          dirtyFields={dirtyFields}
        />
      </Paper>
    </form>
  )
}
)
