import { FC, memo, useCallback, useMemo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { AutocompleteValue, FilterOptionsState } from '@mui/material/useAutocomplete'

export interface BrandProps extends FieldRenderProps<string> {
  companyId: number,
}

const getOptionFromBrand= ({ name }: { name: string }) => ({
  label: name,
  value: name
})
export const Brand: FC<BrandProps> = ({ companymarketplaceId, ...props }) => {
  const fetchCatalog = useCallback<(steach: string) => Promise<Array<Option<any>>>>(
    (search: string) => wbproxyApi.fetchBrands(encodeURIComponent(search), companymarketplaceId)
      .then(
        data => data.data.map(
          getOptionFromBrand
        )
      ),
  [companymarketplaceId]
  )

  const handleChange = (value: AutocompleteValue<Option<any>, false, undefined, undefined>) => {
    props.input.onChange(value?.value)
  }
  const input = useMemo(() => ({
    ...props.input,
    value: { 
      label: props.input.value,
      value: props.input.value
    }
  }), [props.input])

  const filterOptions = useCallback(
    (selectedValues: Option<string> | Array<Option<string>>) =>
      (
        options: Array<Option<string>>,
        state: FilterOptionsState<Option<string>>
      ) => {
        const filtered = options.filter((optionElement) =>
          Array.isArray(selectedValues)
            ? !selectedValues.some(
              (element) => element.value === optionElement.value
            )
            : optionElement !== selectedValues
        )

        if (state.inputValue !== '') {
          filtered.push({
            value: state.inputValue,
            label: `Добавить "${state.inputValue}"`,
          })
        }

        return filtered
      },
    []
  )

  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      input={input}
      // @ts-ignore
      onChange={handleChange}
      fetchCatalog={fetchCatalog}
      error={props.error.toString()}
      noOptionsText="Введите строку для поиска"
      disableClearable={false}
      multiple={false}
      filterOptions={filterOptions}
      freeSolo={true}
    />
  )
}

export const BrandMemo = memo(Brand)