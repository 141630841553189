import { memo } from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { DocType } from 'shared/services/interfaces/doc'

import { DocNumber } from './DocNumber'
import { DocStatus } from './DocStatus'
import { DocStatusDate } from './DocStatusDate'
import { DocDate } from './DocDate'

import { ColumnName, columns } from '../columnsConfig'
import { CustomCell } from '../style'

const getCell = (
  name: ColumnName,
  row: DocType,
  value: string | number,
) => {
  switch (name) {
    case columns.docNumber:
      return <DocNumber docId={row.id} value={value as string} />

    case columns.docDate:
    case columns.supplierDocDate:
      return <DocDate date={row[name]} />

    case columns.status:
      return <DocStatus row={row} />

    case columns.expectedArrivalDate:
    case columns.receiptStartDate:
    case columns.receiptFinishDate:
      return <DocStatusDate date={row[name]} />

    default:
      return undefined
  }
}

const Cell = (props: Omit<Table.DataCellProps, 'row'> & { row: DocType }) => {
  const { column, row, value } = props
  return (
    <CustomCell {...props} style={{ minWidth: 50 }}>
      {getCell(
        column.name as ColumnName,
        row,
        value
      )}
    </CustomCell>
  )
}

export const TableCellComponent = memo(Cell)