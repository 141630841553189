import { Label, withUnits, withMaxCount, withRequired } from 'shared/lib/transform/Label'

import { OzonMapingAttribute } from './getNormalizedAttributes'

export const getLabelPrepared = (WBCardAttribute: OzonMapingAttribute) => {
  const labelInstance = new Label(WBCardAttribute.attributeName)

  if (WBCardAttribute.units) {
    withUnits(labelInstance, WBCardAttribute.units)
  }

  if (WBCardAttribute.maxCount) {
    withMaxCount(labelInstance, WBCardAttribute.maxCount)
  }


  if (WBCardAttribute.required) {
    withRequired(labelInstance)
  }

  return labelInstance.label
}