import styled from 'styled-components'
import { Box, IconButton } from '@mui/material'

export const ImageWrapper = styled(Box)`
    img {
      max-width: 450px;
    }
`

export const CloseHeaderBtn = styled(IconButton)`
    border-radius: 0px 0px 0px 4px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
     
    :hover {
        background-color: white;
    }
`
