import styled from 'styled-components'

export const POrgStyled = styled.p`
margin: 0;
font-weight: 500;
font-size: 18px;
line-height: 175%;
letter-spacing: 0.15px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  
  .org-div {
    display: flex;
    flex-direction: row;
  }
  
  .expanded-checkbox-title {
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    color: #000000DE;
  }
  
  .common-parameters-checker {
    margin-left: 30px;
    margin-right: 7px;
    
    img {
      width: 19px;
    }
  }
`
