import React, { useState } from 'react'
import { reviewGen } from 'shared/lib/generated'
import { StyledLoader } from 'shared/ui/components/ModalComponents/PrivilegesUsersModal'

import { TemplateTable } from './TemplateTable/TemplateTable'


export const TemplateTab = () => {
  const [reviewType, setReviewType] = useState(1)
  const { data: templateQuery, isLoading, isFetching } =
    reviewGen.template.GetReviewTemplates.useGetReviewTemplates({ query: { reviewtypeId: reviewType } })
  return (
    <>
      { (isLoading || isFetching ) && <StyledLoader size={60} /> }
      { templateQuery && <TemplateTable templateData={templateQuery} reviewType={reviewType} setReviewType={setReviewType}/>}
    </>
  )
}