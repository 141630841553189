import styled from 'styled-components'

import { StyledLink } from '../../../../product/common/CompetitorsTab/ui/styled'

export const StyledProductLink = styled(StyledLink)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: max-content;
  -webkit-box-orient: vertical;
  font-size: 14px;
`