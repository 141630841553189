import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosError } from 'axios'

export const getProductsStatsQueryKey = () =>
  ['get', 'pim/products/stats'] as const

type GetProductsStatsQueryKey = ReturnType<typeof getProductsStatsQueryKey>

function getProductsStats() {
  return api.get<pimApi.products.common.Stats>
  ('pim/products/stats').then( ({ data }) => data)
}


export const useGetProductsStatsQuery = () =>
  useQuery<
    pimApi.products.common.Stats,
    AxiosError<errorType>,
    pimApi.products.common.Stats,
    GetProductsStatsQueryKey
  >(
    getProductsStatsQueryKey(),
    getProductsStats, {
      refetchOnMount: false,
      keepPreviousData: true
    }
  )
