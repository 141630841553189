import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { tradeApi, user } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import sumBy from 'lodash/sumBy'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { columnsConfig } from 'features/supplyOrders/EditSupplyOrderProducts/model/tableColumnsConfig'
import { useRowsPerPage } from 'features/supplyOrders/EditSupplyOrderProducts/lib/useRowsPerPage'
import { AddGoods } from 'pages/supplyOrders/edit/ui/Settings/ui/AddGoods'
import { TableParams } from 'shared/ui/components/Table/model/types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { equals } from 'ramda'

import { pagingPanelLocale } from '../../lib/localization'
import { ExtendedInfoSwitch, StyledLoader, StyledTableBox, TableWrapper } from '../styled'
import { changeColumnConfig, tableParams } from '../../../lib/tableParamsSetting'
import CustomInput from '../../../ui/CustomInput'
import NestedWidget from '../NestedWidget/NestedWidget'

function multiplyPriceByQuantity(price, quantity) {
  return price * quantity
}

const getRowId = (row: tradeApi.SupplyOrderProductPosition) => row.id

const EditSupplyOrderProductsTable = () => {
  const {
    supplyOrderQuery,
    tabsSettingObject,
    inTabsSettingHandler,
    rowOpenedInProducts,
    setRowOpenedInProducts,
  } = useSupplyOrderContext()

  const orderProductData = supplyOrderQuery.data

  const { mutate: editSupplyOrder } =
    tradeApi.orders.useEditSupplyOrderMutation()

  const { mutate: deleteSupplyOrder } =
    tradeApi.orders.useDeleteSupplyOrderPositionMutation()

  const [isLoadingCellChanges, setIsLoadingCellChanges] = useState(false)

  const isTableEditable = orderProductData?.editPossible

  const { calculatedRowsPerPage } = useRowsPerPage()

  const memoTableParams = useMemo(
    () => tableParams(tabsSettingObject, 'productspositions', 'editSupplyOrderProducts'),
    [supplyOrderQuery?.data, tabsSettingObject?.inTabsObjectInit]
  )

  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()
  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          editSupplyOrderTableHiddenColumns: hiddenColumns ,
        },
      })
    },
    [userSettingsQuery?.data]
  )


  const tableHiddenColumns = userSettingsQuery?.data?.editSupplyOrderTableHiddenColumns

  const convertTableData = (intData) => {
    if (intData) {
      return intData.map(
        ({ goods, ...rest }) => {
          const goodsSummarized =
            goods.length === 1
              ? {
                goodsPrice: multiplyPriceByQuantity(
                  goods[0].price,
                  goods[0].quantityInBundle
                ),
                goods,
                ordersStatFromDate: orderProductData?.ordersStatFromDate,
                ordersStatToDate: orderProductData?.ordersStatToDate,
                goodsQuantity: goods[0].quantityInBundle,
              }
              : {
                goodsQuantity: sumBy(goods, 'quantityInBundle'),
                ordersStatFromDate: orderProductData?.ordersStatFromDate,
                ordersStatToDate: orderProductData?.ordersStatToDate,
                goodsPrice: sumBy(
                  goods.map((item) => ({
                    goodsPrice: multiplyPriceByQuantity(item.price, item.quantityInBundle)
                  })),
                  'goodsPrice'
                ),
                goods,
              }

          const productPrice = multiplyPriceByQuantity(goodsSummarized.goodsPrice, rest.quantity)

          return {
            ...rest,
            ...goodsSummarized,
            productPrice
          } as Partial<tradeApi.SupplyOrderProductPosition>
        }
      )
    }
    return []
  }

  const tableData = convertTableData(orderProductData?.productspositions)

  const [filteredTableData, setFilteredTableData] = useState<tradeApi.SupplyOrderProductPosition[]>(tableData)

  const tableDataForFiltering = useMemo(() => tableData.map((product) => {
    const goodsString = product.goods.map((good) =>  `${  good.goodsCode} ${  good.goodsName}` ).toString()
    const indexProductString = `${product.productName} ${  product.productCode} ${  product.sku} ${  goodsString}`
    return ({
      id: product.id,
      indexString: indexProductString.toLowerCase()
    })
  }), [orderProductData]
  )

  useEffect(()=>{
    const filteredData = [] as tradeApi.SupplyOrderProductPosition[]

    // eslint-disable-next-line array-callback-return
    tableDataForFiltering.map(data => {
      if (data.indexString.includes(memoTableParams.searchString.toLowerCase())) {
        const filteredProduct = tableData.find(e => e.id === data.id)
        filteredData.push( filteredProduct )
      }
    })

    if (memoTableParams.searchString.length) {
      setFilteredTableData(filteredData)
    } else setFilteredTableData(tableData)

  }, [orderProductData, memoTableParams])

  const handleCellChange = (changedCells) => {
    setIsLoadingCellChanges(true)
    const editedCell = changedCells.changed
    const editedCellId = Number(Object.keys(changedCells.changed)?.[0])

    if(!editedCell[editedCellId]) {
      setIsLoadingCellChanges(false)
      return
    }

    const editedCellSource = tableData.find(
      (position) => position.id === editedCellId
    )

    editSupplyOrder(
      {
        positionId: editedCellId,
        supplyOrderId: orderProductData?.id,
        editedSupplyOrder: {
          id: editedCellId,
          versionNo: editedCellSource?.versionNo,
          ...editedCell[editedCellId],
        },
      },
      {
        onSuccess: () => {
          supplyOrderQuery.refetch().then( () =>
            snackActions.info('Успешно сохранено!')
          )
          setIsLoadingCellChanges(false)
        },
        onError: () => {
          setIsLoadingCellChanges(false)
        }
      }
    )
  }

  const handleRowDelete = (id) => {
    const deletingRowSource = orderProductData?.productspositions?.find(
      (position) => position.id === id
    )

    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <ModalContent
          close={close}
          accept={accept}
          title="Удалить продукт из заказа?"
          acceptBtnText="УДАЛИТЬ"
          acceptBtnColor="error"
        />
      ),
      onAccept: () => {
        deleteSupplyOrder(
          {
            positionId: id,
            supplyOrderId: orderProductData?.id,
            deletingPosition: {
              id,
              versionNo: deletingRowSource?.versionNo,
            },
          },
          {
            onSuccess: () => {
              snackActions.info('Продукт успешно удален из заказа')
              supplyOrderQuery.refetch()
            },
          }
        )
      },
    })
  }

  const changeColumnHandler = (params: TableParams) => {
    changeColumnConfig(params, inTabsSettingHandler, 'productspositions')
  }

  const showingRowArray = useMemo(() => tableData.map(obj => obj.id),
    [tableData, memoTableParams])


  useEffect(() => {
    if (setRowOpenedInProducts) {
      if (rowOpenedInProducts) {
        if (showingRowArray.length < rowOpenedInProducts?.length) {
          setRowOpenedInProducts(prevState => prevState.filter(x => showingRowArray.includes(x)))
        }
      }
    }
  }, [showingRowArray])

  const allRowsAreExpanded = () => {
    if (rowOpenedInProducts) {
      if (showingRowArray.length === 0) {
        return false
      } 
      return (equals(rowOpenedInProducts.sort(), showingRowArray.sort()))
    }
    return false
  }
  const handleChangeShowExtendedInfo = () => {
    if (setRowOpenedInProducts) {
      if (allRowsAreExpanded()) {
        setRowOpenedInProducts([])
      } else {
        setRowOpenedInProducts(showingRowArray)
      }
    }
  }

  // const showingRowArray = isShowExtArr
  return (
    <>
      <StyledTableBox component={Box}>
        {(supplyOrderQuery.isLoading ||
          supplyOrderQuery.isFetching) && <StyledLoader size={60} />}
        {filteredTableData && userSettingsQuery?.data && (
          <TableWrapper  $isEditable={isTableEditable}>
            {isTableEditable
              ? <AddGoods />
              : <></>
            }
            <ExtendedInfoSwitch>
              <FormControlLabel
                value="Расширенная информация"
                control={<Switch
                  size="small"
                  color="primary"
                  checked={allRowsAreExpanded()}
                  onChange={handleChangeShowExtendedInfo}
                  sx={{ marginRight: '8px' }}
                />}
                label="Расширенная информация"
                labelPlacement="end"
              />
            </ExtendedInfoSwitch>

            <Table
              tableList={filteredTableData}
              getRowId={getRowId}
              handleTableSettingsChange={handleTableSettingsChange}
              tableHiddenColumns={tableHiddenColumns}
              tableParams={memoTableParams}
              calculatedRowsPerPage={calculatedRowsPerPage}
              columnsConfig={columnsConfig}
              isTableEditable={isTableEditable}
              onCellChange={handleCellChange}
              onRowDelete={handleRowDelete}
              nestedWidget={NestedWidget}
              expandedRows={rowOpenedInProducts}
              setOpenedRows={setRowOpenedInProducts}
              isLoading={isLoadingCellChanges || supplyOrderQuery.isFetching || supplyOrderQuery.isLoading}
              showInArchiveControl={false}
              showColumnsVisibility={true}
              searching="custom"
              pagination="internal"
              sorting="internal"
              paginationLocale={pagingPanelLocale}
              changeColumnConfig={changeColumnHandler}
              isStoredSettings={true}
              customSearchInput={CustomInput}
              selectTextOnEditStart={true}
            />
          </TableWrapper>
        )}
      </StyledTableBox>
    </>
  )
}

export const MemoEditSupplyOrderProductsTable = memo(EditSupplyOrderProductsTable)
