import { FieldArray } from 'react-final-form-arrays'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { pimApi } from 'shared/api'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { DateFormats } from 'shared/config/dateFormats'

import { PriceCardWithMenu } from './PriceCardWithMenu'
import { PriceChangeWrapper } from './styled'

interface PriceChangeProps {
  discount: number
  futurePrices?: Array<pimApi.products.PriceInfo>
  name?: string
  nomenclatureId?: UniqueId
  onUpdate: (data: pimApi.products.SetSimpleProductPriceInfo) => void
  price: number
  priceRecentHistory?: Array<any>
  readOnly?: boolean
}

export const PriceChange = ({
  futurePrices,
  nomenclatureId,
  name,
  onUpdate,
  readOnly,
  ...props
}: PriceChangeProps) => {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)

  return (
    <PriceChangeWrapper>
      <PriceCardWithMenu
        nomenclatureId={nomenclatureId}
        productId={productId}
        onAdd={onUpdate}
        readOnly={readOnly}
        {...props}
      />
      <FieldArray<pimApi.products.PriceInfo> name="futurePrices">
        {({ fields }) =>
          !isEmpty(fields) &&
            fields.value.map(({ changeDate, ...rest }, index) => (
              <PriceCardWithMenu
                title={format(
                  new Date(changeDate),
                  DateFormats.dayMonthYearHoursMinutes
                )}
                productId={productId}
                nomenclatureId={nomenclatureId}
                onRemove={() => fields.remove(index)}
                readOnly={readOnly}
                {...rest}
              />
            ))
        }
      </FieldArray>
    </PriceChangeWrapper>
  )
}
