import { Label, withUnits, withMaxCount, withRequired } from 'shared/lib/transform/Label'
import { Attribute } from 'domains/pim/goods'

export const getLabelPreparedLeft = (attribute: Attribute) => {
  const labelInstance = new Label(attribute.name)

  if (attribute.unit) {
    withUnits(labelInstance, attribute.unit)
  }

  if (attribute.maxLength) {
    withMaxCount(labelInstance, attribute.maxLength)
  }


  if (attribute.required) {
    withRequired(labelInstance)
  }

  return labelInstance.label
}