import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import styled from 'styled-components'
import * as React from 'react'
import { SplitButton } from 'shared/ui/components'

import { AddSupplyOrderByFileModalContent } from '../AddSupplyOrderByFileModalContent'
import { AddSpecificProductModalContent } from '../AddSpecificProductModalContent'

export const AddButton = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 16px;
  display: flex;
  margin-left: auto;
  margin-right: 90px;
`

export const AddGoods = () => {

  const { supplyOrderQuery } = useSupplyOrderContext()

  const editSupplyOrderOption = {
    label: 'Список продуктов из файла',
    handler: () => {
      dialogModel.openDialog({
        component: ({ close }) => (
          <AddSupplyOrderByFileModalContent
            close={close}
            supplyOrderQuery={supplyOrderQuery}
            title="Добавить список продуктов из файла"
          />
        ),
        onAccept: () => {
          snackActions.error('Сервис временно недоступен')
        },
      })
    }
  }

  const addSpecificProduct = {
    label: 'Определенный продукт',
    handler: () => {
      dialogModel.openDialog({
        component: ({ close }) => (
          <AddSpecificProductModalContent
            close={close}
            supplyOrderQuery={supplyOrderQuery}
            title="Добавить определенный продукт"
          />
        ),
        onAccept: () => {
          snackActions.error('Сервис временно недоступен')
        },
      })
    }
  }
  
  const addOptions = [
    editSupplyOrderOption,
    addSpecificProduct  
  ]  

  return (
    <AddButton>
      <SplitButton
        variant="outlined"
        options={addOptions}
        label="ДОБАВИТЬ"
        size="small"
      />
    </AddButton>
  )
}
