/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'







export const apiFunction = () =>
  api.delete(`/customer/user/photo`).then(res => res.data)


export const useDeleteUsersPhoto = () =>
  useMutation<
    AxiosResponse,
    errorType
  >(apiFunction)

