import styled from 'styled-components'
import { Box, Link, Theme } from '@mui/material'
import { pimApi } from 'shared/api'

type MessageType = 'info' | 'success' | 'error'
export const UploadWrapper = styled(Box)`
  margin-left: auto;
  input {
    display: none;
  }
`

const getColor = (
  messageType: pimApi.goods.GoodsResponseMessageType,
  theme: Theme
) => {
  switch (messageType) {
    case 'error':
      return theme.palette.error.main
    case 'success':
      return theme.palette.success.main
    default:
      return theme.palette.info.main
  }
}

export const Message = styled(Box)<{
  messageType: MessageType
}>`
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    ${({ messageType, theme }) => getColor(messageType, theme)};

  svg {
    font-size: 18px;
    margin-right: ${({ theme }) => theme.spacing(1.5)};
    color: ${({ messageType, theme }) => getColor(messageType, theme)};
    margin-bottom: ${({ theme }) => theme.spacing(-0.5)};
  }
`

export const StyledLink = styled(Link)`
  color: #2296f3;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  align-self: center;
`

export const ReportDownload = styled(Link)`
  color: #2296f3;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-decoration: none;
  align-self: center;
  text-transform: uppercase;
  padding: 4px 5px;
  a {
    text-decoration: none;
  }
  svg {
      width: 18px;
      height: 18px;
    margin-right: 8px;
  }
`
