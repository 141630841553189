import { Box, Button, Grid, Link } from '@mui/material'
import { Field } from 'react-final-form'
import { InputField } from 'shared/ui/components'
import UploadIcon from '@mui/icons-material/Upload'
import { LoadingButton } from '@mui/lab'
import { Delete } from '@mui/icons-material'
import React from 'react'
import { dialogModel } from 'shared/ui/components/dialog'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import {
  AreaContent,
  AreaTitle,
  FormArea,
  InputInSettings,
  UploadWrapper,
  AreaContentNoBorder,
  SubHeader,
  Footer, IconStyle
} from './styled'
import { UploadNoteModal } from './modals/UploadNoteModal'

const FILE_EXTENSION =
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const UploadSettingsSupplierForm = ({
  orderGenerationOptions,
  handleSupplierEdit,
  handleDeleteOrderForm,
  handleCheckOrderForm,
  uploadFile,
  values,
  isUploading,
  inArchive
}) => {

  const handleOpenUploadNoteModalClick = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <UploadNoteModal close={close}/>
      )
    })
  }
  
  return ( <Box p={3}>
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={6}>
        <FormArea>
          <AreaTitle>
            <div>ИСПОЛНЕНИЕ ЗАКАЗА</div>
            <SubHeader>Укажите номер столбца, соответсвующий указанным данным</SubHeader>
          </AreaTitle>
          <AreaContentNoBorder>
            <div>Количество товара</div>
            <InputInSettings>
              <Field
                name="invoiceTemplateSettings.quantityColumn"
                required={false}
                onlyDigits={true}
                component={InputField}
                label="№"
                options={orderGenerationOptions}
                fullWidth={true}
                onBlur={handleSupplierEdit}
                disabled={inArchive}
              />
            </InputInSettings>
          </AreaContentNoBorder>

          <AreaContent>
            <div>Цена товара</div>
            <InputInSettings>
              <Field
                name="invoiceTemplateSettings.priceColumn"
                required={false}
                onlyDigits={true}
                component={InputField}
                label="№"
                options={orderGenerationOptions}
                fullWidth={true}
                onBlur={handleSupplierEdit}
                disabled={inArchive}
              />
            </InputInSettings>
          </AreaContent>

          <AreaContent>
            <div>Код поставщика</div>
            <InputInSettings>
              <Field
                name="invoiceTemplateSettings.supplierCodeColumn"
                required={false}
                onlyDigits={true}
                component={InputField}
                label="№"
                options={orderGenerationOptions}
                fullWidth={true}
                onBlur={handleSupplierEdit}
                disabled={inArchive}
              />
            </InputInSettings>
          </AreaContent>

        </FormArea>
      </Grid>

      <Grid item={true} xs={6}>
        <FormArea>
          <AreaTitle>
            <div>БЛАНК ЗАКАЗА</div>
            {values.orderformTemplateSettings &&
              <>
                <IconStyle>
                  <HelpOutlineIcon onClick={handleOpenUploadNoteModalClick}/>
                </IconStyle>
                <SubHeader>
                  Укажите номер столбца, соответсвующий указанным данным
                </SubHeader>
              </>
            }

          </AreaTitle>
          {values.orderformTemplateSettings && <>
            <AreaContentNoBorder>
              <div>Код поставщика</div>
              <InputInSettings>
                <Field
                  name="orderformTemplateSettings.supplierCodeColumn"
                  required={false}
                  onlyDigits={true}
                  component={InputField}
                  label="№"
                  options={orderGenerationOptions}
                  fullWidth={true}
                  onBlur={handleSupplierEdit}
                  disabled={inArchive}
                />
              </InputInSettings>
            </AreaContentNoBorder>

            <AreaContent>
              <div>Количество товара</div>
              <InputInSettings>
                <Field
                  name="orderformTemplateSettings.quantityColumn"
                  required={false}
                  onlyDigits={true}
                  component={InputField}
                  label="№"
                  options={orderGenerationOptions}
                  fullWidth={true}
                  onBlur={handleSupplierEdit}
                  disabled={inArchive}
                />
              </InputInSettings>
            </AreaContent>

            <AreaContent>
              <div>Количество товара в коробке</div>
              <InputInSettings>
                <Field
                  name="orderformTemplateSettings.quantityInBoxColumn"
                  required={false}
                  onlyDigits={true}
                  component={InputField}
                  label="№"
                  options={orderGenerationOptions}
                  fullWidth={true}
                  onBlur={handleSupplierEdit}
                  disabled={inArchive}
                />
              </InputInSettings>
            </AreaContent>
          </>}

          <Footer inArchive={inArchive} templateSet={!!values.orderformTemplateSettings}>
            {values.orderformTemplateSettings
                && <Link href={values.orderformTemplateSettings.templateUrl}>
                  {values.orderformTemplateSettings.fileName}
                </Link>
            }
            <UploadWrapper inArchive={inArchive}>
              <label htmlFor="excel-file">
                <input
                  accept={FILE_EXTENSION}
                  id="excel-file"
                  type="file"
                  onChange={uploadFile}
                />
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={isUploading}
                  component="span"
                  startIcon={<UploadIcon/>}
                  disabled={inArchive}
                >
                  ИМПОРТ
                </LoadingButton>
              </label>
            </UploadWrapper>
            {values.orderformTemplateSettings &&
            <Button
              variant="outlined"
              sx={{ margin: '24px 0' }}
              disabled={inArchive}
              onClick={handleCheckOrderForm}
            >ПРОВЕРИТЬ
            </Button>}
            {
              values.orderformTemplateSettings &&
                <IconStyle inArchive={inArchive}>
                  <Delete
                    onClick={handleDeleteOrderForm}
                    sx={{ marginLeft: '18px' }}
                  />
                </IconStyle>
            }
          </Footer>
        </FormArea>
      </Grid>
    </Grid>
  </Box>)
}
