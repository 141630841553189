/* eslint-disable react/no-this-in-sfc */
import { wmsApi } from 'shared/api'
import { SplitButton } from 'shared/ui/components'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import LoadingButton from '@mui/lab/LoadingButton'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import icon from 'assets/images/icons/excel.svg'
import { Method } from 'shared/lib/generated/wms/Api'
import { dialogModel } from 'shared/ui/components/dialog'
import { ISelectedState } from 'pages/wms/lib/types'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { StocksImportModal } from './StocksImportModal'
import { UploadFileModal } from './UploadFileModal'
import { MoveStocksModal } from './MoveStocksModal'


export const FileActions = ({ selectedRows, setSelectedRows }:ISelectedState) => {
  const { searchObj } = useQueryParams()

  const { areaId, supplierId, recipientId, brandId, searchString } = searchObj

  const { mutate: downloadFileMutation, isLoading: fileIsDownloading } = wmsApi.stocks.usegetAreaStocksFileStocksFileGet()
  const { mutate: uploadFileMutation, isLoading: fileIsUploading } = wmsApi.stocks.upload.usepatchStocksStocksPatch()

  const handleUploadFile = (file: any, method: Method, methodName: string, contragentId?: string) => {
    if (file) {
      uploadFileMutation({ query: { method, methodParam: contragentId }, data: { file } }, {
        onSuccess: (response) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <StocksImportModal methodName={methodName} close={ close } response={ response }/>
            )
          })
        }
      })
    }
  }

  const handleFileDownload = () => {
    downloadFileMutation({ query: { areaId, supplierId, recipientId, brandId, searchString } }, {
      onSuccess: (response) => {
        const fileName =
          decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            )
          ) || 'template.xlsx'
        const file = response.data
        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const importOptions = [
    {
      label: 'Перемещение остатков',
      uploadFile (e) {
        const file = e.target.files?.[0]
        handleUploadFile(file, Method.MoveStocks, this.label)
      }
    },
    {
      label: 'Списание остатков',
      handler () {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <UploadFileModal
              methodName={this.label}
              close={ close }
              accept={ accept }
            />
          ),
          onAccept: ({ file, contragentId }) => {
            handleUploadFile(file, Method.WriteOffStocks, this.label, contragentId)
          }
        })
      },
    }
  ]

  const actionOptions = [
    {
      label: 'Перемещение остатков',
      handler () {
        dialogModel.openDialog({
          component: ({ close }) => (
            <MoveStocksModal
              setSelectedRows={ setSelectedRows }
              method={Method.MoveStocks}
              selectedRows={selectedRows}
              close={ close }
            />
          ),
        })
      },
    },
    {
      label: 'Списание остатков',
      handler () {
        dialogModel.openDialog({
          component: ({ close }) => (
            <MoveStocksModal
              setSelectedRows={ setSelectedRows }
              method={Method.WriteOffStocks}
              selectedRows={selectedRows}
              close={ close }
            />
          ),
        })
      },
    },
  ]

  return (
    <div style={ { display: 'flex', flexDirection: 'row', gap: '16px' } }>
      <LoadingButton
        variant="contained"
        color="primary"
        loadingPosition="start"
        startIcon={ <ExcelIcon src={ (icon as any) } alt="excelImg"/> }
        onClick={ handleFileDownload }
        loading={ fileIsUploading || fileIsDownloading }
      >
        ЭКСПОРТ
      </LoadingButton>

      <SplitButton
        variant="contained"
        options={ importOptions }
        label="Импорт"
        isLoading={ fileIsUploading || fileIsDownloading }
        size="small"
      />

      <SplitButton
        variant="contained"
        options={ actionOptions }
        disabled={ isEmptyArray(selectedRows) }
        label="Действия"
        isLoading={ fileIsUploading || fileIsDownloading }
        size="small"
      />
    </div>
  )
}