import React from 'react'
import { Typography } from '@mui/material'

import { MarketplacesContainer, StyledMarketplaceBox } from '../styled/styled'

interface MarketplacesParams {
  marketplaceId: number;
  name: string
  icon: string
}
export const MarketplacesList = 
  ({ 
    marketplaceId,
    setMarketplaceId,
    marketplaces
  }: {
    marketplaceId: number | undefined,
    setMarketplaceId: (e) => void
    marketplaces: Array<MarketplacesParams>
  }) => (
    <MarketplacesContainer>
      {marketplaces.map((el) => (
        <StyledMarketplaceBox
          key={el.marketplaceId}
          selected={ marketplaceId === el.marketplaceId }
          onClick={() => {setMarketplaceId(el.marketplaceId)}}
        >
          <img src={el.icon} alt="alt"/>
          <Typography>{el.name}</Typography>
        </StyledMarketplaceBox>
      ))}
    </MarketplacesContainer>
  )