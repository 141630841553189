import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  CircularProgress,
  TextField
} from '@mui/material'
import debounce from 'lodash.debounce'
import Box from '@mui/material/Box'
import usePending from 'shared/lib/hooks/PendingHook'
import { catalogSelector } from 'store/catalog/selectors'
import { addCatalog } from 'store/catalog'

import * as UI from './styled'

interface SmartSearchInputI {
  label: string
  catalog: string
  renderOption?: (option: unknown) => React.ReactNode
  optionLabelPath: string
  onChange?: (value) => void
}

const ONE_SECOND = 1000

const SmartSearchInput = (props: SmartSearchInputI) => {
  const { label, catalog, optionLabelPath, renderOption, onChange } = props
  const [getCatalog, isLoading] = usePending(addCatalog)
  const list = useSelector(catalogSelector(catalog))
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [fieldValue, setFieldValue] = useState('')

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [value])

  const renderOptionDefault = useCallback(
    (optionProps, option) => (
      <UI.Item {...optionProps}>
        <Box display="flex" alignItems="center">
          <UI.ItemText variant="h6">{option[optionLabelPath]}</UI.ItemText>
        </Box>
      </UI.Item>
    ),
    [optionLabelPath]
  )

  const renderOptionProp = renderOption || renderOptionDefault

  const handleChange = (event, option) => {
    setIsOpen(false)
    setValue(option.id)
  }

  const debouncedGetCatalog = debounce(getCatalog, ONE_SECOND)

  const handleInputChange = useCallback(({ target }) => {
    const { value: inputValue } = target
    setFieldValue(inputValue)
    debouncedGetCatalog({
      catalog,
      qParams: {
        value: inputValue,
      },
    })
  }, [])

  return (
    <UI.AutocompleteStyled
      size="small"
      clearOnBlur={false}
      blurOnSelect={true}
      disableClearable={true}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      ListboxProps={{ style: { maxHeight: '60vh' } }}
      getOptionLabel={(option: any) => option[optionLabelPath]}
      options={list}
      fullWidth={true}
      loading={isLoading}
      noOptionsText="Введите строку для поиска"
      onChange={handleChange}
      renderOption={renderOptionProp}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleInputChange}
          value={fieldValue}
          label={label}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

SmartSearchInput.displayName = 'UI_Autocomplete'
// SmartSearchInput.defaultProps = {
//   label: '',
//   catalog: '',
//   optionLabelPath: 'name',
//   onChange: (value) => {},
// }

export default SmartSearchInput
