import { Tooltip } from '@mui/material'
import { MemoIcon } from 'entities/pim/goods/ui'
import { SearchResultType } from 'shared/api/goods/models'
import { GoodsTypeName } from 'shared/config/goods'

import { StyledGoodsType } from './styled'

type GoodsTypeProps = Pick<SearchResultType, 'goodsType'>

export const GoodsTypeCell = ({ goodsType }: GoodsTypeProps) => (
  <Tooltip
    title={GoodsTypeName[goodsType]}
    placement="right"
    componentsProps={{
      tooltip: {
        sx: {
          position: 'relative',
          left: '-42px'
        }
      }
    }}
  >
    <StyledGoodsType>
      <MemoIcon
        goodsType={goodsType}
        color="rgba(0, 0, 0, 0.38)"
      />
    </StyledGoodsType>
  </Tooltip>
)
