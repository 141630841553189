import { StocksAndOrdersCard } from 'entities/pim/stocksAndOrders'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import values from 'lodash/values'
import { tradeApi } from 'shared/api'
import { getServerReadableDateTime, toMaxDateTime, toMinDateTime } from 'shared/lib/utils'
import sumBy from 'lodash/sumBy'
import { useMemo } from 'react'
import { generatePath } from 'react-router'

const currentDate = new Date()
const DEFAULT_MIN_DATE = new Date(currentDate).setMonth(
  toMinDateTime(currentDate).getMonth() - 1
)
const defaultFromDate = getServerReadableDateTime(DEFAULT_MIN_DATE)
const defaultToDate = getServerReadableDateTime(new Date())

export const StocksAndOrders = ({ productId }) => {
  const stocksDate = tradeApi.stocks.useStocksProductQuery(productId)
  const stocksQuery = tradeApi.stocks.useStocksProductHistoryQuery({
    productId,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
  })
  const ordersQuery = tradeApi.orders.useOrdersProductHistoryQuery({
    productId,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
  })
  const stocksGroupedByDate = useMemo(
    () =>
      stocksQuery?.data
        ? values(
          mapValues(
            groupBy(stocksQuery?.data, 'stockDate'),
            (groupedItem, key) => ({
              stocks: sumBy(groupedItem, 'available'),
              date: Number(new Date(key)),
            })
          )
        )
        : [],
    [stocksQuery.data]
  )
  const preparedOrdersData = useMemo(
    () =>
      ordersQuery?.data
        ? ordersQuery?.data.map((order) => ({
          date: Number(new Date(order.orderDate)),
          quantity: order.quantity,
        }))
        : [],
    [ordersQuery.data]
  )
  const preparedStocksAndOrdersData = useMemo(
    () => orderBy([...stocksGroupedByDate, ...preparedOrdersData], 'date'),
    [stocksQuery.data, ordersQuery.data]
  )

  return (
    <a
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
      href={generatePath(
        // eslint-disable-next-line max-len
        `/analytics/charts?productId=${productId}&startValue=${defaultFromDate}&endValue=${defaultToDate}&timelineStart=${defaultFromDate}&timelineEnd=${defaultToDate}&range=3`
      )}
    >
      <StocksAndOrdersCard
        stocksDate={stocksDate}
        stocksAndOrdersData={preparedStocksAndOrdersData}
        min={Number(toMinDateTime(DEFAULT_MIN_DATE))}
        max={Number(toMaxDateTime(new Date()))}
      />
    </a>
  )
}
