import styled from 'styled-components'
import Pagination from '@mui/material/Pagination'
import { Route } from 'react-router'
import PaginationItem, { PaginationItemTypeMap } from '@mui/lab/PaginationItem'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { FC, ReactElement } from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'

const PaginationWrapper = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: ${({ width }) => width}%;
  margin-top: 20px;
  margin-left: -3px;
`

const PaginationItemStyled = styled(PaginationItem)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
` as unknown as OverridableComponent<PaginationItemTypeMap>

interface PaginationContainerProps {
  count: number;
  children: ReactElement;
  width: number;
  className?: string;
}

const PaginationContainer: FC<PaginationContainerProps> = ({ count, children, width, className }) =>
  count <= 1 ?
    null :
    (
      <PaginationWrapper className={className} width={width}>
        <Route>
          {({ location: { pathname, search } }) => {
            const searchObj = queryString.parse(search, { parseNumbers: true }) as { page: number }
            const { page } = searchObj

            return (
              <Pagination
                page={page}
                count={count}
                siblingCount={3}
                renderItem={item => {
                  const paramsPage = queryString.stringify({
                    ...searchObj,
                    page: item.page,
                  })
                  return (
                    <PaginationItemStyled
                      component={Link}
                      to={`${pathname}?${paramsPage}`}
                      {...item}
                    />
                  )
                }}
              />
            )
          }}
        </Route>
        {children}
      </PaginationWrapper>
    )

export default PaginationContainer
