import { Tooltip } from '@mui/material'

interface DaysWithStocksType {
  data: {
    anywhere: number,
    byWarehouse: Array<{ name: string; value: string }>
  }
}

export const DaysWithStocksCell = ({ data }: DaysWithStocksType) => {
  const { anywhere, byWarehouse } = data
  const warehousesWithoutNull = byWarehouse.filter(arr => Number(arr.value) !== 0)
  return (
    <>
      {warehousesWithoutNull.length > 0 ? (
        <Tooltip
          title={byWarehouse.map((item) => (
            <div key={item.name}>
              {`${item.name}: ${item.value} `}
              <br/>
            </div>
          ))}
          placement="right"
        >
          <span>{anywhere}</span>
        </Tooltip>
      ) : (
        <span>{anywhere}</span>
      )}
    </>
  )
}
