import { isNil } from './isNil'

export const checkValue = (value?: any) => {
  const isValueNotValid = isNil(value) || value === ''
  if (isValueNotValid) return undefined

  return value
}

export const getInitialNumberValue = (value?: any) => {
  const isValueNotValid = isNil(value) || value === ''
  if (isValueNotValid) return undefined

  return parseInt(value as string, 10)
}