import React from 'react'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Form } from 'react-final-form'
import { generatePath, useHistory } from 'react-router'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import arrayMutators from 'final-form-arrays'

import { Header } from './ui/Header'
import { CloseButton } from './ui/styled'
import { ProductCopyForm } from './ui/ProductCopyForm'
import { validationSchema } from './lib/validation'


interface Props {
  close: () => void;
  commonProductData: pimApi.products.common.Product;
  wbProductQuery?: pimApi.products.wb.WBNomenclature;
}
const ProductCopyOptions = [
  {
    label: 'Новая номенклатура карточки',
    value: 'newNomenclature'
  },
  {
    label: 'Новая карточка WB',
    value: 'newCard'
  },
]

export const ProductCopyModal = ({ close, commonProductData, wbProductQuery }: Props) => {
  const history = useHistory()
  const { mutate: createProductWB, isLoading } = pimApi.products.wb.useCreateProductWBMutation()

  const validate = useValidationSchema(validationSchema)
  
  const onSubmit = (values) => {
    if (values.productCopy === 'newNomenclature') {
      createProductWB(
        {
          companymarketplaceId: commonProductData.companymarketplaceId,
          goodsId: commonProductData.goodsId,
          wbnomenclatureId: wbProductQuery?.id,
          newVariation: false,
          useGoodsMedia: false,
          productId: commonProductData.productId,
        },
        {
          onSuccess: ({ data }) => {
            close()
            history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
          },
        }
      )
    } else {
      createProductWB(
        {
          companymarketplaceId: values.companymarketplaceId,
          productId: commonProductData.productId,
          goodsId: values?.selectedGoods?.[0]?.componentId,
          useGoodsMedia: false,
          newVariation: true
        },
        {
          onSuccess: ({ data }) => {
            close()
            history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
          },
        }
      )
    }
  }
  return (
    <Box sx={{ width: '684px', padding: '24px' }}>
      <CloseButton onClick={close}><CloseIcon/></CloseButton>
      <Header/>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          companyId: commonProductData.companyId,
          companymarketplaceId: commonProductData.companymarketplaceId,
          productCopy: 'newNomenclature',
          selectedGoods: []
        }}
        render={({
          handleSubmit, valid
        }) => (
          <ProductCopyForm
            valid={valid}
            isLoading={isLoading}
            commonProductData={commonProductData}
            ProductCopyOptions={ProductCopyOptions}
            close={close} 
            handleSubmit={handleSubmit}
          />
        )}
      />
    </Box>
  )
}