import { useMemo, memo } from 'react'
import {
  RowDetailState,
  SummaryState,
  CustomSummary,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSummaryRow,
  DragDropProvider,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui'
import { CalculatedReceiptDocPosition } from 'domains/doc'
import { tableHeaderRowLocale, tableLocale } from 'shared/ui/components/Table/lib'

import { columnsConfig, columnSummaryConfig } from './ColumnsConfig'
import {
  TableTotalRowComponent, TableHeaderCellComponent, 
  RowDetailCellComponent, SummaryItem,
  TableRowComponent, StyledTableCellComponent,
  ToggleCellComponent, StubHeaderCellComponent,
  TableComponent,
} from './ui'
import { getTotalSummaryValues } from './helpers'
import { getCell } from './getCell'
import { TableWrapper } from './styled'

import { NestedTable } from '../NestedTable'

interface ITableProps {
  rows: CalculatedReceiptDocPosition[];
  handleOpenDialog: (data: CalculatedReceiptDocPosition) => void;
}

const getRowId = (row: CalculatedReceiptDocPosition) => row.id

const DocTableLocal = ({ rows, handleOpenDialog }: ITableProps) => {
  const Cell = useMemo(() => (props: Table.DataCellProps) => {
    const { column, row, value } = props

    return (
      <StyledTableCellComponent {...props} columnname={column.name} >
        {getCell(column.name, row, value, handleOpenDialog)}
      </StyledTableCellComponent>
    )
  }, [])

  return (
    <TableWrapper>
      <Grid
        rows={rows}
        columns={columnsConfig}
        getRowId={getRowId}
      >
        <SortingState columnExtensions={columnsConfig} />
        <IntegratedSorting />
        
        <Table
          columnExtensions={columnsConfig}
          cellComponent={Cell}
          rowComponent={TableRowComponent}
          stubHeaderCellComponent={StubHeaderCellComponent}
          messages={tableLocale}
          tableComponent={TableComponent}
        />

        <DragDropProvider />
        <TableColumnReordering defaultOrder={columnsConfig.map(column => column.columnName)} />

        <TableHeaderRow
          showSortingControls={true}
          messages={tableHeaderRowLocale}
          cellComponent={TableHeaderCellComponent}
        />
        <RowDetailState />
        <TableRowDetail
          contentComponent={NestedTable}
          cellComponent={RowDetailCellComponent}
          toggleCellComponent={ToggleCellComponent}
        />
        
        <SummaryState totalItems={columnSummaryConfig} />
        <CustomSummary totalValues={getTotalSummaryValues(rows)}/>
        
        <TableSummaryRow
          // @ts-ignore
          itemComponent={SummaryItem}
          totalRowComponent={TableTotalRowComponent}
        />
      </Grid>
    </TableWrapper>
  )
}

export const DocTable = memo(DocTableLocal)