import React from 'react'
import { CheckboxField } from 'shared/ui/components'
import { Field } from 'react-final-form'
import styled from 'styled-components'

const CheckboxWrapper = styled.div`
  height: 100%;
  
  label {
    margin-right: 0;
    margin-left: 0;
  }
  
  .MuiCheckbox-root {
    color: #00000042;
    padding: 0;
  }
  
  .Mui-checked {
    color: #007DFF;
  }
`
export const AutoChangePriceCell = () => (
  <CheckboxWrapper>
    <Field
      name="autoChangePrice"
      size="small"
      type="checkbox"
      component={ CheckboxField }
    />
  </CheckboxWrapper>
)