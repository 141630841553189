// @ts-nocheck
import React, { useState } from 'react'
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import styled from 'styled-components'
import { prop } from 'ramda'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true,
}

const SelectStyled = styled(Select)`
  .MuiInputBase-input {
    background: transparent;
    padding: 10.5px 14px;
    padding-right: 24px;
    // border: 1px solid rgba(0, 0, 0, 0.23);
  }
`
const Wrapper = styled.div`
  .MuiInputLabel-shrink {
    transform: translate(0, 7.5px) scale(0.75);
  }
  .MuiInputLabel-formControl {
    left: 15px;
    top: -13px;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const SelectMultipleField = ({
  input: { name, value, onChange, ...restInput },
  label,
  api,
  list,
  formControlProps,
  ...rest
}) => {
  const [personName, setPersonName] = useState([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const listResult = list
  const itemList = prop('results', listResult)

  const handleChange = (event) => {
    setPersonName(event.target.value)
    if (onChange) {
      onChange(event.target.value)
    }
  }
  return (
    <Wrapper>
      <FormControl fullWidth={true} {...formControlProps}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <SelectStyled
          multiple={true}
          value={personName}
          onChange={handleChange}
          {...rest}
          {...restInput}
          input={<OutlinedInput fullWidth={true} label="Жанр" />}
          renderValue={(selected: any) =>
            selected.map((item) => item.split('_')[1]).join(', ')
          }
          MenuProps={MenuProps}
          name={name}
        >
          {itemList.map((item) => (
            <MenuItem key={item.shikiId} value={`${item.shikiId}_${item.name}`}>
              {item.name}
            </MenuItem>
          ))}
        </SelectStyled>
      </FormControl>
    </Wrapper>
  )
}

export default SelectMultipleField
