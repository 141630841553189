/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress } from '@mui/material'

import * as UI from './styled'

const TWO_SECONDS = 2000

const Modal = () => {
  const [indexText, setIndexText] = useState(0)
  const modalText = useMemo(
    () => [
      'Идет синхронизация с базой 1С...',
      'Связываемся со спутником...',
      'Информация о товаре летит в космос и возвращается обратно на Землю...',
      'Мы такие маленькие на фоне нашей планеты и звезд...',
      'А во вселенной так много звезд и галактик...',
      'Может быть, мы не одни во Вселенной и там кто-то тоже думает об этом...',
      'Через пару секунд бухгалтерия узнает о том, как у нас дела...',
      'Еще немного...',
      'Элементарные частицы двигаются со скоростью света...',
      'Все в этом мире относительно...',
      'Говорят, что путешествовать во времени невозможно, но... чтобы вы сделали, если бы можно было, но только один раз?',
      'Только не говорите, что не нажимали бы эту кнопку ;)',
      'Еще чуть-чуть, не закрывайте страницу',
      'Если все происходит дольше, чем 1 минуту, то что-то пошло не так. Перезагрузите страницу.',
    ],
    []
  )
  useEffect(() => {
    const updateText = (currentIndex) => {
      if (currentIndex + 1 === modalText.length) {
        return currentIndex
      }
      return currentIndex + 1
    }
    const timerID = setInterval(() => setIndexText(updateText), TWO_SECONDS)
    return () => clearInterval(timerID)
  }, [])
  return (
    <UI.LoadingModal open={true}>
      <UI.LoadingModalContent>
        <UI.LoadingModalText>{modalText[indexText]}</UI.LoadingModalText>
        <CircularProgress />
      </UI.LoadingModalContent>
    </UI.LoadingModal>
  )
}

export default Modal
