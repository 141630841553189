export type AdminGoodsPageTabs = 'categories' | 'attributes'
export type AdminPanelContentPages =
    'suppliers' |
    'companies' |
    'users' |
    'dicts' |
  'reports' |
  'warehouses' |
  'goods'

export const ADMIN_PANEL_CONTENT_PAGES: Array<AdminPanelContentPages> = [
  'suppliers',
  'companies',
  'users',
  'dicts',
  'reports',
  'warehouses',
  'goods'
]

export const ADMIN_GOODS_CONTENT_TABS: Array<AdminGoodsPageTabs> = [
  'categories',
  'attributes'
]