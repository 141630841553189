import React from 'react'
import { CategoriesTree, CategoryTreeHeader } from 'features/admin/Goods/Categories'

import { GoodsCategories } from '../styled'

export const CategoriesTab = () => (
  <GoodsCategories>
    <CategoryTreeHeader/>
    <CategoriesTree/>
  </GoodsCategories>
)