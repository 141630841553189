import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface PostCopackingOrdersQueryParams {
  copackingordersId?: number
  productId?: number
  comments?: string
}

export function postNewProductForPacking(queryParams: PostCopackingOrdersQueryParams) {
  const { copackingordersId } = queryParams
  const addedProduct = { productId: queryParams.productId, comments: queryParams.comments }
  return api.post(`trade/orders/copackingorders/${copackingordersId}/positions`,
    addedProduct
  )
}

export const usePostNewProductForPacking = () =>
  useMutation<
    AxiosResponse<tradeApi.EditCoPackingOrderPosition>,
    AxiosError<errorType>,
    PostCopackingOrdersQueryParams
  >(postNewProductForPacking)