import { useSelector } from 'react-redux'
import * as GoodsService from 'shared/api/wms/GoodsService'
import { detailIdSelector } from 'store/doc/selectors'
import { dialogModel } from 'shared/ui/components/dialog'
import { ModalContent } from 'shared/ui/components/ModalComponents'

import { DeleteIcon, StyledButton, BoldText } from './styled'

interface DeleteBtnProps {
  posId: number;
  versionNo: number;
  goodsName: string;
}

export const DeleteBtn = ({ posId, versionNo, goodsName }: DeleteBtnProps) => {
  const { mutate: deletePosition } = GoodsService.useDeletePosition()
  const docDetailId = useSelector(detailIdSelector)

  const remove = () => {
    deletePosition({ docId: docDetailId, posId, body: { id: posId, versionNo } })
  }

  const onClick = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удаление информации о добавленном документе"
            description={
              <span>
                Вы собираетесь удалить информацию о добавленном документе.
                {' '}
                <BoldText>{goodsName}</BoldText>
              </span>
            }
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
          />
        ),
        onAccept: remove,
      }
    )
  }

  return (
    <StyledButton
      title="Удалить"
      className="delete_action"
      onClick={onClick}
    >
      <DeleteIcon  />
    </StyledButton>
  )
}
