import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { tradeApi, user } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { columnsConfig  } from 'features/supplyOrders/EditSupplyOrderGoods/model/tableColumnsConfig'
import { TableParams } from 'shared/ui/components/Table/model/types'
import { changeColumnConfig, tableParams } from 'features/supplyOrders/lib/tableParamsSetting'
import CustomInput from 'features/supplyOrders/ui/CustomInput'
import { snackActions } from 'shared/lib/react/snackbar'
import { useQueryClient } from 'react-query'
import { tradeGen } from 'shared/lib/generated'

import { TableWrapper, StyledVirtualTableBox } from '../styled'
import { nestedTableColumnsConfig } from '../../model/nestedTableColumnsConfig'
import { StyledLoader } from '../../../EditSupplyOrderProducts/ui/styled'

function multiplyPriceByQuantity(price, quantity) {
  return price * quantity
}

const getRowId = (row: tradeApi.SupplyOrderGoodsPosition & { id: number }) => row.id

const EditSupplyOrderGoodsTable = () => {
  const { supplyOrderQuery, tabsSettingObject, inTabsSettingHandler, rowOpenedInGoods, setRowOpenedInGoods } = useSupplyOrderContext()
  const [isLoadingEditCell, setIsLoadingEditCell] = useState(false)

  const queryClient = useQueryClient()

  const memoTableParams = useMemo(
    () => tableParams(tabsSettingObject, 'goodspositions', 'editSupplyOrderGoods'),
    [supplyOrderQuery?.data, tabsSettingObject?.inTabsObjectInit]
  )

  useEffect(() => {
    if (rowOpenedInGoods?.length) {
      const target = document.getElementById(rowOpenedInGoods[0].toString())
      target?.scrollIntoView({ block: 'end' })
    }
  })

  const isTableEditable = supplyOrderQuery.data?.editPossible

  const { data: userSettingsQuery } =
    user.settings.useFetchUserSettingsQuery()
  const { mutate: updateUserSettings } =
    user.settings.useUpdateUserSettingsMutation()

  const { mutate: updateGoodsPosition } =
    tradeApi.orders.useEditSupplyOrderMutation()

  const tableHiddenColumns =
    userSettingsQuery?.data?.editSupplyOrderTableHiddenColumns

  const handleTableSettingsChange = useCallback(
    (hiddenColumns) => {
      updateUserSettings({
        settings: {
          ...userSettingsQuery?.data,
          editSupplyOrderTableHiddenColumns: hiddenColumns,
        },
      })
    },
    [userSettingsQuery?.data]
  )

  const tableData = supplyOrderQuery.data?.goodspositions?.map(
    ({ price, quantity, goodsId, ...rest }) =>
      ({
        ...rest,
        quantity,
        goodsPrice: multiplyPriceByQuantity(price, quantity),
        id: goodsId,
        goodsId
      } as Partial<tradeApi.SupplyOrderGoodsPosition>)
  )


  const [filteredTableData, setFilteredTableData] = useState<Partial<tradeApi.SupplyOrderGoodsPosition>[] | undefined>(tableData)

  const tableDataForFiltering = useMemo(() => tableData?.map((good) => {
    const productString = good.products?.map((product) =>  `${  product.productName} ${  product.productCode}` ).toString()
    const indexProductString = `${good.goodsName} ${  good.goodsCode} ${  productString} `
    return ({
      id: good.id,
      indexString: indexProductString.toLowerCase()
    })
  }), [tableData]
  )

  useEffect(()=>{
    const filteredData = [] as Partial<tradeApi.SupplyOrderGoodsPosition>[]
    // eslint-disable-next-line array-callback-return
    tableDataForFiltering?.map(data => {
      if (data.indexString.includes(memoTableParams.searchString.toLowerCase())) {
        const filteredGood = tableData?.find(e => e.id === data.id)
        filteredData.push( filteredGood as Partial<tradeApi.SupplyOrderGoodsPosition> )
      }
    })

    if (memoTableParams.searchString.length) {
      setFilteredTableData(filteredData)
    } else setFilteredTableData(tableData)

  }, [supplyOrderQuery.data?.goodspositions, memoTableParams])

  const showingRowArray = useMemo(() => filteredTableData?.map(obj => obj.id) || [],
    [filteredTableData, memoTableParams])

  useEffect(() => {
    if (setRowOpenedInGoods) {
      if (rowOpenedInGoods) {
        if (showingRowArray?.length < rowOpenedInGoods?.length) {
          setRowOpenedInGoods(prevState => prevState.filter(x => showingRowArray?.includes(x)))
        }
      }
    }
  }, [showingRowArray])

  const handleNestedCellChange = (changedCells) => {
    if(changedCells && tableData && supplyOrderQuery?.data?.id && setRowOpenedInGoods) {
      const editedProductPosition = Number(Object.keys(changedCells.data.changed)?.[0])
      if (!changedCells.data.changed[editedProductPosition]) return
      setIsLoadingEditCell(true)
      const newQuantity = Number(changedCells.data.changed[editedProductPosition].quantity)
      const editedGood = tableData.find(good => good.goodsId === changedCells.rowInfo.goodsId)
      const editedProduct = editedGood?.products?.[editedProductPosition]

      updateGoodsPosition (
        {
          positionId: editedProduct?.id as number,
          supplyOrderId: supplyOrderQuery?.data?.id,
          editedSupplyOrder: {
            id: editedProduct?.id as number,
            versionNo: editedProduct?.versionNo as number,
            quantity: newQuantity
          },
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries({
              queryKey: tradeGen.orders.GetSupplyOrder.getQueryKey({
                supplyorderId: supplyOrderQuery?.data?.id
              })
            })
            setRowOpenedInGoods([editedGood?.goodsId as number])
            snackActions.info('Успешно сохранено!')
            setIsLoadingEditCell(false)
          }, 
          onError: () => {
            setIsLoadingEditCell(false)
          },
        }
      )
    }
  }
  const extendedNestedTableColumConfig = { 
    ...nestedTableColumnsConfig, 
    onCommitChanges: handleNestedCellChange,
    isEditable: isTableEditable
  }
  const changeColumnHandler = (params: TableParams) => {
    changeColumnConfig(params, inTabsSettingHandler, 'goodspositions')
  }

  return (
    <>
      <StyledVirtualTableBox component={Box}>
        {(supplyOrderQuery.isLoading ||
          supplyOrderQuery.isFetching) && <StyledLoader size={60} />}
        {filteredTableData && userSettingsQuery?.data && (
          <TableWrapper>
            <Table
              tableList={filteredTableData}
              getRowId={getRowId}
              handleTableSettingsChange={handleTableSettingsChange}
              tableHiddenColumns={tableHiddenColumns}
              tableParams={memoTableParams}
              isTableEditable={isTableEditable}
              columnsConfig={columnsConfig}
              nestedTableColumnsConfig={extendedNestedTableColumConfig}
              expandedRows={rowOpenedInGoods || []}
              setOpenedRows={setRowOpenedInGoods}
              showInArchiveControl={false}
              showColumnsVisibility={true}
              searching="custom"
              sorting="internal"
              isLoading={isLoadingEditCell}
              columnsForSummary={true}
              changeColumnConfig={changeColumnHandler}
              isStoredSettings={true}
              customSearchInput={CustomInput}
            />
          </TableWrapper>
        )}
      </StyledVirtualTableBox>
    </>
  )
}

export const MemoEditSupplyOrderGoodsTable = memo(EditSupplyOrderGoodsTable)
