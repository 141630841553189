import { memo } from 'react'
import { Box, Switch, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { Help } from 'shared/ui/styled/Help'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useMarketplaceList } from 'shared/api/pim/dictionaries'
import { CommonParametersChecker } from 'shared/ui/product/GlobalParametersChecker'

import { POrgStyled, TitleContainer } from './styled'

export const ECOMMarketplace = ({ displayGoodsData, changeDisplayedGoodsData, leftSideTooltip, rightSideTooltip, parametersName }: {
  displayGoodsData?: boolean,
  leftSideTooltip?: string,
  rightSideTooltip?: string,
  parametersName?: Array<string>
  changeDisplayedGoodsData?: (value) => void }) => {
  const { commonProductQuery } = useCommonProductContext()
  const { marketplaceListQuery } = useMarketplaceList()
  const marketplaceName =
    marketplaceListQuery.data?.filter(el => el.id === commonProductQuery?.data?.marketplaceId).map(el => el.name).toString()

  return (
    <TitleContainer>
      { displayGoodsData &&
        <div className="org-div" style={ { width: '50%' } }>
          <POrgStyled>E-COM</POrgStyled>
          { leftSideTooltip &&
            <CustomTooltip title={ leftSideTooltip }/>
          }
        </div>
      }


      <div className="org-div">
        <POrgStyled>{ marketplaceName || 'Маркетплейс' }</POrgStyled>
        { rightSideTooltip &&
          <CustomTooltip title={ rightSideTooltip }/>
        }
      </div>

      { (changeDisplayedGoodsData) &&
        <div style={ {
          height: '30px',
          marginLeft: 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        } }>
          <Switch
            checked={ displayGoodsData }
            onChange={ (_, checked) => {
              changeDisplayedGoodsData(checked)
            } }
            inputProps={ { 'aria-label': 'controlled' } } size="small"
          />
          <span className="expanded-checkbox-title">Информация о товаре</span>
          <CommonParametersChecker parametersName={parametersName} isEditable={true} className="common-parameters-checker" />
        </div>
      }
    </TitleContainer>
  )
}

const CustomTooltip = ({ title }: { title: string }) => (
  <Tooltip
    title={title}
    placement="right"
  >
    <Box ml={ 1 }>
      <Help>
        <InfoOutlined/>
      </Help>
    </Box>
  </Tooltip>
)

export const MemoECOMMarketplace = memo(ECOMMarketplace)