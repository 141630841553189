import styled from 'styled-components'

import { SimpleTextCell } from '../simpleTextCell'

const TextWrapper = styled.div`
  span {
    -webkit-line-clamp: 2;
  }
`

export const SelectedAlgorithmCell = ({ row }) => (
  <TextWrapper>
    <SimpleTextCell text={row.algorithm} />
  </TextWrapper>
)