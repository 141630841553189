import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

import toSnakeCase from '../../../lib/transform/toSnakeCase'


export const editAttribute = (editedAttribute: pimApi.attributes.CommonAttribute) => {
  const editedAttributeSnake = toSnakeCase(editedAttribute)
  return api.put<pimApi.attributes.CommonAttribute>(
    `/pim/attributes/${ editedAttribute.id }`,
    editedAttributeSnake
  )
}


export const useEditAttributeMutation = () =>
  useMutation<
    AxiosResponse<pimApi.attributes.CommonAttribute>,
    AxiosError<errorType>,
    pimApi.attributes.CommonAttribute
  >(editAttribute)
