import styled from 'styled-components'

export const StyledCountBadge = styled.div`
  font-size: 12px;
  font-weight: 500;
  background-color: #007DFF;
  color: white;
  min-width: 20px;
  height: 20px;
  border-radius: 14px;
  position: absolute;
  display: flex;
  padding: 0 3px;
  align-items: center;
  justify-content: center;
  top: 6px;
  left: 17px;
`