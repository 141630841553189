import { memo } from 'react'
import {
  ResultItem,
  ProductTitle,
  ProductAuxCode,
  ProductInfo,
  ProductImagePlaceholder,
} from 'entities/pim/goods/ui/GoodsCard/styled'
import { ozonproxyApi } from 'shared/api'

interface CardComponentProps extends ozonproxyApi.model.ProductPreview {}

export const CardComponent = ({ image, title, price, link }: CardComponentProps) => (
  <ResultItem mt={3}>
    <ProductImagePlaceholder>
      <img src={image} alt="placeholder" />
    </ProductImagePlaceholder>
    <ProductInfo>
      <ProductTitle>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </ProductTitle>
      <ProductAuxCode>{price}р.</ProductAuxCode>
    </ProductInfo>
  </ResultItem>
)

export const MemoCardComponent = memo(CardComponent)
