/* eslint-disable no-case-declarations */
import keyMirror from 'keymirror-nested'
import { select, call, take, put } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { DocType, IDoc } from 'shared/services/interfaces/doc'
import watcherCreator, { requesterFunctionType } from 'store/watcherCreator'
import { actionsCreator, actionType } from 'store/actionsCreator'
import { localStore } from 'shared/services/localStore'
import { TYPES } from 'shared/services/types'
import { diContainer } from 'shared/lib'
import { ISocket } from 'shared/services/interfaces/socket'
import toCamelCase from 'shared/lib/transform/toCamelCase'
import { CalculatedReceiptDocPosition, ReceiptDocAcceptance, ReceiptDocPosition } from 'domains/doc'

export interface docStateType {
  detail: DocType | any | null | undefined,
  warehouseId: null | number,
  list: DocType[] | null | undefined,
  error: errorType | null | undefined;
}

interface UpdateAcceptance {
  resData: ReceiptDocAcceptance;
  editedPosId: number;
}

interface RemoveAcceptance {
  editedPosId: number;
  deletedAcptId: number;
}

export type docActionType = actionType<
  DocType | DocType[] | number,
  errorType
>

export const DOC = keyMirror(
  {
    DETAIL: null,
    LIST: null,
    UPDATE: null,
    CHANGE_STATUS: null,
    SET_WAREHOUSE_ID: null,
    CHANGE_DOC_ACCEPTANCE: null,
    INIT_SOCKET_LISTENER: null,
    REMOVE_SOCKET_CONNECTION: null,
    SYNC_SOCKET_DATA: null,
    DOC_ADD_NEW_GOOD: null,
  },
  '_',
  'DOC'
)

const actionAcceptanceType = {
  changeAcceptance: 'changeAcceptance',
  addAcceptance: 'addAcceptance',
  deleteAcceptance: 'deleteAcceptance',
}

export const docDetail = actionsCreator(DOC.DETAIL)
export const docList = actionsCreator(DOC.LIST)
export const docUpdate = actionsCreator(DOC.UPDATE)
export const docChangeStatus = actionsCreator(DOC.CHANGE_STATUS)
export const docChangeAcceptance = actionsCreator(DOC.CHANGE_DOC_ACCEPTANCE)
export const docAddGood = actionsCreator(DOC.DOC_ADD_NEW_GOOD)
export const initSocketListener = (id: string) => ({
  type: DOC.INIT_SOCKET_LISTENER,
  data: id,
})

export const changeDocAcceptance = (data: UpdateAcceptance) => ({
  type: actionAcceptanceType.changeAcceptance,
  data,
})

export const addDocAcceptance = (data: UpdateAcceptance) => ({
  type: actionAcceptanceType.addAcceptance,
  data,
})

export const removeDocAcceptance = (data: RemoveAcceptance) => ({
  type: actionAcceptanceType.deleteAcceptance,
  data,
})

export const setWarehouseId = (id: number) => ({
  type: DOC.SET_WAREHOUSE_ID,
  data: id,
})

export const docSelector = (state: {
  docReducer: docStateType
}): docStateType => state.docReducer

const initialState = {
  detail: {
    positions: [],
  },
  warehouseId: Number(localStore.get('warehouseId')) || null,
  list: [],
  error: null,
}

export const docReducer = (
  state = initialState,
  action: docActionType
): docStateType => {
  switch (action.type) {
    case DOC.SET_WAREHOUSE_ID: return {
      ...state,
      warehouseId: <number>action.data
    }

    case docList.getType('success'): return {
      ...state,
      list: <DocType[]>action.data
    }
    case docList.getType('error'): return {
      ...state,
      list: <DocType[]>action.data
    }

    case docDetail.getType('pending'):
      return {
        ...state,
        detail: { ...state.detail, ...(action.data as {}) },
      }
    case docDetail.getType('success'):
      return {
        ...state,
        detail: <any>{
          ...state.detail,
          ...action.data as {}
        }
      }
    case docDetail.getType('error'): return {
      ...state,
      detail: <any>action.data,
    }

    case docUpdate.getType('pending'): return {
      ...state,
      detail: { ...state.detail, ...(action.data as {}) },
    }
    case docUpdate.getType('success'): return {
      ...state,
      detail: { ...state.detail, ...(action.data as {}) },
    }
    case docUpdate.getType('error'): return { ...state }

    case docChangeStatus.getType('success'):
      return {
        ...state,
        detail: <any>{ ...state.detail, ...(action.data as {}) },
      }
    case docChangeStatus.getType('error'):
      return {
        ...state,
        detail: <any>{ ...state.detail, ...(action.data as {}) },
      }

    case actionAcceptanceType.changeAcceptance: {
      const { positions }: { positions: CalculatedReceiptDocPosition[] } = state.detail
      const { editedPosId, resData } = action.data as unknown as UpdateAcceptance

      const newPositions = positions.map((item) => {
        if (item.id === editedPosId) {
          let newSumFact = 0
          let newSumDefective = 0

          const newAcceptance = item?.acceptance?.map((acceptyanceItem) => {
            if (acceptyanceItem.id === resData.id) {
              newSumFact += resData.quantityAccepted * resData.quantityInPackaging
              newSumDefective += resData.quantityDefective
              return resData
            }
            
            newSumFact += acceptyanceItem.quantityAccepted * acceptyanceItem.quantityInPackaging
            newSumDefective += acceptyanceItem.quantityDefective
            return acceptyanceItem
          })

          const newItem = {
            ...item,
            acceptance: newAcceptance,
            sumFact: newSumFact,
            sumDefective: newSumDefective,
            difference:  newSumFact - item.quantity,
          }

          return newItem
        }

        return item
      })
      return {
        ...state,
        detail: <any>{ ...state.detail, positions: [...newPositions] },
      }
    }

    case actionAcceptanceType.addAcceptance: {
      const { positions }: { positions: CalculatedReceiptDocPosition[] } = state.detail
      const { editedPosId, resData } = action.data as unknown as UpdateAcceptance

      const newPositions = positions.map((item) => {
        if (item.id === editedPosId) {

          const newAcceptance = resData

          const newItem = {
            ...item,
            acceptance: [newAcceptance],
            sumFact: resData.quantityAccepted,
            difference: resData.quantityAccepted - item.quantity,
          }

          return newItem
        }

        return item
      })
      return {
        ...state,
        detail: <any>{ ...state.detail, positions: [...newPositions] },
      }
    }

    case actionAcceptanceType.deleteAcceptance: {
      const { positions }: { positions: CalculatedReceiptDocPosition[] } = state.detail
      const { editedPosId, deletedAcptId } = action.data  as unknown as RemoveAcceptance

      const newPositions = positions.map((item) => {
        if (item.id === editedPosId) {
          const newAcceptance = item?.acceptance?.filter((acceptanceItem) => acceptanceItem.id !== deletedAcptId)

          return {
            ...item,
            acceptance: [...newAcceptance || []],
          }
        }

        return item
      })

      return {
        ...state,
        detail: <any>{ ...state.detail, positions: [...newPositions] },
      }
    }
      
    default:
      return state
  }
}

const socket = diContainer.get<ISocket>(TYPES.Socket)

const createSocketOSMChannel = () =>
  eventChannel((emit) => {
    socket.on((data) => {
      emit({
        type: DOC.SYNC_SOCKET_DATA,
        data: toCamelCase(JSON.parse(data.data)),
      })
    })

    return socket.close
  })

function* initDocSocket(id: UniqueId) {
  const result = yield call([socket, socket.connect], id)

  if (result) {
    const socketChannel = yield call(createSocketOSMChannel)
    while (true) {
      const action = yield take(socketChannel)
      yield put(action)
    }
  }
}

const docObj = diContainer.get<IDoc>(TYPES.Doc)

const mergeByKey = (a1: any[], a2: any[], key: string) => {
  const res = a1.concat(a2).reduce((acc, x) => {
    acc[x[key]] = Object.assign(acc[x[key]] || {}, x)
    return acc
  }, {})

  return Object.entries(res).map((pair) => {
    const [, value] = pair
    return value
  })
}

export const docWatcher = watcherCreator(
  'DOC',
  // eslint-disable-next-line func-names
  function* ({ type, data }: docActionType, requester: requesterFunctionType) {
    if (type === DOC.SYNC_SOCKET_DATA) {
      let newPositions: any[] = []
      const { id, positions, ...rest } = <DocType>data
      const { detail } = yield select(docSelector)

      if (detail && detail.id === id) {
        let result = {
          ...detail,
          ...rest,
          positions: detail.positions
            .map((item: ReceiptDocPosition) => {
              let res = item

              if (positions && positions.length) {
                positions.forEach((pos) => {
                  if (pos.id === res.id) {
                    if (pos.acceptance) {
                      res = {
                        ...res,
                        ...pos,
                        // @ts-ignore
                        acceptance: mergeByKey(
                          item.acceptance as ReceiptDocAcceptance[],
                          pos.acceptance,
                          'id'
                        ),
                      }
                    } else res = { ...item, ...pos }
                  }
                })
              }

              return res
            })
            // @ts-ignore
            .filter((item) => !item.deleted),
        }
        if (positions && positions.length) {
          newPositions = positions.filter((item) => !detail.positions.find((pos: ReceiptDocPosition) => pos.id === item.id))
        }
        if (newPositions.length) {
          result = {
            ...result,
            positions: [...result.positions, ...newPositions],
          }
        }
        yield put(docDetail.success(docObj.calcAcceptanceInPositions(result)))
      }
    }
    if (type === DOC.INIT_SOCKET_LISTENER) {
      yield initDocSocket(data as number)
    }
    if (type === DOC.SET_WAREHOUSE_ID) {
      localStore.set('warehouseId', String(<number>data))
    }
    if (type === docDetail.getType('pending')) {
      yield requester<DocType, IDoc, [number]>(
        docObj,
        'getDocDetail',
        <number>data
      ).callActions(docDetail)
    }
    if (type === docChangeStatus.getType('pending')) {
      // @ts-ignore
      const { operation, id } = <DocType>data
      yield requester<DocType, IDoc, [object]>(docObj, 'changeDocStatus', <object>data)
        .callActions(docChangeStatus)
      if (operation === 'start' || operation === 'resume') {
        yield initDocSocket(id)
      }
    }
    if (type === docUpdate.getType('pending')) {
      yield requester<DocType, IDoc, [object]>(docObj, 'updateDoc', <object>data)
        .callActions(docUpdate)
    }
    if (type === docChangeAcceptance.getType('pending')) {
      yield requester<DocType, IDoc, [object]>(docObj, 'changeDocAcceptance', <object>data)
        .callActions(docChangeAcceptance)
    }
    if (type === docAddGood.getType('pending')) {
      const { detail } = yield select(docSelector)
      const docId = detail.id
      yield requester<DocType, IDoc, [object]>(docObj, 'docAddGood', <object>{ docId, goodId: data })
        .callActions(docAddGood)
    }
  }
)
