import { Grid } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'

import { AttributeField } from '../AttributeField'

interface AttributeLayoutProps {
  prefixName?: string;
  onChange: (target: {
    name: string;
    value: string | Array<string>;
  }) => void;
  disabled?: boolean;
}

export const AttributeLayout = ({ prefixName = '', onChange, disabled }: AttributeLayoutProps) => (
  <Grid container={true} spacing={2}>
    <FieldArray name={prefixName ? `${prefixName}.attributes` : 'attributes'}>
      {({ fields: attributes }) => attributes.map((attributeName, attributeIndex) => (
        <AttributeField
          key={attributeName}
          {...attributes.value[attributeIndex]}
          fieldName={`${attributeName}.value`}
          onChange={onChange}
          disabled={disabled}
        />
      ))}
    </FieldArray>
  </Grid>
)
