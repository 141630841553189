// @ts-nocheck
import styled from 'styled-components'
import { Toolbar } from '@devexpress/dx-react-grid-material-ui'

export const StyledToolbarRootComponent = styled(Toolbar.Root)`
  border-bottom-style: none !important;
  min-height: auto;
  padding-right: 0;
  position: absolute;
  top: -58px;
  right: -10px;
`