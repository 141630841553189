import { SET_INFO_VALUES } from './actions'
import { GoodsEditAction, GoodsEditState } from './interface'

const initialState: GoodsEditState = {
  goods: null
}

export const goodsEditReducer = (
  state: GoodsEditState = initialState,
  action: GoodsEditAction
): GoodsEditState => {
  switch (action.type) {
    case SET_INFO_VALUES:
      return { ...state, goods: action.data }

    default:
      return state
  }
}