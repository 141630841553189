import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

const downloadSimpleChangePriceTemplateFile = async ({ companymarketplaceId, contragentId, companyId }) =>
  api.post<File>(
    '/pim/ops/products/simple/change/price/template', { companymarketplaceId, contragentId, companyId },
    {
      responseType: 'blob',
    }
  )

export const useDownloadSimpleChangePriceTemplateFile = () =>
  useMutation(downloadSimpleChangePriceTemplateFile)

const uploadSimpleChangePriceFile = async (
  {
    file,
    pricechangereasonId,
    changeDate,
    revertDate,
    comments,
  }: {
    file: File | null
    pricechangereasonId: UniqueId
    changeDate?: string
    revertDate?: string
    comments?: string
  }) => {
  const body = new FormData()
  body.append('file', file || '')
  body.append('comments', comments || '')

  return api.post(
    `/pim/ops/products/simple/change/price?pricechangereason_id=${pricechangereasonId}` +
    `${changeDate ? `&change_date=${changeDate}` : ''}${
      revertDate ? `&revert_date=${revertDate}` : ''
    }`,
    body
  )
}

export const useUploadSimpleChangePriceFile = (
  options?: UseMutationOptions<AxiosResponse,
    errorType,
    {
      file: File
      pricechangereasonId: UniqueId
      changeDate?: string
      revertDate?: string
      comments?: string
    }>
) => useMutation(uploadSimpleChangePriceFile, options)
