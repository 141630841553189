import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api'

export interface GetOrdersProductHistoryParams {
  productId: UniqueId
  fromDate?: string
  toDate?: string
}

export interface GetProductsByCodeParams {
    searchString: string
    orderId: number
}

export interface ResponseProductByCode {
    autoOrdering?: boolean
    barcode?: string
    companyName?: string
    id?: number
    marketplaceCode?: string
    marketplaceName?: string
    marketplaceUrl?: string
    name?: string
    price?: number
}

const getOrdersProductHistoryQueryKey = (
  queryParams: GetOrdersProductHistoryParams
) =>
  [
    'get',
    `trade/orders/product/${queryParams.productId}/history`,
    queryParams,
  ] as const

type OrdersProductHistoryQueryKey = ReturnType<
  typeof getOrdersProductHistoryQueryKey
>

function getOrdersProductHistoryFunc(
  queryParams: GetOrdersProductHistoryParams
) {
  const { productId, fromDate, toDate } = queryParams
  return api
    .get<Array<tradeApi.ProductOrdersOnDate>>(
      `/trade/orders/product/${productId}/history?from_date=${fromDate}${
        toDate ? `&to_date=${toDate}` : ''
      }`
    )
    .then((res) => res.data)
}

export const getOrdersProductHistory = (
  context: QueryFunctionContext<OrdersProductHistoryQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getOrdersProductHistoryFunc(queryParams)
}

export const useOrdersProductHistoryQuery = (
  params: {
    productId: UniqueId
    fromDate?: string
    toDate?: string
  }
) =>
  useQuery<
    Array<tradeApi.ProductOrdersOnDate>,
    errorType,
    Array<tradeApi.ProductOrdersOnDate>,
    OrdersProductHistoryQueryKey
  >(getOrdersProductHistoryQueryKey(params), getOrdersProductHistory,
    {
      refetchOnMount: false, keepPreviousData: true, staleTime: Infinity
    }
  )



const getProductsByCodeQueryKey = (
  queryParams: GetProductsByCodeParams
) =>
    [
      'get',
      `/trade/orders/supplyorders/${queryParams.orderId}/positions/newproducts`, 
      queryParams
    ] as const

type GetProductsByCodeQueryKey = ReturnType<
    typeof getProductsByCodeQueryKey
    >

export function getProductsByCodeFunc (orderId: number, searchString: string) {
  return api.get(`/trade/orders/supplyorders/${orderId}/productpositions/newproducts`, {
    params: { code: searchString },
  })
    .then((res) => res.data)
}


const getProductsByCode = (context: QueryFunctionContext<GetProductsByCodeQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getProductsByCodeFunc(queryParams. orderId, queryParams.searchString)
}

export const useProductsQueryByCode = (queryParams: GetProductsByCodeParams) =>
  useQuery<
        Array<tradeApi.ProductOrdersOnDate>,
        errorType,
        Array<ResponseProductByCode>,
        GetProductsByCodeQueryKey
        >(
          getProductsByCodeQueryKey(queryParams),
          getProductsByCode
        )