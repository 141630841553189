import React from 'react'
import { Box, Typography } from '@mui/material'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { formatter } from 'shared/lib/utils/numberWithSpaces'

import { ValueDeltaContainer, ValueLegendDot } from './styled'

interface ValueDeltaProps {
  name?: string
  value: number
  delta?: number
  color?: string
  shortValue?: boolean
}
export const ValueDelta = ({ name, value, delta, color, shortValue }: ValueDeltaProps) => {
  function round( val: string | undefined ) {
    if (val) {
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0 }).format(Number(value))
    } return '0'
  }

  return (
    <ValueDeltaContainer $diff={delta ? delta < 0 : false}>
      {name &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {color &&
        <ValueLegendDot color={color}/>
        }
        <Typography id="value-delta-label">
          {name}
        </Typography>
      </Box>
      }
      <Box id="value-delta-container">

        <Typography id="value-text">
          {shortValue ? formatter(Math.round(value)) : round(value.toString())}
        </Typography>

        {(delta !== 0 && delta) &&
        <Box id="delta-container">
          <CallMadeIcon id="delta-icon"/>
          <Typography id="delta-value">
            {shortValue ? formatter(Math.round(Math.abs(delta))) : Math.abs(delta).toLocaleString() || 0}
          </Typography>
        </Box>
        }
      </Box>
    </ValueDeltaContainer>
  ) 
}
