// @ts-nocheck
import styled from 'styled-components'
import { Grid } from '@devexpress/dx-react-grid-material-ui'

export const StyledGridRoot = styled(Grid.Root)`
  // thead row
  .MuiTableRow-head {
    background: rgba(247, 247, 247, 0.5);
  }

  .TableContainer-root {
    background-image:
      linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgba(0, 0, 0, 0.15) 0.56%, rgba(0, 0, 0, 0) 92.5%),
      linear-gradient(to left, rgba(0, 0, 0, 0.15) 0.56%, rgba(0, 0, 0, 0) 92.5%);
    /* Shadows */
    /* Shadow covers */
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 25px 100%, 25px 100%, 15px 100%, 15px 100%;
    background-attachment: local, local, scroll, scroll;
  }

  // thead cell
  .CellLayout-cell {
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
  .Mui-disabled svg {
    display: none;
  }

  // tbody row
  .MuiTableBody-root .MuiTableRow-root:not(.MuiTableRow-head):not(.TableRow-expanded):hover {
    background: rgba(0, 0, 0, 0.03);
  }
  .TableRow-expanded {
    background-color: ${({ theme }) => (
    theme.palette.primary.lightBg
  )};
    box-shadow:
      inset 3px 0px 0px ${({ theme }) => theme.palette.primary.main },
      inset -1px 1px 0px ${({ theme }) => theme.palette.primary.main };

    td {
      border-bottom: 1px solid transparent;
    }
  }

  // row detail
  .MuiTableRow-root:not(.TableRow-with-detailBtn) .TableDetailCell-active {
    background-color: ${({ theme }) => theme.palette.primary.lightBg };
    box-shadow:
      inset 3px 0px 0px ${({ theme }) => theme.palette.primary.main },
      inset -1px -1px 0px ${({ theme }) => theme.palette.primary.main };
  }

  // paging
  .Pager-pager {
    padding-top: ${({ theme }) => theme.spacing(0.5)};
    padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
  .Pagination-activeButton {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`