
export const pagingPanelLocale = {
  showAll: 'Все',
  rowsPerPage: 'Заказов на странице',
  info: ({ from, to, count }: { from: number, to: number, count: number }) => `${from}-${to} из ${count}`
}

export const searchPanelLocale = {
  searchPlaceholder: 'Поиск'
}

export const tableLocale = {
  noData: 'Нет данных',
}

export const tableHeaderRowLocale = {
  sortingHint: 'Cортировать'
}

export const columnChooserLocale = {
  showColumnChooser: 'Настройка отображения таблицы'
}