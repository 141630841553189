import packageJson from '../../../package.json'

export * from './env'
export * from './routes'
export * from './queryClient'
export * from './theme'
export * from './httpStatus'
export * from './snackbar'
export * from './product'

export const { version:  APP_VERSION } = packageJson