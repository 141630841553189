export const mergeArrays = (array1, array2) => {
  const merged = [...array1, ...array2] // Объединяем оба массива

  // Используем объект для временного хранения по id
  const resultMap = merged.reduce((acc, item) => {
    // Если объект с таким id уже есть, объединяем данные
    if (acc[item.id]) {
      acc[item.id] = { ...acc[item.id], ...item } // Объединяем объекты с одинаковым id
    } else {
      acc[item.id] = { ...item } // Если объекта нет, добавляем его
    }
    return acc
  }, {})

  // Преобразуем объект обратно в массив
  return Object.values(resultMap)
}