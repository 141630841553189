import { Box, Tooltip } from '@mui/material'

import { StyledDot, StyledProductAbcClass } from './styled'

export const ProductAbcClass = ({ value }) => {

  const productClass = (data: string | null) => {
    if (data?.length) {
      switch (data[0]) {
        case 'A':
          return '#4CAF50'
        case 'B':
          return '#FFB547'
        case 'C':
          return '#F50057'
        default:
          return ''
      }
    } else return ''
  }

  if (value === null || !value) {
    return <></>
  }

  return (
    <Box>
      <Tooltip
        title={<>
          <div>{`${value[0]} - Классификация по сумме продаж`}</div>
          {value[1] ? <div>{`${value[1]} - Классификация по количеству продаж`}</div> : <></>}
        </>}
        placement="top-start"
        componentsProps={{
          tooltip: {
            sx: {
              position: 'relative',
              top: '10px'
            }
          }
        }}
      >
        <StyledProductAbcClass>
          <StyledDot color={productClass(value)}/> {value}
        </StyledProductAbcClass>
      </Tooltip>
    </Box>
  )
}
