import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'

interface DeleteValueProps {
  value: pimApi.DeletingObject
  dictionaryId: UniqueId
}

const deleteValue = (deletedValue: DeleteValueProps) =>
  api.delete(
    `/pim/dictionaries/${deletedValue.dictionaryId}/values/${deletedValue.value.id}`,
    { data: deletedValue.value }
  )


export const useDeleteValueMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteValueProps
  >(deleteValue)