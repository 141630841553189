// @ts-nocheck
import styled from 'styled-components'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

export const StyledTableHeaderCellComponent = styled(TableHeaderRow.Cell)`
  padding: 12px 8px;
  font-size: 14px;
  line-height: 16px;
  :first-of-type {
    padding-left: 16px !important;
  }
  :last-of-type {
    padding-right: 16px !important;
  }
`