import { Box, Tooltip } from '@mui/material'

export const PricesCell = ({ prices }: { prices: any }) => (
  <Tooltip
    title={prices.map((price) => (
      <>
        {`${price.name}: ${price.price} `}
        <br />
      </>
    ))}
    placement="right"
  >
    <Box maxHeight="52px" p={1} textOverflow="ellipsis" overflow="hidden">
      {prices.map((price) => (
        <span key={price.name}>
          {`${price.name}: ${price.price} `}
          <br />
        </span>
      ))}
    </Box>
  </Tooltip>
  
)
