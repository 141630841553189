import { useMemo } from 'react'
import { Button, TextField } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

export const MainTab = ({ goodsInfo, handleDownload }) => {
  const mainFields = useMemo(
    () => [
      {
        label: 'Название',
        content: goodsInfo?.name,
      },
      {
        label: 'Категория',
        content: goodsInfo?.category_name,
      },
      {
        label: 'Бренд',
        content: goodsInfo?.brand_name,
      },
      {
        label: 'Артикул компании',
        content: goodsInfo?.code,
      },
      {
        label: 'Дополнительный артикул',
        content: goodsInfo?.aux_code,
      },
      {
        label: 'Описание',
        content: goodsInfo?.description,
      },
      {
        label: 'Ширина, см',
        content: goodsInfo?.width,
      },
      {
        label: 'Высота, см',
        content: goodsInfo?.height,
      },
      {
        label: 'Глубина, см',
        content: goodsInfo?.depth,
      },
      {
        label: 'Код ТН ВЭД',
        content: goodsInfo?.tnved_code,
      },
      {
        label: 'Страна производства',
        content: goodsInfo?.made_in_country_name,
      },
    ],
    [goodsInfo]
  )

  return (
    <div className="tab-content-wrapper">
      <div className="download-main-wrapper">
        <span>Основное</span>
        <Button
          onClick={handleDownload}
          variant="outlined"
          startIcon={<DownloadIcon />}
        >
          Скачать
        </Button>
      </div>
      <div className="tab-field">
        {mainFields.map((el) => (
          <div key={el.label}>
            <span>{el.label}</span>
            <TextField
              multiline={true}
              className="styled-text-field"
              size="small"
              value={el.content || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}