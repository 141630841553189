import React, { useEffect, useState } from 'react'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { TreeView } from '@mui/x-tree-view/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TreeItemLabel } from 'features/wms/ui/TreeItemLabel'
import { wmsGen } from 'shared/lib/generated'
import { isNotNil } from 'shared/lib/checkers'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { findArea } from 'features/wms/lib/findArea'

const checkElement = (el) => isNotNil(el) && isNotEmptyArray(el)

export const WmsTree = () => {
  const { searchObj } = useQueryParams()
  const { areaId } = searchObj
  const { data } = wmsGen.area.GetAreaTree.useGetAreaTree()
  const [ path, setPath ] = useState<Array<any>>([])

  const handleNodeToggle = (_, nodeIds) => {
    setPath(nodeIds)
  }

  useEffect(() => {
    if (data && isNotEmptyArray(data.children) && isNotNil(areaId)) {
      setPath(prev => [...prev, ...findArea(data.children, areaId)])
    }
  }, [data, areaId])


  if (data) return (
    <>
      <div className="tree-header">
        <span>Склад</span>
      </div>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={areaId || ''}
        expanded={path}
        onNodeToggle={handleNodeToggle}
      >
        {data.children.map(node => renderTree(node))}
      </TreeView>
    </>
  )

  return null
}

const renderTree = (nodes) => {
  const defineFolder = (elem) => {
    if (checkElement(elem.children)) {
      return elem.children.map(el => renderTree(el))
    }
    return null
  }

  return (
    <TreeItem
      key={ nodes.id.toString() }
      nodeId={ nodes.id.toString() }
      label={ <TreeItemLabel node={nodes}/> }>
      { defineFolder(nodes) }
    </TreeItem>
  )
}