import {
  GoodsVariation,
  GoodsVariationAttribute,
  VariationInfo,
} from 'domains/pim/goods'
import { Grid, Box } from '@mui/material'
import { GridTitleStyled } from 'shared/ui/components/TitleDivided/styled'

import { VariationTitle } from './styled'
import { SelectVariation } from './SelectVariation'

interface GoodsVariationProps {
  goodsId: UniqueId;
  marketplaceId: UniqueId | null;
  companyId: UniqueId | null;
  variations: VariationInfo;
  onChange?: (goodsId: UniqueId) => void;
}

interface SortedGoodsVariationAttribute extends GoodsVariationAttribute{
  orderNumber: number
}

export const Variation: React.FC<GoodsVariationProps> = ({
  goodsId,
  variations: {
    attributes,
    goods
  },
  onChange
}) => {
  const currentGoodsAttribute = goods.find((goodsAttribute) => goodsAttribute.goodsId === goodsId) as GoodsVariation
  const currentGoodsAttributeWithOrderNumber: Array<SortedGoodsVariationAttribute> = []
  currentGoodsAttribute?.attributes?.forEach(attr => {
    attributes.forEach(item => {
      if(attr.attributeName === item.attributeName) {
        currentGoodsAttributeWithOrderNumber.push({ ...attr })
      }
    })
  })

  const goodsQuantity = goods.length
  attributes.sort((a, b) => a.orderNumber - b.orderNumber)
  currentGoodsAttributeWithOrderNumber.sort((a, b) => a.orderNumber - b.orderNumber)

  return (
    <Box mb={3} display="flex" flexDirection="row" alignItems="center">
      <GridTitleStyled>
        <VariationTitle>Вариации товаров {goodsQuantity && `(${goodsQuantity})`}</VariationTitle>
      </GridTitleStyled>
      <Grid container={true} spacing={2}>
        {attributes.map((attribute, index) => (
          <SelectVariation
            key={attribute.attributeId}
            value={currentGoodsAttributeWithOrderNumber[index]?.value ?? '–'}
            attribute={attribute}
            onChange={onChange}
            index={index}
            currentGoodsAttribute={currentGoodsAttribute}
            goods={goods}
          />
        ))}
      </Grid>
    </Box>
  )
}
