import { Theme } from '@mui/material'
import { CalculatedReceiptDocPosition } from 'domains/doc'

export const getColorForRow = (theme: Theme, row: CalculatedReceiptDocPosition, isRowExpanded?: boolean) => {
  const validBorderColor = theme.palette.success.main
  const defaultBorderColor = theme.palette.primary.main
  const validBgColor = theme.palette.success.lightBg
  const openRowBgColor = theme.palette.primary.lightBg

  const isValid = row.quantity <= row.sumFact

  if (isValid) {
    return { borderColor: validBorderColor, bgColor: validBgColor }
  }

  if (isRowExpanded) {
    return { borderColor: defaultBorderColor, bgColor: openRowBgColor }
  }

  return {}

}