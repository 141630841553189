import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName extends Omit<
  tradeApi.SupplyOrderGoodsPositionProducts,
  | 'versionNo'
  | 'productId'
> {}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  id: 'id',
  productName: 'productName',
  productCode: 'productCode',
  marketplace: 'marketplace',
  quantity: 'quantity',
  quantityInBundle: 'quantityInBundle'
} as const

interface nestedTableColumnsConfigProps {
  path: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const nestedTableColumnsConfig: nestedTableColumnsConfigProps = {
  isEditable: true,
  path: 'products',
  config: [
    {
      name: columns.productCode,
      columnName: columns.productCode,
      title: 'Артикул продукта',
      width: 224,
      sortingEnabled: false,
      cellType: CellType.WordWrapCell,
    },
    {
      name: columns.productName,
      columnName: columns.productName,
      title: 'Наименование продукта',
      width: 'auto',
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.NameLinkBlank,
    },
    {
      name: columns.marketplace,
      columnName: columns.marketplace,
      title: 'МП',
      width: 160,
      sortingEnabled: false,
    },
    {
      name: columns.quantity,
      columnName: columns.quantity,
      title: 'Количество',
      width: 152,
      editingEnabled: true,
      sortingEnabled: false,
      cellType: CellType.EditField,
    },
    {
      name: columns.quantityInBundle,
      columnName: columns.quantityInBundle,
      title: 'Единиц товара',
      width: 130,
      wordWrapEnabled: true,
      sortingEnabled: false,
    },
  ],
}
