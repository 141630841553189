import styled from 'styled-components'

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  
  img {
    object-fit: cover;
    padding: 8px 0 8px 0;
    height: 76px;
    max-width: 50px;
    min-width: 50px;
  }

  .imagePreloader {
    padding: 8px 0 8px 0;
    height: 100%;
    max-width: 50px;
    min-width: 50px;
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;

    span {
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    
    a {
      text-decoration: none;
      color: #007DFF;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      :last-child {
        width: max-content;
      }
    }
  }
`