import { GOODS_CREATE } from 'shared/config/routes'
import { Box, Button, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link } from 'react-router-dom'
import icon from 'assets/images/icons/excel.svg'
import { pimApi } from 'shared/api'
import { ButtonNavigation } from 'shared/ui/components/navigation'
import { GOODS_LIST, PRODUCT_LIST } from 'shared/config'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { useDownloadFile } from '../../model/downloadFile'

const NAV_PATH = [
  { path: PRODUCT_LIST, title: 'ПРОДУКТЫ' },
  { path: GOODS_LIST, title: 'ТОВАРЫ' },
]

interface HeaderProps {
  downloadParams: pimApi.goods.DownloadGoodsFileParams
}

export const Header = ({ downloadParams }: HeaderProps) => {
  const { download, isLoading } = useDownloadFile()

  const downloadGoodsFile = () => {
    download(downloadParams)
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt="28px">
      <Box display="flex" alignItems="center">
        <Typography variant="h3" fontSize="24px" mr={2}>Мои товары</Typography>
        <Button variant="contained" component={Link} to={GOODS_CREATE} color="primary">
          ДОБАВИТЬ ТОВАР
        </Button>
      </Box>

      <Box ml={2} display="flex" alignItems="center">
        <LoadingButton
          variant="contained"
          color="primary"
          loadingPosition="start"
          startIcon={<ExcelIcon src={icon} alt="excelImg" />}
          onClick={downloadGoodsFile}
          loading={isLoading}
        >
          ЭКСПОРТ
        </LoadingButton>
        <Box ml={3}>
          <ButtonNavigation paths={NAV_PATH} />
        </Box>
      </Box>
    </Box>
  )
}
