import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { tradeGen } from 'shared/lib/generated'
import { snackActions } from 'shared/lib/react/snackbar'

import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'

export const DeleteCustomWarehouse = () => {
  const { invalidateDistributionCache } = useChangeDistribution()
  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const { supplyOrderQuery } = useSupplyOrderContext()

  const { mutate: deleteWarehouseMutation } = tradeGen.orders.
    DeleteCopackingdistributionwarehouse.
    useDeleteCopackingdistributionwarehouse()

  const deleteWarehouse = () => {
    setIsLoading(true)
    deleteWarehouseMutation({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
      copackingdistributionwarehouseId: selectedProducts?.warehouse?.id!,
      data: {
        id: selectedProducts?.warehouse?.id!,
        versionNo: selectedProducts?.warehouse?.versionNo!,
      }
    }, {
      onSuccess: () => {
        snackActions.info('Собственный склад успешно удален')
        setIsLoading(false)
        setSelectedProducts(null)
        invalidateDistributionCache({ supplyOrderQuery })
      },
      onError: () => setIsLoading(false)
    })
  }

  return ({ deleteWarehouse })
}