import { FieldRenderProps } from 'react-final-form'
import { Checkbox } from '@mui/material'
import React, { useEffect } from 'react'
import { BaseInputFieldI } from 'shared/ui/components/TextInput/TextInputI'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

const CheckBoxInput = styled(Checkbox)<{ $isShow?: boolean }>`
  position: absolute;
  right: 0;
  top: 36px;
  svg {
    color: rgba(0, 0, 0, 0.54);
    width: 24px;
    height: 24px;
  }
  display: ${({ $isShow }) => $isShow ? 'block' : 'none'};
`

interface ICheckBoxInputField<T> extends FieldRenderProps<T>, BaseInputFieldI {
  label?: string,
}

const CheckBoxInputField = <T extends {}>({ input, fieldData }: ICheckBoxInputField<T>) => {
  const handleChange = (event) => {
    input.onChange(event)
  }

  useEffect(()=> {
    if (fieldData?.value?.length) {
      input.onChange(true)
    } else if (!fieldData?.value?.length && !fieldData.secretFlag) {
      input.onChange(false)
    }
  }, [fieldData])

  const checkValue = input.value as unknown as boolean

  return (
    <CheckBoxInput
      size="small"
      checked={checkValue}
      checkedIcon={<CloseIcon />}
      onChange={handleChange}
      $isShow={fieldData.secretFlag && fieldData.valueSet}
    />
  )
}

export default CheckBoxInputField
