import { useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import {
  IProductMediaCreate,
  IProductMediaUpdate,
  IProductMediaDelete,
} from 'shared/services/interfaces/product'
import { api } from 'shared/api/base'
import { getFileSizeParams } from 'shared/api/getFileSizeParams'
import { pimApi } from 'shared/api'

export const getProductFile = async (
  productId: UniqueId,
  mediaType: string,
  fileId: UniqueId,
  width?: number,
  height?: number
): Promise<AxiosResponse<File>> => {
  const params =
    width && height ? `?max_height=${height}&max_width=${width}` : ''

  return api.get(
    `/pim/products/simple/product/${productId}/${mediaType}/${fileId}${params}`,
    {
      responseType: 'blob',
    }
  )
}

interface CreateProductMediaPayload {
  productId: UniqueId
  mediaType?: string
  file: FormData | null
}

export const validateProductMediaByURL = ({
  productId,
  mediaType,
  url,
}: createProductMediaByURLPayload) =>
  api.post(
    `/pim/products/simple/product/${productId}/${mediaType}/url/validator`,
    { url }
  )

export const useValidateProductMediaByURLMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    createProductMediaByURLPayload
  >(validateProductMediaByURL)

export const validateMediaFile = (queryParams: CreateProductMediaPayload) =>
  api.post<pimApi.products.MediaFile>(
    `/pim/products/simple/product/${queryParams.productId}/${queryParams.mediaType}/file/validator`,
    queryParams.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useValidateProductMediaFileMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    CreateProductMediaPayload
  >(validateMediaFile)

export const createMedia = ({ productId, mediaType, file }: CreateProductMediaPayload) =>
  api.post<pimApi.products.MediaFile>(
    `/pim/products/simple/product/${productId}/${mediaType}/file`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const useCreateProductMediaMutation = (options) =>
  useMutation<
    AxiosResponse<pimApi.products.MediaFile>,
    AxiosError<errorType>,
    CreateProductMediaPayload
  >(createMedia, options)

// TODO: remove

export function createProductMedia({
  productId,
  mediaType,
  file,
}: {
  productId: UniqueId
  mediaType: string
  file: FormData
}) {
  return api.post(
    `/pim/products/simple/product/${productId}/${mediaType}/file`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}


interface createProductMediaByURLPayload {
  productId: UniqueId
  mediaType: string
  url?: string,
}

export const createProductMediaByURL = ({ productId, mediaType, url }: createProductMediaByURLPayload) =>
  api.post(
    `/pim/products/simple/product/${productId}/${mediaType}/url`,
    { url }
  )

export const useCreateProductMediaByURLMutation = (options) =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    createProductMediaByURLPayload
  >(createProductMediaByURL, options)

// TODO: remove

export function createUrlProductMedia({
  productId,
  mediaType,
  url,
  description,
}: IProductMediaCreate): Promise<AxiosResponse<IProductMediaCreate>> {
  return api.post<IProductMediaCreate>(
    `/pim/products/simple/product/${productId}/${mediaType}/url`,
    { url, description }
  )
}

interface UpdateProductMediaPayload {
  productId: UniqueId
  mediaType: string
  editMediaFileList: Array<pimApi.products.EditMediaFile>
}

export const updateMedia = ({ productId, mediaType, editMediaFileList }: UpdateProductMediaPayload) =>
  api.put<Array<pimApi.products.EditMediaFile>>(
    `/pim/products/simple/product/${productId}/${mediaType}`, editMediaFileList
  )

export const useUpdateProductMediaMutation = (options) =>
  useMutation<
    AxiosResponse<Array<pimApi.products.EditMediaFile>>,
    AxiosError<errorType>,
    UpdateProductMediaPayload
  >(updateMedia, options)


// TODO: remove

export function updateProductMedia({
  productId,
  id,
  description,
  versionNo,
  mediaType,
  indexNumber,
}: IProductMediaUpdate): Promise<AxiosResponse<IProductMediaUpdate>> {
  return api.put(`/pim/products/simple/product/${productId}/${mediaType}/${id}`, {
    id,
    versionNo,
    description,
    indexNumber,
  })
}

interface DeleteProductMediaPayload {
  productId: UniqueId,
  id: UniqueId,
  mediaType: string,
  versionNo: UniqueId,
  comments?: string
}

export const deleteMedia = ({ productId, id, mediaType, versionNo }: DeleteProductMediaPayload) =>
  api.delete(
    `/pim/products/simple/product/${productId}/${mediaType}/${id}`,  {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )


export const useDeleteProductMediaMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteProductMediaPayload
  >(deleteMedia)

// TODO: remove

export function deleteProductMedia({
  productId,
  mediaType,
  id,
  versionNo,
}): Promise<AxiosResponse<IProductMediaDelete>> {
  return api.delete<IProductMediaDelete>(
    `/pim/products/simple/product/${productId}/${mediaType}/${id}`,
    {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )
}

export const getSimpleMediaApiPath = (
  productId: UniqueId,
  photoId?: UniqueId,
  mediaType?: string,
  size?: { maxWidth?: number; maxHeight?: number }
) => {
  const fileThumbnailPath = `/pim/products/simple/product/${productId}/${mediaType}/thumbnail/${photoId}`

  if (!size) {
    return fileThumbnailPath
  }

  const params = getFileSizeParams(size)

  return `${fileThumbnailPath}?${params}`
}

interface BulkDeleteProductMediaPayload {
  mediaType: string
  productId: UniqueId
  value: Array<pimApi.DeletingObject>
}

export const bulkDeleteMedia = ({ productId, mediaType, value }: BulkDeleteProductMediaPayload) =>
  api.delete(
    `/pim/products/simple/product/${productId}/${mediaType}`,  {
      data: value,
    }
  )


export const useBulkDeleteProductMediaMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    BulkDeleteProductMediaPayload
  >(bulkDeleteMedia)