import React, { useMemo } from 'react'
import Paper from '@mui/material/Paper'
import { Table } from 'shared/ui/components/Table'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { StyledLoader } from 'features/stocks/goodssupplying/ui'

import { StyledTableBox } from './styled'

import { pagingPanelLocale } from '../model/localization'
import { SearchObj } from '../../../../shared/ui/components/Table/model/types'
import { isNotNil } from '../../../../shared/lib/checkers'
import { columnsConfig } from '../model/columnsConfig'
import { PriceHistoryType } from '../model/types'

const ROW_HEIGHT = 96
const HEADER_HEIGHT = 88
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const PriceHistoryTable = (
  { priceHistoryData, isLoading }: { priceHistoryData?: Array<PriceHistoryType>, isLoading: boolean }) => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search)

  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      HEADER_HEIGHT -
      TABLE_CONTROLS -
      TABLE_HEADER_FOOTER) /
    ROW_HEIGHT
  )
  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.limit)
    ? parseInt(searchObj.limit, 10)
    : calculatedRowsPerPage

  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit,
      page: 0,
    }),
    [priceHistoryData]
  )

  return (
    <StyledTableBox component={Paper}>
      { (isLoading) && <StyledLoader size={60} />}
      {priceHistoryData &&
      <Table
        allRows={false}
        tableList={priceHistoryData}
        totalCount={priceHistoryData.length}
        paginationLocale={pagingPanelLocale}
        tableParams={memoTableParams}
        calculatedRowsPerPage={calculatedRowsPerPage}
        columnsConfig={columnsConfig}
        searching="internal"
        pagination="internal"
        sorting="internal"
        filtering="internal"
      />
      }
    </StyledTableBox>
  )
}