import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'

interface QueryParametersPayload {
  marketplaceId?: number
  companyId?: number
  companymarketplaceId?: number
  supplierId?: number
  brandId?: number
  blankTemplate?: boolean
}
const downloadProductsMassParamsEditTemplateFile = async (queryParams: QueryParametersPayload) => (
  api.get<File>('/pim/ops/products/change/product/attributes', {
    responseType: 'blob',
    params: {
      marketplace_id: queryParams.marketplaceId,
      company_id: queryParams.companyId,
      companymarketplace_id: queryParams.companymarketplaceId,
      supplier_id: queryParams.supplierId,
      brand_id: queryParams.brandId,
      blank_template: queryParams.blankTemplate
    }
  })
)

const uploadProductsMassParamsEditFile = async (
  queryParams: { file: File | null }
) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')
  return api.post(
    '/pim/ops/products/change/product/attributes',
    body
  )
}

export const useDownloadProductsMassParamsEditTemplateFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, QueryParametersPayload>
) => useMutation(
  downloadProductsMassParamsEditTemplateFile,
  options
)

export const useUploadProductsMassParamsEditFile = (
  options?: UseMutationOptions<AxiosResponse,errorType,
    { file: File | null }>
) => useMutation(
  uploadProductsMassParamsEditFile,
  options,
)
