/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AcceptanceCoefficients } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<AcceptanceCoefficients[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** ID записи */
    id?: number,
  /** ID склада маркетплейса */
    warehouse_id?: number,
  /** Наименование склада */
    warehouse_name?: string,
  /** ID поставки */
    box_type_id?: number[],
  /** Тип поставки */
    box_type_name?: string,
  /** Поиск */
    search_string?: string,
  /**
   * Дата начала действия коэффициента
   * @format date
   */
    date_from?: string,
  /**
   * Дата окончания действия коэффициента
   * @format date
   */
    date_to?: string,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/trade/orders/copackingorders/acceptance/coefficients`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/trade/orders/copackingorders/acceptance/coefficients`, { query }] as const

export const useGetgetacceptancecoefficients = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

