import { ReactNode } from 'react'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Image } from 'shared/ui/components'

import { ImageWrapper, CloseHeaderBtn } from './styled'

interface MediaPreviewDialogContentProps {
  actionBlock?: ReactNode
  imgPath: string
  onClose: () => void
}

export const MediaPreviewDialogContent = ({
  imgPath,
  onClose,
  actionBlock
}: MediaPreviewDialogContentProps) => (
  <Box
    minWidth="350px"
    maxWidth="450px"
    position="relative"
    onMouseDown = {(e) => e.stopPropagation ()}
  >
    <CloseHeaderBtn
      aria-label="close-modal"
      onClick={onClose}
      size="small"
    >
      <CloseIcon />
    </CloseHeaderBtn>

    <ImageWrapper
      minHeight="100px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Image src={imgPath} loadingIconSize={60} onDragStart={(e) => {e.preventDefault()}}/>
    </ImageWrapper>

    <Box my={1} mr={1}>
      {actionBlock}
    </Box>
  </Box>
)
