import {
  VariationInfo,
} from 'domains/pim/goods'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Variation } from 'features/goods/selectVariation'
import { DividerStyled } from 'shared/ui/components/TitleDivided/styled'
import { ArrowIconStyled } from 'features/goods/selectVariation/ui/styled'
import { useCompanyList, useCompanyMarketplaceList, useMarketplaceList } from 'shared/api/pim/dictionaries'

import { StyledProductHeaderInfo } from './styled'

interface OnChangePayload {
  goodsId: UniqueId,
  companyId: UniqueId | null,
  marketplaceId: UniqueId | null
}

interface GoodsVariationProps {
  goodsId: UniqueId;
  variations?: VariationInfo;
  withMarketplaces?: boolean;
  initialMarketplaceValues?: {
    companyId: UniqueId | null,
    marketplaceId: UniqueId | null
    companymarketplaceId: UniqueId | null
  },
  onChange: (
    payload: OnChangePayload
  ) => void
}

export const VariationAndMarketPlace: React.FC<GoodsVariationProps> = ({
  goodsId,
  variations,
  initialMarketplaceValues,
  onChange,
  withMarketplaces
}) => {
  const companyId: number | null = initialMarketplaceValues?.companyId ?? null
  const marketplaceId: number | null = initialMarketplaceValues?.marketplaceId ?? null
  const companymarketplaceId: number | null = initialMarketplaceValues?.companymarketplaceId ?? null

  const handleGoodsIdChange = (newGoodsId: UniqueId) => {
    onChange({
      goodsId: newGoodsId,
      companyId,
      marketplaceId
    })
  }

  const { marketplaceListQuery } = useMarketplaceList()
  const { companyListQuery } = useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(marketplaceId, companyId)

  const marketplaceName = marketplaceListQuery.data?.filter(el => el.id === marketplaceId).map(el => el.name).toString()
  const companyName = companyListQuery.data?.filter(el => el.id === companyId).map(el => el.name).toString()
  const companyMarketplaceName = companyMarketplaceListQuery.data?.
    filter(el => el.id === companymarketplaceId).
    map(el => el.name).toString()

  if (!withMarketplaces) {
    return (
      <>
        {variations &&
        <Variation
          variations={ variations }
          goodsId={ goodsId }
          companyId={ companyId }
          marketplaceId={ marketplaceId }
          onChange={ handleGoodsIdChange }
        />
        }
      </>
    )
  }
  
  return (
    <Grid container={true} mb={3} spacing={2}>
      <Grid item={true} xs={1.8}>
        <ProductHeaderInfo title="Маркетплейс" subTitle={marketplaceName}/>
      </Grid>
      <Grid item={true} xs={6.6} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid container={true} wrap="nowrap" alignItems="center">
          <Box width="100%">
            <DividerStyled />
          </Box>
          <ArrowIconStyled />
        </Grid>
      </Grid>
      <Grid item={true} xs={1.8}>
        <ProductHeaderInfo title="Продавец" subTitle={companyName}/>
      </Grid>
      <Grid item={true} xs={1.8}>
        <ProductHeaderInfo title="Кабинет" subTitle={companyMarketplaceName}/>
      </Grid>
    </Grid>
  )
}

const ProductHeaderInfo = ({ title, subTitle }) => (
  <StyledProductHeaderInfo>
    <Typography>{title}</Typography>
    <Typography>{subTitle}</Typography>
  </StyledProductHeaderInfo>
)
