import styled from 'styled-components'
import { getSupplyOrderStatusInfo, useSupplyOrderStatusQuery } from 'shared/api/pim/dictionaries'


const StatusWrapper = styled.div`
  display: flex;
`
const StatusName = styled.div<{ customColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  height: 28px;
  color: ${props => props.customColor};
  background-color: ${props => props.customColor === '#3B873E' ? '#EDF7ED' : '#F2F3FA'};
  padding: 0 14px 0 5px;
  font-size: 13px;
  margin-right: auto;
  margin-left: 0;
  svg {
    height: 18px;
    margin-right: 12px;
  }
`

export const OrderStatusCell = ({ statusId }: { statusId: number | undefined }) => {

  const { data, isLoading } = useSupplyOrderStatusQuery()
  const { currentStatus, currentObject, icon } = getSupplyOrderStatusInfo(data,statusId)

  return (
    <StatusWrapper>{
      !isLoading && data && statusId!==undefined  &&
        <StatusName customColor={currentObject[0]?.color}>
          {icon || <></>}
          {currentStatus?.name}
        </StatusName>
    }
    </StatusWrapper>
  )
}