// @ts-nocheck
import { Route } from 'react-router-dom'
import type { Route as RouteType } from 'pages/interface'

interface NestedRouteProps extends RouteType {
}

const NestedRoute = ({ ...currentRoute }: NestedRouteProps) => (
  <>
    <Route
      exact={true}
      path={currentRoute.path}
      render={(props) => (
        <currentRoute.layout width={currentRoute.layoutWidth}>
          <currentRoute.component {...props} />
        </currentRoute.layout>
      )}
    />
  </>
)

export default NestedRoute
