import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface TableIsDisabledProps {
  disabled: boolean;
}
export const StyledTableBox = styled(Box)<TableIsDisabledProps>`
  position: relative;
  overflow: hidden;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  border-radius: 4px;
   
  table {
    min-width: 0 !important;
  }
  
  .MuiTableHead-root {
    height: 50px;
    background: #F6F6F680;
  }

  .MuiTableRow-root {
    :last-child {
      .TableCell-cell {
        border-bottom: none;
      }
    }
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
  
  .TableCell-cell {
    :last-child {
      padding-right: 16px;
    }
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`