import React, { useState, useEffect, useCallback } from 'react'
import { DateFormats } from 'shared/config/dateFormats'
import { DateTimePickerProps, DateTimePicker as MuiDatePicker } from '@mui/lab'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'

import { DatePickerI } from './types'
import * as UI from './styled'

const InputLabelProps = { shrink: true, error: false }
const DatePicker = (props: DatePickerI) => {
  const {
    value,
    dateFormat = DateFormats.dayMonthYear,
    label,
    inputHeight,
    disablePrev,
    disablePast,
    disableToolbar,
    minDateTime,
    maxDateTime,
    disabled,
    onChange,
    onBlur,
    pickerType,
    defaultValue = '',
    views,
    showClear = true,
    fullWidth
  } = props
  const [params, setParams] = useState({ dateFormat })

  const [selectedDate, setSelectedDate] = useState<string | Date | number | null>(value || defaultValue)

  useEffect(() => {
    if (pickerType === 'datetime') {
      setParams({
        dateFormat: DateFormats.dayMonthYearHoursMinutes,
      })
    }
  }, [pickerType])

  const handleBlur = useCallback(
    (e) => {
      if (typeof onBlur === 'function') {
        onBlur(e)
      }
    },
    [onBlur]
  )
  useEffect(() => {
    if (!value) {
      onChange?.(defaultValue)
      setSelectedDate(defaultValue || null)
    } else {
      setSelectedDate(value)
    }
  }, [value])

  const handleDateChange = (date: string) => {
    const result = new Date(date).toISOString()
    onChange?.(result)
    setSelectedDate(date)
  }

  const disablePrevDates = (startDate: string) => {
    const startSeconds = Date.parse(startDate)
    return (date: string) => Date.parse(date) < startSeconds
  }

  const handleChange = (newValue: any) => {
    try {
      setSelectedDate(newValue)
    } catch (error) {
      setSelectedDate(new Date())
    }
  }

  const clearFilter = () => {
    setSelectedDate(null)
    onChange?.('')
  }

  const $isEmpty = selectedDate === null || selectedDate === new Date()

  const propsPicker: DateTimePickerProps & React.RefAttributes<HTMLDivElement> & { $isEmpty: boolean } = {
    disableToolbar,
    inputVariant: 'outlined',
    allowKeyboardControl: false,
    size: 'small',
    format: params.dateFormat,
    label,
    onBlur: handleBlur,
    value: value || selectedDate,
    mask: '__.__.____',
    minDateTime,
    maxDateTime,
    disabled,
    inputValue: selectedDate,
    inputFormat: params.dateFormat,
    // @ts-ignore
    onAccept: handleDateChange,
    timeIcon: false,
    showToolbar: false,
    KeyboardButtonProps: {
      'aria-label': 'change date',
    },
    views,
    // @ts-ignore
    shouldDisableDate: disablePrevDates(disablePrev),
    disablePast,
    onChange: handleChange,
    renderInput: (renderProps) => (
      <UI.TextFieldStyled onBlur={handleBlur} {...renderProps} $isEmpty={$isEmpty} InputLabelProps={InputLabelProps}/>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <UI.Wrapper $height={inputHeight} $fullWidth={fullWidth}>
        {showClear && !$isEmpty && (
          <UI.ClearButton onClick={clearFilter}>
            Очистить
          </UI.ClearButton>
        )}
        <MuiDatePicker {...propsPicker} />
      </UI.Wrapper>
    </LocalizationProvider>
  )
}

export default DatePicker
