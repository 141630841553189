import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { dialogModel } from 'shared/ui/components/dialog'
import { customerApi } from 'shared/api'
import { useQueryClient } from 'react-query'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { getCurrentCompanyQueryKey } from 'shared/api/customer'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'


interface SettingsMenuItem {
  label: string,
  handler: () => void,
  type?: string
}
interface IEditCompanyOptions {
  data: customerApi.Company
}

export const EditCompanyOptions = ( { data }: IEditCompanyOptions) => {

  const companyData = data
  const queryClient = useQueryClient()

  const { mutate: editCompany } =
    customerApi.useEditCompanyMutation()
 
  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: companyData.inArchive ? 'Вернуть из архива': 'Архивировать компанию',
      type: 'warning',
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) =>
              <DefaultModal
                close={close}
                accept={accept}
                acceptBtnColor="primary"
                variant="contained"
                acceptBtnText={companyData.inArchive
                  ? 'ПОДТВЕРДИТЬ'
                  : 'АРХИВИРОВАТЬ'
                }
                title={companyData.inArchive
                  ? 'Вернуть компанию из архива?'
                  : 'Перенос компании в архив'
                }
              />,
            onAccept: () => {
              editCompany( {
                companyId: companyData.id,
                editedCompany: {
                  id: companyData.id,
                  versionNo: companyData.versionNo,
                  name: companyData.name,
                  inn: companyData.inn,
                  inArchive: !companyData.inArchive
                }
              },{
                onSuccess: (response ) => {
                  queryClient.setQueryData( getCurrentCompanyQueryKey(companyData.id), response.data )
                  snackActions.info('Информация изменена!')
                }
              })
            }
          }
        )
      }
    }
  ]

  return  (
    <SettingsMenu
      iconType={<MoreVert />}
      options={settingsOptions}
    />
  )
  
}