import produce from 'immer'

// export const reactQueryCacheAdd = ({ queryClient, queryCacheKey, path = '', onSuccess = () => {} }) => (queryResult) => {
//   const queryCache = queryClient.getQueryData(queryCacheKey)

//   const nextState = produce(queryCache, (draftState: any) => {
//     if(path) {
//       if (draftState?.[path]===undefined) {
//         // eslint-disable-next-line no-param-reassign
//         draftState[path] = []
//       }
//       draftState?.[path].push(queryResult?.data)
//     } else {
//       draftState?.push(queryResult?.data)
//     }
//   })

//   queryClient.setQueryData(queryCacheKey, nextState)
//   onSuccess()
// }

export const genReactQueryCacheAdd =
  ({ queryClient, queryCacheKey, path = '', onSuccess = () => {} }) =>
    (queryResult) => {
      const queryCache = queryClient.getQueryData(queryCacheKey)
        
      const nextState = produce(queryCache, (draftState: any) => {
        if (path) {
          if (draftState?.[path] === undefined) {
          // eslint-disable-next-line no-param-reassign
            draftState[path] = []
          }
          draftState?.[path].push(queryResult)
        } else {
          draftState?.push(queryResult)
        }
      })

      queryClient.setQueryData(queryCacheKey, nextState)
      onSuccess()
    }

// export const reactQueryCacheUpdateByKey =
//   ({
//     queryClient,
//     queryCacheKey,
//     path = '',
//     key = 'id',
//     onSuccess = () => {},
//   }) =>
//     (queryResult) => {
//       const queryCache = queryClient.getQueryData(queryCacheKey)
//       const queryResultData = queryResult?.data

//       const nextState = produce(queryCache, (draft: any) => {
//         if (path && draft?.[path]) {
//           const updateIndex = draft?.[path]?.findIndex(
//             (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
//           )
//           if (updateIndex !== -1) draft[path][updateIndex] = queryResult?.data
//         } else {
//           const updateIndex = draft?.findIndex(
//             (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
//           )
//           if (updateIndex !== -1) draft[updateIndex] = queryResult?.data
//         }
//       })

//       queryClient.setQueryData(queryCacheKey, nextState)
//       onSuccess()
//     }

export const genReactQueryCacheUpdateByKey =
    ({
      queryClient,
      queryCacheKey,
      path = '',
      key = 'id',
      onSuccess = () => {},
    }) =>
      (queryResult) => {
        const queryCache = queryClient.getQueryData(queryCacheKey)
        const queryResultData = queryResult
  
        const nextState = produce(queryCache, (draft: any) => {
          if (path && draft?.[path]) {
            const updateIndex = draft?.[path]?.findIndex(
              (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
            )
            if (updateIndex !== -1) draft[path][updateIndex] = queryResult
          } else {
            const updateIndex = draft?.findIndex(
              (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
            )
            if (updateIndex !== -1) draft[updateIndex] = queryResult
          }
        })
  
        queryClient.setQueryData(queryCacheKey, nextState)
        onSuccess()
      }

export const reactQueryCacheUpdate =
  ({ queryClient, queryCacheKey, path = '' }) =>
    (queryResult) => {
      const queryCache = queryClient.getQueryData(queryCacheKey)
      const queryResultData = queryResult?.data

      const nextState = produce(queryCache, (draft: any) => {
        if (path && draft?.[path]) {
          draft[path] = queryResultData
        }
      })

      queryClient.setQueryData(queryCacheKey, nextState)
    }

// export const reactQueryCacheDelete =
//   ({
//     queryClient,
//     queryCacheKey,
//     path = '',
//     key = 'id',
//     deleteItem,
//     onSuccess = () => {},
//   }) =>
//     () => {
//       const queryCache = queryClient.getQueryData(queryCacheKey)
//       const queryResultData = deleteItem

//       const nextState = produce(queryCache, (draft) => {
//         if (path && draft?.[path]) {
//           const deleteIndex = draft?.[path]?.findIndex(
//             (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
//           )
//           if (deleteIndex !== -1) draft[path].splice(deleteIndex, 1)
//         } else {
//           const deleteIndex = draft?.findIndex(
//             (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
//           )
//           if (deleteIndex !== -1) draft?.splice(deleteIndex, 1)
//         }
//         if (queryResultData.length > 0 && draft?.[path]) {
//           const deleteIndex: Array<any> = []
//           queryResultData.forEach((el) => {
//             const index = draft?.[path]?.findIndex(
//               (queryCacheItem) => queryCacheItem[key] === el[key]
//             )
//             deleteIndex.push(index)
//           })
//           if (deleteIndex.length !== -1)
//             draft[path].splice(deleteIndex, deleteIndex.length)
//         }
//       })

//       queryClient.setQueryData(queryCacheKey, nextState)
//       onSuccess()
//     }

export const genReactQueryCacheDelete =
  ({
    queryClient,
    queryCacheKey,
    path = '',
    key = 'id',
    deleteItem,
    onSuccess = () => {},
  }) =>
    () => {
      const queryCache = queryClient.getQueryData(queryCacheKey)
      const queryResultData = deleteItem

      const nextState = produce(queryCache, (draft) => {
        if (path && draft?.[path]) {
          const deleteIndex = draft?.[path]?.findIndex(
            (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
          )
          if (deleteIndex !== -1) draft[path].splice(deleteIndex, 1)
        } else {
          const deleteIndex = draft?.findIndex(
            (queryCacheItem) => queryCacheItem[key] === queryResultData[key]
          )
          if (deleteIndex !== -1) draft?.splice(deleteIndex, 1)
        }
        if (queryResultData.length > 0 && draft?.[path]) {
          const deleteIndex: Array<any> = []
          queryResultData.forEach((el) => {
            const index = draft?.[path]?.findIndex(
              (queryCacheItem) => queryCacheItem[key] === el[key]
            )
            deleteIndex.push(index)
          })
          if (deleteIndex.length !== -1)
            draft[path].splice(deleteIndex, deleteIndex.length)
        }
      })

      queryClient.setQueryData(queryCacheKey, nextState)
      onSuccess()
    }
