import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'

import { api } from '../../base'

interface DeleteCategoryAttribute {
  categoryId: UniqueId
  value: pimApi.DeletingObject
}

const deleteCategoryAttribute = (deletedAttribute: DeleteCategoryAttribute) =>
  api.delete(
    `/pim/categories/${deletedAttribute.categoryId}/attributes/${deletedAttribute.value.id}`,
    { data: deletedAttribute.value, params: { category_attribute_id: deletedAttribute.value.id } }
  )


export const useDeleteAttributeMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteCategoryAttribute
  >(deleteCategoryAttribute)