import styled from 'styled-components'

export const PriceWrapper = styled.div`
  width: 100%;
  #styled-card {
    padding-left: 0;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    
    .main-price-container {
      margin-bottom: 0;
    }
    .title-text {
      display: none !important;
    }
    
    .spp-price-container {
      border-top: none !important; 
      *{
        margin-top: 0 !important;
      }
    }
    
    #discount-container {
      color: #3F51B5 !important;
      border: 1px solid #3F51B5 !important;
    }
  }
`

export const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  >span {
    width: 100%;
    height: 100%;
  }
`