import styled from 'styled-components'
import { Paper, FormLabel, Theme } from '@mui/material'
import { PageTitle } from 'shared/ui/components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  >span {
    margin-bottom: 16px;
  }
`

export const PaperTitle = styled.div`
  font-size: 34px;
  margin-bottom: 30px;
  color: #000;
`
export const InputLabel = styled.div<{ $isSingle?: boolean }>`
  
  margin-bottom: ${(props) => (props.$isSingle ? '0px;' : '16px;')};
  display: flex;
  align-items: center;
`

export const FormLabelStyled = styled(FormLabel)`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
`

export const LabelType = styled.span`
  color: #007dff;
`

export const PaperWrapper = styled(Paper)`
  padding: 40px 48px;
`
export const InputSimpleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiFormLabel-root{
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    font-size: 16px;
    height: 100%;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    max-width: calc(120% - 10px);
  }
`
export const BottomSection = styled.div`
  position: relative;
  bottom: 0;
  box-shadow: inset 0 1px 0 rgba(196, 196, 196, 0.25);
  padding-top: 40px;
  margin-top: 30px;
`
export const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    svg {
      margin-right: 10px;
    }
  }
`
export const PageTitleStyled = styled(PageTitle)`
  button {
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  span {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    svg {
      font-size: 20px;
    }
  }
`
export const SecondNDSSpan = styled.span`
  display: inline-flex;
  margin-right: 20px;
`
export const InputActions = styled.div`
  margin-top: -26px;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
`

export const UploadWrapper = styled.div`
  border: 2px dashed #BDBDBD;
  border-radius: 4px;
  padding: 36px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  > input{
    display: none;
  }
  span {
    svg {
      margin-right: 10px;
    }
  }
`

export const YoutubeImage = styled.img`
  object-fit: cover;
  border-radius: 4px;
`
export const DeleteMedia = styled.div`
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  svg {
    font-size: 40px;
    color: white;
  }
`
export const VideoItemWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 192px;
`
export const VideoContent = styled.div`
  display: inline-flex;
  position: relative;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #00000017;
    object-fit: cover;
  }

  .settingsMenu {
    visibility: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: none;

    button {
      width: 28px;
      height: 28px;
      background-color: white;
    }
  }
  & > a{
    position: absolute;
  }
  &:hover{
    .settingsMenu {
      display: flex;
      visibility: visible;
    }
  }
`
export const VideoIcon = styled.div`
  position: sticky;
  svg {
    font-size: 30px;
    color: #ffffff;
  }
`
export const YoutubeLink = styled.a<{ theme: Theme }>`
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  cursor: pointer;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const MediaList = styled.div`
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
`
export const FillWidthForm = styled.div`
  width: 100%;
`
export const DocItemWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 35px;
  margin-bottom: 24px;
  width: 180px;
`
export const DocContent = styled.a`
  display: inline-flex;
  border-radius: 4px;
  position: relative;
  height: 180px;
  width: 180px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  &:hover{
    div{
      opacity: 1;
    }
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  > embed{
    max-height: 180px;
    max-width: 180px;
  }
`
export const DocIcon = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  padding: 4px;
  background: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FF505F;
  svg {
    font-size: 18px;
    color: #FF505F;
  }
`
export const DocHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(0 125 255 / 40%);
  opacity: 0;
`
export const DeleteDoc = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    font-size: 24px;
    color: #F44336;
  }
`
export const DocName = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const DocDesc = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  svg{
    font-size: 18px;
  }
`
export const DescMedia = styled.div`
  width: 100%;
  margin-right: 10px;
  word-break: break-all;
`
export const MediaActions = styled.div`
  display: flex;
  align-items: center;
`
export const GreyInputWrapper= styled.div`
  width: 100%;
  input{
    background: #fff;
  }
`
export const DocUrl = styled.div`
  opacity: 0;
  position: absolute;
`
export const FieldsWrapper = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  input{
    color: ${(props) => props.$isDisabled ? 'rgba(0, 0, 0, 0.54);' : ''};
  }
`
export const PhotoWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 24px;
`
export const MoverWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
export const VideoLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
`
export const VideoWrapper = styled.div`
  position: relative;
  > a{
    display: inline-block;
    width: 100%;
  }
  &:hover{
    div{
      opacity: 1;
    }
  }
`
