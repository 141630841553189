import {
  CurrentMargin,
  DaysStocks,
  IncomingPriceCell,
  NewMargin,
  StocksCell
} from '../../../ui/tableCells'

export const NestedWidgetCells = [
  {
    name: 'Остатки',
    width: '90px',
    padding: '20px 16px 16px 16px',
    Component: StocksCell
  },
  {
    name: 'В день',
    width: '72px',
    padding: '20px 10px 16px 16px',
    Component: DaysStocks
  },
  {
    name: 'Вход. цена',
    width: '106px',
    padding: '20px 16px 16px 16px',
    Component: IncomingPriceCell
  },
  {
    name: 'Текущая маржа',
    width: '242px',
    padding: '20px 16px 16px 16px',
    Component: CurrentMargin
  },
  {
    name: 'Новая маржа',
    width: 'auto',
    padding: '20px 16px 16px 16px',
    Component: NewMargin
  },
]