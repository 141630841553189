/* eslint-disable no-case-declarations */

import { isNil } from 'shared/lib/checkers'

import { getWeekDates } from './getWeekStartEnd'

function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1)
  // @ts-ignore
  const daysSinceStartOfYear = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000))
  return Math.ceil((daysSinceStartOfYear + startOfYear.getDay() + 1) / 7)
}

function formatWeekNumber(date) {
  const weekNumber = getWeekNumber(date)
  const month = String(date.getMonth() + 1).padStart(2, '0') // Приводим месяц к двухзначному формату
  const year = String(date.getFullYear()).slice(-2) // Последние две цифры года
  return `${weekNumber}W ${month}/${year}`
}

export function startOfWeek(date) {
  const day = date.getDay()
  const diff = day === 0 ? -6 : 2 - day
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff)
}

export function endOfWeek(date) {
  const start = startOfWeek(date)
  start.setDate(start.getDate() + 6)
  return start
}

function getDaysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate()
}

function getQuarterDates(year, quarter) {
  const startMonth = (quarter - 1) * 3
  return [
    new Date(year, startMonth, 2),
    new Date(year, startMonth + 2, getDaysInMonth(year, startMonth + 2) + 1) // last day of quarter
  ]
}

export function aggregateStocksData(data, groupBy) {
  const aggregatedData = {}

  data.forEach(item => {
    const date = new Date(item.orderDate)
    let periodKey = ''
    let startDate
    let endDate

    switch (groupBy) {
      case 'day':
        periodKey = date.toISOString().slice(0, 10)
        break

      case 'week':
        const weekStart = startOfWeek(date)
        periodKey = `${formatWeekNumber(weekStart)}`
        startDate = weekStart
        endDate = endOfWeek(date)
        break

      case 'month':
        periodKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
        startDate = new Date(date.getFullYear(), date.getMonth(), 2)
        endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1)
        break

      case 'quarter':
        const quarter = Math.ceil((date.getMonth() + 1) / 3)
        periodKey = `${quarter}Q-${date.getFullYear()}`
        const [start, end] = getQuarterDates(date.getFullYear(), quarter)
        startDate = start
        endDate = end
        break

      case 'year':
        periodKey = `${date.getFullYear()}`
        startDate = new Date(date.getFullYear(), 0, 2)
        endDate = new Date(date.getFullYear(), 11, 32)
        break

      default:
        throw new Error('Invalid groupBy value. Use day, week, month, quarter, or year.')
    }

    if (!aggregatedData[periodKey]) {
      aggregatedData[periodKey] = {
        stocks: 0,
        orders: 0,
        ordersItems: 0,
        ordersCurrency: 0,
        filteredStocks: [],
        numberOfOrders: 0,
        lastStocks: item.stocks,
        count: 0,
        periodStartDate: date,
        startDate,
        endDate
      }
    }

    aggregatedData[periodKey].numberOfOrders += item.numberOfOrders
    aggregatedData[periodKey].filteredStocks = item.filteredStocks
    aggregatedData[periodKey].orders += item.orders
    aggregatedData[periodKey].ordersItems += item.ordersItems
    aggregatedData[periodKey].ordersCurrency += item.ordersCurrency
    aggregatedData[periodKey].lastStocks = item.stocks
    // eslint-disable-next-line no-plusplus
    aggregatedData[periodKey].count++
  })

  const periodsArray = Object.entries(aggregatedData)
  const lastPeriodIndex = periodsArray.length - 1


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const completePeriods = (Object.entries(aggregatedData) as any).filter(([_, value], index) => {
    const { periodStartDate, count } = value
    const year = periodStartDate.getFullYear()
    const month = periodStartDate.getMonth()

    switch (groupBy) {
      case 'day':
        return true

      case 'week':
        return index === lastPeriodIndex || count === 7

      case 'month':
        return index === lastPeriodIndex || count === getDaysInMonth(year, month)

      case 'quarter':
        const quarterMonths = [0, 1, 2].map(offset => new Date(year, Math.floor(month / 3) * 3 + offset))
        const daysInQuarter = quarterMonths.reduce((acc, date) => acc + getDaysInMonth(date.getFullYear(), date.getMonth()), 0)
        return index === lastPeriodIndex || count === daysInQuarter

      case 'year':
        const daysInYear = (new Date(year, 11, 31).getTime() - new Date(year, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + 1
        return index === lastPeriodIndex || count === daysInYear

      default:
        return false
    }
  })
  
  const lastCompleteIndex = completePeriods.length - 1

  const lastOrderDate = new Date(data[data.length - 1].orderDate)

  return completePeriods.map(([period, value], index) => ({
    period,
    stocks: value.lastStocks,
    orders: value.orders,
    ordersCurrency: value.ordersCurrency,
    ordersItems: value.ordersItems,
    filteredStocks: value.filteredStocks,
    numberOfOrders: value.numberOfOrders,
    startDate: value.startDate ? value.startDate.toISOString().slice(0, 10) : null,
    endDate: index === lastCompleteIndex ? lastOrderDate?.toISOString().slice(0, 10) : value.endDate?.toISOString().slice(0, 10)
  }))
}

export function aggregateSalesData(data, groupBy) {
  const aggregatedData = {}

  data.forEach(item => {
    const date = new Date(item.orderDate)
    let periodKey = ''
    let startDate
    let endDate

    switch (groupBy) {
      case 'day':
        periodKey = date.toISOString().slice(0, 10)
        break

      case 'week':
        const weekStart = startOfWeek(date)
        periodKey = `${formatWeekNumber(weekStart)}`
        startDate = weekStart
        endDate = endOfWeek(date)
        break

      case 'month':
        periodKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
        startDate = new Date(date.getFullYear(), date.getMonth(), 2)
        endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1)
        break

      case 'quarter':
        const quarter = Math.ceil((date.getMonth() + 1) / 3)
        periodKey = `${quarter}Q-${date.getFullYear()}`
        const [start, end] = getQuarterDates(date.getFullYear(), quarter)
        startDate = start
        endDate = end
        break

      case 'year':
        periodKey = `${date.getFullYear()}`
        startDate = new Date(date.getFullYear(), 0, 2)
        endDate = new Date(date.getFullYear(), 11, 32)
        break

      default:
        throw new Error('Invalid groupBy value. Use day, week, month, quarter, or year.')
    }

    if (!aggregatedData[periodKey]) {
      aggregatedData[periodKey] = {
        orders: 0,
        sales: 0,
        ordersItems: 0,
        ordersCurrency: 0,
        filteredStocks: [],
        numberOfOrders: 0,
        count: 0,
        periodStartDate: date,
        startDate,
        endDate
      }
    }

    aggregatedData[periodKey].numberOfOrders += item.numberOfOrders
    aggregatedData[periodKey].filteredStocks = item.filteredStocks
    aggregatedData[periodKey].orders += item.orders
    aggregatedData[periodKey].sales += item.sales
    aggregatedData[periodKey].ordersItems += item.ordersItems
    aggregatedData[periodKey].ordersCurrency += item.ordersCurrency
    // eslint-disable-next-line no-plusplus
    aggregatedData[periodKey].count++
  })

  const periodsArray = Object.entries(aggregatedData)
  const lastPeriodIndex = periodsArray.length - 1


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const completePeriods = (Object.entries(aggregatedData) as any).filter(([_, value], index) => {
    const { periodStartDate, count } = value
    const year = periodStartDate.getFullYear()
    const month = periodStartDate.getMonth()

    switch (groupBy) {
      case 'day':
        return true

      case 'week':
        return index === lastPeriodIndex || count === 7

      case 'month':
        return index === lastPeriodIndex || count === getDaysInMonth(year, month)

      case 'quarter':
        const quarterMonths = [0, 1, 2].map(offset => new Date(year, Math.floor(month / 3) * 3 + offset))
        const daysInQuarter = quarterMonths.reduce((acc, date) => acc + getDaysInMonth(date.getFullYear(), date.getMonth()), 0)
        return index === lastPeriodIndex || count === daysInQuarter

      case 'year':
        const daysInYear = (new Date(year, 11, 31).getTime() - new Date(year, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + 1
        return index === lastPeriodIndex || count === daysInYear

      default:
        return false
    }
  })

  const lastCompleteIndex = completePeriods.length - 1

  const lastOrderDate = new Date(data[data.length - 1].orderDate)

  return completePeriods.map(([period, value], index) => ({
    period,
    sales: value.sales,
    orders: value.orders,
    ordersCurrency: value.ordersCurrency,
    ordersItems: value.ordersItems,
    numberOfOrders: value.numberOfOrders,
    startDate: value.startDate ? value.startDate.toISOString().slice(0, 10) : null,
    endDate: index === lastCompleteIndex ? lastOrderDate?.toISOString().slice(0, 10) : value.endDate?.toISOString().slice(0, 10)
  }))
}

export const getDateByAggregationType = (stringDate, groupby) => {
  let startDate
  let endDate

  if (groupby === 'day' || isNil(groupby)) {
    startDate = stringDate
    endDate = stringDate
  }
  if (groupby === 'week') {
    const { startDate: start, endDate: end } = getWeekDates(stringDate)
    startDate = start
    endDate = end
  }
  if (groupby === 'month') {
    startDate = new Date(Number(stringDate.split('-')[0]), Number(stringDate.split('-')[1]) - 1, 2).toISOString().slice(0, 10)
    endDate = new Date(Number(stringDate.split('-')[0]), Number(stringDate.split('-')[1]), 1).toISOString().slice(0, 10)
  }
  if (groupby === 'quarter') {
    const [start, end] = getQuarterDates(Number(stringDate.split('-')[1]), Number(stringDate.split('-')[0].replace(/\D/g, '')))
    startDate = start.toISOString().slice(0, 10)
    endDate = end.toISOString().slice(0, 10)
  }
  if (groupby === 'year') {
    startDate = new Date(Number(stringDate), 0, 2).toISOString().slice(0, 10)
    endDate = new Date(Number(stringDate), 11, 32).toISOString().slice(0, 10)
  }

  return {
    startDate,
    endDate
  }
}