import keyMirror from 'keymirror-nested'
import { put } from 'redux-saga/effects'
import watcherCreator, { requesterFunctionType } from 'store/watcherCreator'
import { actionsCreator, actionType } from 'store/actionsCreator'
import {
  CatalogLiteralType,
  CatalogType,
  ICatalog,
  CatalogReqType,
} from 'shared/services/interfaces/catalog'
import { TYPES } from 'shared/services/types'
import { diContainer } from 'shared/lib'
import { IUser } from 'shared/services/interfaces/user'
import { qParams } from 'shared/services/interfaces/request'

type CatalogStateValueType = { [key in CatalogLiteralType]: Array<CatalogType> }

type CatalogActionsType = actionType<
  AddCatalogAction | CatalogStateValueType,
  errorType
>

export interface CatalogStateType {
  catalogs: CatalogStateValueType
  error: errorType | null | undefined
}

export const CATALOG = keyMirror(
  {
    LIST: null,
  },
  '_',
  'CATALOG'
)

interface AddCatalogAction {
  catalog: CatalogLiteralType
  qParams?: qParams
  sort?: (a: any, b: any) => number
}
export const addCatalog = actionsCreator<AddCatalogAction>(CATALOG.LIST)

export const catalogSelector = (state: {
  catalogReducer: CatalogStateValueType
}): CatalogStateValueType => state.catalogReducer

const initialState = {
  catalogs: {},  
  error: null,
}

export const catalogReducer = (
  state = initialState,
  action: CatalogActionsType
): CatalogStateType => {
  switch (action.type) {
    case addCatalog.getType('success'):
      return {
        ...state,
        catalogs: {
          ...state.catalogs,
          ...(<CatalogStateValueType>action.data),
        },
      }
    default:
      return state
  }
}

const user = diContainer.get<IUser>(TYPES.User)

export function* initCatalogFetching() {
  try {
    yield user.authWatcher()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

// eslint-disable-next-line no-nested-ternary
const catalogSort = (a: CatalogType, b: CatalogType) => a.name === undefined ? 
  -1 : b.name === undefined ?
    1 : a.name.localeCompare(b.name)

const catalog = diContainer.get<ICatalog>(TYPES.Catalog)
export const catalogWatcher = watcherCreator(
  'CATALOG',
  function* catalogWorker (
    { type, data }: CatalogActionsType,
    requester: requesterFunctionType
  ) {
    if (type === addCatalog.getType('pending')) {
      // @ts-ignore
      const catalogName: CatalogLiteralType = data?.catalog || ''
      if (!catalogName) return
      let params = {
        catalog: catalogName,
      }
      if (catalogName === 'goods') {
        params = {
          ...params,
          // @ts-ignore
          catalog: `${catalogName}?barcode=${data?.qParams.value}`,
        }
      }
      const { result } = yield requester<
        Array<CatalogType>,
        ICatalog,
        [CatalogReqType]
      >(catalog, 'getCatalog', <CatalogReqType>params).getResult();

      (result.data as Array<CatalogType>).sort(
        // @ts-ignore
        data.sort ?? catalogSort
      )
      
      yield put(addCatalog.success({ [catalogName]: result.data }))
    }
  }
)
