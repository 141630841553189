import { FC } from 'react'
import { Typography } from '@mui/material'
import { useField } from 'react-final-form'
import styled from 'styled-components'

interface FieldWithCountProps {
  maxLength?: number
  children: JSX.Element
}

const Text = styled(Typography)`
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 12px;
  letter-spacing: 0.4px;
`

export const FieldWithCount: FC<FieldWithCountProps> = ({ maxLength, children }) => {
  const fieldName = useField(children.props.name)
  const length =
    fieldName.input.value === typeof 'object'
      ? ''
      : fieldName.input.value.length

  return (
    <>
      {children}
      <Text variant="body2" color="textSecondary">
        Символов: {length}{maxLength && `/${maxLength}`}
      </Text>
    </>
  )
}