// @ts-nocheck
import styled from 'styled-components'
import { Grid } from '@devexpress/dx-react-grid-material-ui'

export const StyledGridRoot = styled(Grid.Root)`
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E0E0E0;

  // thead cell
  .CellLayout-cell {
    padding-top: ${({ theme }) => theme.spacing(1.25)};
    padding-bottom: ${({ theme }) => theme.spacing(1.25)};
    line-height: 16px;

    :first-of-type {
      padding-left: ${({ theme }) => theme.spacing(2)};
    }
  }

  // tbody cell
  .TableCell-cell:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(2)};
  }
`