export interface actionType<T = {}, E = undefined> {
  type: string
  data?: T
  error?: E
}

export interface requestActionType<T = any> {
  getType: (type: string) => string
  pending: (data?: T) => actionType<typeof data>
  success: (data?: any) => actionType<typeof data>
  error: (error?: any) => actionType<undefined, errorType>
}

export const actionsCreator = <T>(name: string): requestActionType<T> => ({
  getType: (type: string) => {
    if (type === 'pending') return `${name}_PENDING`
    if (type === 'success') return `${name}_SUCCESS`
    if (type === 'error') return `${name}_ERROR`
    return ''
  },
  pending: (data?) => ({
    type: `${name}_PENDING`,
    data,
  }),
  success: (data?) => ({
    type: `${name}_SUCCESS`,
    data,
  }),
  error: (error) => ({
    type: `${name}_ERROR`,
    error,
  }),
})
