import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

export const DocDate = ({ date }: { date?: string }) => {
  if (!date) return null
  return (
    <span>
      {format(new Date(date), DateFormats.dayMonth)}
    </span>
  )
}
