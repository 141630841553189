import { Box, Grid } from '@mui/material'

import { StyledDifference, StyledSummaryItem } from './styled'

import { columnNames } from '../../ColumnsConfig'

interface SymmaryData {
  value: {
    columnName: string;
    value?: number;
    negative?: number;
    positive?: number;
    valid?: boolean;
  }
}

export const SummaryItem = ({ value }: SymmaryData) => {
  const differenceColumnNotValid = value?.columnName === columnNames.sumDefective && value.value
  const sumFactColumnNotValid = value?.columnName === columnNames.sumFact && !value.valid

  const summaryItemNotValid = Boolean(differenceColumnNotValid || sumFactColumnNotValid)

  let summaryValue: number | React.ReactNode

  switch (value.columnName) {
    case columnNames.price: {
      summaryValue = value?.value?.toFixed(2)
      break
    }

    case columnNames.difference: {
      if (value.negative && value.positive) {
        summaryValue = (
          <Grid container={true}>
            <StyledDifference>{value.negative}</StyledDifference>
            <Box mx={0.5}>/</Box>
            <StyledDifference>+{value.positive}</StyledDifference>
          </Grid>
        )
      } else if (value.negative) {
        summaryValue = (<StyledDifference>{value.negative}</StyledDifference>)
      } else if (value.positive) {
        summaryValue = (<StyledDifference>+{value.positive}</StyledDifference>)
      } else {
        summaryValue = 0
      }
      break
    }

    default: {
      summaryValue = value?.value
      break
    }
  }

  return (
    <StyledSummaryItem summaryItemNotValid={summaryItemNotValid} ml={1} >
      {summaryValue}
    </StyledSummaryItem>
  )
}
