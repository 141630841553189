import React from 'react'
import { useParams } from 'react-router-dom'
import { EditCategoryPanel } from 'features/admin/Goods/Categories'

export const CategoryPage = () => {
  const { id } = useParams<{ id: string }>()
  const categoryId = parseInt(id, 10)

  return (
    <EditCategoryPanel categoryId={categoryId}/>
  )
}