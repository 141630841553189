/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { SupplyOrderResult } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<SupplyOrderResult>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Company Id
   * Фильтр по компании продавцу
   */
    company_id?: number,
  /**
   * Contragent Id
   * Фильтр по поставщику
   */
    contragent_id?: number,
  /**
   * Marketplace Id
   * Фильтр по маркетплейсу
   */
    marketplace_id?: number,
  /**
   * Brand Id
   * Фильтр по бренду
   */
    brand_id?: number,
  /**
   * Status Id
   * Фильтр по статусу
   */
    status_id?: number,
  /**
   * Search String
   * Поиск по набору слов
   */
    search_string?: string,
  /**
   * Page
   * Номер страницы
   * @default 0
   */
    page?: number,
  /**
   * Limit
   * Количество элементов на странице
   * @default 10
   */
    limit?: number,
  /**
   * Sort
   * Столбец сортировки
   */
    sort?: string,
  /**
   * Order
   * Порядок сортировки asc/desc
   * @default "asc"
   */
    order?: string,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/trade/orders/supplyorders`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/trade/orders/supplyorders`, { query }] as const

export const useGetSupplyOrders = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

