import { Wrapper } from 'shared/ui/goods/common'
import { Photos } from 'features/product/OZON/media/photos'
import { Photos360 } from 'features/product/OZON/media/photos360'
import { useOzonProductContext } from 'entities/pim/product/model/ozon/useOzonProductContext'

import { Videos , PictureSample } from './components'
import { VideoCover } from './components/VideoCover'


export const Media = () => {
  const { ozonProductQuery } = useOzonProductContext()
  const isArchived = ozonProductQuery.data?.inArchive

  return (
    <Wrapper>
      <span style={{ fontSize: '20px', fontWeight: '500', color: '#000000DE' }}>Медиа</span>
      <Photos photos={ozonProductQuery.data?.photos} disabled={isArchived} />
      <Photos360 photos360={ozonProductQuery.data?.photos360} disabled={isArchived} />
      <PictureSample productId={ozonProductQuery.data?.id} picture={ozonProductQuery.data?.picture} disabled={isArchived}/>
      <Videos ozonProduct={ozonProductQuery.data} disabled={isArchived} />
      <VideoCover productId={ozonProductQuery.data?.id} cover={ozonProductQuery.data?.videocover} disabled={isArchived}/>
    </Wrapper>
  )
}
