import { getCellWidth } from 'features/analytics/reprice/lib/getCellWidth'
import { useState } from 'react'

import { NestedWidget } from './NestedWidget'

import { ApproveCell } from '../../../ui/tableCells'

export const PriceAdviceTableRow = ({ row, tableCells }) => {
  const [ expandNested, setExpandNested ] = useState(false)

  return (
    <>
      <div className="table-row">
        <div>
          <div>
            { tableCells.map(({ padding, width, Component }, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <td key={ index } style={ { padding, ...getCellWidth(width) } }>
                <Component
                  row={ row }
                  expandNested={ expandNested }
                  setExpandNested={ setExpandNested }
                />
              </td>
            )) }
          </div>
          {expandNested &&
          <div style={{ display: 'flex' }}>
            <NestedWidget row={row}/>
          </div>
          }
        </div>
        <ApproveCell row={row}/>
      </div>
    </>
  )
}