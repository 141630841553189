import { memo, useMemo } from 'react'
import { Paper } from '@mui/material'
import orderBy from 'lodash/orderBy'

import { Table } from './Table'
import { StyledTableBox } from './styled'

import { PriceHistoryRecord } from '../../model'

export const PriceHistoryTable = memo(
  ({
    timeInterval,
    tableData,
  }: {
    timeInterval: Array<number>
    tableData: PriceHistoryRecord[]
  }) => {
    const slicedDataByTime = useMemo<
      PriceHistoryRecord[] | undefined
    >(
      () =>
        orderBy(
          tableData.filter(
            ({ changeDate }) =>
              Number(new Date(changeDate)) >= timeInterval[0] &&
              Number(new Date(changeDate)) <= timeInterval[1]
          ),
          ['changeDate'],
          ['desc']
        ),
      [timeInterval, tableData]
    )

    return (
      <>
        <StyledTableBox component={Paper}>
          {slicedDataByTime && (
            <Table
              tableList={slicedDataByTime}
              totalCount={slicedDataByTime?.length}
            />
          )}
        </StyledTableBox>
      </>
    )
  }
)
