import { useState } from 'react'

type Status<D> = StatusNothing<D> | StatusLoading | StatusError | StatusLoaded<D>

interface StatusNothing<D> {
  status: 'nothing',
  data: D | null
}


interface StatusLoading {
  status: 'loading',
  data: null
}

interface StatusError {
  status: 'error',
  data: string
}


interface StatusLoaded<D> {
  status: 'loaded',
  data: D
}

export function getIsDataLoaded<D>(status: Status<D>): status is StatusLoaded<D> {
  return status.status === 'loaded'
}

export function useStatus() {
  const [status, setStatus] = useState<Status<any>>({ status: 'nothing', data: null })

  function resetData() {
    setStatus({
      status: 'nothing',
      data: null
    })
  }

  function setError(message: string) {
    setStatus({
      status: 'error',
      data: message
    })
  }

  function setLoading() {
    setStatus({
      status: 'loading',
      data: null
    })
  }

  function setData(data) {
    setStatus({
      status: 'loaded',
      data: {
        groups: data.groups,
        attributes: data.attributes.map(el => {
          if (el.multipleValues && !Array.isArray(el.value)) {
            return {
              ...el,
              value: [el.value]
            }
          }
          return el
        })
      }
    })
  }

  return [
    status,
    {
      resetData,
      setError,
      setLoading,
      setData
    }
  ] as const
}
