/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { PrivilegeGroup } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<PrivilegeGroup[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Customer
   * Признак собственных привелегий
   */
    customer?: boolean,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/customer/admin/privileges`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/customer/admin/privileges`, { query }] as const

export const useGetPrivileges = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

