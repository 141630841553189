import { api } from 'shared/api/base'
import { AxiosResponse, AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'

interface UpdateGoodsToPackPayload {
  goodsId: string
  content: pimApi.goods.NewGoodsBundleComponent
}

export const updateGoodsToPack = ({ goodsId, content }: UpdateGoodsToPackPayload) =>
  api.post<pimApi.goods.NewGoodsBundleComponent>(
    `/pim/goods/${goodsId}/components`,
    content
  )

export const useUpdateGoodsToPacktMutation = () =>
  useMutation<
    AxiosResponse<pimApi.goods.NewGoodsBundleComponent>,
    AxiosError<errorType>,
    UpdateGoodsToPackPayload
  >(updateGoodsToPack)

interface updateGoodsTypeToCommonPayload {
  goodsId: string,
}

export const updateGoodsTypeToCommon = ({ goodsId }: updateGoodsTypeToCommonPayload) => 
  api.delete<void>(
    `/pim/goods/${goodsId}/components`,
  )
  
export const useUpdateGoodsTypeToCommonMutation = () =>
  useMutation<
    AxiosResponse<void>,
    AxiosError<errorType>,
    updateGoodsTypeToCommonPayload
  >(updateGoodsTypeToCommon)

  interface updateAssortmentToCommonPayload {
    goodsId: string,
  }
  
export const updateAssortmentToCommon = ({ goodsId }: updateAssortmentToCommonPayload) => 
  api.delete<void>(
    `/pim/goods/${goodsId}/assortment`,
  )
  
export const useUpdateAssortmentToCommonMutation = () =>
  useMutation<
    AxiosResponse<void>,
    AxiosError<errorType>,
    updateAssortmentToCommonPayload
  >(updateAssortmentToCommon)


interface UpdateGoodsToBundlePayload {
  goodsId: string
  content: Array<pimApi.goods.NewGoodsBundleComponent>
}

export const updateGoodsToBundle = ({ goodsId, content }: UpdateGoodsToBundlePayload) =>
  api.post<Array<pimApi.goods.NewGoodsBundleComponent>>(
    `/pim/goods/${goodsId}/components`,
    content
  )

export const useUpdateGoodsToBundleMutation = () =>
  useMutation<
    AxiosResponse<Array<pimApi.goods.NewGoodsBundleComponent>>,
    AxiosError<errorType>,
    UpdateGoodsToBundlePayload
  >(updateGoodsToBundle)

  interface UpdateGoodsToAssortmentPayload {
    goodsId: string,
    content: Array<pimApi.goods.NewGoodsAssortment>
  }
  
export const updateGoodsToAssortment = ({ goodsId, content }: UpdateGoodsToAssortmentPayload) =>
  api.post<Array<pimApi.goods.NewGoodsAssortment>>(
    `/pim/goods/${goodsId}/assortment`,
    content
  )
  
export const useUpdateGoodsToAssortmentMutation = () =>
  useMutation<
    AxiosResponse<Array<pimApi.goods.NewGoodsAssortment>>,
    AxiosError<errorType>,
    UpdateGoodsToAssortmentPayload
  >(updateGoodsToAssortment)