import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'
import snakeCase from 'lodash/snakeCase'

interface PatchSupplyOrderPayload {
  supplyOrderId?: UniqueId,
  patchBody: {
    action?: string
    versionNo?: string,
    comments?: string,
    file?: Blob
  }
}

export const patchSupplyOrder = ({ supplyOrderId, patchBody }: PatchSupplyOrderPayload) => {
  const body = new FormData()

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(patchBody)) {
    body.append(snakeCase(key), value || '')
  }

  return api.patch<tradeApi.SupplyOrder>(
    `/trade/orders/supplyorders/${supplyOrderId}`,
    body
  )
}
  

export const usePatchSupplyOrderMutation = () =>
  useMutation<
    AxiosResponse<tradeApi.SupplyOrder>,
    AxiosError<errorType>,
    PatchSupplyOrderPayload
  >(patchSupplyOrder)