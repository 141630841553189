import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Accordion } from 'shared/ui/components/Accordion'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  DialogActions,
  Button, 
  DialogTitle, 
  Grid, 
  Autocomplete, 
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi, customerApi } from 'shared/api'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import TextField from '@mui/material/TextField'
import { FormDateTimePicker, InputLabel } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'

import { validationSchema } from './lib/validationSchema'

import { ManagerSelectWrapper } from '../styled'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 480px;
    max-width: 480px;
  }
`

interface ManagerAddModalProps {
    close: () => void;
    handleUserAdd: (userData: pimApi.admin.ContragentUser) => void
    handleUserChange: (userData: pimApi.admin.ContragentUser) => void
    manager?: pimApi.admin.ContragentUser
    selectedManagers?: pimApi.admin.ContragentUser[]
}

export const ManagersModal = ({ 
  close, 
  handleUserAdd, 
  handleUserChange, 
  manager,
  selectedManagers
}: ManagerAddModalProps) => {


  const convertedManager = { ...manager, dateIn: manager?.dateIn.split('.')[0] }

  const { data } = customerApi.dictionaries.useUsersListQuery()  // todo не обращаться за датой если режим edit
   
  const filteredManagersFn = () => {
    if ( data ) {
      const selectedManagerNames = selectedManagers?.map(object => object.userName)
      return data?.filter(items => items.state !== 'closed').filter(item =>
        !selectedManagerNames?.includes(item.name))
    }
    return []
  }

  const onSubmit = (userData) => {
    if (manager) {
      handleUserChange(userData)
    }
    else handleUserAdd(userData)
    close()
  }

  const [, setDatePeriodOpen] = useState(false)

  const handleDatePeriodExpand = () => {
    setDatePeriodOpen(true)
  }
  const handleDatePeriodClose = () => {
    setDatePeriodOpen(false)
  }

  const validate = useValidationSchema(validationSchema)

  
  const AddManagerDate = ({ errors }) => (
    <>
      <Grid item={true} xs={12} mt={4}>
        <Accordion
          title="Открыть доступ позднее"
          single={false}
          onOpen={handleDatePeriodExpand}
          onClose={handleDatePeriodClose}
          sx={{ borderBottomRightRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}

        >
          <Grid container={true} item={true} xs={12} spacing={2} pb={2}>
            <StyledField item={true} xs={8}>
              <Field
                name="dateIn"
                label="Дата Время"
                value={manager?.dateIn}
                helperText={errors && errors?.dateIn ? errors?.dateIn : ''}
                error={!!errors?.dateIn}
                InputLabelProps={{
                  shrink: true,
                }}
                component={FormDateTimePicker}
                disabled={!!manager}
              />
            </StyledField>
          </Grid>
        </Accordion>
      </Grid>

      <Grid item={true} xs={12}>
        <Accordion
          title="Закрыть доступ"
          onOpen={handleDatePeriodExpand}
          onClose={handleDatePeriodClose}
          sx={{ borderTopRightRadius: '0 !important', borderTopLeftRadius: '0 !important' }}
        >
          <Grid container={true} item={true} xs={12} spacing={2} pb={2}>
            <StyledField item={true} xs={8}>
              <Field
                name="dateOut"
                label="Дата Время"
                value={manager?.dateOut}
                helperText={errors && errors?.dateOut ? errors?.dateOut : ''}
                error={!!errors?.dateOut}
                InputLabelProps={{
                  shrink: true,
                }}
                component={FormDateTimePicker}
              />
            </StyledField>
          </Grid>
        </Accordion>
      </Grid>
    </>)

  const EditManagerDate = ({ values, errors }) => (
    <Grid container={true} item={true} xs={12}>
      <Typography fontSize={14} fontWeight={500} mb={2}>
        Закрыть доступ позднее
      </Typography>
      <StyledField item={true} xs={8}>
        <Field
          name="dateOut"
          label="Дата Время"
          value={values.dateOut}
          helperText={errors && errors?.dateOut ? errors?.dateOut : ''}
          error={!!errors?.dateOut}
          InputLabelProps={{
            shrink: true,
          }}
          component={FormDateTimePicker}
        />
      </StyledField>
    </Grid>)



  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20}>
          { manager ? manager.userName : 'Выбрать менеджера поставщика' }
        </DialogTitle>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={convertedManager || null}
            render={({ handleSubmit, values, errors }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                {!manager && <Box mt={1}>
                  <InputLabel
                    label="Поиск менеджера"
                    required={false}
                  />
                  <Box mb="5px">
                    <Field name="userId">
                      {({ input }) => (
                        <ManagerSelectWrapper>
                          <Autocomplete
                            id="userId"
                            onChange={(event, selectedValue) => input.onChange(selectedValue?.id)}
                            options={filteredManagersFn()}
                            getOptionLabel={(option) => option.name}
                            popupIcon={<SearchIcon/>}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label="Поиск менеджера"
                              />
                            }
                          />
                        </ManagerSelectWrapper>
                      )
                      }
                    </Field>
                  </Box>
                </Box>}

                {
                  manager
                    ? <EditManagerDate errors={errors} values={values}/>
                    : <AddManagerDate errors={errors}/>
                }

                <DialogActions sx={{
                  marginTop: '32px',
                  padding: 0,
                  marginBottom: '24px'
                }}>

                  <Box>
                    <Button
                      onClick={close}
                      color="primary"
                    >
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={false}
                      disabled={
                        !(!!values.userId || !!manager?.id)
                          || Object.keys(errors || {}).length !== 0
                      }
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      {manager ? 'ПОДТВЕРДИТЬ' : 'ВЫБРАТЬ'}
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )
            }
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



