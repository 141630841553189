import styled from 'styled-components'
import { Box, Grid, IconButton } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import Bookmark from '@mui/icons-material/Bookmark'

export const ArrowForwardStyled = styled(ArrowForward)`
  opacity: 0.38;
`

export const BoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ArrowGrid = styled(Grid)`
  svg {
    font-size: 31px;
  }
`

export const StyledIconButton = styled(IconButton)`
  width: 34px;
`

export const StyledBookmark = styled(BookmarkBorderIcon)`
  width: 20px;
  color: #bdbdbd;
`

export const StyledBookmarkActive = styled(Bookmark)`
  width: 20px;
  color: #007DFF;
`
