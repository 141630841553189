import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { pimApi } from 'shared/api'

import { api } from '../../base'

export const getGoodsChangelogQueryKey = (goodsId?: UniqueId) =>
  ['get', `/pim/goods/${goodsId}/changelog`, goodsId] as const

type GoodsChangelogQueryKey = ReturnType<typeof getGoodsChangelogQueryKey>

export const getGoodsChangelog = (goodsId?: UniqueId) =>
  api.get<pimApi.goods.ChangeLog>(`/pim/goods/${goodsId}/changelog`)

export const useFetchGoodsChangelogQuery = (goodsId?: UniqueId) => 
  useQuery<
    AxiosResponse,
    AxiosError<errorType>,
    AxiosResponse,
    GoodsChangelogQueryKey
  >(
    getGoodsChangelogQueryKey(goodsId),
    (context) => getGoodsChangelog(context.queryKey[2] as UniqueId)
  )