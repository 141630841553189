import React, { useState, useEffect, memo } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  arrayMove
} from '@dnd-kit/sortable'
import { pimApi } from 'shared/api'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { dialogModel } from 'shared/ui/components/dialog'
import { reactQueryCacheAdd, reactQueryCacheDelete, reactQueryCacheUpdate } from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import {
  CircularProgressStyled,
  CircularProgressWrap,
  ContentsWrapper
} from 'shared/ui/components/CircularProgress/CircularProgress'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { PhotoRequirements } from 'widgets/Media/DragDropWidget/ui/Requirements/Ozon/photoRequirements'
import { MoreVert } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { SettingsMenu } from 'shared/ui/components'
import UploadIcon from '@mui/icons-material/Upload'
import { OzonMediaTypes, WbMediaTypes } from 'shared/types/MediaTypes'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { PhotoItem } from './ui/PhotoItem'

const PICTURE_SUPPORTED_FILE_EXTENSION = ['.jpeg', '.jpg', '.webp', '.png']

export const Photos = memo(
  ({
    photos,
    disabled,
  }: {
    photos?: Array<pimApi.products.MediaFile>,
    disabled?: boolean
  }) => {
    const queryClient = useQueryClient()
    const { id } = useParams<{ id: string }>()
    const productId = parseInt(id, 10)
    const mediaLimited = photos?.length === 15
    const [localPhotos, setPhotos] = useState<Array<pimApi.products.MediaFile>>([])
    const { mutate: bulkImportMediaFromProduct } =
      pimApi.products.common.useMassImportMediaFromProduct()
    const { mutate: createMediaMutation, isLoading: createInProgress } =
      pimApi.products.ozon.useCreateProductMediaMutation({
        onSuccess: reactQueryCacheAdd({
          queryClient,
          queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
          path: 'photos'
        })
      })
    const {
      mutate: createMediaByURLMutation,
      isLoading: createByURLInProgress,
    } = pimApi.products.ozon.useCreateProductMediaByURLMutation({
      onSuccess: reactQueryCacheAdd({
        queryClient,
        queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
        path: 'photos'
      })
    })
    const { mutate: updateMediaMutation, isLoading: updateInProgress } =
      pimApi.products.ozon.useUpdateProductMediaMutation({
        onSuccess: reactQueryCacheUpdate({
          queryClient,
          queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
          path: 'photos'
        }),
      })
    const { mutateAsync: validateMediaFileMutation } =
      pimApi.products.ozon.useValidateProductMediaFileMutation()
    const { mutateAsync: validateMediaUrlMutation } =
      pimApi.products.ozon.useValidateProductMediaByURLMutation()

    const { mutate: deleteMediaMutation, isLoading: deleteInProgress } =
      pimApi.products.ozon.useDeleteProductMediaMutation()

    const { mutate: bulkDeleteMediaMutation, isLoading: bulkDeleteInProgress } =
      pimApi.products.ozon.useBulkDeleteProductMediaMutation()

    

    useEffect(() => {
      setPhotos(photos === undefined ? [] : photos)
    }, [photos])

    const onFileValidate = (mediaType: WbMediaTypes) =>
      (file, onSuccess) => {
        if (typeof file === 'string') {
          validateMediaUrlMutation({ url: file, productId, mediaType }).then(res => {
            if (res.status === 200) onSuccess(res)
          })
        } else {
          const formFile = new FormData()
          formFile.append('file', file)
          validateMediaFileMutation({
            productId,
            mediaType,
            file: formFile,
          }).then(res => {
            if (res.status === 200) onSuccess(res)
          })
        }
      }
    const onFileUpload =
      (mediaType: OzonMediaTypes) =>
        (files) => {
          if (files && isNotEmptyArray(files)) {

            files.forEach(el => {
              if (typeof el.file === 'string') {
                createMediaByURLMutation({ url: el.file, productId, mediaType })
              } else {
                const file = new FormData()

                file.append('file', el.file || '')

                createMediaMutation({
                  productId,
                  mediaType,
                  file,
                })
              }
            })
          }
        }

    const onDragChange = (sourceId, sourceIndex, targetIndex) => {
      const swappedPhotos = arrayMove<pimApi.products.MediaFile>(localPhotos, sourceIndex, targetIndex)
      const editMediaFileList = swappedPhotos.map<pimApi.products.EditMediaFile>(
        (photo: pimApi.products.MediaFile, index) => ({
          id: photo.id,
          versionNo: photo.versionNo,
          indexNumber: index
        })
      )
      setPhotos(swappedPhotos)
      updateMediaMutation(
        {
          productId,
          mediaType: 'photos',
          editMediaFileList
        },
        {
          onError: () => {
            setPhotos(localPhotos)
          }
        }
      )
    }

    const handleSort = () => {
      const copyOfLocalPhotos = [...localPhotos]
      const sortedPhotos = copyOfLocalPhotos.sort((a, b) => a.fileName!.localeCompare(b.fileName!, undefined, {
        numeric: true,
        sensitivity: 'base'
      }))

      const editMediaFileList = sortedPhotos.map<pimApi.products.EditMediaFile>(
        (photo, index) => ({
          id: photo.id,
          versionNo: photo.versionNo,
          indexNumber: index
        }))
      setPhotos(sortedPhotos)
      updateMediaMutation(
        {
          productId,
          mediaType: 'photos',
          editMediaFileList
        },
        {
          onError: () => {
            setPhotos(localPhotos)
          }
        }
      )
    }

    const handleDelete = (deletingPhoto: DeletingObject) => {
      dialogModel.openDialog({
        component: MediaDeleteConfirmModal,
        onAccept: () => {
          deleteMediaMutation(
            { productId, mediaType: 'photos', ...deletingPhoto },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
                deleteItem: deletingPhoto,
                path: 'photos'
              }),
            }
          )
        },
      })
    }

    const handleMassImportMedia = () => {
      bulkImportMediaFromProduct({ productId, marketplaceName: 'ozon' },
        {
          onSuccess: () => {
            snackActions.info('Скопировано')
          }
        })
    }

    const handleBulkDelete = () => {
      dialogModel.openDialog({
        component: ({ accept, close }) => (
          <MediaDeleteConfirmModal title="Удалить все изображения?" accept={accept} close={close}/>
        ),
        onAccept: () => {
          bulkDeleteMediaMutation(
            { productId, mediaType: 'photos', value: localPhotos },
            {
              onSuccess: reactQueryCacheDelete({
                queryClient,
                queryCacheKey: pimApi.products.ozon.getOzonProductQueryKey(productId),
                deleteItem: localPhotos,
                path: 'photos'
              }),
            }
          )
        }
      })
    }

    const PhotosSorted = () => {
      const copyLocalPhotos = [...localPhotos]
      return (JSON.stringify(localPhotos) === JSON.stringify(copyLocalPhotos.sort(
        (a, b) => a.fileName!.localeCompare(b.fileName!, undefined, {
          numeric: true,
          sensitivity: 'base'
        }))))
    }

    const settingsOptions: Array<any> = [
      {
        label: 'Отсортировать',
        disabled: disabled || localPhotos.length<2 || PhotosSorted(),
        icon: <SortByAlphaIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
        handler: handleSort
      },
      {
        label: 'Импорт в товар',
        icon: <UploadIcon sx={{ color: '#0000008A', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        divider: true,
        handler: handleMassImportMedia
      },
      {
        label: 'Очистить',
        icon: <DeleteIcon sx={{ color: '#F4433680', marginRight: '8px' }}/>,
        disabled: disabled || localPhotos.length === 0,
        type: 'error',
        handler: handleBulkDelete
      },
    ]

    const isQueriesInProgress =
      createByURLInProgress ||
      createInProgress ||
      updateInProgress ||
      deleteInProgress ||
      bulkDeleteInProgress

    const getFilePath = (mediaType: OzonMediaTypes) =>
      (photoId, size) => pimApi.products.ozon.getOzonMediaApiPath(productId, photoId, mediaType, size)
    const handleOpenGallery = (item) => {
      dialogModel.openDialog({
        component: ({ close }) => (
          <MediaGalleryModal
            mediaVariation="image"
            close={close}
            mediaType="Фото"
            getFilePath={getFilePath('photos')}
            clickedItem={item}
            items={localPhotos}
          />
        )
      })
    }

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <span style={{ fontWeight: '500', color: '#000000DE' }}>Фото</span>
            <span style={{ color: '#0000008A' }}>{ localPhotos.length }/15</span>
          </div>
          <SettingsMenu
            className="settingsMenu"
            options={settingsOptions}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            iconSize="small"
            iconType={<MoreVert />}
          />
        </div>
        <ContentsWrapper>
          {isQueriesInProgress && (
            <CircularProgressWrap>
              <CircularProgressStyled />
            </CircularProgressWrap>
          )}
          {localPhotos && (
            <UploadGallery
              mediaType="фото"
              mediaLimit={15}
              mediaRequirement={PhotoRequirements}
              addDisabled={mediaLimited || disabled}
              withMainElement={true}
              localMedia={localPhotos}
              supportedFileExtension={PICTURE_SUPPORTED_FILE_EXTENSION}
              onUploadFile={onFileUpload('photos')}
              onFileValidate={onFileValidate('photos')}
              urlUploadingExist={true}
              onDragChange={onDragChange}>
              {localPhotos?.map((item, index) => (
                <PhotoItem
                  index={index}
                  openGallery={() => {handleOpenGallery(item)}}
                  item={item}
                  productId={productId}
                  onFileDelete={handleDelete}
                  disabled={disabled}
                />
              ))}
            </UploadGallery>
          )}
        </ContentsWrapper>
      </div>
    )
  }
)
