import { gql } from '@apollo/client'
import { Filters } from 'shared/lib/generated/goods/Api'

export const getFiltersList = (filtersConfig?: Filters) => gql(`
  query MyQuery(
    $id: Int = null
    $searchString: String = null
    $season: [String!] = []
    $excludeInArchive: Boolean = false
    $category: [String!] = []
    $goodsType: [GoodsType!] = null
    $contragentId: [Int!] = []
    $goodsBrandId: [Int!] = []
    ) {
    goods(
      id: $id,
      searchString: $searchString,
      season: $season,
      excludeInArchive: $excludeInArchive,
      category: $category,
      goodsType: $goodsType,
      contragentId: $contragentId,
      goodsBrandId: $goodsBrandId
      ) {
      filters {
      ${filtersConfig ? Object.keys(filtersConfig).map(el => {
    if (filtersConfig[el] === true) {
      return `${el} { value label count }`
    }
    return ''
  }) : `
        category {
          label
          value
          count
        }
        contragentId {
          label
          value
          count
        }
        goodsBrandId {
          label
          value
          count
        }
        goodsType {
          label
          value
          count
        }
        season {
          label
          value
          count
        }
      `
}
      }
    }
  }
  `
)
