import { Table } from '@devexpress/dx-react-grid-material-ui'
import { ArrowForwardIos, Delete, Edit } from '@mui/icons-material'
import styled from 'styled-components'

export const DeleteIcon = styled(Delete)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 24px;
  margin-top: 2px;
`

export const OpenModalIcon = styled(ArrowForwardIos)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 12px;
  margin-top: 2px;
`

export const EditInModalIcon = styled(Edit)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  font-size: 24px;
  margin-top: 2px;
`

export const StyledTableCell = styled(Table.Cell)<{ $isArchived?: boolean }>`
  height: 52px;
  padding: 0 8px 0 8px;
  color:  ${({ $isArchived }) => $isArchived ? '#a4a4a4' : '' };
`