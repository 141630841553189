import React, { useMemo, useState, useEffect, useCallback } from 'react'
import {
  DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent, Checkbox, FormControlLabel, RadioGroup, Radio,
} from '@mui/material'
import { Modal } from 'shared/ui/components'
import { useParams } from 'react-router-dom'
import CloseIcon from 'assets/images/closeIcon.svg'
import { useMarketplaceList, useCompanyList, useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'

import { DialogTitleUI, IconCloseButton } from './common'

import { getModalFormMarketplace } from '../../lib/format/getModalFormMarketplace'
import { PRODUCT_STATUS } from '../../config/productStatus'
import { ModalFormProductCopy } from '../ModalFormProductCopy/ModalFormProductCopy'

interface ProductCreateModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const MODAL_STYLES = { style: { width: '700px', maxWidth: '700px' } }

export const ProductCreateModal = ({
  isOpen,
  setIsOpen,
}: ProductCreateModalProps) => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)

  const [companyId, setCompanyId] = useState<number>()
  const [marketplaceId, setMarketplaceId] = useState<number>()
  const [companymarketplaceId, setCompanymarketplaceId] = useState<number>()
  const [useGoodsMedia, setUseGoodsMedia] = useState<boolean>(false)
  const [productStatus, setProductStatus] = useState(PRODUCT_STATUS[0].value)

  const { marketplaceListQuery } = useMarketplaceList()
  const { companyListQuery } = useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(marketplaceId || null, companyId || null)


  const handleCompanyChange = (event: SelectChangeEvent<any>) => {
    setCompanyId(event.target.value)
    setCompanymarketplaceId(undefined)
  }
  const handleMarketplaceChange = (event: SelectChangeEvent<any>) => {
    setMarketplaceId(event.target.value)
    setCompanymarketplaceId(undefined)
  }

  const handleCompanyMarketplaceChange = (event: SelectChangeEvent<any>) => {
    setCompanymarketplaceId(event.target.value)
  }

  const handleUseGoodsMediaChange  = (event: React.ChangeEvent<any>) => {
    setUseGoodsMedia(event.target.checked)
  }

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const ModalFormMarketplace = useMemo(
    () => (companyId && marketplaceId && companymarketplaceId && getModalFormMarketplace(marketplaceId)) || null,
    [companyId, marketplaceId, companymarketplaceId]
  )

  useEffect(() => {
    if (companyMarketplaceListQuery.data !== undefined) {
      if (companyMarketplaceListQuery.data.length === 1) {
        setCompanymarketplaceId(companyMarketplaceListQuery.data[0].id)
      }
    }
  },[companyMarketplaceListQuery.data])

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      paperProps={MODAL_STYLES}
      disableBackdropClick={true}
    >
      <>
        <IconCloseButton aria-label="close" onClick={handleClose}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <>
          <DialogTitleUI>Создание продукта</DialogTitleUI>
          <RadioGroup
            sx={{ paddingLeft: '24px', display: 'flex', gap: '10px' }}
            row={true}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={productStatus}
            onChange={(event, value) => { setProductStatus(value) }}
          >
            { PRODUCT_STATUS.map(el => (
              <FormControlLabel key={el.value} value={el.value} control={<Radio size="small"/>} label={el.label}/>
            )) }
          </RadioGroup>
          <DialogContent sx={{ paddingBottom: '24px' }}>
            <Grid>
              <Grid container={true} spacing={2} alignItems="center">
                <Grid item={true} xs={6}>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel id="attributes">Маркетплейс</InputLabel>
                    <Select
                      label="Маркетплейс"
                      onChange={handleMarketplaceChange}
                      value={marketplaceId}
                    >
                      {marketplaceListQuery?.data?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel id="attributes">Продавец</InputLabel>
                    <Select
                      label="Продавец"
                      onChange={handleCompanyChange}
                      value={companyId}
                    >
                      {companyListQuery?.data?.map((item) => (
                        <MenuItem key={item.name} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel id="attributes">Кабинет</InputLabel>
                    <Select
                      label="Кабинет"
                      disabled={!companyId}
                      onChange={handleCompanyMarketplaceChange}
                      value={companymarketplaceId ?? ''}
                    >
                      {companyMarketplaceListQuery?.data?.map((item) =>  (
                        <MenuItem key={item.id} value={item.id.toString()}>
                          {item.name}
                          {}
                        </MenuItem>
                      ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={useGoodsMedia}
                        onChange={handleUseGoodsMediaChange}
                        size="small"
                        style={{ height: '15px' }}
                      />
                    }
                    label="Использовать медиа товара"
                  />
                </Grid>
              </Grid>
            </Grid>
            {(productStatus === PRODUCT_STATUS[0].value &&  !companyMarketplaceListQuery.isLoading && ModalFormMarketplace) && (
              <ModalFormMarketplace
                handleClose={handleClose}
                goodsId={goodsId}
                companymarketplaceId={companymarketplaceId!}
                useGoodsMedia={useGoodsMedia}
              />)}
            { (productStatus === PRODUCT_STATUS[1].value && companymarketplaceId) &&
            <ModalFormProductCopy companymarketplaceId={companymarketplaceId!} goodsId={goodsId} handleClose={handleClose}/>
            }
          </DialogContent>
        </>
      </>
    </Modal>
  )
}
