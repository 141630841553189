import React, { FC, ReactElement } from 'react'
import { CreateSharp as EditIcon } from '@mui/icons-material'
import styled from 'styled-components'
import { useField } from 'react-final-form'

const StyledEditIcon = styled(EditIcon)`
  fill: rgba(0, 0, 0, 0.38);
  width: 18px;
  height: 18px;
  pointer-events: none;
  &:hover {
    fill: #000;
  }
`
const MAX_WIDTH: number = 500
const WORD_WIDTH: number = 8

interface ExitableTextFieldWrapperProps {
  children: ReactElement
  disabled?: boolean
}

const EditableTextFieldWrapper: FC<ExitableTextFieldWrapperProps> = ({ children, disabled }) => {
  const fieldName = useField(children.props.name)
  const inputValue =
    fieldName.input.value === typeof 'object' ? '' : fieldName.input.value
  const width = Math.min(inputValue.length * WORD_WIDTH, MAX_WIDTH)

  return (
    <>
      {React.cloneElement(children, {
        width: `${width}px`,
      })}
      {!disabled && <StyledEditIcon />}
    </>
  )
}

export default EditableTextFieldWrapper
