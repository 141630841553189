import { Box } from '@mui/material'
import { customerApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'
import { useQueryClient } from 'react-query'
import { snackActions } from 'shared/lib/react/snackbar'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { customerGen } from 'shared/lib/generated'
import { genReactQueryCacheAdd, genReactQueryCacheDelete, genReactQueryCacheUpdateByKey } from 'shared/lib/genReactQuery'
import { sort } from 'shared/lib/utils/sort'
import { useMemo } from 'react'

import { PrivilegeModal } from './ui/modals/PrivilegeModal'
import { PrivilegesDictTable } from './ui/PrivilegesDictTable'
import { expandPrivileges } from './lib/expandPrivileges'

import { StyledLoader } from '../Brands/ui/styled'

export const PrivilegesDict = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, isFetching } =
    customerGen.administration.GetPrivileges.useGetPrivileges({ query: { customer: true } })

  const { mutate: deletePrivilege } =
    customerGen.administration.DeletePrivilege.useDeletePrivilege()

  const { mutate: editPrivilege } = customerGen.administration.ChangePrivilege.useChangePrivilege()

  const { mutate: createPrivilege } =
    customerGen.administration.CreatePrivilege.useCreatePrivilege()

  const sortedData = useMemo(() => {
    if (data) {
      return sort(expandPrivileges(data), 'globalPrivilege')
    }
    return null
  }, [data])

  const handleCellChange = (privilege) => {
    if (sortedData && privilege) {
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <PrivilegeModal
            close={close}
            accept={accept}
            changedCell={privilege}
          />
        ),
        onAccept: (newPrivilegeData) => {
          editPrivilege(
            {
              privilegeId: newPrivilegeData.id,
              data: newPrivilegeData,
            },
            {
              onSuccess: genReactQueryCacheUpdateByKey({
                queryClient,
                queryCacheKey: customerGen.administration.GetPrivileges.getQueryKey({ query: { customer: true } }),
                onSuccess: () => {
                  snackActions.info('Привилегия изменена')
                },
              }),
            }
          )
        },
      })
    }
  }

  const handleAddPrivilege = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <PrivilegeModal close={close} accept={accept} />
      ),
      onAccept: (createdPrivilege: customerApi.NewPrivilege) => {
        createPrivilege(
          {
            data: {
              name: createdPrivilege.name,
              code: createdPrivilege.code,
              description: createdPrivilege.description,
            },
          },
          {
            onSuccess: genReactQueryCacheAdd({
              queryClient,
              queryCacheKey: customerGen.administration.GetPrivileges.getQueryKey(({ query: { customer: true } })),
              onSuccess: () => {
                snackActions.info('Привилегия добавлена')
              },
            }),
          }
        )
      },
    })
  }

  const handleRowDelete = (id) => {
    if (id && sortedData) {
      const deletedPrivilege = sortedData.filter(
        (privilege) => privilege.id === id
      )[0]
      dialogModel.openDialog({
        component: ({ close, accept }) => (
          <ModalContent
            close={close}
            accept={accept}
            title="Удалить привилегию?"
            acceptBtnText="УДАЛИТЬ"
            acceptBtnColor="error"
            variant="text"
          />
        ),
        onAccept: () => {
          deletePrivilege(
            {
              privilegeId: id,
              data: {
                id: deletedPrivilege.id,
                versionNo: deletedPrivilege.versionNo,
                comments: '',
              },
            },
            {
              onSuccess: genReactQueryCacheDelete({
                queryClient,
                queryCacheKey:
                  customerGen.administration.GetPrivileges.getQueryKey(({ query: { customer: true } })),
                deleteItem: deletedPrivilege,
                onSuccess: () => {
                  snackActions.info('Привилегия удалена')
                },
              }),
            }
          )
        },
      })
    }
  }

  return (
    <Box px={3} pb={3}>
      {(isFetching || isLoading) && <StyledLoader size={60} />}
      {sortedData && (
        <PrivilegesDictTable
          privilegesList={sortedData}
          handleAddPrivilege={handleAddPrivilege}
          handleCellChange={handleCellChange}
          handleRowDelete={handleRowDelete}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}
