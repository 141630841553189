import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledTableBox = styled(Box)`
  position: relative;
  overflow: hidden;

  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%) !important;

  .MuiTableRow-head {
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .MuiTableCell-root {
    height: 36px;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`