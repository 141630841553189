import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'

import { OrderInfoItem, OrderInfoTitleText } from './styled'

export const InfoText = ({ title, text, type }: { title: string, text?: string | number, type?: string}) => {
  if(!text) return null
  if (type === 'date') {
    return <OrderInfoItem>{`${title}: ${format(
      new Date(text), DateFormats.dayMonthYearHoursMinutes
    )}`}</OrderInfoItem>
  }
  return <OrderInfoItem>{`${title}: ${text}`}</OrderInfoItem>
}


export const TitleInfoText = ({ title, text, type }: { title: string, text?: string | number, type?: string}) => {
  if(!text) return null
  if (type === 'date') {
    return <OrderInfoTitleText>{`${title}: ${format(
      new Date(text),
      DateFormats.dayMonthYear
    )}`}</OrderInfoTitleText>
  }
  return <OrderInfoTitleText>{`${title}: ${text}`}</OrderInfoTitleText>
}