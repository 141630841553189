import { pimApi } from 'shared/api'
import { isNil } from 'shared/lib/checkers/isNil'

import { serializeSizeInfoToEditSizeInfo } from './serializeSizeInfoToEditSizeInfo'
import { serializeWBCardAttributeToEditWBCardAttribute } from './serializeWBCardAttributeToEditWBCardAttribute'

const serializeWBCardVariations = (
  variations?: Array<pimApi.products.wb.WBVariation>, ignorePIMOnlyAttributes : boolean = false
) => variations?.map(({ 
  barcodes,
  mainBarcodeId,
  changed,
  versionNo,
  ...rest
}) => {
  let localRest
  if (ignorePIMOnlyAttributes) {
    const { autoOrdering, syncEnabled,...a } = rest
    localRest = a
  } else {
    localRest = rest
  }
  return localRest
})

export const FormValuesToEditWBCardComparatorSerializer = ({
  // поля, которые не нужны
  photos,
  video,
  variations,
  futurePrices,
  priceRecentHistory,
  changed,
  price,
  discount,
  // конец полей, которые не нужны
  ...formValues
}: pimApi.products.wb.WBNomenclature, ignorePIMOnlyAttributes : boolean = false) => {
  let localFormValues
  if (ignorePIMOnlyAttributes) {
    const { packagingrequirementId, newBox,...a } = formValues
    localFormValues = a
  } else {
    localFormValues = formValues
  }
  return {
    ...localFormValues,
    attributes: formValues.attributes?.map(
      serializeWBCardAttributeToEditWBCardAttribute
    ),
    width: isNil(formValues.width)
      ? formValues.width
      : serializeSizeInfoToEditSizeInfo(formValues.width),
    height: isNil(formValues.height)
      ? formValues.height
      : serializeSizeInfoToEditSizeInfo(formValues.height),
    depth: isNil(formValues.depth)
      ? formValues.depth
      : serializeSizeInfoToEditSizeInfo(formValues.depth),
    weight: isNil(formValues.weight)
      ? formValues.weight
      : serializeSizeInfoToEditSizeInfo(formValues.weight),
    colors: formValues.colors,
    variations: serializeWBCardVariations(variations, ignorePIMOnlyAttributes),
  }
}
