import { StyledPlugContainer } from 'shared/ui/components/PlugWithAddButton/styled'

export const DistributionsPlug = () => (
  <StyledPlugContainer height={window.innerHeight - 80}>
    <span className="title">
      Склады не выбраны
    </span>
    <span id="subTitle-styled" className="subTitle" style={{ color: '#00000099' }}>
      {
        'Чтобы начать распределение продуктов по РЦ, \nлогистам нужно выбрать склады'
      }
    </span>
  </StyledPlugContainer>
)