import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'


const downloadGoodsChangeSuppliersTemplateFile = async (queryParams: { contragentId: number | null }) => (
  api.get<File>('/pim/ops/goods/change/suppliers', {
    responseType: 'blob',
    params: { contragent_id: queryParams.contragentId }
  })
)

const uploadGoodsChangeSuppliersFile = async (queryParams: { file: File | null }) => {
  const body = new FormData()
  body.append('file', queryParams.file || '')

  return api.post(
    '/pim/ops/goods/change/suppliers',
    body
  )
}

export const useDownloadGoodsChangeSuppliersTemplateFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, { contragentId: number | null }>
) => useMutation(
  downloadGoodsChangeSuppliersTemplateFile,
  options
)

export const useUploadGoodsChangeSuppliersFile = (
  options?: UseMutationOptions<AxiosResponse,errorType, { file: File }>
) => useMutation(
  uploadGoodsChangeSuppliersFile,
  options,
)
