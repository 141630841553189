import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { DialogTitleWithBtn, Image as SharedImage } from 'shared/ui/components'
import { isNil } from 'shared/lib/checkers'

import { StyledMediaGalleryModal } from './styled'
import { MediaVariationsComponent, TMediaVariation } from './MediaVariations'

interface IMediaGalleryModal {
  close: () => void
  clickedItem: any
  mediaVariation: TMediaVariation
  getFilePath?: any
  items: Array<any>
  mediaType:
    'Фото' |
    'Видео' |
    'Изображение 360' |
    'Образец цвета' |
    'Видеообложка' |
    'Документы'
}

export const MediaGalleryModal = ({ clickedItem, items, getFilePath, close, mediaType, mediaVariation }: IMediaGalleryModal) => {
  const [selectedMedia, setSelectedMedia] = useState(clickedItem)
  const fileThumbnailSize = { maxHeight: 350, maxWidth: 350 }

  const MediaComponent = MediaVariationsComponent[mediaVariation]

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'ArrowRight') {
        setSelectedMedia(prev => {
          const mediaItemIndex = items.findIndex(el => el.id === prev.id)
          if (isNil(items[mediaItemIndex + 1])) {
            return items[0]
          }
          return items[mediaItemIndex + 1]
        })
      }
      if (event.key === 'ArrowLeft') {
        setSelectedMedia(prev => {
          const mediaItemIndex = items.findIndex(el => el.id === prev.id)
          if (isNil(items[mediaItemIndex - 1])) {
            return items[items.length - 1]
          }
          return items[mediaItemIndex - 1]
        })
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  return (
    <StyledMediaGalleryModal>
      <DialogTitleWithBtn onClose={close} sx={{ fontSize: '20px', padding: '0px' }}>
        {mediaType}
      </DialogTitleWithBtn>

      <MediaComponent
        getFilePath={getFilePath}
        selectedMedia={selectedMedia}
        fileThumbnailSize={fileThumbnailSize}
      />

      <Tabs
        className="mediaTabs"
        variant="scrollable"
        scrollButtons="auto"
        onChange={ (event, value) => {
          if (event.type === 'click') {
            setSelectedMedia(value)
          }
        } }
        selectionFollowsFocus={true}
        value={selectedMedia}>
        {items.map(el => {
          const imgPath = () => {
            if (el.url) {
              return el.url
            }
            if (el.fileName.includes('youtube.com')) {
              const youtubeUrl = new URL(el.fileName)
              return `https://img.youtube.com/vi/${ youtubeUrl.searchParams.get('v') }/hqdefault.jpg`
            }
            return getFilePath(el.id, fileThumbnailSize)

          }
          return (
            <Tab
              key={JSON.stringify(el)}
              value={ el }
              disableRipple={true}
              label={ el.fileName && (el.fileName?.includes('youtube.com') && (el.downloadUrl === ' ' || el.downloadUrl === '')) ?
                <img src={ imgPath() } alt=""/>
                :
                <SharedImage
                  src={ imgPath() }
                />
              }
            />
          )
        })}
      </Tabs>
    </StyledMediaGalleryModal>
  )
}