import { CamelCasedPropertiesDeep } from 'type-fest'
import {
  GoodsSupplierPackaging as GoodsSupplierPackagingSnake,
  NewGoodsSupplierPackaging as NewGoodsSupplierPackagingSnake,
  EditGoodsSupplierPackaging as EditGoodsSupplierPackagingSnake,
} from 'shared/lib/generated/pim/Api'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

export type GoodsSupplierPackaging = CamelCasedPropertiesDeep<GoodsSupplierPackagingSnake>
export type NewGoodsSupplierPackaging = CamelCasedPropertiesDeep<NewGoodsSupplierPackagingSnake>
export type EditGoodsSupplierPackaging = CamelCasedPropertiesDeep<EditGoodsSupplierPackagingSnake>

export function createGoodsPackaging(
  productId: UniqueId,
  supplierId: UniqueId,
  packaging: NewGoodsSupplierPackaging
): Promise<AxiosResponse<GoodsSupplierPackaging>> {
  return api.post<GoodsSupplierPackaging>(
    `/pim/goods/${productId}/suppliers/${supplierId}/packaging`,
    packaging
  )
}

export function updateGoodsPackaging(
  productId: UniqueId,
  supplierId: UniqueId,
  packaging: EditGoodsSupplierPackaging
): Promise<AxiosResponse<GoodsSupplierPackaging>> {
  return api.put<GoodsSupplierPackaging>(
    `/pim/goods/${productId}/suppliers/${supplierId}/packaging/${packaging.id}`,
    packaging
  )
}

export function deleteGoodsPackaging(
  productId: UniqueId,
  supplierId: UniqueId,
  packaging: DeletingObject
): Promise<AxiosResponse<void>> {
  return api.delete<void>(
    `/pim/goods/${productId}/suppliers/${supplierId}/packaging/${packaging.id}`,
    { data: packaging }
  )
}
