import React, { PropsWithChildren } from 'react'

import { Chart } from './Chart'
import { StyledChartContainer } from './styled'

import { ChartParams } from '../../components/ChartParams'

interface Props {
  title: string
  setDataParams: (e) => void
  dataParams: any
  isLoading: boolean
  chartUrl: string
  chartData: any
}
export const LargeSizeWidget = ({ 
  title,
  dataParams,
  chartUrl,
  isLoading,
  setDataParams,
  children,
  chartData
}: PropsWithChildren<Props>) => (
  <StyledChartContainer>
    <div id="data-params-box">
      <span>{title}</span>
      <ChartParams chartUrl={chartUrl} dataParams={dataParams} setDataParams={setDataParams}/>
    </div>
    <div>
      {children}
    </div>
    <Chart dataParams={dataParams} isLoading={isLoading} chartData={chartData}/>
  </StyledChartContainer>
)