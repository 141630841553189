import { useDispatch, useSelector } from 'react-redux'
import { tnvedSelector } from 'store/tnved/selectors'
import { tnvedList, tnvedListClear } from 'store/tnved'
import usePending from 'shared/lib/hooks/PendingHook'
import { FieldRenderProps } from 'react-final-form'
import { Option } from 'shared/ui/components/interface'
import { isNil } from 'shared/lib/checkers/isNil'
import { FC, useCallback, useMemo } from 'react'

import { TnvedSelectComponent } from './TnvedSelectComponent'


interface TN_VED_VALUE {
  code: string
  name: string
}

interface TN_VED_VALUE_EMPTY {
  code: null
  name?: null
}

export interface Props extends FieldRenderProps<TN_VED_VALUE_EMPTY | TN_VED_VALUE> {
  label?: string
}

const getOptionFromTnved = ({ name, code }: TN_VED_VALUE): Option => ({ label: name, value: code })
const getTnvedFromOption = (option: Option): TN_VED_VALUE => ({ name: option?.label, code: option?.value })

export const TnvedSelect: FC<Props> = ({
  input: {
    value: inputValue,
    onChange,
    onBlur,
    onFocus
  },
  meta,
  placeholder,
  disabled
}) => {
  const [getTnvedList, isListLoading] = usePending(tnvedList)
  const dispatch = useDispatch()
  const { list }  = useSelector(tnvedSelector)

  const handleChange = (option: Option | null) => {
    if (isNil(option)) {
      onChange(option)
    }

    const tnved = getTnvedFromOption(option as Option)

    onChange(tnved)
  }

  const value = useMemo(() => inputValue.name ?
    getOptionFromTnved(inputValue)
    : null, [inputValue.name]) 

  const onCloseList = useCallback(() => {
    dispatch(tnvedListClear)
  }, [])


  const options: Array<Option> = list ?
    list.map(getOptionFromTnved) :
    [] as Array<Option>

  return (
    <TnvedSelectComponent
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onSearch={getTnvedList}
      options={options}
      loading={isListLoading}
      placeholder={placeholder}
      onCloseList={onCloseList}
      error={meta.invalid && meta.touched}
      disabled={disabled}
    />
  )
}
