/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const PhotoRequirements = () => {
  const Text = 'Формат — JPEG, PNG, WEBP. Размер — до 8 Мбайт'
  const Element = (<StyledTextContainer>
    <ul>
      <li>формат — JPG, PNG, WEBP</li>
      <li>разрешение — от 700 до 1600 px по большей стороне (без учета белых полей)</li>
      <li>размер — до 8 Мбайт</li>
    </ul>
  </StyledTextContainer>
  )

  return { Element, Text }
}