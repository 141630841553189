import React, { memo, useEffect, useMemo, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { getFileSrc } from 'widgets/Media/DragDropWidget/lib/getFileSrc'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { CircularProgress } from '@mui/material'
import { isNotNil } from 'shared/lib/checkers'

import { IValidateMediaItem } from '../../lib/types'

export const ValidateMediaItem = memo(({
  file,
  deleteFile,
  index,
  mediaLimit,
  existingMediaCount,
  onFileValidate,
  itemIndex,
}: IValidateMediaItem) => {
  const error = useMemo(() => {
    const mediaReached = mediaLimit ? existingMediaCount + (index + 1) > mediaLimit : undefined
    if (mediaReached) {
      return `Количество медиа не должно быть больше ${mediaLimit}`
    } return undefined
  },[index])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  
  useEffect(() => {
    const spanElement = document.getElementById(`errorText${itemIndex}`)
    const element = document.getElementById(`validationErrorContainer${itemIndex}`)

    if (element) {
      element.style.visibility = 'hidden'
    }
    if (spanElement) {
      spanElement.innerText = ''
    }
    if (isNotNil(error)) {
      setIsLoading(false)
      if (element) {
        element.style.visibility = 'visible'
      }
      if (spanElement) {
        spanElement.innerText = error
      }
    } else if (onFileValidate) {
      onFileValidate(file, 
        (res) => {
          setIsLoading(false)
          if (res.data !== '') {
            if (element) {
              element.style.visibility = 'visible'
            }
            if (spanElement) {
              spanElement.innerText = res.data
            }
          }
        },
      )
    }
  },[error])


  return (
    <div className="mediaItem">
      { isLoading ?
        <CircularProgress className="progressBar"/>
        :
        <>
          { typeof file === 'object' && file?.type?.includes('video') ?
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video title={ file?.name } src={ getFileSrc(file) }>
              <source src={ getFileSrc(file) } type="video/mp4"/>
            </video>
            :
            <img title={ typeof file === 'string' ? file : file?.name } src={ getFileSrc(file) } alt=""/>
          }
        </>
      }
      <div className="validationErrorContainer" id={`validationErrorContainer${itemIndex}`}>
        <WarningAmberIcon />
        <div className="errorTooltip">
          <span id={`errorText${itemIndex}`}/>
        </div>
      </div>
      <div className="deleteMediaItem">
        <CancelIcon onClick={() => deleteFile({ id: itemIndex })}/>
      </div>
    </div>
  )
}
)