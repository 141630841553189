import { GOODS_EDIT, PRODUCT_EDIT } from 'shared/config/routes'
import { Box, Button, IconButton } from '@mui/material'
import { Image } from 'shared/ui/components'
import { generatePath } from 'react-router'
import { MaskedInputField, Field } from 'shared/ui/components/form'
import { Delete } from '@mui/icons-material'
import React, { memo } from 'react'
import { MemoIcon } from 'entities/pim/goods/ui'
import { Link } from 'react-router-dom'
import { pimApi } from 'shared/api'
import imagePlaceholder from 'assets/images/defaultGoodImage.svg'
import { PlaceholderWrapper } from 'shared/lib/hooks/withImage/styled'

import {
  ResultItem,
  ProductType,
  ProductTitle,
  ProductAuxCode,
  ProductImage,
  ProductInfo,
  BorderedInfo,
  ComponentInfo,
} from './styled'

const INFO_PLACEHOLDER = '-'

interface ComponentCardProps {
  quantityFieldName?: string
  onRemoveClick?: () => void
  onAddClick?: () => void
  onChange?: () => void
  imgUrl?: string
  goodsType?: pimApi.goods.GoodsType
  componentId?: UniqueId
  name?: string
  code?: string
  auxCode?: string
  unitPartible?: boolean
  productId?: UniqueId
  unitCode?: string
  marketplaceUrl?: string
  marketplaceCode?: string
  cabinetName?: string
  barcode?: string
  price?: number
  marketplaceCategory?: string
  withRemove?: boolean
  disabled?: boolean
  companyName?: string
  autoOrdering?: boolean
}

export const ComponentCard = ({
  onRemoveClick,
  onAddClick,
  onChange,
  quantityFieldName,
  imgUrl,
  goodsType,
  componentId,
  name,
  code,
  auxCode,
  unitPartible,
  productId,
  unitCode,
  marketplaceUrl,
  marketplaceCode,
  cabinetName,
  barcode,
  price,
  marketplaceCategory,
  withRemove = true,
  disabled,
  companyName,
}: ComponentCardProps) => (
  <ResultItem $isArchived={disabled}>
    <ProductImage $isArchived={disabled}>
      {imgUrl ? (
        <Image src={imgUrl} />
      ) : (
        <PlaceholderWrapper>
          <img src={imagePlaceholder} alt="placeholder" />
        </PlaceholderWrapper>
      )}
      {goodsType !== 'common' && (
        <ProductType>
          <MemoIcon goodsType={goodsType} />
        </ProductType>
      )}
    </ProductImage>
    <ComponentInfo>
      <ProductTitle $isArchived={disabled}>
        {componentId && (
          <Link
            to={generatePath(GOODS_EDIT, { id: componentId, step: 'info' })}
          >
            {name}
          </Link>
        )}
        {productId && (
          <Link
            to={generatePath(generatePath(PRODUCT_EDIT, { id: productId }))}
          >
            {name}
          </Link>
        )}
      </ProductTitle>
      {code && (
        <ProductAuxCode>
          Артикул: {code}
          {auxCode && `/ Доп. артикул: ${auxCode}`}
        </ProductAuxCode>
      )}
      {companyName && (
        <Box display="flex">
          <BorderedInfo>{cabinetName}</BorderedInfo>
          <BorderedInfo $isArchived={disabled}>
            <span>{companyName}</span>
          </BorderedInfo>
          <BorderedInfo $isArchived={disabled}>
            <span>Номенклатура:</span>
            {marketplaceUrl ? (
              <a href={marketplaceUrl} target="_blank" rel="noreferrer" >
                {marketplaceCode}
              </a>
            ) : (
              marketplaceCode || INFO_PLACEHOLDER
            )}
          </BorderedInfo>
          <BorderedInfo>Штрих-код: {barcode || INFO_PLACEHOLDER}</BorderedInfo>
        </Box>
      )}
      {price && marketplaceCategory && (
        <Box display="flex">
          <BorderedInfo>Цена, р.: {price || INFO_PLACEHOLDER}</BorderedInfo>
          <ProductInfo>
            Категория: {marketplaceCategory || INFO_PLACEHOLDER}
          </ProductInfo>
        </Box>
      )}
    </ComponentInfo>
    <Box display="inline-flex" alignItems="center" pl={2}>
      {quantityFieldName && (
        <div style={{ width: '55px' }}>
          <Field
            name={quantityFieldName}
            label={unitCode || 'шт'}
            component={MaskedInputField}
            scale={unitPartible ? 10 : 0}
            disabled={disabled}
            min={0}
            onBlur={onChange}
          />
        </div>
      )}
      <Box display="inline-flex" pl={1} pr={1}>
        {onRemoveClick && withRemove && (
          <IconButton size="small" 
            sx={{ 
              marginRight: '14px',
              marginLeft: '10px',
              color: 'rgba(0, 0, 0, 0.26)'
            }} disabled={disabled} onClick={onRemoveClick}>
            <Delete/>
          </IconButton>
        )}
        {onAddClick && (
          <Box pr={1.5} onClick={onAddClick}>
            <Button variant="contained" disabled={disabled} >ДОБАВИТЬ</Button>
          </Box>
        )}
      </Box>
    </Box>
  </ResultItem>
)

export const MemoComponentCard = memo(ComponentCard)
