import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'
import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'


interface editOzonProductPayload {
  productId: UniqueId,
  editedOzonCard: pimApi.products.EditOzonCard
}

const editOzonProduct = ({ productId, editedOzonCard } : editOzonProductPayload) =>
  api.put<pimApi.products.ozon.OzonProduct>(`/pim/products/ozon/product/${productId}`, editedOzonCard)
    .then((res) => res.data)

export const useEditOzonProductMutation =
  (options?: UseMutationOptions<pimApi.products.ozon.OzonProduct,AxiosError<errorType>,editOzonProductPayload>) =>
    useMutation<pimApi.products.ozon.OzonProduct,AxiosError<errorType>,editOzonProductPayload>
    ((editOzonProductPayload)=>editOzonProduct(editOzonProductPayload), options)
