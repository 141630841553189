
import styled from 'styled-components'
import { Chip } from '@mui/material'

const ChipStyled = styled(Chip)`
height: 32px;
padding: 4px;
padding-left: 10px;
margin: ${({ theme }) => theme.spacing(0.5)};
background-color: #E9F4FE;

.MuiChip-label {
  padding: 0;
  margin-right: 6px;
}

.MuiChip-deleteIcon {
  margin: 0;
}
`

const DivChipsWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  max-width: 100%;
`


export const renderSelectMultipleChips = (selected: unknown) => (
  <DivChipsWrapperStyled>
    {(selected as Array<string>).map((chipValue) => (
      <ChipStyled
        key={chipValue}
        label={chipValue}
      />
    ))}
  </DivChipsWrapperStyled>
)
