import { memo } from 'react'
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table as DevExpressTable,
  TableHeaderRow,
  DragDropProvider,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui'
import { pimApi } from 'shared/api'
import { tableHeaderRowLocale, tableLocale } from 'shared/ui/components/Table/lib'
import { SearchResultType } from 'shared/api/goods/models'

import { columnsConfig } from './columnsConfig'
import {
  TableCellComponent,
  TableComponent,
  TableRowComponent,
  GridRootComponent,
} from './components'

import { TableParams } from '../../lib/types'

export interface Filter {
  dateFrom: string
  dateTo: string
  numberDoc: string
  recipient: string
  status: Array<string>
  supplier: string
}

interface Sort {
  sortBy: string
}

interface Pagination {
  page: string
  limit: string
}

export type SearchObj = Partial<Filter & Sort & Pagination>

interface TableProps {
  tableList: Array<SearchResultType>
  totalCount: number
  calculatedRowsPerPage: number
  tableParams: TableParams
  handleTableParams: (params: Partial<TableParams>) => void
}

const getRowId = (row: pimApi.goods.ChangeLog) => row.changeDate + row.operation
const defaultColumnsOrder = columnsConfig.map((column) => column.columnName)

export const Table = memo(({ tableList }: TableProps) => (
  <Grid
    rows={tableList}
    columns={columnsConfig}
    getRowId={getRowId}
    rootComponent={GridRootComponent}
  >
    <SortingState columnExtensions={columnsConfig}/>
    <IntegratedSorting />

    <DevExpressTable
      columnExtensions={columnsConfig}
      cellComponent={TableCellComponent}
      rowComponent={TableRowComponent}
      messages={tableLocale}
      tableComponent={TableComponent}
    />

    <DragDropProvider />
    <TableColumnReordering defaultOrder={defaultColumnsOrder} />

    <TableHeaderRow
      showSortingControls={true}
      messages={tableHeaderRowLocale}
    />
  </Grid>
))
