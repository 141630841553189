import autoOrderingIcon from 'assets/images/icons/AutoOrderingIcon.svg'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

interface MarketplaceWithIconProps {
  title: string
  isIcon?: boolean
}

export const AutoOrderingIcon = styled.img`
  margin-left: 8px;
  height: 18px;
`
export const Marketplace = styled.div`
  display: flex;
  align-items: center;
`

AutoOrderingIcon.defaultProps = {
  src: autoOrderingIcon,
  alt: 'AutoOrdering'
}


export const MarketplaceWithIcon = ({ title, isIcon }: MarketplaceWithIconProps) => {
  const NoAutoOrder = () => (
    <Tooltip
      title="Автозаказ отсутствует"
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            position: 'relative',
            top: '10px'
          }
        }
      }}
    >
      <AutoOrderingIcon/>
    </Tooltip>
  )
  
  return (

    <Marketplace>
      {title}
      {!isIcon ? <NoAutoOrder/> : <></>}
    </Marketplace>

  )
}