import { InputAdornment, Box, CircularProgress } from '@mui/material'
import { Search } from '@mui/icons-material'
import React, { FC, useState } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { isEmpty, isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { NewComponentCard } from 'entities/pim/goodsComponents'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { InputLabel } from 'shared/ui/components'
import { useField } from 'react-final-form'

import {
  ProductSearchWrapper, SearchInput,
  ResultList
} from './styled'

import { GoodsAlertMessage } from '../../alertMessages'

interface GoodsComponentSearchProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onAddComponent: (newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent) => void
  disabled?: boolean
  inputDisabled?: boolean
}

export const GoodsComponentSearch: FC<GoodsComponentSearchProps> = ({
  goodsComponents,
  onAddComponent,
  disabled,
  inputDisabled
}) => {
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 200)
  const { data, isLoading } = pimApi.goods.useGoodsByCodeQuery(debouncedValue, false)
  const componentsField = useField('packGoods')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleAdd = (goods: pimApi.goods.GoodsShortInfo, quantity: number) => {
    const newComponent: AppPimGoodsModelsGoodsGoodsComponent = {
      componentId: goods.id,
      quantity,
      name: goods.name,
      code: goods.code,
      auxCode: goods.auxCode,
      goodsBaseImageId: goods.goodsBaseImageId,
      goodsType: goods.goodsType,
      unitCode: goods.unitCode,
      unitPartible: goods.unitPartible,
    }

    onAddComponent(newComponent)
  }

  const productListFiltered = data?.data.filter(
    item => !goodsComponents.find(component => component.componentId === item.id)
  ) ?? []

  if(!isEmpty(componentsField.input.value)) return null

  return (
    <ProductSearchWrapper>
      <InputLabel label="Поиск товаров" helpText="Выберите товар по артикулу" required={false} />
      <Box mb="4px">
        <SearchInput
          variant="outlined"
          size="small"
          fullWidth={true}
          autoComplete="off"
          placeholder="Введите артикул товара"
          onChange={handleChange}
          value={value}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {isLoading &&
              <CircularProgress size={20} />}
              <Search/>
            </InputAdornment>,
          }}
        />
      </Box>

      <ResultList>
        <Box height={(isEmptyArray(productListFiltered) && isEmpty(goodsComponents) && value.length > 0) ? '40px' : ''}>
          { (isEmptyArray(productListFiltered) && isEmpty(goodsComponents) && value.length > 0) &&
            <GoodsAlertMessage message="Товар по данному артикулу не найден"/>
          }
        </Box>
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {productListFiltered.map((item) => (
            <NewComponentCard
              key={item.id}
              goods={item}
              onAddClick={handleAdd}
              isAddDisabled={disabled || false}
              isInputDisabled={!!inputDisabled}
            />
          ))}
        </Box>
      </ResultList>
    </ProductSearchWrapper>
  )
}
