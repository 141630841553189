import { DefaultOptions, MutationCache, QueryCache } from 'react-query'

interface QueryClientConfig {
  queryCache?: QueryCache;
  mutationCache?: MutationCache;
  defaultOptions?: DefaultOptions;
}

export const QUERY_CLIENT_OPTIONS: QueryClientConfig = {

  defaultOptions: {
    queries: {
    // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1
    // staleTime: 10000 // 1min
    }
  }
}