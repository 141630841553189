import React from 'react'
import noDifferencePlugIco from 'assets/images/noDifferencePlugIco.svg'
import { Typography } from '@mui/material'
import styled from 'styled-components'

export const NoDifferenceIco = styled.img`
  margin-bottom: 24px;
  height: 160px;
`

NoDifferenceIco.defaultProps = {
  src: noDifferencePlugIco,
  alt: 'no difference'
}

export const PlugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  height: calc(100% - 80px);
  text-align: center;
`

export const Caption = styled(Typography)`
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
`

const NoDifferencePlug = () => (
  <PlugWrapper>
    <NoDifferenceIco />
    <Typography fontSize={16} mb={1}>
      Расхождений нет
    </Typography>
    <Caption>
      Чтобы увидеть все продукты без расхождений,<br/>
      отключите &#171;Только расхождения&#187;
    </Caption>
  </PlugWrapper>
)

export default NoDifferencePlug