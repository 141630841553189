/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditReport, AppAdminModelReportsReport } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<AppAdminModelReportsReport>

export type IRequest = CamelCasedPropertiesDeep<{
  reportId: number,
  data: EditReport
}>

export const apiFunction = ({ reportId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/admin/reports/${reportId}`,data).then(res => res.data)


export const useEditReport = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

