import styled from 'styled-components'
import { CircularProgressStyled as SharedCircularProgress } from 'shared/ui/components/CircularProgress/CircularProgress'

export const Header = styled.div`
  padding: 16px 24px 16px 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  color:${({ theme }) => `${theme.palette.text.primary}`};
`

export const StyledTable = styled.div<{ $isLoading: boolean, $readOnly: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow-x: auto;
  pointer-events: ${({ $isLoading, $readOnly }) => ($isLoading || $readOnly) && 'none'};
  
  >div {
    width: 100%;
    min-width: max-content;
    
    .table-header {
      display: flex;
      flex-direction: row;
      height: 56px;
      width: 100%;
      font-weight: 500;
      box-shadow: 0 -1px 0 0 #E0E0E0 inset, 0 1px 0 0 #E0E0E0 inset;
      background-color: #fbfbfb;

      > div {
        padding-left: 16px;
        font-weight: 500;
        align-content: center;
        width: 80px;

        :nth-child(-n + 2) {
          min-width: 200px;
          max-width: 200px;
        }
      }
    }


    .table-body {
      opacity: ${({ $isLoading }) => $isLoading ? '0.6' : '1'};
      width: 100%;
      display: flex;
      flex-direction: column;

      .warehouse-item-row {
        >div {
          border-bottom: 1px solid #E0E0E0;
          display: flex;
          flex-direction: row;
          
          > div {
            align-content: center;
            width: 100%;

            :nth-child(-n + 2) {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              padding-left: 16px;
              min-width: 200px;
              max-width: 200px;
            } 
          }

        .coefficient-cell-container {
          display: flex;
          flex-direction: column;

          > div {
            display: flex;
            flex-direction: row;
            height: 52px;

              > div {
                color: #F44336;
                cursor: pointer;
                transition: all 0.1s;
                align-content: center;
                padding-left: 16px;
                width: 80px;
  
                :hover {
                  background-color: #FAFAFA;
                }
              }
  
              .box-type-coefficient.selected {
                background-color: #EDF7FE;
              }
  
              .box-type-coefficient.disabled {
                color: #00000061;
                pointer-events: none;
              }
  
              .box-type-coefficient.free {
                color: #4CAF50;
              }
  
              .box-type-coefficient.disabled.selected {
                background-color: #EDF7FE;
                color: #00000061;
              }
  
              .box-type-coefficient.free.selected {
                color: #4CAF50;
                background-color: #EDF7FE;
              }
            }
          }
        }
      }
    }

  }

`

export const CircularProgressStyled = styled(SharedCircularProgress)`
  margin: 0;
  z-index: 3;
  left: 50%;
  top: 300px;
`