import React from 'react'
import { Button } from '@mui/material'
import { generatePath, useHistory, withRouter } from 'react-router'
import * as Sentry from '@sentry/react'
import { HOME } from 'shared/config'

function FallbackRender({ errorData }) {
  const history = useHistory()
  const handleHome = () => {
    errorData.resetError()
    history.push(generatePath(HOME))
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <p>Ошибка страницы, мы уже работаем над ней</p>
      <Button onClick={() => {
        handleHome()
      }}>На главную страницу</Button>
    </div>
  )
}

const ErrorBoundary: React.PropsWithChildren<any> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={(errorData) => <FallbackRender errorData={errorData}/>}>
    {children}
  </Sentry.ErrorBoundary>
)

export default withRouter(ErrorBoundary)