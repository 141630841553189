import React, { useState } from 'react'
import { DateFormats, RuDateFormats } from 'shared/config/dateFormats'
import { DatePicker as MuiDatePicker } from '@mui/lab'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import { TextField } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

const toISOStringWithTimezone = (date: Date) => {
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0')

  return `${date.getFullYear()
  }-${  pad(date.getMonth() + 1)
  }-${  pad(date.getDate())}`
}

const InputLabelProps = { shrink: true }

type SelectProps = FieldRenderProps<string | number>

const FormDatePicker = ({
  input,
  label,
  helperText,
  isError,
  error,
  key,
  sx,
  id,
  input: {
    onFocus,
  },
}: SelectProps) => {

  const [selectedDate, setSelectedDate] = useState(input.value)

  const selectDateChange = (date) => {
    const result = toISOStringWithTimezone(date)
    setSelectedDate(result)
    input.onChange(result)
  }

  const handleDateChange = (date) => {

    if (date && date.toString() !== 'Invalid Date') {
      const result = toISOStringWithTimezone(date)
      setSelectedDate(result)
      input.onChange(result)
    } else {
      setSelectedDate(date)
      input.onChange(date)
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <MuiDatePicker
        value={selectedDate}
        label={label}
        inputFormat={DateFormats.dayMonthYear}
        mask="__.__.____"
        onChange={handleDateChange}
        onAccept={selectDateChange}
        renderInput={(renderProps) => (
          <TextField
            {...renderProps}
            size="small"
            id={id}
            key={key}
            sx={sx}
            onFocus={onFocus}
            autoComplete="off"
            helperText={error && isError ? helperText : ''}
            InputLabelProps={InputLabelProps}
            error={error && isError}
            inputProps={{
              ...renderProps.inputProps,
              placeholder: RuDateFormats.dayMonthYear
            }}
          />
        )} />
    </LocalizationProvider>
  )
}

export default FormDatePicker

