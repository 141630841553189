import { pimApi } from 'shared/api'
import { MediaImageItemMemo } from 'widgets'

interface PhotoItemProps {
  productId: UniqueId
  item: pimApi.products.MediaFile
  openGallery?: any
  onFileDelete: (item: pimApi.products.MediaFile) => void
  index?: number
  disabled?: boolean
}

const fileThumbnailSize = { maxWidth: 180, maxHeight: 180 }

export const PhotoItem = ({ productId, item, onFileDelete, disabled, openGallery }: PhotoItemProps) => {
  const { downloadUrl, id, fileName } = item

  const imgPath = pimApi.products.ozon.getOzonMediaApiPath(productId, id, 'photos360', fileThumbnailSize)
  const handleFileDelete = () => onFileDelete(item)

  return (
    <MediaImageItemMemo
      imgPath={imgPath}
      openGallery={openGallery}
      fileName={fileName}
      onFileDelete={handleFileDelete}
      downloadUrl={downloadUrl}
      disabled={disabled}
    />
  )
}
