import { ICatalog, CatalogReqType, CatalogResType } from 'shared/services/interfaces/catalog'
import { api } from 'shared/api/base'
import { injectable } from 'inversify'

@injectable()
export class Catalog implements ICatalog {
  // eslint-disable-next-line class-methods-use-this
  getCatalog<T>(params: CatalogReqType): CatalogResType<T> {
    const { catalog } = params
    return api.get(`/${catalog}`)
  }
}
