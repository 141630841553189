import { fetchCategories } from 'shared/api/pim/categories/getCategories'
import keyMirror from 'keymirror-nested'
import { categoryType } from 'shared/services/interfaces/category'
import watcherCreator from 'store/watcherCreator'
import { actionsCreator, actionType } from 'store/actionsCreator'
import { call, put } from 'redux-saga/effects'
import { AxiosError } from 'axios'
import { getErrorType } from 'shared/lib/transform/getErrorType'

export interface categoryStateType {
  category: categoryType | null | undefined
  list: categoryType[] | null | undefined
  error: errorType | null | undefined
}

export type categoryActionType = actionType<
  categoryType | categoryType[] | string,
  errorType
>

export const CATEGORY = keyMirror(
  {
    LIST: null,
  },
  '_',
  'CATEGORY'
)

export const categoryList = actionsCreator(CATEGORY.LIST)

export const categorySelector = (state: {
  categoryReducer: categoryStateType
}): categoryStateType => state.categoryReducer

const initialState = {
  category: null,
  list: null,
  error: null,
}

export const categoryReducer = (
  state = initialState,
  action: categoryActionType
): categoryStateType => {
  switch (action.type) {
    case categoryList.getType('success'):
      return { ...state, list: <categoryType[]>action.data }
    case categoryList.getType('error'):
      return { ...state, error: <errorType>action.error }
    default:
      return state
  }
}

export const categoryWatcher = watcherCreator(
  'CATEGORY',
  function* categoryWorker({ type, data }: categoryActionType) {
    if (type === categoryList.getType('pending')) {
      try {
        const context = { fetchCategories }
        const result: SagaCall<typeof fetchCategories> = yield call<
          typeof fetchCategories
        >(context.fetchCategories, data as string)
        yield put(categoryList.success(result.data))
      } catch (error) {
        yield put(categoryList.error(getErrorType(error as AxiosError)))
      }
    }
    return data
  }
)
