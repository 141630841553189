import { storageType, IStorage } from 'shared/services/interfaces/storage'

export type { storageType } from 'shared/services/interfaces/storage'

export class Storage implements IStorage {
  constructor(private _store: storageType) {}

  get store(): storageType {
    return this._store
  }

  set(key: string, value: string, options?: { [key: string]: string }): void {
    this._store.set(key, value, options)
  }

  get(key: string): string | undefined {
    return this._store.get(key)
  }

  remove(key: string, path?: string): void {
    this._store.rm(key, path)
  }

  clearStorage(): void {
    this._store.clear()
  }
}
