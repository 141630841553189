import React from 'react'

import { ProductPriceHistory } from '../../../../../../../api/pim/price'
import { StyledContainer } from '../DateAndUserNameCell/styled'

export const IncomingPriceCell = ({ row }: { row: ProductPriceHistory }) => {
  const { incomingPrice, rrp } = row
  
  return (
    <StyledContainer>
      <span>{incomingPrice}</span>
      <span>{rrp}</span>
    </StyledContainer>
  )
}