import { StyledLinkName } from './styled'

interface LinkNameProps {
  name: string
  path: string
  isArchived?: boolean
  isSingleString?: boolean
}

export const LinkNameCell = ({ name, path, isArchived, isSingleString }: LinkNameProps) => (
  <StyledLinkName
    onClick={ (e) => e.stopPropagation() }
    to={ path }
    title={ name }
    $isArchived={ isArchived }
    $isSingleString={ isSingleString }>
    { name }
  </StyledLinkName>
)
