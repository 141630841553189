import { FC } from 'react'
import { IconButton, SelectChangeEvent } from '@mui/material'
import { Select } from 'shared/ui/components'
import ArrowForwardDotted from 'assets/images/ArrowForwardDotted.svg'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { sort } from 'shared/lib/utils/sort'

import { LeftComponent } from './LeftComponents'

import {
  NormalizedAttributesLeft as NormalizedAttributesLeftType,
  NormalizedAttributesLeftWaitFilled,
  NormalizedAttributesRight
} from '../../lib/getNormalizedAttributes'

interface NormalizedAttributesLeftProps {
  normalizedAttributesLefts: Array<NormalizedAttributesLeftType | NormalizedAttributesLeftWaitFilled>
  normalizedAttributesRights: Array<NormalizedAttributesRight>
  createLeftWaitFilled: (index: number) => () => void
  onSelectMapAttributeChange: (
    index: number,
  ) => (event: SelectChangeEvent) => void
  disabled?: boolean
}

export const NormalizedAttributesLeft: FC<NormalizedAttributesLeftProps> = ({
  normalizedAttributesLefts,
  normalizedAttributesRights,
  createLeftWaitFilled,
  onSelectMapAttributeChange,
  disabled
}) => (
  <>

    {normalizedAttributesLefts.map(({ type, left }, index) => (
      <InputsWrapper
        key={left.name}
        arrow={
          <IconButton aria-label="set" onClick={createLeftWaitFilled(index)} disabled={disabled} size="small">
            <img src={ArrowForwardDotted} alt="close icon"/>
          </IconButton>
        }
      >
        <LeftComponent attribute={left} />

        {type === 'leftWaitFilled' && (
          <Select
            name="unusedProp"
            // @ts-ignore
            onChange={onSelectMapAttributeChange(index)}
            options={sort(normalizedAttributesRights.map(({ right }, rightIndex) => ({
              label: right.attributeName,
              value: `${rightIndex}`
            })), 'label')}
          />
        )}
      </InputsWrapper>
    )
    )}
  </>
)