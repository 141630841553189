// @ts-nocheck
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const StyledTableCell = styled(Table.Cell)`
  height: 52px;
  padding: 0px 8px 0px 8px;
  word-wrap: no-wrap;
`

export const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  white-space: nowrap;
  color: #007DFF;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-overflow: ellipsis;
  max-width: 180px;
  height: 21px;
  text-align: center;
`

interface Props {
  directionUp: boolean;
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'} !important;

  svg {
    font-size: 19px;
    margin-right: 8px;
  }
`

export const StyledIcon = styled.div<Props>`
  color: ${({ directionUp }) => directionUp ? 'green' : 'red'}
`

interface imageProps {
  url: string;
}

export const ChangelogImage = styled.div<imageProps>`
  height: 180px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
  background-position: center;
`

export const ChangelogImageWrapper = styled.div`
  max-width: 100%;
  padding: 10px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 180px;
    width: 180px;
  }
`

export const StyledDateCell = styled.div<{ $isFutureDate?: boolean }>`
  span {
    color: ${({ $isFutureDate }) => $isFutureDate ? '#f980ab' : '' };    
  }
`

export const StyledPrice = styled.span`
  font-size: 14px;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 8px;
`

export const StyledDiscount = styled.span`
  color: #7BC67E;
`

export const StyledFileLink = styled.a`
  display: inline-flex;
  align-self: center;
  svg {
    width: 19px;
    height: 19px;
    color: #bdbdbd;
  }
`

export const StyledIconButton = styled(IconButton)`
  display: inline-flex;
  align-self: center;
  padding: 0;
  margin-left: 8px;
  svg {
    width: 20px;
    height: 22px;
    color: #bdbdbd;
  }
`