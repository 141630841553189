import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'

interface AddUserPhotoPayload {
  userId: UniqueId
  file: FormData | null
}

export function addPhoto({
  userId,
  file,
}: AddUserPhotoPayload) {
  return api.post<pimApi.admin.PhotoUser>(
    `/customer/admin/users/${userId}/photo`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
export const useAddAdminUserPhoto = () =>
  useMutation<
    AxiosResponse<pimApi.admin.PhotoUser>,
    AxiosError<errorType>,
    AddUserPhotoPayload
    >(addPhoto)

interface DeleteUserPhotoPayload {
  userId: UniqueId
}

export function deletePhoto({ userId }: DeleteUserPhotoPayload) {
  return api.delete(
    `/customer/admin/users/${userId}/photo`
  )
}


export const useDeletePhotoUser = () =>
  useMutation<
    AxiosResponse<pimApi.admin.PhotoUser>,
    AxiosError<errorType>,
    DeleteUserPhotoPayload
    >(deletePhoto)