import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosError } from 'axios'

export const getProductCompetitorsQueryKey = (productId: UniqueId) =>
  ['get', 'pim/products/competitors', productId] as const

type GetProductCompetitorsQueryKey = ReturnType<typeof getProductCompetitorsQueryKey>

function getProductCompetitors(productId: UniqueId) {
  return api.get<pimApi.goods.Competitor[]>
  (`/pim/products/${productId}/competitors`).then((res) => res.data)
}


export const useGetProductCompetitorsQuery = (productId: UniqueId) =>
  useQuery<
    pimApi.goods.Competitor[],
    AxiosError<errorType>,
    pimApi.goods.Competitor[],
    GetProductCompetitorsQueryKey
  >(
    getProductCompetitorsQueryKey(productId),
    () => getProductCompetitors(productId),
    { refetchOnMount: false }
  )
