import { Box } from '@mui/material'
import { ModalContent } from 'shared/ui/components/ModalComponents'

interface ConfoirmModalProps {
  close: () => void
  accept: () => void
}

export const ConfirmModal = ({ close, accept }: ConfoirmModalProps) => (
  <Box width="500px">
    <ModalContent
      close={close}
      accept={accept}
      title="Подтвердить удаление товара"
      description="Удалить товар из этой ассортиментной карточки?"
      acceptBtnText="Удалить"
      acceptBtnColor="error"
    />
  </Box>
)
