import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ProductChangeGoodsModal } from 'features/product/ProductChangeGoodsModal'
import { ArchiveModal } from 'features/product/OZON/ArchiveModal'
import { pimApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu } from 'shared/ui/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { MoreVert } from '@mui/icons-material'
import { ProductCopyModal } from 'features/product/OZON/ProductCopyModal/ProductCopyModal'
import { SettingsMenuItem } from 'pages/product/edit/main/model/types'
import { pimGen } from 'shared/lib/generated'
import { TagsType } from 'shared/lib/generated/pim/Api'
import { EditTagsModal } from 'shared/ui/product/ProductsTags/EditTagsModal/EditTagsModal'

export const ProductSettings = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const commonProductQuery = pimApi.products.common.useFetchCommonProductQuery(parseInt(id, 10))
  const ozonProductQuery = pimApi.products.ozon.useFetchOzonProductQuery(parseInt(id, 10))
  const { mutate: unarchiveProductMutation } = pimApi.products.common.useUnarchiveProductMutation()
  const { mutateAsync: addTagMutation } = pimGen.tags.AddTags.useAddTags()
  const { mutateAsync: deleteTagMutation } = pimGen.tags.DeleteTags.useDeleteTags()
  const isArchived = ozonProductQuery.data?.inArchive

  const editTags = async (newList, initialList) => {
    const removedTags = initialList.filter(tag => !newList.map(el => el.toLowerCase()).includes(tag.toLowerCase()))
    const addedTags = newList.filter(tag => !initialList.map(el => el.toLowerCase()).includes(tag.toLowerCase()))


    const addMutations = removedTags.map(tag => deleteTagMutation({
      data: {
        tagsType: 'product' as TagsType, tagsName: tag, objectId: Number(id) } }))
    const removeMutations = addedTags.map(tag => addTagMutation({
      data: { tagsType: 'product' as TagsType, tagsName: tag, objectId: Number(id) } }))

    await Promise.all([...addMutations, ...removeMutations])
  }

  const editTagsHandler = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <EditTagsModal close={close} accept={accept} objectId={Number(id)}/>
      ),
      onAccept: ({ tagsList, objectTagsData }) => {
        editTags(tagsList, objectTagsData).then(() => {
          queryClient.refetchQueries(pimGen.tags.GetObjectTags.getQueryKey({ objectId: Number(id) }))
          snackActions.info('Метки успешно изменены')
        })
      }
    })
  }
  
  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: 'Редактировать метки',
      handler: editTagsHandler
    },
    {
      label: ozonProductQuery.data?.inArchive ? 'Вернуть из архива': 'Перенести в архив',
      handler: () => {
        if(ozonProductQuery.data?.inArchive && commonProductQuery.data) {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DefaultModal 
                  close={close}
                  accept={accept}
                  title="Вернуть продукт из архива?"
                />,
              onAccept: () => {
                unarchiveProductMutation({
                  productId: commonProductQuery.data?.productId, 
                  deArchivationInfo: {
                    productId: commonProductQuery.data?.productId,
                    versionNo: commonProductQuery.data?.versionNo,
                  }
                }, {
                  onSuccess: ({ data }) => {
                    queryClient.setQueryData(pimApi.products.common.getFetchCommonProductQueryKey(parseInt(id, 10)), data)
                    ozonProductQuery.refetch()
                    snackActions.info('Продукт успешно возвращен из архива!')
                  }
                })
              },
            }
          )
          
        } else {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) =>
                commonProductQuery.data && <ArchiveModal close={close} accept={accept} product={commonProductQuery.data}/>,
              onAccept: () => {},
            }
          )
        }
      }
    },
    {
      label: 'Создать копию',
      divider: true,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close }) =>
              <ProductCopyModal commonProductData={commonProductQuery!.data!} close={close}/>
          }
        )
      }
    },
    { 
      label: 'Перенести на другой товар',
      disabled: isArchived,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) => <ProductChangeGoodsModal close={close} accept={accept} productId={id}/>,
            onAccept: () => {},
          }
        )
      }
    },
  ]

  return (
    <SettingsMenu
      options={settingsOptions}
      iconType={<MoreVert />}
    />
  )
}
