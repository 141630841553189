import { Box } from '@mui/material'
import styled from 'styled-components'

export const ResultItem = styled(Box)< { $isArchived?: boolean } >`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color:  ${({ $isArchived }) => $isArchived ? '#a4a4a4' : '' };
  }
`
export const ProductType = styled.div`
  position: absolute;
  top: 0;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #EBEAEA;
  border-bottom: 1px solid #EBEAEA;
  background-color: #fff;
  svg {
    width: 10px !important;
    height: 10px;
    color: #BDBDBD;
  }
`
export const ProductTitle = styled.span<{ $isArchived?: boolean }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  &, a {
    color:  ${({ $isArchived }) => $isArchived ? '#a4a4a4' : '#007DFF' };
    font-size: 14px;
    text-decoration: none;
  }
`
export const ProductAuxCode = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
`
export const ProductImage = styled.div<{ $isArchived?: boolean }>`
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEAEA;
  filter: ${({ $isArchived }) => $isArchived && 'grayscale(1)'};
  opacity: ${({ $isArchived }) => $isArchived && 0.8 };
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  > div:first-child{
    height: 80px;
    width: 80px;
  }
`
export const ProductImagePlaceholder = styled.div`
  position: relative;
  min-width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #EBEAEA;
  img {
    width: 100%;
    height: 100%;
    min-width: 45px;
    min-height: 56px;
    object-fit: cover;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`
export const ComponentInfo = styled.div`
  width: 100%;
  padding: 8px 16px;
`

export const ProductInfo = styled.span`
  font-size: 12px;
  color: grey;
`
export const BorderedInfo = styled.span< { $isArchived?: boolean } >`
  display: inline-block;
  font-size: 12px;
  color: grey;
  border-right: 1px solid grey;
  padding-right: 5px;
  margin-right: 5px;
  > span{
    margin-right: 3px;
  }
  > a{
    color: ${({ $isArchived, theme }) => $isArchived ? '#a4a4a4' : theme.palette.primary.main };
    text-decoration: none;
  }
  &:last-child{
    border-right: none;
  }
`
