import { forwardRef, HTMLAttributes } from 'react'

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id?: string;
  url?: any
  withMainElement?: boolean;
  largeContainer?: boolean
  withOpacity?: boolean;
  disabled?: boolean
  index?: number
  isActive?: boolean
  isDragging?: boolean;
};

export const Photo = forwardRef<HTMLDivElement, ItemProps>
(({ url, isDragging, withMainElement, disabled, largeContainer, index, isActive, children, style, ...props }, ref) => {
  const isMainElement = () => {
    if (withMainElement) {
      return index === 0
    }
    return false
  }

  const inlineStyles = {
    opacity: isDragging ? '0.2' : '1',
    transformOrigin: '0 0',
    borderRadius: '4px',
    cursor: disabled ? '' : 'grab',
    // eslint-disable-next-line no-nested-ternary
    height: isMainElement() ? 192 : largeContainer ? 192 : 88,
    // eslint-disable-next-line no-nested-ternary
    width: isMainElement() ? 192 : largeContainer ? 192 : 88,
    gridRowStart: isMainElement() ? '1' : null,
    gridRowEnd: isMainElement() ? '3' : null,
    gridColumn: isMainElement() ? '1/3' : null,
    pointerEvents: isActive ? 'none' : '',
    ...style,
  }

  // @ts-ignore
  return <div ref={ref} className="media-grid-items" style={inlineStyles} {...props}>{children}</div>
})