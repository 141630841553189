import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { TabPanel } from 'shared/ui/components'
import * as React from 'react'
import { useState } from 'react'
import { customerGen } from 'shared/lib/generated'

import { StyledTabPanel } from '../styled'
import { UsersContragentsTable } from '../Table/Contragents/UsersContragentsTable'
import { UsersRolesTable } from '../Table/Roles/UsersRolesTable'
import { UsersPrivilegesTable } from '../Table/Privileges/UsersPrivilegesTable'

const TABS = [
  {
    label: 'Поставщики',
    value: 0,
    Component: UsersContragentsTable
  },
  {
    label: 'Роли',
    value: 1,
    Component: UsersRolesTable
  },
  {
    label: 'Привилегии',
    value: 2,
    Component: UsersPrivilegesTable
  },
]

export const AccessSection = ({ userData }: { userData: customerGen.administration.GetUserInfo.ResponseType }) => {
  const [ tabValue, setTabValue ] = useState(0)

  const handleChange = (_, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <StyledTabPanel>
        <Box sx={ { width: '100%' } }>
          <Tabs value={tabValue} onChange={ handleChange } aria-label="basic tabs example">
            { TABS.map(({ label }, index) => (
              <Tab
                /* eslint-disable-next-line react/no-array-index-key */
                key={ index }
                sx={ { padding: '12 24', minWidth: '185px' } }
                label={ label }
                { ...a11yProps(index) }
              />
            )) }
          </Tabs>
        </Box>
      </StyledTabPanel>

      { TABS.map(({ Component }, index) => (
        <TabPanel
          value={tabValue}
          /* eslint-disable-next-line react/no-array-index-key */
          key={ index }
          index={ index }
        >
          <Component userData={ userData } />
        </TabPanel>

      )) }
    </>
  )
}