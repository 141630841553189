import { Select, MenuItem } from '@mui/material'
import styled from 'styled-components'

export const StyledSelect = styled(Select)`
  min-height: 25px;
  .MuiSelect-select {
    padding-top: 10.5px;
    padding-bottom: 6px;
  }

  .MuiButtonBase-root {
    text-align: center;
  }
  
  .MuiListItemIcon-root {
    min-width: 10px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: 0 !important;
  }
  justify-content: center;
  padding: 10px 0;
`