import React, { useContext, useMemo } from 'react'
import { activityApi } from 'shared/api'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { checkValue, isNotNil } from 'shared/lib/checkers'
import { dialogModel } from 'shared/ui/components/dialog'
import { Table } from 'shared/ui/components/Table'
import { Button, Paper, Stack } from '@mui/material'
import { stringToNum } from 'shared/lib/utils/stringToNum'
import { SplitButton } from 'shared/ui/components'
import icon from 'assets/images/icons/excel.svg'
import { useQueryClient } from 'react-query'
import { BulkOperationsResultModal } from 'widgets/product'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import { pagingPanelLocale } from 'pages/product/productList/lib/localization'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { ActivityProductType } from 'shared/api/activity'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'

import { ActivityContext, IActivityContext } from '../../model/ActivityContext'
import { columnsConfig } from '../../lib/tableColumnsConfig'
import { StyledTableBox, ToolBar } from '../styled'

const ROW_HEIGHT = 52
const TABLE_HEIGHT = 750
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEDAULT_ROWS_COUNT = 5
const getRowId = (row) => row.id
export const ActivityProducts = () => {
  const queryClient = useQueryClient()
  const { mutate: downloadTemplate } = activityApi.useDownloadProductAddTemplateFile()
  const { mutate: exportFile } = activityApi.useDownloadActivityProductsFile()
  const { mutate: deleteActivityProduct } = activityApi.useDeleteActivityProductMutation()
  const { mutate: uploadFileMutation } = activityApi.useUploadActivityProductsFile()
  const { search } = useLocation()
  const searchObj = queryString.parse(search)
  const { searchString, page } = searchObj
  const { detailActivity } = useContext(ActivityContext) as IActivityContext

  const bonusCols = Math.floor((window.innerHeight - TABLE_HEIGHT) / ROW_HEIGHT)
  const calculatedRowsPerPage = bonusCols > MIN_ROWS_COUNT ? DEDAULT_ROWS_COUNT + bonusCols : DEDAULT_ROWS_COUNT

  const currentRowsPerPage = isNotNil(searchObj.limit) ? Number(searchObj?.limit) : calculatedRowsPerPage
  const pageSize = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const { data, refetch } = activityApi.useGetActivityProductsQuery(
    {
      page: checkValue(page),
      pageSize: checkValue(pageSize),
      searchString: checkValue(searchString),
      activityId: detailActivity!.id
    }
  )

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: data?.count !== 0 ? pageSize : 0,
      page: stringToNum(page) || 0,
      searchString: checkValue(searchString)
    }),
    [data]
  )

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      uploadFileMutation({ file, activityId: detailActivity!.id },
        {
          onSuccess: (resp) => {
            const messages: Array<any> = []
            if (resp?.data?.success) {
              messages.push({
                text: `${resp.data.success} из ${resp.data.count} продуктов успешно загружено`,
                type: 'success'
              })
            }
            if (resp.data.error) {
              messages.push({
                text: `${resp.data.error} из ${resp.data.count} продуктов загружено с ошибкой`,
                type: 'error'
              })
            }
            dialogModel.openDialog({
              component: ({ close }) => (
                <BulkOperationsResultModal
                  close={close}
                  messages={messages}
                  fileUrl={resp?.data.urlFile}
                  batchId={resp?.data.batchId}
                  syncAvailable={false}
                />
              ),
              onAccept: () => {},
            })
            refetch()
          },
          onError: () => {
            snackActions.error('Произошла ошибка при загрузке файла')
          },
        }
      )
    }
    // Чтобы очищать значение инпута и можно будет закидывать одинаковые файлы
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
  }

  const SplitButtonOptions = [
    {
      label: 'Скачать шаблон',
      handler: () => {downloadTemplate({ activityId: detailActivity!.id },
        {
          onSuccess: (response) => {
            const file = response.data
            const fileName =
            decodeURIComponent(response.headers['content-disposition']?.replace(
              'inline; filename=',
              ''
            ))
            downloadFile(file, fileName)
          },
          onError: () => {
            snackActions.error('Произошла ошибка при загрузке файла')
          }
        })}
    },
    {
      label: 'Импорт',
      uploadFile
    }
  ]

  const allQueriesKey: any = queryClient.getQueriesData({ exact: true }).
    filter(el => el[0][1] === `activity/${detailActivity!.id}/products`).map(el => el[0])

  const handleRowDelete = (activityProductId) => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <ModalContent
          close={close}
          accept={accept}
          title="Удалить продукт из кампании?"
          acceptBtnText="УДАЛИТЬ"
          acceptBtnColor="error"
        />
      ),
      onAccept: () => {
        deleteActivityProduct({ activityId: detailActivity!.id, activityProductId }, {
          onSuccess: (response) => {
            allQueriesKey.forEach(el => {
              queryClient.setQueryData<ActivityProductType>(
                el,
                (activityProducts) => {
                  const newData = {
                    info: activityProducts?.info?.filter(elem => elem.id !== response.data.id),
                    count: activityProducts?.count! - 1
                  }
                  return newData as ActivityProductType
                }
              )
            })
            snackActions.info('Продукт успешно удален')
          }
        })
      },
    })
  }
  
  const handleExportFile = () => {
    exportFile({ activityId: detailActivity!.id }, 
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName =
          decodeURIComponent(response.headers['content-disposition']?.replace(
            'inline; filename=',
            ''
          ))
          downloadFile(file, fileName)
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        }
      })
  }
  
  return (
    <>
      { data?.info &&
      <StyledTableBox component={Paper}>
        <ToolBar style={{ paddingRight: '24px' }}>
          <Stack spacing={1} direction="row">
            <SplitButton
              variant="outlined"
              options={SplitButtonOptions}
              label="ДЕЙСТВИЯ"
              size="small"
            />
            <Button
              variant="contained"
              size="small"
              disabled={data?.count === 0}
              onClick={handleExportFile}
              startIcon={<ExcelIcon src={icon} alt="excelImg" />}
            >
              ЭКСПОРТ
            </Button>
          </Stack>
        </ToolBar>
        <Table
          tableList={data?.info}
          totalCount={data?.count}
          getRowId={getRowId}
          paginationLocale={pagingPanelLocale}
          tableParams={memoTableParams}
          onRowDelete={handleRowDelete}
          calculatedRowsPerPage={calculatedRowsPerPage}
          columnsConfig={columnsConfig}
          showInArchiveControl={false}
          showColumnsVisibility={true}
          searching="external"
          pagination="external"
          sorting="external"
          filtering="external"
        />
      </StyledTableBox>
      }
    </>
  )
}