import { useCallback, useState } from 'react'

type Result  = [boolean, () => void]

export const useToggle = (initialState = false): Result => {
  const [state, setState] = useState(initialState)
  
  const toggle = useCallback(() => setState(prevState => !prevState), [])
  
  return [state, toggle]
}