import { Option } from 'shared/ui/components/interface'
import HistoryIcon from '@mui/icons-material/History'

import { WrapperUl, ElementLi, TabButton, IconButtonWrapper } from './styled'

export interface TabOption<V = string> extends Option<V> {
  progress?: number // 0 - 100%
  requireReady?: boolean // false - серый, true - синий
}

interface TabberProps<Option extends TabOption> {
  options: Array<Option>;
  currentOption: Option;
  onOptionClick: (option: Option) => void
}

export function Tabber<Option extends TabOption = TabOption>({
  options,
  currentOption,
  onOptionClick
}: TabberProps<Option>) {
  return (
    <WrapperUl>
      {options.map((option, index) => (
        <ElementLi key={option.value} disabled={option.disabled}>
          { 
            index !== options.length -1
              ? <TabButton
                $isActive={option.value === currentOption.value}
                disabled={option.disabled}
                onClick={() => onOptionClick(option)}
              >
                {option.label}
              </TabButton>
              : <IconButtonWrapper
                $isActive={option.value === currentOption.value}
                onClick={() => onOptionClick(option)}
              >
                <HistoryIcon/>
              </IconButtonWrapper>
          }

        </ElementLi>
      ))}
    </WrapperUl>
  )
}
