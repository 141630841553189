import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'

import { api } from '../../base'
import { NewCategory } from '../../../lib/generated/pim/Api'

interface NewCategoryPayload extends Omit<NewCategory, 'parentcategory_id'> {
  parentcategory_id?: number | null
}
const addCategory = (newCategory: NewCategoryPayload) =>
  api.post('/pim/categories', newCategory)


export const useAddCategory = () => useMutation<
  AxiosResponse<pimApi.categories.Category>,
  AxiosError<errorType>,
  NewCategoryPayload
>(addCategory)