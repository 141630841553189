import { memo, useContext } from 'react'
import { useField, UseFieldConfig } from 'react-final-form'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { getIsExistOnServer } from 'shared/lib/checkers/isExistOnServer'
import { pimApi } from 'shared/api'
import { useUpdateGoodsAssortmentMutation, useDeleteGoodsAssortmentMutation } from 'shared/api/pim/goods/goodsAssortment'
import { GoodsEditContext } from 'pages/goods/edit'

import { AssortmentGoodsInComponent } from './AssortmentGoodsInComponent'
import { AssortmentGoodsComponentSearch } from './AssortmentGoodsComponentSearch'


type AssortmentField = Array<pimApi.goods.GoodsAssortment>

const COMPONENT_FIELD_CONFIG: UseFieldConfig<AssortmentField> = { defaultValue: [] }

export const AssortmentGoodsSearchSection = ({ disabled } : { disabled?: boolean }) => {
  const { mutate: updateGoodsAssortment } = useUpdateGoodsAssortmentMutation()
  const { mutate: deleteGoodsAssortment } = useDeleteGoodsAssortmentMutation()
  const assortmentField = useField<AssortmentField>(
    'assortment',
    COMPONENT_FIELD_CONFIG
  )
  const goodsContext = useContext(GoodsEditContext)
  const assortmentFieldValue = assortmentField.input.value

  const id = useField<number>('id').input.value
  const isGoodsExistOnServer = getIsExistOnServer({ id })

  const handleAddComponent = (newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent) => {
    if(isGoodsExistOnServer) {
      updateGoodsAssortment({
        goodsId: id,
        assortmentId: newGoodsComponent.componentId,
      }, {
        onSuccess: ({ data }) => {
          assortmentField.input.onChange(data.assortment)
          if (goodsContext !== undefined) {
            goodsContext.goodsQuery.refetch().then()
          }
        }
      })
    } else {
      assortmentField.input.onChange([
        ...assortmentFieldValue,
        { 
          ...newGoodsComponent,
          goodsId: newGoodsComponent.componentId
        }
      ])
    }
  }

  const handleDeleteComponent = (AssortmentId: UniqueId) => {
    if(isGoodsExistOnServer) {
      deleteGoodsAssortment({
        goodsId: id,
        assortmentId: AssortmentId,
      }, {
        onSuccess: ({ data }) => {
          assortmentField.input.onChange(data.assortment)
          if (goodsContext !== undefined) {
            goodsContext.goodsQuery.refetch().then()
          }
        }
      })
    } else {
      assortmentField.input.onChange(assortmentFieldValue.filter((item) => item.goodsId !== AssortmentId))
    }
  }

  const componentData = assortmentFieldValue.map(item => ({
    componentId: item.goodsId,
    ...item,
  }))

  return (
    <>
      <AssortmentGoodsComponentSearch
        goodsComponents={componentData}
        onAddComponent={handleAddComponent}
        disabled={disabled}
      />
      <AssortmentGoodsInComponent
        goodsComponents={componentData}
        onRemoveComponent={handleDeleteComponent}
        disabled={disabled}
      />
    </>
  )
}

export const MemoAssortmentGoodsSearchSection = memo(AssortmentGoodsSearchSection)
