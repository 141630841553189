import styled from 'styled-components'
import { Box, Theme, Link } from '@mui/material'
import { pimApi } from 'shared/api'

const getColor = (
  messageType: pimApi.goods.GoodsResponseMessageType,
  theme: Theme
) => {
  switch (messageType) {
    case 'error':
      return theme.palette.error.main
    case 'success':
      return theme.palette.success.main
    default:
      return theme.palette.info.main
  }
}

export const Message = styled(Box)<{
  messageType: pimApi.goods.GoodsResponseMessageType
}>`
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    ${({ messageType, theme }) => getColor(messageType, theme)};

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  svg {
    font-size: 18px;
    margin-right: ${({ theme }) => theme.spacing(1.5)};
    color: ${({ messageType, theme }) => getColor(messageType, theme)};
    margin-bottom: ${({ theme }) => theme.spacing(-0.5)};
  }
`

export const StyledLink = styled(Link)`
  color: #2296f3;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  align-self: center;
`
