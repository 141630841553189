import styled from 'styled-components'
import Box from '@mui/material/Box'

export const StyledWarehouseCheckbox = styled(Box)<{ checked: boolean }>`
  height: 50px;
  display: flex;
  padding-left: 16px;
  flex-direction: row;
  cursor: pointer;
  gap: 32px;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  p {
    color: ${({ checked }) => checked ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)'};
  }
`

export const StyledMarketplaceBox = styled(Box)<{ selected: boolean }>`
  height: 100px;
  width: 368px;
  border-bottom: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  background-color: ${({ selected }) => selected ? '#E9F4FE' : '#FFFFFF'};
  padding-left: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  &:hover {
    background-color: ${({ selected }) => selected ? '#E9F4FE' : '#FAFAFA'};
  }
  
  img {
    width: 32px;
    height: 32px;
  }
  p {
    pointer-events: none; 
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
`

export const MarketplacesContainer = styled(Box)`
  width: 368px;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
`

export const EmptyPlug = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #E0E0E0;
  img {
    width: 120px;
    height: 120px;
  }
`

export const WarehousesListBox = styled(Box)`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #E0E0E0;
  >div {
    :first-child {
      padding: 16px 24px;
      p {
        font-weight: 500;
        font-size: 20px;
      }
    }
    :last-child {
      overflow-y: auto;
      height: 640px
    }
  }
`

export const PriorityWarehousesBox = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 758px;
  padding-bottom: 56px;
`