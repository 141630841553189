import React from 'react'
import { pimApi } from 'shared/api'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { InfoOutlined } from '@mui/icons-material'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ContentRatingContainer, ContentRatingTooltip } from './styled'



const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>{children}</Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0 1px 3px 0 #0000001F, 0 1px 1px 0 #00000024, 0 2px 1px -1px #00000033',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    border: '1px solid #dadde9',
    padding: 0,
  },
}))
export const ContentRating = ({ productId }: {productId: number}) => {
  const { data: ratingData } = pimApi.products.ozon.useGetContentRatingQuery(productId)

  if (!ratingData?.data.rating) return null

  const improveAttributesExist = () => {
    let exist = false
    ratingData?.data?.groups?.forEach(el => {
      if (isNotEmptyArray(el.improveAttributes)) {
        exist = true
      }
    })
    return exist
  }

  return (
    <ContentRatingContainer>
      <span className="title-span">Контент рейтинг</span>
      <div className="rating-data-container">
        <span className="rating-text">{ ratingData.data.rating }</span>
        {improveAttributesExist() &&
          <CustomTooltip title={
            <ContentRatingTooltip>
              <span className="tooltip-title">Что улучшить в карточке?</span>
              { ratingData.data.groups.map(el => {
                if (isNotEmptyArray(el.improveAttributes)) {
                  return (
                    <div className="rating-item">
                      <span>{ el.name }</span>
                      <ul>
                        { el.improveAttributes.map((elem) => <li>{elem.name}</li>) }
                      </ul>
                    </div>)
                }
                return null
              })
              }
            </ContentRatingTooltip>
          }
          placement="right">
            <HelpWithMargin>
              <InfoOutlined />
            </HelpWithMargin>
          </CustomTooltip>
        }
      </div>
    </ContentRatingContainer>
  )
}