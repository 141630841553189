import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Status } from './models'

import { api } from '../../base'



export function fetchSyncStatuses(): Promise<AxiosResponse<Status[]>> {
  return api.get<Status[]>('/pim/products/syncstatuses')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/products/syncstatuses'] as const

function getStatuses() {
  return fetchSyncStatuses().then((res) => res.data)
}

export const useSyncStatusListQuery = () => useQuery<
  Array<Status>,
  errorType,
  Array<Status>,
  QueryKey
>(QUERY_KEY, getStatuses, {
  refetchOnMount: false,
})

export function useSyncStatusList() {
  const syncStatusListQuery = useQuery<
    Array<Status>,
    errorType,
    Array<Status>,
    QueryKey
  >(QUERY_KEY, getStatuses)

  return {
    syncStatusListQuery,
  } as const
}