import { FC, memo, useCallback, useMemo, useState } from 'react'
import { pimApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { AutocompleteCatalogSearchField } from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { AutocompleteValue } from '@mui/material/useAutocomplete'
import { withFieldEventAdapter } from 'shared/ui/components/form/FieldAdapter'

export interface AutocompleteDictionaryAttributeProps extends FieldRenderProps<Array<string> | string> {
  dictionaryId: UniqueId
  withReset?: boolean
}

const getOptionFromDictionary = (value: string): Option => ({
  label: value,
  value
})

const getDictionaryFromOption = ({ value }: Option) => value

export const AutocompleteDictinaryAttributeRemoteSearchField: FC<AutocompleteDictionaryAttributeProps> = ({
  dictionaryId,
  onChange,
  withReset,
  input: inputProps,
  options: autoCompleteOptions,
  ...props
}) => {
  const [isFirstRequest, setIsFirstRequest] = useState(true)

  const { multiple } = inputProps

  const fetchCatalog = useCallback<(steach: string) => Promise<Array<Option<any>>>>(
    (search: string) => pimApi.dictionaries.fetchDictionaryFiltered(dictionaryId, encodeURIComponent(search))
      .then(
        data => data.data.map(
          getOptionFromDictionary
        )
      )
      .finally(() => {
        setIsFirstRequest(false)
      }),
  [dictionaryId]
  )

  const handleChange = (
    values: AutocompleteValue<Option, true, undefined, undefined> | AutocompleteValue<Option, false, undefined, undefined>
  ) => {
    const newValue = multiple ?
      (values as AutocompleteValue<Option, true, undefined, undefined>).map(getDictionaryFromOption) :
      getDictionaryFromOption(values as NonNullable<AutocompleteValue<Option, false, undefined, undefined>>)

    onChange({
      target: {
        name: inputProps.name,
        value: newValue
      }
    })
  }

  let inputPropsValue
  
  if(autoCompleteOptions) {
    inputPropsValue = Array.isArray(autoCompleteOptions) ? autoCompleteOptions : [autoCompleteOptions]
  } else {
    inputPropsValue = multiple && !Array.isArray(inputProps.value) ?
      [] :
      inputProps.value
  }

  const value = multiple || autoCompleteOptions ?
    (inputPropsValue as Array<string>).map(getOptionFromDictionary) :
    getOptionFromDictionary(inputPropsValue as string)

  const input: FieldInputProps<Array<Option<string>> | Option<string>> = useMemo(() => ({
    ...inputProps,
    value
  }), [inputProps])

  const filterOptions = useCallback(
    (selectedValues: Option<string> | Array<Option<string>>) => (options: Array<Option<string>>) => {
      const filterList = options.filter(
        (optionElement) => Array.isArray(selectedValues) ?
          !selectedValues.some(
            (element) => element.value === optionElement.value
          ) :
          optionElement !== selectedValues
      )
      const isResetAvailable = !multiple && withReset
      if(isResetAvailable) {
        const resetOption = { label: 'Не выбрано', value: '' }
        return [resetOption, ...filterList]
      }
      return filterList

    }, [])

  return (
    <AutocompleteCatalogSearchField<string, boolean>
      {...props as FieldRenderProps<any>}
      // @ts-ignore
      input={input}
      // @ts-ignore
      onChange={handleChange}
      fetchCatalog={fetchCatalog}
      multiple={multiple || !!autoCompleteOptions}
      noOptionsText={isFirstRequest ? 'Введите строку для поиска' : 'Ничего не найдено'}
      filterOptions={filterOptions}
    />
  )
}

export const AutocompleteDictinaryAttributeRemoteSearchFieldMemo = memo(
  withFieldEventAdapter<AutocompleteDictionaryAttributeProps>(
    AutocompleteDictinaryAttributeRemoteSearchField
  )
)
