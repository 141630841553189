import { useMemo } from 'react'
import { Paper } from '@mui/material'
import queryString from 'query-string'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { isNotNil } from 'shared/lib/checkers'

import { Table } from './Table'
import { StyledTableBox, StyledLoader } from './styled'

import { SearchObj, TableParams } from '../lib/types'

const ROW_HEIGHT = 52
const HEADER_HEIGHT = 56
const TABLE_CONTROLS = 153
const TABLE_HEADER_FOOTER = 50 + 80
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEFAULT_ROWS_COUNT = 5

export const GoodsChangelogTable = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const searchObj: SearchObj = queryString.parse(search)

  const bonusCols = Math.floor(
    (window.innerHeight -
      DEFAULT_ROWS_COUNT * ROW_HEIGHT -
      HEADER_HEIGHT -
      TABLE_CONTROLS -
      TABLE_HEADER_FOOTER) /
      ROW_HEIGHT
  )
  const calculatedRowsPerPage =
    bonusCols > MIN_ROWS_COUNT
      ? DEFAULT_ROWS_COUNT + bonusCols
      : DEFAULT_ROWS_COUNT
  const currentRowsPerPage = isNotNil(searchObj.limit)
    ? parseInt(searchObj.limit, 10)
    : calculatedRowsPerPage

  const page = parseInt(searchObj.page || '0', 10)
  const limit = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)
  const sort =
    searchObj.sort && searchObj.order
      ? [{ columnName: searchObj.sort, direction: searchObj.order }]
      : []

  const GoodsChangelogQuery = pimApi.goods.useFetchGoodsChangelogQuery(goodsId)

  // memo для того что бы новые параметры для таблицы не обновляли компонет DocTable до того как пришли
  // новые данные для таблицы
  const memoTableParams = useMemo(
    () => ({ sort, limit, page }),
    [GoodsChangelogQuery?.data?.data]
  )

  const handleTableParams = (params: Partial<TableParams>) => {
    let historyStr = queryString.stringify({ ...searchObj, ...params })
    if (params.sort) {
      const sortParams = {
        sort: params.sort?.[0]?.columnName,
        order: params.sort?.[0]?.direction,
      }
      historyStr = queryString.stringify({ ...searchObj, ...sortParams })
    }

    history.replace({ search: `?${historyStr}` })
  }

  return (
    <>
      <StyledTableBox component={Paper}>
        {(GoodsChangelogQuery.isLoading || GoodsChangelogQuery.isFetching) && (
          <StyledLoader size={60} />
        )}
        {GoodsChangelogQuery?.data?.data && (
          <Table
            tableList={GoodsChangelogQuery.data.data}
            totalCount={GoodsChangelogQuery.data.data.length}
            tableParams={memoTableParams}
            handleTableParams={handleTableParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
          />
        )}
      </StyledTableBox>
    </>
  )
}
