import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  
  svg {
    color: rgba(0, 0, 0, 0.26);
    cursor: pointer;
  }
`