import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { tradeApi } from 'shared/api'

interface IEditedPosition {
  id: UniqueId
  versionNo: number
  comments?: string
  quantity: number
  expectedQuantity: number
}
interface EditOrdersPositionPayload {
  copackingorderId: UniqueId,
  positionId: UniqueId,
  data: IEditedPosition
}

const editOrdersPosition = ({ copackingorderId, positionId, data }: EditOrdersPositionPayload) =>
  api.put<tradeApi.SupplyOrder>(
    `/copacking/copackingorders/${copackingorderId}/positions/${positionId}`, data
  )

export const useEditOrdersPositionMutation = () =>
  useMutation<
    AxiosResponse<tradeApi.SupplyOrder>,
    AxiosError<errorType>,
    EditOrdersPositionPayload
  >(editOrdersPosition)