import CallReceivedIcon from '@mui/icons-material/CallReceived'
import styled from 'styled-components'
import { getDiscountPrice } from 'entities/pim/price/lib'

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 3px;
  
  span {
    color: #00000099;
    text-align: center;
  }
`

export const AdviceCell = ({ row }) => {
  const discountPrice = getDiscountPrice(row.advicePriceDetail.price, row.advicePriceDetail.discount)

  const diff = discountPrice - row.price

  const increaseAngle = '180deg'
  const decreasedAngle = '270deg'

  return (
    <PriceWrapper>
      <CallReceivedIcon style={{ rotate: diff < 0 ? decreasedAngle : increaseAngle }} />
      <span>{Math.abs(diff).toLocaleString() || 0}</span>
    </PriceWrapper>
  )
}