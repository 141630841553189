import React, { useEffect, useState } from 'react'
import {
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi } from 'shared/api'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import TextField from '@mui/material/TextField'
import { SelectMarketplace } from 'widgets/selection/selectMarketplace/selectMarketplace'

import { InputUrlContainer, ModalContainer, InputSkuContainer, InputSku, InputMarket } from './styled'

interface AddCompetitorModalProps {
    close: () => void
    accept: (newCompetitorObject: pimApi.goods.NewCompetitor) => void
}

export const AddCompetitorModal = ({ close, accept }: AddCompetitorModalProps) => {

  const [selectType, setSelectType] = useState<string>('url')
  const [isAnalogue, setIsAnalogue] = useState<string>('similar')
  const [urlString, setUrlString] = useState<string>('')
  const [marketplaceId, setMarketplaceId] = useState<UniqueId | null>(null)
  const [skuString, setSkuString] = useState<string>('')
  const [confirmDisabled, setConfirmDisabled] = useState(true)


  const handleSelectType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkuString('')
    setUrlString('')
    setMarketplaceId(null)
    setSelectType(event.target.value)
  }
  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkuString('')
    setMarketplaceId(null)
    setUrlString(event.target.value)
  }

  const handleMarketplaceIdChange = (newMarketplaceId: UniqueId | null) => {
    setUrlString('')
    setMarketplaceId(newMarketplaceId)
  }

  const handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlString('')
    setSkuString(event.target.value)
  }

  const handleIsAnalogueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnalogue(event.target.value)
  }
  
  const onSubmit = () => {
    const competitorGood = {
      url: urlString,
      marketplaceId: marketplaceId as number,
      sku: skuString,
      analogueFlag: isAnalogue === 'analogue'
    }  
    accept(competitorGood)
    close()
  }
  useEffect(()=> {
    const isButtonDisabled = () => {
      if (selectType === 'url') {
        return urlString.length === 0
      }
      return !(skuString.length !== 0 && marketplaceId !== null)
    }
    setConfirmDisabled(isButtonDisabled())
  }, [urlString, skuString, marketplaceId])

  
  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <Box fontSize={20} fontWeight={500} m={3}>
          Добавить конкурента
        </Box>
        <Box px={3} mt={3}>
          <Box>
            <RadioGroup
              row={true}
              defaultValue="url"
              name="selectType"
              onChange={handleSelectType}
            >
              <FormControlLabel
                value="url"
                control={<Radio />}
                label="Добавить через URL"
              />
              <FormControlLabel
                value="sku"
                control={<Radio />}
                label="Добавить через номенклатуру маркетплейса"
              />
            </RadioGroup>
          </Box>

          {
            selectType === 'url'
              ?<InputUrlContainer>
                <TextField
                  id="url"
                  label="URL"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={urlString}
                  onChange={handleUrlChange}
                  autoComplete="off"
                />
              </InputUrlContainer>
                
              :<InputSkuContainer>
                <InputMarket>
                  <SelectMarketplace
                    onChange={handleMarketplaceIdChange}
                    currentValue={marketplaceId}
                  />
                </InputMarket>
               
                <InputSku>
                  <TextField
                    id="skuField"
                    label="Номенклатура"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={skuString}
                    onChange={handleSkuChange}
                    autoComplete="off"
                  />
                </InputSku>

              </InputSkuContainer>
          }

          <Box mt={3}>
            <RadioGroup
              row={true}
              defaultValue="similar"
              name="goodType"
              onChange={handleIsAnalogueChange}
            >
              <FormControlLabel
                value="similar"
                control={<Radio />}
                label="Такой же товар"
              />
              <FormControlLabel
                value="analogue"
                control={<Radio />}
                label="Похожий товар"
              />
            </RadioGroup>
          </Box>
        </Box>

      </Box>
      <Box m={3} p={0}>
        <DialogActions  sx={{ padding: '0' }}>
          <Button
            variant="text"
            onClick={close}
          >
            ОТМЕНА
          </Button>
          <LoadingButton
            loading={false}
            variant="contained"
            color="primary"
            disabled={confirmDisabled}
            onClick={onSubmit}
          >
            Добавить
          </LoadingButton>
        </DialogActions>
      </Box>
    </ModalContainer>
  )
}



