import { IVariation } from 'shared/services/interfaces/product'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NewGoodsVariation as NewGoodsVariationSnake ,
  Goods as GoodsSnake
} from 'shared/lib/generated/pim/Api'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

type Goods = CamelCasedPropertiesDeep<GoodsSnake>
type NewGoodsVariation = CamelCasedPropertiesDeep<NewGoodsVariationSnake>

export function productVariationCreate(
  goodsId: UniqueId,
  newGoodsVariation: NewGoodsVariation
): Promise<AxiosResponse<Goods>> {
  return api.post<Goods>(
    `/pim/goods/${goodsId}/variation`,
    newGoodsVariation
  )
}

export async function productVariationUpdate(
  variation: IVariation
): Promise<AxiosResponse<IVariation>> {
  const { productId, id } = variation
  const result = await api.put(
    `/pim/goods/${productId}/variation/${id}`,
    variation
  )
  return result.data
}

export function productVariationDelete({
  productId,
  id,
  versionNo,
}: IVariation): Promise<AxiosResponse<IVariation>> {
  return api.delete<IVariation>(
    `/pim/goods/${productId}/variation/${id}`,
    {
      data: {
        id,
        versionNo,
        comments: '',
      },
    }
  )
}