import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { useQueryClient } from 'react-query'
import { pimApi } from 'shared/api'
import { getCategoriesTreeQueryKey } from 'shared/api/pim/categories'
import { snackActions } from 'shared/lib/react/snackbar'
import { dialogModel } from 'shared/ui/components/dialog'
import { generatePath, useHistory } from 'react-router'
import { ADMIN_EDIT_CATEGORY } from 'shared/config'

import { CategoryModal } from '../modal/CategoryModal'
import { StyledLoader } from '../../../../User/EditUser/ui/styled'
import { addCategory, deleteCategoryItem } from '../../lib/CategoriesTreeOperations'


interface CategoriesTreeProps {
  nodes: any
}
export const CategoryItemSettings = ({ nodes }: CategoriesTreeProps) => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate: addCategoryMutation } = pimApi.categories.useAddCategory()
  const { mutate: editCategory } = pimApi.categories.useEditCategoryMutation()

  const handleCategoryClick = (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    const path = generatePath(ADMIN_EDIT_CATEGORY, { id })
    history.push(path)
  }
  
  const handelAddCategory = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <CategoryModal close={close} accept={accept} initialValues={{ parentcategoryId: nodes.id }}/>,
        onAccept: (data) => {
          addCategoryMutation({ ...data, parentcategory_id: data.parentcategoryId }, {
            onSuccess: ({ data: responseData }) => {
              queryClient.setQueryData<pimApi.categories.Category[]>(getCategoriesTreeQueryKey(), (categories) => {
                if (responseData.parentcategoryId) {
                  return addCategory(categories, responseData.parentcategoryId, { ...responseData, children: [] })
                }
                return [ ...categories!, { ...responseData, children: [] } ] as pimApi.categories.Category[]
              })
              snackActions.info('Категория добавлена')
            }
          })
        },
      }
    )
  }

  const handleEditClick = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) => {
          const { data: categoryData } = pimApi.categories.useGetCurrentCategory(nodes.id)
          if (categoryData) return (
            <CategoryModal close={ close } accept={ accept } initialValues={categoryData} />
          )
          return (
            <div style={{ position: 'fixed' }}>
              <StyledLoader size={60}/>
            </div>
          )
        },
        onAccept: (data) => {
          editCategory({ ...data }, {
            onSuccess: ({ data: responseData }) => {
              queryClient.setQueriesData<pimApi.categories.Category[]>(pimApi.categories.getCategoriesTreeQueryKey(),
                (categories) => addCategory(
                  deleteCategoryItem(categories, responseData.id),
                  responseData.parentcategoryId, { ...data, ...{ children: nodes.children } })
              )
              queryClient.setQueriesData(pimApi.categories.getCurrentCategoryQueryKey(responseData.id), responseData)
              snackActions.info('Изменение сохранено')
            }
          })
        },
      }
    )
  }
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
      <span className="category-item-text" onClick={ (e) => handleCategoryClick(e, nodes.id) }>{ nodes.name } </span>
      <div className="icon-container">
        <AddIcon fontSize="small" onClick={ (e) => {
          e.preventDefault()
          e.stopPropagation()
          handelAddCategory()
        } } />
        <EditIcon fontSize="small" onClick={ (e) => {
          e.preventDefault()
          e.stopPropagation()
          handleEditClick()
        } } />
      </div>
    </div>
  )
}