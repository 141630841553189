import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { tradeApi } from 'shared/api'

const getStocksProductQueryKey = (productId: UniqueId) =>
  ['get', 'trade/stocks/product', productId] as const

const getStocksProduct = (productId: UniqueId) =>
  api
    .get<tradeApi.ProductStocksInfo>(`/trade/stocks/product/${productId}`)
    .then((res) => res.data)

export const useStocksProductQuery = (productId: UniqueId) =>
  useQuery<tradeApi.ProductStocksInfo, errorType, tradeApi.ProductStocksInfo>(
    getStocksProductQueryKey(productId),
    (context) => getStocksProduct(context.queryKey[2] as UniqueId),
    {
      refetchOnMount: false, keepPreviousData: true, staleTime: Infinity, enabled: !!productId
    }
  )

export interface GetStocksProductHistoryParams {
  productId?: UniqueId
  fromDate?: string
  toDate?: string
}

const getStocksProductHistoryQueryKey = (
  queryParams: GetStocksProductHistoryParams
) =>
  [
    'get',
    `trade/stocks/product/${queryParams.productId}/history`,
    queryParams,
  ] as const

type StocksProductHistoryQueryKey = ReturnType<
  typeof getStocksProductHistoryQueryKey
>

function getStocksProductHistoryFunc(
  queryParams: GetStocksProductHistoryParams
) {
  const { productId, fromDate, toDate } = queryParams
  return api
    .get<Array<tradeApi.ProductStocksOnDate>>(
      `/trade/stocks/product/${productId}/history?from_date=${fromDate}${
        toDate ? `&to_date=${toDate}` : ''
      }`
    )
    .then((res) => res.data)
}

export const getStocksProductHistory = (
  context: QueryFunctionContext<StocksProductHistoryQueryKey>
) => {
  const [, , queryParams] = context.queryKey
  return getStocksProductHistoryFunc(queryParams)
}

export const useStocksProductHistoryQuery = (
  params: {
    productId?: UniqueId
    fromDate?: string
    toDate?: string
  }
) =>
  useQuery<
    Array<tradeApi.ProductStocksOnDate>,
    errorType,
    Array<tradeApi.ProductStocksOnDate>,
    StocksProductHistoryQueryKey
  >(getStocksProductHistoryQueryKey(params), getStocksProductHistory,
    {
      refetchOnMount: false, keepPreviousData: true, staleTime: Infinity
    }
  )