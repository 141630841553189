import { memo, useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import {
  Grid,
  Box,
  DialogActions,
} from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { InputWrapper } from 'shared/ui/styled/InputWrapper'
import {
  SelectFieldWithAutoComplete,
  InputField,
  FieldWithCount,
  MaskedInputField,
} from 'shared/ui/components/form'
import { Accordion } from 'shared/ui/components/Accordion'
import { Option } from 'shared/ui/components/interface'
import { pimApi } from 'shared/api'
import { PriceCard } from 'entities/pim/price'

import { StyledLoadingButton } from './styled'

import { PriceContainer, StyledAccordionBox } from '../../../../../WB/PriceChange/ui/PriceChangeModal/ui/styled'

interface OptionItem {
  name?: string
  id: number
  commentsRequired?: boolean
}

export const getOptions = (optionItem: OptionItem): Option<number> => ({
  label: optionItem.name || '',
  value: optionItem.id,
  commentsRequired: optionItem.commentsRequired,
})

export const PriceChangeModalFormRender = memo(
  ({
    handleSubmit,
    isLoading,
  }: {
    handleSubmit: (any) => void
    isLoading?: boolean
  }) => {
    const price = useField('price').input.value || null
    const newPrice = useField('newPrice').input.value || null

    const isPriceChangeable = !!price

    const changeDateField = useField('changeDate')
    const revertDateField = useField('revertDate')
    const commentsField = useField('comments')

    const {
      data: priceChangeReasonsQuery,
      isLoading: priceChangeReasonsLoading,
    } = pimApi.dictionaries.usePriceChangeReasonsQuery()
    const priceChangeReasonOptionList = useMemo(
      () => priceChangeReasonsQuery?.map(getOptions) || [],
      [priceChangeReasonsQuery]
    )

    const curDate = new Date()
    const defaultDateValue = useMemo(
      () =>
        new Date(
          new Date(curDate.setHours(24, 0, 0, 0)).getTime() -
            curDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 16),
      []
    )

    const handleChangeDateExpand = () => changeDateField.input.onChange(defaultDateValue)
    const handleChangeDateClose = () => changeDateField.input.onChange(null)
  
    const handleRevertDateExpand = () => revertDateField.input.onChange(defaultDateValue)
    const handleRevertDateClose = () => revertDateField.input.onChange(null)

    const handleCommentsClose = () => commentsField.input.onChange(null)

    return (
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', marginTop: '24px' }}>
          <Box sx={{ width: '245px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <PriceContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                <PriceContainer>
                  <PriceCard showOldPrice={false} price={price} title="Текущая цена"/>
                </PriceContainer>
                <PriceContainer>
                  <PriceCard showOldPrice={false} price={newPrice || price} title="Новая цена"/>
                </PriceContainer>
              </Box>
            </PriceContainer>
            <Grid container={true} columnSpacing={{ md: 2 }}>
              <Grid item={true} xs={7}>
                <InputWrapper>
                  <Field
                    sx={{ '& .MuiInputBase-inputAdornedEnd': { paddingRight: '8px' } }}
                    name="newPrice"
                    label="Цена на МП"
                    autoComplete="off"
                    type="number"
                    component={MaskedInputField}
                    disabled={false}
                    scale={0}
                    min={0}
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </Box>
          {isPriceChangeable && (
            <>
              <Grid item={true} xs={12}>
                <InputLabel label="Выберите причину изменения цены" />
                <InputWrapper>
                  <Field
                    name="pricechangereasonId"
                    id="pricechangereasonId"
                    label="Причина изменения"
                    required={true}
                    errorAfterTouch={true}
                    component={SelectFieldWithAutoComplete}
                    fullWidth={true}
                    options={priceChangeReasonOptionList}
                    isLoading={priceChangeReasonsLoading}
                  />
                </InputWrapper>
              </Grid>
              <Grid item={true} xs={12}>
                <Accordion
                  title="Оставить комментарий"
                  onClose={handleCommentsClose}
                >
                  <Grid item={true} xs={12} pb={0}>
                    <FieldWithCount>
                      <Field
                        name="comments"
                        component={InputField}
                        multiline={true}
                        fullWidth={true}
                        minRows={1}
                        maxRows={10}
                        max={255}
                      />
                    </FieldWithCount>
                  </Grid>
                </Accordion>
              </Grid>
              <Grid item={true} xs={12}>
                <StyledAccordionBox>
                  <Accordion key="first-acc"
                    title="Запланировать время изменения"
                    onOpen={handleChangeDateExpand}
                    onClose={handleChangeDateClose}
                  >
                    <Field
                      name="changeDate"
                      label="Дата время"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={InputField}
                    />
                  </Accordion>
                  <Accordion
                    title="Вернуться к исходной цене"
                    onOpen={handleRevertDateExpand}
                    onClose={handleRevertDateClose}
                  >
                    <Field
                      name="revertDate"
                      label="Дата время"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={InputField}
                    />
                  </Accordion>
                </StyledAccordionBox>
              </Grid>
            </>
          )}
        </Box>
        <DialogActions sx={{ padding: '0', marginTop: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={isPriceChangeable ? false : (!newPrice)}
              loading={isLoading}
            >
              {isPriceChangeable ? 'ИЗМЕНИТЬ' : 'УСТАНОВИТЬ'}
            </StyledLoadingButton>
          </Box>
        </DialogActions>
      </form>
    )
  }
)
