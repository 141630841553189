import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from 'shared/api/base'
import { toSnakeCase } from 'shared/lib/transform'
import { toSnake } from 'shared/lib/transform/toSnakeCase'

import { ProductSumProfitability } from '../../models'

interface DataParamsTypes {
  productId: number
  fromDate: string
  toDate: string
}

export const getProductsSumProfitabilityQueryKey = (queryParams: DataParamsTypes) =>
  ['get', '/pim/products/common/sumprofitability', queryParams] as const

type GetProductsSumProfitabilityQueryKey = ReturnType<typeof getProductsSumProfitabilityQueryKey>

function getProductsSumProfitability(queryParams: DataParamsTypes) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }

  return api.get(
    '/pim/products/common/sumprofitability', { params: queryParamsSnake }
  ).then(res => res.data)
}

const getAllProductsSumprofitability = (context: QueryFunctionContext<GetProductsSumProfitabilityQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getProductsSumProfitability(queryParams)
}

export const useProductsSumProfitabilityQuery = (queryParams: DataParamsTypes) =>
  useQuery<
    ProductSumProfitability,
    errorType,
    ProductSumProfitability,
    GetProductsSumProfitabilityQueryKey
  >(
    getProductsSumProfitabilityQueryKey(queryParams),
    getAllProductsSumprofitability,
    { refetchOnMount: false, staleTime: Infinity }
  )
