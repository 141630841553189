import { Route } from 'pages/interface'
import { BRANDQUAD, LAYOUT_WIDTH } from 'shared/config'
import { ShowcaseLayout } from 'shared/ui/layouts/ShowcaseLayout'

import { ShowcasePage } from './ShowcasePage'

export default () =>
  [
    {
      exact: true,
      path: BRANDQUAD,
      layout: ShowcaseLayout,
      layoutWidth: LAYOUT_WIDTH.table,
      component: ShowcasePage,
    },
  ] as Array<Route>
