import styled from 'styled-components'

export const StyledPlugMarketplace = styled.div`
  height: 641px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  span {
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
  > div {
    border-radius: 8px;
    background-color: var(--background-default, #FAFAFA);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      white-space: break-spaces;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.15px;
    }
  }
`