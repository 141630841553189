import React, { useMemo, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useParams } from 'react-router-dom'
import { pimApi } from 'shared/api'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { StyledLoader } from 'features/supplyOrders/EditSupplyOrderGoods/ui/styled'
import { useQueryClient } from 'react-query'
import { sort } from 'shared/lib/utils/sort'
import { reactQueryCacheDelete, reactQueryCacheUpdateByKey } from 'shared/lib/reactQuery'
import { pimGen } from 'shared/lib/generated'

import { CompetitorsTabs } from './ui/CompetitorsTabs'
import { IOption } from './types/types'
import { ClearField, EmptyPlug, MarketPlaceSelect, SelectorsWrapper, Sort, StyledTabPanel } from './ui/styled'
import { AddCompetitorModal } from './ui/AddCompetitorModal/AddCompetitorModal'
import { MemoCompetitorCard } from './ui/CompetitorCard/CompetitorCard'


interface CompetitorsStepProps {
  isArchived?: boolean
}

const competitionTabsOptions = [
  {
    label: 'Отслеживаемые',
    disabled: false,
    value: 'tracked'
  },
  {
    label: 'Игнорируемые',
    disabled: false,
    value: 'ignored'
  },
]

export const CompetitorsStep = ({ isArchived } : CompetitorsStepProps) => {
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const queryClient = useQueryClient()
  const { data: competitorData, isLoading, isFetching } = pimGen.goods.GetGoodscompetitors.useGetGoodscompetitors({ goodsId })
  const { mutate: editCompetitor } = pimGen.goods.EditGoodscompetitors.useEditGoodscompetitors()
  const { mutate: deleteCompetitor } = pimGen.goods.DeleteGoodscompetitors.useDeleteGoodscompetitors()
  const competitors = sort(competitorData || [], 'status')

  const { mutate: addCompetitor } = pimGen.goods.CreateGoodscompetitors.useCreateGoodscompetitors()

  const [currentTab, setCurrentTab] = useState<IOption>(competitionTabsOptions[0])
  const [selectedMarketplace, setSelectedMarketplace] = useState('')
  const [selectedSort, setSelectedSort] = useState(0)

  const competitorsQuantity = {
    tracked: competitors?.filter(el => el.reckonedFlag).length || 0,
    ignored: competitors?.filter(el => !el.reckonedFlag).length || 0,
  }

  const handleDeleteCompetitor = ({ competitorId, versionNo }) => {
    const deletedCompetitor = competitors.filter(el => el.id === competitorId)[0]
    deleteCompetitor({
      goodsId,
      competitorId,
      data: {
        id: competitorId,
        versionNo,
      },
    },
    {
      onSuccess: reactQueryCacheDelete({
        queryClient,
        queryCacheKey: pimGen.goods.GetGoodscompetitors.getQueryKey({ goodsId }),
        deleteItem: deletedCompetitor,
        onSuccess: () => {snackActions.info('Успешно')}
      })
    }  
    )
  }

  const handleEditCompetitor = ({ competitor }:{competitor: pimApi.goods.Competitor}) => {
    editCompetitor({
      goodsId,
      data: {
        id: competitor.id,
        versionNo: competitor.versionNo,
        reckonedFlag: !competitor.reckonedFlag,
      }
    }, {
      onSuccess: reactQueryCacheUpdateByKey({
        queryClient,
        queryCacheKey: pimGen.goods.GetGoodscompetitors.getQueryKey({ goodsId }),
        onSuccess: () => {snackActions.info('Успешно')}
      })
    })
  }
  const handleTabClick = (selectedTab) => {
    setCurrentTab(selectedTab)
  }

  const handleSelectMarketplace = (event: SelectChangeEvent) => {
    setSelectedMarketplace(event.target.value as string)
  }

  const handleClearMarketplace = () => {
    setSelectedMarketplace('')
  }

  const handleSelectSort = () => {
    const val = selectedSort === 2 ? 0 : selectedSort + 1
    setSelectedSort(val)
  }

  const marketplacesFilter = useMemo(() => {
    if (competitors) {
      const marketplaceNames = competitors.map(item => (item.marketplaceName))
      return marketplaceNames.filter((name, index) => marketplaceNames.indexOf(name) === index)
    }
    return []
  }, [competitors])

  const sorting = (arrayForSort) => {
    if (selectedSort === 1 && arrayForSort) {
      return  [...arrayForSort.sort((a, b) => (b.price || 0) - (a.price || 0) )]
    }
    if (selectedSort === 2 && arrayForSort) {
      return [...arrayForSort.sort((a, b) => (a.price || 0) - (b.price || 0) )]
    }
    return [...arrayForSort]
  }

  const filteredCompetitors = (() => {
    if (selectedMarketplace !== '' && competitors && currentTab.value === 'tracked') {
      return sorting([...competitors])?.filter((item) => (item.marketplaceName === selectedMarketplace && item.reckonedFlag))
    }
    if (selectedMarketplace === '' && competitors && currentTab.value === 'tracked') {
      return sorting([...competitors])?.filter((item) => item.reckonedFlag)
    }
    if (selectedMarketplace !== '' && competitors && currentTab.value === 'ignored') {
      return sorting([...competitors])?.filter((item) => (item.marketplaceName === selectedMarketplace && !item.reckonedFlag))
    }
    if (selectedMarketplace === '' && competitors && currentTab.value === 'ignored') {
      return sorting([...competitors])?.filter((item) => !item.reckonedFlag)
    }
    return []
  })()

  const handleAddCompetitor = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <AddCompetitorModal
          close={close}
          accept={accept}
        />
      ),
      onAccept: (newCompetitorObject) => {
        addCompetitor({
          goodsId,
          data: {
            url: newCompetitorObject.url,
            marketplaceId: newCompetitorObject.marketplaceId,
            sku: newCompetitorObject.sku,
            analogueFlag: newCompetitorObject.analogueFlag,
            reckonedFlag: currentTab.value === 'tracked'
          }
        }, {
          onSuccess: (response) => {
            queryClient.setQueryData<pimGen.goods.GetGoodscompetitors.ResponseType>(
              pimGen.goods.GetGoodscompetitors.getQueryKey({ goodsId }),
              (goodsCompetitors) => goodsCompetitors?.concat(response) as pimGen.goods.GetGoodscompetitors.ResponseType
            )
            snackActions.info('Товар конкурента добавлен')
          },
        }
        )
      },
    })
  }

  return (
    <>
      {(isLoading || isFetching) 
        ? <StyledLoader size={60} />
        : <>
          <StyledTabPanel>
            <CompetitorsTabs
              options={competitionTabsOptions}
              currentOption={currentTab}
              onOptionClick={handleTabClick}
              quantities={competitorsQuantity}
            />
            <SelectorsWrapper>
              <MarketPlaceSelect $disabled={marketplacesFilter.length <= 1}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    id="marketplace-label"
                    size="small"
                  >
                    Маркетплейс
                  </InputLabel>
                  <Select
                    labelId="marketplace-label"
                    id="marketplace"
                    value={selectedMarketplace}
                    label="Маркетплейс"
                    onChange={handleSelectMarketplace}
                    size="small"
                    disabled={marketplacesFilter.length <= 1}
                  >
                    {marketplacesFilter.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ClearField onClick={handleClearMarketplace} $onClear={selectedMarketplace !== ''}/>
              </MarketPlaceSelect>

              <Sort
                onClick={handleSelectSort}
                sortMode={selectedSort}
              >
                Сортировать по цене
                <ArrowUpwardIcon />
              </Sort>
            </SelectorsWrapper>
          </StyledTabPanel>
          <Box mt={3} display="flex" flexDirection="row">
            <Box>
              <Typography fontSize={20} lineHeight="116.688%">
                {currentTab.value === 'tracked' ?
                  'Отслеживаемые конкуренты' :
                  'Игнорируемые конкуренты'}
              </Typography>
              <Typography fontSize={12} lineHeight="20px" color="rgba(0, 0, 0, 0.54)" letterSpacing="0.4px">
                {currentTab.value === 'tracked' ?
                  'Конкуренты, участвующие в волшебнике цен' :
                  'Конкуренты, не участвующие в волшебнике цен'}
              </Typography>
            </Box>
            <Box display="inline-flex" ml="auto">
              <Button
                variant="outlined"
                color="primary"
                sx={{ height: '36px' }}
                type="button"
                startIcon={<AddIcon/>}
                onClick={handleAddCompetitor}
                disabled={isArchived}
              >
                ДОБАВИТЬ КОНКУРЕНТА
              </Button>
            </Box>
          </Box>
          <Box mb={2} mt={3}>
            {filteredCompetitors?.length === 0 && !isLoading && !isFetching
              ? <EmptyPlug>{currentTab.value === 'tracked' ?
                'Нет отслеживаемых конкурентов' :
                'Нет игнорируемых конкурентов'}</EmptyPlug>
              : filteredCompetitors?.map((competitorItem) => (
                <Box mb={2} key={competitorItem.id}>
                  <MemoCompetitorCard
                    competitor={competitorItem}
                    handleEditCompetitor={handleEditCompetitor}
                    handleDeleteCompetitor={handleDeleteCompetitor}
                  />
                </Box>
              ))}
          </Box>
        </>
      }
    </>
  )
}
