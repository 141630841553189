import { useParams } from 'react-router-dom'
import { history } from 'app/providers/with-router'
import { CommonProductContextProvider } from 'entities/pim/product/model/common/useCommonProductContext'
import { pimApi } from 'shared/api'
import { Preloader } from 'shared/ui/components'
import { isNil } from 'shared/lib/checkers'
import { generatePath } from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import { GoodsProvider } from 'shared/lib/hooks/useGoodsContext'
import { PRODUCT_EDIT } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { Paper } from '@mui/material'

import { ProductEditHeading } from './ProductEditHeading'
import { EditContent } from './ProductEditContent'

import { ProductEditPageStep } from '../model/types'

interface OnChangePayload {
  companyId: UniqueId | null
  marketplaceId: UniqueId | null
  companymarketplaceId: UniqueId | null
}

export const ProductEdit = () => {
  const { id, step } = useParams<{ id: string; step: ProductEditPageStep }>()
  const productId = parseInt(id, 10)

  const [companyId, setCompanyId] = useState<UniqueId | null>(null)
  const [marketplaceId, setMarketplaceId] = useState<UniqueId | null>(null)
  const [companymarketplaceId, setCompanymarketplaceId] = useState<UniqueId | null>(null)
  const [variationChanged, setVariationChanged] = useState<boolean>(false)

  const commonProductQuery =
    pimApi.products.common.useFetchCommonProductQuery(productId)
  const goodsQuery = pimApi.goods.useFetchGoodsQuery(
    commonProductQuery?.data?.goodsId!,
    true
  )
  const handleSuccess = (res: pimApi.products.common.Product[]) => {
    if (res?.[0]) {
      const path = generatePath(PRODUCT_EDIT, { id: res[0].productId })
      if (variationChanged) history.push(path)
    } else {
      snackActions.error('Такого продукта нет')
      if (commonProductQuery?.data?.productId === productId) {
        setCompanyId(commonProductQuery?.data?.companyId)
        setMarketplaceId(commonProductQuery?.data?.marketplaceId)
        setCompanymarketplaceId(commonProductQuery?.data?.companymarketplaceId)
      }
    }
  }

  pimApi.products.common.useFetchCommonProductSearchQuery(
    {
      goodsId: commonProductQuery.data?.goodsId,
      companymarketplaceId: commonProductQuery.data?.companymarketplaceId,
      options: {
        onSuccess: handleSuccess,
        enabled: Boolean(commonProductQuery.data?.goodsId && commonProductQuery.data?.companymarketplaceId)
      }
    }
  )

  const onChangeVariation = ({
    companyId: newCompanyId,
    marketplaceId: newMarketplaceId,
    companymarketplaceId: newCompanymarketplaceId
  }: OnChangePayload) => {
    setCompanyId(newCompanyId)
    setMarketplaceId(newMarketplaceId)
    setCompanymarketplaceId(newCompanymarketplaceId)
    setVariationChanged(true)
  }

  const initialMarketplaceValues = useMemo(
    () => ({
      marketplaceId,
      companyId,
      companymarketplaceId
    }),
    [marketplaceId, companyId, companymarketplaceId]
  )

  useEffect(() => {
    if (commonProductQuery?.data?.productId === productId) {
      setCompanyId(commonProductQuery?.data?.companyId)
      setMarketplaceId(commonProductQuery?.data?.marketplaceId)
      setCompanymarketplaceId(commonProductQuery?.data?.companymarketplaceId)
    }
  }, [commonProductQuery?.data])

  if (isNil(goodsQuery.data) || isNil(commonProductQuery.data))
    return <Preloader />

  return (
    <Paper sx={{ padding: '24px', marginTop: '28px', maxWidth:'inherit', marginBottom:'28px', minHeight: '90vh' }}>
      <CommonProductContextProvider
        commonProductQuery={commonProductQuery}>
        <GoodsProvider value={goodsQuery.data}>
          {step !== 'pricehistory' && (
            <ProductEditHeading
              goodsData={goodsQuery?.data}
              initialMarketplaceValues={initialMarketplaceValues}
              onChangeVariation={onChangeVariation}
            />
          )}
          <EditContent
            marketplaceId={commonProductQuery.data.marketplaceId}
            commonProduct={commonProductQuery.data}
            productId={productId}
          />
        </GoodsProvider>
      </CommonProductContextProvider>
    </Paper>
  )
}
