import styled from 'styled-components'
import { Button, IconButton } from '@mui/material'

export const TabButton = styled(Button)<{
  $isActive: boolean
  disabled: boolean | undefined
}>`
  padding: 0 22px;
  
  border: none;
  border-radius: 4px 4px 0 0;
  background-color: ${({ $isActive }) => ($isActive ? '#EBF5FF' : 'transparent')};
  
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : '#007DFF')}!important;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 44px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`

export const WrapperUl = styled.ul`
  display: flex;
  padding: 0;

  list-style: none;
`

export const ElementLi = styled.li<{
  disabled: boolean | undefined
}>`

  :not(:last-child) > ${TabButton} {
    box-shadow: inset 0 -1px 0px rgba(0, 125, 255, 0.5);
  }

  :last-child > ${TabButton} {
    box-shadow: inset 0 -1px 0px rgba(0, 125, 255, 0.5)
  }
`

export const IconButtonWrapper = styled(IconButton)<{
  $isActive: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 46px;
  color: #007DFF;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: ${({ $isActive }) => ($isActive ? '#EBF5FF' : 'transparent')};
  box-shadow: inset 0 -1px 0px rgba(0, 125, 255, 0.5);
`