import { Box, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const StyledItem = styled.div<{ old?: boolean, future?: boolean }>`
  padding-left: 12px;
  padding-right: 12px;
  height: 78px;
  display: flex;
  background-color: ${({ future }) => future ? '#e9f4fe' : '' };
  opacity: ${({ old }) => old ? '0.6' : '1' };
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  transition: all 0.1s;
  
  :hover {
    opacity: ${({ old }) => old ? '0.5' : '0.8' };
  }

  .date-container {
    width: 123px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    span {
      font-size: 12px;
      line-height: 20px;
    }
  }
  
  .divider {
    background: ${({ old }) => old ? '#00000061' : '#2196F3' };
    height: 54px;
    width: 1px;
  }
  
  .activity-main-info {
    height: 54px;
    width: 62%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .activity-info-row {
      display: flex;
      flex-direction: row;
      .left-side {
        width: 248px;
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        span {
          font-size: 12px;
          line-height: 20px;
          color: #00000099;
        }
      }
      
      .right-side {
        span {
          font-size: 12px;
          line-height: 20px;
          color: #00000099;
        }
      }
    }
    
    .activity-name {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      height: max-content;
      -webkit-box-orient: vertical;
    }
  }
  
  .activity-count {
    width: 17%;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    span {
      :first-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #00000099;
      }
      :last-child {
        font-size: 15px;
        line-height: 26px;
      }
    }
  }
  
  .arrow-icon {
    color: #0000008A;
    margin-right: 0;
    margin-left: auto;
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  z-index: 1;
`

export const DetailActivityHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  
  .detail-info-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .activity-name {
      font-size: 20px;
      line-height: 32px;
      color: #000000DE;
    }
    .activity-time-container {
      display: flex;
      flex-direction: row;
      .activity-time-interval {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #00000099;
      }
      
      .timer-span {
        margin-left: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #00000099;

          :last-child {
            font-size: 16px;
            line-height: 24px;
            color: #F50057;
          }
        }
      }
    }
  }
`

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
  box-shadow: none;
  border: none;
  
  .MuiToolbar-root {
    button {
      display: none;
    }
  }

  .MuiTableCell-head {
    white-space: break-spaces;
  }
  
  table {
    min-width: 960px !important;
  }

  .MuiTableCell-root {
    :first-child {
      padding-left: 16px;
      padding-right: 16px;
    }
    padding-left: 16px;
    padding-right: 16px;
  }
  thead {
    height: 56px;
  }

  .TableCell-cellNoWrap {
    white-space: inherit;
  }
  
  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
`

export const ToolBar = styled.div`
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1;
`