import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'

import { api } from '../../base'
import { CommonNewAttribute } from '../../../lib/generated/pim/Api'

const addAttribute = (newAttribute: CommonNewAttribute) =>
  api.post('/pim/attributes', newAttribute)


export const useAddAttribute = () => useMutation<
  AxiosResponse<pimApi.attributes.CommonAttribute>,
  AxiosError<errorType>,
  CommonNewAttribute
>(addAttribute)