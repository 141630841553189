/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ParameterValues, Product } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ParameterValues>

export type IRequest = CamelCasedPropertiesDeep<{
  algorithmId: number,
  parameterId: number,
  query: {
  /** Product Id */
    product_id: number,

}
}>

export const apiFunction = ({ algorithmId,parameterId,query }: IRequest) =>
  api.get<ResponseType>(`/pim/pricecontrol/algorithm/${algorithmId}/parameter/${parameterId}/values`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ algorithmId,parameterId,query }: IRequest) => ['get', `/pim/pricecontrol/algorithm/${algorithmId}/parameter/${parameterId}/values`, { algorithmId,parameterId,query }] as const

export const useGetAlgorithmParams = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

