import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@mui/material'
import ArrowIcon from '@mui/icons-material/ArrowForwardIos'

import { StyledIconButton } from './styled'

const fontSizeIcon = { fontSize: 14 }

type ToggleCellComponentProps = TableRowDetail.ToggleCellProps & {
  disabled?: boolean
}

export const ToggleCell = (props: ToggleCellComponentProps) => {
  const { disabled, onToggle, expanded } = props
  return (
    <TableRowDetail.Cell { ...props } style={{ padding: 0, backgroundColor: 'transparent' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledIconButton
          onClick={ onToggle }
          expanded={ expanded ? 'true' : undefined }
          disabled={ disabled }
        >
          <ArrowIcon
            color={ disabled ? 'disabled' : 'primary' }
            sx={ fontSizeIcon }
          />
        </StyledIconButton>
      </Box>
    </TableRowDetail.Cell>
  )
}
