import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api'

export const getCurrentContragentsQueryKey = (contragentId: UniqueId) =>
    ['get', 'pim/admin/contragents', contragentId] as const

type GetCurrentContragentsQueryKey = ReturnType<typeof getCurrentContragentsQueryKey>


function getCurrentContragent(contragentId: UniqueId) {
  return api.get<pimApi.admin.CurrentContragent>
  (`/pim/admin/contragents/${contragentId}`).then((res) => res.data)
}

const getCurrentContragentFn = (context: QueryFunctionContext<GetCurrentContragentsQueryKey>) => {
  const [, ,contragentId] = context.queryKey
  return getCurrentContragent(contragentId)
}


export const useGetCurrentContragentQuery = (contragentId: UniqueId) =>
  useQuery<
      pimApi.admin.CurrentContragent,
      errorType,
      pimApi.admin.CurrentContragent,
      GetCurrentContragentsQueryKey
        >(
          getCurrentContragentsQueryKey(contragentId),
          getCurrentContragentFn
        )
