
import styled from 'styled-components'
import Chip from '@mui/material/Chip'


export const UserStateText = styled(Chip)`
  font-weight: 500;
  font-size: 12px;
  padding: 0 6px;
  height: 20px;
  color: #fff;
  .MuiChip-label {
    padding: 0;
  }
`