import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { Country } from './models'

import { api } from '../../base'



export function fetchCountries(): Promise<AxiosResponse<Country[]>> {
  return api.get<Country[]>('/pim/countries')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', '/pim/countries'] as const

function getCountries() {
  return fetchCountries().then((res) => res.data)
}

export function useCountryList() {
  const countryListQuery = useQuery<
    Array<Country>,
    errorType,
    Array<Country>,
    QueryKey
  >(QUERY_KEY, getCountries, {
    refetchOnMount: false
  })

  return {
    countryListQuery,
  } as const
}