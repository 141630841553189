import React from 'react'
import styled from 'styled-components'

const StyledText = styled.span`
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const PositionsBarcode = ({ row }) => (
  <StyledText>{row.detail.barcode}</StyledText>
)