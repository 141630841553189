import { Box } from '@mui/material'
import styled from 'styled-components'

export const TitleOrderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const AuthorDate = styled.div`
  display: flex;
  align-items: center;
  div:nth-child(2) {
    margin-left: 4px;
    margin-right: 4px;
  }
`

export const OrderInfo = styled(Box)`
  display: block;
  font-size: 12px;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 8px;
  margin-left: -8px;
`

export const OrderInfoHeaderText = styled(Box)`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
`

export const OrderInfoTitleText = styled(Box)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: -8px 0 8px 0;
  border: none;
`

export const TotalSum = styled(Box)`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
`

export const OrderInfoItem = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 8px;
  border-right: 1px solid #dedede;
  margin-bottom: 12px;
  &:last-child {
    border-right: none;
  }
`
export const CustomAccordion = styled.div`
  .MuiAccordion-root {
    box-shadow: none;
    .MuiAccordionSummary-root {
      margin: 0;
      padding: 0;
      min-height: 44px;
    }
    .MuiAccordionDetails-root {
      margin: 0;
      padding: 0;
    }
    .MuiCollapse-wrapperInner{
      margin: -32px 0 8px 0;
      width: 90%;
    }
    .MuiAccordionSummary-expandIconWrapper {
      margin-bottom: 6px;
    }
  }
`