import React, { useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  Box,
  DialogActions,
  Button, DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { tradeApi } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import {
  getOrderForPackingSearch
} from 'shared/api/trade/orders'
import { SelectProductWidget } from 'features/product/widgets/SelectProductWidget'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 740px;
    max-width: 740px;
  }
`

interface ProductCreateModalProps {
  close: () => void;
  refetchPage: () => void;
  copackingordersId: UniqueId
}

export const AddPositionsInCopackingModalContent = ({
  close,
  copackingordersId,
  refetchPage
}: ProductCreateModalProps) => {
  
  const [ relatedProductId, setRelatedProductId ] = useState<number|undefined>(undefined)

  const { mutate: addPosition } = tradeApi.orders.usePostNewProductForPacking()
  
  const onSubmit = () => {
    addPosition(
      {
        copackingordersId,
        productId: relatedProductId
      },
      {
        onSuccess: () => {
          snackActions.info('Успешно сохранено!')
          refetchPage()
          close()
        }
      }
    )
  }

  const searchFunction = (searchString) => getOrderForPackingSearch(copackingordersId, searchString)
  const onSelectProduct = (product) => setRelatedProductId(product)
  const onClearSelection = () => setRelatedProductId(undefined)

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <DialogTitle fontSize={20}>Новая позиция</DialogTitle>
        <Box px={3} mt={1}>
          <Form
            onSubmit={onSubmit}
            // @ts-ignore
            mutators={{
              ...arrayMutators,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mt={1}>
                  <SelectProductWidget
                    searchFunction={searchFunction}
                    searchInputRequired={true}
                    quantityRequired={false}
                    onSelectProduct={onSelectProduct}
                    onClearSelection={onClearSelection}
                  />
                </Box>
                <DialogActions sx={{
                  padding: 0,
                  marginBottom: '24px'
                }}>
                  <Box >
                    <Button onClick={close} color="primary">
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={false}
                      disabled={false}
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      ДОБАВИТЬ
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )}
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



