import { Plugin } from '@devexpress/dx-react-core'
import { TableRowDetail as DXTableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import { RowDetailState } from '@devexpress/dx-react-grid'
import React from 'react'

import { NestedTable } from '../NestedTable'
import { ToggleCell } from '../TableCells'
import { ColumnConfig } from '../../model/types'

interface ITableRowDetail {
  nestedTableColumnsConfig?: {
    path: string
    config: Array<ColumnConfig<string>>
    isEditable?: boolean
  }
  setOpenedRows?: any
  nestedWidget?: any
  expandedRows:  number[] | undefined
  isLoading?: boolean
  refRow?: (node: any) => void
}

export const TableRowDetail = ({ 
  nestedTableColumnsConfig, 
  nestedWidget,
  setOpenedRows,
  expandedRows, 
  isLoading, 
  refRow,
}: ITableRowDetail) => {

  const Widget = ({ row }) => (
    nestedWidget(row)
  )  
  const NestedTableWithConfig = ({ row }) => {
    if (nestedWidget) {
      return <Widget row={row}/>
    } if (nestedTableColumnsConfig) {
      return <NestedTable
        refRow={refRow}
        row={row}
        columnsConfig={nestedTableColumnsConfig}
        isLoading={isLoading}
      />
    }
    return <></>
  }


  return (
    <Plugin>
      <RowDetailState
        expandedRowIds={expandedRows}
        onExpandedRowIdsChange={(expandedRowIds) => {
          if (setOpenedRows) setOpenedRows(expandedRowIds)}}
      />
      <DXTableRowDetail
        contentComponent={NestedTableWithConfig}
        toggleCellComponent={(props) => (
          <ToggleCell
            {...props}
            disabled={false}
          />
        )}
      />
    </Plugin>
  )
}
