import { Goods, GoodsSupplierCode } from 'domains/pim/goods'
import { useQueryClient } from 'react-query'
import * as GoodsService from 'shared/api/pim/goods'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'

export const useGoodsCode = () => {
  const queryClient = useQueryClient()

  function changeGoodsEditData(
    productId: UniqueId,
    getUpdatedGoods: (goods: Goods) => Goods
  ) {
    const key = getGetPimGoodsQueryKey(productId)

    queryClient.setQueryData<Goods>(key, (data) => {
      if (!data?.suppliers) {
        return data as Goods
      }

      return getUpdatedGoods(data as Goods)
    })
  }

  const create = (
    productId: UniqueId,
    supplierId: UniqueId,
    supplierCode: GoodsSupplierCode
  ) => {
    GoodsService.createGoodsCode(productId, supplierId, supplierCode).then(
      (res) => {
        const updatedCode = res.data
        changeGoodsEditData(productId, (goods) => ({
          ...goods,
          suppliers: goods?.suppliers?.map((supplier) => ({
            ...supplier,
            codes:
              supplier.codes && supplier.id === supplierId
                ? [...supplier.codes, updatedCode]
                : supplier.codes,
          })),
        }))
      }
    )
  }

  const update = (
    productId: UniqueId,
    supplierId: UniqueId,
    supplierCode: GoodsSupplierCode
  ) => {
    GoodsService.updateGoodsCode(productId, supplierId, supplierCode).then(
      (res) => {
        const updatedCode = res.data
        changeGoodsEditData(productId, (goods) => ({
          ...goods,
          suppliers: goods?.suppliers?.map((supplier) => ({
            ...supplier,
            codes: supplier?.codes?.map((code) =>
              code.id === supplierCode.id ? updatedCode : code
            ),
          })),
        }))
      }
    )
  }

  const remove = (
    productId: UniqueId,
    supplierId: UniqueId,
    supplierCode: GoodsSupplierCode
  ) => {
    GoodsService.deleteGoodsCode(productId, supplierId, supplierCode).then(
      () => {
        const key = getGetPimGoodsQueryKey(productId)
        queryClient.setQueryData<Goods>(key, (data) => {
          if (!data?.suppliers) return data as Goods
          return {
            ...data,
            suppliers: data.suppliers.map((supplier) => ({
              ...supplier,
              codes: supplier.codes?.filter(
                (code) => code.id !== supplierCode.id
              ),
            })),
          }
        })
      }
    )
  }

  return {
    create,
    update,
    remove,
  }
}
