import { SearchProductsResultType } from 'shared/api/goods/models'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

type ColumnName = keyof Omit<
  SearchProductsResultType,
  'id' | 'productBaseImageId' | 'marketplaceId' | 'name' | 'productId'
>
type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  marketplaceName: 'marketplaceName',
  marketplaceCategory: 'marketplaceCategory',
  marketplaceCode: 'marketplaceCode',
  supplierCode: 'supplierCode',
  barcodes: 'barcodes',
  price: 'price',
  stocks: 'stocks',
  autoOrdering: 'autoOrdering',
  cabinetName: 'cabinetName',
  companyName: 'companyName',
  inArchive: 'inArchive',
  marketplaceUrl: 'marketplaceUrl'
} as const

interface nestedTableColumnsConfigProps {
  path: string,
  config: Array<ColumnConfig<ColumnName>>
}

export const nestedTableColumnsConfig: nestedTableColumnsConfigProps = {
  path: 'products',
  config: [
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: 'Маркетплейс',
      width: 150,
      sortingEnabled: true,
      cellType: CellType.MarketplaceNameLink,
    },
    {
      name: columns.marketplaceCategory,
      columnName: columns.marketplaceCategory,
      title: 'Категория',
      width: 'auto',
      sortingEnabled: true,
    },
    {
      name: columns.marketplaceCode,
      columnName: columns.marketplaceCode,
      title: 'Номенклатура',
      width: 170,
      sortingEnabled: true,
      cellType: CellType.MarketplaceCode,
    },
    {
      name: columns.supplierCode,
      columnName: columns.supplierCode,
      title: 'Артикул поставщика',
      width: 153,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.barcodes,
      columnName: columns.barcodes,
      title: 'Штрих-код',
      width: 172,
      sortingEnabled: true,
      cellType: CellType.Barcodes,
    },
    {
      name: columns.price,
      columnName: columns.price,
      title: 'Цена, р.',
      width: 108,
      sortingEnabled: true,
    },
    {
      name: columns.stocks,
      columnName: columns.stocks,
      title: 'Остатки, шт.',
      width: 139,
      sortingEnabled: true,
    },
  ],
}
