import { api } from 'shared/api/base'

import { withRefreshTokenResponse } from './interceptors/response/withRefreshTokenResponse'
import { withServerMessageNotifyResponse } from './interceptors/response/withServerMessageNotifyResponse'
import { withSuloginRequest } from './interceptors/response/withSuloginRequest'

api.interceptors.response.use(response => response, withRefreshTokenResponse(api))

api.interceptors.response.use(response => response, withServerMessageNotifyResponse)

api.interceptors.response.use(response => response, withSuloginRequest)

