import { Route } from 'pages/interface'
import { LAYOUT_WIDTH } from 'shared/config'
import { PRODUCT_EDIT, PRODUCT_LIST, PRODUCT_PRICE_HISTORY } from 'shared/config/routes'
import { Layout } from 'shared/ui/layouts'

import { ProductEdit } from './edit'
import { PriceHistory } from './edit/priceHistory'
import { Products } from './productList'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: PRODUCT_EDIT,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: ProductEdit,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: PRODUCT_PRICE_HISTORY,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.content,
    component: PriceHistory,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: PRODUCT_LIST,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: Products,
  },
] as Array<Route>
