import { getStatusTextColor } from 'shared/config'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { pimApi } from 'shared/api'

export const StatusName = styled(Typography)<{ statustype: string, $isArchived?: boolean }>`
  width: 100%;
  color: ${({ $isArchived, statustype, theme }) =>
    getStatusTextColor(
      theme,
      statustype as pimApi.products.StatusType,
      $isArchived
    )
};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StatusDescription = styled(Typography)`
  width: 100%;
  color: ${({ $isArchived } : { $isArchived?: boolean }) => $isArchived ? '#a4a4a4' : '621b16' };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
