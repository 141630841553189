import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { ArchivationReason } from './models'

import { api } from '../../base'

export function fetchArchivationReasons(): Promise<AxiosResponse<ArchivationReason[]>> {
  return api.get<ArchivationReason[]>('/pim/archivationreasons')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'pim/archivationreasons'] as const

function getArchivationReasons() {
  return fetchArchivationReasons().then((res) => res.data)
}

export function useArchivationReasonList() {
  const archivationReasonListQuery = useQuery<
    Array<ArchivationReason>,
    errorType,
    Array<ArchivationReason>,
    QueryKey
  >(QUERY_KEY, getArchivationReasons)

  return {
    archivationReasonListQuery,
  } as const
}