import { Box, Grid } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  AppPimGoodsModelsGoodsGoodsComponent,
} from 'domains/pim/goods'
import { ComponentCard, goodsLib } from 'entities/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import { InputLabel } from 'shared/ui/components'

import { ResultList, ErrorText } from './styled'


interface GoodsInComponentProps {
  goodsType: pimApi.goods.GoodsType
  withRemove: boolean
}

const fileThumbnailSize = { maxWidth: 80, maxHeight: 80 }

export const GoodsInComponent: FC<GoodsInComponentProps> = ({
  goodsType,
  withRemove,
}) => {
  const [prevComponents, setPrevComponents] = useState(0)
  const componentsField = useField('packGoods')
  const auxCodeField = useField('auxCode')


  // Костыль, чтобы включать валидацию формы посе удаления выбранных компонентов, auxCode просто так задан, можно любой
  // другой филд менять и возращать дефолтное значение. Так же есть баг, что при удалении только одного товара все
  // равно не включается валидация
  useEffect(() => {
    if(prevComponents > componentsField.input.value.length) {
      auxCodeField.input.onChange('1')
      auxCodeField.input.onChange(auxCodeField.input.value)
    }
  }, [componentsField.input.value])

  if(isEmpty(componentsField.input.value)) return null

  return (
    <ResultList>
      <InputLabel
        label={goodsLib.getGoodsLabelByType(goodsType)}
        required={false}
      />

      <FieldArray<AppPimGoodsModelsGoodsGoodsComponent> name="packGoods">
        {({ fields }) =>
          !isEmpty(fields) && (
            fields.value.map((goodsComponent, index) => {
              const imgUrl = pimApi.goods.getGoodsThumbnailApiPath(
                goodsComponent.componentId,
                fileThumbnailSize
              )

              return (
                <Grid item={true} key={goodsComponent.componentId}>
                  <ComponentCard
                    imgUrl={goodsComponent.goodsBaseImageId ? imgUrl : undefined}
                    goodsType={goodsComponent.goodsType}
                    componentId={goodsComponent.componentId}
                    name={goodsComponent.name}
                    code={goodsComponent.code}
                    unitCode={goodsComponent.unitCode}
                    auxCode={goodsComponent.auxCode}
                    unitPartible={goodsComponent.unitPartible}
                    quantityFieldName={`packGoods.${index}.quantity`}
                    onRemoveClick={() => {
                      if(withRemove) {
                        setPrevComponents(fields.value.length)
                        fields.remove(index)
                      }
                    }}
                  />
                </Grid>
              )
            })
          )
        }
      </FieldArray>

      {componentsField.meta.touched && componentsField.meta.error && (
        <Box mt={2}>
          <ErrorText>
            {componentsField.meta.error}
          </ErrorText>
        </Box>
      )}
    </ResultList>
  )
}
