import { useFormState } from 'react-final-form'
import { Button } from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import { FieldArray } from 'react-final-form-arrays'
import {
  CircularProgressStyled,
  CircularProgressWrap,
  ContentsWrapper
} from 'shared/ui/components/CircularProgress/CircularProgress'

import { CompositionComponent } from '../CompositionComponent'

const SCRATCH_GOODS_COMPONENT: pimApi.goods.GoodsComponent = {
  id: 0,
  component: '',
  versionNo: 0,
  changed: '',
  changer: '',
}

export const CompositionFormRenderComponent = ({ disabled }) => {
  const { values } = useFormState()

  const { mutate: createContentMutation, isLoading: createInProgress } = pimApi.goods.useCreateContentMutation()
  const { mutate: updateContentMutation, isLoading: updateInProgress } = pimApi.goods.useUpdateContentMutation()
  const { mutate: deleteContentMutation, isLoading: deleteInProgress } = pimApi.goods.useDeleteContentMutation()

  const isFieldInProgress = createInProgress || updateInProgress || deleteInProgress

  const isEmptyComponent = values.content[values.content.length - 1]?.component === ''

  return (
    <ContentsWrapper>
      <InputLabel label="Состав" required={false} />

      <FieldArray<pimApi.goods.GoodsComponent> name="content">
        {({ fields }) => (
          <>
            <ContentsWrapper>
              {isFieldInProgress && (<CircularProgressWrap>
                <CircularProgressStyled/>
              </CircularProgressWrap>)}
              {fields.value.map((content, index) => (
                <CompositionComponent
                  key={content?.id ?? index}
                  index={index}
                  isShowPercent={values.isShowPercents}
                  createContent={createContentMutation}
                  updateContent={updateContentMutation}
                  deleteContent={deleteContentMutation}
                  disabled={isFieldInProgress || disabled}
                  deletable={true}
                />
              ))}
            </ContentsWrapper>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => fields.push(SCRATCH_GOODS_COMPONENT)}
              disabled={isEmptyComponent || disabled}
            >
              ДОБАВИТЬ
            </Button>
          </>
        )}
      </FieldArray>
    </ContentsWrapper>
  )
}
