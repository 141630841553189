import { Tab, Tabs } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { SplitButton, TabPanel } from 'shared/ui/components'
import { brandquadApi } from 'shared/api'
import { useParams } from 'react-router'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { downloadFile } from 'shared/lib/utils'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { CharacteristicsTab, MainTab, MediaTab } from './GoodTabs/tabs'
import { MediaContainer } from './Media/MediaContainer'

import { StyledGoodsInfo } from '../styled'

export const GoodsInfo = () => {
  const { clientId: id } = useParams<{ clientId: string }>()
  const { searchObj } = useQueryParams({
    parseNumbers: true,
  })

  const { data: goodsInfo } = brandquadApi.goods.getGoodInfo.getGoodInfo({
    clientId: id,
    goodsId: searchObj?.goodsId,
  })

  const { mutate: downloadGoodGeneral, isLoading } =
    brandquadApi.goods.getGoodsInfo.useGetGoodExportInfo()
  const { mutate: downloadAll, isLoading: allLoading } =
    brandquadApi.goods.getSingleGoodInfoAll.useGetSingleGoodInfoAll()
  const { mutate: downloadMedia, isLoading: mediaLoading } =
    brandquadApi.goods.getGoodMedia.useGetGoodMedia()

  const handleDownloadGeneral = () => {
    downloadGoodGeneral(
      {
        clientId: id,
        goodsId: searchObj?.goodsId,
        query: {
          responseInfo: 'general',
          responseFormat: 'XLSX',
        },
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName = decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'attachment; filename=',
              ''
            )
          )
          downloadFile(file, fileName)
        },
      }
    )
  }

  const handleDownloadAttributes = () => {
    downloadGoodGeneral(
      {
        clientId: id,
        goodsId: searchObj?.goodsId,
        query: {
          responseInfo: 'attributes',
          responseFormat: 'XLSX',
        },
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName = decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'attachment; filename=',
              ''
            )
          )
          downloadFile(file, fileName)
        },
      }
    )
  }

  const handleDownloadMedia = () => {
    downloadMedia(
      {
        clientId: id,
        goodsId: searchObj?.goodsId,
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName = decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'attachment; filename=',
              ''
            )
          )
          downloadFile(file, fileName)
        },
      }
    )
  }

  const handleDownloadAll = () => {
    downloadAll(
      {
        clientId: id,
        goodsId: searchObj?.goodsId,
      },
      {
        onSuccess: (response) => {
          const file = response.data
          const fileName = decodeURIComponent(
            response.headers['content-disposition']?.replace(
              'attachment; filename=',
              ''
            )
          )
          downloadFile(file, fileName)
        },
      }
    )
  }

  const SplitButtonOptions = [
    {
      label: 'Скачать основное',
      handler: () => handleDownloadGeneral(),
    },
    {
      label: 'Скачать характеристики',
      handler: () => handleDownloadAttributes(),
    },
    {
      label: 'Скачать медиа',
      handler: () => handleDownloadMedia(),
    },
    {
      label: 'Скачать все',
      handler: () => handleDownloadAll(),
    },
  ]

  const TABS = [
    {
      label: 'Основное',
      Component: MainTab,
      handler: handleDownloadGeneral,
      value: '1',
    },
    {
      label: 'Характеристики',
      Component: CharacteristicsTab,
      handler: handleDownloadAttributes,
      value: '2',
    },
    {
      label: 'Медиа',
      Component: MediaTab,
      handler: handleDownloadMedia,
      value: '3',
    },
  ]

  const [tabValue, setTabValue] = useState(TABS[0].value)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  
  return (
    <StyledGoodsInfo>
      <div className="good-content-wrapper">
        <div className="good-header">
          <span>{goodsInfo?.name}</span>
          <SplitButton
            variant="contained"
            options={SplitButtonOptions}
            isLoading={isLoading || mediaLoading || allLoading}
            label="СКАЧАТЬ"
            size="small"
          />
        </div>
        <div className="good-content">
          <div className="good-info">
            <Tabs
              className="styled-tabs"
              value={tabValue}
              onChange={handleChange}
            >
              {TABS.map((value) => (
                <Tab
                  key={value.value}
                  value={value.value}
                  label={value.label}
                />
              ))}
            </Tabs>
            {TABS.map(({ Component, value, handler }) => (
              <TabPanel value={tabValue} key={value} index={value}>
                <Component goodsInfo={goodsInfo} handleDownload={handler} />
              </TabPanel>
            ))}
          </div>
          {goodsInfo && isNotEmptyArray(goodsInfo.images) ?
            <MediaContainer goodsInfo={goodsInfo}/>
            :
            <></>
          }
        </div>
      </div>
    </StyledGoodsInfo>
  )
}
