import React from 'react'
import { Box } from '@mui/material'
import { customerApi } from 'shared/api'
import { Field } from 'react-final-form'
import { InputField, InputLabel } from 'shared/ui/components'

export const ArrayOfCredentials = ({ marketplaceInfo, values }: { marketplaceInfo: customerApi.Marketplace | undefined, values: any }) => {
  const credentialName = (index) => {
    if (marketplaceInfo?.credentials?.length &&
      marketplaceInfo?.credentials?.[index]?.name !== undefined) {
      return marketplaceInfo.credentials?.[index]?.name
    } return ''
  }
  return (
    <Box>
      {values?.credentials &&
        values.credentials.map((name, index) => {
          const fieldName = credentialName(index)
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box mt={3} key={index}>
              <InputLabel
                label={fieldName}
                required={false}
              />
              <Field
                name={`credentials[${index}].value`}
                placeholder={fieldName}
                component={InputField}
                required={false}
                fullWidth={true}
              />
            </Box>
          )})}
    </Box>
  )
}