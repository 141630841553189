import { useMutation, UseMutationOptions } from 'react-query'
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'

const downloadOzonProductBulkCreationTemplateFile = async ({
  categoryId,
  companymarketplaceId,
  parentCategoryId,
  templateType,
  brand,
}: {
  categoryId?: UniqueId
  parentCategoryId?: UniqueId
  companymarketplaceId?: UniqueId
  templateType?: string
  brand?: string
}) =>
  api.get<File>(
    '/pim/ops/products/ozon/product',
    { 
      params: { 
        companymarketplace_id: companymarketplaceId,
        template_type: templateType,
        parent_category_id: parentCategoryId,
        category_id: categoryId,
        brand
      },
      responseType: 'blob',
    }
  )

export const useDownloadOzonProductBulkCreationTemplateFile = (options?) =>
  useMutation(downloadOzonProductBulkCreationTemplateFile, options)


const uploadOzonProductBulkCreationFile = async ({
  brandId,
  categoryId,
  companymarketplaceId,
  parentCategoryId,
  useGoodsMedia,
  file,
  templateType,
}: {
  brandId?: UniqueId
  file: File | null
  categoryId?: UniqueId
  parentCategoryId?: UniqueId
  useGoodsMedia?: boolean
  companymarketplaceId: UniqueId
  templateType?: string
}) => {
  const body = new FormData()
  body.append('file', file || '')
  return api.post(
    '/pim/ops/products/ozon/product',
    body, {
      params: {
        use_goods_media: useGoodsMedia,
        companymarketplace_id: companymarketplaceId,
        template_type: templateType,
        parent_category_id: parentCategoryId,
        category_id: categoryId,
        brand_id: brandId
      },
    }
  )
}

export const useUploadOzonProductBulkCreationFile = (
  options?: UseMutationOptions<
    AxiosResponse,
    errorType,
    {
      file: File
      companymarketplaceId: UniqueId
      parentCategoryId?: UniqueId
      categoryId?: UniqueId
      useGoodsMedia: boolean
      templateType?: string
      brandId?: UniqueId
    }
  >
) => useMutation(uploadOzonProductBulkCreationFile, options)
