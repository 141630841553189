import AddIcon from '@mui/icons-material/Add'
import { dialogModel } from 'shared/ui/components/dialog'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'

import { CreateNewNomenclatureWindow } from './CreateNewNomenclatureWindow'
import { StyledTab } from './styled'

export const CreateNewNomenclatureTab = () => {
  const { commonProductQuery } = useCommonProductContext()
  const { wbProductQuery } = useWBProductContext()

  const onTabClick = () => {
    if (commonProductQuery?.data?.productId &&
        commonProductQuery?.data?.companyId &&
        wbProductQuery?.data?.id) {
      dialogModel.openDialog({
        component: ({ close }) => (
          <CreateNewNomenclatureWindow
            close={close}
            companymarketplaceId={commonProductQuery?.data?.companymarketplaceId}
            productId={commonProductQuery?.data?.productId}
            wbnomenclatureId={wbProductQuery?.data?.id}
          />
        ),
        onAccept: () => {
        },
      })
    }
  }

  return (
    <StyledTab
      key="createNew"
      icon={<AddIcon />}
      iconPosition="end"
      onClick={onTabClick}
    />
  )
}