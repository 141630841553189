import { AxiosResponse } from 'axios'
import { useQuery, QueryFunctionContext } from 'react-query'
import { toSnakeCase } from 'shared/lib/transform'

import { Contragent } from './models'

import { api } from '../../base'

const getContragentListQueryKey = (queryParams?: QueryParams) =>
  ['get', 'pim/contragents', queryParams] as const

type GetContragentListQueryKey = ReturnType<typeof getContragentListQueryKey>
interface QueryParams {
  onlySuppliers?: boolean
}


export function fetchContragents(queryParams?: QueryParams): Promise<AxiosResponse<Contragent[]>> {
  return api.get<Contragent[]>('/pim/contragents', { params: toSnakeCase(queryParams) })
}


function getContragents(context: QueryFunctionContext<GetContragentListQueryKey>) {
  const [, , queryParams] = context.queryKey
  
  return fetchContragents(queryParams).then((res) => res.data)
}

export const useContragentListQuery = (queryParams?: QueryParams) => useQuery<
  Array<Contragent>,
  errorType,
  Array<Contragent>,
  GetContragentListQueryKey
  >(getContragentListQueryKey(queryParams), getContragents, {
    refetchOnMount: false,
  })

export function useContragentList(queryParams?: QueryParams) {
  const contragentListQuery = useQuery<
    Array<Contragent>,
    errorType,
    Array<Contragent>,
    GetContragentListQueryKey
  >(getContragentListQueryKey(queryParams), getContragents,{ refetchOnMount: false })

  return { contragentListQuery } as const
}