import styled from 'styled-components'

export const StyledGoodsName = styled.span<{ disabled: boolean }>`
  white-space: pre-wrap !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ disabled }) => disabled ? 'inherit' : '#007DFF' };
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer' };
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
