import React from 'react'
import { Button } from '@mui/material'
import { tradeGen } from 'shared/lib/generated'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { snackActions } from 'shared/lib/react/snackbar'

import { useChangeDistribution, useDistributionStore } from '../../api/useChangeDistribution'
import { getButtonVariant } from '../../lib/getTransitionStyles'

export const ProductTransitions = () => {
  const { invalidateDistributionCache } = useChangeDistribution()
  const { supplyOrderQuery } = useSupplyOrderContext()
  const selectedProducts = useDistributionStore(state => state.selectedProducts)
  const setIsLoading = useDistributionStore(state => state.setIsLoading)
  const setSelectedProducts = useDistributionStore(state => state.setSelectedProducts)
  const { mutate: refreshDistributions  } = tradeGen.orders.RefreshDistributions.useRefreshDistributions()

  const handleRefreshDistributions = () => {
    setIsLoading(true)
    refreshDistributions({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
      query: {
        copackingorderpositionId: selectedProducts?.distributions[0].copackingorderpositionId!
      }
    }, {
      onSuccess: () => {
        snackActions.info('Успешно ')
        setSelectedProducts(null)
        setIsLoading(false)
        invalidateDistributionCache({ supplyOrderQuery })
      }, onError: () => setIsLoading(false)
    })
  }

  const handleButtonClick = (transition: { id: number, name: string }) => {
    if (transition.id === 7) {
      handleRefreshDistributions()
    }
  }
  return (
    <>
      {selectedProducts?.distributions[0].transitions?.map(el => (
        <Button variant={getButtonVariant(el.id)} onClick={() => handleButtonClick(el)}>{el.name}</Button>
      ))}
    </>
  )
}