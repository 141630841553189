import React from 'react'
import { ChartUrlCell } from 'shared/ui/components/Table/ui/TableCells'
import styled from 'styled-components'

const IconWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  
  a {
    align-items: start !important;
  }
`
export const ChartCell = ({ row }) => (
  <IconWrapper>
    <ChartUrlCell row={row}/>
  </IconWrapper>
)