import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper, Tooltip, Typography } from '@mui/material'
import { TabPanel } from 'shared/ui/components'
import { memo, useMemo } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import { tooltipClasses } from '@mui/material/Tooltip'
import { a11yProps } from 'shared/lib/TabsA11yProps'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

import { StyledTabPanel } from './styled'
import { PriceAdviceTab } from './tabs/priceAdviceTab/PriceAdviceTab'
import { PriceSettingsTab } from './tabs/priceSettings/PriceSettingsTab'

const TABS = [
  {
    label: 'Настройка продуктов',
    Component: PriceSettingsTab,
    text: 'На этой вкладке отображаются все продукты, включенные в\n' +
      'Волшебник цен. Здесь вы можете провести настройку\n' +
      'алгоритмов и параметров ценообразования. После\n' +
      'завершения настройки, начинается процесс формирования\n' +
      'ценовых рекомендаций.'
  },
  {
    label: 'Рекомендации',
    Component: PriceAdviceTab,
    text: 'Эта вкладка предоставляет предложения по корректировке цен продуктов\n' +
      'на основе установленных параметров и текущих рыночных условий.\n' +
      'Вы можете принять или отклонить каждую рекомендацию.\n' +
      'Если рекомендация принята, цена продукта автоматически обновится до предложенного значения.'
  }
] as const

export const Help = styled.span`
  color: #BDBDBD;
  display: inline-flex;
  align-items: center;
  :hover {
    color: #E0E0E0;
  }
  svg {
    font-size: 17px;
  }
`

const HelpWithMargin = styled(Help)`
  padding-bottom: 3px;
  padding-left: 8px;
`

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 6px 9px -7px rgba(0, 0, 0, 0.2), 0px 19px 30px 3px rgba(0, 0, 0, 0.14), 0px 9px 16px 8px rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    padding: 0,
    minWidth: '600px',
    maxWidth: '600px'
  },
}))

const StyledDescription = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  white-space: pre-line
`

export const RepriceTabs = memo(() => {
  const { searchObj, handleChangeParams } = useQueryParams({ parseNumbers: true })

  const handleTabChange = (_, newValue) => {
    handleChangeParams({
      params: {
        tab: newValue,
        limit: null,
        page: 0
      },
      options: {
        skipEmptyString: true,
        skipNull: true
      }
    })
  }
  
  const tabValue = useMemo(() => searchObj.tab ?? 1,[JSON.stringify(searchObj.tab)])
  
  return (
    <>
      <Paper>
        <StyledTabPanel>
          <Box sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              {TABS.map((el, index) => (
                <Tab
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  value={index}
                  sx={{ minWidth: '150px', maxHeight: '48px', minHeight: '48px' }}
                  icon={ <CustomTooltip title={
                    <Box>
                      <Box sx={{
                        paddingLeft: '24px',
                        height: '64px',
                        borderBottom: '1px solid #E0E0E0',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
                          {el.label}
                        </Typography>
                      </Box>
                      <Box sx={{ padding: '16px 24px' }}>
                        <StyledDescription>
                          {el.text}
                        </StyledDescription>
                      </Box>
                    </Box>
                  } placement="bottom">
                    <HelpWithMargin>
                      <InfoOutlined/>
                    </HelpWithMargin>
                  </CustomTooltip>
                  }
                  iconPosition="end"
                  label={el.label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </StyledTabPanel>

        {TABS.map(({ Component }, index) => (
          <TabPanel
            value={tabValue}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
          >
            <Component/>
          </TabPanel>
        ))}
      </Paper>
    </>
  )
}
)