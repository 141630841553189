import React from 'react'
import { useQueryClient } from 'react-query'
import { dialogModel } from 'shared/ui/components/dialog'
import { MediaDeleteConfirmModal } from 'entities/pim/MediaDeleteConfirmModal'
import { pimApi } from 'shared/api'
import {
  VideoCoverRequirements
} from 'widgets/Media/DragDropWidget/ui/Requirements/Ozon/videoCoverRequirements'
import { UploadGallery } from 'widgets/Media/DragDropWidget/ui/UploadGallery'
import { OzonMediaTypes } from 'shared/types/MediaTypes'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { MediaGalleryModal } from 'widgets/Media/MediaGalleryModal'

import { VideoCoverItem } from './VideoCoverItem'

const VIDEO_SUPPORTED_FILE_EXTENSION = ['.mp4', '.mov']

interface VideoCoverProps {
  productId: number
  cover?: pimApi.products.MediaFile
  disabled: boolean
}


export const VideoCover = ({ productId, cover, disabled }: VideoCoverProps ) => {
  const queryClient = useQueryClient()

  const { mutate: createMediaMutation, isLoading: createInProgress } =
    pimApi.products.ozon.useCreateProductMediaMutation({
      onSuccess: (response) => {
        queryClient.setQueryData<any>(pimApi.products.ozon.getOzonProductQueryKey(productId), (updater) => ({
          ...updater, videocover: response.data
        }))
      }
    })

  const { mutateAsync: validateMediaFileMutation } =
    pimApi.products.ozon.useValidateProductMediaFileMutation()
  
  const { mutate: videoCoverDelete, isLoading: deletingInProgress } =
      pimApi.products.ozon.useDeleteProductMediaMutation()

  const onFileValidate = (mediaType: OzonMediaTypes) =>
    (file, onSuccess) => {
      const formFile = new FormData()
      formFile.append('file', file)
      validateMediaFileMutation({
        productId,
        mediaType,
        file: formFile,
      }).then(res => {
        if (res.status === 200) onSuccess(res)
      })
    }

  const onFileUpload =
    (mediaType: OzonMediaTypes) =>
      (files) => {
        if (files && isNotEmptyArray(files)) {

          files.forEach(el => {
            const file = new FormData()

            file.append('file', el.file || '')

            createMediaMutation({
              productId,
              mediaType,
              file,
            })
          })
        }
      }

  const handleDelete = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) =>
        <MediaDeleteConfirmModal accept={accept} close={close} title="Удалить видеообложку?"/>,
      onAccept: () => {
        if (cover !== undefined) {
          videoCoverDelete({
            productId,
            id: cover?.id,
            mediaType: 'videocover',
            versionNo: cover?.versionNo
          }, {
            onSuccess: () => {
              queryClient.setQueryData<pimApi.products.ozon.OzonProduct>(
                pimApi.products.ozon.getOzonProductQueryKey(productId),
                (curr) => {
                  const newObj = { ...curr }
                  newObj.videocover = undefined
                  return newObj as pimApi.products.ozon.OzonProduct
                }
              )
            },
          })
        }
      }
    })
  }

  const isVideoCoverExists = !!cover

  const getModalFilePath = (mediaType: OzonMediaTypes) =>
    (photoId, size) => pimApi.products.ozon.getOzonMediaApiPath(productId, photoId, mediaType, size)
  const handleOpenGallery = (item) => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <MediaGalleryModal
          close={close}
          mediaVariation="video"
          mediaType="Видеообложка"
          getFilePath={getModalFilePath('videocover')}
          clickedItem={item}
          items={[cover]}
        />
      )
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span style={{ fontWeight: '500', color: '#000000DE' }}>Видеообложка</span>
        <span style={{ color: '#0000008A' }}>{ cover ? 1 : 0 }/1</span>
      </div>
      <UploadGallery
        mediaLimit={1}
        withMainElement={true}
        largeContainer={true}
        localMedia={cover ? [cover] : []}
        mediaType="видео"
        supportedFileExtension={VIDEO_SUPPORTED_FILE_EXTENSION}
        onUploadFile={onFileUpload('videocover')}
        onFileValidate={onFileValidate('videocover')}
        mediaRequirement={VideoCoverRequirements}
        addDisabled={isVideoCoverExists}>
        {cover && (
          [cover].map(el => (
            <VideoCoverItem
              item={el}
              productId={productId}
              openGallery={() => {handleOpenGallery(el)}}
              onCoverDelete={handleDelete}
              disabled={disabled || createInProgress || deletingInProgress}
            />
          ))
        )
        }
      </UploadGallery>
    </div>
  )
}
