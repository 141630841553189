import { tradeApi } from 'shared/api'
import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

interface ColumnName
    extends tradeApi.CoPackingOrderSource {
  actionColumn?: string
}

type ColumnNameKey = keyof ColumnName
type ColumnNames = Record<ColumnNameKey, ColumnNameKey>

export const columns: ColumnNames = {
  goodsName: 'goodsName',
  remainder: 'remainder',
  goodsCode: 'goodsCode',
  actionColumn: 'actionColumn',
  goodsId: 'goodsId'
}

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnNameKey>>
  isEditable?: boolean
}

export const sideColumnsConfig: ColumnsConfigProps = {
  isEditable: true,
  config: [
    {
      name: columns.goodsCode,
      columnName: columns.goodsCode,
      title: 'Артикул товара',
      width: 114,
      wordWrapEnabled: true,
      sortingEnabled: false,
    },
    {
      name: columns.goodsName,
      columnName: columns.goodsName,
      title: 'Наименование товара',
      width: 'auto',
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.NameLinkBlank
    },
    {
      name: columns.remainder,
      columnName: columns.remainder,
      title: 'Остаток',
      width: 104,
      sortingEnabled: false,
      cellType: CellType.QuantityInSupply
    },
    {
      name: columns.actionColumn,
      columnName: columns.actionColumn,
      title: ' ',
      sortingEnabled: false,
      width: 48,
      cellType: CellType.ActionOpenModal,
    },
  ],
}
