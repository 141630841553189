import React from 'react'
import { pimApi } from 'shared/api'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { InfoOutlined } from '@mui/icons-material'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

import { ContentRatingContainer, ContentRatingTooltip } from './styled'



const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>{children}</Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0 1px 3px 0 #0000001F, 0 1px 1px 0 #00000024, 0 2px 1px -1px #00000033',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    border: '1px solid #dadde9',
    padding: 0,
  },
}))
export const ContentRating = ({ productId, nmid }: { productId: number, nmid?: number }) => {
  const { data: ratingData } = pimApi.products.wb.useGetContentRatingQuery(productId)

  const filteredRatingData = nmid ? ratingData?.data[nmid] : undefined

  const ratingErrors = filteredRatingData?.errors

  if (!ratingData) return null

  const errorsExist = () => {
    let exist = false
    if (ratingErrors) {
      Object.values(ratingErrors).forEach(el => {
        if (isNotEmptyArray(el as any)) {
          exist = true
        }
      })
    }
    return exist
  }
  if (!filteredRatingData?.rating) {
    return null
  }

  return (
    <ContentRatingContainer>
      <span className="title-span">Качество карточки</span>
      <div className="rating-data-container">
        <span className="rating-text">{ filteredRatingData?.rating }</span>
        {errorsExist() &&
          <CustomTooltip title={
            <ContentRatingTooltip>
              <span className="tooltip-title">Что улучшить в карточке?</span>
              { Object.keys(ratingErrors).map(el => ratingErrors[el].map(elem =>
                <div className="rating-item">
                  {(typeof elem.title === 'string' && elem.title) &&
                  <span>{ elem.title }</span>
                  }
                  {(typeof elem.description === 'string' && elem.description) &&
                  <ul>
                    <li>{elem.description}</li>
                  </ul>
                  }
                </div>
              )) }
            </ContentRatingTooltip>
          }
          placement="right">
            <HelpWithMargin>
              <InfoOutlined/>
            </HelpWithMargin>
          </CustomTooltip>
        }
      </div>
    </ContentRatingContainer>
  )
}