import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface EditContragentPayload {
  contragentId: UniqueId,
  editedContragent: pimApi.admin.EditingContragent
}
export const editContragent = ({ contragentId, editedContragent }: EditContragentPayload) =>
  api.put<pimApi.admin.CurrentContragent>(
    `/pim/admin/contragents/${contragentId}`,
    editedContragent
  )


export const useEditContragentMutation = () =>
  useMutation<
        AxiosResponse<pimApi.admin.CurrentContragent>,
        AxiosError<errorType>,
        EditContragentPayload
        >(editContragent)
