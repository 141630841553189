import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'

interface IDeleteCopackingOrder {
  copackingorderId?: UniqueId,
  deletingOrder?: Partial<DeletingObject>
}

const deleteCopackingOrder = ({ copackingorderId, deletingOrder }: IDeleteCopackingOrder) =>
  api.delete(
    `/copacking/copackingorders/${copackingorderId}`,
    { data: deletingOrder }
  )

export const useDeleteCopackingOrderMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    IDeleteCopackingOrder
  >(deleteCopackingOrder)