import styled from 'styled-components'

export const StyledChip = styled.div<{ textColor: string, iconColor: string }>`
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  padding: 4px 14px 4px 8px;
  width: auto;
  height: min-content;
  
  span {
    font-size: 13px;
    color: ${({ textColor }) => textColor };
  }
  
  svg {
    width: 16px;
    height: 16px;
    color: ${({ iconColor }) => iconColor };
  }
`

export const StyledTableBox = styled.div`
  position: relative;
  table {
    width: max-content;
    min-width: 100% !important;
    font-family: "Ubuntu Mono", monospace;

    .TableNoDataCell-cell {
      height: 300px;
    }
  }

  .MuiTableCell-head {
    height: 68px;

    .CellLayout-container {
      height: inherit;
      align-items: start;
      padding: 4px 0;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.17000000178813934px;
    }
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }

  .MuiTableCell-root {
    padding: 0 16px !important;
    
    :last-child {
      border-left: 1px solid #E0E0E0;
    }
  }

  colgroup {
    col {
      :last-child {
      }
    }
  }
`
