import { AxiosError } from 'axios'
import { sulogin } from 'shared/api/auth'
import { api } from 'shared/api'
import { snackActions } from 'shared/lib/react/snackbar'

export const withSuloginRequest = async (error: AxiosError) => {
  // мега костыль, зато сулогинщикам легче живется
  const requiredSulogin = error.response?.data?.message?.includes('вы вообще-то админ')

  if (requiredSulogin) {
    snackActions.warning('Пристегнитесь, мы сулогинимся')
    sulogin({
      ...JSON.parse(error.config.data),
      customer_code: 1000
    }).then(res => api.setToken(res.data.accessToken))
  }

  return Promise.reject(error)
}