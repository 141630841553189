/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation, useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
    /** Поиск по токену */
    search_string?: string,
    /** Поиск по поставщику */
    supplier_id?: number,
    /** Поиск по получателю */
    recipient_id?: number,
    /** Поиск по бренду */
    brand_id?: number,
    /** Поиск по области */
    area_id?: number,
    /**
     * Возвращать остатки только из данной области, не включая остатки из подобластей
     * @default false
     */
    only_this_area?: boolean,
    /** Возвращать только остатки FBS */
    is_fbs?: boolean,

  }
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wms/stocks/file`,{ params: query, responseType: 'blob' })

export const usegetAreaStocksFileStocksFileGet = (options?: any) =>
  useMutation<
    ResponseType,
    errorType,
    ResponseType
  >(
    queryParams => apiFunction(queryParams),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

