import React from 'react'
import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import { Button, Box } from '@mui/material'
import { productModel } from 'entities/pim/product'
import { PRODUCT_STATUS_OZON } from 'features/product/CreateProductModal/config/productStatus'
import { PRODUCT_EDIT } from 'shared/config'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { Field, RadioGroupField } from 'shared/ui/components/form'
import { pimApi } from 'shared/api'

import { validationSchema } from './validationSchema'
import { NewOzonCard } from './NewOzonCard'
import { ExistingOzonCard } from './existingCard'
import { StyledLoadingButton } from './styled'

interface ModalFormMarketplaceProps {
  companymarketplaceId: UniqueId
  goodsId: UniqueId
  useGoodsMedia: boolean,
  handleClose: () => void
}

export const ModalFormMarketplaceOzon = ({
  companymarketplaceId,
  goodsId,
  useGoodsMedia,
  handleClose,
} : ModalFormMarketplaceProps) => {
  const history = useHistory()
  const { mutate: createProductOzon, isLoading } = pimApi.products.ozon.useCreateProductOzonMutation()
  const validate = useValidationSchema(validationSchema)

  const initialValue: productModel.ozonModel.CreateInitialFormValues = {
    cardType: 'new',
  }

  const onSubmit = (formValues: productModel.ozonModel.CreateSubmitFormValues<productModel.ozonModel.CardType>) => {
    let card
    if (formValues.cardType === 'new') {
      card = {
        categoryId: (formValues as productModel.ozonModel.CardTypeNewFormValues).category.value,
        parentCategoryId: (formValues as productModel.ozonModel.CardTypeNewFormValues).category.descriptionCategoryId
      }
    } else {
      card = {
        name: (formValues as productModel.ozonModel.CardTypeOldFormValues).name,
        offerId: (formValues as productModel.ozonModel.CardTypeOldFormValues).offerId,
        price: parseInt((formValues as productModel.ozonModel.CardTypeOldFormValues).price, 10),
        sku: parseInt((formValues as productModel.ozonModel.CardTypeOldFormValues).sku, 10),
        vat: parseInt((formValues as productModel.ozonModel.CardTypeOldFormValues).vat, 10) as pimApi.products.VatRate
      }
    }
    createProductOzon(
      {
        companymarketplaceId,
        goodsId,
        useGoodsMedia,
        card,
      },
      {
        onSuccess: ({ data }) => {
          handleClose()
          history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
        },
      }
    )
  }

  return (
    <Form<productModel.ozonModel.CreateSubmitFormValues<productModel.ozonModel.CardType>, productModel.ozonModel.CreateInitialFormValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValue}
      render={({ values, handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box mt={3}>
              <Field
                name="cardType"
                component={RadioGroupField}
                options={PRODUCT_STATUS_OZON}
                row={true}
                size="small"
                style={{ display: 'flex', gap: '10px' }}
              />
              {(values.cardType==='new') && <NewOzonCard companymarketplaceId={companymarketplaceId}/>}
              {(values.cardType==='old') && <ExistingOzonCard/>}
            </Box>
          </Box>
          <Box pt={3} sx={{ float: 'right' }}>
            <Button onClick={handleClose} color="primary">
              ОТМЕНА
            </Button>
            <StyledLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!valid}
            >
              СОЗДАТЬ
            </StyledLoadingButton>
          </Box>
        </form>
      )}
    />
  )
}