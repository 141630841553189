import React, { memo, useCallback, useState } from 'react'
import { CircularProgress, Box } from '@mui/material'
import { FieldRenderProps, useField } from 'react-final-form'
import { useSelector } from 'react-redux'
import { categorySelector, categoryList } from 'store/category'
import { prop } from 'ramda'
import { Search, Check, Close, FormatListBulleted } from '@mui/icons-material'
import usePending from 'shared/lib/hooks/PendingHook'

import {
  ActiveIconWrapper,
  ActiveRemove,
  AutocompleteStyled,
  Item,
  ItemSpan,
  PopperStyled,
  SearchButton,
  TextFieldStyled,
  Wrapper
} from './styled'

const checkToEqualValue = (option: any, selectedValue: any) => option.id === selectedValue.id

const LISTBOX_PROPS = {
  style: { maxHeight: '35vh' },
}

const EMPTY_VALUE = ''

export const CategorySelectField = memo(({
  input: { name, ...inputProps }, placeholder, label = '', meta: { invalid, touched }, disabled
}: FieldRenderProps<any>) => {
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isListVisible, setIsListVisible] = useState(false)
  const field = useField(name)

  const [getCategories, isCategoryLoading] = usePending(categoryList)
  const categories = useSelector(categorySelector)
  const list = prop('list', categories)

  const handleChange = useCallback(
    (event, option) => {
      setIsOpen(false)
      field.input.onChange({ id: option.id, fullPathName: option.fullPath })
    },
    [value]
  )

  const removeOption = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    field.input.onChange(EMPTY_VALUE)
  }, [])

  const handleInputChange = useCallback((event, newInputValue, reason) => {
    setValue(newInputValue)
    if (newInputValue.length < 3) {
      setIsListVisible(false)
      return
    }
    setIsOpen(true)
    setIsListVisible(true)

    if (reason === 'reset' && newInputValue.length > 3) {
      setIsOpen(false)
    }
    if (reason === 'input') {
      getCategories(newInputValue)
    }
  }, [])

  const PopperMy = ({ children, ...props }) =>(
    <PopperStyled
      {...props}
      $isActive={!!field.input.value}
      placement="bottom-start"
    >
      {children}
    </PopperStyled>
  )

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <Wrapper>
      <AutocompleteStyled
        {...inputProps}
        size="small"
        aria-label="Меню с выпадающим списком"
        aria-haspopup="true"
        blurOnSelect={true}
        disableClearable={true}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        ListboxProps={LISTBOX_PROPS}
        // @ts-ignore
        PopperComponent={PopperMy}
        inputValue={value}
        value={field.input.value}
        defaultValue={field.input.value}
        filterOptions={(x) => x}
        getOptionLabel={(option: any) =>
          option && option.fullPathName ? option.fullPathName : ''
        }
        isOptionEqualToValue={checkToEqualValue}
        // @ts-ignore
        options={isListVisible && list ? list : []}
        loading={isCategoryLoading}
        noOptionsText="Введите строку для поиска"
        onInputChange={handleInputChange}
        onChange={handleChange}
        disabled={disabled}
        renderOption={(props: any, option: any) => (
          <Item {...props}>
            {option.fullPath === field.input.value.fullPathName && (
              <ActiveIconWrapper>
                <Check />
              </ActiveIconWrapper>
            )}
            <Box display="flex" alignItems="center">
              {option.fullPath.split('/').map((item) => (
                <ItemSpan
                  key={item}
                  $isActive={item.toLowerCase().includes(value.toLowerCase())}
                >
                  {` ${item} `}
                </ItemSpan>
              ))}
            </Box>
            {option.fullPath === field.input.value.fullPathName && (
              <ActiveRemove onClick={removeOption}>
                Удалить <Close />
              </ActiveRemove>
            )}
          </Item>
        )
        }
        renderInput={(params) => (
          <TextFieldStyled
            {...params}
            label={label}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>{isCategoryLoading ? <CircularProgress size={20} /> : null}</>
              ),
            }}
            onKeyPress={handleKeyPress}
            error={invalid && touched}
          />
        )}
      />
      {field.input.value ? (
        <SearchButton $isActive={isOpen} type="button">
          <FormatListBulleted />
        </SearchButton>
      ) : (
        <SearchButton $isActive={isOpen} type="button">
          <Search />
        </SearchButton>
      )}
    </Wrapper>
  )
})
