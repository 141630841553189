/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditUserInfo, UserInfo } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<UserInfo>

export type IRequest = CamelCasedPropertiesDeep<{
  userId: number,
  data: EditUserInfo
}>

export const apiFunction = ({ userId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/admin/users/${userId}`,data).then(res => res.data)


export const useEditUser = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

