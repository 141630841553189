import { Route } from 'pages/interface'
import {
  ANALYTICS_PRICE_ADVICER,
  REPORTS,
  LAYOUT_WIDTH,
  ANALYTICS_CHARTS,
  ANALYTICS_EDIT_ACTIVITY
} from 'shared/config'
import { Layout } from 'shared/ui/layouts'

import { RepricePanel } from './reprice/RepricePanel'
import { ReportsPanel } from './reports/ReportsPanel'
import { ChartsPanel } from './charts/ChartsPanel'
import { EditActivityPanel } from './activities/EditActivity/EditActivityPanel'

export default () => [
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ANALYTICS_CHARTS,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: ChartsPanel,
  },
  // {
  //   protectedStatus: 'authorized',
  //   exact: true,
  //   path: ANALYTICS_ACTIVITIES,
  //   layout: Layout,
  //   layoutWidth: LAYOUT_WIDTH.table,
  //   component: ActivitiesListPanel,
  // },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ANALYTICS_EDIT_ACTIVITY,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: EditActivityPanel,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: ANALYTICS_PRICE_ADVICER,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: RepricePanel,
  },
  {
    protectedStatus: 'authorized',
    exact: true,
    path: REPORTS,
    layout: Layout,
    layoutWidth: LAYOUT_WIDTH.table,
    component: ReportsPanel,
  },
] as Array<Route>
