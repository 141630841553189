import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'

export const AutoOrderCell = ({ value }) => {
  if (value) {
    return (
      <CheckIcon style={{ color: '#4CAF50' }}/>
    )
  }
  return <RemoveIcon style={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
}