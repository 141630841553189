import { AxiosResponse } from 'axios'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  FormControl,
  InputLabel
} from '@mui/material'
import { history } from 'app/providers/with-router'
import { pimApi } from 'shared/api'
import { getBulkOperationsPath } from 'shared/config'
import { dialogModel } from 'shared/ui/components/dialog'
import { BulkOperationsResultModal } from 'widgets/product'
import { snackActions } from 'shared/lib/react/snackbar'
import { BulkFileActionsFooter } from 'shared/ui/components/BulkFileActionsFooter'
import { downloadFile } from 'shared/lib/utils'
import { useState } from 'react'



const DEFAULT_FILE_NAME =  'template.xlsx'

export const BulkSuppliersChange = () => {
  const { mutate: downloadMutation, isLoading: isDownloading } = pimApi.goods.useDownloadGoodsChangeSuppliersTemplateFile()
  const { mutate: uploadMutation, isLoading: isUploading } = pimApi.goods.useUploadGoodsChangeSuppliersFile()

  const [contragentId, setContragentId] = useState<number | null>(0)
  const { contragentListQuery } = pimApi.dictionaries.useContragentList({
    onlySuppliers: true,
  })

  const handleContragentChange = (event: SelectChangeEvent<any>) => {
    setContragentId(event.target.value)
  }

  const download = () => {
    const properContragentId = contragentId === 0 ? null : contragentId
    downloadMutation({ contragentId: properContragentId },  {
      onSuccess: (response) => {
        const file = response.data
        const fileName =
          response.headers['content-disposition']?.replace('inline; filename=', '')
          || DEFAULT_FILE_NAME

        downloadFile(file, fileName)
      },
      onError: () => {
        snackActions.error('Произошла ошибка при загрузке файла')
      }
    })
  }

  const upload = ({ file }: { file: File }) => {
    uploadMutation(
      {
        file,
      },
      {
        onSuccess: (data: AxiosResponse<pimApi.products.ImportMetadata>) => {
          dialogModel.openDialog({
            component: ({ close }) => (
              <BulkOperationsResultModal
                close={close}
                messages={data?.data.messages}
                fileUrl={data?.data.fileUrl}
                batchId={data?.data.batchId}
                syncAvailable={false}
              />
            ),
            onAccept: () => {},
          })
        },
        onError: () => {
          snackActions.error('Произошла ошибка при загрузке файла')
        },
      }
    )
  }

  const handleGoBack = () => {
    history.replace(getBulkOperationsPath('goods'))
  }

  return (
    <Box>
      <Typography fontSize="24px" fontWeight={400} lineHeight="133%">
        Изменение поставщиков товаров
      </Typography>
      <Typography fontSize="16px" fontWeight={400} color="#757575">Товары</Typography>
      <Box display="flex" justifyContent="end" mt={3} mb={1}>
        <Typography variant="body2" gutterBottom={true} component="span">
          Импорт возможен только через шаблон в форматах *.xls, *.xlsx.
        </Typography>
      </Box>
      <FormControl variant="outlined" fullWidth={true} size="small">
        <InputLabel id="suppliers">Поставщик</InputLabel>
        <Select
          label="Поставщик"
          onChange={handleContragentChange}
          value={contragentId}
        >
          <MenuItem key="Без поставщика" value={0}>
            Без поставщика
          </MenuItem>

          {contragentListQuery?.data?.map((item) => (
            <MenuItem key={item.shortName} value={item.id}>
              {item.shortName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <BulkFileActionsFooter
        download={download}
        upload={upload}
        isUploading={isUploading}
        isDownloading={isDownloading}
        onGoBack={handleGoBack}
      />
    </Box>
  )
}
