import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { tradeApi } from 'shared/api'
import { FlexWrapper } from 'shared/ui/components'
import { format } from 'date-fns'
import { getServerReadableDateTime } from 'shared/lib/utils'
import { DateFormats } from 'shared/config/dateFormats'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders/useSupplyOrderContext'
import { Edit } from '@mui/icons-material'
import * as React from 'react'
import styled from 'styled-components'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { EditOrderModal } from 'features/supplyOrders/EditOrderModal/EditOrderModal'

import { InfoText, TitleInfoText } from './ui/InfoText'
import {
  CustomAccordion,
  OrderInfo,
  TotalSum,
  TitleOrderInfo,
  OrderInfoItem,
  AuthorDate,
  OrderInfoTitleText
} from './ui/styled'
import { InfoHeaderText } from './ui/HeaderText'
import { priceConverter } from './lib/priceConverter'
import { Status } from './ui/Status'

import { Settings } from '../Settings'

export const EditIcon = styled(Box)`
  position: absolute;
  right: 60px;
  top: 88px;
  z-index: 1;
  font-size: 18px;
  color:rgba(0, 0, 0, 0.38);

  svg{
    cursor: pointer;
    margin-bottom: 12px;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`
export const Header = ({
  orderNumber,
  statusId,
  creator,
  company,
  marketplace,
  brand,
  contragent,
  estimatedSupplyDate,
  changed,
  marketplacewarehouse,
  days,
  comments,
  totalSumm,
  orderDate,
  productspositions,
  ordersStatFromDate,
  ordersStatToDate,
  useBoxes,
  created,
  ignoreOrdersStatFromDate,
  ignoreOrdersStatToDate,
  versionNo,
  id,
  editPossible  
}: Partial<tradeApi.SupplyOrder>) => {

  const { supplyOrderQuery } = useSupplyOrderContext()

  const quantityOfProducts = () => {
    if (productspositions) return productspositions.length
    return 0
  }
  const calculationPeriod = () => {
    if (ordersStatFromDate && ordersStatToDate) {
      const convertDate = (val: string) => format(new Date(getServerReadableDateTime(new Date(val))),
        DateFormats.dayMonthYear)
      const excludeDate = ignoreOrdersStatFromDate && ignoreOrdersStatToDate
        ? `(исключая: ${convertDate(ignoreOrdersStatFromDate)} - ${convertDate(ignoreOrdersStatToDate)})`
        : ''

      return `${convertDate(ordersStatFromDate)} - ${convertDate(ordersStatToDate)} ${excludeDate}`
    }
    return ''
  }

  const { mutate: editSupplyOrderData } =
      tradeApi.orders.useEditSupplyOrderNumberMutation()

  const handleOrderChange = (newOrderData) => {
    if (id && versionNo && newOrderData) {
      editSupplyOrderData( {
        supplyOrderId: id,
        data: {
          id,
          versionNo,
          orderNumber: newOrderData.orderNumber,
          comments: newOrderData.comments
        }
      }, {
        onSuccess: () => {
          supplyOrderQuery.refetch().then()
          snackActions.info('Изменение сохранено!')
        },
      })
    }}



  const handleOpenEditOrderModal = () => {
    dialogModel.openDialog({
      component: ({ close }) => (
        <EditOrderModal
          handleOrderChange={handleOrderChange}
          orderNumber={orderNumber}
          comments={comments}
          close={close}
        />
      )
    })
  }

  return (
    <Box p={3} pb={0} minHeight="121px" position="relative">
      {
        (!supplyOrderQuery.data)
          ? <></>
          : 
          <> 
            <FlexWrapper>
              <Box flexGrow={1}>
                <AuthorDate >
                  <TitleInfoText title="Автор" text={creator}/>
                  <OrderInfoTitleText> | </OrderInfoTitleText>
                  <TitleInfoText title="Создан" type="date" text={created}/>
                </AuthorDate>
                <TitleOrderInfo>
                  <InfoHeaderText title="Заказ" text={orderNumber}/>
                  <Status statusId={statusId} />
                  <Settings />
                </TitleOrderInfo>
              </Box>
              <Box>
                <Box textAlign="right">
                  <TitleInfoText title="Дата заказа" type="date" text={orderDate}/>
                </Box>
                <TotalSum>
                  { priceConverter(totalSumm) }
                </TotalSum>
              </Box>
            </FlexWrapper>
            {editPossible &&
            <EditIcon>
              <Edit
                onClick={handleOpenEditOrderModal}
              />
            </EditIcon>
            }

            <CustomAccordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                />
                <AccordionDetails>
                  <OrderInfo>
                    <InfoText title="Поставщик" text={contragent}/>
                    <InfoText title="Бренд" text={brand}/>
                    <InfoText title="Продавец" text={company}/>
                    <InfoText title="Маркетплейс" text={marketplace}/>
                    <InfoText title="РЦ" text={marketplacewarehouse}/>
                    <InfoText title="Количество дней для расчета" text={days}/>
                    <InfoText title="Количество продуктов в заказе" text={quantityOfProducts()}/>
                    <InfoText 
                      title="На основании заказов за период" 
                      text={calculationPeriod()}
                    />
                    <InfoText title="Дата последнего изменения" text={changed} type="date"/>
                    <InfoText title="Ожидаемая дата поставки" text={estimatedSupplyDate} type="date"/>
                    { useBoxes
                      ? <OrderInfoItem>Сформирован кратно упаковке</OrderInfoItem>
                      : <OrderInfoItem>Сформирован без учета упаковки</OrderInfoItem>
                    }
                    <InfoText title="Комментарии" text={comments}/>
                  </OrderInfo>
                </AccordionDetails>
              </Accordion>
            </CustomAccordion>
          </>
      }
      
    </Box>
  )
}
