import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import {
  Box,
  DialogActions,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { customerApi } from 'shared/api'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { InputField, InputLabel } from 'shared/ui/components'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import isEmpty from 'lodash/isEmpty'
import {
  useGetMarketplaceInfoQuery,
} from 'shared/api/customer/companies/getMarketplaceInfo'
import { StyledField } from 'shared/ui/components/StyledField/StyledField'

import { ModalContainer, Header, Subheader } from './styled'
import KeyInputField from './ui/KeyInputField'
import CheckBoxInputField from './ui/CheckBoxField'

import { cabinetValidationSchema } from '../../lib/cabinetValidationSchema'


interface AddCabinetModalProps {
    close: () => void;
    handleEditCabinet: (cabinetData: any) => void
    cabinetData: customerApi.CompanyMarketplace
    count?: string
}

export const EditCabinetModal = ({
  close,
  handleEditCabinet,
  cabinetData
}: AddCabinetModalProps) => {

  const onSubmit = (formData) => {
    handleEditCabinet(formData)
    close()
  }


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && event.target.value === undefined) {
        const button = document.getElementById('submit')
        event.preventDefault()
        button?.click()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const { data } = useGetMarketplaceInfoQuery(cabinetData.marketplaceId)
  const marketplaceInfo = data

  const keysDataPrepare = () => {
    const newKeys = [] as customerApi.CompanyMarketplaceCredential[]
    if (marketplaceInfo && marketplaceInfo.credentials) {
      marketplaceInfo?.credentials.forEach(cred => {
        const findKey = cabinetData.credentials?.find((filledCred) => cred.id === filledCred.credentialId)
        if (findKey) {
          newKeys.push(findKey)
        } else newKeys.push(
          {
            credentialId: cred.id,
            credentialName: cred.name ?? '',
            inArchive: false,
            secretFlag: cred.secretFlag,
            value: '',
            valueSet: false
          })
      }
      )
    }
    return { ...cabinetData, credentials: newKeys }
  }
  
  const initialValue = keysDataPrepare()

  const validate = useValidationSchema(cabinetValidationSchema)

  return (
    <ModalContainer open={true}>
      <Box p={3}>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <Header>
          Кабинет {cabinetData.cabinetName}
        </Header>
        <Subheader>
          {cabinetData.marketplaceName}
        </Subheader>
        <Box mt={3} >
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValue}
            mutators={{
              ...arrayMutators
            }}
            render={({ 
              handleSubmit, 
              errors,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <InputLabel
                    label="Наименование"
                    required={true}
                  />
                  <StyledField sx={{ marginBottom: '36px' }}>
                    <Field
                      name="cabinetName"
                      placeholder="Наименование"
                      helperText={errors && errors?.cabinetName ? errors?.cabinetName : ''}
                      required={true}
                      fullWidth={true}
                      component={InputField}
                    />
                  </StyledField>
                  <FieldArray name="credentials">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <Box mt={3} position="relative">
                          <InputLabel
                            label={values.credentials[index].credentialName}
                            required={false}
                          />
                          <Field
                            name={`${name}.value`}
                            fieldData={values.credentials[index]}
                            component={KeyInputField}
                            required={false}
                            fullWidth={true}
                          />
                          <Field 
                            name={`${name}.valueSet`} 
                            fieldData={values.credentials[index]} 
                            component={CheckBoxInputField} 
                            required={false} 
                            fullWidth={false} 
                          /> 
                        </Box>
                      ))
                    }
                  </FieldArray>
                </Box>

                <DialogActions sx={{
                  marginTop: '32px',
                  padding: 0,
                }}>

                  <Box>
                    <Button
                      color="primary"
                      onClick={close}
                    >
                      ОТМЕНА
                    </Button>
                    <LoadingButton
                      id="submit"
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={false}
                      disabled={!isEmpty(errors)}
                      sx={{ marginLeft: 2 }}
                    >
                      ИЗМЕНИТЬ
                    </LoadingButton>
                  </Box>
                </DialogActions>
              </form>
            )
            }
          />
        </Box>
      </Box>
    </ModalContainer>
  )
}



