/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo, memo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { TextField, Typography } from '@mui/material'
import { MemoIcon } from 'entities/pim/goods'
import { Select } from 'shared/ui/components'
import { pimApi, reviewApi } from 'shared/api'
import { GoodsTypeName } from 'shared/config/goods'
import { Option } from 'shared/ui/components/interface'
import { AutocompleteSelect } from 'shared/ui/components/AutocompleteSelect'

import { StyledHeaderIconButton, StyledTableHeaderWrapper, StyledFilter, SelectWrapper, ClassWrapper } from './ui/styled'

import { FilterType, SearchObj } from '../../model/types'

interface OptionItem {
  name?: string
  value?: string
  id: number
  shortName?: string
}

export enum TableHeaderCellAlign {
    left = 'left',
    right = 'right',
    center = 'center',
}

export const getOptions = (optionItem: OptionItem) => ({
  label: optionItem.name || optionItem.shortName || optionItem.value || '',
  value: optionItem.id,
})

interface TableHeaderCellProps {
  filterType: string
  childrenToRender: any
  onFilterChange: (any) => void
  title: string
  align?: TableHeaderCellAlign
}

export const TableHeaderByFilterType = memo(
  ({ filterType, onFilterChange, title }: TableHeaderCellProps) => {
    const { search } = useLocation()
    const searchObj: SearchObj = queryString.parse(search)
    const {
      companyId,
      marketplaceId,
      brandId,
      cardStatus,
      syncStatus,
      goodsType = '',
      contragentId,
      autoOrdering,
      statusId,
      abcClass,
      xyzClass,
      templateModelId
    } = searchObj

    const autoOrderingList = [
      {
        value: 0,
        label: 'Нет',
        order: 'false'
      },
      {
        value: 1,
        label: 'Есть',
        order: 'true'
      }
    ]

    const abcClassOptions = [
      {
        value: '',
        label: '-',
      },
      {
        value: 'A',
        label: 'A',
        color: '#4CAF50'
      },
      {
        value: 'B',
        label: 'B',
        color: '#FFB547'
      },
      {
        value: 'C',
        label: 'C',
        color: '#F50057'
      }
    ]

    const xyzClassOptions = [
      {
        value: '',
        label: '-',
      },
      {
        value: 'X',
        label: 'X',
        color: '#4CAF50'
      },
      {
        value: 'Y',
        label: 'Y',
        color: '#FFB547'
      },
      {
        value: 'Z',
        label: 'Z',
        color: '#F50057'
      }
    ]


    const defaultAutoOrder = autoOrderingList.find(
      // @ts-ignore
      (autoOrder) => `${autoOrder.order}` === autoOrdering
    )

    const defaultAbcClass = abcClassOptions.find(
      (abc) => abc.value === abcClass || ''
    )

    const defaultXyzClass = xyzClassOptions.find(
      (xyz) => xyz.value === xyzClass || ''
    )
    const handleChange = useCallback(
      (e) => {
        if (filterType !== 'autoOrdering') {
          // @ts-ignore
          onFilterChange(
            {
              [filterType]: e?.target ? e?.target.value : e?.value,
              page: 0
            }
          )
        } else {
          onFilterChange(
            {
              [filterType]: e === null ? e?.value : !!e?.value,
              page: 0
            }
          )
        }
      },
      [filterType]
    )

    switch (filterType) {
      case FilterType.GoodsType: {
        const goodsTypeOptions: Array<Option<string>> = [
          {
            value: '',
            label: '-',
          },
          ...Object.entries(GoodsTypeName).map(([value, label]) => ({
            value,
            label,
            icon: (
              <MemoIcon
                goodsType={value as pimApi.goods.GoodsType}
                color="rgba(0, 0, 0, 0.38)"
              />
            ),
          })),
        ]
        return (
          <SelectWrapper>
            <Select
              label="Тип"
              name="goodsType"
              value={ goodsType }
              onChange={ handleChange }
              options={ goodsTypeOptions }
              IconComponent={ ({ onClick }) =>
                <StyledHeaderIconButton
                  aria-haspopup="true"
                  aria-label="sort"
                  id="long-button"
                  onClick={ onClick }
                >
                  <StyledFilter $hasValue={ !!goodsType }/>
                </StyledHeaderIconButton>
              }
            />
          </SelectWrapper>
        )
      }

      case FilterType.Company: {
        const { data: companyListQuery } = pimApi.dictionaries.useCompanyList().companyListQuery
        const companyOptions = useMemo(
          () => companyListQuery?.map(getOptions) || [],
          [companyListQuery]
        )
        const defaultCompany = companyOptions.find(
          (company) => `${company.value}` === companyId
        )
        return (
          <AutocompleteSelect
            label="Продавец"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultCompany }
            variant="standard"
            options={ companyOptions }
            popupIcon={ <StyledFilter $hasValue={ !!defaultCompany }/> }
          />
        )
      }

      case FilterType.AutoOrdering:
        return (
          <AutocompleteSelect
            label="Автозаказ"
            fullWidth={true}
            onChange={handleChange}
            value={defaultAutoOrder}
            variant="standard"
            options={autoOrderingList}
            popupIcon={<StyledFilter $hasValue={!!defaultAutoOrder} />}
          />
        )

      case FilterType.AbcClass:
        return (
          <ClassWrapper>
            <Select
              label="ABC"
              name="class"
              value={abcClass}
              onChange={handleChange}
              defaultValue={defaultAbcClass}
              options={abcClassOptions}
              IconComponent={({ onClick }) =>
                <StyledHeaderIconButton
                  aria-haspopup="true"
                  aria-label="sort"
                  id="long-button"
                  sx={{ marginLeft: '10px' }}
                  onClick={onClick}
                >
                  <StyledFilter $hasValue={!!abcClass}/>
                </StyledHeaderIconButton>
              }
            />
          </ClassWrapper>
        )

      case FilterType.XyzClass:
        return (
          <ClassWrapper>
            <Select
              label="XYZ"
              name="class"
              defaultValue={defaultXyzClass}
              value={xyzClass}
              onChange={handleChange}
              options={xyzClassOptions}
              IconComponent={({ onClick }) =>
                <StyledHeaderIconButton
                  aria-haspopup="true"
                  aria-label="sort"
                  id="long-button"
                  sx={{ marginLeft: '10px' }}
                  onClick={onClick}
                >
                  <StyledFilter $hasValue={!!xyzClass}/>
                </StyledHeaderIconButton>
              }
            />
          </ClassWrapper>
        )

      case FilterType.Marketplace: {
        const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery

        const marketplaceOptions = useMemo(
          () => marketplaceListQuery?.map(getOptions) || [],
          [marketplaceListQuery]
        )

        const defaultMarketplace = marketplaceOptions.find(
          (marketplace) => `${marketplace.value}` === marketplaceId
        )
        return (
          <AutocompleteSelect
            label="Маркетплейс"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultMarketplace }
            variant="standard"
            options={ marketplaceOptions }
            popupIcon={ <StyledFilter $hasValue={ !!defaultMarketplace }/> }
          />
        )
      }

      case FilterType.Brand: {
        const { data: brandListQuery } = pimApi.dictionaries.useBrandListQuery()
        const brandOptionsList = useMemo(
          () => brandListQuery?.map(getOptions) || [],
          [brandListQuery]
        )
        const defaultBrand = brandOptionsList.find(
          (brand) => `${brand.value}` === brandId
        )
        return (
          <AutocompleteSelect
            label="Бренд"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultBrand }
            variant="standard"
            options={ brandOptionsList }
            popupIcon={ <StyledFilter $hasValue={ !!defaultBrand }/> }
          />
        )
      }

      case FilterType.CardStatus: {
        const { data: cardStatusListQuery } = pimApi.dictionaries.useCardStatusListQuery()

        const cardStatusOptions = useMemo(
          () => cardStatusListQuery?.map(getOptions) || [],
          [cardStatusListQuery]
        )

        const defaultCardStatus = cardStatusOptions.find(
          (status) => `${status.value}` === cardStatus
        )

        return (
          <AutocompleteSelect
            label="Статус продажи"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultCardStatus }
            variant="standard"
            options={ cardStatusOptions }
            popupIcon={ <StyledFilter $hasValue={ !!defaultCardStatus }/> }
          />
        )
      }

      case FilterType.SyncStatus: {
        const { data: syncStatusListQuery } = pimApi.dictionaries.useSyncStatusListQuery()

        const syncStatusOptions = useMemo(
          () => syncStatusListQuery?.map(getOptions) || [],
          [syncStatusListQuery]
        )

        const defaultSyncStatus = syncStatusOptions.find(
          (status) => `${status.value}` === syncStatus
        )

        return (
          <AutocompleteSelect
            label="Статус синхронизации"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultSyncStatus }
            variant="standard"
            options={ syncStatusOptions }
            popupIcon={ <StyledFilter $hasValue={ !!defaultSyncStatus }/> }
          />
        )
      }

      case FilterType.Contragent: {
        const { data: contragentListQuery } = pimApi.dictionaries.useContragentListQuery({ onlySuppliers: true })

        const contragentOptionList = useMemo(
          () => contragentListQuery?.map(getOptions) || [],
          [contragentListQuery]
        )

        const defaultContragent = contragentOptionList.find(
          (contragent) => `${contragent.value}` === contragentId
        )
        return (
          <AutocompleteSelect
            label="Поставщик"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultContragent }
            variant="standard"
            options={ contragentOptionList }
            popupIcon={ <StyledFilter $hasValue={ !!defaultContragent }/> }
          />
        )
      }

      case FilterType.OrderStatus: {
        const { data: supplyOrderStatusQuery } = pimApi.dictionaries.useSupplyOrderStatusQuery()

        const supplyOrderStatusList = useMemo(
          () => supplyOrderStatusQuery?.sort( (a, b) => a.id - b.id).map(getOptions) || [],
          [supplyOrderStatusQuery]
        )

        const defaultSupplyOrderStatus = supplyOrderStatusList.find(
          (status) => `${status.value}` === statusId
        )

        return (
          <AutocompleteSelect
            label="Статус"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultSupplyOrderStatus }
            variant="standard"
            options={ supplyOrderStatusList }
            popupIcon={ <StyledFilter $hasValue={ !!defaultSupplyOrderStatus }/> }
          />
        )
      }
      case FilterType.ProductCode:
        return (
          <TextField
            label="Артикул"
            fullWidth={true}
            onChange={handleChange}
            variant="standard"
            // options={supplyOrderStatusList}
            // popupIcon={<StyledFilter $hasValue={!!defaultSupplyOrderStatus}/>}
          />
        )

      case FilterType.TemplateModel: {
        const { data: templateModelListQuery } = reviewApi.templates.useGetTemplatesModelQuery()

        const templateModelOptions = useMemo(
          () => templateModelListQuery?.map(getOptions) || [],
          [templateModelListQuery]
        )

        const defaultTemplateModel = templateModelOptions.find(
          (status) => `${status.value}` === templateModelId
        )

        return (
          <AutocompleteSelect
            label="Модель"
            fullWidth={ true }
            onChange={ handleChange }
            value={ defaultTemplateModel }
            variant="standard"
            options={ templateModelOptions }
            popupIcon={ <StyledFilter $hasValue={ !!defaultTemplateModel }/> }
          />
        )
      }

      default:
        return (
          <Typography
            variant="body1"
            component="span"
            fontSize={14}
            fontWeight={500}
          >
            {title}
          </Typography>
        )
    }
  }
)



export const TableHeaderCellComponent = ({
  column,
  children,
  onFilterChange,
  align
}) => (
  <StyledTableHeaderWrapper align={align}>
    <TableHeaderByFilterType
      filterType={column.filterType}
      title={column.title}
      childrenToRender={children}
      onFilterChange={onFilterChange}
    />
    {children}
  </StyledTableHeaderWrapper>
)


export const MemoTableHeaderCellComponent = memo(TableHeaderCellComponent)
