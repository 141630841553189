import React from 'react'
import styled from 'styled-components'
import { formatDateToDDMMYY } from 'shared/lib/utils/formatDate'
import { generatePath } from 'react-router'
import { DOC_DETAIL_URL } from 'shared/config'

import { StyledLink } from '../../../../../styled/StyledLink'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  span {
    white-space: nowrap;
  }
  
  a {
    word-wrap: break-word;
    word-break: break-all;
    white-space: nowrap; 
    hyphens: manual;
  }
`

export const AcceptanceDataCell = ({ row }) => (
  <Wrapper>
    <StyledLink to={generatePath(DOC_DETAIL_URL, { id: row.receiptdocId })}>{ row.docNumber }</StyledLink>
    <span>{ formatDateToDDMMYY(row.acceptanceDate) }</span>
  </Wrapper>
)