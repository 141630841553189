import { useState } from 'react'
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  Toolbar,
  TableHeaderRow,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  ColumnChooser,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { DocType } from 'shared/services/interfaces/doc'
import { getRowsPerPageOptions, pagingPanelLocale, tableHeaderRowLocale, tableLocale } from 'shared/ui/components/Table/lib'

import  { Wrapper } from './style'
import { columnsConfig } from './columnsConfig'
import {
  TableCellComponent, TableRow,
  ToggleButtonComponent, PagingContainerComponent,
  ToolbarRootComponent, TableHeaderRowComponent,
  TableHeaderCellComponent, TableComponent,
} from './ui'

import { SearchObj } from '../interfaces'

const ASC = 'asc'
const DESC = 'desc'

interface Props {
  tableList: DocType[];
}

interface SortType {
  columnName: string;
  direction: typeof ASC | typeof DESC;
}

const getRowId = (row: DocType) => row.id
const tableColumnOrder = columnsConfig.map(column => column.columnName)

export const DocTable = ({ tableList }: Props) => {
  const { search } = useLocation()
  const history = useHistory()

  const searchObj: SearchObj = queryString.parse(search)
  const rowHeight = 49
  const tableHeight = 710
  const bonusCols = Math.floor((window.innerHeight - tableHeight) / rowHeight)
  const calculatedRowsPerPage = bonusCols > -4 ? 5 + bonusCols : 5
  const currentRowsPerPage = searchObj.limit && parseInt(searchObj.limit, 10) ? parseInt(searchObj.limit, 10) : calculatedRowsPerPage
  const page = parseInt(searchObj.page || '1', 10)
  const { sortBy } = searchObj
  const pageSizes = getRowsPerPageOptions(tableList.length, calculatedRowsPerPage)

  const [currentPage, setCurrentPage] = useState(page ? page - 1 : 0)
  const [pageSize, setPageSize] = useState(currentRowsPerPage || calculatedRowsPerPage)
  const [sorting, setSorting] = useState<SortType[]>(() => {
    if (!sortBy) return []
    const columnName =  sortBy[0] === '-' ? sortBy.slice(1) : sortBy
    const direction = sortBy[0] === '-' ? DESC : ASC
    return  [{ columnName, direction }]
  })

  const historyPush = (filterStr: string) => {
    history.push({ search: `?${filterStr}` })
  }

  const onPageSizeChange = (currentPageSize: number) => {
    const filterStr = queryString.stringify({ ...searchObj, limit: currentPageSize })
    historyPush(filterStr)
    setPageSize(currentPageSize)
  }

  const onCurrentPageChange = (currentPageNumber: number) => {
    const filterStr = queryString.stringify({ ...searchObj, page: currentPageNumber + 1 })
    historyPush(filterStr)
    setCurrentPage(currentPageNumber)
  }

  const onSortingChange = (sortType: SortType[]) => {
    const prevSort = sorting[0]
    const nextSort = sortType[0]

    if (prevSort?.columnName === nextSort?.columnName && prevSort?.direction === DESC) {
      setSorting([])

      const filterStr = queryString.stringify({ ...searchObj, sortBy: undefined })
      historyPush(filterStr)
      return
    }

    const newSortBy = nextSort.direction === ASC ? nextSort.columnName : `-${nextSort.columnName}`
    const filterStr = queryString.stringify({ ...searchObj, sortBy: newSortBy })
    historyPush(filterStr)
    setSorting(sortType)
  }

  const showPagingPanel = calculatedRowsPerPage < tableList.length

  return (
    <Wrapper>
      <Grid
        rows={tableList}
        columns={columnsConfig}
        getRowId={getRowId}
      >
        <SortingState
          sorting={sorting}
          onSortingChange={onSortingChange}
        />
        <IntegratedSorting />

        <PagingState
          pageSize={pageSize}
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
          onPageSizeChange={onPageSizeChange}
        />
        <IntegratedPaging />
        {showPagingPanel && (
          <PagingPanel
            pageSizes={pageSizes}
            messages={pagingPanelLocale}
            containerComponent={PagingContainerComponent}
          />
        )}
        
        <Table
          columnExtensions={columnsConfig}
          cellComponent={TableCellComponent}
          rowComponent={TableRow}
          messages={tableLocale}
          tableComponent={TableComponent}
        />
        <Toolbar rootComponent={ToolbarRootComponent} />
        <TableColumnVisibility/>
        <ColumnChooser toggleButtonComponent={ToggleButtonComponent} />

        <DragDropProvider />
        <TableColumnReordering defaultOrder={tableColumnOrder} />

        <TableHeaderRow
          showSortingControls={true}
          messages={tableHeaderRowLocale}
          cellComponent={TableHeaderCellComponent}
          rowComponent={TableHeaderRowComponent}
        />
      </Grid>
    </Wrapper>
  )
}
