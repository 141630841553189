import React, { useMemo } from 'react'
import { hasRoleAccess } from 'shared/api/base'
import { convertDate } from 'shared/lib/utils/DateAppearanceSettings'
import { checkValue, isNil } from 'shared/lib/checkers'
import { snackActions } from 'shared/lib/react/snackbar'
import { sort } from 'shared/lib/utils/sort'
import { tradeGen } from 'shared/lib/generated'
import { arrOfNum } from 'shared/lib/transform'
import { isEmptyArray, isNotEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { useSupplyOrderContext } from 'entities/trade/supplyOrders'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { useQueryClient } from 'react-query'
import { tradeApi } from 'shared/api'
import { toSimpleDate } from 'shared/lib/utils/dateToString'

import { CircularProgressStyled, StyledTable } from '../styled'
import { getClassName, groupByKey } from '../../lib'
import { getCoefficientTitle } from '../../lib/getCoefficientTitle'
import { PlugOrdersCoefficient } from '../PlugOrdersCoefficient'

interface ICoefficientTable {
  distributionData: tradeGen.orders.Getdistributions.ResponseType
  distributionIsLoading: boolean
}

export const CoefficientTable = ({ distributionData, distributionIsLoading }: ICoefficientTable) => {
  const { searchObj } = useQueryParams({ arrayFormat: 'bracket', parseNumbers: true })
  const { searchString, boxTypeId }  = searchObj
  const queryClient = useQueryClient()
  
  const { supplyOrderQuery } = useSupplyOrderContext()
  
  const { data: coefficientData, isLoading: coefficientIsLoading } =
    tradeGen.orders.Getgetacceptancecoefficients.useGetgetacceptancecoefficients({
      query: {
        dateFrom: toSimpleDate(distributionData.acceptancecoefficientsSettings.dateFrom),
        dateTo: toSimpleDate(distributionData.acceptancecoefficientsSettings.dateTo),
        searchString: searchString?.toString(),
        boxTypeId: arrOfNum(boxTypeId),
      } })
  
  const { mutate: generateDistribution, isLoading: isGenerating } = tradeGen.orders.GenerateDistributions.useGenerateDistributions()
  
  const groupedCoefficients = useMemo(() => {
    if (coefficientData) {
      const dateGroup = groupByKey(sort(coefficientData, 'date', 'desc'), 'date')

      const warehouseGroup = groupByKey(coefficientData, 'warehouseName')
      const boxTypeGroup = Object.keys(warehouseGroup)
        .map(key => ({ [key]: groupByKey(sort(warehouseGroup[key], 'boxTypeId', 'desc'), 'boxTypeName') }))

      return { dateGroup, boxTypeGroup }
    }
    return null
  },[coefficientData])
  const handleCoefficientChoose = (item: tradeApi.AcceptanceCoefficients) => {
    let successSnackbarText: string
    let selectedDistributions = distributionData?.acceptancecoefficientIds ? [...distributionData?.acceptancecoefficientIds] : []

    if (selectedDistributions?.includes(item.id)) {
      successSnackbarText = 'Склад удален из распределения по РЦ'
      selectedDistributions = selectedDistributions.filter(el => el !== item.id)
    } else {
      successSnackbarText = 'Склад участвует в распределении по РЦ\n'
      selectedDistributions?.push(item.id)
    }
    generateDistribution({
      copackingorderId: supplyOrderQuery.data?.copackingorderId!,
      data: {
        id: supplyOrderQuery.data?.copackingorderId!,
        acceptancecoefficientIds: selectedDistributions || [item.id]
      }
    }, {
      onSuccess: () => {
        queryClient.setQueryData<tradeGen.orders.Getdistributions.ResponseType>(tradeGen.orders.Getdistributions.getQueryKey({
          copackingorderId: supplyOrderQuery.data?.copackingorderId!, query: { searchString: checkValue(searchString) }
        }),
        (updater) => ({
          ...updater,
          acceptancecoefficientIds: selectedDistributions || [item.id],
          status: { code: 429, message: '' }
        }) as tradeGen.orders.Getdistributions.ResponseType)
        snackActions.info(successSnackbarText)
      }
    })
  }

  const tableIsLoading = isGenerating || distributionIsLoading || coefficientIsLoading
  
  return (
    <>
      {tableIsLoading ? <CircularProgressStyled size={60} /> : null}
      { ((isNil(coefficientData) || isEmptyArray(coefficientData)) && !tableIsLoading) && <PlugOrdersCoefficient/>  }
      {(coefficientData && isNotEmptyArray(coefficientData)) ? 
        <StyledTable $readOnly={ !hasRoleAccess('LOGISTICS') } $isLoading={ isGenerating }>
          <div>

            <div className="table-header">
              <div>Склад</div>
              <div>Тип приемки</div>

              { Object.keys(groupedCoefficients?.dateGroup).map(dateKey => (
                <div key={ dateKey }>{ convertDate(dateKey) }</div>
              )) }

            </div>

            <div className="table-body">
              { groupedCoefficients?.boxTypeGroup.map(warehouseElem => (
                <div className="warehouse-item-row" key={ JSON.stringify(warehouseElem) }>
                  { Object.keys(warehouseElem).map(warehouseKey => (
                    <div key={ JSON.stringify(warehouseElem[warehouseKey]) }>

                      <div>{ warehouseKey }</div>

                      <div className="box-type-cell">
                        { Object.keys(warehouseElem[warehouseKey]).map(boxTypeKey => (
                          <div key={ boxTypeKey }>{ boxTypeKey }</div>
                        )) }
                      </div>

                      <div className="coefficient-cell-container">
                        { Object.values(warehouseElem[warehouseKey]).map<any>(boxTypeArray => (
                          <div key={ JSON.stringify(boxTypeArray) }>
                            { Object.keys(groupedCoefficients.dateGroup).map(dateKey => {
                              const boxTypeElement = [...boxTypeArray as any].find(el => el.date === dateKey)
                              if (boxTypeElement) return (
                              // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                                <div
                                  key={ boxTypeElement.id }
                                  onClick={ () => handleCoefficientChoose(boxTypeElement) }
                                  className={ getClassName(boxTypeElement, distributionData?.acceptancecoefficientIds) }>
                                  { getCoefficientTitle(boxTypeElement.coefficient) }
                                </div>
                              )
                              return <div key="null-element" className="box-type-coefficient disabled" />
                            }
                            ) }
                          </div>
                        )) }
                      </div>
                    </div>
                  )) }
                </div>
              )) }
            </div>
          </div>

        </StyledTable>
        :
        <></>
      }
    </>
  )
}