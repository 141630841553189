import React from 'react'
import { Form } from 'react-final-form'
import {
  Box,
  Typography,
} from '@mui/material'

import { StyledLink } from './styled'
import { BulkPriceChangeForm } from './BulkPriceChangeForm'

const INSTRUCTION_LINK = 'https://customer.e-comportal.com/cdn/media/products_pricechange_instruction'

export const BulkProductPriceChange = () => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize="24px" fontWeight={400}>
        Массовое изменение цен
      </Typography>
      <StyledLink href={INSTRUCTION_LINK} color="inherit">Инструкция массового изменения цен</StyledLink>
    </Box>

    <Form
      onSubmit={() => {
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <BulkPriceChangeForm/>
        </form>
      )}
    />
  </Box>
)
