import { Grid } from '@mui/material'
import styled from 'styled-components'

export const StyledField = styled(Grid)`
  position: relative;
  .MuiFormHelperText-root {
    width: 300px;
    position: absolute;
    bottom: -24px;
    margin-left: 0;
  }
`