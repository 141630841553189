import React, { useMemo } from 'react'
import { pimApi } from 'shared/api'
import { Table } from 'shared/ui/components/Table'
import { IMenuItem } from 'shared/ui/components'
import { dialogModel } from 'shared/ui/components/dialog'
import { ModalContent } from 'shared/ui/components/ModalComponents'
import { useQueryClient } from 'react-query'
import {
  reactQueryCacheDelete,
  reactQueryCacheUpdateByKey,
} from 'shared/lib/reactQuery'
import { snackActions } from 'shared/lib/react/snackbar'
import { isNotNil } from 'shared/lib/checkers'

import { NestedWidgetContainer } from './styled'
import { WidgetHeader } from './WidgetHeader'
import { ValuesPlug } from './ValuesPlug'

import { columnsConfig } from '../lib/columnsConfig'
import { pagingPanelLocale } from '../lib/localization'
import { AttributesValuesModal } from '../../modal/AttributesValuesModal'
import { StyledLoader } from '../../../../../User/EditUser/ui/styled'

const getRowId = (row) => row.id

export const DictionaryValuesWidget = (
  row: pimApi.attributes.CommonAttribute
) => {
  const { dictionaryId } = row
  const queryClient = useQueryClient()

  const { data: dictionaryValues, isLoading: dictionaryValuesLoading } =
    pimApi.dictionaries.useGetDictionaryValues(dictionaryId || 0)
  const { mutate: editValue } = pimApi.dictionaries.useEditValueMutation()
  const { mutate: deleteValue } = pimApi.dictionaries.useDeleteValueMutation()

  const pageSize = 6

  const memoTableParams = useMemo(
    () => ({
      sort: [],
      limit: pageSize,
      page: 0,
    }),
    [dictionaryValues]
  )

  const settingsMenuOptions: (
    e: pimApi.dictionaries.Value
  ) => Array<IMenuItem> = (valueRow) => [
    {
      label: 'Изменить значение',
      divider: true,
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <AttributesValuesModal
              accept={accept}
              close={close}
              attributesValues={
                dictionaryValues?.filter((el) => el.name !== valueRow.name) ||
                []
              }
              initialValues={valueRow}
            />
          ),
          onAccept: (data) => {
            editValue(
              {
                dictionaryId: dictionaryId!,
                valueId: valueRow.id,
                value: data,
              },
              {
                onSuccess: reactQueryCacheUpdateByKey({
                  queryClient,
                  queryCacheKey:
                    pimApi.dictionaries.getDictionaryValuesQueryKey(
                      dictionaryId!
                    ),
                  onSuccess: () => {
                    snackActions.info('Значение атрибута изменено')
                  },
                }),
              }
            )
          },
        })
      },
    },
    {
      label: 'Удалить значение',
      type: 'error',
      handler: () => {
        dialogModel.openDialog({
          component: ({ close, accept }) => (
            <ModalContent
              close={close}
              accept={accept}
              title="Удалить значение атрибута?"
              acceptBtnText="УДАЛИТЬ"
              acceptBtnColor="error"
            />
          ),
          onAccept: () => {
            deleteValue(
              { dictionaryId: dictionaryId!, value: valueRow },
              {
                onSuccess: reactQueryCacheDelete({
                  queryClient,
                  queryCacheKey:
                    pimApi.dictionaries.getDictionaryValuesQueryKey(
                      dictionaryId!
                    ),
                  deleteItem: valueRow,
                  onSuccess: () => {
                    snackActions.info('Значение атрибута удалено')
                  },
                }),
              }
            )
          },
        })
      },
    },
  ]

  if (dictionaryValues)
    return (
      <NestedWidgetContainer>
        <div className="colored-container">
          <WidgetHeader
            dictionaryValues={dictionaryValues}
            dictionaryId={dictionaryId!}
          />
          {dictionaryId ? (
            <Table
              tableList={dictionaryValues}
              totalCount={dictionaryValues.length}
              getRowId={getRowId}
              isTableEditable={true}
              allRows={false}
              settingsMenuOptions={settingsMenuOptions}
              paginationLocale={pagingPanelLocale}
              tableParams={memoTableParams}
              calculatedRowsPerPage={6}
              columnsConfig={columnsConfig}
              searching="internal"
              pagination="internal"
              sorting="internal"
              filtering="internal"
            />
          ) : (
            <ValuesPlug />
          )}
        </div>
      </NestedWidgetContainer>
    )

  if (isNotNil(dictionaryId) && dictionaryValuesLoading) {
    return (
      <div style={{ position: 'relative', width: '100%', height: '150px' }}>
        <StyledLoader size={60} />
      </div>
    )
  }

  return null
}
