import React, { useMemo } from 'react'
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { pimApi } from 'shared/api'
import { useCompanyMarketplaceList } from 'shared/api/pim/dictionaries'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { SelectWrapper, StyledFilterContainer } from 'shared/ui/components/DataFilters/styled'
import { FilterWrapper } from 'features/reviews/ui/styled'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import { ButtonGroup } from '../ButtonGroup'
import { DataParamsTypes, reviewsCountType } from '../../../../lib/types'

interface Props {
  dataParams: DataParamsTypes
  reviewsCount: reviewsCountType
  setDataParams: any
}
export const Filters = ({ dataParams, setDataParams, reviewsCount }: Props) => {
  const { data: brandListQuery } = pimApi.dictionaries.useBrandListQuery()
  const { data: marketplaceListQuery } = pimApi.dictionaries.useMarketplaceList().marketplaceListQuery
  const { contragentListQuery } = pimApi.dictionaries.useContragentList({ onlySuppliers: true })
  const { companyListQuery } = pimApi.dictionaries.useCompanyList()
  const { companyMarketplaceListQuery } = useCompanyMarketplaceList(dataParams.marketplaceId || null, dataParams.companyId || null)


  const sortOptions = [
    {
      label: 'C высокой оценкой',
      value: true,
    },
    {
      label: 'C низкой оценкой',
      value: false,
    },
  ]

  const marketplaceOptions = useMemo(
    () => marketplaceListQuery?.map(getOptions) || [],
    [marketplaceListQuery]
  )
  const companyOptions = useMemo(
    () => companyListQuery.data?.map(getOptions) || [],
    [companyListQuery.data])

  const companymarketplaceOptions = useMemo(
    () => companyMarketplaceListQuery.data?.map(getOptions) || [],
    [companyMarketplaceListQuery.data]
  )

  const brandOptions = useMemo(
    () => brandListQuery?.map(getOptions) || [],
    [brandListQuery]
  )

  const contragentOptions = useMemo(
    () => contragentListQuery.data?.map(getOptions) || [],
    [contragentListQuery.data])

  return (
    <>
      <ButtonGroup setDataParams={setDataParams} reviewsCount={reviewsCount} dataParams={dataParams}/>
      <FilterWrapper>
        <TextField
          className="searchInput"
          size="small"
          id="searchInput"
          variant="standard"
          autoComplete="off"
          placeholder="Поиск"
          defaultValue={dataParams.searchString}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              document.getElementById('searchInput')?.blur()
            }
          }}
          onBlur={(e) => {
            if ( e.target.value !== (dataParams.searchString || '')) {
              setDataParams(prev => ({
                ...prev,
                searchString: e.target.value !== '' ? e.target.value : undefined,
                page: 0,
              }))
            }
          }}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
          }}/>
        <StyledFilterContainer>
          {dataParams.reviewtypeId !== 2 &&
          <SelectWrapper width={200}>
            <Autocomplete
              options={sortOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={sortOptions.find(el => el.value === dataParams.sortedValue)}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setDataParams(prev =>({ ...prev, sortedValue: undefined, page: 0 }))
                } else {
                  setDataParams(prev => ({ ...prev, sortedValue: value?.value, page: 0, }))
                }
              }}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Сортировка"
                  variant="outlined"
                  style={{ color: 'red' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SwapVertIcon sx={{ color: '#BDBDBD' }}/>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </SelectWrapper>
          }
          <SelectWrapper width={200}>
            <Autocomplete
              options={marketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={marketplaceOptions.find(el => (dataParams.marketplaceId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setDataParams(prev =>({ ...prev, marketplaceId: undefined, companymarketplaceId: undefined, page: 0 }))
                } else {
                  setDataParams(prev => ({ ...prev, marketplaceId: value?.value, page: 0, }))
                }
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.marketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Маркетплейс"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companyOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={companyOptions.find(el => (dataParams.companyId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                if (value === null) {
                  setDataParams(prev =>({ ...prev, companyId: undefined, companymarketplaceId: undefined, page: 0, }))
                } else {
                  setDataParams(prev => ({ ...prev, companyId: value?.value, page: 0, }))
                }
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.companyId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Продавец"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={companymarketplaceOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disabled={companymarketplaceOptions.length === 0}
              disableCloseOnSelect={true}
              size="small"
              value={companymarketplaceOptions.find(el => (dataParams.companymarketplaceId === el.value))  || { label: '', value: 0 }}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams(prev =>({ ...prev, companymarketplaceId: value?.value || undefined, page: 0, }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.companymarketplaceId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Кабинет"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={200}>
            <Autocomplete
              options={brandOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={brandOptions.find(el => (dataParams.brandId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams(prev =>({ ...prev, brandId: value?.value || undefined, page: 0 }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.brandId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Бренд"/>
              )}
            />
          </SelectWrapper>
          <SelectWrapper width={180}>
            <Autocomplete
              options={contragentOptions}
              noOptionsText="Нет фильтров"
              clearOnBlur={false}
              disableCloseOnSelect={true}
              size="small"
              value={contragentOptions.find(el => (dataParams.suppliersId === el.value)) || null}
              isOptionEqualToValue={isOptionEqual}
              onChange={(event, value) => {
                setDataParams(prev =>({ ...prev, suppliersId: value?.value || undefined, page: 0 }))
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              disableClearable={!dataParams.suppliersId}
              getOptionLabel={(option) => option.label || ''}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик"/>
              )}
            />
          </SelectWrapper>
        </StyledFilterContainer>
      </FilterWrapper>
    </>
  )
}

const isOptionEqual = (opt, val) => opt?.value === val?.value