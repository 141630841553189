import { Grid, GridDirection } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, memo } from 'react'
import { subDays } from 'date-fns'
import { useSelector } from 'react-redux'
import { DatePickerField, Field, Form, InputField } from 'shared/ui/components/form'
import usePending from 'shared/lib/hooks/PendingHook'
import { docChangeStatus, docUpdate } from 'store/doc'
import { docNextStatus, DocType } from 'shared/services/interfaces/doc'
import { warehouseIdSelector } from 'store/doc/selectors'
import { ReceiptDocStatusEnum } from 'shared/lib/generated/wms/Api'
import { noop } from 'shared/lib/lodash/noop'

import Info from './Info'
import WareHouseSelect from './WareHouseSelect'
import StatusChange from './StatusChange'

import { RECEIPT_DOC_STATUS_LIST } from '../../constants'
import * as UI from '../Table/styled'

interface Props {
  initialValues: DocType;
  handleFilterChange: (seatchText: string) => void;
}

const Toolbar = memo(({ initialValues, handleFilterChange }: Props) => {
  const prevFormValues = useRef({})
  const {
    status,
    defaultStorageId = null,
    id,
    versionNo,
  } = initialValues

  const changeStatusParams = {
    id,
    versionNo,
    storageId: defaultStorageId,
  }

  const warehouseId = useSelector(warehouseIdSelector)
  const [changeStatus] = usePending(docChangeStatus)
  const [update] = usePending(docUpdate)

  const theDayBefore = useMemo(() => subDays(new Date(), 1), [])

  useEffect(() => {
    prevFormValues.current = initialValues
  }, [initialValues])

  const setStatusStart = useCallback(() => {
    changeStatus({
      ...changeStatusParams,
      operation: docNextStatus.start
    })
  }, [changeStatusParams, defaultStorageId])

  const statusBadge = useMemo(() => {
    const params = RECEIPT_DOC_STATUS_LIST[status]
    if (!params) return null

    const ComponentIcon = RECEIPT_DOC_STATUS_LIST[status].icon
    if (params) {
      return (
        <UI.StyledStatus
          icon={<ComponentIcon />}
          title={params.title}
          $chipStyle={status}
        />
      )
    }
    return null
  }, [status])

  const handleDateChange = (value: string) => update({
    id,
    versionNo,
    'expectedArrivalDate': value || null
  })

  const handleStorageChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = target
    update({
      id,
      versionNo,
      'defaultStorageId': value || null,
    })
  }

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target
    update({
      id,
      versionNo,
      [name]: value || null
    })
  }

  const isStarted = status === ReceiptDocStatusEnum.Started
  const isNew = status === ReceiptDocStatusEnum.New
  const isNotEditable = status === ReceiptDocStatusEnum.Finished || status === ReceiptDocStatusEnum.FinishedWithDiff
  const wareHouseSelectDirection: GridDirection = isNew ? 'row' : 'column'

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={({ form }) => {
        const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          const { value, name } = target
          form.change(name, value)
        }

        const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          const { value, name } = target
          form.change(name, value)
          handleFilterChange(value)
        }
      
        return (
          <UI.ToolbarStyled>
            <Grid container={true} wrap="wrap" spacing={2}>
              <Grid
                item={true}
                container={true}
                direction="column"
                width="auto"
                spacing={2}
              >
                <Grid item={true} container={true} spacing={2}>
                  {!isNew && (<Grid item={true}>{statusBadge}</Grid>)}
                  <Grid item={true}>
                    <WareHouseSelect
                      warehouseId={warehouseId}
                      isDisabled={isStarted || isNotEditable}
                      direction={wareHouseSelectDirection}
                      onChange={handleStorageChange}
                    />
                  </Grid>
                  {isNew &&
                    <Grid item={true}>
                      <Field
                        name="expectedArrivalDate"
                        pickerType="datetime"
                        label="Ожидается"
                        component={DatePickerField}
                        views={['day']}
                        onChange={handleDateChange}
                        disablePrev={theDayBefore}
                      />
                    </Grid>
                  }
                </Grid>
                
                {isNew ? (
                  <StatusChange setStatusStart={setStatusStart} defaultStorageId={defaultStorageId} />
                ) : (
                  <Grid item={true}>
                    <Field
                      name="search"
                      label="Поиск товара"
                      component={InputField}
                      onChange={handleSearch}
                    />
                  </Grid>
                )}
              </Grid>

              <UI.StyledInfoGrid item={true}>
                <Info
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isTransportInfoDisabled={isStarted || isNotEditable}
                  isNotEditable={isNotEditable}
                />
              </UI.StyledInfoGrid>
            </Grid>
          </UI.ToolbarStyled>
        )
      }}
    />
  )
})

export default Toolbar
