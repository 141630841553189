import styled from 'styled-components'

export const EditQuantityStyle = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export const EditableInput = styled.div`
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    min-width: 72px;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background-color:  rgb(255, 255, 255);
`