import {
  GoodsAttributes as GoodsAttributesSnake
} from 'shared/lib/generated/pim/Api'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AxiosResponse } from 'axios'

import { api } from '../../base'

type GoodsAttributes = CamelCasedPropertiesDeep<GoodsAttributesSnake>

/* PIM */
export function getAttributes(
  goodId: UniqueId,
) {
  return api.get<GoodsAttributes>(
    `/pim/goods/${goodId}/attributes`
  )
}
  
export function saveAttribute(
  goodId: UniqueId,
  {
    attributeId: attribute_id,
    value
  }: {
      attributeId: number;
      value: string
    }
): Promise<AxiosResponse<GoodsAttributes>> {
  const body = {
    attribute_id,
    value
  }

  return api.post<GoodsAttributes>(
    `/pim/goods/${goodId}/attributes`,
    body
  )
}
  
  
export function editAttribute(
  goodId: UniqueId,
  goodsAttributeId: number,
  {
    id,
    versionNo: version_no,
    value
  }: {
      id: number;
      versionNo: number;
      value: string
    },
): Promise<AxiosResponse<GoodsAttributes>> {
  const body = {
    id,
    version_no,
    value
  }

  return api.put<GoodsAttributes>(
    `/pim/goods/${goodId}/attributes/${goodsAttributeId}`,
    body
  )
}
  
export function deleteAttribute(
  goodId: UniqueId,
  goodsAttributeId: number,
  {
    id,
    versionNo: version_no,
    value
  }: {
      id: number;
      versionNo: number;
      value: string
    },
): Promise<AxiosResponse<GoodsAttributes>> {
  const body = {
    id,
    version_no,
    value
  }

  return api.delete<GoodsAttributes>(
    `/pim/goods/${goodId}/attributes/${goodsAttributeId}`,
    { data: body }
  )
}