import { Autocomplete, Box, TextField } from '@mui/material'
import { isOptionEqual } from 'shared/lib/checkers/isOptionEqual'
import { useMemo } from 'react'
import { pimGen } from 'shared/lib/generated'
import queryString from 'query-string'
import { useLocation } from 'react-router'
import { getOptions } from 'shared/ui/components/Table/ui/TableHeaderCellComponent/TableHeaderCellComponent'
import { HistoryFiltersWrap, SelectWrapper } from 'pages/bulkOperations/bulkOperationsPage/ui/styled'

interface DataParamsTypes {
  bulkoperationId?: UniqueId
  bulkoperationTypeId?: UniqueId
  userId?: UniqueId
  page?: number
  pageSize?: number
}

interface Props {
  handleChangeFilterParams: (e: DataParamsTypes) => void
  bulkOperationsData: pimGen.bulkOperation.GetBulkOperations.ResponseType
}

export const HistoryFilters = ({ handleChangeFilterParams }: Props) => {
  const { search } = useLocation()
  const searchObj: any = queryString.parse(search, { parseNumbers: true })

  const { data: BulkOperationTypesData } =
    pimGen.bulkOperation.GetBulkOperationTypes.useGetBulkOperationTypes()

  const typeOptions = useMemo(() => {
    if (BulkOperationTypesData) {
      return BulkOperationTypesData.map((el) => ({
        value: el.id,
        label: el.name,
      }))
    }
    return []
  }, [BulkOperationTypesData])

  const { data: userListQuery } = pimGen.administration.GetUsers.useGetUsers()

  const userOptions = useMemo(
    () => userListQuery?.map(getOptions) || [],
    [userListQuery]
  )

  return (
    <HistoryFiltersWrap>
      <SelectWrapper width={162}>
        <Autocomplete
          sx={{
            marginRight: '16px',
          }}
          options={typeOptions}
          noOptionsText="Нет фильтров"
          size="small"
          value={
            typeOptions.find(
              (el) => el.value === searchObj.bulkoperationTypeId
            ) || null
          }
          isOptionEqualToValue={isOptionEqual}
          onChange={(event, value) => {
            handleChangeFilterParams({ bulkoperationTypeId: value?.value })
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.value}>
              {option.label}
            </Box>
          )}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Тип операции" />
          )}
        />
      </SelectWrapper>
      <SelectWrapper width={165}>
        <Autocomplete
          options={userOptions}
          noOptionsText="Нет фильтров"
          size="small"
          disableCloseOnSelect={true}
          value={
            userOptions.find((el) => el.value === searchObj.userId) || null
          }
          isOptionEqualToValue={isOptionEqual}
          onChange={(event, value) => {
            handleChangeFilterParams({ userId: value?.value })
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.value}>
              {option.label}
            </Box>
          )}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Пользователь" />
          )}
        />
      </SelectWrapper>
    </HistoryFiltersWrap>
  )
}
