import { memo, useCallback, useMemo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { wbproxyGen } from 'shared/lib/generated'
import {
  AutocompleteCatalogSearchField
} from 'shared/ui/components/form/AsyncSelectField/AutocompleteCatalogSearchField'

export interface TNVEDProps extends FieldRenderProps<string> {
  companymarketplaceId: number,
  tnvedObject: string,
  subjectId: number
}

const getOptionFromTNVED = ({ code }: wbproxyApi.model.TNVEDCode) => ({
  label: `${code}`,
  value: code
})

export const TNVEDCode = ({ tnvedObject, companymarketplaceId, subjectId, ...props } : TNVEDProps) => {
  const fetchCatalog = useCallback<(steach: string) => Promise<Array<Option<any>>>>(
    (search: string) => wbproxyGen.dictionaries.getTnved.apiFunction({ 
      query: { 
        tnved: encodeURIComponent(search), companymarketplaceId, subjectid: subjectId
      } }).then(
      data => data.map(getOptionFromTNVED)
    ),
  [companymarketplaceId]
  )

  const input = useMemo(() => ({
    ...props.input,
    value: {
      label: props.input.value,
      value: props.input.value
    }
  }), [props.input])

  const handleChange = (value) => {
    props.input.onChange(value?.label)
  }

  return (
    <AutocompleteCatalogSearchField
      {...props as FieldRenderProps<any>}
      input={input}
      error={props.error.toString()}
      // @ts-ignore
      onChange={handleChange}
      freeSolo={true}
      multiple={false}
      disableClearable={false}
      fetchCatalog={fetchCatalog}
    />
  )
}

export const TNVEDCodeMemo = memo(TNVEDCode)
