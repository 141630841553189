import {  Image } from 'shared/ui/components'
import defaultGoodImage from 'assets/images/defaultGoodImage.svg'

import { PhotoWrapper, PlaceholderImg } from './styled'

interface PhotoCellProps {
  apiUrl?: string
  onClick?: () => void
  isArchived?: boolean
}

export const PhotoCell = ({ apiUrl, onClick, isArchived }: PhotoCellProps) => {
  if (apiUrl) {
    return (
      <PhotoWrapper $isArchived={isArchived}>
        <Image src={apiUrl} />
      </PhotoWrapper>
    )
  }

  return (
    <PhotoWrapper $isArchived={isArchived}>
      <PlaceholderImg
        src={defaultGoodImage}
        onClick={onClick}
        disabled={!onClick}
        alt=""
      />
    </PhotoWrapper>
  )
}
