import { memo } from 'react'
import {
  Grid,
  Toolbar,
  VirtualTable,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ChangeSet, SummaryState, IntegratedSummary, DataTypeProvider, Sorting } from '@devexpress/dx-react-grid'

import { TableParams, SearchObj, ColumnConfig } from './model/types'
import {
  GridRootComponent,
  TableCellComponent,
  TableComponent,
  TableHeaderRow,
  TableSearch,
  TableSorting,
  TableColumnVisibility,
  TableFiltering,
  TableEditing,
  TableExpanded, TableRowDetail
} from './ui'
import { VirtualTableWrapperDX } from './ui/styled'
import { tableLocale } from './lib'

interface VirtualTableDXProps {
  columnsConfig:  {
    path?: string
    config: Array<ColumnConfig<string>>
    isEditable?: boolean
  }
  getRowId: (any) => number | string
  nestedTableColumnsConfig?: {
     path: string
     config: Array<ColumnConfig<string>>
     isEditable?: boolean
  }
  isTableEditable?:  boolean
  onCellChange?: (changes: ChangeSet) => void
  onRowDelete?: (id: UniqueId) => void
  onModalOpen?: (id: UniqueId) => void
  tableList: any[]
  totalCount?: number
  tableParams: TableParams
  handleTableSettingsChange?: (any) => void
  tableHiddenColumns?: Array<string>
  showInArchiveControl?: boolean
  showColumnsVisibility?: boolean
  sorting?: 'internal' | 'external'
  searching?: 'internal' | 'external'
  filtering?: 'internal' | 'external'
  isLoading?: boolean
  changeColumnConfig?: ( SortParam ) => void
  columnsForSummary?: any
  defaultSorting?: Array<Sorting>
  isStoredSettings?: boolean
  customSearchInput?: any
  nestedWidget?: any
  expandedRows?: Array<number>
  tableWidth?: number
  tableHeight?: number
}


export const VirtualTableDX = memo(
  ({
    tableList,
    tableParams,
    tableHiddenColumns,
    handleTableSettingsChange,
    isTableEditable,
    onCellChange,
    onRowDelete,
    onModalOpen,
    columnsConfig,
    nestedTableColumnsConfig,
    getRowId,
    showInArchiveControl,
    showColumnsVisibility,
    searching,
    sorting,
    filtering,
    isLoading,
    columnsForSummary,
    changeColumnConfig,
    isStoredSettings = false,
    customSearchInput,
    nestedWidget,
    defaultSorting,
    expandedRows,
    tableWidth = 0,
    tableHeight
  }: VirtualTableDXProps) => {
    const { search } = useLocation()
    const history = useHistory()
    const searchObj: SearchObj = queryString.parse(search)
    const { inArchive } = searchObj
    const { sort = [], searchString } = tableParams



    const handleTableParams = (params: Partial<TableParams>) => {
      if (searching === 'external') {
        let historyStr = queryString.stringify(
          { ...searchObj, ...params },
          { skipEmptyString: true, skipNull: true }
        )
        if (params.sort) {
          const sortParams = {
            sort: params.sort?.[0]?.columnName,
            order: params.sort?.[0]?.direction,
          }
          historyStr = queryString.stringify(
            { ...searchObj, ...sortParams },
            { skipEmptyString: true, skipNull: true }
          )
        }
        history.replace({ search: `?${historyStr}` })
      }

      if (searching === 'internal' && isStoredSettings && changeColumnConfig) {
        changeColumnConfig(params)
      }
    }
    const showToolbar = searching || showColumnsVisibility
      
    const fixedWidthCells = columnsConfig.config.filter((el)=> el.width !== 'auto' )
      .reduce((x, e) => x + (e.width as number), 0)

    const convertColumnConfig = { ...columnsConfig, config: columnsConfig.config.map( configUnit => (
      configUnit.width === 'auto'
        ? { ...configUnit, width: tableWidth - fixedWidthCells -24 }
        : configUnit
    )) }


    return (
      <VirtualTableWrapperDX tableHeight={tableHeight}>
        <Grid
          rows={tableList}
          columns={convertColumnConfig.config}
          getRowId={getRowId}
          rootComponent={GridRootComponent}
        >
          {showToolbar && <Toolbar />}

          {searching && (
            <TableSearch
              searching={searching}
              filtering={filtering}
              searchString={searchString}
              handleTableParams={handleTableParams}
              customSearchInput={customSearchInput}
            />
          )}

          {sorting && (
            <TableSorting
              defaultSorting={defaultSorting}
              handleTableParams={handleTableParams}
              sort={sort}
              columnsConfig={convertColumnConfig.config}
              sorting={sorting}
              isStoredSettings={isStoredSettings}
            />
          )}

          {filtering && <TableFiltering filtering={filtering} />}

          {columnsForSummary &&
            <DataTypeProvider for={convertColumnConfig.config as unknown as string[]}/>
          }
          {columnsForSummary &&
            <SummaryState totalItems={columnsForSummary.summaryRows}/>
          }
          {columnsForSummary &&
            <IntegratedSummary calculator={columnsForSummary.formatlessSummaryFunc}/>
          }
          <VirtualTable
            cellComponent={(props) => <TableCellComponent
              {...props}
              onRowDelete={onRowDelete}
              onModalOpen={onModalOpen}
              isLoading={isLoading}
            />}
            columnExtensions={convertColumnConfig.config}
            messages={tableLocale}
            tableComponent={TableComponent}
            rowComponent={TableExpanded}
            height="auto"
          />

          {showColumnsVisibility && (
            <TableColumnVisibility
              inArchive={inArchive}
              showInArchiveControl={showInArchiveControl}
              handleTableParams={handleTableParams}
              handleTableSettingsChange={handleTableSettingsChange}
              tableHiddenColumns={tableHiddenColumns}
            />
          )}

          <TableHeaderRow
            handleTableParams={handleTableParams}
            sort={sort}
            filtering={filtering}
            sorting={sorting}
          />

          { columnsForSummary &&
            <TableSummaryRow
              formatlessSummaryTypes={columnsForSummary.formatlessSummaryTypes}
              itemComponent={columnsForSummary.itemComponent}
            /> }

          {(nestedTableColumnsConfig || nestedWidget) && (
            <TableRowDetail
              nestedTableColumnsConfig={nestedTableColumnsConfig}
              nestedWidget={nestedWidget}
              expandedRows={expandedRows}
            />
          )}

          {columnsConfig.isEditable && isTableEditable && onCellChange && (
            <TableEditing
              onCommitChanges={onCellChange}
              columnsConfig={convertColumnConfig.config}
            />
          )}
        </Grid>
      </VirtualTableWrapperDX>
    )
  }
)
