import styled from 'styled-components'
import { LoadingButton } from '@mui/lab'
import { Checkbox } from '@mui/material'

export const StyledLoadingButton = styled(LoadingButton)`
  margin-left: 2,
`

export const StyledCheckbox = styled(Checkbox)`
  width: 18px;
  height: 23px;
  margin-right: 18px;
`