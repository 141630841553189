import React from 'react'
import { analyticsApi } from 'shared/api'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { DifferenceDataWidget } from 'widgets/DashboardWidgets'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'

export const SmallStocksWidget = () => {
  const dateFrom = subtractDays(new Date(), 1)
  const dateTo = subtractDays(new Date(), 0)

  const { data: stocksData } =
    analyticsApi.useAnalyticsStocksQuery(
      {
        dateFrom,
        dateTo,
        units: 'items',
      })
  const currentQuantity = stocksData?.data.data.filter(el => el.stocksDate === dateTo).reduce((sum, elem) => sum + elem.available, 0)
  const lastQuantity = stocksData?.data.data.filter(el => el.stocksDate === dateFrom).reduce((sum, elem) => sum + elem.available, 0)
  const diff = (currentQuantity !== undefined && lastQuantity !== undefined) ? (currentQuantity - lastQuantity) : 0

  return (
    <>
      {stocksData?.data ?
        <DifferenceDataWidget title="Остатки на сегодня" data={currentQuantity} diff={diff}/>
        :
        <Skeleton/>
      }
    </>
  )
}