import { InputAdornment, Box, CircularProgress } from '@mui/material'
import { Search } from '@mui/icons-material'
import React, { FC, memo, useState } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { NewComponentCard } from 'entities/pim/goodsComponents'
import { useField } from 'react-final-form'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { InputLabel } from 'shared/ui/components'

import {
  ProductSearchWrapper, SearchInput,
  ResultList
} from './styled'

interface GoodsComponentSearchProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onAddComponent: (newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent) => void
}

export const GoodsComponentSearch: FC<GoodsComponentSearchProps> = ({
  goodsComponents,
  onAddComponent
}) => {
  const goodsTypeField = useField<pimApi.goods.GoodsType>('goodsType')
  const goodsType = goodsTypeField.input.value
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 200)
  const { data, isLoading } = pimApi.goods.useGoodsByCodeQuery(debouncedValue, false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }


  const handleAdd = (goods: pimApi.goods.GoodsShortInfo, quantity: number) => {
    setValue('')
    const newComponent: AppPimGoodsModelsGoodsGoodsComponent = {
      componentId: goods.id,
      quantity,
      name: goods.name,
      code: goods.code,
      auxCode: goods.auxCode,
      goodsBaseImageId: goods.goodsBaseImageId,
      goodsType: goods.goodsType,
      unitCode: goods.unitCode,
      unitPartible: goods.unitPartible,
    }

    onAddComponent(newComponent)
  }

  const isAddDisabled = goodsType === 'pack' && !isEmpty(goodsComponents)
  const productListFiltered = data?.data.filter(
    item => !goodsComponents.find(component => component.componentId === item.id)
  ) ?? []

  if (isAddDisabled) {
    return null
  }

  return (
    <ProductSearchWrapper>
      <InputLabel label="Поиск товаров"/>
      <Box mb="4px">
        <SearchInput
          variant="outlined"
          size="small"
          fullWidth={true}
          autoComplete="off"
          placeholder="Введите артикул товара"
          onChange={handleChange}
          value={value}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              {isLoading &&
                <CircularProgress size={20}/>}
              <Search/>
            </InputAdornment>,
          }}
        />
      </Box>

      <ResultList>
        <Box mt={2}>
          {productListFiltered.map((item) => (
            <NewComponentCard
              key={item.id}
              goods={item}
              onAddClick={handleAdd}
              isAddDisabled={isAddDisabled}
              isInputDisabled={true}
              btnText="ВЫБРАТЬ"
            />
          ))}
        </Box>
      </ResultList>
    </ProductSearchWrapper>
  )
  
}

export const MemoGoodsComponentSearch = memo(GoodsComponentSearch)
