import styled from 'styled-components'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { InputSimpleWrapper } from 'pages/goods/components/common'

export const VariationTitle = styled.div`
  font-size: 14px;
  color: #0000008A;
  background: #fff;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15000000596046448px;
`

export const ArrowIconStyled = styled(ArrowForwardIosIcon)`
  color: #C4C4C4;
  font-size: 14px;
  margin-left: -5px;
`

export const VariationSelectWrapper = styled(InputSimpleWrapper)`
  svg{
     color: rgba(0, 0, 0, 0.38);
  }
`
