import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  margin: 28px 0 24px 0;
`
export const HeaderStyle = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  height: 36px;
`

export const ArticleStyle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`