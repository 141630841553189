import React from 'react'

import { StyledContainer } from './styled'

import { ProductPriceHistory } from '../../../../../../../api/pim/price'

export const NomenclatureCell = ({ row }: { row: ProductPriceHistory }) => {
  const { marketplaceName, marketplaceCode, marketplaceUrl  } = row
  return (
    <StyledContainer>
      <span>{marketplaceName}</span>
      <a id="link-text" href={marketplaceUrl} target="_blank" rel="noreferrer">{marketplaceCode}</a>
    </StyledContainer>
  )
}