import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
  
  span {
    height: 16px;
  }
`

export const ProductSizeCell = ({ row }) => {
  if (row.size || row.ruSize) return (
    <Wrapper>
      <span>{row.size ?? '-'}</span>
      <span>{row.ruSize ?? '-'}</span>
    </Wrapper>
  )

  return <></>
}