import React from 'react'

import { StyledLink } from './styled'

import { ProductPriceHistory } from '../../../../../../../api/pim/price'
import { getProductEditPath } from '../../../../../../../config'

export const ProductNameCell = ({ row }: { row: ProductPriceHistory }) => {
  const { productId, name } = row
  return (
    <StyledLink title={name} target="_blank" to={getProductEditPath(productId)}>{name}</StyledLink>
  )
}