import { yupTransformEmptyStringInNull } from 'shared/lib/utils'
import * as yup from 'yup'

export const validationSchemaWB = yup.object({
  name: yup.string().max(60, 'Превышено количество символов в названии')
    .required('Название продукта не указано'),
  description: yup.string().max(5000, 'Превышено количество символов в описании').nullable(),
  countryproduction: yup.lazy((value, options) => {
    if ('countryproduction' in options.parent) {
      return yup.string().required('Страна производства не указана').nullable()
    }
    return yup.string()
  }),
  brand: yup.string().required('Бренд не указан'),

  width: yup.object().shape({
    required: yup.boolean(),
    value: yup.number()
      .positive('Ширина упаковки должна быть больше нуля')
      .when('required', { is: true, then: yup.number().typeError('Ширина упаковки не указана').required('Ширина упаковки не указана') })
      .transform(yupTransformEmptyStringInNull)
      .nullable()
  }).nullable(),

  height: yup.object().shape({
    required: yup.boolean(),
    value: yup.number()
      .positive('Высота упаковки должна быть больше нуля')
      .when('required', { is: true, then: yup.number().typeError('Высота упаковки не указана').required('Высота упаковки не указана') })
      .transform(yupTransformEmptyStringInNull)
      .nullable()
  }).nullable(),

  depth: yup.object().shape({
    required: yup.boolean(),
    value: yup.number()
      .positive('Длина упаковки должна быть больше нуля')
      .when('required', { is: true, then: yup.number().typeError('Длина упаковки не указана').required('Длина упаковки не указана') })
      .transform(yupTransformEmptyStringInNull)
      .nullable()
  }).nullable(),

  weight: yup.object().shape({
    required: yup.boolean(),
    value: yup.number()
      .positive('Вес упаковки должен быть больше нуля')
      .when('required', { is: true, then: yup.number().typeError('Вес упаковки не указан').required('Вес упаковки не указан') })
      .transform(yupTransformEmptyStringInNull)
      .nullable()
  }).nullable(),
  price: yup.number()
    .min(0)
    .transform(yupTransformEmptyStringInNull)
    .nullable(),
  variations: yup.array().of(
    yup.object().shape({
      chrtid: yup.number()
        .positive()
        .transform(yupTransformEmptyStringInNull)
        .nullable()
    }).nullable()
  ),
  nmid: yup.number()
    .positive()
    .transform(yupTransformEmptyStringInNull)
    .nullable()
})

export const validationSchemaPIM = yup.object({
  packagingrequirementId: yup.number().typeError('Требования по упаковке не указаны').required('Требования по упаковке не указаны')
    .transform(yupTransformEmptyStringInNull),
})

export const validationSchemaAll = validationSchemaWB.concat(validationSchemaPIM)
