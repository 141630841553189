import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'

export const columns = {
  roleId: 'roleId',
  selected: 'selected',
  name: 'name',
  dateIn: 'dateIn',
  dateOut: 'dateOut'
} as const

interface ColumnsConfigProps {
  config: Array<ColumnConfig<any>>
  isEditable?: boolean
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.selected,
      columnName: columns.selected,
      title: 'Доступ',
      width: 128,
      sortingEnabled: false,
      cellType: CellType.RowSelect
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 400,
      sortingEnabled: true,
    },
    {
      name: columns.dateIn,
      columnName: columns.dateIn,
      title: 'Доступ открыт',
      width: 260,
      sortingEnabled: false,
      cellType: CellType.UserDateIn
    },
    {
      name: columns.dateOut,
      columnName: columns.dateOut,
      title: 'Доступ закрыт',
      width: 260,
      sortingEnabled: false,
      cellType: CellType.UserDateOut
    },
    {
      name: columns.roleId,
      columnName: columns.roleId,
      title: ' ',
      width: 'auto',
      sortingEnabled: false,
      cellType: CellType.UserDateEdit,
    }
  ]
}
