import { Box, Typography } from '@mui/material'
import { AxiosResponse } from 'axios'
import { GoodsSupplyingResult } from 'shared/api/trade/stocks'
import { UseQueryResult } from 'react-query'
import { format } from 'date-fns'
import { formatDate } from 'shared/lib/transform/relativeDateFormatting'
import UpdateIcon from '@mui/icons-material/Update'

import { ImportButton } from './ImportFile/ImportButton'

export const Header = ({ goodsSupplyingQuery }: { goodsSupplyingQuery?:  UseQueryResult<AxiosResponse<GoodsSupplyingResult>> }) => {
  const formattedDate = format(new Date(goodsSupplyingQuery?.data?.data?.created || 0), 'dd.MM.yyyy HH:mm')
  return (
    <Box p="24px 24px 16px 24px" display="flex" flexDirection="column" gap="10px">
      <div style={{ height: '20px', display: 'flex' }}>
        { goodsSupplyingQuery?.data?.data &&
          <span style={ { color: '#00000099', fontSize: '14px', height: '20px' } }>
            { `${ goodsSupplyingQuery?.data?.data?.creator } обновил(а) ${ formattedDate }` }
          </span>
        }
      </div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <Typography variant="h3" fontSize="24px">
            Товары в поставке
          </Typography>
          {goodsSupplyingQuery?.data?.data &&
          <div style={{
            height: '28px',
            padding: '4px 8px',
            backgroundColor: '#F5F5F5',
            borderRadius: '16px',
            color: '#00000099',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: '13px',
            gap: '12px'
          }}>
            <UpdateIcon sx={ { color: '#0000008A', width: '20px', height: '20px' } }/>
            { formatDate({ date: new Date(goodsSupplyingQuery?.data?.data?.created || 0), includeDayDiff: true }) }
          </div>
          }
        </div>
        <ImportButton isLoading={ goodsSupplyingQuery?.isLoading || false }/>
      </Box>
    </Box>
  )
}
