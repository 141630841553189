import React from 'react'
import { pimGen } from 'shared/lib/generated'
import { round } from 'shared/lib/utils/round'

import { CompetitorsCell } from '../index'

export const StocksCell = ({ row }) => {
  const { data } = pimGen.product.GetProductCompetitors.useGetProductCompetitors({ productId: row.productId })
  
  if (data) return (
    <CompetitorsCell>
      { data.map(competitor => {
        const totalStocks = competitor?.stocks?.reduce((sum, elem) => sum + elem.available, 0)
        return (
          <span key={competitor.id} style={{ fontSize: '14px' }}>{round(totalStocks, 0)}</span>
        )}) }
    </CompetitorsCell>
  )

  return <></>
}