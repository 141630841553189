import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { useForm, useFormState } from 'react-final-form'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { pimApi, wbproxyApi } from 'shared/api'
import { isNil, isNotNil } from 'shared/lib/checkers'
import { Option } from 'shared/ui/components/interface'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { useMemo } from 'react'
import { Attribute } from 'shared/api/pim/goods'

import {
  getNormalizedAttributes,
  WBMapingAttribute,
  ProductAttributeValue,
} from '../lib/getNormalizedAttributes'

const getOptionFromDictionary = (value): Option => ({
  label: value.name,
  value: value.name,
})

const getOptionFromAttributes = (value): Option => ({
  label: value,
  value,
})

const IS_MULTIPLE_RULE_MAX_COUNT__GT = 0

export const useNormalizedAttributes = () => {
  const goods = useGoodsContext()
  const { wbProductQuery: wb } = useWBProductContext()
  const goodsAttributes: Attribute[] = goods?.attributes ?? []

  const { commonProductQuery } = useCommonProductContext()

  const { change } = useForm()
  const { values } = useFormState({ subscription: { values: true } })
  const { attributes } = values

  const productAttributes: Array<WBMapingAttribute> = useMemo(() => 
    (attributes as Array<pimApi.products.wb.WBNomenclatureAttribute>).map(
      (wbProductAttribute, index) => {
        const multipleValues = isNotNil(wbProductAttribute.maxcount) && wbProductAttribute.maxcount > IS_MULTIPLE_RULE_MAX_COUNT__GT
        return ({
          id: wbProductAttribute.id,
          attributeId: wbProductAttribute?.attributeId,
          attributeName: wbProductAttribute.attributeName,
          attributeGlobal: wbProductAttribute.attributeGlobal,
          values: wbProductAttribute.values!,
          index,
          valuesType: wbProductAttribute?.valuesType,
          unitName: wbProductAttribute?.unitName,
          required: wbProductAttribute?.required,
          maxCount: wbProductAttribute?.maxcount,
          multipleValues,
          useOnlyDictionaryValues: wbProductAttribute?.useonlydictionaryvalues,
          // eslint-disable-next-line no-nested-ternary
          fetchCatalog: isNil(wbProductAttribute.dictionary)
            ? (search: string) =>
              wbproxyApi
                .fetchAttributes( {
                  searchString : search,
                  category: wb?.data!.category,
                  name: wbProductAttribute.attributeName,
                  companymarketplaceId: commonProductQuery.data?.companymarketplaceId,
                }
                )
                .then((data) => data.data.map(getOptionFromAttributes))
            : (search: string) =>
              wbproxyApi
                .fetchDictionary(
                      commonProductQuery.data?.companymarketplaceId!,
                      wbProductAttribute.dictionary!,
                      search,
                      wbProductAttribute.attributeName
                )
                .then((data) => data.data.map(getOptionFromDictionary)),
        })
      })
  ,[attributes])

  const changeWBAttributeProperty = (
    conditionAttributeId: number,
    productAttributeProperties: {
      attributeId?: null | number
      values?: ProductAttributeValue
      type?: string
    }
  ) => {
    const newProductAttributes = [...productAttributes]
    const attributeIndex = newProductAttributes.findIndex(el => el.id === conditionAttributeId)
    Object.entries(productAttributeProperties).forEach(([key, value]) => {
      change(`attributes.${attributeIndex}.${key}`, value)
    })
  }
  return {
    normalizedAttributes: getNormalizedAttributes(goodsAttributes, productAttributes),
    changeWBAttributeProperty,
  } as const
}