import { Box, TextField } from '@mui/material'
import { Field } from 'shared/ui/components/form'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper'
import { pimApi } from 'shared/api'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { FormSpy, useField } from 'react-final-form'
import { DisplayedGoodsDataController } from 'pages/product/edit/main/model/DisplayedGoodsDataController'

import { SizesForm } from './ui/SizesForm'
import { CountryMemo } from './ui/Country'
import { MainParamsForm } from './ui/MainParamsForm'

import { MemoECOMMarketplace } from '../ECOMWB'

export function GeneralInformation({ isArchived }: { isArchived?: boolean }) {
  const tnvedEnabled = useField('tnvedEnabled').input.value

  const parametersName = [
    'brandGlobal',
    'nameGlobal',
    ...(tnvedEnabled ? ['tnvedGlobal'] : []),
    'countryproductionGlobal',
    'sizeinfoGlobal'
  ]

  const goods = useGoodsContext() as pimApi.goods.Goods
  const { commonProductQuery } = useCommonProductContext()
  return (
    <>
      <FormSpy subscription={{ initialValues: true }}>
        {props => {
          const { initialValues } = props
          const countryProductionExist = 'countryproduction' in initialValues
          const { displayedGoodsData, changeDisplayedGoodsData } = DisplayedGoodsDataController()
          return (
            <>
              <MemoECOMMarketplace
                leftSideTooltip="Атрибуты товара, к которому привязан продукт"
                changeDisplayedGoodsData={changeDisplayedGoodsData('generalInformation')}
                displayGoodsData={displayedGoodsData.generalInformation}
                parametersName={parametersName}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <MainParamsForm showGoodsInfo={displayedGoodsData.generalInformation} goods={ goods } disabled={ isArchived }/>

                { countryProductionExist &&
                  <InputsWrapper hideGoodsInfo={!displayedGoodsData.generalInformation} globalAttributeName="countryproductionGlobal">
                    <TextField
                      label="Страна производства"
                      variant="outlined"
                      size="small"
                      fullWidth={ true }
                      value={ goods?.madeInCountry ?? '' }
                      disabled={ true }
                    />
                    <Field
                      name="countryproduction"
                      label="Страна производства*"
                      component={ CountryMemo }
                      companymarketplaceId={ commonProductQuery?.data?.companymarketplaceId }
                      disabled={ isArchived }
                      errorAfterTouch={ true }
                    />
                  </InputsWrapper>
                }

                <SizesForm showGoodsInfo={displayedGoodsData.generalInformation} goods={ goods } disabled={ isArchived }/>
              </Box>
            </>
          )
        }
        }
      </FormSpy>
    </>
  )
}
