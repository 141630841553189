import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { AnalyticsDataSale, AnalyticsParams } from './model'

import { toSnakeCase } from '../../lib/transform'
import { toSnake } from '../../lib/transform/toSnakeCase'

const getAnalyticsSalesQueryKey = (queryParams: AnalyticsParams) =>
  ['get', 'analytics/sales', queryParams] as const

type GetAnalyticsSalesQueryKey = ReturnType<typeof getAnalyticsSalesQueryKey>

function getAnalyticsSales(queryParams: AnalyticsParams) {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  const { dateFrom, dateTo, units, ...rest }= queryParams

  return api.post<AnalyticsDataSale>(
    '/analytics/sales', rest
    , {
      params: { date_from: dateFrom, date_to: dateTo, units }
    })
}

const getAllAnalyticsSales = (context: QueryFunctionContext<GetAnalyticsSalesQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getAnalyticsSales(queryParams)
}

export const useAnalyticsSalesQuery = (queryParams: AnalyticsParams) =>
  useQuery<
    AxiosResponse<AnalyticsDataSale>,
    errorType,
    AxiosResponse<AnalyticsDataSale>,
    GetAnalyticsSalesQueryKey
  >(
    getAnalyticsSalesQueryKey(queryParams),
    getAllAnalyticsSales,
    { staleTime: Infinity, keepPreviousData: true }
  )
