/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { EditPrivilege, Privilege } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Privilege>

export type IRequest = CamelCasedPropertiesDeep<{
  privilegeId: number,
  data: EditPrivilege
}>

export const apiFunction = ({ privilegeId,data }: IRequest) =>
  api.put<ResponseType>(`/customer/admin/privileges/${privilegeId}`,data).then(res => res.data)


export const useChangePrivilege = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

