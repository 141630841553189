/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Company, NewCompany } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<Company>

export type IRequest = CamelCasedPropertiesDeep<{
  data: NewCompany
}>

export const apiFunction = ({ data }: IRequest) =>
  api.post<ResponseType>(`/customer/companies`,data).then(res => res.data)


export const useCreateCompany = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

