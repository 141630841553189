import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { customerApi } from 'shared/api'

export function fetchUsers(): Promise<AxiosResponse<customerApi.AppAdminModelUsersUser[]>> {
  return api.get<customerApi.AppAdminModelUsersUser[]>('/customer/admin/users')
}

type QueryKey = typeof QUERY_KEY

const QUERY_KEY = ['get', 'customer/admin/users'] as const

function getUsers() {
  return fetchUsers().then((res) => res.data)
}

export const useUsersListQuery = () => useQuery<
    Array<customerApi.AppAdminModelUsersUser>,
    errorType,
    Array<customerApi.AppAdminModelUsersUser>,
    QueryKey
  >(QUERY_KEY, getUsers, {
    refetchOnMount: false,
  })

export function useUsersList() {
  const UsersListQuery = useQuery<
    Array<customerApi.AppAdminModelUsersUser>,
    errorType,
    Array<customerApi.AppAdminModelUsersUser>,
    QueryKey
  >(QUERY_KEY, getUsers)

  return {
    UsersListQuery,
  } as const
}