import styled from 'styled-components'
import { Box, FormControl } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'


export const TabsButton = styled.button<{
    isActive: boolean
    disabled: boolean | undefined
}>`
  height: 48px;  
  padding: 0 22px;
  border: none;
  border-radius: 0;
  box-shadow: ${({ isActive }) =>
    isActive
      ? 'inset 0 -2px 0px #007DFF'
      : ''};
  color: ${({ isActive }) => (isActive ? '#007DFF' : '#9e9e9e')};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.46px;
  line-height: 48px;
  white-space: pre;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background: none;

`

export const WrapperTabs = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
`

export const ElementLi = styled.li`

`

export const StyledTabPanel = styled(Box)`
  padding: 0 24px;
  background-color: #FAFAFA;
  border-radius: 16px;
`

export const FormControlSort = styled(FormControl)`
  fieldset {
    border: none;
  }
`

export const SelectorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 16px;

`

export const ClearField = styled(ClearIcon)<{
    $onClear: boolean
}>`
    position: absolute;
    color: rgba(0,0,0,0.54);
    right: 30px;
    display: ${({ $onClear }) => ($onClear ? 'block' : 'none')};
    &:hover {
        color: rgba(0, 0, 0, 0.38);
    }

`
export const MarketPlaceSelect = styled(Box)<{
    $disabled: boolean
}>`
  background-color: #fff;
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  .MuiInputLabel-root {
      color: ${({ $disabled }) => ($disabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)')};
  }
`

export const Sort = styled(Box)<{ sortMode: number }>`
  display: flex;
  align-items: center;
  color: #000000DE;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.68);
    svg {
      opacity: ${({ sortMode }) => (sortMode === 0 ? '0.3' : '1')};
    }
  }

  svg {
    transform: ${({ sortMode }) => (sortMode === 1 ? 'none' : 'rotate(180deg)')};
    margin-left: 6px;
    height: 18px;
    opacity: ${({ sortMode }) => (sortMode === 0 ? '0' : '1')};
    transition: 0.3s ease-in-out;
  }
`

export const EmptyPlug = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
  margin: 48px;
`