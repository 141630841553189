import React from 'react'
import { useParams } from 'react-router-dom'
import { activityApi } from 'shared/api'
import { ActivityContext } from 'features/analytics/activities/model/ActivityContext'

import { EditActivityPage } from './ui/EditActivityPage'

export const EditActivityPanel = () => {
  const { id } = useParams<{ id: string }>()
  const activityId = parseInt(id, 10)

  const { data } = activityApi.useGetActivityInfoQuery({ activityId })

  return (
    <ActivityContext.Provider value={{ detailActivity: data?.data }}>
      <EditActivityPage/>
    </ActivityContext.Provider>
  )
}