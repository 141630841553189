import React, { useState } from 'react'
import { generatePath } from 'react-router'
import { Box, Button } from '@mui/material'
import { GOODS_EDIT } from 'shared/config/routes'
import { Image, MaskedInput } from 'shared/ui/components'
import { pimApi } from 'shared/api'
import {
  ResultItem,
  ProductTitle,
  ProductAuxCode,
  ProductImage,
  ProductInfo,
} from 'pages/goods/components/GoodsComponent/styled'
import { PlaceholderWrapper } from 'shared/lib/hooks/withImage/styled'
import imagePlaceholder from 'assets/images/defaultGoodImage.svg'


interface NewComponentCardProps {
  goods: pimApi.goods.GoodsShortInfo
  onAddClick: (goods: pimApi.goods.GoodsShortInfo, quantity: number) => void
  isAddDisabled: boolean
  isInputDisabled: boolean
  btnText?: string
}

export const NewComponentCard = ({
  goods,
  onAddClick,
  isAddDisabled,
  isInputDisabled,
  btnText,
}: NewComponentCardProps) => {
  const [quantity, setQuantity] = useState(1)
  const DefImageSize = { height: '80px', width: '80px' }
  const apiUrl = `/wms/goods/${goods.id}/image/${goods.goodsBaseImageId}?max_height=80&max_width=80`

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    setQuantity(parseFloat(event.target.value))
  }

  const handleAddClick = () => onAddClick(goods, quantity)

  return (
    <ResultItem>
      <Box display="flex" alignItems="center" pr={3}>
        <ProductImage>
          {goods.goodsBaseImageId ? (
            <Image src={apiUrl} defaultImgParams={DefImageSize}/>
          ) : (
            <PlaceholderWrapper>
              <img src={imagePlaceholder} alt="placeholder" />
            </PlaceholderWrapper>
          )}
        </ProductImage>
        <ProductInfo>
          <ProductTitle title={goods.name} to={generatePath(GOODS_EDIT, { id: goods.id, step: 'info' })}>
            {goods.name}
          </ProductTitle>
          <ProductAuxCode>
            Артикул: {goods.code}{' '}
            {goods.auxCode && `/ Доп. артикул: ${goods.auxCode}`}
          </ProductAuxCode>
        </ProductInfo>
      </Box>
      <Box display="inline-flex" alignItems="center">
        {!isInputDisabled && (
          <Box width="45px">
            <MaskedInput
              name="quantity"
              label={goods.unitCode}
              value={quantity}
              scale={goods.unitPartible ? 10 : 0}
              min={0}
              onBlur={handleChange}
            />
          </Box>
        )}
        <Box display="inline-flex" pl={2} pr="22px">
          <Button
            variant="outlined"
            color="primary"
            disabled={isAddDisabled}
            onClick={handleAddClick}
          >
            {btnText || 'ДОБАВИТЬ'}
          </Button>
        </Box>
      </Box>
    </ResultItem>
  )
}
