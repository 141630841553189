export const getFileSizeParams = (size: { maxWidth?: number, maxHeight?: number }): string => {
  const params = new URLSearchParams('')

  if (size.maxHeight) {
    params.append('max_height', size.maxHeight.toString())
  }

  if (size.maxWidth) {
    params.append('max_width', size.maxWidth.toString())
  }

  return params.toString()
}
