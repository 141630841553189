import { getGoodsEditPath } from 'shared/config/routes'
import { sort } from 'shared/lib/utils/sort'
import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { Preloader } from 'shared/ui/components'
import { snackActions } from 'shared/lib/react/snackbar'
import { pimApi } from 'shared/api'

import { useVariation } from '../../../model/hooks'
import { FormValues, InitialFormValues, IVariation } from '../../interface'
import * as common from '../../../components/common'
import { MemoGoodsHeader } from '../../../components'
import { VariantFormMemo as VariantForm } from '../VariantForm'

const EMPTY_VARIATION_ATTRIBUTES: [InitialFormValues['variationAttributes'][number]] = [{}]

export const VariantStep = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const goodsId = parseInt(id, 10)
  const goodsQuery = pimApi.goods.useFetchGoodsQuery(goodsId)
  const goodsAttributesForVariationsQuery = pimApi.goods.useAttributesForVariations(goodsId)

  const { create } = useVariation()

  const handleSubmit = useCallback((variationFormValues: FormValues) => {
    create(
      { goodsId, variationFormValues },
      {
        onError(error) {
          snackActions.error(error.message ?? 'Что-то пошло не так при создании вариативного товара')
        },
        onSuccess({ id: newGoodsId }) {
          const goodsEditPath = getGoodsEditPath(newGoodsId)

          history.push(goodsEditPath)
        }
      }
    )
  }, [goodsId])

  const goodsVariationAttributes: Array<IVariation> = useMemo(() => goodsQuery.data?.variations?.attributes ?
    sort(goodsQuery.data.variations.attributes, 'id')
      .map(({
        attributeId,
        attributeName,
        dictionaryId
      }) => ({
        attributeId,
        attributeName,
        dictionaryId
      }))
    : []
  , [goodsQuery.data])


  const posibleVariations: Array<IVariation> = useMemo(() => (goodsAttributesForVariationsQuery.data ?? [])
    .map(({
      attributeId,
      name,
      dictionaryId,
    }) => {
      const goodsAttribute = goodsVariationAttributes.find(
        (goodsVariationAttribute) => goodsVariationAttribute.attributeId === attributeId
      )

      if (goodsAttribute) {
        return goodsAttribute
      }

      return {
        attributeId,
        attributeName: name,
        dictionaryId,
      }
    })
  , [goodsAttributesForVariationsQuery.data])

  // @ts-ignore
  const initialValues: InitialFormValues = useMemo(() => ({
    code: goodsQuery.data?.code,
    copyMedia: false,
    variationAttributes: goodsVariationAttributes.length === 0 ?
      EMPTY_VARIATION_ATTRIBUTES :
      goodsVariationAttributes.map((variation) => ({ variation })),
  }), [goodsQuery.data, goodsVariationAttributes])

  if (goodsQuery.status !== 'success' || goodsAttributesForVariationsQuery.status !== 'success') {
    return <Preloader />
  }

  return (
    <common.Wrapper>
      <common.PaperWrapper>
        <MemoGoodsHeader
          title={goodsQuery.data.name}
          code={goodsQuery.data.code}
          auxCode={goodsQuery.data.auxCode}
          goodsId={goodsQuery.data.id}
          imgId={goodsQuery.data.goodsBaseImageId}
          goodsType={goodsQuery.data.goodsType}
        />
        <VariantForm
          initialValues={initialValues}
          posibleVariations={posibleVariations}
          onSubmit={handleSubmit}
        />
      </common.PaperWrapper>
    </common.Wrapper>
  )
}
