import { Modify } from 'notistack'
import { QueryProductsArgs } from 'shared/lib/generated/products/Api'

type ASC = 'asc'
type DESC = 'desc'

export interface FilterParams<ParamIdType> {
  searchString?: string;
  goodsType?: string;
  companyId?: ParamIdType;
  marketplaceId?: ParamIdType;
  brandId?: ParamIdType;
  cardStatus?: ParamIdType;
  syncStatus?: ParamIdType;
}
export interface TableParams extends FilterParams<number | string> {
  page: number;
  limit: number;
  sort: Array<{
    columnName: string;
    direction: ASC | DESC;
  }>;
}

export const FilterNames: Record<string, string> = {
  'marketplaceId': 'Маркетплейс',
  'abcClass': 'ABC класс',
  'stocksStatus': 'Состояние остатков',
  'companymarketplaceId': 'Кабинет',
  'autoOrdering': 'Автозаказ',
  'companyId': 'Компания',
  'category': 'Категория',
  'xyzClass': 'XYZ класс',
  'goodsType': 'Тип',
  'season': 'Сезонность',
  'contragentId': 'Поставщик',
  'goodsBrandId': 'Бренд',
  'syncStatusId': 'Статус',
  'orderStatus': 'Динамика заказа',
  'tags': 'Метки'
}

export type filtersListParamsType = Omit<QueryProductsArgs,
  | 'cardStatusId'
  | 'companymarketplaceId'
  | 'excludeInArchive'
  | 'goodsType'
  | 'productId'
  | 'searchString'
  | 'stocksStatus'
>

export type FiltersType = Omit<QueryProductsArgs,
  | 'cardStatusId'
  | 'excludeInArchive'
  | 'goodsType'
  | 'productId'
  | 'searchString'
>

export type IAvailableFilters  = Modify<FiltersType , {
  marketplaceId?: boolean
  abcClass?: boolean
  autoOrdering?: boolean
  companyId?: boolean
  category?: boolean
  xyzClass?: boolean
  contragentId?: boolean
  goodsBrandId?: boolean
  stocksStatus?: boolean
  syncStatusId?: boolean
  companymarketplaceId?: boolean
  orderStatus?: boolean
  tags?: boolean
}>
