import { inject, injectable } from 'inversify'
import {
  IDoc,
  DocType,
} from 'shared/services/interfaces/doc'
import { TYPES } from 'shared/services/types'
import * as listUtils from 'shared/services/interfaces/list-utils'
import { omit, sum } from 'ramda'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'
import {
  ReceiptDoc as ReceiptDocSnake,
  ReceiptDocAcceptance as ReceiptDocAcceptanceSnake,
  ReceiptDocPosition as ReceiptDocPositionSnake,
  ReceiptDocStatusEnum
} from 'shared/lib/generated/wms/Api'
import { CamelCasedPropertiesDeep } from 'type-fest'

export type ReceiptDocPosition = CamelCasedPropertiesDeep<ReceiptDocPositionSnake>
export type ReceiptDocAcceptance = CamelCasedPropertiesDeep<ReceiptDocAcceptanceSnake> & {
  deleted?: boolean
}
export type ReceiptDoc = CamelCasedPropertiesDeep<ReceiptDocSnake>
export type CalculatedReceiptDocPosition = ReceiptDocPosition & {
  sumAccepted: number;
  sumDefective: number;
  sumFact: number;
  number: number;
  difference: number;
}

@injectable()
export class Doc implements IDoc {
  @inject(TYPES.ListUtils) private readonly _listUtils: listUtils.IListUtils

  // eslint-disable-next-line class-methods-use-this
  calcAcceptanceInPositions(doc: ReceiptDoc) {
    const { status } = doc
    const isShowDifference = status !== ReceiptDocStatusEnum.New

    const getSumValues = (acceptance?: ReceiptDocAcceptance[]) => acceptance?.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      item.quantityAccepted = Number.isNaN(item.quantityAccepted) ? 0 : item.quantityAccepted

      return {
        sumAccepted: acc.sumAccepted + (item.quantityAccepted * item.quantityInPackaging),
        sumDefective: acc.sumDefective + (item.quantityDefective),
        sumFact: acc.sumFact + (item.quantityAccepted * item.quantityInPackaging)
      }
    }, { sumAccepted: 0, sumDefective: 0, sumFact: 0 })

    const positions = doc.positions?.map((position, index) => {

      const acceptance = position.acceptance?.filter(
        (acceptanceItem: ReceiptDocAcceptance) => !acceptanceItem.deleted
      )
      const sumValues = getSumValues(acceptance)
        || { sumAccepted: 0, sumDefective: 0, sumFact: 0 }

      const difference = isShowDifference ? sumValues.sumFact - position.quantity : 0
      return {
        number: index + 1,
        ...position,
        ...sumValues,
        difference,
        acceptance,
      }
    })

    return {
      ...doc,
      positions,
      sumAccepted: sum(positions?.map(item => item.sumAccepted) as Array<number>),
      sumDefective: sum(positions?.map(item => item.sumDefective) as Array<number>),
      sumFact: sum(positions?.map(item => item.sumFact) as Array<number>),
      sumPrice: sum(positions?.map(item => (item.price || 0) * item.quantity) as Array<number>),
      sumQuantity: sum(positions?.map(item => item.quantity) as Array<number>),
    }
  }

  async getDocDetail(docId: number): Promise<DocType> {
    const result = await api.get<ReceiptDoc>(`/wms/receiptdocs/${docId}`)
    return this.calcAcceptanceInPositions(result.data)
  }

  // eslint-disable-next-line class-methods-use-this
  async loadDocList(warehouseId: number):
    Promise<DocType[]> {
    const { data } = await api.get(
      '/wms/receiptdocs',
      {
        params: { warehouse_id: warehouseId }
      }
    )

    return data
  }

  filterList(list: DocType[], query: { [key: string]: string | string[]; }): DocType[] {
    const filterParams = omit(['warehouse_id', 'page'], query)

    // @ts-ignore
    return this._listUtils.sort(this._listUtils.filter(list, filterParams), query)
  }

  // eslint-disable-next-line class-methods-use-this
  async updateDoc(doc: DocType): Promise<AxiosResponse<DocType>> {
    const { id } = doc
    const result = await api.put(
      `/wms/receiptdocs/${id}`,
      doc
    )

    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async changeDocStatus(params: any): Promise<AxiosResponse<[]>> {
    const { id } = params
    try {
      const result = await api.patch(
        `/wms/receiptdocs/${id}`,
        {
          ...params,
          transport_info: params.transportInfo,
          pallet_quantity: params.palletQuantity,
          box_quantity: params.boxQuantity,
        }
      )
      return result.data
    } catch (error) {
      // @ts-ignore
      return undefined
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async changeDocAcceptance(params: any): Promise<AxiosResponse<{}>> {
    const { doc_id, position_id, acceptance_id, ...acceptance } = params
    const result = await api.put(
      `/wms/receiptdocs/${doc_id}/positions/${position_id}/acceptance/${acceptance_id}`,
      acceptance
    )
    return result.data
  }

  // eslint-disable-next-line class-methods-use-this
  async docAddGood(params: any): Promise<AxiosResponse<{}>> {
    const result = await api.post(
      `/wms/receiptdocs/${params.docId}/positions`,
      {
        goods_external_id: 1001,
        price: 100,
        quantity: 100,
        supplier_code: 'FTH-1022/B',
      }
    )
    return result.data
  }
}
