import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { getGetPimGoodsQueryKey } from 'features/goods/getGoods'
import { dialogModel, DialogContentWithTitle } from 'shared/ui/components/dialog'
import { GOODS_LIST } from 'shared/config/routes'
import { snackActions } from 'shared/lib/react/snackbar'
import { SettingsMenu, IMenuItem } from 'shared/ui/components'
import { GoodsToPackModal } from 'features/goods/updateToPackType'
import { GoodsToBundleModal } from 'features/goods/updateToBundleType'
import { GoodsToAssortmentModal } from 'features/goods/updateToAssortmentType'
import { ArchiveModal } from 'features/goods/archive'
import { pimApi } from 'shared/api'
import { MoreVert } from '@mui/icons-material'
import * as React from 'react'


export const GoodsSettings = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const goodsQuery = pimApi.goods.useFetchGoodsQuery(parseInt(id, 10))
  const queryClient = useQueryClient()
  const { mutate: updateGoodsTypeToCommonMutation } = pimApi.goods.useUpdateGoodsTypeToCommonMutation()
  const { mutate: updateAssortmentToCommonMutation } = pimApi.goods.useUpdateAssortmentToCommonMutation()
  const { mutate: deleteGoodsMutation } = pimApi.goods.useDeleteGoodsMutation()
  const { mutate: unarchiveGoodsMutation } = pimApi.goods.useUnarchiveGoodsMutation()
  const deletePossible = goodsQuery?.data?.deletePossible
  const isArchived = goodsQuery?.data?.inArchive
  const DEFAULT_SETTINGS: Array<IMenuItem> = [
    {
      label: isArchived ? 'Вернуть из архива': 'Перенести в архив',
      divider: true,
      handler: () => {
        if(isArchived && goodsQuery.data?.id) {
          unarchiveGoodsMutation({
            goodsId: goodsQuery.data?.id, 
            deArchivationInfo: {
              id: goodsQuery.data?.id,
              versionNo: goodsQuery.data?.versionNo,
            }
          }, {
            onSuccess: ({ data }) => {
              queryClient.setQueryData(
                getGetPimGoodsQueryKey(parseInt(id, 10)),
                data
              )
              snackActions.info('Товар успешно возвращен из архива!')
            }
          })
        } else {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => goodsQuery.data && <ArchiveModal close={close} accept={accept} goods={goodsQuery.data}/>,
              onAccept: () => {},
            }
          )
        }
      }
    },
    {
      label: 'Удалить товар',
      type: '#F50057',
      disabled: !deletePossible,
      handler: () => {
        dialogModel.openDialog(
          {
            component: ({ close, accept }) => 
              <DialogContentWithTitle 
                close={close}
                accept={accept}
                title="Вы уверены, что хотите удалить этот товар?"
                acceptBtnText="ПОДТВЕРДИТЬ"
              />,
            onAccept: () => {
              deleteGoodsMutation(
                { 
                  goodsId: id, 
                  data: { 
                    id,
                    versionNo: goodsQuery.data?.versionNo
                  }
                },
                { 
                  onSuccess: () => {
                    history.push(GOODS_LIST)
                    snackActions.info('Товар успешно удален!')
                  }
                }
              )
            },
          }
        )
      }
    }
  ]
  
  const goodsSettingsOptions = {
    common: [    
      {
        label: 'Преобразовать в спайку',
        disabled: isArchived,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => <GoodsToPackModal close={close} accept={accept} goodsId={id}/>,
              onAccept: () => {},
            }
          )
        }
      },
      {
        label: 'Преобразовать в комплект',
        disabled: isArchived,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => <GoodsToBundleModal close={close} accept={accept} goodsId={id}/>,
              onAccept: () => {},
            }
          )
        }
      },
      { 
        label: 'Преобразовать в ассортимент',
        disabled: isArchived,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => <GoodsToAssortmentModal close={close} accept={accept} goodsId={id}/>,
              onAccept: () => {},
            }
          )
        }
      },


      ...DEFAULT_SETTINGS
    ],
    pack: [
      { 
        label: 'Преобразовать в товар',
        disabled: isArchived,
        divider: true,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DialogContentWithTitle 
                  close={close}
                  accept={accept}
                  title="Преобразовать спайку в товар?"
                  acceptBtnText="ПОДТВЕРДИТЬ"
                  variant="contained"
                  acceptBtnColor="primary"
                />,
              onAccept: () => {
                updateGoodsTypeToCommonMutation(
                  { goodsId: id },
                  { 
                    onSuccess: ({ data }) => {
                      snackActions.info('Сохранено!')
                      queryClient.setQueryData(
                        getGetPimGoodsQueryKey(parseInt(id, 10)),
                        data
                      )
                    }
                  }
                )
              },
            }
          )
        }
      },
      ...DEFAULT_SETTINGS
    ],
    bundle: [
      { 
        label: 'Преобразовать в товар',
        disabled: isArchived,
        divider: true,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DialogContentWithTitle 
                  close={close}
                  accept={accept}
                  title="Преобразовать комплект в товар?"
                  acceptBtnText="ПОДТВЕРДИТЬ"
                  acceptBtnColor="primary"
                  variant="contained"
                />,
              onAccept: () => {
                updateGoodsTypeToCommonMutation(
                  { goodsId: id },
                  { 
                    onSuccess: ({ data }) => {
                      snackActions.info('Сохранено!')
                      queryClient.setQueryData(
                        getGetPimGoodsQueryKey(parseInt(id, 10)),
                        data
                      )
                    }
                  }
                )
              },
            }
          )
        }
      },
      ...DEFAULT_SETTINGS
    ],
    assortment: [
      { 
        label: 'Преобразовать в товар',
        disabled: isArchived,
        divider: true,
        handler: () => {
          dialogModel.openDialog(
            {
              component: ({ close, accept }) => 
                <DialogContentWithTitle 
                  close={close}
                  accept={accept}
                  title="Преобразовать ассортимент в товар?"
                  acceptBtnText="ПОДТВЕРДИТЬ"
                  acceptBtnColor="primary"
                  variant="contained"
                />,
              onAccept: () => {
                updateAssortmentToCommonMutation(
                  { goodsId: id },
                  { 
                    onSuccess: ({ data }) => {
                      snackActions.info('Сохранено!')
                      queryClient.setQueryData(
                        getGetPimGoodsQueryKey(parseInt(id, 10)),
                        data
                      )
                    }
                  }
                )
              },
            }
          )
        }
      },
      ...DEFAULT_SETTINGS
    ],
  }

  return (
    <SettingsMenu
      options={goodsSettingsOptions[goodsQuery?.data?.goodsType || 'common']}
      iconType={<MoreVert />}
    />
  )
}
