import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Tooltip,
  tooltipClasses,
} from '@mui/material'
import styled from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { HelpWithMargin } from 'shared/ui/styled/Help'
import { InfoOutlined } from '@mui/icons-material'
import { round } from 'shared/lib/utils/round'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { generatePath, useParams } from 'react-router'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import { useField } from 'react-final-form'
import { useMemo, useState } from 'react'
import { useOzonProductContext } from 'entities/pim/product/model/ozon/useOzonProductContext'
import { localStore } from 'shared/services/localStore'
import _uniqueId from 'lodash/uniqueId'

import {
  AccordionWrap,
  FormLabelStyled,
  PriceList,
  PseudoHeader,
  StyledDiscount,
  OtherPricesRowWrap,
  FirstCell,
  SecondCell,
  ThirdCell,
  OtherWarehousesSpan,
  WarehouseDataRowWrap,
  WarehouseList,
  HoverHeaderWarehouse,
  HoverWarehouseColumn,
  HoverProfitabilityColumns,
  HoverWarehouse,
  HoverHeader,
  HoverFbo,
} from './styled'

interface AccordionsVariations { 
  warehousesProfitability: boolean,
  otherMPPrices: boolean
}


const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: '400',
    padding: '12px',
  },
}))

export function WidgetsContainer() {
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const { ozonProductQuery } = useOzonProductContext()

  const expandedWidgetList = localStore.get('priceTabExpandedWidgets')

  const [expand, setExpand] = useState<AccordionsVariations>(
    expandedWidgetList
      ? JSON.parse(expandedWidgetList)
      : 
      {
        warehousesProfitability: true, 
        otherMPPrices: true,
      }  
  )

  const handleAccordionExpansion = (key: string) => () => {
    
    setExpand((prevState) =>  {
      const newObj = { ...prevState,
        [key]: !prevState[key]
      }

      localStore.set('priceTabExpandedWidgets', JSON.stringify(newObj))
      return newObj
    })

  }


  const { data: commonData } =
    pimApi.products.common.useFetchCommonProductQuery(productId)
  const { data: goodsProducts } = pimApi.goods.useGoodsProductsQuery(
    commonData?.goodsId
  )

  const newPrice = useField('newPrice').input.value || null

  const { data: newProfitabilityData } =
    pimApi.products.common.useProductsProfitabilityQuery({
      price: Math.floor(newPrice || ozonProductQuery?.data?.price),
      productId,
      priority: true,
    })

  const { data: currentProfitabilityData } =
    pimApi.products.common.useProductsProfitabilityQuery({
      price: Math.floor(ozonProductQuery?.data?.price),
      productId,
      priority: true,
    })

  const concatProfitability = useMemo(
    () =>
      currentProfitabilityData?.map((el) => ({
        current: el,
        new: newProfitabilityData?.find(
          (elem) =>
            elem.marketplacewarehouseName === el.marketplacewarehouseName
        ),
      })),
    [currentProfitabilityData, newProfitabilityData]
  )

  const mainWarehouses = concatProfitability?.slice(0, 4)
  const otherWarehouses =
    concatProfitability && concatProfitability.length > 4
      ? concatProfitability?.slice(4)
      : []

  const ProfitWarehouseData = () => (
    <>
      <PseudoHeader>
        <span>Склад</span>
        <span>Текущая</span>
        <span>Новая</span>
      </PseudoHeader>
      <WarehouseList>
        {mainWarehouses?.map((el) => (
          <WarehouseDataRow
            warehouseName={el.current.marketplacewarehouseName}
            perProfitFbo={el.current.perProfitFbo}
            profitFbo={el.current.profitFbo}
            key={_uniqueId()}
            newPerProfitFbo={el.new?.perProfitFbo}
            newProfitFbo={el.new?.profitFbo}
          />
        ))}
        <OtherWarehousesSpan>
          <span>Другие</span>
          <CustomTooltip
            title={
              <HoverWarehouse>
                <div>
                  <HoverHeaderWarehouse>Склад</HoverHeaderWarehouse>
                  {otherWarehouses.map((el) => (
                    <HoverWarehouseColumn key={_uniqueId()}>
                      {el.current.marketplacewarehouseName}
                    </HoverWarehouseColumn>
                  ))}
                </div>
                <HoverProfitabilityColumns>
                  <HoverHeader>Текущая</HoverHeader>
                  {otherWarehouses.map((el) => (
                    <HoverFbo key={_uniqueId()}>
                      <span>{round(el.current.profitFbo, 0)}</span>
                      <StyledDiscount style={{ marginLeft: '5px' }}>
                        {el.current.perProfitFbo}%
                      </StyledDiscount>
                    </HoverFbo>
                  ))}
                </HoverProfitabilityColumns>
                <HoverProfitabilityColumns>
                  <HoverHeader>Новая</HoverHeader>
                  {otherWarehouses.map((el) => (
                    <HoverFbo key={_uniqueId()}>
                      <span>{round(el.new?.profitFbo, 0)}</span>
                      <StyledDiscount style={{ marginLeft: '5px' }}>
                        {el.new?.perProfitFbo}%
                      </StyledDiscount>
                    </HoverFbo>
                  ))}
                </HoverProfitabilityColumns>
              </HoverWarehouse>
            }
            placement="right"
          >
            <HelpWithMargin>
              <InfoOutlined />
            </HelpWithMargin>
          </CustomTooltip>
        </OtherWarehousesSpan>
      </WarehouseList>
    </>
  )

  const WarehouseDataRow = ({
    warehouseName,
    profitFbo,
    perProfitFbo,
    newProfitFbo,
    newPerProfitFbo,
  }) => (
    <WarehouseDataRowWrap>
      <FirstCell title={warehouseName}>{warehouseName}</FirstCell>
      <SecondCell>
        <span>{round(profitFbo, 0)}</span>
        <StyledDiscount>{perProfitFbo}%</StyledDiscount>
      </SecondCell>
      <ThirdCell>
        <span>{newProfitFbo}</span>
        <StyledDiscount>{newPerProfitFbo}%</StyledDiscount>
      </ThirdCell>
    </WarehouseDataRowWrap>
  )



  return (
    <>
      {currentProfitabilityData ? (
        <AccordionTemplate
          expand={expand.warehousesProfitability}
          onExpandChange={handleAccordionExpansion('warehousesProfitability')}
          title="Доходность по складам"
          details={<ProfitWarehouseData />}
        />
      ) : null}
      {goodsProducts ? (
        <AccordionTemplate
          expand={expand.otherMPPrices}
          onExpandChange={handleAccordionExpansion('otherMPPrices')}
          title="Цена на других МП"
          details={<OtherPrices goodsProducts={goodsProducts} />}
        />
      ) : null}
    </>
  )
}

const AccordionTemplate = ({ expand, onExpandChange, title, details }) => {
  if (details) {
    return (
      <AccordionWrap>
        <Accordion defaultExpanded={true} expanded={expand}>
          <AccordionSummary
            onClick={onExpandChange}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <FormLabelStyled>{title}</FormLabelStyled>
          </AccordionSummary>
          <AccordionDetails>{details}</AccordionDetails>
        </Accordion>
      </AccordionWrap>
    )
  }
  return null
}

const OtherPricesRow = ({ mp, idProduct, price, link }) => (
  <OtherPricesRowWrap>
    <span title={mp}>{mp}</span>
    <Link href={generatePath(PRODUCT_EDIT, { id: idProduct })} underline="none">
      {idProduct}
    </Link>
    <span>{price}</span>

    <Link href={link} color={link ? '#2196F3' : '#00000042'}>
      <OpenInNewIcon fontSize="small" />
    </Link>
  </OtherPricesRowWrap>
)

const OtherPrices = ({ goodsProducts }) => (
  <PriceList>
    {goodsProducts?.map((el) => (
      <OtherPricesRow
        mp={el.marketplaceName}
        idProduct={el.id}
        price={el.price}
        link={el.marketplaceUrl}
        key={JSON.stringify(el)}
      />
    ))}
  </PriceList>
)
