import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { customerApi } from 'shared/api'

interface EditCabinet {
  companyId: UniqueId,
  cabinetId: UniqueId,
  editedCabinet: customerApi.EditCompanyMarketplace
}

export const editCabinet = ({ companyId, cabinetId, editedCabinet }: EditCabinet) =>
  api.put<customerApi.CompanyMarketplace>(
    `/customer/companies/${companyId}/cabinets/${cabinetId}`,
    editedCabinet
  )

export const useEditCabinetMutation = () =>
  useMutation<
    AxiosResponse<customerApi.CompanyMarketplace>,
    AxiosError<errorType>,
    EditCabinet
  >(editCabinet)