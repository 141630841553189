import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

interface AddValuesProps {
  dictionaryId: UniqueId
  name: string
}

const addValue = (newValue: AddValuesProps) =>
  api.post(`/pim/dictionaries/${newValue.dictionaryId}/values`, { name: newValue.name })


export const useAddValues = () => useMutation<
  AxiosResponse<pimApi.dictionaries.Value>,
  AxiosError<errorType>,
  AddValuesProps
>(addValue)