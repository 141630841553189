import React from 'react'
import { analyticsApi } from 'shared/api'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { DifferenceDataWidget } from 'widgets/DashboardWidgets'
import { Skeleton } from 'widgets/DashboardWidgets/components/Skeleton/Skeleton'

export const SmallOrdersWidget = () => {
  const utc = new Date()

  const { data: ordersData } =
    analyticsApi.useAnalyticsOrdersQuery(
      {
        dateFrom: subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 13),
        dateTo: subtractDays(new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)), 0),
        units: 'items',
      })
  const currentWeekQuantity = ordersData?.data.data.slice(7).reduce((sum, elem) => sum + elem.items, 0)
  const prevWeekQuantity = ordersData?.data.data.slice(0,7).reduce((sum, elem) => sum + elem.items, 0)
  const diff = (currentWeekQuantity !== undefined && prevWeekQuantity !== undefined) ? (currentWeekQuantity - prevWeekQuantity) : 0

  return (
    <>
      {currentWeekQuantity !== undefined ?
        <DifferenceDataWidget title="Заказы за неделю" data={currentWeekQuantity} diff={diff}/>
        : <Skeleton/>}
    </>
  )
}