import { useQuery, QueryFunctionContext } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

import { Activity } from './model'


interface IActivityParams {
  activityId: UniqueId
}

export const getActivityInfoQueryKey = (queryParams: IActivityParams) =>
  ['get', 'activity/activity', queryParams] as const

type GetActivityInfoQueryKey = ReturnType<typeof getActivityInfoQueryKey>

function getActivityInfo(queryParams: IActivityParams) {
  return api.get<Activity>(
    `/activity/activity/${queryParams.activityId}`)
}

const getAllActivityInfo = (context: QueryFunctionContext<GetActivityInfoQueryKey>) => {
  const [, , queryParams] = context.queryKey
  return getActivityInfo(queryParams)
}

export const useGetActivityInfoQuery = (queryParams: IActivityParams) =>
  useQuery<
    AxiosResponse<Activity>,
    errorType,
    AxiosResponse<Activity>,
    GetActivityInfoQueryKey
  >(
    getActivityInfoQueryKey(queryParams),
    getAllActivityInfo,
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false }
  )
