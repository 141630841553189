import { Box, Button, Typography } from '@mui/material'
import styled from 'styled-components'
import compPlug from 'assets/images/companiesPlug.svg'


const TITLE = 'У вас пока нет компаний'
const SUBTITLE = 'Чтобы начать работу \n добавьте компанию'

const WrapperPlug = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
`

const Plug = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Icon = styled.div`
  width: 190px;
  height: 190px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.12));
  img{
    width: 132px;
    margin-left: 18px;
  }
`

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
`

const Subtitle = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  margin-bottom: 24px;
  white-space: pre-line
`
export const CompaniesPlug = ({ handleAddCompany }) => (
  <WrapperPlug>
    <Plug>
      <Icon>
        <img src={compPlug} alt="placeholder"/>
      </Icon>
      <Title>
        {TITLE}
      </Title>
      <Subtitle>
        {SUBTITLE}
      </Subtitle>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddCompany}
      >ДОБАВИТЬ
      </Button>
    </Plug>


  </WrapperPlug>
)