/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'







export const apiFunction = () =>
  api.get(`/customer/user/photo`,{ responseType: 'blob' })


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = () => ['get', `/customer/user/photo`] as const

export const useGetUsersPhoto = (options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(),
    () => apiFunction(),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

