import React from 'react'
import { Box } from '@mui/material'
import { pimApi } from 'shared/api'

import { Competitors } from './ui/competitors/Competitors'
import { PriceSettings } from './ui/priceAdviceSettings/PriceSettings'

export const CompetitorsTab = ({ commonProduct }: { commonProduct: pimApi.products.common.Product }) => (
  <Box display="flex" flexDirection="column">
    <PriceSettings commonProduct={commonProduct} />
    <Competitors commonProduct={commonProduct} />
  </Box>
)
