import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import CrossDottedRed from 'assets/images/CrossDottedRed.svg'
import { InputsWrapper } from 'shared/ui/product/LeftRightInputWrapper/ui'
import { ArrowForwardStyled } from 'shared/ui/product/LeftRightInputWrapper/ui/styled'

import { UnmapArrowStyled } from './styled'
import { LeftComponent } from './LeftComponents'

import {
  NormalizedAttributesVariation as NormalizedAttributesVariationType,
  ProductAttributeValue
} from '../../lib/getNormalizedAttributes'
import { RightComponent } from '../RightComponent'

interface NormalizedAttributesVariationProps {
  normalizedAttributesVariations: Array<NormalizedAttributesVariationType>
  onUnmapAttribute: (index: number) => () => void
  showGoodsInfo: boolean
  onAttributeValueChange: (
    id: number
  ) => (productAttributeValues: Array<ProductAttributeValue>) => void
  disabled?: boolean
}

export const NormalizedAttributesVariation: FC<NormalizedAttributesVariationProps> = ({
  normalizedAttributesVariations,
  onUnmapAttribute,
  showGoodsInfo,
  onAttributeValueChange,
  disabled
}) => (
  <>

    {normalizedAttributesVariations.map(({ left, right }, index) => (
      <InputsWrapper
        hideGoodsInfo={!showGoodsInfo}
        key={`${left?.name}${right.attributeName}`}
        description={right.description}
        arrow={left && (
          <UnmapArrowStyled $disabled={disabled}>
            <ArrowForwardStyled />
            <IconButton aria-label="unmap" onClick={onUnmapAttribute(index)} disabled={disabled} size="large">
              <img src={CrossDottedRed} alt="Удалить связывание" />
            </IconButton>
          </UnmapArrowStyled>
        )}
      >
        {left ? <LeftComponent attribute={left} /> : null}
        <RightComponent right={right} onChange={onAttributeValueChange(right.id)} disabled={disabled} />
      </InputsWrapper>
    ))}
  </>
)