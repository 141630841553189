import styled from 'styled-components'
import { Link } from '@mui/material'

export const StyledLink = styled(Link)`
  color: #2296f3;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  align-self: center;
`
