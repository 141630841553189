import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


interface DeleteCompetitor {
  competitorId: UniqueId,
  goodsId: UniqueId
  value: pimApi.DeletingObject
}
const deleteCompetitor = ({ competitorId, goodsId, value }: DeleteCompetitor) =>
  api.delete(
    `/pim/goods/${goodsId}/competitors/${competitorId}`,
    { data: value }
  )


export const useDeleteCompetitorMutation = () =>
  useMutation<
    AxiosResponse,
    AxiosError<errorType>,
    DeleteCompetitor
  >(deleteCompetitor)