import {  useState } from 'react'
import { Box } from '@mui/material'
import { ModalContent } from 'shared/ui/components/ModalComponents'

interface ConfirmModalProps {
  close: () => void
  accept: () => void
  title?: string
}

export const MediaDeleteConfirmModal = ({ close, accept, title = 'Действительно удалить?' }: ConfirmModalProps) => {
  const [isLoading, setIsloading] = useState(false)
  const hadleAccept = () => {
    setIsloading(true)
    accept()
  }
  return (
    <Box width="500px">
      <ModalContent
        close={close}
        accept={hadleAccept}
        title={title}
        acceptBtnText="Удалить"
        acceptBtnColor="error"
        isLoading={isLoading}
      />
    </Box>
  )
}
