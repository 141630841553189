import * as yup from 'yup'
import { yupTransformEmptyStringInNull } from 'shared/lib/utils'

const innValueCheck = (inn) => {
  if (inn !== undefined) {
    return inn?.length === 10 || inn?.length === 12
  }
  return true
}

const kppValueCheck = (kpp) => {
  if ((kpp !== undefined) && (kpp !== null)) {
    return kpp?.length === 0 || kpp?.length === 9
  }
  return true
}

export const validationSchema = yup.object().shape({

  shortName: yup
    .string()
    .max(100)
    .required('Обязательное поле')
    .transform(yupTransformEmptyStringInNull),

  inn: yup
    .string()
    .required('Обязательное поле')
    .test('InnCheck', 'Длина ИНН должна быть 10 или 12 цифр', value => innValueCheck(value))
    .matches(/^[0-9]+$/, 'ИНН может содержать только цифры')
    .transform(yupTransformEmptyStringInNull),

  kpp: yup
    .string()
    .nullable()
    .test('KppCheck', 'Длина КПП должна быть 9 цифр', value => kppValueCheck(value))
    .matches(/^[0-9]+$/, { message:'КПП может содержать только цифры',excludeEmptyString:true } ),

  invoiceTemplateSettings: yup.object().nullable().shape({
    priceColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),

    quantityColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),

    supplierCodeColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),
  }),

  orderformTemplateSettings: yup.object().nullable().shape({
    quantityColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),

    quantityInBoxColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),

    supplierCodeColumn: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, 'только число')
      .max(2, 'только число'),
  }),

})
