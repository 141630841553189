import React, { useEffect, useState } from 'react'
import {
  Box,
  DialogActions,
  Button, 
  Grid,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi } from 'shared/api'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import TextField from '@mui/material/TextField'


export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 480px;
    max-width: 480px;
  }
`
interface BrandData {
  id?: UniqueId
  name: string
  code: string
  versionNo?: number
  comments?: string
}

interface BrandAddModalProps {
    close: () => void;
    accept: (newBrandData: BrandData) => void
    changedCell?: pimApi.dictionaries.Brand
}

export const BrandModal = ({
  close,
  accept,
  changedCell,
}: BrandAddModalProps) => {

  const [brandName, setBrandName] = useState(changedCell?.name || '')
  const [brandCode, setBrandCode] = useState(changedCell?.code || '')
  const [isDisabled, setIsDisabled] = useState(true)


  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(event.target.value)
  }
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrandCode(event.target.value)
  }

  const handleSubmit = () => {
    if (changedCell) {
      const editedBrand = {
        id: changedCell.id,
        versionNo: changedCell.versionNo,
        name: brandName,
        code: brandCode,
        comments: ''
      }
      accept(editedBrand)
      close()
    } else {
      const newBrand = {
        name: brandName,
        code: brandCode,
        comments: ''
      }
      accept(newBrand)
      close()
    }}


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && event.target.value === undefined) {
        event.preventDefault()
        handleSubmit()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  useEffect(()=> {
    if ((brandName === changedCell?.name && brandCode === changedCell?.code) || brandName.length === 0) {
      setIsDisabled(true)
    } else setIsDisabled(false)

  }, [brandCode, brandName])

  return (
    <ModalContainer open={true}>
      <Box>
        <IconCloseButton aria-label="close" onClick={close}>
          <img src={CloseIcon} alt="close icon" />
        </IconCloseButton>
        <Box fontSize={20} m={3} p={0} fontWeight={500}>
          {changedCell 
            ? 'Изменить информацию о бренде'
            : 'Создание бренда'
          }
        </Box>
        <Box px={3}>
          <Grid item={true} xs={6}>
            <Typography fontSize={14} fontWeight={500}>
              Наименование
            </Typography>
            <TextField
              sx={{ marginTop: '16px' }}
              id="brandNameField"
              label="Наименование бренда"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={brandName}
              onChange={handleNameChange}
            />
          </Grid>
          <Typography mt={2} fontSize={14} fontWeight={500}>
            Код бренда
          </Typography>
          <Grid item={true} xs={6}>
            <TextField
              sx={{ marginTop: '16px' }}
              id="brandCodeField"
              label="Код бренда"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={brandCode}
              onChange={handleCodeChange}
            />
          </Grid>

          <DialogActions sx={{
            marginTop: '24px',
            padding: 0,
            marginBottom: '24px'
          }}>

            <Box>
              <Button
                onClick={close}
                color="primary"
              >
                ОТМЕНА
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={false}
                disabled={isDisabled}
                onClick={handleSubmit}
                sx={{
                  marginLeft: 2,
                }}
              >
                {changedCell
                  ? 'ИЗМЕНИТЬ'
                  : 'ДОБАВИТЬ'
                }
              </LoadingButton>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </ModalContainer>
  )
}



