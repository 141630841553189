import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { api } from '../../../../base'

type MarketplaceOptions = 'wb' | 'ozon' | 'simple'
const massImportMediaFromProduct = (
  { productId, marketplaceName }: {
    productId: number, marketplaceName: MarketplaceOptions
  }) => (api.patch(`/pim/products/${marketplaceName}/product/${productId}/photos`, {
}, { params: { method: 'copy_media' } }))

export const useMassImportMediaFromProduct = () =>
  useMutation<
    AxiosResponse,
    errorType,
    {
      productId: UniqueId,
      marketplaceName: MarketplaceOptions
    }
  >(({ productId, marketplaceName }) =>
    massImportMediaFromProduct({ productId, marketplaceName }))

const importMediaFromProduct = ({ productId, mediaId, marketplaceName }:
                                  { productId: UniqueId, mediaId: UniqueId, marketplaceName: MarketplaceOptions }) => (
  api.patch(`/pim/products/${marketplaceName}/product/${productId}/photos/${mediaId}`, {
  }, { params: { method: 'copy_media' } }))

export const useImportMediaFromProduct = () =>
  useMutation<
    AxiosResponse,
    errorType,
    {
      productId: UniqueId,
      mediaId: UniqueId,
      marketplaceName: MarketplaceOptions
    }
  >(({ productId, mediaId, marketplaceName }) => importMediaFromProduct({ productId, mediaId, marketplaceName }))