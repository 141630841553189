export const sortAttributesByNameAndRequired = (
  attributes
) =>
  attributes.sort(
    (a, b) => {
      const firstAttr = a?.right || a?.left
      const secondAttr = b?.right || b?.left
      const firstAttrName = firstAttr?.attributeName || firstAttr?.name
      const secondAttrName = secondAttr?.attributeName || secondAttr?.name

      if(firstAttr?.required && !secondAttr?.required) {
        return -1
      } if (firstAttr?.required && secondAttr?.required) {
        return firstAttrName?.localeCompare(secondAttrName)
      } if (!firstAttr?.required && secondAttr?.required) {
        return 1
      }
      return firstAttrName?.localeCompare(secondAttrName)
    }
  )