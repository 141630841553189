import { AxiosResponse, AxiosError } from 'axios'
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { pimApi } from 'shared/api'


export const editCategory = (editedCategory: pimApi.categories.EditCategory) =>
  api.put<pimApi.categories.Category>(
    `/pim/categories/${editedCategory.id}`,
    editedCategory
  )


export const useEditCategoryMutation = () =>
  useMutation<
    AxiosResponse<pimApi.categories.Category>,
    AxiosError<errorType>,
    pimApi.categories.EditCategory
  >(editCategory)
