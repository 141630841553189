import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { api } from 'shared/api/base'

const changeOzonProductsOfferId = (
  { productId, methodParam }: { productId: number, methodParam: string }) => (api.patch(`/pim/products/ozon/product/${productId}`, {
}, { params: { method: 'change_offerid', method_param: methodParam } }))

export const useChangeOzonProductsOfferId = () =>
  useMutation<
    AxiosResponse,
    errorType,
    {
      productId: UniqueId,
      methodParam: string
    }
  >(({ productId, methodParam }) =>
    changeOzonProductsOfferId({ productId, methodParam }))