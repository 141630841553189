import { pimApi } from 'shared/api'
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'


type QueryKey = ReturnType<typeof getListCompetitorsQueryKey>

export const getListCompetitorsQueryKey = (goodsId: UniqueId) =>
  ['get', '/goods/', goodsId, '/competitors'] as const

function getListCompetitors(
  goodsId: UniqueId
) {
  return api.get<pimApi.goods.Competitor[]>(
    `/pim/goods/${goodsId}/competitors`
  ).then( ({ data }) => data)
}

// function getListCompetitorsFn(context: QueryFunctionContext<QueryKey>) {
//   const [, ,goodsId] = context.queryKey
//   return getListCompetitors(goodsId).then(
//     (res) => res.data
//   )
// }

export const useGetListCompetitors = (goodsId: UniqueId) =>
  useQuery<
    pimApi.goods.Competitor[],
    errorType,
    pimApi.goods.Competitor[],
    QueryKey
  >(getListCompetitorsQueryKey(goodsId), (context) => getListCompetitors(context.queryKey[2] as UniqueId),
    { keepPreviousData: true, refetchOnMount: false })