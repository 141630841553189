// @ts-nocheck
import styled from 'styled-components'
import { TableSummaryRow, Table } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

const StyledTableTotalRowComponent = styled(TableSummaryRow.TotalRow)`
  background-color: #F6F6F6;
  > td {
    border-bottom-style: none !important;
  }
`

const Component = (props: Table.RowProps) => (<StyledTableTotalRowComponent {...props}  />) 
export const TableTotalRowComponent = memo(Component)
