import React, { memo } from 'react'
import { format } from 'date-fns'
import { DateFormats } from 'shared/config/dateFormats'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LineChart,
  Line,
} from 'recharts'
import isArray from 'lodash/isArray'
import { Margin } from 'recharts/types/util/types'
import { CurveType } from 'recharts/types/shape/Curve'
import { Props as XAxisProps } from 'recharts/types/cartesian/XAxis'
import { Props as YAxisProps } from 'recharts/types/cartesian/YAxis'
import { Props as CartesianGridProps } from 'recharts/types/cartesian/CartesianGrid'
import { Props as LineProps } from 'recharts/types/cartesian/Line'

import { StyledResponsiveContainer } from './styled'

export const DEFAULT_COLORS = ['#3F51B5', '#64B6F7', '#FF4081', '#7BC67E']

interface ChartProps {
  data?: Array<any>
  width?: number
  height?: number
  min?: number
  max?: number
  xAxisDataKey: string
  yAxisDataKey?: string | Array<string>
  yAxisOptions?: Partial<YAxisProps>
  xAxisOptions?: Partial<XAxisProps>
  chartDataKey: string | Array<string>
  showTooltip?: boolean
  isBiaxial?: boolean
  margin?: Margin
  colors?: Array<string>
  lineType?: CurveType
  cartesianGridOptions?: CartesianGridProps
  lineOptions?: Partial<LineProps>
}

function formatterFunc(value, name, props) {
  if (
    name === 'futurePrice' &&
    props.payload?.price &&
    props.payload.futurePrice
  ) {
    return null
  }
  return [`${value}₽`, 'Цена']
}

// @ts-ignore
export const Chart = memo(({
  data,
  width,
  height,
  min = 0,
  max = 0,
  xAxisDataKey,
  xAxisOptions,
  yAxisOptions,
  chartDataKey,
  showTooltip,
  isBiaxial,
  margin = { top: 2, left: 0, right: 0, bottom: 0 },
  colors = DEFAULT_COLORS,
  lineType = 'monotone',
  cartesianGridOptions,
  lineOptions
}: ChartProps) => (
  <StyledResponsiveContainer width="100%" height="100%">
    <LineChart
      width={width}
      style={{ cursor: 'pointer' }}
      height={height}
      data={data}
      margin={margin}
    >
      {/* @ts-ignore */}
      <CartesianGrid strokeDasharray="3 3" {...cartesianGridOptions} />

      <XAxis
        dataKey={xAxisDataKey}
        type="number"
        stroke="rgba(0, 0, 0, 0.38)"
        domain={[min, max]}
        tickFormatter={(tickItem) =>
          format(new Date(tickItem), DateFormats.dayMonth)
        }
        padding={{ right: 0, left: 0 }}
        orientation="bottom"
        allowDuplicatedCategory={true}
        interval={0}
        {...xAxisOptions}
      />

      {isBiaxial ? (
        <>
          <YAxis
            yAxisId={chartDataKey[0]}
            allowDataOverflow={false}
            width={40}
            padding={{ top: 0, bottom: 15 }}
            type="number"
            domain={[0, 'auto']}
            allowDuplicatedCategory={false}
            tick={{
              stroke: colors[0],
            }}
            {...yAxisOptions}
          />
          <YAxis
            yAxisId={chartDataKey[1]}
            orientation="right"
            allowDataOverflow={false}
            width={40}
            padding={{ top: 0, bottom: 15 }}
            type="number"
            domain={[0, 'auto']}
            allowDuplicatedCategory={false}
            tick={{
              stroke: colors[1],
            }}
            {...yAxisOptions}
          />
        </>
      ) : (
        <YAxis
          allowDataOverflow={false}
          width={40}
          padding={{ top: 1, bottom: 0 }}
          type="number"
          domain={[0, 'auto']}
          allowDuplicatedCategory={true}
        />
      )}

      {showTooltip && data?.length && (
        <Tooltip
          allowEscapeViewBox={{ x: false, y: true }}
          filterNull={true}
          labelFormatter={(labelItem) =>
            format(new Date(labelItem), DateFormats.dayMonthYearHoursMinutes)
          }
          formatter={formatterFunc}
        />
      )}
      <Legend payload={[]} />
      {isArray(chartDataKey) ? (
        chartDataKey.map((dataKey, index) => (
          // @ts-ignore
          <Line
            key={dataKey}
            type={lineType}
            dataKey={dataKey}
            activeDot={false}
            dot={false}
            strokeWidth="1"
            connectNulls={true}
            isAnimationActive={false}
            fillOpacity={0.5}
            fill={colors[index % colors.length]}
            stroke={colors[index % colors.length]}
            {...(isBiaxial ? {
              yAxisId: chartDataKey?.[index]
            } : {})}
            {...lineOptions}
          />
        ))
      ) : (
        <>
          {/* @ts-ignore */}
          <Line
            type={lineType}
            dataKey={chartDataKey}
            activeDot={false}
            dot={false}
            strokeWidth="2"
            connectNulls={true}
            isAnimationActive={false}
            fillOpacity={0.5}
            fill={colors[0]}
            stroke={colors[0]}
            {...lineOptions}
          />
        </>
        
      )}
    </LineChart>
  </StyledResponsiveContainer>
))
