export const isSameValues = (source, target) =>
  JSON.stringify(source) === JSON.stringify(target)

export const getDiffKeys = (source, target) => {
  const diff: any = []
  Object.keys(source).forEach(el => {
    if (JSON.stringify(source[el]) !== JSON.stringify(target[el])) {
      diff.push(
        {
          key: el,
          source: source[el],
          target: target[el]
        },
      )
    }
  })
  return diff
}
