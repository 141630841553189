import { InputAdornment, Box, CircularProgress, Alert } from '@mui/material'
import { Search } from '@mui/icons-material'
import React, { FC, useState } from 'react'
import { AppPimGoodsModelsGoodsGoodsComponent } from 'domains/pim/goods'
import { isEmpty } from 'shared/lib/checkers/isNotEmptyArray'
import { NewComponentCard } from 'entities/pim/goodsComponents'
import { pimApi } from 'shared/api'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { InputLabel } from 'shared/ui/components'

import { ProductSearchWrapper, SearchInput } from './styled'

interface GoodsComponentSearchProps {
  goodsComponents: Array<AppPimGoodsModelsGoodsGoodsComponent>
  onAddComponent: (
    newGoodsComponent: AppPimGoodsModelsGoodsGoodsComponent
  ) => void
}

export const GoodsComponentSearch: FC<GoodsComponentSearchProps> = ({
  goodsComponents,
  onAddComponent,
}) => {
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 200)
  const { data, isLoading } = pimApi.goods.useGoodsByCodeQuery(
    debouncedValue,
    false
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleAdd = (goods: pimApi.goods.GoodsShortInfo, quantity: number) => {
    const newComponent: AppPimGoodsModelsGoodsGoodsComponent = {
      componentId: goods.id,
      quantity,
      name: goods.name,
      code: goods.code,
      auxCode: goods.auxCode,
      goodsBaseImageId: goods.goodsBaseImageId,
      goodsType: goods.goodsType,
      unitCode: goods.unitCode,
      unitPartible: goods.unitPartible,
    }

    onAddComponent(newComponent)
  }

  const productListFiltered =
    data?.data.filter(
      (item) =>
        !goodsComponents.find((component) => component.componentId === item.id)
    ) ?? []

  return (
    <ProductSearchWrapper isExist={isEmpty(goodsComponents)}>
      <InputLabel
        label="Поиск товара"
        required={false}
      />
      <Box>
        <SearchInput
          name="searchField"
          variant="outlined"
          size="small"
          fullWidth={true}
          onChange={handleChange}
          placeholder="Введите артикул товара"
          value={value}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && <CircularProgress size={20} />}
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {(productListFiltered.length === 0 && value.length !== 0) &&
          <Alert
            sx={{
              height: '40px',
              padding: '0',
              marginTop: '16px',
              paddingLeft: '18px',
              alignItems: 'center'
            }} severity="error">Товары по данному артикулу не найдены
          </Alert>
        }
      </Box>
      {productListFiltered.length !== 0 &&
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: '8px' }}>
        {productListFiltered.map((item) => (
          <NewComponentCard
            key={item.id}
            goods={item}
            onAddClick={handleAdd}
            isAddDisabled={goodsComponents.length >= 1}
            isInputDisabled={true}
            btnText="ВЫБРАТЬ"
          />
        ))}
      </Box>
      }
    </ProductSearchWrapper>
  )
}
