import styled from 'styled-components'

export const StyledPlugContainer = styled.div<{ height: number }>`
  min-width: 100px;
  height: ${({ height }) => `${height - 290}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 16px;
    font-weight: 500;
    white-space: break-spaces;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    margin-bottom: 5px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    white-space: break-spaces;
    text-align: center;
    margin-bottom: 24px;
  }
  .plug-review-text {
    color: #00000099;
    font-size: 16px;
    text-align: center;
    white-space: break-spaces;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
`
