import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosResponse } from 'axios'

import { toSnakeCase } from '../../../lib/transform'
import { toSnake } from '../../../lib/transform/toSnakeCase'


export const getPriceSettingsQueryKey = (queryParams) =>
  ['get', 'pim/pricecontrol/pricesettings', queryParams] as const

type GetProductPriceSettingsQueryKey = ReturnType<typeof getPriceSettingsQueryKey>
function getPriceSettings(queryParams): Promise<AxiosResponse<pimApi.price.ProductWithPriceSettings[]>> {
  const queryParamsSnake = toSnakeCase(queryParams)

  if (queryParamsSnake.sort) {
    queryParamsSnake.sort = toSnake(queryParamsSnake.sort)
  }
  return api.get<pimApi.price.ProductWithPriceSettings[]>('/pim/pricecontrol/pricesettings', {
    params: queryParamsSnake
  })
}

export function getPriceSettingsFn(context: QueryFunctionContext<GetProductPriceSettingsQueryKey>) {
  const [, , queryParams] = context.queryKey
  return getPriceSettings(queryParams).then((res) => res.data)
}

export const useGetPriceSettingsQuery = (queryParams) =>
  useQuery<
    pimApi.price.ProductWithPriceSettings[],
    errorType,
    pimApi.price.ProductWithPriceSettings[],
    GetProductPriceSettingsQueryKey
  >(
    getPriceSettingsQueryKey(queryParams),
    getPriceSettingsFn, {
      refetchOnMount: false
    }
  )
