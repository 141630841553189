import { Table } from '@devexpress/dx-react-grid-material-ui'
import { pimApi } from 'shared/api'
import { getGoodsEditPath } from 'shared/config'
import { GoodsTypeCell, PhotoCell, DefaultCell } from 'shared/ui/components/Table/ui/TableCells'
import { LinkNameCell } from 'shared/ui/components/Table/ui/TableCells/LinkNameCell'
import { GoodsSupplying } from 'shared/api/trade/stocks'

import { StyledTableCell } from './styled'
import { columns } from './columnsConfig'

const fileThumbnailSize = { maxWidth: 50, maxHeight: 50 }


const getCell = (name: string, row, cellValue: string) => {
  const isArchived = row.inArchive

  switch (name) {
    case columns.goodsType:
      return <GoodsTypeCell goodsType={row.goodsType} />

    case columns.hasPhoto:
      return (
        <PhotoCell
          apiUrl={row.hasPhoto && pimApi.goods.getGoodsThumbnailApiPath(
            row.goodsId,
            fileThumbnailSize
          )}
          isArchived={row.inArchive}
        />
      )

    case columns.goodsName: {
      return (
        <LinkNameCell
          name={row.goodsName}
          path={row.goodsId && getGoodsEditPath(row.goodsId)}
          isArchived={isArchived}
        />
      )
    }

    default:
      return <DefaultCell value={cellValue} />
  }
}

type TableCellComponentProps = Omit<Table.DataCellProps, 'row'> & {
  row: GoodsSupplying
}

export const TableCellComponent = (props: TableCellComponentProps) => {
  const { column, row, value } = props
  const isArchived = false // row.inArchive
  return (
    <StyledTableCell {...props} $isArchived={isArchived}>
      {getCell(column.name, row, value)}
    </StyledTableCell>
  )
}
