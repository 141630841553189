import React, { memo } from 'react'
import { IMaskInput } from 'react-imask'

import { IMaskProps } from './types'

const DEFINITION = {
  '#': /[1-9]/,
}

const MAP_TO_RADIX = [',']

const MaskInput = React.forwardRef((props: IMaskProps, ref) => (
  <IMaskInput
    {...props}
    value={props.value.toString()}
    mask={Number}
    radix="." // fractional delimiter
    mapToRadix={MAP_TO_RADIX} // symbols to process as radix
    definitions={DEFINITION}
    // @ts-ignore
    inputRef={ref}
    overwrite={true}
  />
))

export const MaskInputMemo = memo(MaskInput)
