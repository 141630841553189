/* eslint-disable max-len */
import React from 'react'

import { StyledTextContainer } from '../../styled'

export const DocumentRequirements = () => {
  const Text = 'Размер — до 10 Мбайт'
  const Element = (<StyledTextContainer>
    <ul>
      <li>размер — до 10 Мбайт</li>
    </ul>
  </StyledTextContainer>
  )

  return { Element, Text }
}