import styled from 'styled-components'
import { Box, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 9px;
  background-color: ${props => props.color};`

export const StyledText = styled.span`
  width: 32px;
  height: 26px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 6px;
  margin-right: 5px;
  line-height: 133.4%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const StyledClassText = styled.span`
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  flex: none;
  order: 0;
  flex-grow: 1;
`

export const StyledToolTipDiv = styled.div`
  display: flex;
  margin-top: 1px;
`

export const StyledTool = styled(Tooltip)`
  width: 15px;
  height: 15px;
  white-space: pre-line
`

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  height: 18px;
  width: 18px;
  opacity: 0.68;
`

export const StyledBox = styled(Box)`
  display: flex;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #E0E0E0;
  padding: 8px;
  gap: 6px;
  width: 90px;
  height: 66px;
  flex-direction: row
`