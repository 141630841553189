import React from 'react'
import {
  Box,
  DialogTitle,
  Typography,
  Button,
  DialogActions
} from '@mui/material'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import { IconCloseButton } from 'shared/ui/components/dialog/ui/styled'
import CloseIcon from 'assets/images/closeIcon.svg'
import { LoadingButton } from '@mui/lab'

export const ModalContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 680px;
    max-width: 680px;
  }
`

export const ColoredText = styled.span`
  color: #2196F3;
  font-style: italic;
`

export const CheckOrderModal = ({ close, accept }) => (
  <ModalContainer open={true}>
    <Box>
      <IconCloseButton aria-label="close" onClick={close}>
        <img src={CloseIcon} alt="close icon"/>
      </IconCloseButton>
      <DialogTitle fontSize={20}>
        Проверка бланка заказа
      </DialogTitle>
      <Box px={3}>

        <Typography fontSize={14} width={510}>
          После нажатия кнопки
          <ColoredText> Проверить </ColoredText>
          ниже, будет загружен файл с результатами анализа бланка заказа.
          Результаты анализа будут находиться на отдельном листе файла
        </Typography>
          
        <DialogActions sx={{
          marginTop: '24px',
          padding: '16px 0 0 0',
          marginBottom: '24px',
          borderTop: '1px solid rgba(196, 196, 196, 0.25)'
        }}>

          <Box>
            <Button
              onClick={close}
              color="primary"
            >
              ОТМЕНА
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              onClick={accept}
              loading={false}
              sx={{
                marginLeft: 2,
              }}
            >
              ПРОВЕРИТЬ
            </LoadingButton>
          </Box>
        </DialogActions>
      </Box>
    </Box>
  </ModalContainer>
)




