import React from 'react'
import { TablePagination } from '@mui/material'
import { getRowsPerPageOptions } from 'shared/ui/components/Table/lib'

import { DataParamsTypes, reviewsCountType } from '../../lib/types'

interface Props {
  dataParams: DataParamsTypes
  setDataParams: (e) => void
  currentRowsPerPage: number
  pagingPanelLocale: any
  reviewsCount: reviewsCountType
}

export const ReviewsPagination = ({ dataParams, setDataParams, reviewsCount, currentRowsPerPage, pagingPanelLocale }: Props) => {
  const handleChangePage = (
    _,
    newPage,
  ) => {
    setDataParams(prev => ({ ...prev,
      page: newPage,
    }))
  }

  const totalCount = () => {
    if (dataParams.viewed === false && dataParams.processed === false) {
      return reviewsCount.new
    }
    if (dataParams.viewed === true && dataParams.processed === false) {
      return reviewsCount.viewed
    }
    if (dataParams.viewed === true && dataParams.processed === true) {
      return reviewsCount.processed
    }
    if (dataParams.exam === true) {
      return reviewsCount.exam
    }
    return reviewsCount.allcount
  }

  const pageSizes = getRowsPerPageOptions(totalCount() || 0, currentRowsPerPage)

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setDataParams(prev => ({ ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }))
  }
  
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <TablePagination
        count={totalCount() || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={dataParams.page}
        rowsPerPageOptions={pageSizes}
        rowsPerPage={dataParams.pageSize}
        labelDisplayedRows={pagingPanelLocale.info}
        labelRowsPerPage={pagingPanelLocale.rowsPerPage}
      />
    </div>
  )
}