import React, { PropsWithChildren } from 'react'
import { QueryObserverResult } from 'react-query'

const SimpleProductContext = React.createContext<any | undefined>(undefined)

interface SimpleProductContextProviderProps {
  simpleProductQuery: QueryObserverResult,
}

function SimpleProductContextProvider({ children, simpleProductQuery }: PropsWithChildren<SimpleProductContextProviderProps>) {
  return (
    <SimpleProductContext.Provider value={{ simpleProductQuery }}>{children}</SimpleProductContext.Provider>
  )
}

function useSimpleProductContext() {
  const context = React.useContext(SimpleProductContext)

  if (context === undefined) {
    throw new Error('useGoodsContext must be used within a GoodsProvider')
  }

  return context
}

export { SimpleProductContextProvider, useSimpleProductContext }