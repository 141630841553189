import { useQueryParams } from 'shared/lib/hooks/useQueryParams'

export const LocationCell = ({ row }) => {
  const { handleChangeParams, searchObj } = useQueryParams()
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <span
      onClick={() =>
        handleChangeParams({
          params: {
            areaId: row.areaId.toString() === searchObj.areaId ? null : row.areaId
          },
          options: { skipNull: true } }
        )}
      style={{ cursor: 'pointer', color: '#007DFF' }}>
      { row.location }
    </span>
  )
}