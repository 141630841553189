import { Box, Grid, TextField, Tooltip } from '@mui/material'
import { InputLabel } from 'shared/ui/components'
import { InfoOutlined } from '@mui/icons-material'
import { Help } from 'shared/ui/styled/Help'
import { useGoodsContext } from 'shared/lib/hooks/useGoodsContext'
import { useMemo } from 'react'

import { H1PriceControl } from './styled'

export function PriceInfo({ copackingCostInfo, commonProduct }) {
  const goodData = useGoodsContext()

  const actualSupplier = useMemo(() => {
    if (goodData?.suppliers !== undefined) {
      if (goodData.suppliers.length > 0) {
        return goodData.suppliers.reduce((a, b) =>
          (a.id || 0) > (b.id || 0) ? a : b
        )
      }
    }
    return null
  }, [goodData])

  return (
    <Box>
      <H1PriceControl>Дополнительная информация</H1PriceControl>
      <Box>
        <Grid
          container={true}
          direction="row"
          spacing={2}
          sx={{ paddingRight: '24px' }}
        >
          <Grid
            container={true}
            direction="column"
            item={true}
            spacing={2}
            xs={4}
          >
            <Grid item={true}>
              <InputLabel
                label="Входящая информация"
                required={false}
                single={true}
              />
            </Grid>
            <Grid item={true}>
              <TextField
                size="small"
                label="Входящая цена"
                fullWidth={true}
                disabled={true}
                variant="outlined"
                value={actualSupplier?.incomingPrice}
              />
            </Grid>
            <Grid item={true}>
              <TextField
                size="small"
                label="Себестоимость"
                fullWidth={true}
                disabled={true}
                value={commonProduct.netCost}
              />
            </Grid>
          </Grid>
          <Grid
            container={true}
            direction="column"
            item={true}
            spacing={2}
            xs={4}
          >
            <Grid item={true} height={36} />
            <Grid item={true}>
              <TextField
                size="small"
                label="РРЦ"
                fullWidth={true}
                variant="outlined"
                value={actualSupplier?.rrp}
                disabled={true}
              />
            </Grid>
            <Grid item={true}>
              <TextField
                size="small"
                label="Минимальная цена"
                fullWidth={true}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid
            container={true}
            direction="column"
            item={true}
            spacing={2}
            xs={4}
          >
            {copackingCostInfo.length ? (
              <>
                <Grid item={true}>
                  <InputLabel
                    label="Стоимость упаковки"
                    required={false}
                    single={true}
                  />
                </Grid>
                {copackingCostInfo.map((el) => (
                  <Grid
                    item={true}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    key={JSON.stringify(el)}
                  >
                    <TextField
                      size="small"
                      label={el.copackingCenter}
                      fullWidth={true}
                      value={el.cost}
                      variant="outlined"
                      sx={{ marginRight: '8px' }}
                      disabled={true}
                    />
                    <Tooltip title={el.costDescription} placement="right">
                      <Help>
                        <InfoOutlined />
                      </Help>
                    </Tooltip>
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
