import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import Chart ,{
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Label,
  Legend,
  Point,
  Series,
  Tick,
  Tooltip,
  ValueAxis,
  Size } from 'devextreme-react/chart'
import { DeltaValues } from 'widgets/DashboardWidgets/DoubleSmallWidget/ui/styled'

import { ChartContainer } from './styled'

import { ValueDelta } from '../../components/ValueDelta/ValueDelta'


interface WidgetData {
  name: string
  valueField: string
  data: number
  diff: number
  color: string
  areaColor?: {
    fillId: string
  }
}

interface DataParams {
  title: string
  leftData: WidgetData
  rightData: WidgetData
  chartUrl?: string
  chartData: Array<any>
}

export const TwinMediumChartWidget = ({ title, leftData, rightData, chartUrl, chartData }: DataParams) => {
  const chartContainer = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    // @ts-ignore
    setHeight(chartContainer?.current?.clientHeight)
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3px'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '20.02px', color: 'rgba(0, 0, 0, 0.54)' }}>
            {title}
          </Typography>
        </Box>
        <Box>
          {chartUrl &&
          <Box>
            <a
              style={{ display: 'flex', alignItems: 'center' }}
              href={chartUrl}
              target="_blank" rel="noreferrer">
              <BarChartIcon sx={{ color: '#00000061' }}/>
            </a>
          </Box>
          }
        </Box>
      </Box>
      <DeltaValues>
        {(leftData.data !== undefined && leftData.diff !== undefined) &&
        <Box>
          <ValueDelta value={leftData.data} delta={leftData.diff} name={leftData.name} color={leftData.color}/>
        </Box>
        }
        {(rightData.data !== undefined && rightData.diff !== undefined) &&
        <Box>
          <ValueDelta value={rightData.data} delta={rightData.diff} name={rightData.name} color={rightData.color}/>
        </Box>
        }
      </DeltaValues>
      <ChartContainer ref={chartContainer}>
        <Chart
          className="mediumChart"
          palette="Ocean"
          dataSource={chartData}
          redrawOnResize={true}
        >
          <Size height={height}/>
          <CommonSeriesSettings
            argumentField="orderDate"
            hoverMode="none"
          />
          <Series
            type="spline"
            color={leftData.color}
            width={1.5}
            valueField={leftData.valueField}>
            <Point visible={false}/>
          </Series>
          {leftData.areaColor &&
          <Series
            type="splineArea"
            color={leftData.areaColor}
            width={1.5}
            valueField={leftData.valueField}>
            <Point visible={false}/>
          </Series>
          }
          <Series
            type="spline"
            color={rightData.color}
            width={1.5}
            valueField={rightData.valueField}>
            <Point visible={false}/>
          </Series>
          {rightData.areaColor &&
          <Series
            type="splineArea"
            color={rightData.areaColor}
            width={1.5}
            valueField={rightData.valueField}>
            <Point visible={false}/>
          </Series>
          }
          <ValueAxis
            visible={false}
          >
            <Tick visible={false}/>
            <Label visible={false}/>
            <Grid visible={false}/>
          </ValueAxis>
          <ArgumentAxis
            valueMarginsEnabled={false}
            visible={true}
            argumentType="string"
            discreteAxisDivisionMode="crossLabels"
            color="#E0E0E0"
            endOnTick={true}
          >
            <Tick visible={false}/>
            <Label font={{ color: '#00000061' }} customizeText={customDate}/>
            <Grid visible={false}/>
          </ArgumentAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
            visible={false}
          />
          <Tooltip enabled={false}/>
        </Chart>
      </ChartContainer>
    </Box>
  )
}

const customDate = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    return days[utc.getDay()]
  }
  return ''
}