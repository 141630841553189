import styled from 'styled-components'

export const AutocompleteWrapper = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 5px 24px 5px 5px;
  }

  .MuiAutocomplete-inputRoot {
    height: 28px;
  }

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiAutocomplete-input {
    font-size: 14px;
  }
`