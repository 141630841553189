import styled from 'styled-components'
import TextField from '@mui/material/TextField'

export const ProductSearchWrapper = styled.div`
  margin-bottom: 24px;
`
export const SearchInput = styled(TextField)<any>`

`
export const ResultItem = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ResultList = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
`
export const ProductType = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`
export const ProductTitle = styled.a<{ addInfo: boolean }>`
  color: #007DFF;
  font-size: 14px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ addInfo }) => addInfo ? '1' : '2' };
  line-clamp: ${({ addInfo }) => addInfo ? '1' : '2' };
  -webkit-box-orient: vertical;
`
export const ProductAuxCode = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
`
export const ProductImage = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-right: 1px solid #EBEAEA;
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px 0 0 4px;
  }
`
export const ProductInfo = styled.div`
  width: 100%;
`
export const CountInput = styled(TextField)`
  width: 60px;
`
export const NoItems = styled.div`
  font-size: 16px;
  color: #000;
  padding-left: 8px;
  margin-top: 15px;
`
export const ErrorText = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`

export const InfoText = styled.div`
`

export const StyledInfoItem = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 8px;
  border-right: 1px solid #dedede;
  &:last-child {
    border-right: none;
  }
`

export const ComponentInfo = styled.div`
  padding: 8px 16px;
  min-width: 0;
`

export const CustomInput = styled.div`
  width: 53px;
  height: 48px;
  .MuiFormLabel-root {
    left: 5px;
  }
  .MuiFormControl-root, .MuiInputBase-root, input {
    height: 100%;
    text-align: center;
  }
  input {
    padding: 2px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`