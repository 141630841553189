// @ts-nocheck
import { memo } from 'react'
import { TemplateConnector } from '@devexpress/dx-react-core'
import { useSelector } from 'react-redux'
import { detailStatusSelector } from 'store/doc/selectors'
import { noop } from 'shared/lib/lodash/noop'

import { StyledGoodsName } from './styled'

import { checkOnEditable } from '../../helpers'

interface NameCellProps {
  goodsName: string,
  rowId: number,
  isEmptyAcceptanceList: boolean,
}

const CellComponent = ({ goodsName, rowId, isEmptyAcceptanceList }: NameCellProps) => {
  const docDetailStatus = useSelector(detailStatusSelector)

  const isEditable = checkOnEditable(docDetailStatus)
  const disabled = !isEditable && isEmptyAcceptanceList

  return (
    <TemplateConnector>
      {(_, { toggleDetailRowExpanded }) => {
        const toggleDetailRow = () => {
          toggleDetailRowExpanded({ rowId })
        }
        
        return (
          <StyledGoodsName
            title={goodsName}
            disabled={disabled}
            onClick={disabled ? noop : toggleDetailRow}
          >
            {goodsName}
          </StyledGoodsName>
        )
      }}
    </TemplateConnector>
  )
}

export const NameCell = memo(CellComponent)
