import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios'
import { api } from 'shared/api'
import { refreshToken } from 'shared/api/auth'
import { JWTToken } from 'shared/api/auth/models'
import { HTTP_STATUS } from 'shared/config'
import { getFingerprint } from 'shared/lib/browser/getFingerprint'

let refreshRequest: null | Promise<AxiosResponse<JWTToken>> = null

const getIsRefreshRequest = ({ config }: { config: AxiosRequestConfig }) => config?.url?.includes('refresh')

export const withRefreshTokenResponse = (axiosApiInstance: AxiosInstance) => async (error: AxiosError) => {
  const isUnauthorizedError = error?.response?.status === HTTP_STATUS.Unauthorized
  const isRefreshRequest = getIsRefreshRequest(error)

  if (!isUnauthorizedError || isRefreshRequest) {
    return Promise.reject(error)
  }

  if (error?.config?.url === '/auth/login') {
    return Promise.reject(error)
  }

  const fingerprint = await getFingerprint()

  refreshRequest = refreshRequest ?? refreshToken({ fingerprint })

  return refreshRequest
    .then(({ data }) => {
      api.setToken(data.accessToken)

      const originalRequest = error.config
      // originalRequestData - данные с 401 запроса
      // изначально в originalRequest.data данные лежат уже в трансформированном виде для бэка
      // но отправлять нужно данные сырые
      // из-за этого происходит перформирование данные обратно из готовых в сырые
      let originalRequestData
      if (originalRequest.data && Object.keys(originalRequest.data).length > 0) {
        originalRequestData = (originalRequest.transformResponse as Array<AxiosTransformer>).reduce(
          (payload, fn) => fn.call(originalRequest, payload), originalRequest.data
        )
      }
      else {
        originalRequestData = originalRequest.data
      }
      
      return axiosApiInstance({
        ...originalRequest,
        data: originalRequestData
      })
    })
    .catch((catchedError: AxiosError) => {
      const isRefreshRequestCatched = getIsRefreshRequest(catchedError)

      if (isRefreshRequestCatched) {
        api.clearToken()
      }

      throw catchedError
    })
    .finally(() => {
      refreshRequest = null
    })
}