import { api } from 'shared/api/base'
import { QueryFunctionContext, useQuery } from 'react-query'
import { pimApi } from 'shared/api'

export const getAttributeQueryKey = (attributeId: UniqueId) =>
  ['get', '/pim/categories', attributeId] as const

type GetCurrentCategoryQueryKey = ReturnType<typeof getAttributeQueryKey>

function getAttribute(attributeId: UniqueId) {
  return api.get<pimApi.attributes.CommonAttribute>
  (`/pim/attributes/${attributeId}`).then((res) => res.data)
}

const getAttributeFn = (context: QueryFunctionContext<GetCurrentCategoryQueryKey>) => {
  const [, ,categoryId] = context.queryKey
  return getAttribute(categoryId)
}

export const useGetCurrentAttribute = (attributeId: UniqueId) =>
  useQuery<
    pimApi.attributes.CommonAttribute,
    errorType,
    pimApi.attributes.CommonAttribute,
    GetCurrentCategoryQueryKey
  >(
    getAttributeQueryKey(attributeId),
    getAttributeFn
  )
