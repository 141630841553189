import { generatePath, useHistory } from 'react-router'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  Grid,
  Box,
  DialogActions,
  Button, Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pimApi } from 'shared/api'
import { PRODUCT_EDIT } from 'shared/config'
import { snackActions } from 'shared/lib/react/snackbar'
import { useEffect } from 'react'

import { MemoGoodsSearchSection } from './GoodsSearchSection'

interface ProductCreateModalProps {
  close: () => void;
  accept: () => void;
  productId: string;
}

const MODAL_STYLES = { width: '600px', maxWidth: '600px' }

export const ProductChangeGoodsModal = ({
  close,
  productId,
}: ProductCreateModalProps) => {

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && event.target.value === undefined) {
        const button = document.getElementById('submit')
        event.preventDefault()
        button?.click()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])
  const history = useHistory()
  const { mutate: updateProductGoods, isLoading } = pimApi.products.useUpdateProductGoodsMutation()

  const fetchCommonProductQuery = pimApi.products.common.useFetchCommonProductQuery(parseInt(productId, 10))
  // const validate = useValidationSchema(validationSchema)
  
  const onSubmit = (formValues) => {
    const goodsId = formValues?.selectedGoods?.[0]?.componentId
    const updateProductGoodsPayload = {
      productId,
      content: { 
        goodsId,
        productId: parseInt(productId, 10),
        versionNo: fetchCommonProductQuery.data?.versionNo || 0
      }
    }

    updateProductGoods(
      updateProductGoodsPayload,
      {
        onSuccess: ({ data }) => {
          close()
          // TODO update cache instead
          fetchCommonProductQuery.refetch()
          snackActions.info('Сохранено!')
          history.push(generatePath(PRODUCT_EDIT, { id: data.productId }))
        },
      }
    )
  }

  return (
    <Box p={3} pt={3} sx={MODAL_STYLES}>
      <Form
        onSubmit={onSubmit}
        // @ts-ignore
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}>
            <Typography sx={{
              fontSize: '20px',
              fontWeight: '500',
              lineHeight: '160%',
              letterSpacing: '0.15px',
              marginBottom: '24px'
            }}>
              Перенести продукт на другой товар
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <MemoGoodsSearchSection />
              </Grid>
            </Grid>
            <DialogActions sx={{ padding: '0px' }}>
              <Box>
                <Button onClick={close} color="primary">
                  ОТМЕНА
                </Button>
                <LoadingButton
                  id="submit"
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={values?.selectedGoods?.length < 1}
                  sx={{
                    marginLeft: 2,
                  }}
                >
                  Перенести
                </LoadingButton>
              </Box>
            </DialogActions>
          </form>
        )}
      />
    </Box>
  )
}
