export enum DateFormats {
  dayMonth = 'dd.MM',
  dayMonthW = 'd LLL',
  dayMonthYear = 'dd.MM.yyyy',
  dayMonthShortYear = 'dd.MM.yy',
  dayMonthYearHoursMinutes = 'dd.MM.yyyy HH:mm',
  dayMonthHoursMinutes = 'd LLL HH:mm',
  hoursMinutes = 'hh:mm',
  hours24Minutes = 'HH:mm',
  hours24MinutesSeconds = 'HH:mm:ss',
}

export enum RuDateFormats {
  dayMonth = 'дд.мм',
  dayMonthYear = 'дд.мм.гггг',
  dayMonthShortYear = 'дд.мм.нн',
  dayMonthYearHoursMinutes = 'дд.мм.гггг ЧЧ:мм',
  hoursMinutes = 'чч:мм',
  hours24Minutes = 'ЧЧ:мм',
  hours24MinutesSeconds = 'ЧЧ:мм:сс',
}