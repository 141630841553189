import React from 'react'
import { Field } from 'react-final-form'
import { SelectField } from 'shared/ui/components'
import { InputTextWrapper } from 'features/product/common/CompetitorsTab/ui/styled'
import { pimGen } from 'shared/lib/generated'
import styled from 'styled-components'

const FieldsWrapper = styled.div`
  
  .MuiInputBase-input {
    padding: 3px 8px 0 8px !important;
    font-size: 14px;
    height: 25px;
  }
  input {
    width: 160px;
    font-size: 14px;
    height: 28px;
    padding: 0 8px !important;
  }
`
export const AdviceIntervalCell = () => {
  const { data: adviceIntervalOptions } = pimGen.priceWizard.GetIntervals.useGetIntervals()

  return (
    <FieldsWrapper>
      <InputTextWrapper>
        <Field
          name="adviceInterval"
          placeholder="Проверка"
          component={ SelectField }
          options={ adviceIntervalOptions || [] }
          fullWidth={ true }
        />
      </InputTextWrapper>
    </FieldsWrapper>
  )
}