import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from 'shared/api/base'
import { pimApi } from 'shared/api/index'
import { AxiosResponse } from 'axios'

export const getCurrentUsersQueryKey = (userId: UniqueId) =>
  ['get', 'customer/admin/users', userId] as const

type GetCurrentUsersQueryKey = ReturnType<typeof getCurrentUsersQueryKey>

function getCurrentUser(userId: UniqueId) {
  return api.get<pimApi.admin.CurrentUser>
  (`/customer/admin/users/${userId}`).then((res) => res.data)
}

const getCurrentUserFn = (context: QueryFunctionContext<GetCurrentUsersQueryKey>) => {
  const [, ,userId] = context.queryKey
  return getCurrentUser(userId)
}

export const useGetCurrentUserQuery = (userId: UniqueId) =>
  useQuery<
    pimApi.admin.CurrentUser,
    errorType,
    pimApi.admin.CurrentUser,
    GetCurrentUsersQueryKey
    >(
      getCurrentUsersQueryKey(userId),
      getCurrentUserFn
    )



export const getUserPhotoQueryKey = (userId: UniqueId) =>
  ['get', `customer/admin/users/${userId}/photo`, userId] as const

type GetUserPhotoQueryKey = ReturnType<typeof getUserPhotoQueryKey>

export async function getUserPhoto(userId: UniqueId): Promise<AxiosResponse<Blob>> {
  return api.get(
    `/customer/admin/users/${userId}/photo`,
    {
      responseType: 'blob'
    }
  )
}

const getUserPhotoFn = (context: QueryFunctionContext<GetUserPhotoQueryKey>) => {
  const [, ,userId] = context.queryKey
  return getUserPhoto(userId)
}

export const useGetUserPhotoQuery = (userId: UniqueId) =>
  useQuery(
    getUserPhotoQueryKey(userId),
    getUserPhotoFn
  )

