import styled from 'styled-components'
import Box from '@mui/material/Box'

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

export const CompetitorBadge = styled(Box)`
  background-color: #007DFF;
  color: white;
  min-width: 15px;
  height: 15px;
  border-radius: 7px;
  position: absolute;
  display: flex;
  padding: 0 3px;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 4px;
  span {
    font-size: 12px;
  }
`